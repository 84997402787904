import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { MainMenuItem } from 'bpt-ui-library/bpt-layout';
import { isEmpty, replace } from 'lodash-es';
import { Activity, Table, Form } from 'model/experiment.interface';
import { Message, MessageService } from 'primeng/api';
import { Observable, of, Subject } from 'rxjs';
import { elnEncodeSpecialChars } from '../../shared/url-path-serializer';
import {
  NodeType,
  ExperimentNodeTitleChangedNotification,
  ChangeExperimentNodeTitleCommand,
  ExperimentWorkflowState
} from '../../api/data-entry/models';
import { ExperimentEventsService } from '../../api/data-entry/services';
import {
  ElnMenuInlineInputElement,
  ElnMenuInlineInputEvent,
  ElnMenuInlineInputValueChangedContext
} from '../model/eln-menu-inline-edit-input-element';
import { DataRecordService } from './data-record.service';
import { ExperimentService } from './experiment.service';
import { ClientStateService } from 'services/client-state.service';
import { UserService } from '../../services/user.service';

type ActivityNodeTitleChangeElementArguments = {
  activity: Activity;
  activityMenuItem: MainMenuItem;
  activityTitleElement: HTMLSpanElement;
};

@Injectable()
export class ExperimentNodeRetitleService {
  private static _hasPermissionToEditTitle = true;
  retitleOcurredSubject = new Subject<boolean>();
  public readonly currentActivityTitleChanged = new Subject<string>();
  public static get HasPermissionToEditTitle(): boolean {
    return ExperimentNodeRetitleService._hasPermissionToEditTitle;
  }
  public static readonly NotAllowedWorkflowStates: ExperimentWorkflowState[] = [
    ExperimentWorkflowState.Authorized,
    ExperimentWorkflowState.Cancelled,
    ExperimentWorkflowState.InReview,
  ];

  public readonly ActivityTitleChanged = new Subject<void>();
  private readonly nodeTitleSyncHandler: {
    [nodeType: string]: (notification: ExperimentNodeTitleChangedNotification) => void;
  } = {
    activity: this.updateChangedActivityTitle.bind(this),
    module: this.moduleTitleChangedByOtherUser.bind(this),
    table: this.moduleItemTitleChangedByOtherUser.bind(this),
    form: this.moduleItemTitleChangedByOtherUser.bind(this)
  };

  constructor(
    private readonly experimentEventsApiService: ExperimentEventsService,
    public readonly experimentService: ExperimentService,
    private readonly messageService: MessageService,
    private readonly datarecordService: DataRecordService,
    private readonly userService: UserService,
    private readonly location: Location,
    private readonly clientStateService: ClientStateService
  ) {
    this.watchNodeTitleChanges();
  }

  private watchNodeTitleChanges(): void {
    this.datarecordService.experimentNodeTitleChangedNotificationReceiver.subscribe({
      next: this.nodeTitleChangedByOtherUsers.bind(this)
    });
  }

  changeNodeTitle(
    nodeType: NodeType,
    nodeId: string,
    newTitle: string,
    oldTitle: string,
    parentNodeIds: string[]
  ): Observable<ExperimentNodeTitleChangedNotification | undefined> {
    const reTitleSuccessStatus = new Subject<ExperimentNodeTitleChangedNotification | undefined>();
    if (!this.hasPermission()) {
      this.failedToPerformOperationMessageBecauseUnAuthorized(nodeType);
      return of(undefined);
    }

    if (
      ExperimentNodeRetitleService.NotAllowedWorkflowStates.includes(
        this.experimentService.currentExperiment?.workflowState as ExperimentWorkflowState
      )
    ) {
      this.failedToPerformOperationMessage(nodeType);
      return of(undefined);
    }

    if (!this.isUniqueTitle(nodeType, newTitle, parentNodeIds, [oldTitle.toLowerCase()])) {
      return of(undefined);
    }

    this.experimentEventsApiService
      .experimentEventsChangeNodeTitlePost$Json({
        body: this.prepareCommandToChangeNodeTitleFor(nodeType, nodeId, newTitle, parentNodeIds)
      })
      .subscribe({
        next: (response) => {
          this.processReTitleSuccessResponse(response, oldTitle);
          reTitleSuccessStatus.next(response);
          this.retitleOcurredSubject.next(true);
        }
      });
    return reTitleSuccessStatus.asObservable();
  }

  public bindRetitleActivityMenuItemContext(
    activity: Activity,
    activityMenuItem: MainMenuItem
  ): MainMenuItem {
    return {
      label: $localize`:@reTitle:Retitle`,
      icon: 'icon-pencil',
      id: `eln-context-activity-title-changer-menu`,
      state: {
        activity,
        activityMenuItem
      },
      command: (_event: any) => {
        this.renderInputToRetitleFor(activity, activityMenuItem);
      },
      disabled:
        !this.hasPermission() ||
        ExperimentNodeRetitleService.NotAllowedWorkflowStates.includes(
          this.experimentService.currentExperiment?.workflowState as ExperimentWorkflowState
        )
    };
  }

  // Permissions will be updated once the radium is deployed.
  public hasPermission(): boolean {
    if(this.userService.hasOnlyReviewerRights()) {
      return false;
    }
    const featureFlags = this.clientStateService.getFeatureFlags('eln.experiment');
    ExperimentNodeRetitleService._hasPermissionToEditTitle = featureFlags.some((data) => {
      if (!data || !JSON.parse(data)) {
        return false;
      }
      const parsedData = JSON.parse(data);
      return (
        (parsedData.CanStartExperiment && parsedData.CanStartExperiment === true) ||
        (parsedData.CanEditExperimentInReviewState &&
          parsedData.CanEditExperimentInReviewState === true)
      );
    });
    return ExperimentNodeRetitleService._hasPermissionToEditTitle;
  }

  private prepareCommandToChangeNodeTitleFor(
    nodeType: NodeType,
    nodeId: string,
    modifiedTitle: string,
    parentNodeIds: string[]
  ): ChangeExperimentNodeTitleCommand {
    return {
      experimentId: this.experimentService.currentExperiment?.id as string,
      nodeId,
      title: modifiedTitle,
      nodeType: nodeType,
      activityId: nodeType === NodeType.Activity ? nodeId : parentNodeIds[0]
    };
  }

  private isUniqueTitle(
    nodeType: NodeType,
    newTitle: string,
    parentNodeIds: string[],
    titlesToExclude: string[] = []
  ): boolean {
    const currentTitles = this.getChildNodeTitlesByPath(parentNodeIds)
      .map((title) => title.toLowerCase())
      .filter((title) => !titlesToExclude.includes(title));
    if (currentTitles.includes(newTitle.trim().toLowerCase())) {
      this.duplicateTitleNotificationMessage(newTitle, nodeType);
      return false;
    }
    return true;
  }

  private processReTitleSuccessResponse(
    response: ExperimentNodeTitleChangedNotification,
    oldTitle: string
  ): void {
    this.titleChangedNotificationMessage(oldTitle, response.title, response.nodeType);
  }

  private nodeTitleChangedByOtherUsers(notification: ExperimentNodeTitleChangedNotification): void {
    this.nodeTitleSyncHandler[notification.nodeType](notification);
  }

  private getChildNodeTitlesByPath(nodeIdCollection: string[]): string[] {
    if (isEmpty(nodeIdCollection)) {
      return (
        this.experimentService.currentExperiment?.activities.map((activity) =>
          activity.itemTitle.toLowerCase()
        ) || []
      );
    }
    const activity = this.experimentService.currentExperiment?.activities.find(
      (activity) => activity.activityId === nodeIdCollection[0]
    );
    switch (nodeIdCollection.length) {
      case 1:
        return activity?.dataModules.map((module) => module.moduleLabel.toLowerCase()) || [];
      case 2:
        return (
          activity?.dataModules
            .find((module) => module.moduleId === nodeIdCollection[1])
            ?.items.map((moduleItem) => moduleItem.itemTitle.toLowerCase()) || []
        );
      default:
        return [];
    }
  }

  private updateActivityTitleInPath(oldTitle: string, newTitle: string): void {
    const path = this.location.path(true);
    const activityPathLookup = encodeURI(
      `/${this.experimentService.currentExperiment?.experimentNumber}/${elnEncodeSpecialChars(
        oldTitle
      )}`
    );
    if (this.location.path(true).includes(activityPathLookup)) {
      this.location.replaceState(
        path.replace(
          activityPathLookup,
          `/${this.experimentService.currentExperiment?.experimentNumber}/${elnEncodeSpecialChars(
            newTitle
          )}`
        )
      );
      this.currentActivityTitleChanged.next(newTitle);
    }
  }
  private updateChangedActivityTitle(
    notification: ExperimentNodeTitleChangedNotification,
    changedByOtherUser = true
  ): void {
    const node = this.experimentService.currentExperiment?.activities.find(
      (activity) => activity.activityId === notification.nodeId
    );
    const currentTitle = node?.itemTitle.trim() as string;
    var notificationTitle = notification.title.trim().replace(/\s+/g, ' ');

    if (node) {
      if (changedByOtherUser) {
        this.titleChangedNotificationMessage(
          node.itemTitle.trim(),
          notificationTitle,
          notification.nodeType
        );
      }
      node.itemTitle = notificationTitle;
      this.updateActivityTitleInPath(currentTitle, notificationTitle);
      this.updateTitleInMenu(currentTitle, notificationTitle);
      this.ActivityTitleChanged.next();
    }
  }

  private updateTitleInMenu(currentTitle: string, newTitle: string): void {
    const activityTitleSpanElement = this.getActivityTitleSpanElement(
      `eln-menu-activity-${replace(currentTitle, / /g, '')}`
    );
    if (activityTitleSpanElement) {
      activityTitleSpanElement.innerText = newTitle;
      this.retitleOcurredSubject.next(true);
    }
  }

  private moduleTitleChangedByOtherUser(
    notification: ExperimentNodeTitleChangedNotification
  ): void {
    this.experimentService.currentExperiment?.activities.forEach((activity) => {
      const node = activity.dataModules.find(
        (activityItem) => activityItem.moduleId === notification.nodeId
      );
      if (node) {
        this.titleChangedNotificationMessage(
          node.moduleLabel,
          notification.title,
          notification.nodeType
        );
        node.moduleLabel = notification.title;
      }
    });
  }

  private moduleItemTitleChangedByOtherUser(
    notification: ExperimentNodeTitleChangedNotification
  ): void {
    this.experimentService.currentExperiment?.activities.forEach((activity) => {
      activity.dataModules.forEach((activityItem) => {
        activityItem.items.forEach((moduleItem) => {
          if (moduleItem.itemType === NodeType.Table) {
            this.tableTitleChangedByOtherUser(notification, moduleItem as Table);
          } else {
            this.formTitleChangedByOtherUser(notification, moduleItem as Form);
          }
          this.retitleOcurredSubject.next(true);
        });
      });
    });
  }

  private tableTitleChangedByOtherUser(
    notification: ExperimentNodeTitleChangedNotification,
    table: Table
  ): void {
    if (table.tableId !== notification.nodeId) {
      return;
    }
    this.titleChangedNotificationMessage(
      table.itemTitle,
      notification.title,
      notification.nodeType
    );
    table.itemTitle = notification.title;
  }

  private formTitleChangedByOtherUser(
    notification: ExperimentNodeTitleChangedNotification,
    form: Form
  ): void {
    if (form.formId !== notification.nodeId) {
      return;
    }
    this.titleChangedNotificationMessage(form.itemTitle, notification.title, notification.nodeType);
    form.itemTitle = notification.title;
  }

  private renderInputToRetitleFor(activity: Activity, activityMenuItem: MainMenuItem) {
    const activityTitleInputControl = this.createInputElementToRetitle(activity, activityMenuItem);
    if (activityTitleInputControl.inputsContext.callbackArguments) {
      activityTitleInputControl.inputsContext.callbackArguments.activityTitleElement.innerHTML = ``;
      activityTitleInputControl.inputsContext.callbackArguments.activityTitleElement.appendChild(
        activityTitleInputControl.inputElement
      );
    }
    activityTitleInputControl.focusAndSelect();

    activityTitleInputControl.ValueChanged.subscribe({
      next: this.changeActivityTitle.bind(this)
    });

    activityTitleInputControl.cancelEdit.subscribe({
      next: (event: ElnMenuInlineInputEvent<ActivityNodeTitleChangeElementArguments>) => {
        if (event && event.callbackArguments && event.callbackArguments.activityTitleElement) {
          event.callbackArguments.activityTitleElement.innerText =
            event.callbackArguments.activity.itemTitle;
        }
      }
    });
  }

  private getActivityTitleSpanElement(id: string): HTMLSpanElement {
    const activityLinkMenuItem = document.querySelector(`[id*='${id}']`);
    return activityLinkMenuItem?.getElementsByClassName('p-menuitem-text')[0] as HTMLSpanElement;
  }

  private createInputElementToRetitle(
    activity: Activity,
    activityMenuItem: MainMenuItem
  ): ElnMenuInlineInputElement<ActivityNodeTitleChangeElementArguments> {
    const activityTitleSpan = this.getActivityTitleSpanElement(`${activityMenuItem.id}`);
    const callbackArguments: ActivityNodeTitleChangeElementArguments = {
      activity,
      activityMenuItem,
      activityTitleElement: activityTitleSpan
    };

    activityTitleSpan.onkeydown = function (e: KeyboardEvent) {
      if (e.key === ' ') {
        e.stopPropagation();
      }
      return true;
    };

    return new ElnMenuInlineInputElement<ActivityNodeTitleChangeElementArguments>({
      id: `bpt-eln-activity-title-input-${activity.activityId}`,
      initialValue: activity.itemTitle,
      placeholderText: $localize`:@@titlePlaceholder:Enter Title`,
      callbackArguments
    });
  }


  private changeActivityTitle(
    event: ElnMenuInlineInputValueChangedContext<ActivityNodeTitleChangeElementArguments>
  ) {
      this.changeNodeTitle(
        NodeType.Activity,
        event.callbackArguments?.activity.activityId as string,
        event.value,
        event.callbackArguments?.activity.itemTitle as string,
        []
      ).subscribe({
        next: (response) => {
          if (response) {
            this.updateChangedActivityTitle(response, false);
            this.retitleOcurredSubject.next(true);
          } else {
            event.controlContext.setErrorIndidcator();
            event.controlContext.focusAndSelect();
          }
        }
      });
  }

  private duplicateTitleNotificationMessage(newTitle: string, nodeType: string) {
    this.createNotificationMessage(
      $localize`:@@ExperimentNodeTitleDuplicate:The ${nodeType} title "${newTitle}" is already exists, please enter unique title.`,
      '',
      'error'
    );
  }

  private titleChangedNotificationMessage(oldTite: string, newTitle: string, nodeType: string) {
    this.createNotificationMessage(
      $localize`:@@ExperimentNodeTitleChangedMessage:The ${nodeType} title has been changed from "${oldTite}" to "${newTitle}".`,
      ''
    );
    this.retitleOcurredSubject.next(true);
  }

  private failedToPerformOperationMessage(nodeType: string) {
    const state = this.experimentService.currentExperiment?.workflowState;
    const type = nodeType;
    this.createNotificationMessage(
      $localize`:@@NodeRetitleFailedToPerformOperationMessage:The retitle ${type} action cannot be performed, because the experiment workflow state is ${state}.`,
      ``,
      'error'
    );
  }

  private failedToPerformOperationMessageBecauseUnAuthorized(nodeType: string) {
    this.createNotificationMessage(
      $localize`:@@NodeRetitleFailedToPerformOperationMessageUnAuthorized:The retitle ${nodeType} action cannot be performed, because your auhtorization is failed.`,
      ``,
      'error'
    );
  }

  private createNotificationMessage(summary: string, detail: string, severity = 'success') {
    const messageObj: Message = {
      key: 'notification',
      severity: severity,
      summary,
      detail,
      sticky: false
    };
    this.messageService.add(messageObj);
  }
}
