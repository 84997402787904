import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { PromptConstants } from '../prompt-constants';
import { PromptItem, PromptType, PromptItemValueType, promptItemBuilder } from '../models/prompt.model';

@Component({
  selector: 'app-add-prompt-slider',
  templateUrl: './add-prompt-slider.component.html'
})
export class AddPromptSliderComponent implements OnInit {
  @Input() showSlider = false;
  @Input() promptType !: PromptType;
  @Input() promptSliderHeaderLabel = '';
  @Input() currentPromptItemValues = {} as any;
  @Output() closeSlider = new EventEmitter();
  @Output() createPromptClicked = new EventEmitter<PromptItem<PromptType, PromptItemValueType>>();
  @Output() updatePromptClicked = new EventEmitter<{ type: PromptType, promptId: string, editedFields: {[key: string]: string}}>();

  editedFieldInPrompts: {[key: string]: string} = {} as any;

  promptItem!: PromptItem<PromptType, PromptItemValueType>;
  requiredFieldMessage = PromptConstants.fieldRequired;
  updateButton = false;
  sliderCloseConfirmation = new Subject<boolean>();
  sliderOptions = {
    size: 'large',
    visible: true,
    closeOnEscape: false,
    sliderHeaderText: () => $localize`:@@PromptSliderHeaderText:Add ${this.promptSliderHeaderLabel}`,
    sliderHeaderTextForEdit: () =>  $localize`:@@PromptSliderHeaderTextForEdit:Edit ${this.promptSliderHeaderLabel}`,
    isFooterSticky: true,
    displayFooter: true,
    displayFooterWithPrimaryButton: false,
    displayFooterWithSecondaryButton: false,
    isModal: true
  };
  sliderLabelManager = PromptConstants.sliderLabelManager;
  formFieldProperties: { [key: string]: string } = {};
  requiredFields = ['name'];

  ngOnInit(): void {
    this.formFieldProperties = {};
    if(this.currentPromptItemValues === undefined) {
      this.updateButton = false;
      this.promptItem = promptItemBuilder(this.promptType);
    }
    else {
      this.updateButton = true;
      this.promptItem = promptItemBuilder(this.promptType, this.currentPromptItemValues.data);
    }
    this.fieldNames().forEach(key => {
      this.formFieldProperties[key] = (this.promptItem.value as any)[key];
    })
  }

  /*
  *
  * Method will trigger when you click close icon of slider user to implement any logic with closing slider
  */
  sliderClosing = (e: Event) => {
    this.resetPropertyChanges();
    return this.sliderCloseConfirmation.asObservable();
  }

  /**
   * Sets the slider visible state if slider is closed
  */
  sliderVisibleChange(sliderOpen: boolean) {
    if (!sliderOpen) {
      this.resetPropertyChanges();
      this.closeSlider.emit(this.sliderOptions.visible);
    }
  }

  createPrompt() {
    const hasNewData = this.finalizePropertyChanges();
    if (!hasNewData) this.resetPropertyChanges();
    else {
      this.createPromptClicked.emit(this.promptItem);
    };
  }

  updatePrompt() {
    const hasNewData = this.finalizePropertyChanges();
    if (hasNewData) {
      this.updatePromptClicked.emit({type:this.promptType, promptId: this.currentPromptItemValues.data.promptId, editedFields:this.editedFieldInPrompts});
    }
  }

  resetPropertyChanges() {
    this.formFieldProperties = {};
    this.closeSlider.emit(false);
  }

  private finalizePropertyChanges() {
    let hasNewData = false;
    this.fieldNames().forEach(key => {
      if ((this.promptItem.value as any)[key] !== this.formFieldProperties[key]) {
        hasNewData = true;
        this.editedFieldInPrompts[key] = this.formFieldProperties[key];
        (this.promptItem.value as any)[key] = this.formFieldProperties[key];
      }
    })
    return hasNewData;
  }

  fieldNames() {
    const keys = Object.keys(this.promptItem.value);
    ['clientFacingPrompt', 'source', 'isPromptRemoved'].forEach(field => keys.splice(keys.indexOf(field), 1));
    keys.push('clientFacingPrompt');
    return keys;
  }
  
  prepareLabel(prop: string) {
    return this.promptItem.type === PromptType.Columns && prop === 'name' ? this.sliderLabelManager.nameOrPacking : this.sliderLabelManager[prop];
  }

  isRequired = (prop: string) => this.requiredFields.includes(prop);

  isPromptDataChanged = (keys = this.fieldNames()): boolean => keys.some(key => (this.formFieldProperties[key] !== (this.promptItem.value as any)[key]));

  isRequiredDataCorrect = () => this.requiredFields.some(key => this.formFieldProperties[key].trim().length > 0);

  handleModelChange = (value: string, prop: string) => this.formFieldProperties[prop] = value.trim();
}
