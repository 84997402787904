<div class="copy-recipe-dialog" id="copy-recipe-dialog">
  <div style="text-align: left">
    <div class="copy-recipe-dialog-header">{{ dialogHeader }}</div>
    <div class="copy-recipe-dialog-text">{{ dialogSubHeader }}</div>
  </div>
  <div class="copy-recipe-content">
    <bpt-text-input
      inputId="eln-copy-recipe-name-text"
      #elncopyrecipedialogname
      [(ngModel)]="recipeName"
      placeholder="{{ recipeNameInputPlaceholderText }}"
      [maxlength]="1000"
      label="{{ recipeNameInputLabel }}"      
      [required]="true"
    ></bpt-text-input>
  </div>  
  <div class="copy-recipe-dialog-footer">
    <p-button
      id="copy-recipe-dialog-cancel-button"
      label="{{ footerCancelButtonLabel }}"
      styleClass="p-button-outlined p-button-warning"
      (click)="onCancelClicked()"
    ></p-button>
    <p-button
      id="copy-recipe-dialog-save-button"
      label="{{ footerSaveButtonLabel }}"
      (click)="onSaveClicked()"
      [disabled]="recipeName === ''"
    ></p-button>
  </div>
</div>
