import { MonoTypeOperatorFunction, of, Subscription, Observable } from "rxjs";
import { map } from "rxjs/operators";

export const objectCache: { [key: string]: any } = {};

export function UnsubscribeAll(subscriptions: Subscription[]): void {
  subscriptions.forEach((subscription) => {
    if (subscription) {
      subscription.unsubscribe();
    }
  });
}

/** 
 * The data received from observables will be cached and same can be retrieved using key. 
*/
export function elnObjectCache<T>(key: string): MonoTypeOperatorFunction<T> {
  return (source) => source.pipe(map(_source => {
    objectCache[key] = _source;
    return _source;
  }));
}

/** 
 * Will be returning a data from cache when its present, otherwise it will fallback to given function to provide Observable for data
 * and when fallback observable emits the data same will be cached with key supplied here.
 * the fallback function can be a API call nor any other function which can provide the data in case of cache not exits.
*/
export function elnShareReplay<T>(key: string, fallback: (params: any) => Observable<T>, params?: any): Observable<T> {
  if(objectCache[key]){
    return of(objectCache[key])
  }
  return fallback(params).pipe(elnObjectCache(key));
}

/** 
 * deletes the cache for given key. 
*/
export function clearObjectCache(key: string) {
  delete objectCache[key];
}

/**
 * Adds the value to the cache for the given key 
 */
export function addToCache(key: string, value: any) {
  objectCache[key] = value;
}
