import { TitleCasePipe } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { FeatureService } from 'services/feature.service';
import { Logger } from 'services/logger.service';
import { CurrentState } from './app.states';
import  * as titleCaseHelper from '../app/shared/title-case-helper'
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'ELN';

  private eventSubscription?: Subscription;

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly titleService: Title,
    private readonly logService: Logger,
    private readonly featureManager: FeatureService,
    private readonly  titleCasePipe:TitleCasePipe,
    @Inject('Window') private readonly window: Window
  ) {
    titleCaseHelper.setTitleCasePipe(this.titleCasePipe)
  }
  async ngOnInit() {
    const appTitle = this.titleService.getTitle();
    this.eventSubscription = this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let newTitle = '';
          let child = this.activatedRoute.firstChild;
          while (child?.firstChild) {
            child = child.firstChild;
            if (child?.snapshot?.data['title']) {
              newTitle = `${newTitle}${newTitle ? ' - ' : ''}${child.snapshot.data['title']}`;
            }
          }
          return newTitle || appTitle;
        })
      )
      .subscribe((title: string) => {
        this.titleService.setTitle(title);
      });

    this.setupAngularRoutingForMiniSpa();
    this.goToState();
    this.logService.openLogViewer(this.window);
    await this.featureManager.loadFeatureFlags();
  }

  /**
   * Assigns the methods to window property for checking the client state and routing to it in mini-spa pattern
   * Apps rendered inside lims should set this to enable client side routing
   */
  setupAngularRoutingForMiniSpa() {
    window.angularApp = {
      goToState: this.goToState.bind(this),
      checkState: this.checkState.bind(this)
    };
  }

  /**
   * Checks for the current state loaded in window object by lims
   * Based on the client state, the corresponding module will be redirected in ELN
   * This function works only within Helium UI
   */
  goToState() {
    var windowVar = window as any;
    if (windowVar['currentState']) {
      if (CurrentState[windowVar['currentState']]) {
        this.router.navigateByUrl(CurrentState[windowVar['currentState']]);
      }
    }
  }

  /**
   * Checks if the state belongs to this app or not
   * Multiple angular apps are loaded into lims so this is to make sure that the state belongs
   * to the current opened angular app
   */
  checkState(state: string){
    return CurrentState[state] ? true : false;
  }

  ngOnDestroy(): void {
    this.eventSubscription?.unsubscribe();
  }
}

/**
 * Sets the window and a property for mini-spa pattern to invoke clientside routing.
 */
declare global {
  interface Window {
    angularApp: {
      goToState: () => void;
      checkState: (state: string) => boolean;
    }
  }
}
