<div *ngIf="(studyActivityDataSource && studyActivityDataSource.length > 0 && gridSavedPreferences) || containsRemovedRows" class="eln-activity-input-study-activity module-item module-table"
  [ngClass]="{
    'visibility-status': !this.isDataRendered    
  }">
  <div>
    <div>
      <div class="flex flex-row column-gap-2">
        <span id="eln-study-activity-aliquot-icon" class="eln-study-activity-aliquot-icon">{{
          studyActivitiesMaterialAliquots }}
        </span>
        <div class="completion-tracker">
          <app-completion-tracking [completionPercent]="completionPercent"></app-completion-tracking>
        </div>
      </div>      
        <bpt-grid
        #grid
        class="eln-grid"
        *ngIf="reloadGrid"
        ngClass="true"
        gridId="{{ studyActivityId }}"
        [dataSource]="studyActivityDataSource"
        [useJsJodaTypes]="true"
        [columnDefinitions]="columnDefinitions"
        (cellValueChanged)="cellValueChanged($event)"
        [gridMode]="gridMode"
        [allowRowAdd]="false"
        (cellEditStarted)="cellEditStartedEvent($event)"
        (cellEditStopped)="cellEditStoppedEvent($event)"
        (gridReady)="onGridReady()"
        [showAutoSizeButton]="true"
        [showFilterToggleButton]="true"
        [showGridOptionsButton]="false"
        [paginated]="false"
        [savedPreferences]="gridSavedPreferences"
        (newGridPreferenceAdded)="saveNewPreference($event)"
        (gridPreferenceDeleted)="deletePreference($event)"
        (gridPreferenceUpdated)="updatePreference($event)"
        (gridPreferenceSelectionChanged)="changeDefaultPreference($event)"
        (gridReady)="onGridReady()"
        [rowActionConfigurations]="gridActions"
        [displayScrollButtons]="displayScrollButtons"
        [contextMenu]="getContextMenu()"
        (firstDataRendered)="onFirstDataRendered()">
        <ng-container class="bpt-grid-toolbar-right-layout">
          <a
            class="table-grid-toolbar-audit-icon"
            id="eln-iconAuditHistoryShow-{{ studyActivityId }}"
            (click)="loadAuditHistoryDialog()">
            <span
              i18n-pTooltip="@@viewHistory"
              class="fa fa-history"
              pTooltip="View History"
              tooltipPosition="top"><span class="ml-1 audit" i18n="@@History">History</span></span>
          </a>
        </ng-container>
        <ng-container class="bpt-grid-toolbar-right-layout">
          <a
            *ngIf="containsRemovedRows"
            class="bpt-grid-toolbar-item table-grid-toolbar-view-removed-rows-icon study-activity-table-remove-icon"
            align="right"
            id="eln-iconViewRemovedRowsShow-{{ studyActivityId }}"
            (click)="viewRemovedRows()"
          >
            <span
              i18n-pTooltip="@@viewRemovedRows"
              class="fa icon-view-row"
              pTooltip="View Removed Rows"
              tooltipPosition="top"
              ><span class="ml-1 viewRemovedRows-iconTxt" i18n="@@viewRemovedRows"
                >View Removed Rows</span
              ></span
            >
          </a>
        </ng-container>
      </bpt-grid>      
    </div>
  </div>
</div>