import { Component, OnInit, ViewChild, Renderer2, OnDestroy } from '@angular/core';
import { ColumnType } from '../../../../api/models/column-type';
import { BptGridComponent, BptGridMode, BptGridRowActionClickEvent, BptGridRowActionConfiguration, BptRowActionElement, ColumnDefinition } from 'bpt-ui-library/bpt-grid';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { LockType } from 'model/input-lock.interface';
import { ExperimentNotificationService } from 'services/experiment-notification.service';
import { Experiment } from 'model/experiment.interface';
import { ActivityInputType, ModifiableDataValue } from '../../../../api/models';
import { ExperimentService } from '../../../services/experiment.service';
import { ActivityInputEventsService } from '../../../../api/data-entry/services';
import { mapValues } from 'lodash-es';
import { ActivityInputSamples } from '../../../model/activity-input-samples';
import { DataValueService } from '../../../services/data-value.service';
import { ActivityInputRowRestoredResponse } from '../../../../api/data-entry/models/activity-input-row-restored-response';
import { BehaviorSubject } from 'rxjs';
import { SampleTableGridOptions } from '../../sample-table/sample-table-grid-options';
import { ActivityInputHelper } from '../../shared/activity-input-helper';
import { UserService } from '../../../../services/user.service';

@Component({
  selector: 'app-sample-table-removed-rows-table',
  templateUrl: './sample-table-removed-rows.component.html',
  styleUrls: ['./sample-table-removed-rows.component.scss']
})
export class SampleTableRemovedRowsComponent implements OnInit, OnDestroy {
  titleOfTable = $localize`:@@SampleTableTitle:Samples & Aliquots`;
  subHeading = $localize`:@@SampleTableRemovedSubHeading:Please click on the restore icon from the table to restore removed Sample Aliquots`;
  removedRowsColumnDefinitions: ColumnDefinition[] = [];
  gridMode = BptGridMode.dataEntry;
  removedRowsTableId = 'removedSampleAliquotsTable';
  rowSelection = 'single';
  additionalInformation = 'AdditionalInformation';
  testReportableName = 'TestReportableName';
  removedSampleAliquots: ActivityInputSamples[] = [];
  experimentId = '';
  experiment!: Experiment;
  primitiveValue!: { [key: string]: any }[];
  activityInputType!: ActivityInputType;
  gridActions!: BptGridRowActionConfiguration;
  displayScrollButtons = true;
  @ViewChild('removedSampleAliquotsGrid') grid!: BptGridComponent;
  constructor(
    private readonly dynamicDialogConfig: DynamicDialogConfig,
    private readonly confirmationService: ConfirmationService,
    private readonly activityInputEventsService: ActivityInputEventsService,
    private readonly messageService: MessageService,
    private readonly experimentService: ExperimentService,
    private readonly dataValueService: DataValueService,
    private readonly experimentNotificationService: ExperimentNotificationService,
    private readonly renderer: Renderer2,
    private readonly activityInputHelper: ActivityInputHelper,
    private readonly userService: UserService,
  ) {
  }

  ngOnInit(): void {
    this.handleSubscriptions();
    this.removedRowsColumnDefinitions = SampleTableGridOptions.prepareColumns();
    this.refreshDataSource();
    this.addGridActions();
  }

  ngOnDestroy(): void {
    SampleTableGridOptions.isReadOnly = this.dynamicDialogConfig.data.isReadOnly;
  }

  public getPrimitiveDataValueRows(
    incomingRows: { [key: string]: ModifiableDataValue | string }[]
  ): { [key: string]: any }[] {
    return incomingRows.map((row) =>
      mapValues(row, (value: ModifiableDataValue | string, field: string) => {
        const column = (this.removedRowsColumnDefinitions ?? []).find((d) => d.field === field);
        return this.dataValueService.getPrimitiveValue(
          (column?.columnType as ColumnType) ?? ColumnType.String,
          value as ModifiableDataValue
        );
      })
    );
  }

  onGridReady() {
    this.activityInputHelper.loadCellLocks(this.grid.gridOptions, this.renderer);
    this.grid?.gridApi?.autoSizeAllColumns();
  }

  restoreCallbackForSampleAliquot = (row: ActivityInputSamples) => {
    this.activityInputHelper.sendInputStatus(LockType.lock, this.grid.gridId, row.aliquotNumber, this.activityInputHelper.rowRestoreIdentifier);
    this.confirmationService.confirm({
      message: $localize`:@@restoreRowConfirmation:Are you sure you wish to restore this row?`,
      header: $localize`:@@restoreRow:Restore row`,
      icon: 'pi pi-exclamation-triangle',
      closeOnEscape: true,
      dismissableMask: false,
      accept: () => {
        this.processSampleAliquotRestore(row);
        this.activityInputHelper.sendInputStatus(LockType.unlock, this.grid.gridId, row.aliquotNumber, this.activityInputHelper.rowRestoreIdentifier);
      },
      reject: () => {
        this.activityInputHelper.sendInputStatus(LockType.unlock, this.grid.gridId, row.aliquotNumber, this.activityInputHelper.rowRestoreIdentifier);
      }
    });
  };

  private handleSubscriptions() {
    this.experimentNotificationService.inputLockReceiver.subscribe((lock) => {
      if(!this.grid.gridOptions) return;
      this.activityInputHelper.applyCellLock(lock, this.grid.gridOptions, this.renderer);
    }
    );
    this.activityInputHelper.updateSampleAliquotTableDataSource.subscribe({
      next: this.refreshDataSource.bind(this)
    })
  }

  private refreshDataSource(): void {
    const aliquots = this.activityInputHelper.getSampleAliquots(true);
    this.removedSampleAliquots = [...aliquots];
    this.grid?.gridApi?.setGridOption('rowData', this.removedSampleAliquots);
  }

  private processSampleAliquotRestore(row: ActivityInputSamples) {
    this.activityInputEventsService
      .activityInputEventsRestoreRowPost$Json({
        body: {
          activityId: this.experimentService.currentActivityId,
          activityInputReference: row.aliquotNumber,
          activityInputType: ActivityInputType.Aliquot,
          experimentId:
            typeof this.experimentService.currentExperiment?.id === 'undefined'
              ? ''
              : this.experimentService.currentExperiment?.id,
          studyActivities:[],
          selectedStudyActivityCode:''
        }
      })
      .subscribe({
        next: (response: ActivityInputRowRestoredResponse) => {
          const aliquotNumber = response.rowRestoredEventNotification.activityInputReference
          this.activityInputHelper.restoreAliquotFromSampleSource(aliquotNumber, response.rowRestoredEventNotification.activityId);
          this.refreshDataSource();
          this.activityInputHelper.aliquotRestored.next(aliquotNumber);
          this.messageService.add({
            key: 'notification',
            severity: 'success',
            summary: $localize`:@@activityInputRestored:Activity input successfully restored`,
            detail: `${aliquotNumber}`
          });
        },
        error: () => {
          this.showError(row.activityInputReference);
        }
      });
  }

  private showError(activityInputReference: string){
    this.messageService.add({
      key: 'notification',
      severity: 'error',
      summary: $localize`:@@activityInputRestoreFailed:Failed to restore Activity input`,
      detail: `${activityInputReference}`
    });
  }

  private addGridActions() {
    const rowActions = this.getRowActionItems();
    const actionsSubject: BehaviorSubject<BptRowActionElement[]> = new BehaviorSubject(rowActions);

    this.gridActions = {
      actions: actionsSubject,
      skipAutoSizeAllColumnsWhenFirstDataRendered: false
    };
  }

  public getRowActionItems(): BptRowActionElement[] {
    return [
      {
        id: this.activityInputHelper.rowRestoreIdentifier,
        enabled: !(this.userService.hasOnlyReviewerRights() || SampleTableGridOptions.isReadOnly),
        styleClass: 'icon-s icon-restore-icon',
        click: this.rowRestoreActionClick.bind(this),
        tooltip: $localize`:@@RestoreItem:Restore item`
      }
    ];
  }

  private rowRestoreActionClick(e: BptGridRowActionClickEvent) {
    this.restoreCallbackForSampleAliquot(e.params.data);
  }
}
