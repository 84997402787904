/* tslint:disable */
/* eslint-disable */
export enum NodeType {
  Recipe = 'recipe',
  StatusRecord = 'statusRecord',
  Activity = 'activity',
  Module = 'module',
  Table = 'table',
  Form = 'form',
  TableForm = 'tableForm',
  VariablesNode = 'variablesNode',
  Preparations = 'preparations',
  References = 'references',
  Prompts = 'prompts',
  PromptPreparationReferences = 'promptPreparationReferences'
}
