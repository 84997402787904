<div>
  <div class="eln-deleteClicked" (click)="deleteClicked()"
    [ngClass]="{'disable-action': !userActionsAllowed}">
    <span
      class="icon-m icon-trash eln-icon-color"
      pTooltip="{{deleteFile}}"
      tooltipPosition="bottom">
    </span>
  </div>
  <span
    class="pi pi-download fa-lg pl-5 cursor-pointer eln-icon-color"
    (click)="downloadClicked()"
    pTooltip="{{downloadFile}}"
    tooltipPosition="bottom">
  </span>
</div> 