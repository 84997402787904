import { FieldDefinitionResponse, FieldGroupResponse, FormItemResponse } from '../../../api/models';
import {
  ExperimentDataRecordNotification,
  ChangeFieldCommand
} from '../../../api/data-entry/models';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { guid } from 'model/template.interface';
import { FieldGroup } from 'model/experiment.interface';
import { Observable } from 'rxjs';
import { FieldDataForCompletionTracking } from '../../model/field-data-for-completion-tracking.interface';
import { FieldLock } from 'model/input-lock.interface';

@Component({
  selector: 'app-data-field-group[group][value][experimentId][formId][path]',
  templateUrl: './field-group.component.html',
  styleUrls: ['./field-group.component.scss']
})
export class FieldGroupComponent implements OnInit {
  @Input() group!: FieldGroup;
  @Input() value!: any;
  @Input() experimentId!: guid;
  @Input() formId!: guid;
  @Input() path!: string[];
  @Input() greatestTabOrder = 0;
  @Input() parentNodeId: string[] = [];
  @Input() formEvents!: Observable<FieldLock | ExperimentDataRecordNotification>;
  @Output() fieldChangedEvent = new EventEmitter<ChangeFieldCommand>();
  @Output() fieldReady = new EventEmitter<FieldDataForCompletionTracking>();
  private readonly disabledStyle = 'eln-disabled';

  /** Cast to FieldDefinition. For use in component template */
  asFieldDefinition = (item: FormItemResponse) => item as FieldDefinitionResponse;

  /** Cast to FieldGroup. For use in component template */
  asFieldGroup = (item: FormItemResponse) => item as FieldGroupResponse;

  asFormItem = (item: FieldGroupResponse | FieldDefinitionResponse) => item as FormItemResponse;

  isDisabled = (item?: FieldGroupResponse | FieldDefinitionResponse) =>
    (item as FormItemResponse)?.disabled ? this.disabledStyle : '';
  column = (item?: FieldGroupResponse | FieldDefinitionResponse) =>
    (item as FormItemResponse)?.column;
  row = (item?: FieldGroupResponse | FieldDefinitionResponse) => (item as FormItemResponse)?.row;

  childPath = (child: string) => [...this.path, child];

  constructor(
    private readonly renderer: Renderer2,
    private readonly elementRef: ElementRef
  ) {    
  }
   
  ngOnInit(): void {
    this.renderer.setAttribute(this.elementRef.nativeElement, 'data-field', this.group.field);
    this.renderer.setAttribute(this.elementRef.nativeElement, 'data-title', this.group.itemTitle);
  }

  applyFieldChangedEvent(command: ChangeFieldCommand) {
    this.fieldChangedEvent.emit(command);
  }

  applyFieldReadyEvent(command: FieldDataForCompletionTracking) {
    this.fieldReady.emit(command);
  }
}
