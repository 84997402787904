/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { InstrumentResponse } from '../models/instrument-response';
import { instrumentsInstrumentCodeGet$Json } from '../fn/instrument/instruments-instrument-code-get-json';
import { InstrumentsInstrumentCodeGet$Json$Params } from '../fn/instrument/instruments-instrument-code-get-json';
import { instrumentsInstrumentCodeGet$Plain } from '../fn/instrument/instruments-instrument-code-get-plain';
import { InstrumentsInstrumentCodeGet$Plain$Params } from '../fn/instrument/instruments-instrument-code-get-plain';

@Injectable({ providedIn: 'root' })
export class InstrumentService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `instrumentsInstrumentCodeGet()` */
  static readonly InstrumentsInstrumentCodeGetPath = '/instruments/{instrumentCode}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `instrumentsInstrumentCodeGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  instrumentsInstrumentCodeGet$Plain$Response(params: InstrumentsInstrumentCodeGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<InstrumentResponse>> {
    return instrumentsInstrumentCodeGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `instrumentsInstrumentCodeGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  instrumentsInstrumentCodeGet$Plain(params: InstrumentsInstrumentCodeGet$Plain$Params, context?: HttpContext): Observable<InstrumentResponse> {
    return this.instrumentsInstrumentCodeGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<InstrumentResponse>): InstrumentResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `instrumentsInstrumentCodeGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  instrumentsInstrumentCodeGet$Json$Response(params: InstrumentsInstrumentCodeGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<InstrumentResponse>> {
    return instrumentsInstrumentCodeGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `instrumentsInstrumentCodeGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  instrumentsInstrumentCodeGet$Json(params: InstrumentsInstrumentCodeGet$Json$Params, context?: HttpContext): Observable<InstrumentResponse> {
    return this.instrumentsInstrumentCodeGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<InstrumentResponse>): InstrumentResponse => r.body)
    );
  }

}
