<div
  id="eln-labitems-material-grid-container"
  *ngIf="
    (labItemsMaterialDataSource && labItemsMaterialDataSource.length > 0) || containsRemovedRows
  "
  class="eln-table-theme"
>
  <div>
    <span
      id="eln-study-activity-aliquot-icon"
      class="eln-study-activity-aliquot-icon table-title"
      >{{ titleOfTable }}
    </span>
  </div>
  <bpt-grid
    #labItemsMaterialGrid
    *ngIf="reloadGrid"
    class="eln-grid hide-readonly-icons"
    ngClass="true"
    gridId="{{ labItemsMaterialId }}"
    [dataSource]="labItemsMaterialDataSource"
    [useJsJodaTypes]="true"
    [columnDefinitions]="columnDefinitions"
    [gridMode]="gridMode"
    [allowRowAdd]="false"
    (gridReady)="onGridReady()"
    [showAutoSizeButton]="true"
    [showFilterToggleButton]="true"
    [showGridOptionsButton]="false"
    (valuesPasted)="pasteValues($event)"
    [contextMenu]="getContextMenu()"
    [paginated]="false"
    (cellValueChanged)="cellValueChanged($event)"
    [rowActionConfigurations]="gridActions"
    [applicationGridOptions]="gridActionsPositionManager.applicationGridOptions"
    (firstDataRendered)="gridActionsPositionManager.firstDataRendered($event)"
  >
    <ng-container class="bpt-grid-toolbar-right-layout">
      <a
        class="bpt-grid-toolbar-item table-grid-toolbar-audit-icon"
        align="right"
        id="eln-iconAuditHistoryShow-{{ labItemsMaterialId }}"
        (click)="loadAuditHistoryDialog()"
      >
        <span
          i18n-pTooltip="@@viewHistory"
          class="fa fa-history"
          pTooltip="View History"
          tooltipPosition="top"
          ><span class="ml-1 audit" i18n="@@History">History</span></span
        >
      </a>
      <a
        *ngIf="containsRemovedRows"
        class="bpt-grid-toolbar-item table-grid-toolbar-view-removed-rows-icon ml-3"
        align="right"
        id="eln-iconViewRemovedRowsShow-{{ labItemsMaterialId }}"
        (click)="loadRemovedRowsDialog()"
      >
        <span
          i18n-pTooltip="@@viewRemovedRows"
          class="fa icon-view-row"
          pTooltip="View Removed Rows"
          tooltipPosition="top"
          ><span class="ml-1 viewRemovedRows-iconTxt" i18n="@@viewRemovedRows"
            >View Removed Rows</span
          ></span
        >
      </a>
    </ng-container>
  </bpt-grid>
</div>
