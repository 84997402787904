import { ColumnDefinition, ColumnType } from "bpt-ui-library/bpt-grid";
import { Message, MessageService } from "primeng/api";

export class ScalableRecipeConstants {
  static errorMessageObj: Message = {
    key: 'notification',
    severity: 'error',
    summary: $localize`:@@ScalingFactorCannotBeZero:Scaling factor cannot be zero.`,
  }
  static getColumnDefinitions(messageService?: MessageService): { [key: string]: ColumnDefinition[] } {
    return {
      'specification': [
        {
          columnType: ColumnType.autoIncrement,
          field: "index",
          label: "Index",
          width: 90,
          minWidth: 60
        },
        {
          columnType: ColumnType.string,
          field: "tableName",
          label: "Table Name",
          width: "auto",
          editable: false,
        },
        {
          columnType: ColumnType.number,
          field: "factor",
          label: "Factor",
          editable: true,
          allowDecimal: true,
          allowNegative: false,
          onCellValueChanged: (params) => {
            if (params.newValue === "0") {
              messageService?.add(ScalableRecipeConstants.errorMessageObj)
              if (params.node && params.colDef.field) {
                params.node.setDataValue(params.colDef.field, params.oldValue);
              }
            }
          }
        }
      ] as ColumnDefinition[],
      'preparation': [
        {
          columnType: ColumnType.autoIncrement,
          field: "index",
          label: "Index",
          width: "auto",
          minWidth: 50
        },
        {
          columnType: ColumnType.rowId,
          field: "rowId",
          label: "Row id",
          editable: false,
          hidden: true,
          width: "auto"
        },
        {
          columnType:ColumnType.string,
          field: "name",
          label: "Name",
          editable: true,
          width: "auto"
        },
        {
          columnType:ColumnType.string,
          field: "scalingFlag",
          label: "Scaling Flag",
          hidden: true,
          editable: false,
          width: "auto"
        },
        {
          columnType: ColumnType.quantity,
          field: "base",
          label: "Base",
          editable: false,
          filter: true,
          width: "auto"
        },
        {
          columnType:ColumnType.number,
          field: "factor",
          label: "Factor",
          editable: true,
          width: "auto"
        },
        {
          columnType: ColumnType.number,
          field: "scaled",
          label: "Scaled",
          editable: false,
          width: "auto"
        },
      ] as ColumnDefinition[]
    }
  };
}
