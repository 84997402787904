import { Injectable } from '@angular/core';
import { Activity } from 'model/experiment.interface';
import { Subject } from 'rxjs';
import { DataPackageService as OpenAPIDataPackageService } from '../../api/data-package/services';
import { DataPackageResponse } from '../../api/data-package/models/ELN/DataPackage/API/Entities/data-package-response';
import { ElnProgressSpinnerService } from '../../eln-progress-spinner-module/eln-progress-spinner.service';
@Injectable({
  providedIn: 'root'
})
export class DataPackageService {
  public openSlider = new Subject<boolean>();
  public activityData = new Subject<Activity[]>();
  constructor(private readonly service: OpenAPIDataPackageService, private readonly spinnerService: ElnProgressSpinnerService) { }

  getSliderObservable() {
    return this.openSlider.asObservable();
  }

  fetchExperimentActivities() {
    return this.activityData.asObservable();
  }

  openSliderPanel(data?: Activity[]) {
    this.openSlider.next(true);
    if (data) {
      this.activityData.next(data);
    }
  }

  getDataPackage(experimentId: string) {
    this.service.experimentsExperimentIdDataPackagesGet$Json({
      experimentId: experimentId,
    }).subscribe({
      next: (_results) => {
        this.openFileInNewTab(_results);
        this.spinnerService.Hide();
      }
    });
  }

  getDataPackageWithSelectedActivities(experimentId: string, selectedActivities: string[]) {
    this.service.experimentsExperimentIdDataPackagesSelectedActivitiesPost$Json({
      experimentId: experimentId,
      body: selectedActivities
    }).subscribe({
      next: (results) => {
        this.openFileInNewTab(results);
        this.spinnerService.Hide();
      }
    });
  }

  // Opens PDF in New Tab
  openFileInNewTab(data: DataPackageResponse) {
    try {
      const binaryString = window.atob(data.content);
      const bytes = new Uint8Array(binaryString.length);
      const mappedData = bytes.map((_byte, i) => binaryString.charCodeAt(i));
      const blob = new Blob([mappedData], { type: 'application/pdf' });
      const url: string = URL.createObjectURL(blob);
      window.open(url);
    } catch (error) { console.log(error); }
  }
}
