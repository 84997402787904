import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { BptGridComponent, BptGridMode, ColumnDefinition } from 'bpt-ui-library/bpt-grid';

export interface RecipeSourcesModel {
  referenceNumber: string;
  name: string;
  version: string;
  workflowState: string;
  id: string;
  type: any;
  addedTime?: Date;
  sourceType: string;
}

@Component({
  selector: 'app-recipe-sources-slider',
  templateUrl: './recipe-sources-slider.component.html',
  styleUrls: ['./recipe-sources-slider.component.scss']
})
export class RecipeSourcesSliderComponent implements OnChanges{
  @Output() closePanel: EventEmitter<string> = new EventEmitter<string>();
  @Input() sliderVisible = false;
  @Input() data :RecipeSourcesModel[] = [];
  @Input() header = "";
  @Input() subHeader = "";
  @Input() columnDefinitions: ColumnDefinition[] = [];
  @ViewChild('grid') grid!: BptGridComponent;

  gridMode = BptGridMode.dataView;
  dataLoaded = false;

  constructor() {}

  ngOnChanges(_changes: SimpleChanges): void {
    if (_changes.data) {
      this.refreshDataSource();
    }
  }

  refreshDataSource() {
    this.dataLoaded = true;
    this.grid?.gridApi?.setGridOption('rowData', this.data);
  }

  closeSlider() {
    this.closePanel.emit('closePanel');
  }
}
