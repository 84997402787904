import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import {
  ApplicationStateService,
  ApplicationStatus
} from '../../experiment/services/application-state.service';

@Injectable({
  providedIn: 'root'
})
export class ExperimentStatusInterceptor implements HttpInterceptor {
  constructor(private readonly applicationStateService: ApplicationStateService) {}
  totalRequests = 0;
  errorRequests = 0;
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.method == 'GET' || !this.isExperimentChangeRequest(req.url)) return next.handle(req);
    this.applicationStateService.applicationStatus.next(ApplicationStatus.saving);
    this.totalRequests++;
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorRequests++;
        return throwError(error);
      }),
      finalize(() => {
        this.totalRequests--;
        if (this.totalRequests === 0) {
          if (this.errorRequests > 0) {
            this.applicationStateService.applicationStatus.next(ApplicationStatus.errored);
          } else {
            this.applicationStateService.applicationStatus.next(ApplicationStatus.saved);
          }
          this.errorRequests = 0;
        }
      })
    );
  }
  private isExperimentChangeRequest(url: string): boolean {
    return (
      url.indexOf('experiment-events') + url.indexOf('table-events') + url.indexOf('form-events') 
      + url.indexOf('activity-input-events') >
      0
    );
  }
}
