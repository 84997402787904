import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { ExperimentEventsService } from '../../api/data-entry/services/experiment-events.service';
import { RuleActionNotificationService } from '../action-notification/rule-action-notification.service';
import { RuleActionNotification } from '../actions/rule-action-notification';
import { RuleEvent, TemplateRuleContext } from '../events/rule-event-context';
import { Message, MessageService } from 'primeng/api';
import { ModifiableDataValue } from '../../api/models';
import { SetVariableCommand } from '../../api/data-entry/models';
import { RuleActionHandlerHostItemType, RuleActionHandlerHostService } from './rule-action-handler-host.service';
import { RuleActionSetVariableResult, SetVariableType } from '../actions/rule-action-result';

@Injectable()
export class RuleSetVariableHandlerService {
  private readonly subscriptions: Subscription[] = [];
  constructor(
    private readonly ruleActionNotificationService: RuleActionNotificationService,
    private readonly experimentEventsService: ExperimentEventsService,
    private readonly messageService: MessageService
  ) {
    this.watchSetVariableActionFromRule();
  }

  watchSetVariableActionFromRule(): void {
    this.subscriptions.push(
      this.ruleActionNotificationService.SetVariableActionNotification.subscribe({
        next: this.applySetVariableAction.bind(this)
      })
    );
  }

  private canVariableCreateOrUpdate(action: RuleActionSetVariableResult): boolean {
    if (
      action.SetVariableType === SetVariableType.New &&
      RuleActionHandlerHostService.ItemVariablesNode.variables[action.VariableName] &&
      RuleActionHandlerHostService.ItemVariablesNode.variables[action.VariableName].nodeId ===
        action.Target
    ) {
      this.showVariableDuplicateError(action.VariableName);
      return false;
    }
    return true;
  }

  applySetVariableAction(notification: RuleActionNotification<RuleEvent<TemplateRuleContext>>) {
    const action = notification.action as RuleActionSetVariableResult;
    if (!this.canVariableCreateOrUpdate(action)) {
      return;
    }
    const setVariableCommand: SetVariableCommand = this.getVariableCommandFrom(notification);
    if (RuleActionHandlerHostService.ItemType === RuleActionHandlerHostItemType.Experiment) {
      this.experimentEventsService
        .experimentEventsExperimentIdSetVariablePost$Json({
          experimentId: notification.ruleContext.itemId,
          body: setVariableCommand
        })
        .subscribe({ next: (_) => {} });
    }
    this.ruleActionNotificationService.SetVariableActionResponse.next(setVariableCommand);
    if (action.FollowUpAction) {
      notification.action = action.FollowUpAction;
      this.ruleActionNotificationService.NotifyAction(notification);
    } else {
      // Wait for internal notification about variable be processed.
      setTimeout(() => {
        notification.evaluateNextRule();
      }, 250);
    }
  }

  private getVariableCommandFrom(
    notification: RuleActionNotification<RuleEvent<TemplateRuleContext>>
  ): SetVariableCommand {
    const action = notification.action as RuleActionSetVariableResult;
    const modifiableDataValue: ModifiableDataValue = { value: action.Value, isModified: true };
    return {
      experimentId: notification.ruleContext.itemId,
      name: action.VariableName,
      value: modifiableDataValue,
      nodeId: notification.action.Target,
      ruleContext: {
        correlationId: notification.ruleContext.correlationId,
        ruleId: notification.ruleContext.ruleId
      }
    };
  }

  private showVariableDuplicateError(variableName: string): void {
    const errorMessage: Message = {
      key: 'notification',
      severity: 'error',
      summary: $localize`:@@error:Error`,
      detail: $localize`:@@variableExists:While attempting to create Variable:${variableName} from rules, it discovered that it already exists and can not be re-created.`,
      sticky: false
    };
    this.messageService.add(errorMessage);
  }
}
