import {
  CellChangedEventOfFullContext,
  RuleInputDataValue as DataValueAsRuleInput
} from '../events/rule-event-context';
import { ContextFrom, RuleEventContextBaseConverter } from './rule-base-converter';
import { RuleEventContextConverter } from './rule-event-context-converter';
import { RuleActionHandlerHostService } from '../handler/rule-action-handler-host.service';
import { ValueState } from 'bpt-ui-library/shared';
import { ChangeCellCommand } from '../../api/data-entry/models';
import { RuleEvents } from '../../api/models';
import { ChangeRecipeCellCommand } from '../../api/cookbook/models';

export interface CellChangeEventContextFrom extends ContextFrom {
  changeCellCommand: ChangeCellCommand | ChangeRecipeCellCommand;
  rows: Array<{ [key: string]: DataValueAsRuleInput }>;
}
export class CellChangedEventConverter
  extends RuleEventContextBaseConverter
  implements RuleEventContextConverter<CellChangeEventContextFrom>
{
  public convert(
    instanceId: string,
    templateId: string,
    context: CellChangeEventContextFrom
  ): CellChangedEventOfFullContext {
    const currentRow: { [key: string]: DataValueAsRuleInput } =
      context.rows.find((row) => row.id === context.changeCellCommand.rowIds[0]) || {};
    context.changeCellCommand.columnValues.forEach((cell) => {
      if (!currentRow[cell.propertyName]) {
        currentRow[cell.propertyName].value = cell.propertyValue;
      }
    });
    this.normalizeDataValueRow(currentRow);
    return {
      event: {
        context: context.changeCellCommand.columnValues[0].propertyName,
        templateId: templateId,
        templateInstanceId: instanceId,
        eventType: RuleEvents.CellChanged,
        itemId: RuleActionHandlerHostService.CurrentItemId
      },
      currentRow
    } as CellChangedEventOfFullContext;
  }

  // TODO: To Support old and new formats. Until we streamline it.
  private normalizeDataValueRow(row: { [key: string]: DataValueAsRuleInput }): {
    [key: string]: DataValueAsRuleInput;
  } {
    const normalizedRow = row as any;
    Object.keys(normalizedRow).forEach((field) => {
      if (
        normalizedRow[field].state &&
        (!normalizedRow[field].value || !normalizedRow[field].value.value) &&
        normalizedRow[field].state === ValueState.Set
      ) {
        normalizedRow[field].value = normalizedRow[field];
      }
    });
    return normalizedRow;
  }
}
