<app-preparations [disableCreatePreparation]="disableCreatePreparation"
  [isStatusColumnEditable]="isStatusColumnEditable"
  [disableRemoveAndRestorePreparation]="disableRemoveAndRestorePreparation"
  [disablePreparationActions]="disableRemoveAndRestorePreparation"
  [disableGrid]="disableGrid"
  [preparations]="preparations"
  [currentActivityId]="currentActivityId"
  [statusBar]="statusBar"
  [flagConfigurationProvider]="getFlagRendererConfig"
  [initialCellLockDetails]="initialCellLockDetails"
  [completionTrackHandler]="completionTracker"
  (internalCommentsRequest)="showInternalComment($event)"
  (clientFacingNoteRequest)="showClientFacingNotes($event)"
  (cellLockStatus)="sendInputStatus($event)"
  >
</app-preparations>
<bpt-progress-spinner
  i18n-message="@@loadingHistory"
  message="History Loading..."
  [useOverlay]="true"
  *ngIf=preparationEventService.isHistoryLoading>
</bpt-progress-spinner>