/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { activityInputsAuditHistoryExperimentIdPost$Json } from '../fn/activity-input-audit-history/activity-inputs-audit-history-experiment-id-post-json';
import { ActivityInputsAuditHistoryExperimentIdPost$Json$Params } from '../fn/activity-input-audit-history/activity-inputs-audit-history-experiment-id-post-json';
import { activityInputsAuditHistoryExperimentIdPost$Plain } from '../fn/activity-input-audit-history/activity-inputs-audit-history-experiment-id-post-plain';
import { ActivityInputsAuditHistoryExperimentIdPost$Plain$Params } from '../fn/activity-input-audit-history/activity-inputs-audit-history-experiment-id-post-plain';
import { AuditHistoryDataRecordResponse } from '../models/audit-history-data-record-response';

@Injectable({ providedIn: 'root' })
export class ActivityInputAuditHistoryService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `activityInputsAuditHistoryExperimentIdPost()` */
  static readonly ActivityInputsAuditHistoryExperimentIdPostPath = '/activity-inputs-audit-history/{experimentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityInputsAuditHistoryExperimentIdPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputsAuditHistoryExperimentIdPost$Plain$Response(params: ActivityInputsAuditHistoryExperimentIdPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<AuditHistoryDataRecordResponse>> {
    return activityInputsAuditHistoryExperimentIdPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityInputsAuditHistoryExperimentIdPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputsAuditHistoryExperimentIdPost$Plain(params: ActivityInputsAuditHistoryExperimentIdPost$Plain$Params, context?: HttpContext): Observable<AuditHistoryDataRecordResponse> {
    return this.activityInputsAuditHistoryExperimentIdPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<AuditHistoryDataRecordResponse>): AuditHistoryDataRecordResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityInputsAuditHistoryExperimentIdPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputsAuditHistoryExperimentIdPost$Json$Response(params: ActivityInputsAuditHistoryExperimentIdPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<AuditHistoryDataRecordResponse>> {
    return activityInputsAuditHistoryExperimentIdPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityInputsAuditHistoryExperimentIdPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputsAuditHistoryExperimentIdPost$Json(params: ActivityInputsAuditHistoryExperimentIdPost$Json$Params, context?: HttpContext): Observable<AuditHistoryDataRecordResponse> {
    return this.activityInputsAuditHistoryExperimentIdPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<AuditHistoryDataRecordResponse>): AuditHistoryDataRecordResponse => r.body)
    );
  }

}
