<bpt-dropdown
  [editable]="false"
  [placeholder]="placeholder"
  [options]="userList"
  [label]="label"
  [showClear]="showClear"
  [showFilter]="true"
  [multiSelect]="multiSelect"
  [valueField]="valueField"
  [required]="required"
  [errorMessage]="errorMessage"
  [errorFlag]="errorFlag"
  [labelField]="labelField"
  [showToggleAll]="false"
  [(ngModel)]="value"
  (ngModelChange)="modelChanged($event)"
  [ngModelOptions]="ngModelOptions"
  disabledField="inactive"
  [wrap]="wrap"
  [readOnly]="readOnly"
  [disabled]="disabled"
  [required]="required"
></bpt-dropdown>