/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { promptsCreateColumnPost$Json } from '../fn/prompt/prompts-create-column-post-json';
import { PromptsCreateColumnPost$Json$Params } from '../fn/prompt/prompts-create-column-post-json';
import { promptsCreateColumnPost$Plain } from '../fn/prompt/prompts-create-column-post-plain';
import { PromptsCreateColumnPost$Plain$Params } from '../fn/prompt/prompts-create-column-post-plain';
import { promptsCreateConsumableSupplyPost$Json } from '../fn/prompt/prompts-create-consumable-supply-post-json';
import { PromptsCreateConsumableSupplyPost$Json$Params } from '../fn/prompt/prompts-create-consumable-supply-post-json';
import { promptsCreateConsumableSupplyPost$Plain } from '../fn/prompt/prompts-create-consumable-supply-post-plain';
import { PromptsCreateConsumableSupplyPost$Plain$Params } from '../fn/prompt/prompts-create-consumable-supply-post-plain';
import { promptsCreateInstrumentPost$Json } from '../fn/prompt/prompts-create-instrument-post-json';
import { PromptsCreateInstrumentPost$Json$Params } from '../fn/prompt/prompts-create-instrument-post-json';
import { promptsCreateInstrumentPost$Plain } from '../fn/prompt/prompts-create-instrument-post-plain';
import { PromptsCreateInstrumentPost$Plain$Params } from '../fn/prompt/prompts-create-instrument-post-plain';
import { promptsCreateMaterialPost$Json } from '../fn/prompt/prompts-create-material-post-json';
import { PromptsCreateMaterialPost$Json$Params } from '../fn/prompt/prompts-create-material-post-json';
import { promptsCreateMaterialPost$Plain } from '../fn/prompt/prompts-create-material-post-plain';
import { PromptsCreateMaterialPost$Plain$Params } from '../fn/prompt/prompts-create-material-post-plain';
import { promptsCreatePreparationPost$Json } from '../fn/prompt/prompts-create-preparation-post-json';
import { PromptsCreatePreparationPost$Json$Params } from '../fn/prompt/prompts-create-preparation-post-json';
import { promptsCreatePreparationPost$Plain } from '../fn/prompt/prompts-create-preparation-post-plain';
import { PromptsCreatePreparationPost$Plain$Params } from '../fn/prompt/prompts-create-preparation-post-plain';
import { promptsEditRecipePromptPost$Json } from '../fn/prompt/prompts-edit-recipe-prompt-post-json';
import { PromptsEditRecipePromptPost$Json$Params } from '../fn/prompt/prompts-edit-recipe-prompt-post-json';
import { promptsEditRecipePromptPost$Plain } from '../fn/prompt/prompts-edit-recipe-prompt-post-plain';
import { PromptsEditRecipePromptPost$Plain$Params } from '../fn/prompt/prompts-edit-recipe-prompt-post-plain';
import { promptsRemoveRecipePromptPost$Json } from '../fn/prompt/prompts-remove-recipe-prompt-post-json';
import { PromptsRemoveRecipePromptPost$Json$Params } from '../fn/prompt/prompts-remove-recipe-prompt-post-json';
import { promptsRemoveRecipePromptPost$Plain } from '../fn/prompt/prompts-remove-recipe-prompt-post-plain';
import { PromptsRemoveRecipePromptPost$Plain$Params } from '../fn/prompt/prompts-remove-recipe-prompt-post-plain';
import { promptsRestoreRecipePromptPost$Json } from '../fn/prompt/prompts-restore-recipe-prompt-post-json';
import { PromptsRestoreRecipePromptPost$Json$Params } from '../fn/prompt/prompts-restore-recipe-prompt-post-json';
import { promptsRestoreRecipePromptPost$Plain } from '../fn/prompt/prompts-restore-recipe-prompt-post-plain';
import { PromptsRestoreRecipePromptPost$Plain$Params } from '../fn/prompt/prompts-restore-recipe-prompt-post-plain';
import { RecipeColumnPromptAddedResponse } from '../models/recipe-column-prompt-added-response';
import { RecipeConsumableSupplyPromptAddedResponse } from '../models/recipe-consumable-supply-prompt-added-response';
import { RecipeInstrumentPromptAddedResponse } from '../models/recipe-instrument-prompt-added-response';
import { RecipeMaterialPromptAddedResponse } from '../models/recipe-material-prompt-added-response';
import { RecipePreparationPromptAddedResponse } from '../models/recipe-preparation-prompt-added-response';
import { RecipePromptEditResponse } from '../models/recipe-prompt-edit-response';
import { RecipePromptRemovedResponse } from '../models/recipe-prompt-removed-response';
import { RecipePromptRestoredResponse } from '../models/recipe-prompt-restored-response';

@Injectable({ providedIn: 'root' })
export class PromptService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `promptsCreateMaterialPost()` */
  static readonly PromptsCreateMaterialPostPath = '/prompts/create-material';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `promptsCreateMaterialPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptsCreateMaterialPost$Plain$Response(params?: PromptsCreateMaterialPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<RecipeMaterialPromptAddedResponse>> {
    return promptsCreateMaterialPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `promptsCreateMaterialPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptsCreateMaterialPost$Plain(params?: PromptsCreateMaterialPost$Plain$Params, context?: HttpContext): Observable<RecipeMaterialPromptAddedResponse> {
    return this.promptsCreateMaterialPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<RecipeMaterialPromptAddedResponse>): RecipeMaterialPromptAddedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `promptsCreateMaterialPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptsCreateMaterialPost$Json$Response(params?: PromptsCreateMaterialPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<RecipeMaterialPromptAddedResponse>> {
    return promptsCreateMaterialPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `promptsCreateMaterialPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptsCreateMaterialPost$Json(params?: PromptsCreateMaterialPost$Json$Params, context?: HttpContext): Observable<RecipeMaterialPromptAddedResponse> {
    return this.promptsCreateMaterialPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<RecipeMaterialPromptAddedResponse>): RecipeMaterialPromptAddedResponse => r.body)
    );
  }

  /** Path part for operation `promptsCreateColumnPost()` */
  static readonly PromptsCreateColumnPostPath = '/prompts/create-column';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `promptsCreateColumnPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptsCreateColumnPost$Plain$Response(params?: PromptsCreateColumnPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<RecipeColumnPromptAddedResponse>> {
    return promptsCreateColumnPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `promptsCreateColumnPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptsCreateColumnPost$Plain(params?: PromptsCreateColumnPost$Plain$Params, context?: HttpContext): Observable<RecipeColumnPromptAddedResponse> {
    return this.promptsCreateColumnPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<RecipeColumnPromptAddedResponse>): RecipeColumnPromptAddedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `promptsCreateColumnPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptsCreateColumnPost$Json$Response(params?: PromptsCreateColumnPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<RecipeColumnPromptAddedResponse>> {
    return promptsCreateColumnPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `promptsCreateColumnPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptsCreateColumnPost$Json(params?: PromptsCreateColumnPost$Json$Params, context?: HttpContext): Observable<RecipeColumnPromptAddedResponse> {
    return this.promptsCreateColumnPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<RecipeColumnPromptAddedResponse>): RecipeColumnPromptAddedResponse => r.body)
    );
  }

  /** Path part for operation `promptsCreatePreparationPost()` */
  static readonly PromptsCreatePreparationPostPath = '/prompts/create-preparation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `promptsCreatePreparationPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptsCreatePreparationPost$Plain$Response(params?: PromptsCreatePreparationPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<RecipePreparationPromptAddedResponse>> {
    return promptsCreatePreparationPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `promptsCreatePreparationPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptsCreatePreparationPost$Plain(params?: PromptsCreatePreparationPost$Plain$Params, context?: HttpContext): Observable<RecipePreparationPromptAddedResponse> {
    return this.promptsCreatePreparationPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<RecipePreparationPromptAddedResponse>): RecipePreparationPromptAddedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `promptsCreatePreparationPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptsCreatePreparationPost$Json$Response(params?: PromptsCreatePreparationPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<RecipePreparationPromptAddedResponse>> {
    return promptsCreatePreparationPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `promptsCreatePreparationPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptsCreatePreparationPost$Json(params?: PromptsCreatePreparationPost$Json$Params, context?: HttpContext): Observable<RecipePreparationPromptAddedResponse> {
    return this.promptsCreatePreparationPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<RecipePreparationPromptAddedResponse>): RecipePreparationPromptAddedResponse => r.body)
    );
  }

  /** Path part for operation `promptsCreateInstrumentPost()` */
  static readonly PromptsCreateInstrumentPostPath = '/prompts/create-instrument';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `promptsCreateInstrumentPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptsCreateInstrumentPost$Plain$Response(params?: PromptsCreateInstrumentPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<RecipeInstrumentPromptAddedResponse>> {
    return promptsCreateInstrumentPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `promptsCreateInstrumentPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptsCreateInstrumentPost$Plain(params?: PromptsCreateInstrumentPost$Plain$Params, context?: HttpContext): Observable<RecipeInstrumentPromptAddedResponse> {
    return this.promptsCreateInstrumentPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<RecipeInstrumentPromptAddedResponse>): RecipeInstrumentPromptAddedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `promptsCreateInstrumentPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptsCreateInstrumentPost$Json$Response(params?: PromptsCreateInstrumentPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<RecipeInstrumentPromptAddedResponse>> {
    return promptsCreateInstrumentPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `promptsCreateInstrumentPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptsCreateInstrumentPost$Json(params?: PromptsCreateInstrumentPost$Json$Params, context?: HttpContext): Observable<RecipeInstrumentPromptAddedResponse> {
    return this.promptsCreateInstrumentPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<RecipeInstrumentPromptAddedResponse>): RecipeInstrumentPromptAddedResponse => r.body)
    );
  }

  /** Path part for operation `promptsCreateConsumableSupplyPost()` */
  static readonly PromptsCreateConsumableSupplyPostPath = '/prompts/create-consumable-supply';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `promptsCreateConsumableSupplyPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptsCreateConsumableSupplyPost$Plain$Response(params?: PromptsCreateConsumableSupplyPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<RecipeConsumableSupplyPromptAddedResponse>> {
    return promptsCreateConsumableSupplyPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `promptsCreateConsumableSupplyPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptsCreateConsumableSupplyPost$Plain(params?: PromptsCreateConsumableSupplyPost$Plain$Params, context?: HttpContext): Observable<RecipeConsumableSupplyPromptAddedResponse> {
    return this.promptsCreateConsumableSupplyPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<RecipeConsumableSupplyPromptAddedResponse>): RecipeConsumableSupplyPromptAddedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `promptsCreateConsumableSupplyPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptsCreateConsumableSupplyPost$Json$Response(params?: PromptsCreateConsumableSupplyPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<RecipeConsumableSupplyPromptAddedResponse>> {
    return promptsCreateConsumableSupplyPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `promptsCreateConsumableSupplyPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptsCreateConsumableSupplyPost$Json(params?: PromptsCreateConsumableSupplyPost$Json$Params, context?: HttpContext): Observable<RecipeConsumableSupplyPromptAddedResponse> {
    return this.promptsCreateConsumableSupplyPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<RecipeConsumableSupplyPromptAddedResponse>): RecipeConsumableSupplyPromptAddedResponse => r.body)
    );
  }

  /** Path part for operation `promptsEditRecipePromptPost()` */
  static readonly PromptsEditRecipePromptPostPath = '/prompts/edit-recipe-prompt';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `promptsEditRecipePromptPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptsEditRecipePromptPost$Plain$Response(params?: PromptsEditRecipePromptPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<RecipePromptEditResponse>> {
    return promptsEditRecipePromptPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `promptsEditRecipePromptPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptsEditRecipePromptPost$Plain(params?: PromptsEditRecipePromptPost$Plain$Params, context?: HttpContext): Observable<RecipePromptEditResponse> {
    return this.promptsEditRecipePromptPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<RecipePromptEditResponse>): RecipePromptEditResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `promptsEditRecipePromptPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptsEditRecipePromptPost$Json$Response(params?: PromptsEditRecipePromptPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<RecipePromptEditResponse>> {
    return promptsEditRecipePromptPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `promptsEditRecipePromptPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptsEditRecipePromptPost$Json(params?: PromptsEditRecipePromptPost$Json$Params, context?: HttpContext): Observable<RecipePromptEditResponse> {
    return this.promptsEditRecipePromptPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<RecipePromptEditResponse>): RecipePromptEditResponse => r.body)
    );
  }

  /** Path part for operation `promptsRemoveRecipePromptPost()` */
  static readonly PromptsRemoveRecipePromptPostPath = '/prompts/remove-recipe-prompt';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `promptsRemoveRecipePromptPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptsRemoveRecipePromptPost$Plain$Response(params?: PromptsRemoveRecipePromptPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<RecipePromptRemovedResponse>> {
    return promptsRemoveRecipePromptPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `promptsRemoveRecipePromptPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptsRemoveRecipePromptPost$Plain(params?: PromptsRemoveRecipePromptPost$Plain$Params, context?: HttpContext): Observable<RecipePromptRemovedResponse> {
    return this.promptsRemoveRecipePromptPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<RecipePromptRemovedResponse>): RecipePromptRemovedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `promptsRemoveRecipePromptPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptsRemoveRecipePromptPost$Json$Response(params?: PromptsRemoveRecipePromptPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<RecipePromptRemovedResponse>> {
    return promptsRemoveRecipePromptPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `promptsRemoveRecipePromptPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptsRemoveRecipePromptPost$Json(params?: PromptsRemoveRecipePromptPost$Json$Params, context?: HttpContext): Observable<RecipePromptRemovedResponse> {
    return this.promptsRemoveRecipePromptPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<RecipePromptRemovedResponse>): RecipePromptRemovedResponse => r.body)
    );
  }

  /** Path part for operation `promptsRestoreRecipePromptPost()` */
  static readonly PromptsRestoreRecipePromptPostPath = '/prompts/restore-recipe-prompt';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `promptsRestoreRecipePromptPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptsRestoreRecipePromptPost$Plain$Response(params?: PromptsRestoreRecipePromptPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<RecipePromptRestoredResponse>> {
    return promptsRestoreRecipePromptPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `promptsRestoreRecipePromptPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptsRestoreRecipePromptPost$Plain(params?: PromptsRestoreRecipePromptPost$Plain$Params, context?: HttpContext): Observable<RecipePromptRestoredResponse> {
    return this.promptsRestoreRecipePromptPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<RecipePromptRestoredResponse>): RecipePromptRestoredResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `promptsRestoreRecipePromptPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptsRestoreRecipePromptPost$Json$Response(params?: PromptsRestoreRecipePromptPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<RecipePromptRestoredResponse>> {
    return promptsRestoreRecipePromptPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `promptsRestoreRecipePromptPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptsRestoreRecipePromptPost$Json(params?: PromptsRestoreRecipePromptPost$Json$Params, context?: HttpContext): Observable<RecipePromptRestoredResponse> {
    return this.promptsRestoreRecipePromptPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<RecipePromptRestoredResponse>): RecipePromptRestoredResponse => r.body)
    );
  }

}
