<bpt-grid
  #filesGrid
  class="eln-grid"
  ngClass="true"
  gridId="{{gridId}}"
  [includeRowNumberColumn]="false"
  [dataSource]="fileData"
  [useJsJodaTypes]="true"
  [columnDefinitions]="columnDefinitions"
  [gridMode]="gridMode"
  [allowRowAdd]="false"
  [showAutoSizeButton]="false"
  [showFilterToggleButton]="false"
  [showGridOptionsButton]="false"
  [paginated]="false"
  [suppressAggFuncInHeader]="true">
</bpt-grid>