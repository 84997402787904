/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AggregationResult } from '../models/aggregation-result';
import { bookshelfSearchAggregateExperimentIndexPost$Json } from '../fn/bookshelf/bookshelf-search-aggregate-experiment-index-post-json';
import { BookshelfSearchAggregateExperimentIndexPost$Json$Params } from '../fn/bookshelf/bookshelf-search-aggregate-experiment-index-post-json';
import { bookshelfSearchAggregateExperimentIndexPost$Plain } from '../fn/bookshelf/bookshelf-search-aggregate-experiment-index-post-plain';
import { BookshelfSearchAggregateExperimentIndexPost$Plain$Params } from '../fn/bookshelf/bookshelf-search-aggregate-experiment-index-post-plain';
import { bookshelfSearchExperimentIndexPost$Json } from '../fn/bookshelf/bookshelf-search-experiment-index-post-json';
import { BookshelfSearchExperimentIndexPost$Json$Params } from '../fn/bookshelf/bookshelf-search-experiment-index-post-json';
import { bookshelfSearchExperimentIndexPost$Plain } from '../fn/bookshelf/bookshelf-search-experiment-index-post-plain';
import { BookshelfSearchExperimentIndexPost$Plain$Params } from '../fn/bookshelf/bookshelf-search-experiment-index-post-plain';
import { ExperimentRecordSearchResult } from '../models/experiment-record-search-result';

@Injectable({ providedIn: 'root' })
export class BookshelfService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `bookshelfSearchExperimentIndexPost()` */
  static readonly BookshelfSearchExperimentIndexPostPath = '/bookshelf/search-experiment-index';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bookshelfSearchExperimentIndexPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  bookshelfSearchExperimentIndexPost$Plain$Response(params?: BookshelfSearchExperimentIndexPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ExperimentRecordSearchResult>> {
    return bookshelfSearchExperimentIndexPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `bookshelfSearchExperimentIndexPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  bookshelfSearchExperimentIndexPost$Plain(params?: BookshelfSearchExperimentIndexPost$Plain$Params, context?: HttpContext): Observable<ExperimentRecordSearchResult> {
    return this.bookshelfSearchExperimentIndexPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExperimentRecordSearchResult>): ExperimentRecordSearchResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bookshelfSearchExperimentIndexPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  bookshelfSearchExperimentIndexPost$Json$Response(params?: BookshelfSearchExperimentIndexPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ExperimentRecordSearchResult>> {
    return bookshelfSearchExperimentIndexPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `bookshelfSearchExperimentIndexPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  bookshelfSearchExperimentIndexPost$Json(params?: BookshelfSearchExperimentIndexPost$Json$Params, context?: HttpContext): Observable<ExperimentRecordSearchResult> {
    return this.bookshelfSearchExperimentIndexPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExperimentRecordSearchResult>): ExperimentRecordSearchResult => r.body)
    );
  }

  /** Path part for operation `bookshelfSearchAggregateExperimentIndexPost()` */
  static readonly BookshelfSearchAggregateExperimentIndexPostPath = '/bookshelf/search-aggregate-experiment-index';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bookshelfSearchAggregateExperimentIndexPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  bookshelfSearchAggregateExperimentIndexPost$Plain$Response(params?: BookshelfSearchAggregateExperimentIndexPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<AggregationResult>> {
    return bookshelfSearchAggregateExperimentIndexPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `bookshelfSearchAggregateExperimentIndexPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  bookshelfSearchAggregateExperimentIndexPost$Plain(params?: BookshelfSearchAggregateExperimentIndexPost$Plain$Params, context?: HttpContext): Observable<AggregationResult> {
    return this.bookshelfSearchAggregateExperimentIndexPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<AggregationResult>): AggregationResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bookshelfSearchAggregateExperimentIndexPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  bookshelfSearchAggregateExperimentIndexPost$Json$Response(params?: BookshelfSearchAggregateExperimentIndexPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<AggregationResult>> {
    return bookshelfSearchAggregateExperimentIndexPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `bookshelfSearchAggregateExperimentIndexPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  bookshelfSearchAggregateExperimentIndexPost$Json(params?: BookshelfSearchAggregateExperimentIndexPost$Json$Params, context?: HttpContext): Observable<AggregationResult> {
    return this.bookshelfSearchAggregateExperimentIndexPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<AggregationResult>): AggregationResult => r.body)
    );
  }

}
