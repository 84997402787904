import { Injectable } from '@angular/core';
import { ELimsBptLinkoutService } from './elims-bpt-linkout.service';
import { LimsLinkout } from './lims-linkout.interface';

@Injectable({
  providedIn: 'root'
})

/** Provides linkouts to navigate to LIMS components */
export class LimsLinkoutService implements LimsLinkout {
  limsLinkout: LimsLinkout;

  constructor() {
    // Currently assumping only eLIMS-BPT as the external service.
    // When a new LIMS is to be introduced, add corresponding linkout provider
    this.limsLinkout = new ELimsBptLinkoutService();
  }
  
  /** Gets the url to navigate to a LIMS Instrument Column page  */
  getInstrumentColumnRoute(columnCode: string) :string {
    return this.limsLinkout.getInstrumentColumnRoute(columnCode);
  }

  /** Gets the url to navigate to a LIMS sample  */
  getSampleRoute(sampleNumber: string): string {
    return this.limsLinkout.getSampleRoute(sampleNumber);
  }

  /** Gets the url to navigate to a LIMS Study activity  */
  getStudyActivityRoute(studyActivityCode: string): string {
    return this.limsLinkout.getStudyActivityRoute(studyActivityCode);
  }

  /** Gets the url to navigate to a LIMS Material page  */
  getMaterialRoute(materialCode: string) {
    return this.limsLinkout.getMaterialRoute(materialCode);
  }
}
