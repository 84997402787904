import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
  items: MenuItem[] = [];
  private eventSubscription?: Subscription;

  constructor(private readonly router: Router, private readonly activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    // Get the breadcrumb on first load
    this.items = this.createBreadcrumbs(this.activatedRoute.root);
    // All subsequent navigation will use this
    this.eventSubscription = this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe(() => (this.items = this.createBreadcrumbs(this.activatedRoute.root)));
  }

  private createBreadcrumbs(
    route: ActivatedRoute,
    url = '#',
    breadcrumbs: MenuItem[] = []
  ): MenuItem[] {
    const children: ActivatedRoute[] = route?.children ?? [];
    if (children.length !== 0) {
      for (const child of children) {
        const routeURL: string = child.snapshot.url.map((segment) => segment.path).join('/');
        if (routeURL !== '') {
          url += `/${routeURL}`;
        }

        const title = child.snapshot.data['title'];
        if (title) {
          breadcrumbs.push({ label: title, url });
        }

        return this.createBreadcrumbs(child, url, breadcrumbs);
      }
    }
    return breadcrumbs;
  }

  ngOnDestroy(): void {
    this.eventSubscription?.unsubscribe();
  }
}
