/* tslint:disable */
/* eslint-disable */
export enum CommentContextType {
  Experiment = 'experiment',
  Activity = 'activity',
  ActivityInput = 'activityInput',
  LabItems = 'labItems',
  Consumables = 'consumables',
  ActivityReferences = 'activityReferences',
  ActivityCrossReferences = 'activityCrossReferences',
  ActivityCrossReference = 'activityCrossReference',
  Module = 'module',
  Form = 'form',
  FormField = 'formField',
  Table = 'table',
  TableCell = 'tableCell',
  Preparations = 'preparations',
  StudyActivity = 'studyActivity',
  SampleAliquot = 'sampleAliquot',
  Compendia = 'compendia',
  Documents = 'documents',
  CrossReferences = 'crossReferences',
  OutputsInstrumentEvent = 'outputsInstrumentEvent'
}
