import { Injectable } from '@angular/core';
import { NA, Quantity, ValueState } from 'bpt-ui-library/shared';
import { UnitLoaderService } from 'services/unit-loader.service';
import {
  ObservationSpec,
  RangeType,
  SingleValueRangeSpec,
  SingleValueSpec,
  SpecComparisonOperator,
  SpecDisplayType,
  SpecificationValue,
  SpecType,
  TwoValueRangeSpec,
  ValueType,
} from '../../api/data-entry/models';
import { NumberValue, Unit } from '../../api/models';

export type SpecTypeDropdownOption = {
  label: string,
  value: SpecType
};

export enum OperatorBoundaryType {
  Invalid,
  Lower,
  Upper
};

export enum OperatorExclusivity {
  Invalid,
  Inclusive,
  Exclusive
};

export const typesThatRequireUnits = [SpecType.SingleValue, SpecType.SingleValueRange, SpecType.TwoValueRange];

export const validDisplayTypes = {
  [SpecType.SingleValue]: [SpecDisplayType.Expression, SpecDisplayType.Abbreviation],
  [SpecType.SingleValueRange]: [SpecDisplayType.ToleranceRange, SpecDisplayType.Range, SpecDisplayType.ToleranceRangeAndRange],
  [SpecType.TwoValueRange]: [SpecDisplayType.Expression, SpecDisplayType.Abbreviation, SpecDisplayType.Inequality]
};

export const rangeTypes = [
  { label: '±', value: RangeType.PlusOrMinus },
  { label: '+', value: RangeType.Plus },
  { label: '-', value: RangeType.Minus }
];

export class SpecComparisonOperatorDropdownOption {
  public get label(): string {
    return `${this.inequalityLabel} (${this.abbreviationLabel})`;
  }

  constructor(public inequalityLabel: string,
    public abbreviationLabel: string,
    public value: SpecComparisonOperator,
    public boundaryType?: OperatorBoundaryType,
    public exclusivity?: OperatorExclusivity) { }
};

@Injectable({
  providedIn: 'root',
})
export class SpecificationService {
  private static readonly Assembly: string = 'ELN.Blazor.Entry' as const;
  public static readonly CacheUnits: string = 'CacheUnits' as const;
  private static readonly ValidateSpecificationValues: string = 'ValidateSpecificationValues' as const;
  private static readonly CalculateSingleValueRangeSpecRange: string = 'CalculateSingleValueRangeSpecRange' as const;

  get naUnit(): Unit {
    return this.unitLoaderService.naUnit;
  }

  /** Percent (%) */
  get pctUnit(): Unit {
    return this.unitLoaderService.pctUnit;
  }

  constructor(private readonly unitLoaderService: UnitLoaderService) {
  }

  /** valid spec types as label/value pairs to appear in dropdowns */
  public static get validTypesForDropdown(): SpecTypeDropdownOption[] {
    return [
      { label: $localize`:@@observation:Observation`, value: SpecType.Observation },
      { label: $localize`:@@singleValue:Single Value`, value: SpecType.SingleValue },
      { label: $localize`:@@singleValueRange:Single Value Range`, value: SpecType.SingleValueRange },
      { label: $localize`:@@twoValueRange:Two Value Range`, value: SpecType.TwoValueRange }
    ];
  }

  /** valid spec types */
  public static get validTypes(): SpecType[] {
    return this.validTypesForDropdown.map(t => t.value);
  }

  public static get validComparisonOperatorsForDropdown(): SpecComparisonOperatorDropdownOption[] {
    return [
      new SpecComparisonOperatorDropdownOption('=', $localize`:@@equalToAbbreviation:Equal To`, SpecComparisonOperator.EqualTo),
      new SpecComparisonOperatorDropdownOption('≠', $localize`:@@notEqualToAbbreviation:Not Equal To`, SpecComparisonOperator.NotEqualTo),
      new SpecComparisonOperatorDropdownOption(
        '>',
        $localize`:@@moreThanAbbreviation:MT`,
        SpecComparisonOperator.MoreThan,
        OperatorBoundaryType.Lower,
        OperatorExclusivity.Exclusive
      ),
      new SpecComparisonOperatorDropdownOption(
        '<',
        $localize`:@@lessThanAbbreviation:LT`,
        SpecComparisonOperator.LessThan,
        OperatorBoundaryType.Upper,
        OperatorExclusivity.Exclusive
      ),
      new SpecComparisonOperatorDropdownOption(
        '≥',
        $localize`:@@notLessThanAbbreviation:NLT`,
        SpecComparisonOperator.NotLessThan,
        OperatorBoundaryType.Lower,
        OperatorExclusivity.Inclusive
      ),
      new SpecComparisonOperatorDropdownOption(
        '≤',
        $localize`:@@notMoreThanAbbreviation:NMT`,
        SpecComparisonOperator.NotMoreThan,
        OperatorBoundaryType.Upper,
        OperatorExclusivity.Inclusive
      )
    ];
  }

  public getDisplayString(specification: SpecificationValue | undefined): string {
    if (!specification) return '';
    if (specification.state === ValueState.Empty) return '';
    if (specification.state === ValueState.NotApplicable) return NA;

    switch (specification.specType) {
      case SpecType.Observation:
        return this.getObservationDisplayString(specification);
      case SpecType.SingleValue:
        return this.getSingleValueDisplayString(specification);
      case SpecType.SingleValueRange:
        return this.getSingleValueRangeDisplayString(specification);
      case SpecType.TwoValueRange:
        return this.getTwoValueRangeDisplayString(specification);
      default:
        return '';
    }
  }

  public getObservationDisplayString(observation: Partial<ObservationSpec> | undefined): string {
    return observation?.value ?? '';
  }

  public getSingleValueDisplayString(singleValueSpec: Partial<SingleValueSpec> | undefined): string {
    if (
      !singleValueSpec?.displayType ||
      !singleValueSpec.sourceToValueOperator ||
      !singleValueSpec.value?.state ||
      singleValueSpec.value?.state === ValueState.Empty
    ) return '';

    const displayType = singleValueSpec.displayType || SpecDisplayType.Expression;

    const operatorOption = SpecificationService.validComparisonOperatorsForDropdown.find(o => o.value === singleValueSpec.sourceToValueOperator);
    const operator = displayType === SpecDisplayType.Abbreviation ? operatorOption?.abbreviationLabel : operatorOption?.inequalityLabel;

    return `${operator} ${this.convertNumberValueToQuantity(singleValueSpec.value)?.toString()}`;
  }

  public getSingleValueRangeDisplayString(singleValueRangeSpec: Partial<SingleValueRangeSpec> | undefined): string {
    if (
      !singleValueRangeSpec?.value?.state ||
      !singleValueRangeSpec.tolerance?.state ||
      singleValueRangeSpec.value?.state === ValueState.Empty ||
      singleValueRangeSpec.tolerance?.state === ValueState.Empty ||
      !singleValueRangeSpec?.rangeType
    ) return '';

    const displayType = singleValueRangeSpec.displayType ?? SpecDisplayType.ToleranceRange;
    const rangeIsPercent = singleValueRangeSpec.tolerance?.unit === this.pctUnit.id;

    let toleranceRange = '';
    let range = '';
    if (displayType === SpecDisplayType.ToleranceRange || displayType === SpecDisplayType.ToleranceRangeAndRange) {
      const comparator = rangeTypes.find(r => singleValueRangeSpec.rangeType === r.value)?.label;
      const value = rangeIsPercent ? this.convertNumberValueToQuantity(singleValueRangeSpec.value) : singleValueRangeSpec.value.value;
      const valueUnit = singleValueRangeSpec.value.unit ? this.unitLoaderService.getUnit(singleValueRangeSpec.value.unit)?.abbreviation : ''
      const tolerance = rangeIsPercent ? this.convertNumberValueToQuantity(singleValueRangeSpec.tolerance) : `${singleValueRangeSpec.tolerance.value} ${valueUnit}`.trim();
      toleranceRange = `${value} ${comparator} ${tolerance}`
    }
    if (displayType === SpecDisplayType.Range || displayType === SpecDisplayType.ToleranceRangeAndRange) {
      const limits = this.getSingleValueRangeLimits(singleValueRangeSpec);
      const twoValueRange: TwoValueRangeSpec = {
        type: ValueType.Specification,
        state: ValueState.Set,
        sourceToLowerValueOperator: SpecComparisonOperator.NotLessThan,
        sourceToUpperValueOperator: SpecComparisonOperator.NotMoreThan,
        lowerValue: {
          type: ValueType.Number,
          state: ValueState.Set,
          value: limits.lower,
          exact: true,
          unit: singleValueRangeSpec.value.unit
        },
        upperValue: {
          type: ValueType.Number,
          state: ValueState.Set,
          value: limits.upper,
          exact: true,
          unit: singleValueRangeSpec.value.unit
        },
        displayType: SpecDisplayType.Expression
      };
      range = this.getTwoValueRangeDisplayString(twoValueRange);
    }

    switch (displayType) {
      case SpecDisplayType.ToleranceRange:
        return toleranceRange;
      case SpecDisplayType.Range:
        return range;
      case SpecDisplayType.ToleranceRangeAndRange:
        return `${toleranceRange} (${range})`;
      default:
        return '';
    }
  }

  public getTwoValueRangeDisplayString(twoValueRangeSpec: Partial<TwoValueRangeSpec> | undefined): string {
    if (
      !twoValueRangeSpec?.sourceToUpperValueOperator ||
      !twoValueRangeSpec.sourceToLowerValueOperator ||
      !twoValueRangeSpec.upperValue?.state ||
      !twoValueRangeSpec.lowerValue?.state ||
      twoValueRangeSpec.upperValue?.state === ValueState.Empty ||
      twoValueRangeSpec.lowerValue?.state === ValueState.Empty
    ) return '';

    const displayType = twoValueRangeSpec.displayType ?? SpecDisplayType.Expression;

    const upperOperatorOption = SpecificationService.validComparisonOperatorsForDropdown.find(o => o.value === twoValueRangeSpec.sourceToUpperValueOperator);
    const upperOperator = twoValueRangeSpec.displayType === SpecDisplayType.Abbreviation ? upperOperatorOption?.abbreviationLabel : upperOperatorOption?.inequalityLabel;

    const lowerOperatorSpecifiedOption = SpecificationService.validComparisonOperatorsForDropdown.find(o => o.value === twoValueRangeSpec.sourceToLowerValueOperator);

    // inequality types put the operator behind the lower value instead of ahead, so we need to reverse the operator so it makes sense.
    const lowerOperatorOption = displayType === SpecDisplayType.Inequality
      ? SpecificationService.validComparisonOperatorsForDropdown.find(o =>
        o.exclusivity === lowerOperatorSpecifiedOption?.exclusivity && o.boundaryType !== lowerOperatorSpecifiedOption?.boundaryType
      ) : lowerOperatorSpecifiedOption;
    const lowerOperator = displayType === SpecDisplayType.Abbreviation ? lowerOperatorOption?.abbreviationLabel : lowerOperatorOption?.inequalityLabel;

    const unitsAreEqual = twoValueRangeSpec.lowerValue?.unit === twoValueRangeSpec.upperValue?.unit;

    // if the units are equal, only display them after the upper value.
    const lowerValue = unitsAreEqual ? `${twoValueRangeSpec.lowerValue?.value}` : this.convertNumberValueToQuantity(twoValueRangeSpec.lowerValue)?.toString();
    const upperValue = this.convertNumberValueToQuantity(twoValueRangeSpec.upperValue)?.toString();

    switch (displayType) {
      case SpecDisplayType.Expression:
      case SpecDisplayType.Abbreviation:
        return `${lowerOperator} ${lowerValue} and ${upperOperator} ${upperValue}`;
      case SpecDisplayType.Inequality:
        return `${lowerValue} ${lowerOperator} x ${upperOperator} ${upperValue}`;
      default:
        return '';
    }
  }

  public static validateTwoValueRangeSpecValues(spec: Partial<TwoValueRangeSpec>): { IsValid: boolean, RuleException: string } {
    const validationResult: any = DotNet.invokeMethod(
      SpecificationService.Assembly,
      SpecificationService.ValidateSpecificationValues,
      JSON.stringify(spec.lowerValue),
      JSON.stringify(spec.upperValue)
    );

    return JSON.parse(validationResult.result);
  }

  /** This didn't used to be async, but we made it async. We hope we didn't cause any problems */
  public async cacheUnitsInBlazor(): Promise<void> {
    try {
      await DotNet.invokeMethodAsync(
        SpecificationService.Assembly,
        SpecificationService.CacheUnits,
        JSON.stringify(this.unitLoaderService.allUnits)
      );
    } catch (error) {
      console.error('Unable to invoke CacheUnitList', error);
    }
  }

  public getSingleValueRangeLimits(spec: Partial<SingleValueRangeSpec>): { lower: string, upper: string } {
    const result: any = DotNet.invokeMethod(
      SpecificationService.Assembly,
      SpecificationService.CalculateSingleValueRangeSpecRange,
      JSON.stringify(spec)
    );
    const limits: string[] = JSON.parse(result.result);
    return { lower: limits[0], upper: limits[1] };
  }

  /**
   * Creates a quantity instance from NumberValue properties.
   *
   * Note: the opposite is Quantity.valueof().
   */
  public convertNumberValueToQuantity(source: NumberValue | undefined): Quantity | undefined {
    if (!source) return undefined;

    const unit = this.unitLoaderService.allUnits.find(u => u.id === source.unit);
    return new Quantity(source.state, source.value, unit, source.sigFigs, source.exact);
  }
}
