<div
  class="eln-rows-data eln-table-theme stretch pt-0"
>
  <div class="flex flex-row  eln-subHeading">
    {{ subHeading }}
  </div>
  <bpt-grid #grid
    *ngIf="gridReadyAfterReload"
    class="eln-grid hide-readonly-icons"
    ngClass="true"
    [dataSource]="removedRows"
    [useJsJodaTypes]="true"
    [columnDefinitions]="columnDefinitions"
    [gridMode]="gridMode"
    [allowRowAdd]="false"
    [showAutoSizeButton]="true"
    [showFilterToggleButton]="true"
    [showGridOptionsButton]="false"
    [paginated]="false"
    [rowActionConfigurations]="gridActions"
  >
  </bpt-grid>
</div>
