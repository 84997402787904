import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { LimsLinkoutService } from '../../../../integrations/linkout/lims-linkout.service';
import { IFlagConfig } from 'bpt-ui-library/bpt-grid';
import { ActivityInputType } from '../../../../api/models';

type ExtendedIFlagConfig = IFlagConfig & { className: string; style: string };

@Component({
  selector: 'app-bpt-eln-grid-column-linkout',
  templateUrl: './bpt-eln-grid-column-linkout.component.html',
  styleUrls: ['./bpt-eln-grid-column-linkout.component.scss']
})
export class BptElnGridColumnLinkoutComponent implements ICellRendererAngularComp {
  public params!: any;
  flags: ExtendedIFlagConfig[] = [];

  agInit(params: ICellRendererParams): void {
    params.data.id = params.data.code;
    this.params = params;
    this.buildFlags();
  }

  constructor(private readonly limsLinkoutService: LimsLinkoutService) {}

  refresh(params: ICellRendererParams): boolean {
    this.params = params;
    this.buildFlags();
    return true;
  }

  linkClicked() {
    const code = this.params.data.code?.split(':')[0];
    if (!code) return;
    if (this.params.data.activityInputType === ActivityInputType.InstrumentColumn) {
      window.open(this.limsLinkoutService.getInstrumentColumnRoute(code), '_blank');
      return;
    }
    if (this.params.data.activityInputType === ActivityInputType.Material) {
      window.open(this.limsLinkoutService.getMaterialRoute(code), '_blank');
      return;
    }
  }

  flagClick(e: MouseEvent, flagConfig: ExtendedIFlagConfig) {
    flagConfig.onClick(e);
    e.preventDefault();
    e.stopPropagation();
    return false;
  }

  /**
   * TODO: The tech pbi needs to be created to refactor the BPT library cell render selector code to have partial render placeholder.
   * Until the flags renders will be exists at client side as well.
   */
  private buildFlags() {
    this.flags = [];
    if (!this.params.flagConfigProvider) {
      return;
    }
    const rowId = this.params.data?.id;
    const field = this.params.colDef?.field;

    const createFlag = (flag: IFlagConfig, className: string) => {
      return {
        ...flag,
        ...{
          className,
          style: `display:${flag.enabled ? 'block' : 'none'}; background-color: ${flag.color};`
        }
      } as ExtendedIFlagConfig;
    };

    this.flags.unshift(
      createFlag(
        this.params.flagConfigProvider('top-right', rowId, field),
        'bpt-flag bpt-flag-top-right'
      )
    );
    this.flags.push(
      createFlag(
        this.params.flagConfigProvider('bottom-right', rowId, field),
        'bpt-flag bpt-flag-bottom-right'
      )
    );
  }
}
