<div
  [id]="ControlsDefinition.actionsContainer.id"
  class="eln-row-actions flex gap-3 justify-content-evenly"
  [ngClass]="ControlsDefinition.actionsContainer.styleClass">
  <em

    *ngIf="!params.rowActionParams.displayRestoreActions"
    class="far fa-trash-alt grid-row-action-icon"
    pTooltip="{{ tooltips.removedTooltip }}"
    tooltipPosition="top"
    [id]="ControlsDefinition.removeItem.id"
    (click)="removeItem()"
    [ngClass]="ControlsDefinition.removeItem.styleClass"></em>
  <em
    *ngIf="!params.rowActionParams.displayRestoreActions && !params.refreshing"
    pTooltip="{{ tooltips.refreshTooltip }}"
    class="fas fa-sync-alt grid-row-action-icon"
    [id]="ControlsDefinition.refreshItem.id"
    tooltipPosition="top"
    (click)="refreshItem()"
    [ngClass]="ControlsDefinition.refreshItem.styleClass"></em>
  <em
    *ngIf="params.refreshing"
    class="fas fa-sync-alt grid-row-action-icon pending-img"
    id="eln-lab-item-refresh-progress"></em>
  <em
    *ngIf="params.rowActionParams.displayRestoreActions"
    pTooltip="{{ tooltips.restoredTooltip }}"
    [id]="ControlsDefinition.restoreItem.id"
    class="far icon-restore-icon grid-row-action-icon"
    tooltipPosition="top"
    (click)="restoreRow()"
    [ngClass]="ControlsDefinition.restoreItem.styleClass"></em>
</div>