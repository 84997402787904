import { Injectable, OnDestroy } from '@angular/core';
import { ExperimentService } from '../services/experiment.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { BarcodeScannerHelper } from '../../services/barcode-scanner-helper';
import { KeyColumnType } from '../../api/models';
import { uniq } from 'lodash-es';
import { LabItemsService } from '../labItems/lab-items.service';
import { PreparationEventService } from '../../preparation/services/preparation-event.service';

@Injectable({
  providedIn: 'root'
})

export class KeyColumnService implements OnDestroy {
  private readonly aliquotSubscription?: Subscription;
  private readonly studyActivitySubscription?: Subscription;
  private readonly materialSubscription?: Subscription;
  private readonly preparationSubscription?: Subscription;

  private readonly aliquotsSubject = new BehaviorSubject<{label: string, value: string}[]>([]);
  private readonly materialsSubject = new BehaviorSubject<{label: string, value: string}[]>([]);
  private readonly preparationsSubject = new BehaviorSubject<{label: string, value: string}[]>([]);

  public samples$ = this.aliquotsSubject.asObservable();
  public materials$ = this.materialsSubject.asObservable();
  public preparations$ = this.preparationsSubject.asObservable();
  
  constructor(
    private readonly experimentService: ExperimentService,
    private readonly barcodeScannerHelper: BarcodeScannerHelper,
    private readonly labItemService: LabItemsService,
    private readonly preparationEventService: PreparationEventService
  ) {
    this.aliquotSubscription = this.barcodeScannerHelper.aliquotUpdateEvent.subscribe((activityId: string) => {
      this.getAliquotKeys(activityId);
    });

    this.studyActivitySubscription = this.barcodeScannerHelper.studyActivityUpdateEvent.subscribe((activityId: string) => {
      this.getMaterialKeys(activityId);
    });

    this.materialSubscription = this.labItemService.materialUpdateEvent.subscribe((activityId: string) => {
      this.getMaterialKeys(activityId);
    })

    this.preparationSubscription = this.preparationEventService.preparationNodeId$.subscribe((activityId: string) => {
      this.getPreparationKeys(activityId);
    });
   }

   ngOnDestroy(): void {
     this.aliquotSubscription?.unsubscribe();
     this.studyActivitySubscription?.unsubscribe();
     this.materialSubscription?.unsubscribe();
     this.preparationSubscription?.unsubscribe();
   }

  getKeys(activityId: string, columnType: KeyColumnType): {label: string, value: string}[] {
    if (columnType === KeyColumnType.Samples) {
      return this.getAliquotKeys(activityId);
    }
    if (columnType === KeyColumnType.Materials) {
      return this.getMaterialKeys(activityId);
    }
    if(columnType === KeyColumnType.Preparations) {
      return this.getPreparationKeys(activityId);
    }
    return [];
  }

  private getAliquotKeys(activityId: string): {label: string, value: string}[] {
    const activityInputs = this.experimentService.currentExperiment?.activityInputs?.filter(input => input.activityId === activityId);
    const samples = (activityInputs ?? [])
      .flatMap((input) => input.aliquots.filter((aliquot) => !aliquot.isRemoved))
      .map((aliquot) => aliquot.sampleNumber);
    const uniqueSamples = uniq(samples);
    const sampleKeys = uniqueSamples.map(key => ({label: key, value: key}));
    this.aliquotsSubject.next(sampleKeys);
    return sampleKeys;
  }

  private getMaterialKeys(activityId: string): {label: string, value: string}[] {
    const activityLabItems = this.experimentService.currentExperiment?.activityLabItems?.find(input => input.nodeId === activityId);
    const materialsFromLabItems = (activityLabItems?.materials ?? [])
      .filter(material => !material.isRemoved)
      .map(material => material.code);

    const activityInputs = this.experimentService.currentExperiment?.activityInputs?.filter(input => input.activityId === activityId);
    const studyActivities = (activityInputs ?? [])
      .flatMap((input) => input.materials.filter((material) => !material.isRemoved))
      .map((material) => material.code);
      
    const combinedMaterials = uniq([...materialsFromLabItems, ...studyActivities]);
    const materialKeys = combinedMaterials.map(key => ({label: key, value: key}));

    this.materialsSubject.next(materialKeys);
    return materialKeys;
  }

  private getPreparationKeys(activityId: string): {label: string, value: string}[] {
    const activity = this.experimentService.currentExperiment?.activities?.find(a => a.activityId === activityId);
    const preparations = (activity?.preparations ?? [])
      .filter(preparation => !preparation.isRemoved)
      .map(preparation => preparation.preparationNumber);
    const uniquePreparations = uniq(preparations);
    const preparationKeys = uniquePreparations.map(key => ({ label: key, value: key }));
    this.preparationsSubject.next(preparationKeys);
    return preparationKeys;
  }
}
