<div class="grid justify-content-around my-5">
  <div class="headers">
    <div class="col">
      <b i18n="@@singleOrMultipleTablesHeaderDetail">Single or Multiple Tables?</b>
      <p i18n="@@singleOrMultipleTablesSubHeaderDetail">Do you want to add rows for each input for only this table, or
        for
        all tables in the Activity?</p>
    </div>
  </div>
</div>

<div class="grid">
  <div class="col">
    <div
      class="flex flex-direction-column flex-wrap justify-content-end card-container column-gap-1 step-one-options-container pt-3 pb-3 pr-3 right-0 absolute bottom-0">
      <p-button
        id="repeat-target-modal-cancel"
        i18n-label="@@cancel"
        label="Cancel"
        styleClass="p-button-outlined"
        (onClick)="cancel()"></p-button>
      <p-button
        id="repeat-target-modal-addForThisTable"
        i18n-label="@@addForThisTable"
        label="Add for this Table"
        (onClick)="addForThisTable()"></p-button>
      <p-button
        id="repeat-target-modal-addForTheActivity"
        i18n-label="@@addForTheActivity"
        label="Add for the Activity"
        (onClick)="addForTheActivity()"></p-button>
    </div>
  </div>
</div>
