/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { LabsiteGetResponse } from '../../models/labsite-get-response';

export interface LabsitesLabsiteCodeSubBusinessUnitsGet$Plain$Params {
  labsiteCode: string;
  'X-User-Puid'?: any;
  'X-User-LabsiteCode'?: any;

/**
 * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
 */
  'X-Experiment-PreviewMode'?: any;
}

export function labsitesLabsiteCodeSubBusinessUnitsGet$Plain(http: HttpClient, rootUrl: string, params: LabsitesLabsiteCodeSubBusinessUnitsGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<LabsiteGetResponse>> {
  const rb = new RequestBuilder(rootUrl, labsitesLabsiteCodeSubBusinessUnitsGet$Plain.PATH, 'get');
  if (params) {
    rb.path('labsiteCode', params.labsiteCode, {});
    rb.header('X-User-Puid', params['X-User-Puid'], {});
    rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
    rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
  }

  return http.request(
    rb.build({ responseType: 'text', accept: 'text/plain', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<LabsiteGetResponse>;
    })
  );
}

labsitesLabsiteCodeSubBusinessUnitsGet$Plain.PATH = '/labsites/{labsiteCode}/sub-business-units';
