import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent } from '../../../base/base.component';
import { ExperimentService } from '../../services/experiment.service';
import { ColumnType, ExperimentPreparationStatus, ExperimentWorkflowState, FieldType, User, ValueState, ValueType } from '../../../api/models';
import { ClientStateService } from '../../../services/client-state.service';
import { ActivatedRoute } from '@angular/router';
import { UserService as CurrentUserService } from 'services/user.service';
import { UserService } from '../../../api/services'
import { PreparationEventService } from '../../../preparation/services/preparation-event.service';
import {
  CellLockDetails,
  CellLockRequest,
  ContainerGridData,
  NewPreparationsSubmission,
  Preparation,
  PreparationAuditHistoryInputs
} from '../../../preparation/models/preparation.model';
import { v4 as uuid } from 'uuid';
import {
  ChangePreparationInternalInformationCommand,
  CreateExperimentPreparationsCommand,
  ExperimentPreparationChangeInternalInformationCommand,
  ExperimentPreparationChangeStatusCommand,
  ExperimentPreparationDiscardOrConsumeCommand,
  IncludePreparationAdditionalInformationCommand,
  IncludePreparationContainerCommand,
  IncludePreparationInternalInformationCommand,
  IncludePreparationSummaryCommand,
  PreparationChangeStatusCommand,
  RemovePreparationCommand,
  RestorePreparationCommand,
  PreparationCellChangeCommand,
  ExperimentPreparationCellChangeCommand} from '../../../api/data-entry/models';
import { DataValueService } from '../../services/data-value.service';
import { PreparationsEventsService } from '../../../api/data-entry/services';
import { ExperimentPreparationService } from './services/experiment-preparation.service';
import { PreparationItem } from '../../../preparation/models/preparation-presentation.model';
import { Subscription } from 'rxjs';
import { UnsubscribeAll } from '../../../shared/rx-js-helpers';
import { PreparationService } from '../../../preparation/services/preparation.service';
import { ClientValidationDetails } from '../../../model/client-validation-details';
import { DiscardOrConsumePreparationCommand } from '../../../api/data-entry/models/discard-or-consume-preparation-command';
import { Instant } from '@js-joda/core';
import { cloneDeepWith } from 'lodash-es';
import { FlagRendererService } from '../../services/flag-renderer.service';
import { ClientFacingNoteRequest, FlagConfigRequest, InternalCommentsRequest } from '../../../preparation/models/comments.model';
import { PreparationTableDataChangeContext } from '../../../preparation/models/tracking.model';
import { CompletionTrackingService } from '../../../services/completion-tracking.services';
import { StatusBar } from 'bpt-ui-library/bpt-grid/model/status-bar.interface';
import { ExperimentDetailForLabels, PreparationStatusBarComponent } from '../../../preparation/preparation-status-bar/preparation-status-bar.component';
import { CommentService } from '../../comments/comment.service';
import { ExperimentCollaboratorsService } from '../../../services/experiment-collaborators.service';
import { ExperimentNotificationService } from '../../../services/experiment-notification.service';
import { CellLock, LockType } from '../../../model/input-lock.interface';
import { PreparationConstants } from '../../../preparation/preparation-constants';
import { DataRecordService } from '../../services/data-record.service';

@Component({
  selector: 'app-experiment-preparations',
  templateUrl: './experiment-preparations.component.html',
  styleUrls: ['./experiment-preparations.component.scss']
})
export class ExperimentPreparationsComponent extends BaseComponent implements OnInit, OnDestroy {
  preparations: Array<PreparationItem> = [];
  removedPreparations: Array<PreparationItem> = [];
  validation!: ClientValidationDetails;
  subscriptions: Subscription[] = [];
  isStatusColumnEditable = false;
  statusBar: StatusBar = {
    showTotalRowCount: true,
    showSelectedRowsCount: true,
    statusPanels: [{ statusPanel: PreparationStatusBarComponent, key: 'statusBarCompKey', align: 'left' }]
  };
  disableCreatePreparation = false;
  disableRemoveAndRestorePreparation = false;
  currentActivityId = "";
  isReviewer = false;
  disableGrid = false;
  isCollaborator = false;
  initialCellLockDetails: CellLockDetails[] = [];
  constructor(
    private readonly experimentService: ExperimentService,
    private readonly elementRef: ElementRef,
    clientStateService: ClientStateService,
    private readonly route: ActivatedRoute,
    private readonly userApiService: UserService,
    private readonly cdr: ChangeDetectorRef,
    private readonly currentUserService: CurrentUserService,
    private readonly dataValueService: DataValueService,
    private readonly dataRecordService: DataRecordService,
    public readonly preparationEventService: PreparationEventService,
    private readonly preparationsEventsService: PreparationsEventsService,
    public readonly experimentPreparationServices: ExperimentPreparationService,
    private readonly preparationService: PreparationService,
    private readonly flagRendererService: FlagRendererService,
    private readonly commentService: CommentService,
    private readonly completionTrackingService: CompletionTrackingService,
    private readonly experimentCollaboratorsService: ExperimentCollaboratorsService,
    private readonly experimentNotificationService: ExperimentNotificationService,
    ) {
    super(clientStateService, route);
    this.mapRemovedPreparation();
    this.mapRestoredPreparation();
    this.subscriptions.push(this.experimentService.templateEventService.ExperimentRefreshed.subscribe({
      next: () => this.loadDataSource()
    }));
    if (currentUserService.usersWithAnalystRoles.length === 0)
      this.getUsersWithRequiredRoles(['ELN Analyst'])
    this.isExperimentReadOnly();
    this.checkReadOnlyStatus(experimentService.currentExperiment?.workflowState);
    this.subscriptions.push(
      this.flagRendererService.ClientFacingNoteAdded.subscribe({
        next: (note) => {
          this.elementRef.nativeElement.dispatchEvent(note);
        }
      })
    );
    this.subscriptions.push(this.experimentService._isCurrentUserCollaboratorSubject$.subscribe({
      next: (amICollaborator: boolean) => {
        if (amICollaborator) {
          this.isCollaborator = amICollaborator;
          this.checkReadOnlyStatus(this.experimentService.currentExperiment?.workflowState);
        }
      }
    })
    );
    this.watchCollaborativeEditingSubscriptions();
    this.subscriptions.push(this.dataRecordService.experimentWorkFlowDataRecordReceiver.subscribe((workflowEventNotification) => this.checkReadOnlyStatus(workflowEventNotification.state)));
  }

  mapRestoredPreparation() {
    this.subscriptions.push(this.experimentPreparationServices.preparationRestoreResponse().subscribe((preparationRestoredResponse) => {
      const restoredPreparation = this.experimentPreparationServices.removedPreparationsDetails?.find(x => x.preparationId === preparationRestoredResponse.activityPreparationRestoredNotification.preparationId);
      if (restoredPreparation) {
        this.preparations = this.preparations.map(preparation => {
          if (preparation.preparationId === restoredPreparation?.preparationId) {
            preparation.isRemoved = false;
          }
          return preparation;
        })
      }
      this.loadDataSource();
      this.checkCollaborator(true);
      this.preparationEventService.restorePreparationSuccessNotification(true);
    }));
  }

  ngOnInit(): void {
    const pickLists = this.preparationService.getPickListsForPreparation();
    this.subscriptions.push(pickLists.storageCondition.subscribe((storageConditionData) => {
      this.preparationService.storageOptions = storageConditionData.items;
    }));
    this.watchForActivityTitleDeterminationThroughRoute(this.route);
    this.experimentPreparationServices.removedPreparationsDetails = this.preparations.filter(preps => preps.isRemoved);
    this.createPreparations();
    this.removePreparationInformation();
    this.restorePreparationInformation();
    this.changePreparationInternalInformation();
    this.discardOrConsumePreparations();
    this.updatePreparations();
    this.changeExperimentPreparationStatus();
    this.watchWorkFlowStateChanges();
    this.watchInternalCommentsEvents();
    this.watchClientFacingNoteEvents();
    this.cellChangedNotification();
    this.loadAuditHistoryDialog();
    this.loadCellLocks();
  }

  mapRemovedPreparation() {
    this.subscriptions.push(this.experimentPreparationServices.preparationRemovalResponse().subscribe((preparationRemovedResponse) => {
      const currentActivity = this.experimentService.currentActivity;
      if (currentActivity && currentActivity.preparations) {
        this.preparations = currentActivity.preparations.map(preparation => {
          if (preparation.preparationId === preparationRemovedResponse.activityPreparationRemovedNotification.preparationId) {
            preparation.isRemoved = true;
          }
          return preparation
        })
      }
      this.cdr.detectChanges();
      const preparationRemovedSuccessMessage = $localize`:@@preparationRemovedSuccessMessage:The Preparation has been removed successfully`
      this.preparationService.buildToastMessage('notification', 'success', 'addSuccessToast', preparationRemovedSuccessMessage, false)
      this.loadDataSource();
      this.checkCollaborator(true);
      this.preparationEventService.removePreparationSuccessNotification(true);
    }));
  }

  cellChangedNotification() {
    this.subscriptions.push(this.experimentPreparationServices.preparationCellChangedResponse().subscribe((preparationCellChangedResponse) => {
      if (preparationCellChangedResponse) {
        const preparationCellChangedSuccessMessage = $localize`:@@preparationCellChangedSuccessMessage:The cell value has been updated successfully for Preparation`
        this.preparationService.buildToastMessage('notification', 'success', 'addSuccessToast', preparationCellChangedSuccessMessage, false)
      }
    }));
  }

  loadAuditHistoryDialog() {
    this.subscriptions.push(
      this.preparationEventService.preparationHistoryRequestData.subscribe((input: PreparationAuditHistoryInputs) => {
        input.experimentId = this.experimentService.currentExperiment?.id;
        input.activityId = this.experimentService.currentActivity?.activityId ?? '';
        this.experimentPreparationServices.loadAuditHistoryDialog(input)
      })
    );
  }
  /*
   * This Method will be triggered when there is change in route ,basically written to
   * handle when two different activities contains preparations and preparations should change when route changes.
   */
  public watchForActivityTitleDeterminationThroughRoute(route: ActivatedRoute) {
    this.subscriptions.push(route.params.subscribe((params) => {
      const activity = this.experimentService.GetActivityBasedOnParams(params);
      if (activity) {
        this.experimentService.currentActivityId = activity.activityId;
        this.currentActivityId = activity.activityId;
        this.loadDataSource();
      }
    }));
  }

  /**
   * This method used to render all the preparation related to respective activity.
   */
  loadDataSource() {
    const currentActivity = this.experimentService.currentExperiment?.activities.find(act => act.activityId === this.currentActivityId);
    if (currentActivity) {
      if (currentActivity.preparations) {
       const statusPanel = this.statusBar.statusPanels.find(s=>s.key === 'statusBarCompKey');
        if(statusPanel) {
        statusPanel.statusPanelParams = this.getCurrentExperimentDetails();
          }
        this.preparations = [...currentActivity.preparations];
        this.experimentPreparationServices.removedPreparationsDetails = [...this.preparations.filter(x => x.isRemoved)];
        this.cdr.detectChanges();
      }
      else {
        this.preparations = [];
        this.cdr.detectChanges();
      }
    }
  }

  getCurrentExperimentDetails(): Partial<ExperimentDetailForLabels> {
    return {
      experimentDetails: {
        experimentNumber: this.experimentService.currentExperiment?.experimentNumber ?? '' ,
        experimentId: this.experimentService.currentExperiment?.id ?? '',
        currentActivityId: this.currentActivityId
      }
    };
  }

  /*To change disabled status when the Experiment workflow is changed*/
  public isExperimentReadOnly() {
    this.subscriptions.push(this.experimentService.experimentWorkFlowState.subscribe((state) => {
      this.checkReadOnlyStatus(state);
    }));
  }
  private watchWorkFlowStateChanges() {
    this.subscriptions.push(this.experimentService.experimentWorkFlowState.subscribe({
      next: (state) => {
        this.checkReadOnlyStatus(state);
      }
    }));
  }
  /*Returns the flag based on the experiment workflow state */
  public checkReadOnlyStatus(state: ExperimentWorkflowState | undefined) {
    if(this.checkForReviewerRestrictions(state)) return;

    if (state === ExperimentWorkflowState.Setup ||
      state === ExperimentWorkflowState.InProgress ||
      state === ExperimentWorkflowState.InCorrection) {
      this.checkIfUserIsReviewer(false);
      this.isStatusColumnEditable = false;
      if (this.currentUserService.usersWithAnalystRoles.some((user: User) =>
        user.puid === this.currentUserService.currentUser.puid))
        this.disableGrid = false;
      else
        this.disableGrid = true;
    }
    else {
      this.disableGrid = true;
    }
    this.preparationService.isSliderDisabled = this.disableGrid;
    if (state === ExperimentWorkflowState.Authorized ||
      state === ExperimentWorkflowState.InReview ||
      state === ExperimentWorkflowState.Cancelled) {
      this.enableOrDisableCreatePreparation(true);
      this.enableOrDisableRemoveAndRestore(true);
      this.preparationService.isUserAllowedToDelete = false;
      this.experimentPreparationServices.isRestoreButtonEnabled = false;
      if (state === ExperimentWorkflowState.InReview) {
        this.checkIfUserIsReviewer(true);
        this.isStatusColumnEditable = this.isReviewer && !this.isCollaborator;
      }
      else {
        this.checkIfUserIsReviewer(false);
        this.isStatusColumnEditable = false;
      }
    }
    else {
      this.enableOrDisableCreatePreparation(false);
      this.enableOrDisableRemoveAndRestore(false);
      this.preparationService.isUserAllowedToDelete = true;
      this.experimentPreparationServices.isRestoreButtonEnabled = true;
    }
  }

  private checkForReviewerRestrictions(state: ExperimentWorkflowState | undefined): boolean {
    let isRestricted = false;
    if(this.currentUserService.hasOnlyReviewerRights()) {
      this.disableCreatePreparation = true;
      this.disableGrid = true;
      this.preparationService.isUserAllowedToDelete = false;
      this.experimentPreparationServices.isRestoreButtonEnabled = false;
      if (state === ExperimentWorkflowState.InReview) {
        this.checkIfUserIsReviewer(true);
        this.isStatusColumnEditable = this.isReviewer && !this.isCollaborator;
      }
      else {
        this.isStatusColumnEditable = false;
      }
      isRestricted = true;
    }
    return isRestricted;
  }

  /*Disable or Enable the flag based on the experiment workflow state and
  user role(should be ELN Analyst) */
  public enableOrDisableCreatePreparation(experimentState: boolean) {
    if (!this.currentUserService.usersWithAnalystRoles.some((user: User) =>
      user.puid === this.currentUserService.currentUser.puid) || experimentState)
      this.disableCreatePreparation = true;
    else
      this.disableCreatePreparation = false;
  }
  /*Check if user has reviewer role to enable access to status field in preparations grid*/
  public checkIfUserIsReviewer(isValidState: boolean) {
    if (this.currentUserService.usersWithReviewerRoles.some((user: User) =>
      user.puid === this.currentUserService.currentUser.puid) && isValidState)
      this.isReviewer = true;
    else
      this.isReviewer = false;
  }

  /*Disable or Enable the flag based on the experiment workflow state and
user role(should be ELN Analyst) */
  public enableOrDisableRemoveAndRestore(experimentState: boolean) {
    const isAnalyst = this.currentUserService.usersWithAnalystRoles.some((user: User) =>
      user.puid === this.currentUserService.currentUser.puid);
    const isSupervisor = this.currentUserService.usersWithSupervisorRoles.some((user: User) =>
      user.puid === this.currentUserService.currentUser.puid);
    const isReviewer = this.currentUserService.usersWithReviewerRoles.some((user: User) =>
      user.puid === this.currentUserService.currentUser.puid);
    if ((isAnalyst || isSupervisor || isReviewer) && experimentState) {
      this.disableRemoveAndRestorePreparation = true;
      this.experimentPreparationServices.isUserAllowedToRestore = false;
    }
    else {
      this.disableRemoveAndRestorePreparation = false;
      this.experimentPreparationServices.isUserAllowedToRestore = true;
    }
  }

  /* Get all users with the respective user role(ELN Analyst)
  and populates the variable currentUserService.usersWithAnalystRoles */
  public getUsersWithRequiredRoles(roles: string[]) {
    this.subscriptions.push(
      this.userApiService
        .usersActiveUsersPost$Json({
          body: roles
        })
        .subscribe({
          next: (userList: User[]) => {
            userList = userList.sort((firstValue, secondValue) => firstValue.fullName.trim().localeCompare(secondValue.fullName.trim()));
            this.currentUserService.setUsersListBasedOnRoles('ELN Analyst', userList);
          }
        }))
  }

  /* Helps to create new preparation from user input */
  createPreparations() {
    this.subscriptions.push(this.preparationEventService.newPreparationsSubmitData.subscribe(
      newPreparationSubmittedEvent => {
        const createdPreparations = this.buildCreatePreparationCommand(newPreparationSubmittedEvent.data);
        if (createdPreparations.additionalInformation) {
          createdPreparations.additionalInformation.preparedBy = this.currentUserService.currentUser.puid;
          createdPreparations.additionalInformation.subBusinessUnit = this.currentUserService.currentUser.primarySubBusinessUnit;
          if(createdPreparations.internalAdditionalInformation?.storageLocation) {
            createdPreparations.additionalInformation.storedBy = this.currentUserService.currentUser.puid;
          }
        }
        this.savePreparationInformation(createdPreparations, newPreparationSubmittedEvent);
        this.preparationEventService.emitNodeId(createdPreparations.nodeId);
      }))
  }

  /*Helps edit internal information from user input*/
  changePreparationInternalInformation() {
    this.subscriptions.push(this.preparationEventService.preparationInternalInformationChangedData.subscribe({
      next: (preparationInternalInformationChangedEvent) => {
        const internalInformationChangedCommand = this.buildChangePreparationInternalInformationCommand(preparationInternalInformationChangedEvent);
        this.changeInternalInformation(internalInformationChangedCommand);
      }
    }
    ))
  }

  /*Helps discard or consume preparation from user input*/
  discardOrConsumePreparations() {
    this.subscriptions.push(this.preparationEventService.preparationDiscardedOrConsumedData.subscribe({
      next: (preparationDiscardedOrConsumedEvent) => {
        const DiscardOrConsumePreparationCommand = this.buildChangePreparationDiscardedOrConsumedCommand(preparationDiscardedOrConsumedEvent);
        this.discardOrConsumePreparation(DiscardOrConsumePreparationCommand);
      }
    }))
  }

  changeExperimentPreparationStatus() {
    this.subscriptions.push(this.preparationEventService.preparationStatusChangedData.subscribe({
      next: (preparationStatusChangedEvent) => {
        const preparationStatusChangedCommand = this.buildPreparationStatusChangedCommand(preparationStatusChangedEvent);
        this.changePreparationStatus(preparationStatusChangedCommand);
      }
    }))
  }

  /* Helps to save preparation information to the cosmos */
  private savePreparationInformation(preparedPreparations: CreateExperimentPreparationsCommand, submittedPreparation: NewPreparationsSubmission) {
    this.subscriptions.push(this.preparationsEventsService
      .preparationsEventsCreatePreparationsPost$Json$Response({
        body: preparedPreparations
      }).subscribe({
        next: (data: any) => {
          submittedPreparation.closeHandler();
          const preparationData = this.experimentPreparationServices.buildPresentationModel(data.body).preparations;
          const currentActivity = this.experimentService.currentActivity;
          const existingPreparation = cloneDeepWith(this.preparations);
          existingPreparation.push(...preparationData);
          this.preparations = existingPreparation;
          if (currentActivity) {
            if (currentActivity.preparations) {
              currentActivity.preparations.push(...preparationData);
            }
            else {
              currentActivity.preparations = preparationData;
            }
          }
          this.checkCollaborator(true);
          if (submittedPreparation.toasterMessage)
            this.preparationService.buildToastMessage('notification', 'success', 'addSuccessToast', submittedPreparation.toasterMessage, false);
        }
      }));
  }

  private checkCollaborator(currentUserBecameCollaborator: boolean) {
    if (!this.isCollaborator && currentUserBecameCollaborator) {
      this.experimentService._isCurrentUserCollaboratorSubject$.next(currentUserBecameCollaborator);
    }
  }

  removePreparation(preparationToBeRemoved: RemovePreparationCommand) {
    this.experimentPreparationServices.removePreparation(preparationToBeRemoved);
  }

  restorePreparation(preparationToBeRestored: RestorePreparationCommand) {
    this.experimentPreparationServices.restorePreparation(preparationToBeRestored);
  }
  /*Helps save the edited Internal Information to cosmos*/
  private changeInternalInformation(changeInternalInfoCommand: ExperimentPreparationChangeInternalInformationCommand) {
    this.subscriptions.push(
      this.preparationsEventsService.preparationsEventsChangePreparationInternalInformationPost$Json({
        body: changeInternalInfoCommand
      }).subscribe({
        next: (response: any) => {
          this.checkCollaborator(true);
          const currentPreparation = this.preparations.find(preparation => preparation.preparationId === response.notification.preparationId);
          this.buildAdditionalInformationData(currentPreparation, response);
          this.updateInternalInformation(currentPreparation, response.notification);
        }
      })
    );
  }

  private buildAdditionalInformationData(currentPreparation: PreparationItem | undefined, response: any) {
    if (currentPreparation?.additionalInformation) {
      currentPreparation.additionalInformation.analysis = response.notification.analysis ? response.notification.analysis : currentPreparation.additionalInformation.analysis;
      currentPreparation.additionalInformation.client = response.notification.client ? response.notification.client : currentPreparation.additionalInformation.client;
      currentPreparation.additionalInformation.project = response.notification.project ? response.notification.project : currentPreparation.additionalInformation.project;
      currentPreparation.additionalInformation.method = response.notification.method ? response.notification.method : currentPreparation.additionalInformation.method;
      currentPreparation.additionalInformation.storedBy = response.notification.storedBy ? response.notification.storedBy : currentPreparation.additionalInformation.storedBy;
      currentPreparation.additionalInformation.subBusinessUnit = response.notification.subBusinessUnit ? response.notification.subBusinessUnit : currentPreparation.additionalInformation.subBusinessUnit;
      currentPreparation.additionalInformation.preparedBy = response.notification.preparedBy;
    }
  }

  updateInternalInformation(currentPreparation: PreparationItem | undefined, response: any) {
    if (currentPreparation && currentPreparation.internalInformation) {
      currentPreparation.internalInformation.disposal = response.disposal ? response.disposal : currentPreparation.internalInformation.disposal;
      currentPreparation.internalInformation.hazards = response.hazards ? response.hazards : currentPreparation.internalInformation.hazards;
      currentPreparation.internalInformation.additionalLabel = response.additionalLabel ? response.additionalLabel : currentPreparation.internalInformation.additionalLabel;
      currentPreparation.internalInformation.originalQuantity = response.originalQuantity ? response.originalQuantity : currentPreparation.internalInformation.originalQuantity;
      currentPreparation.internalInformation.stability = response.stability ? response.stability : currentPreparation.internalInformation.stability;
      currentPreparation.internalInformation.storageLocation = response.storageLocation ? response.storageLocation : currentPreparation.internalInformation.storageLocation;
    }
  }

  /*Helps save the discarded or consumed status to cosmos*/
  discardOrConsumePreparation(command: ExperimentPreparationDiscardOrConsumeCommand) {
    this.subscriptions.push(
      this.preparationsEventsService.preparationsEventsDiscardOrConsumePreparationPost$Json({
        body: command
      }).subscribe({
        next: (response: any) => {
          this.checkCollaborator(true);
          const currentPreparation = this.preparations.find(preparation => preparation.preparationId === response.notification.preparationId);
          if (currentPreparation && currentPreparation.additionalInformation) {
            currentPreparation.additionalInformation.discardedOrConsumed = response.notification.discardedOrConsumed;
            currentPreparation.additionalInformation.discardedOrConsumedBy = response.notification.discardedOrConsumed ?
              this.currentUserService.currentUser.puid : "";
            currentPreparation.additionalInformation.discardedOrConsumedOn = response.notification.discardedOrConsumed ? Instant.now().toString() : "";
          }
        }
      })
    )
  }

  changePreparationStatus(changePreparationStatusCommand: ExperimentPreparationChangeStatusCommand) {
    this.subscriptions.push(
      this.preparationsEventsService.preparationsEventsChangePreparationStatusPost$Json({
        body: changePreparationStatusCommand
      }).subscribe({
        next: (response: any) => {
          const currentPreparation = this.preparations.find(preparation => preparation.preparationId === changePreparationStatusCommand.command.preparationId);
          if (currentPreparation && currentPreparation.additionalInformation) {
            currentPreparation.status = response.notification.status;
            currentPreparation.additionalInformation.reviewedBy = response.notification.status === ExperimentPreparationStatus.Reviewed ? this.currentUserService.currentUser.puid : "";
            this.preparationService.buildToastMessage(
              'notification',
              'success',
              'validationToast',
              `${currentPreparation.preparationNumber}`,
              false, PreparationConstants.cellValueChangedMessage
            );
          }
        }
      })
    )
  }

  /* Helps to build preparation command from user input */
  buildCreatePreparationCommand(preparation: Preparation): CreateExperimentPreparationsCommand {
    const preparationCommand: CreateExperimentPreparationsCommand = {
      experimentId: this.experimentService.currentExperiment?.id ? this.experimentService.currentExperiment?.id : "",
      nodeId: this.experimentService.currentActivityId,
      summary: this.buildSummary(preparation),
      containers: this.buildContainers(preparation),
      additionalInformation: this.buildAdditionalInformation(preparation),
      internalAdditionalInformation: this.buildInternalInformation(preparation),
      expirationValue: {
        expirationDateValue: preparation.expiration.expirationDate
      }
    }
    return preparationCommand;
  }

  /*Helps build change preparation internal information command from user input*/
  buildChangePreparationInternalInformationCommand(changeInternalInfoCommand: ChangePreparationInternalInformationCommand): ExperimentPreparationChangeInternalInformationCommand {
    const changeInternalInformationCommand: ExperimentPreparationChangeInternalInformationCommand = {
      experimentId: this.experimentService.currentExperiment?.id ? this.experimentService.currentExperiment?.id : "",
      nodeId: this.experimentService.currentActivityId,
      command: changeInternalInfoCommand
    }
    return changeInternalInformationCommand;
  }

  /*Helps build discard or consume preparation command from user input*/
  buildChangePreparationDiscardedOrConsumedCommand(discardOrConsumeCommand: DiscardOrConsumePreparationCommand): ExperimentPreparationDiscardOrConsumeCommand {
    const discardOrConsumePreparationCommand: ExperimentPreparationDiscardOrConsumeCommand = {
      experimentId: this.experimentService.currentExperiment?.id ? this.experimentService.currentExperiment?.id : "",
      nodeId: this.experimentService.currentActivityId,
      command: discardOrConsumeCommand
    }
    return discardOrConsumePreparationCommand;
  }

  buildPreparationStatusChangedCommand(changeStatusCommand: PreparationChangeStatusCommand): ExperimentPreparationChangeStatusCommand {
    const experimentPreparationStatusChangedCommand: ExperimentPreparationChangeStatusCommand = {
      experimentId: this.experimentService.currentExperiment?.id ?? '',
      nodeId: this.experimentService.currentActivityId,
      command: changeStatusCommand
    }
    return experimentPreparationStatusChangedCommand;
  }

  /* Helps to build preparation command from user input */
  buildSummary(preparation: Preparation): IncludePreparationSummaryCommand {
    const summaryCommand: IncludePreparationSummaryCommand = {} as IncludePreparationSummaryCommand;
    if (preparation?.concentration?.state !== ValueState.Empty) {
      summaryCommand.concentration = preparation?.concentration;
    } else {
      summaryCommand.concentration = {
        type: ValueType.Number,
        state: ValueState.NotApplicable
      }
    }
    if (preparation?.formulationOrComponents?.state !== ValueState.Empty) {
      summaryCommand.formulaComponents = preparation?.formulationOrComponents;
    } else {
      summaryCommand.formulaComponents = {
        type: ValueType.String,
        state: ValueState.NotApplicable
      }
    }
    if (preparation?.storageCondition?.state !== ValueState.Empty)
      summaryCommand.storageCondition = preparation?.storageCondition;
    return summaryCommand;
  };

  /* Helps to build container command from user input */
  buildContainers(preparation: Preparation): Array<IncludePreparationContainerCommand> {
    return this.setUpContainers(preparation.containers, preparation.name?.value);
  }

  /* Helps to build containers command from user input */
  setUpContainers(gridData?: Array<ContainerGridData>, preparationName?: string): IncludePreparationContainerCommand[] {
    const containers: Array<IncludePreparationContainerCommand> = [];
    if (!gridData) {
      return containers;
    }
    gridData.forEach((container) => {
      const containerData: IncludePreparationContainerCommand = {} as IncludePreparationContainerCommand;
      containerData.preparationId = uuid();
      this.addPreparationNameToContainerData(preparationName, containerData, container);
      const quantityDataValue = container.originalQuantity ? this.dataValueService.getExperimentDataValue(FieldType.Quantity, container.originalQuantity as any) : null;
      if (quantityDataValue) {
        containerData.originalQuantity = quantityDataValue;
      }
      containerData.containerDescription = container.containerDescription;
      containers.push(containerData);
    });
    return containers;
  }
  private addPreparationNameToContainerData(preparationName: string | undefined, containerData: IncludePreparationContainerCommand, container: ContainerGridData) {
    if (preparationName?.trim() !== '' &&
      preparationName !== undefined) {
      const emittedValue = `${preparationName} ${container.tags ?? '' }`;
      containerData.name = this.dataValueService.getExperimentDataValue(ColumnType.String,emittedValue?.trim());
    }
  }

  public updatePreparations() {
    this.subscriptions.push(this.preparationEventService.preparationCellChanged.subscribe(
      preparationCellChangedEvent => {
        this.experimentPreparationServices.updatePreparation(this.buildCellChangedCommand(preparationCellChangedEvent));
      }))
  }

  /*method builds the Additional Information based on user entered values*/
  buildAdditionalInformation(preparation: Preparation): IncludePreparationAdditionalInformationCommand {
    const additionalInformation: IncludePreparationAdditionalInformationCommand = {
      analysis: preparation.additionalInformation?.analysis,
      client: preparation.additionalInformation?.client,
      method: preparation.additionalInformation?.method,
      preparedBy: preparation.additionalInformation?.preparedBy as string,
      project: preparation.additionalInformation?.project,
      discardedOrConsumed: preparation.additionalInformation?.discardedOrConsumed
    };
    return additionalInformation;
  }

  /*method builds the Additional Information based on user entered values*/
  buildInternalInformation(preparation: Preparation): IncludePreparationInternalInformationCommand {
    const internalInformation: IncludePreparationInternalInformationCommand = {
      disposal: preparation.preparationInternalInformation?.disposal,
      hazards: preparation.preparationInternalInformation?.hazards,
      stability: preparation.preparationInternalInformation?.stability,
      storageLocation: preparation.preparationInternalInformation?.storageLocation,
      additionalLabel: preparation.preparationInternalInformation?.additionalLabel
    }
    return internalInformation;
  }

  /*method builds the cell changed command based on user entered values*/
  buildCellChangedCommand(preparationCellChangeCommand: PreparationCellChangeCommand): ExperimentPreparationCellChangeCommand {
    const experimentPreparationCellChangeCommand: ExperimentPreparationCellChangeCommand = {
      experimentId: this.experimentService.currentExperiment?.id ? this.experimentService.currentExperiment?.id : "",
      nodeId: this.experimentService.currentActivityId,
      command: preparationCellChangeCommand,
    }
    return experimentPreparationCellChangeCommand;
  }
  /* Helps to remove preparation information to the cosmos */
  private removePreparationInformation() {
    this.subscriptions.push(this.preparationEventService.preparationRemoved.subscribe(
      preparationRemovedEvent => {
        this.removePreparation(this.buildRemoveRestorePreparationCommand(preparationRemovedEvent));
      }))
  }

  /* Helps to remove preparation information to the cosmos */
  private restorePreparationInformation() {
    this.subscriptions.push(this.preparationEventService.preparationRestored.subscribe(
      preparationRestoredEvent => {
        this.restorePreparation(this.buildRemoveRestorePreparationCommand(preparationRestoredEvent));
      }))
  }

  buildRemoveRestorePreparationCommand(preparationId: string): RemovePreparationCommand {
    return {
      experimentId: this.experimentService.currentExperiment?.id ? this.experimentService.currentExperiment?.id : "",
      nodeId: this.experimentService.currentActivityId,
      preparationId: preparationId,
      preparationNumber:  (this.preparations.find(preparation => preparation.preparationId === preparationId) as PreparationItem).preparationNumber
    }
  }

  showInternalComment(request: InternalCommentsRequest) {
    this.flagRendererService.showInternalComments(request.paths, request.contextType);
  }

  showClientFacingNotes(request: ClientFacingNoteRequest) {
    this.flagRendererService.showClientFacingNotes(request.paths, request.contextType, request.nodeId);
  }

  getFlagRendererConfig = (flagConfigRequest: FlagConfigRequest) => {
    return this.flagRendererService.getFlagRendererConfig(
      flagConfigRequest.flag,
      this.flagRendererService.getPathBasedOnFlag(
        flagConfigRequest.flag,
        flagConfigRequest.fieldName,
        flagConfigRequest.id,
        flagConfigRequest.field,
        flagConfigRequest.commentContext
      ),
      flagConfigRequest.commentContext,
      flagConfigRequest.rowId
    )
  }

  completionTracker = (changeContext: PreparationTableDataChangeContext) => {
    return this.completionTrackingService.populateCompletionPercentage(
      changeContext.rows,
      changeContext.initialCompletionPercent,
      changeContext.columnDataFieldIds,
      changeContext.tableInfo,
      changeContext.isTable
    );
  }

  watchClientFacingNoteEvents() {
    this.subscriptions.push(
      this.experimentService.clientFacingNoteEvents.subscribe({
        next: () => {
          this.preparationEventService.refreshGrid.next();
        }
      })
    )
  }

  watchInternalCommentsEvents() {
    this.subscriptions.push(
      this.commentService.refreshInternalComment.subscribe({
        next: () => {
          this.preparationEventService.refreshGrid.next();
        }
      })
    );
  }

  //Collaborative editing section
  watchCollaborativeEditingSubscriptions() {
    this.subscriptions.push(
      this.experimentNotificationService.inputLockReceiver.subscribe({
        next: (locks) => {
          this.preparationEventService.sendPreparationCellLockData(
            {
              lockList: locks,
              collaborator: this.experimentCollaboratorsService.getExperimentCollaborator(locks[0].experimentCollaborator.connectionId)
            }
          )
        },
      })
    );
    this.subscriptions.push(
      this.preparationEventService.refreshGrid.subscribe({
        next: () => {
          this.loadDataSource();
        }
      })
    );
  }

  loadCellLocks(): CellLockDetails[] {
    const cellLocks = this.experimentNotificationService.inputLocks.filter(
      (item) => item.lockType === LockType.lock && (item as CellLock).tableId === this.currentActivityId.concat('-Preparations')
    );
    if(cellLocks.length > 0) {
      cellLocks.forEach(lock => {
        this.initialCellLockDetails.push(
          {
            lockList: [lock as CellLock],
            collaborator: this.experimentCollaboratorsService.getExperimentCollaborator(lock.experimentCollaborator.connectionId)
          }
        )
      });
    }
    return this.initialCellLockDetails;
  }

  sendInputStatus(data: CellLockRequest) {
    const fieldLock = new CellLock(
      this.experimentService.currentExperiment?.id as string,
      data.lockType,
      '',
      this.currentActivityId,
      this.experimentNotificationService.getCollaborator(),
      data.tableId,
      data.rowId,
      data.columnName,
      undefined,
      data.multiSelectCells
    );
    this.experimentNotificationService.sendInputControlStatus([fieldLock]);
  }

  ngOnDestroy(): void {
    UnsubscribeAll(this.subscriptions);
  }
}
