import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { UserService } from 'services/user.service';
import { UserService as UserApiService } from '../../api/services/user.service';
import { User } from '../../api/models';
import { first } from 'lodash-es';

type valueType = string[] | string | undefined;
type UserWithStatus = User & { inactive?: boolean };

@Component({
  selector: 'app-user-select',
  templateUrl: './user-select.component.html',
  styleUrls: []
})
export class UserSelectComponent implements OnInit, OnChanges {
  valueField = 'puid';
  labelField = 'fullName';
  @Input() multiSelect = false;
  @Input() label = $localize`:@@users:Users`;
  @Input() placeholder = $localize`:@@selectUser:Select Users`;
  @Input() userRoles = [];
  @Input() value: valueType = [];
  @Input() setCurrentUser = false;
  @Input() roles: string[] = [];
  @Input() allowNA = false;
  @Input() ngModelOptions = {};
  @Input() wrap = true;
  @Input() readOnly = false;
  @Input() disabled = false;
  @Input() required = false;
  @Input() errorFlag = false;
  @Input() errorMessage = "";
  @Input() usersToBeExcludedFromDisplay: string[] = [];
  @Input() showClear = false;
  @Output() valueChange = new EventEmitter();
  @Output() valueModified = new EventEmitter();
  @Output() userListFetched = new EventEmitter<Array<User>>();

  userList: Array<UserWithStatus> = [];
  oldValue: valueType = [];
  allUserList: Array<UserWithStatus> = [];

  constructor(
    private readonly userApiService: UserApiService,
    private readonly userService: UserService
  ) { }

  ngOnInit() {
    this.oldValue = this.value;
    this.getUsers();
  }

  ngOnChanges() {
    this.filterUsers();
  }

  getUsers() {
    this.userApiService
      .usersActiveUsersPost$Json({
        body: this.roles
      })
      .subscribe((data) => {
        data = data.sort((a, b) => a.fullName.trim().localeCompare(b.fullName.trim()));
        this.userList = data;
        this.setInactiveFlagAndPuidToUpperCase();
        this.addInactiveUsers();
        if (this.roles.length === 1 && first(this.roles) !== undefined)
          this.userService.setUsersListBasedOnRoles(first(this.roles), data);
        if (this.setCurrentUser && (!this.value || this.value.length === 0) && data.some((i) => i.puid == this.userService.currentUser.puid)) {
          this.setDefaultUser();
        }
        this.allUserList = JSON.parse(JSON.stringify(this.userList));
        this.filterUsers();
        this.userListFetched.emit(this.userList);
      });
  }

  /**
   * Adds the inactive users who have been selected in the past when they were active
   */
  addInactiveUsers() {
    if (this.value) {
      if (Array.isArray(this.value)) {
        this.value.forEach((puid) => {
          const option = this.userList.find((userLst) => userLst.puid === puid);
          if (!option) this.userList.unshift({ puid: puid, fullName: puid.toLocaleUpperCase(), inactive: true });
        });
      } else {
        const option = this.userList.find((userLst) => userLst.puid === this.value);
        if (!option) this.userList.unshift({ puid: this.value, fullName: this.value.toLocaleUpperCase(), inactive: true });
      }
    }
  }

  setInactiveFlagAndPuidToUpperCase() {
    this.userList.forEach((user) => {
      user.inactive = false;
      user.fullName = user.fullName.replace(user.puid, (user.puid).toLocaleUpperCase());
    });
  }

  /**
   * Sets the current user as default selection when setCurrentUser is true and no selection made
   */
  setDefaultUser() {
    const puid = this.userService.currentUser.puid;
    const index = this.userList.findIndex((obj) => obj.puid.toLowerCase() === puid.toLowerCase())
    if (index > -1) {
      if (this.multiSelect) {
        this.value = [this.userList[index].puid];
      } else {
        this.value = this.userList[index].puid;
      }
    }
  }

  modelChanged = (newValue: valueType) => {
    if (this.oldValue !== newValue) {
      this.oldValue = newValue;
      this.valueChange.emit(newValue);
      this.valueModified.emit(newValue);
    }
  };

  filterUsers() {
    setTimeout(() => {
      this.userList = this.allUserList.filter((user) => {
        return !this.usersToBeExcludedFromDisplay.some((userToExclude) => {
          return user.puid === userToExclude;
        })
      })
    }, 100);
  }
}
