<div *ngIf="module && !module.isHidden" class="module-wrapper">
  <p-orderList [value]="module.items" (onReorder)="onReorder($event)" [dragdrop]="experimentNodeReOrderService.hasPermission() && experimentNodeReOrderService.hasPermissionWithRespectToFlow()" class="draggableItems" #moduleItemOrderList>
    <ng-template let-item let-i="index" pTemplate="item">
      <ng-container [ngSwitch]="item.itemType">
        <app-data-form class="example-handle" 
          *ngSwitchCase="'form'"
          [form]="asForm(item)"
          [greatestTabOrder]="tabOrderReservation[i]"
          [parentNodeId]="[parentNodeId[0],module.moduleId]">
        </app-data-form>
        <app-data-table class="example-handle" 
          [greatestTabOrder]="tabOrderReservation[i]"
          *ngSwitchCase="'table'"
          [table]="asTable(item)"
          [parentNodeId]="[parentNodeId[0],module.moduleId]">
        </app-data-table>
      </ng-container>
    </ng-template>
  </p-orderList>
</div>
