/* tslint:disable */
/* eslint-disable */
export enum ExperimentWorkflowState {
  Setup = 'setup',
  InProgress = 'inProgress',
  InReview = 'inReview',
  Authorized = 'authorized',
  Cancelled = 'cancelled',
  InCorrection = 'inCorrection',
  Restored = 'restored'
}
