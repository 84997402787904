import { Configuration } from './configuration.interface';
import * as config from '../../../assets/eln-assets/configuration.json';
import * as dynamicConfig from '../../../assets/eln-assets/dev-dynamic-configuration.json';

/**
 * Gets the various configurations from configuration JSON file when outside Helium
 * Add required configuration in JSON file
 * Function should be created for any required configurations and get it from the configurations object
 */
export class DefaultConfiguration implements Configuration {

  private readonly configurations = config;
  private readonly dynamic = dynamicConfig;

  //Gets the Re-authMaxNumberOfAttempts configured value from JSON file
  getReAuthFailedAttempts(): number {
    return this.configurations.ReauthMaxNumberOfAttempts;
  }

  isLimsHosted(): boolean {
    return false;
  }

  getLogViewerUrl(): string {
    return this.dynamic.logging.kibanaUrl;
  }

  isLogViewerDisplayEnabled(): boolean {
    return this.dynamic.logging.enableDisplay;
  }
}
