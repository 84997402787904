<div id="eln-activity-inputs-removed-sample-grid-container" 
class="eln-activity-inputs-data eln-table-theme stretch">
<div class="flex flex-row eln-subHeading">
  {{ subHeading }}
</div>
<div class="eln-tableTitle">{{ titleOfTable }}</div>
  
  <bpt-grid 
    #removedSampleAliquotsGrid 
    class="eln-grid"
    ngClass="true" 
    [columnDefinitions]="removedRowsColumnDefinitions"
    [dataSource]="removedSampleAliquots"
    [useJsJodaTypes]="true"
    gridId="{{removedRowsTableId}}"
    (gridReady)="onGridReady()"
    [gridMode]="gridMode"
    [allowRowAdd]="false"
    [showAutoSizeButton]="true"
    [showFilterToggleButton]="true"
    [showGridOptionsButton]="false"
    [paginated]="false"
    groupDisplayType="custom"        
    rowGroupPanelShow="never"
    [suppressAggFuncInHeader] = "true"
    [displayScrollButtons] = "displayScrollButtons"
    [rowActionConfigurations]="gridActions">
  </bpt-grid>
  </div>