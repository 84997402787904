/* tslint:disable */
/* eslint-disable */
export enum TemplateType {
  Invalid = 'Invalid',
  ActivityGroup = 'ActivityGroup',
  Activity = 'Activity',
  Module = 'Module',
  Form = 'Form',
  Table = 'Table'
}
