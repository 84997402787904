import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TabView } from 'primeng/tabview';
import { AugmentedActivity, RecipeModel, RecipeTypeChange } from '../model/recipe';
import { RecipeService } from '../services/recipe.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Activity, Module } from '../../model/experiment.interface';
import { MenuItem } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { elnDecodeSpecialChars } from '../../shared/url-path-serializer';
import { ContextMenu } from 'primeng/contextmenu';
import { NodeType, TemplateType } from '../../api/cookbook/models';
import { RecipeNodeRetitleService } from '../services/recipe-node-re-title.service';
import {moveItemInArray} from '@angular/cdk/drag-drop';
import { RecipeNodeReOrderService } from '../services/recipe-node-re-order.service';
import { AugmentedModule } from '../../model/recipe.interface';
import { UserService } from '../../services/user.service';
import { RecipeNotificationService } from '../services/recipe-notification.service';
import { TemplateDeleteService } from '../../recipe-template-loader/experiment-template-load/services/template-delete.service';

@Component({
  selector: 'app-recipe-data',
  templateUrl: './recipe-data.component.html',
  styleUrls: ['./recipe-data.component.scss']
})
export class RecipeDataComponent implements OnInit, OnDestroy {
  @ViewChild('tabview') tabview!: TabView;
  recipe!: RecipeModel;
  options = {
    completeTooltip: 'The module is completed',
    incompleteTooltip: 'The module has incomplete items'
  };
  private currentActivityTitle: string | undefined;
  private readonly activeSubscriptions: Subscription[] = [];
  activity!: Activity | undefined;
  /** Modules shown in this activity, i.e. those !isHidden */
  modules: Module[] = [];
  isLoading = false;
  message!: string;
  contextCurrentModuleId?: string;
  recipeHasErrors = false;
  /**
   * fixedModules  will be shown in the available client width and
   * moreModules will be shown in the More dropdown
   */
  moreModuleDropdownOptions: MenuItem[] = [];
  selectedModule!: any;
  moreModulesHeader = $localize`:@@More:More`;
  items!: MenuItem[];
  dynamicDialogRef!: DynamicDialogRef;
  moduleName!: string;
  scrollable = true;
  retitleEnabled: {[moduleId: string]: boolean} = {};
  nodeType = NodeType;
  isRecipe = false;
  constructor(
    public readonly recipeService: RecipeService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    public readonly recipeNodeReOrderService: RecipeNodeReOrderService,
    private readonly userService: UserService,
    private readonly templateDeleteService: TemplateDeleteService,
    private readonly notificationService: RecipeNotificationService
  ) {}

  ngOnInit(): void {
    if(this.recipeService.isRecipeLoaded) {
      this.constructRecipe();
    }
    else {
      this.activeSubscriptions.push(this.recipeService.recipeLoaded.subscribe(() => {
        this.constructRecipe();
      }));
    }
    this.watchExperimentRefreshedNotification();
  }

  constructRecipe() {
    this.recipe = this.recipeService.currentRecipe;
    this.buildContextMenuItems();
    this.activeSubscriptions.push(this.route.params.subscribe((params) => {
      this.currentActivityTitle = params['itemTitle'] ? elnDecodeSpecialChars(params['itemTitle']) : undefined;
      this.refreshActivity(this.recipe);
    }));
    if(this.activity) {
      this.setCurrentActivityModules();
    } else {
      this.modules = this.recipe.modules;
    }
  }

  private setCurrentActivityModules() {
    if(this.activity){
      this.recipeService.currentActivityId = this.activity.activityId;
      this.recipeService.currentActivityTitle = this.activity.itemTitle;
      this.modules = [...(this.activity?.dataModules ?? [])].filter((x) => !x.isHidden);
      this.recipeService.setActivityCompletionStatus(this.activity);
      if((this.activity as AugmentedActivity).isRecipeNode){
        this.isRecipe = true;
      }
    }
    if (this.modules && this.modules.length > 0) {
      this.recipeService.currentModuleName = this.modules[0].moduleName;
      this.recipeService.currentModuleId = this.modules[0].moduleId; //assignment in module.ts is removed bcz we are assigning moduleId here
    }
    this.fixupTabView();
  }

  private watchExperimentRefreshedNotification() {
    this.activeSubscriptions.push(this.recipeService.templateEventService.RecipeRefreshed.subscribe((response: RecipeModel) => {
      this.refreshActivity(response);
    }));

    this.activeSubscriptions.push(this.recipeService.isRecipeUsersChanged.subscribe({
      next: () => {
        this.buildContextMenuItems();
      }
    }));

    this.activeSubscriptions.push(this.recipeService.recipeWorkFlowState.subscribe(() => {
      this.buildContextMenuItems();
    }));

    this.activeSubscriptions.push(this.recipeService.handleRecipeError.subscribe(value => {
      if(value) {
        this.recipeHasErrors = true;
        this.buildContextMenuItems();
      }
    }));
  }

  private refreshActivity(recipe: RecipeModel): void {
    this.recipe = recipe;
    const activity = this.recipe.activities.find(
      (a) => a.itemTitle === this.currentActivityTitle
    );
    if (activity) {
      this.activity = activity;
      this.setCurrentActivityModules();
    } else if (this.recipe.modules.length > 0) {
      this.modules = (this.recipe.modules ?? []).filter((x) => !x.isHidden);
      this.fixupTabView();
    }
  }

  checkForRecipe(module: Module) {
    return (module as AugmentedModule).isRecipeNode;
  }

  moduleSelectionChanged(selectionChangedEvent: any) {
    this.updateInkBarWidth(selectionChangedEvent.index);
  }

  public fixupTabView(index = 0) {
    // Tabview inkbars and buttons display inconsistently, so this code fixes them.
    // Toggling scrollable and calling updateButtonState forces the scroll buttons to display properly.
    // Calling updateInkBar directly fixes how the bar is displayed.
    // This doesn't work properly in the tests because there is no active tab, causing an error
    // when calling updateInkBar, so this has to be bypassed.
    this.scrollable = true;
    setTimeout(() => {
      this.scrollable = false;
      this.tabview.updateButtonState();
      this.tabview.updateInkBar();
      if (index === this.modules.length - 1) {
        this.updateInkBarWidth(index);
      } else {
        this.updateInkBarWidth();
      }
    });
  }

  private updateInkBarWidth(index = 0) {
    setTimeout(() => {
      if (index !== this.modules.length - 1) {
        const inkBarWidth = +this.tabview.inkbar.nativeElement.style.width.split('px')[0];
        this.tabview.inkbar.nativeElement.style.width = `${inkBarWidth - 30}px`;
      }
    });
  }

  onContextMenu(module: Module, cm: ContextMenu) {
    this.contextCurrentModuleId = module.moduleId;
  }

  drop(event: any){
    let parentType = '';
    let parentId = '';
    if(event.previousIndex === event.currentIndex){
      return;
    }
    if(this.recipeService.currentRecipe?.activities.length > 0) {
      parentType = NodeType.Activity;
      parentId = this.activity?.activityId as string;
    }
    else {
      parentType = NodeType.Recipe;
      parentId = this.recipeService.currentRecipe.recipeId;
    }
    const newPosition = event.currentIndex;
    const movedObject = this.modules[event.previousIndex];
    moveItemInArray(this.modules, event.previousIndex, event.currentIndex);
    this.recipeNodeReOrderService.changeNodeReOrder(
      NodeType.Module as any,
      movedObject.moduleId,
      parentId,
      parentType as any,
      newPosition,
      movedObject.moduleLabel);
    this.fixupTabView();
    if(this.activity) {
      const index = this.recipeService.currentRecipe.activities.findIndex(
        (a) => a.itemTitle === this.currentActivityTitle
      );
      this.recipeService.currentRecipe.activities[index].dataModules = this.modules;
    }
  }

  checkForPermission(moduleId: string) {
    if (
      RecipeNodeRetitleService.HasPermissionToEditTitle && (!this.recipeHasErrors) &&
      !RecipeNodeRetitleService.NotAllowedWorkflowStates.includes(this.recipeService.currentRecipe?.tracking.state) &&
      this.recipeService.currentRecipe?.tracking.assignedEditors.includes(this.userService.currentUser.puid)) {
      this.retitleEnabled[moduleId] = true;
    } else {
      this.retitleEnabled[moduleId] = false;
    }
  }

  ngOnDestroy(): void {
    this.activeSubscriptions.forEach((s) => s.unsubscribe());
  }

  private buildContextMenuItems(): void {
    this.items = [
      {
        label: $localize`:@reTitle:Retitle`,
        icon: 'icon-pencil',
        disabled: !RecipeNodeRetitleService.HasPermissionToEditTitle || this.recipeHasErrors ||
        RecipeNodeRetitleService.NotAllowedWorkflowStates.includes(
          this.recipeService.currentRecipe?.tracking.state
        ) || !this.recipeService.currentRecipe.tracking.assignedEditors.includes(
          this.userService.currentUser.puid),
        id: `eln-context-module-retitle`,
        command: (_event$) => {
          this.retitleEnabled[this.contextCurrentModuleId as string] = true;
        }
      },
      {
        label: $localize`:@@remove:Remove`,
        icon: 'far fa-trash-alt',
        disabled:
          !RecipeNodeRetitleService.HasPermissionToEditTitle ||
          RecipeNodeRetitleService.NotAllowedWorkflowStates.includes(
            this.recipeService.currentRecipe?.tracking.state
          ) || !this.recipeService.currentRecipe?.tracking.assignedEditors.includes(
            this.userService.currentUser.puid),
        id: `eln-context-module-delete-${this.contextCurrentModuleId}`,
        command: (_event$) => {
          if (this.contextCurrentModuleId) {
            const currentModule = this.modules.find(x => x.moduleId === this.contextCurrentModuleId);
            if (currentModule) {
              currentModule.itemType = NodeType.Module as any;
              this.templateDeleteService.deleteRecipeItem(currentModule, TemplateType.Module,
                (currentModule as AugmentedModule).isRecipeNode);
            }
          }
        },
        visible: true,
      }
    ];
  }
}
