import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ExperimentNotificationService } from './experiment-notification.service';
import { ElnCollaborator } from '../model/eln-collaborator';

@Injectable({
  providedIn: 'root'
})
export class ExperimentCollaboratorsService implements OnDestroy {
  private readonly UserConnectionAddedSubscription?: Subscription;
  private readonly UserConnectionRemovedSubscription?: Subscription;
  private readonly userConnectionSubscription?: Subscription;
  private readonly UserReconnectingSubscription?: Subscription;
  private users: ElnCollaborator[] = new Array<ElnCollaborator>();
  private readonly experimentCollaboratorsSubject = new BehaviorSubject<ElnCollaborator[]>(
    this.users
  );
  private count = 0;
  experimentUsers = this.experimentCollaboratorsSubject.asObservable();

  constructor(private readonly notificationService: ExperimentNotificationService) {
    //Subscribe to get the list of connected users
    this.UserConnectionAddedSubscription = this.notificationService.usersAddedAlert?.subscribe(
      (data) => {
        if (!Array.isArray(data)) data = [data];
        this.users = [...this.users, ...this.buildUserProperties(data)];
        this.experimentCollaboratorsSubject.next(this.users);
      }
    );
    this.UserConnectionRemovedSubscription =
      this.notificationService.UserConnectionRemovedAlert?.subscribe((user) => {
        this.users = this.users.filter((u) => u.connectionId !== user.connectionId);
        this.experimentCollaboratorsSubject.next(this.users);
      });
    this.userConnectionSubscription = this.notificationService.connectionLostAlert?.subscribe(
      (_) => {
        this.users = [];
        this.experimentCollaboratorsSubject.next(this.users);
      }
    );
    this.UserReconnectingSubscription = this.notificationService.reconnectingAlert?.subscribe(
      (_error) => {
        this.users = [];
        this.experimentCollaboratorsSubject.next(this.users);
      }
    );
  }

  public getExperimentCollaborator(connectionId: string) {
    return this.users.find((u) => u.connectionId === connectionId);
  }

  public getExperimentCollaboratorByIndex(index: number) {
    return this.users.filter(
      (u) => u.connectionId !== this.notificationService.hubConnection.connectionId
    )[index];
  }

  private buildUserProperties(data: ElnCollaborator[]) {
    let lastNameInitial: any;
    const newUsers: any[] = [];
    data.forEach((user: any) => {
      if (
        !newUsers.find((u) => u.connectionId === user.connectionId) &&
        !this.users.find((u) => u.connectionId === user.connectionId)
      ) {
        const lastName = user.lastName?.trim().split(' ');
        if (lastName.length > 1) {
          lastNameInitial = lastName.pop()?.charAt(0);
        } else {
          lastNameInitial = user.lastName.trim().charAt(0);
        }
        user.initials = user.firstName?.trim().charAt(0) + lastNameInitial?.trim();
        user.fullName = `${user.firstName.trim()} ${user.lastName.trim()}`;
        user.backgroundColor = this.getBackgroundColor();
        this.count = this.count + 1;
        newUsers.push(user);
      }
    });
    return newUsers;
  }
  private getBackgroundColor() {
    const colors = [
      '#332FD0',
      '#733C3C',
      '#8479E1',
      '#180A0A',
      '#711A75',
      '#F10086',
      '#9254C8',
      '#E15FED',
      '#557B83',
      '#39AEA9',
      '#051367',
      '#4A403A',
      '#A45D5D',
      '#FF5F00',
      '#B20600',
      '#FFCC00',
      '#05595B',
      '#345B63'
    ];
    return colors[this.count % 18];
  }

  ngOnDestroy(): void {
    this.UserConnectionAddedSubscription?.unsubscribe();
    this.UserConnectionRemovedSubscription?.unsubscribe();
    this.userConnectionSubscription?.unsubscribe();
    this.UserReconnectingSubscription?.unsubscribe();
  }
}
