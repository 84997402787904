import { LimsLinkout } from './lims-linkout.interface';

export class ELimsBptLinkoutService implements LimsLinkout {
  getSampleRoute(sampleNumber: string) {
    return `/sampleRegistrationAudit/sampleAuditConfiguration/Sample/${sampleNumber}/`;
  }
  getStudyActivityRoute(studyActivityCode: string) {
    return '/studymanagement/studygroup/study/maintenance';
  }
  getMaterialRoute(materialCode: string) {
    return `/sampleRegistration/incomingParcel/material/maintenance/${materialCode}`;
  }
  getInstrumentColumnRoute(columnCode: string) {
    return `/metrology/maintenance/instrumentColumn/${columnCode}`;
  }
}
