import { Component, OnInit } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { BptGridMode, BptGridRowActionClickEvent, BptGridRowActionConfiguration, BptRowActionElement, ColumnDefinition } from 'bpt-ui-library/bpt-grid';
import { NA } from 'bpt-ui-library/shared';
import { ConfirmationService } from 'primeng/api';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { BehaviorSubject } from 'rxjs';
import { PromptConstants } from '../prompt-constants';

@Component({
  selector: 'app-removed-prompts',
  templateUrl: './removed-prompts.component.html',
  styleUrls: ['./removed-prompts.component.scss']
})
export class RemovedPromptsComponent implements OnInit {
  styleClassProperties: { [key: string]: string } = {
    rejectButtonStyleClass: 'eln-standard-popup-button p-button-outlined',
    acceptButtonStyleClass: 'eln-standard-popup-button',
    icon: 'pi pi-exclamation-triangle'
  };
  gridReadyAfterReload = true;
  subHeading = '';
  accessFlag = false;
  private _removedRows :{ [key: string]: any }[] = [];
  get removedRows() {
    return this._removedRows;
  }
  set removedRows(data : { [key: string]: any }[]) {
    this._removedRows = data.filter(row => row.isPromptRemoved === true);
  }
  columnDefinitions: ColumnDefinition[] = []
  gridMode = BptGridMode.dataView;
   
  get gridActions(): BptGridRowActionConfiguration {
    const rowActions = this.rowActionItems;
    const actionsSubject: BehaviorSubject<BptRowActionElement[]> = new BehaviorSubject(rowActions);
  
    return { actions: actionsSubject };
  }

  get rowActionItems(): BptRowActionElement[] {
    return [
      {
        id: 'bpt-table-restore-row',
        enabled: this.isRowActionEnabled.bind(this),
        styleClass: 'icon-s icon-restore-icon',
        click: this.restorePromptRowConfirmation.bind(this),
        tooltip: $localize`:@@restoreItem:Restore item`
      }
    ];
  }

  constructor(
    private readonly config: DynamicDialogConfig,
    private readonly confirmationService: ConfirmationService
  ) {
  }
  ngOnInit(): void {
    this.columnDefinitions = this.config.data.table.columnDefinitions;
    this.removedRows = this.config.data.table.removedRows;
    this.subHeading = $localize`:@@removedPromptsSubHeading:Please click on the restore icon from the table to restore removed ${PromptConstants.PromptButtonOption[this.config.data.table.promptType]} prompts`;
    this.accessFlag = this.config.data.table.accessFlag;
  }

  public restorePromptRowConfirmation(e: BptGridRowActionClickEvent) {
    this.confirmationService.confirm({
      message: $localize`:@@restorePromptConfirmation:Are you sure you want to restore selected prompt from removed items?`,
      header: $localize`:@@confirmationHeader:Confirmation`,
      closeOnEscape: true,
      dismissableMask: false,
      acceptVisible: true,
      acceptLabel: $localize`:@@confirmationYes:Yes`,
      rejectVisible: true,
      rejectLabel: $localize`:@@confirmationNo:No`,
      ...this.styleClassProperties,
      accept: () => {
        this.config.data.table.restorePromptRowAction(e);
        this.removedRows = this._removedRows;
        this.reloadGridAction(0);
      },
    });
  }

  reloadGridAction(delay = 150) {
    this.gridReadyAfterReload = false;
    setTimeout(() => {
      this.gridReadyAfterReload = true;
    }, delay);
  }

  private readonly isRowActionEnabled = (e: ICellRendererParams) => (this.accessFlag && this.isPromptNative(e.data.promptId)) ?? false;

  private readonly isPromptNative = (promptId: string) => this.removedRows.some(obj => obj.promptId === promptId && obj.source === NA);

}
