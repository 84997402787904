<div class="eln-activity">
  <div class="eln-references-header">
    <span id="eln-ReferencesModuleHeader" i18n="@@references" class="eln-reference-header">References</span>
    <span id="eln-iconHelperTextShow" i18n-pTooltip="@@referencesHelperText"
      class="pi pi-info-circle eln-reference-info"
      pTooltip="Add references to resources external to this activity"
      tooltipPosition="top"></span>
  </div>
  <div *ngIf="!referencesExist" class="eln-references-no-data">
    <div class="eln-references-no-data-img">
      <img src="../../../assets/eln-assets/no-data.svg" alt="" id="eln-no-data-img" class="references-no-data-img" />
      <span class="references-no-data-text">{{ specifyNoData }}</span>
    </div>
  </div>
  <ng-container *ngFor="let instance of [ activity ]; trackBy: trackByActivityId">
    <app-cross-references *ngIf="experiment && activity && (activity.activityReferences.crossReferences.length > 0)"
      [experiment]="experiment"
      [activity]="activity"
    ></app-cross-references>
  </ng-container>
  <bpt-progress-spinner *ngIf="isLoadingDocumentsOrCompendia"
    i18n-message="@@loading"
    message="Loading..."
    [useOverlay]="true"
  ></bpt-progress-spinner>
 <ng-container *ngFor="let instance of [ activity ]; trackBy: trackByActivityIdAndReferences">
    <app-data-table *ngIf="instance?.activityReferences?.documentsTable && activity"
      [table]="instance?.activityReferences?.documentsTable!"
      [parentNodeId]="[activity.activityId,activityReferencesPseudoModuleId]"
    ></app-data-table>
  </ng-container>
  <ng-container *ngFor="let instance of [ activity ]; trackBy: trackByActivityIdAndReferences">
    <app-data-table *ngIf="instance?.activityReferences?.compendiaTable && activity"
      [table]="instance?.activityReferences?.compendiaTable!"
      [parentNodeId]="[activity.activityId,activityReferencesPseudoModuleId]"
    ></app-data-table>
  </ng-container>
</div>