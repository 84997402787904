import { Component, OnDestroy, OnInit } from '@angular/core';
import { User as UserInterface } from 'model/user.interface';
import { RecipeService } from '../services/recipe.service';
import { UserService } from '../../services/user.service';
import { Subscription } from 'rxjs';
import { BaseComponent } from '../../base/base.component';
import { UnsubscribeAll } from '../../shared/rx-js-helpers';
import { ClientStateService } from '../../services/client-state.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { RecipeModel } from '../model/recipe';
import { RecipeState, ReferenceItem } from '../../api/cookbook/models';
import { Activity, Table } from '../../model/experiment.interface';
import { FormResponse } from '../../api/models';
import { elnDecodeSpecialChars } from '../../shared/url-path-serializer';

@Component({
  selector: 'app-recipe-references',
  templateUrl: './recipe-references.component.html',
})
export class RecipeReferencesComponent extends BaseComponent implements OnInit, OnDestroy {
  currentUser: UserInterface;
  private readonly subscriptions: Subscription[] = [];
  recipe!: RecipeModel
  recipeTypeChanged = true;
  documentReadOnly = false;
  compendiumReadOnly = false;

  constructor(
    private readonly userService: UserService,
    private readonly recipeService: RecipeService,
    clientStateService: ClientStateService,
    private readonly route: ActivatedRoute,
    private readonly router: Router
  ) {
    super(clientStateService, route);
    this.currentUser = { ...this.userService.currentUser };
  }

  ngOnInit(): void {
    this.watchForActivityTitleDeterminationThroughRoute(this.route);
    if (this.recipeService.currentRecipe) {
      this.loadRecipeData();
    }
    this.addSubscriptions();
  }

  ngOnDestroy(): void {
    UnsubscribeAll(this.subscriptions);
  }

  private addSubscriptions() {
    this.subscriptions.splice(0, 0,
      this.recipeService.isRecipeUsersChanged.subscribe({
        next: (flag: boolean) => {
          if (flag) {
            this.loadRecipeData();
          }
        }
      }),
      this.recipeService.templateEventService.RecipeRefreshed.subscribe(() => {
        this.router.navigateByUrl(`/recipe/${this.recipe.number}_V${this.recipe.version}/about`);
      }),
      this.recipeService.handleRecipeError.subscribe(value => {
        [this.recipeTypeChanged, this.documentReadOnly, this.compendiumReadOnly] = [!value, value, value];
      })
    );
  }

  get activityReferences(): ReferenceItem[] {
    if (!this.currentActivity) return this.recipeService.currentRecipe.orphan ? this.recipeService.currentRecipe.orphan.references : [];

    // Need to slice the array here to prevent corrupting the original data source, since 'omit' returns a brand new object.
    return this.currentActivity.references;
  }

  get currentActivity() {
    return this.recipeService.currentRecipe.activities.find((activity) => activity.activityId === this.recipeService.currentActivityId) as Activity;
  }

  get currentActivityId() {
    return this.currentActivity?.activityId?.toString();
  }

  private watchForActivityTitleDeterminationThroughRoute(route: ActivatedRoute) {
    this.subscriptions.push(route.params.subscribe((params: Params) => {
      if (params['itemTitle']) {
        const currentActivityTitle = elnDecodeSpecialChars(params['itemTitle']);
        const activity = this.recipeService.currentRecipe.activities.find(
          (activity) => activity.itemTitle === currentActivityTitle
        );
        if (activity) {
          this.recipeService.currentActivityId = activity.activityId;
          this.recipeService.currentActivityTitle = activity.itemTitle;
        }
      }
      this.loadRecipeData();
    }));
  }

  private loadRecipeData(): void {
    this.recipe = this.recipeService.currentRecipe;
  }

  isUserEditorOfTheRecipe(): boolean {
    return (this.recipe && this.recipe.tracking.assignedEditors.includes(this.currentUser.puid)) ?? false;
  }

  isRecipeInDraft = () => (this.recipe && this.recipe.tracking.state === RecipeState.Draft)

  asTable = (item: FormResponse | Table) => item as Table;
}
