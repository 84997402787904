<div class="eln-file-upload">
  <div class="upload-file-content">
    <img src="assets/eln-assets/file-upload-icon.png" class="sources-icon" alt="back-icon">
    <div i18n="@@fileSpecification">Max file size is {{fileConfigOptions.fileSizeForDisplay}}. Supported file types are
      {{fileConfigOptions.fileTypes}}</div>
  </div>
  <bpt-file-upload
    #fileUploader
    [uploadFiles]="uploadSucceeds"
    [deleteFile]="deleteFiles"
    [fileLimit]="fileConfigOptions.fileLimit"
    [maxSize]="fileConfigOptions.maxFileSize"
    [disabled]="fileConfigOptions.disabled"
    [fileTypes]="fileConfigOptions.fileTypes"
    [allowMultipleFiles]="fileConfigOptions.allowMultipleFiles">
  </bpt-file-upload>
  <div class="action-buttons">
    <p-button
      class="align-action-button Cancel"
      styleClass="p-button-outlined eln-standard-popup-button"
      label="Cancel"
      i18n-label="@@cancel"
      (click)="onCancel()"
      id="eln-fileUploadCancelButton"></p-button>

    <p-button
      class="align-action-button Submit"
      styleClass="eln-standard-popup-button"
      label="OK"
      [disabled]="disableOk"
      i18n-label="@@ok"
      (click)="onOk()"
      id="eln-fileUploadOkButton"></p-button>
  </div>
</div>