export class PreparationTableOptions {
   
  public static getDisplayValue(fieldName: string): string {    
    return {      
      preparationNumber: $localize`:@@preparationId:Preparation ID`,
      name: $localize`:@@name:Name`,
      formulaComponents: $localize`:@@formulationOrComponents:Formulation/Components`,
      status: $localize`:@@status:Status`,
      expiration: $localize`:@@expiration:Expiration`,
      expirationDateValue: $localize`:@@expiration:Expiration`,
      storageCondition: $localize`:@@storageCondition:Storage Condition`,
      concentration: $localize`:@@concentration:Concentration`,
      description: $localize`:@@containerDescription:Container Description`,
      disposal: $localize`:@@disposal:Disposal`,
      storageLocation: $localize`:@@storageLocation:Storage Location`,
      stability: $localize`:@@stability:Stability`,
      originalQuantity: $localize`:@@originalQuantity:Original Quantity`,
      hazards: $localize`:@@hazards:Hazards`,
      additionalLabel: $localize`:@@AdditionalLabelText:Additional Label Text`,
      analysis: $localize`:@@analysis:Analysis`,
      subBusinessUnit: $localize`:@@preparationInternalInformationSubBusinessUnit:Sub Business Unit`,
      method: $localize`:@@preparationInternalInformationMethod:Method`,
      client: $localize`:@@preparationInternalInformationClient:Client`,
      project: $localize`:@@preparationInternalInformationProject:Project`,
      preparedBy: $localize`:@@preparationInternalInformationPreparedBy:Prepared By`,
      reviewedBy: $localize`:@@preparationInternalInformationReviewedBy:Reviewed By`,
      storedBy: $localize`:@@preparationInternalInformationStoredBy:Stored By`,
      discardedOrConsumed: $localize`:@@preparationDiscardedCheckbox:Discarded/Consumed`,
      discardedOrConsumedOn: $localize`:@@discardedOrConsumedOn:Discarded/Consumed on`,
      discardedOrConsumedBy: $localize`:@@discardedOrConsumedBy:Discarded/Consumed by`,
    }[fieldName] || fieldName;
  }
}