import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApplicationStateService {
  public applicationState: string = '';
  public applicationStatus = new Subject<ApplicationStatus>();
}
export enum ApplicationStatus {
  saving = 'Saving',
  saved = 'Saved',
  errored = 'Errored'
}
