/* tslint:disable */
/* eslint-disable */
export enum ValueType {
  Invalid = 'invalid',
  String = 'string',
  StringArray = 'stringArray',
  Number = 'number',
  Boolean = 'boolean',
  LocalDate = 'localDate',
  Instant = 'instant',
  Specification = 'specification',
  StringDictionary = 'stringDictionary'
}
