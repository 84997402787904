<app-toast></app-toast>
<div class="eln-layout-wrapper">
  <app-main-menu
    class="eln-recipe-menuItems"
    [menuItems]="menuItems"
    [showElnMenuItem]="true"
    [draggable]="menuItems.length > 2 ? true: false"
    [draggingEnabled]="recipeNodeReOrderService.hasPermission() && recipeNodeReOrderService.hasPermissionWithRespectToFlow() && !recipeHasErrors"
    (RecipeorderedNavItems)="activitiesReOrdered($event)"
  ></app-main-menu>
  <div *ngIf="recipeLoaded" class="eln-layout-content">
    <div class="eln-layout-content-container">
      <div class="eln-layout-content">
        <app-topbar
          [title]="recipe.number"
          [subTitleBelow]="recipeVersionDisplayMessage"
          [visibleInLims]="true"
        ></app-topbar>
        <app-middlebar
          [experiment]="true"
          [enableBreadcrumb]="false"
          [toolbarLeft]="left"
          [toolbarRight]="right"
        ></app-middlebar>

        <ng-container>
          <div *ngIf="messages.length > 0" class="eln-recipe-messages">
            <p-messages [value]="messages" [closable]="!hasTypeChangeErrors && !hasMajorVersionErrorOfReferencedRecipes" class="display:block;"></p-messages>
          </div>
        </ng-container>
        <div class="experiment-content-container">
          <div class="experiment-content">
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
      <div class="eln-layout-footer"></div>
    </div>
  </div>
  <ng-template #left>
    <div class="recipe-topbar-left-button">
      <div class="flex flex-row justify-content-between">
        <bpt-menu-button
          [ngClass]="{'recipe-menu-button-enabled' : enableWorkflowAction, 'recipe-menu-button-disabled' : !enableWorkflowAction || hasTypeChangeErrors, 'eln-secondary-btn' : isWorkflowButtonSecondary}"
          id="eln-workflowStateOptions"
          [model]="workflowActions"
          [label]="WorkflowActionsLabel"
          [icon]="iconHtml"
          [disabled]="!enableWorkflowAction || hasTypeChangeErrors"
          pTooltip="{{ disabledMessage }}"
        >
        </bpt-menu-button>
        <p-button
          styleClass="p-button-text bpt-button-compact font-semibold p-button-link"
          class="eln-recipe-loadTemplateRecipeTitle-btn"
          i18n-label="@@loadTemplateRecipeTitle"
          label="✛ Load Template/Recipe"
          id="eln-recipe-top-btnAddtemplate"
          (onClick)="addTemplateEvent()"
          [disabled]="checkRecipeState()"
          pTooltip="{{ disabledMessage }}"
        ></p-button>
      </div>
    </div>
  </ng-template>
  <ng-template #right>
    <div class="recipe-toolbar-right">
      <div class="flex flex-row justify-content-between">
        <p-button
          pTooltip="{{ startNewVersionBtnTooltip }}"
          tooltipPosition="bottom"
          styleClass="eln-toolbar-button p-button-link bpt-button-compact font-semibold"
          class="recipe-toolbar-right-versionBtn"
          i18n-label="@@startNewVersion"
          label="Start new version"
          id="eln-recipe-btnStartNewRecipe"
          [disabled]="hasTypeChangeErrors || !allowSaveNewVersion"
          (onClick)="saveAsNewRecipe()"
        >
        </p-button>
        <p-button
          pTooltip="{{ saveNewRecipeBtnTooltip }}"
          tooltipPosition="bottom"
          styleClass="eln-toolbar-button p-button-link bpt-button-compact font-semibold"
          class="recipe-toolbar-right-saveBtn"
          i18n-label="@@SaveNewRecipe"
          label="Save as new recipe"
          id="eln-recipe-btnSaveNewRecipe"
          (onClick)="copyRecipe()"
          [loading]="isLoading"
          *ngIf="enableNewRecipeSave"
          [disabled]="hasTypeChangeErrors"
        >
        </p-button>
        <span class="pr-2 my-0" style="color: var(--bpt-top-bar-labsite); height: 18px;"> | </span>
        <div class="recipe-toolbar-right-saveBtn">
        <app-recipe-notification [enableNotification]="true"></app-recipe-notification>
      </div>
      </div>
    </div>
  </ng-template>
</div>
<div *ngIf="(!sbuLoaded && !recipeLoaded) || recipeService.isLoading.getValue()" class="eln-layout-content">
  <bpt-progress-spinner [useOverlay]="true" [message]="loadingMessage"></bpt-progress-spinner>
</div>

<app-custom-dialog
  class="recipe-draft-version-availability-modal"
  *ngIf="
    (showWarningGridForMinorDraftVersions && !collabratorShowWarningGridForMinorDraftVersions)
    ||
    (!showWarningGridForMinorDraftVersions && collabratorShowWarningGridForMinorDraftVersions)"
  [header]="header"
  [config]="dialogConfig"
  [modal]="true"
  [headerIcon]="'icon-alarm_bell icon-m'"
  [subHeader]="subHeader"
  [showModal]="true"
  [buttons]="modalButtons"
  [closable]="true"
  [draggable]="false"
  >
  <bpt-grid #grid
    class="eln-grid"
    [dataSource]="refrencedRecipeNewDraftVersionGridData"
    [useJsJodaTypes]="true"
    [columnDefinitions]="definitions"
    [gridMode]="gridMode"
    [includeRowNumberColumn]="false"
    [allowRowAdd]="false"
    [showAutoSizeButton]="false"
    [showFilterToggleButton]="false"
    [showFilterToggleButton]="false"
    [suppressContextMenu]="true"
    [showGridOptionsButton]="false"
    >
  </bpt-grid>
</app-custom-dialog>

<app-recipe-and-template-loader
  *ngIf="showTemplateLoader"
  [loaderSearchCriteria]="loaderSearchCriteria"
  (templateLoaderResult)="updateTemplateSelection($event)"
  [templateLoaderType]="templateLoaderType"
  [insertOptions]="recipeService.insertOptions"
  [insertLocationOptions]="recipeService.insertLocationOptions"
  [loadConfirmationEvent]="isConfirmationRequired"
  [searchControls]="searchControls"
  [confirmationMessage]="confirmationMessage"
  sliderSize="x-large"
  [showInlineFilters]="true"
  [toolTip]="toolTip"
  i18n-headerTitle="@@sliderHeader"
  headerTitle="Search Template/Recipe"
  [locationRequired]="false"
  (templateLoaderInsertOptionChangedEvent)="templateLoaderInsertOptionChanged($event)"
  (templateLoaderFilterClearedEvent)="templateLoaderFilterCleared($event)"
  (templateLoaderFilterChangedEvent)="templateLoaderFilterChanged($event)"
  (confirmationDialogEvent)="confirmationFunction($event[0], $event[1], $event[2])"
  (templateSelectionChangedEvent)="templateSelectionChanged($event)"
  (templatesLoadEvent)="applySelectedTemplate($event)"
>
</app-recipe-and-template-loader>
<ng-container *ngFor="let context of [ specificationEditorContext ]; trackBy: specificationEditorContextId">
  <!--
    ngFor and trackBy get us a new (single, different) app-specification-input upon a new context;
    and removes any app-specification-input upon no context.
  -->
  <app-specification-input *ngIf="context"
    [inputModel]="context.value"
    [readOnly]="context.readOnly"
    [disabled]="context.disabled"
    [allowedUnits]="context.allowedUnits"
    [allowedSpecTypes]="context.allowedSpecTypes"
    [preloadScalingOptions]="context.preloadScalingOptions"
    [defaultUnit]="context.defaultUnit"
    (valueChanged)="specificationValueChanged($event)"
    (closed)="closeSpecificationInputSlider()"
    (preloadScalingOptionsChanged)="specificationPreLoadScalingOptionChanged($event)"
  ></app-specification-input>
</ng-container>