/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { templatesApplyChangesToCachePost$Json } from '../fn/template/templates-apply-changes-to-cache-post-json';
import { TemplatesApplyChangesToCachePost$Json$Params } from '../fn/template/templates-apply-changes-to-cache-post-json';
import { templatesApplyChangesToCachePost$Plain } from '../fn/template/templates-apply-changes-to-cache-post-plain';
import { TemplatesApplyChangesToCachePost$Plain$Params } from '../fn/template/templates-apply-changes-to-cache-post-plain';
import { templatesSearchForLoadGet$Json } from '../fn/template/templates-search-for-load-get-json';
import { TemplatesSearchForLoadGet$Json$Params } from '../fn/template/templates-search-for-load-get-json';
import { templatesSearchForLoadGet$Plain } from '../fn/template/templates-search-for-load-get-plain';
import { TemplatesSearchForLoadGet$Plain$Params } from '../fn/template/templates-search-for-load-get-plain';
import { templatesTemplatesListGet$Json } from '../fn/template/templates-templates-list-get-json';
import { TemplatesTemplatesListGet$Json$Params } from '../fn/template/templates-templates-list-get-json';
import { templatesTemplatesListGet$Plain } from '../fn/template/templates-templates-list-get-plain';
import { TemplatesTemplatesListGet$Plain$Params } from '../fn/template/templates-templates-list-get-plain';
import { TemplateSummary } from '../models/template-summary';
import { TemplateSummaryForLoad } from '../models/template-summary-for-load';

@Injectable({ providedIn: 'root' })
export class TemplateService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `templatesTemplatesListGet()` */
  static readonly TemplatesTemplatesListGetPath = '/templates/templates-list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesTemplatesListGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  templatesTemplatesListGet$Plain$Response(params?: TemplatesTemplatesListGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TemplateSummary>>> {
    return templatesTemplatesListGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `templatesTemplatesListGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  templatesTemplatesListGet$Plain(params?: TemplatesTemplatesListGet$Plain$Params, context?: HttpContext): Observable<Array<TemplateSummary>> {
    return this.templatesTemplatesListGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TemplateSummary>>): Array<TemplateSummary> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesTemplatesListGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  templatesTemplatesListGet$Json$Response(params?: TemplatesTemplatesListGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TemplateSummary>>> {
    return templatesTemplatesListGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `templatesTemplatesListGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  templatesTemplatesListGet$Json(params?: TemplatesTemplatesListGet$Json$Params, context?: HttpContext): Observable<Array<TemplateSummary>> {
    return this.templatesTemplatesListGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TemplateSummary>>): Array<TemplateSummary> => r.body)
    );
  }

  /** Path part for operation `templatesSearchForLoadGet()` */
  static readonly TemplatesSearchForLoadGetPath = '/templates/search-for-load';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesSearchForLoadGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  templatesSearchForLoadGet$Plain$Response(params?: TemplatesSearchForLoadGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TemplateSummaryForLoad>>> {
    return templatesSearchForLoadGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `templatesSearchForLoadGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  templatesSearchForLoadGet$Plain(params?: TemplatesSearchForLoadGet$Plain$Params, context?: HttpContext): Observable<Array<TemplateSummaryForLoad>> {
    return this.templatesSearchForLoadGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TemplateSummaryForLoad>>): Array<TemplateSummaryForLoad> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesSearchForLoadGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  templatesSearchForLoadGet$Json$Response(params?: TemplatesSearchForLoadGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TemplateSummaryForLoad>>> {
    return templatesSearchForLoadGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `templatesSearchForLoadGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  templatesSearchForLoadGet$Json(params?: TemplatesSearchForLoadGet$Json$Params, context?: HttpContext): Observable<Array<TemplateSummaryForLoad>> {
    return this.templatesSearchForLoadGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TemplateSummaryForLoad>>): Array<TemplateSummaryForLoad> => r.body)
    );
  }

  /** Path part for operation `templatesApplyChangesToCachePost()` */
  static readonly TemplatesApplyChangesToCachePostPath = '/templates/apply-changes-to-cache';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesApplyChangesToCachePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesApplyChangesToCachePost$Plain$Response(params?: TemplatesApplyChangesToCachePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return templatesApplyChangesToCachePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `templatesApplyChangesToCachePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesApplyChangesToCachePost$Plain(params?: TemplatesApplyChangesToCachePost$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.templatesApplyChangesToCachePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesApplyChangesToCachePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesApplyChangesToCachePost$Json$Response(params?: TemplatesApplyChangesToCachePost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return templatesApplyChangesToCachePost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `templatesApplyChangesToCachePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesApplyChangesToCachePost$Json(params?: TemplatesApplyChangesToCachePost$Json$Params, context?: HttpContext): Observable<boolean> {
    return this.templatesApplyChangesToCachePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

}
