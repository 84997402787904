import { Injectable } from '@angular/core';
import {
  SpecificationValue,
  Table,
  TableValueRow
} from 'model/experiment.interface';
import {
  BptGridComponent,
  ColumnDefinition,
  ColumnType,
  GridContextMenuItem,
} from 'bpt-ui-library/bpt-grid';
import {
  Cell,
  ChangeCellCommand,
  ValueType
} from '../api/data-entry/models';
import {
  ValueState
} from '../api/models';
import { cloneDeep, isEmpty, min } from 'lodash-es';
import {
  EditableCallbackParams,
  RowNode,
  CellRange,
  Column,
  ColumnApi
} from 'ag-grid-community';
import { NA, Quantity } from 'bpt-ui-library/shared';
import { ChangeRecipeCellCommand } from '../api/cookbook/models';
import { Subject } from 'rxjs';
import { TableDataService } from '../experiment/data/table/table-data.service';

export type NaGridTableIdentifierData = {
  isCellEditable : (_params: EditableCallbackParams) => boolean,
  getCell :(_propertyName: string, _newValue: any) => Cell,
  postChangeCellCommand : (_preparedNaCommand: ChangeCellCommand | ChangeRecipeCellCommand, _eventSource: string | undefined, _oldCellValue?: Array<TableValueRow>) => void,
  grid: BptGridComponent,
  table: Table,
  containerId: string,
  columnDefinitions: ColumnDefinition[],
  activityId: string,
  isDisabled?: boolean
}

export type NaIdentifier = {
  id: string;
  isEmpty: boolean;
};

@Injectable({
  providedIn: 'root'
})
export class FillWithNaGridHelper {
  readonly iconNotApplicable = '<i class="ag-icon ag-custom-icon icon-not-applicable"></i>';
  readonly fillWithNATriggeredForModule = new Subject<string>();
  readonly forceFillWithNATriggeredForModule = new Subject<string>();
  readonly fillWithNAOnTable = new Subject<NaIdentifier>();
  readonly fillWithNAOnForm = new Subject<NaIdentifier>();
  private _currentModuleId: string | undefined;

  public get currentModuleId(): string | undefined {
    return this._currentModuleId;
  }

  public set currentModuleId(moduleId: string | undefined) {
    this._currentModuleId = moduleId;
  }


  getContextMenuOptionsOfFillWithNa(
    args : NaGridTableIdentifierData
  ): GridContextMenuItem | undefined {
    if (args.grid?.gridApi) {
      const cells = args.grid.gridApi.getCellRanges();
      if (!isEmpty(cells) && cells && cells[0] && cells[0].startRow && cells[0].columns) {
        if (cells[0].columns.some((column: Column) => column.getColId() === 'rowIndex'))
          return this.naRowOptions(cells, args);
        else if (
          cells[0].startRow?.rowIndex ===
          (cells[0].endRow?.rowIndex ?? cells[0].startRow?.rowIndex) &&
          cells[0].columns?.length === 1
        )
          return this.naSingleCellOptions(cells, args);
        else if (
          cells[0].columns?.length > 1 ||
          cells[0].startRow?.rowIndex !== cells[0].endRow?.rowIndex
        )
          return this.naMultipleCellsOptions(cells, args);
      }
    }

    return {
      label: '',
      action: () => { args.grid.suppressContextMenu = true; },
      disabled: args.isDisabled
    };
  }

  private naMultipleCellsOptions(cells: CellRange[], args: NaGridTableIdentifierData): GridContextMenuItem {
    return {
      label: labelsByNaOptionType.fillWithNa.label,
      subitems: [
        {
          label: labelsByNaOptionType.naEmptyCells.label,
          action: () => this.beforeNaAction(cells[0], args, true),
          disabled: args.isDisabled
        },
        {
          label: labelsByNaOptionType.naAllCells.label,
          action: () => this.beforeNaAction(cells[0],args),
          disabled: args.isDisabled
        }
      ],
      icon: this.iconNotApplicable,
      disabled: args.isDisabled
    };
  }

  public updateNAForGrid(cells:CellRange, args: NaGridTableIdentifierData, emptyCells = false) {
    this.beforeNaAction(cells, args, emptyCells);
  }

  private naSingleCellOptions(cells: CellRange[], args: NaGridTableIdentifierData): GridContextMenuItem {
    return {
      label: labelsByNaOptionType.fillWithNa.label,
      action: () => this.beforeNaAction(cells[0],args),
      disabled: args.isDisabled,
      icon: this.iconNotApplicable
    };
  }

  private naRowOptions(cells: CellRange[], args: NaGridTableIdentifierData): GridContextMenuItem  | undefined {
    // Fill with N/A cannot be used on placeholder rows so require some non-placeholder rows.
    const minRowIndex = min(cells.map((cell) => cell.startRow?.rowIndex));
    const maxRowIndex = min(cells.map((cell) => cell.endRow?.rowIndex));
    if (minRowIndex !== undefined && maxRowIndex !== undefined) {
      const rowCount = maxRowIndex - minRowIndex + 1;
      const rowIndexSpan =  Array.from({ length: rowCount }, (_,k) => minRowIndex + k);
      const selectedGridRows = rowIndexSpan.map((index) => args.grid.gridApi.getDisplayedRowAtIndex(index));
      const rows = selectedGridRows.map((row) => args.table.value.find((r) => r.id === row?.data.id)).filter((r) => r && !TableDataService.rowIsPlaceholder(r));
      if (rows.length < 1) return undefined;
    }
    return {
      label: labelsByNaOptionType.naRows.label,
      subitems: [
        {
          label: labelsByNaOptionType.naEmptyCells.label,
          action: () => this.naActionOnRows(cells[0], args, true),
          disabled: args.isDisabled
        },
        {
          label: labelsByNaOptionType.naAllCells.label,
          action: () => this.naActionOnRows(cells[0], args),
          disabled: args.isDisabled
        }
      ],
      icon: this.iconNotApplicable,
      disabled: args.isDisabled
    };
  }

  private naActionOnRows(cells: CellRange, args: NaGridTableIdentifierData, naOnlyEmptyCells = false) {
    if (!cells.startRow) return;
    if (!cells.endRow) {
      cells.endRow = cells.startRow;
    }
    const startRowIndex = Math.min(cells.startRow.rowIndex, cells.endRow.rowIndex);
    const endRowIndex = Math.max(cells.startRow.rowIndex, cells.endRow.rowIndex);
    const allColumns = args.grid.gridApi.getAllGridColumns();
    const rowCellsRange: CellRange = {
      columns: allColumns,
      startColumn: allColumns[0],
      startRow: cells.startRow.rowIndex === startRowIndex ? cells.startRow : cells.endRow,
      endRow: cells.endRow.rowIndex === endRowIndex ? cells.endRow : cells.startRow
    };
    this.beforeNaAction(rowCellsRange, args, naOnlyEmptyCells);
  }

 private beforeNaAction(cells: CellRange, args: NaGridTableIdentifierData, naOnlyEmptyCells = false) {
    args.grid.suppressContextMenu = true;
    const naActionResultCellChangedValues: ChangeCellCommand = {
      columnValues: [],
      rowIds: [],
      tableIds: [args.table.tableId],
      experimentId: args.containerId,
      activityId: args.activityId
    };
    const rowNodes: RowNode[] = [];
    const columnValues: { [key: string]: Array<Cell | boolean> } = {};
    const startRowIndex = cells.startRow?.rowIndex as number;
    const endRowIndex = cells.endRow?.rowIndex ?? startRowIndex;
    for (
      let rowIndex = Math.min(startRowIndex, endRowIndex);
      rowIndex <= Math.max(startRowIndex, endRowIndex);
      rowIndex++
    ) {
      rowNodes.push(args.grid.gridApi.getDisplayedRowAtIndex(rowIndex) as RowNode);
      const rowId = rowNodes[rowNodes.length - 1].id as string;
      naActionResultCellChangedValues.rowIds.push(rowId);
      columnValues[rowId] = [];
      cells.columns.forEach((column) => {
        const colId = column.getColId();
        const targetColumnIndex = args.columnDefinitions.findIndex(
          (colDef) => colDef.field === colId
        );
        if (
          rowIndex !== undefined &&
          targetColumnIndex !== -1 &&
          !['rowIndex', 'id', 'rowIndex_1'].includes(colId)
        ) {
          columnValues[rowId].push(
            this.validateCellForNaAction(
              args.columnDefinitions[targetColumnIndex].columnType as string,
              column,
              rowNodes[rowNodes.length - 1],
              args,
              naOnlyEmptyCells
            )
          );
        }
      });
    }
    this.naAction(columnValues, naActionResultCellChangedValues, rowNodes, args);
  }

  private validateCellForNaAction(
    columnType: string,
    column: Column,
    rowNode: RowNode,
    args: NaGridTableIdentifierData,
    naOnlyEmptyCells = false
  ): Cell | boolean {
    const rowData = args.table.value.find((data) => data.id === rowNode?.data.id);
    const colId = column.getColId();
    const colDef = args.columnDefinitions.find((c) => c.field === colId) as ColumnDefinition;
    if (!rowNode || !rowData || !colDef.field) return false;
    if ([TableDataService.repeatForField, TableDataService.repeatWithField].includes(colDef.field)) return false; // N/A makes no sense for these (hidden) columns.
    if (!this.acceptedColumnTypesForFillWithNA(columnType)) return false;

    args.grid.gridApi?.stopEditing(true);
    const params: EditableCallbackParams = {
      node: rowNode,
      data: rowData,
      column: column,
      colDef: column.getColDef(),
      api: args.grid.gridApi,
      columnApi: new ColumnApi(args.grid.gridApi),
      context: args.grid.gridOptions.context
    };
    const canEditCell = args.isCellEditable(params);
    const alreadyNA = rowData[colId]?.value.state === ValueState.NotApplicable;
    const naOnlyEmptyCellsCheck = naOnlyEmptyCells
      ? rowData[colId] && rowData[colId].value.state !== ValueState.Empty
      : false;
    const isSpecificationOrAllowNa =
      colDef.allowNA !== false || columnType === ColumnType.specification;
    if (!canEditCell || alreadyNA || naOnlyEmptyCellsCheck || !isSpecificationOrAllowNa) {
      return false;
    }
    return args.getCell(colId, this.getNA(colDef));
  }

  private naAction(
    columnValues: { [key: string]: (boolean | Cell)[] },
    preparedNaCommand: ChangeCellCommand,
    rowNodes: RowNode[],
    args: NaGridTableIdentifierData
  ) {
    let rowIds = Object.keys(columnValues);
    while (rowIds.length > 0) {
      const result = rowIds
        .filter((rowId) => {
          return JSON.stringify(columnValues[rowId]) === JSON.stringify(columnValues[rowIds[0]])
            ? rowId
            : '';
        })
        .filter((str) => str !== '');
      preparedNaCommand.rowIds = result;
      preparedNaCommand.columnValues = columnValues[result[0]]
        .filter((colVal) => colVal.valueOf() !== false)
        .map((val) => val as Cell);
      if (preparedNaCommand.columnValues.length > 0) {
        const oldRow = cloneDeep(args.table.value.filter((val) => result.includes(val.id)));
        rowNodes
          .filter((node) => result.includes(node.id as string))
          .forEach((node) => {
            preparedNaCommand.columnValues.forEach((cell) => {
              node.setDataValue(cell.propertyName, this.handleStringArrayNA(cell), 'sourceNA');
            });
          });
        args.postChangeCellCommand(preparedNaCommand, undefined, oldRow);
      }
      rowIds = rowIds.filter((rowId) => !result.includes(rowId));
    }
  }

  private readonly handleStringArrayNA = (cell: Cell) => cell.propertyValue.type === ValueType.StringArray && cell.propertyValue.state === ValueState.NotApplicable ? [NA] : NA;

  private getNA(colDef: ColumnDefinition) {
    switch (colDef.columnType) {
      case ColumnType.editableList:
      case ColumnType.list:
        return colDef.allowMultiSelect ? [NA] : NA;
      case ColumnType.quantity:
        return { type: ValueType.Number, state: ValueState.NotApplicable } as Quantity;
      case ColumnType.specification:
        return {
          type: ValueType.Specification,
          state: ValueState.NotApplicable
        } as SpecificationValue;
      default:
        return NA;
    }
  }

  private acceptedColumnTypesForFillWithNA(columnType: string) {
    switch (columnType) {
      case ColumnType.autoIncrement:
      case ColumnType.boolean:
      case ColumnType.index:
      case ColumnType.rowId:
        return false;
      default:
        return true;
    }
  }
}

export const labelsByNaOptionType: {
  [optionType: string]: { label: string };
} = {
  fillWithNa: {
    label: $localize`:@@fillWithNa:Fill with N/A`
  },
  naEmptyCells: {
    label: $localize`:@@naEmptyCells:N/A Empty Cells`
  },
  naAllCells: {
    label: $localize`:@@naAllCells:N/A All Cells`
  },
  naRows: {
    label: $localize`:@@naRows:N/A Rows`
  }
};
