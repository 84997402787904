import { ColumnDefinition } from 'bpt-ui-library/bpt-grid';
import { ColumnType } from '../../../api/models';
import { FormattedDateRendererComponent } from '../shared/formatted-date-renderer';
import { LinkOutCellRendererComponent } from './linkout-cell-renderer';
import { ITooltipParams } from 'ag-grid-community';
import { formatLocalDate } from '../../../shared/date-time-helpers';

export class SampleTableGridOptions {
  private static readonly aggFunc = 'first';
  private static readonly cellStyleClass = 'grid-cell-align';
  private static readonly maxIsoDateForRefresh = '9999-12-31'
  public static isReadOnly = true;
  public static prepareColumns(
    openTestModelCallback?: (row: any) => void,
  ): ColumnDefinition[] {
    return [
      {
        label: $localize`:@@preparationRowId:Row ID`,
        field: 'id',
        minWidth: 270,
        width: 'auto',
        columnType: ColumnType.RowId,
        hidden: true,
        showInColumnChooser: true,
        editable: false,
        aggFunc: this.aggFunc
      },
      {
        label: $localize`:@@Index:Index`,
        field: 'rowIndex',
        columnType: ColumnType.Index,
        hidden: true,
        showInColumnChooser: false,
        editable: false
      },
      {
        field: 'aliquotNumber',
        label: $localize`:@@AliquotNumber:Aliquot Number`,
        sortable: true,
        width: 'auto',
        minWidth: 270,
        editable: false,
        allowAutoResize: true,
        cellClass: this.cellStyleClass,
        showInColumnChooser: false,
        cellRenderer: LinkOutCellRendererComponent,
        cellRendererParams: {
          suppressCount: true,
          suppressDoubleClickExpand: true
        },
        filterParams: {
          filterOptions: ['contains'],
          suppressAndOrCondition: true
        }
      },
      {
        field: 'testReportableName',
        columnType: ColumnType.String,
        label: $localize`:@@TestReportableName:Test Reportable Name`,
        maxWidth: 320,
        width: 320,
        allowAutoResize: true,
        editable: false,
        onCellClicked: openTestModelCallback?.bind(this),
        filterParams: {
          filterOptions: ['contains'],
          suppressAndOrCondition: true
        },
        showInColumnChooser: false,
        tooltipValueGetter: (params: ITooltipParams) => {
          return params.data[params.column?.getUniqueId() as string];
        },
      },
      {
        field: 'accountCode',
        aggFunc: this.aggFunc,
        label: $localize`:@@AccountCode:Account Code`,
        editable: false,
        minWidth: 180,
        width: 180,
        allowAutoResize: true,
        sortable: true,
        columnType: ColumnType.String,
        cellClass: this.cellStyleClass,
        filterParams: {
          filterOptions: ['contains'],
          suppressAndOrCondition: true
        },
        showInColumnChooser: false
      },
      {
        field: 'projectCode',
        aggFunc: this.aggFunc,
        label: $localize`:@@ProjectCode:Project (CCF) Code`,
        allowAutoResize: true,
        editable: false,
        sortable: true,
        minWidth: 180,
        width: 180,
        columnType: ColumnType.String,
        cellClass: this.cellStyleClass,
        filterParams: {
          filterOptions: ['contains'],
          suppressAndOrCondition: true
        },
        showInColumnChooser: false
      },
      {
        field: 'testName',
        aggFunc: this.aggFunc,
        label: $localize`:@@TestName:Test Name`,
        width: 110,
        sortable: true,
        editable: false,
        columnType: 'string',
        hidden: true,
        tooltipValueGetter: (params: ITooltipParams) => {
          return params.data[params.column?.getUniqueId() as string];
        }
      },
      {
        field: 'testCode',
        aggFunc: this.aggFunc,
        label: $localize`:@@TestCode:Test Code`,
        width: 110,
        editable: false,
        columnType: 'string',
        hidden: true,
        tooltipValueGetter: (params: ITooltipParams) => {
          return params.data[params.column?.getUniqueId() as string];
        }
      },
      {
        field: 'testKey',
        aggFunc: this.aggFunc,
        label: $localize`:@@TestKey:Test Key`,
        width: 180,
        hidden: true,
        editable: false,
        columnType: 'string',
        tooltipValueGetter: (params: ITooltipParams) => {
          return params.data[params.column?.getUniqueId() as string];
        }
      },
      {
        field: 'testReportableMethodReference',
        aggFunc: this.aggFunc,
        label: $localize`:@@TestReportableMethodReference:Reportable Method Reference`,
        minWidth: 250,
        width: 250,
        sortable: true,
        columnType: 'string',
        editable: false,
        hidden: true,
        tooltipValueGetter: (params: ITooltipParams) => {
          return params.data[params.column?.getUniqueId() as string];
        }
      },
      {
        field: 'accountName',
        aggFunc: this.aggFunc,
        label: $localize`:@@AccountName:Account Name`,
        minWidth: 180,
        width: 180,
        sortable: true,
        columnType: 'string',
        editable: false,
        hidden: true
      },
      {
        field: 'projectName',
        aggFunc: this.aggFunc,
        label: $localize`:@@projectCapName:Project (CAP) Name`,
        minWidth: 180,
        width: 180,
        sortable: true,
        columnType: 'string',
        editable: false,
        hidden: true
      },
      {
        field: 'lotNumber',
        aggFunc: this.aggFunc,
        label: $localize`:@@LotNumber:Lot#`,
        minWidth: 110,
        width: 110,
        sortable: true,
        columnType: 'string',
        editable: false,
        hidden: true
      },
      {
        field: 'collectedOn',
        aggFunc: this.aggFunc,
        label: $localize`:@@CollectedOn:Collected On`,
        minWidth: 180,
        width: 180,
        sortable: true,
        editable: false,
        hidden: true,
        columnType: 'string',
        valueGetter: (params: any) => {
          if (!params.data.collectedOn || params.data.collectedOn === this.maxIsoDateForRefresh) return '';
          return formatLocalDate(params.data.collectedOn);
        },
        cellRenderer: FormattedDateRendererComponent,
      },
      {
        field: 'sampleMatrixName',
        aggFunc: this.aggFunc,
        label: $localize`:@@SampleMatrix:Sample Matrix`,
        minWidth: 180,
        width: 180,
        sortable: true,
        columnType: 'string',
        editable: false,
        hidden: true
      },
      {
        field: 'labelClaim',
        aggFunc: this.aggFunc,
        label: $localize`:@@LabelClaim:Label Claim`,
        minWidth: 180,
        width: 180,
        sortable: true,
        columnType: 'string',
        editable: false,
        hidden: true
      },
      {
        field: 'stabilityTimepoint',
        aggFunc: this.aggFunc,
        label: $localize`:@@StabilityTimepoint:Stability Timepoint`,
        minWidth: 200,
        width: 200,
        sortable: true,
        columnType: 'string',
        editable: false,
        hidden: true
      },
      {
        field: 'stabilityStorageCondition',
        aggFunc: this.aggFunc,
        label: $localize`:@@StabilityStorageCondition:Stability Storage Condition`,
        minWidth: 200,
        width: 200,
        sortable: true,
        columnType: 'string',
        editable: false,
        hidden: true
      },
      {
        field: 'stabilityStorageConfiguration',
        aggFunc: this.aggFunc,
        label: $localize`:@@StabilityStorageConfiguration:Stability Storage Configuration`,
        minWidth: 240,
        width: 240,
        sortable: true,
        columnType: 'string',
        editable: false,
        hidden: true,
        lockVisible: false
      },
      {
        field: 'description',
        aggFunc: this.aggFunc,
        label: $localize`:@@Description:Description`,
        minWidth: 180,
        width: 180,
        sortable: true,
        allowMultiLine: true,
        columnType: 'string',
        editable: false,
        hidden: true,
        allowAutoResize: true
      },
      {
        field: 'AdditionalInformation',
        aggFunc: this.aggFunc,
        label: $localize`:@@additionalInfo:Additional Information`,
        minWidth: 180,
        width: 180,
        sortable: true,
        allowMultiLine: true,
        editable: function (params) {
          const rowId = params.data.aliquotNumber ?? '';
          const columnName = params.colDef.field;
          const cellLocked = params.context?.inputLocks && params.context?.inputLocks[rowId + columnName];
          if (params.data.aliquotNumber && !SampleTableGridOptions.isReadOnly) return !cellLocked;

          return false;
        },
        columnType: 'string',
        hidden: true,
        allowAutoResize: true
      }
    ];
  }
}
