<div
  id="eln-preparations-removed-grid-container"
  class="eln-preparations-data eln-table-theme stretch">

  <div class="flex flex-row eln-subHeading">
    {{ subHeading }}
  </div>
  <div class="eln-tableTitle">{{ titleOfTable }}</div>
  <bpt-grid *ngIf="reloadGrid"
    #preparationsRemovedGrid
    class="eln-grid hide-readonly-icons"
    ngClass="true"
    [dataSource]="removedPreparationData"
    [columnDefinitions]="columnDefinitions"
    [gridMode]="gridMode"
    [allowRowAdd]="false"
    [paginated]="true"
    [showFilterToggleButton]="true"
    [showGridOptionsButton]="false"
    [paginated]="false"
    [contextMenu]="getContextMenu()"
    [rowActionConfigurations]="gridActions">
  </bpt-grid>
</div>