import { ColumnDefinition } from "bpt-ui-library/bpt-grid";
import { ColumnType } from "../api/models";
import { NA } from "bpt-ui-library/shared";
import { EditableCallbackParams } from "ag-grid-community";

export class PromptConstants {
  public static readonly PromptButtonOption: { [key: string]: string } = {
    Materials: $localize`:@@PromptButtonOptionMaterials:Materials`,
    Instruments: $localize`:@@PromptButtonOptionInstruments:Instruments`,
    Columns: $localize`:@@PromptButtonOptionColumns:Columns`,
    ConsumablesAndSupplies: $localize`:@@PromptButtonOptionConsumablesAndSupplies:Consumables & supplies`,
    Preparations: $localize`:@@PromptButtonOptionPreparations:Preparations`,
    materials: $localize`:@@PromptButtonOptionMaterialsSmallCase:materials`,
    instruments: $localize`:@@PromptButtonOptionInstrumentsSmallCase:instruments`,
    columns: $localize`:@@PromptButtonOptionColumnsSmallCase:columns`,
    consumablesAndSupplies: $localize`:@@PromptButtonOptionConsumablesAndSuppliesSmallCase:consumables & supplies`,
    preparations: $localize`:@@PromptButtonOptionPreparationsSmallCase:preparations`,
  }
  public static readonly NoPromptsAvailableYet = $localize`:@@NoPromptsAvailableYet:NO PROMPTS AVAILABLE YET`;
  public static readonly PromptsWillAppearHere = $localize`:@@PromptsWillAppearHere: Prompts will appear here`;
  public static readonly AddPromptButtonLabel = $localize`:@@AddPromptButtonLabel:✛ Add Prompt`;
  public static readonly PromptModuleHeader = $localize`:@@promptModuleHeader:Prompts`;
  public static readonly fieldRequired = $localize`:@@fieldIsRequired:This field is required`;
  public static readonly deletePromptConfirmation = $localize`:@@deletePromptConfirmation:Are you sure that you want to remove the selected prompt?`;
  public static readonly confirmationHeader = $localize`:@@confirmationHeader:Confirmation`

  public static readonly sliderLabelManager: { [key: string]: string } = {
    materialType: $localize`:@@promptLabelMaterialType:Material type`,
    brand: $localize`:@@promptLabelBrand:Brand`,
    grade: $localize`:@@promptLabelGrade:Grade`,
    purity: $localize`:@@promptLabelPurity:Purity`,
    concentration: $localize`:@@promptLabelConcentration:Concentration`,
    lot: $localize`:@@promptLabelLot:Lot #`,
    catalog: $localize`:@@promptLabelCatalog:Catalog #`,
    additionalDetails: $localize`:@@promptLabelAdditionalDetails:Additional details`,
    manufacturer: $localize`:@@promptLabelManufacturer:Manufacturer`,
    model: $localize`:@@promptLabelModel:Model`,
    chromatographyType: $localize`:@@promptLabelChromatographyType:Chromatography type`,
    dimensions: $localize`:@@promptLabelDimensions:Dimensions`,
    particleSizeOrFilmThickness: $localize`:@@promptLabelParticleSizeOrFilmThickness:Particle size/film thickness`,
    packingPhase: $localize`:@@promptLabelPackingPhase:Packing phase`,
    serial: $localize`:@@promptLabelSerial:Serial #`,
    storageCondition: $localize`:@@promptLabelStorageCondition:Storage condition`,
    containerDescription: $localize`:@@promptLabelContainerDescription:Container description`,
    client: $localize`:@@promptLabelClient:Client`,
    project: $localize`:@@promptLabelProject:Project`,
    analysis: $localize`:@@promptLabelAnalysis:Analysis`,
    method: $localize`:@@promptLabelMethod:Method`,
    compendia: $localize`:@@promptLabelCompendia:Compendia`,
    details: $localize`:@@promptLabelDetails:Details`,
    part: $localize`:@@promptLabelPart:Part #`,
    nameOrPacking: $localize`:@@promptLabelNameOrPacking:Name/packing`,
    clientFacingPrompt: $localize`:@@clientFacingPrompt:Client Facing Prompt`,
    source: $localize`:@@promptLabelSource:Source`,
    name: $localize`:@@promptLabelName:Name`
  }

  public static readonly acceptLabel = $localize`:@@acceptLabel:Yes`;
  public static readonly rejectLabel = $localize`:@@rejectLabel:No`;

  private static readonly isPromptCellEditable = (params : EditableCallbackParams) => {
    return params.data.source !== NA ? false : true;
  };

  private static getCommonColumns() : ColumnDefinition[] {
    return [
      {
        field: 'promptId',
        columnType: ColumnType.RowId,
        label: $localize`:@@promptRowId:Row ID`,
        cellClass: 'bpt-row-id-cell',
        editable: false,
        showInColumnChooser: false,
        alwaysHidden: true,
        hidden: true
      },
      {
        field: 'name',
        columnType: ColumnType.String,
        label: $localize`:@@promptName:Name`,
        editable: false,
        showInColumnChooser: false
      },
      {
        field: 'source',
        columnType: ColumnType.String,
        label: $localize`:@@source:Source`,
        editable: false,
        hidden: true,
        showInColumnChooser: true
      },
      {
        field: 'isPromptRemoved',
        columnType: ColumnType.Boolean,
        label: $localize`:@@removedPrompt:Removed prompt`,
        editable: false,
        alwaysHidden: true,
        hidden: true,
        showInColumnChooser: false
      }
    ];
  }

  private static getClientFacingPromptColumn() : ColumnDefinition {
    return  {
        field: 'clientFacingPrompt',
        columnType: ColumnType.String,
        label: $localize`:@@clientFacingPrompt:Client Facing Prompt`,
        editable: PromptConstants.isPromptCellEditable,
        showInColumnChooser: true,
        alwaysHidden: false
      }
  }

  private static getAdditionDetailsColumn(): ColumnDefinition{
    return {
      field: 'additionalDetails',
      columnType: ColumnType.String,
      label: $localize`:@@additionalDetails:Additional Details`,
      editable: PromptConstants.isPromptCellEditable,
      showInColumnChooser: true
    }
  }

  private static getManufacturerColumn() : ColumnDefinition {
    return {
      field: 'manufacturer',
      columnType: ColumnType.String,
      label: $localize`:@@manufacturer:Manufacturer`,
      editable: PromptConstants.isPromptCellEditable,
      showInColumnChooser: true
    }
  }

  private static getLotColumn() : ColumnDefinition {
    return {
      field: 'lot',
      columnType: ColumnType.String,
      label: $localize`:@@lot:Lot`,
      editable: PromptConstants.isPromptCellEditable,
      showInColumnChooser: true
    };
  }

  private static getConcentrationColumn() : ColumnDefinition {
    return {
      field: 'concentration',
      columnType: ColumnType.String,
      label: $localize`:@@concentration:Concentration`,
      editable: PromptConstants.isPromptCellEditable,
      showInColumnChooser: true
    }
  }

  private static getCatalogColumn() : ColumnDefinition {
    return {
      field: 'catalog',
      columnType: ColumnType.String,
      label: $localize`:@@catalog:Catalog`,
      editable: PromptConstants.isPromptCellEditable,
      showInColumnChooser: true
    }
  }

  public static readonly MaterialPromptDefinitions: ColumnDefinition[] = [
    ...PromptConstants.getCommonColumns(),
    {
      field: 'materialType',
      columnType: ColumnType.String,
      label: $localize`:@@promptName:Material Type`,
      editable: PromptConstants.isPromptCellEditable,
      showInColumnChooser: true
    },
    {
      field: 'brand',
      columnType: ColumnType.String,
      label: $localize`:@@brand:Brand`,
      editable: PromptConstants.isPromptCellEditable,
      showInColumnChooser: true
    },
    {
      field: 'grade',
      columnType: ColumnType.String,
      label: $localize`:@@grade:Grade`,
      editable: PromptConstants.isPromptCellEditable,
      showInColumnChooser: true
    },
    {
      field: 'purity',
      columnType: ColumnType.String,
      label: $localize`:@@purity:Purity`,
      editable: PromptConstants.isPromptCellEditable,
      showInColumnChooser: true
    },
    PromptConstants.getConcentrationColumn(),
    PromptConstants.getLotColumn(),
    PromptConstants.getCatalogColumn(),
    PromptConstants.getAdditionDetailsColumn(),
    PromptConstants.getClientFacingPromptColumn()
  ];

  public static readonly ColumnPromptDefinitions: ColumnDefinition[] = [
    ...PromptConstants.getCommonColumns(),
    {
      field: 'chromatographyType',
      columnType: ColumnType.String,
      label: $localize`:@@chromatographyType:Chromatography Type`,
      editable: PromptConstants.isPromptCellEditable,
      showInColumnChooser: true
    },
    PromptConstants.getManufacturerColumn(),
    {
      field: 'particleSizeOrFilmThickness',
      columnType: ColumnType.String,
      label: $localize`:@@particleSizeOrFilmThickness:Particle Size Or Film Thickness`,
      editable: PromptConstants.isPromptCellEditable,
      showInColumnChooser: true
    },
    {
      field: 'packingPhase',
      columnType: ColumnType.String,
      label: $localize`:@@packingPhase:Packing Phase`,
      editable: PromptConstants.isPromptCellEditable,
      showInColumnChooser: true
    },
    {
      field: 'dimensions',
      columnType: ColumnType.String,
      label: $localize`:@@dimensions:Dimensions`,
      editable: PromptConstants.isPromptCellEditable,
      showInColumnChooser: true
    },
    PromptConstants.getLotColumn(),
    PromptConstants.getCatalogColumn(),
    {
      field: 'serial',
      columnType: ColumnType.String,
      label: $localize`:@@serial:Serial`,
      editable: PromptConstants.isPromptCellEditable,
      showInColumnChooser: true
    },
    PromptConstants.getClientFacingPromptColumn()
  ];

  public static readonly InstrumentPromptDefinitions: ColumnDefinition[] = [
    ...PromptConstants.getCommonColumns(),
    {
      field: 'model',
      columnType: ColumnType.String,
      label: $localize`:@@model:Model`,
      editable: PromptConstants.isPromptCellEditable,
      showInColumnChooser: true
    },
    PromptConstants.getManufacturerColumn(),
    PromptConstants.getAdditionDetailsColumn(),
    PromptConstants.getClientFacingPromptColumn()
  ];

  public static readonly PreparationPromptDefinitions: ColumnDefinition[] = [
    ...PromptConstants.getCommonColumns(),
    PromptConstants.getConcentrationColumn(),
    {
      field: 'storageCondition',
      columnType: ColumnType.String,
      label: $localize`:@@storageCondition:Storage Condition`,
      editable: PromptConstants.isPromptCellEditable,
      showInColumnChooser: true
    },
    {
      field: 'containerDescription',
      columnType: ColumnType.String,
      label: $localize`:@@containerDescription:Container Description`,
      editable: PromptConstants.isPromptCellEditable,
      showInColumnChooser: true
    },
    {
      field: 'client',
      columnType: ColumnType.String,
      label: $localize`:@@client:Client`,
      editable: PromptConstants.isPromptCellEditable,
      showInColumnChooser: true
    },
    {
      field: 'project',
      columnType: ColumnType.String,
      label: $localize`:@@project:Project`,
      editable: PromptConstants.isPromptCellEditable,
      showInColumnChooser: true
    },
    {
      field: 'analysis',
      columnType: ColumnType.String,
      label: $localize`:@@analysis:Analysis`,
      editable: PromptConstants.isPromptCellEditable,
      showInColumnChooser: true
    },
    {
      field: 'method',
      columnType: ColumnType.String,
      label: $localize`:@@method:Method`,
      editable: PromptConstants.isPromptCellEditable,
      showInColumnChooser: true
    },
    {
      field: 'compendia',
      columnType: ColumnType.String,
      label: $localize`:@@compendia:Compendia`,
      editable: PromptConstants.isPromptCellEditable,
      showInColumnChooser: true
    },
    PromptConstants.getClientFacingPromptColumn()
  ];

  public static readonly ConsumableSupplyPromptDefinitions: ColumnDefinition[] = [
    ...PromptConstants.getCommonColumns(),
    {
      field: 'details',
      columnType: ColumnType.String,
      label: $localize`:@@details:Details`,
      editable: PromptConstants.isPromptCellEditable,
      showInColumnChooser: true
    },
    PromptConstants.getManufacturerColumn(),
    {
      field: 'part',
      columnType: ColumnType.String,
      label: $localize`:@@part:Part`,
      editable: PromptConstants.isPromptCellEditable,
      showInColumnChooser: true
    },
    PromptConstants.getLotColumn(),
    PromptConstants.getCatalogColumn(),
    PromptConstants.getClientFacingPromptColumn()
  ];
}
