import { ColumnDefinition } from "bpt-ui-library/bpt-grid";
import { ColumnType } from "../../../api/models/column-type";
import { PromptsDropdownRendererComponent } from "./prompts-dropdown-renderer/prompts-dropdown-cell-renderer";
import { PromptType } from "../../../api/models";
import { ActivityInputType, ExperimentWorkflowState } from "../../../api/data-entry/models";
import { Observable } from "rxjs";
import { LabItemWisePermissions } from "../../labItems/shared/lab-items-feature-manager";

export class PromptsGridOptions {
  private static readonly cellStyleClass = 'prompt-cell-grid-cell-align';

  private static getCommonColumns(): ColumnDefinition[] {
    return [
      {
        field: 'promptId',
        columnType: ColumnType.RowId,
        label: $localize`:@@promptRowId:Row ID`,
        cellClass: 'bpt-row-id-cell',
        editable: false,
        showInColumnChooser: false,
        alwaysHidden: true,
        hidden: true
      },
      {
        field: 'isUsed',
        columnType: ColumnType.Boolean,
        label: $localize`:@@promptUsed:Used`,
        editable: false,
        maxWidth: 100,
        showInColumnChooser: true,
        alwaysHidden: false,
        cellClass : 'eln-prompt-text-align',
        hidden: false
      },
      {
        field: 'name',
        columnType: ColumnType.String,
        label: $localize`:@@promptLabelName:Name`,
        editable: false,
        showInColumnChooser: false
      },
    ];
  }

  private static getClientFacingPromptColumn(): ColumnDefinition {
    return {
      field: 'clientFacingPrompt',
      columnType: ColumnType.String,
      label: $localize`:@@clientFacingPrompt:Client Facing Prompt`,
      showInColumnChooser: true,
      alwaysHidden: false
    }
  }

  private static getAdditionDetailsColumn(): ColumnDefinition {
    return {
      field: 'additionalDetails',
      columnType: ColumnType.String,
      label: $localize`:@@promptLabelAdditionalDetails:Additional Details`,
      showInColumnChooser: true
    }
  }

  private static getManufacturerColumn(): ColumnDefinition {
    return {
      field: 'manufacturer',
      columnType: ColumnType.String,
      label: $localize`:@@promptLabelManufacturer:Manufacturer`,
      showInColumnChooser: true
    }
  }

  private static getModelPromptcolumn(): ColumnDefinition {
    return {
      field: 'model',
      columnType: ColumnType.String,
      label: $localize`:@@promptLabelModel:Model`,
      showInColumnChooser: true
    }
  }

  private static getLotColumn(): ColumnDefinition {
    return {
      field: 'lot',
      columnType: ColumnType.String,
      label: $localize`:@@promptLabelLot:Lot #`,
      showInColumnChooser: true
    };
  }

  private static getConcentrationColumn(): ColumnDefinition {
    return {
      field: 'concentration',
      columnType: ColumnType.String,
      label: $localize`:@@promptLabelConcentration:Concentration`,
      showInColumnChooser: true
    }
  }

  private static getCatalogColumn(): ColumnDefinition {
    return {
      field: 'catalog',
      columnType: ColumnType.String,
      label: $localize`:@@promptLabelCatalog:Catalog #`,
      showInColumnChooser: true
    }
  }

  public static GetMaterialPromptDefinitions(): ColumnDefinition[] {
    return [
    ...PromptsGridOptions.getCommonColumns(),
    {
      field: 'materialType',
      columnType: ColumnType.String,
      label: $localize`:@@promptName:Material Type`,
      showInColumnChooser: true
    },
    {
      field: 'brand',
      columnType: ColumnType.String,
      label: $localize`:@@promptLabelBrand:Brand`,
      showInColumnChooser: true
    },
    {
      field: 'grade',
      columnType: ColumnType.String,
      label: $localize`:@@promptLabelGrade:Grade`,
      showInColumnChooser: true
    },
    {
      field: 'purity',
      columnType: ColumnType.String,
      label: $localize`:@@promptLabelPurity:Purity`,
      showInColumnChooser: true
    },
    PromptsGridOptions.getConcentrationColumn(),
    PromptsGridOptions.getLotColumn(),
    PromptsGridOptions.getCatalogColumn(),
    PromptsGridOptions.getAdditionDetailsColumn(),
    PromptsGridOptions.getClientFacingPromptColumn()
  ];
  }

  public static GetColumnPromptDefinitions(): ColumnDefinition[] {
    return [
    ...PromptsGridOptions.getCommonColumns(),
    {
      field: 'chromatographyType',
      columnType: ColumnType.String,
      label: $localize`:@@promptLabelChromatographyType:Chromatography Type`,
      showInColumnChooser: true
    },
    PromptsGridOptions.getManufacturerColumn(),
    {
      field: 'particleSizeOrFilmThickness',
      columnType: ColumnType.String,
      label: $localize`:@@promptLabelParticleSizeOrFilmThickness:Particle Size Or Film Thickness`,
      showInColumnChooser: true
    },
    {
      field: 'packingPhase',
      columnType: ColumnType.String,
      label: $localize`:@@promptLabelPackingPhase:Packing Phase`,
      showInColumnChooser: true
    },
    {
      field: 'dimensions',
      columnType: ColumnType.String,
      label: $localize`:@@promptLabelDimensions:Dimensions`,
      showInColumnChooser: true
    },
    PromptsGridOptions.getLotColumn(),
    PromptsGridOptions.getCatalogColumn(),
    {
      field: 'serial',
      columnType: ColumnType.String,
      label: $localize`:@@promptLabelSerial:Serial #`,
      showInColumnChooser: true
    },
    PromptsGridOptions.getClientFacingPromptColumn()
  ]};

  public static GetInstrumentPromptDefinitions(): ColumnDefinition[] {
    return [
    ...PromptsGridOptions.getCommonColumns(),
    PromptsGridOptions.getModelPromptcolumn(),
    PromptsGridOptions.getManufacturerColumn(),
    PromptsGridOptions.getAdditionDetailsColumn(),
    PromptsGridOptions.getClientFacingPromptColumn(),
  ]};

  public static GetPreparationPromptDefinitions(): ColumnDefinition[] {
    return [
    ...PromptsGridOptions.getCommonColumns(),
    PromptsGridOptions.getConcentrationColumn(),
    {
      field: 'storageCondition',
      columnType: ColumnType.String,
      label: $localize`:@@promptLabelStorageCondition:Storage Condition`,
      showInColumnChooser: true
    },
    {
      field: 'containerDescription',
      columnType: ColumnType.String,
      label: $localize`:@@promptLabelContainerDescription:Container Description`,
      showInColumnChooser: true
    },
    {
      field: 'client',
      columnType: ColumnType.String,
      label: $localize`:@@promptLabelClient:Client`,
      showInColumnChooser: true
    },
    {
      field: 'project',
      columnType: ColumnType.String,
      label: $localize`:@@promptLabelProject:Project`,
      showInColumnChooser: true
    },
    {
      field: 'analysis',
      columnType: ColumnType.String,
      label: $localize`:@@promptLabelAnalysis:Analysis`,
      showInColumnChooser: true
    },
    {
      field: 'method',
      columnType: ColumnType.String,
      label: $localize`:@@promptLabelMethod:Method`,
      showInColumnChooser: true
    },
    {
      field: 'compendia',
      columnType: ColumnType.String,
      label: $localize`:@@promptLabelCompendia:Compendia`,
      showInColumnChooser: true
    },
    PromptsGridOptions.getClientFacingPromptColumn()
  ]};

  public static GetConsumableSupplyPromptDefinitions(): ColumnDefinition[] {
    return [
    ...PromptsGridOptions.getCommonColumns(),
    {
      field: 'details',
      columnType: ColumnType.String,
      label: $localize`:@@promptLabelDetails:Details`,
      showInColumnChooser: true
    },
    PromptsGridOptions.getManufacturerColumn(),
    {
      field: 'part',
      columnType: ColumnType.String,
      label: $localize`:@@promptLabelPart:Part #`,
      showInColumnChooser: true
    },
    PromptsGridOptions.getLotColumn(),
    PromptsGridOptions.getCatalogColumn(),
    PromptsGridOptions.getClientFacingPromptColumn()
  ]};

  public static GetPromptsDropdownColDefForLabItems(promptType: PromptType, isVisible: boolean, workflowState?: ExperimentWorkflowState,
    labItemType?: ActivityInputType, latestLabItemsPermissions?: Observable<LabItemWisePermissions>, allowRowAdd = true): ColumnDefinition {
    return {
      field: 'promptsName',
      cellClass: this.cellStyleClass,
      editable: false,
      cellRenderer: PromptsDropdownRendererComponent,
      cellRendererParams: {
        promptType: promptType,
        labItemType: labItemType,
        workflowState: workflowState,
        latestLabItemsPermissions: latestLabItemsPermissions,
        allowRowAdd: allowRowAdd
      },
      sortable: true,
      maxWidth: 200,
      label: $localize`:@@prompt:Prompt`,
      showInColumnChooser: isVisible,
      alwaysHidden: !isVisible
    }
  }
}
