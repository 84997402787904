import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-completion-tracking',
  templateUrl: './completion-tracking.component.html',
  styleUrls: ['./completion-tracking.component.scss']
})
export class CompletionTrackingComponent {
  @Input() completionPercent = 0;
}
