import { Instant } from '@js-joda/core';
import { ElnCollaborator } from './eln-collaborator';
export interface InputLock {
  experimentId: string;
  lockType: LockType;
  moduleId: string;
  activityId: string;
  experimentCollaborator: ElnCollaborator;
  expiresAt: Instant;
  key: string;
  multiSelectCells?:boolean
}

export class FieldLock implements InputLock {
  lockType: LockType;
  experimentCollaborator: ElnCollaborator;
  readonly expiresAt: Instant;
  readonly key: string;

  constructor(
    readonly experimentId: string,
    lockType: LockType,
    readonly moduleId: string,
    readonly activityId: string,
    experimentCollaborator: ElnCollaborator,
    readonly fieldPath: string[],
    readonly formId: string
  ) {
    this.lockType = lockType;
    this.experimentCollaborator = experimentCollaborator;
    this.expiresAt = Instant.now().plusSeconds(720);
    this.key = `${fieldPath.join('_')}${formId}`;
  }
}

export class CellLock implements InputLock {
  lockType: LockType;
  experimentCollaborator: ElnCollaborator;
  readonly expiresAt: Instant;
  readonly key: string;

  constructor(
    readonly experimentId: string,
    lockType: LockType,
    /** moduleId/nodeId for a typical module or a string such as activityReferences */
    readonly moduleId: string,
    readonly activityId: string,
    experimentCollaborator: ElnCollaborator,
    /** tableId/gridId/nodeId for a typical table/grid or a string such as crossReferences */
    readonly tableId: string,
    readonly rowId: string,
    /** "field" (misnamed) the identifier for the column this cell is in */
    readonly columnName: string,
    readonly parentRowId?:string,
    readonly multiSelectCells?: boolean
  ) {
    this.lockType = lockType;
    this.experimentCollaborator = experimentCollaborator;
    this.expiresAt = Instant.now().plusSeconds(720);
    this.key = CellLock.createKey(tableId, rowId, columnName);
  }

  public static createKey(tableId: string, rowId: string, columnName: string): string {
    return `${tableId}-${rowId}-${columnName}`;
  }
}

export enum LockType {
  lock = 'Lock',
  unlock = 'Unlock'
}
