import { Injectable } from '@angular/core';
import { Message, MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { LocalizeHelper } from '../../shared/localize-helper';
import { RuleActionNotificationService } from '../action-notification/rule-action-notification.service';
import { RuleNotificationResult } from '../actions/rule-action-result';

@Injectable({
  providedIn: 'root'
})
export class RuleNotificationsInterceptorService {
  private readonly errorMessageLookup: {
    [message: string]: { lookupErrorMessage: string; localizedErrorMessage: string };
  } = {
    arithmeticErrorMessage: {
      lookupErrorMessage:
        'Error performing a Requested Calculation in rule. A mathematical error occurred while trying to evaluate the expression:',
      localizedErrorMessage: $localize`:@@RuleArithmeticErrorMessage:Error performing a Requested Calculation in rule. A mathematical error occurred while trying to evaluate the expression:`
    }
  };
  private readonly subscriptions: Subscription[] = [];

  constructor(
    private readonly ruleActionNotificationService: RuleActionNotificationService,
    private readonly messageService: MessageService
  ) {
    this.watchRuleEvaluationFailures();
  }
  watchRuleEvaluationFailures() {
    this.subscriptions.push(
      this.ruleActionNotificationService.RuleFailureNotifications.subscribe({
        next: this.processFailureNotifications.bind(this)
      })
    );
  }

  public processFailureNotifications(notifications: RuleNotificationResult): void {
    if (!notifications || !notifications.Errors || notifications.Errors.length === 0) {
      return;
    }
    notifications.Errors.forEach((error) => {
      const errorMessage: Message = {
        key: 'notification',
        severity: 'error',
        summary: $localize`:@@RuleEvaluationError:Rule Evaluation Error`,
        detail:
          error.TranslationKey && error.TranslationKey.length > 0
            ? LocalizeHelper.translateMessage(error.TranslationKey, error.Message)
            : this.localizeByErrorMessage(error.Message),
        sticky: false
      };
      this.messageService.add(errorMessage);
    });
  }

  private localizeByErrorMessage(message: string): string {
    const localizedErrorKey = Object.keys(this.errorMessageLookup).find((key) =>
      message.includes(this.errorMessageLookup[key].lookupErrorMessage)
    );
    if (localizedErrorKey) {
      return message.replace(
        this.errorMessageLookup[localizedErrorKey].lookupErrorMessage,
        this.errorMessageLookup[localizedErrorKey].localizedErrorMessage
      );
    }
    return message;
  }
}
