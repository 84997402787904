import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BptDropdownModule } from 'bpt-ui-library/bpt-dropdown';
import { SubBusinessUnitSelectComponent } from './sub-business-unit-select/sub-business-unit-select.component';
import { UserSelectComponent } from './user-select/user-select.component';

@NgModule({
  declarations: [
    UserSelectComponent,
    SubBusinessUnitSelectComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    BptDropdownModule
  ],
  exports: [
    UserSelectComponent,
    SubBusinessUnitSelectComponent
  ]
})
export class OrganizationModule {}
