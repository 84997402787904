import { Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { InstrumentColumn } from '../../../../api/models/LabItemsELN/Bookshelf/Api/LabItems/instrument-column';
import { BptGridComponent, BptGridMode, BptGridRowActionClickEvent, BptGridRowActionConfiguration, BptRowActionElement, ColumnDefinition } from 'bpt-ui-library/bpt-grid';
import { LabItemsColumnTableOptions } from '../lab-items-column/lab-items-column-table-options';
import { ActivityInputType } from '../../../../api/models';
import { LabItemsService } from '../../lab-items.service';
import { ClientStateService } from '../../../../services/client-state.service';
import { ActivatedRoute } from '@angular/router';
import { UnsubscribeAll } from '../../../../shared/rx-js-helpers';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ExperimentNotificationService } from '../../../../services/experiment-notification.service';
import { LabItemWisePermissions } from '../../shared/lab-items-feature-manager';
import { UserService } from '../../../../services/user.service';
@Component({
  selector: 'app-lab-items-removed-column',
  templateUrl: './lab-items-removed-column.component.html',
  styleUrls: ['./lab-items-removed-column.component.scss']
})
export class LabItemsRemovedColumnComponent  implements OnInit, OnDestroy {

  @Input() titleOfTable = $localize`:@@LabItemsColumnsTableTitle:Columns`;
  labItemsColumnRemovedDataSource: InstrumentColumn[] = [];
  @ViewChild('labItemsRemovedColumnGrid') labItemsRemovedColumnGrid!: BptGridComponent;
  subHeading = $localize`:@@labitemsRemovedColumnSubHeading:Please click on the restore icon from the table to restore removed columns`;
  gridMode = BptGridMode.dataView;
  permission!: LabItemWisePermissions;
  columnDefinitions: ColumnDefinition[] = [];
  rowActions: BptRowActionElement[] = [];
  protected activeSubscriptions: Subscription[] = [];
  removedRowsColumnId = 'removedRowsColumnId';
  gridActions!: BptGridRowActionConfiguration;

  constructor(
    private readonly labItemsService: LabItemsService,
    public readonly clientStateService: ClientStateService,
    public readonly activatedRoute: ActivatedRoute,
    private readonly experimentNotificationService: ExperimentNotificationService,
    private readonly renderer: Renderer2,
    private readonly userService: UserService,
    private readonly elementRef: ElementRef
  ){
    this.experimentNotificationService.inputLockReceiver.subscribe((lock) => {
      this.labItemsService.applyCellLock(
        lock,
        this.labItemsRemovedColumnGrid.gridOptions,
        this.renderer
      );
    });
  }

  ngOnInit(): void {
    this.watchColumnRestoredNotification();
    this.columnDefinitions = LabItemsColumnTableOptions.GetColumnDefinitions(
      this.labItemsService.latestLabItemsPermissions
    )
    this.addGridActions();
    this.refreshDataSource();
    this.renderer.setAttribute(this.elementRef.nativeElement, 'data-id', this.removedRowsColumnId);
  }

  onFirstDataRendered(e: any) {
    this.labItemsService.loadCellLocks(this.labItemsRemovedColumnGrid.gridOptions, this.renderer);
  }

  private refreshDataSource(): void {
    const removedColumns = this.labItemsService.getLabItemsRemovedInstrumentColumns();
    removedColumns.forEach((column) => {
      this.labItemsService.unpackModifiableDataValues<InstrumentColumn>(
        column,
        column.tableData,
        this.columnDefinitions,
        ActivityInputType.InstrumentDetails
      );
    });
    this.labItemsColumnRemovedDataSource = [...removedColumns];
    this.labItemsRemovedColumnGrid?.gridApi?.setGridOption('rowData', this.labItemsColumnRemovedDataSource);
    this.labItemsRemovedColumnGrid?.gridApi?.refreshCells();
  }

  private addGridActions() {
    const rowActions = this.getRowActionItems();
    const actionsSubject: BehaviorSubject<BptRowActionElement[]> = new BehaviorSubject(rowActions);

    this.gridActions = {
      actions: actionsSubject,
    };
  }

  public getRowActionItems(): BptRowActionElement[] {
    return [
      {
        id: this.labItemsService.columnRestoreActionId,
        enabled: this.labItemsService.getRowActionPermission() && !this.userService.hasOnlyReviewerRights(),
        styleClass: 'icon-s icon-restore-icon',
        click: this.rowRestoreActionClick.bind(this),
        tooltip: $localize`:@@RestoreItem:Restore item`
      }
    ];
  }

  private rowRestoreActionClick(e: BptGridRowActionClickEvent) {
    e.params.data.id = e.params.node.rowIndex;
    this.labItemsService.restoreHandler[ActivityInputType.InstrumentColumn](e.params.data);
  }

  private watchColumnRestoredNotification(): void {
    this.activeSubscriptions.push(
      this.labItemsService.InstrumentColumnRestoredRefresh.subscribe({
        next: () => this.refreshDataSource()
      })
    )
  }

  ngOnDestroy(): void {
    UnsubscribeAll(this.activeSubscriptions);
  }

}
