import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { BaseComponent } from '../../../../base/base.component';
import { RepeatForEachOption } from '../../../repeat-for-each-chooser/repeat-for-each-chooser.component';
import { DataValueService } from '../../../../experiment/services/data-value.service';
import { ClientStateService } from '../../../../services/client-state.service';
import { ActivatedRoute } from '@angular/router';
import { ColumnType } from '../../../../api/models';
import { RepeatGroupCellRendererParams, TableDataService } from '../../../../experiment/data/table/table-data.service';

@Component({
  templateUrl: './repeat.component.html',
  styleUrls: ['./repeat.component.scss'],
})
export class RepeatComponent extends BaseComponent implements ICellRendererAngularComp {

  public cellValue!: string;

  public colorClasses = ['#D1EEF7', '#FAE5DE', '#DAE9E1', '#DDD3F5', '#FFDEAB', '#E5C9DA'];

  public groupingNumber?: number;

  public hasRepeatGroup = false;

  /** ex Material Aliquot */
  public repeatForLabel = '';

  /** ex: A, B */
  public groupingIconLabel = '';

  /** hex value for the background color, ex: #FFFFFF */
  public groupingIconColor = '';

  public get showRemoveButton(): boolean {
    return !!this.params?.removeGroupCallback;
  }

  private params?: RepeatGroupCellRendererParams;

  constructor(clientStateService: ClientStateService,
    route: ActivatedRoute,
    private readonly dataValueService: DataValueService) {
    super(clientStateService, route);
  }

  /** gets called once before the renderer is used */
  agInit(params: RepeatGroupCellRendererParams): void {
    this.params = params;
    this.getValueToDisplay();
  }

  /** gets called whenever the user gets the cell to refresh */
  refresh(params: RepeatGroupCellRendererParams) {
    this.params = params;
    this.getValueToDisplay();
    return true;
  }

  getValueToDisplay() {

    const primitives = this.getDisplayPrimitives();
    if(!primitives.groupingNumber || Number.isNaN(primitives.groupingNumber) || !primitives.option) {
      this.hasRepeatGroup = false;
      return;
    }

    this.hasRepeatGroup = true;
    switch(primitives.option) {
      case RepeatForEachOption.Sample:
        this.repeatForLabel = $localize`:@@sampleAliquot:Sample Aliquot`;
        break;
      case RepeatForEachOption.Material:
        this.repeatForLabel = $localize`:@@materialAliquot:Material Aliquot`;
        break;
      case RepeatForEachOption.Instrument:
        this.repeatForLabel = $localize`:@@Instrument:Instrument`;
        break;
      default:
        throw new Error("LOGIC ERROR: Invalid Repeat-for option");
    }

    this.groupingNumber = primitives.groupingNumber
    if (1 > this.groupingNumber || this.groupingNumber > 26) throw new Error('LOGIC ERROR: repeat with field must be a value between 1 and 26');
    this.groupingIconLabel = String.fromCodePoint(64 + this.groupingNumber);
    const colorIndex = this.groupingNumber % 6; // we only care about the smallest radix value
    this.groupingIconColor = this.colorClasses[colorIndex];
  }

  public removeGroup(event: MouseEvent): void {
    this.params?.removeGroupCallback({ groupNumber: this.groupingNumber ?? 0, originalEvent: event });
  }

  private getDisplayPrimitives(): { option?: RepeatForEachOption, groupingNumber?: number } {
    // Needs to be this way to avoid the "invert-if" warning.
    if(!(this.params?.data[TableDataService.repeatForField] && this.params?.data[TableDataService.repeatWithField])) return {};

    const groupingNumber = parseInt(this.dataValueService.getPrimitiveValue(ColumnType.String, this.params.data[TableDataService.repeatWithField]));

    const option = this.dataValueService.getPrimitiveValue(ColumnType.String, this.params.data[TableDataService.repeatForField]);

    return { groupingNumber, option };
  }
}
