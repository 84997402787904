<form 
  novalidate>
  <bpt-slider #slider
    class="flex flex-auto flex-wrap flex-column"
    id="picklistPreviewSlider"
    [headerText]="headerText"
    [size]="'large'"
    [closeOnEscape]="true"
    [(visible)]="visible"
    (visibleChange)="onVisibleChanged($event)"
    [displayFooterWithPrimaryButton]="true"
    [displayFooterWithSecondaryButton]="false"
    i18n-primaryButtonLabel="@@ok"
    primaryButtonLabel="OK"
    (footerPrimaryButtonClick)="close()"
    (onClose)="onClose()">
    <div class="preview-wrapper">
      <bpt-dropdown #dropdown
        id="picklistPreviewSlider-picklist"
        name="picklist"
        ngDefaultControl
        class="picklistPreviewSlider-picklist"
        [commitValueOnEnter]="true"
        [(ngModel)]="chosenPicklist"
        [options]="allPicklists"
        [showFilter]="true"
        labelField="name"
        valueField="id">
      </bpt-dropdown>
    </div>
    <p i18n="@@picklistAppliedWarning">Selected picklist will be applied to field upon close.</p>
    <ul class="picklist-options">
      <li *ngFor="let item of items">
          {{ item }}
      </li>
    </ul>
  </bpt-slider>
</form>
