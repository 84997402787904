import { Component} from '@angular/core';
import { LocalDate } from '@js-joda/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { NA } from 'bpt-ui-library/shared';
import { ActivityInputValidationHelper } from 'services/activity-input-validation-helper';

@Component({
  selector: 'app-date-based-warning-icon-renderer',
  template: `
            <div class = "date-expiry-warning-icon">
            <span>{{this.formattedDateToDisplay}}</span>
            <span *ngIf="showWarningIcon">
            <img id="eln-itemExpiryWarningIcon"
            class= "warning-icon-img"
            pTooltip = {{params.toolTipMessage}}
            tooltipPosition="bottom"
            src="../../../assets/eln-assets/warning.svg" alt=""  width="13px" height="13px"/></span>
            </div>
            `,
  styles: [`.date-expiry-warning-icon{display: flex;justify-content: space-between;} .warning-icon-img{cursor: pointer;}
  `]
})
export class DateBasedWarningIconRendererComponent implements ICellRendererAngularComp{
  refresh(): boolean {
    return false;
  }

  constructor(public validationHelper : ActivityInputValidationHelper){}
  public params!: any;
  showWarningIcon = false;
  formattedDateToDisplay = ""

  agInit(params: any): void {
    this.params = params;
    this.showWarningIcon = false;
    this.formattedDateToDisplay = this.params.value === NA || this.params.value ==="" ? this.params.value : this.validationHelper.getConvertedDateToDisplay(this.params.value);
    if(this.params.value !== NA && this.params.value !=="" && this.params.value < LocalDate.now().toString()){
        this.showWarningIcon = true;
      }
  }
}
