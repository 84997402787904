<div class="eln-instrument-event-panel" *ngIf="!!instrumentEventsDataSource && !instrumentEventsDataSource.isRemoved">
  <div class="grid">
    <div class="col-9 font-bold flex flex-row gap-3 eln-instrument-event-header-left">
      <div id="eln-instrument-header-icon" class="font-bold flex flex-row eln-instrument-event-header">{{
        instrumentEventsPageHeader
        }}</div>
      <div
        class="flex flex-row {{completionPercent === 100 ? 'formFilled' : completionPercent === 0 ? 'zeroFilledForm' : 'unFilledForm'}}">
        <p-progressBar class="eln-FormProgressBar" [showValue]="false" [value]="completionPercent"></p-progressBar>
        <span class="font">{{completionPercent}}%</span>
      </div>
    </div>
    <div class="col-3 text-right m-auto">
      <a (click)="refreshInstruments()"
        class="align-icon"
        [tabindex]="0"
        pTooltip="Refresh"
        i18n-pTooltip="@@Refresh"
        tooltipPosition="top">
        <span class="fas fa-sync-alt" [ngClass]="{'pending-img': refreshing}"></span>
        <span class="ml-1 icon" disabled="isReadOnly" i18n="@@Refresh">Refresh</span>
      </a>
      <a (click)="removeRows()"
        class="align-icon" [tabindex]="0"
        pTooltip="Remove"
        i18n-pTooltip="@@remove"
        tooltipPosition="top">
        <span class="far fa-trash-alt"></span>
        <span class="ml-1 icon" disabled="isReadOnly" i18n="@@remove">Remove</span>
      </a>
      <a class="audit-Icon"
        id="eln-instrument-events-audit-icon"
        (click)="loadDialog()"
        [tabindex]="0"
        i18n-pTooltip="@@viewHistory"
        pTooltip="View History"
        tooltipPosition="top">
        <span class="fa fa-history"></span>
        <span class="ml-1 audit" i18n="@@History">History</span>
      </a>
    </div>
  </div>
  <div *ngIf="validation && validation.errors.length > 0" class="eln-instrument-event-validation">
    <p-messages severity="error" styleClass="p-mr-2">
      <ng-template pTemplate>
        <span class="p-message-icon pi pi-times-circle"></span>
        <span class="p-ml-2">{{ validation.errorTitle }}</span>
        <ul>
          <li *ngFor="let error of validation.errors">{{ error }}</li>
        </ul>
      </ng-template>
    </p-messages>
  </div>
  <div class="eln-main-content">
    <div class="fields">
      <div class="flex eln-flex-container">
        <div class="flex eln-flex flex-three-column-layout">
          <div class="eln-flex-item flex flex-column col-8 eln-instrument-event-id-panel gap-2">
            <label i18n="@@instrumentId" class="eln-instrument-event-id-label">Instrument Id</label>
            <label class="eln-instrument-event-id-value" readonly="isReadOnly">{{
              instrumentEventsDataSource.instrumentNumber
              }}</label>
          </div>
        </div>
        <div class="flex eln-flex flex-three-column-layout align-flex-items">
          <div class="eln-flex-item">
            <bpt-text-input
              inputId="eln-txtInstrumentEventName"
              i18n-label="@@instrumentEventName"
              label="Name"
              placeholder="Enter Name"
              i18n-placeholder="@@instrumentEventNamePlaceHolder"
              [readOnly]="isReadOnly"
              [suppressContextMenu]="false"
              [contextMenu]="getContextMenu('Name')"
              [(ngModel)]="instrumentEventsDataSource.name"
              [disabled]="true"></bpt-text-input>
          </div>
          <div class="eln-flex-item">
            <bpt-dropdown
              *ngIf="refreshEvent === true"
              inputId="eln-drpInstrumentEvent"
              #instrumentEventsDropdown
              i18n-label="@@instrumentEvent"
              i18n-placeholder="@@instrumentEventPlaceHolder"
              label="Instrument Event"
              placeholder="Select Instrument Event"
              [options]="instrumentEventsDataSource.maintenanceEvents"
              [showClear]="false"
              [showFilter]="false"
              [multiSelect]="false"
              [groupLabelField]="'nameDescription'"
              [valueField]="'nameDescription'"
              [labelField]="'nameDescription'"
              [readOnly]="true"
              (click)="onMaintenanceEventClicked()"
              [(ngModel)]="instrumentEventsDataSource.nameDescriptionValue"
              [contextMenu]="getContextMenu('Instrument Event')"
              [controlCustomDefinitionValidator]="controlCustomDefinitionValidatorForNameDescription"
              [suppressContextMenu]="false"></bpt-dropdown>
          </div>
          <div class="eln-flex-item">
            <bpt-text-input
              #descriptionField
              [multiLine]="true"
              [rows]="3"
              inputId="eln-txtInstrumentEventDescription"
              i18n-label="@@instrumentEventDescription"
              i18n-placeholder="@@instrumentEventDescriptionPlaceHolder"
              label="Description of Situation"
              placeholder="Enter Description of Situation"
              [readOnly]="isReadOnly"
              [(ngModel)]="instrumentEventsDataSource.descriptionValue"
              (ngModelChange)="onDescriptionChange($event)"
              [ngModelOptions]="{ updateOn: 'blur' }"
              [disabled]="disableEditingInstrument"
              [contextMenu]="getContextMenu('Description of Situation')"
              [suppressContextMenu]="false"
              [controlCustomDefinitionValidator]="controlCustomDefinitionValidatorForDescription"></bpt-text-input>
          </div>
        </div>
        <div class="flex eln-flex flex-three-column-layout">
          <div class="eln-flex-item">
            <bpt-dropdown
              inputId="eln-drpRemovedFromService"
              #removedFromServiceOptionsDropdown
              i18n-label="@@instrumentEventRemovedFromService"
              i18n-placeholder="@@instrumentEventRemovedFromServicePlaceHolder"
              label="Removed from Service"
              placeholder="Select Instrument Removed from Service"
              [options]="removedFromServiceOptions"
              [showClear]="false"
              [showFilter]="false"
              [multiSelect]="false"
              [readOnly]="isReadOnly"
              [(ngModel)]="instrumentEventsDataSource.removedFromServiceValue"
              (ngModelChange)="removedFromServiceChanged($event)"
              [contextMenu]="getContextMenu('Removed from Service')"
              [suppressContextMenu]="false"
              [disabled]="disableEditingInstrument"
              [ngModelOptions]="{ updateOn: 'blur' }"
              [controlCustomDefinitionValidator]="controlCustomDefinitionValidatorForRemovedFromService"></bpt-dropdown>
          </div>
          <div class="eln-flex-item">
            <bpt-datetime
              #instrumentDateRemovedDropDown
              *ngIf="refreshDateRemoved === true"
              i18n-label="@@instrumentEventDateRemoved"
              i18n-placeholder="@@instrumentEventDateRemovedPlaceHolder"
              label="Date Removed"
              placeholder="Select Date Removed"
              id="eln-drpDateRemoved"
              [disabled]="disableDateRemoved || disableEditingInstrument"
              [allowNA]="true"
              [readOnly]="isReadOnly"
              [(ngModel)]="instrumentEventsDataSource.dateRemovedValue"
              [useJsJodaTypes]="true"
              (ngModelChange)="onDateRemovedChange($event, false)"
              [hourFormat]="'12'"
              [contextMenu]="getContextMenu('Date Removed')"
              [suppressContextMenu]="false"
              [ngModelOptions]="{ updateOn: 'blur' }"
              [required]="true"
              [controlCustomDefinitionValidator]="controlCustomDefinitionValidatorForDateRemoved"></bpt-datetime>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>