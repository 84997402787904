<app-prompt *ngIf="isPromptLoaded"
  [enableAddPrompt]="recipeTypeChanged && isUserEditorOfTheRecipe() && isRecipeInDraft()"
  (submitPromptData)="submitPromptData($event)"
  (editPromptCellChangedData)="editPromptCellChangedData($event)"
  (removePromptData)="removePromptData($event)"
  (restorePromptData)="restorePromptData($event)"
  [prompts]="promptData"
  [savePromptNotifier]="savePromptNotifier"
  [editPromptNotifier]="editPromptNotifier"
  [promptsReadOnly]="promptsReadOnly"
></app-prompt>

<app-custom-dialog
  [header]="header"
  [config]="dialogConfig"
  [modal]="true"
  [headerIcon]="'icon-exclamation icon-m'"
  [subHeader]="subHeader"
  [showModal]="displayModal"
  [buttons]="modalButtons"
  [closable]="false"
  [draggable]="false"
  >
  <div *ngFor="let item of checkboxItems">
    <p-checkbox [binary]="true" label="{{item.label}}" [(ngModel)]="item.value"></p-checkbox>
  </div>
</app-custom-dialog>