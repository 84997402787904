/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ChromatographyDataImportedEventDetails } from '../models/chromatography-data-imported-event-details';
import { ChromatographyDataRefreshedDetails } from '../models/chromatography-data-refreshed-details';
import { experimentEventsEventIdChromatographyImportBlobsBlobNameGet$Json } from '../fn/experiment-blob-events/experiment-events-event-id-chromatography-import-blobs-blob-name-get-json';
import { ExperimentEventsEventIdChromatographyImportBlobsBlobNameGet$Json$Params } from '../fn/experiment-blob-events/experiment-events-event-id-chromatography-import-blobs-blob-name-get-json';
import { experimentEventsEventIdChromatographyImportBlobsBlobNameGet$Plain } from '../fn/experiment-blob-events/experiment-events-event-id-chromatography-import-blobs-blob-name-get-plain';
import { ExperimentEventsEventIdChromatographyImportBlobsBlobNameGet$Plain$Params } from '../fn/experiment-blob-events/experiment-events-event-id-chromatography-import-blobs-blob-name-get-plain';
import { experimentEventsEventIdChromatographyRefreshBlobsBlobNameGet$Json } from '../fn/experiment-blob-events/experiment-events-event-id-chromatography-refresh-blobs-blob-name-get-json';
import { ExperimentEventsEventIdChromatographyRefreshBlobsBlobNameGet$Json$Params } from '../fn/experiment-blob-events/experiment-events-event-id-chromatography-refresh-blobs-blob-name-get-json';
import { experimentEventsEventIdChromatographyRefreshBlobsBlobNameGet$Plain } from '../fn/experiment-blob-events/experiment-events-event-id-chromatography-refresh-blobs-blob-name-get-plain';
import { ExperimentEventsEventIdChromatographyRefreshBlobsBlobNameGet$Plain$Params } from '../fn/experiment-blob-events/experiment-events-event-id-chromatography-refresh-blobs-blob-name-get-plain';
import { experimentEventsEventIdRecipeAppliedBlobsBlobNameGet$Json } from '../fn/experiment-blob-events/experiment-events-event-id-recipe-applied-blobs-blob-name-get-json';
import { ExperimentEventsEventIdRecipeAppliedBlobsBlobNameGet$Json$Params } from '../fn/experiment-blob-events/experiment-events-event-id-recipe-applied-blobs-blob-name-get-json';
import { experimentEventsEventIdRecipeAppliedBlobsBlobNameGet$Plain } from '../fn/experiment-blob-events/experiment-events-event-id-recipe-applied-blobs-blob-name-get-plain';
import { ExperimentEventsEventIdRecipeAppliedBlobsBlobNameGet$Plain$Params } from '../fn/experiment-blob-events/experiment-events-event-id-recipe-applied-blobs-blob-name-get-plain';
import { ExperimentRecipeAppliedDetails } from '../models/experiment-recipe-applied-details';

@Injectable({ providedIn: 'root' })
export class ExperimentBlobEventsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `experimentEventsEventIdChromatographyImportBlobsBlobNameGet()` */
  static readonly ExperimentEventsEventIdChromatographyImportBlobsBlobNameGetPath = '/experiment-events/{eventId}/chromatography-import/blobs/{blobName}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsEventIdChromatographyImportBlobsBlobNameGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentEventsEventIdChromatographyImportBlobsBlobNameGet$Plain$Response(params: ExperimentEventsEventIdChromatographyImportBlobsBlobNameGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ChromatographyDataImportedEventDetails>> {
    return experimentEventsEventIdChromatographyImportBlobsBlobNameGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentEventsEventIdChromatographyImportBlobsBlobNameGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentEventsEventIdChromatographyImportBlobsBlobNameGet$Plain(params: ExperimentEventsEventIdChromatographyImportBlobsBlobNameGet$Plain$Params, context?: HttpContext): Observable<ChromatographyDataImportedEventDetails> {
    return this.experimentEventsEventIdChromatographyImportBlobsBlobNameGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChromatographyDataImportedEventDetails>): ChromatographyDataImportedEventDetails => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsEventIdChromatographyImportBlobsBlobNameGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentEventsEventIdChromatographyImportBlobsBlobNameGet$Json$Response(params: ExperimentEventsEventIdChromatographyImportBlobsBlobNameGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ChromatographyDataImportedEventDetails>> {
    return experimentEventsEventIdChromatographyImportBlobsBlobNameGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentEventsEventIdChromatographyImportBlobsBlobNameGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentEventsEventIdChromatographyImportBlobsBlobNameGet$Json(params: ExperimentEventsEventIdChromatographyImportBlobsBlobNameGet$Json$Params, context?: HttpContext): Observable<ChromatographyDataImportedEventDetails> {
    return this.experimentEventsEventIdChromatographyImportBlobsBlobNameGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChromatographyDataImportedEventDetails>): ChromatographyDataImportedEventDetails => r.body)
    );
  }

  /** Path part for operation `experimentEventsEventIdChromatographyRefreshBlobsBlobNameGet()` */
  static readonly ExperimentEventsEventIdChromatographyRefreshBlobsBlobNameGetPath = '/experiment-events/{eventId}/chromatography-refresh/blobs/{blobName}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsEventIdChromatographyRefreshBlobsBlobNameGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentEventsEventIdChromatographyRefreshBlobsBlobNameGet$Plain$Response(params: ExperimentEventsEventIdChromatographyRefreshBlobsBlobNameGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ChromatographyDataRefreshedDetails>> {
    return experimentEventsEventIdChromatographyRefreshBlobsBlobNameGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentEventsEventIdChromatographyRefreshBlobsBlobNameGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentEventsEventIdChromatographyRefreshBlobsBlobNameGet$Plain(params: ExperimentEventsEventIdChromatographyRefreshBlobsBlobNameGet$Plain$Params, context?: HttpContext): Observable<ChromatographyDataRefreshedDetails> {
    return this.experimentEventsEventIdChromatographyRefreshBlobsBlobNameGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChromatographyDataRefreshedDetails>): ChromatographyDataRefreshedDetails => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsEventIdChromatographyRefreshBlobsBlobNameGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentEventsEventIdChromatographyRefreshBlobsBlobNameGet$Json$Response(params: ExperimentEventsEventIdChromatographyRefreshBlobsBlobNameGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ChromatographyDataRefreshedDetails>> {
    return experimentEventsEventIdChromatographyRefreshBlobsBlobNameGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentEventsEventIdChromatographyRefreshBlobsBlobNameGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentEventsEventIdChromatographyRefreshBlobsBlobNameGet$Json(params: ExperimentEventsEventIdChromatographyRefreshBlobsBlobNameGet$Json$Params, context?: HttpContext): Observable<ChromatographyDataRefreshedDetails> {
    return this.experimentEventsEventIdChromatographyRefreshBlobsBlobNameGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChromatographyDataRefreshedDetails>): ChromatographyDataRefreshedDetails => r.body)
    );
  }

  /** Path part for operation `experimentEventsEventIdRecipeAppliedBlobsBlobNameGet()` */
  static readonly ExperimentEventsEventIdRecipeAppliedBlobsBlobNameGetPath = '/experiment-events/{eventId}/recipe-applied/blobs/{blobName}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsEventIdRecipeAppliedBlobsBlobNameGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentEventsEventIdRecipeAppliedBlobsBlobNameGet$Plain$Response(params: ExperimentEventsEventIdRecipeAppliedBlobsBlobNameGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ExperimentRecipeAppliedDetails>> {
    return experimentEventsEventIdRecipeAppliedBlobsBlobNameGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentEventsEventIdRecipeAppliedBlobsBlobNameGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentEventsEventIdRecipeAppliedBlobsBlobNameGet$Plain(params: ExperimentEventsEventIdRecipeAppliedBlobsBlobNameGet$Plain$Params, context?: HttpContext): Observable<ExperimentRecipeAppliedDetails> {
    return this.experimentEventsEventIdRecipeAppliedBlobsBlobNameGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExperimentRecipeAppliedDetails>): ExperimentRecipeAppliedDetails => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsEventIdRecipeAppliedBlobsBlobNameGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentEventsEventIdRecipeAppliedBlobsBlobNameGet$Json$Response(params: ExperimentEventsEventIdRecipeAppliedBlobsBlobNameGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ExperimentRecipeAppliedDetails>> {
    return experimentEventsEventIdRecipeAppliedBlobsBlobNameGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentEventsEventIdRecipeAppliedBlobsBlobNameGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentEventsEventIdRecipeAppliedBlobsBlobNameGet$Json(params: ExperimentEventsEventIdRecipeAppliedBlobsBlobNameGet$Json$Params, context?: HttpContext): Observable<ExperimentRecipeAppliedDetails> {
    return this.experimentEventsEventIdRecipeAppliedBlobsBlobNameGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExperimentRecipeAppliedDetails>): ExperimentRecipeAppliedDetails => r.body)
    );
  }

}
