import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValidationSummaryComponent } from './validation-summary/validation-summary.component';
import { MessagesModule } from 'primeng/messages';
import {MessageModule} from 'primeng/message';

@NgModule({
  declarations: [
    ValidationSummaryComponent
  ],
  imports: [
    CommonModule,
    MessagesModule,
    MessageModule
  ],
  exports: [
    ValidationSummaryComponent
  ]
})
export class ClientNotificationModule { }
