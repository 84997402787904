<div class="eln-child-table-container">
  <bpt-grid #MaintenanceEventGrid class="eln-maintenance-event-grid" ngClass="true"
    [columnDefinitions]="columnDefinitions" 
    [dataSource]="maintenanceEvents"
    [useJsJodaTypes]="true"
    gridId="{{maintenanceEventTableId}}" 
    [gridMode]="gridMode"
    [allowRowAdd]="false"
    [showAutoSizeButton]="false"
    [showFilterToggleButton]="false"
    [showFilterToggleButton]="false"
    [showGridOptionsButton]="false"
    (selectionChanged)="onSelected($event)">
  </bpt-grid>
  <div class="maintenance-event-buttons-align">
    <p-button
      styleClass="eln-standard-popup-button p-button-outlined align-button"
      i18n-label="@@Cancel"
      label="Cancel"
      id="eln-maintenanceEventTableCancelSelection"
      (click)="cancelSelection()"></p-button>
    <p-button
      styleClass="eln-standard-popup-button"
      id="eln-maintenanceEventTableSaveSelection"
      icon="pi pi-check"
      i18n-label="@@commit"
      [disabled]="disableActionButton"
      label="Commit"
      (click)="saveInstrumentEvent()"></p-button>
  </div>
</div>