import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ChangeReasonService } from '../services/change-reason.service';
import { BptControlSeverityIndicators, NA } from 'bpt-ui-library/shared';

@Component({
  selector: 'app-change-reason-renderer',
  template: `
              <div  class="eln-change-reason">
                <div>{{formattedChangeReasonDetails}}</div>
                <div class="change-reason-indicators">
                  <div *ngIf="changeReasonMissing" class="empty-content icon-exclamation-mark empty-change-reason-icon"></div>
                  <div *ngIf="canUpdateChangeReason"
                  id="eln-change-reason-icon"
                  class="icon-s icon-pencil eln-change-reason-icon"
                  (click)="updateChangeReason()"></div>
                </div>
              </div>
            `,
  styleUrls: ['./change-reason-renderer.component.scss']
})
export class ChangeReasonRendererComponent implements ICellRendererAngularComp {
  notApplicable = NA;
  canUpdateChangeReason = false;
  changeReasonMissing = false;
  indicator = BptControlSeverityIndicators.ServerityIndicators['empty'];
  refresh(): boolean {
    return false;
  }

  constructor(private readonly changeReasonService: ChangeReasonService) {}
  public params!: any;
  formattedChangeReasonDetails = '';

  agInit(params: any): void {
    this.params = params;
    this.formattedChangeReasonDetails = this.params.data.ChangeReason?.changeReasonDetails ?? NA;
    this.changeReasonMissing = this.params.data.ChangeReason?.changeReasonDetails === '';
    this.canUpdateChangeReason = this.params.data.ChangeReason?.canUpdate;
  }

  updateChangeReason() {
    ChangeReasonService.changeReasonId = this.params.data.ChangeReason.changeReasonId;
    this.changeReasonService.triggerSliderVisibility(true,
      this.params.data.ChangeReason.nodeId,
      this.params.data.ChangeReason.oldValue,
      this.params.data.Description,
      this.params.data.Context,
      true);
  }
}
