import { Component, Input, OnInit } from '@angular/core';
import { ClientValidationDetails } from 'model/client-validation-details';

@Component({
  selector: 'app-validation-summary',
  templateUrl: './validation-summary.component.html'
})
export class ValidationSummaryComponent implements OnInit {
  @Input() validation: ClientValidationDetails = new ClientValidationDetails();
  public ready = false;

  ngOnInit(): void {
    this.updateTranslationFromDynamicIds();
  }


  //** Translates the server messages based on translation key, for english the server message will be displayed as it is received  */
  private updateTranslationFromDynamicIds(): void{
    if(this.validation && this.validation.notificationResults.notifications.length > 0){
      this.validation.notificationResults.notifications.forEach(notification =>{
        const localize = this.getLocalize();
        // "Any" type can not avoided here as TemplateStringArray has many members which are not needed to be supplied from our end 
        const translatedMessage  = localize({ '0': `:@@${notification.translationKey}:${notification.translationKey}`, 'raw': [':'] } as any);  
        if(translatedMessage !== notification.translationKey){
          notification.message = translatedMessage;
        }
      })
    }
    this.ready = true;
  }

  clearNotifications(): void{
    this.validation.notificationResults.notifications = [];
  }

  getLocalize(){
    return $localize;
  }
  
}
