<form #form="ngForm"
  novalidate>
  <bpt-slider #slider
    id="crossReferenceSlider"
    class="cross-Reference-slider flex flex-auto flex-wrap flex-column"
    [isModal]="true"
    [size]="'medium'"
    [closeOnEscape]="true"
    [(visible)]="visible"
    (visibleChange)="sliderVisibleChange($event)"
    [headerText]="headerText"
    [displayFooter]="!readOnly"
    primaryButtonLabel="Ok"
    i18n-primaryButtonLabel="@@ok"
    [isPrimaryButtonDisabled]="!searchInput.modelValue"
    (footerPrimaryButtonClick)="onCommit()"
    secondaryButtonLabel="Cancel"
    i18n-secondaryButtonLabel="@@cancel"
    [isSecondaryButtonDisabled]="false"
    (footerSecondaryButtonClick)="onCancel()">
    <p class="p-0 eln-secondaryHeading">
      {{titleText}}
    </p>
    <bpt-text-input #searchInput
      class="eln-cross-reference-search-input"
      name="eln-cross-reference-search-input"
      [(ngModel)]="searchInputValue"
      (textInputBlur)="searchInputBlurred($event)"
      [placeholder]="placeholderText"
      [tabIndex]=1></bpt-text-input>
    <bpt-progress-spinner *ngIf="showLoadingSpinner"
      i18n-message="@@loading"
      message="Loading..."
      [useOverlay]="false"></bpt-progress-spinner>
  </bpt-slider>
</form>
