/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { AuditHistoryDataRecordResponse } from '../../models/audit-history-data-record-response';
import { ExperimentBelongsToContextCommand } from '../../models/experiment-belongs-to-context-command';

export interface LabItemsAuditHistoryExperimentIdPost$Json$Params {
  experimentId: string;
  'X-User-Puid'?: any;
  'X-User-LabsiteCode'?: any;

/**
 * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
 */
  'X-Experiment-PreviewMode'?: any;
      body?: ExperimentBelongsToContextCommand
}

export function labItemsAuditHistoryExperimentIdPost$Json(http: HttpClient, rootUrl: string, params: LabItemsAuditHistoryExperimentIdPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<AuditHistoryDataRecordResponse>> {
  const rb = new RequestBuilder(rootUrl, labItemsAuditHistoryExperimentIdPost$Json.PATH, 'post');
  if (params) {
    rb.path('experimentId', params.experimentId, {});
    rb.header('X-User-Puid', params['X-User-Puid'], {});
    rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
    rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    rb.body(params.body, 'application/*+json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<AuditHistoryDataRecordResponse>;
    })
  );
}

labItemsAuditHistoryExperimentIdPost$Json.PATH = '/lab-items-audit-history/{experimentId}';
