import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { BlazorStartService, IPreloadScriptResult } from './blazor-start.service';
import { RuleRouter } from '../../rule-engine/rule-router';
import { SpecificationService } from '../specification-input/specification.service';
import { RuleActionHandlerHostService } from '../../rule-engine/handler/rule-action-handler-host.service';
import { ExperimentService } from '../../experiment/services/experiment.service';

/**
 * A resolver that can be mapped to a router where blazor is required (for example: Experiment and Template Preview).
 * The resolution ensures that the start of blazor will occur on demand.
 * This can assist blazor in starting to load its resource as a router (experiment, template preview) prerequisite.
 * IE: When the promise was resolved, then blazor may not have completed its resource load,
 * but (window as any).RuleEngineLoadStatus can notify about its load status.
 */
export const blazorStartResolver: ResolveFn<IPreloadScriptResult> =
  async (_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) => {
    const preloadScriptResult = await inject(BlazorStartService).loadBlazor();
    
    if ((window as any).useRemoteRulesEngine) redefineDotNetInvokeMethodAsync();

    return preloadScriptResult;
  };
  
/** 
 * Intercepts DotNet.invokeMethodAsync to use a remote rules engine REST service instead of using Blazor. 
 * This is to more easily debug rules with full client and experiment context.
 * 
 * Note: Some "global" data is needed by rules so must be given to both Blazor and the remote rules service.
 *  ELN.Blazor.Entry CacheVariables
 *  ELN.Blazor.Entry CacheUnits
*/
function redefineDotNetInvokeMethodAsync() {
  const blazorInvokeMethodAsync = DotNet.invokeMethodAsync;
  const remoteRulesEngine = 'https://localhost:62994/';

  const endpoints = { 
    [RuleRouter.RuleMethod]: 'evaluate-rule',
    [RuleActionHandlerHostService.CacheVariables]: 'cache-variables',
    [ExperimentService.CacheUnits]: 'cache-units',
  }
  const headers = { 
    'Accept': 'application/json, text/plain',
    'Content-Type': 'application/json'
  };

  DotNet.invokeMethodAsync = async (assemblyName: string, methodIdentifier: string, ...args: any[]) => {
    if (assemblyName !== RuleRouter.Assembly) return blazorInvokeMethodAsync(assemblyName, methodIdentifier, ...args);

    const url = remoteRulesEngine + endpoints[methodIdentifier];
    switch (methodIdentifier) {
      case RuleRouter.RuleMethod:
        return (await fetch(url, { method: 'POST', headers, body: JSON.stringify({ rulesJson: args[0], inputJson: args[1] }) })).text() as Promise<any>;
      case RuleActionHandlerHostService.CacheVariables:
      case SpecificationService.CacheUnits:
        await (await fetch(url, { method: 'POST', headers, body: JSON.stringify(args[0]) })).text();
        return blazorInvokeMethodAsync(assemblyName, methodIdentifier, ...args);
      default: 
        return blazorInvokeMethodAsync(assemblyName, methodIdentifier, ...args);
    }
  }
}
