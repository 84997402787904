import { RuleActionNotificationService } from './action-notification/rule-action-notification.service';
import { RuleNotificationContext } from './actions/rule-action-notification';
import {
  CellChangedEventConverter,
  CellChangeEventContextFrom
} from './converters/cell-changed-event-converter';
import { RuleEventFilter } from './filters/rule-event-filter';
import { RuleRouter } from './rule-router';
import { TemplateRule } from './rule/template-rule';
import { v4 as uuidV4 } from 'uuid';
import { RuleEvaluationInputOf } from './inputs/rule-evaluation-Input';
import { RuleEvents } from '../api/models';
import { RuleCommandContext } from '../api/data-entry/models';
import { RuleEvent, TemplateRuleContext } from './events/rule-event-context';
import { RuleEventContextConverter } from './converters/rule-event-context-converter';
import { ContextFrom } from './converters/rule-base-converter';
import {
  RowAddedEventConverter,
  RowAddEventContextFrom
} from './converters/row-added-event-converter';
import {
  FieldChangedEventConverter,
  FieldChangeEventContextFrom
} from './converters/field-changed-event-converter';
import { Subject } from 'rxjs';
import { NodeLoadedEventConverter } from './converters/node-loaded-event-converter';

export type RuleProcessing = { correlationId: string; promise?: Promise<void> } | undefined;
type RuleCorrelatedEvent = string | undefined | RuleNotificationContext;
type SessionContext = { correlationId: string; context: RuleNotificationContext | undefined };
type RouteContext = {
  event: RuleEvents;
  context: string;
  eventSource: RuleCorrelatedEvent;
  rules: TemplateRule[];
  session: SessionContext;
  ruleCorrelationId: string;
  hasSession: boolean;
};

export abstract class RuleHandlerBase {
  public static RuleEngineLoadStatus = new Subject<boolean>();
  /** Templates will be using their instance ID in the experiment as rule engine ID */
  public readonly ruleEngineInstanceId: string;
  public readonly templateId: string;
  /** Rules configured in template. */
  public readonly rules: TemplateRule[];
  /** Rules queue. */
  public readonly ruleRouterSessions: { [sessionId: string]: RuleRouter } = {};
  /** Actions notification service used to notify rule evaluation result. */
  public readonly actionNotificationService: RuleActionNotificationService;
  protected readonly converters: { [event: string]: RuleEventContextConverter<ContextFrom> } = {
    cellChanged: new CellChangedEventConverter()
  };

  constructor(
    instanceId: string,
    templateId: string,
    rules: TemplateRule[],
    ruleActionNotificationService: RuleActionNotificationService
  ) {
    this.ruleEngineInstanceId = instanceId;
    this.rules = rules;
    this.actionNotificationService = ruleActionNotificationService;
    this.templateId = templateId;
  }

  protected prepareRouteContext(
    event: RuleEvents,
    context: string,
    eventSource: RuleCorrelatedEvent
  ): RouteContext {
    this.cleanUpRouterSession();
    const rules = this.filterByContext(event, context);
    const session = this.createOrGetRuleSession(eventSource);
    const ruleCorrelationId = session.correlationId;
    const hasSession = typeof this.ruleRouterSessions[ruleCorrelationId] !== 'undefined';
    return {
      event,
      context,
      eventSource,
      rules,
      session,
      ruleCorrelationId: ruleCorrelationId,
      hasSession
    };
  }

  /** next correlated rule in the queue to evaluation. */
  public continueOnCorrelatedRulesEvaluation(
    event: RuleEvents,
    dataToEventContext: ContextFrom,
    eventSource: RuleCorrelatedEvent
  ) {
    this.cleanUpRouterSession();
    const session = this.createOrGetRuleSession(eventSource);
    const ruleCorelationId = session.correlationId;
    if (!this.ruleRouterSessions[ruleCorelationId]) {
      return;
    }
    const eventContext = this.converters[event].convert(
      this.ruleEngineInstanceId,
      this.templateId,
      dataToEventContext
    );
    this.ruleRouterSessions[ruleCorelationId].routeNextRule(eventContext);
  }

  protected processRule(
    eventContext: RuleEvent<TemplateRuleContext>,
    routeContext: RouteContext
  ): RuleProcessing {
    // No rules found, but the source of event can be a rule hence resume queue process
    if (routeContext.rules.length === 0) {
      if (this.ruleRouterSessions[routeContext.ruleCorrelationId]) {
        this.ruleRouterSessions[routeContext.ruleCorrelationId].routeNextRule(eventContext);
        return { correlationId: routeContext.session.correlationId };
      }
      return undefined;
    }

    // Rules & session found, hence queue rules and then resume rule route
    if (routeContext.hasSession) {
      this.ruleRouterSessions[routeContext.ruleCorrelationId].queueRuleAndPrioritize(
         this.prepareRuleEvaluationInput(routeContext.rules, eventContext)
      );
      const promise = this.ruleRouterSessions[routeContext.ruleCorrelationId].evaluateNextRule();
      return  {correlationId: routeContext.session.correlationId, promise };
    } else {
      // Rules found but no session, create session and start rule route
      this.ruleRouterSessions[routeContext.ruleCorrelationId] = new RuleRouter(
        routeContext.ruleCorrelationId,
        this.actionNotificationService,
        this.prepareRuleEvaluationInput(routeContext.rules, eventContext)
      );
      const promise = this.ruleRouterSessions[routeContext.ruleCorrelationId].evaluateNextRule();
      return  {correlationId: routeContext.session.correlationId, promise };
    }
  }

  protected canRouteRule(routeContext: RouteContext): boolean {
    return routeContext.rules.length > 0 || routeContext.hasSession;
  }

  /** Filters the rules by context like cell field ID / form ID / template instance ID */
  private filterByContext(ruleEvent: RuleEvents, context: string): TemplateRule[] {
    const filter = new RuleEventFilter(ruleEvent);
    return filter.filterByContext(this.rules, context);
  }

  private prepareRuleEvaluationInput<TEventContext>(
    eventMatchingRules: TemplateRule[],
    eventContext: TEventContext
  ): RuleEvaluationInputOf<TEventContext>[] {
    return eventMatchingRules.map(
      (rule) => new RuleEvaluationInputOf<TEventContext>(rule, eventContext)
    );
  }

  protected cleanUpRouterSession() {
    Object.keys(this.ruleRouterSessions).forEach((ruleTrackingId) => {
      if (!this.ruleRouterSessions[ruleTrackingId].hasPendingRulesToEvaluate()) {
        delete this.ruleRouterSessions[ruleTrackingId];
      }
    });
  }

  /** 
   * Generates new GUID when first in the corelation rules is going to evaluate.
   * for the rest in the rules, will share GUID created.
  */
  protected createOrGetRuleSession(
    eventSource: string | undefined | RuleNotificationContext
  ): SessionContext {
    if (!eventSource || eventSource === 'nextRule') {
      return { correlationId: uuidV4(), context: undefined };
    }
    let ruleContext!: RuleNotificationContext;
    if ((eventSource as string)?.includes && (eventSource as string)?.includes('correlationId')) {
      ruleContext = JSON.parse(eventSource as string);
    }
    ruleContext = ruleContext || (eventSource as RuleNotificationContext);
    return { correlationId: ruleContext?.correlationId || uuidV4(), context: ruleContext };
  }

  /**
   * When actions are performed the event source contains the correlation rule information
   * which should be stored in data records.
  */
  public getRuleCommandContext(eventSource: string | undefined): RuleCommandContext | undefined {
    if (!eventSource) return undefined;
    
    if (eventSource?.includes && eventSource?.includes('correlationId')) {
      const _ruleContext: RuleNotificationContext = JSON.parse(eventSource);
      return _ruleContext.ruleCommandContext;
    } else {
      return undefined;
    }
  }
}

export class RuleHandler extends RuleHandlerBase {
  constructor(
    instanceId: string,
    templateId: string,
    rules: TemplateRule[],
    ruleActionNotificationService: RuleActionNotificationService
  ) {
    super(instanceId, templateId, rules, ruleActionNotificationService);
  }

  /** routes the rule if any, and returns session ID as correlationId on rule exists */
  public cellChanged(
    context: string,
    eventContext: CellChangeEventContextFrom,
    eventSource: RuleCorrelatedEvent
  ): RuleProcessing {
    const routeContext = this.prepareRouteContext(RuleEvents.CellChanged, context, eventSource);
    if (this.canRouteRule(routeContext)) {
      const cellChangedEventFullContext = new CellChangedEventConverter().convert(
        this.ruleEngineInstanceId,
        this.templateId,
        eventContext
      );
      return this.processRule(cellChangedEventFullContext, routeContext);
    }
    return undefined;
  }

  public rowAdded(
    context: string,
    eventContext: RowAddEventContextFrom,
    eventSource: RuleCorrelatedEvent
  ): RuleProcessing {
    const routeContext = this.prepareRouteContext(RuleEvents.RowAdded, context, eventSource);
    if (this.canRouteRule(routeContext)) {
      const rowAddedEventOfFullContext = new RowAddedEventConverter().convert(
        this.ruleEngineInstanceId,
        this.templateId,
        eventContext
      );
      return this.processRule(rowAddedEventOfFullContext, routeContext);
    }
    return undefined;
  }

  public fieldChanged(
    context: string,
    eventContext: FieldChangeEventContextFrom,
    eventSource: RuleCorrelatedEvent
  ): RuleProcessing {
    const routeContext = this.prepareRouteContext(RuleEvents.FieldChanged, context, eventSource);
    if (this.canRouteRule(routeContext)) {
      const fieldChangedEventOfFullContext = new FieldChangedEventConverter().convert(
        this.ruleEngineInstanceId,
        this.templateId,
        eventContext
      );
      return this.processRule(fieldChangedEventOfFullContext, routeContext);
    }
    return undefined;
  }

  public nodeLoaded(eventContext: { nodeId: string; templateId: string; rules: TemplateRule[]; }, eventSource: string): RuleProcessing {     
    const context = ''; // onLoad pertains only to the whole node so nothing more to say here. Rule Event must be empty string.
    const routeContext = this.prepareRouteContext(RuleEvents.OnLoad, context, eventSource);
    if (this.canRouteRule(routeContext)) {
      const eventWithFullContext = new NodeLoadedEventConverter().convert(
        this.ruleEngineInstanceId,
        eventContext.templateId,
        eventContext
      );
      return this.processRule(eventWithFullContext, routeContext);
    }
    return undefined;
  }
}
