import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ActivityInputType } from '../../../api/models';
import { LabItemLabelsByType, LabItemsService } from '../lab-items.service';

export type RowActionParams = {
  styleClass: string;
  displayRestoreActions?: boolean;
  permissions: {
    [key: string]: boolean;
  };
};

@Component({
  selector: 'app-eln-grid-row-actions',
  templateUrl: './bpt-eln-grid-row-actions.component.html'
})
export class BptElnGridRowActionsComponent implements ICellRendererAngularComp {
  public readonly ControlsDefinition: { [key: string]: { id: string; styleClass: string } } = {
    removeItem: { id: 'eln-remove-lab-item', styleClass: '' },
    refreshItem: { id: 'eln-refresh-lab-item', styleClass: '' },
    restoreItem: { id: 'eln-restore-lab-item', styleClass: '' },
    actionsContainer: { id: 'actions-container', styleClass: '' }
  };
  public static readonly RowActionsWidth = 85;
  public params!: any;
  public cellValue: any;
  tooltips: LabItemLabelsByType = {
    heading: '',
    singularHeading: '',
    restoredTooltip: '',
    refreshTooltip: '',
    removedTooltip: ''
  };

  public actionsContainerStyleClass = '';
  constructor(private readonly labItemsService: LabItemsService) {
    this.watchReadOnlyState();
  }
  // gets called once before the renderer is used
  agInit(params: ICellRendererParams): void {
    params.data.id = params.data.code;
    this.params = params;
    this.updateActionsContainerClass();

    this.tooltips = LabItemsService.labItemsLabelsByItemType(this.params.itemType);
  }

  // gets called whenever the cell refreshes
  refresh(params: ICellRendererParams): boolean {
    // set value into cell again
    this.params = params;
    this.updateActionsContainerClass();
    return true;
  }

  // gets called whenever the row is deleted
  removeItem() {
    if (
      !(this.params.rowActionParams as RowActionParams).permissions[
        this.ControlsDefinition.removeItem.id
      ]
    ) {
      return;
    }
    else
    {
      this.labItemsService.removeHandler[this.params.itemType as ActivityInputType](this.params.data);
    }
   
  }

  refreshItem() {
    if (
      !(this.params.rowActionParams as RowActionParams).permissions[
        this.ControlsDefinition.refreshItem.id
      ]
    ) {
      return;
    }
    try {
      this.params['refreshing'] = true;
      this.labItemsService.refreshHandler[this.params.itemType as ActivityInputType](
        this.params.data
      ).subscribe({
        next: () => {
          this.params['refreshing'] = false;
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  restoreRow() {
    if (
      !(this.params.rowActionParams as RowActionParams).permissions[
        this.ControlsDefinition.restoreItem.id
      ]
    ) {
      return;
    }
    this.labItemsService.restoreHandler[this.params.itemType](this.params.data);
  }

  watchReadOnlyState() {
    this.labItemsService.LabItemsFeatureAccessChange.subscribe({
      next: (permissions) => {
        (this.params.rowActionParams as RowActionParams).permissions =
          permissions[this.params.itemType];
        this.updateActionsContainerClass();
      }
    });
  }

  private updateActionsContainerClass() {
    this.initializeActionParamsToDefaults();
    const rowActionParams = this.params.rowActionParams as RowActionParams;
    Object.keys(rowActionParams.permissions).forEach((key) => {
      const styleClass = rowActionParams.permissions[key] ? '' : 'disabled';
      this.updateStyleClassToControl(key, styleClass);
    });
    const actionsContainerStyleClass = `${rowActionParams.styleClass} ${
      !rowActionParams.permissions.enabled ? 'disabled' : ''
    }`;
    this.updateStyleClassToControl(
      this.ControlsDefinition.actionsContainer.id,
      actionsContainerStyleClass
    );
  }

  public initializeActionParamsToDefaults() {
    this.params.rowActionParams = this.params.rowActionParams || {};
    this.params.rowActionParams.permissions =
      this.params.rowActionParams.permissions || this.GetDefaultPermissions();
  }

  private GetDefaultPermissions(): { [key: string]: boolean } {
    const permissions: { [key: string]: boolean } = {
      enabled: true
    };
    Object.keys(this.ControlsDefinition).forEach((key) => {
      permissions[this.ControlsDefinition[key].id] = true;
    });
    return permissions;
  }

  private updateStyleClassToControl(id: string, styleClass: string) {
    Object.keys(this.ControlsDefinition).forEach((key) => {
      if (this.ControlsDefinition[key].id === id) {
        this.ControlsDefinition[key].styleClass = styleClass;
      }
    });
  }
}
