<ng-container class="bpt-grid-toolbar-left-layout">
  <span class="font-semibold">{{ title }}</span>
</ng-container>

<bpt-grid
  #promptGrid
  *ngIf="reloadGrid"
  class="eln-grid eln-prompt-grid"
  [columnDefinitions]="columnDefinitions"
  [includeRowNumberColumn]="gridOptions.includeRowNumberColumn"
  [showAutoSizeButton]="gridOptions.showAutoSizeButton"
  [showFilterToggleButton]="gridOptions.showFilterToggleButton"
  [showGridOptionsButton]="gridOptions.showGridOptionsButton"
  [allowRowAdd]="gridOptions.allowRowAdd"
  [beforeAddRow]="beforeAddRow"
  [addMultipleRows]="gridOptions.addMultipleRows"
  [dataSource]="primitiveValue"
  [gridMode]="gridOptions.gridMode"
  [suppressContextMenu]="false"
  (cellValueChanged)="cellValueChanged($event)"
  (valuesPasted)="pasteValues($event)"
  [rowActionConfigurations]="gridActions"
  [contextMenu]="getContextMenu()"
  (mousedown)="$event.stopPropagation()"
  [id]="promptType"
  [readOnly]="readOnly || !accessFlag"
>
  <ng-container class="bpt-grid-toolbar-right-layout">
    <a *ngIf="this.removedRows.length > 0"
    class="bpt-grid-toolbar-item table-grid-toolbar-view-removed-rows-icon reference-remove-icon"
    (click)="loadRemovedPromptsDialog()">
    <span
      class="fa icon-view-row"
      i18n-pTooltip="@@viewRemovedRows"
      pTooltip="View Removed Rows"
      tooltipPosition="top"
      ><span class="ml-1 viewRemovedRows-iconTxt" i18n="@@viewRemovedRows"
        >View Removed Rows</span
      ></span
    >
  </a>
  </ng-container>
</bpt-grid>
