import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BptProgressSpinnerComponent, BptProgressSpinnerModule } from 'bpt-ui-library/bpt-progress-spinner';
import { ElnProgressSpinnerComponentComponent } from './eln-progress-spinner-component/eln-progress-spinner-component.component';

@NgModule({
  declarations: [
    ElnProgressSpinnerComponentComponent
  ],
  imports: [
    CommonModule,
    BptProgressSpinnerModule
  ],
  exports:[
    ElnProgressSpinnerComponentComponent,
    BptProgressSpinnerComponent
  ]
})
export class ElnProgressSpinnerModule { }
