<div class="warningBoxPosition">
  <div *ngIf="isReviewer">
    <div *ngIf="validation.warnings!.length > 0">
      <p-messages severity="warn">
        <ng-template pTemplate>
          <div class="inline-flex align-items-center">
            <span class="p-message-icon pi pi-exclamation-triangle"></span>
            <div class="importantStyle">
              <span style="font-size: 14px;"> {{ validation.warningTitle }}
                <button class="seeMoreOrLess" (click)="toggleDetails()">{{expandStyle}}</button>
              </span>
            </div>
          </div>

          <ul *ngIf="!hideDetails">
            <li style="font-size: 14px;" *ngFor="let warn of validation.warnings">{{ warn }}</li>
          </ul>
        </ng-template>
      </p-messages>
    </div>
  </div>
</div>