/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CommentsResponse } from '../models/comments-response';
import { experimentsExperimentIdInternalCommentsAddPost$Json } from '../fn/internal-comment/experiments-experiment-id-internal-comments-add-post-json';
import { ExperimentsExperimentIdInternalCommentsAddPost$Json$Params } from '../fn/internal-comment/experiments-experiment-id-internal-comments-add-post-json';
import { experimentsExperimentIdInternalCommentsAddPost$Plain } from '../fn/internal-comment/experiments-experiment-id-internal-comments-add-post-plain';
import { ExperimentsExperimentIdInternalCommentsAddPost$Plain$Params } from '../fn/internal-comment/experiments-experiment-id-internal-comments-add-post-plain';
import { experimentsExperimentIdInternalCommentsCommentIdAddPost$Json } from '../fn/internal-comment/experiments-experiment-id-internal-comments-comment-id-add-post-json';
import { ExperimentsExperimentIdInternalCommentsCommentIdAddPost$Json$Params } from '../fn/internal-comment/experiments-experiment-id-internal-comments-comment-id-add-post-json';
import { experimentsExperimentIdInternalCommentsCommentIdAddPost$Plain } from '../fn/internal-comment/experiments-experiment-id-internal-comments-comment-id-add-post-plain';
import { ExperimentsExperimentIdInternalCommentsCommentIdAddPost$Plain$Params } from '../fn/internal-comment/experiments-experiment-id-internal-comments-comment-id-add-post-plain';
import { experimentsExperimentIdInternalCommentsCommentIdDelete$Json } from '../fn/internal-comment/experiments-experiment-id-internal-comments-comment-id-delete-json';
import { ExperimentsExperimentIdInternalCommentsCommentIdDelete$Json$Params } from '../fn/internal-comment/experiments-experiment-id-internal-comments-comment-id-delete-json';
import { experimentsExperimentIdInternalCommentsCommentIdDelete$Plain } from '../fn/internal-comment/experiments-experiment-id-internal-comments-comment-id-delete-plain';
import { ExperimentsExperimentIdInternalCommentsCommentIdDelete$Plain$Params } from '../fn/internal-comment/experiments-experiment-id-internal-comments-comment-id-delete-plain';
import { experimentsExperimentIdInternalCommentsCommentIdEditPut$Json } from '../fn/internal-comment/experiments-experiment-id-internal-comments-comment-id-edit-put-json';
import { ExperimentsExperimentIdInternalCommentsCommentIdEditPut$Json$Params } from '../fn/internal-comment/experiments-experiment-id-internal-comments-comment-id-edit-put-json';
import { experimentsExperimentIdInternalCommentsCommentIdEditPut$Plain } from '../fn/internal-comment/experiments-experiment-id-internal-comments-comment-id-edit-put-plain';
import { ExperimentsExperimentIdInternalCommentsCommentIdEditPut$Plain$Params } from '../fn/internal-comment/experiments-experiment-id-internal-comments-comment-id-edit-put-plain';
import { experimentsExperimentIdInternalCommentsCommentIdReopenPut$Json } from '../fn/internal-comment/experiments-experiment-id-internal-comments-comment-id-reopen-put-json';
import { ExperimentsExperimentIdInternalCommentsCommentIdReopenPut$Json$Params } from '../fn/internal-comment/experiments-experiment-id-internal-comments-comment-id-reopen-put-json';
import { experimentsExperimentIdInternalCommentsCommentIdReopenPut$Plain } from '../fn/internal-comment/experiments-experiment-id-internal-comments-comment-id-reopen-put-plain';
import { ExperimentsExperimentIdInternalCommentsCommentIdReopenPut$Plain$Params } from '../fn/internal-comment/experiments-experiment-id-internal-comments-comment-id-reopen-put-plain';
import { experimentsExperimentIdInternalCommentsCommentIdRepliesIndexReplyIndexDelete$Json } from '../fn/internal-comment/experiments-experiment-id-internal-comments-comment-id-replies-index-reply-index-delete-json';
import { ExperimentsExperimentIdInternalCommentsCommentIdRepliesIndexReplyIndexDelete$Json$Params } from '../fn/internal-comment/experiments-experiment-id-internal-comments-comment-id-replies-index-reply-index-delete-json';
import { experimentsExperimentIdInternalCommentsCommentIdRepliesIndexReplyIndexDelete$Plain } from '../fn/internal-comment/experiments-experiment-id-internal-comments-comment-id-replies-index-reply-index-delete-plain';
import { ExperimentsExperimentIdInternalCommentsCommentIdRepliesIndexReplyIndexDelete$Plain$Params } from '../fn/internal-comment/experiments-experiment-id-internal-comments-comment-id-replies-index-reply-index-delete-plain';
import { experimentsExperimentIdInternalCommentsCommentIdRepliesReplyIndexEditPut$Json } from '../fn/internal-comment/experiments-experiment-id-internal-comments-comment-id-replies-reply-index-edit-put-json';
import { ExperimentsExperimentIdInternalCommentsCommentIdRepliesReplyIndexEditPut$Json$Params } from '../fn/internal-comment/experiments-experiment-id-internal-comments-comment-id-replies-reply-index-edit-put-json';
import { experimentsExperimentIdInternalCommentsCommentIdRepliesReplyIndexEditPut$Plain } from '../fn/internal-comment/experiments-experiment-id-internal-comments-comment-id-replies-reply-index-edit-put-plain';
import { ExperimentsExperimentIdInternalCommentsCommentIdRepliesReplyIndexEditPut$Plain$Params } from '../fn/internal-comment/experiments-experiment-id-internal-comments-comment-id-replies-reply-index-edit-put-plain';
import { experimentsExperimentIdInternalCommentsCommentIdResolvePut$Json } from '../fn/internal-comment/experiments-experiment-id-internal-comments-comment-id-resolve-put-json';
import { ExperimentsExperimentIdInternalCommentsCommentIdResolvePut$Json$Params } from '../fn/internal-comment/experiments-experiment-id-internal-comments-comment-id-resolve-put-json';
import { experimentsExperimentIdInternalCommentsCommentIdResolvePut$Plain } from '../fn/internal-comment/experiments-experiment-id-internal-comments-comment-id-resolve-put-plain';
import { ExperimentsExperimentIdInternalCommentsCommentIdResolvePut$Plain$Params } from '../fn/internal-comment/experiments-experiment-id-internal-comments-comment-id-resolve-put-plain';
import { experimentsExperimentIdInternalCommentsGet$Json } from '../fn/internal-comment/experiments-experiment-id-internal-comments-get-json';
import { ExperimentsExperimentIdInternalCommentsGet$Json$Params } from '../fn/internal-comment/experiments-experiment-id-internal-comments-get-json';
import { experimentsExperimentIdInternalCommentsGet$Plain } from '../fn/internal-comment/experiments-experiment-id-internal-comments-get-plain';
import { ExperimentsExperimentIdInternalCommentsGet$Plain$Params } from '../fn/internal-comment/experiments-experiment-id-internal-comments-get-plain';
import { ReplyAddedResponse } from '../models/reply-added-response';
import { ReplyDeletedResponse } from '../models/reply-deleted-response';
import { ReplyEditedResponse } from '../models/reply-edited-response';
import { ThreadAddedResponse } from '../models/thread-added-response';
import { ThreadEditedResponse } from '../models/thread-edited-response';
import { ThreadRemovedResponse } from '../models/thread-removed-response';
import { ThreadStateChangeResponse } from '../models/thread-state-change-response';

@Injectable({ providedIn: 'root' })
export class InternalCommentService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `experimentsExperimentIdInternalCommentsGet()` */
  static readonly ExperimentsExperimentIdInternalCommentsGetPath = '/experiments/{experimentId}/internal-comments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentsExperimentIdInternalCommentsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentsExperimentIdInternalCommentsGet$Plain$Response(params: ExperimentsExperimentIdInternalCommentsGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CommentsResponse>> {
    return experimentsExperimentIdInternalCommentsGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentsExperimentIdInternalCommentsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentsExperimentIdInternalCommentsGet$Plain(params: ExperimentsExperimentIdInternalCommentsGet$Plain$Params, context?: HttpContext): Observable<CommentsResponse> {
    return this.experimentsExperimentIdInternalCommentsGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CommentsResponse>): CommentsResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentsExperimentIdInternalCommentsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentsExperimentIdInternalCommentsGet$Json$Response(params: ExperimentsExperimentIdInternalCommentsGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<CommentsResponse>> {
    return experimentsExperimentIdInternalCommentsGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentsExperimentIdInternalCommentsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentsExperimentIdInternalCommentsGet$Json(params: ExperimentsExperimentIdInternalCommentsGet$Json$Params, context?: HttpContext): Observable<CommentsResponse> {
    return this.experimentsExperimentIdInternalCommentsGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CommentsResponse>): CommentsResponse => r.body)
    );
  }

  /** Path part for operation `experimentsExperimentIdInternalCommentsAddPost()` */
  static readonly ExperimentsExperimentIdInternalCommentsAddPostPath = '/experiments/{experimentId}/internal-comments/add';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentsExperimentIdInternalCommentsAddPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentsExperimentIdInternalCommentsAddPost$Plain$Response(params: ExperimentsExperimentIdInternalCommentsAddPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ThreadAddedResponse>> {
    return experimentsExperimentIdInternalCommentsAddPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentsExperimentIdInternalCommentsAddPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentsExperimentIdInternalCommentsAddPost$Plain(params: ExperimentsExperimentIdInternalCommentsAddPost$Plain$Params, context?: HttpContext): Observable<ThreadAddedResponse> {
    return this.experimentsExperimentIdInternalCommentsAddPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ThreadAddedResponse>): ThreadAddedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentsExperimentIdInternalCommentsAddPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentsExperimentIdInternalCommentsAddPost$Json$Response(params: ExperimentsExperimentIdInternalCommentsAddPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ThreadAddedResponse>> {
    return experimentsExperimentIdInternalCommentsAddPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentsExperimentIdInternalCommentsAddPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentsExperimentIdInternalCommentsAddPost$Json(params: ExperimentsExperimentIdInternalCommentsAddPost$Json$Params, context?: HttpContext): Observable<ThreadAddedResponse> {
    return this.experimentsExperimentIdInternalCommentsAddPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ThreadAddedResponse>): ThreadAddedResponse => r.body)
    );
  }

  /** Path part for operation `experimentsExperimentIdInternalCommentsCommentIdEditPut()` */
  static readonly ExperimentsExperimentIdInternalCommentsCommentIdEditPutPath = '/experiments/{experimentId}/internal-comments/{commentId}/edit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentsExperimentIdInternalCommentsCommentIdEditPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentsExperimentIdInternalCommentsCommentIdEditPut$Plain$Response(params: ExperimentsExperimentIdInternalCommentsCommentIdEditPut$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ThreadEditedResponse>> {
    return experimentsExperimentIdInternalCommentsCommentIdEditPut$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentsExperimentIdInternalCommentsCommentIdEditPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentsExperimentIdInternalCommentsCommentIdEditPut$Plain(params: ExperimentsExperimentIdInternalCommentsCommentIdEditPut$Plain$Params, context?: HttpContext): Observable<ThreadEditedResponse> {
    return this.experimentsExperimentIdInternalCommentsCommentIdEditPut$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ThreadEditedResponse>): ThreadEditedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentsExperimentIdInternalCommentsCommentIdEditPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentsExperimentIdInternalCommentsCommentIdEditPut$Json$Response(params: ExperimentsExperimentIdInternalCommentsCommentIdEditPut$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ThreadEditedResponse>> {
    return experimentsExperimentIdInternalCommentsCommentIdEditPut$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentsExperimentIdInternalCommentsCommentIdEditPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentsExperimentIdInternalCommentsCommentIdEditPut$Json(params: ExperimentsExperimentIdInternalCommentsCommentIdEditPut$Json$Params, context?: HttpContext): Observable<ThreadEditedResponse> {
    return this.experimentsExperimentIdInternalCommentsCommentIdEditPut$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ThreadEditedResponse>): ThreadEditedResponse => r.body)
    );
  }

  /** Path part for operation `experimentsExperimentIdInternalCommentsCommentIdResolvePut()` */
  static readonly ExperimentsExperimentIdInternalCommentsCommentIdResolvePutPath = '/experiments/{experimentId}/internal-comments/{commentId}/resolve';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentsExperimentIdInternalCommentsCommentIdResolvePut$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentsExperimentIdInternalCommentsCommentIdResolvePut$Plain$Response(params: ExperimentsExperimentIdInternalCommentsCommentIdResolvePut$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ThreadStateChangeResponse>> {
    return experimentsExperimentIdInternalCommentsCommentIdResolvePut$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentsExperimentIdInternalCommentsCommentIdResolvePut$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentsExperimentIdInternalCommentsCommentIdResolvePut$Plain(params: ExperimentsExperimentIdInternalCommentsCommentIdResolvePut$Plain$Params, context?: HttpContext): Observable<ThreadStateChangeResponse> {
    return this.experimentsExperimentIdInternalCommentsCommentIdResolvePut$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ThreadStateChangeResponse>): ThreadStateChangeResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentsExperimentIdInternalCommentsCommentIdResolvePut$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentsExperimentIdInternalCommentsCommentIdResolvePut$Json$Response(params: ExperimentsExperimentIdInternalCommentsCommentIdResolvePut$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ThreadStateChangeResponse>> {
    return experimentsExperimentIdInternalCommentsCommentIdResolvePut$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentsExperimentIdInternalCommentsCommentIdResolvePut$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentsExperimentIdInternalCommentsCommentIdResolvePut$Json(params: ExperimentsExperimentIdInternalCommentsCommentIdResolvePut$Json$Params, context?: HttpContext): Observable<ThreadStateChangeResponse> {
    return this.experimentsExperimentIdInternalCommentsCommentIdResolvePut$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ThreadStateChangeResponse>): ThreadStateChangeResponse => r.body)
    );
  }

  /** Path part for operation `experimentsExperimentIdInternalCommentsCommentIdReopenPut()` */
  static readonly ExperimentsExperimentIdInternalCommentsCommentIdReopenPutPath = '/experiments/{experimentId}/internal-comments/{commentId}/reopen';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentsExperimentIdInternalCommentsCommentIdReopenPut$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentsExperimentIdInternalCommentsCommentIdReopenPut$Plain$Response(params: ExperimentsExperimentIdInternalCommentsCommentIdReopenPut$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ThreadStateChangeResponse>> {
    return experimentsExperimentIdInternalCommentsCommentIdReopenPut$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentsExperimentIdInternalCommentsCommentIdReopenPut$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentsExperimentIdInternalCommentsCommentIdReopenPut$Plain(params: ExperimentsExperimentIdInternalCommentsCommentIdReopenPut$Plain$Params, context?: HttpContext): Observable<ThreadStateChangeResponse> {
    return this.experimentsExperimentIdInternalCommentsCommentIdReopenPut$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ThreadStateChangeResponse>): ThreadStateChangeResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentsExperimentIdInternalCommentsCommentIdReopenPut$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentsExperimentIdInternalCommentsCommentIdReopenPut$Json$Response(params: ExperimentsExperimentIdInternalCommentsCommentIdReopenPut$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ThreadStateChangeResponse>> {
    return experimentsExperimentIdInternalCommentsCommentIdReopenPut$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentsExperimentIdInternalCommentsCommentIdReopenPut$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentsExperimentIdInternalCommentsCommentIdReopenPut$Json(params: ExperimentsExperimentIdInternalCommentsCommentIdReopenPut$Json$Params, context?: HttpContext): Observable<ThreadStateChangeResponse> {
    return this.experimentsExperimentIdInternalCommentsCommentIdReopenPut$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ThreadStateChangeResponse>): ThreadStateChangeResponse => r.body)
    );
  }

  /** Path part for operation `experimentsExperimentIdInternalCommentsCommentIdDelete()` */
  static readonly ExperimentsExperimentIdInternalCommentsCommentIdDeletePath = '/experiments/{experimentId}/internal-comments/{commentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentsExperimentIdInternalCommentsCommentIdDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentsExperimentIdInternalCommentsCommentIdDelete$Plain$Response(params: ExperimentsExperimentIdInternalCommentsCommentIdDelete$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ThreadRemovedResponse>> {
    return experimentsExperimentIdInternalCommentsCommentIdDelete$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentsExperimentIdInternalCommentsCommentIdDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentsExperimentIdInternalCommentsCommentIdDelete$Plain(params: ExperimentsExperimentIdInternalCommentsCommentIdDelete$Plain$Params, context?: HttpContext): Observable<ThreadRemovedResponse> {
    return this.experimentsExperimentIdInternalCommentsCommentIdDelete$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ThreadRemovedResponse>): ThreadRemovedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentsExperimentIdInternalCommentsCommentIdDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentsExperimentIdInternalCommentsCommentIdDelete$Json$Response(params: ExperimentsExperimentIdInternalCommentsCommentIdDelete$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ThreadRemovedResponse>> {
    return experimentsExperimentIdInternalCommentsCommentIdDelete$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentsExperimentIdInternalCommentsCommentIdDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentsExperimentIdInternalCommentsCommentIdDelete$Json(params: ExperimentsExperimentIdInternalCommentsCommentIdDelete$Json$Params, context?: HttpContext): Observable<ThreadRemovedResponse> {
    return this.experimentsExperimentIdInternalCommentsCommentIdDelete$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ThreadRemovedResponse>): ThreadRemovedResponse => r.body)
    );
  }

  /** Path part for operation `experimentsExperimentIdInternalCommentsCommentIdAddPost()` */
  static readonly ExperimentsExperimentIdInternalCommentsCommentIdAddPostPath = '/experiments/{experimentId}/internal-comments/{commentId}/add';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentsExperimentIdInternalCommentsCommentIdAddPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentsExperimentIdInternalCommentsCommentIdAddPost$Plain$Response(params: ExperimentsExperimentIdInternalCommentsCommentIdAddPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ReplyAddedResponse>> {
    return experimentsExperimentIdInternalCommentsCommentIdAddPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentsExperimentIdInternalCommentsCommentIdAddPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentsExperimentIdInternalCommentsCommentIdAddPost$Plain(params: ExperimentsExperimentIdInternalCommentsCommentIdAddPost$Plain$Params, context?: HttpContext): Observable<ReplyAddedResponse> {
    return this.experimentsExperimentIdInternalCommentsCommentIdAddPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReplyAddedResponse>): ReplyAddedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentsExperimentIdInternalCommentsCommentIdAddPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentsExperimentIdInternalCommentsCommentIdAddPost$Json$Response(params: ExperimentsExperimentIdInternalCommentsCommentIdAddPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ReplyAddedResponse>> {
    return experimentsExperimentIdInternalCommentsCommentIdAddPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentsExperimentIdInternalCommentsCommentIdAddPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentsExperimentIdInternalCommentsCommentIdAddPost$Json(params: ExperimentsExperimentIdInternalCommentsCommentIdAddPost$Json$Params, context?: HttpContext): Observable<ReplyAddedResponse> {
    return this.experimentsExperimentIdInternalCommentsCommentIdAddPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReplyAddedResponse>): ReplyAddedResponse => r.body)
    );
  }

  /** Path part for operation `experimentsExperimentIdInternalCommentsCommentIdRepliesReplyIndexEditPut()` */
  static readonly ExperimentsExperimentIdInternalCommentsCommentIdRepliesReplyIndexEditPutPath = '/experiments/{experimentId}/internal-comments/{commentId}/Replies/{replyIndex}/edit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentsExperimentIdInternalCommentsCommentIdRepliesReplyIndexEditPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentsExperimentIdInternalCommentsCommentIdRepliesReplyIndexEditPut$Plain$Response(params: ExperimentsExperimentIdInternalCommentsCommentIdRepliesReplyIndexEditPut$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ReplyEditedResponse>> {
    return experimentsExperimentIdInternalCommentsCommentIdRepliesReplyIndexEditPut$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentsExperimentIdInternalCommentsCommentIdRepliesReplyIndexEditPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentsExperimentIdInternalCommentsCommentIdRepliesReplyIndexEditPut$Plain(params: ExperimentsExperimentIdInternalCommentsCommentIdRepliesReplyIndexEditPut$Plain$Params, context?: HttpContext): Observable<ReplyEditedResponse> {
    return this.experimentsExperimentIdInternalCommentsCommentIdRepliesReplyIndexEditPut$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReplyEditedResponse>): ReplyEditedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentsExperimentIdInternalCommentsCommentIdRepliesReplyIndexEditPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentsExperimentIdInternalCommentsCommentIdRepliesReplyIndexEditPut$Json$Response(params: ExperimentsExperimentIdInternalCommentsCommentIdRepliesReplyIndexEditPut$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ReplyEditedResponse>> {
    return experimentsExperimentIdInternalCommentsCommentIdRepliesReplyIndexEditPut$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentsExperimentIdInternalCommentsCommentIdRepliesReplyIndexEditPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentsExperimentIdInternalCommentsCommentIdRepliesReplyIndexEditPut$Json(params: ExperimentsExperimentIdInternalCommentsCommentIdRepliesReplyIndexEditPut$Json$Params, context?: HttpContext): Observable<ReplyEditedResponse> {
    return this.experimentsExperimentIdInternalCommentsCommentIdRepliesReplyIndexEditPut$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReplyEditedResponse>): ReplyEditedResponse => r.body)
    );
  }

  /** Path part for operation `experimentsExperimentIdInternalCommentsCommentIdRepliesIndexReplyIndexDelete()` */
  static readonly ExperimentsExperimentIdInternalCommentsCommentIdRepliesIndexReplyIndexDeletePath = '/experiments/{experimentId}/internal-comments/{commentId}/Replies/index/{replyIndex}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentsExperimentIdInternalCommentsCommentIdRepliesIndexReplyIndexDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentsExperimentIdInternalCommentsCommentIdRepliesIndexReplyIndexDelete$Plain$Response(params: ExperimentsExperimentIdInternalCommentsCommentIdRepliesIndexReplyIndexDelete$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ReplyDeletedResponse>> {
    return experimentsExperimentIdInternalCommentsCommentIdRepliesIndexReplyIndexDelete$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentsExperimentIdInternalCommentsCommentIdRepliesIndexReplyIndexDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentsExperimentIdInternalCommentsCommentIdRepliesIndexReplyIndexDelete$Plain(params: ExperimentsExperimentIdInternalCommentsCommentIdRepliesIndexReplyIndexDelete$Plain$Params, context?: HttpContext): Observable<ReplyDeletedResponse> {
    return this.experimentsExperimentIdInternalCommentsCommentIdRepliesIndexReplyIndexDelete$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReplyDeletedResponse>): ReplyDeletedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentsExperimentIdInternalCommentsCommentIdRepliesIndexReplyIndexDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentsExperimentIdInternalCommentsCommentIdRepliesIndexReplyIndexDelete$Json$Response(params: ExperimentsExperimentIdInternalCommentsCommentIdRepliesIndexReplyIndexDelete$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ReplyDeletedResponse>> {
    return experimentsExperimentIdInternalCommentsCommentIdRepliesIndexReplyIndexDelete$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentsExperimentIdInternalCommentsCommentIdRepliesIndexReplyIndexDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentsExperimentIdInternalCommentsCommentIdRepliesIndexReplyIndexDelete$Json(params: ExperimentsExperimentIdInternalCommentsCommentIdRepliesIndexReplyIndexDelete$Json$Params, context?: HttpContext): Observable<ReplyDeletedResponse> {
    return this.experimentsExperimentIdInternalCommentsCommentIdRepliesIndexReplyIndexDelete$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReplyDeletedResponse>): ReplyDeletedResponse => r.body)
    );
  }

}
