<div class="eln-activity-prompt">
  <div class="eln-activity-prompt-header">
    <span id="eln-promptModuleHeader" class="eln-prompt-header"
      >{{ promptModuleHeader }}
      <span
        id="eln-promptIconHelperTextShow"
        i18n-pTooltip="@@activityPromptHelperText"
        class="pi pi-info-circle eln-prompt-info"
        pTooltip="Add, View, Edit Prompt"
        tooltipPosition="top"
      ></span
    ></span>
    <div class="inline-flex ml-auto column-gap-3">
      <div class="prompt-page-primary-btn">
        <bpt-menu-button
          [label]="promptButton.label"
          [model]="promptButton.options"
          [disabled]="!enableAddPrompt"
          [stroked]="true"
          [compact]="true"
          [ngClass]="{
            'add-prompt-menu-button-enabled': enableAddPrompt,
            'add-prompt-menu-button-disabled': !enableAddPrompt
          }"
          id="eln-btnAddPrompt"
        ></bpt-menu-button>
      </div>
    </div>
  </div>
  <div *ngIf="prompts.length > 0; then promptGrid; else noPrompt"></div>
</div>

<ng-template #noPrompt>
  <div
    class="prompt-container eln-activity-prompt-no-data flex align-items-center justify-content-center"
  >
    <div class="eln-activity-prompt-no-data-img flex flex-column align-items-center">
      <img
        src="../../../assets/eln-assets/no-data.svg"
        alt=""
        id="eln-prompt-no-data-img"
        class="prompt-no-data-img"
      />
      <span class="activity-prompt-no-data-text mt-4">{{ noPromptsAvailableYet }}</span>
      <span class="activity-prompt-no-data-text">{{ promptsWillAppearHere }}</span>
      <bpt-menu-button
        class="mt-4 eln-add-prompt-no-data-btn"
        [label]="promptButton.label"
        [model]="promptButton.options"
        [disabled]="!enableAddPrompt"
        [stroked]="false"
        [compact]="false"
        [ngClass]="{ 
          'add-prompt-menu-button-enabled': enableAddPrompt,
          'add-prompt-menu-button-disabled': !enableAddPrompt,
         }"
        id="eln-btnAddPrompt-noData"
      ></bpt-menu-button>
    </div>
  </div>
</ng-template>

<ng-template #promptGrid>
  <ng-container *ngFor="let item of prompts">
    <div class="prompt-container">
      <app-prompt-grid
        (openPromptSlider)="openPromptSlider($event)"
        (cellEditedEvent)="handleCellEditedInPrompt($event)"
        (removePromptEvent)="removePromptData.emit($event)"
        (restorePromptEvent)="restorePromptData.emit($event)"
        [columnDefinitions]="getColumnDefinitions(item.type)"
        [promptType]="item.type"
        [primitiveValue]="item.data"
        [accessFlag]="enableAddPrompt"
        [readOnly]="promptsReadOnly"
      ></app-prompt-grid>
    </div>
  </ng-container>
</ng-template>

<app-add-prompt-slider
  *ngIf="showSlider"
  (closeSlider)="closeSlider()"
  (createPromptClicked)="handleCreatePrompt($event)"
  (updatePromptClicked)="handleUpdatePrompt($event)"
  [promptSliderHeaderLabel]="promptSliderHeaderLabel"
  [promptType]="selectedPromptType"
  [currentPromptItemValues]="currentPromptItemValues"
></app-add-prompt-slider>

<p-toast></p-toast>
