<div class="eln-instrument-selection">
  <bpt-dropdown
    #instrumentSelection
    [(ngModel)]="instrumentType"
    [ngModelOptions]="{ updateOn: 'blur' }"
    [options]="instrumentTypes"
    [placeholder]="placeHolder"
    label="{{dropDownLabel}}"
    [showClear]=false
    [showHeader]=true
    (dropdownChange)="dropdownChange($event)"></bpt-dropdown>
</div>
<div 
  *ngIf="instrumentConnectionDataSource && instrumentType === 'Balance'" 
  class="eln-instrument-list" 
  [ngModel] = "instrumentType"
>
  <div class="eln-instrument-grid-toolbar">
    <div class="eln-instrument-grid-actions-container">
      <span
        id="eln-instrument-list"
        class="eln-instrument-list-header table-title title-absolute">{{ titleOfTable }}
      </span>
      <div class="reload-instrument-grid">
        <i id="refresh" i18n-pTooltip="@@reloadInstruments" pTooltip="Reload instruments"
        tooltipPosition="bottom" aria-hidden="true" class="fas fa-redo" (click)="refreshInstruments()"></i>
      </div>
    </div>
    <div class="eln-offline-instrument-toggle">
      <p class="eln-toggle-name" i18n="@@offlineInstrumentToggle">Offline instruments</p>
      <bpt-toggle-switch
        [disabled]="false"
        [toggleSwitchSize]="'small'"
        [toggleSwitchLabel]="''"
        id="eln-offlineInstrumentToggle"
        label="Offline instrument"
        [(ngModel)]="toggledValue"
        name="offlineInstrument"
        (ngModelChange)="onOfflineInstrumentToggle()">
      </bpt-toggle-switch>
    </div>
  </div>
  <bpt-grid
    #Grid
    class="eln-grid eln-instrument-connection-grid hide-readonly-icons"
    [ngClass]="{ 'favoriteColumn' : disableFavorite}"
    gridId="{{ instrumentConnectionGridId }}"
    [dataSource]="instrumentConnectionDataSource"
    [useJsJodaTypes]="true"
    [columnDefinitions]="columnDefinitions"
    [gridMode]="gridMode"
    [allowRowAdd]="false"
    [showAutoSizeButton]="false"
    [favoriteColumn]="favoriteRowParameter"
    [showFilterToggleButton]="false"
    [showGridOptionsButton]="false"
    [paginated]="false"
    [suppressAggFuncInHeader]="false"
    (rowSelected)="onInstrumentSelected($event)"
    (selectionChanged)="onInstrumentSelected($event)"
    [noRowsMessage]="allInstrumentsOffline"
    [includeRowNumberColumn]="false"
    (cellValueChanged)="cellValueChanged($event)">
  </bpt-grid>
  <div class="eln-instrument-connection-footer">
    <p-button
      styleClass="eln-standard-popup-button"
      id="eln-connectToInstrumentButton"
      icon="pi pi-check"
      i18n-label="@@Connect"
      [disabled]="disableConnect"
      label="Connect"
      (click)="connectToInstrument()"></p-button>
  </div>
</div>
<div 
*ngIf="instrumentType === phMeter" class="eln-ph-instrument-list" [ngModel] = "instrumentType">
<div class="eln-ph-instrument-connection-footer">
  <p-button
    styleClass="eln-standard-popup-button eln-direct-connect"
    id="eln-direct-connect"
    i18n-label="@@Connect"
    label="Connect"
    (click)="connectToPhInstrument()"></p-button>
</div>
</div>

<p-confirmDialog
[position]="'center'"
key="instrumentVerificationDialog"
acceptButtonStyleClass="bpt-button-compact"
></p-confirmDialog>

<p-confirmDialog
[position]="'center'"
key="instrumentParametersMissingDialog"
acceptButtonStyleClass="bpt-button-compact"
>

