import { ModifiableDataValue, RuleEvents, ValueState } from '../../api/models';
import { AddRowResponse } from '../../api/data-entry/models';
import {
  RowAddedEventOfFullContext,
  RuleInputDataValue as DataValueAsRuleInput
} from '../events/rule-event-context';
import { ContextFrom, RuleEventContextBaseConverter } from './rule-base-converter';
import { RuleEventContextConverter } from './rule-event-context-converter';
import { mapValues } from 'lodash-es';
import { RuleActionHandlerHostItemType, RuleActionHandlerHostService } from '../handler/rule-action-handler-host.service';
import { AddRecipeRowResponse } from '../../api/cookbook/models';

export interface RowAddEventContextFrom extends ContextFrom {
  rowAddedResponse: AddRowResponse | AddRecipeRowResponse;
  rows: Array<{ [key: string]: DataValueAsRuleInput }>;
}

export class RowAddedEventConverter
  extends RuleEventContextBaseConverter
  implements RuleEventContextConverter<RowAddEventContextFrom>
{
  private readonly columnsToPopulateByAddRowResponse = ['rowIndex'];

  public convert(
    instanceId: string,
    templateId: string,
    context: RowAddEventContextFrom
  ): RowAddedEventOfFullContext {
    if(RuleActionHandlerHostService.ItemType === RuleActionHandlerHostItemType.Experiment){
      const mapValue: (value: any) => ModifiableDataValue = (value) => ({
        isModified: false,
        value: value
      });
      const newRow = { ...mapValues((context.rowAddedResponse as AddRowResponse).message.values[0], mapValue) };
      const currentRow: { [key: string]: DataValueAsRuleInput } =
      context.rows.find((row) => row.id === (context.rowAddedResponse as AddRowResponse).rowIds[0]) || {};
      
      Object.keys(newRow).forEach((columnId) => {
        if (currentRow[columnId].value !== undefined) {
          currentRow[columnId].value = newRow[columnId].value;
        }
      });
      this.normalizeDataValueRow(currentRow);
      return {
        event: {
          context: RuleEvents.RowAdded,
          templateId: templateId,
          templateInstanceId: instanceId,
          eventType: RuleEvents.RowAdded,
          itemId: RuleActionHandlerHostService.CurrentItemId
        },
        currentRow
      } as RowAddedEventOfFullContext;
    }
   else{
    return {
      event: {
        context: RuleEvents.RowAdded,
        templateId: templateId,
        templateInstanceId: instanceId,
        eventType: RuleEvents.RowAdded,
        itemId: RuleActionHandlerHostService.CurrentItemId
      },
    } as RowAddedEventOfFullContext;
   }
  }

  // TODO: To Support old and new formats. Until we streamline it.
  private normalizeDataValueRow(row: { [key: string]: DataValueAsRuleInput }): {
    [key: string]: DataValueAsRuleInput;
  } {
    const normalizedRow = row as any;
    Object.keys(normalizedRow).forEach((field) => {
      if (
        normalizedRow[field].state &&
        (!normalizedRow[field].value || !normalizedRow[field].value.value) &&
        normalizedRow[field].state === ValueState.Set
      ) {
        normalizedRow[field].value = normalizedRow[field];
      }
    });
    return normalizedRow;
  }
}
