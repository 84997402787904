import { RuleActionNotificationService } from './action-notification/rule-action-notification.service';
import {
  RuleActionNotification,
  RuleNotificationContext
} from './actions/rule-action-notification';
import {
  RuleAction,
  RuleEvaluationResultByBlazor,
  RuleNotificationResult
} from './actions/rule-action-result';
import { TemplateRule } from './rule/template-rule';
import { RuleEvent, TemplateRuleContext } from './events/rule-event-context';
import { sortBy } from 'lodash-es';
import { RuleEvaluationInputOf } from './inputs/rule-evaluation-Input';
import { RuleActionHandlerHostService } from './handler/rule-action-handler-host.service';

export class RuleRouter {
  public static readonly Assembly: string = 'ELN.Blazor.Entry' as const;
  public static readonly RuleMethod: string = 'EvaluateRule' as const;
  
  private currentRule!: RuleEvaluationInputOf<RuleEvent<TemplateRuleContext>>;
  private contextBasedInProgressRules: RuleEvaluationInputOf<RuleEvent<TemplateRuleContext>>[] = [];
  private readonly evaluatedRules: TemplateRule[] = [];
  private lastEvaluatedRuleNotificationContext!: RuleNotificationContext;
  private readonly ruleTrackingId: string;
  private readonly ruleActionNotificationService: RuleActionNotificationService;

  public get contextBasedPendingRules(): RuleEvaluationInputOf<RuleEvent<TemplateRuleContext>>[] {
    return this.contextBasedInProgressRules;
  }

  public get EvaluatedRules(): TemplateRule[] {
    return this.evaluatedRules;
  }

  constructor(
    ruleTrackingId: string,
    ruleActionNotificationService: RuleActionNotificationService,
    ruleEvaluationInputs: RuleEvaluationInputOf<RuleEvent<TemplateRuleContext>>[]
  ) {
    this.contextBasedInProgressRules = ruleEvaluationInputs;
    this.ruleTrackingId = ruleTrackingId;
    this.ruleActionNotificationService = ruleActionNotificationService;
  }

  public routeNextRule(inputContext: RuleEvent<TemplateRuleContext>): void {
    inputContext.event = this.contextBasedInProgressRules[0].ruleInput.inputs[0].input.event;
    this.contextBasedInProgressRules[0].ruleInput.inputs[0].input = inputContext;
    this.evaluateNextRule();
  }

  public evaluateNextRule(): Promise<void> {
    if (!this.hasPendingRulesToEvaluate()) return Promise.resolve();
    
    this.currentRule = this.contextBasedInProgressRules[0];
    return this.evaluateWorkflow();
  }

  public queueRuleAndPrioritize(
    ruleEvaluationInputs: RuleEvaluationInputOf<RuleEvent<TemplateRuleContext>>[]
  ): void {
    this.contextBasedInProgressRules.push(...ruleEvaluationInputs);
    this.contextBasedInProgressRules = sortBy(
      this.contextBasedInProgressRules,
      (item) => item.ruleInput.rule.priority,
      ['asc']
    );
  }

  public hasPendingRulesToEvaluate(): boolean {
    return this.contextBasedInProgressRules && this.contextBasedInProgressRules.length !== 0;
  }

  private async evaluateWorkflow(): Promise<void> {
    console.group(`Rule: ${this.currentRule.ruleInput.rule.name} is starting to evaluate...`);
    const rules = JSON.stringify(this.currentRule.ruleInput.rule.compiledRuleDefinition);
    this.cacheInitialVersionOfVariables();
    const result = await RuleRouter.delegateEvaluationToBlazor<string>(rules, this.currentRule.convertInputToJsonStringArray());
    this.finalizeRuleEvaluation();
    this.notifyRuleAction(JSON.parse(result));
  }

  public static delegateEvaluationToBlazor<T>(rules: string, inputs: string[]): Promise<T> {
    return DotNet.invokeMethodAsync<T>(RuleRouter.Assembly, RuleRouter.RuleMethod, rules, inputs);
  }

  private static initialVariableNodeIsCached = false;
  private cacheInitialVersionOfVariables() {
    if (!RuleRouter.initialVariableNodeIsCached) {
      RuleRouter.initialVariableNodeIsCached = true;
      RuleActionHandlerHostService.delegateCacheVariablesToBlazor();
    }
  }

  private finalizeRuleEvaluation() {
    this.evaluatedRules.push(this.currentRule.ruleInput.rule);
    this.setRuleNotificationContext();
    this.contextBasedInProgressRules = this.contextBasedInProgressRules.filter(
      r => r.ruleInput.rule.ruleId !== this.currentRule.ruleInput.rule.ruleId
    );
  }

  private setRuleNotificationContext() {
    this.lastEvaluatedRuleNotificationContext = {
      itemId: RuleActionHandlerHostService.CurrentItemId,
      ruleId: this.currentRule.ruleInput.rule.ruleId,
      templateId: this.currentRule.ruleInput.rule.templateId,
      templateInstanceId: this.currentRule.ruleInput.inputs[0].input.event.templateInstanceId,
      correlationId: this.ruleTrackingId,
      ruleCommandContext: {
        correlationId: this.ruleTrackingId,
        ruleId: this.currentRule.ruleInput.rule.ruleId
      }
    };
  }

  private notifyRuleAction(evaluationResult: RuleEvaluationResultByBlazor) {
    this.printVerboseLogOfRulEvaluation(evaluationResult.Notification);
    console.log(
      `Evaluation result recieved for ${this.currentRule.ruleInput.rule.name}: ${this.currentRule.ruleInput.rule.name}`,
      evaluationResult
    );

    let rulesResult = evaluationResult.Results ?? [];
    rulesResult = rulesResult.filter(
      r => r.IsSuccessful && r.ActionResult && r.ActionResult.length > 0
    );
    if (rulesResult.length === 0) {
      this.ruleActionNotificationService.RuleFailureNotifications.next(
        evaluationResult.Notification
      );
      console.log('No Actions found to process.', rulesResult);
      this.evaluateNextRule();
      return;
    }
    rulesResult.forEach((result) => {
      const notification: RuleActionNotification<RuleEvent<TemplateRuleContext>> = {
        sourceEvent: this.currentRule.ruleInput.inputs[0].input,
        action: JSON.parse(result.ActionResult).Action as RuleAction,
        ruleContext: JSON.parse(JSON.stringify(this.lastEvaluatedRuleNotificationContext)),
        evaluateNextRule: this.evaluateNextRule.bind(this)
      };
      this.ruleActionNotificationService.NotifyAction(notification);
    });
  }

  private printVerboseLogOfRulEvaluation(notification: RuleNotificationResult) {
    notification.Verbose.forEach((notification) => {
      if (notification.Message.includes('error')) {
        console.error(notification.Message);
      } else {
        console.info(notification.Message);
      }
    });
    console.group('failures');
    notification.Errors.forEach((notification) => {
      console.error(notification.Message);
    });
    console.groupEnd();
    console.groupEnd();
  }
}
