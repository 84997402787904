import { Injectable } from "@angular/core";
import { Message, MessageService } from "primeng/api";
import { ChangeRecipeNodeTitleCommand, NodeType, RecipeNodeTitleChangedResponse, RecipeState, TemplateType } from "../../api/cookbook/models";
import { ClientStateService } from "../../services/client-state.service";
import { MainMenuItem } from "bpt-ui-library/bpt-layout";
import { Activity, Form, Table } from "../../model/experiment.interface";
import { ElnMenuInlineInputElement, ElnMenuInlineInputEvent, ElnMenuInlineInputValueChangedContext } from "../../experiment/model/eln-menu-inline-edit-input-element";
import { RecipeEventsService } from "../../api/cookbook/services";
import { RecipeService } from "./recipe.service";
import { Observable, Subject, of } from "rxjs";
import { isEmpty, replace } from "lodash-es";
import { elnEncodeSpecialChars } from "../../shared/url-path-serializer";
import { Location } from "@angular/common";
import { TemplateDeleteService } from "../../recipe-template-loader/experiment-template-load/services/template-delete.service";

type ActivityNodeTitleChangeElementArguments = {
  activity: Activity;
  activityMenuItem: MainMenuItem;
  activityTitleElement: HTMLSpanElement;
  isRecipe: boolean;
};

@Injectable()
export class RecipeNodeRetitleService {
  private static _hasPermissionToEditTitle = true;
  public static get HasPermissionToEditTitle(): boolean {
    return RecipeNodeRetitleService._hasPermissionToEditTitle;
  }
  public static readonly NotAllowedWorkflowStates: RecipeState[] = [
    RecipeState.PendingApproval,
    RecipeState.Cancelled,
    RecipeState.Published,
    RecipeState.PendingVerification,
    RecipeState.Retired
  ];

  constructor(
    public readonly recipeEventsApiService: RecipeEventsService,
    public readonly recipeService: RecipeService,
    private readonly templateDeleteService: TemplateDeleteService,
    private readonly messageService: MessageService,
    private readonly location: Location,
    private readonly clientStateService: ClientStateService
  ) {}

  public readonly ActivityTitleChanged = new Subject<string>();

  public bindRetitleActivityMenuItemContext(
    activity: Activity,
    activityMenuItem: MainMenuItem,
    isRecipe: boolean,
    isUserEditor: boolean = true
  ): MainMenuItem[] {
    return [{
        label: $localize`:@reTitle:Retitle`,
        icon: 'icon-pencil',
        id: `eln-context-activity-title-changer-menu`,
        state: {
          activity,
          activityMenuItem
        },
        command: (_event: any) => {
          this.renderInputToRetitleFor(activity, activityMenuItem, isRecipe);
        },
        disabled: RecipeNodeRetitleService.NotAllowedWorkflowStates.includes(
          this.recipeService.currentRecipe?.tracking.state
        ) || !isUserEditor
      },
      {
        label: $localize`:@@remove:Remove`,
        icon: 'far fa-trash-alt',
        disabled:
          !RecipeNodeRetitleService.HasPermissionToEditTitle ||
          RecipeNodeRetitleService.NotAllowedWorkflowStates.includes(
            this.recipeService.currentRecipe?.tracking.state
          ) || !isUserEditor,
        id: `eln-context-activity-delete-${activity.itemTitle.replace(new RegExp(' ', 'g'), '')}`,
        command: (_event$) => {
        this.templateDeleteService.deleteRecipeItem(activity, TemplateType.Activity, isRecipe);
        }
      }];
  }

  // Permissions will be updated once the radium is deployed.
  public hasPermission(): boolean {
    const featureFlags = this.clientStateService.getFeatureFlags('eln.recipe.designer');
    RecipeNodeRetitleService._hasPermissionToEditTitle = featureFlags.some((data) => {
      if (!data || !JSON.parse(data)) {
        return false;
      }
      const parsedData = JSON.parse(data);
      return (
        (parsedData.CanRetitleNode && parsedData.CanRetitleNode === true)
      );
    });
    return RecipeNodeRetitleService._hasPermissionToEditTitle;
  }

  private renderInputToRetitleFor(activity: Activity, activityMenuItem: MainMenuItem, isRecipe: boolean) {
    const activityTitleInputControl = this.createInputElementToRetitle(activity, activityMenuItem, isRecipe);
    if (activityTitleInputControl.inputsContext.callbackArguments) {
      activityTitleInputControl.inputsContext.callbackArguments.activityTitleElement.innerHTML = ``;
      activityTitleInputControl.inputsContext.callbackArguments.activityTitleElement.appendChild(
        activityTitleInputControl.inputElement
      );
    }
    activityTitleInputControl.focusAndSelect();

    activityTitleInputControl.ValueChanged.subscribe({
      next: this.changeActivityTitle.bind(this)
    });

    activityTitleInputControl.cancelEdit.subscribe({
      next: (event: ElnMenuInlineInputEvent<ActivityNodeTitleChangeElementArguments>) => {
        if (event && event.callbackArguments && event.callbackArguments.activityTitleElement) {
          event.callbackArguments.activityTitleElement.innerText =
            event.callbackArguments.activity.itemTitle;
        }
      }
    });
  }

  changeNodeTitle(
    nodeType: NodeType,
    nodeId: string,
    newTitle: string,
    oldTitle: string,
    parentNodeIds: string[],
    isRecipe: boolean
  ) : Observable<RecipeNodeTitleChangedResponse | undefined>{
    const reTitleSuccessStatus = new Subject<RecipeNodeTitleChangedResponse | undefined>();

    if (!this.hasPermission()) {
      this.failedToPerformOperationMessageBecauseUnAuthorized(nodeType);
      return of(undefined);
    }

    if (RecipeNodeRetitleService.NotAllowedWorkflowStates.includes(
        this.recipeService.currentRecipe?.tracking.state)) {
      this.failedToPerformOperationMessage(nodeType);
      return of(undefined);
    }

    if (!this.isUniqueTitle(nodeType, newTitle, parentNodeIds, [oldTitle.toLowerCase()])) {
      return of(undefined);
    }

    this.recipeEventsApiService
      .recipeEventsChangeNodeTitlePost$Json({
        body: this.prepareCommandToChangeNodeTitleFor(nodeType, nodeId, newTitle, isRecipe)
      })
      .subscribe({
        next: (response) => {
          this.processReTitleSuccessResponse(response, oldTitle);
          reTitleSuccessStatus.next(response);
        }
      });
    return reTitleSuccessStatus.asObservable();
  }

  private processReTitleSuccessResponse(
    response: RecipeNodeTitleChangedResponse,
    oldTitle: string
  ): void {
    this.titleChangedNotificationMessage(oldTitle, response.title, response.nodeType);
  }

  private isUniqueTitle(
    nodeType: NodeType,
    newTitle: string,
    parentNodeIds: string[],
    titlesToExclude: string[] = []
  ): boolean {
    const currentTitles = this.getChildNodeTitlesByPath(parentNodeIds)
      .map((title) => title.toLowerCase())
      .filter((title) => !titlesToExclude.includes(title));
    if (currentTitles.includes(newTitle.trim().toLowerCase())) {
      this.duplicateTitleNotificationMessage(newTitle, nodeType);
      return false;
    }
    return true;
  }

  getChildNodeTitlesByPath(nodeIdCollection: string[]): string[] {
    if (isEmpty(nodeIdCollection)) {
      return (
        this.recipeService.currentRecipe?.activities.map((activity) =>
          activity.itemTitle.toLowerCase()
        ) || []
      );
    }
    return this.getValuesBasedOnNodeCollection(nodeIdCollection);
  }

  getValuesBasedOnNodeCollection(nodeIdCollection: string[]) : string[] {
    const activity = this.recipeService.currentRecipe?.activities.find(
      (activity) => activity.activityId === nodeIdCollection[0]
    );
    switch (nodeIdCollection.length) {
      case 1:
        if(this.recipeService.currentRecipe?.modules.length > 0) {
          return this.recipeService.currentRecipe?.modules.map((module) => module.moduleLabel.toLowerCase());
        }
        else if(activity === undefined && this.recipeService.currentRecipe?.modules.length === 0) {
          let formsAndTables: any[] = [];
          formsAndTables = this.recipeService.currentRecipe?.forms.map((form : Form) => form.itemTitle.toLowerCase());
          formsAndTables.push(...this.recipeService.currentRecipe?.tables.map((table : Table) => table.itemTitle.toLowerCase()));
          return formsAndTables;
        }
        return activity?.dataModules.map((module) => module.moduleLabel.toLowerCase()) || [];
      case 2:
        if(activity === undefined && this.recipeService.currentRecipe?.modules.length > 0) {
          return this.recipeService.currentRecipe?.modules?.find((module) => module.moduleId === nodeIdCollection[1])
          ?.items.map((moduleItem) => moduleItem.itemTitle.toLowerCase()) || [];
        }
        return (
          activity?.dataModules
            .find((module) => module.moduleId === nodeIdCollection[1])
            ?.items.map((moduleItem) => moduleItem.itemTitle.toLowerCase()) || []
        );
      default:
        return [];
    }
  }

  changeActivityTitle(
    event: ElnMenuInlineInputValueChangedContext<ActivityNodeTitleChangeElementArguments>
  ) {
    this.changeNodeTitle(
      NodeType.Activity,
      event.callbackArguments?.activity.activityId as string,
      event.value,
      event.callbackArguments?.activity.itemTitle as string,
      [],
      event.callbackArguments?.isRecipe ?? false
    ).subscribe({
      next: (response) => {
        if(response) {
          this.updateChangedActivityTitle(response);
        } else {
          event.controlContext.setErrorIndidcator();
          event.controlContext.focusAndSelect();
        }
      }
    });
  }

  private updateActivityTitleInPath(oldTitle: string, newTitle: string): void {
    const path = this.location.path(true);
    const activityPathLookup = encodeURI(
      `/${this.recipeService.currentRecipe?.number}_V${this.recipeService.currentRecipe?.version}/${elnEncodeSpecialChars(
        oldTitle
      )}`
    );
    if (this.location.path(true).includes(activityPathLookup)) {
      this.location.replaceState(
        path.replace(
          activityPathLookup,
          `/${this.recipeService.currentRecipe?.number}_V${this.recipeService.currentRecipe?.version}/${elnEncodeSpecialChars(
            newTitle
          )}`
        )
      );
    }
  }

  updateChangedActivityTitle(
    notification: RecipeNodeTitleChangedResponse
  ): void {
    const node = this.recipeService.currentRecipe?.activities.find(
      (activity) => activity.activityId === notification.nodeId
    );
    const currentTitle = node?.itemTitle as string;
    if (node) {
      node.itemTitle = notification.title;
      this.updateActivityTitleInPath(currentTitle, notification.title);
      this.updateTitleInMenu(currentTitle, notification.title);
      this.ActivityTitleChanged.next(notification.title);
    }
  }

  updateTitleInMenu(currentTitle: string, newTitle: string): void {
    const activityTitleSpanElement = this.getActivityTitleSpanElement(
      `eln-menu-activity-${replace(currentTitle, / /g, '')}`
    );
    if (activityTitleSpanElement) {
      activityTitleSpanElement.innerText = newTitle;
    }
  }

  private createInputElementToRetitle(
    activity: Activity,
    activityMenuItem: MainMenuItem,
    isRecipe: boolean
  ): ElnMenuInlineInputElement<ActivityNodeTitleChangeElementArguments> {
    const activityTitleSpan = this.getActivityTitleSpanElement(`${activityMenuItem.id}`);
    const callbackArguments: ActivityNodeTitleChangeElementArguments = {
      activity,
      activityMenuItem,
      activityTitleElement: activityTitleSpan,
      isRecipe
    };

    activityTitleSpan.onkeydown = function (e: KeyboardEvent) {
      if (e.key === ' ') {
        e.stopPropagation();
      }
      return true;
    };

    return new ElnMenuInlineInputElement<ActivityNodeTitleChangeElementArguments>({
      id: `bpt-eln-activity-title-input-${activity.activityId}`,
      initialValue: activity.itemTitle,
      placeholderText: $localize`:@@titlePlaceholder:Enter Title`,
      callbackArguments
    });
  }

  private getActivityTitleSpanElement(id: string): HTMLSpanElement {
    const activityLinkMenuItem = document.querySelector(`[id='${id}']`);
    return activityLinkMenuItem?.getElementsByClassName('p-menuitem-text')[0] as HTMLSpanElement;
  }

  private prepareCommandToChangeNodeTitleFor(
    nodeType: NodeType,
    nodeId: string,
    modifiedTitle: string,
    isRecipe: boolean
  ): ChangeRecipeNodeTitleCommand {
    return {
      nodeId,
      nodeType,
      title: modifiedTitle,
      recipeId: this.recipeService.currentRecipe?.recipeId as string,
      isRecipe
    };
  }

  private duplicateTitleNotificationMessage(newTitle: string, nodeType: string) {
    this.createNotificationMessage(
      $localize`:@@RecipeNodeTitleDuplicate:The ${nodeType} title "${newTitle}" is already exists, please enter unique title.`,
      '',
      'error'
    );
  }

  private titleChangedNotificationMessage(oldTite: string, newTitle: string, nodeType: string) {
    this.createNotificationMessage(
      $localize`:@@RecipeNodeTitleChangedMessage:The ${nodeType} title has been changed from "${oldTite}" to "${newTitle}".`,
      ''
    );
  }

  private failedToPerformOperationMessage(nodeType: string) {
    const state = this.recipeService.currentRecipe?.tracking.state;
    const type = nodeType;
    this.createNotificationMessage(
      $localize`:@@NodeRetitleFailedToPerformOperationMessageForRecipe:The retitle ${type} action cannot be performed, because the recipe workflow state is ${state}.`,
      ``,
      'error'
    );
  }

  private failedToPerformOperationMessageBecauseUnAuthorized(nodeType: string) {
    this.createNotificationMessage(
      $localize`:@@NodeRetitleFailedToPerformOperationMessageUnAuthorized:The retitle ${nodeType} action cannot be performed, because your auhtorization is failed.`,
      ``,
      'error'
    );
  }

  private createNotificationMessage(summary: string, detail: string, severity = 'success') {
    const messageObj: Message = {
      key: 'notification',
      severity: severity,
      summary,
      detail,
      sticky: false
    };
    this.messageService.add(messageObj);
  }
}
