/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { DataPackageResponse as ElnDataPackageApiEntitiesDataPackageResponse } from '../../models/ELN/DataPackage/API/Entities/data-package-response';

export interface ExperimentsExperimentIdDataPackagesGet$Json$Params {
  experimentId: string;
  dataPackagePerActivity?: boolean;
  'X-User-Puid'?: any;
  'X-User-LabsiteCode'?: any;

/**
 * Please use this header only when the request is for an dataPackageExperiment or preview dataPackageExperiment. Passing true will be considered a request for a preview dataPackageExperiment.
 */
  'X-DataPackageExperiment-PreviewMode'?: any;
}

export function experimentsExperimentIdDataPackagesGet$Json(http: HttpClient, rootUrl: string, params: ExperimentsExperimentIdDataPackagesGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ElnDataPackageApiEntitiesDataPackageResponse>> {
  const rb = new RequestBuilder(rootUrl, experimentsExperimentIdDataPackagesGet$Json.PATH, 'get');
  if (params) {
    rb.path('experimentId', params.experimentId, {"style":"simple"});
    rb.query('dataPackagePerActivity', params.dataPackagePerActivity, {"style":"form"});
    rb.header('X-User-Puid', params['X-User-Puid'], {"style":"simple"});
    rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {"style":"simple"});
    rb.header('X-DataPackageExperiment-PreviewMode', params['X-DataPackageExperiment-PreviewMode'], {"style":"simple"});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ElnDataPackageApiEntitiesDataPackageResponse>;
    })
  );
}

experimentsExperimentIdDataPackagesGet$Json.PATH = '/experiments/{experimentId}/data-packages';
