const elnUrlEscapeDefinitions = [{
 char: '/',
 escape: ':2F'
}];

export function elnEncodeSpecialChars(path: string): string {
    elnUrlEscapeDefinitions.forEach(escapeDefinition => {
        path = path.replace(escapeDefinition.char, escapeDefinition.escape)
    });
    return path;
}

export function elnDecodeSpecialChars(path: string): string {
    elnUrlEscapeDefinitions.forEach(escapeDefinition => {
        path = path.replace(escapeDefinition.escape, escapeDefinition.char)
    });
    return path;
}
