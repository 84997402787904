import { isEmpty } from "lodash-es";
import { ExperimentDataRecordNotification, ExperimentRecordType } from "../../api/data-entry/models";

export type ExperimentRecordTypesCompatibleType = {
  recordTypes?: {
    [key: string]: Array<ExperimentRecordType>;
  }
};

export class ExperimentRecordTypesHelper {
  public static readonly DefaultKeyForRecordType = 'default';
  // Translations

  public static readonly RecordTypesLocaleDictionary: { [key in ExperimentRecordType]: string } = {
    system: $localize`:@@recordType-system:System`,
    new: $localize`:@@recordType-new:New`,
    change: $localize`:@@recordType-change:Change`,
    remove: $localize`:@@recordType-remove:Remove`,
    restore: $localize`:@@recordType-restore:Restore`,
    reading: $localize`:@@recordType-reading:Reading`,
    access: $localize`:@@recordType-access:Access`,
    workflow: $localize`:@@recordType-workflow:Workflow`,
    statement: $localize`:@@recordType-statement:Statement`,
    review: $localize`:@@recordType-review:Review`,
    template: $localize`:@@recordType-template:Template`,
    recipe: $localize`:@@recordType-recipe:Recipe`,
    rule: $localize`:@@recordType-rule:Rule`,
    calculation: $localize`:@@recordType-calculation:Calculation`,
    reference: $localize`:@@recordType-reference:Reference`,
    labItem: $localize`:@@recordType-labItem:Lab Item`,
    input: $localize`:@@recordType-input:Input`,
    output: $localize`:@@recordType-output:Output`,
    attachedFile: $localize`:@@recordType-attachedFile:Attached File`,
    preparation: $localize`:@@recordType-preparation:Preparation`,
    barcode: $localize`:@@recordType-barcode:Barcode`,
    internalComments: $localize`:@@recordType-internalComments:Internal Comments`,
    clientFacingNotes: $localize`:@@recordType-clientFacingNotes:Client Facing Notes`,
    empower: $localize`:@@recordType-empower:Empower`,
    dataPackage: $localize`:@@recordType-dataPackage:Data Package`,
  }

  public static addRecordTypes(source: ExperimentRecordTypesCompatibleType, recordType: ExperimentRecordType) {
    source.recordTypes = source.recordTypes || {};
    source.recordTypes[ExperimentRecordTypesHelper.DefaultKeyForRecordType] = source.recordTypes[ExperimentRecordTypesHelper.DefaultKeyForRecordType] || [];
    source.recordTypes[ExperimentRecordTypesHelper.DefaultKeyForRecordType].push(recordType);
  }

  public static getDefaultRecordTypesFromNotification(notification: ExperimentDataRecordNotification): ExperimentRecordType[] {
    return notification.recordTypes[ExperimentRecordTypesHelper.DefaultKeyForRecordType] || [];
  }

  public static getRecordTypesFromNotificationByItsKey(notification: ExperimentDataRecordNotification, key: string): ExperimentRecordType[] {
    return notification.recordTypes[key] || [];
  }

  public static getLocalizeRecordTypesFromNotification(notification: ExperimentDataRecordNotification, defaultRecordType?: string): string {
    const recordTypes = ExperimentRecordTypesHelper.getDefaultRecordTypesFromNotification(notification);
    return ExperimentRecordTypesHelper.getLocalizedRecordTypes(recordTypes, defaultRecordType);
  }

  public static getLocalizeRecordTypesFromNotificationByItsKey(notification: ExperimentDataRecordNotification, key: string, defaultRecordType?: string): string {
    const recordTypes = ExperimentRecordTypesHelper.getRecordTypesFromNotificationByItsKey(notification, key);
    return ExperimentRecordTypesHelper.getLocalizedRecordTypes(recordTypes, defaultRecordType);
  }

  private static getLocalizedRecordTypes(recordTypes: ExperimentRecordType[], defaultRecordType?: string): string {
    if (isEmpty(recordTypes)) {
      return defaultRecordType || '';
    }
    return recordTypes.map(recordType => ExperimentRecordTypesHelper.RecordTypesLocaleDictionary[recordType]).join(', ');
  }
}