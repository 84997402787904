<div class="instrument-overlay">
  <div class="overlay-header row mb-2">
    <div class="eln-modalTitle" i18n="@@phMeterReadingHeader">pH Meter{{ phMeterName }}</div>
    <div i18n-pTooltip="@@close" (click)="closeOverlay()"
    class="icon-x icon-m overlay-cancel">
  </div>
  </div>
  <div class="instrument-config row mb-2 ml-1">
    <bpt-numeric-input
      id="eln-Temp"
      label="Temperature"
      [(ngModel)]="temperatureValue"
      i18n-label="@@temperature"
      (ngModelChange)="temperatureValueChanged()"
      [disabled]="false"
    ></bpt-numeric-input>
    <bpt-dropdown
      label="Mode"
      i18n-label="@@mode"
      [disabled]="disableDropDown"
      [options]="modeOptions.options"
      [(ngModel)]="selectedMode"
      (ngModelChange)="modeChanged($event)"
    ></bpt-dropdown>
  </div>
  <div>
    <div class="inputBox row mb-2" *ngIf = "!hideMvField">
      <bpt-numeric-input
      id="eln-mV"
      i18n-label="@@mV"
      label="mV"
      [(ngModel)]="mvValue"
      [disabled]="false"
      [hidden]="hideMvField"
      (ngModelChange)="mvValueChanged()"
      >
    </bpt-numeric-input>
    </div> 
    <div class="instrument-config row mb-2 ml-3" *ngIf = "!hidePhField">
      <bpt-numeric-input
      id="eln-pH"
      i18n-label="@@pH"              
      label="pH"
      [(ngModel)]="phValue"
      [disabled]="false"
      [hidden]="hidePhField"
      (ngModelChange)="phValueChanged()"
      >
    </bpt-numeric-input>
    <bpt-numeric-input
      i18n-label="@@target"           
      label="Target"
      [disabled]="false"
      [hidden]="true">
    </bpt-numeric-input>
    </div>
  </div>

  <div class="row bottom">
    <label class="config-labels">
      <p-checkbox [binary]="true" [(ngModel)]="selectedPushValue" [disabled]="true"></p-checkbox>
      <span i18n="@@pushFromPhMeter">Push from pH meter</span>   
    </label>
    <div>
      <span>
        <p-button
        *ngIf="isReadyToCommit"
        #commit
        id="commit-overlay"
        label="Commit"
        i18n-label="@@commit"    
        styleClass="bpt-button-compact btn-commit"
        (onClick)="commitValue()"
        ></p-button>
      </span>
      <span>
        <p-button
        *ngIf="!isReadyToCommit"
        #cancel
        id="close-overlay"
        label="Cancel"
        i18n-label="@@cancel"    
        styleClass="bpt-button-compact p-button-outlined btn-cancel"
        (onClick)="closeOverlay()"
        ></p-button>
      </span>
    </div>
  </div>
</div>
