/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { activityInputAddExperimentScannedItemsPost } from '../fn/activity-input/activity-input-add-experiment-scanned-items-post';
import { ActivityInputAddExperimentScannedItemsPost$Params } from '../fn/activity-input/activity-input-add-experiment-scanned-items-post';
import { activityInputPost } from '../fn/activity-input/activity-input-post';
import { ActivityInputPost$Params } from '../fn/activity-input/activity-input-post';
import { activityInputRetrieveMaterialDetailsPost$Json } from '../fn/activity-input/activity-input-retrieve-material-details-post-json';
import { ActivityInputRetrieveMaterialDetailsPost$Json$Params } from '../fn/activity-input/activity-input-retrieve-material-details-post-json';
import { activityInputRetrieveMaterialDetailsPost$Plain } from '../fn/activity-input/activity-input-retrieve-material-details-post-plain';
import { ActivityInputRetrieveMaterialDetailsPost$Plain$Params } from '../fn/activity-input/activity-input-retrieve-material-details-post-plain';
import { activityInputRetrieveSampleTestPost$Json } from '../fn/activity-input/activity-input-retrieve-sample-test-post-json';
import { ActivityInputRetrieveSampleTestPost$Json$Params } from '../fn/activity-input/activity-input-retrieve-sample-test-post-json';
import { activityInputRetrieveSampleTestPost$Plain } from '../fn/activity-input/activity-input-retrieve-sample-test-post-plain';
import { ActivityInputRetrieveSampleTestPost$Plain$Params } from '../fn/activity-input/activity-input-retrieve-sample-test-post-plain';
import { activityInputRetrieveStudyActivityPost$Json } from '../fn/activity-input/activity-input-retrieve-study-activity-post-json';
import { ActivityInputRetrieveStudyActivityPost$Json$Params } from '../fn/activity-input/activity-input-retrieve-study-activity-post-json';
import { activityInputRetrieveStudyActivityPost$Plain } from '../fn/activity-input/activity-input-retrieve-study-activity-post-plain';
import { ActivityInputRetrieveStudyActivityPost$Plain$Params } from '../fn/activity-input/activity-input-retrieve-study-activity-post-plain';
import { MaterialAliquotDetails } from '../models/material-aliquot-details';
import { SampleTestResponse } from '../models/sample-test-response';
import { StudyActivityResponse } from '../models/study-activity-response';

@Injectable({ providedIn: 'root' })
export class ActivityInputService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `activityInputPost()` */
  static readonly ActivityInputPostPath = '/activity-input';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityInputPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputPost$Response(params?: ActivityInputPost$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return activityInputPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityInputPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputPost(params?: ActivityInputPost$Params, context?: HttpContext): Observable<void> {
    return this.activityInputPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `activityInputAddExperimentScannedItemsPost()` */
  static readonly ActivityInputAddExperimentScannedItemsPostPath = '/activity-input/add-experiment-scanned-items';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityInputAddExperimentScannedItemsPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputAddExperimentScannedItemsPost$Response(params?: ActivityInputAddExperimentScannedItemsPost$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return activityInputAddExperimentScannedItemsPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityInputAddExperimentScannedItemsPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputAddExperimentScannedItemsPost(params?: ActivityInputAddExperimentScannedItemsPost$Params, context?: HttpContext): Observable<void> {
    return this.activityInputAddExperimentScannedItemsPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `activityInputRetrieveSampleTestPost()` */
  static readonly ActivityInputRetrieveSampleTestPostPath = '/activity-input/retrieve-sample-test';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityInputRetrieveSampleTestPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputRetrieveSampleTestPost$Plain$Response(params?: ActivityInputRetrieveSampleTestPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<SampleTestResponse>> {
    return activityInputRetrieveSampleTestPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityInputRetrieveSampleTestPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputRetrieveSampleTestPost$Plain(params?: ActivityInputRetrieveSampleTestPost$Plain$Params, context?: HttpContext): Observable<SampleTestResponse> {
    return this.activityInputRetrieveSampleTestPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<SampleTestResponse>): SampleTestResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityInputRetrieveSampleTestPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputRetrieveSampleTestPost$Json$Response(params?: ActivityInputRetrieveSampleTestPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<SampleTestResponse>> {
    return activityInputRetrieveSampleTestPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityInputRetrieveSampleTestPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputRetrieveSampleTestPost$Json(params?: ActivityInputRetrieveSampleTestPost$Json$Params, context?: HttpContext): Observable<SampleTestResponse> {
    return this.activityInputRetrieveSampleTestPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<SampleTestResponse>): SampleTestResponse => r.body)
    );
  }

  /** Path part for operation `activityInputRetrieveStudyActivityPost()` */
  static readonly ActivityInputRetrieveStudyActivityPostPath = '/activity-input/retrieve-study-activity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityInputRetrieveStudyActivityPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  activityInputRetrieveStudyActivityPost$Plain$Response(params?: ActivityInputRetrieveStudyActivityPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<StudyActivityResponse>> {
    return activityInputRetrieveStudyActivityPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityInputRetrieveStudyActivityPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  activityInputRetrieveStudyActivityPost$Plain(params?: ActivityInputRetrieveStudyActivityPost$Plain$Params, context?: HttpContext): Observable<StudyActivityResponse> {
    return this.activityInputRetrieveStudyActivityPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<StudyActivityResponse>): StudyActivityResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityInputRetrieveStudyActivityPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  activityInputRetrieveStudyActivityPost$Json$Response(params?: ActivityInputRetrieveStudyActivityPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<StudyActivityResponse>> {
    return activityInputRetrieveStudyActivityPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityInputRetrieveStudyActivityPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  activityInputRetrieveStudyActivityPost$Json(params?: ActivityInputRetrieveStudyActivityPost$Json$Params, context?: HttpContext): Observable<StudyActivityResponse> {
    return this.activityInputRetrieveStudyActivityPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<StudyActivityResponse>): StudyActivityResponse => r.body)
    );
  }

  /** Path part for operation `activityInputRetrieveMaterialDetailsPost()` */
  static readonly ActivityInputRetrieveMaterialDetailsPostPath = '/activity-input/retrieve-material-details';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityInputRetrieveMaterialDetailsPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  activityInputRetrieveMaterialDetailsPost$Plain$Response(params?: ActivityInputRetrieveMaterialDetailsPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MaterialAliquotDetails>> {
    return activityInputRetrieveMaterialDetailsPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityInputRetrieveMaterialDetailsPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  activityInputRetrieveMaterialDetailsPost$Plain(params?: ActivityInputRetrieveMaterialDetailsPost$Plain$Params, context?: HttpContext): Observable<MaterialAliquotDetails> {
    return this.activityInputRetrieveMaterialDetailsPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MaterialAliquotDetails>): MaterialAliquotDetails => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityInputRetrieveMaterialDetailsPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  activityInputRetrieveMaterialDetailsPost$Json$Response(params?: ActivityInputRetrieveMaterialDetailsPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<MaterialAliquotDetails>> {
    return activityInputRetrieveMaterialDetailsPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityInputRetrieveMaterialDetailsPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  activityInputRetrieveMaterialDetailsPost$Json(params?: ActivityInputRetrieveMaterialDetailsPost$Json$Params, context?: HttpContext): Observable<MaterialAliquotDetails> {
    return this.activityInputRetrieveMaterialDetailsPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<MaterialAliquotDetails>): MaterialAliquotDetails => r.body)
    );
  }

}
