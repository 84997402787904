import { Injectable } from '@angular/core';
import { ConfirmationService, Message, MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import {
  DisplayMessageNotificationEvent,
  RuleActionNotificationService
} from '../action-notification/rule-action-notification.service';
import { RuleActionNotification } from '../actions/rule-action-notification';
import {
  DisplayMessageType,
  RuleAction,
  RuleActionDisplayMessageResult
} from '../actions/rule-action-result';

@Injectable()
export class RuleDisplayMessageHandlerService {
  private readonly subscriptions: Subscription[] = [];
  constructor(
    private readonly ruleActionNotificationService: RuleActionNotificationService,
    private readonly messageService: MessageService,
    private readonly confirmationService: ConfirmationService
  ) {
    this.watchDisplayMessageActionsFromRule();
  }

  watchDisplayMessageActionsFromRule(): void {
    this.subscriptions.push(
      this.ruleActionNotificationService.DisplayMessageActionNotification.subscribe({
        next: this.delegateBasedOnMessageType.bind(this)
      })
    );
  }

  delegateBasedOnMessageType(
    notification: RuleActionNotification<DisplayMessageNotificationEvent>
  ) {
    const ruleActionDisplayMessageAction = notification.action as RuleActionDisplayMessageResult;
    switch (ruleActionDisplayMessageAction.MessageType) {
      case DisplayMessageType.Error:
        this.displayError(ruleActionDisplayMessageAction);
        notification.evaluateNextRule();
        break;
      case DisplayMessageType.Confirmation:
        this.processConfirmationAndYesNoAction(notification);
        break;
      case DisplayMessageType.YesNo:
        this.processConfirmationAndYesNoAction(notification);
        break;
      default:
        break;
    }
  }

  private displayError(messageResult: RuleActionDisplayMessageResult): void {
    this.translateMessage(messageResult);
    const errorMessage: Message = {
      key: 'notification',
      severity: 'error',
      summary: $localize`:@@error:Error`,
      detail: messageResult.Message,
      sticky: false
    };
    this.messageService.add(errorMessage);
  }

  processConfirmationAndYesNoAction(
    notification: RuleActionNotification<DisplayMessageNotificationEvent>
  ) {
    const ruleActionDisplayMessageAction = notification.action as RuleActionDisplayMessageResult;
    this.showMessage(
      ruleActionDisplayMessageAction,
      (ruleActionDisplayMessageAction: RuleActionDisplayMessageResult) => {
        this.notifyFollowUpActions(ruleActionDisplayMessageAction.FollowUpYesAction, notification);
      },
      (ruleActionDisplayMessageAction) => {
        this.notifyFollowUpActions(ruleActionDisplayMessageAction.FollowUpNoAction, notification);
      }
    );
  }

  private notifyFollowUpActions(
    followUpAction: RuleAction | null,
    notification: RuleActionNotification<DisplayMessageNotificationEvent>
  ): void {
    if (followUpAction) {
      notification.action = followUpAction;
      this.ruleActionNotificationService.NotifyAction(notification);
    } else {
      notification.evaluateNextRule();
    }
  }

  private showMessage(
    displayMessageContext: RuleActionDisplayMessageResult,
    acceptCallback: (ruleActionDisplayMessageAction: RuleActionDisplayMessageResult) => void,
    rejectCallback: (ruleActionDisplayMessageAction: RuleActionDisplayMessageResult) => void
  ) {
    this.translateMessage(displayMessageContext);
    const dialogContext = this.getDialogContext(displayMessageContext.MessageType);
    this.confirmationService.confirm({
      message: `${displayMessageContext.Message}`,
      header: dialogContext.header,
      acceptVisible: true,
      acceptLabel: dialogContext.acceptLabel,
      rejectVisible: displayMessageContext.MessageType === DisplayMessageType.YesNo,
      rejectLabel: $localize`:@@confirmationNo:No`,
      closeOnEscape: true,
      dismissableMask: false,
      accept: () => {
        acceptCallback(displayMessageContext);
      },
      reject: () => {
        rejectCallback(displayMessageContext);
      }
    });
  }

  private getDialogContext(messageType: DisplayMessageType): {
    header: string;
    acceptLabel: string;
  } {
    switch (messageType) {
      case DisplayMessageType.YesNo:
        return {
          header: $localize`:@@confirmationHeader:Confirmation`,
          acceptLabel: $localize`:@@confirmationYes:Yes`
        };
      case DisplayMessageType.Confirmation:
        return {
          header: $localize`:@@confirmationHeader:Confirmation`,
          acceptLabel: $localize`:@@okTitle:OK`
        };
      default:
        throw new Error('Not supported');
    }
  }

  private translateMessage(displayMessageContext: RuleActionDisplayMessageResult) {
    const localize = this.getLocalizeInstance();
    // "Any" type can not avoided here as TemplateStringArray has many members which are not needed to be supplied from our end
    const translatedMessage = localize({
      '0': `:@@${displayMessageContext.TranslationKey}:${displayMessageContext.TranslationKey}`,
      raw: [':']
    } as any);
    if (
      translatedMessage !== 'undefined' &&
      translatedMessage !== displayMessageContext.TranslationKey
    ) {
      displayMessageContext.Message = translatedMessage;
    }
  }

  getLocalizeInstance() {
    return $localize;
  }
}
