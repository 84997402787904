/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { featureManagerFeatureFlagsGet$Json } from '../fn/feature-management/feature-manager-feature-flags-get-json';
import { FeatureManagerFeatureFlagsGet$Json$Params } from '../fn/feature-management/feature-manager-feature-flags-get-json';
import { featureManagerFeatureFlagsGet$Plain } from '../fn/feature-management/feature-manager-feature-flags-get-plain';
import { FeatureManagerFeatureFlagsGet$Plain$Params } from '../fn/feature-management/feature-manager-feature-flags-get-plain';

@Injectable({ providedIn: 'root' })
export class FeatureManagementService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `featureManagerFeatureFlagsGet()` */
  static readonly FeatureManagerFeatureFlagsGetPath = '/feature-manager/feature-flags';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `featureManagerFeatureFlagsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  featureManagerFeatureFlagsGet$Plain$Response(params?: FeatureManagerFeatureFlagsGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<{
[key: string]: boolean;
}>> {
    return featureManagerFeatureFlagsGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `featureManagerFeatureFlagsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  featureManagerFeatureFlagsGet$Plain(params?: FeatureManagerFeatureFlagsGet$Plain$Params, context?: HttpContext): Observable<{
[key: string]: boolean;
}> {
    return this.featureManagerFeatureFlagsGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
[key: string]: boolean;
}>): {
[key: string]: boolean;
} => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `featureManagerFeatureFlagsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  featureManagerFeatureFlagsGet$Json$Response(params?: FeatureManagerFeatureFlagsGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<{
[key: string]: boolean;
}>> {
    return featureManagerFeatureFlagsGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `featureManagerFeatureFlagsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  featureManagerFeatureFlagsGet$Json(params?: FeatureManagerFeatureFlagsGet$Json$Params, context?: HttpContext): Observable<{
[key: string]: boolean;
}> {
    return this.featureManagerFeatureFlagsGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
[key: string]: boolean;
}>): {
[key: string]: boolean;
} => r.body)
    );
  }

}
