export class ELNFeatureFlags {
  public static readonly AuditExperimentCover = 'AuditExperimentCover';
  public static readonly CanShowReferences = 'CanShowReferences';
  public static readonly CrossReferenceRemovalEnabled = 'CrossReferenceRemovalEnabled';
  public static readonly ActivityOutputsEnabled = 'ActivityOutputsEnabled';
  public static readonly ShowAllExperimentsOnGrid = "ShowAllExperimentsOnGrid";
  public static readonly ShowAllRecipesOnGrid = "ShowAllRecipesOnGrid";
  public static readonly EnableESignatureForExperimentTransition = "EnableESignatureForExperimentTransition";
  public static readonly EnableRecipeWorkflowReAuth = "EnableRecipeWorkflowReAuth";
  public static readonly EnableTemplateWorkflowReAuth = "EnableTemplateWorkflowReAuth";
  public static readonly ReleaseToggleImpactAssessmentEnabled = "ReleaseToggleImpactAssessmentEnabled";
  public static readonly UseLabSiteTimeZone = "UseLabSiteTimeZone"
}
