import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BptGridComponent, BptGridMode, BptGridRowActionClickEvent, BptGridRowActionConfiguration, BptRowActionElement, ColumnDefinition } from 'bpt-ui-library/bpt-grid';
import { BaseComponent } from '../../../../base/base.component';
import { ClientStateService } from 'services/client-state.service';
import { ActivatedRoute } from '@angular/router';
import { UnsubscribeAll } from '../../../../shared/rx-js-helpers';
import { BehaviorSubject, Subject } from 'rxjs';
import { ExperimentService } from '../../../services/experiment.service';
import { Activity } from '../../../../model/experiment.interface';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { UserService } from '../../../../services/user.service';
import { ConfirmationService } from 'primeng/api';
import { TableDataService } from '../table-data.service';

export type RemovedRowsDialogConfigData = {
  columnDefinitions: ColumnDefinition[],
  getRows(): { [key: string]: any }[],
  tableTitle: string,
  /** Notifies the caller that a restore is requested. ID of the row that is being restored */
  rowRestoreRequested: Subject<string>,

  /** Notifies the dialog (this) that a restore has occurred and to refresh the data source */
  rowRestored: Subject<string>,

  /** Notifies the dialog (this) that a removal has occurred and to refresh the data source */
  rowRemoved: Subject<string>
};

/**
 * UI for a generic presentation of removed rows and affordance for restoration of rows. Designed for use as a dialog.
 * Listens for external restoration of rows.
 * Requires a column of of type rowId. Does not display any ~rowSelected~ column.
 */
@Component({
  selector: 'app-removed-rows',
  templateUrl: './removed-rows.component.html',
  styleUrls: ['./removed-rows.component.scss']
})
export class RemovedRowsComponent<T extends RemovedRowsDialogConfigData> extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() subHeading = $localize`:@@howToRestoreRows:Please click on the restore icon from the table to restore removed rows`;
  @ViewChild('grid') grid!: BptGridComponent;

  dataSource: { [key: string]: any }[] = [];
  gridMode = BptGridMode.dataView;
  private readonly configData!: T;
  get tableTitle(): string { return $localize`:@@removedRowsModalHeader:Removed Rows for Table: ${this.configData.tableTitle}`; }

  get columnDefinitions(): ColumnDefinition[] {
    return ((this.configData.columnDefinitions ?? [])).filter(c => c.field !== TableDataService.rowSelectedField);
  }

  get currentActivity(): Activity | undefined {
    return this.experimentService.currentActivity;
  }

  get gridActions(): BptGridRowActionConfiguration {
    const rowActions = this.rowActionItems;
    const actionsSubject: BehaviorSubject<BptRowActionElement[]> = new BehaviorSubject(rowActions);

    return { actions: actionsSubject };
  }

  get rowActionItems(): BptRowActionElement[] {
    return [
      {
        id: 'bpt-table-restore-row',
        enabled: !this.userService.hasOnlyReviewerRights(),
        styleClass: 'icon-s icon-restore-icon',
        click: this.rowRestoreActionClick.bind(this),
        tooltip: $localize`:@@restoreRow:Restore row`
      }
    ];
  }

  constructor(
    public readonly activatedRoute: ActivatedRoute,
    public readonly clientStateService: ClientStateService,
    private readonly userService: UserService,
    private readonly confirmationService: ConfirmationService,
    config: DynamicDialogConfig<T>,
    private readonly experimentService: ExperimentService,
  ) {
    super(clientStateService, activatedRoute);
    if (!config.data) throw new Error('LOGIC ERROR: RemovedRowsComponent expects config data');
    this.configData = config.data;
  }

  ngOnInit(): void {
    this.watchRestoredNotification();
  }

  ngAfterViewInit(): void {
    this.refreshDataSource();
  }

  ngOnDestroy(): void {
    UnsubscribeAll(this.activeSubscriptions);
  }

  private refreshDataSource(): void {
    this.dataSource = this.configData.getRows() ?? []
    // Apparently it's not enough to set the property the grid's dataSource is bound to, we have to call the API for some reason
    this.grid?.gridApi?.setGridOption('rowData', this.dataSource);
  }

  private watchRestoredNotification() {
    this.activeSubscriptions.push(
      this.configData.rowRestored.subscribe({
        next: () => this.refreshDataSource() // don't care which row; assumes data source is already updated.
      })
    );
    this.activeSubscriptions.push(
      this.configData.rowRemoved.subscribe({
        next: () => this.refreshDataSource() // don't care which row; assumes data source is already updated.
      })
    );
  }

  private rowRestoreActionClick(e: BptGridRowActionClickEvent) {
    this.confirmationService.confirm({
      message: $localize`:@@restoreRowConfirmation:Are you sure you wish to restore this row?`,
      header: $localize`:@@confirmationHeader:Confirmation`,
      closeOnEscape: true,
      dismissableMask: false,
      acceptVisible: true,
      acceptLabel: $localize`:@@ok:OK`,
      rejectVisible: true,
      rejectLabel: $localize`:@@cancel:Cancel`,
      accept: () => {
        this.configData.rowRestoreRequested.next(e.params.data['id']);
      },
    });
  }
}
