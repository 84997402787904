<p-dialog 
  [(visible)]="showModal" 
  [closable]="closable" 
  [modal]="modal" 
  [draggable]="draggable"
  styleClass="custom-dialog">
  <ng-template pTemplate="header">
    <div class="dialog-header" [ngStyle]="{'justify-content': config.headerAlignment || 'left'}">
      <div class="mr-1" 
        [ngClass]="headerIcon" 
        *ngIf="headerIcon"
        [ngStyle]="{'color': config.iconColor}"></div>
      <span>{{header}}</span>
    </div>
  </ng-template>
  <div class="mb-4" *ngIf="subHeader">{{subHeader}}</div>

  <div class="mb-5 dialog-content" [ngStyle]="{'text-align': config.contentAlignment || 'left'}">
    <ng-content></ng-content>
  </div>

  <div class="flex justify-content-center">
    <p-button 
      *ngFor="let button of buttons"
      ngClass="button-style" 
      (click)="handleButtonClick(button)" 
      [styleClass]="button.styleClass ?? ''">
      {{button.label}}
    </p-button>
  </div>
</p-dialog>
