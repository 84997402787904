<div class="eln-activity">
  <bpt-progress-spinner
    *ngIf="isLoading"
    [useOverlay]="true"
    [message]="message"
  ></bpt-progress-spinner>

  <p-contextMenu class="eln-module-contextMenu" [model]="items" #cm></p-contextMenu>
  <p-tabView 
  cdkDropList
  #tabview 
  *ngIf="recipe" 
  (onChange)="moduleSelectionChanged($event)"
  cdkDropListOrientation="horizontal"
  (cdkDropListDropped)="drop($event)"
  >
    <ng-container *ngFor="let module of modules; let i = index">
      <p-tabPanel>
        <ng-template pTemplate="header">
          <span
            cdkDrag
            [cdkDragDisabled]="!recipeNodeReOrderService.hasPermission() || !recipeNodeReOrderService.hasPermissionWithRespectToFlow() || recipeHasErrors"
            *ngIf="!retitleEnabled || !retitleEnabled[module.moduleId]"
            class="eln-module-tab-header"
            (contextmenu)="cm.show($event); onContextMenu(module,cm)"
            pTooltip="{{ module.moduleLabel }}"
            tooltipPosition="top"
            [id]="'eln-tabAuditHistoryShow' + i"
            [innerText]="module.moduleLabel"
            (dblclick)="contextCurrentModuleId = module.moduleId; checkForPermission(module.moduleId)">
          >
          </span>
          <div class="bpt-eln-inline-control">
            <app-recipe-node-title-changer
              *ngIf="retitleEnabled && retitleEnabled[module.moduleId]"
              [nodeId]="module.moduleId"
              [parentNodeId]="activity ? [activity.activityId] : [recipe.recipeId]"
              [nodeType]="nodeType.Module"
              [isRecipe]="checkForRecipe(module)"
              [currentTitle]="module.moduleLabel"
              (titleChangeHasCancelled)="retitleEnabled[module.moduleId] = false; fixupTabView();"
              (titleChanged)="module.moduleLabel = $event.title;retitleEnabled[module.moduleId] = false; fixupTabView();">
            </app-recipe-node-title-changer>
          </div>
          
          <span *ngIf="i !== modules.length - 1" class="eln-module-tab-header-separator"></span>
        </ng-template>
        <ng-template #moduleTemplate>
          <app-recipe-data-module [module]="module" [isRecipe]="isRecipe" [recipeId]="recipe.recipeId" [parentNodeId]="activity ? [activity.activityId] : [recipe.recipeId]"> </app-recipe-data-module>
        </ng-template>
        <ng-container *ngIf="true">
          <ng-container *ngTemplateOutlet="moduleTemplate"> </ng-container>
        </ng-container>
      </p-tabPanel>
    </ng-container>
  </p-tabView>
</div>
