/* tslint:disable */
/* eslint-disable */
export enum RecipeState {
  Draft = 'draft',
  PendingVerification = 'pendingVerification',
  PendingApproval = 'pendingApproval',
  Cancelled = 'cancelled',
  Published = 'published',
  Retired = 'retired'
}
