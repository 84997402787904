/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { activityOutputEventsAttachFilesPost$Json } from '../fn/activity-output-events/activity-output-events-attach-files-post-json';
import { ActivityOutputEventsAttachFilesPost$Json$Params } from '../fn/activity-output-events/activity-output-events-attach-files-post-json';
import { activityOutputEventsAttachFilesPost$Plain } from '../fn/activity-output-events/activity-output-events-attach-files-post-plain';
import { ActivityOutputEventsAttachFilesPost$Plain$Params } from '../fn/activity-output-events/activity-output-events-attach-files-post-plain';
import { activityOutputEventsChangeExceptionReferencePost$Json } from '../fn/activity-output-events/activity-output-events-change-exception-reference-post-json';
import { ActivityOutputEventsChangeExceptionReferencePost$Json$Params } from '../fn/activity-output-events/activity-output-events-change-exception-reference-post-json';
import { activityOutputEventsChangeExceptionReferencePost$Plain } from '../fn/activity-output-events/activity-output-events-change-exception-reference-post-plain';
import { ActivityOutputEventsChangeExceptionReferencePost$Plain$Params } from '../fn/activity-output-events/activity-output-events-change-exception-reference-post-plain';
import { activityOutputEventsChangeJustificationPost$Json } from '../fn/activity-output-events/activity-output-events-change-justification-post-json';
import { ActivityOutputEventsChangeJustificationPost$Json$Params } from '../fn/activity-output-events/activity-output-events-change-justification-post-json';
import { activityOutputEventsChangeJustificationPost$Plain } from '../fn/activity-output-events/activity-output-events-change-justification-post-plain';
import { ActivityOutputEventsChangeJustificationPost$Plain$Params } from '../fn/activity-output-events/activity-output-events-change-justification-post-plain';
import { activityOutputEventsChangeNonRoutineSelectionPost$Json } from '../fn/activity-output-events/activity-output-events-change-non-routine-selection-post-json';
import { ActivityOutputEventsChangeNonRoutineSelectionPost$Json$Params } from '../fn/activity-output-events/activity-output-events-change-non-routine-selection-post-json';
import { activityOutputEventsChangeNonRoutineSelectionPost$Plain } from '../fn/activity-output-events/activity-output-events-change-non-routine-selection-post-plain';
import { ActivityOutputEventsChangeNonRoutineSelectionPost$Plain$Params } from '../fn/activity-output-events/activity-output-events-change-non-routine-selection-post-plain';
import { activityOutputEventsChangeReturnToServiceSelectionPost$Json } from '../fn/activity-output-events/activity-output-events-change-return-to-service-selection-post-json';
import { ActivityOutputEventsChangeReturnToServiceSelectionPost$Json$Params } from '../fn/activity-output-events/activity-output-events-change-return-to-service-selection-post-json';
import { activityOutputEventsChangeReturnToServiceSelectionPost$Plain } from '../fn/activity-output-events/activity-output-events-change-return-to-service-selection-post-plain';
import { ActivityOutputEventsChangeReturnToServiceSelectionPost$Plain$Params } from '../fn/activity-output-events/activity-output-events-change-return-to-service-selection-post-plain';
import { activityOutputEventsDeleteFilesPost$Json } from '../fn/activity-output-events/activity-output-events-delete-files-post-json';
import { ActivityOutputEventsDeleteFilesPost$Json$Params } from '../fn/activity-output-events/activity-output-events-delete-files-post-json';
import { activityOutputEventsDeleteFilesPost$Plain } from '../fn/activity-output-events/activity-output-events-delete-files-post-plain';
import { ActivityOutputEventsDeleteFilesPost$Plain$Params } from '../fn/activity-output-events/activity-output-events-delete-files-post-plain';
import { activityOutputEventsImportChromatographyDataPost$Json } from '../fn/activity-output-events/activity-output-events-import-chromatography-data-post-json';
import { ActivityOutputEventsImportChromatographyDataPost$Json$Params } from '../fn/activity-output-events/activity-output-events-import-chromatography-data-post-json';
import { activityOutputEventsImportChromatographyDataPost$Plain } from '../fn/activity-output-events/activity-output-events-import-chromatography-data-post-plain';
import { ActivityOutputEventsImportChromatographyDataPost$Plain$Params } from '../fn/activity-output-events/activity-output-events-import-chromatography-data-post-plain';
import { activityOutputEventsRefreshChromatographyDataPost$Json } from '../fn/activity-output-events/activity-output-events-refresh-chromatography-data-post-json';
import { ActivityOutputEventsRefreshChromatographyDataPost$Json$Params } from '../fn/activity-output-events/activity-output-events-refresh-chromatography-data-post-json';
import { activityOutputEventsRefreshChromatographyDataPost$Plain } from '../fn/activity-output-events/activity-output-events-refresh-chromatography-data-post-plain';
import { ActivityOutputEventsRefreshChromatographyDataPost$Plain$Params } from '../fn/activity-output-events/activity-output-events-refresh-chromatography-data-post-plain';
import { activityOutputEventsRemoveChromatographyDataPost$Json } from '../fn/activity-output-events/activity-output-events-remove-chromatography-data-post-json';
import { ActivityOutputEventsRemoveChromatographyDataPost$Json$Params } from '../fn/activity-output-events/activity-output-events-remove-chromatography-data-post-json';
import { activityOutputEventsRemoveChromatographyDataPost$Plain } from '../fn/activity-output-events/activity-output-events-remove-chromatography-data-post-plain';
import { ActivityOutputEventsRemoveChromatographyDataPost$Plain$Params } from '../fn/activity-output-events/activity-output-events-remove-chromatography-data-post-plain';
import { AttachedFileResponse } from '../models/attached-file-response';
import { ChromatographyDataImportResponse } from '../models/chromatography-data-import-response';
import { ChromatographyDataRemovedResponse } from '../models/chromatography-data-removed-response';
import { ChromatographyDataRowRefreshResponse } from '../models/chromatography-data-row-refresh-response';
import { DeletedFilesResponse } from '../models/deleted-files-response';
import { ExceptionReferenceChangedResponse } from '../models/exception-reference-changed-response';
import { JustificationChangedResponse } from '../models/justification-changed-response';
import { NonRoutineIssueEncounteredDataResponse } from '../models/non-routine-issue-encountered-data-response';
import { ReturnedToServiceDataResponse } from '../models/returned-to-service-data-response';

@Injectable({ providedIn: 'root' })
export class ActivityOutputEventsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `activityOutputEventsRefreshChromatographyDataPost()` */
  static readonly ActivityOutputEventsRefreshChromatographyDataPostPath = '/activity-output-events/refresh-chromatography-data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityOutputEventsRefreshChromatographyDataPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityOutputEventsRefreshChromatographyDataPost$Plain$Response(params?: ActivityOutputEventsRefreshChromatographyDataPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ChromatographyDataRowRefreshResponse>> {
    return activityOutputEventsRefreshChromatographyDataPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityOutputEventsRefreshChromatographyDataPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityOutputEventsRefreshChromatographyDataPost$Plain(params?: ActivityOutputEventsRefreshChromatographyDataPost$Plain$Params, context?: HttpContext): Observable<ChromatographyDataRowRefreshResponse> {
    return this.activityOutputEventsRefreshChromatographyDataPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChromatographyDataRowRefreshResponse>): ChromatographyDataRowRefreshResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityOutputEventsRefreshChromatographyDataPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityOutputEventsRefreshChromatographyDataPost$Json$Response(params?: ActivityOutputEventsRefreshChromatographyDataPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ChromatographyDataRowRefreshResponse>> {
    return activityOutputEventsRefreshChromatographyDataPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityOutputEventsRefreshChromatographyDataPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityOutputEventsRefreshChromatographyDataPost$Json(params?: ActivityOutputEventsRefreshChromatographyDataPost$Json$Params, context?: HttpContext): Observable<ChromatographyDataRowRefreshResponse> {
    return this.activityOutputEventsRefreshChromatographyDataPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChromatographyDataRowRefreshResponse>): ChromatographyDataRowRefreshResponse => r.body)
    );
  }

  /** Path part for operation `activityOutputEventsImportChromatographyDataPost()` */
  static readonly ActivityOutputEventsImportChromatographyDataPostPath = '/activity-output-events/import-chromatography-data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityOutputEventsImportChromatographyDataPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityOutputEventsImportChromatographyDataPost$Plain$Response(params?: ActivityOutputEventsImportChromatographyDataPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ChromatographyDataImportResponse>> {
    return activityOutputEventsImportChromatographyDataPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityOutputEventsImportChromatographyDataPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityOutputEventsImportChromatographyDataPost$Plain(params?: ActivityOutputEventsImportChromatographyDataPost$Plain$Params, context?: HttpContext): Observable<ChromatographyDataImportResponse> {
    return this.activityOutputEventsImportChromatographyDataPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChromatographyDataImportResponse>): ChromatographyDataImportResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityOutputEventsImportChromatographyDataPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityOutputEventsImportChromatographyDataPost$Json$Response(params?: ActivityOutputEventsImportChromatographyDataPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ChromatographyDataImportResponse>> {
    return activityOutputEventsImportChromatographyDataPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityOutputEventsImportChromatographyDataPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityOutputEventsImportChromatographyDataPost$Json(params?: ActivityOutputEventsImportChromatographyDataPost$Json$Params, context?: HttpContext): Observable<ChromatographyDataImportResponse> {
    return this.activityOutputEventsImportChromatographyDataPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChromatographyDataImportResponse>): ChromatographyDataImportResponse => r.body)
    );
  }

  /** Path part for operation `activityOutputEventsRemoveChromatographyDataPost()` */
  static readonly ActivityOutputEventsRemoveChromatographyDataPostPath = '/activity-output-events/remove-chromatography-data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityOutputEventsRemoveChromatographyDataPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityOutputEventsRemoveChromatographyDataPost$Plain$Response(params?: ActivityOutputEventsRemoveChromatographyDataPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ChromatographyDataRemovedResponse>> {
    return activityOutputEventsRemoveChromatographyDataPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityOutputEventsRemoveChromatographyDataPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityOutputEventsRemoveChromatographyDataPost$Plain(params?: ActivityOutputEventsRemoveChromatographyDataPost$Plain$Params, context?: HttpContext): Observable<ChromatographyDataRemovedResponse> {
    return this.activityOutputEventsRemoveChromatographyDataPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChromatographyDataRemovedResponse>): ChromatographyDataRemovedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityOutputEventsRemoveChromatographyDataPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityOutputEventsRemoveChromatographyDataPost$Json$Response(params?: ActivityOutputEventsRemoveChromatographyDataPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ChromatographyDataRemovedResponse>> {
    return activityOutputEventsRemoveChromatographyDataPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityOutputEventsRemoveChromatographyDataPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityOutputEventsRemoveChromatographyDataPost$Json(params?: ActivityOutputEventsRemoveChromatographyDataPost$Json$Params, context?: HttpContext): Observable<ChromatographyDataRemovedResponse> {
    return this.activityOutputEventsRemoveChromatographyDataPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChromatographyDataRemovedResponse>): ChromatographyDataRemovedResponse => r.body)
    );
  }

  /** Path part for operation `activityOutputEventsChangeNonRoutineSelectionPost()` */
  static readonly ActivityOutputEventsChangeNonRoutineSelectionPostPath = '/activity-output-events/change-non-routine-selection';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityOutputEventsChangeNonRoutineSelectionPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityOutputEventsChangeNonRoutineSelectionPost$Plain$Response(params?: ActivityOutputEventsChangeNonRoutineSelectionPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<NonRoutineIssueEncounteredDataResponse>> {
    return activityOutputEventsChangeNonRoutineSelectionPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityOutputEventsChangeNonRoutineSelectionPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityOutputEventsChangeNonRoutineSelectionPost$Plain(params?: ActivityOutputEventsChangeNonRoutineSelectionPost$Plain$Params, context?: HttpContext): Observable<NonRoutineIssueEncounteredDataResponse> {
    return this.activityOutputEventsChangeNonRoutineSelectionPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<NonRoutineIssueEncounteredDataResponse>): NonRoutineIssueEncounteredDataResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityOutputEventsChangeNonRoutineSelectionPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityOutputEventsChangeNonRoutineSelectionPost$Json$Response(params?: ActivityOutputEventsChangeNonRoutineSelectionPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<NonRoutineIssueEncounteredDataResponse>> {
    return activityOutputEventsChangeNonRoutineSelectionPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityOutputEventsChangeNonRoutineSelectionPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityOutputEventsChangeNonRoutineSelectionPost$Json(params?: ActivityOutputEventsChangeNonRoutineSelectionPost$Json$Params, context?: HttpContext): Observable<NonRoutineIssueEncounteredDataResponse> {
    return this.activityOutputEventsChangeNonRoutineSelectionPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<NonRoutineIssueEncounteredDataResponse>): NonRoutineIssueEncounteredDataResponse => r.body)
    );
  }

  /** Path part for operation `activityOutputEventsChangeExceptionReferencePost()` */
  static readonly ActivityOutputEventsChangeExceptionReferencePostPath = '/activity-output-events/change-exception-reference';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityOutputEventsChangeExceptionReferencePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityOutputEventsChangeExceptionReferencePost$Plain$Response(params?: ActivityOutputEventsChangeExceptionReferencePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ExceptionReferenceChangedResponse>> {
    return activityOutputEventsChangeExceptionReferencePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityOutputEventsChangeExceptionReferencePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityOutputEventsChangeExceptionReferencePost$Plain(params?: ActivityOutputEventsChangeExceptionReferencePost$Plain$Params, context?: HttpContext): Observable<ExceptionReferenceChangedResponse> {
    return this.activityOutputEventsChangeExceptionReferencePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExceptionReferenceChangedResponse>): ExceptionReferenceChangedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityOutputEventsChangeExceptionReferencePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityOutputEventsChangeExceptionReferencePost$Json$Response(params?: ActivityOutputEventsChangeExceptionReferencePost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ExceptionReferenceChangedResponse>> {
    return activityOutputEventsChangeExceptionReferencePost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityOutputEventsChangeExceptionReferencePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityOutputEventsChangeExceptionReferencePost$Json(params?: ActivityOutputEventsChangeExceptionReferencePost$Json$Params, context?: HttpContext): Observable<ExceptionReferenceChangedResponse> {
    return this.activityOutputEventsChangeExceptionReferencePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExceptionReferenceChangedResponse>): ExceptionReferenceChangedResponse => r.body)
    );
  }

  /** Path part for operation `activityOutputEventsChangeJustificationPost()` */
  static readonly ActivityOutputEventsChangeJustificationPostPath = '/activity-output-events/change-justification';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityOutputEventsChangeJustificationPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityOutputEventsChangeJustificationPost$Plain$Response(params?: ActivityOutputEventsChangeJustificationPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<JustificationChangedResponse>> {
    return activityOutputEventsChangeJustificationPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityOutputEventsChangeJustificationPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityOutputEventsChangeJustificationPost$Plain(params?: ActivityOutputEventsChangeJustificationPost$Plain$Params, context?: HttpContext): Observable<JustificationChangedResponse> {
    return this.activityOutputEventsChangeJustificationPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<JustificationChangedResponse>): JustificationChangedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityOutputEventsChangeJustificationPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityOutputEventsChangeJustificationPost$Json$Response(params?: ActivityOutputEventsChangeJustificationPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<JustificationChangedResponse>> {
    return activityOutputEventsChangeJustificationPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityOutputEventsChangeJustificationPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityOutputEventsChangeJustificationPost$Json(params?: ActivityOutputEventsChangeJustificationPost$Json$Params, context?: HttpContext): Observable<JustificationChangedResponse> {
    return this.activityOutputEventsChangeJustificationPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<JustificationChangedResponse>): JustificationChangedResponse => r.body)
    );
  }

  /** Path part for operation `activityOutputEventsChangeReturnToServiceSelectionPost()` */
  static readonly ActivityOutputEventsChangeReturnToServiceSelectionPostPath = '/activity-output-events/change-return-to-service-selection';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityOutputEventsChangeReturnToServiceSelectionPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityOutputEventsChangeReturnToServiceSelectionPost$Plain$Response(params?: ActivityOutputEventsChangeReturnToServiceSelectionPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ReturnedToServiceDataResponse>> {
    return activityOutputEventsChangeReturnToServiceSelectionPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityOutputEventsChangeReturnToServiceSelectionPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityOutputEventsChangeReturnToServiceSelectionPost$Plain(params?: ActivityOutputEventsChangeReturnToServiceSelectionPost$Plain$Params, context?: HttpContext): Observable<ReturnedToServiceDataResponse> {
    return this.activityOutputEventsChangeReturnToServiceSelectionPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReturnedToServiceDataResponse>): ReturnedToServiceDataResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityOutputEventsChangeReturnToServiceSelectionPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityOutputEventsChangeReturnToServiceSelectionPost$Json$Response(params?: ActivityOutputEventsChangeReturnToServiceSelectionPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ReturnedToServiceDataResponse>> {
    return activityOutputEventsChangeReturnToServiceSelectionPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityOutputEventsChangeReturnToServiceSelectionPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityOutputEventsChangeReturnToServiceSelectionPost$Json(params?: ActivityOutputEventsChangeReturnToServiceSelectionPost$Json$Params, context?: HttpContext): Observable<ReturnedToServiceDataResponse> {
    return this.activityOutputEventsChangeReturnToServiceSelectionPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReturnedToServiceDataResponse>): ReturnedToServiceDataResponse => r.body)
    );
  }

  /** Path part for operation `activityOutputEventsAttachFilesPost()` */
  static readonly ActivityOutputEventsAttachFilesPostPath = '/activity-output-events/attach-files';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityOutputEventsAttachFilesPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityOutputEventsAttachFilesPost$Plain$Response(params?: ActivityOutputEventsAttachFilesPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<AttachedFileResponse>> {
    return activityOutputEventsAttachFilesPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityOutputEventsAttachFilesPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityOutputEventsAttachFilesPost$Plain(params?: ActivityOutputEventsAttachFilesPost$Plain$Params, context?: HttpContext): Observable<AttachedFileResponse> {
    return this.activityOutputEventsAttachFilesPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<AttachedFileResponse>): AttachedFileResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityOutputEventsAttachFilesPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityOutputEventsAttachFilesPost$Json$Response(params?: ActivityOutputEventsAttachFilesPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<AttachedFileResponse>> {
    return activityOutputEventsAttachFilesPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityOutputEventsAttachFilesPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityOutputEventsAttachFilesPost$Json(params?: ActivityOutputEventsAttachFilesPost$Json$Params, context?: HttpContext): Observable<AttachedFileResponse> {
    return this.activityOutputEventsAttachFilesPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<AttachedFileResponse>): AttachedFileResponse => r.body)
    );
  }

  /** Path part for operation `activityOutputEventsDeleteFilesPost()` */
  static readonly ActivityOutputEventsDeleteFilesPostPath = '/activity-output-events/delete-files';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityOutputEventsDeleteFilesPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityOutputEventsDeleteFilesPost$Plain$Response(params?: ActivityOutputEventsDeleteFilesPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<DeletedFilesResponse>> {
    return activityOutputEventsDeleteFilesPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityOutputEventsDeleteFilesPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityOutputEventsDeleteFilesPost$Plain(params?: ActivityOutputEventsDeleteFilesPost$Plain$Params, context?: HttpContext): Observable<DeletedFilesResponse> {
    return this.activityOutputEventsDeleteFilesPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeletedFilesResponse>): DeletedFilesResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityOutputEventsDeleteFilesPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityOutputEventsDeleteFilesPost$Json$Response(params?: ActivityOutputEventsDeleteFilesPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<DeletedFilesResponse>> {
    return activityOutputEventsDeleteFilesPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityOutputEventsDeleteFilesPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityOutputEventsDeleteFilesPost$Json(params?: ActivityOutputEventsDeleteFilesPost$Json$Params, context?: HttpContext): Observable<DeletedFilesResponse> {
    return this.activityOutputEventsDeleteFilesPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeletedFilesResponse>): DeletedFilesResponse => r.body)
    );
  }

}
