<div
  id="eln-labitems-removed-instrument-grid-container"
  class="eln-lab-items-data eln-table-theme"
  [style]="gridActionsPositionManager.styleDynamicVariables"
>
  <div class="flex flex-row eln-subHeading">
    {{ subHeading }}
  </div>
  <div class="eln-tableTitle">{{ titleOfTable }}</div>
  <bpt-grid
    #labItemsRemovedInstrumentGrid
    class="eln-grid hide-readonly-icons"
    ngClass="true"
    gridId="{{ labItemsInstrumentId }}"
    [dataSource]="labItemsRemovedInstrumentDataSource"
    [useJsJodaTypes]="true"
    [columnDefinitions]="columnDefinitions"
    [gridMode]="gridMode"
    [allowRowAdd]="false"
    (gridReady)="onGridReady()"
    [showAutoSizeButton]="true"
    [showFilterToggleButton]="true"
    [showGridOptionsButton]="false"
    [rowActionConfigurations]="gridActions"
    [displayScrollButtons] = "displayScrollButtons"
    [paginated]="false"
    [applicationGridOptions]="gridActionsPositionManager.applicationGridOptions"
    (firstDataRendered)="gridActionsPositionManager.firstDataRendered($event)"
  >
  </bpt-grid>
</div>
