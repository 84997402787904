import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { UnsubscribeAll } from "../../../shared/rx-js-helpers";
import { BehaviorSubject, Subscription } from "rxjs";
import { BptGridComponent, BptGridMode, BptGridRowActionClickEvent, BptGridRowActionConfiguration, BptRowActionElement, ColumnDefinition } from "bpt-ui-library/bpt-grid";
import { PreparationLabItemsService } from "../../services/preparation-labitems.service";
import { PreparationService } from "../../services/preparation.service";
import { ActivityInputType, ExperimentPreparation } from "../../../api/models";
import { ClientStateService } from "../../../services/client-state.service";
import { ActivatedRoute } from "@angular/router";
import { BaseComponent } from "../../../base/base.component";
import { ClientState } from "../../../app.states";

@Component({
  selector: 'app-removed-preparations-labitems',
  templateUrl: './removed-preparations-labitems.component.html',
  styleUrls: ['./removed-preparations-labitems.component.scss']
})

export class RemovedPreparationsLabitemsComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild('preparationsRemovedGrid') preparationsRemovedGrid!: BptGridComponent;
  removedPreparationData!: { [key: string]: ExperimentPreparation }[];
  titleOfTable = $localize`:@@removedPreparationColumnTableTitle:Removed Preparations`;
  subHeading = $localize`:@@preparationsRemovedColumnSubHeading:Please click on the restore icon from the table to restore removed preparation`;
  columnDefinitions: ColumnDefinition[] = [];
  rowActions: BptRowActionElement[] = [];
  protected activeSubscriptions: Subscription[] = [];
  removedRowsColumnId = 'removedPreparationRows';
  columnRestoreActionId = 'bpt-preparation-restore-row';
  gridActions!: BptGridRowActionConfiguration;
  isDataRendered = false;
  gridOptions = {
    showFilterToggleButton: true,
    showGridOptionsButton: false,
    allowRowAdd: false,
    pagination: false,
    gridMode: BptGridMode.dataView,
  }

  constructor(
    private readonly preparationService: PreparationService,
    private readonly preparationLabItemsService: PreparationLabItemsService,
    public readonly clientStateService: ClientStateService,
    public readonly activatedRoute: ActivatedRoute,
  ) {
    super(clientStateService, activatedRoute);
  }

  ngOnInit(): void {
    this.watchRemovedPreparationRefreshNotification();
    this.columnDefinitions = this.preparationLabItemsService.getColumnDefinitions();
    this.addGridActions();
    this.refreshDataSource();
  }

  private refreshDataSource(): void {
    const removedRows = this.preparationService.getAllPreparationsFromCurrentActivity(this.preparationLabItemsService.getLabItemsRemovedPreparations());
    this.removedPreparationData = [...this.preparationService.getPrimitiveDataValueRows(removedRows as any)];
    this.preparationsRemovedGrid?.gridApi?.setGridOption('rowData', this.removedPreparationData);
    this.preparationsRemovedGrid?.gridApi?.refreshCells();
    this.isDataRendered = true;
  }

  private watchRemovedPreparationRefreshNotification() {
    this.activeSubscriptions.push(
      this.preparationLabItemsService.labItemPreparationShouldRefresh.subscribe({
        next: () => this.refreshDataSource()
      })
    );
  }

  private addGridActions() {
    const rowActions = this.getRowActionItems();
    const actionsSubject: BehaviorSubject<BptRowActionElement[]> = new BehaviorSubject(rowActions);
    this.gridActions = {
      actions: actionsSubject,
    };
  }

  public getRowActionItems(): BptRowActionElement[] {
    return [
      {
        id: this.columnRestoreActionId,
        enabled: this.evaluatePermissions(),
        styleClass: 'icon-s icon-restore-icon',
        click: this.rowRestoreActionClick.bind(this),
        tooltip: $localize`:@@RestoreItem:Restore item`
      }
    ];
  }

  private rowRestoreActionClick(e: BptGridRowActionClickEvent) {
    this.preparationLabItemsService.restoreLabItemPreparation(e.params.data.preparationNumber);
  }

  ngOnDestroy(): void {
    UnsubscribeAll(this.activeSubscriptions);
  }

  private evaluatePermissions(): boolean {
    const features = this.clientStateService.getFeatureFlags(ClientState.EXPERIMENT_ACTIVITIES);
    this.preparationLabItemsService.featuresByClientState = features;
    return this.preparationLabItemsService.evaluateUserPermissionsOnLabItems()[ActivityInputType.Preparation]['eln-restore-lab-item'];
  }
}
