/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AuditHistoryDataRecordResponse } from '../models/audit-history-data-record-response';
import { tableAuditHistoryExperimentIdPost$Json } from '../fn/table-audit-history/table-audit-history-experiment-id-post-json';
import { TableAuditHistoryExperimentIdPost$Json$Params } from '../fn/table-audit-history/table-audit-history-experiment-id-post-json';
import { tableAuditHistoryExperimentIdPost$Plain } from '../fn/table-audit-history/table-audit-history-experiment-id-post-plain';
import { TableAuditHistoryExperimentIdPost$Plain$Params } from '../fn/table-audit-history/table-audit-history-experiment-id-post-plain';

@Injectable({ providedIn: 'root' })
export class TableAuditHistoryService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `tableAuditHistoryExperimentIdPost()` */
  static readonly TableAuditHistoryExperimentIdPostPath = '/table-audit-history/{experimentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tableAuditHistoryExperimentIdPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  tableAuditHistoryExperimentIdPost$Plain$Response(params: TableAuditHistoryExperimentIdPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<AuditHistoryDataRecordResponse>> {
    return tableAuditHistoryExperimentIdPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `tableAuditHistoryExperimentIdPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  tableAuditHistoryExperimentIdPost$Plain(params: TableAuditHistoryExperimentIdPost$Plain$Params, context?: HttpContext): Observable<AuditHistoryDataRecordResponse> {
    return this.tableAuditHistoryExperimentIdPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<AuditHistoryDataRecordResponse>): AuditHistoryDataRecordResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tableAuditHistoryExperimentIdPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  tableAuditHistoryExperimentIdPost$Json$Response(params: TableAuditHistoryExperimentIdPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<AuditHistoryDataRecordResponse>> {
    return tableAuditHistoryExperimentIdPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `tableAuditHistoryExperimentIdPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  tableAuditHistoryExperimentIdPost$Json(params: TableAuditHistoryExperimentIdPost$Json$Params, context?: HttpContext): Observable<AuditHistoryDataRecordResponse> {
    return this.tableAuditHistoryExperimentIdPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<AuditHistoryDataRecordResponse>): AuditHistoryDataRecordResponse => r.body)
    );
  }

}
