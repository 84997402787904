import { Component, OnDestroy, OnInit } from '@angular/core';
import { RecipeService } from '../services/recipe.service';
import { BaseComponent } from '../../base/base.component';
import { ClientStateService } from '../../services/client-state.service';
import { ActivatedRoute } from '@angular/router';
import { NodeType, RecipeState } from '../../api/cookbook/models';
import { TemplateLoaderType } from '../../recipe-template-loader/models/template-loader-type';
import { TemplateLoaderResult } from '../../template-loader/models/template-loader-result';
import { SearchControl } from 'bpt-ui-library/bpt-search';
import { SubBusinessUnit } from '../../api/search/models';
import { TemplateLoaderService } from '../../recipe-template-loader/services/template-loader.service';
import { RecipeModel } from '../model/recipe';
import { Form, Module, ModuleItem, Table } from '../../model/experiment.interface';
import { Observable, Subscription, of } from 'rxjs';
import { ColumnType, FormResponse } from '../../api/models';
import { UnsubscribeAll } from '../../shared/rx-js-helpers';
import { RecipeNodeReOrderService } from '../services/recipe-node-re-order.service';
import { User } from '../../model/user.interface';
import { UserService } from '../../services/user.service';
import { LoaderSearchCriteria } from '../../recipe-template-loader/experiment-template-load/models/finalised-search-criteria';
import { SelectedTemplateCommand } from '../../recipe-template-loader/models/template-loader-information';
import { TemplateLocationOptions } from '../../recipe-template-loader/experiment-template-load/models/insert-location.interface';
import { SelectedTemplate } from '../../recipe-template-loader/models/selected-template';
import { TemplateLoaderFilterChangedEvent } from '../../recipe-template-loader/models/template-loader-filter-changed-event.interface';
import { RecipeNotificationService } from '../services/recipe-notification.service';
import { ColumnDefinition } from 'bpt-ui-library/bpt-grid';
import { rowSelectedColumn } from '../data/table/recipe-table.component';

@Component({
  selector: 'app-recipe-designer',
  templateUrl: './recipe-designer.component.html',
  styleUrls: ['./recipe-designer.component.scss']
})
export class RecipeDesignerComponent extends BaseComponent implements OnInit, OnDestroy {
  showTemplateLoader = false;
  loadRecipeSecondaryHeader = 'loadRecipeSecondaryHeader';
  templateLoaderType: TemplateLoaderType = TemplateLoaderType.TemplateToRecipe;
  loaderSearchCriteria: LoaderSearchCriteria = {};
  searchControls: Array<SearchControl> = [];
  subBusinessUnits: SubBusinessUnit[] = [];
  recipe!: RecipeModel;
  tables!: Table[];
  forms!: Form[];
  modules!: Module[];
  subscriptions: Subscription[] = [];
  blankRecipe = true;
  formTables: Array<ModuleItem> = [];
  currentUser!: User;
  toolTip = $localize`:@@RecipeOrTemplateSearchTooltip:Search by Template/Recipe Name or Number`;
  typeChangeHandle = false;
  recipeHasErrors = false;

  confirmationMessage: { [key: string]: string } = {
    recipeAddActivityTemplateConfirmation:
      $localize`:@@recipeAddActivityTemplateConfirmation:By loading this activity, existing table/form/module will be moved to the newly established activity.`,
    recipeAddModuleTemplateConfirmation:
      $localize`:@@recipeAddModuleTemplateConfirmation:By loading this module, existing table/form will be moved to the newly established module.`
  };

  get hasAnyModules(): boolean {
    return this.recipe?.modules?.length > 0;
  }

  get hasAnyActivities(): boolean {
    return this.recipe?.activities?.length > 0;
  }

  /** Cast to Form. For use in component template */
  asForm = (item: FormResponse | Table) => item as Form;

  /** Cast to Table. For use in component template */
  asTable = (item: FormResponse | Table) => item as Table;

  constructor(readonly recipeService: RecipeService,
    clientStateService: ClientStateService,
    // tslint:disable-next-line:no-unused-variable
    private readonly route: ActivatedRoute,
    private readonly templateLoaderService: TemplateLoaderService,
    public readonly recipeNodeReOrderService: RecipeNodeReOrderService,
    private readonly userService: UserService,
    private readonly notificationService: RecipeNotificationService
  ) {
    super(clientStateService, route);
    this.currentUser = { ...this.userService.currentUser };
  }

  ngOnInit() {
    this.addSubscriptions();
    if (this.recipeService.isDesignerDataLoaded) {
      this.refresh();
    }

    this.templateLoaderService.fetchFavoriteTemplates();
    this.loaderSearchCriteria.recipeSearchCriteria = this.recipeService.recipeSearchCriteria;
    this.loaderSearchCriteria.templateSearchCriteria = this.recipeService.templateSearchCriteria;
    this.searchControls = this.recipeService.searchControls;
  }

  addSubscriptions() {
    this.subscriptions.push(
      this.recipeService.recipeLoaded.subscribe({
        next: () => {
          this.refresh();
        }
      })
    );
    this.subscriptions.push(
      this.recipeService.templateEventService.RecipeRefreshed.subscribe(() => {
        this.refresh();
      })
    );
    this.subscriptions.push(this.recipeService.handleRecipeError.subscribe(value => {
      if(value) {
        this.recipeHasErrors = true;
      }
    }));
  }

  ngOnDestroy(): void {
    UnsubscribeAll(this.subscriptions);
  }

  private orderFormTable() {
    if (this.modules.length === 0) {
      this.formTables.push(...this.forms);
      this.formTables.push(...this.tables);

      this.formTables.sort(
        (a: any, b: any) =>
          this.recipe.childOrder.indexOf(a.id) -
          this.recipe.childOrder.indexOf(b.id)
      );
    }
  }

  updateTemplateSelection(_templateLoaderResult: TemplateLoaderResult<SelectedTemplate>): void {
    this.showTemplateLoader = false;
  }

  addTemplateEvent() {
    if (this.checkRecipeState()) return;
    this.showTemplateLoader = true;
  }

  isUserEditorOfRecipe() {
    return this.recipe?.tracking.assignedEditors.includes(this.currentUser.puid) ?? false;
  }

  templateSelectionChanged(_templateLoaderResult: any): void {
    this.recipeService.selectionChanged(_templateLoaderResult);
  }

  applySelectedTemplate(selectedTemplateInformation: SelectedTemplateCommand) {
    this.recipeService.applySelectedTemplate(selectedTemplateInformation);
  }

  public isConfirmationRequired = (insertOption: TemplateLocationOptions): Observable<boolean> => {
    if (insertOption === TemplateLocationOptions.AddAsNewActivity) {
      return of(this.recipe.modules.length > 0);
    }
    if (insertOption === TemplateLocationOptions.AddAsNewModule) {
      return of(this.recipe.tables.length > 0 || this.recipe.forms.length > 0);
    }
    return of(false);
  }

  confirmationFunction(confirmationMessage: string, loadEvent: any, closeEvent: any) {
    this.recipeService.confirmationDialog(confirmationMessage, loadEvent, closeEvent);
  }

  checkRecipeState(): boolean {
    return this.readOnly || !this.isUserEditorOfRecipe() || this.recipeService.recipeHasErrors || this.recipe?.tracking.state !== RecipeState.Draft;
  }

  refresh() {
    this.recipe = this.recipeService.currentRecipe;
    this.tables = this.recipeService.currentRecipe.tables;
    this.forms = this.recipeService.currentRecipe.forms;
    this.modules = this.recipeService.currentRecipe.modules;
    this.formTables = [];
    this.orderFormTable();
    this.tables.filter(table => table.allowRowAdd).forEach(table => {
      if (!table.columnDefinitions.some(col => col.field === rowSelectedColumn.field)) {
        const pos = table.columnDefinitions.findIndex(col => col.columnType === ColumnType.Index);
        (table.columnDefinitions as ColumnDefinition[]).splice(pos, 0, rowSelectedColumn);
      }
      this.recipeService.appendRowGroupingColumnDefinitions(table.columnDefinitions);
    })
    this.blankRecipe = !(this.recipe.activities.length > 0 || this.tables.length > 0 || this.forms.length > 0 || this.modules.length > 0);
  }

  templateLoaderInsertOptionChanged(insertOption: string) {
    this.recipeService.refreshInsertLocationOptionsAndSetDefaultLocation(insertOption as TemplateLocationOptions);
    this.recipeService.constructFilters(insertOption);
    this.recipeService.resetRecipeAndTemplateCommand(insertOption);
    this.loaderSearchCriteria.templateSearchCriteria = this.recipeService.templateSearchCriteria;
    this.loaderSearchCriteria.recipeSearchCriteria = this.recipeService.recipeSearchCriteria;
  }

  onReorder(event: any) {
    const movedObject = event[0];
    const newPosition = this.formTables?.findIndex(node => RecipeNodeReOrderService.getNodeId(node) === movedObject.id);
    const itemTitle = this.formTables[newPosition]?.itemTitle;
    const nodeType = movedObject.itemType;
    if (movedObject && (nodeType === NodeType.Form || nodeType === NodeType.Table)) {
      this.recipeNodeReOrderService.changeNodeReOrder(
        nodeType,
        movedObject.id as string,
        this.recipe.recipeId,
        NodeType.Recipe,
        newPosition,
        itemTitle
      );
    }
  }

  templateLoaderFilterChanged(filterData: TemplateLoaderFilterChangedEvent) {
    this.searchControls = this.recipeService.alterFilters(filterData._filters, filterData.selectedInsertOption, filterData.fieldName);
  }

  templateLoaderFilterCleared(insertLocationOptions: string) {
    this.searchControls = this.recipeService.constructFilters(insertLocationOptions);
  }

}