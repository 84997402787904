import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MimeTypeService {
  getMimeType(fileExtension: string): string | undefined {
    const extensionToMimeTypeMap: { [key: string]: string } = {
      'jpg': 'image/jpeg',
      'png': 'image/png',
      'txt': 'text/plain',
      'html': 'text/html',
      'jpeg': 'image/jpeg',
      'gif': 'image/gif',
      'bmp': 'image/bmp',
      'svg': 'image/svg+xml',
      'pdf': 'application/pdf',
      'doc': 'application/msword',
      'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'xls': 'application/vnd.ms-excel',
      'xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'ppt': 'application/vnd.ms-powerpoint',
      'zip': 'application/zip',
      'xml': 'application/xml',
      'csv': 'text/csv',
      'msg': 'application/vnd.ms-outlook'
      // Add more extensions and their corresponding MIME types here
    };

    // Convert the extension to lowercase to handle case-insensitivity
    const lowerCaseExtension = fileExtension.toLowerCase();

    return extensionToMimeTypeMap[lowerCaseExtension];
  }
}