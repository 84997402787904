/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { FilesDeletedResponse as ElnFileIntegrationApiFileFilesDeletedResponse } from '../models/ELN/FileIntegration/Api/File/files-deleted-response';
import { FileUploadedResponse as ElnFileIntegrationApiFileFileUploadedResponse } from '../models/ELN/FileIntegration/Api/File/file-uploaded-response';
import { filesDeletePost$Json } from '../fn/file-integration/files-delete-post-json';
import { FilesDeletePost$Json$Params } from '../fn/file-integration/files-delete-post-json';
import { filesDeletePost$Plain } from '../fn/file-integration/files-delete-post-plain';
import { FilesDeletePost$Plain$Params } from '../fn/file-integration/files-delete-post-plain';
import { filesDownloadMultipleCompressedPost } from '../fn/file-integration/files-download-multiple-compressed-post';
import { FilesDownloadMultipleCompressedPost$Params } from '../fn/file-integration/files-download-multiple-compressed-post';
import { filesDownloadPost } from '../fn/file-integration/files-download-post';
import { FilesDownloadPost$Params } from '../fn/file-integration/files-download-post';
import { filesUploadPost$Json } from '../fn/file-integration/files-upload-post-json';
import { FilesUploadPost$Json$Params } from '../fn/file-integration/files-upload-post-json';
import { filesUploadPost$Plain } from '../fn/file-integration/files-upload-post-plain';
import { FilesUploadPost$Plain$Params } from '../fn/file-integration/files-upload-post-plain';

@Injectable({ providedIn: 'root' })
export class FileIntegrationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `filesUploadPost()` */
  static readonly FilesUploadPostPath = '/files/upload';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `filesUploadPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  filesUploadPost$Plain$Response(params?: FilesUploadPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ElnFileIntegrationApiFileFileUploadedResponse>> {
    return filesUploadPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `filesUploadPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  filesUploadPost$Plain(params?: FilesUploadPost$Plain$Params, context?: HttpContext): Observable<ElnFileIntegrationApiFileFileUploadedResponse> {
    return this.filesUploadPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ElnFileIntegrationApiFileFileUploadedResponse>): ElnFileIntegrationApiFileFileUploadedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `filesUploadPost$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  filesUploadPost$Json$Response(params?: FilesUploadPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ElnFileIntegrationApiFileFileUploadedResponse>> {
    return filesUploadPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `filesUploadPost$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  filesUploadPost$Json(params?: FilesUploadPost$Json$Params, context?: HttpContext): Observable<ElnFileIntegrationApiFileFileUploadedResponse> {
    return this.filesUploadPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ElnFileIntegrationApiFileFileUploadedResponse>): ElnFileIntegrationApiFileFileUploadedResponse => r.body)
    );
  }

  /** Path part for operation `filesDownloadPost()` */
  static readonly FilesDownloadPostPath = '/files/download';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `filesDownloadPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  filesDownloadPost$Response(params?: FilesDownloadPost$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return filesDownloadPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `filesDownloadPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  filesDownloadPost(params?: FilesDownloadPost$Params, context?: HttpContext): Observable<Blob> {
    return this.filesDownloadPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `filesDownloadMultipleCompressedPost()` */
  static readonly FilesDownloadMultipleCompressedPostPath = '/files/download-multiple-compressed';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `filesDownloadMultipleCompressedPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  filesDownloadMultipleCompressedPost$Response(params?: FilesDownloadMultipleCompressedPost$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return filesDownloadMultipleCompressedPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `filesDownloadMultipleCompressedPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  filesDownloadMultipleCompressedPost(params?: FilesDownloadMultipleCompressedPost$Params, context?: HttpContext): Observable<Blob> {
    return this.filesDownloadMultipleCompressedPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `filesDeletePost()` */
  static readonly FilesDeletePostPath = '/files/delete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `filesDeletePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  filesDeletePost$Plain$Response(params?: FilesDeletePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ElnFileIntegrationApiFileFilesDeletedResponse>> {
    return filesDeletePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `filesDeletePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  filesDeletePost$Plain(params?: FilesDeletePost$Plain$Params, context?: HttpContext): Observable<ElnFileIntegrationApiFileFilesDeletedResponse> {
    return this.filesDeletePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ElnFileIntegrationApiFileFilesDeletedResponse>): ElnFileIntegrationApiFileFilesDeletedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `filesDeletePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  filesDeletePost$Json$Response(params?: FilesDeletePost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ElnFileIntegrationApiFileFilesDeletedResponse>> {
    return filesDeletePost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `filesDeletePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  filesDeletePost$Json(params?: FilesDeletePost$Json$Params, context?: HttpContext): Observable<ElnFileIntegrationApiFileFilesDeletedResponse> {
    return this.filesDeletePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ElnFileIntegrationApiFileFilesDeletedResponse>): ElnFileIntegrationApiFileFilesDeletedResponse => r.body)
    );
  }

}
