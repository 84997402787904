import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ConfigurationService } from 'services/configuration.service';
import { MainMenuItem } from 'bpt-ui-library/bpt-layout/model/main-menu-item.interface';

export interface BptReoderedEvent<TItem> {
  item: TItem;
  newPosition: number;
}
@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent {
  @Input() expanded = true;
  @Input() expandDown = true;
  @Input() showElnMenuItem = false;
  @Input() menuItems: MainMenuItem[] = [];
  @Input() draggable = false;
  @Input() draggingEnabled = false;
  @Output() ReorderedNavItems = new EventEmitter<BptReoderedEvent<any>>();
  @Output() RecipeorderedNavItems = new EventEmitter<BptReoderedEvent<any>>();
  isLimsHosted: boolean;
  coverId = "eln-menu-cover";
  dataPackageId = "eln-menu-dataPackage";
  aboutId = "eln-recipe-menu-about";
  designerId = "eln-menu-designer";

  constructor(private readonly configurationService: ConfigurationService) {
    this.isLimsHosted = this.configurationService.isLimsHosted();
  }

  drop(e: any) {
    const copiedMenuItemsToReorder = this.menuItems.slice();
    const recipeCheck = copiedMenuItemsToReorder.find(
      (value) => value.id === this.aboutId
    );

    this.menuItems = this.MenuItemsAfterReOrder();
    if (e.item[0].id === this.coverId || e.item[0].id === this.dataPackageId || e.item[0].id === this.aboutId || e.item[0].id === this.designerId) {
      return;
    }
    if (recipeCheck === undefined) {
      this.dropForExperiments(e);
    }
    else {
      this.dropForRecipes(e);
    }
  }

  MenuItemsAfterReOrder() {
    const MenuItemsAfterReorder: any = [];
    const copiedMenuItemsToReorder = this.menuItems.slice();
    let firstObj: any;
    let lastObj: any;
    let designerObject: any;
    copiedMenuItemsToReorder.forEach((element: any, index: number) => {
      if (element.id !== this.coverId && element.id !== this.dataPackageId && element.id !== this.aboutId && element.id !== this.designerId) {
        MenuItemsAfterReorder.push(copiedMenuItemsToReorder[index])
      }
      else if ((element.id === this.coverId && element.id !== this.dataPackageId) || (element.id === this.aboutId)) {
        firstObj = copiedMenuItemsToReorder[index];
      }
      else if (element.id === this.dataPackageId && element.id !== this.coverId) {
        lastObj = copiedMenuItemsToReorder[index];
      }
      else if (element.id === this.designerId && element.id !== this.coverId) {
        designerObject = copiedMenuItemsToReorder[index];
      }
    });
    if (lastObj) {
      MenuItemsAfterReorder.push(lastObj);
    }
    if (designerObject) {
      MenuItemsAfterReorder.unshift(designerObject)
    }
    MenuItemsAfterReorder.unshift(firstObj);
    return MenuItemsAfterReorder;
  }

  dropForExperiments(e: any) {
    if ((e.newPosition !== 0 && e.item[0].id !== this.coverId) && e.newPosition !== (this.menuItems.length - 1)) {
      this.ReorderedNavItems.emit({
        item: e.item,
        newPosition: e.newPosition - 1
      });
    }
    else if ((e.newPosition === 0 && e.item[0].id !== this.coverId)) {
      this.ReorderedNavItems.emit({
        item: e.item,
        newPosition: e.newPosition
      });
    }
    else if (e.newPosition === (this.menuItems.length - 1) && e.item[0].id !== this.dataPackageId) {
      this.ReorderedNavItems.emit({
        item: e.item,
        newPosition: e.newPosition - 2
      });
    }
  }

  dropForRecipes(e: any) {
    if (((e.newPosition !== 0 && e.item[0].id !== this.aboutId) && e.newPosition !== 1) ||
      (e.newPosition === 1 && e.item[0].id !== this.designerId)) {
      this.RecipeorderedNavItems.emit({
        item: e.item,
        newPosition: e.newPosition - 1
      });
    }
    else if ((e.newPosition === 0 && e.item[0].id !== this.aboutId)) {
      this.RecipeorderedNavItems.emit({
        item: e.item,
        newPosition: e.newPosition
      });
    }
  }
}
