import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import { ChangeReasonService } from "../../experiment/services/change-reason.service";

@Injectable({
  providedIn: 'root'
})
export class ChangeReasonsInterceptor implements HttpInterceptor {
  constructor (
    private readonly changeReasonService: ChangeReasonService
  ){}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.method === 'POST') {
      return next.handle(req).pipe(
        map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            if(event.body.hasOwnProperty('changeReasonId')) {
              ChangeReasonService.changeReasonId = event.body.changeReasonId;
              this.changeReasonService.setOldValue();
              this.changeReasonService.setContextTitle(req.body);
              this.changeReasonService.setNewValue(req.body);
              this.changeReasonService.triggerSliderVisibility(true);
            }
          }
          return event;
        })
      );
    }
    return next.handle(req);
  }
}