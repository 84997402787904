import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { RuleActionNotification } from '../actions/rule-action-notification';
import { RuleNotificationResult } from '../actions/rule-action-result';
import {
  CellChangedEventOfFullContext,
  FieldChangedEvent,
  RuleEvent,
  TemplateRuleContext
} from '../events/rule-event-context';
import { RuleActions } from '../actions/rule-actions';
import { SetVariableCommand } from '../../api/data-entry/models';

export type TODO = any;
export type SetValueNotificationEvent = CellChangedEventOfFullContext;
export type SetFieldValueNotificationEvent = FieldChangedEvent;
export type DisplayMessageNotificationEvent = CellChangedEventOfFullContext;

//Let them be here for better readability
type TCompatibleNotification = any;

@Injectable({
  providedIn: 'root'
})
export class RuleActionNotificationService {
  public readonly SetCellValueActionNotification = new Subject<RuleActionNotification<SetValueNotificationEvent>>();
  public readonly DisplayMessageActionNotification = new Subject<RuleActionNotification<DisplayMessageNotificationEvent>>();
  public readonly AddNewRowActionNotification = new Subject<RuleActionNotification<TODO>>();
  public readonly RuleFailureNotifications = new Subject<RuleNotificationResult>();
  public readonly SetVariableActionNotification = new Subject<RuleActionNotification<RuleEvent<TemplateRuleContext>>>();
  public readonly SetVariableActionResponse = new Subject<SetVariableCommand>();
  public readonly SetFieldValueActionNotification = new Subject<RuleActionNotification<SetFieldValueNotificationEvent>>();

  constructor(
    ) {
    /* TODO document why this constructor is empty */
  }

  public NotifyAction(notification: RuleActionNotification<RuleEvent<TemplateRuleContext>>) {
    switch (notification.action.Action) {
      case RuleActions.SetValue:
        this.SetCellValueActionNotification.next(notification as TCompatibleNotification);
        break;
      case RuleActions.SetFieldValue:
        this.SetFieldValueActionNotification.next(notification as TCompatibleNotification);
        break;
      case RuleActions.DisplayMessage:
        this.DisplayMessageActionNotification.next(notification as TCompatibleNotification);
        break;
      case RuleActions.AddRow:
        this.AddNewRowActionNotification.next(notification as TCompatibleNotification);
        break;
      case RuleActions.SetVariable:
        this.SetVariableActionNotification.next(notification);
        break;
      default:
        break;
    }
  }
}
