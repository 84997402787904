/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { recipeRecipeTemplateDetailsPost$Json } from '../fn/recipe/recipe-recipe-template-details-post-json';
import { RecipeRecipeTemplateDetailsPost$Json$Params } from '../fn/recipe/recipe-recipe-template-details-post-json';
import { recipeRecipeTemplateDetailsPost$Plain } from '../fn/recipe/recipe-recipe-template-details-post-plain';
import { RecipeRecipeTemplateDetailsPost$Plain$Params } from '../fn/recipe/recipe-recipe-template-details-post-plain';
import { RecipeRecordAggregationSearchResult } from '../models/recipe-record-aggregation-search-result';
import { RecipeRecordSearchResult } from '../models/recipe-record-search-result';
import { recipeSearchAggregateRecipeIndexPost$Json } from '../fn/recipe/recipe-search-aggregate-recipe-index-post-json';
import { RecipeSearchAggregateRecipeIndexPost$Json$Params } from '../fn/recipe/recipe-search-aggregate-recipe-index-post-json';
import { recipeSearchAggregateRecipeIndexPost$Plain } from '../fn/recipe/recipe-search-aggregate-recipe-index-post-plain';
import { RecipeSearchAggregateRecipeIndexPost$Plain$Params } from '../fn/recipe/recipe-search-aggregate-recipe-index-post-plain';
import { recipeSearchRecipeIndexPost$Json } from '../fn/recipe/recipe-search-recipe-index-post-json';
import { RecipeSearchRecipeIndexPost$Json$Params } from '../fn/recipe/recipe-search-recipe-index-post-json';
import { recipeSearchRecipeIndexPost$Plain } from '../fn/recipe/recipe-search-recipe-index-post-plain';
import { RecipeSearchRecipeIndexPost$Plain$Params } from '../fn/recipe/recipe-search-recipe-index-post-plain';
import { RecipeTemplateSearchResponse } from '../models/recipe-template-search-response';

@Injectable({ providedIn: 'root' })
export class RecipeService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `recipeSearchRecipeIndexPost()` */
  static readonly RecipeSearchRecipeIndexPostPath = '/recipe/search-recipe-index';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeSearchRecipeIndexPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeSearchRecipeIndexPost$Plain$Response(params?: RecipeSearchRecipeIndexPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<RecipeRecordSearchResult>> {
    return recipeSearchRecipeIndexPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeSearchRecipeIndexPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeSearchRecipeIndexPost$Plain(params?: RecipeSearchRecipeIndexPost$Plain$Params, context?: HttpContext): Observable<RecipeRecordSearchResult> {
    return this.recipeSearchRecipeIndexPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<RecipeRecordSearchResult>): RecipeRecordSearchResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeSearchRecipeIndexPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeSearchRecipeIndexPost$Json$Response(params?: RecipeSearchRecipeIndexPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<RecipeRecordSearchResult>> {
    return recipeSearchRecipeIndexPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeSearchRecipeIndexPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeSearchRecipeIndexPost$Json(params?: RecipeSearchRecipeIndexPost$Json$Params, context?: HttpContext): Observable<RecipeRecordSearchResult> {
    return this.recipeSearchRecipeIndexPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<RecipeRecordSearchResult>): RecipeRecordSearchResult => r.body)
    );
  }

  /** Path part for operation `recipeSearchAggregateRecipeIndexPost()` */
  static readonly RecipeSearchAggregateRecipeIndexPostPath = '/recipe/search-aggregate-recipe-index';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeSearchAggregateRecipeIndexPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeSearchAggregateRecipeIndexPost$Plain$Response(params?: RecipeSearchAggregateRecipeIndexPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<RecipeRecordAggregationSearchResult>> {
    return recipeSearchAggregateRecipeIndexPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeSearchAggregateRecipeIndexPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeSearchAggregateRecipeIndexPost$Plain(params?: RecipeSearchAggregateRecipeIndexPost$Plain$Params, context?: HttpContext): Observable<RecipeRecordAggregationSearchResult> {
    return this.recipeSearchAggregateRecipeIndexPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<RecipeRecordAggregationSearchResult>): RecipeRecordAggregationSearchResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeSearchAggregateRecipeIndexPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeSearchAggregateRecipeIndexPost$Json$Response(params?: RecipeSearchAggregateRecipeIndexPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<RecipeRecordAggregationSearchResult>> {
    return recipeSearchAggregateRecipeIndexPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeSearchAggregateRecipeIndexPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeSearchAggregateRecipeIndexPost$Json(params?: RecipeSearchAggregateRecipeIndexPost$Json$Params, context?: HttpContext): Observable<RecipeRecordAggregationSearchResult> {
    return this.recipeSearchAggregateRecipeIndexPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<RecipeRecordAggregationSearchResult>): RecipeRecordAggregationSearchResult => r.body)
    );
  }

  /** Path part for operation `recipeRecipeTemplateDetailsPost()` */
  static readonly RecipeRecipeTemplateDetailsPostPath = '/recipe/recipe-template-details';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeRecipeTemplateDetailsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeRecipeTemplateDetailsPost$Plain$Response(params?: RecipeRecipeTemplateDetailsPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<RecipeTemplateSearchResponse>> {
    return recipeRecipeTemplateDetailsPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeRecipeTemplateDetailsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeRecipeTemplateDetailsPost$Plain(params?: RecipeRecipeTemplateDetailsPost$Plain$Params, context?: HttpContext): Observable<RecipeTemplateSearchResponse> {
    return this.recipeRecipeTemplateDetailsPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<RecipeTemplateSearchResponse>): RecipeTemplateSearchResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeRecipeTemplateDetailsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeRecipeTemplateDetailsPost$Json$Response(params?: RecipeRecipeTemplateDetailsPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<RecipeTemplateSearchResponse>> {
    return recipeRecipeTemplateDetailsPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeRecipeTemplateDetailsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeRecipeTemplateDetailsPost$Json(params?: RecipeRecipeTemplateDetailsPost$Json$Params, context?: HttpContext): Observable<RecipeTemplateSearchResponse> {
    return this.recipeRecipeTemplateDetailsPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<RecipeTemplateSearchResponse>): RecipeTemplateSearchResponse => r.body)
    );
  }

}
