import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment"
import { ConfigurationService } from "./configuration.service";

export enum Level {
  error = "Error",
  warning = "Warning",
  info = "Info",
  debug = "Debug"
}

@Injectable({
  providedIn: 'root'
})

export class Logger {

  public readonly isLogViewerOpen = "LogViewer-Open";

  /**
   * Create a new instance of the logger service
   * @param configService 
   */
  constructor(private readonly configService : ConfigurationService) {
  }

  private logViewerWindow?: Window;

  public logErrorMessage(message:string ){
    this.log(message,Level.error);
  }

  public logError(error:HttpErrorResponse ){
    this.log(error.message,Level.error);
  }

  public logWarning(message:string ){
    this.log(message,Level.warning);
  }

  public logInfo(message:string ){
    if(!environment.production){
    this.log(message,Level.info);
    }
  }

  public logDebug(message:string ){
    if(!environment.production){
    this.log(message,Level.debug);
    }
  }

  private log(message:string, level:Level){
    switch(level)
    {
      case Level.error:{
        console.error(`${message}`);
        break;
      }
      case Level.warning:{
        console.warn(`${message}`);
        break;
      }
      case Level.debug:{
        console.debug(`${message}`);
        break;
      }
      case Level.info:{
        console.info(`${message}`);
        break;
      }
      default:
        console.log(`${message}`);
      }
  }

  /**
   * Opens the log viewer in a new window based on the configured url.
   */
  openLogViewer = (w: Window): void => {

    // Don't display log viewer if 
    //  URL not configured /
    //  display not enabled /
    // log viewer already opened in current session in another tab.
    var logViewerUrl = this.configService.getLogViewerUrl();
    if (!this.configService.isLogViewerDisplayEnabled()) return;
    var isLogViewerOpen = sessionStorage.getItem(this.isLogViewerOpen);
    if (isLogViewerOpen) return;

    if (logViewerUrl) {
        if (!this.logViewerWindow || this.logViewerWindow.closed) {
            this.logViewerWindow = w.open(logViewerUrl, "Log Viewer", "width: 1200, height:800") ?? undefined;
            sessionStorage.setItem(this.isLogViewerOpen, "true");
        }
        if (this.logViewerWindow) { // check again as popup blockers will cause issues
            this.logViewerWindow.location.href = logViewerUrl;
        }
    } else{
      this.logWarning("Log viewer URL missing. The log window cannot be opened.")
    }
  }
}

