/* tslint:disable */
/* eslint-disable */
export enum PromptType {
  Invalid = 'invalid',
  Materials = 'materials',
  Instruments = 'instruments',
  Columns = 'columns',
  ConsumablesAndSupplies = 'consumablesAndSupplies',
  Preparations = 'preparations'
}
