/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AddRowResponse } from '../models/add-row-response';
import { AddRowsForEachResponse } from '../models/add-rows-for-each-response';
import { RowRemovedResponse } from '../models/row-removed-response';
import { RowRestoredResponse } from '../models/row-restored-response';
import { RowsRenumberedResponse } from '../models/rows-renumbered-response';
import { TableEditResponse } from '../models/table-edit-response';
import { tableEventsAddRowsForEachPost$Json } from '../fn/table/table-events-add-rows-for-each-post-json';
import { TableEventsAddRowsForEachPost$Json$Params } from '../fn/table/table-events-add-rows-for-each-post-json';
import { tableEventsAddRowsForEachPost$Plain } from '../fn/table/table-events-add-rows-for-each-post-plain';
import { TableEventsAddRowsForEachPost$Plain$Params } from '../fn/table/table-events-add-rows-for-each-post-plain';
import { tableEventsAddRowsPost$Json } from '../fn/table/table-events-add-rows-post-json';
import { TableEventsAddRowsPost$Json$Params } from '../fn/table/table-events-add-rows-post-json';
import { tableEventsAddRowsPost$Plain } from '../fn/table/table-events-add-rows-post-plain';
import { TableEventsAddRowsPost$Plain$Params } from '../fn/table/table-events-add-rows-post-plain';
import { tableEventsChangeCellsPost$Json } from '../fn/table/table-events-change-cells-post-json';
import { TableEventsChangeCellsPost$Json$Params } from '../fn/table/table-events-change-cells-post-json';
import { tableEventsChangeCellsPost$Plain } from '../fn/table/table-events-change-cells-post-plain';
import { TableEventsChangeCellsPost$Plain$Params } from '../fn/table/table-events-change-cells-post-plain';
import { tableEventsPerformanceTestChangeCellTimesPost$Json } from '../fn/table/table-events-performance-test-change-cell-times-post-json';
import { TableEventsPerformanceTestChangeCellTimesPost$Json$Params } from '../fn/table/table-events-performance-test-change-cell-times-post-json';
import { tableEventsPerformanceTestChangeCellTimesPost$Plain } from '../fn/table/table-events-performance-test-change-cell-times-post-plain';
import { TableEventsPerformanceTestChangeCellTimesPost$Plain$Params } from '../fn/table/table-events-performance-test-change-cell-times-post-plain';
import { tableEventsRemoveRowPost$Json } from '../fn/table/table-events-remove-row-post-json';
import { TableEventsRemoveRowPost$Json$Params } from '../fn/table/table-events-remove-row-post-json';
import { tableEventsRemoveRowPost$Plain } from '../fn/table/table-events-remove-row-post-plain';
import { TableEventsRemoveRowPost$Plain$Params } from '../fn/table/table-events-remove-row-post-plain';
import { tableEventsRenumberRowsPost$Json } from '../fn/table/table-events-renumber-rows-post-json';
import { TableEventsRenumberRowsPost$Json$Params } from '../fn/table/table-events-renumber-rows-post-json';
import { tableEventsRenumberRowsPost$Plain } from '../fn/table/table-events-renumber-rows-post-plain';
import { TableEventsRenumberRowsPost$Plain$Params } from '../fn/table/table-events-renumber-rows-post-plain';
import { tableEventsRestoreRowPost$Json } from '../fn/table/table-events-restore-row-post-json';
import { TableEventsRestoreRowPost$Json$Params } from '../fn/table/table-events-restore-row-post-json';
import { tableEventsRestoreRowPost$Plain } from '../fn/table/table-events-restore-row-post-plain';
import { TableEventsRestoreRowPost$Plain$Params } from '../fn/table/table-events-restore-row-post-plain';

@Injectable({ providedIn: 'root' })
export class TableService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `tableEventsAddRowsPost()` */
  static readonly TableEventsAddRowsPostPath = '/table-events/add-rows';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tableEventsAddRowsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  tableEventsAddRowsPost$Plain$Response(params?: TableEventsAddRowsPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<AddRowResponse>> {
    return tableEventsAddRowsPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `tableEventsAddRowsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  tableEventsAddRowsPost$Plain(params?: TableEventsAddRowsPost$Plain$Params, context?: HttpContext): Observable<AddRowResponse> {
    return this.tableEventsAddRowsPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<AddRowResponse>): AddRowResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tableEventsAddRowsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  tableEventsAddRowsPost$Json$Response(params?: TableEventsAddRowsPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<AddRowResponse>> {
    return tableEventsAddRowsPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `tableEventsAddRowsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  tableEventsAddRowsPost$Json(params?: TableEventsAddRowsPost$Json$Params, context?: HttpContext): Observable<AddRowResponse> {
    return this.tableEventsAddRowsPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<AddRowResponse>): AddRowResponse => r.body)
    );
  }

  /** Path part for operation `tableEventsAddRowsForEachPost()` */
  static readonly TableEventsAddRowsForEachPostPath = '/table-events/add-rows-for-each';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tableEventsAddRowsForEachPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  tableEventsAddRowsForEachPost$Plain$Response(params?: TableEventsAddRowsForEachPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<AddRowsForEachResponse>> {
    return tableEventsAddRowsForEachPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `tableEventsAddRowsForEachPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  tableEventsAddRowsForEachPost$Plain(params?: TableEventsAddRowsForEachPost$Plain$Params, context?: HttpContext): Observable<AddRowsForEachResponse> {
    return this.tableEventsAddRowsForEachPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<AddRowsForEachResponse>): AddRowsForEachResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tableEventsAddRowsForEachPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  tableEventsAddRowsForEachPost$Json$Response(params?: TableEventsAddRowsForEachPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<AddRowsForEachResponse>> {
    return tableEventsAddRowsForEachPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `tableEventsAddRowsForEachPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  tableEventsAddRowsForEachPost$Json(params?: TableEventsAddRowsForEachPost$Json$Params, context?: HttpContext): Observable<AddRowsForEachResponse> {
    return this.tableEventsAddRowsForEachPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<AddRowsForEachResponse>): AddRowsForEachResponse => r.body)
    );
  }

  /** Path part for operation `tableEventsChangeCellsPost()` */
  static readonly TableEventsChangeCellsPostPath = '/table-events/change-cells';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tableEventsChangeCellsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  tableEventsChangeCellsPost$Plain$Response(params?: TableEventsChangeCellsPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<TableEditResponse>> {
    return tableEventsChangeCellsPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `tableEventsChangeCellsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  tableEventsChangeCellsPost$Plain(params?: TableEventsChangeCellsPost$Plain$Params, context?: HttpContext): Observable<TableEditResponse> {
    return this.tableEventsChangeCellsPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<TableEditResponse>): TableEditResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tableEventsChangeCellsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  tableEventsChangeCellsPost$Json$Response(params?: TableEventsChangeCellsPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<TableEditResponse>> {
    return tableEventsChangeCellsPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `tableEventsChangeCellsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  tableEventsChangeCellsPost$Json(params?: TableEventsChangeCellsPost$Json$Params, context?: HttpContext): Observable<TableEditResponse> {
    return this.tableEventsChangeCellsPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<TableEditResponse>): TableEditResponse => r.body)
    );
  }

  /** Path part for operation `tableEventsRemoveRowPost()` */
  static readonly TableEventsRemoveRowPostPath = '/table-events/remove-row';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tableEventsRemoveRowPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  tableEventsRemoveRowPost$Plain$Response(params?: TableEventsRemoveRowPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<RowRemovedResponse>> {
    return tableEventsRemoveRowPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `tableEventsRemoveRowPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  tableEventsRemoveRowPost$Plain(params?: TableEventsRemoveRowPost$Plain$Params, context?: HttpContext): Observable<RowRemovedResponse> {
    return this.tableEventsRemoveRowPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<RowRemovedResponse>): RowRemovedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tableEventsRemoveRowPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  tableEventsRemoveRowPost$Json$Response(params?: TableEventsRemoveRowPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<RowRemovedResponse>> {
    return tableEventsRemoveRowPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `tableEventsRemoveRowPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  tableEventsRemoveRowPost$Json(params?: TableEventsRemoveRowPost$Json$Params, context?: HttpContext): Observable<RowRemovedResponse> {
    return this.tableEventsRemoveRowPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<RowRemovedResponse>): RowRemovedResponse => r.body)
    );
  }

  /** Path part for operation `tableEventsRestoreRowPost()` */
  static readonly TableEventsRestoreRowPostPath = '/table-events/restore-row';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tableEventsRestoreRowPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  tableEventsRestoreRowPost$Plain$Response(params?: TableEventsRestoreRowPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<RowRestoredResponse>> {
    return tableEventsRestoreRowPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `tableEventsRestoreRowPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  tableEventsRestoreRowPost$Plain(params?: TableEventsRestoreRowPost$Plain$Params, context?: HttpContext): Observable<RowRestoredResponse> {
    return this.tableEventsRestoreRowPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<RowRestoredResponse>): RowRestoredResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tableEventsRestoreRowPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  tableEventsRestoreRowPost$Json$Response(params?: TableEventsRestoreRowPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<RowRestoredResponse>> {
    return tableEventsRestoreRowPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `tableEventsRestoreRowPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  tableEventsRestoreRowPost$Json(params?: TableEventsRestoreRowPost$Json$Params, context?: HttpContext): Observable<RowRestoredResponse> {
    return this.tableEventsRestoreRowPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<RowRestoredResponse>): RowRestoredResponse => r.body)
    );
  }

  /** Path part for operation `tableEventsRenumberRowsPost()` */
  static readonly TableEventsRenumberRowsPostPath = '/table-events/renumber-rows';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tableEventsRenumberRowsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  tableEventsRenumberRowsPost$Plain$Response(params?: TableEventsRenumberRowsPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<RowsRenumberedResponse>> {
    return tableEventsRenumberRowsPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `tableEventsRenumberRowsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  tableEventsRenumberRowsPost$Plain(params?: TableEventsRenumberRowsPost$Plain$Params, context?: HttpContext): Observable<RowsRenumberedResponse> {
    return this.tableEventsRenumberRowsPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<RowsRenumberedResponse>): RowsRenumberedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tableEventsRenumberRowsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  tableEventsRenumberRowsPost$Json$Response(params?: TableEventsRenumberRowsPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<RowsRenumberedResponse>> {
    return tableEventsRenumberRowsPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `tableEventsRenumberRowsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  tableEventsRenumberRowsPost$Json(params?: TableEventsRenumberRowsPost$Json$Params, context?: HttpContext): Observable<RowsRenumberedResponse> {
    return this.tableEventsRenumberRowsPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<RowsRenumberedResponse>): RowsRenumberedResponse => r.body)
    );
  }

  /** Path part for operation `tableEventsPerformanceTestChangeCellTimesPost()` */
  static readonly TableEventsPerformanceTestChangeCellTimesPostPath = '/table-events/performance-test-change-cell/{times}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tableEventsPerformanceTestChangeCellTimesPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   *
   * @deprecated
   */
  tableEventsPerformanceTestChangeCellTimesPost$Plain$Response(params: TableEventsPerformanceTestChangeCellTimesPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<TableEditResponse>> {
    return tableEventsPerformanceTestChangeCellTimesPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `tableEventsPerformanceTestChangeCellTimesPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   *
   * @deprecated
   */
  tableEventsPerformanceTestChangeCellTimesPost$Plain(params: TableEventsPerformanceTestChangeCellTimesPost$Plain$Params, context?: HttpContext): Observable<TableEditResponse> {
    return this.tableEventsPerformanceTestChangeCellTimesPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<TableEditResponse>): TableEditResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tableEventsPerformanceTestChangeCellTimesPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   *
   * @deprecated
   */
  tableEventsPerformanceTestChangeCellTimesPost$Json$Response(params: TableEventsPerformanceTestChangeCellTimesPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<TableEditResponse>> {
    return tableEventsPerformanceTestChangeCellTimesPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `tableEventsPerformanceTestChangeCellTimesPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   *
   * @deprecated
   */
  tableEventsPerformanceTestChangeCellTimesPost$Json(params: TableEventsPerformanceTestChangeCellTimesPost$Json$Params, context?: HttpContext): Observable<TableEditResponse> {
    return this.tableEventsPerformanceTestChangeCellTimesPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<TableEditResponse>): TableEditResponse => r.body)
    );
  }

}
