import { Component, OnDestroy } from '@angular/core';
import { IStatusPanelAngularComp } from 'ag-grid-angular';
import { LabelsService as PreparationLabelService } from '../../api/services/labels.service';
import { LabelsService } from '../../api/labels/services';
import { ElnLabelsApiEntitiesLabelTemplateResponse, ElnLabelsDomainEnumsLabelType } from '../../api/labels/models';
import { Subscription, map } from 'rxjs';
import { Media } from '../../api/labels/models/ELN/Labels/API/ArgonEntities/media';
import { PrintPreparationCommand } from '../../api/models';
import { PreparationItem } from '../models/preparation-presentation.model';
import { UnsubscribeAll } from '../../shared/rx-js-helpers';
import { IStatusPanelParams } from 'ag-grid-community';
import { MessageService } from 'primeng/api';
import { UserService } from '../../services/user.service';

export interface ExperimentInformation  {
    experimentNumber: string,
    experimentId:string,
    currentActivityId: string
}
export interface ExperimentDetailForLabels extends IStatusPanelParams<string, ExperimentInformation>{
  experimentDetails: ExperimentInformation
}

@Component({
  selector: 'app-preparation-status-bar',
  templateUrl: './preparation-status-bar.component.html',
  styleUrls: ['./preparation-status-bar.component.scss']
})
export class PreparationStatusBarComponent implements OnDestroy, IStatusPanelAngularComp {
  visible = false;
  disablePrintLabel = true;
  experimentInformation: Partial<ExperimentDetailForLabels> = { 
    experimentDetails: { currentActivityId: '', experimentId: '', experimentNumber: '' }
  }
  labelHeader = $localize`:@@printLabels:Print Labels`;
  labelSuccessMessage = $localize`:@@labelSuccessMessage:Label printing command has been sent to printer,
   printer must be online and properly configured in order to receive the command. 
   If printing fails, check the printing status and configuration then try again`;

  /**
   * To do will bind drop values when Integration is done with backend
   */
  labelStylesToPrintPreparation: Array<{ label: string, value: string }> =
    [{
      label: "ELN_PREPARATION_LABEL",
      value: 'ElnPreparation'
    }];
  printNamesArray: Array<{ label: string, value: string }> = [];
  subscriptions: Subscription[] = [];
  labelTemplateId = "";
  printerId = '';
  printerAvailable = true;
  printLabelOptions = {
    minimumValue: 1,
    maximumValue: 99,
    labelStyle: '',
    printer: '',
    modal: true,
    required: true,
    showButtons: true,
    commitValueOnEnter: true,
    allowKeyboardIncrement: true,
    editable: false,
    draggable: false,
    resizable: false
  };
  numericModel: number | string | undefined = this.printLabelOptions.minimumValue ? this.printLabelOptions.minimumValue : '';
  private params!: any;
  isLoading = false;

  constructor(
    private readonly labelService: LabelsService,
    private readonly preparationLabelService: PreparationLabelService,
    private readonly messageService: MessageService
  ) {}

  agInit(params: any): void {
    this.experimentInformation.experimentDetails = params.experimentDetails;
    this.params = params;
    this.params.api.addEventListener('modelUpdated', this.onGridUpdate.bind(this));
    this.params.api.addEventListener('selectionChanged', this.onRowSelection.bind(this));
  }

  cancel() {
    this.visible = false;
    this.printLabelOptions.labelStyle = '';
    this.printLabelOptions.printer = '';
    this.numericModel = 1;
  }

  onRowSelection(event: any): void {
    this.disablePrintLabel = event.api.getSelectedRows().length <= 0;
  }
  onGridUpdate(): void {
    const rowCount = this.params.api.getDisplayedRowCount();
    this.disablePrintLabel = this.params.api.getSelectedRows().length === 0;
    const rowsReference = this.params.api.getStatusPanel('agTotalAndFilteredRowCountComponent');
    if (rowsReference) {
      rowsReference.eGui.style.display = rowCount > 0 ? 'block' : 'none';
    }
  }

onLabelSelect(event: string) {
  if (event !== '') {
    this.isLoading = true
    this.setToDefaultState();
    const templateType = this.labelStylesToPrintPreparation.find(template=>template.value === event)?.value;  
    const labelType = Object.entries(ElnLabelsDomainEnumsLabelType).find(l => l[0] === templateType)?.[1];
    if (labelType) {
      const result = this.fetchAvailablePrinters(labelType)
      result.then((media: Media) => {
      this.subscriptions.push(  this.labelService.labelsPrintersGet$Json({
          mediaId: media.mediaId,
        }).subscribe(data => {
          this.isLoading = false;
          data.devices?.map(device => {
            this.printNamesArray.push({
              label: device.name,
              value: device.deviceId
            });
          }
          )
          this.printerAvailable = false;
        })
      )});
    }
  }
}

  public fetchAvailablePrinters(templateType: ElnLabelsDomainEnumsLabelType) {
    return new Promise<Media>((resolve) => {
    this.subscriptions.push(this.labelService.labelsLabelTemplatesTemplateTypeGet$Json({
        templateType: templateType  
      }).pipe(
        map((data: ElnLabelsApiEntitiesLabelTemplateResponse) => {
          const printerName = this.labelStylesToPrintPreparation.find(label => label.value === this.printLabelOptions.labelStyle)?.label
          const matchingLabelTemplate = data.labelTemplates?.find(labelTemplate => labelTemplate.name === printerName);
          if (matchingLabelTemplate) {
            this.labelTemplateId = matchingLabelTemplate.labelTemplateId;
            return matchingLabelTemplate.media;
          }
          else {
            return undefined;
          }
        })
      ).subscribe({
        next(value: (Media[] | undefined)) {
          if (value && value.length > 0) {
            resolve(value[0]);
          }
        }
      }));
    }
    );
  }

  public setToDefaultState() {
    this.printNamesArray = [];
    this.printLabelOptions.printer = "";
    this.printerAvailable = true;
  }

  onPrinterSelection(event: string) {
    this.printerId = this.printNamesArray.find(printer => printer.value === event)?.value ?? ""
  }
  checkPrintLabelIsEnabled() {
    return !this.printLabelOptions.labelStyle?.trim() || !this.printLabelOptions.printer?.trim() || !this.numericModel;
  }
  closePrintLabelsDialog() {
    this.printLabelOptions.labelStyle = '';
    this.printLabelOptions.printer = '';
    this.numericModel = 1;
  }
  getLabels(): void {
    this.visible = !this.disablePrintLabel;
    this.printNamesArray = [];
    this.printerAvailable = true;
  }
  buildPrintPreparationCommand(exp: Partial<ExperimentDetailForLabels>) {
    if(exp.experimentDetails?.currentActivityId && exp.experimentDetails){
      var command: PrintPreparationCommand = {
        activityId: exp.experimentDetails.currentActivityId,
        experimentId: exp.experimentDetails.experimentId,
        experimentNumber: exp.experimentDetails.experimentNumber,
        numberOfCopies: Number(this.numericModel),
        preparationNumbers: this.getSelectedPreparationNumber(),
        printerId: this.printerId,
        templateId:  this.labelTemplateId,
        timeZone: UserService.currentLabSiteTimeZone.id()  
      }
      return command;
    }
    return undefined;
  }
  getSelectedPreparationNumber(): string[] {
    return this.params.api.getSelectedRows().map((prep: PreparationItem) => prep.preparationNumber);
  }
  submit() {
    this.visible = false;
    this.messageService.add({
      key: 'notification',
      severity: 'success',
      id: 'successLabel',
      summary: this.labelSuccessMessage
    });
    var printPreparationCommand = this.buildPrintPreparationCommand(this.experimentInformation);
    this.closePrintLabelsDialog();
    if (printPreparationCommand && printPreparationCommand.activityId && this.printerId && this.labelTemplateId) {
      this.subscriptions.push( this.preparationLabelService.experimentLabelsPrintPost$Json({
        body: printPreparationCommand
      }).subscribe());
    }
  }
  ngOnDestroy(): void {
    UnsubscribeAll(this.subscriptions);
  }
} 
