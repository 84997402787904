import { Component, Input } from '@angular/core';
import { ModifiableDataValue, NumberValue, Statement, StatementContextType, StringValue } from '../../../../api/models';
import { Table } from '../../../../model/experiment.interface';
import { DateAndInstantFormat, formatInstant } from '../../../../shared/date-time-helpers';
import { ExperimentService } from '../../../services/experiment.service';
import { CommentsComponent } from '../../comments.component';
import { StatementModel } from '../models/statement-model';
import { ClientFacingNoteModel } from './../../client-facing-note/client-facing-note.model';

@Component({
  selector: 'app-applied-statement',
  templateUrl: './applied-statement.component.html',
  styleUrls: ['./applied-statement.component.scss']
})
export class AppliedStamentComponent {
  @Input() statements: Statement[] = [];
  @Input() clientFacingNoteModel!: ClientFacingNoteModel | undefined;
  table?: Table;
  readonly parent: CommentsComponent;

  constructor(private readonly experimentService: ExperimentService, comments: CommentsComponent) {
    this.parent = comments;
  }

  getAppliedStatement(statment: Statement): string {
    const content = statment.content;
    return content ? JSON.parse(content)?.statementValue : '';
  }

  getUserName(statment: Statement): string {
    const content = (statment.content);
    return content ? JSON.parse(content)?.userName : '';
  }

  getPuid(statment: Statement): string {
    const content = statment.content;
    return content ? JSON.parse(content)?.puid : '';
  }

  getStatementAppliedDate(statment: Statement): string {
    return formatInstant(statment.lastEditedOn, DateAndInstantFormat.dateTimeToSecond)
  }


  public headerText(statementModel: Statement): string {
    this.table = this.experimentService.getTable(statementModel.nodeId);//statementModel.Statement.nodeId
    let headerText = statementModel.number + ': ';
    if (statementModel.contextType === StatementContextType.TableCell) {
      const col = $localize`:@@columnAbbr:COL`;
      const row = $localize`:@@rowAbbr:ROW`;
      headerText += `${col}: ${this.getLabel(statementModel)} | ${row}: ${this.getRowIndex(statementModel)}`;
    }
    return headerText;
  }

  getLabel(statementModel: Statement) {
    return this.table?.columnDefinitions?.find((c) => c.field === statementModel.path[1])?.label
  }

  getRowIndex(statementModel: Statement) {
    return (
      (
        this.table?.value?.find((r) => r.id === statementModel.path[0]) as unknown as { // `as unknown` !!! See TableValueRow docs
          rowIndex: ModifiableDataValue;
        }
      ).rowIndex?.value as NumberValue
    )?.value;
  }

  public lastEditedBy(statementModel: Statement): string {
    const user = this.parent.findUser(statementModel.lastEditedBy.value);
    return user ? user.fullName : '';
  }

  public lastEditedOn(statementModel: Statement): string {
    return statementModel?.lastEditedOn
      ? formatInstant(statementModel?.lastEditedOn, DateAndInstantFormat.dateTimeToMinute)
      : '';
  }

  isTableCellStatement(statement: Statement) {
    return statement.contextType === StatementContextType.TableCell;
  }
}
