import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ClientStateService } from '../../services/client-state.service';
import { AccessibilityTypes } from '../../app.states';
import { AppService } from 'services/app-service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly clientStateService: ClientStateService,
    private appService: AppService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (
      state.url === '/bookshelf/new-experiment' &&
      !this.hasCreateExperimentPermission()
    ) {
      this.router.navigate(['/bookshelf/my-shelf']);
      return false;
    }
    return true;
  }
  hasCreateExperimentPermission() : boolean {
    const state: string = 'eln.bookshelf';
    const featureFlags = this.clientStateService.getFeatureFlags(state);
      return featureFlags.find(
        (data) =>
          JSON.parse(data).CanCreateExperiment && JSON.parse(data).CanCreateExperiment === true
      ) !== (null || undefined);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (route.data?.clientState) {
      if (this.clientStateService.clientStatePermissions.length === 0) {
        this.appService.displayWaitDialog = true;
        return this.clientStateService.getClientStatePermissions().then((data) => {
          this.appService.displayWaitDialog = false;
          return this.getClientState(route.data.clientState);
        });
      } else {
        return this.getClientState(route.data.clientState);
      }
    } else {
      return true;
    }
  }

  getClientState(state: string): boolean {
    const clientState = this.clientStateService.getClientStateVisibility(state);
    if (clientState) {
      // check if route is restricted by role
      if (clientState === AccessibilityTypes.Hidden) {
        // role not authorised so redirect to unauthorized page
        window.open('/unauthorized', '_self');
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }
}
