import { environment } from '../../../environments/environment';
import { Configuration } from './configuration.interface';

/**
 * Gets the various configurations from Helium when inside Helium
 * Function should be created for any required configurations and get it from the configurations object
 */
export class ElimsConfiguration implements Configuration {
  configurations: any;

  constructor(app: any) {
    this.configurations = app;
  }

  //Gets the ReauthMaxNumberOfAttempts configured value from Helium
  getReAuthFailedAttempts(): number {
    return this.configurations.ReauthMaxNumberOfAttempts;
  }

  isLimsHosted(): boolean {
    return true;
  }

  getLogViewerUrl(): string {
    return environment.logging.logViewerUrl;
  }

  isLogViewerDisplayEnabled(): boolean {
    return environment.logging.enableDisplay;
  }

  getLimsConfig(): string {
    return this.configurations.LimsType;
  }
}
