/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { CreateUnitListCommand } from '../../models/create-unit-list-command';
import { CreateUnitListResponse } from '../../models/create-unit-list-response';

export interface UnitsListsCreatePost$Plain$Params {
  'X-User-Puid'?: any;
  'X-User-LabsiteCode'?: any;

/**
 * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
 */
  'X-Experiment-PreviewMode'?: any;
      body?: CreateUnitListCommand
}

export function unitsListsCreatePost$Plain(http: HttpClient, rootUrl: string, params?: UnitsListsCreatePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateUnitListResponse>> {
  const rb = new RequestBuilder(rootUrl, unitsListsCreatePost$Plain.PATH, 'post');
  if (params) {
    rb.header('X-User-Puid', params['X-User-Puid'], {});
    rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
    rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    rb.body(params.body, 'application/*+json');
  }

  return http.request(
    rb.build({ responseType: 'text', accept: 'text/plain', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<CreateUnitListResponse>;
    })
  );
}

unitsListsCreatePost$Plain.PATH = '/units/lists/create';
