<div *ngIf="enableNotification" class="eln-notification flex-v-center">
  <div class="eln-connectionMessage flex flex-v-center">
    <div *ngIf="status === connectedClass">
      <p-badge
        id="eln-connectionSuccessBadge"
        value=""
        severity="success"
        styleClass="p-badge-xs"
      ></p-badge>
    </div>
    <div class="message-container">
      <i
      id="refresh"
      aria-hidden="true"
      *ngIf="showRefreshBtn"
      class="pi pi-refresh refreshBtn"
      (click)="reconnect()"
    ></i>
      <i id="notification" aria-hidden="true" class="notification-alignment" [class]="status"
        ><label class="label-alignment"> {{ connectionStatusMessage }}</label></i
      >
      <em class="pi pi-spin pi-spinner" *ngIf="showConnecting"></em>
    </div>
  </div>
  <div id="avatar" class="avatar-alignment">
    <app-avatar></app-avatar>
  </div>
</div>
