<div class="dataPackage-layout-content">
  <div style="margin-top: 0px; " id="eln-dataPackge" class="data-pacakage-content-container">
    <bpt-slider
      [(visible)]="sliderOptions.visible"
      [closeOnEscape]="sliderOptions.closeOnEscape"
      [headerText]="sliderOptions.headerText"
      [size]="sliderOptions.size"
      [isFooterSticky]="sliderOptions.isFooterSticky"
      [displayFooter]="sliderOptions.displayFooter"
      [displayFooterWithPrimaryButton]="sliderOptions.displayFooterWithPrimaryButton"
      [displayFooterWithSecondaryButton]="sliderOptions.displayFooterWithSecondaryButton"
      [isModal]="sliderOptions.isModal">
      <p class="p-0 eln-secondaryHeading" >
       {{dataPackageSecondaryHeader}}
      </p>
      <div class="mb-5 mt-2">
        <div class="flex flex-row gap-3">
          <p class="flex justify-content-center gap-2">
            <p-radioButton
              class="entireExperimentRadioButton"
              [(ngModel)]="currentSelectedRadioButton"
              (onFocus)="focusedRadioButton($event)"
              value="entireExperiment"></p-radioButton>
              <label for="entireExperimentRadioButton">{{entireExperiment}}</label>
          </p>
          <p class="eln-radioButtons justify-content-center flex gap-2 ">
            <p-radioButton
              class="selectedActivityRadioButton"
              [(ngModel)]="currentSelectedRadioButton"
              (onFocus)="focusedRadioButton($event)"
              value="selectedActivity"></p-radioButton>
              <label for="selectedActivityRadioButton">{{selectedActivities}}</label>
          </p>
        </div>
        <div>
          <div class="eln-activitiesCheckList" *ngIf="activitiesArray.length !==0" >
            <div  class="flex flex-column gap-3" *ngIf="currentSelectedRadioButton === 'selectedActivity' ; else entireExperimentWithActivities " >
              <p-checkbox class="eln-checkBox" *ngFor="let activity of activitiesArray"
                [value]="activity"
                [(ngModel)]="checkedActivities"
                label={{activity.activityName}}
                (onChange)="onCheckBoxClickEvent($event.checked)">
              </p-checkbox>
            </div>
            <ng-template #entireExperimentWithActivities>
              <div  class="flex flex-column gap-3" >
               <div class="flex flex-row" *ngFor="let activity of activitiesArray">
                <span class="icon-check icon-m pr-1"
                ></span>  
                <span >{{activity.activityName}}</span>
               </div>
            </div>
            </ng-template>
          </div>
      
          </div>
          <div  class="eln-dataPackage-no-activity flex justify-content-center align-items-center" *ngIf="activitiesArray.length ===0 && currentSelectedRadioButton === 'selectedActivity'" >
            <div class="eln-dataPackage-no-activity-img">
              <img  style="width: 64px; height: 40px; margin-bottom: 10px;"  src="../../../assets/eln-assets/no-data.svg" alt="" id="eln-no-data-img"
                class="activity-inputs-no-data-img" />
                <span class="text">{{noActivityAvailable}}</span>
            </div>
        </div>
        <div  class="eln-dataPackage-no-activity flex justify-content-center align-items-center" *ngIf="activitiesArray.length ===0 && currentSelectedRadioButton === 'entireExperiment'" >
          <div class="eln-dataPackage-no-activity-img" style="color: #333;">
            <p [innerHTML]="noActivityAvailableOnCoverPage"></p>
          </div>
      </div>
      </div>

      <div class="flex flex-row">
        <div class="inline-flex ml-auto column-gap-3">
          <p-button
            class="data-package-action-button"
            styleClass="bpt-button-compact p-button-outlined"
            i18n-label="@@Cancel"
            label="Cancel"
            [hidden]="activitiesArray.length ===0 && currentSelectedRadioButton === 'selectedActivity'"
            (onClick)="cancelDataPackage()"></p-button>
          <p-button 
            class="data-package-action-button"
            i18n-label="@@Generate&Preview"
            styleClass="bpt-button-compact"
            label="Generate & Preview"
            [disabled]="disablePreviewButton"
            [hidden]="activitiesArray.length ===0 && currentSelectedRadioButton === 'selectedActivity'"
            id="eln-btnPreviewDataPackage"
            (onClick)="previewDataPackage()"></p-button>
        </div>
      </div>
    </bpt-slider>
  </div>
</div>