<app-toast></app-toast>
<eln-progress-spinner></eln-progress-spinner>
<router-outlet></router-outlet>

<div class="eln-experimentConfirmDialog" id="eln-experimentConfirmationDialog">
  <p-confirmDialog
    [position]="'center'"
    rejectButtonStyleClass="bpt-button-compact"
    acceptButtonStyleClass="bpt-button-compact"
    id="startExperimentConfirmation"
  >
  </p-confirmDialog>
</div>