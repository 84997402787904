import { ClientStatePermission } from 'model/client-state-permission.interface';
import * as data from '../../../../assets/eln-assets/data.json';
import { ClientState } from './client-state.interface';

export class ConstantClientState implements ClientState {
  jsonData: any;
  constructor() {
    this.jsonData = data;
  }

  getClientStatePermissions(): Promise<ClientStatePermission[]> {
    (this.jsonData.clientStatePermissions as any[]).forEach((permission) => {
      permission.featureFlags = JSON.stringify(permission.featureFlags);
    });
    return Promise.resolve(this.jsonData.clientStatePermissions);
  }
}
