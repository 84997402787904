<span
  *ngIf="!params.disableLinkOut"
  id="eln-lab-item-row-data-id-icon"
  class="fas fa-external-link-alt bpt-eln-link"
  (click)="linkClicked()"
></span>
<span
  id="eln-lab-item-row-data-id"
  [ngClass]="{ 'bpt-eln-id-with-link': !params.disableLinkOut }"
  >{{ params.value }}</span
>
<div *ngIf="flags && flags.length > 0">
  <div
    *ngFor="let flag of flags"
    [ngClass]="flag.className"
    (click)="flagClick($event, flag)"
    [title]="flag.hoverText"
    [style]="flag.style"
  ></div>
</div>
