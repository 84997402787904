import { Injectable } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { DataValueService } from '../experiment/services/data-value.service';
import { ValueState } from '../api/models';
import { SeverityIndicatorType } from 'bpt-ui-library/shared';
import { AliquotTest, StudyActivity } from '../api/data-entry/models';
import { formatLocalDate } from '../shared/date-time-helpers';

@Injectable({
  providedIn: 'root'
})
export class ActivityInputValidationHelper {
  constructor(private readonly dataValueService: DataValueService,
    ) { }

  wasEmpty = false;


  public getConvertedDateToDisplay(value: string): string {
    return formatLocalDate(value);
  }

  private getRowDataForSample(aliquotNumber: string, tableData: { [key: string]: any }[]) {
    return tableData.find(t => t.aliquotNumber === aliquotNumber)?.modifiableFields as Array<{ [key: string]: any }>;
  }

  private getRowDataForStudyActivity(aliquotNumber: string, tableData: { [key: string]: any }[]) {
    return tableData.find(t => t.code === aliquotNumber)?.modifiableFields as Array<{ [key: string]: any }>;
  }

  public getSeverityIndicatorDefinition = (data: { [key: string]: any }[], params: ICellRendererParams): any => {
    if (this.dataValueService.getExperimentDataValue(
      undefined,
      params.value
    )?.state === ValueState.Empty) {
      return { indicatorType: SeverityIndicatorType.Empty };
    }

    if (!params || !params.data || !params.colDef || !params.colDef.field) {
      return { indicatorType: '' };
    }
    const rowData = params.data.activityInputType === "aliquot" ? this.getRowDataForSample(params.data.aliquotNumber, data) :
      this.getRowDataForStudyActivity(params.data.code, data);
    const modifiedField = rowData?.find(x => { return params?.colDef?.field && x[params.colDef.field] });
    return (!!rowData && modifiedField && this.wasEmpty === false &&
      modifiedField[params.colDef.field]?.isModified) ? { indicatorType: SeverityIndicatorType.Modified } : { indicatorType: '' };
  }


  public getSeverityIndicatorDefinitionForTest = (
    data: { [key: string]: any }[],
    params: ICellRendererParams,
    previousSampleDataSource: { [key: string]: any }[]
  ): any => {
    const currentSelectedTests = params.data.aliquotTests;
    const previouslyUpdatedAliquot = previousSampleDataSource.find(x => x.aliquotNumber === params.data.aliquotNumber);
    const previouslySelectedTests = previouslyUpdatedAliquot?.aliquotTests ?? [];

    if (currentSelectedTests.length === 0) {
      if (previouslySelectedTests.length > 0)
      {
        params.data.testSelectionModified = true;
      }
      return { indicatorType: SeverityIndicatorType.Empty };
    }
    if(params.data.testSelectionModified) return { indicatorType: SeverityIndicatorType.Modified };

    if(!params.data.testSelectionModified && previouslySelectedTests.length > 0
      && this.areNotEqual(currentSelectedTests, previouslySelectedTests))
    {
      params.data.testSelectionModified = true;
      return { indicatorType: SeverityIndicatorType.Modified };
    }
    else{
      return { indicatorType: '' };
    }
  };

  public getSeverityIndicatorDefinitionForStudyActivity = (
    data: { [key: string]: any }[],
    params: ICellRendererParams,
    previousSampleDataSource: { [key: string]: any }[]
  ): any => {
    const currentSelectedStudyActivities = params.data.studyActivities;
    const previouslyUpdatedAliquot = previousSampleDataSource.find(x => x.code === params.data.code);
    const previouslySelectedStudyActivities = previouslyUpdatedAliquot?.studyActivities ?? [];

    if (currentSelectedStudyActivities.length === 0) {
      if (previouslySelectedStudyActivities.length > 0) {
        params.data.studySelectionModified = true;
      }
      return { indicatorType: SeverityIndicatorType.Empty };
    }

    if (params.data.studySelectionModified) {
      return { indicatorType: SeverityIndicatorType.Modified };
    }

    if (!params.data.studySelectionModified && previouslySelectedStudyActivities.length > 0
      && this.selectedActivitiesAreNotEqual(currentSelectedStudyActivities, previouslySelectedStudyActivities)) {
      params.data.studySelectionModified = true;
      return { indicatorType: SeverityIndicatorType.Modified };
    }
    else {
      return { indicatorType: '' };
    }
  };

  public selectedActivitiesAreNotEqual = (currentSelectedStudyActivity: StudyActivity[], previouslySelectedStudyActivity: StudyActivity[]) => {
    const currentSelectedStudyCodes = currentSelectedStudyActivity.map((ev: StudyActivity) => ev.code) ?? [];
    const alreadySavedStudyCodes = previouslySelectedStudyActivity.map((ev: StudyActivity) => ev.code) ?? [];
    const selectedStudyCodes = currentSelectedStudyCodes.filter(function (obj: string) {
      return alreadySavedStudyCodes.indexOf(obj) === -1;
    });
    const unselectedStudyCodes = alreadySavedStudyCodes.filter(function (obj: string) {
      return currentSelectedStudyCodes.indexOf(obj) === -1;
    });
    return selectedStudyCodes.length > 0 || unselectedStudyCodes.length > 0;
  }

  public areNotEqual = (currentSelectedTest: AliquotTest[], previouslySelectedTest: AliquotTest[])=> {
    const currentSelectedTestIds = currentSelectedTest.map((ev: AliquotTest) => ev.testId) ?? [];
    const alreadySavedTestIds = previouslySelectedTest.map((ev: AliquotTest) => ev.testId) ?? [];
    const selectedTestIds = currentSelectedTestIds.filter(function(obj: string) {
      return alreadySavedTestIds.indexOf(obj) === -1;
    });
    const unselectedTestIds = alreadySavedTestIds.filter(function(obj: string) {
      return currentSelectedTestIds.indexOf(obj) === -1;
    });
    return selectedTestIds.length > 0 || unselectedTestIds.length > 0;
  }
}
