import { ExperimentWorkflowState } from '../../../api/data-entry/models';
import { PromptType } from '../../../api/models';
export type PromptsWisePermissions = { [key: string]: { [key: string]: boolean } };

export class PromptsFeatureManager {
  static readonly deletePrompt = 'eln-remove-prompt';
  static readonly restorePrompt = 'eln-restore-prompt';


  public static readonly FeatureNamesByItemType: {
    [itemType: string]: {
      removeFeatureName: string;
      restoreFeatureName: string;
    };
  } = {
    materials: {
      removeFeatureName: 'PermittedWorkflowStatesToRemovePrompt',
      restoreFeatureName: 'PermittedWorkflowStatesToRestorePrompt'
    },
    instruments: {
      removeFeatureName: 'PermittedWorkflowStatesToRemovePrompt',
      restoreFeatureName: 'PermittedWorkflowStatesToRestorePrompt'
    },
    columns: {
      removeFeatureName: 'PermittedWorkflowStatesToRemovePrompt',
      restoreFeatureName: 'PermittedWorkflowStatesToRestorePrompt'
    },
    preparations: {
      removeFeatureName: 'PermittedWorkflowStatesToRemovePrompt',
      restoreFeatureName: 'PermittedWorkflowStatesToRestorePrompt'
    },
    consumablesAndSupplies:{
      removeFeatureName: 'PermittedWorkflowStatesToRemovePrompt',
      restoreFeatureName: 'PermittedWorkflowStatesToRestorePrompt'
    }
  };

  public static readonly PromptsDataModifiableFeatures = [
    {
      feature:
        PromptsFeatureManager.FeatureNamesByItemType[PromptType.Materials].removeFeatureName,
      expectedValue: true,
      elementIdOfActionOption: this.deletePrompt,
      itemType: PromptType.Materials
    },
    {
      feature:
        PromptsFeatureManager.FeatureNamesByItemType[PromptType.Instruments]
          .removeFeatureName,
      expectedValue: true,
      elementIdOfActionOption: this.deletePrompt,
      itemType: PromptType.Instruments
    },
    {
      feature:
        PromptsFeatureManager.FeatureNamesByItemType[PromptType.Columns]
          .removeFeatureName,
      expectedValue: true,
      elementIdOfActionOption: this.deletePrompt,
      itemType: PromptType.Columns
    },
    {
      feature:
        PromptsFeatureManager.FeatureNamesByItemType[PromptType.Preparations]
          .removeFeatureName,
      expectedValue: true,
      elementIdOfActionOption: this.deletePrompt,
      itemType: PromptType.Preparations
    },
    {
      feature:
        PromptsFeatureManager.FeatureNamesByItemType[PromptType.ConsumablesAndSupplies]
          .removeFeatureName,
      expectedValue: true,
      elementIdOfActionOption: this.deletePrompt,
      itemType: PromptType.ConsumablesAndSupplies
    },
    {
      feature:
        PromptsFeatureManager.FeatureNamesByItemType[PromptType.Materials].restoreFeatureName,
      expectedValue: true,
      elementIdOfActionOption: this.restorePrompt,
      itemType: PromptType.Materials
    },
    {
      feature:
        PromptsFeatureManager.FeatureNamesByItemType[PromptType.Instruments]
          .restoreFeatureName,
      expectedValue: true,
      elementIdOfActionOption: this.restorePrompt,
      itemType: PromptType.Instruments
    },
    {
      feature:
        PromptsFeatureManager.FeatureNamesByItemType[PromptType.Columns]
          .restoreFeatureName,
      expectedValue: true,
      elementIdOfActionOption: this.restorePrompt,
      itemType: PromptType.Columns
    },
    {
      feature:
        PromptsFeatureManager.FeatureNamesByItemType[PromptType.Preparations]
          .restoreFeatureName,
      expectedValue: true,
      elementIdOfActionOption: this.restorePrompt,
      itemType: PromptType.Preparations
    },
    {
      feature:
        PromptsFeatureManager.FeatureNamesByItemType[PromptType.ConsumablesAndSupplies]
          .restoreFeatureName,
      expectedValue: true,
      elementIdOfActionOption: this.restorePrompt,
      itemType: PromptType.ConsumablesAndSupplies
    }
  ];

  private static getDefaultPermissions(): PromptsWisePermissions {
    const permissions: PromptsWisePermissions = {
      materials: {
        enabled: false
      },
      instruments: {
        enabled: false
      },
      columns: {
        enabled: false
      },
      preparations: {
        enabled: false
      },
      consumablesAndSupplies: {
        enabled: false
      }
    };
    return permissions;
  }

  public static EvaluateUserPermissionsOnLabItems(
    workflowState: ExperimentWorkflowState,
    featuresByClientState: string[]
  ): PromptsWisePermissions {
    const permissions = PromptsFeatureManager.getDefaultPermissions();
    PromptsFeatureManager.PromptsDataModifiableFeatures.forEach((featureToLookup) => {

      permissions[featureToLookup.itemType][featureToLookup.feature] =
        permissions[featureToLookup.itemType][featureToLookup.feature] || false;
      permissions[featureToLookup.itemType][featureToLookup.elementIdOfActionOption] =
        permissions[featureToLookup.itemType][featureToLookup.elementIdOfActionOption] || false;

      featuresByClientState.forEach((feature) => {
        if (feature) {
          const features = JSON.parse(feature);
          const hasAccess =
            features[featureToLookup.feature] &&
            (features[featureToLookup.feature] as Array<string>).includes(workflowState);

          if (
            features[featureToLookup.feature] &&
            !permissions[featureToLookup.itemType][featureToLookup.feature]
          ) {
            permissions[featureToLookup.itemType].enabled =
              permissions[featureToLookup.itemType].enabled || hasAccess;
            permissions[featureToLookup.itemType][featureToLookup.feature] = hasAccess;
            permissions[featureToLookup.itemType][featureToLookup.elementIdOfActionOption] =
              hasAccess;
          }
        }
      });
    });
    return permissions;
  }
}
