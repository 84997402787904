import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { Subject } from 'rxjs';
import { Logger } from './logger.service';
import { environment } from '../../environments/environment';
import { UserService } from './user.service';
import { ExperimentService } from '../experiment/services/experiment.service';
import { LockType, InputLock, CellLock } from 'model/input-lock.interface';
import { BarcodeScannerHelper } from './barcode-scanner-helper';
import { ExperimentNotificationOnlyResponse } from '../experiment/model/experiment-notification-only-response.model';
import { ExperimentDataRecordNotification, ExperimentSentForCorrectionEventNotification, NotificationDetails, NotificationRemovalType } from '../api/data-entry/models';
import { ElnCollaborator } from '../model/eln-collaborator';
import { isArray, pick } from 'lodash-es';
import { Experiment } from '../model/experiment.interface';
import { ClientValidationDetails } from '../model/client-validation-details';

type validationToRemoveLookupType = Pick<ExperimentSentForCorrectionEventNotification, "changeContext">;
@Injectable({
  providedIn: 'root'
})
export class ExperimentNotificationService {
  public hubConnection!: signalR.HubConnection;
  private get serviceUrl(): string {
    return `${environment.dataEntryServicesUrl}/experimentNotifications`;
  };
  private readonly signalRRetryAttemptsAndDuration: number[];
  public reconnectingAlert = new Subject<any>();
  public reconnectedAlert = new Subject<any>();
  public usersAddedAlert = new Subject<ElnCollaborator[]>();
  public UserConnectionRemovedAlert = new Subject<ElnCollaborator>();
  public connectionLostAlert = new Subject<any>();
  public connectionStartedAlert = new Subject<any>();
  public dataRecordReceiver = new Subject<ExperimentDataRecordNotification | ExperimentDataRecordNotification[]>();
  public nonDataRecordReceiver = new Subject<any>();
  public inputLockReceiver = new Subject<any[]>();
  public inputLocks: Array<InputLock> = [];
  public experimentNotificationOnlyReceiver = new Subject<ExperimentNotificationOnlyResponse>();
  private inputLocksLoadedStatus = false;

  public warningKeys: string[] = [];
  public warningsHub: { [key: string]: Partial<ClientValidationDetails> } =
    {
      ModificationPostExperimentAuthorized: {
        warnings: [],
        warningTitle: $localize`:@@warningPostExperimentAuthorized:The experiment workflow is currently in an authorized state, and post-authorization changes have been identified. To rectify any data modifications, please transition the experiment to the Correction state. Click See more to view the Modifed Record types`
      },
    }

  public static readonly warningTitles : {[key: string]: string} = {
    "ModificationPostExperimentAuthorized-CellChanged": $localize`:@@ModificationPostExperimentAuthorized-CellChanged:Cell Changed`
  }

  constructor(
    private readonly logger: Logger,
    private readonly userService: UserService,
    private readonly experimentService: ExperimentService,
    private readonly barcodeScannerHelper: BarcodeScannerHelper
  ) {
    this.signalRRetryAttemptsAndDuration = environment.signalRRetryAttempts;
    this.dataRecordReceiver.subscribe(this.updatehWarningsByDataRecordNotification.bind(this));
  }

  setConnection() {
    this.hubConnection = this.getConnectionBuilder()
      .withUrl(this.serviceUrl, {
        accessTokenFactory: () => {
          return this.userService.getOauthToken(this.serviceUrl);
        }
      })
      .configureLogging(signalR.LogLevel.Information)
      .withAutomaticReconnect(this.signalRRetryAttemptsAndDuration)
      .build();

    this.hubConnection.onclose((error) => {
      if (this.hubConnection.state === signalR.HubConnectionState.Disconnected) {
        this.logger.logWarning(`Connection lost. ${error ?? ''}`);
        this.connectionLostAlert.next(error);
      }
    });

    this.hubConnection.onreconnecting((error) => {
      if (this.hubConnection.state === signalR.HubConnectionState.Reconnecting) {
        this.logger.logWarning(`Reconnecting...${error ?? ''}`);
        this.reconnectingAlert.next(error);
      }
    });

    this.hubConnection.onreconnected((success) => {
      if (this.hubConnection.state === signalR.HubConnectionState.Connected) {
        this.logger.logInfo(`Reconnected.`);
        this.reconnectedAlert.next(success);
      }
    });

    // Client Methods to be invoked from Server
    this.hubConnection.on(
      'UserConnectionAdded',
      (data: ElnCollaborator | ElnCollaborator[]): void => {
        const collaborators = 'connectionId' in data ? [data] : data;
        const connectionIds = collaborators.map((c) => `${c.connectionId} ${c.puid}`).join('; ');
        this.logger.logInfo(
          `User Notification from Hub: UserConnectionAdded (length: ${collaborators.length}) ${connectionIds}`
        );
        this.usersAddedAlert.next(collaborators);
        if (Array.isArray(data) && data.length === 1) this.inputLocksLoadedStatus = true;
      }
    );

    this.hubConnection.on('UserConnectionRemoved', (data: ElnCollaborator): void => {
      this.logger.logInfo(
        `User Notification from Hub: UserConnectionRemoved ${data.connectionId} ${data.puid}`
      );
      this.UserConnectionRemovedAlert.next(data);
    });

    this.hubConnection.on('applyDataRecord', (data: any): void => {
      this.dataRecordReceiver.next(data);
    });

    this.hubConnection.on('ApplyNonDataRecord', (data: any): void => {
      this.nonDataRecordReceiver.next(data);
    })

    this.hubConnection.on('ChromatographyDataAvailable', (data: any): void => {
      this.nonDataRecordReceiver.next(data);
    })

    this.hubConnection.on('ExperimentNotificationOnly', (data: any): void => {
      this.experimentNotificationOnlyReceiver.next(data);
    });

    this.hubConnection.on(
      'ApplyInputLocks',
      (data: Array<InputLock>, initialLoad = false): void => {
        if (initialLoad) this.inputLocksLoadedStatus = true;
        if (data.length > 0) {
          const locks = this.getLocksToBeApplied(data);

          //This will enable or disable the field or cell based on lock type
          this.inputLockReceiver.next(locks);
        }
      }
    );

    this.hubConnection.on('FetchInputLocks', (connectionId: string): void => {
      this.sendInputLocks(connectionId);
    });

    this.hubConnection.on('ReceiveActivityInputDetails', (data: any): void => {
      if (data && data.addExperimentScannedItemsCommand !== null && data.addExperimentScannedItemsCommand !== undefined) {
        this.barcodeScannerHelper.updateActivityInputsScanStatus(data);
      }
    });

  }

  private getLocksToBeApplied(data: InputLock[]) {
    const collaborators = data.map((e) => e.experimentCollaborator.connectionId);
    const locks = this.inputLocks.filter((v) =>
      collaborators.includes(v.experimentCollaborator.connectionId)
    );
    this.inputLocks = this.inputLocks.filter(
      (v) => !collaborators.includes(v.experimentCollaborator.connectionId)
    );
    data.forEach((item) => {
      if (item.lockType === LockType.lock) {
        this.inputLocks.push(item);
      }
      locks.push(item);
    });
    return locks;
  }

  getConnectionBuilder() {
    return new signalR.HubConnectionBuilder();
  }

  private async leaveAnExperiment() {
    if (this.hubConnection?.state === signalR.HubConnectionState.Connected) {
      await this.hubConnection?.stop();
    }
  }

  //Single method to start a connection as well as join the experiment group.
  //Can be used while loading an experiment or trying a force reconnect
  public async joinAnExperiment() {
    if ((environment as any).signalRDisabled) {
      this.logger.logWarning(`SignalR has been disabled in the environment file`);
      return;
    }
    this.inputLocksLoadedStatus = false;

    if (this.hubConnection?.state !== signalR.HubConnectionState.Connected) {
      this.setConnection();
      await this.startConnection();
    } else {
      this.invokeHub(
        'JoinExperiment',
        this.experimentService.currentExperiment?.id,
        this.getCollaborator()
      );
      this.connectionStartedAlert.next(this.hubConnection);
    }

    this.synchronize();
    setTimeout(() => {
      this.inputLocksLoadedStatus = true;
    }, environment.maxIntervalToLoadLocks);
  }

  private async invokeHub<T = any>(methodName: string, ...args: any[]) {
    let result = {};
    if (this.hubConnection?.state === signalR.HubConnectionState.Connected) {
      result = await this.hubConnection.invoke(methodName, ...args);
    }
    return result;
  }

  private synchronize() {
    this.invokeHub(
      'SynchronizeMessages',
      this.experimentService.currentExperiment?.id,
      this.experimentService.currentExperiment?.lastProcessedDataRecordTimeStamp
    );
  }

  public sendInputControlStatus(inputHandler: InputLock[]) {
    if (inputHandler[0].lockType === LockType.lock) {
      //Unlock the table for only single cell case.
      if (!inputHandler[0].multiSelectCells) {
        this.unlockTable();
      }
      this.invokeHub(
        'ChangeInputStatus',
        inputHandler,
        this.experimentService.currentExperiment?.id
      );
      inputHandler.forEach((lockItem: InputLock) => {
        this.inputLocks.push(lockItem);
      });
    } else {
      let isLocked = false;
      inputHandler.forEach((lockItem: InputLock) => {
        if (this.inputLocks.find((i) => i.key === lockItem.key)) {
          isLocked = true;
          this.inputLocks = this.inputLocks.filter((item) => item.key !== lockItem.key);
        }
      });
      if (isLocked) {
        this.invokeHub(
          'ChangeInputStatus',
          inputHandler,
          this.experimentService.currentExperiment?.id
        );
      }
    }
  }

  /** This will unlock table cell when another is made */
  unlockTable() {
    this.inputLocks = this.inputLocks.filter(
      (item) =>
        !(
          (item as CellLock).tableId !== undefined &&
          item.experimentCollaborator.connectionId === this.hubConnection.connectionId
        )
    );
  }

  public sendInputLocks(connectionId: string) {
    if (this.inputLocksLoadedStatus === true)
      this.invokeHub('SendInputLocks', this.inputLocks, connectionId);
  }

  getInputLocks(connectionId: string) {
    this.invokeHub('GetInputLocks', connectionId);
  }

  public async disconnectUser() {
    const unlockCurrentUser = this.inputLocks
      .filter((i) => i.experimentCollaborator.connectionId === this.hubConnection.connectionId)
      .map((lockItem) => {
        lockItem.lockType = LockType.unlock;
        return lockItem;
      });

    const unlockUsers = this.inputLocks.map((lockItem) => {
      lockItem.lockType = LockType.unlock;
      return lockItem;
    });

    this.inputLocks = [];

    await this.invokeHub(
      'ChangeInputStatus',
      unlockCurrentUser,
      this.experimentService.currentExperiment?.id
    );
    this.leaveAnExperiment();
    this.inputLockReceiver.next(unlockUsers);
  }

  public replayInputLocks() {
    if (this.inputLocksLoadedStatus) {
      this.inputLockReceiver.next(this.inputLocks);
    }
  }

  public getInputLocksLoadedStatus() {
    return this.inputLocksLoadedStatus;
  }

  private readonly startConnection = async () => {
    await this.hubConnection
      .start()
      .then(() => {
        console.assert(this.hubConnection.state === signalR.HubConnectionState.Connected);
        this.logger.logInfo(`Connection started.`);
        this.invokeHub(
          'JoinExperiment',
          this.experimentService.currentExperiment?.id,
          this.getCollaborator()
        );
        this.connectionStartedAlert.next(this.hubConnection);
      })
      .catch((err) => {
        console.assert(this.hubConnection.state === signalR.HubConnectionState.Disconnected);
        this.logger.logErrorMessage(`Error while starting connection: ${err}`);
        setTimeout(this.startConnection, environment.signalRInitialDelay);
      });
  };

  public getCollaborator(): ElnCollaborator {
    if (
      !this.userService.currentUser.firstName ||
      !this.userService.currentUser.lastName ||
      !this.hubConnection.connectionId
    ) {
      throw console.error('Either user or websocket connection details not present.');
    }

    return {
      puid: this.userService.currentUser.puid,
      firstName: this.userService.currentUser.firstName,
      lastName: this.userService.currentUser.lastName,
      connectionId: this.hubConnection.connectionId
    };
  }

  public updatehWarningsByDataRecordNotification(dataRecord: ExperimentDataRecordNotification | ExperimentDataRecordNotification[]): void {
    if (isArray(dataRecord)) {
      dataRecord.forEach(notification => this.updatehWarningsByDataRecordNotification(notification));
      return;
    }
    const dataRecordForValidations = pick(dataRecord, "changeContext") as validationToRemoveLookupType;
    if (dataRecordForValidations.changeContext?.validationsToRemove) {
      dataRecordForValidations.changeContext?.validationsToRemove.forEach(validationToRemove => {
        switch (validationToRemove.notificationRemovalType) {
          case NotificationRemovalType.All:
            {
              if (this.experimentService.currentExperiment !== undefined) {
                (this.experimentService.currentExperiment).validationFailures = []
              }
            }
            this.clearWarnings();
            break;
        }
      })
    }
  }

  public refreshWarnings(): void {
    this.experimentService.currentExperiment?.validationFailures?.forEach((validation) => {
      if (this.warningsHub[validation.notificationId]) {
        const transactionNameToDisplay = this.localizeNotificationMessage(validation.notificationDetails);
        if (!this.warningsHub[validation.notificationId].warnings?.includes(transactionNameToDisplay)) {
          this.warningsHub[validation.notificationId].warnings?.push(transactionNameToDisplay);
        }
      }
    })
    this.refreshWarningKeys();
  }

  public refreshWarningKeys(): void {
    this.warningKeys = [];
    const warningKeys = Object.keys(this.warningsHub);
    warningKeys.forEach(warningKey => {
      if (this.warningsHub[warningKey].warnings && this.warningsHub[warningKey].warnings!.length > 0) {
        this.warningKeys.push(warningKey);
      }
    })
  }

  private clearWarnings(): void{
    const removedWarningKeys: string[] = [];
    this.warningKeys.forEach(currentWarningKey =>{
      if(this.warningsHub[currentWarningKey]){
        this.warningsHub[currentWarningKey].warnings = [];
        removedWarningKeys.push(currentWarningKey);
      }
    })
    this.warningKeys =  this.warningKeys.filter(warningKey => !removedWarningKeys.includes(warningKey));
  }

  private localizeNotificationMessage(notification: NotificationDetails): string {
    // "Any" type can not avoided here as TemplateStringArray has many members which are not needed to be supplied from our end
    const translatedMessage = ExperimentNotificationService.warningTitles[notification.translationKey];
    if (translatedMessage !== notification.translationKey) {
      return translatedMessage;
    }
    return notification.message;
  }
}
