<div class="eln-slide-panel {{ showSlidePanel ? 'slide-full' : '' }}" id="eln-btnSlidePanel">
    <p-toolbar class="slide-button-panel">
        <button class="back-button" *ngIf="showBack" (click)="pageBack()" id="eln-btnPageBack">
            <img src="assets/eln-assets/nexus-icons/arrow-back.png" alt="back-icon">
        </button>
        <button class="close-button" (click)="closeSlidePanel()" id="eln-btnClosePanel">
            <span class="p-message-close-icon pi pi-times" i18n-pTooltip="@@close" pTooltip="Close"
                tooltipPosition="left"></span>
        </button>
    </p-toolbar>

    <div class="eln-slide-panel-container">
        <ng-container *ngTemplateOutlet="slidePanelContent">
        </ng-container>
    </div>
</div>

<div *ngIf="showSlidePanel" class="overlay"></div>