/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiEmpowerColumnNamesPost$Json } from '../fn/empower/api-empower-column-names-post-json';
import { ApiEmpowerColumnNamesPost$Json$Params } from '../fn/empower/api-empower-column-names-post-json';
import { apiEmpowerColumnNamesPost$Plain } from '../fn/empower/api-empower-column-names-post-plain';
import { ApiEmpowerColumnNamesPost$Plain$Params } from '../fn/empower/api-empower-column-names-post-plain';
import { apiEmpowerPeaksAndResultsPost$Json } from '../fn/empower/api-empower-peaks-and-results-post-json';
import { ApiEmpowerPeaksAndResultsPost$Json$Params } from '../fn/empower/api-empower-peaks-and-results-post-json';
import { apiEmpowerPeaksAndResultsPost$Plain } from '../fn/empower/api-empower-peaks-and-results-post-plain';
import { ApiEmpowerPeaksAndResultsPost$Plain$Params } from '../fn/empower/api-empower-peaks-and-results-post-plain';
import { apiEmpowerQuarterAndResultsetsPost$Json } from '../fn/empower/api-empower-quarter-and-resultsets-post-json';
import { ApiEmpowerQuarterAndResultsetsPost$Json$Params } from '../fn/empower/api-empower-quarter-and-resultsets-post-json';
import { apiEmpowerQuarterAndResultsetsPost$Plain } from '../fn/empower/api-empower-quarter-and-resultsets-post-plain';
import { ApiEmpowerQuarterAndResultsetsPost$Plain$Params } from '../fn/empower/api-empower-quarter-and-resultsets-post-plain';
import { ColumnHeadersResponse } from '../models/column-headers-response';
import { QuartersWithResultSetsResponse } from '../models/quarters-with-result-sets-response';
import { ResultAndPeakCollectionResponse } from '../models/result-and-peak-collection-response';

@Injectable({ providedIn: 'root' })
export class EmpowerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiEmpowerQuarterAndResultsetsPost()` */
  static readonly ApiEmpowerQuarterAndResultsetsPostPath = '/api/empower/quarter-and-resultsets';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmpowerQuarterAndResultsetsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiEmpowerQuarterAndResultsetsPost$Plain$Response(params?: ApiEmpowerQuarterAndResultsetsPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<QuartersWithResultSetsResponse>> {
    return apiEmpowerQuarterAndResultsetsPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiEmpowerQuarterAndResultsetsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiEmpowerQuarterAndResultsetsPost$Plain(params?: ApiEmpowerQuarterAndResultsetsPost$Plain$Params, context?: HttpContext): Observable<QuartersWithResultSetsResponse> {
    return this.apiEmpowerQuarterAndResultsetsPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<QuartersWithResultSetsResponse>): QuartersWithResultSetsResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmpowerQuarterAndResultsetsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiEmpowerQuarterAndResultsetsPost$Json$Response(params?: ApiEmpowerQuarterAndResultsetsPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<QuartersWithResultSetsResponse>> {
    return apiEmpowerQuarterAndResultsetsPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiEmpowerQuarterAndResultsetsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiEmpowerQuarterAndResultsetsPost$Json(params?: ApiEmpowerQuarterAndResultsetsPost$Json$Params, context?: HttpContext): Observable<QuartersWithResultSetsResponse> {
    return this.apiEmpowerQuarterAndResultsetsPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<QuartersWithResultSetsResponse>): QuartersWithResultSetsResponse => r.body)
    );
  }

  /** Path part for operation `apiEmpowerColumnNamesPost()` */
  static readonly ApiEmpowerColumnNamesPostPath = '/api/empower/column-names';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmpowerColumnNamesPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiEmpowerColumnNamesPost$Plain$Response(params?: ApiEmpowerColumnNamesPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ColumnHeadersResponse>> {
    return apiEmpowerColumnNamesPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiEmpowerColumnNamesPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiEmpowerColumnNamesPost$Plain(params?: ApiEmpowerColumnNamesPost$Plain$Params, context?: HttpContext): Observable<ColumnHeadersResponse> {
    return this.apiEmpowerColumnNamesPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ColumnHeadersResponse>): ColumnHeadersResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmpowerColumnNamesPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiEmpowerColumnNamesPost$Json$Response(params?: ApiEmpowerColumnNamesPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ColumnHeadersResponse>> {
    return apiEmpowerColumnNamesPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiEmpowerColumnNamesPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiEmpowerColumnNamesPost$Json(params?: ApiEmpowerColumnNamesPost$Json$Params, context?: HttpContext): Observable<ColumnHeadersResponse> {
    return this.apiEmpowerColumnNamesPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ColumnHeadersResponse>): ColumnHeadersResponse => r.body)
    );
  }

  /** Path part for operation `apiEmpowerPeaksAndResultsPost()` */
  static readonly ApiEmpowerPeaksAndResultsPostPath = '/api/empower/peaks-and-results';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmpowerPeaksAndResultsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiEmpowerPeaksAndResultsPost$Plain$Response(params?: ApiEmpowerPeaksAndResultsPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ResultAndPeakCollectionResponse>> {
    return apiEmpowerPeaksAndResultsPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiEmpowerPeaksAndResultsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiEmpowerPeaksAndResultsPost$Plain(params?: ApiEmpowerPeaksAndResultsPost$Plain$Params, context?: HttpContext): Observable<ResultAndPeakCollectionResponse> {
    return this.apiEmpowerPeaksAndResultsPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResultAndPeakCollectionResponse>): ResultAndPeakCollectionResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmpowerPeaksAndResultsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiEmpowerPeaksAndResultsPost$Json$Response(params?: ApiEmpowerPeaksAndResultsPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ResultAndPeakCollectionResponse>> {
    return apiEmpowerPeaksAndResultsPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiEmpowerPeaksAndResultsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiEmpowerPeaksAndResultsPost$Json(params?: ApiEmpowerPeaksAndResultsPost$Json$Params, context?: HttpContext): Observable<ResultAndPeakCollectionResponse> {
    return this.apiEmpowerPeaksAndResultsPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResultAndPeakCollectionResponse>): ResultAndPeakCollectionResponse => r.body)
    );
  }

}
