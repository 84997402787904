<div class="eln-activity">
  <div class="eln-activity-input-header">
    <span id="eln-InputModuleHeader" i18n="@@inputModuleHeader" class="eln-input-header">Activity Input</span>
    <span id="eln-iconHelperTextShow" i18n-pTooltip="@@activityInputHelperText" class="pi pi-info-circle eln-input-info"
            pTooltip="Scan sample aliquots, study material aliquots, materials to prepare, or equipment for instrument events"
            tooltipPosition="top"></span>
  </div>
  <div *ngIf="(!sampleAliquots || !sampleAliquots.sampleDataSource || 
  (sampleAliquots.sampleDataSource.length === 0 && !sampleAliquots.containsRemovedRows && !materialAliquots?.containsRemovedRows)) 
  && (!materialAliquots || !materialAliquots?.studyActivityDataSource || 
  materialAliquots.studyActivityDataSource.length === 0) && 
  (!instruments || !instruments.instrumentEventsDataSource)" class="eln-activity-input-no-data">
    <div class="eln-activity-input-no-data-img">
      <img src="../../../assets/eln-assets/no-data.svg" alt="" id="eln-no-data-img" class="activity-inputs-no-data-img"/>
      <span class="activity-inputs-no-data-text">{{
        specifyNoData
        }}</span>
    </div>
  </div>
  <div *ngIf="reloadAll" class="eln-input-data">
    <app-sample-table #sampleAliquots></app-sample-table>
    <app-study-activity-table #materialAliquots></app-study-activity-table>
    <app-instrument-event #instruments></app-instrument-event>
  </div>
</div>
