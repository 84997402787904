import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import {
  BptGridCellValueChangedEvent,
  BptGridComponent,
  BptGridMode,
  BptGridRowActionClickEvent,
  BptGridRowActionConfiguration,
  BptRowActionElement,
  ColumnDefinition,
  GridContextMenuItem,
  IFlagConfig,
  ISeverityIndicatorConfig,
} from 'bpt-ui-library/bpt-grid';
import { LabItemRefreshedContext, LabItemsService } from '../../lab-items.service';
import { LabItemsColumnTableOptions } from './lab-items-column-table-options';
import { BaseComponent } from '../../../../base/base.component';
import { ClientStateService } from 'services/client-state.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UnsubscribeAll } from '../../../../shared/rx-js-helpers';
import { filter } from 'rxjs/operators';
import { ActivityInputType, ClientFacingNoteContextType } from '../../../../api/models';
import { Experiment } from 'model/experiment.interface';
import { InstrumentColumn } from '../../../../api/models/LabItemsELN/Bookshelf/Api/LabItems/instrument-column';
import { ICellRendererParams } from 'ag-grid-community';
import { LabItemWisePermissions } from '../../shared/lab-items-feature-manager';
import { LabItemsRemovedColumnComponent } from '../lab-items-removed-column/lab-items-removed-column.component';
import { DialogService } from 'primeng/dynamicdialog';
import { BehaviorSubject } from 'rxjs';
import { ExperimentNotificationService } from '../../../../services/experiment-notification.service';
import { BptGridCellEditEvent } from 'bpt-ui-library/bpt-grid/model/bpt-grid-cell-edit-event.interface';
import { LockType } from '../../../../model/input-lock.interface';
import { ExperimentService } from '../../../services/experiment.service';
import { DataRecordService } from '../../../services/data-record.service';
import { FlagRendererService } from '../../../services/flag-renderer.service';
import { CommentContextType } from '../../../../api/internal-comment/models';
import { UserService } from '../../../../services/user.service';
import { PromptType } from '../../../../prompt/models/prompt.model';

@Component({
  selector: 'app-lab-items-column',
  templateUrl: './lab-items-column.component.html',
  styleUrls: ['./lab-items-column.component.scss']
})
export class LabItemsColumnComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() titleOfTable = $localize`:@@LabItemsColumnsTableTitle:Columns`;
  @Input() labItemsColumnDataSource: InstrumentColumn[] = [];
  @ViewChild('labItemsColumnGrid') labItemsColumnGrid!: BptGridComponent;

  labItemsColumnId = 'labItemsColumn';
  columnDefinitions: ColumnDefinition[] = [];
  gridMode = BptGridMode.dataEntry;
  containsRemovedRows = false;
  rowActions: BptRowActionElement[] = [];
  wasEmpty = false;
  public styleRootVariables = '';
  experiment!: Experiment;
  gridActions!: BptGridRowActionConfiguration;
  lockTimeOut = 0;
  permission!: LabItemWisePermissions;
  reloadGrid = true;

  constructor(
    public readonly clientStateService: ClientStateService,
    public readonly activatedRoute: ActivatedRoute,
    private readonly labItemsService: LabItemsService,
    private readonly router: Router,
    private readonly userService: UserService,
    private readonly dialogService: DialogService,
    private readonly renderer: Renderer2,
    private readonly elementRef: ElementRef,
    private readonly experimentNotificationService: ExperimentNotificationService,
    private readonly experimentService: ExperimentService,
    private readonly dataRecordService: DataRecordService,
    private readonly flagRendererService: FlagRendererService,
  ) {
    super(clientStateService, activatedRoute);
    this.experimentNotificationService.inputLockReceiver.subscribe((lock) => {
      this.labItemsService.applyCellLock(
        lock,
        this.labItemsColumnGrid?.gridOptions,
        this.renderer
      );
    });
  }

  ngOnInit(): void {
    this.addGridActions();
    this.columnDefinitions = LabItemsColumnTableOptions.GetColumnDefinitions(
      this.labItemsService.latestLabItemsPermissions,
      this.severityIndicatorConfig,
      this.labItemsService.doesRecipePromptTypeExist(PromptType.Columns),
      this.experimentService.currentExperiment?.workflowState
    );
    this.refreshDataSource();
    this.watchColumnRefreshNotification();
    this.watchRouterPathChanges();
    this.labItemsService.watchForActivityTitleDeterminationThroughRoute(this.activatedRoute);
    this.watchColumnAddedNotification();
    this.watchColumnUpdatedNotification();
    this.watchCollaborativeRefreshedColumnNotification();
    this.watchColumnRemovednotification();
    this.watchWorkFlowStateChanges();
    this.renderer.setAttribute(this.elementRef.nativeElement, 'data-id', this.labItemsColumnId);
    this.watchClientFacingNoteNotification();
    this.watchInternalCommentsNotification();
    this.currentUserRoleActionAssignment();
    this.augmentColumnsWithCornerFlagProviderForCells.bind(this);
    this.flagRendererService.ClientFacingNoteAdded.subscribe({
      next: (note) => {
        this.elementRef.nativeElement.dispatchEvent(note);
      }
    });
  }

  ngOnDestroy(): void {
    UnsubscribeAll(this.activeSubscriptions);
  }


  onFirstDataRendered(e: any) {
    this.labItemsService.loadCellLocks(this.labItemsColumnGrid.gridOptions, this.renderer);
    this.augmentColumnsWithCornerFlagProviderForCells();
  }

  private currentUserRoleActionAssignment() {
    if (this.userService.hasOnlyReviewerRights()) {
      this.gridMode = BptGridMode.dataView;
    }
    this.columnDefinitions = LabItemsColumnTableOptions.GetColumnDefinitions(
      this.labItemsService.latestLabItemsPermissions,
      this.severityIndicatorConfig,
      this.labItemsService.doesRecipePromptTypeExist(PromptType.Columns),
      this.experimentService.currentExperiment?.workflowState
    );
  }

  private watchClientFacingNoteNotification() {
    this.activeSubscriptions.push(
      this.labItemsService.experimentService.clientFacingNoteEvents.subscribe({
        next: () => {
          this.labItemsColumnGrid?.gridApi?.refreshCells({ force: true });
        }
      })
    );
  }
  onGridReady() {
    this.augmentColumnsWithCornerFlagProviderForCells();
  }

  private watchInternalCommentsNotification() {
    this.activeSubscriptions.push(
      this.labItemsService.internalCommentsRefresh.subscribe({
        next: () => {
          this.labItemsColumnGrid?.gridApi?.refreshCells({ force: true });
        }
      })
    );
  }

  private addGridActions() {
    const rowActions = this.getRowActionItems();
    const actionsSubject: BehaviorSubject<BptRowActionElement[]> = new BehaviorSubject(rowActions);

    this.gridActions = {
      actions: actionsSubject
    };
  }

  getContextMenu(): GridContextMenuItem[] {
    return [
      'copy',
      'copyWithHeaders',
      'copyWithGroupHeaders',
      'paste',
      'separator',
      {
        label: $localize`:@@clientFacingNoteContextMenuOption:Client-facing Notes`,
        action: () => {
          const cell = this.labItemsColumnGrid?.gridApi.getFocusedCell();
          if (cell) {
            const row = this.labItemsColumnGrid?.gridApi.getDisplayedRowAtIndex(cell.rowIndex);
            const colDef = cell.column.getColDef();
            const field = colDef.field as string;
            if (
              row &&
              row.id &&
              colDef?.field
            ) {
              this.flagRendererService.showClientFacingNotes(
                [
                  row.id,
                  field,
                  this.labItemsService.experimentService.currentActivityId,
                  ActivityInputType.InstrumentColumn
                ],
                ClientFacingNoteContextType.LabItems,
                this.labItemsService.experimentService.currentActivityId
              );
            }
          }
        },
        icon: '<img src="assets/eln-assets/apps-add.svg" class="ag-icon ag-custom-icon" />',
        disabled: !this.experimentService.isClientFacingNoteEnabled()
      },
      {
        label: $localize`:@@commentsHeader:Internal Comments`,
        action: () => {
          const cell =
            this.labItemsColumnGrid && this.labItemsColumnGrid.gridApi.getFocusedCell();
          if (cell) {
            const row =
              this.labItemsColumnGrid &&
              this.labItemsColumnGrid.gridApi.getDisplayedRowAtIndex(cell.rowIndex);
            const field = cell.column.getColDef().field as string;
            const fieldName = LabItemsColumnTableOptions.ColumnDefinition[field].displayName;
            const currentRowIndex = this.labItemsColumnGrid.gridApi.getDisplayedRowAtIndex(cell.rowIndex)?.rowIndex;
            if (row && currentRowIndex != null)
              this.flagRendererService.showInternalComments(
                [
                  this.labItemsService.experimentService.currentActivityId,
                  fieldName,
                  row.id as string,
                  field,
                  (currentRowIndex + 1).toString(),
                  ActivityInputType.InstrumentColumn
                ],
                CommentContextType.TableCell,
              );
          }
        },
        icon: '<img src="assets/eln-assets/apps-add.svg" class="ag-icon ag-custom-icon" />'
      }
    ];
  }

  private augmentColumnsWithCornerFlagProviderForCells(): any {
    const flagConfigProvider = (
      flag: 'top-right' | 'bottom-right',
      rowId: string,
      field: string
    ): IFlagConfig => {
      const fieldName = LabItemsColumnTableOptions.ColumnDefinition[field].displayName;
      return this.flagRendererService.getFlagRendererConfig(
        flag,
        this.flagRendererService.getPathBasedOnFlag(flag, fieldName, rowId, field, CommentContextType.LabItems, ActivityInputType.InstrumentColumn),
        CommentContextType.TableCell,
        rowId
      );
    };
    this.columnDefinitions.forEach((c: ColumnDefinition) => {
      c.flagConfigProvider = flagConfigProvider;
    });
    this.columnDefinitions?.forEach((columnDefinition: ColumnDefinition) => {
      this.labItemsColumnGrid?.updateColumnDefinitions(columnDefinition);
    });
    return flagConfigProvider;
  }

  cellEditStartedEvent(e: BptGridCellEditEvent) {
    this.lockTimeOut = window.setTimeout(() => {
      this.labItemsService.sendInputStatus(
        LockType.lock,
        e.gridId ?? '',
        e.rowId ?? '',
        e.field ?? ''
      );
    }, 100);
  }

  cellEditStoppedEvent(e: BptGridCellEditEvent) {
    window.clearTimeout(this.lockTimeOut);
    this.labItemsColumnGrid.gridApi.clearFocusedCell();
    this.labItemsService.sendInputStatus(
      LockType.unlock,
      e.gridId ?? '',
      e.rowId ?? '',
      e.field ?? ''
    );
  }

  public severityIndicatorConfig = () => {
    return {
      getIndicator: this.getSeverityIndicator //Called by the ag grid cell renderer
    } as ISeverityIndicatorConfig;
  };

  public readonly getSeverityIndicator = (params: ICellRendererParams) => {
    return this.labItemsService.getSeverityIndicatorDefinition(
      this.labItemsColumnDataSource,
      params
    );
  };

  public getRowActionItems(): BptRowActionElement[] {
    return [
      {
        id: this.labItemsService.columnDeleteActionId,
        enabled: this.isUserPermittedToEdit(),
        styleClass: 'far fa-trash-alt',
        click: this.rowDeleteActionClick.bind(this),
        tooltip: $localize`:@@RemoveItem:Remove item`
      },
      {
        id: this.labItemsService.columnRefreshActionId,
        enabled: this.isUserPermittedToEdit(),
        styleClass: 'fas fa-sync-alt',
        click: this.rowRefreshActionClick.bind(this),
        tooltip: $localize`:@@RefreshItem:Refresh item`
      }
    ];
  }

  isUserPermittedToEdit(): boolean {
    if (this.labItemsService.getRowActionPermission() && !(this.userService.hasOnlyReviewerRights())) {
      return true;
    }
    return false;
  }

  private rowDeleteActionClick(e: BptGridRowActionClickEvent) {
    this.labItemsService.removeHandler[ActivityInputType.InstrumentColumn](e.params.data);
  }

  private rowRefreshActionClick(e: BptGridRowActionClickEvent) {
    this.labItemsService.columnRefreshHandler(e.params.data, this.renderer);
  }

  private refreshDataSource(): void {
    const columns = this.labItemsService.getLabItemsColumns();
    columns.forEach((column) => {
      this.labItemsService.unpackModifiableDataValues<InstrumentColumn>(
        column,
        column.tableData,
        this.columnDefinitions,
        ActivityInputType.InstrumentColumn
      );
    });
    this.labItemsColumnDataSource = [...columns];
    this.labItemsColumnGrid?.gridApi?.setGridOption('rowData', this.labItemsColumnDataSource);
    this.containsRemovedRows = this.labItemsService.getLabItemsRemovedInstrumentColumns().length > 0;
    this.labItemsColumnGrid?.gridApi?.autoSizeAllColumns();
  }

  private watchColumnRefreshNotification() {
    this.activeSubscriptions.push(
      this.labItemsService.columnShouldRefresh.subscribe({
        next: () => this.refreshDataSource()
      })
    );
  }

  private watchWorkFlowStateChanges() {
    this.experimentService.experimentWorkFlowState.subscribe({
      next: (state) => {
        this.addGridActions();

        this.reloadGrid = false;
        setTimeout(() => {
          this.reloadGrid = true;
        }, 200);
      }
    });
    this.dataRecordService.experimentWorkFlowDataRecordReceiver.subscribe({
      next: (notification) => {
        this.addGridActions();
        this.reloadGrid = false;
        setTimeout(() => {
          this.reloadGrid = true;
        }, 200);
      }
    });
  }

  private watchColumnRemovednotification(): void {
    this.activeSubscriptions.push(
      this.labItemsService.InstrumentColumnRemovedRefresh.subscribe({
        next: () => this.refreshDataSource()
      })
    )
  }

  private watchColumnAddedNotification(): void {
    this.activeSubscriptions.push(
      this.labItemsService.ColumnAdded.subscribe({
        next: this.addNewColumnIntoGrid.bind(this)
      })
    );
  }

  private watchColumnUpdatedNotification(): void {
    this.activeSubscriptions.push(
      this.labItemsService.ColumnUpdated.subscribe({
        next: this.updateColumnIntoGrid.bind(this)
      })
    );
  }

  private addNewColumnIntoGrid(_instrument: InstrumentColumn): void {
    _instrument.activityInputType = ActivityInputType.InstrumentColumn;
    this.labItemsColumnDataSource.push(_instrument);
    this.labItemsColumnGrid?.gridApi.autoSizeAllColumns();
    this.refreshDataSource();
  }

  private updateColumnIntoGrid(item: InstrumentColumn): void {
    this.labItemsColumnGrid?.gridApi.applyTransaction({
      update: [item]
    });
  }

  private watchRouterPathChanges() {
    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe((_route: { url: string }) => {
        if (_route.url.toLowerCase().includes('/labitems')) {
          this.refreshDataSource();
        }
      });
  }

  private watchCollaborativeRefreshedColumnNotification() {
    this.labItemsService.MaterialRefreshedByOtherUser.subscribe({
      next: this.applyCellChange.bind(this)
          });
    this.labItemsService.cellChangedNotificationByItemType[ActivityInputType.InstrumentColumn].subscribe({
      next: this.applyCellChange.bind(this)
    });
  }

  cellValueChanged(e: BptGridCellValueChangedEvent) {
    this.labItemsService.labItemsCellValueChanged(
      ActivityInputType.InstrumentColumn,
      e,
      this.labItemsColumnGrid as BptGridComponent
    );
    this.refreshDataSource();
  }

  applyCellChange(labItemRefreshedContext: LabItemRefreshedContext): void {
    const rowNode = this.labItemsColumnGrid?.gridApi.getRowNode(labItemRefreshedContext.rowId);
    if (rowNode) {
      LabItemsService.UpdateCellValueInGrid(labItemRefreshedContext, rowNode);
      if (labItemRefreshedContext.skipFlash) {
        return;
      }
      this.labItemsColumnGrid?.gridApi.flashCells({
        rowNodes: [rowNode],
        columns: labItemRefreshedContext.changedColumns
      });
    }
  }

  loadRemovedRowsDialog() {
    this.dialogService.open(LabItemsRemovedColumnComponent, {
      width: '80%',
      autoZIndex: true,
      closable: true,
      closeOnEscape: true,
      header: $localize`:@@labitemsRemovedInstrumentColumns:Removed Instrument Columns`,
      styleClass: 'eln-removed-column-dialog'
    });
  }

  loadAuditHistoryDialog()
  {
    this.labItemsService.loadAuditHistoryDialog(ActivityInputType.InstrumentColumn);
  }

}
