import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { UserService } from 'services/user.service';
import { Labsite } from '../../api/models/labsite';
import { LabsiteService } from '../../api/services/labsite.service';
import { BptDropdownComponent } from 'bpt-ui-library/bpt-dropdown'

type valueType = string[] | string | undefined;

@Component({
  selector: 'app-sub-business-unit-select',
  templateUrl: './sub-business-unit-select.component.html',
  styleUrls: []
})
export class SubBusinessUnitSelectComponent implements OnInit, OnChanges {
  @Input() multiSelect = true;
  @Input() label = $localize`:@@SubBusinessUnit:Sub Business Unit(s)`;
  @Input() placeholder = $localize`:@@SubBusinessUnitPlaceHolder:Select Sub Business Unit(s)`;
  @Input() value: valueType = [];
  @Input() labsiteCodes: Array<string> | undefined = undefined;
  @Input() readonly = false;
  @Input() disabled = false;
  @Input() setPrimarySubBusinessUnit = true;
  @Input() allowNA = false;
  @Input() ngModelOptions = {};
  @Input() wrap = true;
  @Input() styleClass = '';
  @Input() panelStyleClass = 'eln-dropdown-wrap';
  @Input() required = false;
  @Input() errorFlag = false;
  @Input() errorMessage = "";
  @Input() showClear = false;
  
  @Output() valueChange = new EventEmitter();
  @Output() displayLabelModified = new EventEmitter();

  Labsites!: Array<Labsite>;
  oldValue: valueType = [];
  valueField = 'code';
  labelField = 'displayLabel';
  @ViewChild('bptDropdown') subBusinessUnitsDropdownControl!: BptDropdownComponent;

  constructor(
    private readonly labsiteService: LabsiteService,
    private readonly userService: UserService,
    private readonly changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.oldValue = this.value;
    this.setDefaultLabsiteCode();
    this.getSubBusinessUnits();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['labsiteCodes']) {
      this.getSubBusinessUnits();
    }
  }

  setDefaultLabsiteCode(): void {
    if (!this.labsiteCodes) {
      this.labsiteCodes = [this.userService.currentUser.labSiteCode as string];
    }
  }

  getSubBusinessUnits = () => {
    if (!this.labsiteCodes || this.labsiteCodes.length === 0) {
      this.Labsites = [];
      this.value = this.multiSelect ? [] : '';
      return;
    }
    this.labsiteService
      .labsitesSubBusinessUnitsGet$Json({
        labsiteCodes: this.labsiteCodes!.join(',')
      })
      .subscribe(
        (labsiteGetResponse) => {
          this.Labsites = labsiteGetResponse.labsites;
          this.setDefaultSubBusinessUnit();
          this.removeSubBusinessUnitsToSelection();
          this.notifyDisplayLabel();
          this.changeDetector.detectChanges();
        },
        (error) => {
          this.Labsites = [];
        }
      );
  };

  removeSubBusinessUnitsToSelection(): void {
    let subBusinessUnitCodes: Array<string> = [];
    const labsiteSubBusinessUnits = this.Labsites.map((x) => x.subBusinessUnits.map((u) => u.code));
    subBusinessUnitCodes = subBusinessUnitCodes.concat.apply([], labsiteSubBusinessUnits);
    const sbuCode = subBusinessUnitCodes.filter((code) => this.value?.includes(code));
    this.value = this.multiSelect ? sbuCode : sbuCode[0];
    this.valueChange.emit(this.value);
  }

  /**
   * Sets the current user as default selection when setCurrentUser is true and no selection made
   */
  setDefaultSubBusinessUnit() {
    if (this.setPrimarySubBusinessUnit && (!this.value || this.value.length === 0)) {
      const subBusinessUnit = this.userService.currentUser.primarySubBusinessUnit as string;
      if (this.multiSelect) {
        this.value = [subBusinessUnit];
        this.notifyDisplayLabel();
      } else {
        this.value = subBusinessUnit;
      }
    }
  }

  modelChanged = (newValue: valueType) => {
    if (this.oldValue !== newValue) {
      this.oldValue = newValue;
      this.valueChange.emit(newValue);
      this.notifyDisplayLabel();
    }
  };

  private notifyDisplayLabel(): void {
    setTimeout(() => {
      if (
        this.multiSelect &&
        this.subBusinessUnitsDropdownControl &&
        this.subBusinessUnitsDropdownControl.multiSelect &&
        this.subBusinessUnitsDropdownControl.multiSelectComponent.containerViewChild
      ) {
        this.displayLabelModified.emit(
          this.subBusinessUnitsDropdownControl.multiSelectComponent.containerViewChild.nativeElement
            .outerText
        );
      }
    }, 250);
  }
}
