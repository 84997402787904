/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AddRecipeRowResponse } from '../models/add-recipe-row-response';
import { CancelRecipeResponse } from '../models/cancel-recipe-response';
import { ChangeRecipeAdditionalNotesResponse } from '../models/change-recipe-additional-notes-response';
import { ChangeRecipeAnalysisTechniqueResponse } from '../models/change-recipe-analysis-technique-response';
import { ChangeRecipeApprovalRequiredResponse } from '../models/change-recipe-approval-required-response';
import { ChangeRecipeApprovedResponse } from '../models/change-recipe-approved-response';
import { ChangeRecipeApproversResponse } from '../models/change-recipe-approvers-response';
import { ChangeRecipeAvailabilityResponse } from '../models/change-recipe-availability-response';
import { ChangeRecipeCellResponse } from '../models/change-recipe-cell-response';
import { ChangeRecipeClientsResponse } from '../models/change-recipe-clients-response';
import { ChangeRecipeCompendiaResponse } from '../models/change-recipe-compendia-response';
import { ChangeRecipeConsumingLabsitesResponse } from '../models/change-recipe-consuming-labsites-response';
import { ChangeRecipeEditorsResponse } from '../models/change-recipe-editors-response';
import { ChangeRecipeFieldResponse } from '../models/change-recipe-field-response';
import { ChangeRecipeMethodResponse } from '../models/change-recipe-method-response';
import { ChangeRecipeNameResponse } from '../models/change-recipe-name-response';
import { ChangeRecipePreLoadDataTransformOptionsResponse } from '../models/change-recipe-pre-load-data-transform-options-response';
import { ChangeRecipeProjectsResponse } from '../models/change-recipe-projects-response';
import { ChangeRecipePurposeResponse } from '../models/change-recipe-purpose-response';
import { ChangeRecipeReviewStatusResponse } from '../models/change-recipe-review-status-response';
import { ChangeRecipeSatisfiesPurposeResponse } from '../models/change-recipe-satisfies-purpose-response';
import { ChangeRecipeSubBusinessUnitsResponse } from '../models/change-recipe-sub-business-units-response';
import { ChangeRecipeTagsResponse } from '../models/change-recipe-tags-response';
import { ChangeRecipeTypeResponse } from '../models/change-recipe-type-response';
import { ChangeRecipeVerifiersResponse } from '../models/change-recipe-verifiers-response';
import { ChangeVerifiedRecipeResponse } from '../models/change-verified-recipe-response';
import { CopiedRecipeResponse } from '../models/copied-recipe-response';
import { CreateRecipeResponse } from '../models/create-recipe-response';
import { RecipeChangeToPendingApprovalResponse } from '../models/recipe-change-to-pending-approval-response';
import { RecipeChangeToPendingVerificationResponse } from '../models/recipe-change-to-pending-verification-response';
import { recipeEventsAddRowsPost$Json } from '../fn/recipe-events/recipe-events-add-rows-post-json';
import { RecipeEventsAddRowsPost$Json$Params } from '../fn/recipe-events/recipe-events-add-rows-post-json';
import { recipeEventsAddRowsPost$Plain } from '../fn/recipe-events/recipe-events-add-rows-post-plain';
import { RecipeEventsAddRowsPost$Plain$Params } from '../fn/recipe-events/recipe-events-add-rows-post-plain';
import { recipeEventsAddTemplatePost$Json } from '../fn/recipe-events/recipe-events-add-template-post-json';
import { RecipeEventsAddTemplatePost$Json$Params } from '../fn/recipe-events/recipe-events-add-template-post-json';
import { recipeEventsAddTemplatePost$Plain } from '../fn/recipe-events/recipe-events-add-template-post-plain';
import { RecipeEventsAddTemplatePost$Plain$Params } from '../fn/recipe-events/recipe-events-add-template-post-plain';
import { recipeEventsCancelRecipePost$Json } from '../fn/recipe-events/recipe-events-cancel-recipe-post-json';
import { RecipeEventsCancelRecipePost$Json$Params } from '../fn/recipe-events/recipe-events-cancel-recipe-post-json';
import { recipeEventsCancelRecipePost$Plain } from '../fn/recipe-events/recipe-events-cancel-recipe-post-plain';
import { RecipeEventsCancelRecipePost$Plain$Params } from '../fn/recipe-events/recipe-events-cancel-recipe-post-plain';
import { recipeEventsChangeAvailabilityPost$Json } from '../fn/recipe-events/recipe-events-change-availability-post-json';
import { RecipeEventsChangeAvailabilityPost$Json$Params } from '../fn/recipe-events/recipe-events-change-availability-post-json';
import { recipeEventsChangeAvailabilityPost$Plain } from '../fn/recipe-events/recipe-events-change-availability-post-plain';
import { RecipeEventsChangeAvailabilityPost$Plain$Params } from '../fn/recipe-events/recipe-events-change-availability-post-plain';
import { recipeEventsChangeCellsPost$Json } from '../fn/recipe-events/recipe-events-change-cells-post-json';
import { RecipeEventsChangeCellsPost$Json$Params } from '../fn/recipe-events/recipe-events-change-cells-post-json';
import { recipeEventsChangeCellsPost$Plain } from '../fn/recipe-events/recipe-events-change-cells-post-plain';
import { RecipeEventsChangeCellsPost$Plain$Params } from '../fn/recipe-events/recipe-events-change-cells-post-plain';
import { recipeEventsChangeFieldPost$Json } from '../fn/recipe-events/recipe-events-change-field-post-json';
import { RecipeEventsChangeFieldPost$Json$Params } from '../fn/recipe-events/recipe-events-change-field-post-json';
import { recipeEventsChangeFieldPost$Plain } from '../fn/recipe-events/recipe-events-change-field-post-plain';
import { RecipeEventsChangeFieldPost$Plain$Params } from '../fn/recipe-events/recipe-events-change-field-post-plain';
import { recipeEventsChangeNodeOrderPost$Json } from '../fn/recipe-events/recipe-events-change-node-order-post-json';
import { RecipeEventsChangeNodeOrderPost$Json$Params } from '../fn/recipe-events/recipe-events-change-node-order-post-json';
import { recipeEventsChangeNodeOrderPost$Plain } from '../fn/recipe-events/recipe-events-change-node-order-post-plain';
import { RecipeEventsChangeNodeOrderPost$Plain$Params } from '../fn/recipe-events/recipe-events-change-node-order-post-plain';
import { recipeEventsChangeNodeTitlePost$Json } from '../fn/recipe-events/recipe-events-change-node-title-post-json';
import { RecipeEventsChangeNodeTitlePost$Json$Params } from '../fn/recipe-events/recipe-events-change-node-title-post-json';
import { recipeEventsChangeNodeTitlePost$Plain } from '../fn/recipe-events/recipe-events-change-node-title-post-plain';
import { RecipeEventsChangeNodeTitlePost$Plain$Params } from '../fn/recipe-events/recipe-events-change-node-title-post-plain';
import { recipeEventsChangePreLoadDataTransformOptionsPost$Json } from '../fn/recipe-events/recipe-events-change-pre-load-data-transform-options-post-json';
import { RecipeEventsChangePreLoadDataTransformOptionsPost$Json$Params } from '../fn/recipe-events/recipe-events-change-pre-load-data-transform-options-post-json';
import { recipeEventsChangePreLoadDataTransformOptionsPost$Plain } from '../fn/recipe-events/recipe-events-change-pre-load-data-transform-options-post-plain';
import { RecipeEventsChangePreLoadDataTransformOptionsPost$Plain$Params } from '../fn/recipe-events/recipe-events-change-pre-load-data-transform-options-post-plain';
import { recipeEventsChangeRecipeAdditionalNotesPost$Json } from '../fn/recipe-events/recipe-events-change-recipe-additional-notes-post-json';
import { RecipeEventsChangeRecipeAdditionalNotesPost$Json$Params } from '../fn/recipe-events/recipe-events-change-recipe-additional-notes-post-json';
import { recipeEventsChangeRecipeAdditionalNotesPost$Plain } from '../fn/recipe-events/recipe-events-change-recipe-additional-notes-post-plain';
import { RecipeEventsChangeRecipeAdditionalNotesPost$Plain$Params } from '../fn/recipe-events/recipe-events-change-recipe-additional-notes-post-plain';
import { recipeEventsChangeRecipeAnalysisTechniquePost$Json } from '../fn/recipe-events/recipe-events-change-recipe-analysis-technique-post-json';
import { RecipeEventsChangeRecipeAnalysisTechniquePost$Json$Params } from '../fn/recipe-events/recipe-events-change-recipe-analysis-technique-post-json';
import { recipeEventsChangeRecipeAnalysisTechniquePost$Plain } from '../fn/recipe-events/recipe-events-change-recipe-analysis-technique-post-plain';
import { RecipeEventsChangeRecipeAnalysisTechniquePost$Plain$Params } from '../fn/recipe-events/recipe-events-change-recipe-analysis-technique-post-plain';
import { recipeEventsChangeRecipeApprovalRequiredPost$Json } from '../fn/recipe-events/recipe-events-change-recipe-approval-required-post-json';
import { RecipeEventsChangeRecipeApprovalRequiredPost$Json$Params } from '../fn/recipe-events/recipe-events-change-recipe-approval-required-post-json';
import { recipeEventsChangeRecipeApprovalRequiredPost$Plain } from '../fn/recipe-events/recipe-events-change-recipe-approval-required-post-plain';
import { RecipeEventsChangeRecipeApprovalRequiredPost$Plain$Params } from '../fn/recipe-events/recipe-events-change-recipe-approval-required-post-plain';
import { recipeEventsChangeRecipeApprovedPost$Json } from '../fn/recipe-events/recipe-events-change-recipe-approved-post-json';
import { RecipeEventsChangeRecipeApprovedPost$Json$Params } from '../fn/recipe-events/recipe-events-change-recipe-approved-post-json';
import { recipeEventsChangeRecipeApprovedPost$Plain } from '../fn/recipe-events/recipe-events-change-recipe-approved-post-plain';
import { RecipeEventsChangeRecipeApprovedPost$Plain$Params } from '../fn/recipe-events/recipe-events-change-recipe-approved-post-plain';
import { recipeEventsChangeRecipeApproverPost$Json } from '../fn/recipe-events/recipe-events-change-recipe-approver-post-json';
import { RecipeEventsChangeRecipeApproverPost$Json$Params } from '../fn/recipe-events/recipe-events-change-recipe-approver-post-json';
import { recipeEventsChangeRecipeApproverPost$Plain } from '../fn/recipe-events/recipe-events-change-recipe-approver-post-plain';
import { RecipeEventsChangeRecipeApproverPost$Plain$Params } from '../fn/recipe-events/recipe-events-change-recipe-approver-post-plain';
import { recipeEventsChangeRecipeClientPost$Json } from '../fn/recipe-events/recipe-events-change-recipe-client-post-json';
import { RecipeEventsChangeRecipeClientPost$Json$Params } from '../fn/recipe-events/recipe-events-change-recipe-client-post-json';
import { recipeEventsChangeRecipeClientPost$Plain } from '../fn/recipe-events/recipe-events-change-recipe-client-post-plain';
import { RecipeEventsChangeRecipeClientPost$Plain$Params } from '../fn/recipe-events/recipe-events-change-recipe-client-post-plain';
import { recipeEventsChangeRecipeCompendiaPost$Json } from '../fn/recipe-events/recipe-events-change-recipe-compendia-post-json';
import { RecipeEventsChangeRecipeCompendiaPost$Json$Params } from '../fn/recipe-events/recipe-events-change-recipe-compendia-post-json';
import { recipeEventsChangeRecipeCompendiaPost$Plain } from '../fn/recipe-events/recipe-events-change-recipe-compendia-post-plain';
import { RecipeEventsChangeRecipeCompendiaPost$Plain$Params } from '../fn/recipe-events/recipe-events-change-recipe-compendia-post-plain';
import { recipeEventsChangeRecipeConsumingLabsitePost$Json } from '../fn/recipe-events/recipe-events-change-recipe-consuming-labsite-post-json';
import { RecipeEventsChangeRecipeConsumingLabsitePost$Json$Params } from '../fn/recipe-events/recipe-events-change-recipe-consuming-labsite-post-json';
import { recipeEventsChangeRecipeConsumingLabsitePost$Plain } from '../fn/recipe-events/recipe-events-change-recipe-consuming-labsite-post-plain';
import { RecipeEventsChangeRecipeConsumingLabsitePost$Plain$Params } from '../fn/recipe-events/recipe-events-change-recipe-consuming-labsite-post-plain';
import { recipeEventsChangeRecipeEditorsPost$Json } from '../fn/recipe-events/recipe-events-change-recipe-editors-post-json';
import { RecipeEventsChangeRecipeEditorsPost$Json$Params } from '../fn/recipe-events/recipe-events-change-recipe-editors-post-json';
import { recipeEventsChangeRecipeEditorsPost$Plain } from '../fn/recipe-events/recipe-events-change-recipe-editors-post-plain';
import { RecipeEventsChangeRecipeEditorsPost$Plain$Params } from '../fn/recipe-events/recipe-events-change-recipe-editors-post-plain';
import { recipeEventsChangeRecipeMethodPost$Json } from '../fn/recipe-events/recipe-events-change-recipe-method-post-json';
import { RecipeEventsChangeRecipeMethodPost$Json$Params } from '../fn/recipe-events/recipe-events-change-recipe-method-post-json';
import { recipeEventsChangeRecipeMethodPost$Plain } from '../fn/recipe-events/recipe-events-change-recipe-method-post-plain';
import { RecipeEventsChangeRecipeMethodPost$Plain$Params } from '../fn/recipe-events/recipe-events-change-recipe-method-post-plain';
import { recipeEventsChangeRecipeNamePost$Json } from '../fn/recipe-events/recipe-events-change-recipe-name-post-json';
import { RecipeEventsChangeRecipeNamePost$Json$Params } from '../fn/recipe-events/recipe-events-change-recipe-name-post-json';
import { recipeEventsChangeRecipeNamePost$Plain } from '../fn/recipe-events/recipe-events-change-recipe-name-post-plain';
import { RecipeEventsChangeRecipeNamePost$Plain$Params } from '../fn/recipe-events/recipe-events-change-recipe-name-post-plain';
import { recipeEventsChangeRecipeProjectPost$Json } from '../fn/recipe-events/recipe-events-change-recipe-project-post-json';
import { RecipeEventsChangeRecipeProjectPost$Json$Params } from '../fn/recipe-events/recipe-events-change-recipe-project-post-json';
import { recipeEventsChangeRecipeProjectPost$Plain } from '../fn/recipe-events/recipe-events-change-recipe-project-post-plain';
import { RecipeEventsChangeRecipeProjectPost$Plain$Params } from '../fn/recipe-events/recipe-events-change-recipe-project-post-plain';
import { recipeEventsChangeRecipePurposePost$Json } from '../fn/recipe-events/recipe-events-change-recipe-purpose-post-json';
import { RecipeEventsChangeRecipePurposePost$Json$Params } from '../fn/recipe-events/recipe-events-change-recipe-purpose-post-json';
import { recipeEventsChangeRecipePurposePost$Plain } from '../fn/recipe-events/recipe-events-change-recipe-purpose-post-plain';
import { RecipeEventsChangeRecipePurposePost$Plain$Params } from '../fn/recipe-events/recipe-events-change-recipe-purpose-post-plain';
import { recipeEventsChangeRecipeReviewPost$Json } from '../fn/recipe-events/recipe-events-change-recipe-review-post-json';
import { RecipeEventsChangeRecipeReviewPost$Json$Params } from '../fn/recipe-events/recipe-events-change-recipe-review-post-json';
import { recipeEventsChangeRecipeReviewPost$Plain } from '../fn/recipe-events/recipe-events-change-recipe-review-post-plain';
import { RecipeEventsChangeRecipeReviewPost$Plain$Params } from '../fn/recipe-events/recipe-events-change-recipe-review-post-plain';
import { recipeEventsChangeRecipeSatisfiesPurposePost$Json } from '../fn/recipe-events/recipe-events-change-recipe-satisfies-purpose-post-json';
import { RecipeEventsChangeRecipeSatisfiesPurposePost$Json$Params } from '../fn/recipe-events/recipe-events-change-recipe-satisfies-purpose-post-json';
import { recipeEventsChangeRecipeSatisfiesPurposePost$Plain } from '../fn/recipe-events/recipe-events-change-recipe-satisfies-purpose-post-plain';
import { RecipeEventsChangeRecipeSatisfiesPurposePost$Plain$Params } from '../fn/recipe-events/recipe-events-change-recipe-satisfies-purpose-post-plain';
import { recipeEventsChangeRecipeSubBusinessUnitPost$Json } from '../fn/recipe-events/recipe-events-change-recipe-sub-business-unit-post-json';
import { RecipeEventsChangeRecipeSubBusinessUnitPost$Json$Params } from '../fn/recipe-events/recipe-events-change-recipe-sub-business-unit-post-json';
import { recipeEventsChangeRecipeSubBusinessUnitPost$Plain } from '../fn/recipe-events/recipe-events-change-recipe-sub-business-unit-post-plain';
import { RecipeEventsChangeRecipeSubBusinessUnitPost$Plain$Params } from '../fn/recipe-events/recipe-events-change-recipe-sub-business-unit-post-plain';
import { recipeEventsChangeRecipeTagPost$Json } from '../fn/recipe-events/recipe-events-change-recipe-tag-post-json';
import { RecipeEventsChangeRecipeTagPost$Json$Params } from '../fn/recipe-events/recipe-events-change-recipe-tag-post-json';
import { recipeEventsChangeRecipeTagPost$Plain } from '../fn/recipe-events/recipe-events-change-recipe-tag-post-plain';
import { RecipeEventsChangeRecipeTagPost$Plain$Params } from '../fn/recipe-events/recipe-events-change-recipe-tag-post-plain';
import { recipeEventsChangeRecipeTypePost$Json } from '../fn/recipe-events/recipe-events-change-recipe-type-post-json';
import { RecipeEventsChangeRecipeTypePost$Json$Params } from '../fn/recipe-events/recipe-events-change-recipe-type-post-json';
import { recipeEventsChangeRecipeTypePost$Plain } from '../fn/recipe-events/recipe-events-change-recipe-type-post-plain';
import { RecipeEventsChangeRecipeTypePost$Plain$Params } from '../fn/recipe-events/recipe-events-change-recipe-type-post-plain';
import { recipeEventsChangeRecipeVerifierPost$Json } from '../fn/recipe-events/recipe-events-change-recipe-verifier-post-json';
import { RecipeEventsChangeRecipeVerifierPost$Json$Params } from '../fn/recipe-events/recipe-events-change-recipe-verifier-post-json';
import { recipeEventsChangeRecipeVerifierPost$Plain } from '../fn/recipe-events/recipe-events-change-recipe-verifier-post-plain';
import { RecipeEventsChangeRecipeVerifierPost$Plain$Params } from '../fn/recipe-events/recipe-events-change-recipe-verifier-post-plain';
import { recipeEventsChangeVerifyRecipePost$Json } from '../fn/recipe-events/recipe-events-change-verify-recipe-post-json';
import { RecipeEventsChangeVerifyRecipePost$Json$Params } from '../fn/recipe-events/recipe-events-change-verify-recipe-post-json';
import { recipeEventsChangeVerifyRecipePost$Plain } from '../fn/recipe-events/recipe-events-change-verify-recipe-post-plain';
import { RecipeEventsChangeVerifyRecipePost$Plain$Params } from '../fn/recipe-events/recipe-events-change-verify-recipe-post-plain';
import { recipeEventsCopyRecipePost$Json } from '../fn/recipe-events/recipe-events-copy-recipe-post-json';
import { RecipeEventsCopyRecipePost$Json$Params } from '../fn/recipe-events/recipe-events-copy-recipe-post-json';
import { recipeEventsCopyRecipePost$Plain } from '../fn/recipe-events/recipe-events-copy-recipe-post-plain';
import { RecipeEventsCopyRecipePost$Plain$Params } from '../fn/recipe-events/recipe-events-copy-recipe-post-plain';
import { recipeEventsCreateRecipePost$Json } from '../fn/recipe-events/recipe-events-create-recipe-post-json';
import { RecipeEventsCreateRecipePost$Json$Params } from '../fn/recipe-events/recipe-events-create-recipe-post-json';
import { recipeEventsCreateRecipePost$Plain } from '../fn/recipe-events/recipe-events-create-recipe-post-plain';
import { RecipeEventsCreateRecipePost$Plain$Params } from '../fn/recipe-events/recipe-events-create-recipe-post-plain';
import { recipeEventsDeleteTemplatePost$Json } from '../fn/recipe-events/recipe-events-delete-template-post-json';
import { RecipeEventsDeleteTemplatePost$Json$Params } from '../fn/recipe-events/recipe-events-delete-template-post-json';
import { recipeEventsDeleteTemplatePost$Plain } from '../fn/recipe-events/recipe-events-delete-template-post-plain';
import { RecipeEventsDeleteTemplatePost$Plain$Params } from '../fn/recipe-events/recipe-events-delete-template-post-plain';
import { recipeEventsRecipeChangeToPendingapprovalPost$Json } from '../fn/recipe-events/recipe-events-recipe-change-to-pendingapproval-post-json';
import { RecipeEventsRecipeChangeToPendingapprovalPost$Json$Params } from '../fn/recipe-events/recipe-events-recipe-change-to-pendingapproval-post-json';
import { recipeEventsRecipeChangeToPendingapprovalPost$Plain } from '../fn/recipe-events/recipe-events-recipe-change-to-pendingapproval-post-plain';
import { RecipeEventsRecipeChangeToPendingapprovalPost$Plain$Params } from '../fn/recipe-events/recipe-events-recipe-change-to-pendingapproval-post-plain';
import { recipeEventsRecipeChangeToPendingverificationPost$Json } from '../fn/recipe-events/recipe-events-recipe-change-to-pendingverification-post-json';
import { RecipeEventsRecipeChangeToPendingverificationPost$Json$Params } from '../fn/recipe-events/recipe-events-recipe-change-to-pendingverification-post-json';
import { recipeEventsRecipeChangeToPendingverificationPost$Plain } from '../fn/recipe-events/recipe-events-recipe-change-to-pendingverification-post-plain';
import { RecipeEventsRecipeChangeToPendingverificationPost$Plain$Params } from '../fn/recipe-events/recipe-events-recipe-change-to-pendingverification-post-plain';
import { recipeEventsRecipePublishedPost$Json } from '../fn/recipe-events/recipe-events-recipe-published-post-json';
import { RecipeEventsRecipePublishedPost$Json$Params } from '../fn/recipe-events/recipe-events-recipe-published-post-json';
import { recipeEventsRecipePublishedPost$Plain } from '../fn/recipe-events/recipe-events-recipe-published-post-plain';
import { RecipeEventsRecipePublishedPost$Plain$Params } from '../fn/recipe-events/recipe-events-recipe-published-post-plain';
import { recipeEventsRecipeReturnToIndraftPost$Json } from '../fn/recipe-events/recipe-events-recipe-return-to-indraft-post-json';
import { RecipeEventsRecipeReturnToIndraftPost$Json$Params } from '../fn/recipe-events/recipe-events-recipe-return-to-indraft-post-json';
import { recipeEventsRecipeReturnToIndraftPost$Plain } from '../fn/recipe-events/recipe-events-recipe-return-to-indraft-post-plain';
import { RecipeEventsRecipeReturnToIndraftPost$Plain$Params } from '../fn/recipe-events/recipe-events-recipe-return-to-indraft-post-plain';
import { recipeEventsRemoveRowsPost$Json } from '../fn/recipe-events/recipe-events-remove-rows-post-json';
import { RecipeEventsRemoveRowsPost$Json$Params } from '../fn/recipe-events/recipe-events-remove-rows-post-json';
import { recipeEventsRemoveRowsPost$Plain } from '../fn/recipe-events/recipe-events-remove-rows-post-plain';
import { RecipeEventsRemoveRowsPost$Plain$Params } from '../fn/recipe-events/recipe-events-remove-rows-post-plain';
import { recipeEventsRenumberRowsPost$Json } from '../fn/recipe-events/recipe-events-renumber-rows-post-json';
import { RecipeEventsRenumberRowsPost$Json$Params } from '../fn/recipe-events/recipe-events-renumber-rows-post-json';
import { recipeEventsRenumberRowsPost$Plain } from '../fn/recipe-events/recipe-events-renumber-rows-post-plain';
import { RecipeEventsRenumberRowsPost$Plain$Params } from '../fn/recipe-events/recipe-events-renumber-rows-post-plain';
import { recipeEventsRestoreRecipePost$Json } from '../fn/recipe-events/recipe-events-restore-recipe-post-json';
import { RecipeEventsRestoreRecipePost$Json$Params } from '../fn/recipe-events/recipe-events-restore-recipe-post-json';
import { recipeEventsRestoreRecipePost$Plain } from '../fn/recipe-events/recipe-events-restore-recipe-post-plain';
import { RecipeEventsRestoreRecipePost$Plain$Params } from '../fn/recipe-events/recipe-events-restore-recipe-post-plain';
import { recipeEventsRestoreRowsPost$Json } from '../fn/recipe-events/recipe-events-restore-rows-post-json';
import { RecipeEventsRestoreRowsPost$Json$Params } from '../fn/recipe-events/recipe-events-restore-rows-post-json';
import { recipeEventsRestoreRowsPost$Plain } from '../fn/recipe-events/recipe-events-restore-rows-post-plain';
import { RecipeEventsRestoreRowsPost$Plain$Params } from '../fn/recipe-events/recipe-events-restore-rows-post-plain';
import { recipeEventsRetireRecipePost$Json } from '../fn/recipe-events/recipe-events-retire-recipe-post-json';
import { RecipeEventsRetireRecipePost$Json$Params } from '../fn/recipe-events/recipe-events-retire-recipe-post-json';
import { recipeEventsRetireRecipePost$Plain } from '../fn/recipe-events/recipe-events-retire-recipe-post-plain';
import { RecipeEventsRetireRecipePost$Plain$Params } from '../fn/recipe-events/recipe-events-retire-recipe-post-plain';
import { recipeEventsSetVariablePost$Json } from '../fn/recipe-events/recipe-events-set-variable-post-json';
import { RecipeEventsSetVariablePost$Json$Params } from '../fn/recipe-events/recipe-events-set-variable-post-json';
import { recipeEventsSetVariablePost$Plain } from '../fn/recipe-events/recipe-events-set-variable-post-plain';
import { RecipeEventsSetVariablePost$Plain$Params } from '../fn/recipe-events/recipe-events-set-variable-post-plain';
import { recipeEventsStartNewPost$Json } from '../fn/recipe-events/recipe-events-start-new-post-json';
import { RecipeEventsStartNewPost$Json$Params } from '../fn/recipe-events/recipe-events-start-new-post-json';
import { recipeEventsStartNewPost$Plain } from '../fn/recipe-events/recipe-events-start-new-post-plain';
import { RecipeEventsStartNewPost$Plain$Params } from '../fn/recipe-events/recipe-events-start-new-post-plain';
import { RecipeNodeOrderChangedResponse } from '../models/recipe-node-order-changed-response';
import { RecipeNodeTitleChangedResponse } from '../models/recipe-node-title-changed-response';
import { RecipePublishedResponse } from '../models/recipe-published-response';
import { RecipeReturnToInDraftResponse } from '../models/recipe-return-to-in-draft-response';
import { RecipeTemplateAddedResponse } from '../models/recipe-template-added-response';
import { RecipeTemplateDeletedResponse } from '../models/recipe-template-deleted-response';
import { RemoveRecipeRowResponse } from '../models/remove-recipe-row-response';
import { RenumberRecipeRowsResponse } from '../models/renumber-recipe-rows-response';
import { RestoreRecipeResponse } from '../models/restore-recipe-response';
import { RestoreRecipeRowResponse } from '../models/restore-recipe-row-response';
import { RetireRecipeResponse } from '../models/retire-recipe-response';
import { SetVariableResponse } from '../models/set-variable-response';
import { StartNewVersionResponse } from '../models/start-new-version-response';

@Injectable({ providedIn: 'root' })
export class RecipeEventsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `recipeEventsCreateRecipePost()` */
  static readonly RecipeEventsCreateRecipePostPath = '/recipe-events/create-recipe';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsCreateRecipePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsCreateRecipePost$Plain$Response(params?: RecipeEventsCreateRecipePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateRecipeResponse>> {
    return recipeEventsCreateRecipePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsCreateRecipePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsCreateRecipePost$Plain(params?: RecipeEventsCreateRecipePost$Plain$Params, context?: HttpContext): Observable<CreateRecipeResponse> {
    return this.recipeEventsCreateRecipePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateRecipeResponse>): CreateRecipeResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsCreateRecipePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsCreateRecipePost$Json$Response(params?: RecipeEventsCreateRecipePost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateRecipeResponse>> {
    return recipeEventsCreateRecipePost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsCreateRecipePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsCreateRecipePost$Json(params?: RecipeEventsCreateRecipePost$Json$Params, context?: HttpContext): Observable<CreateRecipeResponse> {
    return this.recipeEventsCreateRecipePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateRecipeResponse>): CreateRecipeResponse => r.body)
    );
  }

  /** Path part for operation `recipeEventsChangeRecipeNamePost()` */
  static readonly RecipeEventsChangeRecipeNamePostPath = '/recipe-events/change-recipe-name';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeRecipeNamePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeNamePost$Plain$Response(params?: RecipeEventsChangeRecipeNamePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangeRecipeNameResponse>> {
    return recipeEventsChangeRecipeNamePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeRecipeNamePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeNamePost$Plain(params?: RecipeEventsChangeRecipeNamePost$Plain$Params, context?: HttpContext): Observable<ChangeRecipeNameResponse> {
    return this.recipeEventsChangeRecipeNamePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangeRecipeNameResponse>): ChangeRecipeNameResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeRecipeNamePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeNamePost$Json$Response(params?: RecipeEventsChangeRecipeNamePost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangeRecipeNameResponse>> {
    return recipeEventsChangeRecipeNamePost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeRecipeNamePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeNamePost$Json(params?: RecipeEventsChangeRecipeNamePost$Json$Params, context?: HttpContext): Observable<ChangeRecipeNameResponse> {
    return this.recipeEventsChangeRecipeNamePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangeRecipeNameResponse>): ChangeRecipeNameResponse => r.body)
    );
  }

  /** Path part for operation `recipeEventsChangeRecipeEditorsPost()` */
  static readonly RecipeEventsChangeRecipeEditorsPostPath = '/recipe-events/change-recipe-editors';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeRecipeEditorsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeEditorsPost$Plain$Response(params?: RecipeEventsChangeRecipeEditorsPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangeRecipeEditorsResponse>> {
    return recipeEventsChangeRecipeEditorsPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeRecipeEditorsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeEditorsPost$Plain(params?: RecipeEventsChangeRecipeEditorsPost$Plain$Params, context?: HttpContext): Observable<ChangeRecipeEditorsResponse> {
    return this.recipeEventsChangeRecipeEditorsPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangeRecipeEditorsResponse>): ChangeRecipeEditorsResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeRecipeEditorsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeEditorsPost$Json$Response(params?: RecipeEventsChangeRecipeEditorsPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangeRecipeEditorsResponse>> {
    return recipeEventsChangeRecipeEditorsPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeRecipeEditorsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeEditorsPost$Json(params?: RecipeEventsChangeRecipeEditorsPost$Json$Params, context?: HttpContext): Observable<ChangeRecipeEditorsResponse> {
    return this.recipeEventsChangeRecipeEditorsPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangeRecipeEditorsResponse>): ChangeRecipeEditorsResponse => r.body)
    );
  }

  /** Path part for operation `recipeEventsCancelRecipePost()` */
  static readonly RecipeEventsCancelRecipePostPath = '/recipe-events/cancel-recipe';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsCancelRecipePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsCancelRecipePost$Plain$Response(params?: RecipeEventsCancelRecipePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CancelRecipeResponse>> {
    return recipeEventsCancelRecipePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsCancelRecipePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsCancelRecipePost$Plain(params?: RecipeEventsCancelRecipePost$Plain$Params, context?: HttpContext): Observable<CancelRecipeResponse> {
    return this.recipeEventsCancelRecipePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CancelRecipeResponse>): CancelRecipeResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsCancelRecipePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsCancelRecipePost$Json$Response(params?: RecipeEventsCancelRecipePost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<CancelRecipeResponse>> {
    return recipeEventsCancelRecipePost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsCancelRecipePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsCancelRecipePost$Json(params?: RecipeEventsCancelRecipePost$Json$Params, context?: HttpContext): Observable<CancelRecipeResponse> {
    return this.recipeEventsCancelRecipePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CancelRecipeResponse>): CancelRecipeResponse => r.body)
    );
  }

  /** Path part for operation `recipeEventsRestoreRecipePost()` */
  static readonly RecipeEventsRestoreRecipePostPath = '/recipe-events/restore-recipe';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsRestoreRecipePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsRestoreRecipePost$Plain$Response(params?: RecipeEventsRestoreRecipePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<RestoreRecipeResponse>> {
    return recipeEventsRestoreRecipePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsRestoreRecipePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsRestoreRecipePost$Plain(params?: RecipeEventsRestoreRecipePost$Plain$Params, context?: HttpContext): Observable<RestoreRecipeResponse> {
    return this.recipeEventsRestoreRecipePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<RestoreRecipeResponse>): RestoreRecipeResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsRestoreRecipePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsRestoreRecipePost$Json$Response(params?: RecipeEventsRestoreRecipePost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<RestoreRecipeResponse>> {
    return recipeEventsRestoreRecipePost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsRestoreRecipePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsRestoreRecipePost$Json(params?: RecipeEventsRestoreRecipePost$Json$Params, context?: HttpContext): Observable<RestoreRecipeResponse> {
    return this.recipeEventsRestoreRecipePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<RestoreRecipeResponse>): RestoreRecipeResponse => r.body)
    );
  }

  /** Path part for operation `recipeEventsChangeRecipeSatisfiesPurposePost()` */
  static readonly RecipeEventsChangeRecipeSatisfiesPurposePostPath = '/recipe-events/change-recipe-satisfies-purpose';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeRecipeSatisfiesPurposePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeSatisfiesPurposePost$Plain$Response(params?: RecipeEventsChangeRecipeSatisfiesPurposePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangeRecipeSatisfiesPurposeResponse>> {
    return recipeEventsChangeRecipeSatisfiesPurposePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeRecipeSatisfiesPurposePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeSatisfiesPurposePost$Plain(params?: RecipeEventsChangeRecipeSatisfiesPurposePost$Plain$Params, context?: HttpContext): Observable<ChangeRecipeSatisfiesPurposeResponse> {
    return this.recipeEventsChangeRecipeSatisfiesPurposePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangeRecipeSatisfiesPurposeResponse>): ChangeRecipeSatisfiesPurposeResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeRecipeSatisfiesPurposePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeSatisfiesPurposePost$Json$Response(params?: RecipeEventsChangeRecipeSatisfiesPurposePost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangeRecipeSatisfiesPurposeResponse>> {
    return recipeEventsChangeRecipeSatisfiesPurposePost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeRecipeSatisfiesPurposePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeSatisfiesPurposePost$Json(params?: RecipeEventsChangeRecipeSatisfiesPurposePost$Json$Params, context?: HttpContext): Observable<ChangeRecipeSatisfiesPurposeResponse> {
    return this.recipeEventsChangeRecipeSatisfiesPurposePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangeRecipeSatisfiesPurposeResponse>): ChangeRecipeSatisfiesPurposeResponse => r.body)
    );
  }

  /** Path part for operation `recipeEventsChangeRecipeReviewPost()` */
  static readonly RecipeEventsChangeRecipeReviewPostPath = '/recipe-events/change-recipe-review';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeRecipeReviewPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeReviewPost$Plain$Response(params?: RecipeEventsChangeRecipeReviewPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangeRecipeReviewStatusResponse>> {
    return recipeEventsChangeRecipeReviewPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeRecipeReviewPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeReviewPost$Plain(params?: RecipeEventsChangeRecipeReviewPost$Plain$Params, context?: HttpContext): Observable<ChangeRecipeReviewStatusResponse> {
    return this.recipeEventsChangeRecipeReviewPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangeRecipeReviewStatusResponse>): ChangeRecipeReviewStatusResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeRecipeReviewPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeReviewPost$Json$Response(params?: RecipeEventsChangeRecipeReviewPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangeRecipeReviewStatusResponse>> {
    return recipeEventsChangeRecipeReviewPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeRecipeReviewPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeReviewPost$Json(params?: RecipeEventsChangeRecipeReviewPost$Json$Params, context?: HttpContext): Observable<ChangeRecipeReviewStatusResponse> {
    return this.recipeEventsChangeRecipeReviewPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangeRecipeReviewStatusResponse>): ChangeRecipeReviewStatusResponse => r.body)
    );
  }

  /** Path part for operation `recipeEventsChangeVerifyRecipePost()` */
  static readonly RecipeEventsChangeVerifyRecipePostPath = '/recipe-events/change-verify-recipe';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeVerifyRecipePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeVerifyRecipePost$Plain$Response(params?: RecipeEventsChangeVerifyRecipePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangeVerifiedRecipeResponse>> {
    return recipeEventsChangeVerifyRecipePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeVerifyRecipePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeVerifyRecipePost$Plain(params?: RecipeEventsChangeVerifyRecipePost$Plain$Params, context?: HttpContext): Observable<ChangeVerifiedRecipeResponse> {
    return this.recipeEventsChangeVerifyRecipePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangeVerifiedRecipeResponse>): ChangeVerifiedRecipeResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeVerifyRecipePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeVerifyRecipePost$Json$Response(params?: RecipeEventsChangeVerifyRecipePost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangeVerifiedRecipeResponse>> {
    return recipeEventsChangeVerifyRecipePost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeVerifyRecipePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeVerifyRecipePost$Json(params?: RecipeEventsChangeVerifyRecipePost$Json$Params, context?: HttpContext): Observable<ChangeVerifiedRecipeResponse> {
    return this.recipeEventsChangeVerifyRecipePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangeVerifiedRecipeResponse>): ChangeVerifiedRecipeResponse => r.body)
    );
  }

  /** Path part for operation `recipeEventsChangeRecipeApprovalRequiredPost()` */
  static readonly RecipeEventsChangeRecipeApprovalRequiredPostPath = '/recipe-events/change-recipe-approval-required';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeRecipeApprovalRequiredPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeApprovalRequiredPost$Plain$Response(params?: RecipeEventsChangeRecipeApprovalRequiredPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangeRecipeApprovalRequiredResponse>> {
    return recipeEventsChangeRecipeApprovalRequiredPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeRecipeApprovalRequiredPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeApprovalRequiredPost$Plain(params?: RecipeEventsChangeRecipeApprovalRequiredPost$Plain$Params, context?: HttpContext): Observable<ChangeRecipeApprovalRequiredResponse> {
    return this.recipeEventsChangeRecipeApprovalRequiredPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangeRecipeApprovalRequiredResponse>): ChangeRecipeApprovalRequiredResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeRecipeApprovalRequiredPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeApprovalRequiredPost$Json$Response(params?: RecipeEventsChangeRecipeApprovalRequiredPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangeRecipeApprovalRequiredResponse>> {
    return recipeEventsChangeRecipeApprovalRequiredPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeRecipeApprovalRequiredPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeApprovalRequiredPost$Json(params?: RecipeEventsChangeRecipeApprovalRequiredPost$Json$Params, context?: HttpContext): Observable<ChangeRecipeApprovalRequiredResponse> {
    return this.recipeEventsChangeRecipeApprovalRequiredPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangeRecipeApprovalRequiredResponse>): ChangeRecipeApprovalRequiredResponse => r.body)
    );
  }

  /** Path part for operation `recipeEventsChangeRecipePurposePost()` */
  static readonly RecipeEventsChangeRecipePurposePostPath = '/recipe-events/change-recipe-purpose';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeRecipePurposePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipePurposePost$Plain$Response(params?: RecipeEventsChangeRecipePurposePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangeRecipePurposeResponse>> {
    return recipeEventsChangeRecipePurposePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeRecipePurposePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipePurposePost$Plain(params?: RecipeEventsChangeRecipePurposePost$Plain$Params, context?: HttpContext): Observable<ChangeRecipePurposeResponse> {
    return this.recipeEventsChangeRecipePurposePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangeRecipePurposeResponse>): ChangeRecipePurposeResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeRecipePurposePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipePurposePost$Json$Response(params?: RecipeEventsChangeRecipePurposePost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangeRecipePurposeResponse>> {
    return recipeEventsChangeRecipePurposePost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeRecipePurposePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipePurposePost$Json(params?: RecipeEventsChangeRecipePurposePost$Json$Params, context?: HttpContext): Observable<ChangeRecipePurposeResponse> {
    return this.recipeEventsChangeRecipePurposePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangeRecipePurposeResponse>): ChangeRecipePurposeResponse => r.body)
    );
  }

  /** Path part for operation `recipeEventsChangeRecipeAdditionalNotesPost()` */
  static readonly RecipeEventsChangeRecipeAdditionalNotesPostPath = '/recipe-events/change-recipe-additional-notes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeRecipeAdditionalNotesPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeAdditionalNotesPost$Plain$Response(params?: RecipeEventsChangeRecipeAdditionalNotesPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangeRecipeAdditionalNotesResponse>> {
    return recipeEventsChangeRecipeAdditionalNotesPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeRecipeAdditionalNotesPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeAdditionalNotesPost$Plain(params?: RecipeEventsChangeRecipeAdditionalNotesPost$Plain$Params, context?: HttpContext): Observable<ChangeRecipeAdditionalNotesResponse> {
    return this.recipeEventsChangeRecipeAdditionalNotesPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangeRecipeAdditionalNotesResponse>): ChangeRecipeAdditionalNotesResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeRecipeAdditionalNotesPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeAdditionalNotesPost$Json$Response(params?: RecipeEventsChangeRecipeAdditionalNotesPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangeRecipeAdditionalNotesResponse>> {
    return recipeEventsChangeRecipeAdditionalNotesPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeRecipeAdditionalNotesPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeAdditionalNotesPost$Json(params?: RecipeEventsChangeRecipeAdditionalNotesPost$Json$Params, context?: HttpContext): Observable<ChangeRecipeAdditionalNotesResponse> {
    return this.recipeEventsChangeRecipeAdditionalNotesPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangeRecipeAdditionalNotesResponse>): ChangeRecipeAdditionalNotesResponse => r.body)
    );
  }

  /** Path part for operation `recipeEventsChangeRecipeMethodPost()` */
  static readonly RecipeEventsChangeRecipeMethodPostPath = '/recipe-events/change-recipe-method';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeRecipeMethodPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeMethodPost$Plain$Response(params?: RecipeEventsChangeRecipeMethodPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangeRecipeMethodResponse>> {
    return recipeEventsChangeRecipeMethodPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeRecipeMethodPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeMethodPost$Plain(params?: RecipeEventsChangeRecipeMethodPost$Plain$Params, context?: HttpContext): Observable<ChangeRecipeMethodResponse> {
    return this.recipeEventsChangeRecipeMethodPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangeRecipeMethodResponse>): ChangeRecipeMethodResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeRecipeMethodPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeMethodPost$Json$Response(params?: RecipeEventsChangeRecipeMethodPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangeRecipeMethodResponse>> {
    return recipeEventsChangeRecipeMethodPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeRecipeMethodPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeMethodPost$Json(params?: RecipeEventsChangeRecipeMethodPost$Json$Params, context?: HttpContext): Observable<ChangeRecipeMethodResponse> {
    return this.recipeEventsChangeRecipeMethodPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangeRecipeMethodResponse>): ChangeRecipeMethodResponse => r.body)
    );
  }

  /** Path part for operation `recipeEventsChangeRecipeAnalysisTechniquePost()` */
  static readonly RecipeEventsChangeRecipeAnalysisTechniquePostPath = '/recipe-events/change-recipe-analysis-technique';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeRecipeAnalysisTechniquePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeAnalysisTechniquePost$Plain$Response(params?: RecipeEventsChangeRecipeAnalysisTechniquePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangeRecipeAnalysisTechniqueResponse>> {
    return recipeEventsChangeRecipeAnalysisTechniquePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeRecipeAnalysisTechniquePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeAnalysisTechniquePost$Plain(params?: RecipeEventsChangeRecipeAnalysisTechniquePost$Plain$Params, context?: HttpContext): Observable<ChangeRecipeAnalysisTechniqueResponse> {
    return this.recipeEventsChangeRecipeAnalysisTechniquePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangeRecipeAnalysisTechniqueResponse>): ChangeRecipeAnalysisTechniqueResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeRecipeAnalysisTechniquePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeAnalysisTechniquePost$Json$Response(params?: RecipeEventsChangeRecipeAnalysisTechniquePost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangeRecipeAnalysisTechniqueResponse>> {
    return recipeEventsChangeRecipeAnalysisTechniquePost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeRecipeAnalysisTechniquePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeAnalysisTechniquePost$Json(params?: RecipeEventsChangeRecipeAnalysisTechniquePost$Json$Params, context?: HttpContext): Observable<ChangeRecipeAnalysisTechniqueResponse> {
    return this.recipeEventsChangeRecipeAnalysisTechniquePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangeRecipeAnalysisTechniqueResponse>): ChangeRecipeAnalysisTechniqueResponse => r.body)
    );
  }

  /** Path part for operation `recipeEventsChangeRecipeVerifierPost()` */
  static readonly RecipeEventsChangeRecipeVerifierPostPath = '/recipe-events/change-recipe-verifier';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeRecipeVerifierPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeVerifierPost$Plain$Response(params?: RecipeEventsChangeRecipeVerifierPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangeRecipeVerifiersResponse>> {
    return recipeEventsChangeRecipeVerifierPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeRecipeVerifierPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeVerifierPost$Plain(params?: RecipeEventsChangeRecipeVerifierPost$Plain$Params, context?: HttpContext): Observable<ChangeRecipeVerifiersResponse> {
    return this.recipeEventsChangeRecipeVerifierPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangeRecipeVerifiersResponse>): ChangeRecipeVerifiersResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeRecipeVerifierPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeVerifierPost$Json$Response(params?: RecipeEventsChangeRecipeVerifierPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangeRecipeVerifiersResponse>> {
    return recipeEventsChangeRecipeVerifierPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeRecipeVerifierPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeVerifierPost$Json(params?: RecipeEventsChangeRecipeVerifierPost$Json$Params, context?: HttpContext): Observable<ChangeRecipeVerifiersResponse> {
    return this.recipeEventsChangeRecipeVerifierPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangeRecipeVerifiersResponse>): ChangeRecipeVerifiersResponse => r.body)
    );
  }

  /** Path part for operation `recipeEventsChangeRecipeApproverPost()` */
  static readonly RecipeEventsChangeRecipeApproverPostPath = '/recipe-events/change-recipe-approver';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeRecipeApproverPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeApproverPost$Plain$Response(params?: RecipeEventsChangeRecipeApproverPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangeRecipeApproversResponse>> {
    return recipeEventsChangeRecipeApproverPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeRecipeApproverPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeApproverPost$Plain(params?: RecipeEventsChangeRecipeApproverPost$Plain$Params, context?: HttpContext): Observable<ChangeRecipeApproversResponse> {
    return this.recipeEventsChangeRecipeApproverPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangeRecipeApproversResponse>): ChangeRecipeApproversResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeRecipeApproverPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeApproverPost$Json$Response(params?: RecipeEventsChangeRecipeApproverPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangeRecipeApproversResponse>> {
    return recipeEventsChangeRecipeApproverPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeRecipeApproverPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeApproverPost$Json(params?: RecipeEventsChangeRecipeApproverPost$Json$Params, context?: HttpContext): Observable<ChangeRecipeApproversResponse> {
    return this.recipeEventsChangeRecipeApproverPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangeRecipeApproversResponse>): ChangeRecipeApproversResponse => r.body)
    );
  }

  /** Path part for operation `recipeEventsChangeRecipeClientPost()` */
  static readonly RecipeEventsChangeRecipeClientPostPath = '/recipe-events/change-recipe-client';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeRecipeClientPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeClientPost$Plain$Response(params?: RecipeEventsChangeRecipeClientPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangeRecipeClientsResponse>> {
    return recipeEventsChangeRecipeClientPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeRecipeClientPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeClientPost$Plain(params?: RecipeEventsChangeRecipeClientPost$Plain$Params, context?: HttpContext): Observable<ChangeRecipeClientsResponse> {
    return this.recipeEventsChangeRecipeClientPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangeRecipeClientsResponse>): ChangeRecipeClientsResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeRecipeClientPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeClientPost$Json$Response(params?: RecipeEventsChangeRecipeClientPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangeRecipeClientsResponse>> {
    return recipeEventsChangeRecipeClientPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeRecipeClientPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeClientPost$Json(params?: RecipeEventsChangeRecipeClientPost$Json$Params, context?: HttpContext): Observable<ChangeRecipeClientsResponse> {
    return this.recipeEventsChangeRecipeClientPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangeRecipeClientsResponse>): ChangeRecipeClientsResponse => r.body)
    );
  }

  /** Path part for operation `recipeEventsChangeRecipeProjectPost()` */
  static readonly RecipeEventsChangeRecipeProjectPostPath = '/recipe-events/change-recipe-project';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeRecipeProjectPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeProjectPost$Plain$Response(params?: RecipeEventsChangeRecipeProjectPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangeRecipeProjectsResponse>> {
    return recipeEventsChangeRecipeProjectPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeRecipeProjectPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeProjectPost$Plain(params?: RecipeEventsChangeRecipeProjectPost$Plain$Params, context?: HttpContext): Observable<ChangeRecipeProjectsResponse> {
    return this.recipeEventsChangeRecipeProjectPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangeRecipeProjectsResponse>): ChangeRecipeProjectsResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeRecipeProjectPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeProjectPost$Json$Response(params?: RecipeEventsChangeRecipeProjectPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangeRecipeProjectsResponse>> {
    return recipeEventsChangeRecipeProjectPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeRecipeProjectPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeProjectPost$Json(params?: RecipeEventsChangeRecipeProjectPost$Json$Params, context?: HttpContext): Observable<ChangeRecipeProjectsResponse> {
    return this.recipeEventsChangeRecipeProjectPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangeRecipeProjectsResponse>): ChangeRecipeProjectsResponse => r.body)
    );
  }

  /** Path part for operation `recipeEventsChangeRecipeCompendiaPost()` */
  static readonly RecipeEventsChangeRecipeCompendiaPostPath = '/recipe-events/change-recipe-compendia';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeRecipeCompendiaPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeCompendiaPost$Plain$Response(params?: RecipeEventsChangeRecipeCompendiaPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangeRecipeCompendiaResponse>> {
    return recipeEventsChangeRecipeCompendiaPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeRecipeCompendiaPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeCompendiaPost$Plain(params?: RecipeEventsChangeRecipeCompendiaPost$Plain$Params, context?: HttpContext): Observable<ChangeRecipeCompendiaResponse> {
    return this.recipeEventsChangeRecipeCompendiaPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangeRecipeCompendiaResponse>): ChangeRecipeCompendiaResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeRecipeCompendiaPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeCompendiaPost$Json$Response(params?: RecipeEventsChangeRecipeCompendiaPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangeRecipeCompendiaResponse>> {
    return recipeEventsChangeRecipeCompendiaPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeRecipeCompendiaPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeCompendiaPost$Json(params?: RecipeEventsChangeRecipeCompendiaPost$Json$Params, context?: HttpContext): Observable<ChangeRecipeCompendiaResponse> {
    return this.recipeEventsChangeRecipeCompendiaPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangeRecipeCompendiaResponse>): ChangeRecipeCompendiaResponse => r.body)
    );
  }

  /** Path part for operation `recipeEventsChangeRecipeTagPost()` */
  static readonly RecipeEventsChangeRecipeTagPostPath = '/recipe-events/change-recipe-tag';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeRecipeTagPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeTagPost$Plain$Response(params?: RecipeEventsChangeRecipeTagPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangeRecipeTagsResponse>> {
    return recipeEventsChangeRecipeTagPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeRecipeTagPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeTagPost$Plain(params?: RecipeEventsChangeRecipeTagPost$Plain$Params, context?: HttpContext): Observable<ChangeRecipeTagsResponse> {
    return this.recipeEventsChangeRecipeTagPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangeRecipeTagsResponse>): ChangeRecipeTagsResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeRecipeTagPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeTagPost$Json$Response(params?: RecipeEventsChangeRecipeTagPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangeRecipeTagsResponse>> {
    return recipeEventsChangeRecipeTagPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeRecipeTagPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeTagPost$Json(params?: RecipeEventsChangeRecipeTagPost$Json$Params, context?: HttpContext): Observable<ChangeRecipeTagsResponse> {
    return this.recipeEventsChangeRecipeTagPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangeRecipeTagsResponse>): ChangeRecipeTagsResponse => r.body)
    );
  }

  /** Path part for operation `recipeEventsChangeRecipeSubBusinessUnitPost()` */
  static readonly RecipeEventsChangeRecipeSubBusinessUnitPostPath = '/recipe-events/change-recipe-sub-business-unit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeRecipeSubBusinessUnitPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeSubBusinessUnitPost$Plain$Response(params?: RecipeEventsChangeRecipeSubBusinessUnitPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangeRecipeSubBusinessUnitsResponse>> {
    return recipeEventsChangeRecipeSubBusinessUnitPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeRecipeSubBusinessUnitPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeSubBusinessUnitPost$Plain(params?: RecipeEventsChangeRecipeSubBusinessUnitPost$Plain$Params, context?: HttpContext): Observable<ChangeRecipeSubBusinessUnitsResponse> {
    return this.recipeEventsChangeRecipeSubBusinessUnitPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangeRecipeSubBusinessUnitsResponse>): ChangeRecipeSubBusinessUnitsResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeRecipeSubBusinessUnitPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeSubBusinessUnitPost$Json$Response(params?: RecipeEventsChangeRecipeSubBusinessUnitPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangeRecipeSubBusinessUnitsResponse>> {
    return recipeEventsChangeRecipeSubBusinessUnitPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeRecipeSubBusinessUnitPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeSubBusinessUnitPost$Json(params?: RecipeEventsChangeRecipeSubBusinessUnitPost$Json$Params, context?: HttpContext): Observable<ChangeRecipeSubBusinessUnitsResponse> {
    return this.recipeEventsChangeRecipeSubBusinessUnitPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangeRecipeSubBusinessUnitsResponse>): ChangeRecipeSubBusinessUnitsResponse => r.body)
    );
  }

  /** Path part for operation `recipeEventsChangeRecipeApprovedPost()` */
  static readonly RecipeEventsChangeRecipeApprovedPostPath = '/recipe-events/change-recipe-approved';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeRecipeApprovedPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeApprovedPost$Plain$Response(params?: RecipeEventsChangeRecipeApprovedPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangeRecipeApprovedResponse>> {
    return recipeEventsChangeRecipeApprovedPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeRecipeApprovedPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeApprovedPost$Plain(params?: RecipeEventsChangeRecipeApprovedPost$Plain$Params, context?: HttpContext): Observable<ChangeRecipeApprovedResponse> {
    return this.recipeEventsChangeRecipeApprovedPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangeRecipeApprovedResponse>): ChangeRecipeApprovedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeRecipeApprovedPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeApprovedPost$Json$Response(params?: RecipeEventsChangeRecipeApprovedPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangeRecipeApprovedResponse>> {
    return recipeEventsChangeRecipeApprovedPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeRecipeApprovedPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeApprovedPost$Json(params?: RecipeEventsChangeRecipeApprovedPost$Json$Params, context?: HttpContext): Observable<ChangeRecipeApprovedResponse> {
    return this.recipeEventsChangeRecipeApprovedPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangeRecipeApprovedResponse>): ChangeRecipeApprovedResponse => r.body)
    );
  }

  /** Path part for operation `recipeEventsCopyRecipePost()` */
  static readonly RecipeEventsCopyRecipePostPath = '/recipe-events/copy-recipe';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsCopyRecipePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsCopyRecipePost$Plain$Response(params?: RecipeEventsCopyRecipePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CopiedRecipeResponse>> {
    return recipeEventsCopyRecipePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsCopyRecipePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsCopyRecipePost$Plain(params?: RecipeEventsCopyRecipePost$Plain$Params, context?: HttpContext): Observable<CopiedRecipeResponse> {
    return this.recipeEventsCopyRecipePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CopiedRecipeResponse>): CopiedRecipeResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsCopyRecipePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsCopyRecipePost$Json$Response(params?: RecipeEventsCopyRecipePost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<CopiedRecipeResponse>> {
    return recipeEventsCopyRecipePost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsCopyRecipePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsCopyRecipePost$Json(params?: RecipeEventsCopyRecipePost$Json$Params, context?: HttpContext): Observable<CopiedRecipeResponse> {
    return this.recipeEventsCopyRecipePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CopiedRecipeResponse>): CopiedRecipeResponse => r.body)
    );
  }

  /** Path part for operation `recipeEventsChangeRecipeConsumingLabsitePost()` */
  static readonly RecipeEventsChangeRecipeConsumingLabsitePostPath = '/recipe-events/change-recipe-consuming-labsite';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeRecipeConsumingLabsitePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeConsumingLabsitePost$Plain$Response(params?: RecipeEventsChangeRecipeConsumingLabsitePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangeRecipeConsumingLabsitesResponse>> {
    return recipeEventsChangeRecipeConsumingLabsitePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeRecipeConsumingLabsitePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeConsumingLabsitePost$Plain(params?: RecipeEventsChangeRecipeConsumingLabsitePost$Plain$Params, context?: HttpContext): Observable<ChangeRecipeConsumingLabsitesResponse> {
    return this.recipeEventsChangeRecipeConsumingLabsitePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangeRecipeConsumingLabsitesResponse>): ChangeRecipeConsumingLabsitesResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeRecipeConsumingLabsitePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeConsumingLabsitePost$Json$Response(params?: RecipeEventsChangeRecipeConsumingLabsitePost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangeRecipeConsumingLabsitesResponse>> {
    return recipeEventsChangeRecipeConsumingLabsitePost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeRecipeConsumingLabsitePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeConsumingLabsitePost$Json(params?: RecipeEventsChangeRecipeConsumingLabsitePost$Json$Params, context?: HttpContext): Observable<ChangeRecipeConsumingLabsitesResponse> {
    return this.recipeEventsChangeRecipeConsumingLabsitePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangeRecipeConsumingLabsitesResponse>): ChangeRecipeConsumingLabsitesResponse => r.body)
    );
  }

  /** Path part for operation `recipeEventsAddTemplatePost()` */
  static readonly RecipeEventsAddTemplatePostPath = '/recipe-events/add-template';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsAddTemplatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsAddTemplatePost$Plain$Response(params?: RecipeEventsAddTemplatePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<RecipeTemplateAddedResponse>> {
    return recipeEventsAddTemplatePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsAddTemplatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsAddTemplatePost$Plain(params?: RecipeEventsAddTemplatePost$Plain$Params, context?: HttpContext): Observable<RecipeTemplateAddedResponse> {
    return this.recipeEventsAddTemplatePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<RecipeTemplateAddedResponse>): RecipeTemplateAddedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsAddTemplatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsAddTemplatePost$Json$Response(params?: RecipeEventsAddTemplatePost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<RecipeTemplateAddedResponse>> {
    return recipeEventsAddTemplatePost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsAddTemplatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsAddTemplatePost$Json(params?: RecipeEventsAddTemplatePost$Json$Params, context?: HttpContext): Observable<RecipeTemplateAddedResponse> {
    return this.recipeEventsAddTemplatePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<RecipeTemplateAddedResponse>): RecipeTemplateAddedResponse => r.body)
    );
  }

  /** Path part for operation `recipeEventsDeleteTemplatePost()` */
  static readonly RecipeEventsDeleteTemplatePostPath = '/recipe-events/delete-template';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsDeleteTemplatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsDeleteTemplatePost$Plain$Response(params?: RecipeEventsDeleteTemplatePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<RecipeTemplateDeletedResponse>> {
    return recipeEventsDeleteTemplatePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsDeleteTemplatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsDeleteTemplatePost$Plain(params?: RecipeEventsDeleteTemplatePost$Plain$Params, context?: HttpContext): Observable<RecipeTemplateDeletedResponse> {
    return this.recipeEventsDeleteTemplatePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<RecipeTemplateDeletedResponse>): RecipeTemplateDeletedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsDeleteTemplatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsDeleteTemplatePost$Json$Response(params?: RecipeEventsDeleteTemplatePost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<RecipeTemplateDeletedResponse>> {
    return recipeEventsDeleteTemplatePost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsDeleteTemplatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsDeleteTemplatePost$Json(params?: RecipeEventsDeleteTemplatePost$Json$Params, context?: HttpContext): Observable<RecipeTemplateDeletedResponse> {
    return this.recipeEventsDeleteTemplatePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<RecipeTemplateDeletedResponse>): RecipeTemplateDeletedResponse => r.body)
    );
  }

  /** Path part for operation `recipeEventsRecipeChangeToPendingverificationPost()` */
  static readonly RecipeEventsRecipeChangeToPendingverificationPostPath = '/recipe-events/recipe-change-to-pendingverification';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsRecipeChangeToPendingverificationPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsRecipeChangeToPendingverificationPost$Plain$Response(params?: RecipeEventsRecipeChangeToPendingverificationPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<RecipeChangeToPendingVerificationResponse>> {
    return recipeEventsRecipeChangeToPendingverificationPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsRecipeChangeToPendingverificationPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsRecipeChangeToPendingverificationPost$Plain(params?: RecipeEventsRecipeChangeToPendingverificationPost$Plain$Params, context?: HttpContext): Observable<RecipeChangeToPendingVerificationResponse> {
    return this.recipeEventsRecipeChangeToPendingverificationPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<RecipeChangeToPendingVerificationResponse>): RecipeChangeToPendingVerificationResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsRecipeChangeToPendingverificationPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsRecipeChangeToPendingverificationPost$Json$Response(params?: RecipeEventsRecipeChangeToPendingverificationPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<RecipeChangeToPendingVerificationResponse>> {
    return recipeEventsRecipeChangeToPendingverificationPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsRecipeChangeToPendingverificationPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsRecipeChangeToPendingverificationPost$Json(params?: RecipeEventsRecipeChangeToPendingverificationPost$Json$Params, context?: HttpContext): Observable<RecipeChangeToPendingVerificationResponse> {
    return this.recipeEventsRecipeChangeToPendingverificationPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<RecipeChangeToPendingVerificationResponse>): RecipeChangeToPendingVerificationResponse => r.body)
    );
  }

  /** Path part for operation `recipeEventsRetireRecipePost()` */
  static readonly RecipeEventsRetireRecipePostPath = '/recipe-events/retire-recipe';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsRetireRecipePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsRetireRecipePost$Plain$Response(params?: RecipeEventsRetireRecipePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<RetireRecipeResponse>> {
    return recipeEventsRetireRecipePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsRetireRecipePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsRetireRecipePost$Plain(params?: RecipeEventsRetireRecipePost$Plain$Params, context?: HttpContext): Observable<RetireRecipeResponse> {
    return this.recipeEventsRetireRecipePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<RetireRecipeResponse>): RetireRecipeResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsRetireRecipePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsRetireRecipePost$Json$Response(params?: RecipeEventsRetireRecipePost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<RetireRecipeResponse>> {
    return recipeEventsRetireRecipePost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsRetireRecipePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsRetireRecipePost$Json(params?: RecipeEventsRetireRecipePost$Json$Params, context?: HttpContext): Observable<RetireRecipeResponse> {
    return this.recipeEventsRetireRecipePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<RetireRecipeResponse>): RetireRecipeResponse => r.body)
    );
  }

  /** Path part for operation `recipeEventsRecipeChangeToPendingapprovalPost()` */
  static readonly RecipeEventsRecipeChangeToPendingapprovalPostPath = '/recipe-events/recipe-change-to-pendingapproval';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsRecipeChangeToPendingapprovalPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsRecipeChangeToPendingapprovalPost$Plain$Response(params?: RecipeEventsRecipeChangeToPendingapprovalPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<RecipeChangeToPendingApprovalResponse>> {
    return recipeEventsRecipeChangeToPendingapprovalPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsRecipeChangeToPendingapprovalPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsRecipeChangeToPendingapprovalPost$Plain(params?: RecipeEventsRecipeChangeToPendingapprovalPost$Plain$Params, context?: HttpContext): Observable<RecipeChangeToPendingApprovalResponse> {
    return this.recipeEventsRecipeChangeToPendingapprovalPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<RecipeChangeToPendingApprovalResponse>): RecipeChangeToPendingApprovalResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsRecipeChangeToPendingapprovalPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsRecipeChangeToPendingapprovalPost$Json$Response(params?: RecipeEventsRecipeChangeToPendingapprovalPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<RecipeChangeToPendingApprovalResponse>> {
    return recipeEventsRecipeChangeToPendingapprovalPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsRecipeChangeToPendingapprovalPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsRecipeChangeToPendingapprovalPost$Json(params?: RecipeEventsRecipeChangeToPendingapprovalPost$Json$Params, context?: HttpContext): Observable<RecipeChangeToPendingApprovalResponse> {
    return this.recipeEventsRecipeChangeToPendingapprovalPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<RecipeChangeToPendingApprovalResponse>): RecipeChangeToPendingApprovalResponse => r.body)
    );
  }

  /** Path part for operation `recipeEventsRecipePublishedPost()` */
  static readonly RecipeEventsRecipePublishedPostPath = '/recipe-events/recipe-published';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsRecipePublishedPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsRecipePublishedPost$Plain$Response(params?: RecipeEventsRecipePublishedPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<RecipePublishedResponse>> {
    return recipeEventsRecipePublishedPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsRecipePublishedPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsRecipePublishedPost$Plain(params?: RecipeEventsRecipePublishedPost$Plain$Params, context?: HttpContext): Observable<RecipePublishedResponse> {
    return this.recipeEventsRecipePublishedPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<RecipePublishedResponse>): RecipePublishedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsRecipePublishedPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsRecipePublishedPost$Json$Response(params?: RecipeEventsRecipePublishedPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<RecipePublishedResponse>> {
    return recipeEventsRecipePublishedPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsRecipePublishedPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsRecipePublishedPost$Json(params?: RecipeEventsRecipePublishedPost$Json$Params, context?: HttpContext): Observable<RecipePublishedResponse> {
    return this.recipeEventsRecipePublishedPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<RecipePublishedResponse>): RecipePublishedResponse => r.body)
    );
  }

  /** Path part for operation `recipeEventsRecipeReturnToIndraftPost()` */
  static readonly RecipeEventsRecipeReturnToIndraftPostPath = '/recipe-events/recipe-return-to-indraft';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsRecipeReturnToIndraftPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsRecipeReturnToIndraftPost$Plain$Response(params?: RecipeEventsRecipeReturnToIndraftPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<RecipeReturnToInDraftResponse>> {
    return recipeEventsRecipeReturnToIndraftPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsRecipeReturnToIndraftPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsRecipeReturnToIndraftPost$Plain(params?: RecipeEventsRecipeReturnToIndraftPost$Plain$Params, context?: HttpContext): Observable<RecipeReturnToInDraftResponse> {
    return this.recipeEventsRecipeReturnToIndraftPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<RecipeReturnToInDraftResponse>): RecipeReturnToInDraftResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsRecipeReturnToIndraftPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsRecipeReturnToIndraftPost$Json$Response(params?: RecipeEventsRecipeReturnToIndraftPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<RecipeReturnToInDraftResponse>> {
    return recipeEventsRecipeReturnToIndraftPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsRecipeReturnToIndraftPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsRecipeReturnToIndraftPost$Json(params?: RecipeEventsRecipeReturnToIndraftPost$Json$Params, context?: HttpContext): Observable<RecipeReturnToInDraftResponse> {
    return this.recipeEventsRecipeReturnToIndraftPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<RecipeReturnToInDraftResponse>): RecipeReturnToInDraftResponse => r.body)
    );
  }

  /** Path part for operation `recipeEventsStartNewPost()` */
  static readonly RecipeEventsStartNewPostPath = '/recipe-events/start-new';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsStartNewPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsStartNewPost$Plain$Response(params?: RecipeEventsStartNewPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<StartNewVersionResponse>> {
    return recipeEventsStartNewPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsStartNewPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsStartNewPost$Plain(params?: RecipeEventsStartNewPost$Plain$Params, context?: HttpContext): Observable<StartNewVersionResponse> {
    return this.recipeEventsStartNewPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<StartNewVersionResponse>): StartNewVersionResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsStartNewPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsStartNewPost$Json$Response(params?: RecipeEventsStartNewPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<StartNewVersionResponse>> {
    return recipeEventsStartNewPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsStartNewPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsStartNewPost$Json(params?: RecipeEventsStartNewPost$Json$Params, context?: HttpContext): Observable<StartNewVersionResponse> {
    return this.recipeEventsStartNewPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<StartNewVersionResponse>): StartNewVersionResponse => r.body)
    );
  }

  /** Path part for operation `recipeEventsChangeAvailabilityPost()` */
  static readonly RecipeEventsChangeAvailabilityPostPath = '/recipe-events/change-availability';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeAvailabilityPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeAvailabilityPost$Plain$Response(params?: RecipeEventsChangeAvailabilityPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangeRecipeAvailabilityResponse>> {
    return recipeEventsChangeAvailabilityPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeAvailabilityPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeAvailabilityPost$Plain(params?: RecipeEventsChangeAvailabilityPost$Plain$Params, context?: HttpContext): Observable<ChangeRecipeAvailabilityResponse> {
    return this.recipeEventsChangeAvailabilityPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangeRecipeAvailabilityResponse>): ChangeRecipeAvailabilityResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeAvailabilityPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeAvailabilityPost$Json$Response(params?: RecipeEventsChangeAvailabilityPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangeRecipeAvailabilityResponse>> {
    return recipeEventsChangeAvailabilityPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeAvailabilityPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeAvailabilityPost$Json(params?: RecipeEventsChangeAvailabilityPost$Json$Params, context?: HttpContext): Observable<ChangeRecipeAvailabilityResponse> {
    return this.recipeEventsChangeAvailabilityPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangeRecipeAvailabilityResponse>): ChangeRecipeAvailabilityResponse => r.body)
    );
  }

  /** Path part for operation `recipeEventsChangeFieldPost()` */
  static readonly RecipeEventsChangeFieldPostPath = '/recipe-events/change-field';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeFieldPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeFieldPost$Plain$Response(params?: RecipeEventsChangeFieldPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangeRecipeFieldResponse>> {
    return recipeEventsChangeFieldPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeFieldPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeFieldPost$Plain(params?: RecipeEventsChangeFieldPost$Plain$Params, context?: HttpContext): Observable<ChangeRecipeFieldResponse> {
    return this.recipeEventsChangeFieldPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangeRecipeFieldResponse>): ChangeRecipeFieldResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeFieldPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeFieldPost$Json$Response(params?: RecipeEventsChangeFieldPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangeRecipeFieldResponse>> {
    return recipeEventsChangeFieldPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeFieldPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeFieldPost$Json(params?: RecipeEventsChangeFieldPost$Json$Params, context?: HttpContext): Observable<ChangeRecipeFieldResponse> {
    return this.recipeEventsChangeFieldPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangeRecipeFieldResponse>): ChangeRecipeFieldResponse => r.body)
    );
  }

  /** Path part for operation `recipeEventsAddRowsPost()` */
  static readonly RecipeEventsAddRowsPostPath = '/recipe-events/add-rows';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsAddRowsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsAddRowsPost$Plain$Response(params?: RecipeEventsAddRowsPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<AddRecipeRowResponse>> {
    return recipeEventsAddRowsPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsAddRowsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsAddRowsPost$Plain(params?: RecipeEventsAddRowsPost$Plain$Params, context?: HttpContext): Observable<AddRecipeRowResponse> {
    return this.recipeEventsAddRowsPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<AddRecipeRowResponse>): AddRecipeRowResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsAddRowsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsAddRowsPost$Json$Response(params?: RecipeEventsAddRowsPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<AddRecipeRowResponse>> {
    return recipeEventsAddRowsPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsAddRowsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsAddRowsPost$Json(params?: RecipeEventsAddRowsPost$Json$Params, context?: HttpContext): Observable<AddRecipeRowResponse> {
    return this.recipeEventsAddRowsPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<AddRecipeRowResponse>): AddRecipeRowResponse => r.body)
    );
  }

  /** Path part for operation `recipeEventsChangeCellsPost()` */
  static readonly RecipeEventsChangeCellsPostPath = '/recipe-events/change-cells';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeCellsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeCellsPost$Plain$Response(params?: RecipeEventsChangeCellsPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangeRecipeCellResponse>> {
    return recipeEventsChangeCellsPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeCellsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeCellsPost$Plain(params?: RecipeEventsChangeCellsPost$Plain$Params, context?: HttpContext): Observable<ChangeRecipeCellResponse> {
    return this.recipeEventsChangeCellsPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangeRecipeCellResponse>): ChangeRecipeCellResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeCellsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeCellsPost$Json$Response(params?: RecipeEventsChangeCellsPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangeRecipeCellResponse>> {
    return recipeEventsChangeCellsPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeCellsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeCellsPost$Json(params?: RecipeEventsChangeCellsPost$Json$Params, context?: HttpContext): Observable<ChangeRecipeCellResponse> {
    return this.recipeEventsChangeCellsPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangeRecipeCellResponse>): ChangeRecipeCellResponse => r.body)
    );
  }

  /** Path part for operation `recipeEventsRemoveRowsPost()` */
  static readonly RecipeEventsRemoveRowsPostPath = '/recipe-events/remove-rows';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsRemoveRowsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsRemoveRowsPost$Plain$Response(params?: RecipeEventsRemoveRowsPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<RemoveRecipeRowResponse>> {
    return recipeEventsRemoveRowsPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsRemoveRowsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsRemoveRowsPost$Plain(params?: RecipeEventsRemoveRowsPost$Plain$Params, context?: HttpContext): Observable<RemoveRecipeRowResponse> {
    return this.recipeEventsRemoveRowsPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<RemoveRecipeRowResponse>): RemoveRecipeRowResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsRemoveRowsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsRemoveRowsPost$Json$Response(params?: RecipeEventsRemoveRowsPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<RemoveRecipeRowResponse>> {
    return recipeEventsRemoveRowsPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsRemoveRowsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsRemoveRowsPost$Json(params?: RecipeEventsRemoveRowsPost$Json$Params, context?: HttpContext): Observable<RemoveRecipeRowResponse> {
    return this.recipeEventsRemoveRowsPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<RemoveRecipeRowResponse>): RemoveRecipeRowResponse => r.body)
    );
  }

  /** Path part for operation `recipeEventsRestoreRowsPost()` */
  static readonly RecipeEventsRestoreRowsPostPath = '/recipe-events/restore-rows';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsRestoreRowsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsRestoreRowsPost$Plain$Response(params?: RecipeEventsRestoreRowsPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<RestoreRecipeRowResponse>> {
    return recipeEventsRestoreRowsPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsRestoreRowsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsRestoreRowsPost$Plain(params?: RecipeEventsRestoreRowsPost$Plain$Params, context?: HttpContext): Observable<RestoreRecipeRowResponse> {
    return this.recipeEventsRestoreRowsPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<RestoreRecipeRowResponse>): RestoreRecipeRowResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsRestoreRowsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsRestoreRowsPost$Json$Response(params?: RecipeEventsRestoreRowsPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<RestoreRecipeRowResponse>> {
    return recipeEventsRestoreRowsPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsRestoreRowsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsRestoreRowsPost$Json(params?: RecipeEventsRestoreRowsPost$Json$Params, context?: HttpContext): Observable<RestoreRecipeRowResponse> {
    return this.recipeEventsRestoreRowsPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<RestoreRecipeRowResponse>): RestoreRecipeRowResponse => r.body)
    );
  }

  /** Path part for operation `recipeEventsRenumberRowsPost()` */
  static readonly RecipeEventsRenumberRowsPostPath = '/recipe-events/renumber-rows';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsRenumberRowsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsRenumberRowsPost$Plain$Response(params?: RecipeEventsRenumberRowsPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<RenumberRecipeRowsResponse>> {
    return recipeEventsRenumberRowsPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsRenumberRowsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsRenumberRowsPost$Plain(params?: RecipeEventsRenumberRowsPost$Plain$Params, context?: HttpContext): Observable<RenumberRecipeRowsResponse> {
    return this.recipeEventsRenumberRowsPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<RenumberRecipeRowsResponse>): RenumberRecipeRowsResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsRenumberRowsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsRenumberRowsPost$Json$Response(params?: RecipeEventsRenumberRowsPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<RenumberRecipeRowsResponse>> {
    return recipeEventsRenumberRowsPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsRenumberRowsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsRenumberRowsPost$Json(params?: RecipeEventsRenumberRowsPost$Json$Params, context?: HttpContext): Observable<RenumberRecipeRowsResponse> {
    return this.recipeEventsRenumberRowsPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<RenumberRecipeRowsResponse>): RenumberRecipeRowsResponse => r.body)
    );
  }

  /** Path part for operation `recipeEventsChangeNodeTitlePost()` */
  static readonly RecipeEventsChangeNodeTitlePostPath = '/recipe-events/change-node-title';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeNodeTitlePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeNodeTitlePost$Plain$Response(params?: RecipeEventsChangeNodeTitlePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<RecipeNodeTitleChangedResponse>> {
    return recipeEventsChangeNodeTitlePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeNodeTitlePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeNodeTitlePost$Plain(params?: RecipeEventsChangeNodeTitlePost$Plain$Params, context?: HttpContext): Observable<RecipeNodeTitleChangedResponse> {
    return this.recipeEventsChangeNodeTitlePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<RecipeNodeTitleChangedResponse>): RecipeNodeTitleChangedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeNodeTitlePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeNodeTitlePost$Json$Response(params?: RecipeEventsChangeNodeTitlePost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<RecipeNodeTitleChangedResponse>> {
    return recipeEventsChangeNodeTitlePost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeNodeTitlePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeNodeTitlePost$Json(params?: RecipeEventsChangeNodeTitlePost$Json$Params, context?: HttpContext): Observable<RecipeNodeTitleChangedResponse> {
    return this.recipeEventsChangeNodeTitlePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<RecipeNodeTitleChangedResponse>): RecipeNodeTitleChangedResponse => r.body)
    );
  }

  /** Path part for operation `recipeEventsChangeNodeOrderPost()` */
  static readonly RecipeEventsChangeNodeOrderPostPath = '/recipe-events/change-node-order';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeNodeOrderPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeNodeOrderPost$Plain$Response(params?: RecipeEventsChangeNodeOrderPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<RecipeNodeOrderChangedResponse>> {
    return recipeEventsChangeNodeOrderPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeNodeOrderPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeNodeOrderPost$Plain(params?: RecipeEventsChangeNodeOrderPost$Plain$Params, context?: HttpContext): Observable<RecipeNodeOrderChangedResponse> {
    return this.recipeEventsChangeNodeOrderPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<RecipeNodeOrderChangedResponse>): RecipeNodeOrderChangedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeNodeOrderPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeNodeOrderPost$Json$Response(params?: RecipeEventsChangeNodeOrderPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<RecipeNodeOrderChangedResponse>> {
    return recipeEventsChangeNodeOrderPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeNodeOrderPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeNodeOrderPost$Json(params?: RecipeEventsChangeNodeOrderPost$Json$Params, context?: HttpContext): Observable<RecipeNodeOrderChangedResponse> {
    return this.recipeEventsChangeNodeOrderPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<RecipeNodeOrderChangedResponse>): RecipeNodeOrderChangedResponse => r.body)
    );
  }

  /** Path part for operation `recipeEventsSetVariablePost()` */
  static readonly RecipeEventsSetVariablePostPath = '/recipe-events/set-variable';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsSetVariablePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsSetVariablePost$Plain$Response(params?: RecipeEventsSetVariablePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<SetVariableResponse>> {
    return recipeEventsSetVariablePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsSetVariablePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsSetVariablePost$Plain(params?: RecipeEventsSetVariablePost$Plain$Params, context?: HttpContext): Observable<SetVariableResponse> {
    return this.recipeEventsSetVariablePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<SetVariableResponse>): SetVariableResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsSetVariablePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsSetVariablePost$Json$Response(params?: RecipeEventsSetVariablePost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<SetVariableResponse>> {
    return recipeEventsSetVariablePost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsSetVariablePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsSetVariablePost$Json(params?: RecipeEventsSetVariablePost$Json$Params, context?: HttpContext): Observable<SetVariableResponse> {
    return this.recipeEventsSetVariablePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<SetVariableResponse>): SetVariableResponse => r.body)
    );
  }

  /** Path part for operation `recipeEventsChangePreLoadDataTransformOptionsPost()` */
  static readonly RecipeEventsChangePreLoadDataTransformOptionsPostPath = '/recipe-events/change-pre-load-data-transform-options';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangePreLoadDataTransformOptionsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangePreLoadDataTransformOptionsPost$Plain$Response(params?: RecipeEventsChangePreLoadDataTransformOptionsPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangeRecipePreLoadDataTransformOptionsResponse>> {
    return recipeEventsChangePreLoadDataTransformOptionsPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangePreLoadDataTransformOptionsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangePreLoadDataTransformOptionsPost$Plain(params?: RecipeEventsChangePreLoadDataTransformOptionsPost$Plain$Params, context?: HttpContext): Observable<ChangeRecipePreLoadDataTransformOptionsResponse> {
    return this.recipeEventsChangePreLoadDataTransformOptionsPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangeRecipePreLoadDataTransformOptionsResponse>): ChangeRecipePreLoadDataTransformOptionsResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangePreLoadDataTransformOptionsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangePreLoadDataTransformOptionsPost$Json$Response(params?: RecipeEventsChangePreLoadDataTransformOptionsPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangeRecipePreLoadDataTransformOptionsResponse>> {
    return recipeEventsChangePreLoadDataTransformOptionsPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangePreLoadDataTransformOptionsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangePreLoadDataTransformOptionsPost$Json(params?: RecipeEventsChangePreLoadDataTransformOptionsPost$Json$Params, context?: HttpContext): Observable<ChangeRecipePreLoadDataTransformOptionsResponse> {
    return this.recipeEventsChangePreLoadDataTransformOptionsPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangeRecipePreLoadDataTransformOptionsResponse>): ChangeRecipePreLoadDataTransformOptionsResponse => r.body)
    );
  }

  /** Path part for operation `recipeEventsChangeRecipeTypePost()` */
  static readonly RecipeEventsChangeRecipeTypePostPath = '/recipe-events/change-recipe-type';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeRecipeTypePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeTypePost$Plain$Response(params?: RecipeEventsChangeRecipeTypePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangeRecipeTypeResponse>> {
    return recipeEventsChangeRecipeTypePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeRecipeTypePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeTypePost$Plain(params?: RecipeEventsChangeRecipeTypePost$Plain$Params, context?: HttpContext): Observable<ChangeRecipeTypeResponse> {
    return this.recipeEventsChangeRecipeTypePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangeRecipeTypeResponse>): ChangeRecipeTypeResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipeEventsChangeRecipeTypePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeTypePost$Json$Response(params?: RecipeEventsChangeRecipeTypePost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangeRecipeTypeResponse>> {
    return recipeEventsChangeRecipeTypePost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipeEventsChangeRecipeTypePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipeEventsChangeRecipeTypePost$Json(params?: RecipeEventsChangeRecipeTypePost$Json$Params, context?: HttpContext): Observable<ChangeRecipeTypeResponse> {
    return this.recipeEventsChangeRecipeTypePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangeRecipeTypeResponse>): ChangeRecipeTypeResponse => r.body)
    );
  }

}
