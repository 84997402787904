import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BptProgressSpinnerModule } from 'bpt-ui-library/bpt-progress-spinner';
import { BptLayoutModule } from 'bpt-ui-library/bpt-layout';
import { AvatarModule } from 'primeng/avatar';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DialogModule } from 'primeng/dialog';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ToastModule } from 'primeng/toast';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { AvatarComponent } from './avatar/avatar.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { ExperimentNotificationComponent } from './experiment-notification/experiment-notification.component';
import { MainMenuComponent } from './main-menu/main-menu.component';
import { MiddlebarComponent } from './middlebar/middlebar.component';
import { SlidePanelComponent } from './slide-panel/slide-panel.component';
import { ToastComponent } from './toast/toast.component';
import { TopbarComponent } from './topbar/topbar.component';
import { WaitDialogComponent } from './wait-dialog/wait-dialog.component';
import {BadgeModule} from 'primeng/badge';

@NgModule({
  declarations: [
    BreadcrumbComponent,
    TopbarComponent,
    MainMenuComponent,
    WaitDialogComponent,
    ToastComponent,
    MiddlebarComponent,
    ExperimentNotificationComponent,
    AvatarComponent,
    SlidePanelComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    BptLayoutModule,
    BreadcrumbModule,
    BadgeModule,
    TooltipModule,
    ButtonModule,
    OverlayPanelModule,
    InputTextModule,
    DialogModule,
    ToastModule,
    ToolbarModule,
    AvatarModule,
    ContextMenuModule,
    DynamicDialogModule,
    BptProgressSpinnerModule
  ],
  exports: [
    BreadcrumbComponent,
    TopbarComponent,
    MainMenuComponent,
    WaitDialogComponent,
    ToastComponent,
    MiddlebarComponent,
    ExperimentNotificationComponent,
    AvatarComponent,
    SlidePanelComponent
  ],
  providers: [DialogService]
})
export class LayoutModule {}
