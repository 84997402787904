import { Injectable } from '@angular/core';
import { NgxSerial } from 'ngx-serial';
import { Timestamp } from 'rxjs';
import { InstrumentConnectionHelper } from '../instrument-connection/shared/instrument-connection-helper';
import { MessageService } from 'primeng/api';
import { InstrumentType } from '../instrument-connection/shared/instrument-type';
import { PhMeasurementCollection } from '../model/instrument-connection/ph-measurement-collection';
import { InstrumentNotificationService } from '../instrument-connection/shared/instrument-notification-service';

export enum CommandName {
  'None' = 0,
  'SystemInfoCommand',
  'SetDataFormatCommand',
  'SetDateCommand',
  'SetModeCommand',
  'GetMeasurementCommand'
}

export interface CommandText {
  CommandName: string;
  CommandValue: string;
}

export type PhDeviceInfo = {
  InstrumentModel: string;
  SerialNumber: string;
  SoftwareRevision: string;
  UserId: string;
  DateTime: Timestamp<string>;
  SampleId: string;
}

enum Query {
  None = 'none',
  Closing = 'closing', // not really a query but a flag so declare disinterest in anything that comes while closing.
  Prompt = 'prompt',
  Identify = 'identify',
  Pull = 'pull'
}

@Injectable({
  providedIn: 'root'
})
export class PhMeterService {
  port: any;
  private static readonly Assembly: string = 'ELN.Blazor.Entry' as const;
  private static readonly GetConnectToInstrumentCommandsMethod: string = 'GetConnectToInstrumentCommands' as const;
  private static readonly IsModelSupportedMethod: string = 'IsModelSupported' as const;
  private static readonly GetImmediateReadingsMethod: string = 'GetImmediateReadings' as const;
  cmdList!: CommandText[];
  serial!: NgxSerial;
  pendingQuery = Query.None;
  CurrentCommandName = CommandName.None;
  deviceInfo!: PhDeviceInfo;
  private readonly prompt = '>';
  private dataThroughSerialPortCanBeProcessed = false;
  private timeOut = 5000;
  phMeterActiveConnectionFlag?: boolean;
  wasConnectionActive?: boolean;
  private disconnectTimer!: ReturnType<typeof setInterval>;

  constructor(private readonly messageService: MessageService,
    private readonly instrumentNotificationService: InstrumentNotificationService,
    public readonly instrumentConnectionHelper: InstrumentConnectionHelper) {
    const options = { baudRate: 9600, dataBits: 8, parity: 'none', bufferSize: 256, flowControl: 'none' };
    this.serial = new NgxSerial((data: string) => this.dataHandler(data), options, '\r');
  }

  checkForDisconnection(timeOut: number) {
    this.disconnectTimer = setInterval(async () => { await this.checkIfDeviceIsStillConnected(); }, timeOut);
  }

  async checkIfDeviceIsStillConnected() {
    const component = this;
    try {
      const ports = await (<any>navigator).serial.getPorts();
      // for debugging: console.log('Ports: ' + ports?.length);
      // for debugging: console.log('EquipmentId: ' + this.instrumentConnectionHelper._equipmentId);
      if ((ports === null || ports === undefined || (!!ports && ports.length === 0)) && this.instrumentConnectionHelper._equipmentId) {
        // for debugging: console.log('Ports before disconnect: ' + ports);
        // for debugging: console.log('EquipmentId before disconnect: ' + this.instrumentConnectionHelper._equipmentId);
        this.instrumentNotificationService.disconnectFromInstrument(this.instrumentConnectionHelper._equipmentId, InstrumentType.phMeter);
        component.disconnect(true);
      }
    } catch (ex) {
      console.log(ex);
    }
  }

  callBackForSerialPortConnectionClose = (port: any) => {
    port ??= undefined;
    this.port = port;
    this.pendingQuery = Query.None;
  }

  connectToPhMeter(disconnectTimeout?: number) {
    this.checkForDisconnection(disconnectTimeout ?? this.timeOut);
    if (this.port) {
      console.log('Port is already opened');
      this.disconnect();
    }
    this.GetConnectToInstrumentCommands<string>().then((result: string) => {
      this.ProcessGetConnectionResponse(result);
    },
      () => {
        console.log('pH meter connection failed while trying to connect');
        this.handleFailedPhMeterConnection('');
      });
  }

  retainConnection(): boolean {
    const restoredFlag = sessionStorage.getItem('phMeterActiveConnectionFlag');
    this.instrumentConnectionHelper.phMeterActiveSession.subscribe((wasConnectionActive) => {
      this.phMeterActiveConnectionFlag = !!wasConnectionActive;
      sessionStorage.setItem('phMeterActiveConnectionFlag', JSON.stringify(this.phMeterActiveConnectionFlag));
    })
    this.phMeterActiveConnectionFlag = JSON.parse(restoredFlag as string);
    return this.phMeterActiveConnectionFlag ?? false;
  }

  private handleFailedPhMeterConnection(error: string) {
    this.messageService.add({
      key: 'notification',
      severity: 'error',
      summary: $localize`:@@pHMeterConnectionFailed:Connection to pH meter failed`,
      detail: error,
      sticky: false
    });
  }

  disconnect(deviceUnplugged?: boolean) {
    clearInterval(this.disconnectTimer);
    if (!this.port) return;
    this.pendingQuery = Query.Closing;
    this.serial.close(this.callBackForSerialPortConnectionClose);
    this.instrumentConnectionHelper.phMeterDisconnectedSuccessfully.next();
    this.instrumentConnectionHelper.phMeterActiveSession.next(false);
    console.log('Device is not unplugged: ' + deviceUnplugged);
    if (deviceUnplugged) {
      console.log('Device unplugged: ' + deviceUnplugged);
      this.messageService.add({
        key: 'notification',
        severity: 'error',
        closable: true,
        summary: this.instrumentConnectionHelper.phMeterReadingSessionActive ?
          $localize`:@@phMeterConnectionFailed:pH meter connection failed` :
          $localize`:@@phMeterDisconnected:Disconnected from pH meter`
      });
    }
  }

  private ProcessGetConnectionResponse(adaptorResponse: string) {
    console.log(adaptorResponse);
    this.cmdList = JSON.parse(adaptorResponse);
    this.serial.connect((port: any) => {
      console.log('Serial port is connected');
      this.port = port;
      this.cmdList = [...this.cmdList].reverse();
      this.ProcessGetConnectionOutput(this.cmdList.pop());
      setTimeout(() => {
        if (!this.dataThroughSerialPortCanBeProcessed) {
          console.log('Connection failed after serial port connection');
          this.handleFailedPhMeterConnection('');
        }
      }, this.timeOut);
    });
  }

  private ProcessGetConnectionOutput(command: CommandText | undefined) {
    if (command === undefined) return;
    const element = command;
    if (element.CommandName === 'SystemInfoCommand') {
      this.CurrentCommandName = CommandName.SystemInfoCommand;
    } else if (element.CommandName === 'SetDataFormatCommand') {
      this.CurrentCommandName = CommandName.SetDataFormatCommand;
    } else if (element.CommandName === 'SetDateCommand') {
      this.CurrentCommandName = CommandName.SetDateCommand;
    } else if (element.CommandName === 'SetModeCommand') {
      this.CurrentCommandName = CommandName.SetModeCommand;
    }
    this.serial.sendData(element.CommandValue).then();
  }

  dataHandler(data: string) {
    this.dataThroughSerialPortCanBeProcessed = true;
    this.instrumentConnectionHelper.instrumentType = InstrumentType.phMeter;
    if (!this.serial || !this.port || this.pendingQuery === Query.Closing) return;
    if (data === '\u{00000A}') return; // completely ignore undocumented maybe-sort-of-part-of line terminator.
    if (data === ' ') return; // completely ignore undocumented extra line-with-space that may be part of prompt.
    switch (this.CurrentCommandName) {
      case CommandName.SystemInfoCommand: {
        if (data === 'SYSTEM ') {
          break;// ignore intervening prompt and undocumented remote echo.
        }
        const promptData = data.trim();
        if (promptData === this.prompt) {
          this.ProcessGetConnectionOutput(this.cmdList.pop());
          break;
        }
        this.IsModelSupported<string>(data).then((result) => {
          console.log('IsModelSupported response: ' + result);
          this.deviceInfo = JSON.parse(result);
          if (this.deviceInfo !== null) {
            console.log('deviceInfo: ' + this.deviceInfo.InstrumentModel);
            this.instrumentConnectionHelper.phMeterConnectionSuccess.next(this.deviceInfo);
            this.instrumentConnectionHelper.phMeterActiveSession.next(true);
          }
        });
        break;
      }
      case CommandName.SetDataFormatCommand: {
        if (data === 'SETCSV ' || data === '> ') {
          break; // ignore intervening prompt and undocumented remote echo.
        }
        const readingRawData = data.trim();
        this.GetImmediateReadings<string>(readingRawData).then((res: string) => {
          const readings: PhMeasurementCollection = JSON.parse(res);
          this.instrumentConnectionHelper.phMeterReadingsReceived.next(readings);
        });
        break;
      }
    }
  }

  public getPhMeterActiveTab() {
    return document.getElementById('eln-connectedInstrument')?.getAttribute('attr-phmeteractivetab');
  }

  phMeterConnected() {
    this.messageService.add({
      key: 'notification',
      severity: 'success',
      summary: $localize`:@@pHMeterConnected:Connected to pH Meter:`,
      detail: this.formattedIdentification('\n'),
      sticky: false
    })
  }

  formattedIdentification(separator: string): string {
    return [
      $localize`:@@model:Model`, ': ', this.deviceInfo?.InstrumentModel, separator,
      $localize`:@@serial:Serial`, ': ', this.deviceInfo?.SerialNumber, separator,
      $localize`:@@softwareVersion:Version`, ': ', this.deviceInfo?.SoftwareRevision,
    ].join(' ');
  }

  private GetConnectToInstrumentCommands<T>() {
    return DotNet.invokeMethodAsync<T>(PhMeterService.Assembly, PhMeterService.GetConnectToInstrumentCommandsMethod);
  }

  private GetImmediateReadings<T>(data: string) {
    return DotNet.invokeMethodAsync<T>(PhMeterService.Assembly, PhMeterService.GetImmediateReadingsMethod, data);
  }

  public IsModelSupported<T>(model: string) {
    return DotNet.invokeMethodAsync<T>(PhMeterService.Assembly, PhMeterService.IsModelSupportedMethod, model);
  }
}
