/* tslint:disable */
/* eslint-disable */
export enum RuleEvents {
  OnLoad = 'onLoad',
  CellChanged = 'cellChanged',
  FieldChanged = 'fieldChanged',
  RowAdded = 'rowAdded',
  Focused = 'focused',
  DropDownFocused = 'dropDownFocused'
}
