/* tslint:disable */
/* eslint-disable */
export enum ClientFacingNoteContextType {
  Invalid = 'invalid',
  ActivityGroup = 'activityGroup',
  Activity = 'activity',
  Module = 'module',
  Form = 'form',
  Table = 'table',
  ActivityInput = 'activityInput',
  Experiment = 'experiment',
  TableCell = 'tableCell',
  FormField = 'formField',
  LabItems = 'labItems',
  Consumables = 'consumables',
  CrossReference = 'crossReference',
  Preparations = 'preparations',
  LabItemsPreparation = 'labItemsPreparation'
}
