export class ELNAppConstants {
  public static readonly WhiteSpace = '\xa0';

  // This doesn't happen to be the  $link-color from
  // https://xd.adobe.com/view/0b6726d1-eacf-47f9-a36b-83b1154565f6-5e34/screen/d56334ff-6635-4a2a-af23-28a53383f92e?fullscreen
  // but it what was chosen
  public static readonly ClientFacingNoteFlagColor = '#F7E005'; 
  public static readonly InternalCommentFlagColor = '#0072BC !important';
  public static readonly InternalCommentBackGroundColor = '#FFFEE0';
  public static readonly BackGroundColorWhite = '#FFFFFF';
  public static readonly UntouchedFieldBackGroundColor = '#FEF3F4';
  public static readonly OutputButtonDisabledColor = '#999999';
  public static readonly NaUpperCase = 'N/A';
  public static readonly NaLowerCase = 'n/a';
}