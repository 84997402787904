import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TemplateLoaderComponent } from './template-loader.component';
import { BptSearchModule } from 'bpt-ui-library/bpt-search';
import { BptSliderModule } from 'bpt-ui-library/bpt-slider';
import { BptProgressSpinnerModule } from 'bpt-ui-library/bpt-progress-spinner';
import { OrganizationModule } from '../organization/organization.module';
import { BptDropdownModule } from 'bpt-ui-library/bpt-dropdown';
import { ButtonModule } from 'primeng/button';
import { FormsModule } from '@angular/forms';
import { TemplateLoaderService } from './services/template-loader.service';
import { BptTextInputModule } from 'bpt-ui-library/bpt-text-input';
import { ConfirmTemplateLocationComponent } from './experiment-template-load/confirm-template-location/confirm-template-location.component';
import { CardModule } from 'primeng/card';
import { RadioButtonModule } from 'primeng/radiobutton';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ClientNotificationModule } from '../client-notification/client-notification.module';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
  declarations: [TemplateLoaderComponent, ConfirmTemplateLocationComponent],
  imports: [
    CommonModule,
    FormsModule,
    BptSearchModule,
    BptDropdownModule,
    BptSliderModule,
    BptProgressSpinnerModule,
    OrganizationModule,
    ButtonModule,
    BptTextInputModule,
    CardModule,
    RadioButtonModule,
    BreadcrumbModule,
    ClientNotificationModule,
    MessagesModule,
    MessageModule,
    TooltipModule
  ],
  providers: [TemplateLoaderService],
  exports: [TemplateLoaderComponent]
})
export class TemplateLoaderModule {}
