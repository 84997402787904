import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-copy-recipe-dialog',
  templateUrl: './copy-recipe-dialog.component.html',
  styleUrls: ['./copy-recipe-dialog.component.scss'],
})
export class CopyRecipeDialogComponent implements OnInit {
  recipeName = '';
  footerCancelButtonLabel = $localize`:@@cancelTitle:Cancel`;
  footerSaveButtonLabel = $localize`:@@ok:Ok`;
  dialogHeader = $localize`:@@SaveNewRecipe:Save as new recipe`;
  dialogSubHeader = $localize`:@@recipeNameInputPlaceholderText:Enter the new recipe name`;
  recipeNameValidationError = '';
  recipeNameInputPlaceholderText = $localize`:@@recipeNameInputPlaceholderText:Enter the new recipe name`;
  recipeNameInputLabel = $localize`:@@recipeNameLabel:Recipe Name`;

  constructor(
    private readonly dynamicDialogConfig: DynamicDialogConfig,
    private readonly recipeCopyDialogRef: DynamicDialogRef
  ) {}

  ngOnInit(): void {
    const name = this.dynamicDialogConfig.data.name;
    this.recipeName = name + ' - ' + $localize`:@@recipeCopySuffix:Copy`;
  }

  onSaveClicked() {
    if (this.recipeName.trim() === '' || this.recipeNameValidationError) return;
    this.recipeCopyDialogRef.close({
      recipeName: this.recipeName,
    });
  }

  onCancelClicked() {
    this.recipeCopyDialogRef.close();
  }
}
