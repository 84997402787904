import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-middlebar',
  templateUrl: './middlebar.component.html',
  styleUrls: ['./middlebar.component.scss']
})
export class MiddlebarComponent {
  @Input() toolbarRight?: any;
  @Input() toolbarLeft?: any;
  @Input() toolbarCenter?: any;
  @Input() experiment = false;
  @Input() enableNotification = false;
  @Input() enableBreadcrumb? = true;

  // Inbuilt classes "toolbar-no-inline-padding"
  @Input() styleClass = '';
  constructor() {}
}
