import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { UserService } from 'services/user.service';
import { ConfigurationService } from 'services/configuration.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit, OnChanges {
  @Input() subTitle?: string;
  @Input() title?: string;
  @Input() subTitleBelow?: string;
  @Input() visibleInLims = false;
  isLimsHosted: boolean;
  topBarTitle = '';
  userPuid: string;
  labsiteCode = '';
  labsiteName = '';

  constructor(
    private userService: UserService,
    private readonly configurationService: ConfigurationService
  ) {
    this.isLimsHosted = this.configurationService.isLimsHosted();
    this.userPuid = this.userService.currentUser.puid;
    if (this.userService.currentUser.labSiteCode) {
      this.labsiteCode = this.userService.currentUser.labSiteCode;
    }
    if (this.userService.currentUser.labSiteName) {
      this.labsiteName = this.userService.currentUser.labSiteName;
    }
  }

  ngOnInit(): void {
    this.setTopBarTitle();
  }

  ngOnChanges(changes: SimpleChanges): void {
      if(changes['subTitle'] || changes['title']){
        this.setTopBarTitle();
      }
  }

  private setTopBarTitle():void{
    if (this.title) {
      this.topBarTitle = this.title;
    }
    if (this.subTitle && this.subTitle.length > 0) {
      this.topBarTitle = `${this.title} - ${this.subTitle}`;
    }
  }
}
