import { Component, OnDestroy, OnInit, ViewChild, Renderer2, ElementRef, LOCALE_ID, Inject } from '@angular/core';
import { ColumnType } from '../../../api/models/column-type';
import { LockType } from 'model/input-lock.interface';
import { ExperimentNotificationService } from 'services/experiment-notification.service';
import { Activity, Experiment } from 'model/experiment.interface';
import { ICellRendererParams } from 'ag-grid-enterprise';
import {
  BptGridComponent,
  BptGridMode,
  ColumnDefinition,
  BptGridPreferences,
  PreferenceAdded,
  PreferenceDeleted,
  PreferenceUpdated,
  PreferenceSelectionChanged,
  BptGridCellValueChangedEvent,
  GridContextMenuItem,
  ISeverityIndicatorConfig,
  BptGridRowActionConfiguration,
  BptGridRowActionClickEvent,
  BptRowActionElement
} from 'bpt-ui-library/bpt-grid';
import { InputsComponent } from '../inputs.component';
import { UserService } from 'services/user.service';
import { User } from 'model/user.interface';
import { ExperimentService } from '../../services/experiment.service';
import { BarcodeScannerHelper } from 'services/barcode-scanner-helper';
import { ActivityInputStudyActivity } from '../../model/activity-input-study-activity';
import { UserPreferenceService } from '../../../api/services/user-preference.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { GridPreferenceService } from '../../services/grid-preference.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import {
  RefreshActivityInputRowCommand,
  SelectStudyActivityCommand,
  StudyActivitySelectedEventNotification,
  MaterialAliquot,
  MaterialRowRefreshedDetails,
  StatementAppliedEventNotification
} from '../../../../app/api/data-entry/models';
import {
  ActivityInputType,
  ClientFacingNoteContextType,
  MaterialAliquotDetails,
  StudyActivityDomain
} from '../../../api/models';
import { cloneDeep, isEqual, mapValues } from 'lodash-es';
import { DataValueService } from '../../services/data-value.service';
import { DataRecordService } from '../../services/data-record.service';
import {
  ActivityInputCellChangedEventNotification,
  ChangeActivityInputCellCommand,
  StringValue
} from '../../../api/data-entry/models';
import {
  AuditHistoryDataRecordResponse,
  ExperimentDataRecordNotification,
  ExperimentEventType
} from '../../../api/audit/models';
import { AuditHistoryService } from '../../audit-history/audit-history.service';
import { DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { ActivityInputEventsService } from '../../../../app/api/data-entry/services';
import { StudyActivityTableGridOptions } from './study-activity-table-grid-options';
import { BptGridCellEditEvent } from 'bpt-ui-library/bpt-grid/model/bpt-grid-cell-edit-event.interface';
import { ActivityInputConstants } from '../shared/activity-input-constants';
import { ELNAppConstants } from '../../../shared/eln-app-constants';
import { ActivityInputValidationHelper } from 'services/activity-input-validation-helper';
import { CommentContextType } from '../../../api/internal-comment/models/comment-context-type'
import { CommentService } from '../../comments/comment.service';
import { CommentResponse, CommentsResponse, InternalCommentStatus } from '../../../api/internal-comment/models';
import { ClientFacingNoteModel } from '../../comments/client-facing-note/client-facing-note.model';
import { ActivityInputClientFacingNoteContext, ShowClientFacingNotesEventData } from '../../comments/client-facing-note/client-facing-note-event.model';
import { StudyActivityTableRemovedRowsComponent } from './study-activity-removed-rows/study-activity-removed-rows.component';
import { UnsubscribeAll } from '../../../shared/rx-js-helpers';
import { ActivityInputHelper } from '../shared/activity-input-helper';
import { ActivatedRoute } from '@angular/router';
import { ActivityInputStudyActivitySelectionComponent } from '../activity-input-study-activity-selection/activity-input-study-activity-selection.component';
import { ActivityInputService } from '../../../api/services';
import { StudyActivities } from '../../../model/study-activities';
import { NA } from 'bpt-ui-library/shared';
import { ElnProgressSpinnerService } from '../../../eln-progress-spinner-module/eln-progress-spinner.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ExperimentWarningService } from '../../services/experiment-warning.service';
import { CompletionTrackingService } from '../../../services/completion-tracking.services';
import { StudyActivitiesTitle } from '../../comments/internal-comments-constants';

@Component({
  selector: 'app-study-activity-table',
  templateUrl: './study-activity-table.component.html',
  styleUrls: ['./study-activity-table.component.scss']
})

export class StudyActivityTableComponent implements OnInit, OnDestroy {
  gridSavedPreferences!: BptGridPreferences;
  columnDefinitions: ColumnDefinition[] = [];
  gridMode = BptGridMode.dataEntry;
  studyActivitiesMaterialAliquots = $localize`:@@StudyActivities:Study Activities`;
  user!: User;
  experimentId = '';
  dynamicDialogRef!: DynamicDialogRef;
  experiment!: Experiment;
  subscriptionList: Subscription[] = [];
  materialAliquots: MaterialAliquot[] = [];
  primitiveValue!: { [key: string]: any }[];
  studyActivitiesWithSelectedData!: StudyActivities;
  studyActivityCommand!: SelectStudyActivityCommand;
  internalCommentActiveCell!: Element;
  studyActivityData: StudyActivityDomain[] = [];
  cellChangedValues!: ChangeActivityInputCellCommand;
  gridActions!: BptGridRowActionConfiguration;
  selectedStudyData!: StudyActivityDomain[];
  containsRemovedRows = false;
  isModalOpen = false;
  isLoading = false;
  displayScrollButtons = true;
  previousSampleDataSource: ActivityInputStudyActivity[] = [];
  lockTimeOut = 0;
  rowActions: BptRowActionElement[] = [];
  readonly studyActivityId = 'studyActivityGridId';
  readonly inputs = 'Inputs';
  readonly pendingImgClass = 'pending-img';
  private readonly backgroundColorString = 'background-color';
  private readonly imagePath = '<img src="assets/eln-assets/apps-add.svg" class="ag-icon ag-custom-icon" />';
  private readonly dateColumns = ['expirationDate', 'inUseExpirationDate'];
  // Bug created to fix this, https://dev.azure.com/BPTCollection/Eurofins%20ELN/_backlogs/backlog/Konark/Backlog%20items/?workitem=3225439
  private readonly dateFormat = 'yyyy-MM-dd';
  private readonly clientFacingNotesFlag = 'top-right';
  private readonly internalCommentsFlag = 'bottom-right';
  isDataRendered = false;
  reloadGrid = true;
  completionPercent = 0;

  @ViewChild('grid') grid!: BptGridComponent;

  private readonly eventsForHistory: ExperimentEventType[] = [
    ExperimentEventType.MaterialAdded,
    ExperimentEventType.ActivityInputAdded,
    ExperimentEventType.ActivityInputCellChanged,
    ExperimentEventType.ActivityInputRowRefreshed,
    ExperimentEventType.ActivityInputRowRestored,
    ExperimentEventType.ActivityInputRowRemoved,
    ExperimentEventType.StudyActivitySelected,
    ExperimentEventType.StatementApplied
  ];
  additionalInformation = 'AdditionalInformation';
  rowRemoved = $localize`:@@RemovedRows:Table contains one or more removed rows`;
  studyActivityDataSource!: ActivityInputStudyActivity[];

  constructor(
    private dialogService: DialogService,
    private readonly dataValueService: DataValueService,
    private readonly experimentService: ExperimentService,
    private readonly barcodeScannerHelper: BarcodeScannerHelper,
    private readonly inputsComponent: InputsComponent,
    private readonly userService: UserService,
    private readonly preferenceService: UserPreferenceService,
    private readonly gridPreferenceService: GridPreferenceService,
    private readonly messageService: MessageService,
    private readonly validationHelper: ActivityInputValidationHelper,
    private readonly dataRecordService: DataRecordService,
    private readonly auditHistoryService: AuditHistoryService,
    private readonly activityInputEventsService: ActivityInputEventsService,
    private readonly confirmationService: ConfirmationService,
    private readonly experimentNotificationService: ExperimentNotificationService,
    private readonly commentService: CommentService,
    private readonly renderer: Renderer2,
    private readonly elementRef: ElementRef,
    private readonly activityInputHelper: ActivityInputHelper,
    private readonly activatedRoute: ActivatedRoute,
    private readonly activityInputService: ActivityInputService,
    private readonly elnProgressSpinnerService: ElnProgressSpinnerService,
    private readonly experimentWarningService: ExperimentWarningService,
    private readonly completionTrackingService: CompletionTrackingService,
    @Inject(LOCALE_ID) private readonly locale: string
  ) {
  }

  ngOnInit(): void {
    this.handleSubscriptions();
    this.refreshDataSource();
    this.prepareMaterialData();
    this.loadGridPreferences();
    this.addGridActions();
    this.completionPercent = this.completionTrackingService.calculateActivityInputsTableCompletionPercent(this.studyActivityDataSource, ActivityInputType.Material);
    this.currentUserRoleActionAssignment();
  }


  private prepareMaterialData() {
    this.user = { ...this.userService.currentUser };
    StudyActivityTableGridOptions.isReadOnly = this.inputsComponent.activityInputComponentsReadonly;
    this.columnDefinitions = StudyActivityTableGridOptions.prepareColumns(this.openModal);
    this.experiment = this.experimentService.currentExperiment as Experiment;
    this.experimentId = this.experiment?.id;
  }

  private refreshDataSource(): void {
    const materials = this.activityInputHelper.getMaterialAliquots();
    this.studyActivityDataSource = [...materials];
    this.previousSampleDataSource = this.getPreviouslyUpdatedDataSource();
    this.grid?.gridApi?.setGridOption('rowData', this.studyActivityDataSource);
    this.completionPercent = this.completionTrackingService.calculateActivityInputsTableCompletionPercent(this.studyActivityDataSource, ActivityInputType.Material);
    this.grid?.gridApi?.refreshCells();
    this.containsRemovedRows = (this.activityInputHelper.getMaterialAliquots(true)).length > 0;
    this.grid?.gridApi?.autoSizeAllColumns();
    this.isDataRendered = true;
  }

  cellEditStartedEvent(e: BptGridCellEditEvent) {
    this.lockTimeOut = window.setTimeout(() => {
      this.activityInputHelper.sendInputStatus(LockType.lock, e.gridId ?? '', e.rowId ?? '', e.field ?? '');
    }, 500);
  }

  cellEditStoppedEvent(e: BptGridCellEditEvent) {
    window.clearTimeout(this.lockTimeOut);
    this.activityInputHelper.sendInputStatus(LockType.unlock, e.gridId ?? '', e.rowId ?? '', e.field ?? '');
  }

  private currentUserRoleActionAssignment() {
    if (this.userService.hasOnlyReviewerRights()) {
      this.gridMode = BptGridMode.dataView;
    }
    this.columnDefinitions = StudyActivityTableGridOptions.prepareColumns(this.openModal);
  }

  isUserPermittedToEdit(): boolean {
    if (!(this.userService.hasOnlyReviewerRights() || this.inputsComponent.activityInputComponentsReadonly)) {
      return true;
    }
    return false;
  }

  openModal = (row: any): void => {
    if (this.isModalOpen || !this.isUserPermittedToEdit()) {
      return;
    }
    this.activityInputHelper.sendInputStatus(
      LockType.lock,
      this.grid.gridId ?? '',
      row.data.code ?? '',
      row.column.colId ?? ''
    );
    this.isModalOpen = true;
    this.studyActivityData = [];
    this.showTestDetailsRetrievingMessage(row.data.code);
    this.activityInputService.activityInputRetrieveStudyActivityPost$Json({
      materialAliquotNumber: row.data.code
    }).subscribe({
      next: (response) => {
        this.elnProgressSpinnerService.Hide();
        this.studyActivityData = response.studyActivities;
        this.studyActivitiesWithSelectedData = {
          StudyActivities: this.studyActivityData.length > 0 ? this.studyActivityData : [],
          StudyActivityIds: this.getStudyActivitiesForSelectedStudy(row.data),
          AliquotNumber: row.node.id
        };
        this.dynamicDialogRef = this.dialogService.open(ActivityInputStudyActivitySelectionComponent, {
          width: '45%',
          autoZIndex: true,
          closeOnEscape: true,
          data: this.studyActivitiesWithSelectedData,
          showHeader: true,
          header: $localize`:@@selectStudyActivity:Select Study Activity(s)`,
          styleClass: 'eln-test-dialog'
        });

        this.dynamicDialogRef.onClose.subscribe((params) => {
          this.isModalOpen = false;
          this.activityInputHelper.sendInputStatus(
            LockType.unlock,
            this.grid.gridId ?? '',
            row.data.code ?? '',
            row.column.colId ?? ''
          );
        });
      }
      , error: (error: HttpErrorResponse) => {
        this.elnProgressSpinnerService.Hide();
        this.isModalOpen = false;
        this.messageService.add({
          key: 'notification',
          severity: 'error',
          summary: $localize`:@@StudyActivitiesNotFound:Study Activities Not found for this material`,
          detail: ` ${row.node.key}`
        });
      }
    }
    );
  };

  getStudyActivitiesForSelectedStudy(data: MaterialAliquotDetails) {
    if (data.studyActivities !== null && data.studyActivities.length === 1 && data.studyActivities[0].studyActivityName === NA)
      return [];
    return data.studyActivities.length > 0 ? data.studyActivities.map((ma: StudyActivityDomain) => ma.code) : [];
  }

  private showTestDetailsRetrievingMessage(materialAliquotNumber: string) {
    const spinnerOptions = {
      message: $localize`:@@studyActivityRetreival:Retrieving study Activity details from the server for the material: ${materialAliquotNumber}...`,
      i18nMessage: `@@studyActivityRetreival`
    };
    this.elnProgressSpinnerService.Show(spinnerOptions);
  }

  private augmentColumnsWithCornerFlagProviderForCells(): void {
    const flagConfigProvider = (
      flag: 'top-right' | 'bottom-right',
      rowId: string,
      field: string
    ) => {
      if (flag === this.clientFacingNotesFlag) {
        const note = this.experiment.clientFacingNotes.find((n) =>
          isEqual(n.path, [rowId, field, this.experimentService.currentActivityId])
        ); // can depend on rowId being globally unique
        return {
          enabled: note ? true : false,
          color: ELNAppConstants.ClientFacingNoteFlagColor,
          hoverText: note?.indicatorText ?? '', // by policy for notes, empty is not allowed
          onClick: () => this.showClientFacingNotes(rowId, field)
        };
      }
      if (flag === this.internalCommentsFlag) {
        const internalComments = this.experiment.internalComments?.comments.find(
          (x: CommentResponse) => x.path.includes(rowId) && x.path.includes(field)
            && x.path.includes(this.experimentService.currentActivityId) && x.status !== InternalCommentStatus.Removed
        ); // can depend on rowId being globally unique
        const fieldLabel = this.columnDefinitions.find(columDef => columDef.field === field)?.label;
        return {
          enabled: internalComments ? true : false,
          color: ELNAppConstants.InternalCommentFlagColor,
          isHollow: internalComments?.status === InternalCommentStatus.Pending,
          hoverText: this.activityInputHelper.getHoverOverText(internalComments?.content),
          onClick: () => this.showInternalComments(rowId, field, fieldLabel ?? '')
        };
      }

      return { enabled: false, color: 'orange', hoverText: 'not used today', onClick: () => { } };
    };

    this.columnDefinitions.forEach(
      (c: ColumnDefinition) => (c.flagConfigProvider = flagConfigProvider)
    );
    this.grid?.updateColumnDefinitions();
  }

  onClientFacingNoteEvent(note: ClientFacingNoteModel): void {
    if (note.contextType !== ClientFacingNoteContextType.ActivityInput) return;
    const context = note.context as ActivityInputClientFacingNoteContext;
    context.activityId = this.experimentService.currentActivityId;
    const rowNode = this.grid.gridApi.getRowNode(context.rowId);
    const column = this.grid.gridApi.getColumn(context.columnField);
    if (!rowNode || !column) return;

    this.grid.gridApi.refreshCells({ force: true, rowNodes: [rowNode], columns: [column] });
  }

  private showClientFacingNotes(rowId: string, field: string): void {
    const eventTarget: ActivityInputClientFacingNoteContext = {
      activityInputId: this.experimentService.currentActivityId,
      columnField: field,
      rowId: rowId,
      activityId: this.experimentService.currentActivityId,
      tableTitle: $localize`:@@StudyActivities:Study Activities`,
      label: this.columnDefinitions.find(c => c.field === field)?.label || ''
    };
    const details: ShowClientFacingNotesEventData = {
      eventContext: {
        contextType: ClientFacingNoteContextType.ActivityInput,
        mode: 'clientFacingNotes'
      },
      targetContext: eventTarget
    };
    const customEvent = new CustomEvent<ShowClientFacingNotesEventData>('ShowSlider', {
      bubbles: true,
      detail: details
    });
    this.elementRef.nativeElement.dispatchEvent(customEvent);
  }

  private loadGridPreferences(): void {
    this.preferenceService
      .userPreferencesUserPreferenceKeyGet$Json$Response({
        userPreferenceKey: this.studyActivityId
      })
      .subscribe((res) => {
        this.gridSavedPreferences = {
          enabled: true,
          preferences: this.gridPreferenceService.buildGridPreference(res.body.userPreferences)
        };
      });
  }

  saveNewPreference($event: PreferenceAdded): void {
    this.gridPreferenceService.saveNewPreference($event, this.user.puid, this.gridSavedPreferences, this.grid, this.studyActivityId);
  }

  deletePreference($event: PreferenceDeleted): void {
    this.gridPreferenceService.deletePreference($event);
  }

  updatePreference($event: PreferenceUpdated): void {
    this.gridPreferenceService.updatePreference($event, this.user.puid, this.studyActivityId);
  }

  changeDefaultPreference($event: PreferenceSelectionChanged): void {
    this.gridPreferenceService.changeDefaultPreference($event, this.user.puid, this.studyActivityId);
  }

  public severityIndicatorConfig = (
  ) => {
    return {
      getIndicator: this.getSeverityIndicator //Called by the ag grid cell renderer
    } as ISeverityIndicatorConfig;
  };

  private readonly getSeverityIndicator = (params: ICellRendererParams) => {
    return this.validationHelper.getSeverityIndicatorDefinition(this.studyActivityDataSource, params);
  }

  public severityIndicatorConfigForStudyActivity = () => {
    return {
      getIndicator: this.getSeverityIndicatorForStudyActivity
    } as ISeverityIndicatorConfig;
  };

  private readonly getSeverityIndicatorForStudyActivity = (params: ICellRendererParams) => {
    return this.validationHelper.getSeverityIndicatorDefinitionForStudyActivity(this.studyActivityDataSource, params, this.previousSampleDataSource);
  }

  /**
   * Gets called to load audit history dialog
   */
  loadAuditHistoryDialog(rowId?: string, columnName?: string) {
    this.isLoading = true;
    this.auditHistoryService
      .loadActivityInputsAuditHistory(this.experimentId, this.experimentService.currentActivityId)
      .subscribe((data: AuditHistoryDataRecordResponse) => {
        data.dataRecords = this.getStudyActivityDataRecords(data.dataRecords);
        this.isLoading = false;
        if (!rowId || !columnName) {
          this.allAuditHistory(data);
          return;
        }
        switch (columnName) {
          case ActivityInputConstants.studyActivityCodeColumn:
          case ActivityInputConstants.studyActivityNameColumn:
          case ActivityInputConstants.studyNameColumn:
          case ActivityInputConstants.studyActivityCode:
          case ActivityInputConstants.studyGroupNameColumn:
          case ActivityInputConstants.studyActivityStatusColumn:
          case ActivityInputConstants.studyCodeColumn:
            this.studySelectionAuditHistory(rowId, columnName, data);
            break;
          default:
            this.cellChangedAuditHistory(rowId, columnName, data);
        }
      });
  }

  cellValueChanged(e: BptGridCellValueChangedEvent) {
    this.activityInputHelper.cellValueChanged(e, ActivityInputType.Material);
    const propertyValue = this.dataValueService.getExperimentDataValue(ColumnType.String, e.newValue);
    this.syncCellValueToClientSideMemoryWhenItsChanged(e.rowId || '', propertyValue);
  }

  onGridReady() {
    this.setValidationStyles();
    this.augmentColumnsWithCornerFlagProviderForCells();
    this.activityInputHelper.loadCellLocks(this.grid.gridOptions, this.renderer);
  }

  onFirstDataRendered() {
    this.isDataRendered = true;
  }

  public setValidationStyles = (): void => {
    this.columnDefinitions?.forEach((columnDefinition: ColumnDefinition) => {
      if (columnDefinition.editable) {
        columnDefinition.severityIndicatorConfig = this.severityIndicatorConfig;
      }
      else if (columnDefinition.field === 'studyActivityName') {
        columnDefinition.severityIndicatorConfig = this.severityIndicatorConfigForStudyActivity;
      }
      this.grid?.updateColumnDefinitions(columnDefinition);
    });
  };

  private syncCellValueToClientSideMemoryWhenItsChanged(aliquotNumber: string, newValue: StringValue): void {
    const row = this.studyActivityDataSource.find((row) => row.code === aliquotNumber);
    if (!row) return;
    row.AdditionalInformation = DataValueService.getStringFromValue(newValue);
    this.activityInputHelper.setAndUpdateModifiableDataField(row.modifiableFields, this.additionalInformation, newValue);
    this.grid.gridApi.refreshCells({ force: true });
    this.grid.gridApi.applyTransaction({ update: [row] });
  }

  private handleSubscriptions(): void {
    this.subscriptionList.push(
      this.inputsComponent.readOnlyOnStateChange.subscribe((val) => {
        StudyActivityTableGridOptions.isReadOnly = this.inputsComponent.activityInputComponentsReadonly;
        this.columnDefinitions = val ?
          StudyActivityTableGridOptions.prepareColumns()
          : StudyActivityTableGridOptions.prepareColumns(this.openModal);
        this.addGridActions();
        this.setValidationStyles();
        this.reloadGrid = false;
        setTimeout(() => {
          this.reloadGrid = true;
        }, 200);
        this.grid.applyPreviousSelectedPreference();
      }));
    this.subscriptionList.push(
      this.activityInputHelper.updateStudyActivityTableDataSource.subscribe({
        next: this.refreshDataSource.bind(this)
      })
    );
    this.subscriptionList.push(
      this.barcodeScannerHelper.refreshInputs.subscribe(() => {
        this.refreshDataSource();
      })
    );
    this.subscriptionList.push(
      this.activityInputHelper.updateStudyActivityTableCellChangedDataSource.subscribe({
        next: this.updateCellChangedDataRecord.bind(this)
      }));
    this.subscriptionList.push(
      this.barcodeScannerHelper.studyActivityTableDataSourcePrepared.subscribe((data) => {
        this.refreshDataSource();
      }));
    this.subscriptionList.push(
      this.activityInputHelper.updateStudyActivitySelectedDataSource.subscribe((data) => {
        this.updateStudyActivitySelectedDataRecord(data);
      }));
    this.subscriptionList.push(
      this.activityInputHelper.refreshStudyActivityTableDataSource.subscribe({
        next: this.refreshDataSource.bind(this)
      })
    );
    this.subscriptionList.push(this.experimentNotificationService.inputLockReceiver.subscribe((lock) => {
      this.activityInputHelper.applyCellLock(lock, this.grid?.gridOptions, this.renderer);
    }));
    this.subscriptionList.push(this.experimentService.clientFacingNoteEvents.subscribe((note) =>
      this.onClientFacingNoteEvent(note)
    ));
    this.subscriptionList.push(this.commentService.internalCommentsSliderClosed.subscribe((state) => {
      this.removeHighlightFromCell();
    }));
    this.subscriptionList.push(this.activityInputHelper.materialAliquotRestored.subscribe((aliquotNumber: string) => {
      this.refreshDataSource();
    }));
    this.subscriptionList.push(this.activityInputHelper.studyActivitySelected.subscribe((params: StudyActivities) => {
      this.studyActivitySelectedCallback(params);
    }));
    this.subscriptionList.push(this.commentService.refreshInternalComment.subscribe((currentContext: CommentsResponse) => {
      this.experiment.internalComments = currentContext;
      this.grid.gridApi?.refreshCells({ force: true });
    }));
    this.subscriptionList.push(this.activatedRoute.params.subscribe((params) => {
      const activity = this.experimentService.GetActivityBasedOnParams(params);
      if (!activity) return;
      this.experimentService.currentActivityId = activity.activityId;
      this.refreshDataSource();
    }));
  }

  private allAuditHistory(data: AuditHistoryDataRecordResponse) {
    const history = data.dataRecords.filter((x) => {
      return (
        x !== null &&
        x.eventContext !== null &&
        this.eventsForHistory.indexOf(x.eventContext.eventType) > -1
      );
    });
    history.forEach((x: any) => {
      const column = this.columnDefinitions.find(c => (c.field?.toLowerCase() === x.propertyName?.toLowerCase()) ||
        c.field?.split('.')[1]?.toLowerCase() === x.propertyName?.toLowerCase()
      );
      x.propertyName = column?.label
    })
    this.dynamicDialogRef = this.auditHistoryService.showAuditDialog(
      history,
      $localize`:@@StudyActivities:Study Activities`);
  }

  private updateCellChangedDataRecord(data: ActivityInputCellChangedEventNotification) {
    this.syncCellValueToClientSideMemoryWhenItsChanged(data.activityInputReference, data.propertyValue);
  }

  private updateStudyActivitySelectedDataRecord(data: StudyActivitySelectedEventNotification) {
    if (data.activityId !== this.experimentService.currentActivityId || !this.grid?.gridApi) return;
    const aliquot = this.studyActivityDataSource.find((a) => a.code === data.activityInputReference);
    if (!aliquot) return;
    this.previousSampleDataSource = this.getPreviouslyUpdatedDataSource();
    aliquot.studyActivities = data.studyActivities;
    this.updateStudyProperties(aliquot);
    this.grid.gridApi.applyTransaction({ update: [aliquot] });
    this.completionPercent = this.completionTrackingService.calculateActivityInputsTableCompletionPercent(this.studyActivityDataSource, ActivityInputType.Material);
  }

  private deleteStudyActivityRow(data: ActivityInputStudyActivity) {
    this.activityInputHelper.sendInputStatus(
      LockType.lock,
      this.grid.gridId,
      data.code,
      this.activityInputHelper.rowDeleteIdentifier
    );
    const confirmationMessage = $localize`:@@RowRemovalConfirmationForStudyActivity:Are you sure you want to remove this row?
       The row may restored through use of the View Removed Rows Option.`;
    this.confirmationService.confirm({
      message: `${confirmationMessage}`,
      header: $localize`:@@RowRemovalConfirmationHeader:Confirmation`,
      acceptVisible: true,
      acceptLabel: $localize`:@@confirmationOk:OK`,
      rejectVisible: true,
      rejectLabel: $localize`:@@confirmationCancel:Cancel`,
      closeOnEscape: true,
      dismissableMask: false,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        var command = {
          activityId: this.experimentService.currentActivityId,
          activityInputReference: data.code,
          activityInputType: ActivityInputType.Material,
          experimentId: this.experimentId
        };
        this.activityInputEventsService
          .activityInputEventsRemoveRowPost$Json$Response({ body: command })
          .subscribe({
            next: (response) => {
              this.activityInputHelper.removeAliquotFromStudyActivitySource(data.code, response.body.rowRemovedEventNotification.activityId);
              this.refreshDataSource();
              this.messageService.add({
                key: 'notification',
                severity: 'success',
                summary: $localize`:@@aliquotRemoved:Aliquot Removed Successfully ${response.body.rowRemovedEventNotification.activityInputReference}`
              });
            },
            error: () => {
              this.messageService.add({
                key: 'notification',
                severity: 'error',
                summary: $localize`:@@aliquotRemovedFailed:Failed to remove particular Aliquot`,
                detail: ` ${command.activityInputReference}`
              });
              this.activityInputHelper.sendInputStatus(
                LockType.unlock,
                this.grid.gridId,
                data.code,
                this.activityInputHelper.rowDeleteIdentifier
              );
            }
          });
      },
      reject: () => {
        this.activityInputHelper.sendInputStatus(
          LockType.unlock,
          this.grid.gridId,
          data.code,
          this.activityInputHelper.rowDeleteIdentifier
        );
      }
    });
  };

  viewRemovedRows() {
    this.dynamicDialogRef = this.dialogService.open(StudyActivityTableRemovedRowsComponent, {
      width: '80%',
      autoZIndex: true,
      closable: true,
      closeOnEscape: true,
      data: {
        isReadOnly: this.inputsComponent.activityInputComponentsReadonly
      },
      styleClass: 'eln-removed-study-activity-dialog',
      header: $localize`:@@removedRowsModalHeader:Removed Rows for Table: ${this.studyActivitiesMaterialAliquots}`
    });
  }

  studyActivitySelectedCallback = (studyActivity: StudyActivities) => {
    if (!!studyActivity) {
      this.selectedStudyData = studyActivity.StudyActivities;
      this.prepareStudyCommand(studyActivity.AliquotNumber);
      this.activityInputEventsService
        .activityInputEventsSelectStudyActivityPost$Json({
          body: this.studyActivityCommand
        })
        .subscribe({
          next: (response: any) => this.onSuccessCallback(response, studyActivity),
          error: () => this.onErrorCallback(studyActivity)
        }
        );
    }
  }

  onSuccessCallback(response: any, studyActivity: StudyActivities) {
    this.updateGridDataOnStudyActivitySelect(studyActivity.AliquotNumber);
    this.messageService.add({
      key: 'notification',
      severity: 'success',
      summary: $localize`:@@studyActivitySelectedForMaterialAliquot:Study Activity(s) selected Successfully
       ${response.studyActivitySelectedNotification.studyActivities
          .map((k: StudyActivityDomain) => this.activityInputHelper.formatTestProperties(k.studyActivityName)).join(', ')}
      for this material`,
      detail: `${response.studyActivitySelectedNotification.activityInputReference}`
    });
  }

  onErrorCallback(studyActivity: StudyActivities) {
    this.messageService.add({
      key: 'notification',
      severity: 'error',
      summary: $localize`:@@studyActivitySelectionFailed:study selection failed for this aliquot`,
      detail: `${studyActivity.AliquotNumber}`
    });
  }

  private updateGridDataOnStudyActivitySelect(aliquotNumber: string) {
    if (!this.selectedStudyData) return;
    let row = this.studyActivityDataSource.find(x => x.code === aliquotNumber);
    const existingAliquot = this.activityInputHelper.getMaterialByAliquotNumber(aliquotNumber);
    existingAliquot!.studyActivities = this.selectedStudyData;
    if (!row) return;
    this.previousSampleDataSource = this.getPreviouslyUpdatedDataSource();
    row.studyActivities = this.selectedStudyData;
    row = this.updateStudyProperties(row);
    this.grid?.gridApi?.applyTransaction({
      update: [row]
    });
    this.completionPercent = this.completionTrackingService.calculateActivityInputsTableCompletionPercent(this.studyActivityDataSource, ActivityInputType.Material);
    this.grid?.gridApi?.refreshCells({ force: true });
  }

  getPreviouslyUpdatedDataSource() {
    return cloneDeep(this.studyActivityDataSource);
  }

  private updateStudyProperties(row: ActivityInputStudyActivity): ActivityInputStudyActivity {
    row.studyActivityCode = row.studyActivities.map(t => this.activityInputHelper.formatTestProperties(t.code)).join(', ')
    row.accountCode = row.studyActivities[0]?.accountCode
    row.projectName = row.studyActivities[0]?.projectName
    row.studyActivityName = row.studyActivities.map(t => this.activityInputHelper.formatTestProperties(t.studyActivityName)).join(', ')
    row.studyGroupName = row.studyActivities.map(t => this.activityInputHelper.formatTestProperties(t.studyGroupName)).join(', ')
    row.studyCode = row.studyActivities.map(t => this.activityInputHelper.formatTestProperties(t.studyCode)).join(', ')
    row.studyName = row.studyActivities.map(t => this.activityInputHelper.formatTestProperties(t.studyName)).join(', ')
    row.accountName = row.studyActivities[0]?.accountName
    row.studyActivityStatus = row.studyActivities.map(t => this.activityInputHelper.formatTestProperties(t.studyActivityStatus)).join(', ')
    row.customerAnalyticalProjectCode = row.studyActivities[0]?.customerAnalyticalProjectCode
    const colDef = this.columnDefinitions.find(c => c.field === 'studyActivityName')
    if (colDef) colDef.severityIndicatorConfig = this.severityIndicatorConfigForStudyActivity;
    return row;
  }


  private prepareStudyCommand(aliquotNumber: string) {
    this.studyActivityCommand = {
      experimentId: this.experimentId,
      activityId: this.experimentService.currentActivityId,
      activityInputReference: aliquotNumber,
      activityInputType: ActivityInputType.Aliquot,
      studyActivities: this.selectedStudyData,
    };
  }

  getContextMenu(): GridContextMenuItem[] {
    const contextMenuItems: GridContextMenuItem[] = ['copy', 'copyWithHeaders', 'copyWithGroupHeaders', 'paste', 'separator'];
    contextMenuItems.push(...this.getCustomContextMenuItems());
    return contextMenuItems;
  }

  private getCustomContextMenuItems(): GridContextMenuItem[] {
    return [
      {
        label: $localize`:@@clientFacingNoteContextMenuOption:Client-facing Notes`,
        icon: this.imagePath,
        action: () => this.showCellFlags(this.clientFacingNotesFlag),
        disabled: !this.isUserPermittedToEdit(),
      },
      {
        label: $localize`:@@commentsHeader:Internal Comments`,
        icon: this.imagePath,
        action: () => this.showCellFlags(this.internalCommentsFlag)
      },
      {
        label: $localize`:@@History:History`,
        icon: '<img src="assets/eln-assets/audit-history.svg" class="ag-icon ag-custom-icon" />',
        action: () => this.getHistoryFromContext(),
      }
    ];
  }

  private showCellFlags(flagType: string) {
    const cell = this.grid.gridApi.getFocusedCell();
    if (!cell) return;
    const rowId = this.grid.gridApi.getDisplayedRowAtIndex(cell.rowIndex)?.id;
    const field = cell.column.getColDef().field;
    const fieldLabel = this.columnDefinitions.find(columDef => columDef.field === field)?.label;
    if (!rowId || !field || !fieldLabel) return;
    if (flagType === this.clientFacingNotesFlag) {
      this.showClientFacingNotes(rowId, field);
    } else if (flagType === this.internalCommentsFlag) {
      this.showInternalComments(rowId, field, fieldLabel);
    }
  }

  private showInternalComments(rowId: string, field: string, fieldLabel: string): void {
    const cell = document.querySelector(`ag-grid-angular [row-id="${rowId}"] [col-id="${field}"]`);
    if (cell) {
      this.internalCommentActiveCell = cell;
      this.renderer.setStyle(
        this.internalCommentActiveCell,
        this.backgroundColorString,
        ELNAppConstants.InternalCommentBackGroundColor
      );
    }
    this.buildInternalComments(rowId, field);
  }

  private removeHighlightFromCell() {
    if (this.internalCommentActiveCell) {
      this.renderer.removeStyle(
        this.internalCommentActiveCell,
        this.backgroundColorString
      );
    }
  }

  private buildInternalComments(rowId: string | undefined, field: string) {
    const columnLabel = this.columnDefinitions.find(c => c.field === field)?.label;
    const cell = this.grid && this.grid.gridApi.getFocusedCell();
    if (cell && columnLabel) {
      const rowIndex = this.grid && this.grid.gridApi.getDisplayedRowAtIndex(cell.rowIndex)?.rowIndex;
      if (rowIndex !== null && rowIndex !== undefined && rowId) {
        const activity = this.experiment.activities.find(
          (act: Activity) => act.activityId === this.experimentService.currentActivityId
        );
        if (activity) {
          this.commentService.setUpDataForInternalComments(
            activity.activityId,
            [activity.activityId, columnLabel, rowId, field, (rowIndex + 1).toString(), CommentContextType.StudyActivity],
            CommentContextType.TableCell
          );
        }
        else {
          this.commentService.setUpDataForInternalComments(
            this.experimentService.currentActivityId,
            [this.experimentService.currentActivityId, columnLabel, rowId, field, (rowIndex + 1).toString(), CommentContextType.StudyActivity],
            CommentContextType.TableCell
          );
        }
      }
    }
  }

  private studySelectionAuditHistory(rowId: string, field: string, data: AuditHistoryDataRecordResponse) {
    let cellChangedRecords: ExperimentDataRecordNotification[] = data.dataRecords
      .filter(
        (d): d is StudyActivitySelectedEventNotification =>
          d?.eventContext.eventType.toString() === ExperimentEventType.StudyActivitySelected
      )
      .filter(c => c.activityInputReference === rowId);

    const statementRecords = data.dataRecords
      .filter(
        (d: ExperimentDataRecordNotification): d is StatementAppliedEventNotification =>
          d?.eventContext.eventType.toString() === ExperimentEventType.StatementApplied
      ).filter((c: StatementAppliedEventNotification) => {
        return (c?.contentDetails.some(m => m?.path[0] === rowId && `${m?.path[1].toLowerCase()}` === field.toLowerCase()));
      });
    cellChangedRecords = cellChangedRecords.concat(statementRecords);

    const column = this.columnDefinitions.find(c => c.field === field);
    if (!column) return;
    if (field === ActivityInputConstants.studyActivityNameColumn) {
      this.dynamicDialogRef = this.auditHistoryService.showAuditDialog(
        cellChangedRecords,
        $localize`:@@StudyActivities:Study Activities`.concat(
          ELNAppConstants.WhiteSpace,
          '→',
          ELNAppConstants.WhiteSpace,
          column.label
        ));
      return;
    }
    const changeRecords = data.dataRecords
      .filter(
        (d): d is ActivityInputCellChangedEventNotification => d?.eventContext.eventType.toString() === ExperimentEventType.ActivityInputCellChanged
      )
      .filter(c => (c.activityInputReference === rowId) && c.propertyName.toLowerCase() === field.toLowerCase());
    cellChangedRecords = cellChangedRecords.concat(changeRecords);
    this.dynamicDialogRef = this.auditHistoryService.showAuditDialog(
      cellChangedRecords,
      $localize`:@@StudyActivities:Study Activities`.concat(
        ELNAppConstants.WhiteSpace,
        '→',
        ELNAppConstants.WhiteSpace,
        column.label
      ));
  }

  private cellChangedAuditHistory(
    rowId: string,
    field: string,
    data: AuditHistoryDataRecordResponse
  ) {
    let cellChangedRecords: ExperimentDataRecordNotification[] = data.dataRecords
      .filter(
        (d): d is ActivityInputCellChangedEventNotification => d?.eventContext.eventType.toString() === ExperimentEventType.ActivityInputCellChanged
      )
      .filter((c) => (c.activityInputReference === rowId) && c.propertyName?.toLowerCase() === field.toLowerCase());
    const column = this.columnDefinitions.find(c => c.field === field);
    if (!column) return;
    const statementRecords = data.dataRecords
    .filter(
      (d: any): d is StatementAppliedEventNotification =>
        d?.eventContext.eventType.toString() === ExperimentEventType.StatementApplied
    ).filter((c: StatementAppliedEventNotification) => {
      return (c?.contentDetails.some(m => m?.path[0] === rowId && `${m?.path[1].toLowerCase()}` === field.toLowerCase()));
    });
    cellChangedRecords = cellChangedRecords.concat(statementRecords);
    cellChangedRecords.forEach((x: any) => x.propertyName = column.label);
    this.dynamicDialogRef = this.auditHistoryService.showAuditDialog(
      cellChangedRecords,
      $localize`:@@StudyActivities:Study Activities`.concat(
        ELNAppConstants.WhiteSpace,
        '→',
        ELNAppConstants.WhiteSpace,
        column.label
      ));
  }

  private getHistoryFromContext() {
    const cell = this.grid.gridApi.getFocusedCell();
    if (!cell) return;
    const rowId = this.grid.gridApi.getDisplayedRowAtIndex(cell.rowIndex)?.id;
    const field = cell.column.getColDef()?.field;
    this.loadAuditHistoryDialog(rowId, field);
  }

  private refreshRows(data: MaterialRowRefreshedDetails) {
    const aliquotRowData = this.studyActivityDataSource.find((row) => row.code === data.activityInputReference) as any;
    if (!aliquotRowData) return;
    if (aliquotRowData.materialStatus === this.barcodeScannerHelper.excludedMaterialStatus) {
      aliquotRowData.isRefreshed = true;
    }

    mapValues(data.modifiedFields, (item: any) => {
      mapValues(Object.keys(aliquotRowData), (propertyName: string) => {
        if (propertyName.toLowerCase() === item.propertyName.toLowerCase()) {
          aliquotRowData[propertyName] = item.propertyValue.value;
        }
      });
    });
    this.previousSampleDataSource = this.getPreviouslyUpdatedDataSource();
    this.refreshStudyActivityDetails(aliquotRowData, data);
    this.grid.gridApi.applyTransaction({
      update: [aliquotRowData]
    });
    this.completionPercent = this.completionTrackingService.calculateActivityInputsTableCompletionPercent(this.studyActivityDataSource, ActivityInputType.Material);
  }

  private refreshStudyActivityDetails(aliquotRowData: any, data: MaterialRowRefreshedDetails) {
    if (aliquotRowData.studyActivities) {
      mapValues(data.modifiedFields, (item: any) => {
        mapValues(Object.keys(aliquotRowData.studyActivities), (propertyName: string) => {
          if (propertyName.toLowerCase() === item.propertyName.toLowerCase()) {
            aliquotRowData.studyActivities[propertyName] = item.propertyValue.value;
          }
        });
      });
      this.previousSampleDataSource = this.getPreviouslyUpdatedDataSource();
      aliquotRowData = this.updateStudyProperties(aliquotRowData);
      this.grid?.gridApi?.applyTransaction({
        update: [aliquotRowData]
      });
      this.grid?.gridApi?.refreshCells({ force: true });
    }
  }

  private refreshStudyActivityRow(data: ActivityInputStudyActivity) {
    const aliquotNumber = data.code;
    this.activityInputHelper.sendInputStatus(
      LockType.lock,
      this.grid.gridId,
      aliquotNumber,
      this.activityInputHelper.rowRefreshIdentifier
    );
    const cell = document.querySelector(
      `ag-grid-angular [row-id="${aliquotNumber}"] [id="${this.activityInputHelper.rowRefreshIdentifier}"] `
    );
    this.renderer.addClass(cell, this.pendingImgClass);
    const aliquot = cloneDeep(this.studyActivityDataSource.find(x => x.code === aliquotNumber) as ActivityInputStudyActivity);
    aliquot.modifiableFields = [];
    var command = this.getRefreshActivityInputCommand(aliquot)
    this.activityInputEventsService
      .activityInputEventsRefreshRowPost$Json$Response({
        body: command
      })
      .subscribe({
        next: (data) => {
          this.renderer.removeClass(cell, this.pendingImgClass);
          this.activityInputHelper.sendInputStatus(
            LockType.unlock,
            this.grid.gridId,
            aliquotNumber,
            this.activityInputHelper.rowRefreshIdentifier
          );
          this.messageService.add({
            key: 'notification',
            severity: 'success',
            summary: $localize`:@@AliquotRefreshSuccess:Aliquot Refreshed Successfully ${aliquotNumber}`
          });
          const materialDetails = data.body.rowRefreshedEventNotification.materialsDetails[0];
          if (this.checkAndRemoveAliquotIfAllStudyActivitiesUnlinked(materialDetails)) return;
          materialDetails.studyActivities = command.materialAliquotDetails ?
            command.materialAliquotDetails[0].studyActivities : [];
          this.refreshRows(materialDetails);
        },
        error: () => {
          this.activityInputHelper.sendInputStatus(
            LockType.unlock,
            this.grid.gridId,
            aliquotNumber,
            this.activityInputHelper.rowRefreshIdentifier
          );
          this.renderer.removeClass(cell, this.pendingImgClass);
        }
      });
  };

  ngOnDestroy(): void {
    UnsubscribeAll(this.subscriptionList);
  }

  private checkAndRemoveAliquotIfAllStudyActivitiesUnlinked(data: MaterialRowRefreshedDetails): boolean {
    const aliquotRowData = this.studyActivityDataSource.filter((row) => row.code === data.activityInputReference)[0];
    if (data.studyActivities.length > 0) return false;
    var command = {
      activityId: this.experimentService.currentActivityId,
      activityInputReference: aliquotRowData.code,
      activityInputType: ActivityInputType.Material,
      experimentId: this.experimentId
    };
    this.activityInputEventsService
      .activityInputEventsRemoveRowPost$Json$Response({ body: command })
      .subscribe({
        next: (response) => this.activityInputHelper.removeAliquotFromStudyActivitySource(aliquotRowData.code, response.body.rowRemovedEventNotification.activityId)
      });
    aliquotRowData.isRemoved = true;
    aliquotRowData.isRefreshed = true;
    this.messageService.add({
      key: 'notification',
      severity: 'error',
      summary: $localize`:@@RemovedAliquotsOnRefresh:The following material aliquot(s) no longer have associated study activities and were removed from Activity Inputs`,
      detail: `${data.activityInputReference}`,
      sticky: true
    });
    this.refreshDataSource();
    return true;
  }

  private getRefreshActivityInputCommand(aliquot: MaterialAliquot): RefreshActivityInputRowCommand {
    return {
      materialAliquotDetails: [aliquot],
      experimentId: this.experimentId,
      activityInputType: ActivityInputType.Material,
      activityId: this.experimentService.currentActivityId
    };
  }

  private addGridActions() {
    this.rowActions = this.getRowActionItems();
    const actionsSubject: BehaviorSubject<BptRowActionElement[]> = new BehaviorSubject(this.rowActions);

    this.gridActions = {
      actions: actionsSubject
    };
  }

  public getRowActionItems(): BptRowActionElement[] {
    return [
      {
        id: this.activityInputHelper.rowDeleteIdentifier,
        enabled: this.isUserPermittedToEdit(),
        styleClass: 'far fa-trash-alt',
        click: (e: BptGridRowActionClickEvent) => this.deleteStudyActivityRow(e.params.data),
        tooltip: $localize`:@@RemoveItem:Remove item`
      },
      {
        id: this.activityInputHelper.rowRefreshIdentifier,
        enabled: this.isUserPermittedToEdit(),
        styleClass: 'fas fa-sync-alt',
        click: (e: BptGridRowActionClickEvent) => this.refreshStudyActivityRow(e.params.data),
        tooltip: $localize`:@@RefreshItem:Refresh item`
      }
    ];
  }

  private getStudyActivityDataRecords(dataRecords: ExperimentDataRecordNotification[]): ExperimentDataRecordNotification[] {
    return dataRecords.filter((x: any) => x &&
      (x.activityInputType === ActivityInputType.Material ||
        x.eventContext.eventType === ExperimentEventType.MaterialAdded ||
        x.eventContext.eventType === ExperimentEventType.StudyActivitySelected) ||
      (x.eventContext.eventType === ExperimentEventType.StatementApplied && (x as StatementAppliedEventNotification).contentDetails
        ?.some(x => x.path && x.path.length > 0 && x.path[3] === StudyActivitiesTitle)))
  }
}
