import { Route } from '@angular/router';
import { AuthGuard } from './routing/auth/auth.guard';
import { NotFoundComponent } from './not-found/not-found.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';

export const APP_ROUTES: Route[] = [
  {
    path: '',
    canActivateChild: [AuthGuard],
    children: [
      {
        path: '',
        // For eager loading, comment out loadChildren, uncomment children,
        // add MainModule to imports in app.module.ts, and remove forChild() call
        // on RouterModule import in main.module.ts
        loadChildren: () => import('./main/main.module').then((m) => m.MainModule)
        //children: MAIN_ROUTES,
      },
      {
        path: 'template-designer',
        loadChildren: () =>
          import('./template-designer/template-designer.module').then(
            (m) => m.TemplateDesignerModule
          )
      },
      {
        path: 'experiment',
        loadChildren: () => import('./experiment/experiment.module').then((m) => m.ExperimentModule)
      },
      {
        path: 'recipe',
        loadChildren: () => import('./recipe/recipe.module').then((m) => m.RecipeModule)
      },
      { path: 'unauthorized', component: UnauthorizedComponent },
      { path: '**', component: NotFoundComponent }
    ]
  }
];
