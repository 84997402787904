import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Unit, UnitList } from '../../api/models';
import { ClientValidationDetails } from '../../model/client-validation-details';
import { NgForm } from '@angular/forms';

/** 
 * Type data for the input location being designed 
 * Approximately the same as those of FieldType and ColumnType that are currently used in ELN plus any special values affection the designers.
 * This values are maintained separately from FieldType and ColumnType because of special values as well as the potential for subtypes to evolve.
 * 
 * Note: rowId is a column type but CommonPropertiesComponent isn't invoked for it. It's defined here so that consuming code 
 * can compute their data type value for more comprehensively.
 */
export enum DataType {
  DateAndTime = 'dateAndTime',
  DropdownList = 'dropdownList',  
  FieldGroup = 'fieldGroup',
  Index = 'index',
  KeyColumn = 'keyColumn',
  Quantity = 'quantity',
  rowId = 'rowId',
  specification = 'specification',
  stepNumber = 'stepNumber',
  textArea = 'textArea',
  textBlock = 'textBlock',
  textEntry = 'textEntry',
}

@Component({
  selector: 'app-common-properties',
  templateUrl: './common-properties.component.html',
  styleUrls: ['./common-properties.component.scss']
})
export class CommonPropertiesComponent {
  @Input() dataType!: DataType;
  @Input() label!: string;
  @Input() fieldValidation?: ClientValidationDetails;
  @Input() hidden = false;
  @Input() isReadOnly = false;
  @Input() labelValidation?: ClientValidationDetails;
  @Input() field!: string;

  @Output() fieldChange = new EventEmitter<string>();
  @Output() labelChange = new EventEmitter<string>();
  @Output() setDefaults = new EventEmitter<void>();

  @ViewChild('form') form!: NgForm;

  get labelIsRendered(): boolean {
    switch (this.dataType) {
      case DataType.rowId: return false;
      case DataType.textBlock: return false;
      default: return true;
    }    
  }

  get labelIsReadonly(): boolean {
    if (this.isReadOnly) return true;
    switch (this.dataType) {
      case DataType.Index: return true;
      case DataType.rowId: return true;
      case DataType.textBlock: return true;
      default: return false;
    }
  }
  
  get fieldIsRendered(): boolean {
    switch (this.dataType) {
      case DataType.rowId: return false;
      default: return true;
    }    
  }
  
  get fieldIsReadonly(): boolean {
    if (this.isReadOnly) return true;
    switch (this.dataType) {
      case DataType.Index: return true;
      case DataType.KeyColumn: return true;
      case DataType.rowId: return true;
      default: return false;
    }
  }
  
  everyUnit: Array<Unit> = [];
  applyUnitList: Array<UnitList> = [];

  setDefaultsSettings() {
    this.setDefaults.emit();
  }

  onLabelChange(label: string) {
    this.labelChange.emit(label);
  }

  onFieldChange(field: string) {
    this.fieldChange.emit(field);
  }
}
