/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { UserPicklistAppendItemResponse } from '../models/user-picklist-append-item-response';
import { UserPicklistCreateResponse } from '../models/user-picklist-create-response';
import { UserPicklistResponse } from '../models/user-picklist-response';
import { userPicklistsAppendItemIdPost$Json } from '../fn/user-picklists/user-picklists-append-item-id-post-json';
import { UserPicklistsAppendItemIdPost$Json$Params } from '../fn/user-picklists/user-picklists-append-item-id-post-json';
import { userPicklistsAppendItemIdPost$Plain } from '../fn/user-picklists/user-picklists-append-item-id-post-plain';
import { UserPicklistsAppendItemIdPost$Plain$Params } from '../fn/user-picklists/user-picklists-append-item-id-post-plain';
import { userPicklistsCreatePicklistPost$Json } from '../fn/user-picklists/user-picklists-create-picklist-post-json';
import { UserPicklistsCreatePicklistPost$Json$Params } from '../fn/user-picklists/user-picklists-create-picklist-post-json';
import { userPicklistsCreatePicklistPost$Plain } from '../fn/user-picklists/user-picklists-create-picklist-post-plain';
import { UserPicklistsCreatePicklistPost$Plain$Params } from '../fn/user-picklists/user-picklists-create-picklist-post-plain';
import { userPicklistsGet$Json } from '../fn/user-picklists/user-picklists-get-json';
import { UserPicklistsGet$Json$Params } from '../fn/user-picklists/user-picklists-get-json';
import { userPicklistsGet$Plain } from '../fn/user-picklists/user-picklists-get-plain';
import { UserPicklistsGet$Plain$Params } from '../fn/user-picklists/user-picklists-get-plain';
import { userPicklistsIdGet$Json } from '../fn/user-picklists/user-picklists-id-get-json';
import { UserPicklistsIdGet$Json$Params } from '../fn/user-picklists/user-picklists-id-get-json';
import { userPicklistsIdGet$Plain } from '../fn/user-picklists/user-picklists-id-get-plain';
import { UserPicklistsIdGet$Plain$Params } from '../fn/user-picklists/user-picklists-id-get-plain';

@Injectable({ providedIn: 'root' })
export class UserPicklistsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `userPicklistsCreatePicklistPost()` */
  static readonly UserPicklistsCreatePicklistPostPath = '/user-picklists/create-picklist';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userPicklistsCreatePicklistPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userPicklistsCreatePicklistPost$Plain$Response(params?: UserPicklistsCreatePicklistPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UserPicklistCreateResponse>> {
    return userPicklistsCreatePicklistPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userPicklistsCreatePicklistPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userPicklistsCreatePicklistPost$Plain(params?: UserPicklistsCreatePicklistPost$Plain$Params, context?: HttpContext): Observable<UserPicklistCreateResponse> {
    return this.userPicklistsCreatePicklistPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserPicklistCreateResponse>): UserPicklistCreateResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userPicklistsCreatePicklistPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userPicklistsCreatePicklistPost$Json$Response(params?: UserPicklistsCreatePicklistPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<UserPicklistCreateResponse>> {
    return userPicklistsCreatePicklistPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userPicklistsCreatePicklistPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userPicklistsCreatePicklistPost$Json(params?: UserPicklistsCreatePicklistPost$Json$Params, context?: HttpContext): Observable<UserPicklistCreateResponse> {
    return this.userPicklistsCreatePicklistPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserPicklistCreateResponse>): UserPicklistCreateResponse => r.body)
    );
  }

  /** Path part for operation `userPicklistsAppendItemIdPost()` */
  static readonly UserPicklistsAppendItemIdPostPath = '/user-picklists/append-item/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userPicklistsAppendItemIdPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userPicklistsAppendItemIdPost$Plain$Response(params: UserPicklistsAppendItemIdPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UserPicklistAppendItemResponse>> {
    return userPicklistsAppendItemIdPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userPicklistsAppendItemIdPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userPicklistsAppendItemIdPost$Plain(params: UserPicklistsAppendItemIdPost$Plain$Params, context?: HttpContext): Observable<UserPicklistAppendItemResponse> {
    return this.userPicklistsAppendItemIdPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserPicklistAppendItemResponse>): UserPicklistAppendItemResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userPicklistsAppendItemIdPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userPicklistsAppendItemIdPost$Json$Response(params: UserPicklistsAppendItemIdPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<UserPicklistAppendItemResponse>> {
    return userPicklistsAppendItemIdPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userPicklistsAppendItemIdPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userPicklistsAppendItemIdPost$Json(params: UserPicklistsAppendItemIdPost$Json$Params, context?: HttpContext): Observable<UserPicklistAppendItemResponse> {
    return this.userPicklistsAppendItemIdPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserPicklistAppendItemResponse>): UserPicklistAppendItemResponse => r.body)
    );
  }

  /** Path part for operation `userPicklistsIdGet()` */
  static readonly UserPicklistsIdGetPath = '/user-picklists/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userPicklistsIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  userPicklistsIdGet$Plain$Response(params: UserPicklistsIdGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UserPicklistResponse>> {
    return userPicklistsIdGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userPicklistsIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userPicklistsIdGet$Plain(params: UserPicklistsIdGet$Plain$Params, context?: HttpContext): Observable<UserPicklistResponse> {
    return this.userPicklistsIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserPicklistResponse>): UserPicklistResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userPicklistsIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  userPicklistsIdGet$Json$Response(params: UserPicklistsIdGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<UserPicklistResponse>> {
    return userPicklistsIdGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userPicklistsIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userPicklistsIdGet$Json(params: UserPicklistsIdGet$Json$Params, context?: HttpContext): Observable<UserPicklistResponse> {
    return this.userPicklistsIdGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserPicklistResponse>): UserPicklistResponse => r.body)
    );
  }

  /** Path part for operation `userPicklistsGet()` */
  static readonly UserPicklistsGetPath = '/user-picklists';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userPicklistsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  userPicklistsGet$Plain$Response(params?: UserPicklistsGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<UserPicklistResponse>>> {
    return userPicklistsGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userPicklistsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userPicklistsGet$Plain(params?: UserPicklistsGet$Plain$Params, context?: HttpContext): Observable<Array<UserPicklistResponse>> {
    return this.userPicklistsGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UserPicklistResponse>>): Array<UserPicklistResponse> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userPicklistsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  userPicklistsGet$Json$Response(params?: UserPicklistsGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<UserPicklistResponse>>> {
    return userPicklistsGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userPicklistsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userPicklistsGet$Json(params?: UserPicklistsGet$Json$Params, context?: HttpContext): Observable<Array<UserPicklistResponse>> {
    return this.userPicklistsGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UserPicklistResponse>>): Array<UserPicklistResponse> => r.body)
    );
  }

}
