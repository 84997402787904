import { Component, OnInit, Output, EventEmitter, OnDestroy, ViewChild } from '@angular/core';
import { ClientValidationDetails } from 'model/client-validation-details';
import { ExperimentWorkflowState, ReservedInstancePurpose } from '../../api/models';
import { ClientStateService } from 'services/client-state.service';
import { ClientState, AccessibilityTypes } from '../../app.states';
import { Subscription, filter } from 'rxjs';
import { BaseComponent } from '../../base/base.component';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Experiment } from 'model/experiment.interface';
import { BarcodeScannerHelper } from 'services/barcode-scanner-helper';
import { SampleTableComponent } from './sample-table/sample-table.component';
import { StudyActivityTableComponent } from './study-activity-table/study-activity-table.component';
import { InstrumentEventComponent } from './instrument-event/instrument-event.component';
import { DataRecordService } from '../services/data-record.service';
import { WorkflowEventNotification } from '../../api/data-entry/models';
import { ExperimentService } from '../services/experiment.service';

@Component({
  selector: 'app-inputs',
  templateUrl: './inputs.component.html',
  styleUrls: ['./inputs.component.scss']
})
export class InputsComponent extends BaseComponent implements OnInit, OnDestroy {
  activityInputComponentsReadonly = true;
  scrollable = false;
  isLoading = false;
  message!: string;
  experiment?: Experiment;
  validation!: ClientValidationDetails;
  experimentStartedStateSubscription?: Subscription;
  experimentWorkFlowStateNotificationSubscription?: Subscription;
  activityInputsDoNotExist = true;
  isReviewerOrSupervisor = false;
  isScannedWithoutTemplate = false;
  specifyNoData = $localize`:@@NoScannedItem:No inputs have been scanned`;
  currentActivityId : string | undefined;
  reloadAll = true;
  private routeSubscription?: Subscription;
  @Output() readOnlyOnStateChange: EventEmitter<any> = new EventEmitter();

  @ViewChild('sampleAliquots') sampleAliquots?: SampleTableComponent;
  @ViewChild('materialAliquots') materialAliquots?: StudyActivityTableComponent;
  @ViewChild('instruments') instruments?: InstrumentEventComponent;

  constructor(
    readonly clientStateService: ClientStateService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly barcodeScannerHelper: BarcodeScannerHelper,
    private readonly dataRecordService: DataRecordService,
    private readonly experimentService: ExperimentService,
  ) {
    super(clientStateService, route);
  }

  ngOnInit(): void {
    this.watchActivitySelectionChanges();
    this.routeSubscription = this.route.params.subscribe((params) => {
      this.currentActivityId = this.experimentService.GetActivityBasedOnParams(params)?.activityId;
      this.checkAliquotsExist();
      this.barcodeScannerHelper.activityInputSelected.next(true);
    });
    this.experimentStartedStateSubscription =
      this.barcodeScannerHelper.experimentService.experimentWorkFlowState.subscribe((data: ExperimentWorkflowState) =>
        this.setupInputsPage(data)
      );
      this.experimentWorkFlowStateNotificationSubscription =
      this.dataRecordService.experimentWorkFlowDataRecordReceiver.subscribe((notification: WorkflowEventNotification) =>
        this.setupInputsPage(notification.state)
      );
      this.barcodeScannerHelper.instrumentEventDataSourcePrepared.subscribe(() => {
        this.activityInputsDoNotExist = false;
    })
    this.barcodeScannerHelper.activityInputSelected.next(true);
    if (this.experiment) this.setupInputsPage(this.experiment.workflowState);
  }

  ngOnDestroy(): void {
    this.routeSubscription?.unsubscribe();
    this.experimentStartedStateSubscription?.unsubscribe();
    this.experimentWorkFlowStateNotificationSubscription?.unsubscribe();
    this.barcodeScannerHelper.activityInputSelected.next(false);
  }

  private setupInputsPage(workflowState: ExperimentWorkflowState) {
    const visibility = this.clientStateService.getClientStateVisibility(
      ClientState.EXPERIMENT_ACTIVITIES
    );
    this.isReviewerOrSupervisor = !this.clientStateService
      .getFeatureFlags(this.clientState)
      .find((data) => {
        const receivedData = JSON.parse(data);
        return (
          receivedData.CanEditExperimentInReviewState &&
          receivedData.CanEditExperimentInReviewState === true
        );
      })
      ? false
      : true;
    const editableInReviewState =
      workflowState === ExperimentWorkflowState.InReview && this.isReviewerOrSupervisor;

    if (
      visibility === AccessibilityTypes.ReadWrite &&
      (workflowState === ExperimentWorkflowState.Setup ||
        workflowState === ExperimentWorkflowState.InProgress ||
        workflowState === ExperimentWorkflowState.InCorrection ||
        editableInReviewState)
    ) {
      this.activityInputComponentsReadonly = false;
    } else {
      this.activityInputComponentsReadonly = true;
    }
    this.readOnlyOnStateChange.emit(this.activityInputComponentsReadonly);
  }

  private checkAliquotsExist() {
    this.experiment = this.barcodeScannerHelper.experimentService.currentExperiment;
    this.isScannedWithoutTemplate = this.barcodeScannerHelper.experimentService.currentExperiment?.activities.length === 0;
    const instanceId = this.barcodeScannerHelper.experimentService.currentExperiment?.reservedInstances.find(
        (s) => s.purpose === ReservedInstancePurpose.Activity)?.instanceId;
    if (!this.currentActivityId) return;
    this.barcodeScannerHelper.experimentService.currentActivityId =
      this.isScannedWithoutTemplate && instanceId ? instanceId : this.currentActivityId;
    const activityInput = this.barcodeScannerHelper.experimentService.currentExperimentResponse?.activityInputs.find(
        (a: any) => a.activityId === this.barcodeScannerHelper.experimentService.currentActivityId);
    const aliquots = activityInput?.aliquots;
    const materials = activityInput?.materials;
    const instruments = activityInput?.instruments;
    this.activityInputsDoNotExist = !aliquots && !materials && !instruments;
  }

  private watchActivitySelectionChanges() {
    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe((route: { url: string }) => {
        if (route.url.toLowerCase().includes('/inputs')) {
          this.reloadAll = false;
          setTimeout(() => {
            this.reloadAll = true;
          });
        }
      });
  }
}
