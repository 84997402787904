import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { loadTranslations } from '@angular/localize';
import { I18nConfig } from 'model/i18n-config.interface';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Logger } from './logger.service';
import { TranslationService } from 'bpt-ui-library/services';

@Injectable({
  providedIn: 'root'
})
export class I18nConfigService {
  private readonly urlFormat = (languageCode: string) =>
    `/assets/eln-assets/i18n/messages.${languageCode}.json`;

  public static userLocale = 'en-US';

  constructor(
    private readonly httpClient: HttpClient,
    private readonly logger: Logger,
    private readonly translationService: TranslationService
  ) {}

  initialize(): Observable<any> | undefined {
    // Fall back to the default language if none of the configured ones are supported
    const language =
      this.getTranslationLanguage(navigator.languages) || environment.defaultLanguage;
    // We don't need to load translations for the default language
    if (language !== environment.defaultLanguage) {
      const messagesUrl = this.urlFormat(language);
      this.logger.logDebug(`Loading translations for "${language}" from ${messagesUrl}`);
      return this.httpClient.get<I18nConfig>(messagesUrl).pipe(
        catchError((error: HttpErrorResponse) => {
          // If for some reason we can't load the translation (file not found or it isn't valid JSON),
          // we'll log a warning and end up using the default language
          this.logger.logWarning(
            `There was an unexpected error loading translations for "${language}": ${error.message}`
          );
          return of<I18nConfig>();
        }),
        tap((i18nConfig: I18nConfig) => {
          I18nConfigService.userLocale = i18nConfig.locale;
          //this calls the Bpt-ui-library function to set the locale to render corresponding
          //translation files in PrimeNG, AGGrid and custom text
          this.translationService.setTranslation(i18nConfig.locale);
          this.applyTranslations(i18nConfig.translations);
        })
      );
    } else {
      return undefined;
    }
  }

  // This is a helper function to assist in testing
  applyTranslations(translations: Record<string, string>) {
    loadTranslations(translations);
  }

  // Gets the first supported language from a list of languages.
  // Returns undefined if no languages provided or none are found.
  private getTranslationLanguage(languages: readonly string[]): string | undefined {
    if (!languages) {
      return undefined;
    }
    // Assumes languages are in order of preference
    let targetLanguage = undefined;
    // Find the first language that matches a supported language
    languages.some((language) => {
      if (environment.supportedLanguages.includes(language.toLowerCase())) {
        targetLanguage = language;
        return true;
      } else {
        // If we didn't find the exact language, we look for the 2-character version and use that if found
        const twoDigitCode = language.slice(0, 2).toLowerCase();
        if (environment.supportedLanguages.includes(twoDigitCode)) {
          targetLanguage = twoDigitCode;
          return true;
        }
      }
      return false;
    });
    return targetLanguage;
  }
}
