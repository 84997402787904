<div id="eln-barcodeScannerSliderContainer" class="barcode-scanner-slider-container" *ngIf="sliderOptions.visible">
  <bpt-slider
    id="eln-barcodeScannerSlider"
    #elnBarcodeSlider
    [(visible)]="sliderOptions.visible"
    [closeOnEscape]="sliderOptions.closeOnEscape"
    [headerText]="sliderOptions.headerText"
    [size]="sliderOptions.size"
    (visibleChange)="performSliderClosingActivity($event)"
    [displayFooter]="sliderOptions.displayFooter"
    [isModal]="!isBarcodeOpenedManually">
    <div id="eln-barcodeScannerContainer" class="barcode-scanner-container">
      <div class="barcode-scanner-input-container">
        <div id="eln-barcodeScannerIcon" class="barcode-scanner-icon">
          <em class="pi pi-qrcode barcode-icon-size"></em>
        </div>
        <div id="eln-barcodeScannerText" class="barcode-scanner-text">
          <bpt-text-input
            #elnBarcodeScannerTextInput
            [(ngModel)]="scanInputModel"
            [highlightAllOnFocus]="true"
            placeholder="{{textInputPlaceHolder}}"
            [suppressContextMenu]="true"
            label="{{textInputLabel}}"
            (textInputKeyUp)="addScanItem($event)"
            [disabled]="disabledTextInput"></bpt-text-input>
        </div>
      </div>
      <br />
      <div>
        <label for="selectedActivityRadioButton">Select Activities</label>
      </div>
      <div class="eln-activitiesCheckList" *ngIf="experimentActivities.length !== 0">
        <div class="flex flex-column gap-3">
          <p-checkbox class="eln-checkBox" *ngFor="let activity of experimentActivities"
            [value]="activity"
            [(ngModel)]="selectedActivitiesToScan"
            (ngModelChange)="activitySelectionChanged()"
            label={{activity.activityName}}
            [disabled]="disableInputField(activity)">
          </p-checkbox>
        </div>
      </div>
      <div id="eln-pendingScanListContainer" class="pending-scan-list-container">
        <div id="eln-pendingScanListContainer" class="pending-scan-no-data"
          *ngIf="barcodeScannerHelper.pendingScanListItems.length === 0">
          <img src="../../../assets/eln-assets/no-data.svg" alt="" />
          <span class="pending-scan-no-data-text">{{noDataText}}</span>
        </div>
        <div id="eln-pendingScanItem"
          *ngIf="barcodeScannerHelper.pendingScanListItems.length !== 0 && displayScannedActivityTree">
          <bpt-tree [treeData]="scannedItemsToDisplay" [isExpandOrCollapseTree]="true" [bptTreeCustomIconsList]="iconList" 
          [bptTreeCustomIcon]= "true"/>
        </div>
      </div>
    </div>
  </bpt-slider>
</div>