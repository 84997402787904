import { Injectable } from '@angular/core';
import { Activity, Module } from 'model/experiment.interface';
import { Subject, BehaviorSubject } from 'rxjs';
import { ModuleNode, TemplateType } from '../../../api/models';
import {
  ExperimentTemplateApplyCommand,
  NotificationResult  
} from '../../../api/data-entry/models';
import { RecipeModel } from '../../../recipe/model/recipe';
import { RecipeAddTemplateCommand, RecipeDeleteTemplateCommand, RecipeTemplateAddedResponse, RecipeTemplateDeletedResponse, RecipeType } from '../../../api/cookbook/models';

export type ActiveTemplateModule = Module | ModuleNode;
export type ActiveTemplate = Activity | ActiveTemplateModule | undefined;
@Injectable({
  providedIn: 'root'
})
export class TemplateEventService {
  // Events
  private readonly templateApplyCommandFinalized = new Subject<{command:RecipeAddTemplateCommand, number:string}>();
  public TemplateApplyCommandFinalized = this.templateApplyCommandFinalized.asObservable();

  private readonly activeTemplateChanged = new BehaviorSubject<ActiveTemplate>(undefined);
  public ActiveTemplateChanged = this.activeTemplateChanged.asObservable();

  private readonly lastUpdatedTemplateApplyCommand = new BehaviorSubject<
    ExperimentTemplateApplyCommand | undefined | RecipeAddTemplateCommand
  >(undefined);
  public LastUpdatedTemplateApplyCommand = this.lastUpdatedTemplateApplyCommand.asObservable();

  private readonly templateAppliedResponse = new Subject<RecipeTemplateAddedResponse>();
  public TemplateAppliedResponse = this.templateAppliedResponse.asObservable();

  private readonly templateApplyFailed = new Subject<NotificationResult>();
  public TemplateApplyFailed = this.templateApplyFailed.asObservable();

  private readonly templateApplySuccess = new Subject<{command: RecipeAddTemplateCommand, response:RecipeTemplateAddedResponse, number:string}>();
  public TemplateApplied = this.templateApplySuccess.asObservable();

  private readonly templateDeleteSuccess = new Subject<{ command: RecipeDeleteTemplateCommand, response: RecipeTemplateDeletedResponse, number: string }>();
  public TemplateDeleted = this.templateDeleteSuccess.asObservable();

  public readonly recipeRefreshed = new Subject<RecipeModel>();
  public RecipeRefreshed = this.recipeRefreshed.asObservable();

  // Event through delegates
  public LoadedItem: () => RecipeModel | undefined = () => {
    return undefined;
  };

  public PublishApplyRecipeTemplateCommand(command: RecipeAddTemplateCommand, templateNumber:string): void {
    this.templateApplyCommandFinalized.next({command:command, number:templateNumber});
  }
  
  public assessTypeOnLoad: (
    newlyLoadedTemplateType: TemplateType
  ) => RecipeType = () => {
    return RecipeType.None;
  };

  public TemplateAppliedResponseNotification(response: RecipeTemplateAddedResponse): void {
    this.templateAppliedResponse.next(response);
  }

  public TemplateSelectionChangedNotification(activeTemplate: ActiveTemplate): void {
    this.activeTemplateChanged.next(activeTemplate);
  }

  public ApplyTemplateCommandChangedNotification(command: ExperimentTemplateApplyCommand | RecipeAddTemplateCommand): void {
    this.lastUpdatedTemplateApplyCommand.next(command);
  }

  public TemplateApplyFailedNotification(notificationResult: NotificationResult): void {
    this.templateApplyFailed.next(notificationResult);
  }

  public TemplateApplySuccessNotification(command: RecipeAddTemplateCommand,
    successResponse: RecipeTemplateAddedResponse, number = ''
  ): void {
    this.templateApplySuccess.next({command:command,response:successResponse, number:number});
  }

  public TemplateDeletedSuccessNotification(command: RecipeDeleteTemplateCommand,
    successResponse: RecipeTemplateDeletedResponse, number = ''
  ): void {
    this.templateDeleteSuccess.next({command:command,response:successResponse, number:number});
  }

  public RecipeHasRefreshed(recipe: RecipeModel): void {
    this.recipeRefreshed.next(recipe);
  }
}
