import { AfterViewInit, Component, Input, OnDestroy } from '@angular/core';
import { ExperimentCollaboratorsService } from 'services/experiment-collaborators.service';
import { ExperimentNotificationService } from 'services/experiment-notification.service';
import { environment } from '../../../environments/environment';
import {
  ApplicationStateService,
  ApplicationStatus
} from '../../experiment/services/application-state.service';

export enum ConnectionStatus {
  connected = 'Connected',
  reconnecting = 'Reconnecting',
  disconnected = 'Disconnected'
}

@Component({
  selector: 'app-experiment-notification',
  templateUrl: './experiment-notification.component.html',
  styleUrls: ['./experiment-notification.component.scss']
})
export class ExperimentNotificationComponent implements AfterViewInit, OnDestroy {
  private readonly notificationService: ExperimentNotificationService;
  private readonly disconnectedClass = 'eln-disConnected-status';
  public readonly connectedClass = 'eln-connected-status';
  private readonly reconnectingClass = 'eln-reConnecting-status';
  connectionStatusMessage = $localize`:@@notConnected:Not Connected`;
  private readonly applicationStateService: ApplicationStateService;

  constructor(
    experimentNotificationService: ExperimentNotificationService,
    _applicationStateService: ApplicationStateService,
    private readonly experimentCollaboratorService: ExperimentCollaboratorsService
  ) {
    this.notificationService = experimentNotificationService;
    this.applicationStateService = _applicationStateService;
    this.init();
  }
  ngOnDestroy(): void {
    this.leaveAnExperiment();
  }

  @Input() enableNotification = false;

  public showRefreshBtn = false;
  public showConnecting = false;
  public status = this.disconnectedClass;
  private connectionStatus: ConnectionStatus = ConnectionStatus.disconnected;
  private timerID: any;
  private init() {
    this.applicationStateService.applicationStatus.subscribe((status) => {
      if (status === ApplicationStatus.saving) {
        clearTimeout(this.timerID);
        this.connectionStatusMessage = $localize`:@@saving:Saving...`;
      } else {
        if (status === ApplicationStatus.errored) {
          this.connectionStatusMessage = $localize`:@@errored:Errored`;
        } else {
          this.connectionStatusMessage = $localize`:@@saved:Saved`;
        }
        this.timerID = setTimeout(() => {
          this.setStatus(this.connectionStatus);
        }, 400);
      }
    });

    this.notificationService.connectionStartedAlert?.subscribe((_connection) => {
      this.showRefreshBtn = false;
      this.showConnecting = false;
      this.setStatus(ConnectionStatus.connected);
      this.loadInputLocks();
    });

    this.notificationService.reconnectingAlert?.subscribe((_error) => {
      this.showRefreshBtn = false;
      this.setStatus(ConnectionStatus.reconnecting);
    });

    this.notificationService.reconnectedAlert?.subscribe((_success) => {
      this.showRefreshBtn = false;
      this.reconnect();
      this.setStatus(ConnectionStatus.connected);
    });

    this.notificationService.connectionLostAlert?.subscribe((_error) => {
      this.setStatus(ConnectionStatus.disconnected);
      this.showRefreshBtn = true;
    });
  }

  async ngAfterViewInit() {
    if (this.enableNotification) await this.joinAnExperiment();
    window.onbeforeunload = () => this.ngOnDestroy();
  }

  public async reconnect() {
    this.showRefreshBtn = false;
    this.showConnecting = true;
    this.setStatus(ConnectionStatus.reconnecting);
    await this.joinAnExperiment();
  }

  private setStatus(status: ConnectionStatus): string {
    switch (status) {
      case ConnectionStatus.disconnected:
        this.status = this.disconnectedClass;
        this.connectionStatusMessage = $localize`:@@connectionLost:Connection Lost`;
        break;
      case ConnectionStatus.connected:
        this.status = this.connectedClass;
        this.connectionStatusMessage = $localize`:@@connected:Connected`;
        break;
      case ConnectionStatus.reconnecting:
        this.status = this.reconnectingClass;
        this.connectionStatusMessage = $localize`:@@retrying:Connection to Server Lost. Retrying...`;
        break;
    }
    this.connectionStatus = status;
    return status;
  }

  private async joinAnExperiment() {
    await this.notificationService.joinAnExperiment();
  }

  private leaveAnExperiment(): void {
    this.notificationService.disconnectUser();
  }

  private loadInputLocks() {
    let userIndex = 0;
    var loader = setInterval(() => {
      const user = this.experimentCollaboratorService.getExperimentCollaboratorByIndex(userIndex);
      if (user) {
        this.notificationService.getInputLocks(user.connectionId);
        userIndex++;
      }
    }, 1000);
    setTimeout(() => {
      clearInterval(loader);
    }, environment.maxIntervalToLoadLocks);
  }
}
