import { Injectable } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { ClientStateService } from 'services/client-state.service';
import { ClientState } from './../../app.states';
import { ExperimentWorkflowState } from './../../api/models';
import { ExperimentService } from './experiment.service';
import { UserService } from '../../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class ExperimentWarningService {
  constructor(
    private readonly experimentService: ExperimentService,
    private readonly confirmationService: ConfirmationService,
    private readonly clientStateService: ClientStateService,
    private readonly userService: UserService
  ) {}

  styleClassPropertiesForCollaboratorWarning: { [key: string]: string } = {
    rejectButtonStyleClass: 'eln-standard-popup-button p-button-outlined',
    acceptButtonStyleClass: 'eln-standard-popup-button'
  };

  public get isUserAllowedToEdit() {
    if (this.checkExperimentIsInReview()) {
      return (this.userService.hasSupervisorOrReviewerRights());
    }
    if (this.checkExperimentIsInProgressOrCorrection()) {
      if (!this.clientStateService.isClientStateReadOnly)
        return true;
    }
    return true;
  }

  private checkExperimentIsInReview() {
    return (
      this.experimentService.currentExperiment?.workflowState === ExperimentWorkflowState.InReview
    );
  }

  private checkExperimentIsInProgressOrCorrection() {
    return (
      this.experimentService.currentExperiment?.workflowState ===
        ExperimentWorkflowState.InProgress ||
      this.experimentService.currentExperiment?.workflowState ===
        ExperimentWorkflowState.InCorrection
    );
  }

  private checkUserHasELNAnalystRole(): boolean {
    const featureFlags = this.clientStateService.getFeatureFlags(ClientState.EXPERIMENT);
    return featureFlags.find(
      (data) =>
        JSON.parse(data).isAnalystEdit && JSON.parse(data).isAnalystEdit === true
    ) !== (null || undefined);
  }

}
