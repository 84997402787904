import { Component, OnInit } from "@angular/core";
import { InstrumentConfigMethods } from "../shared/instrument-config-methods";
import { InstrumentConfigUnits } from "../shared/instrument-config-units";
import { InstrumentConnectionHelper } from "../shared/instrument-connection-helper";
import { InstrumentPushValues } from "../shared/instrument-push-values";
import { InstrumentConfigurationKeys } from "../shared/instrument-configuration-keys";
import { ExperimentService } from "../../services/experiment.service";
import { InstrumentBlowerValues } from "../shared/instrument-blower-value";
import { BlowerState } from "../../model/instrument-connection/blower-state";
import { InstrumentConnectionService } from "../instrument-connection.service";

@Component({
  selector: 'app-instrument-connection-config-modal',
  templateUrl: './instrument-connection-config-modal.component.html',
  styleUrls: ['./instrument-connection-config-modal.component.scss']
})
export class InstrumentConnectionConfigModalComponent implements OnInit {
  instrumentMethodLabel = $localize`:@@instrumentMethodLabel:Method`;
  instrumentUnitLabel = $localize`:@@instrumentUnitLabel:Unit`;
  instrumentPushLabel = $localize`:@@instrumentPushLabel:Default to Push`;
  instrumentBlowerLabel= $localize`:@@blowerOn:Blower On`;
  selectedMethod = InstrumentConfigMethods.direct;
  selectedUnit = InstrumentConfigUnits.g;
  pushValue = InstrumentPushValues.yes;
  blowerState = InstrumentBlowerValues.yes;
  configMethods: string[] = [];
  configUnits: string[] = [];
  configPushValues: string[] = [];
  configBlowerValues: string[] = [];
  configBlowerVisible = false;
  configBlowerEditable =  false;
  configUnitsForBalanceConnection = [InstrumentConfigUnits.g, InstrumentConfigUnits.mg];
  BLOWER_VALUE = 'Blower Value';
  configBlowerOptions = {
    showClear: false,
    showHeader: true
  }

  constructor(
    private readonly instrumentConnectionHelper: InstrumentConnectionHelper,
    private readonly experimentService: ExperimentService,
    private readonly instrumentConnectionService: InstrumentConnectionService) {
  }

  ngOnInit(): void {
    this.initializeDropdownValues();
  }

  onCancel() {
    this.instrumentConnectionHelper.connectionConfigCancelled.next(true);
  }

  onOk() {
    this.instrumentConnectionHelper.setConfigurationsForSelectedInstrument(this.selectedMethod, this.selectedUnit, this.pushValue);
    this.instrumentConnectionHelper.instrumentConnectionStarted.next(true);
    localStorage.setItem(InstrumentConfigurationKeys.method, this.selectedMethod);
    localStorage.setItem(InstrumentConfigurationKeys.unit, this.selectedUnit);
    localStorage.setItem(InstrumentConfigurationKeys.push, this.pushValue);
    localStorage.setItem("instrumentConnectionInitiatedActivity", this.experimentService.currentActivityId)
  }
  
  private initializeDropdownValues() {
    this.configMethods = Object.values(InstrumentConfigMethods);
    this.configUnits = Object.values(this.configUnitsForBalanceConnection);
    this.configPushValues = Object.values(InstrumentPushValues);
    this.setBlowerValues();
  }

  private setBlowerValues() {
    if (this.instrumentConnectionService.balanceBlowerState) {
      this.configBlowerVisible = true;
      switch (this.instrumentConnectionService.balanceBlowerState) {
        case BlowerState.Blower_On:
          this.configBlowerEditable = false;
          this.blowerState = InstrumentBlowerValues.yes;
          this.configBlowerValues = [Object.values(InstrumentBlowerValues)[0]];
          break;
        case BlowerState.Blower_Off:
          this.configBlowerEditable = false;
          this.blowerState = InstrumentBlowerValues.no;
          this.configBlowerValues = [Object.values(InstrumentBlowerValues)[1]];
          break;
        case BlowerState.Blower_On_Off:
          this.configBlowerEditable = true;
          this.blowerState = InstrumentBlowerValues.yes;
          localStorage.setItem(this.BLOWER_VALUE, this.blowerState);
          this.configBlowerValues = Object.values(InstrumentBlowerValues);
          break;
        case BlowerState.No_Blower:
          this.configBlowerVisible = false;
          break;
      }
    } else {
      this.configBlowerVisible = false
    }
  }

  changeBlowerState () {
    localStorage.setItem(this.BLOWER_VALUE, this.blowerState);
  }
}