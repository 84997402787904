<div class="bpt-layout-content">
  <div class="internal-comments-content-container" *ngIf="isCommentsVisible">
    <bpt-slider
      id="eln-bptSlider"
      [(visible)]="sliderOptions.visible"
      [closeOnEscape]="sliderOptions.closeOnEscape"
      [headerText]="sliderOptions.headerText"
      [size]="sliderOptions.size"
      [isFooterSticky]="sliderOptions.isFooterSticky"
      [displayFooter]="sliderOptions.displayFooter"
      [displayFooterWithPrimaryButton]="false"
      [displayFooterWithSecondaryButton]="false"
      [isModal]="sliderOptions.isModal"
      (visibleChange)="toggleSlider($event)"
    >
      <bpt-comments
        #commentsInput
        [id]="uniqueId"
        [commentCollection]="commentDetails"
        [headerText]="commentService.internalCommentDefaultDetails.displayLabel"
        (newComment)="submitComment($event)"
        [hidePrimaryCommentEditor]="hidePrimaryCommentEditor ? hidePrimaryCommentEditor : commentService.hidePrimaryCommentEditor"
        (commentEdited)="commentEdited($event)"
        (replyEdited)="replyEdited($event)"
        (newReply)="newReply($event)"
        (primaryAction)="primaryAction($event)"
        (commentHeaderClick)="commentHeaderClicked($event)"
      ></bpt-comments>
    </bpt-slider>
  </div>
</div>

