import { Injectable } from "@angular/core";
import { DynamicDialogRef } from "primeng/dynamicdialog";
import { Subject, Subscription } from "rxjs";
import {
  PreparationItem,
  ActivityPreparationsPresentation,
  PreparationAdditionalInformationPresentation,
  PreparationSummaryPresentation,
  PreparationInternalInformationPresentation } from "../../../preparation/models/preparation-presentation.model";
import {
  ExperimentPreparationStatus,
  InstantValue,
  LocalDateValue,
  ModifiableDataValue,
  PreparationAdditionalInformationResponse,
  PreparationInternalInformation,
  PreparationInternalInformationResponse,
  PreparationRemovedResponse,
  PreparationSummaryResponse,
  RecipeNewPreparations,
  RecipePreparationCellChangeCommand,
  RecipePreparationCellChangedResponse,
  RecipeRemovePreparationCommand,
  ValueType } from "../../../api/cookbook/models";
import { PreparationsService } from "../../../api/cookbook/services";
import { processRecipeSource } from "../../services/recipe.service";

@Injectable()
export class RecipePreparationService {
  dynamicDialogRef!: DynamicDialogRef;
  private readonly preparationCellChangedResponseSubject = new Subject<RecipePreparationCellChangedResponse>();
  private readonly preparationRemovalResponseSubject = new Subject<PreparationRemovedResponse>();
  constructor(private readonly preparationsService: PreparationsService) {
  }

  removedPreparationsDetails: Array<PreparationItem> = [];

  subscriptions: Subscription[] = [];

  preparationCellChanged() {
    return this.preparationCellChangedResponseSubject.asObservable();
  }

  preparationRemovalResponse() {
    return this.preparationRemovalResponseSubject.asObservable();
  }

  buildPresentationModel(activityPreparations: RecipeNewPreparations): ActivityPreparationsPresentation {
    let expiration = {} as ModifiableDataValue;
    const activityPreparationsPresentation: ActivityPreparationsPresentation = {
      nodeId: activityPreparations.activityId,
      preparations: []
    }
    activityPreparations.preparations.forEach((preparation) => {
      if (preparation.expirationValue) {
        expiration = {
          isModified: false,
          value: preparation.expirationValue.expirationDateValue?.type === ValueType.Instant ?
            preparation.expirationValue.expirationDateValue as InstantValue : preparation.expirationValue.expirationDateValue as LocalDateValue,
        }
      }
      const newlyCreatedPreparation: PreparationItem = {} as PreparationItem;
      newlyCreatedPreparation.source = processRecipeSource(undefined);
      newlyCreatedPreparation.additionalInformation = this.buildAdditionalInformationPresentation(preparation.additionalInformation);
      newlyCreatedPreparation.internalInformation = this.buildInternalInformationPresentation(preparation.internalInformation as PreparationInternalInformation);
      newlyCreatedPreparation.summary = this.buildSummaryPresentation(preparation.summary);
      newlyCreatedPreparation.description = {
        isModified: false,
        value: preparation.description
      }
      newlyCreatedPreparation.isRemoved = false;
      newlyCreatedPreparation.name = {
        isModified: false,
        value: preparation.name
      };
      newlyCreatedPreparation.status = ExperimentPreparationStatus.Invalid;
      newlyCreatedPreparation.preparationId = preparation.preparationId;
      newlyCreatedPreparation.expirationValue = {
        expirationDateValue: expiration
      }
      activityPreparationsPresentation.preparations.push(newlyCreatedPreparation);
    });
    return activityPreparationsPresentation;
  }


  buildInternalInformationPresentation(internalInfo: PreparationInternalInformationResponse): PreparationInternalInformationPresentation {
    const result: PreparationInternalInformationPresentation = {
      disposal: internalInfo?.disposal,
      storageLocation: internalInfo?.storageLocation,
      stability: internalInfo?.stability,
      hazards: internalInfo?.hazards,
      originalQuantity: internalInfo?.originalQuantity,
      additionalLabel: internalInfo?.additionalLabel
    };
    return result;
  }

  public updatePreparation(recipePreparationCellChangeCommand: RecipePreparationCellChangeCommand) {
    this.subscriptions.push(this.preparationsService
      .preparationsCellChangePost$Json({
        body: recipePreparationCellChangeCommand
      }).subscribe({
       next: (result) => {
          this.preparationCellChangedResponseSubject.next(result);
        }
      }));
  }

  public removePreparation(preparationToBeRemoved: RecipeRemovePreparationCommand) {
    this.subscriptions.push(this.preparationsService
      .preparationsRemovePreparationPost$Json({
        body: preparationToBeRemoved
      }).subscribe({
        next: (result) => {
          this.preparationRemovalResponseSubject.next(result);
        }
      }));
  }


  buildAdditionalInformationPresentation(additionalInfo: PreparationAdditionalInformationResponse): PreparationAdditionalInformationPresentation {
    const additionalInformation: PreparationAdditionalInformationPresentation = {
      analysis: additionalInfo.analysis,
      type: additionalInfo.client,
      method: additionalInfo.method,
      client: additionalInfo.client,
      project: additionalInfo.project,
      preparedBy: additionalInfo.preparedBy ? additionalInfo.preparedBy : "",
      reviewedBy: additionalInfo.reviewedBy,
      storedBy: additionalInfo.storedBy,
      subBusinessUnit: additionalInfo.subBusinessUnit,
      discardedOrConsumed: additionalInfo.discardedOrConsumed,
      discardedOrConsumedBy: additionalInfo.discardedOrConsumedBy,
      discardedOrConsumedOn: additionalInfo.discardedOrConsumedOn
    };
    return additionalInformation;
  }

  buildSummaryPresentation(summary: PreparationSummaryResponse): PreparationSummaryPresentation {
    const summaryInfo: PreparationSummaryPresentation = {} as PreparationSummaryPresentation;
    if (summary.formulaComponents) {
      summaryInfo.formulaComponents = {
        value: summary.formulaComponents,
        isModified: false
      }
    }
    if (summary.concentration) {
      summaryInfo.concentration = {
        value: summary.concentration,
        isModified: false
      }
    }
    if (summary.storageCondition) {
      summaryInfo.storageCondition = {
        value: summary.storageCondition,
        isModified: false
      }
    }
    return summaryInfo;
  }
}
