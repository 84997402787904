/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AuditHistoryDataRecordResponse } from '../models/audit-history-data-record-response';
import { dataPackageAuditHistoryExperimentIdPost$Json } from '../fn/experiment-data-package-audit-history/data-package-audit-history-experiment-id-post-json';
import { DataPackageAuditHistoryExperimentIdPost$Json$Params } from '../fn/experiment-data-package-audit-history/data-package-audit-history-experiment-id-post-json';
import { dataPackageAuditHistoryExperimentIdPost$Plain } from '../fn/experiment-data-package-audit-history/data-package-audit-history-experiment-id-post-plain';
import { DataPackageAuditHistoryExperimentIdPost$Plain$Params } from '../fn/experiment-data-package-audit-history/data-package-audit-history-experiment-id-post-plain';

@Injectable({ providedIn: 'root' })
export class ExperimentDataPackageAuditHistoryService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `dataPackageAuditHistoryExperimentIdPost()` */
  static readonly DataPackageAuditHistoryExperimentIdPostPath = '/data-package-audit-history/{experimentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dataPackageAuditHistoryExperimentIdPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  dataPackageAuditHistoryExperimentIdPost$Plain$Response(params: DataPackageAuditHistoryExperimentIdPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<AuditHistoryDataRecordResponse>> {
    return dataPackageAuditHistoryExperimentIdPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `dataPackageAuditHistoryExperimentIdPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  dataPackageAuditHistoryExperimentIdPost$Plain(params: DataPackageAuditHistoryExperimentIdPost$Plain$Params, context?: HttpContext): Observable<AuditHistoryDataRecordResponse> {
    return this.dataPackageAuditHistoryExperimentIdPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<AuditHistoryDataRecordResponse>): AuditHistoryDataRecordResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dataPackageAuditHistoryExperimentIdPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  dataPackageAuditHistoryExperimentIdPost$Json$Response(params: DataPackageAuditHistoryExperimentIdPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<AuditHistoryDataRecordResponse>> {
    return dataPackageAuditHistoryExperimentIdPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `dataPackageAuditHistoryExperimentIdPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  dataPackageAuditHistoryExperimentIdPost$Json(params: DataPackageAuditHistoryExperimentIdPost$Json$Params, context?: HttpContext): Observable<AuditHistoryDataRecordResponse> {
    return this.dataPackageAuditHistoryExperimentIdPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<AuditHistoryDataRecordResponse>): AuditHistoryDataRecordResponse => r.body)
    );
  }

}
