/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ChangeFieldResponse } from '../models/change-field-response';
import { formEventsChangeFieldPost$Json } from '../fn/form-events/form-events-change-field-post-json';
import { FormEventsChangeFieldPost$Json$Params } from '../fn/form-events/form-events-change-field-post-json';
import { formEventsChangeFieldPost$Plain } from '../fn/form-events/form-events-change-field-post-plain';
import { FormEventsChangeFieldPost$Plain$Params } from '../fn/form-events/form-events-change-field-post-plain';

@Injectable({ providedIn: 'root' })
export class FormEventsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `formEventsChangeFieldPost()` */
  static readonly FormEventsChangeFieldPostPath = '/form-events/change-field';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `formEventsChangeFieldPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  formEventsChangeFieldPost$Plain$Response(params?: FormEventsChangeFieldPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangeFieldResponse>> {
    return formEventsChangeFieldPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `formEventsChangeFieldPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  formEventsChangeFieldPost$Plain(params?: FormEventsChangeFieldPost$Plain$Params, context?: HttpContext): Observable<ChangeFieldResponse> {
    return this.formEventsChangeFieldPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangeFieldResponse>): ChangeFieldResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `formEventsChangeFieldPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  formEventsChangeFieldPost$Json$Response(params?: FormEventsChangeFieldPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangeFieldResponse>> {
    return formEventsChangeFieldPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `formEventsChangeFieldPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  formEventsChangeFieldPost$Json(params?: FormEventsChangeFieldPost$Json$Params, context?: HttpContext): Observable<ChangeFieldResponse> {
    return this.formEventsChangeFieldPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangeFieldResponse>): ChangeFieldResponse => r.body)
    );
  }

}
