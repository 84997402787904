import { ColumnDefinition } from "bpt-ui-library/bpt-grid";
import { ColumnType } from "../../../api/models";

export class InstrumentListGridOptions {
  private static readonly aggFunc = 'first';
  private static readonly cellStyleClass = 'grid-cell-align';

  public static prepareColumns(): ColumnDefinition[] {
    return [
      {
        label: $localize`:@@preparationRowId:Row ID`,
        field: 'id',
        columnType: ColumnType.RowId,
        hidden: true,
        showInColumnChooser: true,
        editable: false,
        aggFunc: this.aggFunc
      },
      {
        field: 'name',
        aggFunc: this.aggFunc,
        label: $localize`:@@name:Name`,
        editable: false,
        allowAutoResize: true,
        sortable: true,
        cellClass: this.cellStyleClass,
        filterParams: {
          filterOptions: ['contains'],
          suppressAndOrCondition: true
        },
        showInColumnChooser: false,        
      },
      {
        field: 'equipmentId',
        aggFunc: this.aggFunc,
        label: $localize`:@@ID:ID`,
        allowAutoResize: true,
        editable: false,
        sortable: true,
        columnType: ColumnType.String,
        cellClass: this.cellStyleClass,
        filterParams: {
          filterOptions: ['contains'],
          suppressAndOrCondition: true
        },
        showInColumnChooser: false
      },
      {
        field: 'type',
        aggFunc: this.aggFunc,
        label: $localize`:@@type:Type`,
        minWidth: 180,
        width: 180,
        sortable: true,
        columnType: 'string',
        editable: false
      },
      {
        field: 'modelNumber',
        aggFunc: this.aggFunc,
        label: $localize`:@@model:Model`,
        minWidth: 180,
        width: 180,
        sortable: true,
        columnType: 'string',
        editable: false
      },
      {
        field: 'manufacturer',
        aggFunc: this.aggFunc,
        label: $localize`:@@manufacturer:Manufacturer`,
        sortable: true,
        columnType: 'string',
        editable: false
      },
      {
        field: 'location',
        aggFunc: this.aggFunc,
        label: $localize`:@@Location:Location`,
        minWidth: 180,
        width: 180,
        sortable: true,
        columnType: 'string',
        editable: false
      },
      {
        field: 'department',
        aggFunc: this.aggFunc,
        label: $localize`:@@Department:Department`,
        minWidth: 180,
        width: 180,
        sortable: true,
        columnType: 'string',
        editable: false
      }
    ];
  }
}