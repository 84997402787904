<div class="eln-lab-items-data eln-table-theme secondary"
  *ngIf="isLabItemConsumableGridVisible">
  <div class="col-10 eln-exp-table-header module-item-title flex flex-row align-items-center gap-3">
    <div [ngClass]="{'table-title title-absolute' : allowRowAdd, 'table-title-no-row title-absolute' : !allowRowAdd}"
      #tableTitle>
      <span class="eln-module-tab-header"> {{ titleOfTable }} </span>
    </div>
    <app-completion-tracking [completionPercent]=completionPercent></app-completion-tracking>
  </div>
  <ng-container class="bpt-grid-toolbar-left-layout" *ngIf="allowRowAdd">
    <div [ngClass]="{
      'addRowDisabledButton': disableAddingConsumables()      
    }"
      class="eln-add-consumable-button"
      (click)="addNewRow()"      
      [tabIndex]="1">
      <a class="table-grid-toolbar-left">
        <span class="pi pi-plus"></span>
        <span class="ml-1" i18n="@@addItem" styleClass="bpt-grid-add-rows-button">Add Item</span>
      </a>
    </div>
  </ng-container>
  <bpt-grid
    #labItemsConsumableGrid
    *ngIf="reloadGrid"
    class="eln-grid hide-readonly-icons"
    gridId="{{ labItemsConsumableId }}"
    [dataSource]="labItemsConsumableDataSource"
    [columnDefinitions]="columnDefinitions"
    [gridMode]="gridMode"
    [allowRowAdd]="false"
    (gridReady)="onGridReady()"
    (valuesPasted)="pasteValues($event)"
    [contextMenu]="getContextMenu()"
    [showAutoSizeButton]="true"
    [showFilterToggleButton]="true"
    [showGridOptionsButton]="false"
    [paginated]="false"
    [savedPreferences]="LabItemConsumableSavedPreferences"
    (newGridPreferenceAdded)="saveNewPreference($event)"
    (gridPreferenceDeleted)="deletePreference($event)"
    (gridPreferenceUpdated)="updatePreference($event)"
    (gridPreferenceSelectionChanged)="changeDefaultPreference($event)"
    (cellValueChanged)="cellValueChanged($event)"
    (rowsAdded)="rowsAdded($event)"
    [readOnly]="false"
    (toolbarItemClick)="rowsAdded($event)"
    [rowActionConfigurations]="gridActions"
    (cellEditStarted)="cellEditStartedEvent($event)"
    (cellEditStopped)="cellEditStoppedEvent($event)">
    <ng-container class="bpt-grid-toolbar-right-layout">
      <a
        class="bpt-grid-toolbar-item table-grid-toolbar-audit-icon"
        align="right"
        id="eln-iconAuditHistoryShow-{{ labItemsConsumableId }}"
        (click)="loadAuditHistoryDialog()">
        <span
          i18n-pTooltip="@@viewHistory"
          class="fa fa-history"
          pTooltip="View History"
          tooltipPosition="top"><span class="ml-1 audit" i18n="@@History">History</span></span>
      </a>
    </ng-container>
    <ng-container class="bpt-grid-toolbar-right-layout">
      <a
        *ngIf="containsRemovedRows"
        class="bpt-grid-toolbar-item table-grid-toolbar-view-removed-rows-icon consumable-remove-icon ml-3"
        align="right"
        id="eln-iconViewRemovedRowsShow-{{ labItemsConsumableId }}"
        (click)="loadRemovedRowsDialog()">
        <span
          i18n-pTooltip="@@viewRemovedRows"
          class="fa icon-view-row"
          pTooltip="View Removed Rows"
          tooltipPosition="top"><span class="ml-1 viewRemovedRows-iconTxt" i18n="@@viewRemovedRows">View Removed
            Rows</span></span>
      </a>
    </ng-container>
  </bpt-grid>
</div>