/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { QueueChromatographyResultSetImportRequestCommand as ElnInstrumentIntegrationDomainChromatographyQueueChromatographyResultSetImportRequestCommand } from '../../models/ELN/InstrumentIntegration/Domain/Chromatography/queue-chromatography-result-set-import-request-command';

export interface ChromatographyIntegrationResultSetImportRequestPost$Params {
  'X-User-Puid'?: any;
  'X-User-LabsiteCode'?: any;
      body?: ElnInstrumentIntegrationDomainChromatographyQueueChromatographyResultSetImportRequestCommand
}

export function chromatographyIntegrationResultSetImportRequestPost(http: HttpClient, rootUrl: string, params?: ChromatographyIntegrationResultSetImportRequestPost$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
  const rb = new RequestBuilder(rootUrl, chromatographyIntegrationResultSetImportRequestPost.PATH, 'post');
  if (params) {
    rb.header('X-User-Puid', params['X-User-Puid'], {"style":"simple"});
    rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {"style":"simple"});
    rb.body(params.body, 'application/*+json');
  }

  return http.request(
    rb.build({ responseType: 'text', accept: '*/*', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
    })
  );
}

chromatographyIntegrationResultSetImportRequestPost.PATH = '/chromatography-integration/result-set-import-request';
