import { ExperimentOptions } from "model/experiment-options.interace";

const previewExperimentConfigurations: ExperimentOptions = {
    previewMode: true,
    banner: $localize`:@@experimentPreviewBanner:Experiment Preview.`
}
const experimentConfigurations: ExperimentOptions = {
    previewMode: false
}

export class ExperimentOptionsHelper {
    public static getOptionsFromRoute(): ExperimentOptions {
        const paths = window.location.pathname.split('/');
        const vexpFound = paths.some(x => x.toLowerCase().includes("vexp-"));
        const experimentFound = paths.some(x => x.toLowerCase().includes("experiment"));
        if (experimentFound && vexpFound) {
            return previewExperimentConfigurations;
        } else {
            experimentConfigurations.previewMode = false;
            return experimentConfigurations;
        }
    }
}
