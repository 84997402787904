/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { PreparationInternalInformationChangedResponse } from '../models/preparation-internal-information-changed-response';
import { PreparationRemovedResponse } from '../models/preparation-removed-response';
import { preparationsCellChangePost$Json } from '../fn/preparations/preparations-cell-change-post-json';
import { PreparationsCellChangePost$Json$Params } from '../fn/preparations/preparations-cell-change-post-json';
import { preparationsCellChangePost$Plain } from '../fn/preparations/preparations-cell-change-post-plain';
import { PreparationsCellChangePost$Plain$Params } from '../fn/preparations/preparations-cell-change-post-plain';
import { preparationsChangeInternalInformationPost$Json } from '../fn/preparations/preparations-change-internal-information-post-json';
import { PreparationsChangeInternalInformationPost$Json$Params } from '../fn/preparations/preparations-change-internal-information-post-json';
import { preparationsChangeInternalInformationPost$Plain } from '../fn/preparations/preparations-change-internal-information-post-plain';
import { PreparationsChangeInternalInformationPost$Plain$Params } from '../fn/preparations/preparations-change-internal-information-post-plain';
import { preparationsCreatePreparationPost$Json } from '../fn/preparations/preparations-create-preparation-post-json';
import { PreparationsCreatePreparationPost$Json$Params } from '../fn/preparations/preparations-create-preparation-post-json';
import { preparationsCreatePreparationPost$Plain } from '../fn/preparations/preparations-create-preparation-post-plain';
import { PreparationsCreatePreparationPost$Plain$Params } from '../fn/preparations/preparations-create-preparation-post-plain';
import { preparationsRemovePreparationPost$Json } from '../fn/preparations/preparations-remove-preparation-post-json';
import { PreparationsRemovePreparationPost$Json$Params } from '../fn/preparations/preparations-remove-preparation-post-json';
import { preparationsRemovePreparationPost$Plain } from '../fn/preparations/preparations-remove-preparation-post-plain';
import { PreparationsRemovePreparationPost$Plain$Params } from '../fn/preparations/preparations-remove-preparation-post-plain';
import { RecipeNewPreparations } from '../models/recipe-new-preparations';
import { RecipePreparationCellChangedResponse } from '../models/recipe-preparation-cell-changed-response';

@Injectable({ providedIn: 'root' })
export class PreparationsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `preparationsCreatePreparationPost()` */
  static readonly PreparationsCreatePreparationPostPath = '/preparations/create-preparation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `preparationsCreatePreparationPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsCreatePreparationPost$Plain$Response(params?: PreparationsCreatePreparationPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<RecipeNewPreparations>> {
    return preparationsCreatePreparationPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `preparationsCreatePreparationPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsCreatePreparationPost$Plain(params?: PreparationsCreatePreparationPost$Plain$Params, context?: HttpContext): Observable<RecipeNewPreparations> {
    return this.preparationsCreatePreparationPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<RecipeNewPreparations>): RecipeNewPreparations => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `preparationsCreatePreparationPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsCreatePreparationPost$Json$Response(params?: PreparationsCreatePreparationPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<RecipeNewPreparations>> {
    return preparationsCreatePreparationPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `preparationsCreatePreparationPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsCreatePreparationPost$Json(params?: PreparationsCreatePreparationPost$Json$Params, context?: HttpContext): Observable<RecipeNewPreparations> {
    return this.preparationsCreatePreparationPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<RecipeNewPreparations>): RecipeNewPreparations => r.body)
    );
  }

  /** Path part for operation `preparationsCellChangePost()` */
  static readonly PreparationsCellChangePostPath = '/preparations/cell-change';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `preparationsCellChangePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsCellChangePost$Plain$Response(params?: PreparationsCellChangePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<RecipePreparationCellChangedResponse>> {
    return preparationsCellChangePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `preparationsCellChangePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsCellChangePost$Plain(params?: PreparationsCellChangePost$Plain$Params, context?: HttpContext): Observable<RecipePreparationCellChangedResponse> {
    return this.preparationsCellChangePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<RecipePreparationCellChangedResponse>): RecipePreparationCellChangedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `preparationsCellChangePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsCellChangePost$Json$Response(params?: PreparationsCellChangePost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<RecipePreparationCellChangedResponse>> {
    return preparationsCellChangePost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `preparationsCellChangePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsCellChangePost$Json(params?: PreparationsCellChangePost$Json$Params, context?: HttpContext): Observable<RecipePreparationCellChangedResponse> {
    return this.preparationsCellChangePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<RecipePreparationCellChangedResponse>): RecipePreparationCellChangedResponse => r.body)
    );
  }

  /** Path part for operation `preparationsRemovePreparationPost()` */
  static readonly PreparationsRemovePreparationPostPath = '/preparations/remove-preparation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `preparationsRemovePreparationPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsRemovePreparationPost$Plain$Response(params?: PreparationsRemovePreparationPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<PreparationRemovedResponse>> {
    return preparationsRemovePreparationPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `preparationsRemovePreparationPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsRemovePreparationPost$Plain(params?: PreparationsRemovePreparationPost$Plain$Params, context?: HttpContext): Observable<PreparationRemovedResponse> {
    return this.preparationsRemovePreparationPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<PreparationRemovedResponse>): PreparationRemovedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `preparationsRemovePreparationPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsRemovePreparationPost$Json$Response(params?: PreparationsRemovePreparationPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<PreparationRemovedResponse>> {
    return preparationsRemovePreparationPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `preparationsRemovePreparationPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsRemovePreparationPost$Json(params?: PreparationsRemovePreparationPost$Json$Params, context?: HttpContext): Observable<PreparationRemovedResponse> {
    return this.preparationsRemovePreparationPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<PreparationRemovedResponse>): PreparationRemovedResponse => r.body)
    );
  }

  /** Path part for operation `preparationsChangeInternalInformationPost()` */
  static readonly PreparationsChangeInternalInformationPostPath = '/preparations/change-internal-information';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `preparationsChangeInternalInformationPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsChangeInternalInformationPost$Plain$Response(params?: PreparationsChangeInternalInformationPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<PreparationInternalInformationChangedResponse>> {
    return preparationsChangeInternalInformationPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `preparationsChangeInternalInformationPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsChangeInternalInformationPost$Plain(params?: PreparationsChangeInternalInformationPost$Plain$Params, context?: HttpContext): Observable<PreparationInternalInformationChangedResponse> {
    return this.preparationsChangeInternalInformationPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<PreparationInternalInformationChangedResponse>): PreparationInternalInformationChangedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `preparationsChangeInternalInformationPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsChangeInternalInformationPost$Json$Response(params?: PreparationsChangeInternalInformationPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<PreparationInternalInformationChangedResponse>> {
    return preparationsChangeInternalInformationPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `preparationsChangeInternalInformationPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsChangeInternalInformationPost$Json(params?: PreparationsChangeInternalInformationPost$Json$Params, context?: HttpContext): Observable<PreparationInternalInformationChangedResponse> {
    return this.preparationsChangeInternalInformationPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<PreparationInternalInformationChangedResponse>): PreparationInternalInformationChangedResponse => r.body)
    );
  }

}
