import { NotificationResult } from "../api/models";

/**
 * Manages client-side validation details that can be displayed in the UI
 * TODO: build a validation component that can be embedded into the UI
 */
export class ClientValidationDetails {
  errorTitle!: string;
  warningTitle!: string;
  infoTitle!: string;
  successTitle!: string;
  errors!: Array<string>;
  warnings!: Array<string>;
  info!: Array<string>;
  successes!: Array<string>;
  displayAsInline = false;
  notificationResults: NotificationResult = { notifications : []} as NotificationResult;

  constructor() {
    this.initialize();
  }

  private initialize() {
    this.errorTitle = $localize`:@@pleaseCorrectErrors:Please correct these error(s)`;
    this.warningTitle = $localize`:@@warnings:Warning(s)`;
    this.infoTitle = $localize`:@@info:Information`;
    this.successTitle = $localize`:@@success:Success`;

    this.errors = new Array<string>();
    this.warnings = new Array<string>();
    this.info = new Array<string>();
    this.successes = new Array<string>();
    this.notificationResults = { notifications : []} as NotificationResult;
  }

  isValid(): boolean {
    return this.errors.length === 0 && this.notificationResults.notifications.length === 0;
  }

  clear(): void {
    this.initialize();
  }

  removeErrorMessage(message : string) {
    const index = this.errors.indexOf(message, 0);
    if (index > -1) {
      this.errors.splice(index, 1);
    }
   }
}
