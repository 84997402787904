<div
  class="eln-rows-data eln-table-theme stretch"
>
  <div class="flex flex-row eln-subHeading">
    {{ subHeading }}
  </div>
  <div class="eln-tableTitle">{{ tableTitle }}</div>
  <bpt-grid #grid
    class="eln-grid hide-readonly-icons"
    ngClass="true"
    [dataSource]="dataSource"
    [useJsJodaTypes]="true"
    [columnDefinitions]="columnDefinitions"
    [gridMode]="gridMode"
    [allowRowAdd]="false"
    [showAutoSizeButton]="true"
    [showFilterToggleButton]="true"
    [showGridOptionsButton]="false"
    [paginated]="false"
    [rowActionConfigurations]="gridActions"
  >
  </bpt-grid>
</div>
