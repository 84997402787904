import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-repeat-target-modal',
  templateUrl: './repeat-target-modal.component.html',
  styleUrls: ['./repeat-target-modal.component.scss']
})
export class RepeatTargetModalComponent {

  constructor(private readonly dynamicDialogConfig: DynamicDialogConfig,
    public readonly dynamicDialogRef: DynamicDialogRef) {
  }

  addForTheActivity() {
    this.dynamicDialogRef.close(this.dynamicDialogConfig.data.activity);
  }

  addForThisTable() {
    this.dynamicDialogRef.close(this.dynamicDialogConfig.data.table);
  }

  cancel() {
    this.dynamicDialogRef.close();
  }
}
