/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ChangeRecipeReferenceCellResponse } from '../models/change-recipe-reference-cell-response';
import { RecipeReferenceRowsAddedResponse } from '../models/recipe-reference-rows-added-response';
import { RecipeRemoveReferenceRowResponse } from '../models/recipe-remove-reference-row-response';
import { RecipeRestoreReferenceRowResponse } from '../models/recipe-restore-reference-row-response';
import { referencesAddRecipeReferenceRowsPost$Json } from '../fn/reference/references-add-recipe-reference-rows-post-json';
import { ReferencesAddRecipeReferenceRowsPost$Json$Params } from '../fn/reference/references-add-recipe-reference-rows-post-json';
import { referencesAddRecipeReferenceRowsPost$Plain } from '../fn/reference/references-add-recipe-reference-rows-post-plain';
import { ReferencesAddRecipeReferenceRowsPost$Plain$Params } from '../fn/reference/references-add-recipe-reference-rows-post-plain';
import { referencesRecipeReferenceChangeCellPost$Json } from '../fn/reference/references-recipe-reference-change-cell-post-json';
import { ReferencesRecipeReferenceChangeCellPost$Json$Params } from '../fn/reference/references-recipe-reference-change-cell-post-json';
import { referencesRecipeReferenceChangeCellPost$Plain } from '../fn/reference/references-recipe-reference-change-cell-post-plain';
import { ReferencesRecipeReferenceChangeCellPost$Plain$Params } from '../fn/reference/references-recipe-reference-change-cell-post-plain';
import { referencesRemovePost$Json } from '../fn/reference/references-remove-post-json';
import { ReferencesRemovePost$Json$Params } from '../fn/reference/references-remove-post-json';
import { referencesRemovePost$Plain } from '../fn/reference/references-remove-post-plain';
import { ReferencesRemovePost$Plain$Params } from '../fn/reference/references-remove-post-plain';
import { referencesRestorePost$Json } from '../fn/reference/references-restore-post-json';
import { ReferencesRestorePost$Json$Params } from '../fn/reference/references-restore-post-json';
import { referencesRestorePost$Plain } from '../fn/reference/references-restore-post-plain';
import { ReferencesRestorePost$Plain$Params } from '../fn/reference/references-restore-post-plain';

@Injectable({ providedIn: 'root' })
export class ReferenceService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `referencesAddRecipeReferenceRowsPost()` */
  static readonly ReferencesAddRecipeReferenceRowsPostPath = '/references/add-recipe-reference-rows';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `referencesAddRecipeReferenceRowsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  referencesAddRecipeReferenceRowsPost$Plain$Response(params?: ReferencesAddRecipeReferenceRowsPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<RecipeReferenceRowsAddedResponse>> {
    return referencesAddRecipeReferenceRowsPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `referencesAddRecipeReferenceRowsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  referencesAddRecipeReferenceRowsPost$Plain(params?: ReferencesAddRecipeReferenceRowsPost$Plain$Params, context?: HttpContext): Observable<RecipeReferenceRowsAddedResponse> {
    return this.referencesAddRecipeReferenceRowsPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<RecipeReferenceRowsAddedResponse>): RecipeReferenceRowsAddedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `referencesAddRecipeReferenceRowsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  referencesAddRecipeReferenceRowsPost$Json$Response(params?: ReferencesAddRecipeReferenceRowsPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<RecipeReferenceRowsAddedResponse>> {
    return referencesAddRecipeReferenceRowsPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `referencesAddRecipeReferenceRowsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  referencesAddRecipeReferenceRowsPost$Json(params?: ReferencesAddRecipeReferenceRowsPost$Json$Params, context?: HttpContext): Observable<RecipeReferenceRowsAddedResponse> {
    return this.referencesAddRecipeReferenceRowsPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<RecipeReferenceRowsAddedResponse>): RecipeReferenceRowsAddedResponse => r.body)
    );
  }

  /** Path part for operation `referencesRecipeReferenceChangeCellPost()` */
  static readonly ReferencesRecipeReferenceChangeCellPostPath = '/references/recipe-reference-change-cell';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `referencesRecipeReferenceChangeCellPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  referencesRecipeReferenceChangeCellPost$Plain$Response(params?: ReferencesRecipeReferenceChangeCellPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangeRecipeReferenceCellResponse>> {
    return referencesRecipeReferenceChangeCellPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `referencesRecipeReferenceChangeCellPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  referencesRecipeReferenceChangeCellPost$Plain(params?: ReferencesRecipeReferenceChangeCellPost$Plain$Params, context?: HttpContext): Observable<ChangeRecipeReferenceCellResponse> {
    return this.referencesRecipeReferenceChangeCellPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangeRecipeReferenceCellResponse>): ChangeRecipeReferenceCellResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `referencesRecipeReferenceChangeCellPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  referencesRecipeReferenceChangeCellPost$Json$Response(params?: ReferencesRecipeReferenceChangeCellPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangeRecipeReferenceCellResponse>> {
    return referencesRecipeReferenceChangeCellPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `referencesRecipeReferenceChangeCellPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  referencesRecipeReferenceChangeCellPost$Json(params?: ReferencesRecipeReferenceChangeCellPost$Json$Params, context?: HttpContext): Observable<ChangeRecipeReferenceCellResponse> {
    return this.referencesRecipeReferenceChangeCellPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangeRecipeReferenceCellResponse>): ChangeRecipeReferenceCellResponse => r.body)
    );
  }

  /** Path part for operation `referencesRemovePost()` */
  static readonly ReferencesRemovePostPath = '/references/remove';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `referencesRemovePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  referencesRemovePost$Plain$Response(params?: ReferencesRemovePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<RecipeRemoveReferenceRowResponse>> {
    return referencesRemovePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `referencesRemovePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  referencesRemovePost$Plain(params?: ReferencesRemovePost$Plain$Params, context?: HttpContext): Observable<RecipeRemoveReferenceRowResponse> {
    return this.referencesRemovePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<RecipeRemoveReferenceRowResponse>): RecipeRemoveReferenceRowResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `referencesRemovePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  referencesRemovePost$Json$Response(params?: ReferencesRemovePost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<RecipeRemoveReferenceRowResponse>> {
    return referencesRemovePost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `referencesRemovePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  referencesRemovePost$Json(params?: ReferencesRemovePost$Json$Params, context?: HttpContext): Observable<RecipeRemoveReferenceRowResponse> {
    return this.referencesRemovePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<RecipeRemoveReferenceRowResponse>): RecipeRemoveReferenceRowResponse => r.body)
    );
  }

  /** Path part for operation `referencesRestorePost()` */
  static readonly ReferencesRestorePostPath = '/references/restore';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `referencesRestorePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  referencesRestorePost$Plain$Response(params?: ReferencesRestorePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<RecipeRestoreReferenceRowResponse>> {
    return referencesRestorePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `referencesRestorePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  referencesRestorePost$Plain(params?: ReferencesRestorePost$Plain$Params, context?: HttpContext): Observable<RecipeRestoreReferenceRowResponse> {
    return this.referencesRestorePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<RecipeRestoreReferenceRowResponse>): RecipeRestoreReferenceRowResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `referencesRestorePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  referencesRestorePost$Json$Response(params?: ReferencesRestorePost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<RecipeRestoreReferenceRowResponse>> {
    return referencesRestorePost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `referencesRestorePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  referencesRestorePost$Json(params?: ReferencesRestorePost$Json$Params, context?: HttpContext): Observable<RecipeRestoreReferenceRowResponse> {
    return this.referencesRestorePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<RecipeRestoreReferenceRowResponse>): RecipeRestoreReferenceRowResponse => r.body)
    );
  }

}
