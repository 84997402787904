/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { chromatographyEmpowerDatabasesGet$Json } from '../fn/chromatography/chromatography-empower-databases-get-json';
import { ChromatographyEmpowerDatabasesGet$Json$Params } from '../fn/chromatography/chromatography-empower-databases-get-json';
import { chromatographyEmpowerDatabasesGet$Plain } from '../fn/chromatography/chromatography-empower-databases-get-plain';
import { ChromatographyEmpowerDatabasesGet$Plain$Params } from '../fn/chromatography/chromatography-empower-databases-get-plain';
import { chromatographySaveResultsetColumnsPost$Json } from '../fn/chromatography/chromatography-save-resultset-columns-post-json';
import { ChromatographySaveResultsetColumnsPost$Json$Params } from '../fn/chromatography/chromatography-save-resultset-columns-post-json';
import { chromatographySaveResultsetColumnsPost$Plain } from '../fn/chromatography/chromatography-save-resultset-columns-post-plain';
import { ChromatographySaveResultsetColumnsPost$Plain$Params } from '../fn/chromatography/chromatography-save-resultset-columns-post-plain';
import { EmpowerDatabaseResponse } from '../models/empower-database-response';
import { SaveResultSetColumnResponse } from '../models/save-result-set-column-response';

@Injectable({ providedIn: 'root' })
export class ChromatographyService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `chromatographyEmpowerDatabasesGet()` */
  static readonly ChromatographyEmpowerDatabasesGetPath = '/chromatography/empower-databases';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `chromatographyEmpowerDatabasesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  chromatographyEmpowerDatabasesGet$Plain$Response(params?: ChromatographyEmpowerDatabasesGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<EmpowerDatabaseResponse>> {
    return chromatographyEmpowerDatabasesGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `chromatographyEmpowerDatabasesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  chromatographyEmpowerDatabasesGet$Plain(params?: ChromatographyEmpowerDatabasesGet$Plain$Params, context?: HttpContext): Observable<EmpowerDatabaseResponse> {
    return this.chromatographyEmpowerDatabasesGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<EmpowerDatabaseResponse>): EmpowerDatabaseResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `chromatographyEmpowerDatabasesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  chromatographyEmpowerDatabasesGet$Json$Response(params?: ChromatographyEmpowerDatabasesGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<EmpowerDatabaseResponse>> {
    return chromatographyEmpowerDatabasesGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `chromatographyEmpowerDatabasesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  chromatographyEmpowerDatabasesGet$Json(params?: ChromatographyEmpowerDatabasesGet$Json$Params, context?: HttpContext): Observable<EmpowerDatabaseResponse> {
    return this.chromatographyEmpowerDatabasesGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<EmpowerDatabaseResponse>): EmpowerDatabaseResponse => r.body)
    );
  }

  /** Path part for operation `chromatographySaveResultsetColumnsPost()` */
  static readonly ChromatographySaveResultsetColumnsPostPath = '/chromatography/save-resultset-columns';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `chromatographySaveResultsetColumnsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  chromatographySaveResultsetColumnsPost$Plain$Response(params?: ChromatographySaveResultsetColumnsPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<SaveResultSetColumnResponse>> {
    return chromatographySaveResultsetColumnsPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `chromatographySaveResultsetColumnsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  chromatographySaveResultsetColumnsPost$Plain(params?: ChromatographySaveResultsetColumnsPost$Plain$Params, context?: HttpContext): Observable<SaveResultSetColumnResponse> {
    return this.chromatographySaveResultsetColumnsPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<SaveResultSetColumnResponse>): SaveResultSetColumnResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `chromatographySaveResultsetColumnsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  chromatographySaveResultsetColumnsPost$Json$Response(params?: ChromatographySaveResultsetColumnsPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<SaveResultSetColumnResponse>> {
    return chromatographySaveResultsetColumnsPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `chromatographySaveResultsetColumnsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  chromatographySaveResultsetColumnsPost$Json(params?: ChromatographySaveResultsetColumnsPost$Json$Params, context?: HttpContext): Observable<SaveResultSetColumnResponse> {
    return this.chromatographySaveResultsetColumnsPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<SaveResultSetColumnResponse>): SaveResultSetColumnResponse => r.body)
    );
  }

}
