import { Injectable } from '@angular/core';
import { FeatureManagementService } from '../api/services/feature-management.service';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class FeatureService {
  public static featureService: FeatureService;
  private readonly isLoadedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor(private readonly featureManagementService: FeatureManagementService) {
    FeatureService.featureService = this;
  }
  private features: { [key: string]: boolean } | undefined;
  async loadFeatureFlags() {
    this.features = await this.featureManagementService
      .featureManagerFeatureFlagsGet$Json()
      .toPromise();
      this.isLoadedSubject.next(true);
  }

  isEnabled(featureName: string): boolean {
    if (!this.isLoadedSubject.getValue()) {
      return false;
    }
    return this.features ? this.features[featureName] : false;
  }

  isLoaded(): Observable<boolean> {
    return this.isLoadedSubject.asObservable();
  }
}
