import { map, single, mergeAll, concatMap, tap, mergeMap } from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, from, Observable, of, Subject } from 'rxjs';
// Experiment
import { ExperimentResponse } from '../../api/models/experiment-response';
import { ActivityNode } from '../../api/models/activity-node';
import { ModuleNode } from '../../api/models/module-node';
import { FormNode } from '../../api/models/form-node';
import { TableNode } from '../../api/models/table-node';
import {
  Activity,
  ActivityReferences,
  ColumnSpecification,
  Experiment,
  Form,
  Module,
  ModuleItem,
  ReferenceGridType,
  SpecificationValue,
  Table,
  TemplateNodeRule,
} from 'model/experiment.interface';
import { DropdownAttributes } from 'model/template.interface';
import {
  ExperimentBlobEventsService,
  ExperimentService as ExperimentNodesService,
  LabsiteService,
  TemplatesService,
  UserPicklistsService
} from '../../api/services';
import { ExperimentEventsService } from '../../api/data-entry/services';
import { InternalCommentService } from '../../api/internal-comment/services';
// Template
import { FieldDefinitionResponse } from '../../api/models/field-definition-response';
import { FieldGroupResponse } from '../../api/models/field-group-response';
import { FormItemResponse } from '../../api/models/form-item-response';
import { FormResponse } from '../../api/models/form-response';
import { FormTemplate } from '../../api/models/form-template';
import { ColumnSpecification as ApiColumnSpecification } from '../../api/models/column-specification';
import { TableTemplate } from '../../api/models/table-template';
import { ExperimentTemplateEventService } from '../../template-loader/experiment-template-load/services/experiment-template-event.service';
import {
  ClientFacingNote,
  ClientFacingNoteContextType,
  ExperimentWorkflowState,
  FieldType,
  ValueType,
  FormItemType,
  NodeType,
  ValueState,
  Unit,
  NumberValue,
  UnitList,
  ExperimentRaisedFlag,
  CrossReference,
  ActivityLabItemsNode,
  ActivityPreparations,
  ExperimentPreparation,
  FormWithFieldDefinitionsResponse,
  UserPicklistResponse,
  ExperimentPreparationStatus,
  LabsiteGetResponse,
  TemplateRule,
  ActivityInputNode,
  PreparationSubStatus,
  Instrument,
  PromptType,
  ExperimentRecipeAppliedDetails,
} from '../../../app/api/models';
import {
  Cell,
  Row,
  ChangeExperimentAssignedAnalystsCommand,
  ChangeExperimentAssignedReviewersCommand,
  ChangeExperimentAssignedSupervisorsCommand,
  ChangeExperimentAuthorizationDueDateCommand,
  ChangeExperimentScheduledReviewStartDateCommand,
  ChangeExperimentScheduledStartDateCommand,
  ChangeExperimentSubBusinessUnitsCommand,
  ChangeExperimentTagsCommand,
  ChangeExperimentTitleCommand,
  ExperimentTemplateAppliedResponse,
  ExperimentTemplateApplyCommand,
  SetVariableCommand,
  ModifiableDataValue,
  ExperimentDataValue,
  SpecType,
  ApplyReferenceTemplateCommand,
  ReferenceTemplateType,
  ReferenceTemplateAppliedResponse,
  ReferenceTemplateAppliedEventNotification,
  ActivityInputType,
  RowRemovedEventNotification,
  RowRestoredEventNotification,
  RowsRenumberedEventNotification,
  MaterialAliquot,
  Aliquot,
  ExperimentRecipeApplyCommand,
  ExperimentRecipeAppliedEventNotification,
  ChangeReasonAddedEventNotification
} from '../../api/data-entry/models';
import { ClientFacingNoteModel } from '../comments/client-facing-note/client-facing-note.model';
import { NodeCompletionStatus } from '../model/node-completion-status.interface';
import {
  ActivityInputClientFacingNoteContext,
  CrossReferenceClientFacingNoteContext,
  FormFieldClientFacingNoteContext,
  LabItemsClientFacingNoteContext,
  LabItemsPreparationClientFacingNoteContext,
  PreparationsClientFacingNoteContext,
  TableCellClientFacingNoteContext
} from '../comments/client-facing-note/client-facing-note-event.model';
import { addToCache, elnShareReplay, objectCache } from '../../../app/shared/rx-js-helpers';
import { RuleActionHandlerHostItemType, RuleActionHandlerHostService } from '../../rule-engine/handler/rule-action-handler-host.service';
import { DataRecordService, SetVariableEventNotification } from './data-record.service';
import { UnitLoaderService } from 'services/unit-loader.service';
import { isEqual, mapValues, uniq } from 'lodash-es';
import { BptGridPreferences } from 'bpt-ui-library/bpt-grid';
import { ExperimentOptionsHelper } from '../../shared/experiment-options-helper';
import { PreparationItem } from '../../preparation/models/preparation-presentation.model';
import { UserService } from '../../services/user.service';
import { TemplateInsertLocationOptions } from '../../recipe-template-loader/experiment-template-load/models/recipe-template-insert-location-options';
import { RuleHandler, RuleProcessing } from '../../rule-engine/rule-handler';
import { RuleActionNotificationService } from '../../rule-engine/action-notification/rule-action-notification.service';
import { HttpErrorResponse } from '@angular/common/http';
import { FileUploadedResponse } from '../../api/file-integration/models/ELN/FileIntegration/Api/File/file-uploaded-response';
import { TableDataService } from '../data/table/table-data.service';
import { Logger } from '../../services/logger.service';
import { InstrumentType } from '../instrument-connection/shared/instrument-type';
import { ActivityInputItemState } from '../barcode-scanner/activity-input-item-state';
import { ReferencesService } from '../references/references.service';
import { ActivityReferencesPseudoModuleTitle } from '../references/references.component';
import { MessageService } from 'primeng/api';
import { Params } from '@angular/router';
import { elnDecodeSpecialChars } from '../../shared/url-path-serializer';
import { ExperimentRecord } from '../../api/search/models';
import { AuditHistoryDataRecordResponse, ExperimentEventType } from '../../api/audit/models';
import { ChangeReasonsSliderDetails } from '../model/change-reason-slider-details';

// Aliases for in-place augmentations from an incoming object to a view model
type AugmentedExperiment = Omit<ExperimentResponse, 'clientFacingNotes'> & Experiment;
type AugmentedActivity = ActivityNode & Activity & TemplateNodeRule;
type AugmentedModule = Module & ModuleNode & TemplateNodeRule;
type AugmentedForm = FormNode & FormResponse & TemplateNodeRule & FormWithFieldDefinitionsResponse;
type AugmentedTable = Table & TableNode;

/** simplification and clarification of possible objects for activity preloading */
type ActivityPreloadType = ActivityNode | Unit[] | UnitList[] | void | TableNode;

/** Dictionary of Form Templates */
interface Forms {
  [key: string]: FormTemplate;
}

/** Dictionary of Table Templates */
interface Tables {
  [key: string]: TableTemplate;
}

export type SpecificationEditorContext = {
  /** Arbitrary, unique id, such a a UUID string created by the emitter of ExperimentService.beginEditSpecification */
  id: string,
  /** Initial value. */
  value: SpecificationValue,
  readOnly: boolean,
  disabled: boolean,
  allowedUnits: Unit[],
  allowedSpecTypes: SpecType[],
  defaultUnit?: Unit,
  /** "Callback" Subject to emit a committed value into, such as by the Commit button of specificationInputComponent */
  onChange: Subject<SpecificationValue>,
  onClose?: Subject<never>
}

@Injectable({
  providedIn: 'root'
})
export class ExperimentService {
  public readonly promptTypeToActivityInputTypeMapping: {
    [key: string]: ActivityInputType
  } = {
      invalid: ActivityInputType.Invalid,
      materials: ActivityInputType.Material,
      instruments: ActivityInputType.InstrumentDetails,
      columns: ActivityInputType.InstrumentColumn,
      consumablesAndSupplies: ActivityInputType.Consumable,
      preparations: ActivityInputType.Preparation
    }

  private static readonly Assembly: string = 'ELN.Blazor.Entry' as const;
  static readonly CacheUnits: string = 'CacheUnits' as const;
  private readonly storageConditionId = 'eb7aef54-5e41-4b2f-879c-c246152f5e4a';
  private readonly activityReferenceKey = 'activityReferenceKey';
  private experiment?: Experiment;
  /**
   * @deprecated ExperimentResponse is not the current state of the Experiment. Experiment is.
   * We can sometimes get away with this. But don't count always reloading the experiment to get this refreshed.
   */
  private experimentResponse?: ExperimentResponse;
  private readonly tableList: Tables[] = [];
  private readonly formList: Forms[] = [];
  /** Subject that emits node status changes to/from 100% */
  public readonly nodeCompletionStatus = new Subject<NodeCompletionStatus>();
  public readonly experimentWorkFlowState = new Subject<ExperimentWorkflowState>();
  public readonly instrumentEventRemoved = new Subject<boolean>();
  public readonly clientFacingNoteEvents = new Subject<ClientFacingNoteModel>();
  public readonly activityCompletionStatus = new Subject<Activity>();
  public readonly experimentFlags = new Subject<ExperimentRaisedFlag[]>();
  public readonly beginEditSpecification = new Subject<SpecificationEditorContext>();
  public readonly activitySelectionChanged = new Subject<string | undefined>();
  public readonly referenceTypeAdded = new Subject<ReferenceGridType>();
  public readonly addLabItemsConsumable = new Subject<void>();
  public readonly labItemsConsumableAdded = new Subject<void>();
  public readonly crossReferenceAdded = new Subject<{ crossRef: CrossReference, exptRecord: ExperimentRecord }>();
  public readonly doNotWishToBeACollaborator = new Subject<ActivityInputType>();
  public readonly isExperimentTitleEmpty = new Subject<boolean>();
  public readonly isExperimentTitleNotChanged = new Subject<boolean>();
  public readonly isExperimentTitleChanged = new Subject<string>();
  public readonly isSubBusinessUnitNotSelected = new Subject<boolean>();
  public readonly isSubBusinessUnitNotChanged = new Subject<boolean>();
  public readonly isSubBusinessUnitSelected = new Subject<boolean>();
  public readonly activityFileUploaded = new Subject<FileUploadedResponse>();
  public readonly changeReasonSliderDisplayDetails = new Subject<ChangeReasonsSliderDetails>();
  public readonly barcodeScanned = new Subject<{ scanStatus: ActivityInputItemState, scannerMode: ActivityInputType, instrumentType: InstrumentType }>();
  private _currentActivityId: string | undefined;
  public pickList: UserPicklistResponse[] = [];
  isLoadingDocumentsOrCompendia = false;
  public currentContextMenuActivityId = '';
  public insertLocationOptions: TemplateInsertLocationOptions[] = [];
  loadAuditHistoryForActivityIds: string[] = [];

  get currentActivityId(): string {
    if (!this._currentActivityId) {
      if (!this.currentExperiment?.reservedInstances[0].instanceId) {
        this.showNullInstanceIDMessage();
        return '';
      } else {
        return this.currentExperiment?.reservedInstances[0].instanceId
      }
    }
    return this._currentActivityId;
  }
  set currentActivityId(value: string | undefined) {
    this._currentActivityId = value;
    this.activitySelectionChanged.next(value);
  }

  get currentActivity(): Activity | undefined {
    if (!this.currentExperiment) return undefined;
    if (!this._currentActivityId) return undefined;
    return this.currentExperiment.activities.find((a) => a.activityId === this._currentActivityId);
  }

  /**
   * @deprecated (Repairable) An experiment doesn't always have a current module. And, there can be bugs that miss updating it as a module becomes current or ceases to be current.
   */
  currentModuleId = '';
  /**
   * @deprecated (Repairable) An experiment doesn't always have a current module. And, there can be bugs that miss updating it as a module becomes current or ceases to be current.
   */
  currentModuleName = '';
  get currentExperiment(): Experiment | undefined {
    return this.experiment;
  }

  /**
   * @deprecated ExperimentResponse is not the current state of the Experiment. Experiment is.
   * We can sometimes get away with this. But don't count always reloading the experiment to get this refreshed.
   */
  get currentExperimentResponse(): ExperimentResponse | undefined {
    return this.experimentResponse;
  }

  get formsList(): Forms[] {
    return this.formList;
  }

  get tablesList(): Tables[] {
    return this.tableList;
  }

  set lastProcessedDataRecordTimeStamp(timeStamp: string) {
    if (this.experiment) this.experiment.lastProcessedDataRecordTimeStamp = timeStamp;
  }

  public _isCurrentUserCollaborator: boolean | undefined;
  public readonly reviewerIsNowCollaborator = new Subject<void>();
  public readonly reviewerIsForCollaboratorWarning = new Subject<void>();
  public _isCurrentUserCollaboratorSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public _showOutputAttachments = true;
  public readonly openAttachments = new Subject<boolean>();
  public readonly recipeBlobDetailsFetched = new Subject<boolean>();

  constructor(
    private readonly logger: Logger,
    public readonly experimentNodesService: ExperimentNodesService,
    private readonly templatesService: TemplatesService,
    private readonly picklistService: UserPicklistsService,
    public readonly templateEventService: ExperimentTemplateEventService,
    private readonly experimentEventsApiService: ExperimentEventsService,
    private readonly unitLoaderService: UnitLoaderService,
    private readonly internalCommentService: InternalCommentService,
    private readonly ruleActionNotificationService: RuleActionNotificationService,
    private readonly userService: UserService,
    private readonly labsiteService: LabsiteService,
    private readonly messageService: MessageService,
    private readonly experimentBlobEventsService: ExperimentBlobEventsService,
    @Inject('ExperimentTableDataService') private readonly tableDataService: TableDataService<'experiment'>,
  ) {
    this.templateEventService.LoadedExperiment = () => this.currentExperiment;
    this.templateEventService.TemplateApplyCommandFinalized.subscribe(command => {
      this.sendExperimentTemplateApplyCommand(command);
    });
    this.templateEventService.RecipeApplyCommandFinalized.subscribe(command => {
      this.sendExperimentRecipeApplyCommand(command);
    });
    this.crossReferenceAdded.subscribe(crossReference => this.updateCrossReferenceCompletion(crossReference.crossRef));
  }

  GetActivityBasedOnParams(params: Params): Activity | void {
    if (params[this.activityReferenceKey]) {
      const activityReferenceKeyDecoded = elnDecodeSpecialChars(params[this.activityReferenceKey]);
      return this.experiment?.activities.find(
        (a) => a.itemTitle === activityReferenceKeyDecoded || a.activityReferenceNumber === activityReferenceKeyDecoded
      );
    }
  }

  HasActivities(): boolean {
    return this.currentExperiment !== undefined && (this.currentExperiment.activities.length > 0);
  }

  private showNullInstanceIDMessage() {
    this.messageService.add({
      key: 'notification',
      severity: 'error',
      summary: $localize`:@@nullInstanceId:Instance ID has been set to null`,
      sticky: false
    });
  }

  public getForm(formId: string): Form | undefined {
    const allModules = this.currentExperiment?.activities.flatMap((a: Activity) => a.dataModules);
    const allModuleItems = allModules?.flatMap(m => m.items);
    const allForms = (allModuleItems?.filter(i => i.itemType === NodeType.Form) ?? []) as Form[];
    return allForms.find((f: Form) => f.formId === formId);
  }

  public getTable(tableId: string): Table | undefined {
    const allModules = this.currentExperiment?.activities.flatMap((a: Activity) => a.dataModules);
    const allModuleItems = allModules?.flatMap(m => m.items);
    const allModuleTables = allModuleItems?.filter(i => i.itemType === NodeType.Table) as Table[];

    const referenceTables = this.currentExperiment?.activities
      .flatMap(act => [act.activityReferences?.compendiaTable, act.activityReferences?.documentsTable])
      .filter(tbl => !!tbl) as Table[];
    const allTables = referenceTables?.length > 0 ? allModuleTables.concat(referenceTables) ?? [] : allModuleTables ?? [];

    return allTables.find((t: Table) => t.tableId === tableId);
  }

  private getTables(tableIds: string[]) {
    return this.currentExperiment?.activities.flatMap((a) =>
      a.dataModules.flatMap((dm) =>
        dm.items.filter(
          (item) => item.itemType === NodeType.Table && tableIds.includes((item as Table).tableId)
        )
      )
    );
  }

  public tableIsActivityReference(tableId: string): boolean {
    const referenceTables = this.currentExperiment?.activities
      .flatMap(act => [act.activityReferences?.compendiaReferencesTableId, act.activityReferences?.documentReferencesTableId])
      .filter(id => !!id);

    return !!referenceTables?.includes(tableId);
  }

  public setVariableUsingCommand(ruleData: SetVariableCommand | SetVariableEventNotification) {
    if (!this.currentExperiment) {
      return;
    }
    this.currentExperiment.variablesNode.variables[ruleData.name] = {
      value: ruleData.value,
      nodeId: ruleData.nodeId
    };
    RuleActionHandlerHostService.ItemVariablesNode = this.currentExperiment.variablesNode;
    RuleActionHandlerHostService.delegateCacheVariablesToBlazor();
  }

  public isTableComplete(table: Table): boolean {
    for (const row of table.value) {
      if (TableDataService.rowIsRemoved(row)) continue;
      if (TableDataService.rowIsPlaceholder(row)) continue;

      for (const column of table.columnDefinitions.filter(c => c.field !== 'id')) {
        const cellValue: ModifiableDataValue | undefined = row[column.field];
        if (!cellValue || cellValue.value.state === ValueState.Empty) return false;
      }
    }
    return true;
  }

  updateCrossReferenceCompletion(crossReference: CrossReference): void {
    if (!this.currentExperiment) return;
    const activity = this.currentExperiment?.activities.find(a => a.activityReferences.crossReferences.find(r => r.id === crossReference.id));
    if (!activity) return;

    const nodeStatus = {
      title: [activity.itemTitle, ActivityReferencesPseudoModuleTitle, $localize`:@@crossReferences:Cross References`].join(),
      id: `${activity.activityId}/crossReferences`,
      isComplete: ExperimentService.isCrossReferencesComplete(activity.activityReferences.crossReferences),
    };

    const existing = this.currentExperiment.experimentCompletionStatus.find(c => c.id === nodeStatus.id);
    if (!existing) {
      this.currentExperiment.experimentCompletionStatus.push(nodeStatus);
    } else {
      existing.isComplete = nodeStatus.isComplete;
    }
  }

  public static isCrossReferencesComplete(table: CrossReference[]): boolean {
    return table
      .filter(r => !r.isRemoved)
      .every(ReferencesService.crossReferenceIsComplete);
  }

  public isConsumableComplete(consumable: ActivityLabItemsNode): boolean {
    let isCompleted = true;
    for (const row of consumable.consumables) {
      for (const tableRow of row.tableData) {
        Object.keys(tableRow).forEach((fieldName) => {
          if (fieldName !== 'itemReference' || 'rowIndex') {
            const fieldValue = tableRow[fieldName].value;
            if (!fieldValue || fieldValue.state === ValueState.Empty) {
              isCompleted = false;
              return false;
            }
          }
          return isCompleted;
        });
      }
    }
    return isCompleted;
  }

  public isCollaboratorWarningRequired(): boolean {
    const puid = this.userService.currentUser?.puid;
    return this.userService.hasAnalystRights(puid) && !this.userService.hasReviewerRights(puid) && !this.userService.hasSupervisorRights(puid);
  }

  public isFormComplete(form: FormWithFieldDefinitionsResponse): boolean {
    for (const formItem of form.fieldDefinitions) {
      if (form.value[formItem.field]) {
        if (
          (formItem.itemType === FormItemType.FieldGroup &&
            this.isFieldGroupComplete(formItem as FieldGroupResponse, form.value[formItem.field]) === false) ||
          (formItem.itemType === FormItemType.Field &&
            form.value[formItem.field]['value']?.state === ValueState.Empty)
        ) {
          return false;
        }
      } else {
        return false;
      }
    }
    return true;
  }

  private isFieldGroupComplete(fieldGroup: FieldGroupResponse, formValues: { [key: string]: any }): boolean {
    for (const formItem of fieldGroup.fieldDefinitions) {
      if (formValues[formItem.field]) {
        if (
          (formItem.itemType === FormItemType.FieldGroup &&
            this.isFieldGroupComplete(formItem as FieldGroupResponse, formValues[formItem.field]) === false) ||
          (formItem.itemType === FormItemType.Field &&
            formValues[formItem.field]['value']?.state === ValueState.Empty)
        ) {
          return false;
        }
      } else {
        return false;
      }
    }
    return true;
  }

  public setExperimentFlags(flags: ExperimentRaisedFlag[]) {
    this.experimentFlags.next(flags);
  }

  public applyFieldChange(formId: string, fieldPath: string[], newValue: any) {
    const form = this.getForm(formId);
    if (form) {
      let field: any = form.value;
      for (let index = 0; index < fieldPath.length; index++) {
        const path: any = fieldPath[index];
        if (index === fieldPath.length - 1) {
          if (!isEqual(field[path]?.value, newValue)) field[path] = DataRecordService.getModifiableDataValue(newValue, field[path]);
        } else {
          if (!field[path]) field[path] = {};
          field = field[path];
        }
      }
    }
  }

  public applyRowRemovedDataRecord(data: RowRemovedEventNotification) {
    const table = this.getTable(data.tableId);
    if (!table) {
      this.logger.logWarning(`RowRemovedEventNotification with tableId ${data.tableId} but table doesn't exist locally`);
      // shouldn't matter: could happen if row removed is received/processed before template applied or similar
      return;
    }

    const row = table.value.find(r => r.id === data.rowId);
    if (!row) {
      this.logger.logWarning(`RowRemovedEventNotification with tableId ${data.tableId} rowId ${data.rowId} doesn't match table locally`);
      // shouldn't matter: could happen if row removed is received/processed before row added or similar
      return;
    }

    row[TableDataService.isRemovedColumn] = true;
  }

  public applyRowRestoredDataRecord(data: RowRestoredEventNotification) {
    const table = this.getTable(data.tableId);
    if (!table) {
      this.logger.logWarning(`RowRestoredEventNotification with tableId ${data.tableId} but table doesn't exist locally`);
      // shouldn't matter: could happen if row restored is received/processed before template applied or similar
      return;
    }

    const row = table.value.find(r => r.id === data.rowId);
    if (!row) {
      this.logger.logWarning(`RowRestoredEventNotification with tableId ${data.tableId} rowId ${data.rowId} doesn't match table locally`);
      // shouldn't matter: could happen if row restored is received/processed before row added or similar
      return;
    }

    row[TableDataService.isRemovedColumn] = false;
  }

  public applyRowsRenumberedDataRecord(renumbered: RowsRenumberedEventNotification) {
    const table = this.getTable(renumbered.tableId);
    if (!table) {
      this.logger.logWarning(`RowsRenumberedEventNotification with tableId ${renumbered.tableId} but table doesn't exist locally`);
      // shouldn't matter: could happen if row renumbered is received/processed before template applied or similar
      return;
    }

    this.tableDataService.assignNewStepNumbers(renumbered.stepNumbers, table, renumbered.stepNumberField);
  }

  private unpackRowData(row: Row): { [key: string]: ExperimentDataValue } {
    const dataValues: { [key: string]: ExperimentDataValue } = {};
    row.data.forEach((cell) => {
      dataValues[cell.propertyName] = cell.propertyValue;
    });
    return dataValues;
  }

  /** The following code will help to sync collaborative data of non active table stored in local memory */
  public applyAddRow(tableId: string, rows: Row[]) {
    const mapValue: (value: any) => ModifiableDataValue = value => ({
      isModified: false,
      value
    });

    const newRows = rows.map((r: any) => ({
      id: r.id,
      ...mapValues(this.unpackRowData(r), mapValue)
    }));

    const tables = this.getTables([tableId]);
    // this gets the table which has the provided id.
    // if the selector returns any element then the table is loaded in the document and data update can be skipped
    const domTableElements = document.querySelectorAll(`app-data-table[data-id='${tableId}'] bpt-grid`);
    if (domTableElements.length === 0 && tables && tables.length > 0) {
      (tables[0] as Table).value.push(...newRows);
    }
  }

  /** The following code will help to sync collaborative data of non active table stored in local memory */
  public applyCellChange(tableIds: string[], rowIds: string[], columnValues: Cell[]) {
    const tables = this.getTables(tableIds);
    tables?.forEach((moduleItem) => {
      const rows = moduleItem.value.filter((r: { id: string }) => rowIds.includes(r['id']));
      rows.forEach((r: any) => {
        columnValues.forEach((col) => {
          const propertyValue = DataRecordService.getModifiableDataValue(
            col.propertyValue,
            r[col.propertyName]
          );
          r[col.propertyName] = propertyValue;
        });
      });
    });
  }

  getScannedInputKeys(activityId: string): string[] {
    const activityInputNode = this.currentExperiment?.activityInputs;
    const activityInputSample = activityInputNode?.filter(input => input.activityId === activityId);
    const aliquotKeys = (activityInputSample ?? [])
      .flatMap((input) => input.aliquots.filter((aliquot) => !aliquot.isRemoved))
      .map((aliquot) => aliquot.sampleNumber);
    const activityInputMaterial = activityInputNode?.find(input => input.activityId === activityId);
    const materialKeys = (activityInputMaterial?.materials ?? [])
      .filter((material) => !material.isRemoved)
      .map((material) => material.code);
    return uniq([...aliquotKeys, ...materialKeys]);
  }

  /**
   * Checks if a specified instrument exists (and is not removed) in any of the experiment's activities' lab items.
   */
  isInstrumentExistInCurrentExperimentLabItems(instrumentId: string): boolean {
    const activityLabItems = this.currentExperiment?.activityLabItems ?? [];
    for (const labItem of activityLabItems) {
      for (const instrument of labItem.instruments) {
        if (instrument.code === instrumentId && !instrument.isRemoved) {
          return true;
        }
      }
    }
    return false;
  }

  private sendExperimentTemplateApplyCommand(command: ExperimentTemplateApplyCommand): void {
    this.experimentEventsApiService
      .experimentEventsAddTemplatePost$Json({ body: command })
      .subscribe({
        next: response => this.processExperimentTemplateAppliedResponse(response),
        error: (errorResponse: HttpErrorResponse) => this.processExperimentTemplateAppliedResponse(errorResponse.error)
      });
  }

  private sendExperimentRecipeApplyCommand(command: ExperimentRecipeApplyCommand): void {
    this.experimentEventsApiService
      .experimentEventsAddRecipePost$Json({ body: command })
      .subscribe({
        next: response => this.templateEventService.RecipeApplySuccessNotification(response),
        error: (errorResponse: HttpErrorResponse) => this.processExperimentTemplateAppliedResponse(errorResponse.error)
      });
  }
  private processExperimentTemplateAppliedResponse(response: ExperimentTemplateAppliedResponse) {
    if (response.notifications && response.notifications.notifications.length > 0) {
      this.templateEventService.TemplateApplyFailedNotification(response.notifications);
    } else {
      this.templateEventService.TemplateApplySuccessNotification(response);
    }
  }

  /**
   * Retrieves the experiment identified by experimentNumber or undefined if no such.
   * Performs in-place augmentation to:
   *   * Recreate the experiment's tree structure
   *   * Populate the properties of nodes that aren't stored because they are fixed by the node's original template definition. (e.g. column definitions and form structure)
   *   * Populates picklists that are sourced from user-picklists because they are not experiment data so not stored and are dynamic lists (in a limited sense)
   */
  public loadExperiment(experimentNumber: string): Observable<Experiment | undefined> {
    const setupRuleActionHandlerHostService = (experiment: Experiment) => {
      RuleActionHandlerHostService.CurrentItemId = experiment.id;
      RuleActionHandlerHostService.ItemVariablesNode = experiment.variablesNode;
      RuleActionHandlerHostService.ItemType = RuleActionHandlerHostItemType.Experiment;
    }
    return this.experimentNodesService
      .experimentNodesExperimentNumberGet$Json({ experimentNumber })
      .pipe(
        map(snapshot => this.arrangeExperimentProperties(snapshot)),
        map(snapshot => this.populateFromTemplates(snapshot)),
        mergeAll(),
        map(experiment => {
          setupRuleActionHandlerHostService(experiment);
          return experiment;
        }),
        // Now, things that must be done before the Experiment is used
        mergeMap(experiment => from(this.cacheUnitsInBlazor().then(() => experiment))), // cacheUnitsInBlazor before any rules run
        mergeMap(experiment => from(this.triggerNodeLoadedEvents(experiment).then(() => experiment)))
      ); // this is an in-place augmentation of the same object
  }

  /**
   * Iterates through the tree in postorder and for a node than can have a rule and triggers OnLoad for that node.
   * Note: triggers even if node has IsHidden true
   * Note: to have rules a node would have to be from a template (i.e., have a templateId)
   * Note: Full complex, function decomposition because SonarQube can't read simple, concise code. Now it's harder to see the full picture; sorry humans.
  */
  private async triggerNodeLoadedEvents(experiment: Experiment): Promise<void> {
    for await (const a of experiment.activities) {
      await this.triggerNodeLoadedEventsForActivity(a);
    }
  }

  private async triggerNodeLoadedEventsForActivity(activity: Activity): Promise<void> {
    if (!activity.nodeId) throw new Error('LOGIC ERROR: activity must have a node ID.');

    for await (const m of activity.dataModules) {
      await this.triggerNodeLoadedEventsForModule(m);
    }
    await this.nodeLoadedEventRuleEvaluation({ nodeId: activity.nodeId, templateId: activity.templateId, rules: activity.rules }, RuleHandler)?.promise ?? Promise.resolve();
    return Promise.resolve();
  }

  private async triggerNodeLoadedEventsForModule(module: Module): Promise<void> {
    if (!module.nodeId) throw new Error('LOGIC ERROR: module must have a node ID.');

    for await (const mi of module.items) {
      await this.triggerNodeLoadedEventsForModuleItem(mi);
    }
    await this.nodeLoadedEventRuleEvaluation({ nodeId: module.nodeId, templateId: module.templateId, rules: module.rules }, RuleHandler)?.promise ?? Promise.resolve();
    return Promise.resolve();
  }

  private async triggerNodeLoadedEventsForModuleItem(moduleItem: ModuleItem): Promise<void> {
    if (!moduleItem.nodeId) throw new Error('LOGIC ERROR: module item must have a node ID.');

    await this.nodeLoadedEventRuleEvaluation({ nodeId: moduleItem.nodeId, templateId: moduleItem.templateId, rules: moduleItem.rules }, RuleHandler)?.promise ?? Promise.resolve();
    return Promise.resolve();
  }

  private nodeLoadedEventRuleEvaluation(
    node: { nodeId: string, templateId: string, rules: TemplateRule[] },
    ruleHandlerConstructor: new (instanceId: string, templateId: string, rules: TemplateRule[], ruleActionNotificationService: RuleActionNotificationService) => RuleHandler
  ): RuleProcessing {
    const ruleHandler = new ruleHandlerConstructor(node.nodeId, node.templateId, node.rules, this.ruleActionNotificationService);
    const eventSource = node.nodeId; // perhaps not relevant to onLoad
    return ruleHandler.nodeLoaded(node, eventSource);
  }

  /**
   * Arranges Experiment properties from the ExperimentResponse.
   * Most of the changes are pulling up ExperimentResponse experiment node properties into the root experiment object.
   * @returns same object
   */
  private arrangeExperimentProperties(snapshot: ExperimentResponse): ExperimentResponse {
    if (!snapshot.experiment) throw new Error('Experiment header node is missing');

    this.experimentResponse = snapshot;
    const experiment = snapshot as AugmentedExperiment; // in-place augmentation coming up…
    experiment.activityOutputChromatographyData = experiment.activityOutputChromatographyData ?? [];
    experiment.activityOutputChromatographyResultSetsSummary = experiment.activityOutputChromatographyResultSetsSummary ?? [];
    experiment.id = snapshot.experiment.experimentId;
    experiment.experimentNumber = snapshot.experiment.experimentNumber;
    experiment.organization = snapshot.experiment.organization;
    experiment.clients = snapshot.experiment?.clients ?? [];
    experiment.projects = snapshot.experiment?.projects ?? [];
    experiment.title = snapshot.experiment.title;
    experiment.tracking = snapshot.experiment.tracking;
    experiment.tags = snapshot.experiment.tags;
    experiment.workflowState = snapshot.experiment.workflowState;
    experiment.reservedInstances = snapshot.experiment.reservedInstances;
    experiment.lastProcessedDataRecordTimeStamp = snapshot?.lastProcessedDataRecord?.lastProcessedDataRecordTimeStamp;

    if (!ExperimentOptionsHelper.getOptionsFromRoute().previewMode) {
      // It must be okay to load these in the background. Probably wins the race with other HTTP requests and the user.
      this.internalCommentService.experimentsExperimentIdInternalCommentsGet$Json({ experimentId: experiment.id })
        .subscribe({
          next: results => {
            experiment.internalComments = results;
          }
        }
        );
    }
    return snapshot;
  }

  /**
   * Drills down to forms and tables and populates their attributes that come from their referenced template
   * modifies and returns the same object for efficiency
   * snapshot is the playback state of an experiment (saved snapshot or not)
   */
  private populateFromTemplates(snapshot: ExperimentResponse): Observable<Experiment> {
    const experiment = snapshot as unknown as Experiment;
    this.experiment = experiment;

    experiment.experimentCompletionStatus ??= [];
    experiment.activities ??= [];
    // activities's elements, if any, would already be ActivityNodes so we don't need to create this property and add activities like with arrays deeper in the tree.
    snapshot.experiment.childOrder ??= [];
    experiment.activities.sort((a, b) => snapshot.experiment.childOrder.indexOf(b.activityId) - snapshot.experiment.childOrder.indexOf(a.activityId));

    experiment.activityOutputChromatographyData = experiment.activityOutputChromatographyData ?? [];
    experiment.activityOutputChromatographyResultSetsSummary = experiment.activityOutputChromatographyResultSetsSummary ?? [];
    if (snapshot.activityPreparations) {
      this.mappingPreparations(snapshot.activityPreparations, experiment.activities);
    }

    return forkJoin([this.unitLoaderService.allUnits$, this.getTemplateIds(snapshot)]).pipe(
      concatMap((result) => {
        // incoming _allUnits not used here directly because `UnitLoaderService` caches it, which is where it's used from at anytime thereafter

        this.addTemplatesToCache(result[1]);
        const observables: Observable<ActivityPreloadType>[] = [];
        observables.push(this.unitLoaderService.allUnitLists$); // for completeness. Not currently used by Experiment or ExperimentComponent.
        observables.push(...this.createActivityPreloadObservables(snapshot, experiment));

        experiment.clientFacingNotes = (snapshot.clientFacingNotes ?? []).map((note) => this.vivifyClientFacingNote(note));

        experiment.validationFailures = [];
        snapshot.validationResults.forEach(validation => {
          experiment.validationFailures = experiment.validationFailures?.concat(validation.validationFailures)
        });
        this.experiment = experiment;
        return forkJoin(observables);
      }),
      map((_) => experiment)
    );
  }

  private getTemplateIds(snapshot: ExperimentResponse) {
    const templateIds = new Set<string>();
    const addTemplateIdsFrom = (nodes: { templateId?: string }[]) => nodes.forEach((n) => { if (n.templateId) templateIds.add(n.templateId); })

    addTemplateIdsFrom(snapshot.activities);
    addTemplateIdsFrom(snapshot.modules);
    addTemplateIdsFrom(snapshot.forms);
    addTemplateIdsFrom(snapshot.tables);

    return this.templatesService.templatesGetTemplatesPost$Json({
      body: Array.from(templateIds)
    });
  }

  private addTemplatesToCache(templates: any[]) {
    templates.forEach(template => addToCache(template.templateId, template));
  }

  createActivityPreloadObservables(snapshot: ExperimentResponse, experiment: Experiment): Observable<ActivityPreloadType>[] {
    if (experiment.activities.length === 0) return [];

    const referenceObservables: Observable<TableNode>[] = [];
    return [
      ...snapshot.experiment.childOrder.map(activityId => {
        const activity = snapshot.activities?.find(a => a.activityId === activityId);
        if (!activity) throw new Error(`Activity ${activityId} not found`);

        (activity as AugmentedActivity).nodeId = activity.activityId;
        (activity as AugmentedActivity).rules ??= [];

        if ((activity as AugmentedActivity).templateId) {
          elnShareReplay(
            (activity as AugmentedActivity).templateId,
            this.templatesService.templatesIdGet$Json.bind(this.templatesService),
            {
              id: (activity as AugmentedActivity).templateId,
            }
          ).pipe(
            single(),
            map(template => template.rules)
          ).subscribe({
            next: (response) => {
              (activity as AugmentedActivity).rules.push(...response);
            }
          })
        }

        const exptModelActivity = activity.activityReferences as ActivityReferences;

        const documentsTableNode =
          snapshot.tables.find(t => t.tableId === activity.activityReferences.documentReferencesTableId);
        exptModelActivity.documentsTable = documentsTableNode as Table | undefined;
        if (documentsTableNode) referenceObservables.push(this.populateFromTableTemplate(documentsTableNode));

        const compendiaTableNode =
          snapshot.tables.find(t => t.tableId === activity.activityReferences.compendiaReferencesTableId);
        exptModelActivity.compendiaTable = compendiaTableNode as Table | undefined;
        if (compendiaTableNode) referenceObservables.push(this.populateFromTableTemplate(compendiaTableNode));

        // nothing to copy from an activity template so don't even look it up
        // Extension point: Copy future attributes of an activity template, if applicable
        return this.populateModules(snapshot, activity);
      }),
      ...referenceObservables
    ];
  }

  mappingPreparations(activityPreparations: ActivityPreparations[], activities: Activity[]) {
    const pickLists = this.loadStorageDropDownForPreparations();
    pickLists.subscribe((storageConditionData: UserPicklistResponse) => {
      this.pickList = storageConditionData.items;
    });
    if (activities.length !== 0 && activityPreparations.length !== 0) {
      activities.forEach((activity) => {
        const collectionOfPreparations: PreparationItem[] = [];
        const activityPreparation = activityPreparations.find((preparation: ActivityPreparations) => preparation.nodeId === activity.activityId) ?? undefined;
        activityPreparation?.preparations.forEach((preparation: ExperimentPreparation) => {
          const prepItem: PreparationItem = {
            additionalInformation: preparation.additionalInformation,
            internalInformation: preparation.internalInformation,
            summary: preparation.summary,
            name: preparation.name,
            preparationNumber: preparation.preparationNumber,
            status: preparation.status,
            preparationId: preparation.preparationId,
            expirationValue: preparation.expirationValue,
            description: preparation.description,
            isRemoved: preparation.isRemoved,
          };
          collectionOfPreparations.push(prepItem);
        });
        activity.preparations = collectionOfPreparations;
      });
    }
  }

  getSubBusinessUnits() {
    return elnShareReplay<LabsiteGetResponse>('subBusinessUnits', () =>
      this.labsiteService
        .labsitesSubBusinessUnitsGet$Json({
          labsiteCodes: this.userService.currentUser.labSiteCode
        })
    );
  }

  loadStorageDropDownForPreparations(): Observable<UserPicklistResponse> {
    return elnShareReplay<UserPicklistResponse>('storageCondition', () =>
      this.picklistService.userPicklistsIdGet$Json({ id: this.storageConditionId })
    );
  }

  private populateModules(snapshot: ExperimentResponse, activity: ActivityNode): Observable<ActivityNode> {
    (activity as AugmentedActivity).dataModules ??= [];
    activity.childOrder ??= [];

    const observables = activity.childOrder.map((moduleId) => {
      const module = snapshot.modules?.find((m) => m.moduleId === moduleId);
      const activityInput = snapshot.activityInputs?.find((m) => m.activityInputId === moduleId);
      const activityLabItem = snapshot.activityLabItems?.find((m) => m.activityLabItemNodeId === moduleId);
      if (module) {
        (activity as AugmentedActivity).dataModules?.push(module as AugmentedModule);
        return this.populateFromModuleTemplate(snapshot, module);
      }

      if (activityInput || activityLabItem) {
        return of(false);
      }

      throw new Error(`Module ${moduleId} not found`);
    });
    return forkJoin(observables).pipe(
      single(),
      map((_) => activity)
    );
  }

  private populateFromModuleTemplate(snapshot: ExperimentResponse, module: ModuleNode): Observable<ModuleNode> {
    // Extension point: Copy future attributes of a module template, if applicable
    (module as AugmentedModule).nodeId = module.moduleId;
    (module as AugmentedModule).rules ??= [];
    (module as AugmentedModule).items ??= [];
    module.childOrder ??= [];

    if ((module as AugmentedModule).templateId) {
      const replay = elnShareReplay(
        (module as AugmentedModule).templateId,
        this.templatesService.templatesIdGet$Json.bind(this.templatesService),
        {
          id: (module as AugmentedModule).templateId,
        }
      );
      replay.pipe(
        single(),
        map(template => template.rules)
      ).subscribe({
        next: (response) => {
          (module as AugmentedModule).rules.push(...response);
        }
      });
    }

    const observables = module.childOrder.map((moduleItemId) => {
      const form = snapshot.forms?.find((f) => f.formId === moduleItemId) as AugmentedForm;
      if (form) {
        (module as AugmentedModule).items.push(form);
        return this.populateFromFormTemplate(form);
      }

      const table = snapshot.tables?.find((t) => t.tableId === moduleItemId) as AugmentedTable;
      if (table) {
        table.preferencesReady = new Subject<BptGridPreferences>();
        (module as AugmentedModule).items.push(table);

        return this.populateFromTableTemplate(table);
      }

      throw new Error(`Module Item ${moduleItemId} not found`);
    });

    return forkJoin(observables).pipe(
      single(),
      map(() => module)
    );
  }

  /**
   * Sets Quantity field's default unit. (and build missing nested field group to do so.)
   * @param value Is either the form itself when first called, a field group the form already has
   * @param fieldDefinitions defined by the template but describe the initial values for a quantity field.
   */
  private configureFormQuantityFields(
    value: { [key: string]: any },
    fieldDefinitions: FormItemResponse[]
  ) {
    const fieldGroups = fieldDefinitions.filter(
      (item): item is FieldGroupResponse => item.itemType === 'fieldGroup'
    );

    fieldGroups.forEach((group) => {
      value[group.field] ??= {};
      this.configureFormQuantityFields(value[group.field], group.fieldDefinitions ?? []);
    });

    const quantitiesWithDefaults = fieldDefinitions.filter(
      (item): item is FieldDefinitionResponse =>
        (item as FieldDefinitionResponse)?.fieldType === FieldType.Quantity &&
        (item as FieldDefinitionResponse).fieldAttributes?.defaultUnit
    );

    quantitiesWithDefaults.forEach((item) => {
      const defaultUnit = this.unitLoaderService.allUnits.find(
        (unit: Unit) => unit.id === item.fieldAttributes.defaultUnit
      );
      if (defaultUnit) {
        if (value[item.field]) {
          const unit = (value[item.field] as NumberValue).unit;
          if (!unit || unit.length === 0) {
            // shouldn't happen that a NumberValue doesn't have a unit but this can help to deal with legacy values that might not
            (value[item.field] as NumberValue).unit = defaultUnit.id;
          }
        } else {
          value[item.field] = {
            isModified: false,
            value: {
              type: ValueType.Number,
              state: ValueState.Empty,
              unit: defaultUnit.id
            }
          };
        }
      } else {
        // if defaultUnit isn't found then it would be astonishing but just leave it alone
        console.error(
          'defaultUnit is not in the units dataset; Should never happen; Ignoring.',
          item.fieldAttributes.defaultUnit
        );
      }
    });
  }

  /**
   * @summary
   *   Populates fixed properties of the form by retrieving the template from the template service.
   * @returns
   *   Observable of the same form with fixed properties populated
   */
  private populateFromFormTemplate(form: FormNode): Observable<FormNode> {
    if (!form.templateId) {
      throw new Error(`Form ${form.formId} doesn't have a templateId`);
    }

    const observables = elnShareReplay(
      form.templateId,
      this.templatesService.templatesIdGet$Json.bind(this.templatesService),
      {
        id: form.templateId,
      }
    ).pipe(
      single(),
      map((template) => {
        this.sortFieldDefinitions(template as FormTemplate);
        this.getFormList(form, template);
        // Extension point: Copy future attributes of a table template, if applicable
        (form as AugmentedForm).nodeId = form.formId;
        (form as AugmentedForm).fieldDefinitions = (template as FormTemplate).fieldDefinitions;
        (form as AugmentedForm).rules = template.rules;

        this.configureFormQuantityFields(
          (form as AugmentedForm).value ?? {},
          (form as AugmentedForm).fieldDefinitions ?? []
        );
        return this.populateFieldsFromPicklists((form as AugmentedForm).fieldDefinitions ?? []);
      })
    );
    return observables.pipe(
      single(),
      mergeAll(),
      map((_) => form)
    );
  }

  private getFormList(form: FormNode, template: unknown) {
    const forms: Forms = {};
    forms[form.formId] = template as FormTemplate;
    this.formList.push(forms);
  }

  /**
   * @summary
   *   Populates fixed properties of the table by retrieving the template from the template service.
   * @returns
   *   Observable of the same table with fixed properties populated
   */
  private populateFromTableTemplate(table: TableNode): Observable<TableNode> {
    if (!table.templateId) throw new Error(`Table ${table.tableId} doesn't have a templateId`);

    const observables = elnShareReplay(
      table.templateId,
      this.templatesService.templatesIdGet$Json.bind(this.templatesService), {
      id: table.templateId,
    }
    ).pipe(
      single(),
      map((template) => {
        this.getTableList(table, template);
        // Extension point: Copy future attributes of a table template, if applicable
        (table as AugmentedTable).columnDefinitions = (
          template as TableTemplate
        ).columnDefinitions.map((apiColumn: ApiColumnSpecification) => {
          const allowedUnits = apiColumn.allowedUnits
            ? this.unitLoaderService.allUnits.filter(
              u => u.isAvailable && apiColumn.allowedUnits?.includes(u.id)
            )
            : undefined;
          const defaultUnit = this.unitLoaderService.allUnits.find(
            u => u.id === apiColumn.defaultUnit
          );
          return {
            ...apiColumn,
            allowedUnits,
            defaultUnit
          };
        });
        (table as AugmentedTable).allowPagination = (template as TableTemplate).allowPagination;
        (table as AugmentedTable).allowRangeSelection = (template as TableTemplate).allowRangeSelection;
        (table as AugmentedTable).allowReadOnly = (template as TableTemplate).allowReadOnly;
        (table as AugmentedTable).allowRowAdd = (template as TableTemplate).allowRowAdd;
        (table as AugmentedTable).allowMultipleRows = (template as TableTemplate).allowMultipleRows;
        (table as AugmentedTable).allowRowRemoval = (template as TableTemplate).allowRowRemoval;
        (table as AugmentedTable).columnDefaults = (template as TableTemplate).columnDefaults;
        (table as AugmentedTable).itemTitle = table.itemTitle;
        (table as AugmentedTable).nodeId = table.tableId;
        (table as AugmentedTable).rules = template.rules;
        return this.populateColumnsFromPicklists((table as AugmentedTable).columnDefinitions ?? []);
      })
    );
    return observables.pipe(
      single(),
      mergeAll(),
      map((_) => table)
    );
  }

  static isExperimentAuthorizedOrCancelled(data: ExperimentWorkflowState) {
    return (
      data === ExperimentWorkflowState.Authorized || data === ExperimentWorkflowState.Cancelled
    );
  }

  static isExperimentInReview(data: ExperimentWorkflowState) {
    return (
      data === ExperimentWorkflowState.InReview
    );
  }

  private getTableList(table: TableNode, template: unknown) {
    const tables: Tables = {};
    tables[table.tableId] = template as TableTemplate;
    this.tableList.push(tables);
  }

  /**
   * @summary
   *   Populates the listValues property of all fields with a listSource value by retrieving the list from the user-picklist service.
   *   Recurses into field groups.
   * @returns
   *   Observable of the same fieldDefinitions with listValues populated
   */
  private populateFieldsFromPicklists(
    fieldDefinitions: FormItemResponse[]
  ): Observable<FormItemResponse[]> {
    const observables: Observable<FormItemResponse[]>[] = fieldDefinitions?.map((item) => {
      if ((item as FieldGroupResponse).itemType === 'fieldGroup') {
        // field group doesn't support picklists but children might
        return this.populateFieldsFromPicklists(
          (item as FieldGroupResponse).fieldDefinitions as FormItemResponse[]
        );
      }

      const field = item as FieldDefinitionResponse;
      if (field.fieldType === FieldType.Quantity && field?.fieldAttributes?.defaultUnit) {
        return of([field as FormItemResponse]);
      }

      const dropdownAttributes = field?.fieldAttributes as DropdownAttributes;
      if (!dropdownAttributes?.listSource) {
        return of([field as FormItemResponse]);
      }

      return elnShareReplay(
        dropdownAttributes.listSource,
        this.picklistService.userPicklistsIdGet$Json.bind(this.picklistService),
        { id: dropdownAttributes.listSource }
      ).pipe(
        map((list) => {
          dropdownAttributes.listValues = list.items ?? [];
          return [field as FormItemResponse];
        })
      );
    });
    return forkJoin(observables).pipe(
      mergeAll(),
      map((_) => fieldDefinitions)
    );
  }

  /**
   * @summary
   *   Populates the listValues property of all columns with a listSource value by retrieving the list from the user-picklist service.
   * @returns
   *   Observable of the same columns with listValues populated
   */
  private populateColumnsFromPicklists(
    columns: ColumnSpecification[]
  ): Observable<ColumnSpecification[]> {
    const observables = columns.map((column) => {
      if (!column.listSource) return of(column);

      return elnShareReplay(
        column.listSource,
        this.picklistService.userPicklistsIdGet$Json.bind(this.picklistService),
        { id: column.listSource }
      ).pipe(
        map((list) => {
          column.listValues = list.items;
          return column;
        })
      );
    });
    return forkJoin(observables);
  }

  vivifyClientFacingNote(data: ClientFacingNote): ClientFacingNoteModel {
    switch (data.contextType) {
      case ClientFacingNoteContextType.FormField: {
        const context: FormFieldClientFacingNoteContext = {
          formId: data.nodeId,
          fieldIdentifier: data.path[data.path.length - 1]
        };
        return new ClientFacingNoteModel(data.contextType, data, context);
      }
      case ClientFacingNoteContextType.TableCell: {
        const context: TableCellClientFacingNoteContext = {
          tableId: data.nodeId,
          rowId: data.path[0],
          columnField: data.path[1]
        };
        return new ClientFacingNoteModel(data.contextType, data, context);
      }
      case ClientFacingNoteContextType.ActivityInput: {
        const context: ActivityInputClientFacingNoteContext = {
          activityInputId: data.nodeId,
          rowId: data.path[0],
          columnField: data.path[1],
          activityId: data.path[2],
          tableTitle: data.path[3],
          label: data.path[4]
        };
        return new ClientFacingNoteModel(data.contextType, data, context);
      }
      case ClientFacingNoteContextType.CrossReference: {
        const context: CrossReferenceClientFacingNoteContext = {
          activityId: data.nodeId,
          rowId: data.path[0],
          columnField: data.path[1]
        };
        return new ClientFacingNoteModel(data.contextType, data, context);
      }
      case ClientFacingNoteContextType.LabItems: {
        const context: LabItemsClientFacingNoteContext = {
          labItemId: data.nodeId,
          rowId: data.path[0],
          columnField: data.path[1],
          labItemType: data.path[3]
        };
        return new ClientFacingNoteModel(data.contextType, data, context);
      }
      case ClientFacingNoteContextType.Preparations: {
        const context: PreparationsClientFacingNoteContext = {
          nodeId: data.nodeId,
          preparationsTableId: data.nodeId.concat('-Preparations'),
          rowId: data.path[0],
          columnField: data.path[1]
        };
        return new ClientFacingNoteModel(data.contextType, data, context);
      }
      case ClientFacingNoteContextType.LabItemsPreparation: {
        const context: LabItemsPreparationClientFacingNoteContext = {
          nodeId: data.nodeId,
          preparationsTableId: data.nodeId + '-labItemsPreparations',
          rowId: data.path[0],
          columnField: data.path[1],
          labItemsPreparationIdentifier: 'labItemsPreparation'
        };
        return new ClientFacingNoteModel(data.contextType, data, context);
      }
      default: {
        throw new Error(
          `Logic Error: Not implemented. ClientFacingNoteContextType ${data.contextType}`
        );
      }
    }
  }

  changeTitle(value: ChangeExperimentTitleCommand) {
    this.experimentEventsApiService.experimentEventsChangeTitlePost$Json({
      body: value
    }).subscribe({
      next: (response) => {
        this.isExperimentTitleChanged.next(response.titleChangedEventNotification.title);
      }
    });
  }

  titleIsEmpty(): Observable<boolean> {
    this.isExperimentTitleEmpty.next(true);
    return this.isExperimentTitleEmpty;
  }

  titleNotChanged(): Observable<boolean> {
    this.isExperimentTitleNotChanged.next(true);
    return this.isExperimentTitleNotChanged;
  }

  changeTags(value: ChangeExperimentTagsCommand) {
    return this.experimentEventsApiService.experimentEventsChangeTagsPost$Json({
      body: value
    });
  }

  changeSubBusinessUnits(value: ChangeExperimentSubBusinessUnitsCommand) {
    this.experimentEventsApiService.experimentEventsChangeSubBusinessUnitsPost$Json({
      body: value
    }).subscribe({
      next: () => {
        this.isSubBusinessUnitSelected.next(true);
      }
    });
  }

  subBusinessUnitNotSelected(): Observable<boolean> {
    this.isSubBusinessUnitNotSelected.next(true);
    return this.isSubBusinessUnitNotSelected;
  }

  subBusinessUnitNotChanged(): Observable<boolean> {
    this.isSubBusinessUnitNotChanged.next(true);
    return this.isSubBusinessUnitNotChanged;
  }

  changeAssignedReviewers(value: ChangeExperimentAssignedReviewersCommand) {
    return this.experimentEventsApiService.experimentEventsChangeAssignedReviewersPost$Json({
      body: value
    });
  }

  changeAssignedSupervisors(value: ChangeExperimentAssignedSupervisorsCommand) {
    return this.experimentEventsApiService.experimentEventsChangeAssignedSupervisorsPost$Json({
      body: value
    });
  }

  changeAssignedAnalysts(value: ChangeExperimentAssignedAnalystsCommand) {
    return this.experimentEventsApiService.experimentEventsChangeAssignedAnalystsPost$Json({
      body: value
    });
  }

  changeAuthorizationDueDate(value: ChangeExperimentAuthorizationDueDateCommand) {
    return this.experimentEventsApiService.experimentEventsChangeAuthorizationDueDatePost$Json({
      body: value
    });
  }

  changeScheduledStartDate(value: ChangeExperimentScheduledStartDateCommand) {
    return this.experimentEventsApiService.experimentEventsChangeScheduledStartDatePost$Json({
      body: value
    });
  }

  changeScheduledReviewStartDate(value: ChangeExperimentScheduledReviewStartDateCommand) {
    return this.experimentEventsApiService.experimentEventsChangeScheduledReviewStartDatePost$Json({
      body: value
    });
  }

  private sortFieldDefinitions(template: FormTemplate): void {
    template.fieldDefinitions.sort((lt, rt) => lt.row - rt.row);
  }

  public cacheUnitsInBlazor(): Promise<void> {
    try {
      return DotNet.invokeMethodAsync<void>(
        ExperimentService.Assembly,
        ExperimentService.CacheUnits,
        JSON.stringify(this.unitLoaderService.allUnits)
      );
    } catch (error) {
      console.error('Unable to invoke CacheUnitList', error);
      return new Promise<void>((_resolve, reject) => {
        reject();
      });
    }
  }

  setActivityCompletionStatus(activity: Activity) {
    this.activityCompletionStatus.next(activity);
  }

  static formatWorkflowState(state: string): string {
    const states: { [key: string]: string } = {
      // casing has to be flexible to match various data sources, experiment search service vs GET experiment-nodes
      setup: $localize`:@@setupState:Setup`,
      inprogress: $localize`:@@inProgressState:In Progress`,
      inreview: $localize`:@@inReviewState:In Review`,
      incorrection: $localize`:@@inCorrectionState:In Correction`,
      cancelled: $localize`:@@cancelledState:Cancelled`,
      authorized: $localize`:@@authorizedState:Authorized`,
      restored: $localize`:@@restoredState:Restored`
    };
    return states[state.toLowerCase()] ?? state;
  }

  public renderAppliedTemplate(
    activity: Activity,
    data:
      ReferenceTemplateAppliedEventNotification
      | (ApplyReferenceTemplateCommand & ReferenceTemplateAppliedResponse)
  ) {
    if (!this.experiment) throw new Error('Expected experiment data to be defined');

    const typeAsString = data.type.toString();

    // mimic data record processing
    const tableNode: TableNode = {
      isHidden: false,
      itemTitle: typeAsString[0].toUpperCase() + typeAsString.substring(1), // Capitalize first letter
      itemType: NodeType.Table,
      sourceTemplateId: data.templateId,
      tableId: data.tableId,
      templateId: data.templateId,
      experimentId: this.experiment.id,
      value: []
    } as unknown as TableNode; // We don't have access to all data that is unused, like createdBy, createdOn and _ts

    this.populateFromTableTemplate(tableNode).subscribe(table => {
      switch (data.type) {
        case ReferenceTemplateType.Documents:
          activity.activityReferences.documentReferencesTableId = data.tableId;
          activity.activityReferences.documentsTable = table as unknown as Table; // See above comment
          break;
        case ReferenceTemplateType.Compendia:
          activity.activityReferences.compendiaReferencesTableId = data.tableId;
          activity.activityReferences.compendiaTable = table as unknown as Table; // See above comment
          break;
      }
    });
  }

  public isClientFacingNoteEnabled(): boolean {
    return !(this.userService.hasOnlyReviewerRights() || this.currentExperiment?.workflowState === ExperimentWorkflowState.InReview);
  }

  /**
  * indicates true when current user contributed to experiment data entry, the determination happened
  * at the time of experiment load hence if the user contribution to data after experiment loaded will take affect
  * using refreshCollaborationStatusOfCurrentUser.
  */
  amICollaborator(returnMostRecentState = false): Observable<boolean | undefined> {
    if (returnMostRecentState) return of(this._isCurrentUserCollaborator);
    if (this._isCurrentUserCollaborator) return of(true);
    if (!this.currentExperiment) return of(undefined);

    return this.experimentNodesService
      .experimentNodesExperimentIdAmICollaboratorGet$Json({
        experimentId: this.currentExperiment.id
      })
      .pipe(
        tap({
          next: (amICollaborator) => {
            this._isCurrentUserCollaborator = amICollaborator;
            this._isCurrentUserCollaboratorSubject$.next(amICollaborator);
          }
        })
      );
  }

  markReviewerAsCollaborator(isCollaboratorWarningMessage = false): void {
    if (this._isCurrentUserCollaborator) return;

    if (isCollaboratorWarningMessage) {
      this.reviewerIsForCollaboratorWarning.next();
    } else {
      this.reviewerIsNowCollaborator.next();
    }
  }

  public checkPreparationsAreComplete(): boolean {
    let preparationsArray: PreparationItem[] = [];
    if (this.experiment?.activities) {
      preparationsArray = this.experiment.activities
        .flatMap(activity => activity.preparations?.filter(preparation =>
          preparation && !preparation.isRemoved
        ) || []);
    }
    if (this.experiment?.activities.length === 0 || preparationsArray.length === 0) return true;
    return !preparationsArray.some(preparation => (
      preparation.name?.value.state === ValueState.Empty ||
      preparation.description?.value.state === ValueState.Empty ||
      preparation.summary.formulaComponents?.value.state === ValueState.Empty ||
      preparation.summary.storageCondition?.value.state === ValueState.Empty ||
      preparation.summary.concentration?.value.state === ValueState.Empty
    ));
  }

  public checkAnyExperimentPreparationsInPending(): boolean {
    if (!this.experiment?.activities || this.experiment?.activities.length === 0) {
      return false;
    }
    return this.experiment.activities.some(activity =>
      activity.preparations?.some(preparation => !preparation.isRemoved && preparation.status === ExperimentPreparationStatus.Pending)
    );
  }

  public checkAnyLabItemsPreparationsInPending(): boolean {
    if (!this.experiment?.activities || this.experiment?.activities.length === 0) {
      return false;
    }
    return this.experiment.activityLabItems.some(activity =>
      activity.preparations?.some(preparation =>
        preparation.summary?.preparationSubStatus !== PreparationSubStatus.RemovedAndCannotBeRestored &&
        preparation.summary?.preparationSubStatus !== PreparationSubStatus.RemovedButCanBeRestored &&
        preparation.status === ExperimentPreparationStatus.Pending)
    );
  }

  public checkInputsCompletion(activityInputNodes: ActivityInputNode[]): boolean {
    let isComplete = true;
    for (const inputNode of activityInputNodes) {
      const areInputsMaterialsComplete = inputNode.materials.length <= 0 || this.checkCompletionOfMaterials(inputNode.materials);
      if (!areInputsMaterialsComplete) {
        isComplete = false;
        break;
      }
      const areInputsAliquotsComplete = inputNode.aliquots.length <= 0 || this.checkCompletionOfAliquots(inputNode.aliquots);
      if (!areInputsAliquotsComplete) {
        isComplete = false;
        break;
      }
      const areInstrumentEventComplete = ((inputNode.instruments ?? []) as Instrument[]).length === 0 || this.checkCompletionOfInstrumentEvent(inputNode.instruments as Instrument);
      if (!areInstrumentEventComplete) {
        isComplete = false;
        break;
      }
    }
    return isComplete;
  }

  private checkCompletionOfMaterials(materials: MaterialAliquot[]): boolean {
    let isComplete = true;
    materials = materials.filter(material => !material.isRemoved);
    for (const material of materials) {
      if (material.studyActivities.length === 0) {
        isComplete = false;
        break;
      }
    }
    return isComplete;
  }

  private checkCompletionOfAliquots(aliquots: Aliquot[]): boolean {
    let isComplete = true;
    aliquots = aliquots.filter(aliquot => !aliquot.isRemoved);
    for (const aliquot of aliquots) {
      if (aliquot.aliquotTests.length === 0) {
        isComplete = false;
        break;
      }
    }
    return isComplete;
  }

  private checkCompletionOfInstrumentEvent(instrument: Instrument): boolean {
    let isComplete = true;
    if (!instrument.isRemoved) {
      if (
        !instrument.nameDescription ||
        !instrument.removedFromService ||
        !instrument.description ||
        !instrument.dateRemoved ||
        instrument.nameDescription.value.state === ValueState.Empty ||
        instrument.removedFromService.value.state === ValueState.Empty ||
        instrument.description.value.state === ValueState.Empty ||
        instrument.dateRemoved.value.state === ValueState.Empty
      ) {
        isComplete = false;
      }
    }
    return isComplete;
  }

  public getPromptItemType(promptId: string): ActivityInputType {
    let type = ActivityInputType.Invalid;
    this.experiment?.activityPrompts?.forEach(activityPrompt => {
      const prompt = activityPrompt.prompts.find(prompt => prompt.promptId === promptId);
      if (prompt) {
        type = this.promptTypeToActivityInputTypeMapping[prompt.type ?? PromptType.Invalid]
      }
    });
    return type;
  }

  public addRecipeAppliedEventBlobDetailsToCache(records: AuditHistoryDataRecordResponse) {
    const unCachedRecipeBlobNames: string[] = [];
    const observablesList: Observable<ExperimentRecipeAppliedDetails>[] = [];
    const recipeRecords = records.dataRecords.filter(
      r => r.eventContext.eventType === ExperimentEventType.ExperimentRecipeApplied
    ) as ExperimentRecipeAppliedEventNotification[];
    recipeRecords.forEach((record: ExperimentRecipeAppliedEventNotification) =>
      this.addUnCachedRecipeRecordsToObservablesList(
        record, observablesList, unCachedRecipeBlobNames
      ));
    if (observablesList.length > 0) {
      forkJoin(observablesList)
        .subscribe(responses => {
          responses.forEach((response: ExperimentRecipeAppliedDetails, index: number) => {
            addToCache(unCachedRecipeBlobNames[index], response);
          });
          this.recipeBlobDetailsFetched.next(true);
        });
    } else {
      this.recipeBlobDetailsFetched.next(true);
    }
  }

  private addUnCachedRecipeRecordsToObservablesList(
    record: ExperimentRecipeAppliedEventNotification,
    observablesList: Observable<ExperimentRecipeAppliedDetails>[],
    unCachedRecipeBlobNames: string[]
  ) {
    if (!record.eventContext.blobReference?.blobName) return;

    const blobName = record.eventContext.blobReference.blobName;
    if (!objectCache[blobName]) {
      observablesList.push(
        this.experimentBlobEventsService.experimentEventsEventIdRecipeAppliedBlobsBlobNameGet$Json({
          eventId: record.eventId,
          blobName
        }));
      unCachedRecipeBlobNames.push(blobName);
    }
  }

  public areRecipeBlobDetailsFetched() {
    return this.recipeBlobDetailsFetched.asObservable();
  }

  public isExperimentCrossReferencesAuthorized(): Observable<boolean> {
    if (!this.currentExperiment) return of(false);
    return this.experimentNodesService.experimentNodesCrossReferencesExperimentIdGet$Json({
      experimentId: this.currentExperiment.id
    }).pipe(map(crossReferenceDetailsResponse => {
      const currentActivityIds = this.currentExperiment?.activities.map(({ activityId }) => activityId) ?? [];
      const referencesOfOtherExperiment =
        crossReferenceDetailsResponse.crossReferenceDetails?.filter(ref => !currentActivityIds.includes(ref.referenceId)) ?? []
      return referencesOfOtherExperiment
        .every(crossRef => crossRef.workflowState.toLowerCase() === ExperimentWorkflowState.Authorized.toString().toLowerCase())
    }));
  }
}
