import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BptGridComponent, BptGridMode, ColumnDefinition, RowSelection } from 'bpt-ui-library/bpt-grid';
import { ConfirmationService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BarcodeScannerHelper } from '../../../services/barcode-scanner-helper';
import { NA } from 'bpt-ui-library/shared';
import { StudyActivityDomain } from '../../../api/models';
import { StudyActivities } from '../../../model/study-activities';
import { ActivityInputHelper } from '../shared/activity-input-helper';

@Component({
  selector: 'app-activity-input-study-activity-selection',
  templateUrl: './activity-input-study-activity-selection.component.html',
  styleUrls: ['./activity-input-study-activity-selection.component.scss']
})
export class ActivityInputStudyActivitySelectionComponent implements OnInit, OnDestroy {
  columnDefinitions: ColumnDefinition[] = [];
  rowSelection: RowSelection = "multiple";
  studyActivityTableId = 'StudyActivityId';
  NA: string = NA;
  gridMode = BptGridMode.dataEntry;
  studyActivityList: string[] = [];
  data: any[] = [];
  @ViewChild('Grid') grid!: BptGridComponent;
  checkForWarning = true;
  studyActivitiesWithSelectedData!: StudyActivities;
  event!: StudyActivityDomain[];


  constructor(
    private readonly dynamicDialogConfig: DynamicDialogConfig,
    private readonly dynamicDialogRef: DynamicDialogRef,
    private readonly confirmationService: ConfirmationService,
    public readonly barcodeScannerHelper: BarcodeScannerHelper,
    private readonly activityInputHelper: ActivityInputHelper,
  ) { }

  ngOnInit(): void {
    this.studyActivitiesWithSelectedData = this.dynamicDialogConfig.data as StudyActivities;
    this.data = this.studyActivitiesWithSelectedData.StudyActivities;
    this.event = this.studyActivitiesWithSelectedData.StudyActivities.filter(t => this.studyActivitiesWithSelectedData.StudyActivityIds.indexOf(t.code) > -1);
    this.prepareColumns();
  }

  private prepareColumns() {
    this.columnDefinitions = [
      {
        field: 'checkbox',
        label: '',
        checkboxSelection: this.updateData,
        width: 10,
        editable: false
      },
      {
        field: 'studyActivityName',
        label: $localize`:@@StudyActivityName:Study Activity Name`,
        width: 'auto',
        editable: false,
        columnType: 'string',
      },
      {
        field: 'code',
        label: $localize`:@@StudyActivityCode:Study Activity Code`,
        width: 'auto',
        sortable: true,
        editable: false,
        columnType: 'string'
      },
      {
        field: 'studyCode',
        label: $localize`:@@StudyCode:Study Code`,
        width: 'auto',
        editable: false,
        filterParams: {
          filterOptions: ['contains'],
          suppressAndOrCondition: true
        },
        lockVisible: true
      }];
  }

  updateData = (params: any) => {
    params.api.forEachNode(
      (node: { data: any; setSelected: (arg0: boolean) => void }) => {
        if (node.data && this.studyActivitiesWithSelectedData.StudyActivityIds.indexOf(node.data.code) > -1) {
          node.setSelected(true);
        }
      });
    return true;
  };

  onButtonClick = (params: any, isSelected = true) => {
    this.grid?.gridApi?.forEachNode(
      (node: { data: { code: string }; setSelected: (arg0: boolean) => void }) => {
        if (node.data.code === params[0].code) node.setSelected(isSelected);
      }
    );
  };

  saveStudyActivities = () => {
    this.checkForWarning = false;
    const studyActivity: StudyActivities = {
      AliquotNumber: this.studyActivitiesWithSelectedData.AliquotNumber,
      StudyActivities: this.event,
      StudyActivityIds: []
    }
    this.activityInputHelper.studyActivitySelected.next(studyActivity);
    this.closeModal();
  }

  cancelStudySelection = () => {
    const studyActivity: StudyActivities = {
      AliquotNumber: this.studyActivitiesWithSelectedData.AliquotNumber,
      StudyActivities: this.event,
      StudyActivityIds: []
    }
    if (this.checkForUnsavedChanges()) {
      this.confirmEventSelectionClose(studyActivity);
    }
    else {
      this.closeModal();
    }
  }

  checkForUnsavedChanges = () => {
    this.event = this.event ?? [];
    const allSelectedStudyActivities = this.event.map(ev => ev.code) ?? [];
    const alreadySavedTestIds = this.studyActivitiesWithSelectedData.StudyActivityIds ?? [];
    const selectedTestIds = allSelectedStudyActivities.filter(function (obj) {
      return alreadySavedTestIds.indexOf(obj) === -1;
    });
    const unselectedTestIds = alreadySavedTestIds.filter(function (obj) {
      return allSelectedStudyActivities.indexOf(obj) === -1;
    });
    return selectedTestIds.length > 0 || unselectedTestIds.length > 0;
  }

  confirmEventSelectionClose(params: StudyActivities) {
    const confirmationMessage = $localize`:@@NavigateAwayConfirmation:You are about to leave this
     page without saving. All changes will be lost. Do you really want to leave without saving?`;
    this.confirmationService.confirm({
      message: `${confirmationMessage}`,
      header: $localize`:@@unsavedChangesConfirmationHeader:Unsaved Changes`,
      acceptVisible: true,
      acceptLabel: $localize`:@@saveChanges:Save changes`,
      rejectVisible: true,
      rejectLabel: $localize`:@@leaveWithoutSaving:Leave without saving`,
      dismissableMask: false,
      accept: () => {
        this.activityInputHelper.studyActivitySelected.next(params);
        this.closeModal();
      },
      reject: () => {
        this.closeModal();
      }
    });
  }

  closeModal = () => {
    this.checkForWarning = false;
    if (this.dynamicDialogRef) {
      this.dynamicDialogRef.close();
    }
  }

  clearSelection = () => {
    this.checkForWarning = false;
    const studyActivity: StudyActivities = {
      AliquotNumber: this.studyActivitiesWithSelectedData.AliquotNumber,
      StudyActivities: [{
        studyActivityName: NA,
        studyActivityStatus: NA,
        accountCode: NA,
        accountName: NA,
        code: NA,
        customerAnalyticalProjectCode: NA,
        isSelected: true,
        projectName: NA,
        studyCode: NA,
        studyGroupName: NA,
        studyName: NA
      }],
      StudyActivityIds: []
    }
    this.activityInputHelper.studyActivitySelected.next(studyActivity);
    this.closeModal();
  }

  onSelected($event: StudyActivityDomain[]) {
    this.event = $event;
  }

  ngOnDestroy(): void {
    if (this.dynamicDialogRef) {
      if (this.checkForWarning)
        this.cancelStudySelection();
      else
        this.dynamicDialogRef.close();
    }
  }
}
