import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  HostListener,
  AfterViewInit,
  Inject
} from '@angular/core';
import { InstrumentConnectionHelper } from '../instrument-connection/shared/instrument-connection-helper';
import { InstrumentNotificationService } from '../instrument-connection/shared/instrument-notification-service';
import { ConfirmationService, MessageService, Message } from 'primeng/api';
import { Observable, Subscription, map } from 'rxjs';
import { InstrumentsReadingResponse } from '../instrument-connection/shared/instrument-getreading-response';
import { BptTextInputComponent } from 'bpt-ui-library/bpt-text-input';
import { KeyboardService } from 'bpt-ui-library/services';
import { MeasurementMethod, MethodType, FieldKeys, RecordTimeFieldKeys } from './instrument-balance-reading.interface';
import { DropdownChangeEvent } from 'bpt-ui-library/bpt-dropdown';
import { ActivityInputService, UnitsService } from '../../api/services';
import { ExperimentService } from '../services/experiment.service';
import { InstrumentDetailsDto } from '../../api/instrument-admin/models';
import {
  InstrumentCategory,
  InstrumentReadingValue,
  RestoreLabItemsInstrumentCommand
} from '../../api/data-entry/models';
import { DateAndInstantFormat, formatInstant } from '../../shared/date-time-helpers';
import { Instant } from '@js-joda/core';
import {
  ActivityInputType,
  ActivityLabItemsNode,
  AddExperimentScannedItemsCommand,
  InstrumentParameterDetails,
  NumberValue
} from '../../api/models';
import { LabItemsAuditHistoryService } from '../../api/audit/services';
import { LabItemEventsService } from '../../api/data-entry/services';
import { LabItemsService } from '../labItems/lab-items.service';
import { UnitLoaderService } from '../../services/unit-loader.service';
import { InstrumentConfigUnits } from '../instrument-connection/shared/instrument-config-units';
import { TableDataService } from '../data/table/table-data.service';
import { InstrumentConfigMethods } from '../instrument-connection/shared/instrument-config-methods';
import { InstrumentParameterConditionalMode } from '../model/instrument-connection/instrument-parameter-conditional-modes';
import { NA } from 'bpt-ui-library/shared';
import { BlowerState } from '../model/instrument-connection/blower-state';
import { InstrumentBlowerValues } from '../instrument-connection/shared/instrument-blower-value';

@Component({
  selector: 'app-instrument-balance-reading',
  templateUrl: './instrument-balance-reading.component.html',
  styleUrls: ['./instrument-balance-reading.component.scss']
})
export class InstrumentBalanceReadingComponent implements OnInit, OnDestroy, AfterViewInit {
  @Output() closeEvent = new EventEmitter<string>();
  @Output() actualValueEvent = new EventEmitter<InstrumentReadingValue>();
  @Output() unitEvent = new EventEmitter<string>();
  @Input() targetValue!: NumberValue;
  @Input() showTarget = false;
  @Input() sequentialReadingEnabled = false;
  isSubscribed = false;
  isCommit = false;
  isTare = false;
  isDifferencePan = false;
  isResidualDifferencePanSample = false;
  balanceName!: string;
  configuredMethod!: string;
  selectedMethod!: string;
  configuredUnit!: string;
  selectedUnit!: string;
  configuredPushValue!: string;
  directTare!: number;
  directActual!: number;
  differencePan!: number;
  differencePanSample!: number;
  differencePanSampleTarget!: number;
  differenceActual!: number;
  residualDifferencePanSample!: number;
  residualDifferencePanResidual!: number;
  residualDifferencePanResidualTarget!: number;
  residualDifferenceActual!: number;
  blowerValue = false;
  blowerState = BlowerState.No_Blower;
  toleranceSpecDetails = NA.toString();
  isBlowerVisible = false;
  isBlowerEditable = false;
  balanceReadingMethodChanged = false;
  balanceReadingUnitChanged = false;
  DIRECT_METHOD = 'Direct';
  DIFFERENCE_METHOD = 'Difference';
  RESIDUAL_DIFFERENCE_METHOD = 'Residual Difference';
  WEIGHTS_PARAMETER_NAME = 'Weight';
  confirmationMessage = $localize`:@@replaceResultConfirmation:Confirm overriding with new value.`;
  selectedPushValue = false;
  confirmationIcon = 'pi pi-exclamation-triangle';
  bindReadingSubscription!: Subscription;
  errorMessageSubscription!: Subscription;
  receivedReading = false;
  timeoutError!: any;
  timeoutLimit = 30000;
  maxValue5Place = NA.toString();
  minValue5Place = NA.toString();
  maxValue4Place: string | undefined;
  minValue4Place: string | undefined;
  isValidSinglePlace = false;
  isToleranceNA = false;
  selectedMethodChangeMessage =
    $localize`:@@methodChangeConfirmationWithFieldsPopulated:Are you sure you want to change the Reading Method? Pending reading values will be transcribed to new Method's reading fields`;
  private readonly methodFieldMap: Record<MethodType, FieldKeys[]> = {
    [MeasurementMethod.Direct]: ['directTare', 'directActual'],
    [MeasurementMethod.Difference]: ['differencePan', 'differencePanSample', 'differenceActual'],
    [MeasurementMethod.ResidualDifference]: [
      'residualDifferencePanSample',
      'residualDifferencePanResidual',
      'residualDifferenceActual'
    ]
  };
  private readonly methodRecordTimeFieldMap: Record<MethodType, RecordTimeFieldKeys[]> = {
    [MeasurementMethod.Direct]: ['directTareRecordTime', 'directActualRecordTime'],
    [MeasurementMethod.Difference]: ['differencePanRecordTime', 'differencePanSampleRecordTime'],
    [MeasurementMethod.ResidualDifference]: [
      'residualDifferencePanSampleRecordTime',
      'residualDifferencePanResidualRecordTime'
    ]
  };
  @ViewChild('directActualInput') directActualInput!: BptTextInputComponent;
  @ViewChild('directTareInput') directTareInput!: BptTextInputComponent;
  @ViewChild('differencePanInput') differencePanInput!: BptTextInputComponent;
  @ViewChild('differencePanSampleInput') differencePanSampleInput!: BptTextInputComponent;
  @ViewChild('residualDifferencePanSampleInput')
  residualDifferencePanSampleInput!: BptTextInputComponent;
  @ViewChild('residualDifferencePanResidualInput')
  residualDifferencePanResidualInput!: BptTextInputComponent;

  connectedInstrument?: InstrumentDetailsDto;
  directTareRecordTime!: string;
  directTareReadMethod!: string;
  directActualReadMethod!: string;
  directActualRecordTime!: string;

  differencePanRecordTime!: string;
  differencePanReadMethod!: string;
  differencePanSampleRecordTime!: string;
  differencePanSampleReadMethod!: string;

  residualDifferencePanSampleRecordTime!: string;
  residualDifferencePanSampleReadMethod!: string;
  residualDifferencePanResidualRecordTime!: string;
  residualDifferencePanResidualReadMethod!: string;
  specificationTarget!: number;
  isToleranceInvalid = false;
  BLOWER_VALUE = 'Blower Value';
  BLOWER_STATE = 'Blower State';
  blowerValues?: InstrumentParameterDetails[];

  constructor(
    private readonly instrumentConnectionHelper: InstrumentConnectionHelper,
    readonly instrumentNotificationService: InstrumentNotificationService,
    readonly messageService: MessageService,
    private readonly confirmationService: ConfirmationService,
    private readonly keyboardService: KeyboardService,
    readonly experimentService: ExperimentService,
    private readonly unitsService: UnitsService,
    private readonly activityInputService: ActivityInputService,
    private readonly labItemsAuditHistoryService: LabItemsAuditHistoryService,
    private readonly labItemEventsService: LabItemEventsService,
    private readonly labItemsService: LabItemsService,
    private readonly unitLoaderService: UnitLoaderService,
    @Inject('ExperimentTableDataService') private readonly tableDataService: TableDataService<'experiment'>
  ) {
    this.handleSubscriptions();
  }

  ngAfterViewInit(): void {
    // The UI is displayed after afterViewInit. So adding negligible timeout period.
    setTimeout(() => {
      this.highlightDefaultField();
    }, 1);
  }

  @HostListener('contextmenu')
  preventContextMenu() {
    return false;
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification(event: BeforeUnloadEvent) {
    if (this.isCommit) {
      event.preventDefault();
      this.closeOverlay();
      return false;
    }
    return true;
  }

  unSubscribe(): void {
    if (this.bindReadingSubscription) {
      this.bindReadingSubscription.unsubscribe();
    }
    if (this.errorMessageSubscription) {
      this.errorMessageSubscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.checkBlowerConditions();
    this.checkToleranceRange();
    this.balanceName = this.instrumentNotificationService.instrumentDetails?.name ?? localStorage.getItem('lastConnectedInstrumentName') ?? '';
    this.connectedInstrument = this.instrumentConnectionHelper.connectedInstrument;
    this.configuredMethod = localStorage.getItem('configuration-value-method') ?? this.instrumentConnectionHelper.configuredMethod;
    this.selectedMethod = this.instrumentConnectionHelper.selectedMethod ?? this.configuredMethod;
    /**
    * @property {string} configuredUnit - The configured unit for the instrument. Set to an empty string by default to ensure consistent initialization.
    * This is necessary since the balance reading panel, which requires a connected instrument, should have a defined unit upon being opened.
    */
    this.configuredUnit = localStorage.getItem('configuration-value-unit') ?? this.instrumentConnectionHelper.configuredUnit ?? '';
    this.selectedUnit = this.configuredUnit;
    this.configuredPushValue = localStorage.getItem('configuration-value-push') ?? this.instrumentConnectionHelper.configuredPushValue;
    this.selectedPushValue = this.sequentialReadingEnabled || this.configuredPushValue === 'Yes';
    this.handleTargetWeight();
    this.getReadingsForPush();
    this.blowerValue = this.getBlowerValue()
    this.addBlowerBasedToleranceRange();
  }

  private getBlowerValue() {
    if (localStorage.getItem(this.BLOWER_STATE) === BlowerState.Blower_On_Off) {
      return (localStorage.getItem(this.BLOWER_VALUE) as InstrumentBlowerValues || InstrumentBlowerValues.yes) === InstrumentBlowerValues.yes;
    }
    return localStorage.getItem(this.BLOWER_STATE) === BlowerState.Blower_On;
  }

  private checkBlowerConditions() {
    const weightBlowerParameters =
      this.instrumentNotificationService.instrumentDetailsLims?.parameters.filter(
        (parameter: InstrumentParameterDetails) =>
          parameter.name === this.WEIGHTS_PARAMETER_NAME &&
          parameter.conditionalMode?.includes('Blower')
      );
    if (weightBlowerParameters && weightBlowerParameters.length > 0) {
      if (weightBlowerParameters.length === 1) {
        switch (weightBlowerParameters[0].conditionalMode) {
          case InstrumentParameterConditionalMode.BlowerOn:
            this.blowerValue = true;
            this.blowerState = BlowerState.Blower_On;
            break;
          case InstrumentParameterConditionalMode.BlowerOff:
            this.blowerValue = false;
            this.blowerState = BlowerState.Blower_Off;
            break;
          default:
            return;
        }
        this.isBlowerEditable = false;
        this.isBlowerVisible = true;
      } else if (
        weightBlowerParameters.length === 2 &&
        weightBlowerParameters.some(
          (weightParameter: InstrumentParameterDetails) =>
            weightParameter.conditionalMode === InstrumentParameterConditionalMode.BlowerOn
        ) &&
        weightBlowerParameters.some(
          (weightParameter: InstrumentParameterDetails) =>
            weightParameter.conditionalMode === InstrumentParameterConditionalMode.BlowerOff
        )
      ) {
        this.blowerValue = localStorage.getItem(this.BLOWER_VALUE) === InstrumentBlowerValues.yes;
        this.isBlowerEditable = true;
        this.isBlowerVisible = true;
        this.blowerState = BlowerState.Blower_On_Off;
      }
    }
  }

  private checkToleranceRange() {
    const weightParameters =
      this.instrumentNotificationService.instrumentDetailsLims?.parameters.filter(
        (parameter: InstrumentParameterDetails) => parameter.name === this.WEIGHTS_PARAMETER_NAME
      );
    if (weightParameters && weightParameters.length > 0) {
      const blowerParameters = weightParameters.filter(
        (instrumentParameter: InstrumentParameterDetails) =>
          instrumentParameter.conditionalMode?.includes('Blower')
      );
      const place4Parameters = weightParameters.filter(
        (instrumentParameter: InstrumentParameterDetails) =>
          instrumentParameter.conditionalMode === InstrumentParameterConditionalMode.Place_4
      );
      const naParameters = weightParameters.filter(
        (instrumentParameter: InstrumentParameterDetails) =>
          instrumentParameter.conditionalMode === InstrumentParameterConditionalMode.NA
      );
      this.checkToleranceRangeConditions(
        weightParameters,
        blowerParameters,
        place4Parameters,
        naParameters
      );
      if (
        this.minValue5Place === NA ||
        this.maxValue5Place === NA ||
        this.minValue4Place === NA ||
        this.maxValue4Place === NA
      )
        this.isToleranceNA = true;
    } else {
      this.isToleranceNA = true;
      this.minValue5Place = NA;
      this.maxValue5Place = NA;
    }
  }

  private checkToleranceRangeConditions(
    weightParameters: InstrumentParameterDetails[],
    blowerParameters: InstrumentParameterDetails[],
    place4Parameters: InstrumentParameterDetails[],
    naParameters: InstrumentParameterDetails[]
  ) {
    switch (weightParameters.length) {
      case 1:
        if (blowerParameters.length === 1) {
          this.addToleranceRange(blowerParameters[0]);
        } else if (place4Parameters.length === 1) {
          this.addToleranceRange(place4Parameters[0]);
        } else if (naParameters.length === 1) {
          this.isToleranceNA = true;
          this.minValue5Place = NA;
          this.maxValue5Place = NA;
        } else {
          this.isToleranceInvalid = true;
        }
        break;
      case 2:
        if (this.checkBlowerOnAndBlowerOff(blowerParameters)) {
          this.blowerValues = blowerParameters;
          this.addBlowerBasedToleranceRange();
        } else if (blowerParameters.length === 1 && place4Parameters.length === 1) {
          this.add4And5PlaceToleranceRange(this.getBlowerToleranceRange(blowerParameters), place4Parameters[0]);
        } else {
          this.isToleranceInvalid = true;
        }
        break;
      case 3:
        this.blowerValues = blowerParameters;
        if (this.checkBlowerOnAndBlowerOff(blowerParameters) && place4Parameters.length === 1) {
          this.add4And5PlaceToleranceRange(this.getBlowerToleranceRange(blowerParameters), place4Parameters[0]);
        } else {
          this.isToleranceInvalid = true;
        }
        break;
      default:
        this.isToleranceInvalid = true;
        break;
    }
  }

  getBlowerToleranceRange(blowerValues: InstrumentParameterDetails[]) {
    const blowerState = this.blowerValue ? BlowerState.Blower_On : BlowerState.Blower_Off;
    const instrumentParameter = blowerValues.find(blowerValue => blowerValue.conditionalMode.includes(blowerState)) ?? blowerValues[0];
    return instrumentParameter;
  }

  addBlowerBasedToleranceRange() {
    if (this.blowerValues) {
      this.addToleranceRange(this.getBlowerToleranceRange(this.blowerValues));
    }
  }

  private checkBlowerOnAndBlowerOff(blowerParameters: InstrumentParameterDetails[]) {
    return (
      blowerParameters.some(
        (s) => s.conditionalMode === InstrumentParameterConditionalMode.BlowerOn
      ) &&
      blowerParameters.some(
        (s) => s.conditionalMode === InstrumentParameterConditionalMode.BlowerOff
      )
    );
  }

  private addToleranceRange(instrumentParameter: InstrumentParameterDetails) {
    this.isToleranceNA = false;
    const weightBlowerParameters =
    this.instrumentNotificationService.instrumentDetailsLims?.parameters.filter(
      (parameter: InstrumentParameterDetails) =>
        parameter.name === this.WEIGHTS_PARAMETER_NAME
      );

    const is4PlacePresent = weightBlowerParameters?.some(weightBlowerParameter => weightBlowerParameter.conditionalMode === InstrumentParameterConditionalMode.Place_4) ?? false;
    const is5PlacePresent = weightBlowerParameters?.some(weightBlowerParameter => weightBlowerParameter.conditionalMode.includes('Blower')) ?? false;
    this.setIsValidSinglePlace(is4PlacePresent, is5PlacePresent)
    if (instrumentParameter.lowerComparator.value) this.minValue5Place = `${instrumentParameter.lowerComparator.value} ${instrumentParameter.lowerComparator.unit}`;
    if (instrumentParameter.upperComparator.value) this.maxValue5Place = `${instrumentParameter.upperComparator.value} ${instrumentParameter.upperComparator.unit}`;

    const place4Object = weightBlowerParameters?.find(weightBlowerParameter => weightBlowerParameter.conditionalMode === InstrumentParameterConditionalMode.Place_4);
    if (place4Object) {
      if (place4Object.lowerComparator.value) this.minValue4Place = `${place4Object.lowerComparator.value} ${place4Object.lowerComparator.unit}`;
      if (place4Object.upperComparator.value) this.maxValue4Place = `${place4Object.upperComparator.value} ${place4Object.upperComparator.unit}`;
    }

    if (is4PlacePresent && is5PlacePresent) {
      this.toleranceSpecDetails = `4 Place >${this.minValue4Place} and <${this.maxValue4Place}, 5 place >${this.minValue5Place} and <${this.maxValue5Place}`;

    } else if (is4PlacePresent && !is5PlacePresent) {
      this.toleranceSpecDetails = `>${this.minValue4Place} and <${this.maxValue4Place}`;

    } else if (!is4PlacePresent && is5PlacePresent) {
      this.toleranceSpecDetails = `>${this.minValue5Place} and <${this.maxValue5Place}`;
    }
  }

  setIsValidSinglePlace(is4PlacePresent: boolean, is5PlacePresent: boolean) {
    if((is4PlacePresent && ! is5PlacePresent) || (is5PlacePresent && !is4PlacePresent)) this.isValidSinglePlace = true
  }

  private add4And5PlaceToleranceRange(
    blowerParameter: InstrumentParameterDetails,
    place4Parameter: InstrumentParameterDetails
  ) {
    this.isToleranceNA = false;
    if (blowerParameter.lowerComparator.value) {
      this.minValue5Place = `${blowerParameter.lowerComparator.value} ${blowerParameter.lowerComparator.unit}`;
    }
    if (blowerParameter.upperComparator.value) {
      this.maxValue5Place = `${blowerParameter.upperComparator.value} ${blowerParameter.upperComparator.unit}`;
    }
    if (place4Parameter.lowerComparator.value) {
      this.minValue4Place = `${place4Parameter.lowerComparator.value} ${place4Parameter.lowerComparator.unit}`;
    } else {
      this.minValue4Place = NA.toString();
    }
    if (place4Parameter.upperComparator.value) {
      this.maxValue4Place = `${place4Parameter.upperComparator.value} ${place4Parameter.upperComparator.unit}`;
    } else {
      this.maxValue4Place = NA.toString();
    }
    if (
      blowerParameter.lowerComparator.value &&
      blowerParameter.upperComparator.value &&
      place4Parameter.lowerComparator.value &&
      place4Parameter.upperComparator.value
    )
      this.toleranceSpecDetails = `4 Place >${this.minValue4Place} and <${this.maxValue4Place}, 5 place >${this.minValue5Place} and <${this.maxValue5Place}`;
  }

  handleTargetWeight() {
    if (this.showTarget) {
      this.specificationTarget = Number(this.targetValue.value);
      const unitId = this.targetValue.unit;
      const unitIdForG = this.unitLoaderService.allUnits.find(unit => unit.abbreviation === InstrumentConfigUnits.g)?.id;
      const unit = unitId === unitIdForG ? InstrumentConfigUnits.g : InstrumentConfigUnits.mg;
      if (unit !== this.selectedUnit) {
        this.specificationTarget =
          this.selectedUnit === InstrumentConfigUnits.g
            ? this.specificationTarget / 1000
            : this.specificationTarget * 1000;
      }
    }
  }

  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (!this.keyboardService.isTabOrEnter(event)) event.preventDefault();
  }

  ngOnDestroy(): void {
    this.unSubscribe();
  }

  handleSubscriptions() {
    this.bindReadingSubscription = this.instrumentNotificationService.instrumentGetReadings.subscribe((weightReadingJsonString: InstrumentsReadingResponse) => {
      this.receivedReading = true;
      clearTimeout(this.timeoutError);

      this.bindReadingToTextBox(weightReadingJsonString);
    });
  }

  onMethodChanged(event: DropdownChangeEvent) {
    const previousMethod = this.selectedMethod as MethodType;
    this.selectedMethod = event.value;
    let message = $localize`:@@methodChangeConfirmation:Are you sure you want to change the Reading Method?`;
    if (this.anyFieldCapturedForMethod(previousMethod)) {
      message = this.selectedMethodChangeMessage;
    }
    this.confirmationService.confirm({
      message: message,
      header: $localize`:@@confirmationHeader:Confirmation`,
      acceptVisible: true,
      acceptLabel: $localize`:@@confirmationOk:Yes`,
      rejectVisible: true,
      rejectLabel: $localize`:@@confirmationCancel:No`,
      closeOnEscape: true,
      dismissableMask: false,
      icon: this.confirmationIcon,
      accept: () => {
        this.transcribeValuesOnMethodChange(previousMethod, this.selectedMethod as MethodType);
      },
      reject: () => {
        this.selectedMethod = previousMethod;
      }
    });
  }

  anyFieldCapturedForMethod(method: MethodType): boolean {
    for (const field of this.methodFieldMap[method]) {
      if (this[field as keyof this]) {
        return true;
      }
    }
    return false;
  }

  calculateTargetWeight(currentMethod: string) {
    switch (currentMethod) {
      case this.DIFFERENCE_METHOD:
        this.differencePanSampleTarget = this.differencePan + this.specificationTarget;
        break;
      case this.RESIDUAL_DIFFERENCE_METHOD:
        this.residualDifferencePanResidualTarget = this.residualDifferencePanSample - this.specificationTarget
    }
  }

  highlightInputElement(focusElement: HTMLElement) {
    focusElement.style.backgroundColor = '#e3eefd';
    focusElement.focus();
  }

  blurInputElement(focusElement: HTMLElement) {
    focusElement.style.backgroundColor = '#ffffff';
    focusElement.blur();
  }

  highlightDefaultField() {
    switch (this.selectedMethod) {
      case this.DIRECT_METHOD:
        this.blurInputElement(this.directActualInput?.input?.nativeElement);
        this.highlightInputElement(this.directTareInput?.input?.nativeElement);
        this.isTare = true;
        break;
      case this.DIFFERENCE_METHOD:
        this.blurInputElement(this.differencePanSampleInput?.input?.nativeElement);
        this.highlightInputElement(this.differencePanInput?.input?.nativeElement);
        this.isDifferencePan = true;
        break;
      case this.RESIDUAL_DIFFERENCE_METHOD:
        this.blurInputElement(this.residualDifferencePanResidualInput?.input?.nativeElement);
        this.highlightInputElement(this.residualDifferencePanSampleInput?.input?.nativeElement);
        this.isResidualDifferencePanSample = true;
        break;
    }
  }

  moveToNextField() {
    switch (this.selectedMethod) {
      case this.DIRECT_METHOD:
        this.blurInputElement(this.directTareInput?.input?.nativeElement);
        this.highlightInputElement(this.directActualInput?.input?.nativeElement);
        this.isTare = false;
        break;
      case this.DIFFERENCE_METHOD:
        this.blurInputElement(this.differencePanInput?.input?.nativeElement);
        this.highlightInputElement(this.differencePanSampleInput?.input?.nativeElement);
        this.isDifferencePan = false;
        break;
      case this.RESIDUAL_DIFFERENCE_METHOD:
        this.blurInputElement(this.residualDifferencePanSampleInput?.input?.nativeElement);
        this.highlightInputElement(this.residualDifferencePanResidualInput?.input?.nativeElement);
        this.isResidualDifferencePanSample = false;
        break;
    }
  }

  transcribeValuesOnMethodChange(previousMethod: MethodType, newMethod: MethodType) {
    const previousFields = this.methodFieldMap[previousMethod];
    const newFields = this.methodFieldMap[newMethod];
    const values = previousFields.map((field) => this[field]);
    values.forEach((value, index) => {
      this[newFields[index]] = value;
    });
    const previousRecordTimeFields = this.methodRecordTimeFieldMap[previousMethod];
    const newRecordTimeFields = this.methodRecordTimeFieldMap[newMethod];
    const recordTimes = previousRecordTimeFields.map((field) => this[field]);
    recordTimes.forEach((value, index) => {
      this[newRecordTimeFields[index]] = value;
    });

    if (newMethod === this.DIFFERENCE_METHOD && this.differencePan && this.differencePanSample) {
      this.differenceActual = this.differencePanSample - this.differencePan;
    } else if (
      newMethod === this.RESIDUAL_DIFFERENCE_METHOD &&
      this.residualDifferencePanSample &&
      this.residualDifferencePanResidual
    ) {
      this.residualDifferenceActual =
        this.residualDifferencePanSample - this.residualDifferencePanResidual;
    }
    this.balanceReadingMethodChanged = true;
    this.highlightDefaultField();
  }

  bindReadingToTextBox(weight: InstrumentsReadingResponse) {
    this.handleWeightUnitConversion(weight);
    if (this.selectedMethod === this.DIRECT_METHOD) {
      this.handleDirectMethod(weight);
    } else if (this.selectedMethod === this.DIFFERENCE_METHOD) {
      this.handleDifferenceMethod(weight);
    } else if (this.selectedMethod === this.RESIDUAL_DIFFERENCE_METHOD) {
      this.handleResidualDifferenceMethod(weight);
    }
  }

  private handleWeightUnitConversion(weight: InstrumentsReadingResponse) {
    if (this.selectedUnit !== weight.weightUnit) {
      weight.weightValue = this.selectedUnit === 'g'
        ? weight.weightValue / 1000
        : weight.weightValue * 1000;
    }
  }

  private handleDirectMethod(weight: InstrumentsReadingResponse) {
    const targetProperty = this.isTare ? 'directTare' : 'directActual';
    if (this[targetProperty] === undefined) {
      this[targetProperty] = weight.weightValue;
    } else {
      this.confirmWorkflowStateTransition(this.confirmationMessage, weight);
    }

    if (this.directTare !== undefined && this.directActual !== undefined) {
      this.isCommit = true;
    }
  }

  private handleDifferenceMethod(weight: InstrumentsReadingResponse) {
    const targetProperty = this.isDifferencePan ? 'differencePan' : 'differencePanSample';
    if (this[targetProperty] === undefined) {
      this[targetProperty] = weight.weightValue;
    } else {
      this.confirmWorkflowStateTransition(this.confirmationMessage, weight);
    }

    if (this.differencePan !== undefined && this.differencePanSample !== undefined) {
      this.differenceActual = this.differencePanSample - this.differencePan;
      this.isCommit = true;
    }
  }

  private handleResidualDifferenceMethod(weight: InstrumentsReadingResponse) {
    const targetProperty = this.isResidualDifferencePanSample ? 'residualDifferencePanSample' : 'residualDifferencePanResidual';
    if (this[targetProperty] === undefined) {
      this[targetProperty] = weight.weightValue;
    } else {
      this.confirmWorkflowStateTransition(this.confirmationMessage, weight);
    }

    if (this.residualDifferencePanSample !== undefined && this.residualDifferencePanResidual !== undefined) {
      this.residualDifferenceActual = this.residualDifferencePanSample - this.residualDifferencePanResidual;
      this.isCommit = true;
    }
  }

  stopReading() {
    this.instrumentNotificationService.stopReading();
  }

  getUnit(targetUnit: string): Observable<string> {
    return this.unitsService.unitsListsGet$Json$Response().pipe(
      map(unitsList => {
        const unitList = unitsList.body.unitLists.find(unitList => unitList.name === 'Mass');
        return unitList?.units.find(unit => unit.abbreviation === targetUnit)?.id ?? '';
      })
    );
  }

  getCurrentTime(): string {
    return formatInstant(Instant.now(), DateAndInstantFormat.dateTimeToSecond);
  }

  commitValue() {
    const currentTime = this.getCurrentTime();
    let instrumentMetaData: { [key: string]: any } = {
      ReadingMethod: this.selectedMethod,
      BlowerState: this.blowerState,
      ToleranceSpec: this.toleranceSpecDetails
    };

    switch (this.selectedMethod) {
      case MeasurementMethod.Direct:
        instrumentMetaData = {
          ...instrumentMetaData,
          tare: this.directTare,
          tareReadMethod: this.directTareReadMethod,
          tareReadTime: this.directTareRecordTime,
          actual: this.directActual,
          actualReadMethod: this.directActualReadMethod,
          actualReadTime: currentTime
        };
        break;

      case MeasurementMethod.Difference:
        instrumentMetaData = {
          ...instrumentMetaData,
          pan: this.differencePan,
          panReadMethod: this.differencePanReadMethod,
          panReadTime: this.differencePanRecordTime,
          panSample: this.differencePanSample,
          panSampleReadMethod: this.differencePanSampleReadMethod,
          panSampleReadTime: this.differencePanSampleRecordTime,
          actual: this.differenceActual,
          actualReadTime: currentTime
        };
        break;

      case MeasurementMethod.ResidualDifference:
        instrumentMetaData.ReadingMethod = 'ResidualDifference';
        instrumentMetaData = {
          ...instrumentMetaData,
          panResidual: this.residualDifferencePanResidual,
          panResidualReadMethod: this.residualDifferencePanResidualReadMethod,
          panResidualReadTime: this.residualDifferencePanResidualRecordTime,
          panSample: this.residualDifferencePanSample,
          panSampleReadMethod: this.residualDifferencePanSampleReadMethod,
          panSampleReadTime: this.residualDifferencePanSampleRecordTime,
          actual: this.residualDifferenceActual,
          actualReadTime: currentTime
        };
        break;
    }

    const emittedValue: InstrumentReadingValue = {
      category: InstrumentCategory.Balances,
      equipmentId: this.connectedInstrument?.equipmentId ?? '',
      instrumentName: this.connectedInstrument?.name ?? '',
      instrumentType: this.connectedInstrument?.type ?? '',
      manufacturer: this.connectedInstrument?.manufacturer ?? '',
      modelNumber: this.connectedInstrument?.modelNumber ?? '',
      serialNumber: this.connectedInstrument?.serialNumber ?? '',
      instrumentMetaData: instrumentMetaData
    };

    this.getUnit(this.selectedUnit).subscribe((unitId: string) => {
      this.unitEvent.emit(unitId);
      this.actualValueEvent.emit(emittedValue);
      if (this.sequentialReadingEnabled) {
        this.tableDataService.actualValueAdded.next(emittedValue);
      }
      this.closeEvent.emit();
      this.instrumentNotificationService.stopPushReadings(emittedValue.equipmentId);
    });

    const instrumentId = this.instrumentNotificationService.instrumentDetails?.equipmentId;
    const instrumentConnectionInitiatedActivity = localStorage.getItem('instrumentConnectionInitiatedActivity');
    if (
      instrumentId &&
      instrumentConnectionInitiatedActivity &&
      !this.experimentService.isInstrumentExistInCurrentExperimentLabItems(instrumentId)
    ) {
      this.addInstrumentToLabItem(instrumentId, instrumentConnectionInitiatedActivity);
    }
    this.instrumentConnectionHelper.goNextClicked.next(this.selectedMethod as InstrumentConfigMethods);
  }

  addInstrumentToLabItem(instrumentId: string, instrumentConnectionInitiatedActivity: string) {
    this.labItemsAuditHistoryService.labItemsAuditHistoryExperimentIdPost$Json({
      experimentId: this.experimentService.currentExperiment?.id ?? '',
      body: { ids: [instrumentConnectionInitiatedActivity] }
    }).subscribe({
      next: data => {
        const labItem = data.dataRecords.find(labItem => (labItem as any).activityInputReference === instrumentId)
        if (labItem) {
          this.restoreLabItem(instrumentId, instrumentConnectionInitiatedActivity, labItem.eventContext.experimentId);
        } else {
          this.addNewLabItem(instrumentId, instrumentConnectionInitiatedActivity)
        }
      }
    })
    this.experimentService._isCurrentUserCollaboratorSubject$.next(true);
  }

  addNewLabItem(instrumentId: string, instrumentConnectionInitiatedActivity: string) {
    const addInstrumentToLabItemCommand: AddExperimentScannedItemsCommand = {
      activityIds: [instrumentConnectionInitiatedActivity],
      experimentId: this.experimentService.currentExperiment?.id ?? '',
      activityInputReference: instrumentId,
      activityInputType: ActivityInputType.InstrumentDetails
    };
    this.activityInputService.activityInputAddExperimentScannedItemsPost$Response({ body: addInstrumentToLabItemCommand }).subscribe(
      {
        next: () => {
          this.showInstrumentAddedSuccess();
        }
      }
    );
  }

  restoreLabItem(instrumentId: string, instrumentConnectionInitiatedActivity: string, experimentId: string) {
    const restoreLabItemsInstrumentCommand: RestoreLabItemsInstrumentCommand = {
      activityId: instrumentConnectionInitiatedActivity,
      itemReference: instrumentId,
      experimentId: experimentId
    };
    this.labItemEventsService
      .labItemEventsExperimentIdRestoreInstrumentPost$Json$Response({
        experimentId: restoreLabItemsInstrumentCommand.experimentId,
        body: restoreLabItemsInstrumentCommand
      })
      .subscribe({
        next: () => {
          this.showInstrumentAddedSuccess();
          const activityLabItemNode = this.experimentService.currentExperiment?.activityLabItems.find(
            (labItemNode: ActivityLabItemsNode) => labItemNode.nodeId === instrumentConnectionInitiatedActivity
          );
          if (activityLabItemNode) {
            const instrument = activityLabItemNode.instruments.find(
              i => i.code === instrumentId
            );
            if (instrument) {
              instrument.isRemoved = false;
              this.labItemsService.InstrumentRestored.next(instrument);
            }
          }
        }
      }
      );
  }

  showInstrumentAddedSuccess() {
    const messageObj: Message = {
      key: 'notification',
      severity: 'success',
      summary: '',
      detail: $localize`:@@BalanceAddedToTheLabItems:Balance added to the Lab Items`,
      sticky: false
    };
    this.messageService.add(messageObj);
  }

  closeOverlay() {
    if (this.isCommit) {
      this.confirmMessageForCommit($localize`:@@commitConfirmationMessage:Commit the active instrument reading?`);
      return;
    }
    this.confirmationService.confirm({
      message: $localize`:@@partialReadingConfirmation:The partial reading will not be saved. Close anyway?`,
      header: $localize`:@@ConfirmationHeader:Confirmation`,
      acceptVisible: true,
      acceptLabel: $localize`:@@Yes:Yes`,
      rejectVisible: true,
      rejectLabel: $localize`:@@No:No`,
      closeOnEscape: true,
      dismissableMask: false,
      icon: this.confirmationIcon,
      accept: () => {
        this.tableDataService.terminateSequentialReadingSession.next(true);
        this.closeEvent.emit();
        this.instrumentNotificationService.stopPushReadings(this.connectedInstrument?.equipmentId);
      },
      reject: () => { }
    });
  }

  setBindingField(isFirstField: boolean) {
    switch (this.selectedMethod) {
      case this.DIRECT_METHOD:
        this.isTare = isFirstField;
        break;
      case this.DIFFERENCE_METHOD:
        this.isDifferencePan = isFirstField;
        break;
      case this.RESIDUAL_DIFFERENCE_METHOD:
        this.isResidualDifferencePanSample = isFirstField;
        break;
    }
    isFirstField ? this.highlightDefaultField() : this.moveToNextField();
  }

  getReadings() {
    this.resetBalanceReadingMethodChange();
    if (!this.selectedPushValue) {
      this.receivedReading = false;
      this.instrumentNotificationService.requestWeightReadings();
      this.timeoutError = setTimeout(() => {
        if (!this.receivedReading) {
          this.confirmErrorMessage($localize`:@@getReadingTimeOut:Get reading timed out.`);
        }
      }, this.timeoutLimit);
    } else {
      this.instrumentNotificationService.requestWeightReadingsWithPush();
    }
  }

  getReadingsForPush() {
    if (this.selectedPushValue) {
      this.instrumentNotificationService.requestWeightReadingsWithPush();
    }
  }

  confirmMessageForCommit(message: string) {
    this.confirmationService.confirm({
      message: `${message}`,
      header: $localize`:@@confirmationHeader:Confirmation`,
      acceptVisible: true,
      acceptLabel: $localize`:@@confirmationOk:Yes`,
      rejectVisible: true,
      rejectLabel: $localize`:@@confirmationCancel:No`,
      closeOnEscape: true,
      dismissableMask: false,
      icon: this.confirmationIcon,
      accept: () => {
        this.commitValue();
      },
      reject: () => { }
    });
  }

  confirmErrorMessage(message: string) {
    this.confirmationService.confirm({
      message: `${message}`,
      header: $localize`:@@confirmationHeader:Confirmation`,
      acceptVisible: true,
      acceptLabel: $localize`:@@confirmationOk:Ok`,
      rejectVisible: false,
      closeOnEscape: true,
      dismissableMask: false,
      icon: this.confirmationIcon,
      accept: () => {
      },
    });
  }

  private confirmWorkflowStateTransition(message: string, weight: InstrumentsReadingResponse) {
    this.confirmationService.confirm({
      message: `${message}`,
      header: $localize`:@@confirmationHeader:Confirmation`,
      acceptVisible: true,
      acceptLabel: $localize`:@@confirmationOk:Yes`,
      rejectVisible: true,
      rejectLabel: $localize`:@@confirmationCancel:No`,
      closeOnEscape: true,
      dismissableMask: false,
      icon: this.confirmationIcon,
      accept: () => {
        this.setTextboxValue(weight);
      },
    });
  }

  private setTextboxValue(weight: InstrumentsReadingResponse) {
    switch (this.selectedMethod) {
      case this.DIRECT_METHOD:
        this.replaceValuesForDirectMethod(weight);
        break;

      case this.DIFFERENCE_METHOD:
        this.replaceValuesForDifferenceMethod(weight);
        break;

      case this.RESIDUAL_DIFFERENCE_METHOD:
        this.replaceValuesForResidualDifferenceMethod(weight);
        break;

      default:
        break;
    }
  }

  private replaceValuesForDirectMethod(weight: InstrumentsReadingResponse) {
    if (this.isTare) {
      this.directTare = weight.weightValue;
    } else {
      this.directActual = weight.weightValue;
    }
  }

  private replaceValuesForDifferenceMethod(weight: InstrumentsReadingResponse) {
    if (this.isDifferencePan) {
      this.differencePan = weight.weightValue;
    } else {
      this.differencePanSample = weight.weightValue;
    }

    if (this.differencePan !== undefined && this.differencePanSample !== undefined) {
      this.differenceActual = this.differencePanSample - this.differencePan;
    }
  }

  private replaceValuesForResidualDifferenceMethod(weight: InstrumentsReadingResponse) {
    if (this.isResidualDifferencePanSample) {
      this.residualDifferencePanSample = weight.weightValue;
    } else {
      this.residualDifferencePanResidual = weight.weightValue;
    }

    if (this.residualDifferencePanSample !== undefined && this.residualDifferencePanResidual !== undefined) {
      this.residualDifferenceActual = this.residualDifferencePanSample - this.residualDifferencePanResidual;
    }
  }

  private convertUnit(value: number, targetUnit: string): number {
    if (value !== undefined) {
      return targetUnit === 'mg' ? value * 1000 : value / 1000;
    }
    return value;
  }

  unitConversion() {
    switch (this.selectedMethod) {
      case this.DIRECT_METHOD:
        this.directMethodWeightConversion();
        break;
      case this.DIFFERENCE_METHOD:
        this.differenceMethodWeightConversion();
        break;
      case this.RESIDUAL_DIFFERENCE_METHOD:
        this.residualDifferenceMethodWeightConversion();
        break;
    }
    this.balanceReadingUnitChanged = true;
    if (this.showTarget) {
      this.specificationTarget = this.convertUnit(this.specificationTarget, this.selectedUnit);
    }
  }

  private directMethodWeightConversion() {
    this.directTare = this.convertUnit(this.directTare, this.selectedUnit);
    this.directActual = this.convertUnit(this.directActual, this.selectedUnit);
  }

  private differenceMethodWeightConversion() {
    this.differencePan = this.convertUnit(this.differencePan, this.selectedUnit);
    this.differencePanSample = this.convertUnit(this.differencePanSample, this.selectedUnit);
    if (this.differencePan !== undefined && this.differencePanSample !== undefined) {
      this.differenceActual = this.differencePanSample - this.differencePan;
    }
    if (this.showTarget) {
      this.differencePanSampleTarget = this.convertUnit(this.differencePanSampleTarget, this.selectedUnit);
    }
  }

  private residualDifferenceMethodWeightConversion() {
    this.residualDifferencePanSample = this.convertUnit(this.residualDifferencePanSample, this.selectedUnit);
    this.residualDifferencePanResidual = this.convertUnit(this.residualDifferencePanResidual, this.selectedUnit);
    if (this.residualDifferencePanSample !== undefined && this.residualDifferencePanResidual !== undefined) {
      this.residualDifferenceActual = this.residualDifferencePanSample - this.residualDifferencePanResidual;
    }
    if (this.showTarget) {
      this.residualDifferencePanResidualTarget = this.convertUnit(this.residualDifferencePanResidualTarget, this.selectedUnit);
    }
  }

  onDirectTareChange(): void {
    if(!this.balanceReadingMethodChanged && !this.balanceReadingUnitChanged && this.directTare){
      this.directTareRecordTime = this.getCurrentTime();
    }
    this.directTareReadMethod = this.selectedPushValue ? 'push' : 'pull';
    this.moveToNextField();
  }

  onDirectActualChange(): void {
    if(!this.balanceReadingMethodChanged && !this.balanceReadingUnitChanged && this.directActual){
      this.directActualRecordTime = this.getCurrentTime();
    }
    this.resetBalanceReadingMethodChange();
    this.directActualReadMethod = this.selectedPushValue ? 'push' : 'pull';
    if (this.sequentialReadingEnabled && this.directActual) this.commitValue();
  }

  onDifferencePanChanged() {
    if(!this.balanceReadingMethodChanged && !this.balanceReadingUnitChanged && this.differencePan){
      this.differencePanRecordTime = this.getCurrentTime();
    }
    this.differencePanReadMethod = this.selectedPushValue ? 'push' : 'pull';
    this.moveToNextField();
    if (this.showTarget) this.calculateTargetWeight(this.selectedMethod);
  }

  onDifferencePanSampleChanged() {
    if(!this.balanceReadingMethodChanged && !this.balanceReadingUnitChanged && this.differencePanSample){
      this.differencePanSampleRecordTime = this.getCurrentTime();
    }
    this.differencePanSampleReadMethod = this.selectedPushValue ? 'push' : 'pull';
  }

  onDifferenceActualChanged() {
    this.resetBalanceReadingMethodChange();
    if (this.sequentialReadingEnabled && this.differenceActual) this.commitValue();
  }

  onResidualDifferencePanSampleChanged() {
    if(!this.balanceReadingMethodChanged && !this.balanceReadingUnitChanged && this.residualDifferencePanSample){
      this.residualDifferencePanSampleRecordTime = this.getCurrentTime();
    }
    this.residualDifferencePanSampleReadMethod = this.selectedPushValue ? 'push' : 'pull';
    this.moveToNextField();
    if (this.showTarget) this.calculateTargetWeight(this.selectedMethod);
  }

  onResidualDifferencePanResidualChange() {
    if(!this.balanceReadingMethodChanged && !this.balanceReadingUnitChanged && this.residualDifferencePanResidual){
      this.residualDifferencePanResidualRecordTime = this.getCurrentTime();
    }
    this.residualDifferencePanResidualReadMethod = this.selectedPushValue ? 'push' : 'pull';
  }

  onResidualDifferenceActualChange() {
    this.resetBalanceReadingMethodChange();
    if (this.sequentialReadingEnabled && this.residualDifferenceActual) this.commitValue();
  }

  resetBalanceReadingMethodChange(){
    this.balanceReadingMethodChanged = false;
    this.balanceReadingUnitChanged = false;
  }
}
