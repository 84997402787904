import { NA } from "bpt-ui-library/shared";

export enum PromptType {
  Invalid = 'invalid',
  Materials = 'materials',
  Instruments = 'instruments',
  Columns = 'columns',
  ConsumablesAndSupplies = 'consumablesAndSupplies',
  Preparations = 'preparations'
}

export type PromptItem<T extends PromptType, V extends PromptItemValueType> = {
  type: T,
  value: V & PromptItemCommonValueProperties
}

export type PromptItemValueType = PromptItemMaterial | PromptItemInstrument | PromptItemColumn | PromptItemPreparation | PromptItemConsumableOrSupply;

export type PromptItemCommonValueProperties = {
  name: string,
  /**
   * @default NA
   * Always hidden in table and is readonly
   */
  source: string | typeof NA,

  clientFacingPrompt: string,
  /**
   * @default false
   */
  isPromptRemoved: boolean
}

export type PromptItemMaterial = {
  materialType: string,
  brand?: string,
  grade?: string,
  purity?: string,
  concentration?: string,
  lot?: string,
  catalog?: string,
  additionalDetails?: string
}

export type PromptItemInstrument = {
  manufacturer?: string,
  model?: string,
  additionalDetails?: string,
}

export type PromptItemColumn = {
  // nameOrPacking: string,
  chromatographyType?: string,
  manufacturer?: string,
  dimensions?: string,
  particleSizeOrFilmThickness?: string,
  packingPhase?: string,
  lot?: string,
  catalog?: string,
  serial?: string
}

export type PromptItemPreparation = {
  concentration?: string,
  storageCondition?: string,
  containerDescription?: string,
  client?: string,
  project?: string,
  analysis?: string,
  method?: string,
  compendia?: string
}

export type PromptItemConsumableOrSupply = {
  details?: string,
  manufacturer?: string,
  lot?: string,
  catalog?: string,
  part?: string
}

export interface PromptData{
  type: PromptType,
  data: { [key: string]: any }[]
}

function getCommonProperties(values?: any) {
  if(values === undefined) {
    return {
      name: "",
      source: NA,
      clientFacingPrompt: "",
      isPromptRemoved: false
    };
  }
  return {
    name: values.name,
    source: values.source,
    clientFacingPrompt: values.clientFacingPrompt,
    isPromptRemoved: values.isPromptRemoved
  }
}

export function promptItemBuilder(T: PromptType, values?: PromptItemValueType) {
  let promptItem: PromptItem<PromptType, PromptItemValueType>;
  switch (T) {
    case PromptType.Columns:
      const ColumnsValues = values as PromptItemColumn;
      promptItem = {
        type: PromptType.Columns,
        value: values === undefined ? 
        {
          ...getCommonProperties(),
          chromatographyType: "",
          manufacturer: "",
          dimensions: "",
          particleSizeOrFilmThickness: "",
          packingPhase: "",
          lot: "",
          catalog: "",
          serial: ""
        } : 
        {
          ...getCommonProperties(values),
          chromatographyType: ColumnsValues.chromatographyType,
          manufacturer: ColumnsValues.manufacturer,
          dimensions: ColumnsValues.dimensions,
          particleSizeOrFilmThickness: ColumnsValues.particleSizeOrFilmThickness,
          packingPhase: ColumnsValues.packingPhase,
          lot: ColumnsValues.lot,
          catalog: ColumnsValues.catalog,
          serial: ColumnsValues.serial
        }
      } as PromptItem<PromptType.Columns, PromptItemColumn>
      break;
    case PromptType.Instruments:
      const instrumentValues = values as PromptItemInstrument;
      promptItem = {
        type: PromptType.Instruments,
        value: values === undefined ? 
        {
          ...getCommonProperties(),
          manufacturer: "",
          model: "",
          additionalDetails: ""
        } : 
        {
          ...getCommonProperties(values),
          manufacturer: instrumentValues.manufacturer,
          model: instrumentValues.model,
          additionalDetails: instrumentValues.additionalDetails
        }
      } as PromptItem<PromptType.Instruments, PromptItemInstrument>
      break;
    case PromptType.ConsumablesAndSupplies:
      const consumableAndSuppliesValues = values as PromptItemConsumableOrSupply;
      promptItem = {
        type: PromptType.ConsumablesAndSupplies,
        value: values === undefined ? 
        {
          ...getCommonProperties(),
          details: "",
          manufacturer: "",
          lot: "",
          catalog: "",
          part: ""
        } : 
        {
          ...getCommonProperties(values),
          details: consumableAndSuppliesValues.details,
          manufacturer: consumableAndSuppliesValues.manufacturer,
          lot: consumableAndSuppliesValues.lot,
          catalog: consumableAndSuppliesValues.catalog,
          part: consumableAndSuppliesValues.part
        }
      } as PromptItem<PromptType.ConsumablesAndSupplies, PromptItemConsumableOrSupply>
      break;
    case PromptType.Preparations:
      const preparationsValues = values as PromptItemPreparation;
      promptItem = {
        type: PromptType.Preparations,
        value: values === undefined ? 
        {
          ...getCommonProperties(),
          concentration: "",
          storageCondition: "",
          containerDescription: "",
          client: "",
          project: "",
          analysis: "",
          method: "",
          compendia: ""
        }: 
        {
          ...getCommonProperties(values),
          concentration: preparationsValues.concentration,
          storageCondition: preparationsValues.storageCondition,
          containerDescription: preparationsValues.containerDescription,
          client: preparationsValues.client,
          project: preparationsValues.project,
          analysis: preparationsValues.analysis,
          method: preparationsValues.method,
          compendia: preparationsValues.compendia
        }
      } as PromptItem<PromptType.Preparations, PromptItemPreparation>
      break;
    case PromptType.Materials:
    default:
      const materialValues = values as PromptItemMaterial;
      promptItem = {
        type: PromptType.Materials,
        value: values === undefined ? {
          ...getCommonProperties(),
          materialType: "",
          brand: "",
          grade: "",
          purity: "",
          concentration: "",
          lot: "",
          catalog: "",
          additionalDetails: ""
        } : 
        {
          ...getCommonProperties(values),
          materialType: materialValues.materialType,
          brand: materialValues.brand,
          grade: materialValues.grade,
          purity: materialValues.purity,
          concentration: materialValues.concentration,
          lot: materialValues.lot,
          catalog: materialValues.catalog,
          additionalDetails: materialValues.additionalDetails
        }
      } as PromptItem<PromptType.Materials, PromptItemMaterial>
      break;
  }
  return promptItem;

}