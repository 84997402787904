import { Instant, LocalDate } from "@js-joda/core";
import { StringTypeDictionaryValue, StringValue } from "../../api/data-entry/models";
import { InstantValue, LocalDateValue } from "../../api/models";
import { ColumnDefinition } from "bpt-ui-library/bpt-grid/model/column-definition.interface";
import { Quantity } from "bpt-ui-library/shared";
import { CellLock, LockType } from "../../model/input-lock.interface";
import { ElnCollaborator } from "../../model/eln-collaborator";
import { NumberValue } from "../../api/cookbook/models";

export interface Preparation {
  preparationId?: string;
  name: StringValue;
  formulationOrComponents?: StringValue;
  concentration?: NumberValue;
  expiration: ExpirationType;
  storageCondition: StringValue;
  preparationInternalInformation?: PreparationInternalInformation;
  additionalInformation?: AdditionalInformation;
  containers?: Array<ContainerGridData>;
  isRemoved: boolean;

}
export interface NewPreparationsSubmission {
  data: Preparation,
  closeHandler: () => void,
  toasterMessage? : string 
}

export interface ContainerGridData {
  tagSequenceNumber: number;
  tags?: string;
  originalQuantity?: NumberValue;
  containerDescription: StringTypeDictionaryValue;
}
export interface ContainerDescription {
  color?: string;
  material?: string;
  type?: string;
  exposure?: string;
}
export interface PreparationAuditHistoryInputs {
  row?: string;
  field?: string;
  experimentId?: string;
  activityId: string,
  columnDefinition: ColumnDefinition[] 
}
export interface PreparationInternalInformation {
  preparationId?: string;
  disposal?: string;
  storageLocation?: string;
  stability?: NumberValue;
  hazards?: string;
  originalQuantity?: NumberValue;
  additionalLabel?: string;
  allowScalingUp?: boolean;
  allowScalingDown?: boolean;
}
export interface AdditionalInformation {
  preparationId?: string;
  analysis?: string;
  method?: string;
  compendia?: string;
  client?: string;
  project?: string;
  preparedBy?: string;
  reviewedBy?: string;
  storedBy?: string;
  discardedOrConsumed?: boolean
  subBusinessUnit?: string
  discardedOrConsumedBy?: string
  discardedOrConsumedOn?: Instant
}

//to do rename to expirationValue
export interface ExpirationType {
  preparationId?: string;
  suitableForUse: boolean;
  expirationDate: InstantValue | LocalDateValue;
  duration? : Quantity;
}
export interface ExpirationModel {
  preparationId?: string;
  suitableForUse: boolean;
  expirationDate?: Instant | LocalDate | undefined;
  duration? : Quantity;
}

export enum ActionType {
  Remove = 'remove',
  Restore = 'restore',
}

export interface CellLockDetails {
  lockList: CellLock[];
  collaborator: ElnCollaborator | undefined;
}

export interface CellLockRequest {
  lockType: LockType;
  tableId: string;
  rowId: string;
  columnName: string;
  multiSelectCells: boolean;
}
