import { CellRange, Column, ColumnApi, EditableCallbackParams, RowNode } from "ag-grid-community";
import { BptGridComponent, ColumnDefinition, ColumnType, GridContextMenuItem } from "bpt-ui-library/bpt-grid";
import { NA } from "bpt-ui-library/shared";
import { isEmpty } from "lodash-es";
import { Injectable } from "@angular/core";


export type NaGridTableIdentifierDataForPrompts = {
  isCellEditable : (_params: EditableCallbackParams) => boolean | (()=>boolean),
  postChangeCellCommand : (id: string, changedValue:  {[key: string]: string}) => void,
  grid: BptGridComponent,
  table: {values: { [key: string]: any }[]},
  columnDefinitions: ColumnDefinition[],
  idFieldName: string
}

@Injectable({
  providedIn: 'root'
})
export class FillWithNaGridHelperForPrompt {
  readonly iconNotApplicable = '<i class="ag-icon ag-custom-icon icon-not-applicable"></i>';

  getContextMenuOptionsOfFillWithNa(
    args : NaGridTableIdentifierDataForPrompts
  ): GridContextMenuItem {
    if (args.grid?.gridApi) {
      const cells = args.grid.gridApi.getCellRanges();

      if (!isEmpty(cells) && cells && cells[0] && cells[0].startRow && cells[0].columns) {
        if (
          cells[0].startRow?.rowIndex ===
          (cells[0].endRow?.rowIndex ?? cells[0].startRow?.rowIndex) &&
          cells[0].columns?.length === 1
        ) {
          return this.naSingleCellOptions(cells, args);
        }
        else if (
          cells[0].columns?.length > 1 ||
          cells[0].startRow?.rowIndex !== cells[0].endRow?.rowIndex
        ) {
          return this.naMultipleCellsOptions(cells, args);
        }
      }
    }

    return {
      label: '',
      action: () => { args.grid.suppressContextMenu = true; }
    };
  }

  private naMultipleCellsOptions(cells: CellRange[], args: NaGridTableIdentifierDataForPrompts): GridContextMenuItem {
    return {
      label: labelsByNaOptionType.fillWithNa.label,
      subitems: [
        {
          label: labelsByNaOptionType.naEmptyCells.label,
          action: () => this.beforeNaActionForPrompt(cells[0], args, true)
        },
        {
          label: labelsByNaOptionType.naAllCells.label,
          action: () => this.beforeNaActionForPrompt(cells[0],args)
        }
      ],
      icon: this.iconNotApplicable
    };
  }

  private naSingleCellOptions(cells: CellRange[], args: NaGridTableIdentifierDataForPrompts): GridContextMenuItem {
    return {
      label: labelsByNaOptionType.fillWithNa.label,
      action: () => this.beforeNaActionForPrompt(cells[0],args),
      icon: this.iconNotApplicable
    };
  }

  private beforeNaActionForPrompt(cells: CellRange, args: NaGridTableIdentifierDataForPrompts, naOnlyEmptyCells = false) {
    args.grid.suppressContextMenu = true;
    const rowNodes: RowNode[] = [];
    const columnValues: { [key: string]: any } = {};
    const startRowIndex = cells.startRow?.rowIndex as number;
    const endRowIndex = cells.endRow?.rowIndex ?? startRowIndex;
    for (
      let rowIndex = Math.min(startRowIndex, endRowIndex);
      rowIndex <= Math.max(startRowIndex, endRowIndex);
      rowIndex++
    ) {
      rowNodes.push(args.grid.gridApi.getDisplayedRowAtIndex(rowIndex) as RowNode);
      const rowId = rowNodes[rowNodes.length - 1].id as string;
      columnValues[rowId] = {};
      cells.columns.forEach((column) => {
        const colId = column.getColId();
        const targetColumnIndex = args.columnDefinitions.findIndex(
          (colDef) => colDef.field === colId
        );
        if (
          rowIndex !== undefined &&
          targetColumnIndex !== -1 &&
          !['rowIndex', 'id', 'rowIndex_1'].includes(colId)
        ) {
            if(!this.validateCellForNaAction(
              args.columnDefinitions[targetColumnIndex].columnType as string,
              column,
              rowNodes[rowNodes.length - 1],
              args,
              naOnlyEmptyCells
            )) {
              columnValues[rowId as any][colId] = NA;
            }
          }
      });
    }
    this.naAction(columnValues, args);
  }

  private naAction(
    columnValues: { [key: string]: any },
    args: NaGridTableIdentifierDataForPrompts
  ) {
    const rowIds = Object.keys(columnValues);
    if(rowIds.length > 0) {
      rowIds.forEach((item : string) => {
        if(Object.keys(columnValues[item]).length > 0) {
          args.postChangeCellCommand(item, columnValues[item])
        }
      })
    }
  }

  private validateCellForNaAction(
    columnType: string,
    column: Column,
    rowNode: RowNode,
    args: NaGridTableIdentifierDataForPrompts,
    naOnlyEmptyCells = false
  ) : boolean {
    const rowData = args.table.values.find((data) => data[args.idFieldName] === rowNode.id);
    const colId = column.getColId();
    if (!rowData) return false;
    const alreadyNA = rowData[colId] === NA;
    if (!rowNode || !rowData || !this.acceptedColumnTypesForFillWithNA(columnType)) return true;
    args.grid.gridApi?.stopEditing(true);
    const params: EditableCallbackParams = {
      node: rowNode,
      data: rowData,
      column: column,
      colDef: column.getColDef(),
      api: args.grid.gridApi,
      columnApi: new ColumnApi(args.grid.gridApi),
      context: args.grid.gridOptions.context
    };
    const canEditCell = args.isCellEditable(params);
    const naOnlyEmptyCellsCheck = naOnlyEmptyCells
      ? rowData[colId] && rowData[colId] !== ""
      : false;
    return alreadyNA || !canEditCell || naOnlyEmptyCellsCheck;
  }

  private acceptedColumnTypesForFillWithNA(columnType: string) {
    switch (columnType) {
      case ColumnType.boolean:
        return false;
      default:
        return true;
    }
  }
}

export const labelsByNaOptionType: {
  [optionType: string]: { label: string };
} = {
  fillWithNa: {
    label: $localize`:@@fillWithNa:Fill with N/A`
  },
  naEmptyCells: {
    label: $localize`:@@naEmptyCells:N/A Empty Cells`
  },
  naAllCells: {
    label: $localize`:@@naAllCells:N/A All Cells`
  }
};
