export enum TemplateLocationOptions {
  None = 'none',
  AddToExistingModule = 'existingModule',
  AddAsNewModuleAndExistingActivity = 'newModuleExistingActivity',
  AddAsNewActivityAndModule = 'newActivityAndModule',
  AddAsExistingActivity = 'existingActivity',
  AddAsNewActivity = 'newActivity',
  AddAsNewModule = 'newModule',
  AddAsNewTableOrForm = 'newTableOrForm',
  AddAsNewTableOrFormOrPpr = 'newTableOrFormOrPpr'
}

export class TemplateLocationOptionsConstants {
  static readonly newTableOrForm = {
    label: $localize`:@@InsertOptionAddNewFormTable:New form/table`,
    value: TemplateLocationOptions.AddAsNewTableOrForm
  };
  static readonly newTableOrFormOrPpr = {
    label: $localize`:@@InsertOptionAddNewFormTableBlank:New form/table/ReferencePromptPreparation`,
    value: TemplateLocationOptions.AddAsNewTableOrFormOrPpr
  };
  static readonly newModule = {
    label: $localize`:@@InsertOptionAddNewModule:New module`,
    value: TemplateLocationOptions.AddAsNewModule
  };
  static readonly newActivity = {
    label: $localize`:@@InsertOptionAddNewActivity:New activity`,
    value: TemplateLocationOptions.AddAsNewActivity
  };
  static readonly existingModule = {
    label: $localize`:@@InsertOptionAddToExistingModule:To existing module`,
    value: TemplateLocationOptions.AddToExistingModule
  };
  static readonly existingActivityAsModule = {
    label: $localize`:@@InsertOptionAddToExistingActivityAndNewModule:To existing activity as new module`,
    value: TemplateLocationOptions.AddAsNewModuleAndExistingActivity
  };
}
