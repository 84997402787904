<div *ngIf="labItemPreparations.length>0 && showGrid">
  <app-preparations-labitems
  [flagConfigurationProvider]="getFlagRendererConfig"
  [allowRowEdit]="false"
  [preparations]="labItemPreparations"
  [currentActivityId]="currentActivityId"
  [isExperimentInReview]="isExperimentInReview"
  (clientFacingNoteRequest)="showClientFacingNotes($event)"
  [internalInformationReadOnly]='true'>
</app-preparations-labitems>
</div>