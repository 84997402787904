import { Injectable } from '@angular/core';
import { Activity, Experiment, Module } from 'model/experiment.interface';
import { Subject, BehaviorSubject } from 'rxjs';
import { ModuleNode } from '../../../api/models';
import {
  ExperimentTemplateApplyCommand,
  ExperimentTemplateAppliedResponse,
  NotificationResult,
  ExperimentTemplateAppliedEventNotification,
  ExperimentRecipeApplyCommand,
  ExperimentRecipeAppliedResponse
} from '../../../api/data-entry/models';

export type ActiveTemplateModule = Module | ModuleNode;
export type ActiveTemplate = Activity | ActiveTemplateModule | undefined;
@Injectable({
  providedIn: 'root'
})
export class ExperimentTemplateEventService {
  // Events
  private readonly templateApplyCommandFinalized = new Subject<ExperimentTemplateApplyCommand>();
  public TemplateApplyCommandFinalized = this.templateApplyCommandFinalized.asObservable();

  private readonly recipeApplyCommandFinalized = new Subject<ExperimentRecipeApplyCommand>();
  public RecipeApplyCommandFinalized = this.recipeApplyCommandFinalized.asObservable();

  private readonly activeTemplateChanged = new BehaviorSubject<ActiveTemplate>(undefined);
  public ActiveTemplateChanged = this.activeTemplateChanged.asObservable();

  private readonly lastUpdatedTemplateApplyCommand = new BehaviorSubject<
    ExperimentTemplateApplyCommand | undefined
  >(undefined);
  public LastUpdatedTemplateApplyCommand = this.lastUpdatedTemplateApplyCommand.asObservable();

  private readonly templateAppliedResponse = new Subject<ExperimentTemplateAppliedResponse>();
  public TemplateAppliedResponse = this.templateAppliedResponse.asObservable();

  private readonly templateApplyFailed = new Subject<NotificationResult>();
  public TemplateApplyFailed = this.templateApplyFailed.asObservable();

  private readonly templateApplySuccess = new Subject<ExperimentTemplateAppliedResponse>();
  public TemplateApplied = this.templateApplySuccess.asObservable();

  private readonly recipeApplySuccess = new Subject<ExperimentRecipeAppliedResponse>();
  public RecipeApplied = this.recipeApplySuccess.asObservable();


  private readonly templateAddedByOtherUser =
    new Subject<ExperimentTemplateAppliedEventNotification>();
  public TemplateAddedByOtherUser = this.templateAddedByOtherUser.asObservable();

  public readonly experimentRefreshed = new Subject<Experiment>();
  public ExperimentRefreshed = this.experimentRefreshed.asObservable();

  // Event through delegates
  public LoadedExperiment: () => Experiment | undefined = () => {
    return undefined;
  };

  public PublishApplyExperimentTemplateCommand(command: ExperimentTemplateApplyCommand): void {
    this.templateApplyCommandFinalized.next(command);
  }
  public PublishApplyExperimentRecipeCommand(command: ExperimentRecipeApplyCommand): void {
    this.recipeApplyCommandFinalized.next(command);
  }

  public TemplateAppliedResponseNotification(response: ExperimentTemplateAppliedResponse): void {
    this.templateAppliedResponse.next(response);
  }

  public TemplateSelectionChangedNotification(activeTemplate: ActiveTemplate): void {
    this.activeTemplateChanged.next(activeTemplate);
  }

  public ApplyTemplateCommandChangedNotification(command: ExperimentTemplateApplyCommand): void {
    this.lastUpdatedTemplateApplyCommand.next(command);
  }

  public TemplateApplyFailedNotification(notificationResult: NotificationResult): void {
    this.templateApplyFailed.next(notificationResult);
  }

  public TemplateApplySuccessNotification(
    successResponse: ExperimentTemplateAppliedResponse
  ): void {
    // Note: Since we don't actually have the cloned nodes anywhere yet, we can't trigger the onLoad Rules Event.
    // However, since applying a template causes reloading of the whole experiment, it's moot.
    this.templateApplySuccess.next(successResponse);
  }

  public RecipeApplySuccessNotification(
    successResponse: ExperimentRecipeAppliedResponse
  ): void {
    this.recipeApplySuccess.next(successResponse);
  }
  public ExperimentHasRefreshed(experiment: Experiment): void {
    this.experimentRefreshed.next(experiment);
  }

  public notifyAppliedTemplateEvent(
    notification: ExperimentTemplateAppliedEventNotification
  ): void {
    // Note: Since we don't actually have the cloned nodes anywhere yet, we can't trigger the onLoad Rules Event.
    // However, since applying a template causes reloading of the whole experiment, it's moot.
    this.templateAddedByOtherUser.next(notification);
  }
}
