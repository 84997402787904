import { Injectable } from '@angular/core';
import { FillWithNaGridHelper, NaIdentifier } from '../services/fill-with-na-grid-helper';
import { NA } from 'bpt-ui-library/shared';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { UserService } from '../services/user.service';
import { ExperimentService } from '../experiment/services/experiment.service';
import { ExperimentNodeRetitleService } from '../experiment/services/experiment-node-re-title.service';

@Injectable({
  providedIn: 'root'
})
export class EllipsisMenuBuilderHelper {
  isUserHavingOnlyReviewerRights: boolean;

  constructor(
    private readonly fillWithNAHelper: FillWithNaGridHelper,
    private readonly confirmationService: ConfirmationService,
    private readonly experimentService: ExperimentService,
    private readonly userService: UserService
  ) {
    this.isUserHavingOnlyReviewerRights = this.userService.hasOnlyReviewerRights();
  }

  public buildEllipseMenuItem(
    id: string,
    fillWithNAOptions: boolean,
    isTriggeredForTable = false
  ): MenuItem[] {
    const menuItems = [];
    const fillWithNaMenu = {
      label: $localize`:@@fillWithNa:Fill with ${NA}`,
      command: (_event$: any) => {
        const response: NaIdentifier = {
          id: id,
          isEmpty: true
        };
        isTriggeredForTable
          ? this.fillWithNAHelper.fillWithNAOnTable.next(response)
          : this.fillWithNAHelper.fillWithNAOnForm.next(response);
      },
      icon: 'icon-s icon-not-applicable'
    };
    const forceFillWithNaMenu = {
      label: $localize`:@@forcefillWithNa:Force fill with ${NA}`,
      command: (_event$: any) => {
        this.confirmationService.confirm({
          message: $localize`:@@forceFillWithNaConfirmation:Are you sure you want to override the observable data with ${NA} value?`,
          header: $localize`:@@confirmationHeader:Confirmation`,
          acceptVisible: true,
          acceptLabel: $localize`:@@confirmationYes:Yes`,
          rejectLabel: $localize`:@@confirmationNo:No`,
          rejectVisible: true,
          closeOnEscape: true,
          dismissableMask: false,
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            const response: NaIdentifier = {
              id: id,
              isEmpty: false
            };
            isTriggeredForTable
              ? this.fillWithNAHelper.fillWithNAOnTable.next(response)
              : this.fillWithNAHelper.fillWithNAOnForm.next(response);
          }
        });
      },
      icon: 'icon-s icon-not-applicable'
    };

    if (fillWithNAOptions && this.isNaOptionVisible()) {
      menuItems.push(fillWithNaMenu);
      menuItems.push(forceFillWithNaMenu);
    }
    return menuItems;
  }

  isNaOptionVisible(): boolean {
    const currentExperiment = this.experimentService.currentExperiment;
    if (currentExperiment && (this.isUserHavingOnlyReviewerRights ||
        ExperimentService.isExperimentAuthorizedOrCancelled(currentExperiment.workflowState) ||
        ExperimentService.isExperimentInReview(currentExperiment.workflowState) ||
        !ExperimentNodeRetitleService.HasPermissionToEditTitle)
    ) {
      return false;
    } else {
      return true;
    }
  }
}
