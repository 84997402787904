<app-preparations 
  [disableCreatePreparation]="disableCreatePreparation"
  [isStatusColumnEditable]="false"
  [disableRemoveAndRestorePreparation]="true"
  [disableGrid]="disableGrid"
  [preparations]="preparations"
  [currentActivityId]="currentActivityId"
  [statusBar]="statusBar"
  >
</app-preparations>
