<div
  id="eln-labitems-prompt-grid-container"
  class="eln-prompts-data eln-table-theme"
  *ngIf="dataSource || containsRemovedRows">
  <span
    id="eln-study-activity-aliquot-icon"
    class="eln-study-activity-aliquot-icon table-title">{{ title }}
  </span>
  <bpt-grid
    #promptsGrid
    class="eln-grid eln-prompt-grid hide-readonly-icons"
    ngClass="true"
    gridId="{{ promptsGridId }}"
    [dataSource]="dataSource"
    [includeRowNumberColumn]="false"
    [useJsJodaTypes]="true"
    [gridMode]="gridMode"
    [allowRowAdd]="false"
    [columnDefinitions]="columnDefinitions"
    [showAutoSizeButton]="true"
    [rowActionConfigurations]="gridActions"
    [showFilterToggleButton]="true"
    [allowRowAdd]="promptGridConfig.allowRowAdd"
    [enableExport]="promptGridConfig.enableExport"
    (exportAllData)="exportDataToExcel()"
    [useJsJodaTypes]="promptGridConfig.useJsJodaTypes"
    [showGridOptionsButton]="false">
    <ng-container class="bpt-grid-toolbar-right-layout">
      <a
        *ngIf="containsRemovedRows"
        class="bpt-grid-toolbar-item table-grid-toolbar-view-removed-rows-icon sample-table-remove-icon"
        align="right"
        id="eln-iconViewRemovedRowsShow"
        (click)="viewRemovedRows()">
        <span
          i18n-pTooltip="@@viewRemovedRows"
          class="fa icon-view-row"
          pTooltip="View Removed Rows"
          tooltipPosition="top"><span class="ml-1 viewRemovedRows-iconTxt" i18n="@@viewRemovedRows">View Removed
            Rows</span></span>
      </a>
    </ng-container>
  </bpt-grid>
</div>