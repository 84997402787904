<div class="flex align-items-center gap-2">
    <div class="flags-container" *ngFor="let flag of flagsInfo">
        <em
        class="flag {{flag.icon}} 
        {{flag.isRaised ? flag.flagStyle : ''}} 
        {{flag.isDisabled && !flag.isRaised ? 'eln-experimentFlag-disabled':''}}
        {{flag.isDisabled && flag.isRaised ? 'eln-experimentFlag-raised-inactive':''}}"
        pTooltip="{{flag.tooltipText}}"
        tooltipPosition="bottom"
        (click)="toggleExperimentFlag(flag.flagType, flag.isRaised)"
        id="eln-{{flag.flagType}}Flag"
        ></em>
    </div>
     <div *ngIf="options.showSpinner">
        <bpt-progress-spinner
        [useOverlay]="options.useOverlay"
        [message]="options.spinnerMessage"
        id="eln-progressSpinShow"
        ></bpt-progress-spinner>
    </div>
</div>
