<ng-container *ngIf="!isLimsHosted || visibleInLims">
  <bpt-top-bar
    [title]="topBarTitle"
    [subtitle]="subTitleBelow"
    [userPuid]="userPuid"
    [labsiteCode]="labsiteCode"
    [labsiteName]="labsiteName"
  >
  </bpt-top-bar>
</ng-container>
