import { Component, OnInit } from '@angular/core';
import { OutputEmpowerService } from '../../services/output-empower.service';
import { ChromatographyService } from '../../../api/services';
import { Experiment } from '../../../model/experiment.interface';
import { ExperimentWarningService } from '../../services/experiment-warning.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-activity-outputs-instrument-data-dropdown',
  templateUrl: './activity-outputs-instrument-data-dropdown.component.html'
})
export class ActivityOutputsInstrumentDataDropdownComponent implements OnInit
{
  experiment!: Experiment;
  isUserAllowedtoEdit?: boolean;
  constructor(
    readonly outputEmpowerService: OutputEmpowerService,
    readonly chromatographyService: ChromatographyService,
    readonly experimentWarningService: ExperimentWarningService
  ) {
  }

  get outputEmpowerData(): any {
    return this.outputEmpowerService;
  }

  instrumentData = {
    label: $localize`:@@instrumentData:Instrument Data`,
    icon: 'icon-database icon-m'
  };

  instrumentDataItems = [
    {
      label: $localize`:@@empower:Empower`,
      styleClass: 'p-menuitem-compact font-semibold'
    },
    {
      label: $localize`:@@importResults:Import Results`,
      icon: 'icon-save icon-m',
      command: () => {
        this.isUserAllowedtoEdit = this.experimentWarningService.isUserAllowedToEdit;
        this.importResults();
      }
    },
  ];

  ngOnInit() {
    this.isUserAllowedtoEdit = this.experimentWarningService.isUserAllowedToEdit;
  }

  importResults() {
    if (this.isUserAllowedtoEdit) {
      this.displayEmpowerLogin();
      return;
    }
  }

  displayEmpowerLogin() {
    this.outputEmpowerService.clearCachedData();
    this.outputEmpowerService.resetLoginData();
    this.chromatographyService.chromatographyEmpowerDatabasesGet$Json().subscribe({
      next: (response) => {
        this.outputEmpowerService.empowerDatabaseOptions = response.empowerDatabases;
      }
    });
    this.outputEmpowerService.canShowEmpowerLogin = true;
  }

  isOutputsTab(): boolean {
    return window.location.pathname.indexOf('Outputs') > -1;
  }
}
