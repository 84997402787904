/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { UserPreferenceListResponse } from '../models/user-preference-list-response';
import { UserPreferenceResponse } from '../models/user-preference-response';
import { userPreferencesSaveUserPreferencePost$Json } from '../fn/user-preference/user-preferences-save-user-preference-post-json';
import { UserPreferencesSaveUserPreferencePost$Json$Params } from '../fn/user-preference/user-preferences-save-user-preference-post-json';
import { userPreferencesSaveUserPreferencePost$Plain } from '../fn/user-preference/user-preferences-save-user-preference-post-plain';
import { UserPreferencesSaveUserPreferencePost$Plain$Params } from '../fn/user-preference/user-preferences-save-user-preference-post-plain';
import { userPreferencesSetDefaultUserPreferenceIdUserPreferenceKeyPost$Json } from '../fn/user-preference/user-preferences-set-default-user-preference-id-user-preference-key-post-json';
import { UserPreferencesSetDefaultUserPreferenceIdUserPreferenceKeyPost$Json$Params } from '../fn/user-preference/user-preferences-set-default-user-preference-id-user-preference-key-post-json';
import { userPreferencesSetDefaultUserPreferenceIdUserPreferenceKeyPost$Plain } from '../fn/user-preference/user-preferences-set-default-user-preference-id-user-preference-key-post-plain';
import { UserPreferencesSetDefaultUserPreferenceIdUserPreferenceKeyPost$Plain$Params } from '../fn/user-preference/user-preferences-set-default-user-preference-id-user-preference-key-post-plain';
import { userPreferencesUpdateUserPreferencePut$Json } from '../fn/user-preference/user-preferences-update-user-preference-put-json';
import { UserPreferencesUpdateUserPreferencePut$Json$Params } from '../fn/user-preference/user-preferences-update-user-preference-put-json';
import { userPreferencesUpdateUserPreferencePut$Plain } from '../fn/user-preference/user-preferences-update-user-preference-put-plain';
import { UserPreferencesUpdateUserPreferencePut$Plain$Params } from '../fn/user-preference/user-preferences-update-user-preference-put-plain';
import { userPreferencesUserBulkPreferencePost$Json } from '../fn/user-preference/user-preferences-user-bulk-preference-post-json';
import { UserPreferencesUserBulkPreferencePost$Json$Params } from '../fn/user-preference/user-preferences-user-bulk-preference-post-json';
import { userPreferencesUserBulkPreferencePost$Plain } from '../fn/user-preference/user-preferences-user-bulk-preference-post-plain';
import { UserPreferencesUserBulkPreferencePost$Plain$Params } from '../fn/user-preference/user-preferences-user-bulk-preference-post-plain';
import { userPreferencesUserPreferenceIdDelete$Json } from '../fn/user-preference/user-preferences-user-preference-id-delete-json';
import { UserPreferencesUserPreferenceIdDelete$Json$Params } from '../fn/user-preference/user-preferences-user-preference-id-delete-json';
import { userPreferencesUserPreferenceIdDelete$Plain } from '../fn/user-preference/user-preferences-user-preference-id-delete-plain';
import { UserPreferencesUserPreferenceIdDelete$Plain$Params } from '../fn/user-preference/user-preferences-user-preference-id-delete-plain';
import { userPreferencesUserPreferenceKeyGet$Json } from '../fn/user-preference/user-preferences-user-preference-key-get-json';
import { UserPreferencesUserPreferenceKeyGet$Json$Params } from '../fn/user-preference/user-preferences-user-preference-key-get-json';
import { userPreferencesUserPreferenceKeyGet$Plain } from '../fn/user-preference/user-preferences-user-preference-key-get-plain';
import { UserPreferencesUserPreferenceKeyGet$Plain$Params } from '../fn/user-preference/user-preferences-user-preference-key-get-plain';
import { userPreferencesUserPreferenceKeyUserPreferenceNamePost$Json } from '../fn/user-preference/user-preferences-user-preference-key-user-preference-name-post-json';
import { UserPreferencesUserPreferenceKeyUserPreferenceNamePost$Json$Params } from '../fn/user-preference/user-preferences-user-preference-key-user-preference-name-post-json';
import { userPreferencesUserPreferenceKeyUserPreferenceNamePost$Plain } from '../fn/user-preference/user-preferences-user-preference-key-user-preference-name-post-plain';
import { UserPreferencesUserPreferenceKeyUserPreferenceNamePost$Plain$Params } from '../fn/user-preference/user-preferences-user-preference-key-user-preference-name-post-plain';
import { userPreferencesUserPreferenceUserPreferenceKeyGet$Json } from '../fn/user-preference/user-preferences-user-preference-user-preference-key-get-json';
import { UserPreferencesUserPreferenceUserPreferenceKeyGet$Json$Params } from '../fn/user-preference/user-preferences-user-preference-user-preference-key-get-json';
import { userPreferencesUserPreferenceUserPreferenceKeyGet$Plain } from '../fn/user-preference/user-preferences-user-preference-user-preference-key-get-plain';
import { UserPreferencesUserPreferenceUserPreferenceKeyGet$Plain$Params } from '../fn/user-preference/user-preferences-user-preference-user-preference-key-get-plain';

@Injectable({ providedIn: 'root' })
export class UserPreferenceService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `userPreferencesUserPreferenceUserPreferenceKeyGet()` */
  static readonly UserPreferencesUserPreferenceUserPreferenceKeyGetPath = '/user-preferences/user-preference/{userPreferenceKey}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userPreferencesUserPreferenceUserPreferenceKeyGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  userPreferencesUserPreferenceUserPreferenceKeyGet$Plain$Response(params: UserPreferencesUserPreferenceUserPreferenceKeyGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UserPreferenceResponse>> {
    return userPreferencesUserPreferenceUserPreferenceKeyGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userPreferencesUserPreferenceUserPreferenceKeyGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userPreferencesUserPreferenceUserPreferenceKeyGet$Plain(params: UserPreferencesUserPreferenceUserPreferenceKeyGet$Plain$Params, context?: HttpContext): Observable<UserPreferenceResponse> {
    return this.userPreferencesUserPreferenceUserPreferenceKeyGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserPreferenceResponse>): UserPreferenceResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userPreferencesUserPreferenceUserPreferenceKeyGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  userPreferencesUserPreferenceUserPreferenceKeyGet$Json$Response(params: UserPreferencesUserPreferenceUserPreferenceKeyGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<UserPreferenceResponse>> {
    return userPreferencesUserPreferenceUserPreferenceKeyGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userPreferencesUserPreferenceUserPreferenceKeyGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userPreferencesUserPreferenceUserPreferenceKeyGet$Json(params: UserPreferencesUserPreferenceUserPreferenceKeyGet$Json$Params, context?: HttpContext): Observable<UserPreferenceResponse> {
    return this.userPreferencesUserPreferenceUserPreferenceKeyGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserPreferenceResponse>): UserPreferenceResponse => r.body)
    );
  }

  /** Path part for operation `userPreferencesUserPreferenceKeyGet()` */
  static readonly UserPreferencesUserPreferenceKeyGetPath = '/user-preferences/{userPreferenceKey}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userPreferencesUserPreferenceKeyGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  userPreferencesUserPreferenceKeyGet$Plain$Response(params: UserPreferencesUserPreferenceKeyGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UserPreferenceListResponse>> {
    return userPreferencesUserPreferenceKeyGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userPreferencesUserPreferenceKeyGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userPreferencesUserPreferenceKeyGet$Plain(params: UserPreferencesUserPreferenceKeyGet$Plain$Params, context?: HttpContext): Observable<UserPreferenceListResponse> {
    return this.userPreferencesUserPreferenceKeyGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserPreferenceListResponse>): UserPreferenceListResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userPreferencesUserPreferenceKeyGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  userPreferencesUserPreferenceKeyGet$Json$Response(params: UserPreferencesUserPreferenceKeyGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<UserPreferenceListResponse>> {
    return userPreferencesUserPreferenceKeyGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userPreferencesUserPreferenceKeyGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userPreferencesUserPreferenceKeyGet$Json(params: UserPreferencesUserPreferenceKeyGet$Json$Params, context?: HttpContext): Observable<UserPreferenceListResponse> {
    return this.userPreferencesUserPreferenceKeyGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserPreferenceListResponse>): UserPreferenceListResponse => r.body)
    );
  }

  /** Path part for operation `userPreferencesUserBulkPreferencePost()` */
  static readonly UserPreferencesUserBulkPreferencePostPath = '/user-preferences/user-bulk-preference';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userPreferencesUserBulkPreferencePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userPreferencesUserBulkPreferencePost$Plain$Response(params?: UserPreferencesUserBulkPreferencePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UserPreferenceListResponse>> {
    return userPreferencesUserBulkPreferencePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userPreferencesUserBulkPreferencePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userPreferencesUserBulkPreferencePost$Plain(params?: UserPreferencesUserBulkPreferencePost$Plain$Params, context?: HttpContext): Observable<UserPreferenceListResponse> {
    return this.userPreferencesUserBulkPreferencePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserPreferenceListResponse>): UserPreferenceListResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userPreferencesUserBulkPreferencePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userPreferencesUserBulkPreferencePost$Json$Response(params?: UserPreferencesUserBulkPreferencePost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<UserPreferenceListResponse>> {
    return userPreferencesUserBulkPreferencePost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userPreferencesUserBulkPreferencePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userPreferencesUserBulkPreferencePost$Json(params?: UserPreferencesUserBulkPreferencePost$Json$Params, context?: HttpContext): Observable<UserPreferenceListResponse> {
    return this.userPreferencesUserBulkPreferencePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserPreferenceListResponse>): UserPreferenceListResponse => r.body)
    );
  }

  /** Path part for operation `userPreferencesSaveUserPreferencePost()` */
  static readonly UserPreferencesSaveUserPreferencePostPath = '/user-preferences/save-user-preference';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userPreferencesSaveUserPreferencePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userPreferencesSaveUserPreferencePost$Plain$Response(params?: UserPreferencesSaveUserPreferencePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UserPreferenceResponse>> {
    return userPreferencesSaveUserPreferencePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userPreferencesSaveUserPreferencePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userPreferencesSaveUserPreferencePost$Plain(params?: UserPreferencesSaveUserPreferencePost$Plain$Params, context?: HttpContext): Observable<UserPreferenceResponse> {
    return this.userPreferencesSaveUserPreferencePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserPreferenceResponse>): UserPreferenceResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userPreferencesSaveUserPreferencePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userPreferencesSaveUserPreferencePost$Json$Response(params?: UserPreferencesSaveUserPreferencePost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<UserPreferenceResponse>> {
    return userPreferencesSaveUserPreferencePost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userPreferencesSaveUserPreferencePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userPreferencesSaveUserPreferencePost$Json(params?: UserPreferencesSaveUserPreferencePost$Json$Params, context?: HttpContext): Observable<UserPreferenceResponse> {
    return this.userPreferencesSaveUserPreferencePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserPreferenceResponse>): UserPreferenceResponse => r.body)
    );
  }

  /** Path part for operation `userPreferencesUpdateUserPreferencePut()` */
  static readonly UserPreferencesUpdateUserPreferencePutPath = '/user-preferences/update-user-preference';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userPreferencesUpdateUserPreferencePut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userPreferencesUpdateUserPreferencePut$Plain$Response(params?: UserPreferencesUpdateUserPreferencePut$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UserPreferenceResponse>> {
    return userPreferencesUpdateUserPreferencePut$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userPreferencesUpdateUserPreferencePut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userPreferencesUpdateUserPreferencePut$Plain(params?: UserPreferencesUpdateUserPreferencePut$Plain$Params, context?: HttpContext): Observable<UserPreferenceResponse> {
    return this.userPreferencesUpdateUserPreferencePut$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserPreferenceResponse>): UserPreferenceResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userPreferencesUpdateUserPreferencePut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userPreferencesUpdateUserPreferencePut$Json$Response(params?: UserPreferencesUpdateUserPreferencePut$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<UserPreferenceResponse>> {
    return userPreferencesUpdateUserPreferencePut$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userPreferencesUpdateUserPreferencePut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userPreferencesUpdateUserPreferencePut$Json(params?: UserPreferencesUpdateUserPreferencePut$Json$Params, context?: HttpContext): Observable<UserPreferenceResponse> {
    return this.userPreferencesUpdateUserPreferencePut$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserPreferenceResponse>): UserPreferenceResponse => r.body)
    );
  }

  /** Path part for operation `userPreferencesUserPreferenceKeyUserPreferenceNamePost()` */
  static readonly UserPreferencesUserPreferenceKeyUserPreferenceNamePostPath = '/user-preferences/{userPreferenceKey}/{userPreferenceName}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userPreferencesUserPreferenceKeyUserPreferenceNamePost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  userPreferencesUserPreferenceKeyUserPreferenceNamePost$Plain$Response(params: UserPreferencesUserPreferenceKeyUserPreferenceNamePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UserPreferenceResponse>> {
    return userPreferencesUserPreferenceKeyUserPreferenceNamePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userPreferencesUserPreferenceKeyUserPreferenceNamePost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userPreferencesUserPreferenceKeyUserPreferenceNamePost$Plain(params: UserPreferencesUserPreferenceKeyUserPreferenceNamePost$Plain$Params, context?: HttpContext): Observable<UserPreferenceResponse> {
    return this.userPreferencesUserPreferenceKeyUserPreferenceNamePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserPreferenceResponse>): UserPreferenceResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userPreferencesUserPreferenceKeyUserPreferenceNamePost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  userPreferencesUserPreferenceKeyUserPreferenceNamePost$Json$Response(params: UserPreferencesUserPreferenceKeyUserPreferenceNamePost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<UserPreferenceResponse>> {
    return userPreferencesUserPreferenceKeyUserPreferenceNamePost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userPreferencesUserPreferenceKeyUserPreferenceNamePost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userPreferencesUserPreferenceKeyUserPreferenceNamePost$Json(params: UserPreferencesUserPreferenceKeyUserPreferenceNamePost$Json$Params, context?: HttpContext): Observable<UserPreferenceResponse> {
    return this.userPreferencesUserPreferenceKeyUserPreferenceNamePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserPreferenceResponse>): UserPreferenceResponse => r.body)
    );
  }

  /** Path part for operation `userPreferencesSetDefaultUserPreferenceIdUserPreferenceKeyPost()` */
  static readonly UserPreferencesSetDefaultUserPreferenceIdUserPreferenceKeyPostPath = '/user-preferences/set-default/{userPreferenceId}/{userPreferenceKey}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userPreferencesSetDefaultUserPreferenceIdUserPreferenceKeyPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  userPreferencesSetDefaultUserPreferenceIdUserPreferenceKeyPost$Plain$Response(params: UserPreferencesSetDefaultUserPreferenceIdUserPreferenceKeyPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UserPreferenceResponse>> {
    return userPreferencesSetDefaultUserPreferenceIdUserPreferenceKeyPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userPreferencesSetDefaultUserPreferenceIdUserPreferenceKeyPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userPreferencesSetDefaultUserPreferenceIdUserPreferenceKeyPost$Plain(params: UserPreferencesSetDefaultUserPreferenceIdUserPreferenceKeyPost$Plain$Params, context?: HttpContext): Observable<UserPreferenceResponse> {
    return this.userPreferencesSetDefaultUserPreferenceIdUserPreferenceKeyPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserPreferenceResponse>): UserPreferenceResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userPreferencesSetDefaultUserPreferenceIdUserPreferenceKeyPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  userPreferencesSetDefaultUserPreferenceIdUserPreferenceKeyPost$Json$Response(params: UserPreferencesSetDefaultUserPreferenceIdUserPreferenceKeyPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<UserPreferenceResponse>> {
    return userPreferencesSetDefaultUserPreferenceIdUserPreferenceKeyPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userPreferencesSetDefaultUserPreferenceIdUserPreferenceKeyPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userPreferencesSetDefaultUserPreferenceIdUserPreferenceKeyPost$Json(params: UserPreferencesSetDefaultUserPreferenceIdUserPreferenceKeyPost$Json$Params, context?: HttpContext): Observable<UserPreferenceResponse> {
    return this.userPreferencesSetDefaultUserPreferenceIdUserPreferenceKeyPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserPreferenceResponse>): UserPreferenceResponse => r.body)
    );
  }

  /** Path part for operation `userPreferencesUserPreferenceIdDelete()` */
  static readonly UserPreferencesUserPreferenceIdDeletePath = '/user-preferences/{userPreferenceId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userPreferencesUserPreferenceIdDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  userPreferencesUserPreferenceIdDelete$Plain$Response(params: UserPreferencesUserPreferenceIdDelete$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UserPreferenceResponse>> {
    return userPreferencesUserPreferenceIdDelete$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userPreferencesUserPreferenceIdDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userPreferencesUserPreferenceIdDelete$Plain(params: UserPreferencesUserPreferenceIdDelete$Plain$Params, context?: HttpContext): Observable<UserPreferenceResponse> {
    return this.userPreferencesUserPreferenceIdDelete$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserPreferenceResponse>): UserPreferenceResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userPreferencesUserPreferenceIdDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  userPreferencesUserPreferenceIdDelete$Json$Response(params: UserPreferencesUserPreferenceIdDelete$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<UserPreferenceResponse>> {
    return userPreferencesUserPreferenceIdDelete$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userPreferencesUserPreferenceIdDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userPreferencesUserPreferenceIdDelete$Json(params: UserPreferencesUserPreferenceIdDelete$Json$Params, context?: HttpContext): Observable<UserPreferenceResponse> {
    return this.userPreferencesUserPreferenceIdDelete$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserPreferenceResponse>): UserPreferenceResponse => r.body)
    );
  }

}
