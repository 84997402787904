import { Component, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { GridActionsPositionManager } from '../../shared/grid-actions-position-manager';
import { BptElnGridRowActionsComponent } from '../../shared/bpt-eln-grid-row-actions.component';
import { BptGridComponent, BptGridMode, BptGridRowActionClickEvent, BptGridRowActionConfiguration, BptRowActionElement, ColumnDefinition } from 'bpt-ui-library/bpt-grid';
import { ClientStateService } from 'services/client-state.service';
import { ActivatedRoute } from '@angular/router';
import { LabItemsService } from '../../lab-items.service';
import { ActivityInputType, ExperimentWorkflowState, LabItemsInstrument } from '../../../../api/models';
import { UnsubscribeAll } from '../../../../shared/rx-js-helpers';
import { LabItemWisePermissions } from '../../shared/lab-items-feature-manager';
import { BaseComponent } from '../../../../base/base.component';
import { ClientState } from '../../../../app.states';
import { LabItemsInstrumentTableOptions } from '../lab-items-instrument/lab-items-instrument-table-options';
import { BehaviorSubject } from 'rxjs';
import { UserService } from '../../../../services/user.service';
@Component({
  selector: 'app-lab-items-removed-instrument',
  templateUrl: './lab-items-removed-instrument.component.html',
  styleUrls: ['./lab-items-removed-instrument.component.scss']
})
export class LabItemsRemovedInstrumentComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() titleOfTable = $localize`:@@LabItemsInstrumentsTableTitle:Instruments`;
  @Input()
  subHeading = $localize`:@@labItemsRemovedInstrumentsSubHeading:Please click on the restore icon from the table to restore removed instruments`;
  labItemsRemovedInstrumentDataSource: LabItemsInstrument[] = [];
  @ViewChild('labItemsRemovedInstrumentGrid') labItemsRemovedInstrumentGrid?: BptGridComponent;

  public readonly gridActionsPositionManager = new GridActionsPositionManager(
    'app-lab-items-removed-instrument',
    BptElnGridRowActionsComponent.RowActionsWidth
  );

  labItemsInstrumentId = 'labItemsRemovedInstrument';
  columnDefinitions: ColumnDefinition[] = [];
  rowActions: BptRowActionElement[] = [];
  gridMode = BptGridMode.dataView;
  containsRemovedRows = false;
  gridActions!: BptGridRowActionConfiguration;
  displayScrollButtons = true;
  wasEmpty = false;
  public styleRootVariables: string = '';

  constructor(
    public readonly clientStateService: ClientStateService,
    public readonly activatedRoute: ActivatedRoute,
    private readonly labItemsService: LabItemsService,
    private  readonly userService: UserService
  ) {
    super(clientStateService, activatedRoute);
    this.columnDefinitions = LabItemsInstrumentTableOptions.GetColumnDefinitions(
      this.labItemsService.latestLabItemsPermissions
    );
    this.refreshDataSource();
  }

  ngOnInit(): void {
    this.watchRemovedInstrumentRefreshNotification();
    this.watchInstrumentRestoredNotification();
    this.evaluateAccessibilityForRowActions();
    this.addGridActions();
  }

  ngOnDestroy(): void {
    UnsubscribeAll(this.activeSubscriptions);
  }

  onGridReady() {
    this.setValidationStyles();
  }

  loadAuditHistoryDialog() {
    this.labItemsService.loadAuditHistoryDialog(ActivityInputType.InstrumentDetails);
  }

  private refreshDataSource(): void {
    const removedInstruments = this.labItemsService.getLabItemsRemovedInstruments();
    removedInstruments.forEach((instrument) => {
      this.labItemsService.unpackModifiableDataValues<LabItemsInstrument>(
        instrument,
        instrument.tableData,
        this.columnDefinitions,
        ActivityInputType.InstrumentDetails
      );
    });
    this.labItemsRemovedInstrumentDataSource = [...removedInstruments];
    this.labItemsRemovedInstrumentGrid?.gridApi?.setGridOption('rowData', this.labItemsRemovedInstrumentDataSource);
    this.labItemsRemovedInstrumentGrid?.gridApi.autoSizeAllColumns();
  }

  private readonly setValidationStyles = (): void => {
    this.columnDefinitions?.forEach((columnDefinition: ColumnDefinition) => {
      if (columnDefinition.editable) {
        columnDefinition.cellClassRules = this.labItemsService.getCssRules(
          this.labItemsRemovedInstrumentDataSource
        );
      }
      this.labItemsRemovedInstrumentGrid?.updateColumnDefinitions(columnDefinition);
    });
  };

  private watchRemovedInstrumentRefreshNotification() {
    this.activeSubscriptions.push(
      this.labItemsService.instrumentShouldRefresh.subscribe({
        next: () => this.refreshDataSource()
      })
    );
  }

  private watchInstrumentRestoredNotification() {
    this.activeSubscriptions.push(
      this.labItemsService.InstrumentRestored.subscribe({
        next: () => this.refreshDataSource()
      })
    );
  }

  private evaluatePermissions(): LabItemWisePermissions {
    const features = this.clientStateService.getFeatureFlags(ClientState.EXPERIMENT_ACTIVITIES);
    this.labItemsService.featuresByClientState = features;
    return this.labItemsService.evaluateUserPermissionsOnLabItems();
  }

  private addGridActions() {
    this.rowActions = this.getRowActionItems();
    const actionsSubject: BehaviorSubject<BptRowActionElement[]> = new BehaviorSubject(this.rowActions);

    this.gridActions = {
      actions: actionsSubject
    };
  }

  public getRowActionItems(): BptRowActionElement[] {
    return [
      {
        id: 'lab-items-instrument-restore-row',
        enabled: this.evaluatePermissions()[ActivityInputType.InstrumentDetails]['eln-restore-lab-item'] && !this.userService.hasOnlyReviewerRights(),
        styleClass: 'far icon-restore-icon',
        click: (e: BptGridRowActionClickEvent) => this.labItemsService.restoreHandler[ActivityInputType.InstrumentDetails](e.params.data),
        tooltip: $localize`:@@RestoreItem:Restore item`
      }
    ];
  }

  public evaluateAccessibilityForRowActions(): void {
    this.activeSubscriptions.push(this.labItemsService.LabItemsFeatureAccessChange.subscribe((permissions: any) => {
      this.rowActions[0].enabled = permissions.instrumentDetails['eln-restore-lab-item']
    })
    )
  }

  @HostListener('window:resize', ['$event'])
  onResize(_event: any) {
    if (this.labItemsRemovedInstrumentGrid) {
      this.gridActionsPositionManager.updatePosition(
        this.labItemsRemovedInstrumentGrid.gridApi
      );
    }
  }
}
