/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ActivityNodesResponse } from '../models/activity-nodes-response';
import { ActivityOutputChromatographyResultSet } from '../models/activity-output-chromatography-result-set';
import { Collaborator } from '../models/collaborator';
import { CrossReferenceDetailsResponse } from '../models/cross-reference-details-response';
import { experimentNodesByIdExperimentIdGet$Json } from '../fn/experiment/experiment-nodes-by-id-experiment-id-get-json';
import { ExperimentNodesByIdExperimentIdGet$Json$Params } from '../fn/experiment/experiment-nodes-by-id-experiment-id-get-json';
import { experimentNodesByIdExperimentIdGet$Plain } from '../fn/experiment/experiment-nodes-by-id-experiment-id-get-plain';
import { ExperimentNodesByIdExperimentIdGet$Plain$Params } from '../fn/experiment/experiment-nodes-by-id-experiment-id-get-plain';
import { experimentNodesCrossReferencesExperimentIdGet$Json } from '../fn/experiment/experiment-nodes-cross-references-experiment-id-get-json';
import { ExperimentNodesCrossReferencesExperimentIdGet$Json$Params } from '../fn/experiment/experiment-nodes-cross-references-experiment-id-get-json';
import { experimentNodesCrossReferencesExperimentIdGet$Plain } from '../fn/experiment/experiment-nodes-cross-references-experiment-id-get-plain';
import { ExperimentNodesCrossReferencesExperimentIdGet$Plain$Params } from '../fn/experiment/experiment-nodes-cross-references-experiment-id-get-plain';
import { experimentNodesExperimentIdActivityNodesActivityIdGet$Json } from '../fn/experiment/experiment-nodes-experiment-id-activity-nodes-activity-id-get-json';
import { ExperimentNodesExperimentIdActivityNodesActivityIdGet$Json$Params } from '../fn/experiment/experiment-nodes-experiment-id-activity-nodes-activity-id-get-json';
import { experimentNodesExperimentIdActivityNodesActivityIdGet$Plain } from '../fn/experiment/experiment-nodes-experiment-id-activity-nodes-activity-id-get-plain';
import { ExperimentNodesExperimentIdActivityNodesActivityIdGet$Plain$Params } from '../fn/experiment/experiment-nodes-experiment-id-activity-nodes-activity-id-get-plain';
import { experimentNodesExperimentIdAmICollaboratorGet$Json } from '../fn/experiment/experiment-nodes-experiment-id-am-i-collaborator-get-json';
import { ExperimentNodesExperimentIdAmICollaboratorGet$Json$Params } from '../fn/experiment/experiment-nodes-experiment-id-am-i-collaborator-get-json';
import { experimentNodesExperimentIdAmICollaboratorGet$Plain } from '../fn/experiment/experiment-nodes-experiment-id-am-i-collaborator-get-plain';
import { ExperimentNodesExperimentIdAmICollaboratorGet$Plain$Params } from '../fn/experiment/experiment-nodes-experiment-id-am-i-collaborator-get-plain';
import { experimentNodesExperimentIdChromatographyResultSetsResultSetInstanceIdGet$Json } from '../fn/experiment/experiment-nodes-experiment-id-chromatography-result-sets-result-set-instance-id-get-json';
import { ExperimentNodesExperimentIdChromatographyResultSetsResultSetInstanceIdGet$Json$Params } from '../fn/experiment/experiment-nodes-experiment-id-chromatography-result-sets-result-set-instance-id-get-json';
import { experimentNodesExperimentIdChromatographyResultSetsResultSetInstanceIdGet$Plain } from '../fn/experiment/experiment-nodes-experiment-id-chromatography-result-sets-result-set-instance-id-get-plain';
import { ExperimentNodesExperimentIdChromatographyResultSetsResultSetInstanceIdGet$Plain$Params } from '../fn/experiment/experiment-nodes-experiment-id-chromatography-result-sets-result-set-instance-id-get-plain';
import { experimentNodesExperimentIdChromatographyResultSetsResultSetInstanceIdResultIdsGet$Json } from '../fn/experiment/experiment-nodes-experiment-id-chromatography-result-sets-result-set-instance-id-result-ids-get-json';
import { ExperimentNodesExperimentIdChromatographyResultSetsResultSetInstanceIdResultIdsGet$Json$Params } from '../fn/experiment/experiment-nodes-experiment-id-chromatography-result-sets-result-set-instance-id-result-ids-get-json';
import { experimentNodesExperimentIdChromatographyResultSetsResultSetInstanceIdResultIdsGet$Plain } from '../fn/experiment/experiment-nodes-experiment-id-chromatography-result-sets-result-set-instance-id-result-ids-get-plain';
import { ExperimentNodesExperimentIdChromatographyResultSetsResultSetInstanceIdResultIdsGet$Plain$Params } from '../fn/experiment/experiment-nodes-experiment-id-chromatography-result-sets-result-set-instance-id-result-ids-get-plain';
import { experimentNodesExperimentIdCollaboratorsGet$Json } from '../fn/experiment/experiment-nodes-experiment-id-collaborators-get-json';
import { ExperimentNodesExperimentIdCollaboratorsGet$Json$Params } from '../fn/experiment/experiment-nodes-experiment-id-collaborators-get-json';
import { experimentNodesExperimentIdCollaboratorsGet$Plain } from '../fn/experiment/experiment-nodes-experiment-id-collaborators-get-plain';
import { ExperimentNodesExperimentIdCollaboratorsGet$Plain$Params } from '../fn/experiment/experiment-nodes-experiment-id-collaborators-get-plain';
import { experimentNodesExperimentNumberGet$Json } from '../fn/experiment/experiment-nodes-experiment-number-get-json';
import { ExperimentNodesExperimentNumberGet$Json$Params } from '../fn/experiment/experiment-nodes-experiment-number-get-json';
import { experimentNodesExperimentNumberGet$Plain } from '../fn/experiment/experiment-nodes-experiment-number-get-plain';
import { ExperimentNodesExperimentNumberGet$Plain$Params } from '../fn/experiment/experiment-nodes-experiment-number-get-plain';
import { experimentNodesSummaryExperimentNumberActivityActivityIdGet$Json } from '../fn/experiment/experiment-nodes-summary-experiment-number-activity-activity-id-get-json';
import { ExperimentNodesSummaryExperimentNumberActivityActivityIdGet$Json$Params } from '../fn/experiment/experiment-nodes-summary-experiment-number-activity-activity-id-get-json';
import { experimentNodesSummaryExperimentNumberActivityActivityIdGet$Plain } from '../fn/experiment/experiment-nodes-summary-experiment-number-activity-activity-id-get-plain';
import { ExperimentNodesSummaryExperimentNumberActivityActivityIdGet$Plain$Params } from '../fn/experiment/experiment-nodes-summary-experiment-number-activity-activity-id-get-plain';
import { experimentNodesSummaryExperimentNumberGet$Json } from '../fn/experiment/experiment-nodes-summary-experiment-number-get-json';
import { ExperimentNodesSummaryExperimentNumberGet$Json$Params } from '../fn/experiment/experiment-nodes-summary-experiment-number-get-json';
import { experimentNodesSummaryExperimentNumberGet$Plain } from '../fn/experiment/experiment-nodes-summary-experiment-number-get-plain';
import { ExperimentNodesSummaryExperimentNumberGet$Plain$Params } from '../fn/experiment/experiment-nodes-summary-experiment-number-get-plain';
import { ExperimentResponse } from '../models/experiment-response';

@Injectable({ providedIn: 'root' })
export class ExperimentService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `experimentNodesExperimentNumberGet()` */
  static readonly ExperimentNodesExperimentNumberGetPath = '/experiment-nodes/{experimentNumber}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentNodesExperimentNumberGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentNodesExperimentNumberGet$Plain$Response(params: ExperimentNodesExperimentNumberGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ExperimentResponse>> {
    return experimentNodesExperimentNumberGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentNodesExperimentNumberGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentNodesExperimentNumberGet$Plain(params: ExperimentNodesExperimentNumberGet$Plain$Params, context?: HttpContext): Observable<ExperimentResponse> {
    return this.experimentNodesExperimentNumberGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExperimentResponse>): ExperimentResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentNodesExperimentNumberGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentNodesExperimentNumberGet$Json$Response(params: ExperimentNodesExperimentNumberGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ExperimentResponse>> {
    return experimentNodesExperimentNumberGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentNodesExperimentNumberGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentNodesExperimentNumberGet$Json(params: ExperimentNodesExperimentNumberGet$Json$Params, context?: HttpContext): Observable<ExperimentResponse> {
    return this.experimentNodesExperimentNumberGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExperimentResponse>): ExperimentResponse => r.body)
    );
  }

  /** Path part for operation `experimentNodesSummaryExperimentNumberGet()` */
  static readonly ExperimentNodesSummaryExperimentNumberGetPath = '/experiment-nodes/summary/{experimentNumber}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentNodesSummaryExperimentNumberGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentNodesSummaryExperimentNumberGet$Plain$Response(params: ExperimentNodesSummaryExperimentNumberGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ExperimentResponse>> {
    return experimentNodesSummaryExperimentNumberGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentNodesSummaryExperimentNumberGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentNodesSummaryExperimentNumberGet$Plain(params: ExperimentNodesSummaryExperimentNumberGet$Plain$Params, context?: HttpContext): Observable<ExperimentResponse> {
    return this.experimentNodesSummaryExperimentNumberGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExperimentResponse>): ExperimentResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentNodesSummaryExperimentNumberGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentNodesSummaryExperimentNumberGet$Json$Response(params: ExperimentNodesSummaryExperimentNumberGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ExperimentResponse>> {
    return experimentNodesSummaryExperimentNumberGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentNodesSummaryExperimentNumberGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentNodesSummaryExperimentNumberGet$Json(params: ExperimentNodesSummaryExperimentNumberGet$Json$Params, context?: HttpContext): Observable<ExperimentResponse> {
    return this.experimentNodesSummaryExperimentNumberGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExperimentResponse>): ExperimentResponse => r.body)
    );
  }

  /** Path part for operation `experimentNodesSummaryExperimentNumberActivityActivityIdGet()` */
  static readonly ExperimentNodesSummaryExperimentNumberActivityActivityIdGetPath = '/experiment-nodes/summary/{experimentNumber}/activity/{activityId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentNodesSummaryExperimentNumberActivityActivityIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentNodesSummaryExperimentNumberActivityActivityIdGet$Plain$Response(params: ExperimentNodesSummaryExperimentNumberActivityActivityIdGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ExperimentResponse>> {
    return experimentNodesSummaryExperimentNumberActivityActivityIdGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentNodesSummaryExperimentNumberActivityActivityIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentNodesSummaryExperimentNumberActivityActivityIdGet$Plain(params: ExperimentNodesSummaryExperimentNumberActivityActivityIdGet$Plain$Params, context?: HttpContext): Observable<ExperimentResponse> {
    return this.experimentNodesSummaryExperimentNumberActivityActivityIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExperimentResponse>): ExperimentResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentNodesSummaryExperimentNumberActivityActivityIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentNodesSummaryExperimentNumberActivityActivityIdGet$Json$Response(params: ExperimentNodesSummaryExperimentNumberActivityActivityIdGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ExperimentResponse>> {
    return experimentNodesSummaryExperimentNumberActivityActivityIdGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentNodesSummaryExperimentNumberActivityActivityIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentNodesSummaryExperimentNumberActivityActivityIdGet$Json(params: ExperimentNodesSummaryExperimentNumberActivityActivityIdGet$Json$Params, context?: HttpContext): Observable<ExperimentResponse> {
    return this.experimentNodesSummaryExperimentNumberActivityActivityIdGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExperimentResponse>): ExperimentResponse => r.body)
    );
  }

  /** Path part for operation `experimentNodesExperimentIdActivityNodesActivityIdGet()` */
  static readonly ExperimentNodesExperimentIdActivityNodesActivityIdGetPath = '/experiment-nodes/{experimentId}/activity-nodes/{activityId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentNodesExperimentIdActivityNodesActivityIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentNodesExperimentIdActivityNodesActivityIdGet$Plain$Response(params: ExperimentNodesExperimentIdActivityNodesActivityIdGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ActivityNodesResponse>> {
    return experimentNodesExperimentIdActivityNodesActivityIdGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentNodesExperimentIdActivityNodesActivityIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentNodesExperimentIdActivityNodesActivityIdGet$Plain(params: ExperimentNodesExperimentIdActivityNodesActivityIdGet$Plain$Params, context?: HttpContext): Observable<ActivityNodesResponse> {
    return this.experimentNodesExperimentIdActivityNodesActivityIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ActivityNodesResponse>): ActivityNodesResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentNodesExperimentIdActivityNodesActivityIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentNodesExperimentIdActivityNodesActivityIdGet$Json$Response(params: ExperimentNodesExperimentIdActivityNodesActivityIdGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ActivityNodesResponse>> {
    return experimentNodesExperimentIdActivityNodesActivityIdGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentNodesExperimentIdActivityNodesActivityIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentNodesExperimentIdActivityNodesActivityIdGet$Json(params: ExperimentNodesExperimentIdActivityNodesActivityIdGet$Json$Params, context?: HttpContext): Observable<ActivityNodesResponse> {
    return this.experimentNodesExperimentIdActivityNodesActivityIdGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ActivityNodesResponse>): ActivityNodesResponse => r.body)
    );
  }

  /** Path part for operation `experimentNodesByIdExperimentIdGet()` */
  static readonly ExperimentNodesByIdExperimentIdGetPath = '/experiment-nodes/by-id/{experimentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentNodesByIdExperimentIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentNodesByIdExperimentIdGet$Plain$Response(params: ExperimentNodesByIdExperimentIdGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ExperimentResponse>> {
    return experimentNodesByIdExperimentIdGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentNodesByIdExperimentIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentNodesByIdExperimentIdGet$Plain(params: ExperimentNodesByIdExperimentIdGet$Plain$Params, context?: HttpContext): Observable<ExperimentResponse> {
    return this.experimentNodesByIdExperimentIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExperimentResponse>): ExperimentResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentNodesByIdExperimentIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentNodesByIdExperimentIdGet$Json$Response(params: ExperimentNodesByIdExperimentIdGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ExperimentResponse>> {
    return experimentNodesByIdExperimentIdGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentNodesByIdExperimentIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentNodesByIdExperimentIdGet$Json(params: ExperimentNodesByIdExperimentIdGet$Json$Params, context?: HttpContext): Observable<ExperimentResponse> {
    return this.experimentNodesByIdExperimentIdGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExperimentResponse>): ExperimentResponse => r.body)
    );
  }

  /** Path part for operation `experimentNodesExperimentIdAmICollaboratorGet()` */
  static readonly ExperimentNodesExperimentIdAmICollaboratorGetPath = '/experiment-nodes/{experimentId}/am-i-collaborator';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentNodesExperimentIdAmICollaboratorGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentNodesExperimentIdAmICollaboratorGet$Plain$Response(params: ExperimentNodesExperimentIdAmICollaboratorGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return experimentNodesExperimentIdAmICollaboratorGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentNodesExperimentIdAmICollaboratorGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentNodesExperimentIdAmICollaboratorGet$Plain(params: ExperimentNodesExperimentIdAmICollaboratorGet$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.experimentNodesExperimentIdAmICollaboratorGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentNodesExperimentIdAmICollaboratorGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentNodesExperimentIdAmICollaboratorGet$Json$Response(params: ExperimentNodesExperimentIdAmICollaboratorGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return experimentNodesExperimentIdAmICollaboratorGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentNodesExperimentIdAmICollaboratorGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentNodesExperimentIdAmICollaboratorGet$Json(params: ExperimentNodesExperimentIdAmICollaboratorGet$Json$Params, context?: HttpContext): Observable<boolean> {
    return this.experimentNodesExperimentIdAmICollaboratorGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `experimentNodesExperimentIdCollaboratorsGet()` */
  static readonly ExperimentNodesExperimentIdCollaboratorsGetPath = '/experiment-nodes/{experimentId}/collaborators';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentNodesExperimentIdCollaboratorsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentNodesExperimentIdCollaboratorsGet$Plain$Response(params: ExperimentNodesExperimentIdCollaboratorsGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Collaborator>>> {
    return experimentNodesExperimentIdCollaboratorsGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentNodesExperimentIdCollaboratorsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentNodesExperimentIdCollaboratorsGet$Plain(params: ExperimentNodesExperimentIdCollaboratorsGet$Plain$Params, context?: HttpContext): Observable<Array<Collaborator>> {
    return this.experimentNodesExperimentIdCollaboratorsGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Collaborator>>): Array<Collaborator> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentNodesExperimentIdCollaboratorsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentNodesExperimentIdCollaboratorsGet$Json$Response(params: ExperimentNodesExperimentIdCollaboratorsGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Collaborator>>> {
    return experimentNodesExperimentIdCollaboratorsGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentNodesExperimentIdCollaboratorsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentNodesExperimentIdCollaboratorsGet$Json(params: ExperimentNodesExperimentIdCollaboratorsGet$Json$Params, context?: HttpContext): Observable<Array<Collaborator>> {
    return this.experimentNodesExperimentIdCollaboratorsGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Collaborator>>): Array<Collaborator> => r.body)
    );
  }

  /** Path part for operation `experimentNodesExperimentIdChromatographyResultSetsResultSetInstanceIdGet()` */
  static readonly ExperimentNodesExperimentIdChromatographyResultSetsResultSetInstanceIdGetPath = '/experiment-nodes/{experimentId}/chromatography-result-sets/{resultSetInstanceId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentNodesExperimentIdChromatographyResultSetsResultSetInstanceIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentNodesExperimentIdChromatographyResultSetsResultSetInstanceIdGet$Plain$Response(params: ExperimentNodesExperimentIdChromatographyResultSetsResultSetInstanceIdGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ActivityOutputChromatographyResultSet>> {
    return experimentNodesExperimentIdChromatographyResultSetsResultSetInstanceIdGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentNodesExperimentIdChromatographyResultSetsResultSetInstanceIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentNodesExperimentIdChromatographyResultSetsResultSetInstanceIdGet$Plain(params: ExperimentNodesExperimentIdChromatographyResultSetsResultSetInstanceIdGet$Plain$Params, context?: HttpContext): Observable<ActivityOutputChromatographyResultSet> {
    return this.experimentNodesExperimentIdChromatographyResultSetsResultSetInstanceIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ActivityOutputChromatographyResultSet>): ActivityOutputChromatographyResultSet => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentNodesExperimentIdChromatographyResultSetsResultSetInstanceIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentNodesExperimentIdChromatographyResultSetsResultSetInstanceIdGet$Json$Response(params: ExperimentNodesExperimentIdChromatographyResultSetsResultSetInstanceIdGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ActivityOutputChromatographyResultSet>> {
    return experimentNodesExperimentIdChromatographyResultSetsResultSetInstanceIdGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentNodesExperimentIdChromatographyResultSetsResultSetInstanceIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentNodesExperimentIdChromatographyResultSetsResultSetInstanceIdGet$Json(params: ExperimentNodesExperimentIdChromatographyResultSetsResultSetInstanceIdGet$Json$Params, context?: HttpContext): Observable<ActivityOutputChromatographyResultSet> {
    return this.experimentNodesExperimentIdChromatographyResultSetsResultSetInstanceIdGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ActivityOutputChromatographyResultSet>): ActivityOutputChromatographyResultSet => r.body)
    );
  }

  /** Path part for operation `experimentNodesExperimentIdChromatographyResultSetsResultSetInstanceIdResultIdsGet()` */
  static readonly ExperimentNodesExperimentIdChromatographyResultSetsResultSetInstanceIdResultIdsGetPath = '/experiment-nodes/{experimentId}/chromatography-result-sets/{resultSetInstanceId}/result-ids';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentNodesExperimentIdChromatographyResultSetsResultSetInstanceIdResultIdsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentNodesExperimentIdChromatographyResultSetsResultSetInstanceIdResultIdsGet$Plain$Response(params: ExperimentNodesExperimentIdChromatographyResultSetsResultSetInstanceIdResultIdsGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return experimentNodesExperimentIdChromatographyResultSetsResultSetInstanceIdResultIdsGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentNodesExperimentIdChromatographyResultSetsResultSetInstanceIdResultIdsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentNodesExperimentIdChromatographyResultSetsResultSetInstanceIdResultIdsGet$Plain(params: ExperimentNodesExperimentIdChromatographyResultSetsResultSetInstanceIdResultIdsGet$Plain$Params, context?: HttpContext): Observable<Array<string>> {
    return this.experimentNodesExperimentIdChromatographyResultSetsResultSetInstanceIdResultIdsGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentNodesExperimentIdChromatographyResultSetsResultSetInstanceIdResultIdsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentNodesExperimentIdChromatographyResultSetsResultSetInstanceIdResultIdsGet$Json$Response(params: ExperimentNodesExperimentIdChromatographyResultSetsResultSetInstanceIdResultIdsGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return experimentNodesExperimentIdChromatographyResultSetsResultSetInstanceIdResultIdsGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentNodesExperimentIdChromatographyResultSetsResultSetInstanceIdResultIdsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentNodesExperimentIdChromatographyResultSetsResultSetInstanceIdResultIdsGet$Json(params: ExperimentNodesExperimentIdChromatographyResultSetsResultSetInstanceIdResultIdsGet$Json$Params, context?: HttpContext): Observable<Array<string>> {
    return this.experimentNodesExperimentIdChromatographyResultSetsResultSetInstanceIdResultIdsGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `experimentNodesCrossReferencesExperimentIdGet()` */
  static readonly ExperimentNodesCrossReferencesExperimentIdGetPath = '/experiment-nodes/cross-references/{experimentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentNodesCrossReferencesExperimentIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentNodesCrossReferencesExperimentIdGet$Plain$Response(params: ExperimentNodesCrossReferencesExperimentIdGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CrossReferenceDetailsResponse>> {
    return experimentNodesCrossReferencesExperimentIdGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentNodesCrossReferencesExperimentIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentNodesCrossReferencesExperimentIdGet$Plain(params: ExperimentNodesCrossReferencesExperimentIdGet$Plain$Params, context?: HttpContext): Observable<CrossReferenceDetailsResponse> {
    return this.experimentNodesCrossReferencesExperimentIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CrossReferenceDetailsResponse>): CrossReferenceDetailsResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentNodesCrossReferencesExperimentIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentNodesCrossReferencesExperimentIdGet$Json$Response(params: ExperimentNodesCrossReferencesExperimentIdGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<CrossReferenceDetailsResponse>> {
    return experimentNodesCrossReferencesExperimentIdGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentNodesCrossReferencesExperimentIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentNodesCrossReferencesExperimentIdGet$Json(params: ExperimentNodesCrossReferencesExperimentIdGet$Json$Params, context?: HttpContext): Observable<CrossReferenceDetailsResponse> {
    return this.experimentNodesCrossReferencesExperimentIdGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CrossReferenceDetailsResponse>): CrossReferenceDetailsResponse => r.body)
    );
  }

}
