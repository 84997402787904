<app-toast></app-toast>
<bpt-progress-spinner
  i18n-message="@@loadingHistory"
  message="History Loading..."
  [useOverlay]="true"
  *ngIf="isHistoryLoading"
>
</bpt-progress-spinner>
<app-data-form *ngIf="resultSetForm" [form]="resultSetForm"></app-data-form>
<div class="resultset-table-header"  *ngIf="resultSet">
  <span id="empower-grid-title{{resultSet.chromatographyDataId}}" class="resultset-table-title large">{{ tableTitle }}</span>
  <div>
    <span>
    <a
      class="audit-Icon"
      id="empower-grid-iconRemove{{ resultSet.chromatographyDataId }}"
      (click)="performResultsetOperation('remove')"
      [ngClass]="{ disabled: outputEmpowerService.disableEmpowerOperations }"
    >
      <span class="fa fa-trash-alt"></span>
      <span class="ml-1 audit" i18n="@@removeResultSet">Remove Result Set</span>
    </a>
  </span>
  <span>
    <a
      class="audit-Icon"
      id="empower-grid-iconRefresh{{ resultSet.chromatographyDataId }}"
      (click)="performResultsetOperation('refresh')"
      [ngClass]="{ disabled: outputEmpowerService.disableEmpowerOperations }"
    >
      <span
        class="fa fa-sync-alt"
        [ngClass]="{ 'pending-img': isGridRefreshing && outputEmpowerService.isRefreshEvent }"
      ></span>
      <span class="ml-1 audit" i18n="@@Refresh">Refresh</span>
    </a>
  </span>
    <a class="audit-Icon" id="empower-grid-iconAuditHistoryShow{{resultSet.chromatographyDataId}}" (click)="loadHistoryDialog()">
      <span i18n-pTooltip="@@viewHistory" class="fa fa-history"
        ><span class="ml-1 audit" i18n="@@History">History</span></span
      >
    </a>
  </div>
</div>
<div class="resultset-table" *ngIf="resultSet">
  <span class="resultset-table-title" i18n="@@Peaks">Peaks</span>
  <bpt-grid
    #PeaksGrid
    [dataSource]="peaksTable"
    [useJsJodaTypes]="true"
    [gridMode]="gridMode"
    [columnDefinitions]="peaksColumnDefinitions"
    [allowRowAdd]="false"
    [readOnly]="true"
    groupDisplayType="custom"
    [masterDetail]="false"
    gridId="empower-peaks-grid"
  ></bpt-grid>
  <span class="resultset-table-title" i18n="@@Results">Results</span>
  <bpt-grid
    #ResultsGrid
    [dataSource]="resultsTable"
    [useJsJodaTypes]="true"
    [gridMode]="gridMode"
    [columnDefinitions]="resultsColumnDefinitions"
    [allowRowAdd]="false"
    [readOnly]="true"
    groupDisplayType="custom"
    [masterDetail]="false"
    gridId="empower-results-grid"
  ></bpt-grid>
</div>
