import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ExperimentService } from "../../experiment/services/experiment.service";
import { UserService } from "../../services/user.service";
import { EMPTY, Observable } from "rxjs";
import { ExperimentWorkflowState } from "../../api/models";
import { MessageService } from "primeng/api";

const allowedApis = [
  "get-templates",
  "search-experiment-index"
];

const allowedModules = [
  "users",
  "experiment-flags",
  "experiment-events",
  "user-preferences"
];

const allowedServices = [
  "files",
  "audit",
  "internal-comments",
  "datapackage",
]

const excludedExperimentEventApis = [
  "add-template",
  "set-variable",
  "change-node-title",
  "change-node-order",
  "add-recipe",
];

@Injectable({
  providedIn: 'root'
})
export class UserRoleInterceptor implements HttpInterceptor {
  constructor(
    private readonly experimentService: ExperimentService,
    private readonly userService: UserService,
    private readonly messageService: MessageService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isRestrictedState = this.isUserRestricted();
    const isPermittedUrl = this.isUrlPermitted(req.url);
    if(req.method === 'POST' && isRestrictedState) {
      if(isPermittedUrl) {
        return next.handle(req);
      }
      this.messageService.add({
        key: 'notification',
        severity: 'error',
        id: 'actionNotAllowedForUserRole',
        summary: $localize`:@@userNotAllowedToPerformAction:You are not allowed to perform this action`,
        sticky: false
      });
      return EMPTY;
    }
    return next.handle(req);
  }

  isUrlPermitted(url: string):boolean {
    const splitUrl = url.split('/');
    const targetApi = splitUrl[splitUrl.length-1];
    for(const service of allowedServices) {
      if(splitUrl[2].includes(service)) {
        return true;
      }
    }
    if(splitUrl.includes('experiment-events') && excludedExperimentEventApis.includes(targetApi)) {
      return false;
    }
    for(let i = 1; i < splitUrl.length; i++) {
      if(allowedModules.includes(splitUrl[i])) {
        return true;
      }
    }
    return allowedApis.includes(targetApi);
  }

  isUserRestricted(): boolean {
    const isExperimentInReview = this.experimentService.currentExperiment?.workflowState === ExperimentWorkflowState.InReview;
    return this.userService.hasOnlySupervisorRights() && isExperimentInReview;
  }
}