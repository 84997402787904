import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * Any events to update audit history for data source update.
 */
@Injectable({
  providedIn: 'root'
})
export class AuditHistoryEventsService {
  public readonly updateAuditHistoryDataSource = new Subject<void>();
}