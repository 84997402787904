/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ActivityGroupTemplate } from '../models/activity-group-template';
import { ActivityTemplate } from '../models/activity-template';
import { FormTemplate } from '../models/form-template';
import { ModuleTemplate } from '../models/module-template';
import { NoContentNotification } from '../models/no-content-notification';
import { TableTemplate } from '../models/table-template';
import { TemplateAddResponse } from '../models/template-add-response';
import { TemplateGetLatestResponse } from '../models/template-get-latest-response';
import { templatesCreateTemplatePost$Json } from '../fn/templates/templates-create-template-post-json';
import { TemplatesCreateTemplatePost$Json$Params } from '../fn/templates/templates-create-template-post-json';
import { templatesCreateTemplatePost$Plain } from '../fn/templates/templates-create-template-post-plain';
import { TemplatesCreateTemplatePost$Plain$Params } from '../fn/templates/templates-create-template-post-plain';
import { templatesGetLatestGet$Json } from '../fn/templates/templates-get-latest-get-json';
import { TemplatesGetLatestGet$Json$Params } from '../fn/templates/templates-get-latest-get-json';
import { templatesGetLatestGet$Plain } from '../fn/templates/templates-get-latest-get-plain';
import { TemplatesGetLatestGet$Plain$Params } from '../fn/templates/templates-get-latest-get-plain';
import { templatesGetTemplatesPost$Json } from '../fn/templates/templates-get-templates-post-json';
import { TemplatesGetTemplatesPost$Json$Params } from '../fn/templates/templates-get-templates-post-json';
import { templatesGetTemplatesPost$Plain } from '../fn/templates/templates-get-templates-post-plain';
import { TemplatesGetTemplatesPost$Plain$Params } from '../fn/templates/templates-get-templates-post-plain';
import { templatesIdActivityMinorVersionUpdatePost$Json } from '../fn/templates/templates-id-activity-minor-version-update-post-json';
import { TemplatesIdActivityMinorVersionUpdatePost$Json$Params } from '../fn/templates/templates-id-activity-minor-version-update-post-json';
import { templatesIdActivityMinorVersionUpdatePost$Plain } from '../fn/templates/templates-id-activity-minor-version-update-post-plain';
import { TemplatesIdActivityMinorVersionUpdatePost$Plain$Params } from '../fn/templates/templates-id-activity-minor-version-update-post-plain';
import { templatesIdCancelTemplatePost$Json } from '../fn/templates/templates-id-cancel-template-post-json';
import { TemplatesIdCancelTemplatePost$Json$Params } from '../fn/templates/templates-id-cancel-template-post-json';
import { templatesIdCancelTemplatePost$Plain } from '../fn/templates/templates-id-cancel-template-post-plain';
import { TemplatesIdCancelTemplatePost$Plain$Params } from '../fn/templates/templates-id-cancel-template-post-plain';
import { templatesIdGet$Json } from '../fn/templates/templates-id-get-json';
import { TemplatesIdGet$Json$Params } from '../fn/templates/templates-id-get-json';
import { templatesIdGet$Plain } from '../fn/templates/templates-id-get-plain';
import { TemplatesIdGet$Plain$Params } from '../fn/templates/templates-id-get-plain';
import { templatesIdIsCorePut$Json } from '../fn/templates/templates-id-is-core-put-json';
import { TemplatesIdIsCorePut$Json$Params } from '../fn/templates/templates-id-is-core-put-json';
import { templatesIdIsCorePut$Plain } from '../fn/templates/templates-id-is-core-put-plain';
import { TemplatesIdIsCorePut$Plain$Params } from '../fn/templates/templates-id-is-core-put-plain';
import { templatesIdMakeAvailablePost$Json } from '../fn/templates/templates-id-make-available-post-json';
import { TemplatesIdMakeAvailablePost$Json$Params } from '../fn/templates/templates-id-make-available-post-json';
import { templatesIdMakeAvailablePost$Plain } from '../fn/templates/templates-id-make-available-post-plain';
import { TemplatesIdMakeAvailablePost$Plain$Params } from '../fn/templates/templates-id-make-available-post-plain';
import { templatesIdPublishMajorVersionPost$Json } from '../fn/templates/templates-id-publish-major-version-post-json';
import { TemplatesIdPublishMajorVersionPost$Json$Params } from '../fn/templates/templates-id-publish-major-version-post-json';
import { templatesIdPublishMajorVersionPost$Plain } from '../fn/templates/templates-id-publish-major-version-post-plain';
import { TemplatesIdPublishMajorVersionPost$Plain$Params } from '../fn/templates/templates-id-publish-major-version-post-plain';
import { templatesIdRestoreToDraftPost$Json } from '../fn/templates/templates-id-restore-to-draft-post-json';
import { TemplatesIdRestoreToDraftPost$Json$Params } from '../fn/templates/templates-id-restore-to-draft-post-json';
import { templatesIdRestoreToDraftPost$Plain } from '../fn/templates/templates-id-restore-to-draft-post-plain';
import { TemplatesIdRestoreToDraftPost$Plain$Params } from '../fn/templates/templates-id-restore-to-draft-post-plain';
import { templatesIdRetireTemplatePost$Json } from '../fn/templates/templates-id-retire-template-post-json';
import { TemplatesIdRetireTemplatePost$Json$Params } from '../fn/templates/templates-id-retire-template-post-json';
import { templatesIdRetireTemplatePost$Plain } from '../fn/templates/templates-id-retire-template-post-plain';
import { TemplatesIdRetireTemplatePost$Plain$Params } from '../fn/templates/templates-id-retire-template-post-plain';
import { templatesIdRulesPut$Json } from '../fn/templates/templates-id-rules-put-json';
import { TemplatesIdRulesPut$Json$Params } from '../fn/templates/templates-id-rules-put-json';
import { templatesIdRulesPut$Plain } from '../fn/templates/templates-id-rules-put-plain';
import { TemplatesIdRulesPut$Plain$Params } from '../fn/templates/templates-id-rules-put-plain';
import { templatesIdSaveAsCopyPost$Json } from '../fn/templates/templates-id-save-as-copy-post-json';
import { TemplatesIdSaveAsCopyPost$Json$Params } from '../fn/templates/templates-id-save-as-copy-post-json';
import { templatesIdSaveAsCopyPost$Plain } from '../fn/templates/templates-id-save-as-copy-post-plain';
import { TemplatesIdSaveAsCopyPost$Plain$Params } from '../fn/templates/templates-id-save-as-copy-post-plain';
import { templatesIdSaveAsNewDraftMinorPost$Json } from '../fn/templates/templates-id-save-as-new-draft-minor-post-json';
import { TemplatesIdSaveAsNewDraftMinorPost$Json$Params } from '../fn/templates/templates-id-save-as-new-draft-minor-post-json';
import { templatesIdSaveAsNewDraftMinorPost$Plain } from '../fn/templates/templates-id-save-as-new-draft-minor-post-plain';
import { TemplatesIdSaveAsNewDraftMinorPost$Plain$Params } from '../fn/templates/templates-id-save-as-new-draft-minor-post-plain';
import { templatesMajorVersionNumberGet$Json } from '../fn/templates/templates-major-version-number-get-json';
import { TemplatesMajorVersionNumberGet$Json$Params } from '../fn/templates/templates-major-version-number-get-json';
import { templatesMajorVersionNumberGet$Plain } from '../fn/templates/templates-major-version-number-get-plain';
import { TemplatesMajorVersionNumberGet$Plain$Params } from '../fn/templates/templates-major-version-number-get-plain';
import { templatesNonCancelledTemplatesTemplateNumberGet$Json } from '../fn/templates/templates-non-cancelled-templates-template-number-get-json';
import { TemplatesNonCancelledTemplatesTemplateNumberGet$Json$Params } from '../fn/templates/templates-non-cancelled-templates-template-number-get-json';
import { templatesNonCancelledTemplatesTemplateNumberGet$Plain } from '../fn/templates/templates-non-cancelled-templates-template-number-get-plain';
import { TemplatesNonCancelledTemplatesTemplateNumberGet$Plain$Params } from '../fn/templates/templates-non-cancelled-templates-template-number-get-plain';
import { templatesSaveActivityTemplatePost$Json } from '../fn/templates/templates-save-activity-template-post-json';
import { TemplatesSaveActivityTemplatePost$Json$Params } from '../fn/templates/templates-save-activity-template-post-json';
import { templatesSaveActivityTemplatePost$Plain } from '../fn/templates/templates-save-activity-template-post-plain';
import { TemplatesSaveActivityTemplatePost$Plain$Params } from '../fn/templates/templates-save-activity-template-post-plain';
import { templatesSaveFormTemplatePost$Json } from '../fn/templates/templates-save-form-template-post-json';
import { TemplatesSaveFormTemplatePost$Json$Params } from '../fn/templates/templates-save-form-template-post-json';
import { templatesSaveFormTemplatePost$Plain } from '../fn/templates/templates-save-form-template-post-plain';
import { TemplatesSaveFormTemplatePost$Plain$Params } from '../fn/templates/templates-save-form-template-post-plain';
import { templatesSaveModuleTemplatePost$Json } from '../fn/templates/templates-save-module-template-post-json';
import { TemplatesSaveModuleTemplatePost$Json$Params } from '../fn/templates/templates-save-module-template-post-json';
import { templatesSaveModuleTemplatePost$Plain } from '../fn/templates/templates-save-module-template-post-plain';
import { TemplatesSaveModuleTemplatePost$Plain$Params } from '../fn/templates/templates-save-module-template-post-plain';
import { templatesSaveTableTemplatePost$Json } from '../fn/templates/templates-save-table-template-post-json';
import { TemplatesSaveTableTemplatePost$Json$Params } from '../fn/templates/templates-save-table-template-post-json';
import { templatesSaveTableTemplatePost$Plain } from '../fn/templates/templates-save-table-template-post-plain';
import { TemplatesSaveTableTemplatePost$Plain$Params } from '../fn/templates/templates-save-table-template-post-plain';
import { templatesUpdateFormTemplatePost$Json } from '../fn/templates/templates-update-form-template-post-json';
import { TemplatesUpdateFormTemplatePost$Json$Params } from '../fn/templates/templates-update-form-template-post-json';
import { templatesUpdateFormTemplatePost$Plain } from '../fn/templates/templates-update-form-template-post-plain';
import { TemplatesUpdateFormTemplatePost$Plain$Params } from '../fn/templates/templates-update-form-template-post-plain';
import { templatesUpdateModuleTemplatePost$Json } from '../fn/templates/templates-update-module-template-post-json';
import { TemplatesUpdateModuleTemplatePost$Json$Params } from '../fn/templates/templates-update-module-template-post-json';
import { templatesUpdateModuleTemplatePost$Plain } from '../fn/templates/templates-update-module-template-post-plain';
import { TemplatesUpdateModuleTemplatePost$Plain$Params } from '../fn/templates/templates-update-module-template-post-plain';
import { templatesUpdateTableTemplatePost$Json } from '../fn/templates/templates-update-table-template-post-json';
import { TemplatesUpdateTableTemplatePost$Json$Params } from '../fn/templates/templates-update-table-template-post-json';
import { templatesUpdateTableTemplatePost$Plain } from '../fn/templates/templates-update-table-template-post-plain';
import { TemplatesUpdateTableTemplatePost$Plain$Params } from '../fn/templates/templates-update-table-template-post-plain';
import { templatesUpdateTemplatePost$Json } from '../fn/templates/templates-update-template-post-json';
import { TemplatesUpdateTemplatePost$Json$Params } from '../fn/templates/templates-update-template-post-json';
import { templatesUpdateTemplatePost$Plain } from '../fn/templates/templates-update-template-post-plain';
import { TemplatesUpdateTemplatePost$Plain$Params } from '../fn/templates/templates-update-template-post-plain';

@Injectable({ providedIn: 'root' })
export class TemplatesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `templatesGetLatestGet()` */
  static readonly TemplatesGetLatestGetPath = '/templates/get-latest';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesGetLatestGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  templatesGetLatestGet$Plain$Response(params?: TemplatesGetLatestGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<TemplateGetLatestResponse>> {
    return templatesGetLatestGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `templatesGetLatestGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  templatesGetLatestGet$Plain(params?: TemplatesGetLatestGet$Plain$Params, context?: HttpContext): Observable<TemplateGetLatestResponse> {
    return this.templatesGetLatestGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<TemplateGetLatestResponse>): TemplateGetLatestResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesGetLatestGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  templatesGetLatestGet$Json$Response(params?: TemplatesGetLatestGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<TemplateGetLatestResponse>> {
    return templatesGetLatestGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `templatesGetLatestGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  templatesGetLatestGet$Json(params?: TemplatesGetLatestGet$Json$Params, context?: HttpContext): Observable<TemplateGetLatestResponse> {
    return this.templatesGetLatestGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<TemplateGetLatestResponse>): TemplateGetLatestResponse => r.body)
    );
  }

  /** Path part for operation `templatesIdGet()` */
  static readonly TemplatesIdGetPath = '/templates/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  templatesIdGet$Plain$Response(params: TemplatesIdGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<(ActivityGroupTemplate | ActivityTemplate | ModuleTemplate | FormTemplate | TableTemplate)>> {
    return templatesIdGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `templatesIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  templatesIdGet$Plain(params: TemplatesIdGet$Plain$Params, context?: HttpContext): Observable<(ActivityGroupTemplate | ActivityTemplate | ModuleTemplate | FormTemplate | TableTemplate)> {
    return this.templatesIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<(ActivityGroupTemplate | ActivityTemplate | ModuleTemplate | FormTemplate | TableTemplate)>): (ActivityGroupTemplate | ActivityTemplate | ModuleTemplate | FormTemplate | TableTemplate) => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  templatesIdGet$Json$Response(params: TemplatesIdGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<(ActivityGroupTemplate | ActivityTemplate | ModuleTemplate | FormTemplate | TableTemplate)>> {
    return templatesIdGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `templatesIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  templatesIdGet$Json(params: TemplatesIdGet$Json$Params, context?: HttpContext): Observable<(ActivityGroupTemplate | ActivityTemplate | ModuleTemplate | FormTemplate | TableTemplate)> {
    return this.templatesIdGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<(ActivityGroupTemplate | ActivityTemplate | ModuleTemplate | FormTemplate | TableTemplate)>): (ActivityGroupTemplate | ActivityTemplate | ModuleTemplate | FormTemplate | TableTemplate) => r.body)
    );
  }

  /** Path part for operation `templatesGetTemplatesPost()` */
  static readonly TemplatesGetTemplatesPostPath = '/templates/get-templates';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesGetTemplatesPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesGetTemplatesPost$Plain$Response(params?: TemplatesGetTemplatesPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<(ActivityGroupTemplate | ActivityTemplate | ModuleTemplate | FormTemplate | TableTemplate)>>> {
    return templatesGetTemplatesPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `templatesGetTemplatesPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesGetTemplatesPost$Plain(params?: TemplatesGetTemplatesPost$Plain$Params, context?: HttpContext): Observable<Array<(ActivityGroupTemplate | ActivityTemplate | ModuleTemplate | FormTemplate | TableTemplate)>> {
    return this.templatesGetTemplatesPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<(ActivityGroupTemplate | ActivityTemplate | ModuleTemplate | FormTemplate | TableTemplate)>>): Array<(ActivityGroupTemplate | ActivityTemplate | ModuleTemplate | FormTemplate | TableTemplate)> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesGetTemplatesPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesGetTemplatesPost$Json$Response(params?: TemplatesGetTemplatesPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<(ActivityGroupTemplate | ActivityTemplate | ModuleTemplate | FormTemplate | TableTemplate)>>> {
    return templatesGetTemplatesPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `templatesGetTemplatesPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesGetTemplatesPost$Json(params?: TemplatesGetTemplatesPost$Json$Params, context?: HttpContext): Observable<Array<(ActivityGroupTemplate | ActivityTemplate | ModuleTemplate | FormTemplate | TableTemplate)>> {
    return this.templatesGetTemplatesPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<(ActivityGroupTemplate | ActivityTemplate | ModuleTemplate | FormTemplate | TableTemplate)>>): Array<(ActivityGroupTemplate | ActivityTemplate | ModuleTemplate | FormTemplate | TableTemplate)> => r.body)
    );
  }

  /** Path part for operation `templatesMajorVersionNumberGet()` */
  static readonly TemplatesMajorVersionNumberGetPath = '/templates/major-version/{number}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesMajorVersionNumberGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  templatesMajorVersionNumberGet$Plain$Response(params: TemplatesMajorVersionNumberGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<(ActivityGroupTemplate | ActivityTemplate | ModuleTemplate | FormTemplate | TableTemplate)>>> {
    return templatesMajorVersionNumberGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `templatesMajorVersionNumberGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  templatesMajorVersionNumberGet$Plain(params: TemplatesMajorVersionNumberGet$Plain$Params, context?: HttpContext): Observable<Array<(ActivityGroupTemplate | ActivityTemplate | ModuleTemplate | FormTemplate | TableTemplate)>> {
    return this.templatesMajorVersionNumberGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<(ActivityGroupTemplate | ActivityTemplate | ModuleTemplate | FormTemplate | TableTemplate)>>): Array<(ActivityGroupTemplate | ActivityTemplate | ModuleTemplate | FormTemplate | TableTemplate)> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesMajorVersionNumberGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  templatesMajorVersionNumberGet$Json$Response(params: TemplatesMajorVersionNumberGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<(ActivityGroupTemplate | ActivityTemplate | ModuleTemplate | FormTemplate | TableTemplate)>>> {
    return templatesMajorVersionNumberGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `templatesMajorVersionNumberGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  templatesMajorVersionNumberGet$Json(params: TemplatesMajorVersionNumberGet$Json$Params, context?: HttpContext): Observable<Array<(ActivityGroupTemplate | ActivityTemplate | ModuleTemplate | FormTemplate | TableTemplate)>> {
    return this.templatesMajorVersionNumberGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<(ActivityGroupTemplate | ActivityTemplate | ModuleTemplate | FormTemplate | TableTemplate)>>): Array<(ActivityGroupTemplate | ActivityTemplate | ModuleTemplate | FormTemplate | TableTemplate)> => r.body)
    );
  }

  /** Path part for operation `templatesNonCancelledTemplatesTemplateNumberGet()` */
  static readonly TemplatesNonCancelledTemplatesTemplateNumberGetPath = '/templates/non-cancelled-templates/{templateNumber}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesNonCancelledTemplatesTemplateNumberGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  templatesNonCancelledTemplatesTemplateNumberGet$Plain$Response(params: TemplatesNonCancelledTemplatesTemplateNumberGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<(ActivityGroupTemplate | ActivityTemplate | ModuleTemplate | FormTemplate | TableTemplate)>>> {
    return templatesNonCancelledTemplatesTemplateNumberGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `templatesNonCancelledTemplatesTemplateNumberGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  templatesNonCancelledTemplatesTemplateNumberGet$Plain(params: TemplatesNonCancelledTemplatesTemplateNumberGet$Plain$Params, context?: HttpContext): Observable<Array<(ActivityGroupTemplate | ActivityTemplate | ModuleTemplate | FormTemplate | TableTemplate)>> {
    return this.templatesNonCancelledTemplatesTemplateNumberGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<(ActivityGroupTemplate | ActivityTemplate | ModuleTemplate | FormTemplate | TableTemplate)>>): Array<(ActivityGroupTemplate | ActivityTemplate | ModuleTemplate | FormTemplate | TableTemplate)> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesNonCancelledTemplatesTemplateNumberGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  templatesNonCancelledTemplatesTemplateNumberGet$Json$Response(params: TemplatesNonCancelledTemplatesTemplateNumberGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<(ActivityGroupTemplate | ActivityTemplate | ModuleTemplate | FormTemplate | TableTemplate)>>> {
    return templatesNonCancelledTemplatesTemplateNumberGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `templatesNonCancelledTemplatesTemplateNumberGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  templatesNonCancelledTemplatesTemplateNumberGet$Json(params: TemplatesNonCancelledTemplatesTemplateNumberGet$Json$Params, context?: HttpContext): Observable<Array<(ActivityGroupTemplate | ActivityTemplate | ModuleTemplate | FormTemplate | TableTemplate)>> {
    return this.templatesNonCancelledTemplatesTemplateNumberGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<(ActivityGroupTemplate | ActivityTemplate | ModuleTemplate | FormTemplate | TableTemplate)>>): Array<(ActivityGroupTemplate | ActivityTemplate | ModuleTemplate | FormTemplate | TableTemplate)> => r.body)
    );
  }

  /** Path part for operation `templatesCreateTemplatePost()` */
  static readonly TemplatesCreateTemplatePostPath = '/templates/create-template';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesCreateTemplatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesCreateTemplatePost$Plain$Response(params?: TemplatesCreateTemplatePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<TemplateAddResponse>> {
    return templatesCreateTemplatePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `templatesCreateTemplatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesCreateTemplatePost$Plain(params?: TemplatesCreateTemplatePost$Plain$Params, context?: HttpContext): Observable<TemplateAddResponse> {
    return this.templatesCreateTemplatePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<TemplateAddResponse>): TemplateAddResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesCreateTemplatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesCreateTemplatePost$Json$Response(params?: TemplatesCreateTemplatePost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<TemplateAddResponse>> {
    return templatesCreateTemplatePost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `templatesCreateTemplatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesCreateTemplatePost$Json(params?: TemplatesCreateTemplatePost$Json$Params, context?: HttpContext): Observable<TemplateAddResponse> {
    return this.templatesCreateTemplatePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<TemplateAddResponse>): TemplateAddResponse => r.body)
    );
  }

  /** Path part for operation `templatesSaveActivityTemplatePost()` */
  static readonly TemplatesSaveActivityTemplatePostPath = '/templates/save-activity-template';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesSaveActivityTemplatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesSaveActivityTemplatePost$Plain$Response(params?: TemplatesSaveActivityTemplatePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<TemplateAddResponse>> {
    return templatesSaveActivityTemplatePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `templatesSaveActivityTemplatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesSaveActivityTemplatePost$Plain(params?: TemplatesSaveActivityTemplatePost$Plain$Params, context?: HttpContext): Observable<TemplateAddResponse> {
    return this.templatesSaveActivityTemplatePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<TemplateAddResponse>): TemplateAddResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesSaveActivityTemplatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesSaveActivityTemplatePost$Json$Response(params?: TemplatesSaveActivityTemplatePost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<TemplateAddResponse>> {
    return templatesSaveActivityTemplatePost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `templatesSaveActivityTemplatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesSaveActivityTemplatePost$Json(params?: TemplatesSaveActivityTemplatePost$Json$Params, context?: HttpContext): Observable<TemplateAddResponse> {
    return this.templatesSaveActivityTemplatePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<TemplateAddResponse>): TemplateAddResponse => r.body)
    );
  }

  /** Path part for operation `templatesSaveModuleTemplatePost()` */
  static readonly TemplatesSaveModuleTemplatePostPath = '/templates/save-module-template';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesSaveModuleTemplatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesSaveModuleTemplatePost$Plain$Response(params?: TemplatesSaveModuleTemplatePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<TemplateAddResponse>> {
    return templatesSaveModuleTemplatePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `templatesSaveModuleTemplatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesSaveModuleTemplatePost$Plain(params?: TemplatesSaveModuleTemplatePost$Plain$Params, context?: HttpContext): Observable<TemplateAddResponse> {
    return this.templatesSaveModuleTemplatePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<TemplateAddResponse>): TemplateAddResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesSaveModuleTemplatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesSaveModuleTemplatePost$Json$Response(params?: TemplatesSaveModuleTemplatePost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<TemplateAddResponse>> {
    return templatesSaveModuleTemplatePost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `templatesSaveModuleTemplatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesSaveModuleTemplatePost$Json(params?: TemplatesSaveModuleTemplatePost$Json$Params, context?: HttpContext): Observable<TemplateAddResponse> {
    return this.templatesSaveModuleTemplatePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<TemplateAddResponse>): TemplateAddResponse => r.body)
    );
  }

  /** Path part for operation `templatesUpdateModuleTemplatePost()` */
  static readonly TemplatesUpdateModuleTemplatePostPath = '/templates/update-module-template';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesUpdateModuleTemplatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesUpdateModuleTemplatePost$Plain$Response(params?: TemplatesUpdateModuleTemplatePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<TemplateAddResponse>> {
    return templatesUpdateModuleTemplatePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `templatesUpdateModuleTemplatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesUpdateModuleTemplatePost$Plain(params?: TemplatesUpdateModuleTemplatePost$Plain$Params, context?: HttpContext): Observable<TemplateAddResponse> {
    return this.templatesUpdateModuleTemplatePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<TemplateAddResponse>): TemplateAddResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesUpdateModuleTemplatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesUpdateModuleTemplatePost$Json$Response(params?: TemplatesUpdateModuleTemplatePost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<TemplateAddResponse>> {
    return templatesUpdateModuleTemplatePost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `templatesUpdateModuleTemplatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesUpdateModuleTemplatePost$Json(params?: TemplatesUpdateModuleTemplatePost$Json$Params, context?: HttpContext): Observable<TemplateAddResponse> {
    return this.templatesUpdateModuleTemplatePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<TemplateAddResponse>): TemplateAddResponse => r.body)
    );
  }

  /** Path part for operation `templatesSaveFormTemplatePost()` */
  static readonly TemplatesSaveFormTemplatePostPath = '/templates/save-form-template';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesSaveFormTemplatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesSaveFormTemplatePost$Plain$Response(params?: TemplatesSaveFormTemplatePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<TemplateAddResponse>> {
    return templatesSaveFormTemplatePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `templatesSaveFormTemplatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesSaveFormTemplatePost$Plain(params?: TemplatesSaveFormTemplatePost$Plain$Params, context?: HttpContext): Observable<TemplateAddResponse> {
    return this.templatesSaveFormTemplatePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<TemplateAddResponse>): TemplateAddResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesSaveFormTemplatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesSaveFormTemplatePost$Json$Response(params?: TemplatesSaveFormTemplatePost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<TemplateAddResponse>> {
    return templatesSaveFormTemplatePost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `templatesSaveFormTemplatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesSaveFormTemplatePost$Json(params?: TemplatesSaveFormTemplatePost$Json$Params, context?: HttpContext): Observable<TemplateAddResponse> {
    return this.templatesSaveFormTemplatePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<TemplateAddResponse>): TemplateAddResponse => r.body)
    );
  }

  /** Path part for operation `templatesUpdateFormTemplatePost()` */
  static readonly TemplatesUpdateFormTemplatePostPath = '/templates/update-form-template';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesUpdateFormTemplatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesUpdateFormTemplatePost$Plain$Response(params?: TemplatesUpdateFormTemplatePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<TemplateAddResponse>> {
    return templatesUpdateFormTemplatePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `templatesUpdateFormTemplatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesUpdateFormTemplatePost$Plain(params?: TemplatesUpdateFormTemplatePost$Plain$Params, context?: HttpContext): Observable<TemplateAddResponse> {
    return this.templatesUpdateFormTemplatePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<TemplateAddResponse>): TemplateAddResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesUpdateFormTemplatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesUpdateFormTemplatePost$Json$Response(params?: TemplatesUpdateFormTemplatePost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<TemplateAddResponse>> {
    return templatesUpdateFormTemplatePost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `templatesUpdateFormTemplatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesUpdateFormTemplatePost$Json(params?: TemplatesUpdateFormTemplatePost$Json$Params, context?: HttpContext): Observable<TemplateAddResponse> {
    return this.templatesUpdateFormTemplatePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<TemplateAddResponse>): TemplateAddResponse => r.body)
    );
  }

  /** Path part for operation `templatesSaveTableTemplatePost()` */
  static readonly TemplatesSaveTableTemplatePostPath = '/templates/save-table-template';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesSaveTableTemplatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesSaveTableTemplatePost$Plain$Response(params?: TemplatesSaveTableTemplatePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<TemplateAddResponse>> {
    return templatesSaveTableTemplatePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `templatesSaveTableTemplatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesSaveTableTemplatePost$Plain(params?: TemplatesSaveTableTemplatePost$Plain$Params, context?: HttpContext): Observable<TemplateAddResponse> {
    return this.templatesSaveTableTemplatePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<TemplateAddResponse>): TemplateAddResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesSaveTableTemplatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesSaveTableTemplatePost$Json$Response(params?: TemplatesSaveTableTemplatePost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<TemplateAddResponse>> {
    return templatesSaveTableTemplatePost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `templatesSaveTableTemplatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesSaveTableTemplatePost$Json(params?: TemplatesSaveTableTemplatePost$Json$Params, context?: HttpContext): Observable<TemplateAddResponse> {
    return this.templatesSaveTableTemplatePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<TemplateAddResponse>): TemplateAddResponse => r.body)
    );
  }

  /** Path part for operation `templatesUpdateTableTemplatePost()` */
  static readonly TemplatesUpdateTableTemplatePostPath = '/templates/update-table-template';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesUpdateTableTemplatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesUpdateTableTemplatePost$Plain$Response(params?: TemplatesUpdateTableTemplatePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<TemplateAddResponse>> {
    return templatesUpdateTableTemplatePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `templatesUpdateTableTemplatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesUpdateTableTemplatePost$Plain(params?: TemplatesUpdateTableTemplatePost$Plain$Params, context?: HttpContext): Observable<TemplateAddResponse> {
    return this.templatesUpdateTableTemplatePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<TemplateAddResponse>): TemplateAddResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesUpdateTableTemplatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesUpdateTableTemplatePost$Json$Response(params?: TemplatesUpdateTableTemplatePost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<TemplateAddResponse>> {
    return templatesUpdateTableTemplatePost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `templatesUpdateTableTemplatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesUpdateTableTemplatePost$Json(params?: TemplatesUpdateTableTemplatePost$Json$Params, context?: HttpContext): Observable<TemplateAddResponse> {
    return this.templatesUpdateTableTemplatePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<TemplateAddResponse>): TemplateAddResponse => r.body)
    );
  }

  /** Path part for operation `templatesUpdateTemplatePost()` */
  static readonly TemplatesUpdateTemplatePostPath = '/templates/update-template';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesUpdateTemplatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesUpdateTemplatePost$Plain$Response(params?: TemplatesUpdateTemplatePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<TemplateAddResponse>> {
    return templatesUpdateTemplatePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `templatesUpdateTemplatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesUpdateTemplatePost$Plain(params?: TemplatesUpdateTemplatePost$Plain$Params, context?: HttpContext): Observable<TemplateAddResponse> {
    return this.templatesUpdateTemplatePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<TemplateAddResponse>): TemplateAddResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesUpdateTemplatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesUpdateTemplatePost$Json$Response(params?: TemplatesUpdateTemplatePost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<TemplateAddResponse>> {
    return templatesUpdateTemplatePost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `templatesUpdateTemplatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesUpdateTemplatePost$Json(params?: TemplatesUpdateTemplatePost$Json$Params, context?: HttpContext): Observable<TemplateAddResponse> {
    return this.templatesUpdateTemplatePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<TemplateAddResponse>): TemplateAddResponse => r.body)
    );
  }

  /** Path part for operation `templatesIdActivityMinorVersionUpdatePost()` */
  static readonly TemplatesIdActivityMinorVersionUpdatePostPath = '/templates/{id}/activity/minor-version-update';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesIdActivityMinorVersionUpdatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdActivityMinorVersionUpdatePost$Plain$Response(params: TemplatesIdActivityMinorVersionUpdatePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<NoContentNotification>> {
    return templatesIdActivityMinorVersionUpdatePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `templatesIdActivityMinorVersionUpdatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdActivityMinorVersionUpdatePost$Plain(params: TemplatesIdActivityMinorVersionUpdatePost$Plain$Params, context?: HttpContext): Observable<NoContentNotification> {
    return this.templatesIdActivityMinorVersionUpdatePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<NoContentNotification>): NoContentNotification => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesIdActivityMinorVersionUpdatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdActivityMinorVersionUpdatePost$Json$Response(params: TemplatesIdActivityMinorVersionUpdatePost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<NoContentNotification>> {
    return templatesIdActivityMinorVersionUpdatePost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `templatesIdActivityMinorVersionUpdatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdActivityMinorVersionUpdatePost$Json(params: TemplatesIdActivityMinorVersionUpdatePost$Json$Params, context?: HttpContext): Observable<NoContentNotification> {
    return this.templatesIdActivityMinorVersionUpdatePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<NoContentNotification>): NoContentNotification => r.body)
    );
  }

  /** Path part for operation `templatesIdMakeAvailablePost()` */
  static readonly TemplatesIdMakeAvailablePostPath = '/templates/{id}/make-available';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesIdMakeAvailablePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdMakeAvailablePost$Plain$Response(params: TemplatesIdMakeAvailablePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<NoContentNotification>> {
    return templatesIdMakeAvailablePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `templatesIdMakeAvailablePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdMakeAvailablePost$Plain(params: TemplatesIdMakeAvailablePost$Plain$Params, context?: HttpContext): Observable<NoContentNotification> {
    return this.templatesIdMakeAvailablePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<NoContentNotification>): NoContentNotification => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesIdMakeAvailablePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdMakeAvailablePost$Json$Response(params: TemplatesIdMakeAvailablePost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<NoContentNotification>> {
    return templatesIdMakeAvailablePost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `templatesIdMakeAvailablePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdMakeAvailablePost$Json(params: TemplatesIdMakeAvailablePost$Json$Params, context?: HttpContext): Observable<NoContentNotification> {
    return this.templatesIdMakeAvailablePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<NoContentNotification>): NoContentNotification => r.body)
    );
  }

  /** Path part for operation `templatesIdPublishMajorVersionPost()` */
  static readonly TemplatesIdPublishMajorVersionPostPath = '/templates/{id}/publish-major-version';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesIdPublishMajorVersionPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdPublishMajorVersionPost$Plain$Response(params: TemplatesIdPublishMajorVersionPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<TemplateAddResponse>> {
    return templatesIdPublishMajorVersionPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `templatesIdPublishMajorVersionPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdPublishMajorVersionPost$Plain(params: TemplatesIdPublishMajorVersionPost$Plain$Params, context?: HttpContext): Observable<TemplateAddResponse> {
    return this.templatesIdPublishMajorVersionPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<TemplateAddResponse>): TemplateAddResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesIdPublishMajorVersionPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdPublishMajorVersionPost$Json$Response(params: TemplatesIdPublishMajorVersionPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<TemplateAddResponse>> {
    return templatesIdPublishMajorVersionPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `templatesIdPublishMajorVersionPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdPublishMajorVersionPost$Json(params: TemplatesIdPublishMajorVersionPost$Json$Params, context?: HttpContext): Observable<TemplateAddResponse> {
    return this.templatesIdPublishMajorVersionPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<TemplateAddResponse>): TemplateAddResponse => r.body)
    );
  }

  /** Path part for operation `templatesIdSaveAsCopyPost()` */
  static readonly TemplatesIdSaveAsCopyPostPath = '/templates/{id}/save-as-copy';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesIdSaveAsCopyPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdSaveAsCopyPost$Plain$Response(params: TemplatesIdSaveAsCopyPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<TemplateAddResponse>> {
    return templatesIdSaveAsCopyPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `templatesIdSaveAsCopyPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdSaveAsCopyPost$Plain(params: TemplatesIdSaveAsCopyPost$Plain$Params, context?: HttpContext): Observable<TemplateAddResponse> {
    return this.templatesIdSaveAsCopyPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<TemplateAddResponse>): TemplateAddResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesIdSaveAsCopyPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdSaveAsCopyPost$Json$Response(params: TemplatesIdSaveAsCopyPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<TemplateAddResponse>> {
    return templatesIdSaveAsCopyPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `templatesIdSaveAsCopyPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdSaveAsCopyPost$Json(params: TemplatesIdSaveAsCopyPost$Json$Params, context?: HttpContext): Observable<TemplateAddResponse> {
    return this.templatesIdSaveAsCopyPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<TemplateAddResponse>): TemplateAddResponse => r.body)
    );
  }

  /** Path part for operation `templatesIdSaveAsNewDraftMinorPost()` */
  static readonly TemplatesIdSaveAsNewDraftMinorPostPath = '/templates/{id}/save-as-new-draft-minor';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesIdSaveAsNewDraftMinorPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdSaveAsNewDraftMinorPost$Plain$Response(params: TemplatesIdSaveAsNewDraftMinorPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<TemplateAddResponse>> {
    return templatesIdSaveAsNewDraftMinorPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `templatesIdSaveAsNewDraftMinorPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdSaveAsNewDraftMinorPost$Plain(params: TemplatesIdSaveAsNewDraftMinorPost$Plain$Params, context?: HttpContext): Observable<TemplateAddResponse> {
    return this.templatesIdSaveAsNewDraftMinorPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<TemplateAddResponse>): TemplateAddResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesIdSaveAsNewDraftMinorPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdSaveAsNewDraftMinorPost$Json$Response(params: TemplatesIdSaveAsNewDraftMinorPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<TemplateAddResponse>> {
    return templatesIdSaveAsNewDraftMinorPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `templatesIdSaveAsNewDraftMinorPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdSaveAsNewDraftMinorPost$Json(params: TemplatesIdSaveAsNewDraftMinorPost$Json$Params, context?: HttpContext): Observable<TemplateAddResponse> {
    return this.templatesIdSaveAsNewDraftMinorPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<TemplateAddResponse>): TemplateAddResponse => r.body)
    );
  }

  /** Path part for operation `templatesIdCancelTemplatePost()` */
  static readonly TemplatesIdCancelTemplatePostPath = '/templates/{id}/cancel-template';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesIdCancelTemplatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdCancelTemplatePost$Plain$Response(params: TemplatesIdCancelTemplatePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<NoContentNotification>> {
    return templatesIdCancelTemplatePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `templatesIdCancelTemplatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdCancelTemplatePost$Plain(params: TemplatesIdCancelTemplatePost$Plain$Params, context?: HttpContext): Observable<NoContentNotification> {
    return this.templatesIdCancelTemplatePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<NoContentNotification>): NoContentNotification => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesIdCancelTemplatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdCancelTemplatePost$Json$Response(params: TemplatesIdCancelTemplatePost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<NoContentNotification>> {
    return templatesIdCancelTemplatePost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `templatesIdCancelTemplatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdCancelTemplatePost$Json(params: TemplatesIdCancelTemplatePost$Json$Params, context?: HttpContext): Observable<NoContentNotification> {
    return this.templatesIdCancelTemplatePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<NoContentNotification>): NoContentNotification => r.body)
    );
  }

  /** Path part for operation `templatesIdRestoreToDraftPost()` */
  static readonly TemplatesIdRestoreToDraftPostPath = '/templates/{id}/restore-to-draft';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesIdRestoreToDraftPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdRestoreToDraftPost$Plain$Response(params: TemplatesIdRestoreToDraftPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<NoContentNotification>> {
    return templatesIdRestoreToDraftPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `templatesIdRestoreToDraftPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdRestoreToDraftPost$Plain(params: TemplatesIdRestoreToDraftPost$Plain$Params, context?: HttpContext): Observable<NoContentNotification> {
    return this.templatesIdRestoreToDraftPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<NoContentNotification>): NoContentNotification => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesIdRestoreToDraftPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdRestoreToDraftPost$Json$Response(params: TemplatesIdRestoreToDraftPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<NoContentNotification>> {
    return templatesIdRestoreToDraftPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `templatesIdRestoreToDraftPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdRestoreToDraftPost$Json(params: TemplatesIdRestoreToDraftPost$Json$Params, context?: HttpContext): Observable<NoContentNotification> {
    return this.templatesIdRestoreToDraftPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<NoContentNotification>): NoContentNotification => r.body)
    );
  }

  /** Path part for operation `templatesIdRetireTemplatePost()` */
  static readonly TemplatesIdRetireTemplatePostPath = '/templates/{id}/retire-template';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesIdRetireTemplatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdRetireTemplatePost$Plain$Response(params: TemplatesIdRetireTemplatePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<NoContentNotification>> {
    return templatesIdRetireTemplatePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `templatesIdRetireTemplatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdRetireTemplatePost$Plain(params: TemplatesIdRetireTemplatePost$Plain$Params, context?: HttpContext): Observable<NoContentNotification> {
    return this.templatesIdRetireTemplatePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<NoContentNotification>): NoContentNotification => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesIdRetireTemplatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdRetireTemplatePost$Json$Response(params: TemplatesIdRetireTemplatePost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<NoContentNotification>> {
    return templatesIdRetireTemplatePost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `templatesIdRetireTemplatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdRetireTemplatePost$Json(params: TemplatesIdRetireTemplatePost$Json$Params, context?: HttpContext): Observable<NoContentNotification> {
    return this.templatesIdRetireTemplatePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<NoContentNotification>): NoContentNotification => r.body)
    );
  }

  /** Path part for operation `templatesIdRulesPut()` */
  static readonly TemplatesIdRulesPutPath = '/templates/{id}/rules';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesIdRulesPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdRulesPut$Plain$Response(params: TemplatesIdRulesPut$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<NoContentNotification>> {
    return templatesIdRulesPut$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `templatesIdRulesPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdRulesPut$Plain(params: TemplatesIdRulesPut$Plain$Params, context?: HttpContext): Observable<NoContentNotification> {
    return this.templatesIdRulesPut$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<NoContentNotification>): NoContentNotification => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesIdRulesPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdRulesPut$Json$Response(params: TemplatesIdRulesPut$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<NoContentNotification>> {
    return templatesIdRulesPut$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `templatesIdRulesPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdRulesPut$Json(params: TemplatesIdRulesPut$Json$Params, context?: HttpContext): Observable<NoContentNotification> {
    return this.templatesIdRulesPut$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<NoContentNotification>): NoContentNotification => r.body)
    );
  }

  /** Path part for operation `templatesIdIsCorePut()` */
  static readonly TemplatesIdIsCorePutPath = '/templates/{id}/is-core';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesIdIsCorePut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdIsCorePut$Plain$Response(params: TemplatesIdIsCorePut$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<NoContentNotification>> {
    return templatesIdIsCorePut$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `templatesIdIsCorePut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdIsCorePut$Plain(params: TemplatesIdIsCorePut$Plain$Params, context?: HttpContext): Observable<NoContentNotification> {
    return this.templatesIdIsCorePut$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<NoContentNotification>): NoContentNotification => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templatesIdIsCorePut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdIsCorePut$Json$Response(params: TemplatesIdIsCorePut$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<NoContentNotification>> {
    return templatesIdIsCorePut$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `templatesIdIsCorePut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  templatesIdIsCorePut$Json(params: TemplatesIdIsCorePut$Json$Params, context?: HttpContext): Observable<NoContentNotification> {
    return this.templatesIdIsCorePut$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<NoContentNotification>): NoContentNotification => r.body)
    );
  }

}
