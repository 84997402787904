<div *ngIf="(sampleDataSource && sampleDataSource.length > 0 && sampleTableGridSavedPreferences) || containsRemovedRows " class="eln-activity-input-sample module-item module-table">
  <div>
    <div>
      <div class="flex flex-row column-gap-2">
        <span id="eln-sample-aliquot-icon" class="eln-sample-aliquot-icon">{{ sampleAliquots }}
        </span>
        <div class="completion-tracker">
          <app-completion-tracking [completionPercent]="completionPercent"></app-completion-tracking>
        </div>
      </div>
      <bpt-grid
        #Grid
        class="eln-grid"
        *ngIf="reloadGrid"
        ngClass="true"
        gridId="{{ sampleId }}"
        [dataSource]="sampleDataSource"
        [useJsJodaTypes]="true"
        [columnDefinitions]="columnDefinitions"
        [gridMode]="gridMode"
        [allowRowAdd]="false"
        (cellValueChanged)="cellValueChanged($event)"
        (gridReady)="onGridReady()"
        [showAutoSizeButton]="true"
        [showFilterToggleButton]="true"
        [showGridOptionsButton]="false"
        [paginated]="false"
        [suppressAggFuncInHeader]="true"
        [displayScrollButtons]="displayScrollButtons"
        [savedPreferences]="sampleTableGridSavedPreferences"
        (newGridPreferenceAdded)="saveNewPreference($event)"
        (gridPreferenceDeleted)="deletePreference($event)"
        (gridPreferenceUpdated)="updatePreference($event)"
        (gridPreferenceSelectionChanged)="changeDefaultPreference($event)"
        (cellEditStarted)="cellEditStartedEvent($event)"
        (cellEditStopped)="cellEditStoppedEvent($event)"
        [rowActionConfigurations]="gridActions"
        [contextMenu]="getContextMenu()">
        <ng-container class="bpt-grid-toolbar-right-layout">
          <a
            class="table-grid-toolbar-audit-icon"
            id="eln-iconAuditHistoryShow-{{ sampleId }}"
            (click)="loadAuditHistoryDialog()">
            <span
              i18n-pTooltip="@@viewHistory"
              class="fa fa-history"
              pTooltip="View History"
              tooltipPosition="top"><span class="ml-1 audit" i18n="@@History">History</span></span>
          </a>
        </ng-container>
        <ng-container class="bpt-grid-toolbar-right-layout">
          <a
            *ngIf="containsRemovedRows"
            class="bpt-grid-toolbar-item table-grid-toolbar-view-removed-rows-icon sample-table-remove-icon"
            align="right"
            id="eln-iconViewRemovedRowsShow-{{ sampleId }}"
            (click)="viewRemovedRows()"
          >
            <span
              i18n-pTooltip="@@viewRemovedRows"
              class="fa icon-view-row"
              pTooltip="View Removed Rows"
              tooltipPosition="top"
              ><span class="ml-1 viewRemovedRows-iconTxt" i18n="@@viewRemovedRows"
                >View Removed Rows</span
              ></span
            >
          </a>
        </ng-container>
      </bpt-grid>
    </div>
  </div>
  <br />
</div>