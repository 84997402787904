import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ClientStateService } from 'services/client-state.service';
import { AccessibilityTypes } from '../app.states';

@Component({
  selector: 'app-base',
  template: '',
  styleUrls: []
})
export class BaseComponent {
  /** Subscriptions that may need to be unsubscribed from especially upon ngOnDestroy. "Active" doesn't mean anything.  */
  protected activeSubscriptions: Subscription[] = [];

  @Input()
  /**
   * General-purpose readonly state for use by this component.
   *
   * BaseComponent initializes it to ClientStateService.isClientStateReadOnly; if that's not initialized, it initializes it first.
   * See ClientStateService.getClientStateVisibility.
   *
   * Components that override or set this property can use ClientStateService.isClientStateReadOnly to reset it to original value
   * or to selectively get the original value.
  */
  protected get readOnly() {
    return this._readOnly;
  }
  protected set readOnly(value) {
    this._readOnly = value;
  }
  private _readOnly: boolean;

  public unsavedChanges = false;

  /**
   * Clientstate from the route, if any.
   */
  protected clientState: string;
  constructor(
    public clientStateService: ClientStateService,
    public activatedRoute: ActivatedRoute
  ) {
    this.clientState = activatedRoute.snapshot.data?.clientState;
    if (this.clientState) {
      this.clientStateService.isClientStateReadOnly ||= this.clientStateService.getClientStateVisibility(this.clientState) === AccessibilityTypes.ReadOnly;
    } else {
      this.clientStateService.isClientStateReadOnly = false;
    }
    this._readOnly = clientStateService.isClientStateReadOnly;
  }
}
