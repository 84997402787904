<div *ngIf="showElnMenuItem || !isLimsHosted" class="divMenuContainer">
  <bpt-nav-bar *ngIf="draggable"
    class="bpt-nav-bar-reorder"
    [menuItems]="menuItems"
    [showMenuItem]="true"
    [expanded]="expanded"
    applicationName="ELN"
    logo="assets/eln-assets/logomark.png"
    collapseImage="assets/eln-assets/collapse-ic.svg"
    [openMultiple]="true"
    titleText="Eurofins"
    subTitle="ELN"
    [isDraggable]="true"
    (ReorderedNavItems)="drop($event)"
    [draggingEnabled]="draggingEnabled"
  ></bpt-nav-bar>
  <bpt-nav-bar *ngIf="!draggable"
    [menuItems]="menuItems"
    [showMenuItem]="true"
    [expanded]="expanded"
    applicationName="ELN"
    logo="assets/eln-assets/logomark.png"
    collapseImage="assets/eln-assets/collapse-ic.svg"
    [openMultiple]="true"
    titleText="Eurofins"
    subTitle="ELN"
  ></bpt-nav-bar>
</div>
