<div *ngIf="enableInstrumentConnection" class="eln-instrument-connection flex-v-center message-container">
  <p-button
   *ngIf="!isInstrumentConnected"
    styleClass="p-button-text mx-1"
    i18n-label="@@connectToInstrumentTitle"
    label="Connect to Instrument"
    id="eln-instrumentConnection"
    icon="icon-s icon-connection icon-instrument-connection"
    (click)="openInstrumentConnectionModal()"
  ></p-button>

  <p-button
    *ngIf="isInstrumentConnected"
    styleClass="p-button-text mx-1"
    label="{{handleInstrumentLabel()}}"
    pTooltip="{{handleInstrumentLabelToolTip()}}"
    tooltipPosition="bottom"
    ngClass="{{ isPaused ? 'eln-instrument-connection-paused' : '' }}"
    id="eln-connectedInstrument"
    icon="icon-s icon-connection-ic icon-instrument-connection"
    (onClick)="setConfigValue()"
    (click)="handleConnectedInstrumentConnection($event)"
    [disabled]="disablePhMeter"
    [attr.attr-phmeteractivetab]="+isPhMeterConnectedInCurrentTab"
  ></p-button>
  <p-button
    *ngIf="!isInstrumentConnected && lastConnectedInstrumentId"
    styleClass="p-button-text"
    id="eln-lastConnectedInstrumentId"
    icon="icon-s icon-chevron-down"
    class="eln-last-connected-instrument-dropdown"
    (click)="lastConnectedInstrumentModal.toggle($event)"
  ></p-button>
</div>

<p-overlayPanel #configurationModal styleClass="eln-overlay-panel">
<div class="eln-instrument-config-dialog">
  <div class="eln-instrument-configuration">
    <p-button
      class="eln-instrument-config-button"
      styleClass="pr-0 p-button-text"
      i18n-label="@@configuration"
      label="Configuration"
      [disabled]="readOnlyState"
      id="eln-instrument-config-button"
      icon="pi pi-cog">
    </p-button>
    <p-button
      class="eln-instrument-config-pause-button"
      [styleClass]="isPaused ? 'p-button-text eln-instrument-pause-button pr-0' : 'p-button-text pr-0'"
      i18n-label="@@pause"
      label="Pause"
      id="eln-instrument-pause-button"
      icon="icon-m icon-pause"
      [disabled]="readOnlyState"
      (onClick)="pauseOrUnpauseInstrumentConnection()">
    </p-button>
    <p-button
      class="eln-instrument-connection eln-disconnect-button-label"
      styleClass="p-button-text pr-0"
      i18n-label="@@disconnect"
      label="Disconnect"
      id="eln-instrument-disconnect-button"
      icon="icon-s icon-connection icon-instrument-connection"
      (click)="disconnectFromInstrument()">
    </p-button>
  </div>
  <div class="eln-instrument-connected-name">{{connectedInstrumentId}}</div>
  <div class="eln-instrument-selection-config">
    <bpt-dropdown
    #instrumentConfigMethod
    [(ngModel)]="selectedMethod"
    [ngModelOptions]="{ updateOn: 'blur' }"
    [options]="configMethods"
    label="{{instrumentMethodLabel}}"
    [showClear]=false
    [showHeader]=true
    [disabled]="readOnlyState || isPhInstrument"
    id="eln-instrument-config-method"
    ></bpt-dropdown>
    <bpt-dropdown
    #instrumentConfigUnit
    [(ngModel)]="selectedUnit"
    [ngModelOptions]="{ updateOn: 'blur' }"
    [options]="configUnits"
    label="{{instrumentUnitModeLabel}}"
    [showClear]=false
    [showHeader]=true
    [disabled]="readOnlyState"
    id="eln-instrument-config-unit"
    ></bpt-dropdown>
  </div>

  <div class="eln-instrument-connection-config-footer">
    <div class="eln-instrument-config-check">
      <p-checkbox class="eln-checkBox"
        [binary]="true"
        [(ngModel)]="selectedBinaryPushValue"
        (ngModelChange)="updatePushValue($event)"
        label="Push from {{connectedInstrumentId}}"
        i18n-label="@@pushFromInstrument"
        [disabled]="readOnlyState || isPhInstrument"
        id="eln-instrument-config-push-value">
      </p-checkbox>
      <p-checkbox class="eln-checkBox"
       *ngIf="configBlowerVisible && !isPhInstrument"
        [binary]="true"
        [(ngModel)]="selectedBinaryBlowerValue"
        (ngModelChange)="updateBlowerValue($event)"
        label="Blower On"
        i18n-label="@@blowerOn"
        [disabled]="!configBlowerEditable"
        id="eln-instrument-config-push-value">
      </p-checkbox>
    </div>
    <p-button
      class="Submit"
      styleClass="bpt-button-compact eln-ok-button"
      label="OK"
      i18n-label="@@OkButton"
      (onClick)="onOk()"
      (click)="configurationModal.hide()"
      [disabled]="readOnlyState"
      id="eln-instrumentConfigOkButton"
    ></p-button>
  </div>
  </div>
  <div *ngIf = "displaySequentialReadingProgressMessage">{{sequentialReadingMessage}}</div>
</p-overlayPanel>

<p-overlayPanel #lastConnectedInstrumentModal styleClass="eln-overlay-panel eln-last-connected-instrument">
  <div (click)="connectToLastConnectedInstrument()" >{{lastConnectedInstrumentId}}</div>
</p-overlayPanel>
