import { Injectable } from '@angular/core';
import { Message, MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { ElnProgressSpinnerService } from '../../../eln-progress-spinner-module/eln-progress-spinner.service';
import { SpinnerOptions } from '../../../eln-progress-spinner-module/spinner-options.interface';
import { ConfigurationService } from '../../../services/configuration.service';
import { AuthRequest, AuthStatusType } from '../model/auth.model';

@Injectable({
  providedIn: 'root'
})
export class FamsHelperService {
  public static readonly ProgressStatusAlert = new Subject<string>();
  private static readonly SpinnerOptions: { validatingUserProgressMessage: SpinnerOptions } = {
    validatingUserProgressMessage: {
      message: $localize`:@@validatingUser:Validating User...`,
      i18nMessage: `@@validatingUser`
    }
  };

  oidcTokenManager: any;
  master = (window as any).Master;
  authRequest!: AuthRequest;

  constructor(
    private readonly messageService: MessageService,
    private readonly elnProgressSpinnerService: ElnProgressSpinnerService
  ) {
    if (this.master && this.master.oidcTokenManager)
      this.oidcTokenManager = new this.master.oidcTokenManager();
  }

  handleReauthenticationResponse(request: AuthRequest) {
    if (!ConfigurationService.isLimsHosted) {
      request.successCallback({ status: AuthStatusType.Success });
      return;
    }
    if (this.oidcTokenManager) {
      this.authRequest = request;
      console.log('outside reauth call');
      const reauth = this.reauthSuccess.bind(this, request);
      this.oidcTokenManager.reauthenticate(reauth);
    }
  }

  validateLoggedInUserWithCurrentUser(winObj: any, accessToken: string) {
    if (winObj && winObj.jwt_decode && accessToken) {
      var currentUserPuid = winObj.app.puid.toLowerCase();
      var tokenDetails = winObj.jwt_decode(accessToken);
      if (tokenDetails.name) {
        const tokenPuid = tokenDetails.name.split('@')[0].toLowerCase();
        if (tokenPuid === currentUserPuid) return true;
      }
    }
    return false;
  }

  prepareValidationReq(winObj: any) {
    return {
      AuthorizationEndpoint: winObj.app.fams.tokenEndpoint,
      ClientId: winObj.app.fams.clientId,
      GrantType: 'authorization_code',
      Response_Types: 'code',
      Code: winObj.localStorage ? winObj.localStorage.getItem('reauthcode') : '',
      Code_Verifier: winObj.localStorage ? winObj.localStorage.getItem('reauthcode_verifier') : '',
      Redirect_Uri: winObj.app.fams.redirectUrl
    };
  }

  reauthSuccess(request: AuthRequest) {
    const winObj = window as any;
    if (winObj && winObj.app && winObj.app.fams && this.master.NetworkResources) {
      setTimeout(() => {
        this.elnProgressSpinnerService.Show(
          FamsHelperService.SpinnerOptions.validatingUserProgressMessage
        );
        document.body.click();
      },0)
      
      var reqParam = this.prepareValidationReq(winObj);
      this.master.NetworkResources.validateUserAuthentication(reqParam).then((token: any) => {
        var isValidLogged = this.validateLoggedInUserWithCurrentUser(winObj, token.access_token);
        if (isValidLogged) {
          this.elnProgressSpinnerService.Hide();
          request.successCallback({
            status: AuthStatusType.Success,
            token: token.access_token
          });
        } else {
          this.elnProgressSpinnerService.Hide();
          request.successCallback({
            status: AuthStatusType.Failed
          });
          setTimeout(() =>{
            this.displayErrorMessage(
              '',
              request.messageForUnauthorizedCredentials ??
              $localize`:@@userPuidDifferentFromCurrentUser:User PUID should be same as the current logged in user.`
            );
            document.body.click();
          },0)
          
          this.handleReauthenticationResponse(request);
        }
      });
    }
  }

  displayErrorMessage(summary: string, detail: string) {
    const messageObj: Message = {
      key: 'notification',
      severity: 'error',
      summary,
      detail,
      sticky: false
    };
    this.messageService.add(messageObj);
  }

  displaySuccessMessage(summary: string, detail: string) {
    const messageObj: Message = {
      key: 'notification',
      severity: 'success',
      summary,
      detail,
      sticky: false
    };
    this.messageService.add(messageObj);
  }
}
