import { Injectable } from '@angular/core';
import { Instant, LocalDate } from '@js-joda/core';
import { NA, Quantity } from 'bpt-ui-library/shared';
import {
  ExperimentDataValue,
  InstantValue,
  LocalDateValue,
  ModifiableDataValue,
  NumberValue,
  StringArrayValue,
  ValueState,
  ValueType,
  StringValue,
} from '../../api/models';
import { ColumnType } from '../../api/models/column-type';
import { FieldType } from '../../api/models/field-type';
import { UnitLoaderService } from 'services/unit-loader.service';
import { FieldDefinition, SpecificationValue } from 'model/experiment.interface';
import { SpecificationService } from '../../shared/specification-input/specification.service';
import { ElnDateTimeTypes } from '../../shared/date-time-helpers';
import { StringTypeDictionaryValue } from '../../api/data-entry/models';
import { omitBy, union } from 'lodash-es';
import { PicklistAttributes } from '../../model/template.interface';

export type DataValue =
  | (ExperimentDataValue & { value: boolean | undefined }) // For checkbox; deprecated. Revisit when quad-state is in the service API.
  | StringValue
  | StringArrayValue
  | StringTypeDictionaryValue
  | NumberValue
  | InstantValue
  | LocalDateValue
  | SpecificationValue;

export type FieldOrColumnType = FieldType | ColumnType; // Alternatively, in some incarnation, this might be the right enum: keyof typeof ColumnType from 'bpt-ui-library/bpt-grid'

export type EmittedStringValue = string | typeof NA | undefined;
export type EmittedValue =
  | boolean
  | string[]
  | typeof NA
  | null
  | undefined
  | EmittedStringValue
  | ElnDateTimeTypes
  | Quantity
  | SpecificationValue;

@Injectable({
  providedIn: 'root'
})
export class DataValueService {
  constructor(
    private readonly unitLoaderService: UnitLoaderService,
    private readonly specificationService: SpecificationService
  ) { }

  /**
   * This "cached"/"static" array multiselectNA seems required for inner working of bpt-dropdown and/or Angular to do some sort of value object comparison.
   */
  readonly multiselectNA = [NA];

  public static isEmpty(value: ModifiableDataValue | undefined): boolean {
    return !value || value.value.state === ValueState.Empty;
  }

  public static isNotApplicable(value: ModifiableDataValue | undefined): boolean {
    return value?.value.state === ValueState.NotApplicable;
  }

  /**
   * Transforms a value into a format that the rendering component for the field or column type accepts and displays and/or edits.
   * Some might do their own formatting and localization. But none take a `ModifiableDataValue`.
   *
   * @returns primitive (aka native) value for presumed component
   */
  public getPrimitiveValue(fieldOrColumnType: FieldOrColumnType, value: ModifiableDataValue | undefined | /* API inappropriately sends null # 3207047 */null): any {
    if (!value) return undefined; // if value is missing leave as undefined; It's an alternate representation of Empty and never modified.
    // Determine if we should use the passed in type or use the desired one from value.
    // This is could be relevant in general. Definitely needed when dealing with dynamic columns.
    const typeToUse = value.value?.type === ValueType.Number && fieldOrColumnType === ColumnType.String
      ? ColumnType.Quantity
      : fieldOrColumnType;
    const shouldProceed = this.getPrimitiveForNonModifiableDataValue(typeToUse, value);
    return shouldProceed === 'checkForModifiableDataValue' ? this.getPrimitiveForModifiableDataValue(typeToUse, value) : shouldProceed;
  }

  private getPrimitiveForModifiableDataValue(fieldOrColumnType: FieldOrColumnType, value: ModifiableDataValue) {
    switch (fieldOrColumnType) {
      case FieldType.Datepicker:
      case ColumnType.Date:
        return this.getPrimitiveDateValue(value);
      case FieldType.Quantity:
      case ColumnType.Quantity:
        return this.getPrimitiveQuantityValue(value);
      case ColumnType.Index:
      case ColumnType.StepNumber:
      case ColumnType.Number: {
        const number: NumberValue = value.value as NumberValue;
        return number.value;
      }
      case FieldType.Specification:
      case ColumnType.Specification:
        return this.specificationService.getDisplayString(value.value);
      case FieldType.EditableList:
      case ColumnType.EditableList:
        return 'value' in value.value ? value.value.value
          : /* for much older, pre-release 1 values; currently shouldn't get here */ DataValueService.getStringFromValue(value.value);
      case FieldType.List:
      case ColumnType.List:
      case ColumnType.Boolean:
      case FieldType.Textbox:
      case ColumnType.String:
      case FieldType.Textarea:
      default:
        {
          if (value.hasOwnProperty('isModified')) {
            return 'value' in value.value ? value.value.value : undefined;
          }
          return 'value' in value.value ? value.value.value
            : /* for much older, pre-release 1 values; currently shouldn't get here */ value.value;
        }
    }
  }

  private getPrimitiveForNonModifiableDataValue(
    fieldOrColumnType: FieldOrColumnType,
    value: ModifiableDataValue | undefined | null //TODO API inappropriately sends null # 3207047
  ): any {
    if (!value) return undefined;
    if (fieldOrColumnType === ColumnType.RowId) return value; // Shouldn't get here; Caller should not try to convert a Row Id.
    if ((value as any).type) value = { isModified: false, value: value as any }; // Shouldn't get here; This would be a ExperimentDataValue
    if (!value.value) return value; // Shouldn't get here; This would be old data records with just primitive values.
    if (
      value.value.state === ValueState.NotApplicable &&
      !(fieldOrColumnType === FieldType.Quantity || fieldOrColumnType === ColumnType.Quantity)
    ) {
      return value.value.type === ValueType.StringArray ? this.multiselectNA : NA;
    } else {
      return 'checkForModifiableDataValue';
    }
  }

  /**
   * Memoizes calls to getPrimitiveQuantityValue.
   * Primary reason is to return the same object to prevent event storms due to object reference changes.
   * Secondary reason is tiny performance improvement.
   */
  private readonly cachedQuantityValues: { [key: string]: Quantity | undefined } = {};

  private readonly cachedDateValues: { [key: string]: LocalDate | Instant } = {};
  /*
   * 5 separate functions to reduce complexity of getExperimentDataValue to within SonarQube's comprehension; Sorry, humans.
   */

  /**
   * Converts from an missing, empty, notApplicable or set LocalDateValue or InstantValue to undefined, N/A, LocalDate or Instant
   */
  private getPrimitiveDateValue(value: ModifiableDataValue): ElnDateTimeTypes {
    if (!('value' in value.value)) return undefined;
    if (value.value.state === ValueState.Empty) return undefined;
    if (value.value.state === ValueState.NotApplicable) return NA;

    // Caching needed to prevent hangup
    const StoreLocalDateOrInstance = (type: ValueType, str: string): LocalDate | Instant => {
      let storageName: string;
      let storageValue: LocalDate | Instant;
      switch (type) {
        case ValueType.LocalDate:
          storageValue = LocalDate.parse(str);
          storageName = `${type}|${storageValue}`
          break;
        case ValueType.Instant:
          storageValue = Instant.parse(str);
          storageName = `${type}|${storageValue}`
          break;
        default: throw new Error('LOGIC ERROR: ModifiableDataValue should contain a LocalDateValue or InstantValue');
      }
      this.cachedDateValues[storageName] ??= storageValue;
      return this.cachedDateValues[storageName];
    }

    return StoreLocalDateOrInstance(value.value.type, value.value.value as string);
  }

  private readonly getPrimitiveQuantityValue: (value: ModifiableDataValue) => Quantity | undefined =
    (value) => {
      const number: NumberValue = value.value as NumberValue;
      const key = JSON.stringify(number); // using number because other properties of ModifiableDataValue are ignored in this entire function

      if (!this.cachedQuantityValues[key]) {
        const unit = this.unitLoaderService.allUnits.find((u) => u.id === number.unit);

        // Empty with N/A unit is not a valid state
        const unitToSet = unit?.id === this.unitLoaderService.naUnit.id && number.state === ValueState.Empty ? undefined : unit;

        const newQuantity = new Quantity(
          number.state,
          number.value,
          unitToSet,
          number.sigFigs,
          number.exact
        );
        this.cachedQuantityValues[key] = newQuantity;
      }
      return this.cachedQuantityValues[key];
    };

  /**
   * Converts a component-emitted value to an ExperimentDataValue.
   *
   * @param {(FieldOrColumnType | undefined)} fieldOrColumnType Data type that determines which component renders the value.
   * @param {EmittedValue} emittedValue Component-emitted value. It depends on the component but is likely the same type as the data-bound ("primitive") value.
   * @return {*} {ExperimentDataValue} Subclass of ExperimentDataValue with target data type and converted value.
   * @memberof DataValueService
   */
  public getExperimentDataValue(fieldOrColumnType: FieldOrColumnType | undefined, emittedValue: EmittedValue): ExperimentDataValue {
    // Normalize parameters
    emittedValue = emittedValue === null || emittedValue === '' ? undefined : emittedValue;

    const valueState = this.getValueState(emittedValue);

    let dataValue: DataValue;
    switch (fieldOrColumnType) {
      case FieldType.Checkbox:
        dataValue = this.getCheckboxDataValue(
          valueState,
          emittedValue as boolean | typeof NA | undefined
        );
        break;
      case ColumnType.Number:
      case ColumnType.Index:
      case ColumnType.StepNumber:
        dataValue = this.getNumericDataValue(
          valueState,
          emittedValue?.toString() as EmittedStringValue,
          1, // TODO Stand-in dummy sigfigs until either number value is retired or retrofitted with sigfigs
          ''
        );
        break;

      case FieldType.Quantity:
      case ColumnType.Quantity:
        dataValue = this.getExperimentDataValueQuantity(emittedValue as Quantity);
        break;

      case ColumnType.Date:
      case FieldType.Datepicker:
        dataValue = emittedValue instanceof Instant ? this.getInstantValue(valueState, emittedValue)
          : this.getLocalDateValue(valueState, emittedValue as ElnDateTimeTypes)

        break;

      case FieldType.List:
      case FieldType.EditableList:
      case ColumnType.List:
      case ColumnType.EditableList:
        dataValue = Array.isArray(emittedValue)
          ? this.getStringArrayValue(valueState, emittedValue as string[] | typeof NA | undefined)
          : this.getStringValue(valueState, emittedValue as EmittedStringValue);
        break;

      case FieldType.Textarea:
      case FieldType.Textbox:
      case ColumnType.String:
        dataValue = this.getStringValue(valueState, emittedValue as EmittedStringValue);
        break;
      case FieldType.Specification:
      case ColumnType.Specification:
        dataValue = emittedValue as SpecificationValue ?? { type: ValueType.Specification, state: ValueState.Empty };
        break;
      default:
        dataValue = this.getDefaultDataValue(valueState, emittedValue as EmittedStringValue);
    }

    // Re omitBy null: No DataValue subtype allows null properties so removing them doesn't change the type
    return omitBy(dataValue, v => v === null) as DataValue;
  }

  /** In the case where an editable multi-select has an off-list entry, we need to add it to the available options. */
  setupListValues(value: ExperimentDataValue, fieldDefinition: FieldDefinition): any[] | undefined {
    const picklistAttributes = fieldDefinition.fieldAttributes as PicklistAttributes;
    if (value?.state !== ValueState.Set || fieldDefinition.fieldType !== FieldType.EditableList || !picklistAttributes.allowMultiSelect) {
      return picklistAttributes.listValues;
    }

    const inboundOptions = ((value as StringArrayValue).value).map((v: string) => ({ label: v, value: v }));
    // filter the inbound selected options and remove the ones that are present on the picklist.
    const inboundOffListOptions = inboundOptions.filter(inboundOption => !picklistAttributes.listValues?.some(picklistOption => inboundOption.value === picklistOption.value));

    inboundOffListOptions.sort((a, b) => a.label.localeCompare(b.label));
    return union(picklistAttributes.listValues, inboundOffListOptions);
  }

  private getValueState(emittedValue: EmittedValue): ValueState {
    //using JavaScript idiomatic truthy value selectors (&& ||) to avoid SonarQube's hatred for simple, sequential ternary expressions.
    return (
      // Just N/A
      (emittedValue === NA && ValueState.NotApplicable) ||
      // Array of just N/A
      (Array.isArray(emittedValue) &&
        emittedValue.length === 1 &&
        emittedValue[0] === NA &&
        ValueState.NotApplicable) ||
      // no value or just whitespace
      ((emittedValue === undefined ||
        emittedValue === null ||
        emittedValue.toString().trim() === '') &&
        ValueState.Empty) ||
      // some value
      ValueState.Set
    );
  }

  // checkbox is deprecated; it's not supported by form designer, though it still is by form templates and experiment. Revisit with quad-state.
  private getCheckboxDataValue(
    valueState: ValueState,
    emittedValue: boolean | typeof NA | undefined
  ): ExperimentDataValue & { value: boolean | undefined } {
    return {
      type: ValueType.Boolean,
      state: valueState,
      value: valueState === ValueState.Set ? (emittedValue as boolean) : undefined
    };
  }

  /** Converts Instant to set InstantValue. See getLocalDateValue for Empty and NotApplicable */
  private getInstantValue(valueState: ValueState, emittedValue: Instant): InstantValue {
    return {
      type: ValueType.Instant,
      state: valueState,
      value: emittedValue.toJSON()
    };
  }

  /** Converts LocalDate to set LocalDateValue, also handles Empty and NotApplicable (because some type is required but is not very meaningful) */
  private getLocalDateValue(valueState: ValueState, emittedValue: ElnDateTimeTypes): LocalDateValue {
    switch (valueState) {
      case ValueState.Empty:
      case ValueState.NotApplicable:
        return { type: ValueType.LocalDate, state: valueState };
      case ValueState.Set:
        return {
          type: ValueType.LocalDate,
          state: valueState,
          value: (emittedValue as LocalDate).toJSON()
        };
      default:
        throw new Error('LOGIC ERROR: Not implemented');
    }
  }

  private getNumericDataValue(
    valueState: ValueState,
    emittedValue: EmittedStringValue,
    sigFigs: number,
    unit: string
  ): DataValue {
    const fallbackUnit = valueState === ValueState.NotApplicable
      ? undefined
      : this.unitLoaderService.naUnit.id;
    return {
      type: ValueType.Number,
      state: valueState,
      value: valueState === ValueState.Set ? emittedValue : undefined,
      sigFigs: valueState === ValueState.Set ? sigFigs : undefined,
      exact: valueState === ValueState.Set ? emittedValue !== '' && !sigFigs : undefined,
      unit:
        valueState === ValueState.Set && unit && unit.length > 0
          ? unit
          : fallbackUnit
    };
  }

  private getStringArrayValue(
    valueState: ValueState,
    emittedValue: string[] | typeof NA | undefined
  ): StringArrayValue {
    return {
      type: ValueType.StringArray,
      state: valueState,
      value: valueState === ValueState.Set ? (emittedValue as string[]) : []
    };
  }

  private getStringValue(valueState: ValueState, emittedValue: EmittedStringValue): StringValue {
    switch (valueState) {
      case ValueState.Empty:
      case ValueState.NotApplicable:
        return {
          type: ValueType.String,
          state: valueState
        };
      case ValueState.Set:
        return {
          type: ValueType.String,
          state: valueState,
          value: this.getDataValue(valueState, emittedValue)
        };
      default:
        throw new Error('LOGIC ERROR: value state is invalid');
    }
  }

  // Returning NA in the value can be avoided once saving data record
  // with value being undefined when state is not applicable.
  private getDataValue<TEmittedValue>(
    valueState: ValueState,
    emittedValue: TEmittedValue
  ): TEmittedValue | undefined {
    if (valueState === ValueState.Set) {
      return emittedValue;
    } else if (valueState === ValueState.NotApplicable) {
      return undefined;
    }
    return undefined;
  }

  private getDefaultDataValue(
    valueState: ValueState,
    emittedValue: EmittedStringValue
  ): StringValue {
    return this.getStringValue(valueState, emittedValue);
  }

  static getValueFromString(value: string): StringValue {
    const trimmed = value.trim();
    const nonEmptyState = trimmed === NA ? ValueState.NotApplicable : ValueState.Set;
    const state = trimmed === '' ? ValueState.Empty : nonEmptyState;

    return {
      type: ValueType.String,
      state,
      value: trimmed
    };
  }

  static getStringFromValue(value: ExperimentDataValue): string {
    if (value.type !== ValueType.String)
      throw new Error('Logic Error: cannot get a string from an non-string');
    return this.getStringFromStringValue(value);
  }

  static getStringFromStringValue(value: StringValue): string {
    switch (value.state) {
      case ValueState.NotApplicable:
        return NA;
      case ValueState.Set:
        return value.value ?? '';
      case ValueState.Empty:
        return '';
      default:
        return '';
    }
  }

  /**
   * Modifies the quantity parameter for consistency with property combinations.
   */
  static pruneQuantity(quantity: Quantity) {
    // need to remove the nonsensical properties for empty or N/A states that bpt-quantity might give.
    if (quantity.state === ValueState.Empty) {
      delete quantity.exact;
      delete quantity.sigFigs;
    } else if (quantity.state === ValueState.NotApplicable) {
      delete quantity.exact;
      delete quantity.sigFigs;
      delete quantity.unitDetails;
      delete quantity.value;
    }
  }

  /**
   * Compares one optional NumberValue with another, (extracting number from measurement or quantity as applicable).
   * Ignores inapplicable properties (e.g. unitDetails) and property values (e.g. undefined).
   *
   * To be clear, a number is not a measurement and a measurement is not just a number. But it can be useful to extract the number from a measurement.
   */
  static areEquivalentNumberValues(a: NumberValue | Quantity | undefined, b: NumberValue | Quantity | undefined): boolean {
    if (!a && !b) return true;
    if (!(a && b)) return false;

    if (a.type !== b.type) return false;
    if (a.state !== b.state) return false;
    if (a.value !== b.value) return false;
    if (a.sigFigs !== b.sigFigs) return false;
    if ((a.exact ?? false) !== (b.exact ?? false)) return false;
    if (a.unit !== b.unit) return false;
    // ignoring instrumentReading from NumberValue
    // ignoring all other properties of Quantity

    return true;
  }

  getExperimentDataValueQuantity(emittedValue: Quantity): Omit<NumberValue, 'instrumentReading'> {
    const value: NumberValue = (emittedValue ?? new Quantity(ValueState.Empty)).valueOf();
    if (value.exact === undefined) delete value.exact;
    if (value.sigFigs === undefined) delete value.sigFigs;
    if (value.unit === undefined) delete value.unit;
    if (value.value === undefined) delete value.value;
    if ('__proto__' in value) delete value['__proto__'];
    return value;
  }

  joinValues(inputValue: StringTypeDictionaryValue): string {
    switch (inputValue.state) {
      case ValueState.Empty:
        return '';
      case ValueState.Set:
        return inputValue.value ? Object.values(inputValue.value).filter(item => item !== undefined && item !== '').map(data => data).join(' ') : '';
      case ValueState.NotApplicable:
        return NA;
      default:
        return '';
    }
  }
}
