import { User } from 'model/user.interface';
import { environment } from '../../../../environments/environment';
import * as data from '../../../../assets/eln-assets/data.json';
import { UserData } from './user-data.interface';
import * as dynamicConfig from '../../../../assets/eln-assets/dev-dynamic-configuration.json';

export class ConstantUserData implements UserData {
  jsonData: any;
  dynamicConfiguration: any;
  constructor() {
    this.jsonData = data;
    this.dynamicConfiguration = dynamicConfig;
  }

  /** eLIMS's time zone identifiers are just a mangling of IANA-compatible ones. They replace / with __. */
  getUserLabSiteTimeZone(): string {
    return this.jsonData.user.userLabSiteTimeZone;
  }

  getCurrentUser(): User {
    const user = { ...this.jsonData.user, ...(this.dynamicConfiguration.user) };
    user.puid = user.puid.toUpperCase(); // ensure in IT standard format https://int.dms.eurofins.local/peg/KGDpedia/KGDpedia/Personal%20Unique%20Identifier.aspx
    return user;
  }

  getOauthToken(_url: string): Promise<string> {
    if (_url.includes('/assets/eln-assets/configurations/appconfig.json')) {
      return Promise.resolve('');
    }
    return Promise.resolve(this.getOAuthTokenForApiUrls(_url));
  }

  private getOAuthTokenForApiUrls(_url: string) {
    const apiTokenSearchDefinition = ConstantUserData.buildApiTokenSearchDefinition();
    const tokenSearchDefinition = apiTokenSearchDefinition.find(searchDefinition => _url.includes(searchDefinition.apiUrl));
    if (tokenSearchDefinition) {
      const token = localStorage[tokenSearchDefinition.tokenOfflineStorageKey];
      return token ?? this.dynamicConfiguration.apiTokens[tokenSearchDefinition.tokenFallbackKey];
    }
    const token = localStorage['eln-access-token'];
    return token ?? this.dynamicConfiguration.apiTokens.accessToken;
  }

  /**
   * Define the API URL and its API token storage details.
   * tokenOfflineStorageKey is used to get the token from local storage memory.
   * tokenFallbackKey is used when token is not exists in local storage then it will be taken from dynamicConfiguration.apiTokens.
   */
  private static buildApiTokenSearchDefinition() {
    return [
      {
        apiUrl: environment.searchServiceUrl,
        tokenOfflineStorageKey: 'eln-search-access-token',
        tokenFallbackKey: 'searchAccessToken'
      },
      {
        apiUrl: environment.auditServiceUrl,
        tokenOfflineStorageKey: 'eln-audit-token',
        tokenFallbackKey: 'auditToken'
      },
      {
        apiUrl: environment.internalCommentServiceUrl,
        tokenOfflineStorageKey: 'eln-internal-comment-token',
        tokenFallbackKey: 'internalCommentToken'
      },
      {
        apiUrl: environment.dataPackageServiceUrl,
        tokenOfflineStorageKey: 'eln-data-package-token',
        tokenFallbackKey: 'dataPackageToken'
      },
      {
        apiUrl: environment.cookbookServiceUrl,
        tokenOfflineStorageKey: 'eln-cookbook-token',
        tokenFallbackKey: 'cookbookToken'
      },
      {
        apiUrl: environment.instrumentAdminServiceUrl,
        tokenOfflineStorageKey: 'eln-instrument-admin',
        tokenFallbackKey: 'instrumentAdminToken'
      },
      {
        apiUrl: environment.elnFilesServiceUrl,
        tokenOfflineStorageKey: 'ebpt-eln-dev-files',
        tokenFallbackKey: 'elnFilesToken'
      },
      {
        apiUrl: environment.elnLabelsServiceUrl,
        tokenOfflineStorageKey: 'ebpt-labels-token',
        tokenFallbackKey: 'elnLabelsToken'
      }];
  }
}
