import { CellRange, Column, ColumnApi, EditableCallbackParams, RowNode } from "ag-grid-community";
import { BptGridComponent, ColumnDefinition, ColumnType, GridContextMenuItem } from "bpt-ui-library/bpt-grid";
import { NA } from "bpt-ui-library/shared";
import { isEmpty } from "lodash-es";
import { Injectable } from "@angular/core";
import { Consumable } from "../../api/models";
import { UserService } from "../../services/user.service";


export type NaGridTableIdentifierDataForGrids = {
  isCellEditable: (_params: EditableCallbackParams) => boolean | (() => boolean),
  postChangeCellCommand: (id: string, changedValue: { [key: string]: string }) => void,
  grid: BptGridComponent,
  table: Consumable[],
  columnDefinitions: ColumnDefinition[],
  idFieldName: string
}

@Injectable({
  providedIn: 'root'
})
export class FillWithNaGridHelperForGrids {
  readonly iconNotApplicable = '<i class="ag-icon ag-custom-icon icon-not-applicable"></i>';

  constructor(
    private readonly userService: UserService,
  ) {
  }

  getContextMenuOptionsOfFillWithNa(
    args: NaGridTableIdentifierDataForGrids
  ): GridContextMenuItem {
    if (args.grid?.gridApi) {
      const cells = args.grid.gridApi.getCellRanges();

      if (!isEmpty(cells) && cells && cells[0] && cells[0].startRow && cells[0].columns) {
        if (
          cells[0].startRow?.rowIndex ===
          (cells[0].endRow?.rowIndex ?? cells[0].startRow?.rowIndex) &&
          cells[0].columns?.length === 1
        ) {
          return this.naSingleCellOptions(cells, args);
          } else if (
          cells[0].columns?.length > 1 ||
          cells[0].startRow?.rowIndex !== cells[0].endRow?.rowIndex
        ) {
          return this.naMultipleCellsOptions(cells, args);
        }
      }
    }

    return {
      label: '',
      action: () => { args.grid.suppressContextMenu = true; }
    };
  }

  public naMultipleCellsOptions(cells: CellRange[], args: NaGridTableIdentifierDataForGrids): GridContextMenuItem {
    return {
      label:$localize`:@@fillWithNa:Fill with ${NA}`,
      subitems: [
        {
          label: $localize`:@@naEmptyCells:${NA} Empty Cells`,
          action: () => this.beforeNaActionForLabItems(cells[0], args, true),
          disabled: this.userService.hasOnlyReviewerRights()
        },
        {
          label: $localize`:@@naAllCells:${NA} All Cells` ,
          action: () => this.beforeNaActionForLabItems(cells[0], args),
          disabled: this.userService.hasOnlyReviewerRights()
        }
      ],
      icon: this.iconNotApplicable
    };
  }

  public naSingleCellOptions(cells: CellRange[], args: NaGridTableIdentifierDataForGrids): GridContextMenuItem {
    return {
      label: $localize`:@@fillWithNa:Fill with ${NA}` ,
      action: () => this.beforeNaActionForLabItems(cells[0], args),
      icon: this.iconNotApplicable,
      disabled: this.userService.hasOnlyReviewerRights()
    };
  }

  public beforeNaActionForLabItems(cells: CellRange, args: NaGridTableIdentifierDataForGrids, naOnlyEmptyCells = false) {
    args.grid.suppressContextMenu = true;
    const rowNodes: RowNode[] = [];
    const columnValues: { [key: string]: any } = {};
    const startRowIndex = cells.startRow?.rowIndex as number;
    const endRowIndex = cells.endRow?.rowIndex ?? startRowIndex;
    for (
      let rowIndex = Math.min(startRowIndex, endRowIndex);
      rowIndex <= Math.max(startRowIndex, endRowIndex);
      rowIndex++
    ) {
      rowNodes.push(args.grid.gridApi.getDisplayedRowAtIndex(rowIndex) as RowNode);
      const rowId = rowNodes[rowNodes.length - 1].id as string;
      columnValues[rowId] = {};
      cells.columns.forEach((column) => {
        const colId = column.getColId();
        const targetColumnIndex = args.columnDefinitions.findIndex(
          (colDef) => colDef.field === colId
        );
        if (
          rowIndex !== undefined &&
          targetColumnIndex !== -1 &&
          !['rowIndex', 'id', 'rowIndex_1'].includes(colId)
        ) {
          if (!this.validateCellForNaAction(
            args.columnDefinitions[targetColumnIndex].columnType as string,
            column,
            rowNodes[rowNodes.length - 1],
            args,
            naOnlyEmptyCells
          )) {
            columnValues[rowId as any][colId] = NA;
          }
        }
      });
    }
    this.naAction(columnValues, args);
  }

  public naAction(
    columnValues: { [key: string]: any },
    args: NaGridTableIdentifierDataForGrids
  ) {
    const rowIds = Object.keys(columnValues);
    if (rowIds.length > 0) {
      rowIds.forEach((item: string) => {
        if (Object.keys(columnValues[item]).length > 0) {
          args.postChangeCellCommand(item, columnValues[item]);
        }
      })
    }
  }

  public validateCellForNaAction(
    columnType: string,
    column: Column,
    rowNode: RowNode,
    args: NaGridTableIdentifierDataForGrids,
    naOnlyEmptyCells = false
  ): boolean {
    const rowData: { [key: string]: any } | undefined = args.table.find((data: any) => data[args.idFieldName] === rowNode.id)?.tableData;
    let colId = column.getColId();
    colId = colId.charAt(0).toUpperCase() + colId.slice(1);
    if (!rowData) return false;
    const alreadyNA = rowData[colId] === NA;
    if (!rowNode || !rowData || !this.acceptedColumnTypesForFillWithNA(columnType)) return true;
    args.grid.gridApi?.stopEditing(true);
    const params: EditableCallbackParams = {
      node: rowNode,
      data: rowData,
      column,
      colDef: column.getColDef(),
      api: args.grid.gridApi,
      columnApi: new ColumnApi(args.grid.gridApi),
      context: args.grid.gridOptions.context
    };
    const canEditCell = args.isCellEditable(params);
    const celldata = rowData.find((f: { hasOwnProperty: (arg0: string) => any; }) => f.hasOwnProperty(colId));
    const cellValue = this.getValueByKey(celldata, colId).value;
    const naOnlyEmptyCellsCheck = naOnlyEmptyCells && cellValue && cellValue !== "";
    return alreadyNA || !canEditCell || naOnlyEmptyCellsCheck;
  }

  getValueByKey(celldata: { [x: string]: any; }, key: string) {
    for (const prop in celldata) {
      if (prop === key) {
        if (typeof celldata[prop] === "object" && celldata[prop].hasOwnProperty("value")) {
          return celldata[prop].value;
        }
        return undefined;
      }
    }
    return undefined;
  }

  public acceptedColumnTypesForFillWithNA(columnType: string) {
    switch (columnType) {
      case ColumnType.boolean:
        return false;
      default:
        return true;
    }
  }
}
