<div class="preparation-labels">
  <div class="preparation-status-bar ml-1">
    <p-button
      id="preparation-label-printer"
      name="preparation-label-printer"
      i18n-label="@@printLabels"
      label="Print Labels"
      styleClass="p-button-ghost  bpt-button-compact statusBar"
      icon="icon-printer"
      loadingIcon="pi pi-spinner pi-spin"
      [disabled]="disablePrintLabel"
      (onClick)="getLabels()"></p-button>
  </div>
  
  <p-dialog  header="PrintLabel"
   [(visible)]="visible"
   (onHide)="closePrintLabelsDialog()"
   [modal]="printLabelOptions.modal" 
   [style]="{ width: '378px',height:'325px'  }"
    [draggable]="printLabelOptions.draggable" [resizable]="printLabelOptions.resizable">
    <ng-template pTemplate="header">
      <span class="header-label">{{labelHeader}}</span>
    </ng-template>
     <div class="flex flex-column mt-2 gap-4" >
        <bpt-numeric-input
          #numericInput
          [commitValueOnEnter]="printLabelOptions.commitValueOnEnter"
          [(ngModel)]="numericModel"
          label ='Copies'
          id="label-copies"
          name="label-copies"
          i18n-label="@@preparationLabelCopies"
          (ngModelChange)="checkPrintLabelIsEnabled()"
          [showButtons]="printLabelOptions.showButtons"
          [allowKeyboardIncrement]="printLabelOptions.allowKeyboardIncrement"
          [minValue]="printLabelOptions.minimumValue"
          [maxValue]="printLabelOptions.maximumValue"
          [required]="printLabelOptions.required"
        ></bpt-numeric-input>
      <bpt-dropdown
        label="Label Style"
        id="label-style"
        name="label-style"
        ngDefaultControl
        i18n-label="@@labelStyle"
        [editable]="printLabelOptions.editable"
        [required]="printLabelOptions.required"
        [options]="labelStylesToPrintPreparation"
        [(ngModel)]="printLabelOptions.labelStyle"
        [ngModelOptions]="{ updateOn: 'change' }"
        (ngModelChange)="onLabelSelect($event)"
        valueField="value"
        labelField="label"
        ></bpt-dropdown>
      <bpt-dropdown
        id="label-printer"
        name="label-printer"
        ngDefaultControl
        i18n-label="@@printer"
        label="Printer"
        [editable]="printLabelOptions.editable"
        [required]="printLabelOptions.required"
        [options]="printNamesArray"
        [(ngModel)]="printLabelOptions.printer"
        (ngModelChange)="onPrinterSelection($event)"
        [disabled]="printerAvailable"
        valueField="value"
        labelField="label"
        ></bpt-dropdown>
    </div>
    <ng-template pTemplate="footer">
      <div class="dialog-footer justify-content-end  flex gap-3">
        <p-button
          id="eln-btnPreparationLabelsCancel"
          name="eln-btnPreparationLabelsCancel"
          i18n-label="@@cancel"
          label="Cancel"
          class="preparationLabelCancel"
          styleClass="p-button-outlined"
          type="button"
          (onClick)="cancel()"
          ></p-button>
        <p-button
          id="eln-btnPreparationLabelsSubmit"
          name="eln-btnPreparationLabelsSubmit"
          i18n-label="@@printLabels"
          label="Print Labels"
          class="submit"
          type="submit"
          [loading] = "isLoading"
          styleClass="p-button"
          (onClick)="submit()"
          [disabled]="checkPrintLabelIsEnabled()"
          ></p-button>
      </div>
    </ng-template>
  </p-dialog>
</div>