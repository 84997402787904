export type AuthSuccessCallback = (result: AuthResult) => void;

export enum AuthStatusType {
  ReAuthRequired = 418,
  Success = 1,
  Failed = 0
}

export interface AuthResult {
  status: AuthStatusType;
  token?: string;
}

export interface AuthRequest {
  successCallback: AuthSuccessCallback;
  /**
   * Send a message to be displayed. When the validated credentials user is not the same as the currently logged in user.
   * When there is no specified message for the same, the default message will be displayed.
   */
  messageForUnauthorizedCredentials?: string;
}
