import { Injectable } from "@angular/core";
import { RecipeService, RecipeSources } from "../../../recipe/services/recipe.service";
import { TemplateType } from "../../../api/models";
import { Table, Activity, Form, Module } from "../../../model/experiment.interface";
import { AugmentedForm, AugmentedModule, AugmentedTable } from "../../../recipe/model/recipe";
import { PromptItemResponse, RecipeDeleteTemplateCommand, RecipeType } from "../../../api/cookbook/models";
import { NodeType } from "../../../api/data-entry/models";
import { NodeDeleteDetails } from "../models/node-delete-details";
import { SearchItemType } from "../../../api/search/models";
import { AugmentedActivity } from "../../../model/recipe.interface";

type NodeTypes = Form | Table | Module | Activity;
type AugmentedNodeTypes = AugmentedForm | AugmentedTable | AugmentedModule | AugmentedActivity;

@Injectable({
  providedIn: 'root'
})

export class TemplateDeleteService {
  confirmationMessage: { [key: string]: string } = {};
  deleteCommand: RecipeDeleteTemplateCommand | undefined;
  defaultNodeDetails: NodeDeleteDetails = { itemId: undefined, templateId: undefined, type: TemplateType.Invalid, isSynthetic: false, number: '', isRecipe: false };
  constructor(private readonly recipeService: RecipeService) {
  }

  updateConfirmationMessage(nodeType: string) {
    this.confirmationMessage = {
      recipeItemDeleteConfirmation: $localize`:@@recipeItemDeleteConfirmation:Are you sure, you want to remove the selected ${nodeType}?`,
      recipeParentItemDeleteConfirmation:
        $localize`:@@recipeParentItemDeleteConfirmation:Removing this template/recipe will remove all contexts associated with it and any content added or modified in it, are you sure you want to continue removing the selected template/recipe?`,
      recipeItemDeleteConfirmationAsSyntheticChild:
        $localize`:@@recipeItemDeleteConfirmationAsSyntheticChild:Selected item is part of template/recipe, removing this will remove actual recipe and contexts associated with it from the current recipe, are you sure you want to continue removing the selected template/recipe?`
    };
  }

  private getModule(itemId: string): Module | undefined {
    if (this.recipeService.currentRecipe.activities.length > 0) {
      const activity = this.recipeService.currentRecipe.activities.find(a => a.dataModules.find(m => m.items.find(i => (i as Form).formId === itemId)))
        || this.recipeService.currentRecipe.activities.find(x => x.dataModules.find(x => x.items.find(x => (x as Table).tableId === itemId)));
      const module = activity?.dataModules.find(x => x.items.some(x => (x as Form).formId === itemId)) || activity?.dataModules.find(x => x.items.some(x => (x as Table).tableId === itemId));
      return module || undefined;
    }
    else {
      const module = this.recipeService.currentRecipe.modules.find(x => x.items.some(x => (x as Form).formId === itemId)) || this.recipeService.currentRecipe.modules.find(x => x.items.some(x => (x as Table).tableId === itemId));
      return module || undefined;
    }
  }

  private getActivityId(moduleId: string | undefined): NodeDeleteDetails {
    const activity = this.recipeService.currentRecipe.activities.find(a => a.dataModules.some(m => m.moduleId === moduleId));
    return activity ? this.getActivityItem(activity, (activity as AugmentedActivity).isRecipeNode) : this.defaultNodeDetails;
  }

  private readonly deleteFormCallback = () => {
    if (this.deleteCommand)
      this.recipeService.deleteTemplate(this.deleteCommand);
  };

  deleteRecipeItem(recipeItem: NodeTypes, type: TemplateType, isRecipe = false) {
    //determine template to be deleted
    isRecipe ? this.updateConfirmationMessage('recipe') : this.updateConfirmationMessage('template');
    const itemToBeDeleted = this.getItemIdToBeDeleted(recipeItem, type, isRecipe);
    switch (itemToBeDeleted.type) {
      case TemplateType.Form: {
        const form = (recipeItem as AugmentedForm);
        this.deleteOperation(form, TemplateType.Form, itemToBeDeleted, type);
        break;
      }
      case TemplateType.Table: {
        const table = (recipeItem as AugmentedTable);
        this.deleteOperation(table, TemplateType.Table, itemToBeDeleted, type);
        break;
      }
      case TemplateType.Module: {
        const module = (recipeItem as AugmentedModule);
        this.deleteOperation(module, TemplateType.Module, itemToBeDeleted, type);
        break;
      }
      case TemplateType.ActivityGroup:
      case TemplateType.Activity: {
        const activity = (recipeItem as AugmentedActivity);
        this.deleteOperation(activity, TemplateType.Activity, itemToBeDeleted, type);
        break;
      }
      case TemplateType.TableForm: {
        const tableForm = type === TemplateType.Table ? (recipeItem as AugmentedTable) : (recipeItem as AugmentedForm);
        this.deleteOperation(tableForm, TemplateType.TableForm, itemToBeDeleted, type);
        break;
      }
    }
  }

  deleteOperation(recipeItem: AugmentedNodeTypes, type: TemplateType,
    itemToBeDeleted: any, itemType: string) {
    const confirmationMessage = this.getConfirmationMessage(type, itemToBeDeleted, itemType);
    this.deleteCommand = this.createDeleteCommand(recipeItem, type, itemToBeDeleted, itemToBeDeleted.isSynthetic);
    this.recipeService.confirmationDialog(confirmationMessage, this.deleteFormCallback);
  }

  getConfirmationMessage(type: TemplateType, itemToBeDeleted: any, itemType: string): string {
    return itemToBeDeleted.type !== itemType && (itemType === TemplateType.TableForm ||
      itemType === TemplateType.Form || itemType === TemplateType.Table || itemType === TemplateType.Module)
      ? this.confirmationMessage['recipeItemDeleteConfirmationAsSyntheticChild']
      : ((type === TemplateType.Module || type === TemplateType.Activity) && itemToBeDeleted.type === itemType)
        ? this.confirmationMessage['recipeParentItemDeleteConfirmation']
        : itemToBeDeleted.type === itemType
          ? this.confirmationMessage['recipeItemDeleteConfirmation']
          : this.confirmationMessage['recipeItemDeleteConfirmationAsSyntheticChild'];
  }

  createDeleteCommand(recipeItem: any, templateType: TemplateType, itemToBeDeleted: NodeDeleteDetails, isSynthetic = false): RecipeDeleteTemplateCommand {
    return {
      isRecipe: itemToBeDeleted.isRecipe,
      itemId: itemToBeDeleted.itemId,
      recipeId: this.recipeService.currentRecipe.recipeId,
      recipeNumber: this.recipeService.currentRecipe.number,
      sourceTemplateType: templateType,
      recipeType: this.findRecipeType(itemToBeDeleted),
      templateReferenceId: isSynthetic ? recipeItem.sourceTemplateId : itemToBeDeleted.templateId,
      isSynthetic: isSynthetic,
      templateReferenceNumber: itemToBeDeleted.number,
      referencedRecipeId: itemToBeDeleted.referencedRecipeId,
      childTemplates: itemToBeDeleted.childTemplates,
      childRecipes: itemToBeDeleted.childRecipes
    } as RecipeDeleteTemplateCommand;
  }

  findRecipeType(itemToBeDeleted: NodeDeleteDetails): RecipeType {
    const currentRecipeType = this.recipeService.currentRecipe.type;
    const recipeSourcesCount = this.recipeService.RecipeSources.length;
    if (!itemToBeDeleted.templateId) return RecipeType.None;

    switch (itemToBeDeleted.type) {
      case TemplateType.Form:
        return this.getTypeForForm(currentRecipeType, recipeSourcesCount, itemToBeDeleted.templateId);
      case TemplateType.Table:
        return this.getTypeForTable(currentRecipeType, recipeSourcesCount, itemToBeDeleted.templateId);
      case TemplateType.Module:
        if (currentRecipeType === RecipeType.Module) {
          return this.getTypeForModule(itemToBeDeleted.templateId);
        }
        break;
      case TemplateType.TableForm:
        if (currentRecipeType === RecipeType.TableForm) {
          return this.getTypeForTableForm(itemToBeDeleted.templateId);
        }
        break;
      case TemplateType.Activity:
      case TemplateType.ActivityGroup:
        return this.getTypeForActivity(currentRecipeType, itemToBeDeleted.templateId);
      default:
        return RecipeType.None;
    }
    return currentRecipeType;
  }

  getTypeForActivity(currentRecipeType: RecipeType, instanceId: string) {
    if (currentRecipeType === RecipeType.Activity) {
      return RecipeType.None;
    }
    const instanceIds = this.getInstanceIds(instanceId);
    const activityCount = this.getOtherActivityNodeCount(instanceIds);
    if (activityCount > 1) {
      return RecipeType.ActivityGroup;
    } else if (activityCount === 1) {
      return RecipeType.Activity;
    } else {
      return RecipeType.None;
    }
  }

  getPPRType() {
    const promptExists = this.recipeService.recipe.orphan.prompts.flatMap(p => p.promptItems).some(i => (i as PromptItemResponse).source === 'N/A');
    const preparationExists = this.recipeService.recipe.orphan.preparations.some(p => p.source?.value.state === 'notApplicable');
    const referenceExists = this.recipeService.recipe.orphan.references.flatMap(r => r.rows).some(r => r['source'].value.value === null);
    return (promptExists || preparationExists || referenceExists) ? RecipeType.ReferencePromptPreparation : RecipeType.None;
  }

  getTypeForTable(currentRecipeType: RecipeType, recipeSourcesCount: number, instanceId: string) {
    const instanceIds = this.getInstanceIds(instanceId);
    if (currentRecipeType === RecipeType.Table && recipeSourcesCount > 1) {
      return RecipeType.Table;
    }
    else if (currentRecipeType === RecipeType.TableForm) {
      const tablesCount = this.getOtherTableNodeCount(instanceIds);
      return tablesCount > 0 ? RecipeType.TableForm : RecipeType.Form;
    }
    else if (recipeSourcesCount === 1) {
      return this.getPPRType();
    }
    else {
      return currentRecipeType;
    }
  }

  getTypeForForm(currentRecipeType: RecipeType, recipeSourcesCount: number, instanceId: string) {
    const instanceIds = this.getInstanceIds(instanceId);
    if (currentRecipeType === RecipeType.Form && recipeSourcesCount > 1) {
      return RecipeType.Form;
    }
    else if (currentRecipeType === RecipeType.TableForm) {
      const formsCount = this.getOtherFormNodeCount(instanceIds);
      return formsCount > 0 ? RecipeType.TableForm : RecipeType.Table;
    }
    else if (recipeSourcesCount === 1) {
      return this.getPPRType();
    }
    else {
      return currentRecipeType;
    }
  }

  getTypeForModule(instanceId: string) {
    const modulesCount = this.getOtherModuleNodeCount(this.getInstanceIds(instanceId));
    return modulesCount > 0 ? RecipeType.Module : this.getPPRType();
  }

  getTypeForTableForm(instanceId: string) {
    const instanceIds = this.getInstanceIds(instanceId);
    const tablesCount = this.getOtherTableNodeCount(instanceIds);
    const formsCount = this.getOtherFormNodeCount(instanceIds);

    if (tablesCount > 0 && formsCount > 0) {
      return RecipeType.TableForm;
    } else if (tablesCount > 0) {
      return RecipeType.Table;
    } else if (formsCount > 0) {
      return RecipeType.Form;
    } else {
      return this.getPPRType();
    }
  }

  getInstanceIds(instanceId: string) {
    const instanceIds: string[] = [];
    this.recipeService.RecipeSources.filter(s => s.instanceId !== instanceId).forEach(r => {
      if (r.itemType === SearchItemType.Template) {
        instanceIds.push(r.instanceId ?? '');
      }
      if (r.itemType === SearchItemType.Recipe && r.childInstanceIds) {
        instanceIds.push(...r.childInstanceIds);
      }
    });
    return instanceIds;
  }

  getOtherTableNodeCount(instanceIds: string[]) {
    return this.recipeService.currentRecipe.tables.filter(t => instanceIds.includes(t.tableId)).length;
  }

  getOtherFormNodeCount(instanceIds: string[]) {
    return this.recipeService.currentRecipe.forms.filter(f => instanceIds.includes(f.formId)).length;
  }

  getOtherModuleNodeCount(instanceIds: string[]) {
    return this.recipeService.currentRecipe.modules.filter(m => instanceIds.includes(m.moduleId)).length;
  }

  getOtherActivityNodeCount(instanceIds: string[]) {
    return this.recipeService.currentRecipe.activities.filter(a => instanceIds.includes(a.activityId)).length;
  }

  // Populates nodes sources which are added inside the deleting node(module/activity)
  getChildSources(itemToBeDeleted: NodeDeleteDetails, isRecipe: boolean) {
    const itemId = itemToBeDeleted.itemId ?? '';
    const recipeSource = this.recipeService.RecipeSources.find(r => r.childInstanceIds?.includes(itemId));
    const siblings = Array.from(new Set(recipeSource?.childInstanceIds?.filter(c => c !== itemId)));
    let childOrder: string[] = [];

    if (itemToBeDeleted.type === TemplateType.Module) {
      const module = this.getRecipeModules().find(m => m.moduleId === itemId);
      childOrder = module ? (module as AugmentedModule).childOrder.filter(o => !recipeSource?.childInstanceIds?.includes(o)) : [];
      if (isRecipe) {
        const siblingModules = this.getRecipeModules().filter(m => siblings.includes(m.moduleId));
        this.addChildOrderFromSiblings(childOrder, recipeSource, siblings, siblingModules);
      }
    } else {
      const activity = this.recipeService.currentRecipe.activities.find(a => a.activityId === itemId);
      childOrder = activity ? (activity as AugmentedActivity).childOrder.filter(o => !recipeSource?.childInstanceIds?.includes(o)) : [];
      childOrder = childOrder.concat(activity?.dataModules.flatMap(m => (m as AugmentedModule).childOrder
        .filter(o => !siblings.includes(o))) ?? []);
      if (isRecipe) {
        const siblingActivities = this.recipeService.currentRecipe.activities.filter(a => siblings.includes(a.activityId));
        this.addChildOrderFromSiblings(childOrder, recipeSource, siblings, siblingActivities);
      }
    }
    this.populateReferencedItems(itemToBeDeleted, recipeSource, childOrder);
  }

  addChildOrderFromSiblings(childOrder: string[], recipeSource: RecipeSources | undefined, siblings: string[],
    items: { moduleId?: string; activityId?: string }[]) {
    items.forEach(item => {
      const order = (item as AugmentedModule | AugmentedActivity).childOrder || [];
      const nestedChildren = order.filter(o => !recipeSource?.childInstanceIds?.includes(o));
      childOrder.push(...nestedChildren);
      if ('activityId' in item) {
        const siblingChildOrder = (item as AugmentedActivity).dataModules.flatMap(m => (m as AugmentedModule).childOrder
          .filter(o => !siblings.includes(o))) ?? [];
        childOrder.push(...siblingChildOrder);
      }
    });
  };

  populateReferencedItems(itemToBeDeleted: NodeDeleteDetails, recipeSource: RecipeSources | undefined, childOrder: string[]) {
    childOrder.forEach(c => {
      const templateSource = this.recipeService.RecipeSources.find(r => r.instanceId === c);
      if (templateSource) {
        itemToBeDeleted.childTemplates?.push(templateSource.referenceId);
      } else {
        const referencedRecipe = this.recipeService.RecipeSources.find(r => r.referenceId !== recipeSource?.referenceId
          && r.childInstanceIds?.includes(c));
        if (referencedRecipe && !itemToBeDeleted.childRecipes?.some(cr => cr.instanceId === referencedRecipe.instanceId)) {
          itemToBeDeleted.childRecipes?.push({ recipeId: referencedRecipe.referenceId, number: referencedRecipe.referenceNumber ?? '',
            instanceId: referencedRecipe.instanceId ?? ''
           });
        }
      }
    });
  }

  findRecipeItem(recipeItem: any) {
    const recipe = this.recipeService.currentRecipe;
    const modules = recipe.modules ?? [];
    const activities = recipe.activities ?? [];
    const allDataModules = activities.flatMap(activity => activity.dataModules ?? []);
    const allModules = modules.concat(allDataModules);

    return allModules.find(module =>
      module.items.some(item => item.templateId === recipeItem.templateId)
    );
  }

  isSynthetic(item: any, isRecipeNode: boolean) {
    return item && 'isSynthetic' in item && item.isSynthetic && !isRecipeNode;
  }

  private getItemIdToBeDeleted(recipeItem: NodeTypes, type: TemplateType, isRecipe: boolean): NodeDeleteDetails {
    switch (type) {
      case TemplateType.Activity:
        return this.getActivityItem(recipeItem as Activity, isRecipe);
      case TemplateType.Module:
        return this.getModuleItem(recipeItem as Module, type, isRecipe);
      case TemplateType.Form:
        return this.getFormItem(recipeItem as Form, type, isRecipe);
      case TemplateType.Table:
        return this.getTableItem(recipeItem as Table, type, isRecipe);
      default:
        return this.defaultNodeDetails;
    }
  }

  getActivityItem(recipeItem: Activity, isRecipe: boolean): NodeDeleteDetails {
    const activityId = recipeItem.activityId;
    const isSynthetic = this.isSynthetic(recipeItem, (recipeItem as AugmentedActivity).isRecipeNode);
    const activityItem = {
      itemId: activityId,
      templateId: isRecipe ? this.getRecipeId(activityId) : recipeItem.sourceTemplateId,
      type: isRecipe ? this.getRecipeTypeOnActivityDelete(activityId,) : TemplateType.Activity,
      isSynthetic: isSynthetic,
      number: isSynthetic ? '' : this.getNumber(activityId, isRecipe),
      childRecipes: [],
      childTemplates: [],
      referencedRecipeId: isRecipe ? this.getReferencedRecipeId(recipeItem.activityId) : undefined,
      isRecipe: isRecipe
    };
    this.getChildSources(activityItem, isRecipe);
    return activityItem;
  }

  getModuleItem(recipeItem: Module, type: TemplateType, isRecipe: boolean): NodeDeleteDetails {
    const moduleId = recipeItem.moduleId;
    const sourceTemplateId = recipeItem.sourceTemplateId;
    const isSynthetic = this.isSynthetic(recipeItem, (recipeItem as AugmentedModule).isRecipeNode);
    const isRecipeChild: boolean = this.recipeService.currentRecipe.childOrder.some(x => x === moduleId);
    const hasSyntheticParent = this.hasSyntheticParent(recipeItem, type, isRecipe) && this.recipeService.currentRecipe.activities.some(act => act.sourceTemplateId === sourceTemplateId) &&
      recipeItem.items.some(item => {
        switch (item.itemType) {
          case NodeType.Form:
            return this.checkSyntheticParentForRecipe((item as Form).formId, sourceTemplateId ?? '') ? true : (item as Form).formId === sourceTemplateId;
          case NodeType.Table:
            return this.checkSyntheticParentForRecipe((item as Table).tableId, sourceTemplateId ?? '') ? true : (item as Table).tableId === sourceTemplateId;
          default:
            return false; // Handle default case if necessary
        }
      });
    return this.getSyntheticModuleItem(recipeItem, hasSyntheticParent, isSynthetic, isRecipeChild, type, isRecipe);
  }

  getSyntheticModuleItem(recipeItem: Module, hasSyntheticParent: boolean, isSynthetic: boolean, isRecipeChild: boolean,
    type: TemplateType, isRecipe: boolean) {
    const moduleId = recipeItem.moduleId;
    const sourceTemplateId = recipeItem.sourceTemplateId;
    if (!isSynthetic) {
      return this.getNonSyntheticModuleItem(recipeItem, isSynthetic, isRecipeChild, type, isRecipe);
    } else if (isRecipeChild || !hasSyntheticParent) {
      // Synthetic Module logic
      const moduleItem = {
        itemId: moduleId,
        templateId: sourceTemplateId,
        type: TemplateType.Module,
        isSynthetic: isSynthetic,
        number: this.getNumber(moduleId, isRecipe),
        childRecipes: [],
        childTemplates: [],
        referencedRecipeId: isRecipe ? this.getReferencedRecipeId(moduleId) : undefined,
        isRecipe: isRecipe
      };
      this.getChildSources(moduleItem, isRecipe);
      return moduleItem;
    } else {
      // Module is not a recipe child and has a synthetic parent
      return this.getActivityId(moduleId);
    }
  }

  getNonSyntheticModuleItem(recipeItem: Module, isSynthetic: boolean, isRecipeChild: boolean,
    type: TemplateType, isRecipe: boolean) {
    const moduleId = recipeItem.moduleId;

    const conditionsMet = isRecipeChild ? true : this.checkSources(moduleId, recipeItem, type, isRecipe);
    // Non-synthetic Module logic
    if (conditionsMet) {
      const moduleItem = {
        itemId: moduleId,
        templateId: isRecipe ? this.getRecipeId(moduleId) : recipeItem.sourceTemplateId,
        type: TemplateType.Module,
        isSynthetic: isSynthetic,
        number: this.getNumber(moduleId, isRecipe),
        childRecipes: [],
        childTemplates: [],
        referencedRecipeId: isRecipe ? this.getReferencedRecipeId(moduleId) : undefined,
        isRecipe: isRecipe
      };
      this.getChildSources(moduleItem, isRecipe);
      return moduleItem;
    } else {
      // Module is not a recipe child and does not exist in sources
      return this.getActivityId(moduleId);
    }
  }

  getFormItem(recipeItem: Form, type: TemplateType, isRecipe = false): NodeDeleteDetails {
    const formId = recipeItem.formId;
    // Determine if Table is a recipe child or meets other conditions
    const isRecipeChild = this.recipeService.currentRecipe.childOrder.includes(formId);
    const meetsConditions = isRecipeChild ? true : this.checkSources(recipeItem.formId, recipeItem, type, isRecipe);

    // Check if Table should be returned directly
    if (isRecipeChild || meetsConditions) {
      return {
        itemId: formId,
        templateId: isRecipe ? this.getRecipeId(recipeItem.formId) : recipeItem.sourceTemplateId,
        type: isRecipe ? this.getRecipeType(formId, type) : TemplateType.Form,
        isSynthetic: false,
        number: this.getNumber(formId, isRecipe),
        referencedRecipeId: isRecipe ? this.getReferencedRecipeId(recipeItem.formId) : undefined,
        isRecipe: isRecipe
      };
    }
    // If not, check for related Module and return its details using getModuleItem
    const module = this.getModule(formId) as Module;
    return module ? this.getModuleItem(module, TemplateType.Module, (module as AugmentedModule).isRecipeNode)
      : this.defaultNodeDetails;
  }

  getTableItem(recipeItem: Table, type: TemplateType, isRecipe = false): NodeDeleteDetails {
    const tableId = recipeItem.tableId;
    // Determine if Table is a recipe child or meets other conditions
    const isRecipeChild = this.recipeService.currentRecipe.childOrder.includes(tableId);
    const meetsConditions = isRecipeChild ? true : this.checkSources(recipeItem.tableId, recipeItem, type, isRecipe);

    // Check if Table should be returned directly
    if (isRecipeChild || meetsConditions) {
      return {
        itemId: tableId,
        templateId: isRecipe ? this.getRecipeId(tableId) : recipeItem.sourceTemplateId,
        type: isRecipe ? this.getRecipeType(tableId, type) : TemplateType.Table,
        isSynthetic: false,
        number: this.getNumber(tableId, isRecipe),
        referencedRecipeId: isRecipe ? this.getReferencedRecipeId(tableId) : undefined,
        isRecipe: isRecipe
      };
    }
    // If not, check for related Module and return its details using getModuleItem
    const module = this.getModule(tableId) as Module;
    return module ? this.getModuleItem(module, TemplateType.Module, (module as AugmentedModule).isRecipeNode)
      : this.defaultNodeDetails;
  }

  checkSyntheticParentForRecipe(itemId: string, sourceTemplateId: string): boolean {
    const instanceId = this.getRecipeId(itemId);
    return instanceId === sourceTemplateId;
  }

  checkSyntheticModuleChildren(module: AugmentedModule, itemId: string, isRecipe: boolean) {
    if (isRecipe) {
      const recipeSource = this.recipeService.RecipeSources.find(r => r.childInstanceIds?.includes(itemId));
      if (recipeSource?.instanceId && module.sourceTemplateId === recipeSource?.instanceId) {
        return true;
      }
      else if (module.childOrder.length === recipeSource?.childInstanceIds?.length) {
        return true;
      }
      else {
        return false;
      }
    } else {
      return module.items.length === 1;
    }
  }

  hasSyntheticParent(recipeItem: NodeTypes, itemType: TemplateType, isRecipe: boolean): boolean {
    if (recipeItem) {
      switch (itemType) {
        case TemplateType.Form: {
          const form = (recipeItem as Form);
          const module: Module | undefined = this.getModule(form.formId);
          const isRecipeNode = (module as AugmentedModule).isRecipeNode;
          return (this.isSynthetic(form, isRecipeNode) && module && this.isSynthetic(module, isRecipeNode))
            || (module && this.isSynthetic(module, isRecipeNode) && this.checkSyntheticModuleChildren((module as AugmentedModule), form.formId, isRecipe));
        }
        case TemplateType.Table: {
          const table = (recipeItem as Table);
          const moduleTemp: Module | undefined = this.getModule(table.tableId);
          const isRecipeNode = (moduleTemp as AugmentedModule).isRecipeNode;
          return (this.isSynthetic(table, isRecipeNode) && moduleTemp && this.isSynthetic(moduleTemp, isRecipeNode))
            || (moduleTemp && this.isSynthetic(moduleTemp, isRecipeNode) && this.checkSyntheticModuleChildren((moduleTemp as AugmentedModule), table.tableId, isRecipe));
        }
        case TemplateType.Module: {
          return this.hasSyntheticParentForModule(recipeItem);
        }
        default:
          return false; // If the itemType is not recognized, return false
      }
    }
    else {
      return false;
    }
  }

  hasSyntheticParentForModule(recipeItem: NodeTypes) {
    const module = recipeItem as AugmentedModule;
    const actTemplate = this.recipeService.currentRecipe.activities.find(act => act.dataModules.some(a => a.moduleId === module.moduleId));
    const isRecipeNode = actTemplate ? (actTemplate as AugmentedActivity).isRecipeNode : false;
    const activitySource = module.isRecipeNode ? this.getRecipeId(module.moduleId) === actTemplate?.sourceTemplateId
      : actTemplate?.sourceTemplateId === module.moduleId;
    return (this.isSynthetic((recipeItem as Module), (recipeItem as AugmentedModule).isRecipeNode) && actTemplate && this.isSynthetic(actTemplate, isRecipeNode))
      || (actTemplate && this.isSynthetic(actTemplate, isRecipeNode) && (activitySource || module.isSynthetic));
  }

  checkSources(itemId: string, recipeItem: NodeTypes | undefined, itemType: TemplateType, isRecipe = false): boolean {
    if (this.hasSyntheticParent(recipeItem as any, itemType, isRecipe)) return false;

    if (isRecipe && this.checkRecipeSources(itemId, itemType)) {
      return true;
    }
    else if (recipeItem && this.recipeService.RecipeSources.some(r => r.instanceId === itemId)) {
      return true;
    }
    else {
      return false;
    }
  }

  checkRecipeSources(itemId: string, itemType: TemplateType) {
    // If recipe and the node which are under deletion is not direct source then
    // Get the module under which the node is added or belongs to
    // Then check if the module node is recipe; if yes then return false else return true
    // true means that the node is from a table/form recipe so the entire recipe can be deleted
    const moduleNode = [TemplateType.Form, TemplateType.Table].includes(itemType) ? this.getModule(itemId) : undefined;
    if (moduleNode) {
      const referencedRecipe = this.recipeService.RecipeSources.find(r => r.childInstanceIds?.includes(itemId));
      if (referencedRecipe?.childInstanceIds?.includes(moduleNode?.moduleId)) {
        return false;
      }
    } else {
      const activityNode = this.recipeService.currentRecipe.activities.find(a => a.dataModules.some(m => m.moduleId === itemId));
      const referencedRecipe = this.recipeService.RecipeSources.find(r => r.childInstanceIds?.includes(itemId));
      if (activityNode && referencedRecipe?.childInstanceIds?.includes(activityNode?.activityId)) {
        return false;
      }
    }
    return true;
  }

  getRecipeId(itemId: string): string | undefined {
    return this.recipeService.RecipeSources.find(r => r.childInstanceIds?.includes(itemId))?.instanceId;
  }

  getReferencedRecipeId(itemId: string): string | undefined {
    return this.recipeService.RecipeSources.find(r => r.childInstanceIds?.includes(itemId))?.referenceId;
  }

  // Determines if the deleted is part of tableForm recipe or not
  getRecipeType(itemId: string, itemType: TemplateType): TemplateType {
    const childInstanceIds = this.recipeService.RecipeSources.find(r => r.childInstanceIds?.includes(itemId))?.childInstanceIds;
    let hasForm = false;
    let hasTable = false;

    const module = this.getRecipeModules().find(m => (m as AugmentedModule).childOrder.includes(itemId));
    if (module) {
      hasForm = module.items.some(i => i.itemType === NodeType.Form && childInstanceIds?.includes((i as AugmentedForm).formId));
      hasTable = module.items.some(i => i.itemType === NodeType.Table && childInstanceIds?.includes((i as AugmentedTable).tableId));
    }

    if (itemType === TemplateType.Form && (this.recipeService.currentRecipe.tables.find(t => childInstanceIds?.includes(t.tableId)) || hasTable)) {
      return TemplateType.TableForm;
    }
    else if (itemType === TemplateType.Table && (this.recipeService.currentRecipe.forms.find(f => childInstanceIds?.includes(f.formId)) || hasForm)) {
      return TemplateType.TableForm;
    }
    else {
      return itemType;
    }
  }

  getRecipeTypeOnActivityDelete(itemId: string): TemplateType {
    const childInstanceIds = this.recipeService.RecipeSources.find(r => r.childInstanceIds?.includes(itemId))?.childInstanceIds;
    if (childInstanceIds) {
      const activityCount = this.recipeService.currentRecipe.activities.filter(a => childInstanceIds.includes(a.activityId)).length;
      if (activityCount > 1) {
        return TemplateType.ActivityGroup;
      }
    }
    return TemplateType.Activity;
  }

  getNumber(itemId: string, isRecipe: boolean) {
    if (!isRecipe) return '';
    const source = this.recipeService.RecipeSources.find(r => r.childInstanceIds?.includes(itemId));
    return source!.referenceNumber!;
  }

  getRecipeModules() {
    return [RecipeType.Activity, RecipeType.ActivityGroup].includes(this.recipeService.currentRecipe.type)
      ? this.recipeService.currentRecipe.activities.flatMap(a => a.dataModules)
      : this.recipeService.currentRecipe.modules;
  }
}
