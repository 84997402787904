import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BptSliderComponent } from 'bpt-ui-library/bpt-slider';
import { ApplyStatementCommand, StatementContextType, StatementContentDetails, StatementResponse } from '../../../api/data-entry/models';
import { ClientFacingNoteContextType, WitnessingStatementLookupResponse } from '../../../api/models';
import { ConfigurationService } from '../../../services/configuration.service';
import { FeatureService } from '../../../services/feature.service';
import { UserService } from '../../../services/user.service';
import { AuthResult, AuthStatusType } from '../../../shared/authentication-maintainance/model/auth.model';
import { AuthenticationHelperService } from '../../../shared/authentication-maintainance/services/authentication-helper.service';
import { ELNFeatureFlags } from '../../../shared/eln-feature-flags';
import { ShowClientFacingNotesEventData, TableCellClientFacingNoteContext } from '../client-facing-note/client-facing-note-event.model';
import { StatementService as dataentryStatementService } from './../../../../app/api/data-entry/services/statement.service';
import { StatementsService as WitnessingStatementLookupService } from './../../../api/services/statements.service';
import { Statement } from './models/statement';
import { StatementModel } from './models/statement-model';
import { StatementsService } from './statements.service';
import { UserDefinedCustomStatement } from './models/user-defined-statement';
import { MessageService } from 'primeng/api';
import { StatementType } from './models/statement-type';
import { Subscription } from 'rxjs';
import { UnsubscribeAll } from '../../../shared/rx-js-helpers';
import { LocalizeHelper } from '../../../shared/localize-helper';

@Component({
  selector: 'app-statements',
  templateUrl: './statements.component.html',
  styleUrls: ['./statements.component.scss']
})
export class StatementsComponent implements OnInit {
  witnessed = 'witnessed';
  disableApplyButton = false;
  @ViewChild('slider') slider!: BptSliderComponent;
  @Input() showBackArrow = false;
  public selectedStatements: ( Statement | UserDefinedCustomStatement)[] = [];
  randomStatement = '';
  currentStatement!: StatementModel;
  @Output() whenStatementAdded: EventEmitter<StatementModel> = new EventEmitter();
  public applyStatementButton = $localize`:@@applyStatementButton:Apply Statement`;
  subscriptions: Subscription[] = [];

  sliderOptions = {
    size: 'medium',
    visible: false,
    closeOnEscape: true,
    headerText: $localize`:@@preDefinedStatements:Predefined Statements`,
    isFooterSticky: false,
    displayFooter: false,
    displayFooterWithPrimaryButton: false,
    displayFooterWithSecondaryButton: false,
    isModal: false,
    showCloseIcon: true
  };
  isStatement = false;
  preDefinedStatements: Statement[] = [];
  disableSelection = false;
  savedCustomStatementsLoading = true;
  savedCustomStatements: UserDefinedCustomStatement[] = [];


  constructor(private readonly statementsService: StatementsService, private readonly userService: UserService,
    private readonly featureService: FeatureService,
    private readonly witnessingStatementLookupService: WitnessingStatementLookupService,
    private readonly authenticationHelperService: AuthenticationHelperService,
    private readonly dataentryStatementService: dataentryStatementService,
    private readonly elementRef: ElementRef,
    private readonly messageService: MessageService
  ) { }
  ngOnInit(): void {
    this.handleSubscriptions();
    this.resetRandomStatement();
    this.onFirstDataRendered();
    this.checkStatementType();
    this.disableApplyStatement();
  }

  private handleSubscriptions() {
    this.subscriptions.push(this.statementsService.customStatementsLoaded.subscribe({
      next: (response: UserDefinedCustomStatement[]) => {
        this.savedCustomStatements = response;
        this.savedCustomStatementsLoading = false;
      }
    }));
  }

  ngOnDestroy(): void {
    UnsubscribeAll(this.subscriptions);
  }

  onFirstDataRendered() {
    this.witnessingStatementLookupService.witnessStatementsPredefinedLookupsGet$Json()
      .subscribe((response: WitnessingStatementLookupResponse) => {
        this.preDefinedStatements = this.isAnalysistOrAnalystAndReviewer()
          ? response
            .statements.filter(f => f.statementType === this.witnessed)
            .map(m => {
              return {
                selected: false,
                statement: LocalizeHelper.translateMessage(m.translationKey, m.statement),
                translationKey: LocalizeHelper.translateMessage(m.translationKey, m.statement)
              }
            })
          : response.statements.map(r => {
            return {
              selected: false,
              statement: LocalizeHelper.translateMessage(r.translationKey, r.statement),
              translationKey: LocalizeHelper.translateMessage(r.translationKey, r.statement)
            }
          });
      });
    this.statementsService.loadStatements.subscribe((statementModel: StatementModel) => {
      this.currentStatement = statementModel
      this.showBackArrow = statementModel.showBackButton ?? false;
      this.sliderOptions.showCloseIcon = !this.showBackArrow;
      this.isStatement = this.sliderOptions.visible = true;
      this.clearSelectedStatements();
      this.disableApplyStatement();
    });
  }

  private isAnalysistOrAnalystAndReviewer(): boolean {
    return this.userService.hasOnlyAnalystRights() || (this.userService.hasOnlyAnalystRights() && this.userService.hasOnlyReviewerRights());
  }

  closeSlider(event: MouseEvent): void {
    this.resetRandomStatement();
    this.slider.closeSlider(event);
  }
  toggleSlider(sliderOpen: boolean) {
    if (!sliderOpen) {
      this.preDefinedStatements = [];
      this.onFirstDataRendered();
    }
    this.statementsService.sliderVisibilityChanged.next(sliderOpen);
    this.clearSelectedStatements();
    this.resetRandomStatement();
  }

  disableApplyStatement(): boolean {
    this.disableApplyButton = (!this.checkStatementType() || this.disableSelection)
    return this.disableApplyButton;
  }

  applyStatement(): void {
    this.disableSelection = true;
    this.disableApplyStatement();
    this.disableApplyButton = true;
    this.reauthBeforeApplyingStatement(this.saveStatement.bind(this));
    this.checkStatementType();
  }


  public processCreateResponse(response: StatementResponse) {
    this.statementsService.statementAdded.next(response.statementAppliedEventNotification);
    if (response.statementAppliedEventNotification.contextType === StatementContextType.TableCell) {
      this.showClientFacingNotes(response.statementAppliedEventNotification.contentDetails[0].path[0], response.statementAppliedEventNotification.contentDetails[0].path[1], response.statementAppliedEventNotification.nodeId);
    }
  }

  showClientFacingNotes(rowId: string, field: string, tableId: string): void {
    const eventTarget: TableCellClientFacingNoteContext = {
      tableId: tableId,
      columnField: field,
      rowId: rowId
    };
    const details: ShowClientFacingNotesEventData = {
      eventContext: {
        contextType: ClientFacingNoteContextType.TableCell,
        mode: 'clientFacingNotes'
      },
      targetContext: eventTarget
    };
    const customEvent = new CustomEvent<ShowClientFacingNotesEventData>('ShowSlider', {
      bubbles: true,
      detail: details
    });
    this.elementRef.nativeElement.dispatchEvent(customEvent);
  }

  saveStatement = () => {
    const puid = this.userService.currentUser.puid;
    const userName = `${this.userService.currentUser.firstName} ${this.userService.currentUser.lastName}`;
    const contentDetails: StatementContentDetails[] = [];
    const statementsSelected = this.randomStatement.length > 0 ? this.selectedStatements.concat({
      selected: true,
      statement: this.randomStatement
    }) : this.selectedStatements;
    statementsSelected.forEach(s => {
      const statementValue = s.statement;
      const contentValue = {
        statementValue,
        puid,
        userName
      }
      this.currentStatement.pathDetails?.forEach(x => {
        if (x.path) {
          contentDetails.push({
            content: JSON.stringify(contentValue),
            number: 0,
            path: x.path
          })
        }
      })
    })

    this.clearSelectedStatements();
    if (contentDetails.length === 0) return;
    const command: ApplyStatementCommand = {
      contentDetails: contentDetails,
      contextType: this.currentStatement.contextType,
      cfnNumber: this.currentStatement.cfnNumber,
      experimentId: this.currentStatement.experimentId,
      nodeId: this.currentStatement.nodeId
    }

    this.dataentryStatementService.statementEventsApplyPost$Json({
      body: command
    }).subscribe((response: StatementResponse) => {
      this.processCreateResponse(response);
      this.slider?.closeSlider({});
    });
  }

  clearSelectedStatements() {
    this.selectedStatements = [];
    this.preDefinedStatements.forEach(x => x.selected = false);
    this.statementsService.clearSelectedStatements.next(true);
    this.disableApplyStatement();
  }


  private reauthBeforeApplyingStatement(transitionCallback: () => void): void {
    if (!this.featureService.isEnabled(ELNFeatureFlags.EnableESignatureForExperimentTransition)
      || !ConfigurationService.isLimsHosted) {
      transitionCallback();
      return;
    }
    (window as any).Master?.oidcTokenManager?.checkFamsAndGetWindowPopupForReauth();
    const request = {
      successCallback: (authResult: AuthResult) => {
        if (authResult && authResult.status === AuthStatusType.Success && authResult.token) {
          transitionCallback();
        }
      },
      messageForUnauthorizedCredentials: $localize`:@@TransitionInvalidCredentials:Credentials are incorrect and the status update was not successful.`
    };
    this.authenticationHelperService.handleReauthenticationResponse(request);
  }

  onSelectedItemsChanged(selectedItem: Statement | UserDefinedCustomStatement) {
    if (selectedItem.selected) {
      const existingSelectedItem = this.selectedStatements.find((s: any) => s.index && (s.index === (selectedItem as UserDefinedCustomStatement).index))
      if(!existingSelectedItem)
        this.selectedStatements.push(selectedItem);
    }
    else {
      const index = this.selectedStatements.findIndex(x => x.statement === selectedItem.statement);
      this.selectedStatements.splice(index, 1);
    }
    this.validateMulticategoryStatements(selectedItem);
    this.disableApplyStatement();
  }

  setTypeOfStatements(selectedItem: Statement | UserDefinedCustomStatement) {
    const predefinedSelected = this.preDefinedStatements.some(p => p.statement === selectedItem?.statement && p.selected);
    const customSelected = this.savedCustomStatements.some(c => c.statement === selectedItem?.statement && c.selected);
    if (predefinedSelected)
      selectedItem.type = StatementType.Predefined;
    else if (customSelected)
      selectedItem.type = StatementType.Custom
  }

  validateMulticategoryStatements(selectedItem: Statement | UserDefinedCustomStatement) {
    this.setTypeOfStatements(selectedItem);
    this.disableSelection = !this.checkStatementType()
  }

  checkStatementType(): boolean {
    const selectedStatements = this.selectedStatements.filter((item) => item.selected);
    if (selectedStatements.length === 0 && this.randomStatement.length === 0) {
      return false;
    }
    const firstType = selectedStatements[0]?.type;
    for (const item of selectedStatements) {
      if (item.type !== firstType) {
        return false;
      }
    }
    if (selectedStatements.length > 0) {
      if (this.randomStatement.length > 0) {
        return false;
      }
      else if (this.randomStatement.length === 0) {
        this.checkForMulticategory();
      }
    }
    else if (this.randomStatement.length > 0) {
      this.disableSelection = false;
      return true;
    }
    return true;
  }

  checkForMulticategory() {
    const selectedStatements = this.selectedStatements.filter((item) => item.selected);
    const firstType = selectedStatements[0]?.type;
    for (const item of selectedStatements) {
      if (item.type !== firstType) {
        return false;
      }
      this.disableSelection = false;
      return true
    }
    return;
  }

  onStatementsChanged(randomStatement: string) {
    this.randomStatement = randomStatement?.trim();
    this.checkStatementType();
    this.disableApplyStatement();
  }

  resetRandomStatement() {
    this.randomStatement = '';
    this.checkStatementType();
    this.disableApplyStatement();
  }
}

export type SubmissionContext = {
  path: string[];
  nodeId: string;
};
