/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { FileUploadedResponse as ElnFileIntegrationApiFileFileUploadedResponse } from '../../models/ELN/FileIntegration/Api/File/file-uploaded-response';

export interface FilesUploadPost$Json$Params {
  'X-User-Puid'?: any;
  'X-User-LabsiteCode'?: any;
      body?: {
'ExperimentId'?: string;
'ActivityId'?: string;
'FolderName'?: string;
'UploadedData'?: Array<Blob>;
}
}

export function filesUploadPost$Json(http: HttpClient, rootUrl: string, params?: FilesUploadPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ElnFileIntegrationApiFileFileUploadedResponse>> {
  const rb = new RequestBuilder(rootUrl, filesUploadPost$Json.PATH, 'post');
  if (params) {
    rb.header('X-User-Puid', params['X-User-Puid'], {"style":"simple"});
    rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {"style":"simple"});
    rb.body(params.body, 'multipart/form-data');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ElnFileIntegrationApiFileFileUploadedResponse>;
    })
  );
}

filesUploadPost$Json.PATH = '/files/upload';
