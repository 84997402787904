import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  Renderer2,
  AfterViewChecked,
  OnDestroy,
} from '@angular/core';
import {
  CommentCollection,
  Comment,
  NewComment,
  CommentEdited,
  NewReply
} from 'bpt-ui-library/bpt-comments';
import { CommentDetails } from '../comment.model';
import {
  CommentContextType,
  CommentResponse,
  CommentsResponse,
} from '../../../api/internal-comment/models';

import { BaseComponent } from '../../../base/base.component';
import { ActivatedRoute } from '@angular/router';
import { ClientStateService } from 'services/client-state.service';
import { ELNAppConstants } from '../../../shared/eln-app-constants';
import { CommentService } from '../comment.service';
import { ExperimentService } from '../../services/experiment.service';
import { Subscription } from 'rxjs';
import { UnsubscribeAll } from '../../../shared/rx-js-helpers';
import { DateAndInstantFormat, formatInstant } from '../../../shared/date-time-helpers';
import { ExperimentNodeRetitleService } from '../../services/experiment-node-re-title.service';

type ClientComment = any;
@Component({
  selector: 'app-internal-comments',
  templateUrl: './internal-comments.component.html',
  styleUrls: ['./internal-comments.component.scss']
})
export class InternalCommentsComponent extends BaseComponent implements OnInit, AfterViewChecked,OnDestroy {
  path: string[] = [];
  constructor(
    public readonly clientStateService: ClientStateService,
    private readonly route: ActivatedRoute,
    private readonly renderer: Renderer2,
    public readonly commentService: CommentService,
    public readonly experimentNodeRetitleService: ExperimentNodeRetitleService
  ) {
    super(clientStateService, route);
  }

  @Output() toggleCommentBox: EventEmitter<boolean> = new EventEmitter<boolean>();
  users: any = [];
  internalCommentDefaultDetails: CommentDetails = {} as CommentDetails;
  hidePrimaryCommentEditor = false;
  isCommentsVisible = false;
  activities: any = [];
  changed = false;
  experimentId = '';
  isStyleApplied = false;
  isCommentOrReplyChanged = false;
  isCommentAdded = false;
  controlName = '';
  userRole = $localize`:@@internalCommentsRole:ELN Analyst`;
  uniqueId = '';
  comment: Comment<any> = {} as Comment<any>;
  commentDetails = {} as CommentCollection<ClientComment>;
  ResolvedCommentDetails?: CommentResponse[];
  private readonly subscriptions: Subscription[] = [];
  options = {
    cancelLabel: $localize`:@@confirmationNo:No`,
    acceptLabel: $localize`:@@confirmationYes:Yes`,
    position: 'center',
    closeOnEscape: true,
    dismissableMask: true
  };
  sliderOptions = {
    size: 'medium',
    // Options : 'large','medium','small'
    visible: false,
    closeOnEscape: true,
    headerText: $localize`:@@commentsHeader:Internal Comments`,
    isFooterSticky: false,
    displayFooter: false,
    displayFooterWithPrimaryButton: false,
    displayFooterWithSecondaryButton: false,
    isModal: false
  };
  iconEdit = 'pi pi-user-edit';
  iconTrash = 'pi pi-trash';
  iconCheck = 'pi pi-check';

  ngOnInit(): void {
    this.receiveInternalCommentDetails();
  }

  private receiveInternalCommentDetails() {
    this.subscriptions.push(this.experimentNodeRetitleService.retitleOcurredSubject.subscribe(success => {
      if (success && this.sliderOptions.visible) {
        this.commentService.openInternalComments(this.commentService.commentIdRefresh);
      }
    }));
    this.subscriptions.push(this.commentService.loadInternalComments.subscribe((currentContext: CommentDetails) => {
      if (currentContext.contextType === CommentContextType.TableCell) {
        this.setCellStyle(currentContext);
      }
      const commentsResponse = this.commentService.getCurrentExperiment()?.internalComments;
      if (commentsResponse) {
        this.commentService.internalCommentsResponse = commentsResponse;
      }
      this.commentService.internalCommentDefaultDetails = currentContext;
      this.path = currentContext.path;
      this.isCommentsVisible = this.isStyleApplied = this.sliderOptions.visible = true;
      const experimentId = this.commentService.getCurrentExperiment()?.id;
      if(experimentId){
        this.experimentId = experimentId;
      }
      this.uniqueId = this.path.join('-');
      this.commentDetails = this.commentService.getInternalComments();
      this.ResolvedCommentDetails = this.commentService.getResolvedInternalComments();
      this.commentDetails.Comments.forEach(comment => {
        comment.CommentedOn = comment.CommentedOn ? formatInstant(comment.CommentedOn, DateAndInstantFormat.dateTimeToSecond) : '';
        comment.Replies.forEach( reply => {
          reply.CommentedOn = reply.CommentedOn ? formatInstant(reply.CommentedOn, DateAndInstantFormat.dateTimeToSecond) : ''
        })
      });
      this.togglePrimaryCommentBox();
    }));
  }

  setCellStyle(currentContext: CommentDetails) {
    //removed highlight cell from internal comment because it is the responsibility of consuming component to highlight required cell
    if (currentContext.path.join(`~`) !== this.path.join(`~`)) {
      this.resetCellStyle(this.commentService.internalCommentDefaultDetails.contextType);
    }
  }

  togglePrimaryCommentBox() {
    if (
      this.commentDetails?.Comments &&
      (this.commentDetails.Comments as Array<any>).find(
        (com) => com.Path.join(`~`) === this.path.join(`~`)
      )
    ) {
      this.isStyleApplied = this.hidePrimaryCommentEditor = true;
    } else {
      this.hidePrimaryCommentEditor = false;
      this.isStyleApplied = true;
    }
  }
  ngAfterViewChecked(): void {
    const element = document.getElementsByClassName('p-accordion-tab-active');
    const resolvedComment = 'resolved-comment';
    const glowComment = 'glow-comment';
    if (this.isStyleApplied) {
      setTimeout(() => {
        try {
          if (element !== null && element.length > 0) {
            this.isStyleApplied = false;
            this.renderer.addClass(element.item(0)?.lastChild?.childNodes.item(0), glowComment);
            this.renderer.addClass(element.item(0)?.firstChild?.childNodes.item(0), glowComment);
            if (element.item(1))
              this.renderer.addClass(element.item(1)?.children?.item(1)?.children[0], glowComment);
          }
          this.addGreyShadeToResolvedComments(resolvedComment);
        } catch (error) {
          console.info(error);
        }
      }, 0);
    }
  }
  private addGreyShadeToResolvedComments(resolvedComment: string) {
    this.ResolvedCommentDetails?.forEach((comment) => {
      const resolvedElement = document.getElementById(comment.commentId);
      if (resolvedElement !== null) {
        const replySection = resolvedElement.getElementsByClassName('p-accordion-content');
        if (replySection.length > 1) {
          this.renderer.addClass(replySection[1], resolvedComment);
        }
        if (resolvedElement?.firstChild?.firstChild?.firstChild !== null) {
          this.renderer.addClass(
            resolvedElement?.firstChild?.firstChild?.firstChild,
            resolvedComment
          );
        }
        if (resolvedElement?.firstChild?.childNodes.item(0) !== null) {
          this.renderer.addClass(
            resolvedElement?.lastChild?.childNodes?.item(1).childNodes.item(0),
            resolvedComment
          );
        }
        this.isStyleApplied = false;
      }
    });
  }

  submitComment(newComment: NewComment<string>) {
    this.isStyleApplied = false;
    if (this.commentService.submitComment(newComment, this.experimentId))
      this.hidePrimaryCommentEditor = false;
  }
  commentEdited(editedComment: CommentEdited<string>) {
    this.commentService.commentEdited(editedComment, this.experimentId);
  }

  newReply(newReply: NewReply<string>) {
    this.commentService.newReply(newReply, this.experimentId);
  }

  replyEdited(editedReply: CommentEdited<string>) {
    this.commentService.replyEdited(editedReply, this.experimentId);
  }

  primaryAction(comment: Comment<ClientComment>) {
    this.commentService.resolveComment(comment.Id);
  }

  toggleSlider(sliderOpen: boolean) {
    this.toggleCommentBox.emit(this.sliderOptions.visible);
    if(!sliderOpen) {
      this.commentService.internalCommentsSliderClosed.next(true);
      this.resetCellStyle(this.commentService.internalCommentDefaultDetails.contextType);
      this.isCommentsVisible = false;
    }
  }

  resetCellStyle(contextType: CommentContextType) {
    if (contextType === CommentContextType.TableCell) {
      const cell = document.querySelector(
        `ag-grid-angular [row-id="${this.path[2]}"] [col-id="${this.path[3]}"]`
      );
      if (cell) {
        this.renderer.setStyle(
          cell,
          'background-color', ELNAppConstants.BackGroundColorWhite
        );
      }
      else{
        const labItem = document.querySelector(
          `ag-grid-angular [row-id="${this.path[0]}"] [col-id="${this.path[1]}"]`);
          if (labItem) {
            this.renderer.setStyle(
              labItem,
              'background-color', ELNAppConstants.BackGroundColorWhite
            );
          }
      }
    }
    const highlightedElements = document.getElementsByClassName(
      'internal-comment-yellow-background'
    );

    Array.from(highlightedElements).forEach((ele) => {
      let styleString = '';
      styleString = `background-color: ${ELNAppConstants.BackGroundColorWhite}`;
      this.renderer.setAttribute(ele, 'style', styleString);
      this.renderer.removeClass(ele, 'internal-comment-yellow-background');
    });
  }
  commentHeaderClicked(comment:Comment<ClientComment>) {
    this.commentService.commentHeaderClicked(comment);
  }

  ngOnDestroy(): void {
    clearInterval(this.commentService.highLightingInterval);
    UnsubscribeAll(this.subscriptions)
  }
}

