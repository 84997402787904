<div class="eln-statement-layout">
  <div class="internal-comments-content-container" *ngIf="isStatement">
    <bpt-slider #slider
      id="eln-bptSlider"
      [(visible)]="sliderOptions.visible"
      [closeOnEscape]="sliderOptions.closeOnEscape"
      [size]="sliderOptions.size"
      [isFooterSticky]="sliderOptions.isFooterSticky"
      [displayFooter]="sliderOptions.displayFooter"
      [displayFooterWithPrimaryButton]="false"
      [displayFooterWithSecondaryButton]="false"
      [isModal]="sliderOptions.isModal"
      (visibleChange)="toggleSlider($event)"
      [showCloseIcon]="sliderOptions.showCloseIcon"
      (firstDataRendered)="onFirstDataRendered()"
      [isModal]="true">
      <img *ngIf="showBackArrow" src="assets/eln-assets/arrow-back.svg"
        class="ag-icon ag-custom-icon cursor-pointer statment-back"
        (click)="closeSlider($event)" alt="arrow-back.svg">
      <div class="eln-predefined-staements-header">{{sliderOptions.headerText}}</div>
      <div class="card flex justify-content-center">
        <div class="flex flex-column gap-2">
          <div *ngFor="let preDefinedStatement of preDefinedStatements" class="field-checkbox"
            style="margin-bottom: 5px;">
            <p-checkbox class="eln-checkBox statements-checkbox"
              [binary]="true"
              [(ngModel)]="preDefinedStatement.selected"
              [label]="preDefinedStatement.translationKey ? preDefinedStatement.translationKey : ''"
              (onChange)="onSelectedItemsChanged(preDefinedStatement)">
            </p-checkbox>
          </div>
        </div>
      </div>
      <app-user-defined-custom-statements (selectedStatementsChanged)="onSelectedItemsChanged($event)"
      (statementsChanged)="onStatementsChanged($event)">
      </app-user-defined-custom-statements>
      <div class="statement-apply-container">
        <p-button
          class="Cancel"
          styleClass="bpt-button-compact p-button-outlined"
          label="Cancel"
          i18n-label="@@confirmationCancel"
          (click)="clearSelectedStatements()"
          [disabled]="selectedStatements.length === 0"></p-button>
        <p-button
          class="Submit"
          styleClass="bpt-button-compact"
          label="Apply Statement"
          i18n-label="@@applyStatementButton"
          [disabled]="disableApplyButton"
          (click)="applyStatement()"></p-button>
      </div>
    </bpt-slider>
  </div>
</div>