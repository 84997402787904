export const ReferenceModuleHeader = $localize`:@@references:References`;
export const ActivityInputPageTitle = $localize`:@@activityInputPageTitle:Inputs`;
export const PreparationModuleHeader = $localize`:@@preparationModuleHeader:Preparations`;
export const CrossReferences = $localize`:@@crossReferences:Cross References`;
export const LabItemsModuleHeader = $localize`:@@labItemsModuleHeader:Lab Items`;
export const SampleAliquotsTitle = $localize`:@@SampleAliquots:Samples & Aliquots`;
export const StudyActivitiesTitle = $localize`:@@StudyActivities:Study Activities`;
export const LabItemsMaterialsTableTitle = $localize`:@@LabItemsMaterialsTableTitle:Materials`;
export const LabItemsInstrumentsTableTitle = $localize`:@@LabItemsInstrumentsTableTitle:Instruments`;
export const ConsumablesTableTitle = $localize`:@@LabItemsConsumableTableTitle:Consumables and Supplies`
export const CompendiaTitle = $localize`:@@compendia:Compendia`;
export const DocumentsTitle = $localize`:@@documents:Documents`;
export const OutPutsHeader = $localize`:@@outputs:Outputs`
export const LabItemsColumnsTableTitle = $localize`:@@LabItemsColumnsTableTitle:Columns`
export const rowAbbr = $localize`:@@designerPropertyItemRow:Row`
export const Modules = 'Modules';
export const References = 'References';
export const Materials = 'LabItems';
export const Inputs = 'Inputs';
export const Compendia = 'Compendia';
export const Documents = 'Documents';
export const LabItems = 'LabItems';
export const Preparations = 'Preparations';