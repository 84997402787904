import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable()
export class FilesTabHelper {
  readonly fileDeleteActionClicked = new Subject<DeletedFileHelper>();
  readonly fileDownloadActionClicked = new Subject<string>();
}

export interface DeletedFileHelper {
  fileId: string;
  fileName: string
}