import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { guid } from '../../../model/template.interface';
import { BptSliderComponent } from 'bpt-ui-library/bpt-slider';
import { BptDropdownComponent } from 'bpt-ui-library/bpt-dropdown';
import { Item } from '../../field-properties/field-properties.component';

@Component({
  selector: 'app-picklist-preview',
  templateUrl: './picklist-preview.component.html',
  styleUrls: ['./picklist-preview.component.scss']
})
export class PicklistPreviewComponent {
  @Input() visible = false;
  @Input() allPicklists: Array<Item> = [];
  @Input() chosenPicklist?: guid;
  
  @Output() chosenPicklistChange = new EventEmitter<guid>();

  @ViewChild('slider') slider?: BptSliderComponent;
  @ViewChild('dropdown') dropdown?: BptDropdownComponent;

  headerText: string = $localize`:@@picklistOptions:Picklist options`;

  get items(): any[] {
    return this.allPicklists.find(p => p.id === this.chosenPicklist)?.items ?? [];
  };

  onVisibleChanged(event: boolean) {
    this.visible = event;
    if (!this.visible) this.onClose();
  }

  close() {
    this.onVisibleChanged(false);
  }

  onClose() {
    this.chosenPicklistChange.emit(this.chosenPicklist);
  }
}
