import { FormResponse } from './../../../api/models/form-response';
import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild, OnDestroy, ElementRef, Renderer2 } from '@angular/core';
import { Form, Module, ModuleItem, Table } from '../../../model/experiment.interface';
import { ExperimentService } from '../../services/experiment.service';
import { FormTabOrderService } from '../../../services/form-tab-order.service';
import { TableComponent } from '../table/table.component';
import { FormComponent } from '../form/form.component';
import { ExperimentNodeReOrderService } from '../../services/experiment-node-re-order.service';
import { NodeType } from '../../../api/models';
import { OrderList } from 'primeng/orderlist';
import { Subscription } from 'rxjs';
import { UnsubscribeAll } from '../../../shared/rx-js-helpers';
import { ExperimentNodeOrderChangedNotification } from '../../../api/data-entry/models';
type ChangeModuleItemOrderUICommand = {
  previousIndex: number,
  currentIndex: number,
  item: {data: ModuleItem, source?: string}
}

type CdkDragAndDropConvertibleEvent = any;

@Component({
  selector: 'app-data-module[module]',
  templateUrl: './module.component.html',
  styleUrls: ['./module.component.scss']
})
export class ModuleComponent implements OnInit, OnChanges, OnDestroy {
  @Input() module!: Module;
  @Input() parentNodeId: string[] = [];
  @Input() dragTableOrForm = true;
  @ViewChild('moduleItemOrderList') moduleItemOrderList!: OrderList;

  /// Cast to Form. For use in component template
  asForm = (item: FormResponse | Table) => item as Form;

  /// Cast to Table. For use in component template
  asTable = (item: FormResponse | Table) => item as Table;

  tabOrderReservation: { [greatestTabOrder: number]: number } = {};
  nodeType = NodeType;
  subscriptions: Subscription[] = [];
  constructor(
    private readonly experimentService: ExperimentService,
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
    private readonly formTabOrderService: FormTabOrderService,
    public readonly experimentNodeReOrderService: ExperimentNodeReOrderService,
  ) {}

  ngOnInit() {
    this.buildTabOrderReservation();
    this.subscriptions.push(
      this.experimentNodeReOrderService.nodeOrderChanged.subscribe({
        next: (data) => {
          if (data.nodeType === this.nodeType.Table) {
            this.tableCollaboration(data);
          } else if (data.nodeType === this.nodeType.Form) {
            this.formCollaboration(data);
          }
        }
      })
    );
    this.buildTabOrderReservation();
    this.renderer.setAttribute(this.elementRef.nativeElement, 'data-id', this.module.moduleId);
    this.renderer.setAttribute(this.elementRef.nativeElement, 'data-title', this.module.moduleLabel);    
  }

  tableCollaboration(data: ExperimentNodeOrderChangedNotification) {
    const command = {
      previousIndex: this.moduleItemOrderList.value.findIndex(
        (moduleItem) => moduleItem.tableId === data.nodeId
      ),
      currentIndex: data.position,
      item: {
        data: 
        { ...this.moduleItemOrderList.value.find(
          (moduleItem) => moduleItem.tableId === data.nodeId
        ), ...{ _source: 'collaborative'}
        }
      }
    } as ChangeModuleItemOrderUICommand;
    this.moduleItemOrderList.onDrop(command as CdkDragAndDropConvertibleEvent);
  }

  formCollaboration(data: ExperimentNodeOrderChangedNotification) {
    const command = {
      previousIndex: this.moduleItemOrderList.value.findIndex(
        (moduleItem) => moduleItem.formId === data.nodeId
      ),
      currentIndex: data.position,
      item: {
        data: 
        { ...this.moduleItemOrderList.value.find(
          (moduleItem) => moduleItem.formId === data.nodeId
        ), ...{ _source: 'collaborative'}
        }
      }
    } as ChangeModuleItemOrderUICommand;
    this.moduleItemOrderList.onDrop(command as CdkDragAndDropConvertibleEvent);
  }

  onReorder(e: any) {
    if (e !== undefined && this.module.items !== undefined && e[0] && (!e[0]._source || e[0]._source !== 'collaborative')) {
      const newPosition = this.module.items?.findIndex((m) => m.templateId === e[0].templateId);
      const ItemToBeConsidered = this.module.items?.find((m) => m.templateId === e[0].templateId);
      const movedObject = e[0];

      if (movedObject && movedObject.itemType === this.nodeType.Table) {
        this.experimentNodeReOrderService.changeNodeReorder(
          this.nodeType.Table,
          movedObject.tableId,
          this.module.moduleId,
          newPosition,
          ItemToBeConsidered?.itemTitle as string,
          this.parentNodeId[0]
        );
      } else if (movedObject && movedObject.itemType === this.nodeType.Form) {
        this.experimentNodeReOrderService.changeNodeReorder(
          this.nodeType.Form,
          movedObject.formId,
          this.module.moduleId,
          newPosition,
          ItemToBeConsidered?.itemTitle as string,
          this.parentNodeId[0]
        );
      }
    }
  }

  ngOnChanges(_changes: SimpleChanges): void {
    this.buildTabOrderReservation();
  }

  public buildTabOrderReservation() {
    this.tabOrderReservation = this.formTabOrderService.buildTabOrderReservation(
      this.module,
      FormComponent.AdditionalTabOrderReservationCount,
      TableComponent.TabOrderReservationCount
    );
  }

  ngOnDestroy(): void {
    UnsubscribeAll(this.subscriptions);
  }
}
