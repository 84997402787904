import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-repeat-for-each-chooser',
  templateUrl: './repeat-for-each-chooser.component.html',
  styleUrls: ['./repeat-for-each-chooser.component.scss']
})
export class RepeatForEachChooserComponent {
  @Input() selectedRows!: any[];
  @Input() visible!: boolean;
  @Output() option = new EventEmitter<RepeatForEachOption>();

  input: RepeatForEachOption = RepeatForEachOption.Sample;
  repeatForEachHeader: string = $localize`:@@repeatForEach:Repeat for each`;

  submit(): void {
    this.option.emit(this.input);
    this.visible = false;
  }

  cancel(): void {
    this.visible = false;
  }

  show(): void {
    this.visible = true;
  }
}

export enum RepeatForEachOption {
  Sample = 'sample',
  Material = 'material',
  Instrument = 'instrument'
}
