/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { TemplateSearchFor } from '../../models/template-search-for';
import { TemplateSummary } from '../../models/template-summary';

export interface TemplatesTemplatesListGet$Json$Params {
  ReferenceIds?: Array<string>;
  puid?: string;
  owningLabsite?: string;
  getLatestVersion?: boolean;
  consumingLabsiteCodes?: string;
  subBusinessUnitCodes?: string;
  templateTypes?: string;
  templateEditors?: string;
  searchFor?: TemplateSearchFor;
  'X-User-Puid'?: any;
  'X-User-LabsiteCode'?: any;
}

export function templatesTemplatesListGet$Json(http: HttpClient, rootUrl: string, params?: TemplatesTemplatesListGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TemplateSummary>>> {
  const rb = new RequestBuilder(rootUrl, templatesTemplatesListGet$Json.PATH, 'get');
  if (params) {
    rb.query('ReferenceIds', params.ReferenceIds, {});
    rb.query('puid', params.puid, {});
    rb.query('owningLabsite', params.owningLabsite, {});
    rb.query('getLatestVersion', params.getLatestVersion, {});
    rb.query('consumingLabsiteCodes', params.consumingLabsiteCodes, {});
    rb.query('subBusinessUnitCodes', params.subBusinessUnitCodes, {});
    rb.query('templateTypes', params.templateTypes, {});
    rb.query('templateEditors', params.templateEditors, {});
    rb.query('searchFor', params.searchFor, {});
    rb.header('X-User-Puid', params['X-User-Puid'], {});
    rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<TemplateSummary>>;
    })
  );
}

templatesTemplatesListGet$Json.PATH = '/templates/templates-list';
