/* tslint:disable */
/* eslint-disable */
export enum FieldType {
  Textbox = 'textbox',
  Textblock = 'textblock',
  Checkbox = 'checkbox',
  List = 'list',
  EditableList = 'editableList',
  Textentry = 'textentry',
  Textarea = 'textarea',
  Datepicker = 'datepicker',
  Quantity = 'quantity',
  Specification = 'specification'
}
