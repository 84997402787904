<div>
  <p-tabView #tabview class="eln-prompt-tab-panel"
    (onChange)="onTabSelectionChanged($event)"
    orientation="left"
    [scrollable]="false">
    <ng-container *ngFor="let panel of tabPanels">
      <p-tabPanel *ngIf="panel.show">
        <ng-template pTemplate="header">{{panel.header}}</ng-template>
        <ng-template #promptsTemplate>
          <app-prompts-grid
            *ngIf = "panel.dataSource.length > 0"
            [columnDefinitions]="panel.columnDefinitions"
            [dataSource]="panel.dataSource"
            [promptType]="panel.type"
            [title]="panel.gridTitle"></app-prompts-grid>
        </ng-template>
        <ng-container *ngTemplateOutlet="promptsTemplate"></ng-container>
      </p-tabPanel>
    </ng-container>
  </p-tabView>
</div>