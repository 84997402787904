import { Injectable } from '@angular/core';
import { AuthRequest } from '../model/auth.model';
import { FamsHelperService } from './fams-helper.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationHelperService {
  windowVar = window as any;
  constructor(private readonly famsHelperService: FamsHelperService) {}

  handleReauthenticationResponse(request: AuthRequest) {
    if (this.windowVar.app?.features?.isFAMSEnabled) {
      this.famsHelperService.handleReauthenticationResponse(request);
    }
  }
}
