import { CommonModule } from '@angular/common';
import { NgModule, Provider } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BptDateTimeModule } from 'bpt-ui-library/bpt-datetime';
import { BptDropdownModule } from 'bpt-ui-library/bpt-dropdown';
import { BptQuantityModule } from 'bpt-ui-library/bpt-quantity';
import { BptGridModule } from 'bpt-ui-library/bpt-grid';
import { BptNumericInputModule } from 'bpt-ui-library/bpt-numeric-input';
import { BptProgressSpinnerModule } from 'bpt-ui-library/bpt-progress-spinner';
import { BptTagsModule } from 'bpt-ui-library/bpt-tags';
import { BptToolbarModule } from 'bpt-ui-library/bpt-toolbar';
import { BptTextInputModule } from 'bpt-ui-library/bpt-text-input';
import { BptTreeModule } from 'bpt-ui-library/bpt-tree';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { ContextMenuModule } from 'primeng/contextmenu';
import { FieldsetModule } from 'primeng/fieldset';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MessagesModule } from 'primeng/messages';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TabViewModule } from 'primeng/tabview';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { LayoutModule } from '../layout/layout.module';
import { OrganizationModule } from '../organization/organization.module';
import { TemplateLoaderModule } from '../template-loader/template-loader.module';
import { AuditHistoryComponent } from './audit-history/audit-history.component';
import { CoverComponent } from './cover/cover.component';
import { DataComponent } from './data/data.component';
import { FieldGroupComponent } from './data/field-group/field-group.component';
import { FieldComponent } from './data/field/field.component';
import { FormComponent } from './data/form/form.component';
import { ModuleComponent } from './data/module/module.component';
import { TableComponent } from './data/table/table.component';
import { ExperimentComponent } from './experiment.component';
import { EXPERIMENT_ROUTES } from './experiment.routes';
import { ViewsComponent } from './views/views.component';
import { CommentsComponent } from './comments/comments.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { ToastModule } from 'primeng/toast';
import { DropdownModule } from 'primeng/dropdown';
import { BptSliderModule } from 'bpt-ui-library/bpt-slider';
import { ClientNotificationModule } from '../client-notification/client-notification.module';
import { BarcodeScannerComponent } from './barcode-scanner/barcode-scanner.component';
import { ClientFacingNoteComponent } from './comments/client-facing-note/client-facing-note.component';
import { InputsComponent } from './inputs/inputs.component';
import { SampleTableComponent } from './inputs/sample-table/sample-table.component';
import { ActivityInputTestTableComponent } from './inputs/activity-input-test-table/activity-input-test-table.component';
import { BptMenuButtonModule } from 'bpt-ui-library/bpt-menu';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ExperimentWarningService } from './services/experiment-warning.service';
import { KeyFilterModule } from 'primeng/keyfilter';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ExperimentFlagsComponent } from './experiment-flags/experiment-flags.component';
import { NonDataRecordService } from './services/non-data-record.service';
import { RuleActionHandlerHostService } from './../rule-engine/handler/rule-action-handler-host.service';
import { RuleDisplayMessageHandlerService } from './../rule-engine/handler/rule-display-message-handler.service';
import { StudyActivityTableComponent } from './inputs/study-activity-table/study-activity-table.component';
import { GridPreferenceService } from './services/grid-preference.service';
import { InternalCommentsComponent } from './comments/internal-comments/internal-comments.component';
import { RuleSetVariableHandlerService } from './../rule-engine/handler/rule-set-variable-handler.service';
import { BptContextMenuModule } from 'bpt-ui-library/bpt-context-menu';
import { ProgressBarModule } from 'primeng/progressbar';
import { BptRichTextEditorModule } from 'bpt-ui-library/bpt-rich-text-editor';
import { InstrumentEventComponent } from './inputs/instrument-event/instrument-event.component';
import { ActivityInputMaintenanceEventComponent } from './inputs/activity-input-maintenance-event-table/activity-input-maintenance-event-table.component';
import { BptCommentsBoxModule } from 'bpt-ui-library/bpt-comments';
import { SharedModule } from '../shared/shared.module';
import { OutputsComponent } from './outputs/outputs.component';
import { LabItemsComponent } from './labItems/lab-items/lab-items.component';
import { LabItemsMaterialComponent } from './labItems/materials/lab-items-material/lab-items-material.component';
import { LabItemsInstrumentComponent } from './labItems/instruments/lab-items-instrument/lab-items-instrument.component';
import { ReferencesComponent } from './references/references.component';
import { BptElnGridRowActionsComponent } from './labItems/shared/bpt-eln-grid-row-actions.component';
import { StatusBasedWarningIconRendererComponent } from './labItems/materials/lab-items-material/state-based-warning-icon-renderer';
import { ExpiryDateBasedWarningIconRendererComponent } from './labItems/materials/lab-items-material/expirydate-based-warning-icon-renderer';
import { LabItemsService } from './labItems/lab-items.service';
import { DateBasedWarningIconRendererComponent } from './shared/date-based-warning-icon-renderer';
import { FormattedDateRendererComponent } from './inputs/shared/formatted-date-renderer';
import { ExperimentService, UserPreferenceService } from '../api/services';
import { DataPackageService } from './data-packages/data-package.service';
import { RadioButtonModule } from 'primeng/radiobutton';
import { LabItemsRemovedMaterialComponent } from './labItems/materials/lab-items-removed-material/lab-items-removed-material.component';
import { LabItemsRemovedInstrumentComponent } from './labItems/instruments/lab-items-removed-instrument/lab-items-removed-instrument.component';
import { DataRecordService } from './services/data-record.service';
import { LabItemsConsumableComponent } from './labItems/consumables/lab-items-consumable.component';
import { BptElnGridColumnLinkoutComponent } from './labItems/shared/bpt-eln-grid-column-linkout/bpt-eln-grid-column-linkout.component';
import { CrossReferencesComponent } from './references/cross-references/cross-references.component';
import { OutputEmpowerService } from './services/output-empower.service';
import { KeyColumnService } from './services/key-column.service';
import { ActivityOutputsInstrumentDataDropdownComponent } from './outputs/activity-outputs-instrument-data-dropdown/activity-outputs-instrument-data-dropdown.component';
import { LabItemsRemovedConsumableComponent } from './labItems/consumables/lab-items-removed-consumable/lab-items-removed-consumable.component';
import { ExperimentNodeTitleChangerComponent } from './experiment-node-title-changer/experiment-node-title-changer.component';
import { ExperimentNodeRetitleService } from './services/experiment-node-re-title.service';
import { SampleTableRemovedRowsComponent } from './inputs/sample-table/sample-table-removed-rows/sample-table-removed-rows.component';
import { ReferencesRemovedCrossReferenceComponent } from './references/cross-references/references-removed-cross-reference/references-removed-cross-reference.component';
import { ExperimentNodeReOrderService } from './services/experiment-node-re-order.service';
import { OrderListModule } from 'primeng/orderlist';
import { DividerModule } from 'primeng/divider';
import { OutputsImportedResultsetComponent } from './outputs/outputs-imported-resultset/outputs-imported-resultset.component';
import { StudyActivityTableRemovedRowsComponent } from './inputs/study-activity-table/study-activity-removed-rows/study-activity-removed-rows.component';
import { PreparationModule } from '../preparation/preparation.module';
import { ExperimentPreparationsComponent } from './preparations/experiment-preparations/experiment-preparations.component';
import { DataPackageComponent } from './data-packages/data-package/data-package.component';
import { ActivityInputStudyActivitySelectionComponent } from './inputs/activity-input-study-activity-selection/activity-input-study-activity-selection.component';
import { LabItemsColumnComponent } from './labItems/columns/lab-items-column/lab-items-column.component';
import { BooleanBasedWarningIconRendererComponent } from './labItems/columns/lab-items-column/boolean-based-warning-icon-renderer';
import { EmpowerTabsComponent } from './empower-tabs/empower-tabs.component';
import { LabItemsRemovedColumnComponent } from './labItems/columns/lab-items-removed-column/lab-items-removed-column.component';
import { InstrumentConnectionComponent } from './instrument-connection/instrument-connection.component';
import { InstrumentConnectionModalComponent } from './instrument-connection/instrument-connection-modal/instrument-connection-modal.component';
import { BptToggleSwitchModule } from 'bpt-ui-library/bpt-toggle-switch';
import { InstrumentStatusRendererComponent } from './instrument-connection/shared/instrument-status-renderer';
import { InstrumentConnectionConfigModalComponent } from './instrument-connection/instrument-connection-config-modal/instrument-connection-config-modal.component';
import { ExperimentPreparationService } from './preparations/experiment-preparations/services/experiment-preparation.service';
import { InstrumentBalanceReadingComponent } from './instrument-balance-reading/instrument-balance-reading.component';
import { RecipeTemplateLoaderModule } from '../recipe-template-loader/recipe-and-template-loader.module';
import { WarningDialogBoxComponent } from './warning-dialog-box/warning-dialog-box.component';
import { InstrumentConnectionHelper } from './instrument-connection/shared/instrument-connection-helper';
import { InstrumentNotificationService } from './instrument-connection/shared/instrument-notification-service';
import { BptFileUploadModule } from 'bpt-ui-library/bpt-file-upload';
import { InstrumentPhReadingComponent } from './instrument-ph-reading/instrument-ph-reading.component';
import { FilesTabComponent } from './files-tab/files-tab.component';
import { FileUploadModalComponent } from './file-upload-modal/file-upload-modal.component';
import { FilesTabHelper } from './files-tab/shared/files-tab-helper';
import { FilesTabActionsCellRendererComponent } from './files-tab/files-tab-actions-cell-renderer';
import { RemovedRowsComponent } from './data/table/removed-rows/removed-rows.component';
import { LabItemsPreparationComponent } from './labItems/Preparation/lab-items-preparation.component';
import { PhMeterService } from './services/ph-meter.service';
import { ChangeCellsCommand, RemoveRowCommand, RenumberRowsCommand, RestoreRowCommand, TableDataService } from './data/table/table-data.service';
import { TableService } from '../api/data-entry/services';
import { ClipboardService } from 'bpt-ui-library/services';
import { UnitLoaderService } from '../services/unit-loader.service';
import { RecipeEventsService } from '../api/cookbook/services';
import { DataValueService } from './services/data-value.service';
import { DialogService } from 'primeng/dynamicdialog';
import { StatementsComponent } from './comments/statements/statements.component';
import { UserDefinedCustomStatementsComponent } from './comments/statements/user-defined-custom-statements/user-defined-custom-statements.component';
import { AppliedStamentComponent } from './comments/statements/applied-statement/applied-statement.component';
import { ActivityInputHelper } from './inputs/shared/activity-input-helper';
import { PromptsGridComponent } from './prompts/prompts-grid/prompts-grid.component';
import { PromptsComponent } from './prompts/prompts.component';
import { PromptsDropdownRendererComponent } from './prompts/prompts-grid/prompts-dropdown-renderer/prompts-dropdown-cell-renderer';
import { RemovedPromptsComponent } from './prompts/prompts-grid/prompts-removed-rows/prompts-removed-rows.component';
import { ChangeReasonComponent } from './change-reason/change-reason.component';
import { ChangeReasonService } from './services/change-reason.service';
import { RepeatGroupTableInputSelectorComponent } from './data/table/repeat-for-each/repeat-group-table-input-selector/repeat-group-table-input-selector.component';
import { RepeatTargetModalComponent } from './data/table/repeat-for-each/repeat-target-modal/repeat-target-modal.component';
import { ChangeReasonRendererComponent } from './audit-history/change-reason-renderer.component';

/**
 * Provider with injection token 'ExperimentTableDataService' for TableDataService<'experiment'> constructed with API methods from TableService (e.g. tableEventsRemoveRowPost$Json)
 */
export const experimentTableDataServiceProvider: Provider = {
  provide: 'ExperimentTableDataService',
  deps: [
    ClipboardService,
    ConfirmationService,
    DataValueService,
    MessageService,
    TableService,
    UnitLoaderService,
    DialogService,
    RecipeEventsService,
  ],
  useFactory: (
    clipboardService: ClipboardService,
    confirmationService: ConfirmationService,
    dataValueService: DataValueService,
    messageService: MessageService,
    tableService: TableService,
    unitLoaderService: UnitLoaderService,
    dialogService: DialogService,
  ) =>
    new TableDataService<'experiment'>(
      clipboardService,
      confirmationService,
      dataValueService,
      messageService,
      unitLoaderService,
      dialogService,
      (command: ChangeCellsCommand<'experiment'>) => tableService.tableEventsChangeCellsPost$Json({ body: command }),
      (command: RemoveRowCommand<'experiment'>) => tableService.tableEventsRemoveRowPost$Json({ body: command }),
      (command: RestoreRowCommand<'experiment'>) => tableService.tableEventsRestoreRowPost$Json({ body: command }),
      (command: RenumberRowsCommand<'experiment'>) => tableService.tableEventsRenumberRowsPost$Json({ body: command }),
    )
}

@NgModule({
  declarations: [
    ActivityInputMaintenanceEventComponent,
    ActivityInputStudyActivitySelectionComponent,
    ActivityInputTestTableComponent,
    ActivityOutputsInstrumentDataDropdownComponent,
    AuditHistoryComponent,
    BarcodeScannerComponent,
    BooleanBasedWarningIconRendererComponent,
    BptElnGridColumnLinkoutComponent,
    BptElnGridRowActionsComponent,
    ClientFacingNoteComponent,
    ClientFacingNoteComponent,
    CommentsComponent,
    CommentsComponent,
    CoverComponent,
    CrossReferencesComponent,
    DataComponent,
    DataPackageComponent,
    DateBasedWarningIconRendererComponent,
    EmpowerTabsComponent,
    ExperimentComponent,
    ExperimentFlagsComponent,
    ExperimentNodeTitleChangerComponent,
    ExperimentPreparationsComponent,
    ExpiryDateBasedWarningIconRendererComponent,
    FieldComponent,
    FieldGroupComponent,
    FilesTabActionsCellRendererComponent,
    FilesTabComponent,
    FileUploadModalComponent,
    FormattedDateRendererComponent,
    FormComponent,
    InputsComponent,
    InstrumentBalanceReadingComponent,
    InstrumentConnectionComponent,
    InstrumentConnectionConfigModalComponent,
    InstrumentConnectionModalComponent,
    InstrumentEventComponent,
    InstrumentPhReadingComponent,
    InstrumentStatusRendererComponent,
    InternalCommentsComponent,
    LabItemsColumnComponent,
    LabItemsComponent,
    LabItemsConsumableComponent,
    LabItemsInstrumentComponent,
    LabItemsMaterialComponent,
    LabItemsPreparationComponent,
    LabItemsRemovedColumnComponent,
    LabItemsRemovedConsumableComponent,
    LabItemsRemovedInstrumentComponent,
    LabItemsRemovedMaterialComponent,
    ModuleComponent,
    OutputsComponent,
    OutputsImportedResultsetComponent,
    ReferencesComponent,
    ReferencesRemovedCrossReferenceComponent,
    RemovedRowsComponent,
    SampleTableComponent,
    SampleTableRemovedRowsComponent,
    StatusBasedWarningIconRendererComponent,
    StudyActivityTableComponent,
    StudyActivityTableRemovedRowsComponent,
    TableComponent,
    ViewsComponent,
    WarningDialogBoxComponent,
    StatementsComponent,
    UserDefinedCustomStatementsComponent,
    AppliedStamentComponent,
    PromptsComponent,
    PromptsGridComponent,
    RemovedPromptsComponent,
    PromptsDropdownRendererComponent,
    ChangeReasonComponent,
    RepeatGroupTableInputSelectorComponent,
    RepeatTargetModalComponent,
    ChangeReasonRendererComponent
  ],
  imports: [
    BptCommentsBoxModule,
    BptContextMenuModule,
    BptDateTimeModule,
    BptDropdownModule,
    BptFileUploadModule,
    BptGridModule,
    BptMenuButtonModule,
    BptNumericInputModule,
    BptProgressSpinnerModule,
    BptQuantityModule,
    BptRichTextEditorModule,
    BptSliderModule,
    BptTagsModule,
    BptTextInputModule,
    BptToggleSwitchModule,
    BptToolbarModule,
    BptTreeModule,
    ButtonModule,
    CheckboxModule,
    ClientNotificationModule,
    CommonModule,
    ConfirmDialogModule,
    ContextMenuModule,
    DialogModule,
    DropdownModule,
    FieldsetModule,
    FormsModule,
    InputTextareaModule,
    InputTextModule,
    KeyFilterModule,
    LayoutModule,
    MessagesModule,
    OrderListModule,
    DividerModule,
    OrganizationModule,
    OverlayPanelModule,
    PreparationModule,
    ProgressBarModule,
    ProgressSpinnerModule,
    RadioButtonModule,
    ReactiveFormsModule,
    RecipeTemplateLoaderModule,
    RouterModule.forChild(EXPERIMENT_ROUTES),
    SharedModule,
    TabViewModule,
    TemplateLoaderModule,
    ToastModule,
    ToolbarModule,
    TooltipModule,
  ],
  providers: [
    ConfirmationService,
    DataPackageService,
    DataRecordService,
    ExperimentNodeReOrderService,
    ExperimentNodeRetitleService,
    ExperimentPreparationService,
    ExperimentService,
    experimentTableDataServiceProvider,
    ExperimentWarningService,
    FilesTabHelper,
    GridPreferenceService,
    InstrumentConnectionHelper,
    InstrumentNotificationService,
    KeyColumnService,
    LabItemsService,
    ChangeReasonService,
    MessageService,
    NonDataRecordService,
    OutputEmpowerService,
    PhMeterService,
    ActivityInputHelper,
    RuleActionHandlerHostService,
    RuleDisplayMessageHandlerService,
    RuleSetVariableHandlerService,
    UserPreferenceService,
  ]
})
export class ExperimentModule { }
