import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { Subject } from 'rxjs';
import { Logger } from '../../services/logger.service';
import { environment } from '../../../environments/environment';
import { RecipeService } from './recipe.service';
import { RecipeCollaborator } from '../recipe-notification/recipe-collaborator';
import { UserService } from '../../services/user.service';
import { RecipePublishChange, RecipeStartNewVersionChange, RecipeTypeChange } from '../model/recipe';

@Injectable({
  providedIn: 'root'
})
export class RecipeNotificationService {
  public hubConnection!: signalR.HubConnection;
  private get serviceUrl(): string {
    return `${environment.cookbookServiceUrl}/recipeNotifications`
  };
  private readonly signalRRetryAttemptsAndDuration: number[];
  public reconnectingAlert = new Subject<any>();
  public reconnectedAlert = new Subject<any>();
  public usersAddedAlert = new Subject<RecipeCollaborator[]>();
  public userConnectionRemovedAlert = new Subject<RecipeCollaborator>();
  public connectionLostAlert = new Subject<any>();
  public connectionStartedAlert = new Subject<any>();
  public dataRecordReceiver = new Subject<any>();
  public recipeTypeChangeReceiver = new Subject<RecipeTypeChange>();
  public recipeStartNewVersionChange = new Subject<RecipeStartNewVersionChange>();
  public recipeMajorVersionAvailabilityError = new Subject<RecipePublishChange>();
  public inputLockReceiver = new Subject<any[]>();

  constructor(
    private readonly logger: Logger,
    private readonly userService: UserService,
    private readonly recipeService: RecipeService,
  ) {
    this.signalRRetryAttemptsAndDuration = environment.signalRRetryAttempts;
  }

  setConnection() {
    this.hubConnection = this.getConnectionBuilder()
      .withUrl(this.serviceUrl, {
        accessTokenFactory: () => {
          return this.userService.getOauthToken(this.serviceUrl);
        }
      })
      .configureLogging(signalR.LogLevel.Information)
      .withAutomaticReconnect(this.signalRRetryAttemptsAndDuration)
      .build();

    this.hubConnection.onclose((error) => {
      if (this.hubConnection.state === signalR.HubConnectionState.Disconnected) {
        this.logger.logWarning(`Connection lost. ${error ?? ''}`);
        this.connectionLostAlert.next(error);
      }
    });

    this.hubConnection.onreconnecting((error) => {
      if (this.hubConnection.state === signalR.HubConnectionState.Reconnecting) {
        this.logger.logWarning(`Reconnecting...${error ?? ''}`);
        this.reconnectingAlert.next(error);
      }
    });

    this.hubConnection.onreconnected((success) => {
      if (this.hubConnection.state === signalR.HubConnectionState.Connected) {
        this.logger.logInfo(`Reconnected.`);
        this.reconnectedAlert.next(success);
      }
    });

    // Client Methods to be invoked from Server
    this.hubConnection.on(
      'UserConnectionAdded',
      (data: RecipeCollaborator | RecipeCollaborator[]): void => {
        const collaborators = 'connectionId' in data ? [data] : data;
        const connectionIds = collaborators.map((c) => `${c.connectionId} ${c.puid}`).join('; ');
        this.logger.logInfo(
          `User Notification from Hub: UserConnectionAdded (length: ${collaborators.length}) ${connectionIds}`
        );
        this.usersAddedAlert.next(collaborators);
      }
    );

    this.hubConnection.on('UserConnectionRemoved', (data: RecipeCollaborator): void => {
      this.logger.logInfo(
        `User Notification from Hub: UserConnectionRemoved ${data.connectionId} ${data.puid}`
      );
      this.userConnectionRemovedAlert.next(data);
    });

    this.hubConnection.on('RecipeTypeChange', (data: RecipeTypeChange): void => {
      this.recipeTypeChangeReceiver.next(data);
    });

    this.hubConnection.on('RecipeNewMinorVersionAvailability', (data: RecipeStartNewVersionChange): void => {
      this.recipeStartNewVersionChange.next(data);
    });

    this.hubConnection.on('RecipeNewMajorVersionAvailability', (data: RecipePublishChange): void => {
      this.recipeMajorVersionAvailabilityError.next(data);
    });
  }

  getConnectionBuilder() {
    return new signalR.HubConnectionBuilder();
  }

  private async leaveRecipe() {
    if (this.hubConnection?.state === signalR.HubConnectionState.Connected) {
      await this.hubConnection?.stop();
    }
  }

  public async publishRecipeTypeChange(recipeTypeChange: RecipeTypeChange) {
    this.invokeHub('PublishRecipeTypeChange', recipeTypeChange, this.hubConnection.connectionId);
  }

  public async PublishRecipeNewMinorVersionAvailability(recipeNewMinorVersionChangeObject: RecipeStartNewVersionChange) {
    this.invokeHub('PublishRecipeNewMinorVersionAvailability', recipeNewMinorVersionChangeObject, this.hubConnection.connectionId);
  }

  public async PublishRecipeNewMajorVersionAvailability(recipeNewMajorVersionChangeObject: RecipePublishChange) {
    this.invokeHub('PublishRecipeNewMajorVersionAvailability', recipeNewMajorVersionChangeObject, this.hubConnection.connectionId);
  }

  //Single method to start a connection as well as join the recipe group.
  //Can be used while loading a recipe or trying a force reconnect
  public async joinRecipe() {
    if ((environment as any).signalRDisabled) {
      this.logger.logWarning(`SignalR has been disabled in the environment file`);
      return;
    }

    if (this.hubConnection?.state !== signalR.HubConnectionState.Connected) {
      this.setConnection();
      await this.startConnection();
    } else {
      this.invokeHub(
        'JoinRecipe',
        this.recipeService.currentRecipe?.recipeId,
        this.getCollaborator()
      );
      this.connectionStartedAlert.next(this.hubConnection);
    }
  }

  private async invokeHub<T = any>(methodName: string, ...args: any[]) {
    let result = {};
    if (this.hubConnection?.state === signalR.HubConnectionState.Connected) {
      result = await this.hubConnection.invoke(methodName, ...args);
    }
    return result;
  }

  public async disconnectUser() {
    this.leaveRecipe();
  }

  private readonly startConnection = async () => {
    await this.hubConnection
      .start()
      .then(() => {
        console.assert(this.hubConnection.state === signalR.HubConnectionState.Connected);
        this.logger.logInfo(`Connection started.`);
        this.invokeHub(
          'JoinRecipe',
          this.recipeService.currentRecipe?.recipeId,
          this.getCollaborator()
        );
        this.connectionStartedAlert.next(this.hubConnection);
      })
      .catch((err) => {
        this.logger.logErrorMessage(`Error while starting connection: ${err}`);
        console.assert(this.hubConnection.state === signalR.HubConnectionState.Disconnected);
        setTimeout(this.startConnection, environment.signalRInitialDelay);
      });
  };

  public getCollaborator(): RecipeCollaborator {
    if (
      !this.userService.currentUser.firstName ||
      !this.userService.currentUser.lastName ||
      !this.hubConnection.connectionId
    ) {
      throw console.error('Either user or websocket connection details not present.');
    }

    return {
      puid: this.userService.currentUser.puid,
      firstName: this.userService.currentUser.firstName,
      lastName: this.userService.currentUser.lastName,
      connectionId: this.hubConnection.connectionId
    };
  }
}
