import { Component, OnInit } from '@angular/core';
import { Activity } from 'model/experiment.interface';
import { ExperimentService } from '../../services/experiment.service';
import { DataPackageService } from '../data-package.service';
import { ElnProgressSpinnerService } from '../../../eln-progress-spinner-module/eln-progress-spinner.service';

export interface dataPackageActivityDetails {
  activityId: string;
  activityName: string;
}
@Component({
  selector: 'app-data-package',
  templateUrl: './data-package.component.html',
  styleUrls: ['./data-package.component.scss']
})
export class DataPackageComponent implements OnInit {
  activitiesArray: dataPackageActivityDetails[] = [];
  checkedActivities: dataPackageActivityDetails[] = [];
  currentSelectedRadioButton!: string;
  disablePreviewButton = false;
  readonly sliderOptions = {
    size: 'medium', // Options : 'large','medium','small'
    visible: false,
    closeOnEscape: true,
    headerText: $localize`:@@DataPackageHeader:Data Package`,
    isFooterSticky: false,
    displayFooter: false,
    displayFooterWithPrimaryButton: true,
    displayFooterWithSecondaryButton: true,
    isModal: true
  };
  readonly radioButtonOptions = {
    selectedActivity: "selectedActivity",
    entireExperiment: "entireExperiment"
  };
  readonly entireExperiment = $localize`:@@EntireExperimentHtml:Entire Experiment`;
  readonly selectedActivities = $localize`:@@SelectActivitiesHtml:Select Activities`;
  readonly noActivityAvailable = $localize`:@@NoActivityAvailableHtml:No activity available.`;
  readonly noActivityAvailableOnCoverPage = $localize`:@@NoActivityOnCoverPageHtml:Currently there are no activities <br> only cover page will be generated.`;
  readonly dataPackageSecondaryHeader = $localize`:@@dataPackageSecondaryHeaderHtml:Generate a report for the entire experiment or specific activities.`;

  spinnerOptions = {
    message: $localize`:@@generateDataPackage:Loading Experiment Data...`,
    i18nMessage: `@@generateDataPackage`, useOverlay: true
  }
  constructor(
    private readonly dataPackageService: DataPackageService,
    private readonly experimentService: ExperimentService,
    private readonly spinnerService: ElnProgressSpinnerService
  ) { }

  ngOnInit() {
    this.showSlider();
    this.showActivities();
  }
  showActivities() {
    this.dataPackageService.fetchExperimentActivities().subscribe(activities => {
      const uniqueActivities = activities.filter((activity: Activity) => 
      !this.activitiesArray.some((currentActivity: dataPackageActivityDetails) => currentActivity.activityId === activity.activityId)).map(activity =>
         ({ activityId: activity.activityId, activityName: activity.itemTitle }) as dataPackageActivityDetails);
      this.activitiesArray = uniqueActivities.concat(this.activitiesArray);
      this.currentSelectedRadioButton = this.radioButtonOptions.entireExperiment; 
      this.handleRetitleCase(activities);
    }); 
  }
  private showSlider() {
    this.dataPackageService.getSliderObservable().subscribe((isVisible: boolean) => {
      this.sliderOptions.visible = isVisible;
    });
  }

  onCheckBoxClickEvent($event: any) {
    this.disablePreviewButton = $event.length > 0 ? false : true;
  }

  cancelDataPackage(): void {
    this.sliderOptions.visible = false;
  }
  previewDataPackage(): void {
    const experimentId = this.experimentService.currentExperiment?.id;
    if (experimentId === undefined) {
      return;
    }
    this.spinnerService.Show(this.spinnerOptions);
    if (this.currentSelectedRadioButton === this.radioButtonOptions.entireExperiment) {
      this.dataPackageService.getDataPackage(experimentId);
    }
    else {
      this.dataPackageService.getDataPackageWithSelectedActivities(experimentId, this.checkedActivities.map( activity => activity.activityId));
    }
  }
  focusedRadioButton(event: any) {
    if (event.target.defaultValue === this.radioButtonOptions.entireExperiment
      || (event.target.defaultValue === this.radioButtonOptions.selectedActivity
        && this.checkedActivities.length > 0)) {
      this.disablePreviewButton = false;
    }
    else {
      this.disablePreviewButton = true;
    }
  }
  
  private handleRetitleCase(activities: Activity[]) {
    this.activitiesArray = activities.filter((act)=> this.activitiesArray.some(x=>x.activityId === act.activityId)).map(activity =>
      ({ activityId: activity.activityId, activityName: activity.itemTitle }) as dataPackageActivityDetails);
  }

}

