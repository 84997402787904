import { AfterViewInit, Component, Inject, LOCALE_ID, NgZone, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ConfigurationService } from '../../services/configuration.service';
import { UserService as UserAPIService } from '../../api/services/user.service';
import { UserService } from '../../services/user.service';
import { LabsiteService, UserPicklistsService } from '../../api/services';
import { Labsite, User } from '../../api/models';
import { User as UserInterface } from 'model/user.interface';
import { ProjectLogLoaderService } from '../../services/project-log-loader.service';
import {
  ChangeRecipeAdditionalNotesCommand,
  ChangeRecipeAnalysisTechniqueCommand,
  ChangeRecipeApprovalRequiredCommand,
  ChangeRecipeApproversCommand,
  ChangeRecipeClientsCommand,
  ChangeRecipeCompendiaCommand,
  ChangeRecipeConsumingLabsitesCommand,
  ChangeRecipeEditorsCommand,
  ChangeRecipeMethodCommand,
  ChangeRecipeNameCommand,
  ChangeRecipeProjectsCommand,
  ChangeRecipePurposeCommand,
  ChangeRecipeSubBusinessUnitsCommand,
  ChangeRecipeTagsCommand,
  ChangeRecipeVerifiedCommand,
  ChangeRecipeVerifiersCommand,
  RecipeState,
  RecipeType
} from '../../api/cookbook/models';
import { difference } from 'lodash-es';
import { Subscription } from 'rxjs';
import { RecipeService, recipeStateLabel, recipeTypeLabel } from '../services/recipe.service';
import { Instant } from '@js-joda/core';
import { DateAndInstantFormat, formatInstant } from '../../shared/date-time-helpers';
import { ClientStateService } from '../../services/client-state.service';
import { BaseComponent } from '../../base/base.component';
import { ActivatedRoute } from '@angular/router';
import { RecipeModel } from '../model/recipe';
import { UnsubscribeAll } from '../../shared/rx-js-helpers';
import { ELNFeatureFlags } from '../../shared/eln-feature-flags';
import { Tooltip } from 'primeng/tooltip';
import { RecipeSourcesModel, RecipeSourcesSliderComponent } from './recipe-sources-slider/recipe-sources-slider.component';
import { TemplateEventService } from '../../recipe-template-loader/experiment-template-load/services/template-event.service';
import { referencesConstants } from '../../configurations/app-configuration.interface';
import { RecipeSourcesService } from '../services/recipe-sources.service';
import { ColumnDefinition } from 'bpt-ui-library/bpt-grid';
import { navigateRecipeToAboutPage } from '../recipe.routes';

@Component({
  selector: 'app-recipe-about',
  templateUrl: './recipe-about.component.html',
  styleUrls: ['./recipe-about.component.scss']
})
export class RecipeAboutComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
  generalInformation = $localize`general information`;
  additionalFields = $localize`:@@recipeAdditionalFields:additional fields`;
  verificationInformation = $localize`:@@recipeVerificationInformation:verification information`;
  recipePurpose = $localize`:@@recipePurpose:This recipe is designed to capture pH meter collaboration documentation`;
  recipeNameRequiredValidationMessage: string = $localize`:@@RecipeNameRequired:Name required`;
  recipeEubBusinessUnitRequiredValidationMessage: string = $localize`:@@recipeSubBusinessUnitsRequired:Sub Business Unit(s) required`;
  recipeConsumingLabsitesRequiredValidationMessage: string = $localize`:@@recipeConsumingLabsitesRequired:Consuming Lab Site(s) required`;
  recipeEditorsRequiredValidationMessage: string = $localize`:@@recipeEditorsRequired:Editor(s) required`;
  recipePurposeLabel = $localize`:@@recipePurposeLabel:Purpose`;
  recipeAdditionalNotesLabel = $localize`:@@recipeAdditionalNotesLabel:Additional Notes`;
  recipeAvailableFlagConfirmation = $localize`:@@recipeAvailableFlagConfirmation:This will make the recipe available for usage in experiments, are you sure you want to continue?`;
  recipeNotAvailableFlagConfirmation
    = $localize`:@@recipeNotAvailableFlagConfirmation:This will make the recipe not available for usage in experiments, are you sure you want to continue?`;
  recipeReferenceCopied = $localize`:@@recipeReferenceCopied:The Recipe Reference has been copied.`;
  copyRecipeReferenceId = 'eln-recipe-reference-copy';
  copyRecipeTooltip = $localize`:@@recipeReferenceCopyTooltip:Copy Recipe Reference`;
  copyRecipeCurrentTooltip = this.copyRecipeTooltip;
  recipeTitleErrorFlag = false;
  recipeSubBusinessUnitErrorFlag = false;
  recipeConsumingLabsitesErrorFlag = false;
  recipeEditorErrorFlag = false;
  createdOnValue = '';
  lastEditedOnValue = '';
  isLimsHosted = ConfigurationService.isLimsHosted;
  isRecipeAboutPageReadOnly = false;
  users: User[] = [];
  usersExcludedFromEditors: string[] = [];
  usersExcludedFromVerifiers: string[] = [];
  usersExcludedFromApprovers: string[] = [];
  currentUser: UserInterface;
  compendiaValues: string[] = [];
  isProjectsEditable = false;
  recipe!: RecipeModel;
  recipeLoaded = false;
  isLoading = false;
  consumingLabsiteForSubUnits: string[] = [];
  featureFlags: string[] = [];
  enableBasicAndAdditionalFields = false;
  enableApprovalRequiredAndPurpose = false;
  enableAdditionalNotes = false;
  enableAcceptanceAndVerified = false;
  enableApproved = false;
  disableAll = false;
  consumingLabsites: any[] = [];
  recipeAvailable = false;
  sourcesSliderVisible = false;
  disableSources = true;
  consumingRecipesCount = 0;
  data: RecipeSourcesModel[] = [];
  sliderDetails = {
    sliderTitle: "",
    sliderSubHeading: "",
  };

  sliderGridColumnDefinitions:ColumnDefinition[] =  [
    {
      label: $localize`:@@recipeSourcesNumberColumn:Reference Number`,
      field: 'referenceNumber',
      width: 230,
      columnType: 'link',
      cellClass: 'cell-Link',
      linkData: (_: MouseEvent, data: RecipeSourcesModel) => {
        if (data) {
          this.clickedReferencedRecipeOrTemplateNumber(data);
        }
      }
    },
    {
      label: $localize`:@@recipeSourcesNameColumn:Name`,
      field: 'name',
      columnType: 'string',
      width: 265
    },
    {
      label: $localize`:@@recipeSourcesVersionColumn:Version`,
      field: 'version',
      columnType: 'string',
      width: 130
    },
    {
      label: $localize`:@@recipeSourcesWorkflowStateColumn:Workflow State`,
      field: 'workflowState',
      columnType: 'string',
      width: 212
    }
  ];

  private readonly subscriptions: Subscription[] = [];
  @ViewChildren(Tooltip) tooltips: QueryList<Tooltip> = new QueryList();
  @ViewChild(RecipeSourcesSliderComponent) sourcesSlider!: RecipeSourcesSliderComponent;

  constructor(
    @Inject(LOCALE_ID) private readonly locale: string,
    private readonly userAPIService: UserAPIService,
    private readonly userService: UserService,
    private readonly picklistService: UserPicklistsService,
    private readonly projectLogLoaderService: ProjectLogLoaderService,
    private readonly recipeService: RecipeService,
    private readonly labsiteService: LabsiteService,
    private readonly templateEventService: TemplateEventService,
    private readonly recipeSourcesService: RecipeSourcesService,
    clientStateService: ClientStateService,
    private readonly route: ActivatedRoute,
    private readonly ngZone: NgZone,
  ) {
    super(clientStateService, route);
    this.currentUser = { ...this.userService.currentUser };
    this.addSubscriptions();
  }

  private addSubscriptions() {
    this.subscriptions.splice(
      0,
      0,
      this.recipeService.isRecipeVerified.subscribe({
        next: () => {
          this.recipe.verificationDetails.verifiedBy = this.recipe.verificationDetails.isVerified
            ? this.currentUser.puid
            : undefined;
          this.recipe.verificationDetails.verifiedOn = this.recipe.verificationDetails.isVerified
            ? Instant.now().toString()
            : undefined;
        }
      }),
      this.recipeService.isRecipeApproved.subscribe({
        next: () => {
          this.recipe.verificationDetails.approvedBy = this.recipe.verificationDetails.isApproved
            ? this.currentUser.puid
            : undefined;
          this.recipe.verificationDetails.approvedOn = this.recipe.verificationDetails.isApproved
            ? Instant.now().toString()
            : undefined;
        }
      }),
      this.templateEventService.TemplateApplied.subscribe({
        next: () => {
          this.disableSources = false;
        }
      }),
      this.templateEventService.RecipeRefreshed.subscribe({
        next: (response: RecipeModel) => {
          if (response.childOrder.length === 0) this.disableSources = true;
        }
      })
    );
  }

  get recipeType() {
    return recipeTypeLabel[this.recipe.type];
  }
  get recipeState() {
    return (
      recipeStateLabel[this.recipe.tracking.state] ??
      recipeStateLabel[RecipeState.Draft]
    );
  }

  get clientOptions() {
    return this.projectLogLoaderService.getAllClients();
  }

  ngOnInit(): void {
    this.featureFlags = this.clientStateService.getFeatureFlags(this.clientState);
    this.loadUsers();
    this.loadCompendiaValues();
    this.setLabsites();
    this.disableSources = this.recipeService.RecipeSources.length <= 0;
    this.consumingRecipesCount = this.recipeService.consumingRecipes.filter((obj, index, self) =>
      index === self.findIndex((t) => ( t.referenceId === obj.referenceId ))).length;
  }

  ngAfterViewInit() {
    this.watchRecipeData();
  }

  ngOnDestroy(): void {
    UnsubscribeAll(this.subscriptions);
  }

  loadConsumingRecipesSlider() {
    this.sliderDetails.sliderTitle = $localize`:@@ConsumingRecipes:Consuming recipe(s)`;
    this.sliderDetails.sliderSubHeading = $localize`:@@recipeConsumingRecipesSubHeading:Consuming recipe(s) list
    references of all recipes containing the current recipe. Make sure to refresh the recipe about page to get the latest list of consuming recipes.`;
    this.data = [];
    this.recipeSourcesService.getConsumingRecipes(this.setConsumingRecipes);
  }

  setConsumingRecipes = (data: RecipeSourcesModel[])  => {
    this.data = data;
    this.sourcesSliderVisible = true;
  };

  loadSourcesSlider() {
    this.sliderDetails.sliderTitle = $localize`:@@recipeSourcesHeader:Sources`;
    this.sliderDetails.sliderSubHeading = $localize`:@@recipeSourcesSubHeading:Source(s) lists references of other recipes and/or templates used in this recipe`;
    this.data = [];
    this.recipeSourcesService.getSources(this.setSources);
  }

  setSources = (data: RecipeSourcesModel[], _recipeRecords: RecipeSourcesModel[])  => {
    this.data = data;
    this.sourcesSliderVisible = true;
  };

  clickedReferencedRecipeOrTemplateNumber(data: RecipeSourcesModel) {
    if (data.sourceType === 'Template') window.open(`/template-designer/${data.id}/${data.type.toLowerCase()}-designer`, '_blank');
    else navigateRecipeToAboutPage(data.referenceNumber, data.version.substring(1));
  }

  hideSourcesSlider() {
    this.sourcesSliderVisible = false;
  }

  private watchRecipeData(): void {
    this.subscriptions.push(
      this.recipeService.recipeLoaded.subscribe({
        next: (isValidResponse: boolean) => {
          if (isValidResponse) {
            this.loadRecipeData();
            this.recipeLoaded = true;
          }
        }
      }),
      this.recipeService.recipeWorkFlowState.subscribe({
        next: () => {
          this.loadRecipeData();
        }
      })
    );
  }

  private loadCompendiaValues(): void {
    this.picklistService
      .userPicklistsIdGet$Json({
        id: referencesConstants.compendiaPicklistId
      })
      .subscribe({
        next: (picklist: { items: string[] }) => {
          this.compendiaValues = picklist.items;
        },
        error: () => {
          this.compendiaValues = [];
        }
      });
  }

  private loadUsers(): void {
    this.userAPIService
      .usersActiveUsersPost$Json({
        body: ['ELN Recipe Designer', 'ELN Recipe Verifier', 'ELN Recipe Approver']
      })
      .subscribe({
        next: (data: User[]) => {
          this.users = data;
          if (this.recipeService.currentRecipe) {
            this.recipeLoaded = true;
            this.loadRecipeData();
          }
        }
      });
  }

  private loadRecipeData(): void {
    this.recipe = this.recipeService.currentRecipe;
    this.consumingLabsiteForSubUnits = this.recipe.organization.consumingLabsites.slice();
    this.recipeAvailable = this.recipe.tracking.available ?? false;
    this.formatAndPrepareData();
    this.computeUsersToBeExcluded();
    this.setAccessControl();
  }

  isUserEditorVerifierApproverOfRecipe(): boolean {
    return (
      this.recipe.tracking.assignedEditors.includes(this.currentUser.puid) ||
      this.recipe.tracking.assignedVerifiers.includes(this.currentUser.puid) ||
      this.recipe.tracking.assignedApprovers.includes(this.currentUser.puid)
    );
  }

  private computeUsersToBeExcluded(): void {
    this.usersExcludedFromEditors = [];
    this.usersExcludedFromVerifiers = [];
    this.usersExcludedFromApprovers = [];
    this.usersExcludedFromEditors.push(
      ...this.recipe.tracking.assignedVerifiers,
      ...this.recipe.tracking.assignedApprovers
    );
    this.usersExcludedFromVerifiers.push(
      ...this.recipe.tracking.assignedEditors,
      ...this.recipe.tracking.assignedApprovers
    );
    this.usersExcludedFromApprovers.push(
      ...this.recipe.tracking.assignedEditors,
      ...this.recipe.tracking.assignedVerifiers
    );
  }

  private formatAndPrepareData(): void {
    this.recipe.tracking.createdBy = this.getFullName(this.recipe.tracking.createdBy);
    this.recipe.tracking.lastEditedBy = this.getFullName(this.recipe.tracking.lastEditedBy);
    this.createdOnValue = formatInstant(this.recipe.tracking.createdOn, DateAndInstantFormat.dateTimeToMinute);
    this.lastEditedOnValue = formatInstant(this.recipe.tracking.lastEditedOn, DateAndInstantFormat.dateTimeToMinute);
  }

  recipeEditorChanged($event: string[]): void {
    if ($event.length === 0) {
      this.recipeEditorErrorFlag = true;
      this.recipeEditorsNotSelected();
      return;
    } else {
      this.recipeEditorErrorFlag = false;
    }
    const modifiedValue = this.checkModifiedList($event, this.recipe.tracking.assignedEditors);
    if (modifiedValue) {
      this.changeEditors(modifiedValue);
    } else {
      this.editorsNotChanged();
    }
  }

  recipeEditorsNotSelected(): void {
    this.recipeService.editorsNotSelected();
  }

  private editorsNotChanged() {
    this.recipeService.editorsNotChanged();
  }

  recipeVerifierChanged($event: string[]): void {
    const modifiedValue = this.checkModifiedList($event, this.recipe.tracking.assignedVerifiers);
    if (modifiedValue) this.changeVerifiers(modifiedValue);
  }

  recipeApproverChanged($event: string[]): void {
    const modifiedValue = this.checkModifiedList($event, this.recipe.tracking.assignedApprovers);
    if (modifiedValue) this.changeApprovers(modifiedValue);
  }

  private setLabsites() {
    this.labsiteService.labsitesGet$Json().subscribe((result: Labsite[]) => {
      this.consumingLabsites = result.map((item) => {
        return { label: item.displayLabel, value: item.labsiteCode };
      });
    });
  }

  consumingLabsiteChanged($event: string[]) {
    if ($event.length === 0) {
      this.recipeConsumingLabsitesErrorFlag = true;
      this.recipeSubBusinessUnitErrorFlag = true;
      this.labSiteNotSelected();
      return;
    } else {
      this.recipeConsumingLabsitesErrorFlag = false;
    }
    const modifiedValue = this.checkModifiedList(
      $event,
      this.recipe.organization.consumingLabsites
    );
    if (modifiedValue) {
      this.changeConsumingLabsite(modifiedValue);
    }
    else {
      this.labSiteNotChanged();
    }
  }

  labSiteNotSelected() {
    this.recipeService.labSiteNotSelected();
  }

  labSiteNotChanged() {
    this.recipeService.labSiteNotChanged();
  }

  subBusinessUnitChange($event: string[]) {
    if ($event.length === 0) {
      this.recipeSubBusinessUnitErrorFlag = true;
      this.subBusinessUnitNotSelected();
      return;
    } else {
      this.recipeSubBusinessUnitErrorFlag = false;
    }
    const modifiedValue = this.checkModifiedList($event, this.recipe.organization.subBusinessUnits);
    if (modifiedValue) {
      this.changeSubBusinessUnits(modifiedValue);
    }
    else {
      this.subBusinessUnitNotChanged();
    }
  }

  subBusinessUnitNotSelected() {
    this.recipeService.subBusinessUnitNotSelected();
  }

  subBusinessUnitNotChanged() {
    this.recipeService.subBusinessUnitNotChanged();
  }

  clientsChanged($event: string[]) {
    const modifiedValue = this.checkModifiedList($event, this.recipe.clients);
    if (modifiedValue) this.changeClients(modifiedValue);
    else {
      this.isProjectsEditable = this.recipe.clients.length !== 0;
    }
  }

  changeApprovalRequired($event: boolean): void {
    if (
      $event === this.recipe.verificationDetails.isApprovalRequired ||
      !this.checkRecipeState(['draft', 'pendingVerification'])
    ) {
      return;
    }
    this.recipe.verificationDetails.isApprovalRequired = $event;
    const approvalRequiredCommand: ChangeRecipeApprovalRequiredCommand = {
      isApprovalRequired: $event,
      recipeId: this.recipeService.currentRecipeId
    };
    this.recipeService.changeApprovalRequired(approvalRequiredCommand);
  }

  changePurposeValue($event: string): void {
    if (
      $event === this.recipe.verificationDetails.purpose ||
      !this.checkRecipeState(['draft', 'pendingVerification'])
    ) {
      return;
    }
    this.recipe.verificationDetails.purpose = $event;
    const purposeCommand: ChangeRecipePurposeCommand = {
      purpose: $event,
      recipeId: this.recipeService.currentRecipeId
    };
    this.recipeService.changePurpose(purposeCommand);
  }

  changeAdditionalNotesValue($event: string): void {
    if (
      $event === this.recipe.verificationDetails.additionalNotes ||
      !this.checkRecipeState(['draft', 'pendingVerification', 'pendingApproval'])
    ) {
      return;
    }
    this.recipe.verificationDetails.additionalNotes = $event;
    const additionalNotesCommand: ChangeRecipeAdditionalNotesCommand = {
      additionalNotes: $event,
      recipeId: this.recipeService.currentRecipeId
    };
    this.recipeService.changeAdditionalNotes(additionalNotesCommand);
  }

  checkRecipeState(states: string[]): boolean {
    return states.includes(this.recipe.tracking.state.toString());
  }

  projectsChanged($event: string[]) {
    const modifiedValue = this.checkModifiedList($event, this.recipe.projects);
    if (modifiedValue) this.changeProjects(modifiedValue);
  }

  compendiaChanged($event: string[]) {
    const modifiedValue = this.checkModifiedList($event, this.recipe.compendia);
    if (modifiedValue) this.changeCompendias(modifiedValue);
  }
  tagChanged($event: string[]) {
    const modifiedValue = this.checkModifiedList($event, this.recipe.tags);
    if (modifiedValue) this.changeTags(modifiedValue);
  }

  getFullName(puid: string): string {
    const fullName =
      this.users.find((i) => i.puid.toUpperCase() === puid.toUpperCase())?.fullName ??
      puid.toUpperCase();
    return fullName.replace(puid.toLowerCase(), puid.toUpperCase());
  }

  get projectOptionsBasedOnClientsSelected() {
    return this.projectLogLoaderService.fetchProjectsLinkedToClients(this.recipe.clients);
  }

  initiateTitleEditing(newValue: string) {
    if (!newValue) {
      this.recipeTitleErrorFlag = true;
      this.titleIsEmpty();
      return;
    } else {
      this.recipeTitleErrorFlag = false;
    }
    if (newValue === this.recipe.name) {
      this.titleNotChanged();
      return;
    }
    const titleChangeValue: ChangeRecipeNameCommand = {
      recipeId: this.recipeService.currentRecipeId,
      title: newValue
    };
    this.changeTitle(titleChangeValue);
  }

  private changeTitle(value: ChangeRecipeNameCommand) {
    this.recipeService.changeName(value).subscribe({
      next: (isValidResponse: boolean) => {
        if (isValidResponse) this.recipe = this.recipeService.currentRecipe;
      }
    });
  }

  private titleNotChanged() {
    this.recipeService.nameNotChanged();
  }

  private titleIsEmpty() {
    this.recipeService.isNameEmpty();
  }

  initiateAnalysisTechniqueEditing(newValue: string) {
    if (newValue === this.recipe.analysisTechnique) {
      return;
    }
    const analysisTechniqueChangeValue: ChangeRecipeAnalysisTechniqueCommand = {
      recipeId: this.recipeService.currentRecipeId,
      analysisTechnique: newValue
    };
    this.changeAnalysisTechnique(analysisTechniqueChangeValue);
  }

  private changeAnalysisTechnique(value: ChangeRecipeAnalysisTechniqueCommand) {
    this.recipeService.changeAnalysisTechnique(value).subscribe({
      next: (isValidResponse: boolean) => {
        if (isValidResponse) this.recipe = this.recipeService.currentRecipe;
      }
    });
  }

  initiateMethodEditing(newValue: string) {
    if (newValue === this.recipe.method) {
      return;
    }
    const methodChangeValue: ChangeRecipeMethodCommand = {
      recipeId: this.recipeService.currentRecipeId,
      method: newValue
    };
    this.changeMethod(methodChangeValue);
  }

  private changeMethod(value: ChangeRecipeMethodCommand) {
    this.recipeService.changeMethod(value).subscribe({
      next: (isValidResponse: boolean) => {
        if (isValidResponse) this.recipe = this.recipeService.currentRecipe;
      }
    });
  }

  private changeEditors(value: ChangeRecipeEditorsCommand) {
    this.recipeService.changeEditors(value).subscribe({
      next: (isValidResponse: boolean) => {
        if (isValidResponse) {
          this.recipe = this.recipeService.currentRecipe;
          this.computeUsersToBeExcluded();
        }
      }
    });
  }

  private changeConsumingLabsite(value: ChangeRecipeConsumingLabsitesCommand) {
    this.recipeService.changeConsumingLabsite(value).subscribe({
      next: (isValidResponse: boolean) => {
        if (isValidResponse) this.recipe = this.recipeService.currentRecipe;
      }
    });
  }

  private changeSubBusinessUnits(value: ChangeRecipeSubBusinessUnitsCommand) {
    this.recipeService.changeSubBusinessUnits(value).subscribe({
      next: (isValidResponse: boolean) => {
        if (isValidResponse) this.recipe = this.recipeService.currentRecipe;
      }
    });
  }

  private changeVerifiers(value: ChangeRecipeVerifiersCommand) {
    this.recipeService.changeVerifiers(value).subscribe({
      next: (isValidResponse: boolean) => {
        if (isValidResponse) {
          this.recipe = this.recipeService.currentRecipe;
          this.computeUsersToBeExcluded();
        }
      }
    });
  }

  private changeApprovers(value: ChangeRecipeApproversCommand) {
    this.recipeService.changeApprovers(value).subscribe({
      next: (isValidResponse: boolean) => {
        if (isValidResponse) {
          this.recipe = this.recipeService.currentRecipe;
          this.computeUsersToBeExcluded();
        }
      }
    });
  }

  private changeClients(value: ChangeRecipeClientsCommand) {
    this.recipeService.changeClients(value).subscribe({
      next: (isValidResponse: boolean) => {
        if (isValidResponse) {
          this.recipe = this.recipeService.currentRecipe;
          if (this.recipe.clients.length > 0) {
            this.isProjectsEditable = true;
            this.projectsChanged(
              this.projectLogLoaderService.filterOutInvalidProjectSelections(
                this.recipe.projects,
                this.recipe.clients
              )
            );
          } else {
            this.isProjectsEditable = false;
            this.projectsChanged([]);
          }
        }
      }
    });
  }
  private changeProjects(value: ChangeRecipeProjectsCommand) {
    this.recipeService.changeProjects(value).subscribe({
      next: (isValidResponse: boolean) => {
        if (isValidResponse) this.recipe = this.recipeService.currentRecipe;
      }
    });
  }
  private changeCompendias(value: ChangeRecipeCompendiaCommand) {
    this.recipeService.changeCompendias(value).subscribe({
      next: (isValidResponse: boolean) => {
        if (isValidResponse) this.recipe = this.recipeService.currentRecipe;
      }
    });
  }
  private changeTags(value: ChangeRecipeTagsCommand) {
    this.recipeService.changeTags(value).subscribe({
      next: (isValidResponse: boolean) => {
        if (isValidResponse) this.recipe = this.recipeService.currentRecipe;
      }
    });
  }

  isUserVerifierOfRecipe(): boolean {
    return this.recipe.tracking.assignedVerifiers.includes(this.currentUser.puid);
  }

  isUserApproverOfTheRecipe(): boolean {
    return this.recipe.tracking.assignedApprovers.includes(this.currentUser.puid);
  }

  isUserEditorOfTheRecipe(): boolean {
    return this.recipe.tracking.assignedEditors.includes(this.currentUser.puid);
  }

  isVerifiedChanged() {
    const $event = !this.recipe.verificationDetails.isVerified;
    if (!this.checkRecipeState(['pendingVerification']) || !this.isUserVerifierOfRecipe()
      || !this.enableAcceptanceAndVerified || this.recipe.verificationDetails.purpose.trim() === '' ||
      !this.recipe.verificationDetails.isRecipeSatisfiesItsPurpose ||
      !this.recipe.verificationDetails.isConsideredReviewed) {
      return;
    }
    const changeRecipeVerifiedCommand: ChangeRecipeVerifiedCommand = {
      isVerified: $event,
      recipeId: this.recipeService.currentRecipeId
    };

    if ($event) {
      this.recipeService.reauthenticate(
        () => {
          this.ngZone.run(() => {
            this.recipe.verificationDetails.isVerified = true;
            this.recipe.verificationDetails.verifiedBy = this.currentUser.puid;
            this.recipe.verificationDetails.verifiedOn = Instant.now().toString();
          });
          this.recipeService.changeRecipeVerified(changeRecipeVerifiedCommand);
        },
        ELNFeatureFlags.EnableRecipeWorkflowReAuth,
        $localize`:@@RecipeVerificationInvalidCredentials:Credentials are incorrect and the verification was not successful`
      );
    } else {
      this.recipeService.changeRecipeVerified(changeRecipeVerifiedCommand);
      this.recipe.verificationDetails.isVerified = false;
    }
  }

  isApprovalChanged() {
    if (!this.checkRecipeState(['pendingApproval']) || !this.isUserApproverOfTheRecipe() || this.recipeService.isVerifierAndApproverSameASCurrentUser(this.currentUser.puid)) {
      return;
    }

    const $event = !this.recipe.verificationDetails.isApproved;
    if (!$event) {
      this.isApprovedChanged($event);
    } else {
      this.recipeService.reauthenticate(
        () => {
          this.ngZone.run(() => {
            this.recipe.verificationDetails.isApproved = $event;
            this.recipe.verificationDetails.approvedBy = this.currentUser.puid;
            this.recipe.verificationDetails.approvedOn = Instant.now().toString();
          });
          this.isApprovedChanged($event);
        },
        ELNFeatureFlags.EnableRecipeWorkflowReAuth,
        $localize`:@@RecipeApprovalInvalidCredentials:Credentials are incorrect and the approval was not successful`
      );
    }
  }

  isApprovedChanged($event: boolean) {
    this.recipeService
      .changeRecipeApproved({
        isApproved: $event,
        recipeId: this.recipeService.currentRecipeId
      })
      .subscribe({
        next: (isValidResponse: boolean) => {
          if (isValidResponse) this.recipe = this.recipeService.currentRecipe;
        }
      });
  }

  isRecipeSatisfiesPurposeChanged($event: boolean) {
    if (!this.checkRecipeState(['pendingVerification'])) {
      return;
    }
    this.recipeService
      .changeRecipeSatisfiesPurpose({
        isSatisfyPurpose: $event,
        recipeId: this.recipeService.currentRecipeId
      })
      .subscribe({
        next: (isValidResponse: boolean) => {
          if (isValidResponse) this.recipe = this.recipeService.currentRecipe;
        }
      });
  }

  isRecipeReviewedChanged($event: boolean) {
    if (!this.checkRecipeState(['pendingVerification'])) {
      return;
    }
    this.recipeService
      .changeRecipeReviewed({
        isConsiderReviewed: $event,
        recipeId: this.recipeService.currentRecipeId
      })
      .subscribe({
        next: (isValidResponse: boolean) => {
          if (isValidResponse) this.recipe = this.recipeService.currentRecipe;
        }
      });
  }

  formatOptionalInstant(value: string | Instant | undefined) {
    return value ? formatInstant(value, DateAndInstantFormat.dateTimeToMinute) : value;
  }

  /** This method compares the input list with existing list
   *  return an object of lists with the values that are either added or removed
   */
  getUpdatedData(
    newValue: string[],
    existingData: string[]
  ): { added: string[]; removed: string[] } {
    const updatedData: Array<string> = newValue;
    const added = difference(updatedData, existingData) ?? [];
    const removed = difference(existingData, updatedData) ?? [];
    return { added, removed };
  }

  /** This method return's a an object when the change event is valid
   *  else return's undefined to avoid event creation
   */
  checkModifiedList(
    newValue: string[],
    oldValue: string[]
  ): { recipeId: string; added: string[]; removed: string[] } | undefined {
    const { added, removed } = this.getUpdatedData(newValue, oldValue);
    if (added.length === 0 && removed.length === 0) {
      return;
    }
    return {
      recipeId: this.recipeService.currentRecipeId,
      added: added,
      removed: removed
    };
  }

  getRecipeTypeDisplayValue(type: RecipeType) { }

  //Role Access
  private setAccessControl() {
    switch (this.recipe.tracking.state) {
      case RecipeState.Draft as RecipeState:
        this.setAccess('PermittedEditableFieldsInDraft');
        break;
      case RecipeState.Cancelled:
        this.setAccess('PermittedEditableFieldsInCancelled');
        break;
      case RecipeState.PendingApproval:
        this.setAccess('PermittedEditableFieldsInPendingApproval');
        break;
      case RecipeState.PendingVerification:
        this.setAccess('PermittedEditableFieldsInPendingVerification');
        break;
      case RecipeState.Published:
        this.setAccess('PermittedEditableFieldsInPublished');
        break;
      case RecipeState.Retired:
        this.setAccess('PermittedEditableFieldsInRetired');
        break;
      default:
        break;
    }
  }

  private isEditable(featureName: string, field: string) {
    return this.featureFlags.some(data => JSON.parse(data)[featureName]?.includes(field));
  }

  private setAccess(featureName: string) {
    this.enableBasicAndAdditionalFields = this.isEditable(featureName, 'basicAdditional');
    this.enableApprovalRequiredAndPurpose = this.isEditable(featureName, 'approvalWithPurpose');
    this.enableAdditionalNotes = this.isEditable(featureName, 'additionalNotes');
    this.enableAcceptanceAndVerified = this.isEditable(
      featureName,
      'acceptanceTestingWithVerified'
    );
    this.enableApproved = this.isEditable(featureName, 'approved');
  }

  private readonly changeAvailabilityAcceptCallback = () => {
    this.recipeService.changeAvailability();
  };
  private readonly changeAvailabilityRejectCallback = () => {
    this.recipeAvailable = this.recipe.tracking.available;
  };

  changeAvailability() {
    this.recipeService.confirmationDialog(
      this.recipe.tracking.available
        ? this.recipeNotAvailableFlagConfirmation
        : this.recipeAvailableFlagConfirmation,
      this.changeAvailabilityAcceptCallback,
      this.changeAvailabilityRejectCallback
    );
  }

  copyReferenceToClipboard() {
    this.writeToClipboard(this.recipe.number).then((_) => {
      this.copyRecipeCurrentTooltip = this.recipeReferenceCopied;
      this.getTooltipById(this.copyRecipeReferenceId)?.activate();
    });
  }

  getTooltipById(nativeElementId: string): Tooltip | undefined {
    return this.tooltips.find((tooltip) => tooltip.el.nativeElement.id === nativeElementId);
  }

  writeToClipboard(data: string): Promise<void> {
    return navigator.clipboard.writeText(data);
  }
}
