<div class="eln-add-reference-table">
  <div class="eln-input-data">
    <div>
      <bpt-progress-spinner *ngIf="isLoading"
        i18n-message="@@loading"
        message="Loading..."
        [useOverlay]="true"
      ></bpt-progress-spinner>
      <bpt-progress-spinner
        i18n-message="@@loadingHistory"
        message="Loading History..."
        [useOverlay]="true"
        *ngIf="isHistoryLoading"></bpt-progress-spinner>
      <div class="col-10 eln-exp-table-header module-item-title flex flex-row align-items-center gap-3">
        <p-contextMenu [target]="tableTitle" [model]="tableContextMenuItems"></p-contextMenu>
        <div class="tableTitle" #tableTitle>
          <span class="eln-module-tab-header" i18n="@@crossReferences">Cross References</span>
        </div>
      <app-completion-tracking [completionPercent]=completionPercent></app-completion-tracking>
      </div>
      <ng-container class="bpt-grid-toolbar-left-layout" *ngIf="allowAddRow && activity.activityReferences.crossReferences.length > 0">
        <div
          class="eln-add-reference-button"
          (click)="showSlider()"
          [tabIndex]="1">
          <a *ngIf="!userIsReviewer" class="table-grid-toolbar-left">
            <span class="pi pi-plus"></span>
            <span class="ml-1" styleClass="bpt-grid-add-rows-button" i18n="@@addReference">Add Reference</span>
          </a>
        </div>
      </ng-container>

      <bpt-grid *ngIf="activity.activityReferences.crossReferences.length > 0"
        #Grid
        class="eln-grid eln-cross-references-grid"
        [gridId]="gridId"
        [useJsJodaTypes]="true"
        [columnDefinitions]="columns"
        [dataSource]="activeRows"
        [readOnly]="readOnly"
        [allowRowAdd]="false"
        [tabIndex]="2"
        [showAutoSizeButton]="true"
        [showFilterToggleButton]="true"
        [showGridOptionsButton]="true"
        [contextMenu]="getContextMenu()"
        [rowActionConfigurations]="gridActions"
        (cellEditStarted)="cellEditStartedEvent($event)"
        (cellEditStopped)="cellEditStoppedEvent($event)"
        (cellKeyDown)="cellKeyDownEvent($event)"
        (gridReady)="onGridReady()"
        (firstDataRendered)="onFirstDataRendered($event)"
        (cellValueChanged)="cellValueEditing($event)"
        >
        <ng-container class="bpt-grid-toolbar-right-layout">
          <a
            id="eln-iconAuditHistoryShow"
            class="table-grid-toolbar-audit-icon"
            [ngClass]="{ 'leftmost-button': activeRows.length === 0 }"
            (click)="showHistoryDialog()"
            [tabindex]="3">
            <span
              class="fa fa-history"
              i18n-pTooltip="@@viewHistory"
              pTooltip="View History"
              tooltipPosition="top"><span class="ml-1 audit" i18n="@@history">History</span></span>
          </a>
          <a
            *ngIf="containsRemovedRows"
            class="bpt-grid-toolbar-item table-grid-toolbar-view-removed-rows-icon reference-remove-icon ml-3"
            (click)="loadRemovedRowsDialog()"
          >
            <span
              class="fa icon-view-row"
              i18n-pTooltip="@@viewRemovedRows"
              pTooltip="View Removed Rows"
              tooltipPosition="top"
              ><span class="ml-1 viewRemovedRows-iconTxt" i18n="@@viewRemovedRows"
                >View Removed Rows</span
              ></span
            >
          </a>
        </ng-container>
      </bpt-grid>

      <app-cross-reference-slider
        [activity]="activity"
        [visible]="showCrossReferenceSlider"
        (closed)="crossReferenceSliderClosed()"
      ></app-cross-reference-slider>
    </div>
  </div>
</div>
