import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { ExperimentService } from '../../experiment/services/experiment.service';
import { UserService } from '../../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class CollaboratorWarningInterceptor implements HttpInterceptor {
  constructor(
    private readonly experimentService: ExperimentService,
    private readonly userService: UserService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          if (event.body && req.method === 'POST' && this.userService.hasReviewerRights(this.userService.currentUser.puid)
            && (!this.experimentService._isCurrentUserCollaborator || this.experimentService._isCurrentUserCollaborator === undefined)) {
            this.experimentService.amICollaborator().subscribe(userCollaborator => {
              if (userCollaborator !== undefined) this.experimentService._isCurrentUserCollaboratorSubject$.next(userCollaborator);
            })
          }
        }
        return event;
      })
    );
  }
}
