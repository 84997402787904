export interface ApiConfiguration {
  coreServicesUrl: string;
  searchServiceUrl: string;
  auditServiceUrl: string;
  dataEntryServicesUrl: string;
  dataPackageServiceUrl: string;
  internalCommentServiceUrl: string;
  cookbookServiceUrl: string;
  instrumentIntegrationServiceUrl: string;
  instrumentAdminServiceUrl: string;
  instrumentServerUrl: string;
  elnInstrumentsServiceUrl: string;
  elnFilesServiceUrl: string;
  elnLabelsServiceUrl: string;
}

export interface AppConfiguration {
  ElnApiConfig: ApiConfiguration;
  elnWebUrl: string;
  instrumentDisconnectTimeout: number,
  logging: LoggingConfiguration;
  features?: FeatureConfiguration;
}

export interface LoggingConfiguration {
  logViewerUrl: string;
  enableDisplay: boolean;
}

export interface FeatureConfiguration {
  systemAlert?: SystemAlertConfiguration;
}

export interface SystemAlertConfiguration {
  message: string;
}

export const referencesConstants = {
  documentPicklistId: '2a3f5de5-2b99-4021-bfe1-666457b0528b',
  compendiaPicklistId: '00c26ec6-ee74-49ed-9ba8-f88270daabf1'
}
