import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BptGridComponent, BptGridMode, BptGridRowActionClickEvent, BptGridRowActionConfiguration, BptRowActionElement, ColumnDefinition } from 'bpt-ui-library/bpt-grid';
import { BehaviorSubject, Subscription } from 'rxjs';
import { PromptsService } from '../../prompts.service';
import { PromptItem, PromptType } from '../../../../api/models';
import { ClientStateService } from '../../../../services/client-state.service';
import { UnsubscribeAll } from '../../../../shared/rx-js-helpers';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { BaseComponent } from '../../../../base/base.component';
import { ActivatedRoute } from '@angular/router';
import { LockType } from '../../../../model/input-lock.interface';
@Component({
  selector: 'app-prompts-removed-rows',
  templateUrl: './prompts-removed-rows.component.html',
  styleUrls: ['./prompts-removed-rows.component.scss']
})
export class RemovedPromptsComponent extends BaseComponent implements OnInit {
  @Input() titleOfTable = $localize`:@@PromptsTableTitle:Prompts`;
  promptsRemovedDataSource: PromptItem[] = [];
  @ViewChild('removedPromptsGrid') grid!: BptGridComponent;
  subHeading = $localize`:@@promptsRemovedPromptsSubHeading:Please click on the restore icon from the table to restore removed Prompts`;
  gridMode = BptGridMode.dataView;
  columnDefinitions: ColumnDefinition[] = [];
  promptType?: PromptType;
  removedRowsPromptId = 'removedRowspromptId';
  gridActions!: BptGridRowActionConfiguration;
  subscriptions: Subscription[] = [];

  constructor(
    public readonly clientStateService: ClientStateService,
    private readonly dynamicDialogConfig: DynamicDialogConfig,
    private readonly promptsService: PromptsService,
    public readonly activatedRoute: ActivatedRoute
  ) {
    super(clientStateService, activatedRoute);
    this.handleSubscriptions();
  }

  ngOnInit(): void {
    this.promptType = this.dynamicDialogConfig.data.type;
    this.columnDefinitions = this.dynamicDialogConfig.data.columnDefinitions;
    this.addGridActions();
    this.refreshDataSource();
  }

  private refreshDataSource(): void {
    const removedPrompts = this.promptsService.getRemovedPrompts(this.promptType);
    this.promptsRemovedDataSource = [...removedPrompts];
    this.grid?.gridApi?.setGridOption('rowData', this.promptsRemovedDataSource);
    this.grid?.updateColumnDefinitions();
    this.grid?.gridApi?.refreshCells({force: true});
  }

  private addGridActions() {
    const rowActions = this.getRowActionItems();
    const actionsSubject: BehaviorSubject<BptRowActionElement[]> = new BehaviorSubject(rowActions);
    this.gridActions = {
      actions: actionsSubject,
    };
  }

  public getRowActionItems(): BptRowActionElement[] {
    if(!this.promptType) return [];
    return [
      {
        id: 'lab-item-prompts-restore-row',
        enabled: this.promptsService.validateRestorePromptFeatureToEnable.bind(this, this.promptType, "eln.experiment.activities"), //will correct client state in next PR
        styleClass: 'icon-s icon-restore-icon',
        click: this.rowRestoreActionClick.bind(this),
        tooltip: $localize`:@@RestoreItem:Restore item`
      }
    ];
  }

  private rowRestoreActionClick(e: BptGridRowActionClickEvent) {
    if(!this.promptType) return;
    if (!this.promptsService.hasRestorePermission(this.promptType)) {
      this.promptsService.failedToPerformOperationMessage();
      return;
    }
    this.promptsService.sendInputStatus(
      LockType.lock,
      e.gridId ?? '',
      e.params.data.promptId,
      this.promptsService.rowActionIds['restoreRowIconId']
    );
    e.params.data.id = e.params.node.rowIndex;
    this.promptsService.confirmThenRestorePrompts(e.params.data);
  }

  private handleSubscriptions() {
    this.subscriptions.push(this.promptsService.PromptRestored.subscribe({
      next: () => {
        this.refreshDataSource();
      }
    }))
  }

  ngOnDestroy(): void {
    UnsubscribeAll(this.subscriptions);
  }
}