import { Injectable, Renderer2 } from '@angular/core';
import { ExperimentCollaboratorsService } from '../../services/experiment-collaborators.service';
import { ExperimentNotificationService } from '../../services/experiment-notification.service';
import { ExperimentService } from './experiment.service';
import { FieldLock, LockType } from '../../model/input-lock.interface';
import { ELNAppConstants } from '../../shared/eln-app-constants';

@Injectable({
  providedIn: 'root'
})
export class ActivityOutputCollaboratorService {
  private readonly pointerEventsAttr = 'pointer-events';
  private readonly colorString = 'color';
  readonly deleteIdentifier = 'empower-grid-iconRemove';
  readonly refreshIdentifier = 'empower-grid-iconRefresh';
  readonly nonRoutineIdentifier = 'non-routine';
  readonly returnToServiceIdentifier = 'returned-to-service';

  constructor(
    private readonly experimentNotificationService: ExperimentNotificationService,
    public readonly experimentService: ExperimentService,
    private readonly experimentCollaboratorsService: ExperimentCollaboratorsService
  ) {}

  sendInputStatus(lockType: LockType, experimentId: string, path: string[], formId: string) {
    const fieldLock = new FieldLock(
      experimentId,
      lockType,
      this.experimentService.currentModuleId,
      this.experimentService.currentActivityId,
      this.experimentNotificationService.getCollaborator(),
      path,
      formId
    );
    this.experimentNotificationService.sendInputControlStatus([fieldLock]);
  }

  public lockActionItems(lock: FieldLock, renderer: Renderer2) {
    const lockOwner =
      this.experimentCollaboratorsService.getExperimentCollaborator(
        lock.experimentCollaborator.connectionId
      ) ?? lock.experimentCollaborator;
    const cell = document.querySelector('#' + lock.key);
    if (!cell) return;
    if (lock.lockType === LockType.lock) {
      renderer.setAttribute(cell.parentElement, 'title', lockOwner?.fullName || '');
      renderer.setStyle(cell, this.colorString, ELNAppConstants.OutputButtonDisabledColor);
      renderer.setStyle(cell, 'opacity', `0.5`);
      renderer.setStyle(cell, this.pointerEventsAttr, `none`);
    } else {
      renderer.removeStyle(cell, this.colorString);
      renderer.removeAttribute(cell.parentElement, 'title');
      renderer.setStyle(cell, 'opacity', `1`);
      renderer.removeStyle(cell, this.pointerEventsAttr);
    }
  }
}
