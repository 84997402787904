<ng-template #ClientFacingNotesSlideContent>
  <div class="flex flex-auto flex-wrap flex-column cfn-container">
    <app-client-facing-note
      #newComment
      id="cfn-new"
      *ngIf="!readOnly && showNotes"
      [fieldPlaceholder]="clientFacingNoteConstants.newEntryLabel"
      (whenCommentAdded)="publishClientFacingNoteEvent($event)"
      [visible]="newCommentVisible"
      [clientFacingNote]="newClientFacingNote"
      [isNewClientFacingNote]="true"></app-client-facing-note>
    <ng-container *ngIf="showNotes">
      <app-client-facing-note *ngFor="let note of clientFacingNotes"
        #note
        [id]="note.cssId"
        [fieldPlaceholder]="clientFacingNoteConstants.newEntryLabel"
        (whenCommentUpdated)="publishClientFacingNoteEvent($event)"
        [clientFacingNote]="note"
        [isNewClientFacingNote]="false"
        [statements]="statements">
      </app-client-facing-note>
    </ng-container>
    <app-applied-statement [statements]="getTableCellAppliedStatements()">
    </app-applied-statement>
  </div>
</ng-template>
<bpt-slider
  #commentsSlider
  id="commentsSlider"
  [closeOnEscape]="closeOnEsc"
  [(visible)]="visible"
  (visibleChange)="commentsSliderVisibleChange($event)"
  [headerText]="clientFacingNoteConstants.header"
  [displayFooter]="false"
  class="comments-side-panel"
  [size]="'medium'">
  <ng-container *ngTemplateOutlet="ClientFacingNotesSlideContent"></ng-container>
</bpt-slider>
