import { Component, ElementRef, HostListener, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Activity, Experiment, Form, Module, Table } from 'model/experiment.interface';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { TabView } from 'primeng/tabview';
import { Observable, Subscription, Subject } from 'rxjs';
import { ExperimentService } from '../services/experiment.service';
import { ExperimentResponse, ExperimentWorkflowState, NodeType } from '../../api/models';
import { AuditHistoryService } from '../audit-history/audit-history.service';
import { ExperimentTemplateEventService } from '../../template-loader/experiment-template-load/services/experiment-template-event.service';
import { DataRecordService } from '../services/data-record.service';
import { ClientValidationDetails } from 'model/client-validation-details';
import { WorkflowEventNotification } from '../../api/data-entry/models/workflow-event-notification';
import { NodeCompletionStatus } from '../model/node-completion-status.interface';
import { ComponentCanDeactivate } from '../../../app/routing/unsaved-changes.guard';
import { CommentDetails } from '../comments/comment.model';
import { CommentContextType } from '../../api/internal-comment/models';
import { CommentService } from '../comments/comment.service';
import { ContextMenu } from 'primeng/contextmenu';
import { ExperimentNodeRetitleService } from '../services/experiment-node-re-title.service';
import { UnsubscribeAll } from '../../shared/rx-js-helpers';
import { ExperimentNodeReOrderService } from '../services/experiment-node-re-order.service';
import { moveItemInArray} from '@angular/cdk/drag-drop';
import { InstrumentConnectionHelper } from '../instrument-connection/shared/instrument-connection-helper';
import { FillWithNaGridHelper } from '../../services/fill-with-na-grid-helper';
import { ReferencesService } from '../references/references.service';
import { UserService } from 'services/user.service';
import { User } from 'model/user.interface';
import { NA } from 'bpt-ui-library/shared';
import { ExperimentEventType } from '../../api/data-entry/models/experiment-event-type';

/**
 * "ActivityComponent": Renderer for an Activity
 */
@Component({
  selector: 'app-data',
  templateUrl: './data.component.html',
  styleUrls: ['./data.component.scss']
})
export class DataComponent implements OnInit, OnDestroy, ComponentCanDeactivate {
  @ViewChild('tabview') tabview!: TabView;
  @ViewChild('cmForMoreModule') moreModuleContextMenu!: ContextMenu;
  scrollable = true;
  experiment!: Experiment;
  activity!: Activity;
  /** Modules shown in this activity, i.e. those !isHidden */
  modules: Module[] = [];
  /**
   * fixedModules  will be shown in the available client width and
   * moreModules will be shown in the More dropdown
   */
  fixedModules: Module[] = [];
  moreModules: Module[] = [];
  moreModuleDropdownOptions: MenuItem[] = [];
  selectedModule!: any;
  moreModulesHeader = $localize`:@@More:More`;
  items!: MenuItem[];
  itemsMoreModules!: MenuItem[];
  dynamicDialogRef!: DynamicDialogRef;
  moduleName!: string;
  private readonly routeSubscription?: Subscription;
  private readonly activeSubscriptions: Subscription[] = [];
  isLoading = false;
  instrumentConnectionLoading = true;
  message!: string;
  checkModuleIsCurrent = true;
  user!: User;
  validation!: ClientValidationDetails;
  nodeCompletionArray!: Array<any>;
  internalCommentData?: CommentDetails;
  contextCurrentModuleId?: string;
  currentModuleId?: string;
  doesUserHaveOnlyReviewerRights: boolean;
  /** Note: Experiment also has experimentCompletionStatus. This one here is for ??? */
  experimentCompletionStatus: NodeCompletionStatus[] = [];
  canDeactivateEvent: Subject<boolean> = new Subject<boolean>();
  options = {
    completeTooltip: 'The module is completed',
    incompleteTooltip: 'The module has incomplete items'
  };
  retitleEnabled: {[moduleId: string]: boolean} = {};
  nodeType = NodeType;
  isInstrumentDetailsFetched = false;
  isInstrumentLIMSDetailsFetched = false;
  isInstrumentConnected = false;

  constructor(
    public readonly experimentService: ExperimentService,
    private readonly route: ActivatedRoute,
    private readonly renderer: Renderer2,
    private readonly elementRef: ElementRef,
    private readonly experimentTemplateEventService: ExperimentTemplateEventService,
    private readonly auditHistoryService: AuditHistoryService,
    private readonly dataRecordService: DataRecordService,
    private readonly commentService: CommentService,
    public readonly experimentNodeReOrderService: ExperimentNodeReOrderService,
    public readonly fillWithNAGridHelper: FillWithNaGridHelper,
    public readonly userService: UserService,
    private readonly instrumentConnectionHelper: InstrumentConnectionHelper,
    private readonly confirmationService: ConfirmationService,
    public readonly experimentNodeRetitleService: ExperimentNodeRetitleService
  ) {
    this.instrumentConnectionLoading = !this.instrumentConnectionHelper.isInstrumentConnected;
    this.user = { ...this.userService.currentUser };
    this.activeSubscriptions.splice(0, 0,
      this.dataRecordService.experimentWorkFlowDataRecordReceiver.subscribe(
        (data: WorkflowEventNotification) => {
          if (data) this.applyWorkflowStateTransition(data.state);
          this.buildContextMenuItems();
        }
      ),
      this.experimentService.experimentWorkFlowState.subscribe((data: ExperimentWorkflowState) =>
        {
          this.applyWorkflowStateTransition(data);
          this.buildContextMenuItems();
        }
      ),
      this.experimentService.nodeCompletionStatus.subscribe(
        (data: NodeCompletionStatus) => {
          this.getCompletionStatus(data);
          this.canShowCompletionIcon();
          this.checkActivityCompletion();
        }
      ),
      );

    this.activeSubscriptions.push(experimentNodeReOrderService.nodeOrderChanged.subscribe({
      next: (data) =>{
        if (data.nodeType === this.nodeType.Module) {
          const moduleToBeDeleted = this.fixedModules.find(
            (a) => a.moduleId === data.nodeId
          );
          this.fixedModules.forEach((item, index) => {
            if(item.moduleId === data.nodeId) {
              this.fixedModules.splice(index,1);
              this.modules.splice(index,1);
            }
          });
          this.fixedModules.splice(data.position, 0, moduleToBeDeleted as Module);
          this.modules.splice(data.position, 0, moduleToBeDeleted as Module);
          const index = this.fixedModules.findIndex(x => x?.moduleLabel === this.experimentService.currentModuleName);
          this.fixupTabView(index);
        }
      }
    }));
    this.doesUserHaveOnlyReviewerRights = this.userService.hasOnlyReviewerRights();
  }

  private checkInstrumentConnection() {
    if ( this.isInstrumentDetailsFetched && this.isInstrumentLIMSDetailsFetched && this.isInstrumentConnected){
      this.instrumentConnectionLoading = false;
    }
  }

  ngOnInit(): void {
    this.experiment = this.experimentService.currentExperiment!;
    this.activeSubscriptions.push(this.route.params.subscribe((params) => {
      const activity = this.experimentService.GetActivityBasedOnParams(params);
      if (activity) this.activity = activity;
      this.refreshActivity();
      if (this.activity) { // property is not declared to be possibly undefined but at least the test "should not find invalid activity" anticipates that it could be
        this.renderer.setAttribute(this.elementRef.nativeElement, 'data-id', this.activity.activityId);
        this.renderer.setAttribute(this.elementRef.nativeElement, 'data-title', this.activity.itemTitle);
      }
    }));
    this.activeSubscriptions.splice(0, 0,
      this.instrumentConnectionHelper.instrumentConnectionSuccessForOtherExperiments.subscribe(() => {
        this.isInstrumentConnected = true;
        this.checkInstrumentConnection();
      }),
      this.instrumentConnectionHelper.pageLoaded.subscribe(() => {
        this.instrumentConnectionLoading = false;
      }),
      this.instrumentConnectionHelper.instrumentJoinHubStatus.subscribe(() => {
        this.isInstrumentConnected = true;
        this.checkInstrumentConnection();
      }),
      this.instrumentConnectionHelper.instrumentDetailsFetched.subscribe(() => {
        this.isInstrumentDetailsFetched = true;
        this.checkInstrumentConnection();
      }),
      this.instrumentConnectionHelper.instrumentLIMSDetailsFetched.subscribe(() => {
        this.isInstrumentLIMSDetailsFetched = true;
        this.checkInstrumentConnection();
      }),
      this.instrumentConnectionHelper.instrumentDetailsFetchInitiated.subscribe(() => {
        this.instrumentConnectionLoading = true;
        this.isInstrumentLIMSDetailsFetched = false;
      }));
    this.watchExperimentRefreshedNotification();
    this.setValidationMessage();
    this.canShowCompletionIcon();
    if (this.modules && this.modules.length > 0) {
      this.experimentService.currentModuleName = this.modules[0].moduleName;
      this.experimentService.currentModuleId = this.modules[0].moduleId; //assignment in module.ts is removed bcz we are assigning moduleId here
    }
    this.buildContextMenuItems();
  }

  drop(event: any) {
    if (event.previousIndex === event.currentIndex) {
      return;
    }
    const newPosition = event.currentIndex;
    const movedObject = this.fixedModules[event.previousIndex];
    this.experimentNodeReOrderService.changeNodeReorder(
      this.nodeType.Module,
      movedObject.moduleId,
      this.activity.activityId,
      newPosition,
      movedObject.moduleLabel,
      this.activity.activityId
    );
    moveItemInArray(this.fixedModules, event.previousIndex, event.currentIndex);
    moveItemInArray(this.modules, event.previousIndex, event.currentIndex);
    const index = this.fixedModules.findIndex(x => x.moduleLabel === this.selectedModule.moduleLabel);
    this.fixupTabView(index);
  }

  private setValidationMessage() {
    this.validation = new ClientValidationDetails();
    if (this.experiment.workflowState === ExperimentWorkflowState.Setup) {
      this.validation.warnings.push(
        $localize`:@@experimentInSetupWarning:
        Experiment is in Setup. Please click 'Start Experiment' to enable editing of Observable Data within the Experiment.`
      );
    }
  }

  applyWorkflowStateTransition(currentWorkflow: ExperimentWorkflowState) {
    if (currentWorkflow !== ExperimentWorkflowState.Setup) {
      this.validation.clear();
    }
  }

  private watchExperimentRefreshedNotification() {
    this.activeSubscriptions.push(
      this.experimentService.templateEventService.ExperimentRefreshed.subscribe((_response) => {
        this.refreshActivity();
      })
    );
  }



  @HostListener('window:beforeunload')
  canDeactivate(): boolean | Observable<boolean> {
    setTimeout(() => {
      this.canDeactivateEvent.next(true);
    }, 250);
    return this.canDeactivateEvent.asObservable();
  }

  @HostListener('window:resize', ['$event'])
  onResize(_event: any) {
    try {
      this.fixedModules = [];
      this.fixedModules.push(...this.modules);
      this.moreModules = [];
      this.moreModuleDropdownOptions = [];
      this.fixupTabView();
    } catch (error) {
      console.error("Error occurred while processing resize", error);
    }
  }

  private refreshActivity(): void {
    this.experiment = this.experimentService.currentExperiment as Experiment;
    if (this.activity) {
      this.activity = this.experimentService.currentExperiment?.activities.find(activity => activity.activityId === this.activity.activityId) as Activity;
      this.experimentService.currentActivityId = this.activity.activityId;
      this.experimentService.activitySelectionChanged.next(this.activity.activityId);
      this.experimentTemplateEventService.TemplateSelectionChangedNotification(this.activity);
      this.modules = [...((this.activity.dataModules ?? []))].filter((x) => !x.isHidden);
      this.selectedModule = this.modules[0];
      this.fixedModules = [];
      this.fixedModules.push(...this.modules);
      this.moreModules = [];
      this.moreModuleDropdownOptions = [];
      this.experimentService.currentModuleId = this.selectedModule.moduleId;
      this.fixupTabView();
    }
  }

  public onMoreModuleContext(event: any) {
    if (event.target.innerText.includes(this.moreModulesHeader)) {
      this.moreModuleContextMenu.hide();
    }
    else {
      const moduleId = this.moreModules.find(m => m.moduleName === event.target.innerText)?.moduleId;
      this.contextCurrentModuleId = moduleId;
      if (this.contextCurrentModuleId === this.experimentService.currentModuleId) {
        this.moreModuleContextMenu.show(event);
      }
      else {
        this.moreModuleContextMenu.hide();
      }
    }
  }
  public buildMoreModulesTab() {
    if (this.isMoreModulesDropdownNeeded() && !this.scrollable) {
      for (let i = this.modules.length - 1; i > 2; i--) {
        if (this.isModuleExceedingAvailableWidth(i)) {
          this.moreModules.unshift(this.modules[i]);
          this.fixedModules.splice(i, 1);
        }
      }
      this.buildMoreModuleOptionsForDropdown();
    }
  }

  private isMoreModulesDropdownNeeded(): boolean {
    return (
      this.tabview.navbar.nativeElement.clientWidth > this.tabview.content.nativeElement.clientWidth
    );
  }

  private isModuleExceedingAvailableWidth(index: number) {
    const modeModuleDropDownCutOff = this.tabview.navbar.nativeElement.children[index].clientWidth - 100 ;
    return (
      this.tabview.navbar.nativeElement.children[index].offsetLeft + this.tabview.navbar.nativeElement.children[index].clientWidth >
      this.tabview.content.nativeElement.clientWidth - modeModuleDropDownCutOff
    );
  }

  private buildMoreModuleOptionsForDropdown() {
    this.moreModules.forEach((m) => {
      this.moreModuleDropdownOptions.push({
        id: m.moduleId,
        label: m.moduleLabel,
        icon: m.isModuleComplete ? 'icon-check icon-m' : 'icon-exclamation-mark icon-s',
        styleClass: '',
        tooltipOptions: {
          tooltipLabel: m.isModuleComplete
            ? $localize`:@@moduleComplete:The module is completed`
            : $localize`:@@moduleIncomplete:The module has incomplete items`,
          tooltipPosition: 'left',
          tooltipEvent: 'hover'
        },
        command: (event) => {
          this.changeTabSelection();
          this.selectedModule = this.modules.find((x) => x.moduleId === event.item.id);
          this.experimentService.currentModuleId = this.selectedModule.moduleId;
          this.experimentService.currentModuleName = this.selectedModule.moduleName;
          this.experimentTemplateEventService.TemplateSelectionChangedNotification(
            this.selectedModule
          );
          this.adjustTabsHighlight(event);
        }
      });
    });
  }

  private changeTabSelection(): void {
    const selectedTab = this.tabview.findSelectedTab();
    selectedTab.selected = false;
    this.tabview.tabs[this.tabview.tabs.length - 1].selected = true;
    this.tabview.tabChanged = true;
  }

  private adjustTabsHighlight(event: any): void {
    this.moreModuleDropdownOptions.forEach((x) => (x.styleClass = ''));
    event.item.styleClass = 'p-dropdown-item-active';
  }

  moduleSelectionChanged(selectionChangedEvent: any) {
    this.updateInkBarWidth(selectionChangedEvent.index);
    this.experimentService.currentModuleId = this.modules[selectionChangedEvent.index].moduleId;
    this.currentModuleId = this.modules[selectionChangedEvent.index].moduleId;
    this.experimentService.currentModuleName = this.modules[selectionChangedEvent.index].moduleName;
    this.experimentTemplateEventService.TemplateSelectionChangedNotification(
      this.modules[selectionChangedEvent.index]
    );
  }

  fetchActivityAndModule(actId:string, modId:string){
    const activity = this.experiment.activities.find(
      (act) => act.activityId === actId
    );
    const module = (activity?.dataModules ?? []).find(
      (mod) => mod.moduleId === modId
    );
    return [activity,module];
  }

  loadInternalCommentsForModuleLevel(moduleId: string) {
    const data =  this.fetchActivityAndModule(this.experimentService.currentActivityId, moduleId);
    const activity = data[0] as Activity;
    const module = data[1] as Module;
    this.internalCommentData = {} as CommentDetails;
    this.internalCommentData.nodeId = this.experiment.id;
    this.internalCommentData.path = [activity.activityId, module.moduleId, CommentContextType.Module]
    this.internalCommentData.contextType = CommentContextType.Module;
    this.commentService.openInternalComments(this.internalCommentData);
  }

  ngOnDestroy(): void {
    this.routeSubscription?.unsubscribe();
    UnsubscribeAll(this.activeSubscriptions);
    if (this.dynamicDialogRef) {
      this.dynamicDialogRef.close();
    }
  }

  public fixupTabView(index = 0) {
    // Tabview inkbars and buttons display inconsistently, so this code fixes them.
    // Toggling scrollable and calling updateButtonState forces the scroll buttons to display properly.
    // Calling updateInkBar directly fixes how the bar is displayed.
    // This doesn't work properly in the tests because there is no active tab, causing an error
    // when calling updateInkBar, so this has to be bypassed.
    this.scrollable = true;
    setTimeout(() => {
      this.scrollable = false;
      this.tabview.updateButtonState();
      this.tabview.updateInkBar();
      if(index === this.fixedModules.length - 1){
        this.updateInkBarWidth(index);
      } else{
        this.updateInkBarWidth();
      }
      this.buildMoreModulesTab();
    });
  }

  private updateInkBarWidth(index = 0) {
    setTimeout(() => {
      if (index !== this.fixedModules.length - 1) {
        const inkBarWidth = +this.tabview.inkbar.nativeElement.style.width.split('px')[0];
        this.tabview.inkbar.nativeElement.style.width = `${inkBarWidth - 30}px`;
      }
    });
  }

  /**
   * Gets called to load audit history dialog
   */
  loadAuditHistoryDialog(moduleId: string) {
    this.message = $localize`:@@loadingHistory:Loading History...`;
    this.isLoading = true;
    const response = this.experiment as unknown as ExperimentResponse;
    const module = response.modules
      .find((m) => m.moduleId === moduleId);
    const childOrders = module
      ?.childOrder.flat();
    const formIds = response.forms
      .filter((f) => childOrders?.includes(f.formId))
      .map((f) => f.formId);
    const tableIds = response.tables
      .filter((t) => childOrders?.includes(t.tableId))
      .map((t) => t.tableId);
    // For synthetic modules, activityId is saved in the place of moduleReference.moduleId
    // The below condition is to only send the childIds and not send the activityId in case of synthetic modules
    const nodeIds = response.activities.some(a => a.activityId === moduleId)
      ? tableIds.concat(formIds)
      : tableIds.concat(module?.moduleId as string).concat(formIds);
    this.auditHistoryService
      .loadSelectedActivityOrModuleAuditHistory(this.experiment.id, nodeIds)
      .subscribe({
        next: (results) => {
          this.isLoading = false;
          this.dynamicDialogRef = this.auditHistoryService.showAuditDialog(
            results.dataRecords.filter((item) => item.eventContext.eventType !== ExperimentEventType.ExperimentNodeOrderChanged),
            module?.moduleName.concat('\xa0') + $localize`:@@Module:Module`,
            NodeType.Module
          );
        }
      });
  }

  onContextMenu(module: Module, cm: ContextMenu) {
    this.contextCurrentModuleId = module.moduleId;
    this.buildContextMenuItems();
  }

  onDoubleClick(module: Module) {
    if(!this.doesUserHaveOnlyReviewerRights) {
      this.contextCurrentModuleId = module.moduleId;
      this.retitleEnabled[module.moduleId] = true
    }
  }

  getCompletionStatus(data: NodeCompletionStatus) {
    if (this.experimentCompletionStatus.find((obj) => obj.id === data.id)) {
      this.experimentCompletionStatus.forEach((item) => {
        if (item.id === data.id) {
          item.isComplete = data.isComplete;
        }
      });
    } else {
      this.experimentCompletionStatus.push({
        title: data.title,
        id: data.id,
        isComplete: data.isComplete
      });
    }
  }

  /** Checks modules for completion and refreshes icon for Modules */
  canShowCompletionIcon() {
    for (const i of this.modules) {
      this.checkForModuleCompletion(i);
    }
    this.resetMoreModules();
  }

  resetMoreModules() {
    this.moreModuleDropdownOptions = [];
    this.buildMoreModuleOptionsForDropdown();
  }

  checkForModuleCompletion(module: Module) {
    module.isModuleComplete = true;
    for (const moduleItem of module.items) {
      const currentId =
        moduleItem.itemType === NodeType.Table
          ? (moduleItem as Table).tableId
          : (moduleItem as Form).formId;
      const item = this.experimentCompletionStatus.find((x) => x.id === currentId);
      if (item?.isComplete === false) {
        module.isModuleComplete = false;
        break;
      }
    }
  }

 /**
   * Checks if this activity (in this UI component) is complete, including its children (except for Preparations).
   *
   * Note: does not consider Preparations. That's (inappropriately?) handled by checkExperimentCompletion.
   */
 checkActivityCompletion() {
  let isActivityComplete = true; // hypothesis
  for (const dataModule of this.activity.dataModules) {
    const currentModule = this.modules?.find((m) => m.moduleLabel === dataModule.moduleLabel);
    if (currentModule && !currentModule.isModuleComplete) {
      isActivityComplete = false;
      break;
    }
  }
  //TODO when Documents and Compendia are changed from the template implementation, add code here as needed # 3260332
  if (!this.activity.activityReferences.crossReferences.every(ReferencesService.crossReferenceIsComplete)) {
    isActivityComplete = false;
  }
  this.activity.isActivityComplete = isActivityComplete;
  this.experimentService.setActivityCompletionStatus(this.activity);
}

  private buildContextMenuItems(): void {
    this.items = this.buildContextMenu("eln-context-module-");
    this.itemsMoreModules = this.buildContextMenu("eln-context-moremodule-");
  }

  private buildContextMenu(id: string): MenuItem[] {
    var menuItems = [];
    const internalCommentsMenu =
    {
      label: $localize`:@@InternalComments:Internal Comments`,
      icon: 'pi pi-comments',
      id: `${id}${this.experimentService.currentModuleName.replace(new RegExp(' ', 'g'), '')}`,
      command: (_event$: any) => {
        this.loadInternalCommentsForModuleLevel(this.contextCurrentModuleId as string);
      }
    };
    menuItems.push(internalCommentsMenu);
    const historyMenu =
    {
      label: $localize`:@@History:History`,
      icon: 'fas fa-history',
      id: `${id}history-${this.experimentService.currentModuleName.replace(new RegExp(' ', 'g'), '')}`,
      command: (_event$: any) => {
        this.loadAuditHistoryDialog(this.contextCurrentModuleId as string);
      }
    };
    menuItems.push(historyMenu);
    const reTitleMenu = {
      label: $localize`:@reTitle:Retitle`,
      icon: 'icon-pencil',
      disabled: this.isRetitleOptionDisabled(),
      id: `${id}retitle`,
      command: (_event$: any) => {
        this.retitleEnabled[this.contextCurrentModuleId as string] = true;
        setTimeout(() => {
          this.fixupTabView();
        }, 250);
      }
    };
    menuItems.push(reTitleMenu);
    const fillWithNaMenu =
    {
      label: $localize`:@@fillWithNa:Fill with ${NA}`,
      command: (_event$: any) => {
        if (this.contextCurrentModuleId)
          this.fillWithNAGridHelper.fillWithNATriggeredForModule.next(this.contextCurrentModuleId);
      },
      icon: 'icon-s icon-not-applicable',
      disabled: this.isNAOptionDisabled()
    };

    const forceFillWithNaMenu = {
      label: $localize`:@@forcefillWithNa:Force fill with ${NA}`,
      command: (_event$: any) => {
        this.confirmationService.confirm({
          message: $localize`:@@forceFillWithNaConfirmation:Are you sure you want to override the observable data with ${NA} value?`,
          header: $localize`:@@confirmationHeader:Confirmation`,
          acceptVisible: true,
          acceptLabel: $localize`:@@confirmationYes:Yes`,
          rejectLabel: $localize`:@@confirmationNo:No`,
          rejectVisible: true,
          closeOnEscape: true,
          dismissableMask: false,
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
          if (this.contextCurrentModuleId)
            this.fillWithNAGridHelper.forceFillWithNATriggeredForModule.next(
            this.contextCurrentModuleId
          );
        }});
      },
      icon: 'icon-s icon-not-applicable',
      disabled: this.isNAOptionDisabled()
    };

    if (this.isNAOptionVisible()) {
      menuItems.push(fillWithNaMenu);
      menuItems.push(forceFillWithNaMenu);
    }
    return menuItems;
  }

  private isRetitleOptionDisabled(): boolean {
    return this.doesUserHaveOnlyReviewerRights ||
    !ExperimentNodeRetitleService.HasPermissionToEditTitle ||
    ExperimentNodeRetitleService.NotAllowedWorkflowStates.includes(
      this.experimentService.currentExperiment?.workflowState as ExperimentWorkflowState
    );
  }

  isNAOptionVisible(): boolean {
    if (this.doesUserHaveOnlyReviewerRights || ExperimentService.isExperimentAuthorizedOrCancelled(this.experiment.workflowState) ||
      ExperimentService.isExperimentInReview(this.experiment.workflowState) || !ExperimentNodeRetitleService.HasPermissionToEditTitle) {
      return false;
    } else {
      return true;
    }
  }

  isNAOptionDisabled(): boolean {
    return ((this.fillWithNAGridHelper.currentModuleId ??  this.currentModuleId) !== this.contextCurrentModuleId);
  }
}
