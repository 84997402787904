import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BptGridCellValueChangedEvent, BptGridComponent, BptGridMode, ColumnDefinition } from 'bpt-ui-library/bpt-grid';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { InstrumentConnectionStatus, InstrumentDetailsDto, InstrumentSearchDto, InstrumentSearchResultsDto } from '../../../api/instrument-admin/models';
import { InstrumentService } from '../../../api/instrument-admin/services';
import { InstrumentService as RegisteredInstrumentService } from '../../../api/services';
import { ElnProgressSpinnerService } from '../../../eln-progress-spinner-module/eln-progress-spinner.service';
import { UserService } from '../../../services/user.service';
import { UnsubscribeAll } from '../../../shared/rx-js-helpers';
import { InstrumentConnectionConfigModalComponent } from '../instrument-connection-config-modal/instrument-connection-config-modal.component';
import { InstrumentConnectionHelper } from '../shared/instrument-connection-helper';
import { InstrumentNotificationService } from '../shared/instrument-notification-service';
import { InstrumentStatusRendererComponent } from '../shared/instrument-status-renderer';
import { InstrumentType } from '../shared/instrument-type';
import { InstrumentListGridOptions } from './intrument-list-grid-options';
import { BptGridFavoriteRowParameter } from 'bpt-ui-library/bpt-grid/model/bpt-grid-favorite-row-definition.interface';
import { InstrumentResponse, UserPreference } from '../../../api/models';
import { isEmpty } from 'lodash-es';
import { User } from '../../../model/user.interface';
import { InstrumentConnectionService } from '../instrument-connection.service';
import { DropdownChangeEvent } from 'bpt-ui-library/bpt-dropdown';
import { ConfirmationService, MessageService } from 'primeng/api';
import { LocalDate } from '@js-joda/core';

export interface InstrumentConnectionDetailsDto extends InstrumentDetailsDto {
  favorite?: boolean
}

@Component({
  selector: 'app-instrument-connection-modal',
  templateUrl: './instrument-connection-modal.component.html',
  styleUrls: ['./instrument-connection-modal.component.scss']
})
export class InstrumentConnectionModalComponent implements OnInit, OnDestroy {
  public readonly favoriteUserPreferenceKey: string = 'eln-instrument-connection-favorites';
  dropDownLabel = $localize`:@@instrumentType:Instrument Type`;
  placeHolder = $localize`:@@selectInstrumentType:Select Instrument type`;
  instrumentType?: InstrumentType;
  columnDefinitions!: ColumnDefinition[];
  instrumentConnectionDataSource?: InstrumentConnectionDetailsDto[];
  dataSource?: InstrumentDetailsDto[];
  instrumentConnectionGridId = 'instrumentConnectionGridId';
  gridMode = BptGridMode.dataEntry;
  titleOfTable = $localize`:@@instrumentsList:Instruments List`;
  allInstrumentsOffline = $localize`:@@allInstrumentsOffline:All Instruments are offline at this moment`;
  instrumentTypes!: InstrumentType[];
  disableConnect = true;
  toggledValue = false;
  disableFavorite = false;
  selectedInstrumentToConnect?: InstrumentDetailsDto;
  dynamicDialogRef?: DynamicDialogRef;
  mockPhInstruments:string[] = ["Sample phMeter 1","Sample phMeter 2", "Sample phMeter 3",  "Sample phMeter 4"];
  isPhMeterSelected = false;
  phMeter = InstrumentType.phMeter
  private readonly subscriptionList: Subscription[] = [];

  user!: User;
  favouritePreferences: UserPreference[] = [];
  @ViewChild('Grid') grid!: BptGridComponent;

  constructor(
    private readonly elnProgressSpinnerService: ElnProgressSpinnerService,
    private readonly instrumentConnectionHelper: InstrumentConnectionHelper,
    private readonly instrumentService: InstrumentService,
    private readonly userService: UserService,
    private readonly instrumentConnectionService: InstrumentConnectionService,
    private readonly dialogService: DialogService,
    private readonly instrumentNotificationService: InstrumentNotificationService,
    private readonly messageService: MessageService,
    private readonly registeredInstrumentService: RegisteredInstrumentService,
    private readonly confirmationService: ConfirmationService) {
      this.handleSubscriptions();
  }

  ngOnInit(): void {
    this.user = { ...this.userService.currentUser };
    this.instrumentTypes = Object.values(InstrumentType);
    this.columnDefinitions = InstrumentListGridOptions.prepareColumns();
  }

  ngOnDestroy(): void {
    this.dynamicDialogRef?.destroy();
    UnsubscribeAll(this.subscriptionList);
  }

  dropdownChange(event: DropdownChangeEvent) {
    if (!event.value) return;
    this.instrumentConnectionHelper.instrumentType = event.value
    localStorage.setItem('instrumentType', this.instrumentConnectionHelper.instrumentType ?? '');
    this.getInstrumentDataSource(event.value);
  }

  connectToInstrument() {
    this.verifyInstrumentData();
  }

  loadFavoritePreferences() {
    this.instrumentConnectionService.loadFavoritePreferences().subscribe({
      next: (preferences: UserPreference[]) => {
        this.favouritePreferences = preferences;
        this.syncFavoritesOnLoad(this.favouritePreferences);
        this.grid?.gridApi?.refreshClientSideRowModel()
        this.grid?.gridApi?.refreshCells({ force: true });
        this.elnProgressSpinnerService.Hide();
      }
    });
  }

  onInstrumentSelected = (event: any) => {
    if(!event.data) return;
    this.disableConnect = event.data.instrumentConnectionStatus !== InstrumentConnectionStatus.Online;
    this.selectedInstrumentToConnect = event.data;
    this.instrumentConnectionHelper.balanceName = this.selectedInstrumentToConnect?.name ?? 'Instrument Title'
    event.node.setSelected(true);
  }

  onOfflineInstrumentToggle() {
    if (!this.dataSource) return;
    this.instrumentConnectionDataSource = this.toggledValue ? this.dataSource : this.dataSource?.filter((x: InstrumentDetailsDto) => (x.instrumentConnectionStatus === InstrumentConnectionStatus.Online || x.instrumentConnectionStatus === InstrumentConnectionStatus.Busy));
    this.syncFavoritesOnLoad(this.favouritePreferences);
    this.grid?.gridApi?.setGridOption('rowData', this.instrumentConnectionDataSource);
  }

  private handleSubscriptions() {
    this.subscriptionList.push(this.instrumentConnectionHelper.connectionConfigCancelled.subscribe(() =>
      this.dynamicDialogRef?.close()
    ));
    this.subscriptionList.push(this.instrumentConnectionHelper.instrumentConnectionStarted.subscribe(() => {
      if (!this.selectedInstrumentToConnect?.equipmentId || !this.selectedInstrumentToConnect?.name) return;
      this.elnProgressSpinnerService.Show({
        message: $localize`:@@balanceConnecting:Connecting to Balance...`,
        i18nMessage: '@@balanceConnecting'
      });
      this.instrumentNotificationService.connectToInstrument(this.selectedInstrumentToConnect);
    }));
    this.subscriptionList.push(this.instrumentConnectionHelper.instrumentConnectionSuccess.subscribe((connectedInstrumentId: string) => {
      this.elnProgressSpinnerService.Hide();
      this.dynamicDialogRef?.close();
      this.messageService.add({
        key: 'notification',
        severity: 'success',
        summary: $localize`:@@instrumentConnectionSuccess:Connected to ${connectedInstrumentId} Successfully`
      });
    }));
    this.subscriptionList.push(this.instrumentConnectionHelper.instrumentConnectionFailed.subscribe(() => {
      this.elnProgressSpinnerService.Hide();
    }));
  }

  private getInstrumentDataSource(instrumentType: string) {
    const spinnerOptions = {
      message: $localize`:@@instrumentRetrieval:Retrieving instruments...`,
      i18nMessage: `@@instrumentRetrieval`
    };
    this.elnProgressSpinnerService.Show(spinnerOptions);
    switch (instrumentType) {
      case InstrumentType.balance:
        this.handleBalanceInstruments();
        this.instrumentType = instrumentType;
        break;
      case InstrumentType.phMeter:
        this.elnProgressSpinnerService.Hide();
        this.instrumentType = instrumentType;
        break;
    }
  }


  syncFavoritesOnLoad(savedPreferences: UserPreference[]) {
    savedPreferences.forEach(pref => {
      if (pref.userPreferenceValue && this.instrumentConnectionDataSource) {
        const correspondingRow = this.instrumentConnectionDataSource.find(s => s.id === pref.userPreferenceValue)
        if (correspondingRow)
          correspondingRow.favorite = true;
      }
    })
  }

  favoriteRowParameter: BptGridFavoriteRowParameter =
    {
      enableFavoriteColumn: true,
      favoriteFieldName: 'favorite',
      enableMultiSort: true
    }

  cellValueChanged(e: BptGridCellValueChangedEvent) {
    this.disableFavorite = true;
    if (e.rowId && e.field === 'favorite') {
      e.newValue ? this.markFavorite(e.rowId) : this.removeFavorite(e.rowId);
    }
  }

  markFavorite(id: string): void {
    if (this.isRowFavorite(id)) {
      return;
    } else {
      this.disableFavorite = true;
      this.instrumentConnectionService.addFavoritePreference(this.favouritePreferences, id).subscribe({
        next: (preferences: UserPreference[]) => {
          this.favouritePreferences = preferences;
          this.disableFavorite = false;
        }
      });
    }
  }

  private isRowFavorite(id: string): boolean {
    if (isEmpty(this.favouritePreferences)) {
      return false;
    } else {
      return this.favouritePreferences.some(
        (preference) => preference.userPreferenceValue === id
      );
    }
  }

  refreshInstruments() {
    if(this.instrumentType)
      this.getInstrumentDataSource(this.instrumentType);
  }

  removeFavorite(id: string): void {
    const userPreferenceIdIndex = this.favouritePreferences.findIndex(
      (preference) => preference.userPreferenceValue === id
    );
    if (userPreferenceIdIndex >= 0) {
      const preferenceId =
        this.favouritePreferences[userPreferenceIdIndex].userPreferenceId;
      if (!this.favouritePreferences) {
        return;
      }
      this.disableFavorite = true;
      this.instrumentConnectionService.deleteFavoritePreference(this.favouritePreferences, preferenceId).subscribe({
        next: (preferences: UserPreference[]) => {
          this.favouritePreferences = preferences;
          this.disableFavorite = false;
        }
      });
    }
  }

  disconnectFromInstrument() {
    if(this.selectedInstrumentToConnect && this.selectedInstrumentToConnect.equipmentId)
      this.instrumentNotificationService.disconnectFromInstrument(this.selectedInstrumentToConnect.equipmentId, InstrumentType.balance);
  }

  private handleBalanceInstruments() {
    const balanceInstrumentsSearch = this.getSearchCriteria();
    this.instrumentService.instrumentsSearchPost$Json({ body: balanceInstrumentsSearch }).subscribe({
      next: (res: InstrumentSearchResultsDto) => {
        if (!res.instrumentDetails) return;
        this.dataSource = res.instrumentDetails;
        this.instrumentConnectionDataSource = this.instrumentConnectionHelper.getSortedInstrumentDetails(this.dataSource);
        this.instrumentConnectionDataSource.forEach(s => s.favorite = false);
        this.instrumentConnectionDataSource = this.toggledValue ? this.dataSource : this.dataSource?.filter((x: InstrumentDetailsDto) => (x.instrumentConnectionStatus === InstrumentConnectionStatus.Online || x.instrumentConnectionStatus === InstrumentConnectionStatus.Busy));
        this.loadFavoritePreferences();
        this.columnDefinitions.forEach(x => x.onCellClicked = this.onInstrumentSelected);
        this.setInstrumentStatus();
        this.grid?.gridApi?.setGridOption('rowData', this.instrumentConnectionDataSource);
        this.grid?.gridApi?.refreshCells({ force: true });
      }
    });
  }

  private setInstrumentStatus() {
    const colDef = this.columnDefinitions.find(x => x.field === 'name');
    if (!colDef) return;
    colDef.cellRenderer = InstrumentStatusRendererComponent;
  }

  private getSearchCriteria(): InstrumentSearchDto {
    return {
      filterDictionary: {
        Labsites: [this.userService.currentUser.labSiteCode],
        Groups: ["Balances"]
      },
      sortingDictionary: {}
    };
  }

  private openConnectionConfigurationModal() {
    this.dynamicDialogRef = this.dialogService.open(
      InstrumentConnectionConfigModalComponent, {
        width: '30%',
        autoZIndex: true,
        closeOnEscape: true,
        header: $localize`:@@configuration:Configuration`,
        styleClass: 'eln-instrument-connection-configuration-dialog',
        data: this.selectedInstrumentToConnect
    });
  }

  onPhInstrumentSelected(event: DropdownChangeEvent) {
    this.isPhMeterSelected = true;
    this.instrumentConnectionHelper.phName = event.value;
  }

  connectToPhInstrument() {
    this.instrumentConnectionHelper.phMeterConnectionStarted.next();
  }

  private verifyInstrumentData() {
    this.elnProgressSpinnerService.Show({
      message: $localize`:@@verifyInstrumentData:Verifying Instrument Data...`,
      i18nMessage: '@@verifyInstrumentData'
    });
    this.registeredInstrumentService.instrumentsInstrumentCodeGet$Json({
      instrumentCode: this.selectedInstrumentToConnect?.equipmentId ?? ''
    }).subscribe({
      next: (data: InstrumentResponse) => {
        this.instrumentNotificationService.instrumentDetailsLims = data.instrumentDetails;
        this.instrumentConnectionService.setBlowerState(data.instrumentDetails);
        const currentDate = LocalDate.now();
        const nextMaintenanceDate = data.nextMaintenanceDate && LocalDate.parse(data.nextMaintenanceDate);
        const nextQualificationDate = data.nextQualificationDate && LocalDate.parse(data.nextQualificationDate);
        const isServiceDateDue = nextMaintenanceDate && currentDate.isAfter(nextMaintenanceDate);
        const isQualificationDatePassed =  nextQualificationDate && currentDate.isAfter(nextQualificationDate);
        const activityOverdueUseStatus = "ActivityOverdue";
        const isActivityOverDue = data.instrumentDetails.useStatus === activityOverdueUseStatus;
        let noVerificationMessage = $localize`:@@noVerificationMessage: The instrument could not be verified due to:`;
        const activityOverDueMessage = $localize`:@@activityOverDue: Status is set to Activity Overdue.`
        const serviceDateDueMessage = $localize`:@@serviceDateDue:  Service date is due for the Instrument.`
        const qualificationDatePassedMessage = $localize`:@@qualificationDatePassed: The equipment has already passed its qualification date.`
        const parameterName = "Weight";
        if (isActivityOverDue) {
          noVerificationMessage =`${noVerificationMessage} <li> ${activityOverDueMessage} </li>`
        }
        if (isServiceDateDue) {
          noVerificationMessage = `${noVerificationMessage} <li> ${serviceDateDueMessage} </li>`
        }
        if (isQualificationDatePassed) {
          noVerificationMessage = `${noVerificationMessage} <li> ${qualificationDatePassedMessage} </li>`
        }
        const tolerance = data.instrumentDetails.parameters?.find(parameter => parameter.name === parameterName);
        const isToleranceMissing = !( tolerance && tolerance?.lowerComparator && tolerance?.upperComparator );
        this.elnProgressSpinnerService.Hide();
        if( isActivityOverDue || isServiceDateDue || isQualificationDatePassed) {
          this.confirmationService.confirm({
            message: `${noVerificationMessage}`,
            key: 'instrumentVerificationDialog',
            header: $localize`:@@notVerified:Not Verified`,
            acceptVisible: true,
            acceptLabel: $localize`:@@confirmationOk:OK`,
            closeOnEscape: true,
            dismissableMask: true,
            rejectVisible: false,
            icon: 'pi pi-exclamation-triangle',
            accept: () => {this.checkToleranceParameters(isToleranceMissing);},
            reject: () => {this.checkToleranceParameters(isToleranceMissing);}
          });
          return
        }
        this.checkToleranceParameters(isToleranceMissing);
      },
      error: () => {
        this.openConnectionConfigurationModal();
      }
    })
  }

  private checkToleranceParameters(isToleranceMissing:boolean) {
    if ( isToleranceMissing ) {
      this.confirmationService.confirm({
        message: $localize`:@@toleranceMissingMessage: Tolerance range parameters are missing in LIMS for this balance, please notify your supervisor`,
        key: 'instrumentParametersMissingDialog',
        header:  $localize`:@@instrumentConnection:Instrument Connection`,
        acceptVisible: true,
        acceptLabel: $localize`:@@confirmationOk:OK`,
        rejectVisible: false,
        closeOnEscape: true,
        dismissableMask: false,
        icon: 'pi pi-exclamation-triangle',
        accept: () => {this.openConnectionConfigurationModal();},
        reject: () => {this.openConnectionConfigurationModal();}
      });
      return
    }
    this.openConnectionConfigurationModal();
  }
}
