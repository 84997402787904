import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LabItemsConsumableComponent } from '../consumables/lab-items-consumable.component';
import { LabItemsInstrumentComponent } from '../instruments/lab-items-instrument/lab-items-instrument.component';
import { LabItemsMaterialComponent } from '../materials/lab-items-material/lab-items-material.component';
import { LabItemsColumnComponent } from '../columns/lab-items-column/lab-items-column.component';
import { LabItemsPreparationComponent } from '../Preparation/lab-items-preparation.component';

@Component({
  selector: 'app-lab-items',
  templateUrl: './lab-items.component.html',
  styleUrls: ['./lab-items.component.scss']
})
export class LabItemsComponent implements OnInit {
  @ViewChild('labItemsMaterials') labItemsMaterials?: LabItemsMaterialComponent;
  @ViewChild('labItemsInstruments') labItemsInstruments?: LabItemsInstrumentComponent;
  @ViewChild('labItemsColumns') labItemsColumns?: LabItemsColumnComponent;
  @ViewChild('labItemsConsumables') labItemsConsumables?: LabItemsConsumableComponent;
  @ViewChild('labItemsPreparation') labItemsPreparation?: LabItemsPreparationComponent;
  @ViewChild('labItemGrids') labItemGrids?: ElementRef;

  labItemsDoNotExistBanner = $localize`:@@NoLabItemsScanned:No Lab Items Scanned.`;

  ngOnInit(): void {
    console.log("code will be added soon..")
  }
}