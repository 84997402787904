import { Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '../../base/base.component';
import { ClientStateService } from 'services/client-state.service';
import { ActivatedRoute } from '@angular/router';
import { ElnEmpowerTreeNode, OutputEmpowerService } from '../services/output-empower.service';
import { EmpowerService } from '../../api/instrument-integration/services/empower.service';
import { EmpowerLoginDialog } from '../model/empower-login-interface';
import { ElnProgressSpinnerService } from '../../eln-progress-spinner-module/eln-progress-spinner.service';
import { ColumnHeadersResponse, ColumnNames } from '../../api/instrument-integration/models';
import { ChromatographyService, UserPreferenceService } from '../../api/services';
import { ExperimentService } from '../services/experiment.service';
import { ResultSetColumn, UserPreference } from '../../api/models';
import { BptTextInputComponent } from 'bpt-ui-library/bpt-text-input';
import { BptTreeNode } from 'bpt-ui-library/bpt-tree/model/bpttreenode.interface';
import { BptTreeComponent } from 'bpt-ui-library/bpt-tree';
import { Experiment } from '../../model/experiment.interface';
import { UserService } from '../../services/user.service';
import { v4 as uuidv4 } from 'uuid';
import { ActivityOutputEventsService } from '../../api/data-entry/services';
import { ChromatographyDataRemovedEventNotification } from '../../api/data-entry/models';
import { Subscription } from 'rxjs';
import { DataRecordService } from '../services/data-record.service';
import { ActivityOutputCollaboratorService } from '../services/output-collaborator-service';
import { LockType } from '../../model/input-lock.interface';
import { Message, MessageService } from 'primeng/api';
import { ActivityOutputChromatographyResultSetSummary } from '../../api/models/activity-output-chromatography-result-set-summary';
import { BptFileUploadComponent } from 'bpt-ui-library/bpt-file-upload';

@Component({
  selector: 'app-outputs',
  templateUrl: './outputs.component.html',
  styleUrls: ['./outputs.component.scss']
})
export class OutputsComponent extends BaseComponent implements OnInit,OnDestroy {
  @ViewChild('empowerUsername') empowerUsername!: BptTextInputComponent;
  @ViewChild('empowerProject') empowerProject!: BptTextInputComponent;
  @ViewChild('empowerDatabase') empowerDatabase!: BptTextInputComponent;
  usernameValue = '';
  passwordValue = '';
  databaseValue = '';
  projectValue = '';
  isResultSetImported = false;
  isResultSetExists = false;
  isImportEvent = false;
  RefreshResultSetButtonId? = '';
  subscriptionList: Subscription[] = [];
  importedResultSetsSummary?: Array<ActivityOutputChromatographyResultSetSummary> = [];
  //importedResultSets: Array<ActivityOutputChromatographyResultSet> = [];
  loginTitle = $localize`:@@loginToEmpower:Log-in to Empower`;
  selectResultSetTitle = $localize`:@@selectAResultSet:Select a ResultSet`;
  selectColumnsToImport = $localize`:@@selectColumnstoImport:Select Columns to Import`;
  noCurrentExperimentFound = $localize`:@@throwErrorMessage:No current experiment found`;
  canShowSelectColumnsToImportDialogBox = false;
  columnOptions: Array<ColumnNames> = [];
  isFilterable = true;
  isCollaborator = false;
  empowerColumns: Array<ResultSetColumn> = [];
  experiment?: Experiment;
  favoriteColumnPreferenceKey = 'outputs-favorite-columns';
  favoriteColumnPreferences: UserPreference[] = [];
  /**
  * Represents the items that are selected in the multi select dropdown.
  */
  selectedItems: string[] = [];
  /**
  * Represents the items that are starred (set as a user favorite) in the multi select dropdown.
  */
  favoriteItems: string[] = [];
  /**
  * Represents a list of user preference columns along with their column types.
  */
  preferredOptions: any[] = [];

  @ViewChild('quarterTree') quarterTree!: BptTreeComponent;
  @ViewChild('fileUploader') fileUploader!: BptFileUploadComponent;

  public static readonly empowerRequestDatabaseValidation = $localize`:@@EmpowerRequestDatabaseValidation:Empower Request is missing required attribute: Database.`;
  public static readonly EmpowerRequestUsernameValidation = $localize`:@@EmpowerRequestUsernameValidation:Empower Request is missing required attribute: Username.`;
  public static readonly EmpowerRequestPasswordValidation = $localize`:@@EmpowerRequestPasswordValidation:Empower Request is missing required attribute: Password.`;
  public static readonly EmpowerRequestProjectValidation = $localize`:@@EmpowerRequestProjectValidation:Empower Request is missing required attribute: Project.`;

  constructor(
    readonly clientStateService: ClientStateService,
    readonly route: ActivatedRoute,
    readonly outputEmpowerService: OutputEmpowerService,
    readonly empowerService: EmpowerService,
    readonly chromatographyService: ChromatographyService,
    readonly experimentService: ExperimentService,
    readonly elnProgressSpinnerService: ElnProgressSpinnerService,
    readonly preferenceService: UserPreferenceService,
    readonly userService: UserService,
    readonly ngZone: NgZone,
    readonly activityOutputEventsService: ActivityOutputEventsService,
    private readonly dataRecordService: DataRecordService,
    private readonly activityOutputCollaboratorService: ActivityOutputCollaboratorService,
    private readonly messageService: MessageService
  ) {
    super(clientStateService, route);
  }

  ngOnInit(): void {
    this.handleSubscriptions();
    this.outputEmpowerService.clearCachedData();
    this.experiment = this.experimentService.currentExperiment;
    this.loadImportedResultSets();
  }

  ngOnDestroy(): void {
    this.subscriptionList.forEach((s) => s.unsubscribe());
  }

  private handleSubscriptions() {
    this.subscriptionList.push(
      this.outputEmpowerService.GetEmpowerActivityEventGirdFor(this.experimentService.currentActivityId).newResultSetSummaryApplied.subscribe({
        next: this.updateResultSetsImported.bind(this)
      })
    );
    this.subscriptionList.push(this.activatedRoute.params.subscribe((params) => {
      const activity = this.experimentService.GetActivityBasedOnParams(params);
      if (!activity) return;
      this.experimentService.currentActivityId = activity.activityId;
    }));
    this.subscriptionList.push(this.experimentService._isCurrentUserCollaboratorSubject$.subscribe({
      next: (isCollaborator: boolean) => {
        if (isCollaborator) {
          this.isCollaborator = isCollaborator;
        }
      }
    }));

    this.subscriptionList.push(
      this.dataRecordService.chromatographyDataRemovedEventNotificationReceiver.subscribe((val) => {
        this.updateResultSetsRemoved(val);
        this.showRemovedNotification(val, val.eventContext.puid);
      })
    );
  }

  makeUserAsCollaborator(currentUserBecameCollaborator: boolean) {
    if (!this.isCollaborator && currentUserBecameCollaborator) {
      this.experimentService._isCurrentUserCollaboratorSubject$.next(currentUserBecameCollaborator);
    }
  }

  updateResultSetsImported(newResultSetSummary: ActivityOutputChromatographyResultSetSummary) {
    if (!this.experimentService.currentExperiment) {
      throw new Error(this.noCurrentExperimentFound);
    }
    this.importedResultSetsSummary = [...(this.importedResultSetsSummary || []), newResultSetSummary];

  }

  updateResultSetsRemoved(response: ChromatographyDataRemovedEventNotification) {
    if (!this.experimentService.currentExperiment) {
      throw new Error(this.noCurrentExperimentFound);
    }
    const index = (this.importedResultSetsSummary ?? []).findIndex(el => el.chromatographyDataId === response.chromatographyDataId);
    if (index > -1) {
      this.importedResultSetsSummary = [
        ...(this.importedResultSetsSummary?.slice(0, index) ?? []),
        ...(this.importedResultSetsSummary?.slice(index + 1) ?? [])
      ];
    }
    this.removeActivityOutputChromatographyData(response.chromatographyDataId);
  }

  removeActivityOutputChromatographyData(chromatographyDataId: string)
  {
    this.outputEmpowerService.removeResultSet(chromatographyDataId);
  }

  private showRemovedNotification(response: ChromatographyDataRemovedEventNotification, user?: string) {
    const id = this.experimentService.currentExperiment?.activityOutputChromatographyResultSetsSummary?.find(
      (data) => data.chromatographyDataId === response.chromatographyDataId
      )?.resultSetId;

    const messageObj: Message = {
      key: 'notification',
      severity: 'success',
      summary: $localize`:@@empowerRemoveComplete:Empower Remove Complete`,
      detail: $localize`:@@empowerRemoveCompleteMessageDetails:Empower Result Set ${id} has been removed by ${user}.`,
      sticky: false
    };
    if(user)
      this.messageService.add(messageObj);
  }

  loadImportedResultSets() {
    this.importedResultSetsSummary = this.experimentService.currentExperiment?.activityOutputChromatographyResultSetsSummary?.filter(
      (resultSet) => resultSet.activityId === this.experimentService.currentActivityId
    ) ?? [];
  }


  removeResultSet(event:ActivityOutputChromatographyResultSetSummary) {
    this.importedResultSetsSummary = [...(this.importedResultSetsSummary?.filter(el => el.chromatographyDataId !== event.chromatographyDataId) ?? [])];
    event.isDeleted = true;
    this.outputEmpowerService.removeResultSet(event.chromatographyDataId);
  }

  handleRefreshResultSetButtonId(event:string)
  {
    this.RefreshResultSetButtonId=event;
  }

  get outputEmpowerData(): OutputEmpowerService {
    return this.outputEmpowerService;
  }

  loadFavoriteColumns() {
    this.preferenceService.userPreferencesUserPreferenceKeyGet$Json({
      userPreferenceKey: this.favoriteColumnPreferenceKey
    }).subscribe({
      next: (_response) => {
        this.favoriteColumnPreferences = _response.userPreferences;
        this.preferredOptions = _response.userPreferences.map(item => JSON.parse(item.userPreferenceValue));
        this.favoriteItems = this.preferredOptions.map(item => item.columnName);
        this.updateColumnOptions();
        this.closeLoginDialog();
        this.elnProgressSpinnerService.Hide();
        this.canShowSelectColumnsToImportDialogBox = true;
      }
    });
  }

  closeLoginDialog() {
    this.unlockResultSetRefreshButton();
    this.outputEmpowerService.resetLoginData();
    this.usernameValue = '';
    this.passwordValue = '';
    this.databaseValue = '';
    this.projectValue = '';
    this.ngZone.run(() => {
      this.outputEmpowerData.canShowEmpowerLogin = false;
    });
  }

  cancelLoginDialog() {
    this.unlockResultSetRefreshButton();
    this.isResultSetImported = false;
    this.isImportEvent = false;
    this.selectedItems = [];
    this.empowerColumns = [];
    this.closeLoginDialog();
    this.outputEmpowerService.clearCachedData();
  }
  unlockResultSetRefreshButton()
  {
    if (!this.experimentService.currentExperiment) {
      throw new Error(this.noCurrentExperimentFound);
    }
    this.activityOutputCollaboratorService.sendInputStatus(LockType.unlock,this.experimentService.currentExperiment.id
      ,[this.activityOutputCollaboratorService.refreshIdentifier],this.RefreshResultSetButtonId!);
  }
  selectResultSet(event: any) {
    this.isResultSetExists = false;
    if (event.node.type === 'resultSet') {
      this.outputEmpowerData.selectedResultSet = {
        project: event.node.parent.fullPath,
        resultSetId: event.node.id,
        resultSetName: event.node.label
      };
      this.outputEmpowerData.canImportResultSets = true;
    } else {
      this.outputEmpowerData.canImportResultSets = false;
    }
  }

  resetTreeData(): void {
    if (this.quarterTree) {
      this.quarterTree.treeData = [] as ElnEmpowerTreeNode[];
      this.quarterTree.selectedNode = [] as BptTreeNode[];
      this.outputEmpowerData.resultSetTreeData = [];
    }
  }

  importSelectedResultSet() {
    const project = this.outputEmpowerData.selectedResultSet.project;
    const database = this.outputEmpowerService.getCachedDatabase().label;
    const resultSetId = this.outputEmpowerData.selectedResultSet.resultSetId;
    if (
      this.importedResultSetsSummary?.find(
        (resultSet) =>
          resultSet.projectFilePath === project &&
          resultSet.resultSetId === resultSetId &&
          resultSet.databaseName === database &&
          resultSet.isDeleted !== true
      )
    ) {
      this.isResultSetExists = true;
      return;
    }
    this.isResultSetExists = false;
    this.outputEmpowerData.canImportResultSets = false;
    this.resetTreeData();
    this.isResultSetImported = true;
    this.ngZone.run(() => {
      this.outputEmpowerData.canShowEmpowerStructure = false;
      this.outputEmpowerData.canShowEmpowerLogin = true;
    });
    this.outputEmpowerData.empowerData.username = this.outputEmpowerService.getCachedUsername();
    this.outputEmpowerData.empowerData.database = this.outputEmpowerService.getCachedDatabase();
    this.outputEmpowerData.empowerData.project = this.outputEmpowerData.selectedResultSet.project;
  }

  closeQuarterStructureDialog() {
    this.resetTreeData();
    this.ngZone.run(() => {
      this.outputEmpowerData.canShowEmpowerStructure = false;
    });
    this.outputEmpowerData.canImportResultSets = false;
  }

  cancelQuarterStructureDialog() {
    this.isResultSetImported = false;
    this.outputEmpowerService.clearCachedData();
    this.closeQuarterStructureDialog();
  }

  fetchResultSetsFromEmpower(): void {
    if (this.outputEmpowerData.empowerData.database?.label) {
      this.outputEmpowerData.empowerResponse = '';
      const loginSpinnerOptions = {
        message: $localize`:@@loggingInToEmpower:Logging in to Empower`,
        i18nMessage: `@@loggingInToEmpower`
      };
      this.elnProgressSpinnerService.Show(loginSpinnerOptions);
      this.isResultSetImported = false;
      this.outputEmpowerService.cacheLoginData();
      this.empowerService
        .apiEmpowerQuarterAndResultsetsPost$Json({
          body: {
            userName: this.outputEmpowerData.empowerData.username,
            password: window.btoa(this.outputEmpowerData.empowerData.password),
            database: this.outputEmpowerData.empowerData.database?.label,
            projectName: this.outputEmpowerData.empowerData.project
          }
        })
        .subscribe({
          error: (errorResponse) => {
            if (errorResponse.error.notifications) {
              this.outputEmpowerData.empowerResponse =
                errorResponse.error.notifications.notifications[0].message;
            }
            this.elnProgressSpinnerService.Hide();
          },
          next: (response) => {
            this.outputEmpowerService.cacheLoginData();
            this.elnProgressSpinnerService.Hide();
            this.makeUserAsCollaborator(true);
            if (response.quartersResultSets) {
              this.outputEmpowerService.digestQuarterResultSetsResponse(
                response.quartersResultSets || []
              );
            }
          }
        });
    }
  }

  fetchColumnsFromEmpower() {
    const spinnerOptions = {
      message: $localize`:@@fetchingColumns:Fetching Columns`,
      i18nMessage: `@@fetchingColumns`
    };
    this.elnProgressSpinnerService.Show(spinnerOptions);
    this.outputEmpowerService.cacheLoginData();
    this.empowerService
      .apiEmpowerColumnNamesPost$Json({
        body: {
          userName: this.outputEmpowerData.empowerData.username,
          password: window.btoa(this.outputEmpowerData.empowerData.password),
          projectName: this.outputEmpowerData.empowerData.project ?? '',
          database: this.outputEmpowerData.empowerData.database?.label ?? '',
          resultSetId: this.outputEmpowerData.selectedResultSet.resultSetId
        }
      })
      .subscribe({
        error: (errorResponse) => {
          if (errorResponse.error.notifications) {
            this.outputEmpowerData.empowerResponse =
              errorResponse.error.notifications?.notifications[0].message;
          }
        },
        next: (response) => {
          this.updateColumnResponse(response);
          this.makeUserAsCollaborator(true);
        }
      });
  }

  updateColumnResponse(columns: ColumnHeadersResponse) {
    this.columnOptions = columns.columnNamesCollection;
    if (this.columnOptions.length > 0) {
      this.loadFavoriteColumns();
    }
  }

  callToEmpower(): void {
    this.outputEmpowerService.empowerResponse = ""
    if (this.outputEmpowerData.isRefreshEvent) {
      this.outputEmpowerService.isRefreshAuthenticated.next(true);
      return;
    }
    if (this.isImportEvent) {
      this.fetchResultSetDataFromEmpower();
      return;
    }
    if (this.isResultSetImported) {
      this.fetchColumnsFromEmpower();
      return;
    }
    this.isResultSetExists = false;
    this.fetchResultSetsFromEmpower();
  }

  fetchResultSetDataFromEmpower() {
    const peakColumns: string[] = ['Name'];
    const resultColumns: string[] = [];
    this.selectedItems.forEach((item) => {
      const columnType = this.columnOptions.find((column) => column.columnName === item)?.columnType;
      columnType === 'Result' ? resultColumns.push(item) : peakColumns.push(item);
    });
    if (this.outputEmpowerData.empowerData.database?.label) {
      this.outputEmpowerService.queueResultSetImportRequest({
          activityId: this.experimentService.currentActivityId,
          database : this.outputEmpowerData.empowerData.database?.label,
          experimentId: this.experiment?.id ?? '',
          peakColumns: peakColumns,
          projectName: this.outputEmpowerData.empowerData.project,
          resultColumns: resultColumns,
          resultSetId: this.outputEmpowerData.selectedResultSet.resultSetId,
          sampleNames: this.getScannedItemKeysByActivity(),
        }, this.onSuccessOfImportRequestCreation.bind(this)
      );
    }
  }

  onSuccessOfImportRequestCreation(): void{
    this.cancelLoginDialog();
    this.elnProgressSpinnerService.Hide();
    this.makeUserAsCollaborator(true);
  }

  showImportNotificationForEmptyResult() {
    const messageObj: Message = {
      key: 'notification',
      severity: 'success',
      summary: $localize`:@@empowerImportComplete: Empower Import Complete`,
      detail: $localize`:@@empowerImportCompleteMessageDetails:Empower Result Set "${this.outputEmpowerData.selectedResultSet.resultSetId}"
                          did not have any Injections matching a scanned input.`,
      sticky: false
    };
    this.messageService.add(messageObj);
  }

  getScannedItemKeysByActivity(): string[] {
      return this.experimentService.getScannedInputKeys(this.experimentService.currentActivityId);
  }

  updateLoginStatus() {
    const empowerLoginData = this.outputEmpowerData.empowerData;
    const userDetails = empowerLoginData.username?.trim() && empowerLoginData.password?.trim();
    const empowerDetails = empowerLoginData.project?.trim() && empowerLoginData.database?.label;
    this.outputEmpowerData.disableEmpowerLogin = !(userDetails && empowerDetails);
    var password = empowerLoginData.password?.trim().length
      ? empowerLoginData.password?.trim()
      : this.passwordValue;
    this.passwordValue = password;
    if (this.passwordValue.length) {
      this.outputEmpowerData.empowerData.passwordEmpty = empowerLoginData.password.length === 0;
    }
    if (!this.isResultSetImported) {
      this.checkRequiredOnCachedFields(empowerLoginData);
    }
  }

  checkRequiredOnCachedFields(empowerLoginData: EmpowerLoginDialog) {
    this.usernameValue = empowerLoginData.username?.trim().length
      ? empowerLoginData.username?.trim()
      : this.usernameValue;
    this.databaseValue = empowerLoginData.database?.label.length
      ? empowerLoginData.database?.label
      : this.databaseValue;
    this.projectValue = empowerLoginData.project?.trim().length
      ? empowerLoginData.project?.trim()
      : this.projectValue;
    if (this.usernameValue.length) {
      this.outputEmpowerData.empowerData.usernameEmpty = empowerLoginData.username.length === 0;
    }
    if (this.databaseValue.length) {
      this.outputEmpowerData.empowerData.databaseEmpty =
        !empowerLoginData.database || empowerLoginData.database?.label.length === 0;
    }
    if (this.projectValue.length) {
      this.outputEmpowerData.empowerData.projectEmpty = empowerLoginData.project.length === 0;
    }
  }

  closeImportColumns() {
    this.canShowSelectColumnsToImportDialogBox = false;
    if(!this.isImportEvent) {
      this.cancelLoginDialog();
    }
  }

  cancelImportColumns() {
    this.cancelQuarterStructureDialog();
    this.outputEmpowerService.clearCachedData();
    this.isResultSetImported = false;
    this.isImportEvent = false;
    this.empowerColumns = [];
    this.closeImportColumns();
  }

  sortSelectedItems() {
    this.selectedItems.sort();
    this.updateColumnOptions();
  }

  updateColumnOptions() {
    //Arrays here are converted to Sets for efficiency. The 'has' operation on a Set takes constant time (O(1))
    const favoriteItemsSet = new Set(this.favoriteItems);
    const selectedItemsSet = new Set(this.selectedItems);
    const bySelected = (a: { columnName: string }, b: { columnName: string }) => {
        return +selectedItemsSet.has(b.columnName) - +selectedItemsSet.has(a.columnName);
    };
    const byFavorite = (a: { columnName: string }, b: { columnName: string }) => {
        return +favoriteItemsSet.has(b.columnName) - +favoriteItemsSet.has(a.columnName);
    };
    const byName = (a: ColumnNames, b: ColumnNames) => a.columnName.localeCompare(b.columnName);
    this.columnOptions.sort((a, b) => bySelected(a, b) || byFavorite(a, b) || byName(a, b));
  }

  setColumnFavorite(option: any) {
    this.favoriteItems.push(option.columnName);
    this.updateColumnOptions();
    this.preferenceService.userPreferencesSaveUserPreferencePost$Json({
      body: this.getDefaultUserPreferenceOfFavoriteColumns(option)
    }).subscribe({
        next: (_response) => {
          if (_response.userPreference) {
            this.favoriteColumnPreferences.push(_response.userPreference);
          } else {
            console.error("User preference is undefined or null");
          }
        }
      });
  }

  removeColumnFavorite(option: any) {
    this.favoriteItems = this.favoriteItems.filter(item => item !== option.columnName);
    this.updateColumnOptions();
    const userPreferenceIdIndex = this.favoriteColumnPreferences.findIndex(
      (preference) => preference.userPreferenceValue === JSON.stringify(option)
    );
    if (userPreferenceIdIndex >= 0) {
      const preferenceId = this.favoriteColumnPreferences[userPreferenceIdIndex].userPreferenceId;
      return this.deletePreference(preferenceId);
    }
    return false;
  }

  deletePreference(preferenceId: string) {
    this.preferenceService.userPreferencesUserPreferenceIdDelete$Json({
      userPreferenceId: preferenceId
    }).subscribe({
      next: (_response) => {
        this.favoriteColumnPreferences = this.favoriteColumnPreferences.filter(
          (preference) => preference.userPreferenceId !== preferenceId
        );
      }
    })
  }

  getDefaultUserPreferenceOfFavoriteColumns(id: any): UserPreference {
    return {
      userPuid: this.userService.currentUser.puid,
      userPreferenceId: uuidv4(),
      userPreferenceKey: this.favoriteColumnPreferenceKey,
      userPreferenceName: 'Favorite Dropdown',
      userPreferenceValue: JSON.stringify(id),
      isDefault: false
    };
  }

  importResultSetData() {
    this.isImportEvent = true;
    this.canShowSelectColumnsToImportDialogBox = false;
    this.outputEmpowerData.canShowEmpowerLogin = true;
    this.outputEmpowerData.empowerData.password = '';
    this.passwordValue = '';
    this.outputEmpowerData.empowerData.username = this.outputEmpowerService.getCachedUsername();
    this.outputEmpowerData.empowerData.database = this.outputEmpowerService.getCachedDatabase();
    this.outputEmpowerData.empowerData.project = this.outputEmpowerData.selectedResultSet.project;
  }

}
