import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize} from 'rxjs/operators';
import { AppService } from 'services/app-service';
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class HttpSlowInterceptor implements HttpInterceptor {
  private totalRequests = 0;

  constructor(private appService: AppService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.totalRequests++;
    setTimeout(() =>{
      if (this.totalRequests !== 0) {
      this.appService.displayWaitDialog  = true;
      }
    },environment.displayWaitDialogThreshold);
    
    return next.handle(req).pipe(
      finalize(() => {
        this.totalRequests--;
        if (this.totalRequests === 0) {
            this.appService.displayWaitDialog  = false;
        }
      })
    );
  }
}
