import { AfterViewInit, Component, ElementRef, HostListener, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Subject, finalize, forkJoin, of } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { isEqual, uniq } from 'lodash-es';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { environment } from '../../../../environments/environment';
import { BaseComponent } from '../../../base/base.component';
import { UnsubscribeAll } from '../../../shared/rx-js-helpers';
import {
  BptGridCellValueChangedEvent,
  BptGridComponent,
  BptGridRowActionClickEvent,
  BptGridRowActionConfiguration,
  BptRowActionElement,
  ColumnDefinition,
  ColumnType,
  GridContextMenuItem,
  ISeverityIndicatorConfig
} from 'bpt-ui-library/bpt-grid';
import {
  ColumnType as ApiColumnType,
  CrossReference,
  CrossReferenceType,
  ExperimentWorkflowState,
  ModifiableDataValue,
  NumberValue,
  ValueState,
  ValueType
} from '../../../api/models';
import { BookshelfService } from '../../../api/search/services';
import { ConditionType, DataType, ExperimentRecord, ExperimentRecordSearchResult, SearchCriteria, StringMatchType } from '../../../api/search/models';
import {
  ChangeCrossReferenceCommand,
  ClientFacingNoteChangedEventNotification,
  ClientFacingNoteContextType,
  ClientFacingNoteCreatedEventNotification,
  CrossReferenceAddedEventNotification,
  CrossReferenceChangedEventNotification,
  CrossReferenceModifiableProperty,
  CrossReferenceRemovedEventNotification,
  CrossReferenceRestoredEventNotification,
  StatementAppliedEventNotification,
  StatementContentDetails,
} from '../../../api/data-entry/models';
import { ExperimentService } from '../../services/experiment.service';
import { AuditHistoryDataRecordResponse, ExperimentEventType } from '../../../api/audit/models';
import { AuditHistoryService } from '../../audit-history/audit-history.service';
import { Activity, Experiment, TableValueRow } from 'model/experiment.interface';
import { ClientStateService } from 'services/client-state.service';
import { CrossReferenceClientFacingNoteContext, ShowClientFacingNotesEventData } from '../../comments/client-facing-note/client-facing-note-event.model';
import { ELNAppConstants } from '../../../shared/eln-app-constants';
import { ClientFacingNoteModel } from '../../comments/client-facing-note/client-facing-note.model';
import { ExperimentWarningService } from '../../services/experiment-warning.service';
import { DataValueService, EmittedValue, FieldOrColumnType } from '../../services/data-value.service';
import { DataRecordService } from '../../services/data-record.service';
import { ActivityReferenceEventsService } from '../../../api/data-entry/services';
import { ClientValidationDetails } from '../../../model/client-validation-details';
import { MenuItem } from 'primeng/api';
import { AccessibilityTypes } from '../../../app.states';
import { EditableCallbackParams, ICellRendererParams } from 'ag-grid-community';
import { DataValidationsService } from '../../services/data-validations.service';
import { BptControlSeverityIndicator } from 'bpt-ui-library/shared';
import { ReferencesService } from '../references.service';
import { ELNFeatureFlags } from '../../../shared/eln-feature-flags';
import { FeatureService } from '../../../services/feature.service';
import { ReferencesRemovedCrossReferenceComponent } from './references-removed-cross-reference/references-removed-cross-reference.component';
import { BptGridCellEditEvent } from 'bpt-ui-library/bpt-grid/model/bpt-grid-cell-edit-event.interface';
import { CellLock, LockType } from '../../../model/input-lock.interface';
import { ExperimentNotificationService } from '../../../services/experiment-notification.service';
import { ExperimentCollaboratorsService } from '../../../services/experiment-collaborators.service';
import { ActivityReferencesPseudoModuleId, ActivityReferencesPseudoModuleTitle } from '../references.component';
import { CommentContextType, CommentResponse, CommentsResponse, InternalCommentStatus } from '../../../api/internal-comment/models';
import { CommentDetails } from '../../comments/comment.model';
import { CommentService } from '../../comments/comment.service';
import { CompletionTrackingService } from '../../../services/completion-tracking.services';
import { TableDataForCompletionTracking } from '../../model/cell-data-for-completion-tracking.interface';
import { UserService } from '../../../services/user.service';
import { CrossReferences } from '../../comments/internal-comments-constants';

/** Identifies this table below the parent activity references, such as for cell locks */
export const CrossReferencesPseudoTableId = 'crossReferences';

@Component({
  selector: 'app-cross-references',
  templateUrl: './cross-references.component.html',
  styleUrls: ['./cross-references.component.scss']
})
export class CrossReferencesComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() experiment!: Experiment;
  /** The relevant activity inside of experiment, (so an alias) */
  @Input() activity!: Activity;
  @ViewChild('Grid') grid!: BptGridComponent;

  showCrossReferenceSlider = false;
  /** gridId is not persisted; just use any value for this session; not sharable across clients and page loads. */
  readonly gridId = uuid();
  readonly title = $localize`:@@crossReferences:Cross References`
  readonly itemTitle = this.title;
  readonly parentTitle = $localize`:@@references:References`;

  isHistoryLoading = false;
  dynamicDialogRef?: DynamicDialogRef;
  lockTimeOut = 0;
  isLoading = true;
  gridRows: Subject<CrossReferenceRow> = new Subject();
  columns = CrossReferencesComponent.columns;
  userIsReviewer = false;
  /** Percentage of user-fillable fields that are filled. Only sendCompletionStatus should update this value. */
  completionPercent = 0;
  canEditExperimentInReviewStateFlag = false;
  allowAddRow = false;
  validation!: ClientValidationDetails;
  readonly backgroundColor = 'background-color';
  internalCommentData?: CommentDetails;
  tableContextMenuItems: MenuItem[] = this.buildContextMenuItems();

  gridActions: BptGridRowActionConfiguration = {
    actions: new BehaviorSubject<BptRowActionElement[]>([])
  };

  get activeRows(): CrossReferenceRow[] {
    if (!this?.crossReferences || !this.activity?.activityId || !this.referencesService?.crossReferencesByActivity?.[this.activity.activityId]) return [];
    const activeXRefs = this.crossReferences.filter(c => !c.isRemoved).map(c => c.id);
    return this.referencesService.crossReferencesByActivity[this.activity.activityId].filter(r => activeXRefs.includes(r.id));
  }

  get crossReferences(): CrossReference[] {
    return this.activity.activityReferences.crossReferences;
  }

  get containsRemovedRows(): boolean {
    return this.crossReferences.some(c => c.isRemoved);
  }

  static get columns(): ColumnDefinition[] {
    return [
      {
        columnType: ColumnType.rowId,
        field: 'id',
        label: $localize`:@@promptRowId:Row ID`,
        hidden: true,
        editable: false,
      },
      {
        columnType: ColumnType.index,
        field: 'rowIndex',
        label: $localize`:@@rowIndex:Row Index`,
        hidden: true,
        editable: false,
      },
      {
        columnType: ColumnType.string,
        field: 'type',
        label: $localize`:@@type:Type`,
        hidden: true,
        editable: false,
      },
      {
        columnType: ColumnType.string,
        field: 'linkId',
        label: $localize`:@@linkId:Link ID`,
        hidden: true,
        editable: false,
      },
      {
        columnType: ColumnType.link,
        field: 'reference',
        label: $localize`:@@reference:Reference`,
        editable: false,
        width: 200,
        minWidth: 200,
        cellClass: 'cell-Link',
        linkData: (_: MouseEvent, data: any) => {
          if (data.route) CrossReferencesComponent.referenceClicked(data.route);
        },
      },
      {
        columnType: ColumnType.string,
        field: 'title',
        label: $localize`:@@title:Title`,
        editable: false,
        width: 330, // Gives the approximation width of 100 characters to display
        minWidth: 330,
      },
      {
        columnType: ColumnType.string,
        field: 'purpose',
        label: $localize`:@@purpose:Purpose`,
        editable: true, // replaced by function later
      },
      {
        columnType: ColumnType.string,
        field: 'state',
        label: $localize`:@@stateColumn:State`,
        editable: false,
        width: 100,
        minWidth: 100,
      },
      {
        columnType: ColumnType.string,
        field: 'route',
        label: $localize`:@@route:Route`,
        editable: false,
        hidden: true,
        alwaysHidden: true
      }
    ]
  };

  constructor(
    private readonly activityReferenceEventsService: ActivityReferenceEventsService,
    private readonly commentService: CommentService,
    private readonly completionTrackingService: CompletionTrackingService,
    private readonly dataRecordService: DataRecordService,
    private readonly dataValueService: DataValueService,
    private readonly dialogService: DialogService,
    private readonly elementRef: ElementRef,
    private readonly experimentCollaboratorsService: ExperimentCollaboratorsService,
    private readonly experimentNotificationService: ExperimentNotificationService,
    private readonly experimentService: ExperimentService,
    private readonly experimentWarningService: ExperimentWarningService,
    private readonly featureService: FeatureService,
    private readonly historyService: AuditHistoryService,
    private readonly renderer: Renderer2,
    private readonly searchService: BookshelfService,
    private readonly validationHelper: DataValidationsService,
    public readonly activatedRoute: ActivatedRoute,
    public readonly referencesService: ReferencesService,
    public readonly userService: UserService,
    readonly clientStateService: ClientStateService,
  ) {
    super(clientStateService, activatedRoute);
    this.validation = new ClientValidationDetails();
    searchService.rootUrl = environment.searchServiceUrl;
    this.activeSubscriptions.splice(0, 0,
      this.experimentService.clientFacingNoteEvents.subscribe(note => this.onClientFacingNoteEvent(note)),
      this.dataRecordService.experimentWorkFlowDataRecordReceiver.subscribe(data => {
        this.setAddRowBasedOnWorkflowState(data.state);
        this.buildContextMenuItems();
        this.addGridActions();
      }),
      this.experimentService.experimentWorkFlowState.subscribe(experimentWorkflowState => {
        this.setAddRowBasedOnWorkflowState(experimentWorkflowState);
        this.buildContextMenuItems();
        this.addGridActions();
      }),
      this.experimentNotificationService.inputLockReceiver.subscribe(lock =>
        this.applyCellLock(lock)
      ),
      this.dataRecordService.crossReferenceAddedEventReceiver.subscribe({
        next: event => {
          if (event.activityId !== this.activity.activityId) return;

          this.applyCrossReferences(this.activity.activityReferences.crossReferences);
        }
      }),
      this.dataRecordService.crossReferenceChangedEventReceiver.subscribe({
        next: event => {
          if (event.activityId !== this.activity.activityId) return;

          this.refreshCell(event.crossReferenceId, event.property);
          this.sendCompletionStatus();
        },
      }),
      this.dataRecordService.crossReferenceRemovedEventReceiver.subscribe({
        next: event => {
          if (event.activityId !== this.activity.activityId) return;

          this.grid.gridApi.refreshCells({ force: true });
          this.sendCompletionStatus();
        },
      }),
      this.dataRecordService.crossReferenceRestoredEventReceiver.subscribe({
        next: event => {
          if (event.activityId !== this.activity.activityId) return;

          this.grid.gridApi.refreshCells({ force: true });
          this.sendCompletionStatus();
        },
      }),
      this.referencesService.crossReferenceRemoved.subscribe(() => this.sendCompletionStatus()),
      this.referencesService.crossReferenceRestored.subscribe(() => this.sendCompletionStatus()),
    );
  }

  public severityIndicatorConfig = (): ISeverityIndicatorConfig => ({
    getIndicator: this.getSeverityIndicator // Called by the bpt-grid cell renderer
  });

  loadCellLocks() {
    const cellLocks = this.experimentNotificationService.inputLocks
      .filter((item): item is CellLock => item.lockType === LockType.lock && (item as CellLock).tableId === CrossReferencesPseudoTableId);
    this.applyCellLock(cellLocks);
  }

  private applyCellLock(lockList: CellLock[]) {
    lockList.forEach((lock) => {
      if (CrossReferencesPseudoTableId === lock.tableId) {
        this.grid.gridOptions.context ??= {};
        this.grid.gridOptions.context.inputLocks ??= {};
        this.grid.gridOptions.context.inputLocks[lock.rowId + lock.columnName] = lock.lockType === LockType.lock;

        const lockOwner = this.experimentCollaboratorsService.getExperimentCollaborator(lock.experimentCollaborator.connectionId);
        lock.experimentCollaborator = lockOwner ?? lock.experimentCollaborator;
        const cell = document.querySelector(`ag-grid-angular [row-id="${lock.rowId}"] [col-id="${lock.columnName}"]`);
        if (!cell) {
          console.error('cell not found to apply cell lock to', lock);
          return;
        }
        if (lock.lockType === LockType.lock) {
          const name = lockOwner?.fullName ?? lock.experimentCollaborator.firstName;
          const borderColor = lockOwner?.backgroundColor ?? 'blue';
          this.renderer.setAttribute(cell, 'title', name);
          this.renderer.setStyle(cell, this.backgroundColor, '#F9F9F9');
          this.renderer.setStyle(cell, 'border', `1px solid ${borderColor}`);
        } else {
          this.renderer.removeStyle(cell, 'border');
          this.renderer.removeStyle(cell, this.backgroundColor);
          this.renderer.removeAttribute(cell, 'title');
        }
      }
    });
  }

  private readonly getSeverityIndicator = (params: ICellRendererParams): BptControlSeverityIndicator => {
    const rows = this.activity.activityReferences.crossReferences.map(r =>
      // `as unknown` !!! See TableValueRow docs
      ({ id: r.id, purpose: r.purpose } as unknown as TableValueRow)
    );
    return this.validationHelper.getSeverityIndicatorDefinition(rows, params);
  }

  setValidationStyles = (): void => {
    this.columns.forEach((columnDefinition: ColumnDefinition) => {
      if (columnDefinition.editable) {
        columnDefinition.severityIndicatorConfig = this.severityIndicatorConfig;
      }
      this.grid.updateColumnDefinitions(columnDefinition);
    });
  };

  ngOnInit(): void {
    this.updateFeatureFlags();
    this.readOnly = this.clientStateService.getClientStateVisibility(this.clientState) !== AccessibilityTypes.ReadWrite;
    this.setAddRowBasedOnWorkflowState(this.experiment.workflowState);
    this.setColumnProperties();
    this.sendCompletionStatus();
    this.watchCrossReferenceRefreshNotification();
    this.evaluatePermissions();
    this.internalCommentsChanged();
    if (this.featureService.isEnabled(ELNFeatureFlags.CrossReferenceRemovalEnabled)) this.addGridActions();
  }

  ngAfterViewInit(): void {
    this.activeSubscriptions.splice(0, 0,
      this.experimentService.crossReferenceAdded.subscribe({
        next: crossReference => this.applyCrossReferences([crossReference.crossRef], crossReference.exptRecord)
      }),
    );
  }

  @HostListener('unloaded') // want a new component whenever navigation
  ngOnDestroy(): void {
    UnsubscribeAll(this.activeSubscriptions);
    this.elementRef.nativeElement.remove(); // want a new component whenever navigation
  }

  onGridReady() {
    this.applyCrossReferences(this.activity.activityReferences.crossReferences);
    this.augmentColumnsWithCornerFlagProviderForCells();
    this.setValidationStyles();
    this.watchCrossReferenceRefreshNotification();
  }

  onFirstDataRendered(_e: any) {
    this.loadCellLocks();
  }

  private watchCrossReferenceRefreshNotification() {
    this.activeSubscriptions.push(
      this.referencesService.crossReferenceRefresh.subscribe({
        next: () => this.refreshDataSource()
      })
    );
  }

  public evaluatePermissions() {
    if (this.userService.hasOnlyReviewerRights()) this.userIsReviewer = true;
  }

  private refreshDataSource(): void {
    this.grid.gridApi.setGridOption('rowData', this.activeRows);
  }

  loadRemovedRowsDialog() {
    this.dialogService.open(ReferencesRemovedCrossReferenceComponent, {
      width: '80%',
      autoZIndex: true,
      height: '50%',
      closable: true,
      closeOnEscape: true,
      header: $localize`:@@referencesRemovedCrossReferences:Removed Cross References`,
      styleClass: 'eln-removed-cross-reference-dialog'
    });
  }

  private refreshCrossReferences(crossReferences: CrossReference[], exptRecord?: ExperimentRecord): void {
    if (exptRecord) {
      const theRef = crossReferences[0];
      const experimentSearchResults = theRef.type === CrossReferenceType.Experiment ? { records: [exptRecord], totalRecordCount: 1 } : undefined;
      const activitySearchResults = theRef.type === CrossReferenceType.Activity ? { records: [exptRecord], totalRecordCount: 1 } : undefined;
      this.mergeSearchResults(activitySearchResults, experimentSearchResults, crossReferences);
    } else {
      const experimentCrossReferences = uniq(crossReferences.filter(r => r.type === CrossReferenceType.Experiment));
      const activityCrossReferences = uniq(crossReferences.filter(r => r.type === CrossReferenceType.Activity));

      const experimentLinkIds = uniq(experimentCrossReferences.map(r => r.linkId));
      const activityLinkIds = uniq(activityCrossReferences.map(r => r.linkId));

      const experimentSearchCriteria = this.getSearchCriteria(CrossReferenceType.Experiment, experimentLinkIds);
      const activitySearchCriteria = this.getSearchCriteria(CrossReferenceType.Activity, activityLinkIds);

      const experimentSearchObs = experimentLinkIds.length === 0 ? of(undefined) : this.searchService.bookshelfSearchExperimentIndexPost$Json({ body: experimentSearchCriteria });
      const activitySearchObs = activityLinkIds.length === 0 ? of(undefined) : this.searchService.bookshelfSearchExperimentIndexPost$Json({ body: activitySearchCriteria });
      const searchObservables = forkJoin({
        activitySearchResults: activitySearchObs,
        experimentSearchResults: experimentSearchObs
      });

      searchObservables.subscribe({
        next: ({ activitySearchResults, experimentSearchResults }) => {
          this.mergeSearchResults(activitySearchResults, experimentSearchResults, crossReferences);
        },
        error: () => {
          this.isLoading = false;
        },
        complete: () => {
          this.isLoading = false;
          this.refreshDataSource();
        },
      });
    }
  }

  private mergeSearchResults(
    activitySearchResults: ExperimentRecordSearchResult | undefined,
    experimentSearchResults: ExperimentRecordSearchResult | undefined,
    crossReferences: CrossReference[]
  ) {
    const experimentSearchResultProjections = experimentSearchResults?.records.map(exp => ({
      experimentId: exp.experimentId,
      entityTitle: exp.title,
      entityNumber: exp.experimentNumber,
      workflowState: exp.workflowState
    })) ?? [];

    const activitySearchResultProjections = activitySearchResults?.records.flatMap(asr => asr.activityDetails?.map(ad => ({
      experimentId: asr.experimentId,
      activityId: ad.activityId,
      activityTitle: ad.activityTitle,
      entityTitle: `${asr.title} / ${ad.activityTitle}`,
      entityNumber: ad.activityNumber,
      workflowState: asr.workflowState
    }))) ?? [];

    crossReferences.forEach(xRef => {
      let row: CrossReferenceRow;
      if (xRef.type === CrossReferenceType.Experiment) {
        const referencedExperiment = experimentSearchResultProjections.find(e => e.experimentId === xRef.linkId);
        if (!referencedExperiment) return;
        row = {
          id: xRef.id,
          rowIndex: (xRef.rowIndex.value as NumberValue).value,
          type: xRef.type,
          linkId: xRef.linkId,
          reference: referencedExperiment.entityNumber,
          title: referencedExperiment.entityTitle,
          purpose: this.dataValueService.getPrimitiveValue(ApiColumnType.String, xRef.purpose),
          state: referencedExperiment.workflowState ? ExperimentService.formatWorkflowState(referencedExperiment.workflowState) : '',
          route: `/experiment/${referencedExperiment.entityNumber}`
        };
      } else {
        const referencedActivity = activitySearchResultProjections.find(a => a?.activityId === xRef.linkId);
        if (!referencedActivity || !referencedActivity.entityNumber) return;
        const experimentNumber = referencedActivity.entityNumber.substring(0, referencedActivity.entityNumber.lastIndexOf('-')) ?? '';
        row = {
          id: xRef.id,
          rowIndex: (xRef.rowIndex.value as NumberValue).value,
          type: xRef.type,
          linkId: xRef.linkId,
          reference: referencedActivity.entityNumber,
          title: referencedActivity.entityTitle,
          purpose: this.dataValueService.getPrimitiveValue(ApiColumnType.String, xRef.purpose),
          state: referencedActivity.workflowState ? ExperimentService.formatWorkflowState(referencedActivity.workflowState) : '',
          route: `/experiment/${experimentNumber}/${referencedActivity.activityTitle}/Modules`
        };
      }
      if (row) {
        this.updateRowCache(row);
        this.gridRows.next(row);
        this.refreshDataSource();
      };
    });
  }

  private getSearchCriteria(type: CrossReferenceType, entryIds: string[]): SearchCriteria {
    let searchColumnName: string;
    const labSiteCode = this.experiment.organization.labSiteCode;

    switch (type) {
      case CrossReferenceType.Activity:
        // Do not add .keyword here or it will no longer find the activity
        searchColumnName = 'activityDetails.activityId';
        break;
      case CrossReferenceType.Experiment:
        searchColumnName = 'experimentId';
        break;
      default:
        throw Error('Cross Reference Type not supported');
    }

    return {
      bypassSecurity: false,
      filterConditions: [
        {
          conditionType: ConditionType.And,
          filters: [
            { columnName: searchColumnName, matchType: StringMatchType.In, values: entryIds, isSecurityFlag: false, dataType: DataType.String },
            { columnName: 'labsiteCode', matchType: StringMatchType.Word, text: labSiteCode, isSecurityFlag: true, dataType: DataType.String },
          ],
        }],
      pagination: { pageNumber: 1, pageSize: 5000 },
      sort: []
    };
  }

  /** Upserts row by id in cache */
  private updateRowCache(row: CrossReferenceRow) {
    if (!this.referencesService.crossReferencesByActivity[this.activity.activityId]) {
      this.referencesService.crossReferencesByActivity[this.activity.activityId] = [];
    }
    const foundRowId = this.referencesService.crossReferencesByActivity[this.activity.activityId].findIndex(r => r.id === row.id);
    if (foundRowId >= 0) {
      this.referencesService.crossReferencesByActivity[this.activity.activityId][foundRowId] = row;
    } else {
      this.referencesService.crossReferencesByActivity[this.activity.activityId].push(row);
    }
  }

  /**
   * @param exptRecord If only one crossReference provided, you can optionally pass in an ExperimentRecord returned from a recent search, so that it doesn't have to be repeated
   */
  applyCrossReferences(crossReferences: CrossReference[], exptRecord?: ExperimentRecord) {
    this.refreshCrossReferences(crossReferences, exptRecord);
    this.sendCompletionStatus();
  }

  onClientFacingNoteEvent(note: ClientFacingNoteModel): void {
    if (note.contextType !== ClientFacingNoteContextType.CrossReference) return;

    const context = note.context as CrossReferenceClientFacingNoteContext;
    if (context.activityId !== this.activity.activityId) return;

    this.refreshCell(context.rowId, context.columnField, true);
  }

  private updateFeatureFlags() {
    const featureFlags = this.clientStateService.getFeatureFlags(this.clientState);
    this.canEditExperimentInReviewStateFlag = !!featureFlags.find((data) => JSON.parse(data).CanEditExperimentInReviewState);
  }

  private setAddRowBasedOnWorkflowState(state: ExperimentWorkflowState) {
    const disableRowAdd =
      state === ExperimentWorkflowState.Authorized
      || state === ExperimentWorkflowState.Cancelled
      || (state === ExperimentWorkflowState.InReview && !this.canEditExperimentInReviewStateFlag);
    this.allowAddRow = !disableRowAdd;
  }

  buildContextMenuItems(): MenuItem[] {
    return [
      {
        label: $localize`:@@internalComments:Internal Comments`,
        icon: 'pi pi-comments',
        styleClass: 'eln-context-table-title eln-activity-cross-references', // classes may nor may not be used in a CSS style-rule0
        command: (_event$) => {
          this.loadInternalCommentForTableTitleLevel();
        }
      },
    ];
  }

  /**
   * Invokes refreshCells for a single cell.
   *
   * Note: Might need to this.refreshDataSource() or otherwise update the cell's value in the the grid first.
   *
   * @param force Pass true to invoke the cell's renderer even though its data value might be unchanged. This is useful for cell flags.
   */
  refreshCell(rowId: string, columnField: string, force = false): void {
    const rowNode = this.grid.gridApi.getRowNode(rowId);
    if (!rowNode) return;

    const column = this.grid.gridApi.getColumn(columnField);
    if (!column) return;

    this.grid.gridApi.refreshCells({ force, rowNodes: [rowNode], columns: [column] });
  }

  /** Handles the request to show history for this activity's cross references table */
  showHistoryDialog() {
      this.isHistoryLoading = true;
    this.historyService
      .loadTableAuditHistory(this.experiment.id, this.activity.activityId) // For History, cross-references acts like a table
      .subscribe({
        next: this.bindHistoryToDialog.bind(this)
      });
  }

  showSlider() {
    this.showCrossReferenceSlider = true;
  }

  crossReferenceSliderClosed() {
    this.showCrossReferenceSlider = false;
  }

  /** Context menu for grid */
  getContextMenu(): GridContextMenuItem[] {
    /* Hopefully we can get a context menu based on context but apparently not today.
    const mightCellHaveHistory = this.columns.find((c) => c.field === this.grid.gridApi.getFocusedCell()?.column.getColDef().field)
      ?.editable !== false; // false means was never editable and will never be editable so has no history
    */
    const mightCellHaveHistory = true;
    const menu: GridContextMenuItem[] = [
      'copy',
      'copyWithHeaders',
      'copyWithGroupHeaders',
      'paste',
      'separator',
      {
        label: $localize`:@@clientFacingNoteHeader:Client-facing Notes`,
        action: () => {
          const cell = this.grid.gridApi.getFocusedCell();
          if (cell) {
            const row = this.grid.gridApi.getDisplayedRowAtIndex(cell.rowIndex);
            const colDef = cell.column.getColDef();
            const field = colDef.field as string; // template validation prevents undefined
            if (row?.id && colDef?.field) this.showClientFacingNotes(row.id, field);
          }
        },
        icon: '<img src="assets/eln-assets/apps-add.svg" class="ag-icon ag-custom-icon" />',
        disabled: !this.experimentService.isClientFacingNoteEnabled()
      },
      {
        label: $localize`:@@internalComments:Internal Comments`,
        action: () => {
          const cell = this.grid.gridApi.getFocusedCell();
          if (cell) {
            const row = this.grid.gridApi.getDisplayedRowAtIndex(cell.rowIndex);
            const field = cell.column.getColDef().field as string;
            if (row) this.showInternalComments(row.id as string, field);
          }
        },
        icon: '<img src="assets/eln-assets/apps-add.svg" class="ag-icon ag-custom-icon" />'
      },
    ];
    const wantSeparator = mightCellHaveHistory;
    if (wantSeparator) menu.push('separator');
    if (mightCellHaveHistory) menu.push({
      label: $localize`:@@history:History`,
      action: () => this.getHistoryFromContext(),
      icon: '<img src="assets/eln-assets/audit-history.svg" class="ag-icon ag-custom-icon" />'
    }
    );

    return menu;
  }

  /** Triggers showing history of context focused cell */
  getHistoryFromContext() {
    const cell = this.grid.gridApi.getFocusedCell();
    if (cell) {
      const row = this.grid.gridApi.getDisplayedRowAtIndex(cell.rowIndex);
      if (row) {
        const columnName = cell.column.getColDef()?.field as string;
        const rowId = row.id as string;
        this.loadTableCellHistoryDialog(rowId, columnName);
      }
    }
  }

  /** Shows dialog for Cell or Row */
  private loadTableCellHistoryDialog(rowId: string, columnField: string) {
    const cellFilter = (event: CrossReferenceEventTypes) => {
      const requestIsForRowHistory = this.columns.find((c) => c.field === columnField)?.columnType === ColumnType.index;
      if (event.eventContext.eventType === ExperimentEventType.ActivityCrossReferenceAdded) {
        if (requestIsForRowHistory) return (event as CrossReferenceAddedEventNotification).id === rowId;

        return false;  // POLICY: cell history doesn't include its row being added
      }

      if ('crossReferenceId' in event && event.crossReferenceId !== rowId) return false;

      const note = 'number' in event && this.lookupNote(event.number);
      if (note && 'rowId' in note.context && (note.context.rowId !== rowId || note.context.columnField !== columnField)) return false;

      if (event.eventContext.eventType === ExperimentEventType.ActivityCrossReferenceChanged) {
        const changed = event as CrossReferenceChangedEventNotification;
        if (changed.property !== columnField) return false;
      }

      if (event.eventContext.eventType === ExperimentEventType.StatementApplied) {
        const changed = event as StatementAppliedEventNotification;
        return changed.contentDetails.some((statement: StatementContentDetails) => statement.path[0] === rowId && statement.path[1] === columnField);
      }
      return true;
    }
    this.isLoading = true;
    this.historyService
      .loadTableAuditHistory(this.activity.experimentId, this.activity.activityId)
      .pipe(finalize(() => this.isLoading = false))
      .subscribe({
        next: records => this.bindHistoryToDialog(records, cellFilter)
      });
  }

  showClientFacingNotes(rowId: string, field: string): void {
    const eventTarget: CrossReferenceClientFacingNoteContext = {
      activityId: this.activity.activityId,
      columnField: field,
      rowId
    };
    const details: ShowClientFacingNotesEventData = {
      eventContext: {
        contextType: ClientFacingNoteContextType.CrossReference,
        mode: 'clientFacingNotes'
      },
      targetContext: eventTarget
    };
    const customEvent = new CustomEvent<ShowClientFacingNotesEventData>('ShowSlider', {
      bubbles: true,
      detail: details
    });
    this.elementRef.nativeElement.dispatchEvent(customEvent);
  }

  private augmentColumnsWithCornerFlagProviderForCells(): void {
    // common provider for all corner flags, for all cells in this table.
    const flagConfigProvider = (flag: 'top-right' | 'bottom-right', rowId: string, field: string) => {
      if (flag === 'top-right') {
        const note = this.experiment.clientFacingNotes.find(n => isEqual(n.path, [rowId, field])); // can depend on rowId being globally unique
        return {
          enabled: !!note,
          color: ELNAppConstants.ClientFacingNoteFlagColor,
          hoverText: note?.indicatorText ?? '', // by policy for notes, empty is not allowed
          onClick: () => this.showClientFacingNotes(rowId, field)
        };
      } else if (flag === 'bottom-right') {
        const internalComments = this.experiment.internalComments?.comments.find(
          (c: CommentResponse) => c.path.includes(rowId) && c.path.includes(field) && c.status !== InternalCommentStatus.Removed
        ); // can depend on rowId being globally unique

        return {
          enabled: !!internalComments,
          color: ELNAppConstants.InternalCommentFlagColor,
          isHollow: internalComments?.status === InternalCommentStatus.Pending,
          hoverText: this.getHoverOverText(internalComments?.content),
          onClick: () => this.showInternalComments(rowId, field)
        };
      }
      // default. Future: could use the 'bottom-right' flag for, say, internal comments.
      return { enabled: false, color: 'orange', hoverText: 'not used today', onClick: () => { } };
    };
    this.columns.forEach((c: ColumnDefinition) => c.flagConfigProvider = flagConfigProvider);
  }

  private addGridActions() {
    const rowActions = this.getRowActionItems();
    const actionsSubject: BehaviorSubject<BptRowActionElement[]> = new BehaviorSubject(rowActions);

    this.gridActions = {
      actions: actionsSubject
    };
  }

  public getRowActionItems(): BptRowActionElement[] {
    return [
      {
        id: this.referencesService.crossReferenceDeleteActionId,
        enabled: () => !(this.userIsReviewer || this.experiment.workflowState === ExperimentWorkflowState.InReview),
        styleClass: 'far fa-trash-alt',
        click: this.rowDeleteActionClick.bind(this),
        tooltip: $localize`:@@RemoveItem:Remove item`
      }
    ];
  }

  private rowDeleteActionClick(e: BptGridRowActionClickEvent) {
    this.referencesService.confirmThenRemoveCrossReference(e.params.data);
  }

  getHoverOverText(message = ''): string {
    return (
      $localize`:@@internalComments:Internal Comments` +
      `:${this.shortContent(
        message.replace(/<[^>]*>/g, ' ').replace(/\s{2,}/g, ' ').replace(/&nbsp;/g, '')
      )}(click to view)`
    );
  }

  public shortContent(message: string): string {
    const limit = 30;
    return message.length <= limit ? message : message.substring(0, limit) + '…';
  }

  static clickedExperimentReference(experimentReference: string) {
    window.open(`/experiment/${experimentReference}`, '_blank');
  }

  static referenceClicked(referencedRoute: string) {
    window.open(referencedRoute, '_blank');
  }

  static clickedActivityReference(experimentReference: string) {
    window.open(`/experiment/${experimentReference}`, '_blank');
  }

  isEmpty(value: ModifiableDataValue | undefined): boolean {
    return !value || value.value.state === ValueState.Empty;
  }

  cellValueEditing(e: BptGridCellValueChangedEvent) {
    if (e.source === 'collaborativeEdit' || this.experimentWarningService.isUserAllowedToEdit) {
      this.cellValueChanged(e);
    }
  }

  setColumnProperties() {
    this.columns.forEach(column => {
      column.lockVisible = column.disableHiding;
      if (column.editable) {
        column.editable = (params: EditableCallbackParams) => this.isCellEditable(params);
      }
    });
  }

  private isCellEditable(params: EditableCallbackParams): boolean {
    const rowId = params.node.id;
    if (!rowId) return false; // can never happen

    const currentColumnDefinition = this.columns.find((data) => data.field === params.colDef.field);
    if (!currentColumnDefinition) return false; // can never happen
    if (this.userIsReviewer) return false;
    const cancelledOrAuthorizedLock =
      this.experiment.workflowState === ExperimentWorkflowState.Authorized ||
      this.experiment.workflowState === ExperimentWorkflowState.Cancelled;
    const setupLock =
      this.experiment.workflowState === ExperimentWorkflowState.Setup &&
      currentColumnDefinition?.containsObservableData;
    const reviewLock =
      this.experiment.workflowState === ExperimentWorkflowState.InReview &&
      !this.canEditExperimentInReviewStateFlag;
    const experimentWorkflowLocks = cancelledOrAuthorizedLock || setupLock || reviewLock;
    const cellLocked = params.context?.inputLocks?.[rowId + currentColumnDefinition.field] || experimentWorkflowLocks;
    return !cellLocked;
  }

  /**
   * Handle cell value changed due to reason indicated by e.source:
   *   * grid event
   *   * collaborativeEdit (which means we don't send a change command)
   *   * specificationEdit (which means we don't send a change command)
   *   * systemFields ???
   */
  cellValueChanged(e: BptGridCellValueChangedEvent) {
    if (!e.gridId) throw new Error(missingGridIdMessage);
    if (!e.rowId) throw new Error(missingRowIdMessage);
    if (!e.field) throw new Error(missingFieldMessage);

    const row = this.activity.activityReferences.crossReferences.find(r => r.id === e.rowId);
    if (!row) return; // This would be okay if the row wasn't here yet, if that's possible.

    const accessor = e.field as ModifiablePropertiesKey;

    const column = this.columns.find(c => c.field === e.field);
    if (!column || !column.field) throw new Error(missingColumnMessage);

    const newColValue: any = this.convertNewColumnValueBasedOnType(column.field, e.newValue);

    const oldCellValue = row[accessor] ?? {
      isModified: false,
      value: { type: ValueType.String, state: ValueState.Empty }
    };
    const newValue = this.dataValueService.getExperimentDataValue(column.columnType as FieldOrColumnType, newColValue);
    const newCellValue = DataRecordService.getModifiableDataValue(newValue, oldCellValue);

    if (isEqual(newCellValue, oldCellValue)) return;

    if (e.source !== 'specificationEdit') row[accessor] = newCellValue;

    if (
      e.source === 'collaborativeEdit' ||
      e.newValue === undefined ||
      e.source === 'systemFields' ||
      e.source === 'specificationEdit'
    ) {
      return;
    }

    const cellChangedValues = {
      activityId: this.activity.activityId,
      crossReferenceId: row.id,
      experimentId: this.experiment.id,
      property: accessor as CrossReferenceModifiableProperty,
      propertyValue: newValue
    };
    this.postChangeCellCommand(cellChangedValues, e.source, oldCellValue);
  }

  cellEditStartedEvent(e: BptGridCellEditEvent) {
    const { rowId, field } = e;
    if (!rowId) return;
    if (!field) return;

    this.lockTimeOut = window.setTimeout(() => {
      this.sendInputStatus(LockType.lock, rowId, field);
    }, 3000);
  }

  cellEditStoppedEvent(e: BptGridCellEditEvent) {
    if (!e.rowId) return;
    if (!e.field) return;

    window.clearTimeout(this.lockTimeOut);
    this.sendInputStatus(LockType.unlock, e.rowId, e.field);
  }

  cellKeyDownEvent(e: BptGridCellEditEvent) {
    if (!e.rowId) return;
    if (!e.field) return;

    // Skipping sending input status while key down for Tab, Enter and Escape keys triggered
    // as these events denotes edit is stopped for the cell and cellEditStoppedEvent is called before cellKeyDownEvent
    // cellEditStoppedEvent sends unlock notification for the cell so cell would be unlocked
    // when cellKeyDownEvent is hit after cellEditStoppedEvent, we are skipping sending lock notification for the cell which is already unlocked
    if (e.keyCode !== undefined && !['Tab', 'Enter', 'Escape'].includes(e.keyCode)) {
      window.clearTimeout(this.lockTimeOut);
      this.sendInputStatus(LockType.lock, e.rowId, e.field);
    }
  }

  sendInputStatus(lockType: LockType, rowId: string, columnName: string) {
    if (!this.experimentService.currentExperiment) return;

    const fieldLock = new CellLock(
      this.experimentService.currentExperiment.id,
      lockType,
      ActivityReferencesPseudoModuleId,
      this.experimentService.currentActivityId,
      this.experimentNotificationService.getCollaborator(),
      CrossReferencesPseudoTableId,
      rowId,
      columnName
    );
    this.experimentNotificationService.sendInputControlStatus([fieldLock]);
  }

  /**
   * Posts change command. Updates Table Model, including completion tracking.
   */
  private postChangeCellCommand(commandValues: ChangeCrossReferenceCommand, eventSource: string | undefined, oldCellValue?: any) {
    this.updateTableModel(commandValues, oldCellValue);
    if (!this.grid || !this.grid.gridApi) return;

    this.grid.gridApi.refreshCells({ force: true });
    /*
     Commented out because if there is a desire to add rules to cross reference then these will need an implementation
       this.cellChangedEventRuleEvaluation(commandValues, eventSource);
       commandValues.ruleContext = this.RuleHandler.getRuleCommandContext(eventSource);
    */
    this.activityReferenceEventsService
      .activityReferencesCrossReferenceChangePost$Json({ body: commandValues })
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: () => {
          this.validation.successes.push(
            $localize`:@@TableRowEditedSuccessfully:Row Edited successfully`
          );
        },
        error: (error: any) => {
          console.log(JSON.stringify(error));
          this.validation.errorTitle = $localize`:@@receivedErrorFromServer:Received the following error from server`;
        }
      });
    this.sendCompletionStatus();
  }

  /**
   * Updates our own UI-bound completion percentage for this activity's cross references (MAIN PURPOSE)
   * and sends status change to/from 100 (might not be needed in new workflow but kept anyway)
   *
   * Note: CompletionTrackingService is not the only story. There are also functions that compute top-down if a tree is complete
   * (e.g. checkActivityCompletion, setActivityCompletionStatus).
   */
  sendCompletionStatus() {
    const CalculatedCompletionPercent = this.referencesService.calculateCompletionPercentage(this.crossReferences);
    const tableInfo: TableDataForCompletionTracking = {
      tableId: `${this.activity.activityId}/crossReferences`,
      tableTitle: [this.activity.itemTitle, ActivityReferencesPseudoModuleTitle, $localize`:@@crossReferences:Cross References`].join(),
    };
    this.completionPercent = this.completionTrackingService
      .calculateCrossReferencesCompletionPercentage(CalculatedCompletionPercent.totalCells, CalculatedCompletionPercent.emptyCellsCount, this.completionPercent, tableInfo);
  }

  private updateTableModel(command: ChangeCrossReferenceCommand, oldCellValue: any): void {
    const row = this.activity.activityReferences.crossReferences.find((row) => row.id === command.crossReferenceId);
    if (!row) return;

    row[command.property as ModifiablePropertiesKey] = DataRecordService.getModifiableDataValue(command.propertyValue, oldCellValue);
  }

  private convertNewColumnValueBasedOnType(field: string, value: EmittedValue): EmittedValue {
    const column = this.columns.find(c => c.field === field);
    if (!column) throw new Error(missingColumnMessage);

    // Structure is similar to TableComponent but nothing to actually do here since all fields are StringValue.

    return value;
  }

  private lookupNote(number: number): ClientFacingNoteModel | undefined {
    return this.experiment.clientFacingNotes.find(n => n.number === number);
  }

  private bindHistoryToDialog(data: AuditHistoryDataRecordResponse, filter: (event: CrossReferenceEventTypes) => boolean = () => true) {
    this.isHistoryLoading = false;
    const applicableDataRecords = this.filterEvents(data)
      .filter(filter);
    this.dynamicDialogRef = this.historyService.showAuditDialog(applicableDataRecords, this.title);
  }

  private filterEvents(data: AuditHistoryDataRecordResponse) {
    return data.dataRecords
      .filter((h): h is CrossReferenceEventTypes => this.crossReferenceEventTypes.includes(h.eventContext.eventType))
      .filter(dr => (!('cfnNumber' in dr)) ||
        ((this.lookupNote(dr.cfnNumber as number)?.contextType === ClientFacingNoteContextType.CrossReference) &&
          dr.eventContext.eventType === ExperimentEventType.StatementApplied)
      )
  }

  showInternalComments(rowId: string, field: string): void {
    const cell = document.querySelector(`ag-grid-angular [row-id="${rowId}"] [col-id="${field}"]`);
    if (cell) {
      this.renderer.setStyle(
        cell,
        this.backgroundColor,
        ELNAppConstants.InternalCommentBackGroundColor
      );
    }

    this.buildInternalComments(rowId, field);
  }

  private buildInternalComments(rowId: string | undefined, field: string) {
    const columnLabel = this.columns.find(c => c.field === field)?.label;
    const cell = this.grid?.gridApi.getFocusedCell();
    if (cell) {
      const rowIndex = this.grid?.gridApi.getDisplayedRowAtIndex(cell.rowIndex)?.rowIndex;
      if (rowIndex || rowIndex === 0) {
        this.openInternalComments(
          CrossReferencesPseudoTableId,
          [this.activity.activityId, CrossReferencesPseudoTableId, rowId ?? '', field, columnLabel ?? '', (rowIndex + 1).toString(), CommentContextType.ActivityCrossReference],
          CommentContextType.TableCell
        );
      }
    }
  }

  openInternalComments(nodeId: string, path: string[], contextType: CommentContextType) {
    this.internalCommentData = {} as CommentDetails;
    this.internalCommentData.nodeId = nodeId;
    this.internalCommentData.path = path;
    this.internalCommentData.contextType = contextType;
    this.commentService.openInternalComments(this.internalCommentData);
  }

  /** "subscribeToRefreshInternalComment" (misnamed but copied from similar work) */
  internalCommentsChanged() {
    this.activeSubscriptions.push(
      this.commentService.refreshInternalComment.subscribe((currentContext: CommentsResponse) => {
        if (!this.grid?.gridApi) return;

        this.experiment.internalComments = currentContext;
        this.grid.gridApi.refreshCells({ force: true });
      })
    );
  }

  /** "loadInternalCommentForTableLevel" (misnamed but kept similar to outer work) */
  loadInternalCommentForTableTitleLevel() {
    this.openInternalComments(
      this.experiment.id,
      [this.activity?.activityId, ActivityReferencesPseudoModuleId, CommentContextType.CrossReferences],
      CommentContextType.Table
    );
  }

  /** Experiment event types relevant to cross references */
  crossReferenceEventTypes: ExperimentEventType[] = [
    ExperimentEventType.ActivityCrossReferenceAdded,
    ExperimentEventType.ActivityCrossReferenceChanged,
    ExperimentEventType.ActivityCrossReferenceRemoved,
    ExperimentEventType.ActivityCrossReferenceRestored,
    ExperimentEventType.ClientFacingNoteCreated,
    ExperimentEventType.ClientFacingNoteChanged,
    ExperimentEventType.StatementApplied,
  ];
}

type CrossReferenceEventTypes =
  | CrossReferenceAddedEventNotification
  | CrossReferenceChangedEventNotification
  | CrossReferenceRemovedEventNotification
  | CrossReferenceRestoredEventNotification
  | ClientFacingNoteCreatedEventNotification
  | ClientFacingNoteChangedEventNotification
  | StatementAppliedEventNotification;

export const CrossReferencesColumns: ColumnDefinition[] = [
  {
    columnType: ColumnType.rowId,
    field: 'id',
    label: $localize`:@@promptRowId:Row ID`,
    hidden: true,
    editable: false,
  },
  {
    columnType: ColumnType.index,
    field: 'rowIndex',
    label: $localize`:@@rowIndex:Row Index`,
    hidden: true,
    editable: false,
  },
  {
    columnType: ColumnType.string,
    field: 'type',
    label: $localize`:@@type:Type`,
    hidden: true,
    editable: false,
  },
  {
    columnType: ColumnType.string,
    field: 'linkId',
    label: $localize`:@@linkId:Link ID`,
    hidden: true,
    editable: false,
  },
  {
    columnType: ColumnType.link,
    field: 'reference',
    label: $localize`:@@reference:Reference`,
    editable: false,
    width: 200,
    minWidth: 200,
    cellClass: 'cell-Link',
    linkData: (_: MouseEvent, data: any) => {
      CrossReferencesComponent.clickedExperimentReference(data.reference);
    },
  },
  {
    columnType: ColumnType.string,
    field: 'title',
    label: $localize`:@@title:Title`,
    editable: false,
    width: 330, // Gives the approximation width of 100 characters to display
    minWidth: 330,
  },
  {
    columnType: ColumnType.string,
    field: 'purpose',
    label: $localize`:@@purpose:Purpose`,
    editable: true, // replaced by function later
  },
  {
    columnType: ColumnType.string,
    field: 'state',
    label: $localize`:@@stateColumn:State`,
    editable: false,
    width: 100,
    minWidth: 100,
  },
  { // Permanently hidden with #3259652
    columnType: ColumnType.string,
    field: 'additionalDetails',
    label: $localize`:@@additionalDetails:Additional Details`,
    editable: false, // replaced by function later
  },
];

/**
 * Display values for cross-reference fields
 */
export type CrossReferenceRow = {
  id: string, // string of a GUID
  rowIndex?: string, // string of a positive integer of a NumberValue
  type: string, // string of CrossReferenceType, hidden so doesn't need to be prettied up
  linkId: string, // string of a GUID
  reference?: string, // experiment number
  title?: string, // experiment title
  state: string, // display of ExperimentWorkflowState
  purpose?: string, // string of a StringValue
  route: string // linkout route
};

export type ModifiablePropertiesKey = keyof Pick<CrossReference, 'purpose'>;

const missingGridIdMessage =
  'Logic error: When a grid is used to render a table, gridId must be set to tableId but gridId is missing.';
const missingRowIdMessage =
  'Logic error: When a grid creates a row, it must sett rowId but rowId is missing.';
const missingFieldMessage =
  'Logic error: When a grid is used to render a table, each column definition must have a field value but at least one is missing.';
const missingColumnMessage =
  'Logic error: When a grid event has a cell value the field property must identify a column but its definition is missing.';
