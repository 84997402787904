/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CustomerAnalyticalProject } from '../models/customer-analytical-project';
import { customerAnalyticalProjectsLabsiteCodeGet$Json } from '../fn/customer-analytical-project/customer-analytical-projects-labsite-code-get-json';
import { CustomerAnalyticalProjectsLabsiteCodeGet$Json$Params } from '../fn/customer-analytical-project/customer-analytical-projects-labsite-code-get-json';
import { customerAnalyticalProjectsLabsiteCodeGet$Plain } from '../fn/customer-analytical-project/customer-analytical-projects-labsite-code-get-plain';
import { CustomerAnalyticalProjectsLabsiteCodeGet$Plain$Params } from '../fn/customer-analytical-project/customer-analytical-projects-labsite-code-get-plain';

@Injectable({ providedIn: 'root' })
export class CustomerAnalyticalProjectService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `customerAnalyticalProjectsLabsiteCodeGet()` */
  static readonly CustomerAnalyticalProjectsLabsiteCodeGetPath = '/customerAnalyticalProjects/{labsiteCode}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerAnalyticalProjectsLabsiteCodeGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerAnalyticalProjectsLabsiteCodeGet$Plain$Response(params: CustomerAnalyticalProjectsLabsiteCodeGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CustomerAnalyticalProject>>> {
    return customerAnalyticalProjectsLabsiteCodeGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `customerAnalyticalProjectsLabsiteCodeGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerAnalyticalProjectsLabsiteCodeGet$Plain(params: CustomerAnalyticalProjectsLabsiteCodeGet$Plain$Params, context?: HttpContext): Observable<Array<CustomerAnalyticalProject>> {
    return this.customerAnalyticalProjectsLabsiteCodeGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CustomerAnalyticalProject>>): Array<CustomerAnalyticalProject> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerAnalyticalProjectsLabsiteCodeGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerAnalyticalProjectsLabsiteCodeGet$Json$Response(params: CustomerAnalyticalProjectsLabsiteCodeGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CustomerAnalyticalProject>>> {
    return customerAnalyticalProjectsLabsiteCodeGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `customerAnalyticalProjectsLabsiteCodeGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerAnalyticalProjectsLabsiteCodeGet$Json(params: CustomerAnalyticalProjectsLabsiteCodeGet$Json$Params, context?: HttpContext): Observable<Array<CustomerAnalyticalProject>> {
    return this.customerAnalyticalProjectsLabsiteCodeGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CustomerAnalyticalProject>>): Array<CustomerAnalyticalProject> => r.body)
    );
  }

}
