<bpt-dropdown
              #bptDropdown
              *ngIf = "Labsites"
              [styleClass]="styleClass"
              [panelStyleClass]="panelStyleClass"
              [editable]="false"
              [placeholder]="placeholder"
              [options]="Labsites"
              [label]="label"
              [showClear]="showClear"
              [showFilter]="true"
              [multiSelect]="multiSelect"
              [valueField]="valueField"
              [labelField]="labelField"
              [showToggleAll]="false"
              [(ngModel)]="value"
              ngModel="labsiteCodes"
              ng-change="modelChanged($event)"
              (ngModelChange)="modelChanged($event)"
              [ngModelOptions]="ngModelOptions"
              disabledField="isClosed"
              [wrap]="wrap"
              [group]="true"
              [groupLabelField]="labelField"
              groupChildrenField="subBusinessUnits"
              [readOnly]="readonly"
              [disabled]="disabled"
              [required]="required"
              [errorMessage]="errorMessage"
              [errorFlag]="errorFlag"
            ></bpt-dropdown>