<ng-container *ngIf="nodeId && nodeType">
    <bpt-text-input
        #bptInputEntry
        [commitValueOnEnter]=true
        [multiLine]="false"
        [autoResize]="false"
        [ngModel]="title"
        [errorFlag]="hasError"
        [ngModelOptions]="{ updateOn: 'blur' }"
        (textInputBlur)="onBlur($event)"
        (textInputKeyUp)="onkeyUp($event)"
        inputId="eln-experiment-node-title-input"
        [suppressContextMenu]="true"
        i18n-placeholder="@@titlePlaceholder"
        placeholder="Enter Title"
        styleClass="bpt-eln-node-inline-editing-input"></bpt-text-input>
</ng-container>