//{Key} should be replaced by the respective values of the environment.

import { EnvironmentVariables } from "./environment.model";

//These will be replaced during deployment by Azure DevOps.
export const environment: EnvironmentVariables = {
  production: true,
  displayWaitDialogThreshold: 500,
  supportedLanguages: ['en', 'es', 'fr', 'ja'],
  defaultLanguage: 'en',
  coreServicesUrl: '',
  dataEntryServicesUrl: '',
  searchServiceUrl: '',
  auditServiceUrl: '',
  internalCommentServiceUrl: '',
  dataPackageServiceUrl: '',
  cookbookServiceUrl: '',
  instrumentIntegrationServiceUrl: '',
  instrumentAdminServiceUrl: '',
  instrumentServerUrl: '',
  elnInstrumentsServiceUrl: '',
  elnFilesServiceUrl: '',
  elnLabelsServiceUrl: '',
  elnWebUrl: '',
  logging: {
    enableDisplay: false,
    logViewerUrl: ''
  },
  instrumentDisconnectTimeout: 0,
  signalRRetryAttempts: [5000, 5000, 5000, 5000, 5000],
  signalRInitialDelay: 5000,
  fieldFlashDelay: 1000,
  maxIntervalToLoadLocks: 5000,
  // This license is only for Eurofins ELN and should not be used by any other application
  agGridLicense: 'Using_this_{AG_Grid}_Enterprise_key_{AG-049975}_in_excess_of_the_licence_granted_is_not_permitted___'
                + 'Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___'
                + '{Eurofins_(US,_Lancaster,_PA)}_is_granted_a_{Single_Application}_Developer_License_for_the_application_'
                + '{Eurofins_ELN}_only_for_{5}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_'
                + '{Eurofins_ELN}_need_to_be_licensed___{Eurofins_ELN}_has_not_been_granted_a_Deployment_License_Add-on___'
                + 'This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{3_January_2025}____[v3]_[01]_'
                + 'MTczNTg2MjQwMDAwMA==2617b6af740c7ba16e521e20c658f504',
  features: {
    systemAlert: {
      message: ''
    }
  },
};
