import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { environment } from '../../environments/environment';
import { AppConfiguration } from '../configurations/app-configuration.interface';
import { Configuration } from '../integrations/configurations/configuration.interface';
import { DefaultConfiguration } from '../integrations/configurations/default-configuration';
import { ElimsConfiguration } from '../integrations/configurations/elims-configuration';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  config: Configuration;
  // app is the global namespace in Helium which holds all the configurations and urls
  app = (window as any).app;

  public static get isLimsHosted(): boolean {
    return typeof ((window as any).app.appVersion) !== "undefined";
  }

  constructor(private readonly httpClient: HttpClient) {
    this.config = new DefaultConfiguration();
    this.initializeConfiguration();
  }

  getReAuthFailedAttempts(): number {
    return this.config.getReAuthFailedAttempts();
  }

  isLimsHosted(): boolean {
    return this.config.isLimsHosted();
  }

  getLogViewerUrl(): string {
    return this.config.getLogViewerUrl();
  }

  isLogViewerDisplayEnabled(): boolean {
    return this.config.isLogViewerDisplayEnabled();
  }

  loadConfig(): Observable<AppConfiguration> {
    return this.httpClient.get<AppConfiguration>('/assets/eln-assets/configurations/appconfig.json').pipe(
      tap((configData: AppConfiguration) => {
        this.populateConfigurationIntoEnvironment(configData);
      })
    );
  }

  private populateConfigurationIntoEnvironment(applicationConfigurations: AppConfiguration): void {
    environment.coreServicesUrl = applicationConfigurations.ElnApiConfig.coreServicesUrl.replace(
      /\/$/,
      ''
    );
    environment.dataEntryServicesUrl = applicationConfigurations.ElnApiConfig.dataEntryServicesUrl.replace(
      /\/$/,
      ''
    );
    environment.searchServiceUrl = applicationConfigurations.ElnApiConfig.searchServiceUrl.replace(
      /\/$/,
      ''
    );
    environment.auditServiceUrl = applicationConfigurations.ElnApiConfig.auditServiceUrl.replace(
      /\/$/,
      ''
    );
    environment.internalCommentServiceUrl = applicationConfigurations.ElnApiConfig.internalCommentServiceUrl?.replace(/\/$/, '');
    environment.dataPackageServiceUrl = applicationConfigurations.ElnApiConfig.dataPackageServiceUrl?.replace(/\/$/, '');
    environment.cookbookServiceUrl = applicationConfigurations.ElnApiConfig.cookbookServiceUrl?.replace(
      /\/$/,
      ''
    );
    environment.instrumentIntegrationServiceUrl = applicationConfigurations.ElnApiConfig.instrumentIntegrationServiceUrl
      ?.replace(/\/$/, '')
      ?.replace(':5000', '')
      ?.replace('chromatograhy.uat.liis.eurofins.local', 'chromatograhy.staging.liis.eurofins.local');
    environment.instrumentAdminServiceUrl = applicationConfigurations.ElnApiConfig.instrumentAdminServiceUrl?.replace(/\/$/, '');

    environment.instrumentServerUrl = applicationConfigurations.ElnApiConfig.instrumentServerUrl?.replace(
      /\/$/,
      ''
    );

    environment.elnLabelsServiceUrl = applicationConfigurations.ElnApiConfig.elnLabelsServiceUrl?.replace(
      /\/$/,
      ''
    );
    environment.elnInstrumentsServiceUrl = (
      environment.elnInstrumentsServiceUrl ||
      environment.dataEntryServicesUrl.replace(
        'dataentry',
        'instruments'
      )
    ).replace(/\/$/, '');
    environment.elnFilesServiceUrl = applicationConfigurations.ElnApiConfig.elnFilesServiceUrl?.replace(/\/$/, '');
    environment.elnWebUrl = applicationConfigurations.elnWebUrl;
    environment.instrumentDisconnectTimeout = applicationConfigurations.instrumentDisconnectTimeout;
    environment.logging = applicationConfigurations.logging;

    if (applicationConfigurations.features && environment.features) {
      environment.features.systemAlert = applicationConfigurations.features.systemAlert;
    }
  }

  coverageDummyFunction() {
    return true;
  }

  initializeConfiguration() {
    if (this.app.appVersion) { // elims loads app version, eln standalone does not.
      this.config = new ElimsConfiguration(this.app);
    }
  }
}
