<div class="preparation-container-layout-content">
  <div id="eln-preparation-container-description" class="preparation-container-description">
    <bpt-slider
      id="eln-bptSlider-preparation-container-description"
      [(visible)]="sliderOptions.visible"
      [closeOnEscape]="sliderOptions.closeOnEscape"
      [headerText]="sliderOptions.headerText"
      [size]="sliderOptions.size"
      [isFooterSticky]="sliderOptions.isFooterSticky"
      [displayFooter]="sliderOptions.displayFooter"
      [displayFooterWithPrimaryButton]="false"
      [displayFooterWithSecondaryButton]="false"
      [isModal]="sliderOptions.isModal">
      <div class="flex flex-column">
        <bpt-dropdown
          [editable]="true"
          label="Color"
          i18n-label="@@ContainerDescColor"
          [showClear]="false"
          [options]="optionsForColor"
          [(ngModel)]="descModels.color"
          valueField="value"
          labelField="label"
          [showClear]="true"
          class="mb-5 mt-3 color-dropdown"
          [readOnly]="isReadOnly"></bpt-dropdown>
        <bpt-dropdown
          [editable]="true"
          label="Material"
          i18n-label="@@ContainerDescMaterial"
          [showClear]="false"
          [options]="optionsForMaterial"
          [(ngModel)]="descModels.material"
          valueField="value"
          labelField="label"
          [showClear]="true"
          class="mb-5 material-dropdown"
          [readOnly]="isReadOnly"></bpt-dropdown>
        <bpt-dropdown
          [editable]="true"
          label="Type"
          i18n-label="@@ContainerDescType"
          [showClear]="false"
          [options]="optionsForType"
          [(ngModel)]="descModels.type"
          valueField="value"
          labelField="label"
          [showClear]="true"
          class="mb-5 type-dropdown"
          [readOnly]="isReadOnly"></bpt-dropdown>
        <bpt-dropdown
          [editable]="true"
          label="Exposure"
          i18n-label="@@ContainerDescExposure"
          [showClear]="false"
          [options]="optionsForExposure"
          [(ngModel)]="descModels.exposure"
          valueField="value"
          labelField="label"
          [showClear]="true"
          class="mb-5 exposure-dropdown"
          [readOnly]="isReadOnly"></bpt-dropdown>
      </div>
      <div class="flex flex-row gap-2">
        <p-button
          class="preparation-container-description-cancel-button w-6"
          styleClass="bpt-button-compact p-button-outlined"
          i18n-label="@@Cancel"
          label="Cancel"
          (onClick)="cancelContainerDescription()"></p-button>
        <p-button
          class="preparation-container-description-commit-button w-6"
          i18n-label="@@CommitPreparationsContainerDesc"
          styleClass="bpt-button-compact"
          label="Commit"
          (onClick)="commitContainerDescription()"
          [disabled]="isReadOnly"></p-button>
      </div>
    </bpt-slider>
  </div>
</div>
