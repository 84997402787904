import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren
} from '@angular/core';
import { Instant, LocalDate } from '@js-joda/core';
import { difference, isEqual } from 'lodash-es';
import { ClientValidationDetails } from 'model/client-validation-details';
import { Activity, Experiment } from 'model/experiment.interface';
import { User } from 'model/user.interface';
import { ExperimentWorkflowState, User as ApiModelsUser } from '../../api/models';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable, Subject, Subscription } from 'rxjs';
import { finalize, first, take } from 'rxjs/operators';
import { UserService } from 'services/user.service';
import { BaseComponent } from '../../base/base.component';
import { ClientStateService } from 'services/client-state.service';
import {
  ChangeExperimentAssignedReviewersCommand,
  ChangeExperimentAssignedAnalystsCommand,
  ChangeExperimentAuthorizationDueDateCommand,
  ChangeExperimentAssignedSupervisorsCommand,
  ChangeExperimentScheduledReviewStartDateCommand,
  ChangeExperimentScheduledStartDateCommand,
  ChangeExperimentTagsCommand,
  ChangeExperimentTitleCommand,
  ExperimentAuthorizationDueDateChangedResponse,
  ExperimentScheduledStartDateChangedResponse,
  ExperimentScheduledReviewStartDateChangedResponse,
  ExperimentTagsChangedResponse,
  ExperimentAssignedAnalystsChangedResponse,
  ExperimentAssignedSupervisorsChangedResponse,
  ExperimentAssignedReviewersChangedResponse,
  ChangeExperimentSubBusinessUnitsCommand,
  CreateExperimentCommand,
  ExperimentDataRecordNotification,
  ChangeExperimentClientsCommand,
  ChangeExperimentProjectsCommand
} from '../../api/data-entry/models';
import { TemplateType } from '../../../app/api/models';
import { TemplateSearchCommand } from '../../template-loader/models/template-search-command.interface';
import { ExperimentService } from '../services/experiment.service';
import { MappedCoverPageCommand } from './mapped.cover.page.command';
import { ActivatedRoute } from '@angular/router';
import { AuditHistoryService } from '../audit-history/audit-history.service';
import { SelectedTemplate } from '../../../../src/app/template-loader/models/selected-template';
import { ConfigurationService } from 'services/configuration.service';
import { DataRecordService } from '../services/data-record.service';
import { ELNAppConstants } from '../../shared/eln-app-constants';
import { FeatureService } from 'services/feature.service';
import { ELNFeatureFlags } from '../../../app/shared/eln-feature-flags';
import { ExperimentWarningService } from '../services/experiment-warning.service';
import { AuditHistoryDataRecordResponse } from '../../api/audit/models';
import { ExperimentOptionsHelper } from '../../shared/experiment-options-helper';
import { Tooltip } from 'primeng/tooltip';
import { ComponentCanDeactivate } from '../../../app/routing/unsaved-changes.guard';
import { ProjectLogLoaderService } from '../../services/project-log-loader.service';
import { ExperimentEventsService } from '../../api/data-entry/services';
import { UnsubscribeAll } from '../../shared/rx-js-helpers';
import { ActivityReferenceDetails } from '../model/activity-reference-details';

@Component({
  selector: 'app-cover',
  templateUrl: './cover.component.html',
  styleUrls: ['./cover.component.scss']
})
export class CoverComponent
  extends BaseComponent
  implements OnInit, OnDestroy, ComponentCanDeactivate
{
  experimentId!: string;
  user!: User;
  loading = false;
  experiment!: Experiment;
  validateExperiment!: Experiment;
  experimentNumber?: string;
  validation!: ClientValidationDetails;
  isLoading = false;
  disableCreate!: boolean;
  valueField = 'id';
  labelField = 'name';
  canDeactivateEvent: Subject<boolean> = new Subject<boolean>();
  projectPlaceholder = $localize`:@@projectPlaceholder:Select Project(s)`;
  clientPlaceholder = $localize`:@@clientPlaceholder:Select Clients(s)`;
  project = $localize`:@@project:Project(s)`;
  client = $localize`:@@client:Client(s)`;
  targetDates = $localize`target dates`;
  assignments = $localize`assignments`;
  generalInformation = $localize`general information`;
  activityReferences = $localize`:@@activityReferencesHeader: Activity References`;
  referenceTitle = $localize`:@@referenceTitle:Reference`;
  copyExperimentTooltip = $localize`:@@experimentReferenceCopyTooltip:Copy Experiment Reference`;
  copyExperimentCurrentTooltip = this.copyExperimentTooltip;
  copyActivityReferenceNumberTooltip = $localize`:@@copyActivityReferenceNumberTooltip:Copy reference number`;
  copyActivityReferenceNumberCurrentTooltip = this.copyActivityReferenceNumberTooltip
  experimentReferenceCopied = $localize`:@@experimentReferenceCopied:The Experiment Reference has been copied.`;
  activityReferenceCopied = $localize`:@@activityReferenceCopied:The Activity Reference has been copied.`;
  copyExperimentReferenceId = 'eln-experiment-reference-copy';
  copyActivityReferenceNumber = 'eln-activity-reference-number-copy';
  subBusinessUnit = $localize`Sub Business Unit(s)`;
  createdBy = $localize`created by`;
  selectedSubBusinessUnitsLabel = '';
  static readonly titleRequired: string = $localize`:@@experimentTitleRequired:Experiment Label required`;
  static readonly subBusinessUnitRequired: string = $localize`:@@experimentSubBusinessUnitsRequired:Experiment Sub Business Unit(s) required`;

  experimentWorkFlowSubscription: Subscription;
  experimentWorkFlowStateSubscription: Subscription;
  subscriptions: Subscription[] = [];
  coverPageCommand!: MappedCoverPageCommand;
  dynamicDialogRef!: DynamicDialogRef;
  showTemplateLoader = false;
  templateSearchCriteria: TemplateSearchCommand = {};
  selectedTemplate: SelectedTemplate | undefined = undefined;
  createCommand!: CreateExperimentCommand;
  selectedTemplateName: string | undefined = undefined;
  locationOptionDetails: Array<{ id: string; name: string }> = [];
  canEditExperimentInReviewStateFlag = false;
  isCoverPageReadOnly = false;
  dataRecords!: ExperimentDataRecordNotification[];
  createdByFullName = '';
  isLimsHosted = ConfigurationService.isLimsHosted;
  clientAndProjectOptions = {
    showClear: true,
    showFilter: true,
    multiSelect: true,
    showToggleAll: false,
    wrap: true
  };
  isAuditEnabled = false;
  isProjectsEditable = false;
  authorizationDue?: LocalDate;
  scheduledStart?: LocalDate;
  scheduledReviewStart?: LocalDate;
  isUserAllowedToEdit?: boolean;
  experimentActivityReferences: ActivityReferenceDetails[] = [];
  @ViewChildren(Tooltip) tooltips: QueryList<Tooltip> = new QueryList();

  constructor(
    clientStateService: ClientStateService,
    route: ActivatedRoute,
    private readonly experimentService: ExperimentService,
    private readonly experimentEventService: ExperimentEventsService,
    private readonly projectLogLoaderService: ProjectLogLoaderService,
    private readonly userService: UserService,
    private readonly dataRecordService: DataRecordService,
    private readonly auditHistoryService: AuditHistoryService,
    private readonly featureManager: FeatureService,
    private readonly experimentWarningService: ExperimentWarningService
  ) {
    super(clientStateService, route);
    this.user = { ...this.userService.currentUser };
    this.experimentWorkFlowSubscription =
      this.dataRecordService.experimentWorkFlowDataRecordReceiver.subscribe((data) => {
        const experimentWorkflowState = data.state;
        this.applyExperimentWorkflowStateEvent(experimentWorkflowState);
        this.tryToClearValidations(experimentWorkflowState);
      });
    this.experimentWorkFlowStateSubscription =
      this.experimentService.experimentWorkFlowState.subscribe((data) => {
        this.applyExperimentWorkflowStateEvent(data);
        this.tryToClearValidations(data);
      });
    this.createTemplateOptionsQuery();
  }

  ngOnInit(): void {
    this.loading = true;
    this.coverPageCommand = this.getDefaultCreateCommand();
    this.validation = new ClientValidationDetails();
    this.experimentService.templateEventService.TemplateSelectionChangedNotification(undefined);
    this.setUpPageData();
    this.isProjectsEditable = this.coverPageCommand?.clients?.length !== 0;
    this.updateFeatureFlags();
    if (this.experiment.workflowState === ExperimentWorkflowState.Setup) {
      this.validation.warnings.push(
        $localize`:@@experimentInSetupWarning:Experiment is in Setup. Please click 'Start Experiment' to enable editing of Observable Data within the Experiment.`
      );
    }
    this.isAuditEnabled = this.featureManager.isEnabled(ELNFeatureFlags.AuditExperimentCover);
    this.authorizationDue = this.coverPageCommand.authorizationDueDate;
    this.scheduledStart = this.coverPageCommand.scheduledStartDate;
    this.scheduledReviewStart = this.coverPageCommand.scheduledReviewStartDate;
    this.isUserAllowedToEdit = this.experimentWarningService.isUserAllowedToEdit;
    this.subscriptions.push(
      this.experimentService.templateEventService.ExperimentRefreshed.subscribe((experiment: Experiment)=>{
        this.populateActivityReferences(experiment.activities);
      }));
    this.setCoverPageEditability(this.experiment.workflowState);
    this.experimentService.activitySelectionChanged.next(undefined);
  }

  private tryToClearValidations(experimentWorkflowState: ExperimentWorkflowState) {
    if (experimentWorkflowState !== ExperimentWorkflowState.Setup) {
      this.validation.warnings = [];
    }
  }

  setUpPageData() {
    this.experiment = this.experimentService.currentExperiment!;
    this.setData(this.experiment);
  }

  get disableProjects() {
    return this.isProjectsEditable;
  }
  get clientOptions() {
    return this.projectLogLoaderService.getAllClients();
  }

  get projectOptionsBasedOnClientsSelected() {
    return this.projectLogLoaderService.fetchProjectsLinkedToClients(
      this.coverPageCommand.clients ?? []
    );
  }

  clientsChanged($event: string[]) {
    if (this.isUserAllowedToEdit) {
      const modifiedValue = this.checkModifiedList($event, this.coverPageCommand.clients ?? []);
      if (modifiedValue) {
        this.changeClients(modifiedValue);
      } else {
        this.isProjectsEditable = this.coverPageCommand?.clients?.length !== 0;
      }
    }
  }

  private changeClients(value: ChangeExperimentClientsCommand) {
    this.changeCoverPageRelatedClients(value);
  }

  private clientChanged(isValidResponse: boolean) {
    if (isValidResponse && this.experiment?.clients && this.experiment.clients.length > 0) {
      this.isProjectsEditable = true;
      this.projectsChanged(
        this.projectLogLoaderService.filterOutInvalidProjectSelections(
          this.experiment?.projects ?? [],
          this.experiment?.clients ?? []
        )
      );
    } else {
      this.isProjectsEditable = false;
      this.projectsChanged([]);
    }
  }
  changeCoverPageRelatedClients(value: ChangeExperimentClientsCommand): Observable<boolean> {
    this.projectsChanged(
      this.projectLogLoaderService.filterOutInvalidProjectSelections(
        this.experiment?.projects ?? [],
        this.experiment?.clients ?? []
      )
    );
    const clientsChanged = new Subject<boolean>();
    this.subscriptions.push(
      this.experimentEventService
        .experimentEventsChangeClientsPost$Json({
          body: value
        })
        .subscribe({
          next: (response) => {
            if (response.notifications.notifications.length === 0) {
              this.experiment.clients = this.experiment?.clients
                ?.concat(response.clientsChangedEventNotification.addedClients)
                ?.filter(
                  (c) => !response.clientsChangedEventNotification.removedClients.includes(c)
                );
              this.coverPageCommand.clients = this.experiment.clients;
              this.clientChanged(true);
            } else {
              this.clientChanged(false);
            }
          },
          error: () => {
            this.clientChanged(false);
          }
        })
    );
    return clientsChanged;
  }

  projectsChanged($event: string[]) {
    if (this.isUserAllowedToEdit) {
      const modifiedValue = this.checkModifiedList($event, this.coverPageCommand?.projects ?? []);
      if (modifiedValue) this.changeCoverPageProjects(modifiedValue);
    }
  }
  changeCoverPageProjects(value: ChangeExperimentProjectsCommand) {
   this.subscriptions.push(this.experimentEventService
      .experimentEventsChangeProjectsPost$Json({
        body: value
      })
      .subscribe({
        next: (response) => {
          if (response.notifications.notifications.length === 0) {
            this.experiment.projects = this.experiment?.projects
              ?.concat(response.projectsChangedEventNotification.addedProjects)
              ?.filter(
                (p) => !response.projectsChangedEventNotification.removedProjects.includes(p)
              );
            this.coverPageCommand.projects = this.experiment.projects;
          }
        }
      }));
  }
  updateFeatureFlags() {
    this.canEditExperimentInReviewStateFlag =
      this.clientStateService
        .getFeatureFlags(this.clientState)
        .find(
          (data) =>
            JSON.parse(data).CanEditExperimentInReviewState &&
            JSON.parse(data).CanEditExperimentInReviewState === true
        ) !== (null || undefined);
  }

  createTemplateOptionsQuery() {
    this.templateSearchCriteria = {
      getLatestVersion: true,
      templateTypes: `${TemplateType.Activity},${TemplateType.Module},${TemplateType.Form},${TemplateType.Table}`,
      consumingLabsiteCodes: this.user.labSiteCode
    };
  }

  initiateTitleEditing(newValue?: string) {
    if (this.isUserAllowedToEdit) {
      this.initiateTitleChanged(newValue);
      return;
    }
  }

  initiateTitleChanged(newValue?: string) {
    this.validation.removeErrorMessage(CoverComponent.titleRequired);
    if (!newValue) {
      this.validation.errors.push(CoverComponent.titleRequired);
      this.experimentService.titleIsEmpty();
      return;
    }
    if (newValue === this.experiment.title) {
      this.experimentService.titleNotChanged();
      return;
    }
    const titleChangeValue: ChangeExperimentTitleCommand = {
      experimentId: this.experiment.id,
      title: newValue
    };
    this.changeTitle(titleChangeValue);
  }

  initiateTagsEditing(newValue: string[]) {
    if (this.isUserAllowedToEdit) {
      this.initiateTagsChange(newValue);
      return;
    }
  }

  initiateTagsChange(newValue: string[]) {
    const modifiedValue = this.checkModifiedList(newValue, this.experiment.tags);
    if (modifiedValue) this.changeTags(modifiedValue);
  }

  initiateAssignedSupervisorsEditing(newValue: string[]) {
    if (this.isUserAllowedToEdit) {
      this.initiateAssignedSupervisorsChange(newValue);
      return;
    }
  }

  initiateAssignedSupervisorsChange(newValue: string[]) {
    const modifiedValue = this.checkModifiedList(
      newValue,
      this.experiment.tracking.assignedSupervisors
    );
    if (modifiedValue) this.changeAssignedSupervisors(modifiedValue);
  }

  initiateAssignedReviewersEditing(newValue: string[]) {
    if (this.isUserAllowedToEdit) {
      this.initiateAssignedReviewersChange(newValue);
      return;
    }
  }

  initiateAssignedReviewersChange(newValue: string[]) {
    const modifiedValue = this.checkModifiedList(
      newValue,
      this.experiment.tracking.assignedReviewers
    );
    if (modifiedValue) this.changeAssignedReviewers(modifiedValue);
  }

  initiateAssignedAnalystsEditing(newValue: string[]) {
    if (this.isUserAllowedToEdit) {
      this.initiateAssignedAnalystsChange(newValue);
      return;
    }
  }

  initiateAssignedAnalystsChange(newValue: string[]) {
    const modifiedValue = this.checkModifiedList(
      newValue,
      this.experiment.tracking.assignedAnalysts
    );
    if (modifiedValue) this.changeAssignedAnalysts(modifiedValue);
  }

  initiateSubBusinessUnitsEditing(newValue: string[]) {
    if (this.isUserAllowedToEdit) {
      this.initiateSubBusinessUnitsChange(newValue);
      return;
    }
  }

  initiateSubBusinessUnitsChange(newValue: string[]) {
    this.validation.removeErrorMessage(CoverComponent.subBusinessUnitRequired);
    if (newValue.length === 0) {
      this.validation.errors.push(CoverComponent.subBusinessUnitRequired);
      this.experimentService.subBusinessUnitNotSelected();
      return;
    }

    const modifiedValue = this.checkModifiedList(
      newValue,
      this.experiment.organization.subBusinessUnits
    );
    if (modifiedValue) {
      this.changeSubBusinessUnits(modifiedValue);
    } else {
      this.experimentService.subBusinessUnitNotChanged();
    }
  }

  initiateAuthorizationDueDateEditing(newValue?: LocalDate) {
    if (this.isUserAllowedToEdit) {
      this.initiateChangeAuthorizationDueDate(newValue);
      return;
    }
  }

  initiateChangeAuthorizationDueDate(newValue?: LocalDate) {
    if (isEqual(newValue, this.authorizationDue)) return;
    let command: ChangeExperimentAuthorizationDueDateCommand;
    if (newValue) {
      command = {
        experimentId: this.experiment.id,
        authorizationDate: newValue.toJSON()
      };
    } else {
      command = {
        experimentId: this.experiment.id
      };
    }
    this.changeAuthorizationDueDate(command);
    this.authorizationDue = newValue;
  }

  initiateScheduledStartDateEditing(newValue?: LocalDate) {
    if (this.isUserAllowedToEdit) {
      this.initiateChangeScheduledStartDate(newValue);
      return;
    }
  }

  initiateChangeScheduledStartDate(newValue?: LocalDate) {
    if (isEqual(newValue, this.scheduledStart)) return;

    if (newValue) {
      this.changeScheduledStartDate({
        experimentId: this.experiment.id,
        scheduledStartDate: newValue.toJSON()
      });
    } else {
      this.changeScheduledStartDate({
        experimentId: this.experiment.id
      });
    }

    this.scheduledStart = newValue;
  }

  initiateScheduledReviewStartDateEditing(newValue?: LocalDate) {
    if (this.isUserAllowedToEdit) {
      this.initiateChangeScheduledReviewStartDate(newValue);
      return;
    }
  }

  initiateChangeScheduledReviewStartDate(newValue?: LocalDate) {
    if (isEqual(newValue, this.scheduledReviewStart)) return;

    if (newValue) {
      this.changeScheduledReviewStartDate({
        experimentId: this.experiment.id,
        scheduledReviewStartDate: newValue?.toJSON()
      });
    } else {
      this.changeScheduledReviewStartDate({
        experimentId: this.experiment.id
      });
    }
    this.scheduledReviewStart = newValue;
  }

  /** This method compares the input list with existing list
   *  return an object of lists with the values that are either added or removed
   */
  getUpdatedData(
    newValue: string[],
    existingData: string[]
  ): { added: string[]; removed: string[] } {
    const updatedData: Array<string> = newValue;
    const added = difference(updatedData, existingData) ?? [];
    const removed = difference(existingData, updatedData) ?? [];
    return { added, removed };
  }

  /** This method return's a an object when the change event is valid
   *  else return's undefined to avoid event creation
   */
  checkModifiedList(
    newValue: string[],
    oldValue: string[]
  ): { experimentId: string; added: string[]; removed: string[] } | undefined {
    const { added, removed } = this.getUpdatedData(newValue, oldValue);
    if (added.length === 0 && removed.length === 0) {
      return;
    }
    return {
      experimentId: this.experiment.id,
      added: added,
      removed: removed
    };
  }

  private changeTitle(value: ChangeExperimentTitleCommand) {
    this.experimentService.changeTitle(value);
  }

  private changeAssignedReviewers(value: ChangeExperimentAssignedReviewersCommand) {
    this.processResponse(
      this.experimentService.changeAssignedReviewers(value),
      (
        experimentAssignedReviewersChangedResponse: ExperimentAssignedReviewersChangedResponse,
        experiment: Experiment
      ) => {
        experimentAssignedReviewersChangedResponse.assignedReviewersChangedEventNotification.addedReviewers.forEach(
          (x) => experiment.tracking.assignedReviewers.push(x)
        );
        experimentAssignedReviewersChangedResponse.assignedReviewersChangedEventNotification.removedReviewers.forEach(
          (x) => {
            const indexOfAssignedReviewers = experiment.tracking.assignedReviewers.findIndex(
              (u) => u.toString() === x.toString()
            );
            experiment.tracking.assignedReviewers.splice(indexOfAssignedReviewers, 1);
          }
        );
      }
    );
  }

  private changeAssignedSupervisors(value: ChangeExperimentAssignedSupervisorsCommand) {
    this.processResponse(
      this.experimentService.changeAssignedSupervisors(value),
      (
        experimentAssignedSupervisorsChangedResponse: ExperimentAssignedSupervisorsChangedResponse,
        experiment: Experiment
      ) => {
        experimentAssignedSupervisorsChangedResponse.assignedSupervisorsNotification.addedSupervisors.forEach(
          (x) => experiment.tracking.assignedSupervisors.push(x)
        );
        experimentAssignedSupervisorsChangedResponse.assignedSupervisorsNotification.removedSupervisors.forEach(
          (x) => {
            const indexOfAssignedSupervisors = experiment.tracking.assignedSupervisors.findIndex(
              (u) => u.toString() === x.toString()
            );
            experiment.tracking.assignedSupervisors.splice(indexOfAssignedSupervisors, 1);
          }
        );
      }
    );
  }

  private changeAssignedAnalysts(value: ChangeExperimentAssignedAnalystsCommand) {
    this.processResponse(
      this.experimentService.changeAssignedAnalysts(value),
      (
        experimentAssignedAnalystsChangedResponse: ExperimentAssignedAnalystsChangedResponse,
        experiment: Experiment
      ) => {
        experimentAssignedAnalystsChangedResponse.assignedAnalystsChangedEventNotification.addedAnalysts.forEach(
          (x) => experiment.tracking.assignedAnalysts.push(x)
        );
        experimentAssignedAnalystsChangedResponse.assignedAnalystsChangedEventNotification.removedAnalysts.forEach(
          (x) => {
            const indexOfAssignedAnalysts = experiment.tracking.assignedAnalysts.findIndex(
              (u) => u.toString() === x.toString()
            );
            experiment.tracking.assignedAnalysts.splice(indexOfAssignedAnalysts, 1);
          }
        );
      }
    );
  }

  private changeTags(value: ChangeExperimentTagsCommand) {
    this.processResponse(
      this.experimentService.changeTags(value),
      (experimentTagsChangedResponse: ExperimentTagsChangedResponse, experiment: Experiment) => {
        experimentTagsChangedResponse.tagsChangedEventNotification.addedTags.forEach((x) =>
          experiment.tags.push(x)
        );
        experimentTagsChangedResponse.tagsChangedEventNotification.removedTags.forEach((x) => {
          const indexOfTag = experiment.tags.findIndex((u) => u.toString() === x.toString());
          experiment.tags.splice(indexOfTag, 1);
        });
      }
    );
  }

  private changeSubBusinessUnits(value: ChangeExperimentSubBusinessUnitsCommand) {
    this.experimentService.changeSubBusinessUnits(value);
  }

  private changeAuthorizationDueDate(value: ChangeExperimentAuthorizationDueDateCommand) {
    this.processResponse(
      this.experimentService.changeAuthorizationDueDate(value),
      (
        experimentAuthorizationDueDateChangedResponse: ExperimentAuthorizationDueDateChangedResponse,
        experiment: Experiment
      ) =>
        (experiment.tracking.authorizationDueDate =
          experimentAuthorizationDueDateChangedResponse.authorizationDueDateChangedEventNotification.authorizationDueDate)
    );
  }

  private changeScheduledStartDate(value: ChangeExperimentScheduledStartDateCommand) {
    this.processResponse(
      this.experimentService.changeScheduledStartDate(value),
      (
        experimentScheduledStartDateChangedResponse: ExperimentScheduledStartDateChangedResponse,
        experiment: Experiment
      ) =>
        (experiment.tracking.scheduledStartDate =
          experimentScheduledStartDateChangedResponse.scheduledStartDateChangedEventNotification.scheduledStartDate)
    );
  }

  private changeScheduledReviewStartDate(value: ChangeExperimentScheduledReviewStartDateCommand) {
    this.processResponse(
      this.experimentService.changeScheduledReviewStartDate(value),
      (
        experimentScheduledReviewStartDateChangedResponse: ExperimentScheduledReviewStartDateChangedResponse,
        experiment: Experiment
      ) =>
        (experiment.tracking.scheduledReviewStartDate =
          experimentScheduledReviewStartDateChangedResponse.scheduledReviewStartDateChangedEventNotification.scheduledReviewStartDate)
    );
  }

  processResponse<T>(
    obs: Observable<T>,
    acknowledgment: (response: T, experiment: Experiment) => void
  ) {
    obs
      .pipe(
        first(),
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (data: T) => {
          acknowledgment(data, this.experiment);
        },
        (error: any) => {
          this.validation.errorTitle = $localize`:@@receivedErrorFromServer:Received following error from server`;
        },
        () => {
          this.isLoading = false;
        }
      );
  }

  private getDefaultCreateCommand(): MappedCoverPageCommand {
    return {
      assignedAnalysts: [],
      assignedReviewers: [],
      assignedSupervisors: [],
      clients: [],
      projects: [],
      authorizationDueDate: undefined,
      createdBy: undefined,
      createdOn: undefined,
      scheduledReviewStartDate: undefined,
      scheduledStartDate: undefined,
      tags: [],
      status: undefined,
      title: undefined
    };
  }

  private getStatusString(state: ExperimentWorkflowState): string {
    const statusStrings = {
      setup: $localize`:@@setupState:Setup`,
      inProgress: $localize`:@@inProgressState:In Progress`,
      inReview: $localize`:@@inReviewState:In Review`,
      inCorrection: $localize`:@@inCorrectionState:In Correction`,
      cancelled: $localize`:@@cancelledState:Cancelled`,
      authorized: $localize`:@@authorizedState:Authorized`,
      restored: $localize`:@@restoredState:Restored`
    };
    return statusStrings[state];
  }

  setData(experimentData: Experiment) {
    this.populateActivityReferences(experimentData.activities);
    const trackingData = experimentData.tracking;
    this.coverPageCommand.title = experimentData.title;
    this.coverPageCommand.status = this.getStatusString(experimentData.workflowState);
    this.coverPageCommand.assignedSupervisors = [...new Set(trackingData.assignedSupervisors)];
    this.coverPageCommand.assignedAnalysts = [...new Set(trackingData.assignedAnalysts)];
    this.coverPageCommand.assignedReviewers = [...new Set(trackingData.assignedReviewers)];
    this.coverPageCommand.createdBy = trackingData.createdBy;
    this.coverPageCommand.clients = experimentData.clients;
    this.coverPageCommand.projects = experimentData.projects;
    this.coverPageCommand.createdOn = Instant.parse(trackingData.createdOn);
    this.coverPageCommand.scheduledStartDate = this.parseToLocalDate(
      trackingData.scheduledStartDate
    );
    this.coverPageCommand.scheduledReviewStartDate = this.parseToLocalDate(
      trackingData.scheduledReviewStartDate
    );
    this.coverPageCommand.authorizationDueDate = this.parseToLocalDate(
      trackingData.authorizationDueDate
    );
    this.coverPageCommand.tags = experimentData.tags;
    this.coverPageCommand.sbu = experimentData.organization.subBusinessUnits;
  }

  private populateActivityReferences(activities: Activity[]) {
    this.experimentActivityReferences = activities.flatMap((a) => {
      return {
        activityTitle: a.itemTitle,
        activityReferenceNumber: a.activityReferenceNumber
      } as ActivityReferenceDetails;
    }).reverse();
  }

  private parseToLocalDate(text: string | undefined): LocalDate | undefined {
    return text ? LocalDate.parse(text) : undefined;
  }

  /**
   * Gets called to load audit history dialog
   */
  loadAuditHistoryDialog() {
    this.isLoading = true;
    this.auditHistoryService.loadExperimentCoverAuditHistory(this.experiment.id).subscribe({
      next: this.getRecipeBlobsDetails.bind(this)
    });
  }

  /**
   * Gets called to get recipe blob details before loading audit history dialog
   */
  private getRecipeBlobsDetails(records: AuditHistoryDataRecordResponse) {
    this.subscriptions.push(this.experimentService.areRecipeBlobDetailsFetched()
    .pipe(take(1))
    .subscribe({
      next: ((response) => {
        if (response) this.bindDataToAuditHistoryDialog(records);
      })
    }));
    this.experimentService.addRecipeAppliedEventBlobDetailsToCache(records);
  }

  private bindDataToAuditHistoryDialog(data: AuditHistoryDataRecordResponse) {
    this.isLoading = false;
    this.dynamicDialogRef = this.auditHistoryService.showAuditDialog(
      data.dataRecords,
      this.experiment.experimentNumber.concat(
        ELNAppConstants.WhiteSpace,
        $localize`:@@experiment:Experiment`
      )
    );
  }

  private applyExperimentWorkflowStateEvent(data: ExperimentWorkflowState): void {
    this.coverPageCommand.status = this.getStatusString(data);
    this.setCoverPageEditability(data);
    this.isUserAllowedToEdit = this.experimentWarningService.isUserAllowedToEdit;
  }

  setCoverPageEditability(data: ExperimentWorkflowState) {
    const workflowStateIndicatesReadOnly =
      ExperimentOptionsHelper.getOptionsFromRoute().previewMode ||
      ExperimentService.isExperimentAuthorizedOrCancelled(data) ||
      (data === ExperimentWorkflowState.InReview && !this.userService.hasSupervisorOrReviewerRights());
    this.isCoverPageReadOnly = workflowStateIndicatesReadOnly || this.readOnly;
  }

  ngOnDestroy(): void {
    if (this.dynamicDialogRef) {
      this.dynamicDialogRef.close();
    }
    this.experimentWorkFlowSubscription.unsubscribe();
    this.experimentWorkFlowStateSubscription.unsubscribe();
    UnsubscribeAll(this.subscriptions);
  }

  updateSelectedSubBusinessUnitsLabel(selectedSubBusinessUnitsLabel: string) {
    this.selectedSubBusinessUnitsLabel = selectedSubBusinessUnitsLabel;
  }

  @HostListener('window:beforeunload')
  canDeactivate(): boolean | Observable<boolean> {
    setTimeout(() => {
      this.canDeactivateEvent.next(true);
    }, 250);
    return this.canDeactivateEvent.asObservable();
  }

  userListFetched(users: Array<ApiModelsUser>): void {
    this.setCreatedByFullName(users);
  }

  private setCreatedByFullName(users: Array<ApiModelsUser>): void {
    if (
      this.createdByFullName !== '' &&
      this.createdByFullName.toLowerCase() !==
        (this.coverPageCommand.createdBy && this.coverPageCommand.createdBy.toLowerCase())
    ) {
      return;
    }
    this.createdByFullName =
      users.find(
        (u) =>
          this.coverPageCommand.createdBy &&
          u.puid.toLowerCase() === this.coverPageCommand.createdBy.toLowerCase()
      )?.fullName || (this.coverPageCommand.createdBy as string).toUpperCase();
  }

  copyReferenceToClipboard() {
    this.writeToClipboard(this.experiment.experimentNumber).then((_) => {
      this.copyExperimentCurrentTooltip = this.experimentReferenceCopied;
      this.getTooltipById(this.copyExperimentReferenceId)?.activate();
    });
  }

  async copyActivityReferenceToClipboard(activityReferenceNumber: string): Promise<void> {
    await this.writeToClipboard(activityReferenceNumber);
    this.copyActivityReferenceNumberCurrentTooltip = this.activityReferenceCopied;
    this.getTooltipById(`${this.copyActivityReferenceNumber}-${activityReferenceNumber}`)?.activate();
  }

  getTooltipById(nativeElementId: string): Tooltip | undefined {
    return this.tooltips.find((tooltip) => tooltip.el.nativeElement.id === nativeElementId);
  }

  writeToClipboard(data: string): Promise<void> {
    return navigator.clipboard.writeText(data);
  }
}
