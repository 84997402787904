import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ExperimentCollaboratorsService } from 'services/experiment-collaborators.service';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit, OnDestroy {
  private experimentCollaboratorsSubscription?: Subscription;
  users: any[] = [];
  constructor(private readonly experimentCollaboratorsService: ExperimentCollaboratorsService) {}
  ngOnDestroy(): void {
    this.experimentCollaboratorsSubscription?.unsubscribe();
  }

  ngOnInit(): void {
    //Subscribe to get the list of connected users
    this.experimentCollaboratorsSubscription =
      this.experimentCollaboratorsService.experimentUsers.subscribe((userList) => {
        this.users = userList;
      });
  }
}
