import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ExperimentNotificationService } from "services/experiment-notification.service";

@Injectable({
    providedIn: 'root'
  })
  export class SignalRConnectionInterceptor implements HttpInterceptor {
    constructor(private readonly signalR: ExperimentNotificationService) {}
  
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        var modified = req;
        var connectionId = this.signalR.hubConnection?.connectionId;
        if (connectionId)
        {
            modified = req.clone({
            headers: req.headers.set('X-User-ConnectionId', connectionId)
            });
        }
        return next.handle(modified);
    }
}
