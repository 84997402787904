import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import {
  EmpowerColumnType,
  NodeType,
  FieldType,
  FormItemType,
  KeyColumnType,
  ActivityOutputChromatographyResultSet,
  ChromatographyDataPeakRow,
  ChromatographyDataCell} from '../../../api/models';
import { Experiment, Form } from '../../../model/experiment.interface';
import {
  BptGridComponent,
  BptGridMode,
  ColumnDefinition,
  ColumnType} from 'bpt-ui-library/bpt-grid';
import { ChromatographyService } from '../../../api/services';
import { ExperimentService } from '../../services/experiment.service';
import { OutputEmpowerService } from '../../services/output-empower.service';
import { EmpowerService } from '../../../api/instrument-integration/services';
import { Column } from '../../../api/instrument-integration/models';
import { ActivityOutputEventsService } from '../../../api/data-entry/services';
import { ConfirmationService, Message, MessageService } from 'primeng/api';
import { ElnProgressSpinnerService } from '../../../eln-progress-spinner-module/eln-progress-spinner.service';
import { ExperimentWarningService } from '../../services/experiment-warning.service';
import { Subscription } from 'rxjs';
import { DataRecordService } from '../../services/data-record.service';
import { ExperimentNotificationService } from '../../../services/experiment-notification.service';
import { LockType } from '../../../model/input-lock.interface';
import { ActivityOutputCollaboratorService } from '../../services/output-collaborator-service';
import { AuditHistoryService } from '../../audit-history/audit-history.service';
import { AuditHistoryDataRecordResponse, ExperimentEventType } from '../../../api/audit/models';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { ActivityOutputChromatographyResultSetSummary } from '../../../api/models/activity-output-chromatography-result-set-summary';
@Component({
  selector: 'app-outputs-imported-resultset',
  templateUrl: './outputs-imported-resultset.component.html',
  styleUrls: ['./outputs-imported-resultset.component.scss']
})
export class OutputsImportedResultsetComponent implements OnInit, OnDestroy {
  @Input() resultSetSummary!: ActivityOutputChromatographyResultSetSummary;
  resultSet!: ActivityOutputChromatographyResultSet;
  @Output() SendResultSetEvent = new EventEmitter<ActivityOutputChromatographyResultSetSummary>();
  @Output() ResultSetRefresh = new EventEmitter<ActivityOutputChromatographyResultSet>();
  @Output() RefreshResultSetButtonId = new EventEmitter<string>();
  @ViewChild('PeaksGrid') peaksGrid!: BptGridComponent;
  @ViewChild('ResultsGrid') resultsGrid!: BptGridComponent;
  subscriptionList: Subscription[] = [];
  resultSetForm!: Form;
  peaksTable: any[] = [];
  resultsTable: any[] = [];
  tableTitle = '';
  isGridRefreshing = false;
  peakColumns: string[] = [];
  resultColumns: string[] = [];
  gridMode: BptGridMode = BptGridMode.dataView;
  isUserAuthenticated = false;
  isCollaborator = false;
  commonColumnNames: string[] = ['ResultId', 'SampleName', 'InjectionId', 'Sample'];
  successMessages: Message[] = [];
  experiment: Experiment | undefined;
  isUserAllowedToEdit?: boolean;
  noCurrentExperimentFound = $localize`:@@noCurrentExperimentFound:No current experiment found`;
  dynamicDialogRef!: DynamicDialogRef;
  isHistoryLoading = false;

  peaksColumnDefinitions: ColumnDefinition[] = [
    {
      label: '',
      field: 'rowIndex',
      columnType: ColumnType.index,
      hidden: true,
      showInColumnChooser: false,
      editable: false,
      valueGetter: (params: any) => {
        return params.getValue('number');
      }
    },
    {
      label: 'Injection ID',
      field: 'InjectionId',
      cellRenderer: 'agGroupCellRenderer',
      showRowGroup: true,
      rowGroup: true,
      cellRendererParams: { suppressCount: true }
    },
    {
      label: 'Sample Name',
      field: 'SampleName',
      columnType: ColumnType.string,
      sortable: true
    },
    {
      label: 'Result ID',
      field: 'ResultId',
      columnType: ColumnType.string,
      sortable: true
    }
  ];

  resultsColumnDefinitions: any[] = [
    {
      label: '',
      field: 'rowIndex',
      columnType: ColumnType.index,
      hidden: true,
      showInColumnChooser: false,
      editable: false,
      valueGetter: (params: any) => {
        return params.getValue('number');
      }
    },
    {
      label: 'Injection ID',
      field: 'InjectionId',
      cellRenderer: 'agGroupCellRenderer',
      showRowGroup: true,
      cellRendererParams: { suppressCount: true }
    },
    {
      label: 'Sample Name',
      field: 'SampleName',
      columnType: ColumnType.string,
      sortable: true
    },
    {
      label: 'Sample',
      field: 'Sample',
      columnType: ColumnType.string,
      isKeyColumn: true,
      allowedKeyColumnTypes: [KeyColumnType.Samples],
      sortable: true,
      enforceColumnUniqueness: false
    },
    {
      label: 'Result ID',
      field: 'ResultId',
      columnType: ColumnType.string,
      sortable: true
    }
  ];

  constructor(
    readonly outputEmpowerService: OutputEmpowerService,
    readonly chromatographyService: ChromatographyService,
    readonly empowerService: EmpowerService,
    readonly activityOutputEventsService: ActivityOutputEventsService,
    readonly experimentService: ExperimentService,
    readonly messageService: MessageService,
    readonly elnProgressSpinnerService: ElnProgressSpinnerService,
    private readonly experimentWarningService: ExperimentWarningService,
    private readonly confirmationService: ConfirmationService,
    private readonly experimentNotificationService: ExperimentNotificationService,
    private readonly activityOutputCollaboratorService: ActivityOutputCollaboratorService,
    private readonly renderer: Renderer2,
    private readonly auditHistoryService: AuditHistoryService
  ) {}

  ngOnInit(): void {
    this.experiment = this.experimentService.currentExperiment;
    this.loadResultSet();
  }

  private loadResultSet() {
    this.subscriptionList.push(
      this.outputEmpowerService
        .GetResultSetData(
          this.resultSetSummary.chromatographyDataId,
          this.resultSetSummary.experimentId
        )
        .subscribe({
          next: this.initializeViewForResult.bind(this)
        })
    );
  }

  private initializeViewForResult(resultSet: ActivityOutputChromatographyResultSet): void {
    this.resultSet = resultSet;
    this.handleSubscriptions();
    this.formatResultSet();
    this.subscriptionList.push(this.outputEmpowerService.isRefreshAuthenticated.subscribe((isUserAuthenticated) => {
      this.showLatestResultSetData(isUserAuthenticated);
    }));
    this.isUserAllowedToEdit = this.experimentWarningService.isUserAllowedToEdit;
  }

  private handleSubscriptions() {
    this.subscriptionList.push(
      this.outputEmpowerService
        .GetEmpowerFullEventGirdFor(
          this.experimentService.currentActivityId,
          this.resultSet.chromatographyDataId
        )
        .currentResultSetEvents.refreshed.subscribe({
          next: this.updateResultSetBindGrid.bind(this)
        })
    );

    this.subscriptionList.push(
      this.experimentNotificationService.inputLockReceiver.subscribe((lockList) => {
        lockList.forEach((lock) => {
          this.activityOutputCollaboratorService.lockActionItems(lock, this.renderer);
        });
      })
    );

    this.subscriptionList.push(this.experimentService._isCurrentUserCollaboratorSubject$.subscribe({
      next: (isCollaborator: boolean) => {
        if (isCollaborator) {
          this.isCollaborator = isCollaborator;
        }
      }
    }));
  }

  updateResultSetBindGrid(latestResultSet: ActivityOutputChromatographyResultSet): void {
    this.resultSet = latestResultSet;
    this.ResultSetRefresh.emit(this.resultSet);
    this.resultsGrid.dataSource = [];
    this.peaksGrid.dataSource = [];
    this.populateValuesToGrid();
    this.resultsGrid.dataSource = this.resultsTable;
    this.peaksGrid.dataSource = this.peaksTable;
    this.resultsGrid?.gridApi?.setGridOption('rowData', this.resultsTable);
    this.resultsGrid?.gridApi?.refreshCells({ force: true });
    this.peaksGrid?.gridApi?.setGridOption('rowData', this.peaksTable);
    this.peaksGrid?.gridApi?.refreshCells({ force: true });
    this.isGridRefreshing = false;
  }

  ngOnDestroy() {
    this.subscriptionList.forEach((s) => s.unsubscribe());
    if (this.dynamicDialogRef) {
      this.dynamicDialogRef.close();
    }
  }

  canRefreshResultSet() {
    if (!this.experimentService.currentExperiment) {
      throw new Error(this.noCurrentExperimentFound);
    }
    this.activityOutputCollaboratorService.sendInputStatus(
      LockType.lock,
      this.experimentService.currentExperiment.id,
      [this.activityOutputCollaboratorService.refreshIdentifier],
      this.resultSet.chromatographyDataId
    );
    this.RefreshResultSetButtonId.emit(this.resultSet.chromatographyDataId);
    if (this.isUserAllowedToEdit) {
      this.refreshResultSet();
      return;
    }
}

  canDeleteResultSet() {
    OutputEmpowerService.removedChromatographyData.set(
      this.resultSet.chromatographyDataId,
      this.resultSet.resultSetId
    );
    if (!this.experimentService.currentExperiment) {
      throw new Error(this.noCurrentExperimentFound);
    }
    this.activityOutputCollaboratorService.sendInputStatus(
      LockType.lock,
      this.experimentService.currentExperiment.id,
      [this.activityOutputCollaboratorService.deleteIdentifier],
      this.resultSet.chromatographyDataId
    );
    if (this.isUserAllowedToEdit) {
      this.configureConfirmationDialog();
       return;
    }
  }

  showLatestResultSetData(isUserAuthenticated: boolean) {
    if (isUserAuthenticated !== this.isUserAuthenticated) {
      this.isUserAuthenticated = isUserAuthenticated;
      if (this.isUserAuthenticated && this.isGridRefreshing) {
        this.validateResultIdAndCreateRefreshRequest();
        this.outputEmpowerService.isRefreshAuthenticated.next(false);
      }
    }
  }

  isValidResultSetId(id: string | undefined | null): asserts id is string {
    if (!id || id === '') {
      throw new Error('Invalid resultSetId');
    }
  }

  validateResultIdAndCreateRefreshRequest() {
    this.isValidResultSetId(this.resultSet.resultSetId);
    this.outputEmpowerService
      .queueResultSetRefreshRequest(this.resultSet.chromatographyDataId, {
          projectName: this.outputEmpowerService.empowerData.project,
          database: this.outputEmpowerService.empowerData.database?.label || '',
          resultSetId: this.resultSet.resultSetId,
          resultColumns: this.resultColumns,
          peakColumns: this.peakColumns,
          sampleNames: this.getScannedItemKeysByActivity(),
          activityId: this.resultSet.activityId,
          experimentId:this.resultSet.experimentId
        }
      );
  }

  getScannedItemKeysByActivity(): string[] {
    return this.experimentService.getScannedInputKeys(this.experimentService.currentActivityId);
  }

  showRefreshedNotification() {
    const messageObj: Message = {
      key: 'notification',
      severity: 'success',
      summary: $localize`:@@empowerRefreshComplete: Empower Refresh Complete`,
      detail: $localize`:@@empowerRefreshCompleteMessageDetails:ResultSet ID "${this.resultSet.resultSetId}" was refreshed.`,
      sticky: false
    };
    this.messageService.add(messageObj);
  }

  configureConfirmationDialog() {
    const confirmationMessage = $localize`:@@removeResultSetConfirmation:Are you sure you want to remove
                                this Result Set? The Result Set can be restored by re-importing it from Empower.`;
    this.confirmWorkflowStateTransition(confirmationMessage);
  }
  removeResultSet(resultSet: ActivityOutputChromatographyResultSet) {
    this.activityOutputEventsService
      .activityOutputEventsRemoveChromatographyDataPost$Json({
        body: {
          activityId: resultSet.activityId,
          chromatographyDataId: resultSet.chromatographyDataId,
          experimentId: resultSet.experimentId
        }
      })
      .subscribe({
        error: (errorResponse) => {
          if (errorResponse.error.notifications) {
            this.outputEmpowerService.empowerResponse =
              errorResponse.error.notifications.notifications[0].message;
          }
        }
      });
    this.unlockInputLocks();
    this.makeUserAsCollaborator(true);
    this.SendResultSetEvent.emit(this.resultSetSummary);
  }

  private confirmWorkflowStateTransition(message: string) {
    this.confirmationService.confirm({
      message: `${message}`,
      key: 'outputConfirmationDialog',
      header: $localize`:@@confirmationHeader:Confirmation`,
      acceptVisible: true,
      acceptLabel: $localize`:@@confirmationOk:OK`,
      rejectVisible: true,
      rejectLabel: $localize`:@@confirmationCancel:Cancel`,
      closeOnEscape: true,
      dismissableMask: false,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.removeResultSet(this.resultSet);
      },
      reject: () => {
        this.unlockInputLocks();
      }
    });
  }

  private makeUserAsCollaborator(currentUserBecameCollaborator: boolean) {
    if (!this.isCollaborator && currentUserBecameCollaborator) {
      this.experimentService._isCurrentUserCollaboratorSubject$.next(currentUserBecameCollaborator);
    }
  }

  unlockInputLocksforRefresh() {
    if (!this.experimentService.currentExperiment) {
      throw new Error(this.noCurrentExperimentFound);
    }
    this.activityOutputCollaboratorService.sendInputStatus(
      LockType.unlock,
      this.experimentService.currentExperiment.id,
      [this.activityOutputCollaboratorService.refreshIdentifier],
      this.resultSet.chromatographyDataId
    );
  }

  unlockInputLocks() {
    if (!this.experimentService.currentExperiment) {
      throw new Error(this.noCurrentExperimentFound);
    }
    this.activityOutputCollaboratorService.sendInputStatus(
      LockType.unlock,
      this.experimentService.currentExperiment.id,
      [this.activityOutputCollaboratorService.deleteIdentifier],
      this.resultSet.chromatographyDataId
    );
  }


  getFormattedPeakRows(peakValues: Column[][]): ChromatographyDataPeakRow[] {
    const formattedPeakValues: ChromatographyDataPeakRow[] = [];
    peakValues.forEach((peakRows) => {
      const formattedPeakRows: ChromatographyDataCell[] = [];
      peakRows.forEach((peakRow) => {
        formattedPeakRows.push({
          name: peakRow.name,
          type: EmpowerColumnType.Peaks,
          value: peakRow.value
        });
      });
      formattedPeakValues.push({ peakValues: formattedPeakRows });
    });
    return formattedPeakValues;
  }
  getFormattedResultRows(resultValues: Column[]): ChromatographyDataCell[] {
    const formattedResultValues: ChromatographyDataCell[] = [];
    resultValues.forEach((resultValue) => {
      formattedResultValues.push({
        name: resultValue.name,
        type: EmpowerColumnType.Results,
        value: resultValue.value
      });
    });
    return formattedResultValues;
  }

  formatResultSet(): void {
    if (this.resultSet) {
      this.tableTitle =
        $localize`:@@empowerTableTitle:Empower Results - ` + this.resultSet.resultSetId;
      this.resultSetForm = this.getFormattedResultSetForm();
      this.resultSetForm.formId = `EmpowerResultSet||${this.resultSet.activityId}||${this.resultSet.chromatographyDataId}||${this.resultSet.resultSetId}`;
      this.resultSetForm.experimentId = this.resultSet.experimentId;
      this.setColumnDefinitions();
      this.populateValuesToGrid();
    }
  }

  populateValuesToGrid() {
    this.resultsTable = [];
    this.peaksTable = [];
    this.resultSet.resultSetData?.forEach((result) => {
      const resultRow: { [key: string]: string | any[] } = {};
      result.resultValues.forEach((resultValue) => {
        resultRow[resultValue.name] = resultValue.value;
      });
      this.resultsTable.push(resultRow);
      result.peakRows?.forEach((peakValue) => {
        const peakRow: { [key: string]: string } = {};
        result.resultValues.forEach((resultValue) => {
          if (this.commonColumnNames.includes(resultValue.name)) {
            peakRow[resultValue.name] = resultValue.value;
          }
        });
        peakValue.peakValues.forEach((subColumn) => {
          peakRow[subColumn.name] = subColumn.value;
        });
        this.peaksTable.push(peakRow);
      });
    });
  }

  setColumnDefinitions() {
    if (this.resultSet.resultSetData && this.resultSet.resultSetData.length > 0) {
      this.resultSet.resultSetData[0]?.resultValues.forEach((resultValue) => {
        if (!this.commonColumnNames.includes(resultValue.name)) {
          this.resultColumns.push(resultValue.name);
          this.resultsColumnDefinitions?.push({
            field: resultValue.name,
            label: resultValue.name
          });
        }
      });
      if (this.resultSet.resultSetData[0].peakRows) {
        this.resultSet.resultSetData[0].peakRows[0]?.peakValues.forEach((peakValue) => {
          this.peakColumns.push(peakValue.name);
          this.peaksColumnDefinitions?.push({
            field: peakValue.name,
            label: peakValue.name
          });
        });
      }
    }
  }

  refreshResultSet() {
    this.isGridRefreshing = true;
    this.outputEmpowerService.isRefreshEvent = true;
    this.outputEmpowerService.empowerDatabaseOptions = [{ label: this.resultSet.databaseName }];
    this.outputEmpowerService.empowerData.database = { label: this.resultSet.databaseName };
    this.outputEmpowerService.empowerData.project = this.resultSet.projectFilePath || '';
    this.outputEmpowerService.canShowEmpowerLogin = true;
    this.outputEmpowerService.empowerData.projectReadOnly = true;
    this.outputEmpowerService.empowerData.databaseReadOnly = true;
    this.outputEmpowerService.isRefreshEvent = true;
  }

  getFormattedResultSetForm(): Form {
    return {
      itemType: NodeType.Form,
      itemTitle:
        $localize`:@@empowerFormTitle:Empower Imported Results - ` + this.resultSet.resultSetId,
      isHidden: false,
      value: {
        project_file_path: `${this.resultSet.projectFilePath}\\${this.resultSet.resultSetId}`,
        result_set_id: this.resultSet.resultSetId
      },
      fieldDefinitions: [
        {
          itemType: FormItemType.Field,
          fieldType: FieldType.Textbox,
          label: 'Project File Path',
          maxLength: 100,
          disabled: true,
          fieldAttributes: {},
          field: 'project_file_path',
          row: 1,
          column: 1,
          xPct: 0,
          yPct: 0,
          widthPct: 80,
          heightPct: 0,
          widthType: 'auto'
        },
        {
          itemType: FormItemType.Field,
          fieldType: FieldType.Textbox,
          label: 'Result Set Id',
          maxLength: 6,
          fieldAttributes: {},
          field: 'result_set_id',
          disabled: true,
          row: 1,
          column: 2,
          xPct: 0,
          yPct: 0,
          widthPct: 0,
          heightPct: 0,
          widthType: 'auto'
        }
      ],
      rules: [],
      useLatestTemplateVersionAvailable: false,
      disabled: true,
      templateId: '',
      formId: '',
      experimentId: ''
    };
  }

  performResultsetOperation(operation: string) {
    if (!this.outputEmpowerService.disableEmpowerOperations) {
      if (operation === 'remove') {
        this.canDeleteResultSet();
      } else {
        this.canRefreshResultSet();
      }
    }
  }

  loadHistoryDialog() {
    this.isHistoryLoading = true;
    this.auditHistoryService
      .loadActivityOutputsAuditHistory(this.resultSet.experimentId, this.resultSet.activityId)
      .subscribe({
        next: this.bindDataToAuditHistoryDialog.bind(this)
      });
  }

  private bindDataToAuditHistoryDialog(data: AuditHistoryDataRecordResponse) {
    this.isHistoryLoading = false;
    this.dynamicDialogRef = this.auditHistoryService.showAuditDialog(
      data.dataRecords.filter(
        (dr) =>
          (dr.eventContext.eventType === ExperimentEventType.ChromatographyDataImported ||
            dr.eventContext.eventType === ExperimentEventType.ChromatographyDataRefreshed) &&
          (dr as any).chromatographyDataId === this.resultSet.chromatographyDataId
      ),
      this.tableTitle
    );
  }
}
