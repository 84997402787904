import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
@Component({
  selector: 'app-boolean-based-warning-icon-renderer',
  template: `
            <div class = "boolean-based-warning-icon">  
            <span>{{ isActive }}
            </span>      
            <span *ngIf="showWarningIcon" class = "icon-alignment">
            <img
            class= "warning-icon-img"
            pTooltip = {{params.toolTipMessage}}
            tooltipPosition="bottom"
            src="../../../assets/eln-assets/warning.svg" alt=""  width="13px" height="13px"/></span>

            </div>
            `,
  styles: [`.boolean-based-warning-icon {
              display: flex;
              justify-content: space-between;  
              align-items: center;          
            }
            .warning-icon-img {
              cursor: pointer;

            }
            .icon-alignment {
              padding-top: 2px;
            }
            `]

})

export class BooleanBasedWarningIconRendererComponent implements ICellRendererAngularComp {
  public refresh(): boolean {
    return false;
  }

  public params!: any;
  showWarningIcon = false;
  isActive? : string;
  public agInit(params: ICellRendererParams): void {

    this.params = params;
    this.isActive = this.params.value.toString().toLowerCase();

    this.showWarningIcon = this.isActive === 'false';
  }
}
