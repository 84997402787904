import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ExperimentNotificationService } from 'services/experiment-notification.service';
import { ExperimentNodeOperationType } from '../model/experiment-node-operation-type';
import { ExperimentFlagRaisedNotification } from '../model/ExperimentFlagRaisedNotification';
import { ExperimentFlagUnraisedNotification } from '../model/ExperimentFlagUnraisedNotification';

@Injectable()
export class NonDataRecordService {
  public readonly experimentFlagRaisedNotification = new Subject<ExperimentFlagRaisedNotification>();
  public readonly experimentFlagUnraisedNotification = new Subject<ExperimentFlagUnraisedNotification>();

  constructor(
    private readonly experimentNotificationService: ExperimentNotificationService,
  ) {
    this.experimentNotificationService.nonDataRecordReceiver.subscribe((data: any) => 
      this.applyNotification(data)
    )
  }

  private applyNotification( nonDataRecordNotification: any ) {
    switch(nonDataRecordNotification.eventContext.operationType) {
      case ExperimentNodeOperationType.ExperimentFlagRaised:
        this.experimentFlagRaisedNotification.next(nonDataRecordNotification);
        break;
      case ExperimentNodeOperationType.ExperimentFlagUnraised:
        this.experimentFlagUnraisedNotification.next(nonDataRecordNotification);
        break;
    }
  }
}
