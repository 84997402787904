import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Logger } from 'services/logger.service';
import { NotificationDetails } from '../../api/models';
import { NotificationResult } from '../../../app/api/models/notification-result';
import { ElnProgressSpinnerService } from '../../../app/eln-progress-spinner-module/eln-progress-spinner.service';
import { NotificationType } from '../../api/data-entry/models';

interface ServerMessage {
  key: string;
  message: string;
  type: NotificationType;
}

/**
 * Handles server exceptions, client exceptions and notifications returned from the server.
 */
@Injectable({
  providedIn: 'root'
})
export class ExceptionInterceptor implements HttpInterceptor {
  public static readonly NotificationsToSkip: string[] = ['TemplateCannotBePublished', 'invalidRecipe'];
  public static readonly EndpointsToIgnoreErrors: string[] = [
    `user-preferences/eln-favorite-templates`,
    `api/empower/quarter-and-resultsets`,
    `api/empower/column-names`,
    `/instruments/`
  ];

  constructor(
    private readonly messageService: MessageService,
    private readonly logger: Logger,
    private readonly elnProgressSpinnerService: ElnProgressSpinnerService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          if (((event as any).body) && ((event as any).body.notifications)) {
            var notifications = (event as any).body.notifications as NotificationResult;
            var messages = this.getNotifications(notifications, req);
            if (messages.length > 0) this.addMessages(messages);
          }
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        this.elnProgressSpinnerService.Hide();
        this.logger.logError(error);
        if (ExceptionInterceptor.ignoreError(req)) {
          return throwError(() => error);
        }
        const errorMsg = this.getErrorMessage(error, req);
        if (errorMsg === error) {
          return throwError(() => error);
        }
        this.addMessages(errorMsg as ServerMessage[]);
        return throwError(() => error);
      })
    );
  }

  private addMessages(messages: ServerMessage[]) {
    messages.forEach(m => {
      var severity = (m.type === NotificationType.Error || m.type === NotificationType.Validation) ? "error"
        : m.type === NotificationType.Warning ? "warn" : "info";
      this.messageService.add({ key: 'notification', severity: severity, summary: m.key, detail: m.message, sticky: true })
    }
    );
    document.body.click();
  }

  private getErrorMessage(errorResponse: HttpErrorResponse, request: HttpRequest<any>): ServerMessage[] | HttpErrorResponse {
    var notifications: ServerMessage[] = [];

    var notification: ServerMessage = {
      key: 'unknown',
      message: $localize`:@@defaultServerApiError:Something went wrong!. Please contact the IT administrator.`,
      type: NotificationType.Information
    };

    if (errorResponse.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      notification.key = 'Client Error';
      notification.message = `An error occurred: ${errorResponse.error?.message}`;
      ExceptionInterceptor.setErrorWhenMessageForStatusCodeExists(notification, 0, request);
      notifications.push(notification);
    } else {
      // The backend returned an unsuccessful response code. server error
      const notificationResult: NotificationResult = errorResponse?.error
        ?.notifications as NotificationResult;
      var messages = this.getNotifications(notificationResult, request, errorResponse);

      if (messages?.length === 0) return errorResponse;
      return messages;
    }
    return notifications;
  }

  private getNotifications(notificationResult: NotificationResult, request: HttpRequest<any>, errorResponse?: HttpErrorResponse): ServerMessage[] {
    var notifications: ServerMessage[] = [];
    if (notificationResult && notificationResult.notifications.length > 0) {
      notificationResult.notifications.forEach(n => {
        if (ExceptionInterceptor.NotificationsToSkip.includes(n.translationKey)) {
          return;
        }

        var displayKey = (n.notificationType === NotificationType.Error || n.notificationType === NotificationType.Validation) ? "error:Error"
          : n.notificationType === NotificationType.Warning ? "warning:Warning" : "info:Info";

        const localize = this.getLocalize();
        const translatedKey = localize({ '0': `:@@${displayKey}`, raw: [':'] } as any);

        var notification: ServerMessage = {
          key: translatedKey,
          message: this.localizeNotificationMessage(n),
          type: n.notificationType
        }
        notification.type = n.notificationType;

        notifications.push(notification);
      });

    } else if (errorResponse) {
      var notification: ServerMessage = {
        key: $localize`:@@error:Error`,
        message: "",
        type: NotificationType.Error
      }

      this.buildErrorMessage(errorResponse, request, notification);
      notifications.push(notification);
    }

    return notifications;
  }

  private buildErrorMessage(errorResponse: HttpErrorResponse, request: HttpRequest<any>, message: ServerMessage) {
    switch (errorResponse.status) {
      case 0:
        message.message = $localize`:@@defaultServerApiError:Something went wrong!. Please contact the IT administrator.`;
        ExceptionInterceptor.setErrorWhenMessageForStatusCodeExists(message, 0, request);
        break;
      case 504:
        message.message = $localize`:@@504:Request Timeout. Please contact the IT administrator.`;
        ExceptionInterceptor.setErrorWhenMessageForStatusCodeExists(message, 504, request);
        break;
      case 400:
        message.message = $localize`:@@400:Bad Request. Please contact the IT administrator.`;
        break;
      case 401:
        message.message = $localize`:@@401:Your authorization failed. Please try refreshing the page and fill in the correct credentials.`;
        break;
      case 403:
        message.message = $localize`:@@403:You don't have permission to access the requested resource. Please contact the IT administrator.`;
        break;
      case 404:
        message.message = $localize`:@@404:The requested resource does not exist. Please contact the IT administrator.`;
        break;
      case 412:
        message.message = $localize`:@@412:You have not selected the lab site of the resource you are attempting to view. Please try again after selecting the appropriate lab site.`;
        break;
      case 500:
        if (
          errorResponse.error &&
          errorResponse.error.Message &&
          errorResponse.error.Message.includes('Schema')
        ) {
          // This works only in the lower environment
          message.message = errorResponse.error.Message;
        } else {
          const correlationId = errorResponse.error ? errorResponse.error.CorrelationId : '';
          message.key = $localize`:@@500:Error: Please contact the IT administrator.`;
          message.message = $localize`:@@correlationId:Reference Number: ${correlationId}`;
        }
        break;
      case 503:
        message.message = $localize`:@@503:The requested service is not available. Please contact the IT administrator.`;
        break;
      default:
        message.message = $localize`:@@defaultServerApiError:Something went wrong!. Please contact the IT administrator.`;
    }
  }

  private localizeNotificationMessage(notification: NotificationDetails): string {
    const localize = this.getLocalize();
    // "Any" type can not avoided here as TemplateStringArray has many members which are not needed to be supplied from our end
    const translatedMessage = localize({
      '0': `:@@${notification.translationKey}:${notification.translationKey}`,
      raw: [':']
    } as any);
    if (translatedMessage !== notification.translationKey) {
      return translatedMessage;
    }
    return notification.message;
  }

  getLocalize() {
    return $localize;
  }

  private static ignoreError(req: HttpRequest<any>): boolean {
    return ExceptionInterceptor.EndpointsToIgnoreErrors.some((url) => req.url.indexOf(url) >= 0);
  }

  private static getErrorMessageForStatusCode(statucCode: number, req: HttpRequest<any>): ServerMessage | undefined {
    const endpoints = Object.keys(ExceptionInterceptor.StatusCodeWiseErrorsForEndPoints);
    const matchingEndpoint = endpoints.find(endpoint => req.url.indexOf(endpoint) >= 0);
    if (!matchingEndpoint) {
      return undefined;
    }
    return ExceptionInterceptor.StatusCodeWiseErrorsForEndPoints[matchingEndpoint][statucCode];
  }

  private static setErrorWhenMessageForStatusCodeExists(message: ServerMessage, statucCode: number, request: HttpRequest<any>): void {
    const statusCodeWiseErrorMessage = ExceptionInterceptor.getErrorMessageForStatusCode(504, request);
    if (statusCodeWiseErrorMessage) {
      message.message = statusCodeWiseErrorMessage.message;
      message.key = statusCodeWiseErrorMessage.key;
      message.type = NotificationType.Error;
    }
  }

  public static readonly StatusCodeWiseErrorsForEndPoints: { [key: string]: { [key: number]: ServerMessage } } = {
    '/files/download':
    {
      0: {
        key: $localize`:@@error:Error`,
        message: $localize`:@@fileDownloadClientFailure:File download request could not be processed at the moment.Please check your network connection and try again. If the issue persists, please contact administrator.`,
        type: NotificationType.Error
      },
    },
    '/files/upload': {
      504: {
        key: $localize`:@@error:Error`,
        message: $localize`:@@fileUpload504Error:Your request took too long to process.Please check your network connection and try again. If the issue persists, please contact administrator.`,
        type: NotificationType.Error
      }
    }
  }

}
