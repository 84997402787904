/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { UserPreferenceListResponse } from '../../models/user-preference-list-response';

export interface UserPreferencesUserPreferenceKeyGet$Json$Params {
  userPreferenceKey: string;
  'X-User-Puid'?: any;
  'X-User-LabsiteCode'?: any;

/**
 * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
 */
  'X-Experiment-PreviewMode'?: any;
}

export function userPreferencesUserPreferenceKeyGet$Json(http: HttpClient, rootUrl: string, params: UserPreferencesUserPreferenceKeyGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<UserPreferenceListResponse>> {
  const rb = new RequestBuilder(rootUrl, userPreferencesUserPreferenceKeyGet$Json.PATH, 'get');
  if (params) {
    rb.path('userPreferenceKey', params.userPreferenceKey, {});
    rb.header('X-User-Puid', params['X-User-Puid'], {});
    rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
    rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<UserPreferenceListResponse>;
    })
  );
}

userPreferencesUserPreferenceKeyGet$Json.PATH = '/user-preferences/{userPreferenceKey}';
