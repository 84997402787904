import { BptIconDefinition, BptSizes } from 'bpt-ui-library/shared';
import { dropdownBasicList } from 'model/drop-down-basic-list';
import { TemplateType } from '../../api/models';
import { TemplateTypeNameDictionary } from './template-type-names';

export class TemplateListPresentationHelper {
  public static readonly templateId = 'id';
  public static readonly templateNumber = 'templateNumber';
  public static readonly templateType = 'templateType';

  public static getTemplateTypeDisplayIconDefinition(
    templateType: TemplateType | string
  ): BptIconDefinition | undefined {
    const iconPath = TemplateListPresentationHelper.getTemplateTypeDisplayIconPath(templateType);
    return {
      iconClass: undefined,
      src: iconPath,
      size: { iconSize: BptSizes.Medium },
      styleClass: ' mr-2'
    };
  }

  public static getTemplateTypeDisplayIconPath(
    templateType: TemplateType | string
  ): string | undefined {
    const nexusIconPath = 'assets/eln-assets/nexus-icons/';
    switch (templateType.toLowerCase()) {
      case TemplateType.Activity:
        return `${nexusIconPath}activity-icon.svg`;
      case TemplateType.Module:
        return `${nexusIconPath}module-icon.svg`;
      case TemplateType.Form:
        return `${nexusIconPath}form-icon.svg`;
      case TemplateType.Table:
        return `${nexusIconPath}table-icon.svg`;
    }
    return undefined;
  }

  public static getTemplateTypeLookup(
    templateTypes: string | null | undefined
  ): dropdownBasicList<string>[] {
    let templateTypeKeys: string[] = [];
    if (templateTypes) {
      templateTypeKeys = templateTypes.split(',');
    }
    return templateTypeKeys.map((templateTypeKey) => {
      return {
        value: templateTypeKey,
        label: TemplateTypeNameDictionary[templateTypeKey]
      };
    });
  }
}
