import { EventEmitter } from '@angular/core';
import { cloneDeep } from 'lodash-es';

export type ElnMenuInlineInputElementContext<TArguments> = {
  initialValue: string;
  id: string;
  placeholderText?: string;
  callbackArguments?: TArguments;
};

export type ElnMenuInlineInputEvent<TArguments> = {
    callbackArguments?: TArguments;
    controlContext: ElnMenuInlineInputElement<TArguments>
};

export type ElnMenuInlineInputValueChangedContext<TArguments> = ElnMenuInlineInputEvent<TArguments> & {
  value: string;
};

export class ElnMenuInlineInputElement<TArguments> {
  private static readonly invalidControlValueClass = 'bpt-eln-invalid';
  public get inputElement(): HTMLInputElement {
    return this.input;
  }
  private readonly input: HTMLInputElement;

  public readonly ValueChanged = new EventEmitter<
    ElnMenuInlineInputValueChangedContext<TArguments>
  >();
  public readonly cancelEdit = new EventEmitter<ElnMenuInlineInputEvent<TArguments>>();

  public readonly inputsContext: ElnMenuInlineInputElementContext<TArguments>;
  private hasError = false;
  private valueSnapshotWhileErrorOccured!: string;
  
  constructor(inputElementContext: ElnMenuInlineInputElementContext<TArguments>) {
    this.inputsContext = inputElementContext;
    this.input = document.createElement('input');
    this.createElement();
  }

  public focusAndSelect() {
    setTimeout(() => {
      this.input.focus();
      this.input.select();
    }, 100);
  }

  private createElement(): void {
    this.input.setAttribute('id', `${this.inputsContext.id}`);
    this.input.setAttribute('type', 'text');
    this.input.setAttribute(
      'class',
      'bpt-eln-node-inline-editing-input p-inputtext p-component p-element blue-theme'
    );
    this.input.setAttribute('placeholder', $localize`:@@titlePlaceHolder:Enter Title`);
    this.input.setAttribute('value', this.inputsContext.initialValue);
    this.attachBlurEvent();
    this.attachKeyUp();
  }

  private attachBlurEvent() {
    if (!this.input) {
      return;
    }
    this.input.addEventListener('blur', (e: Event) => {
      this.triggerValueChangeEvent();
      e.preventDefault();
    });
  }

  private attachKeyUp() {
    if (!this.input) {
      return;
    }
    this.input.addEventListener('keyup', (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        this.leaveFromEdit();
        return;
      }
      if (e.key === 'Enter') {
        this.hasError = false;
        this.input.blur();
      }
    });
  }

  public leaveFromEdit(): void {
    if (this.input.removeAllListeners) {
      this.input.removeAllListeners('blur');
    }
    const currentContext = this.getEventArguments();
    this.cancelEdit.next(currentContext);
  }

  private getEventArguments(): ElnMenuInlineInputValueChangedContext<TArguments> {
    return {
      value: (document.querySelector(`[id='${this.inputsContext.id}']`) as HTMLInputElement).value,
      callbackArguments: this.inputsContext.callbackArguments,
      controlContext: this
    };
  }

  private triggerValueChangeEvent(): void {
    const currentContext = this.getEventArguments();
    if (!currentContext.value || currentContext.value.trim().length === 0) {
      this.setErrorIndidcator();
      return;
    }
    if(this.shouldCancel(currentContext.value)){
      this.cancelEdit.next(currentContext);
      return;
    }
    this.resetErrorIndidcator();
    if (
      currentContext.value !== this.inputsContext.initialValue
    ) {
      this.ValueChanged.next(currentContext);
    } else {
      this.cancelEdit.next(currentContext);
    }
  }

  public setErrorIndidcator() {
    this.markAsInvalid();
    const input = document.querySelector(`[id='${this.inputsContext.id}']`) as HTMLInputElement;
    input.classList.add(ElnMenuInlineInputElement.invalidControlValueClass);
  }

  public resetErrorIndidcator() {
    this.hasError = false;
    const input = document.querySelector(`[id='${this.inputsContext.id}']`) as HTMLInputElement;
    input.classList.remove(ElnMenuInlineInputElement.invalidControlValueClass);
  }

  markAsInvalid(): void{
    const currentContext = this.getEventArguments();
    this.hasError = true;
    this.valueSnapshotWhileErrorOccured = cloneDeep(currentContext.value);
  }

  shouldCancel(currentTitle: string): boolean {
    return this.hasError && currentTitle === this.valueSnapshotWhileErrorOccured;
  }
}
