import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { PreparationService } from '../services/preparation.service';
import {
  BptGridComponent, BptGridMode, BptGridRowActionClickEvent,
  BptGridRowActionConfiguration, BptRowActionElement, ColumnDefinition, ColumnType
} from 'bpt-ui-library/bpt-grid';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { ConfirmationService } from 'primeng/api';
import {
  AdditionalInformation, ContainerGridData, ContainerDescription,
  ExpirationModel, ExpirationType, Preparation
} from '../models/preparation.model';
import { ValueType } from '../../api/models/value-type';
import { ValueState } from '../../api/models/value-state';
import { Quantity, Unit } from 'bpt-ui-library/shared';
import { ModifiableDataValue, StringValue } from '../../api/data-entry/models';
import { InstantValue, LocalDateValue } from '../../api/models';
import { UnsubscribeAll } from '../../shared/rx-js-helpers';
import { Instant, LocalDate } from '@js-joda/core';
import { BptDateTimeComponent } from 'bpt-ui-library/bpt-datetime';
import { PreparationEventService } from '../services/preparation-event.service';
import { PreparationConstants } from '../preparation-constants';
import { ClientValidationDetails } from '../../model/client-validation-details';
import { SpinnerOptions } from '../../eln-progress-spinner-module/spinner-options.interface';
import { ElnProgressSpinnerService } from '../../eln-progress-spinner-module/eln-progress-spinner.service';
import { UserService } from '../../services/user.service';


@Component({
  selector: 'app-create-preparation',
  templateUrl: './create-preparation.component.html',
  styleUrls: ['./create-preparation.component.scss']
})
export class CreatePreparationComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('preparationGrid') preparationGrid?: BptGridComponent;
  @Input() showSlider = false;
  @Input() rowsPresentInPreparationsGrid = 0;
  @Output() closeSlider = new EventEmitter();
  sliderCloseConfirmation = new Subject<boolean>();
  newPreparation: Preparation;
  openContainerSlider = false;
  storageConditionHasError = false;
  expirationHasError = false;
  stabilityHasError = false
  preparationNameHasError = false;
  isErrorOccurred = false;
  disableAddContainerButton = false;
  sliderUnsavedChangesDictionary: { [key: string]: string } = {};
  toggleExpirationSlider = false;
  value!: ModifiableDataValue;
  createPreparationQuantityModels: {
    concentrationModel?: string,
    stabilityModel?: string
  } = {};
  expirationModel: Instant | LocalDate | undefined = undefined;
  expiration: ExpirationType = {
    suitableForUse: false,
    expirationDate: {
      state: ValueState.Empty,
      type: ValueType.LocalDate
    }
  };
  optionsForStorageCondition: Array<{ label: string, value: string }> = [];
  optionsForDisposal: Array<{ label: string, value: string }> = [];
  optionsForCompendia: Array<{ label: string, value: string }> = [];

  projects = [{ label: '', value: '' }];
  clients = [{ label: '', value: '' }];
  disableProjects = false;
  concentrationUnits: Unit[] = [];
  originalQuantity: Unit[] = [];
  stabilityUnits: Unit[] = [];
  dropdownOptions = {
    showClear: false,
    showFilter: false,
    multiSelect: false,
    wrap: false,
  }
  clientDropdownOptions = {
    showClear: true,
  }
  quantityOptions = {
    commitValueOnEnter: true,
    enableSignificantFigures: true,
    cancelValueOnEscape: true,
    allowNA: true,
    allowDecimal: true,
    highlightAllOnFocus: false,
    suppressContextMenu: true,
  }
  subscriptions: Subscription[] = [];
  pickListsForCreatePreparation$: any;
  isExpirationAvailable: any;
  whileSuitableForUse = false;
  basedOnStability = false
  readonly addContainerLabel = PreparationConstants.addContainerLabel;
  readonly addContainer = PreparationConstants.addContainer;
  readonly requiredFieldMessage = PreparationConstants.fieldRequired;
  validation: ClientValidationDetails = new ClientValidationDetails();
  static readonly maximumContainerReached = PreparationConstants.maximumContainerReached;
  static readonly PreparationSuccess = PreparationConstants.PreparationSuccess;
  static readonly RequiredFieldsValidation = PreparationConstants.RequiredFieldsValidation;
  static readonly MinimumRowValidation = PreparationConstants.MinimumRowValidation;
  nameTitle = PreparationConstants.nameTitle;
  formulationTitle = PreparationConstants.formulationTitle;
  concentrationTitle = PreparationConstants.concentrationTitle;
  expirationTitle = PreparationConstants.expirationTitle;
  storageConditionTitle = PreparationConstants.storageConditionTitle;
  preparationInternalInfoTitle = PreparationConstants.preparationInternalInfoTitle;
  disposalTitle = PreparationConstants.disposalTitle;
  storageLocationTitle = PreparationConstants.storageLocationTitle;
  stabilityTitle = PreparationConstants.stabilityTitle;
  hazardsTitle = PreparationConstants.hazardsTitle;
  AdditionalInfoTitle = PreparationConstants.AdditionalInfoTitle;
  analysisTitle = PreparationConstants.analysisTitle;
  methodTitle = PreparationConstants.methodTitle;
  compendiaTitle = PreparationConstants.compendiaTitle;
  clientTitle = PreparationConstants.clientTitle;
  suitableForUseText = PreparationConstants.suitableForUseText;
  basedOnStabilityText = PreparationConstants.basedOnStability;
  maxPreparationsReachedText = PreparationConstants.maxPreparationsReachedText;
  isParentRecipe: boolean;

  private readonly spinnerOptions: SpinnerOptions = {
    message: PreparationConstants.createPreparationMsg,
    i18nMessage: `@@createPreparation`
  };
  constructor(private readonly preparationService: PreparationService,
    private readonly preparationEventService: PreparationEventService,
    public readonly confirmationService: ConfirmationService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly spinnerService: ElnProgressSpinnerService
  ) {
    this.isParentRecipe = preparationService.ParentItemType === 'Recipe';
    this.populateDropDownsFromPickLists();
    this.populateUnitsForQuantity();
    this.assignSliderState();
    this.newPreparation = this.getNewPreparation();
    this.clients = this.preparationService.getClients();
  }

  public get primitiveValue(): any {
    return this.preparationService.getPrimitiveValue(this.value);
  }
  /** IANA time zone id for lab site */
  get labSiteTimeZone(): string {
    return UserService.currentLabSiteTimeZone.id();
  }

  sliderOptions = {
    size: 'large',
    visible: true,
    closeOnEscape: false,
    headerText: PreparationConstants.HeaderText,
    isFooterSticky: true,
    displayFooter: true,
    displayFooterWithPrimaryButton: false,
    displayFooterWithSecondaryButton: false,
    isModal: true
  };
  expirationOptions = {
    hourFormat: '12' as typeof BptDateTimeComponent.prototype.hourFormat,
    required: true,
    commitValueOnEnter: true,
    includeTime: false
  }
  columnName = {
    index: '#',
    tags: PreparationConstants.tags,
    OriginalQuantity: PreparationConstants.OriginalQuantity,
    ContainerDescription: PreparationConstants.ContainerDescription
  }
  columnDefinitions: ColumnDefinition[] = [
    { label: this.columnName.index, field: 'tagSequenceNumber', editable: false },
    { label: this.columnName.tags, field: 'tags' },
    {
      label: this.columnName.OriginalQuantity, field: 'originalQuantity',
      columnType: 'quantity',
      allowNA: true,
      allowDecimal: true,
      editable: true,
      showUnitNameInList: true,
      enableSignificantFigures: true,
      allowAutoResize: true,
    },
    {
      label: this.columnName.ContainerDescription,
      field: 'containerDescription',
      width: 470,
      minWidth: 470,
      editable: false,
      valueGetter: (params: any) => {
        if (params.data.containerDescription) {
          const joinedValue =  this.preparationService.buildContainerDescription(params.data.containerDescription) ;
          return  joinedValue !== "N/A" ? joinedValue : "";
        }
        return "";
      }
    },
  ];
  defaultRow: ContainerGridData = {
    tagSequenceNumber: 1,
    tags: "",
    originalQuantity: undefined,
    containerDescription: {
      type: ValueType.StringDictionary,
      state: ValueState.NotApplicable,
      value: {}
    }
  };
  preparationDataSource = [this.defaultRow];

  defaultIndex = "1";
  defaultTags = "Ctr1";
  lastRowToBeDeleted = 1;
  maximumAllowedRowsToAdd = 100;
  indexOfCurrentFlaskIconClickedOnGrid = 0;
  gridActions!: BptGridRowActionConfiguration;
  gridMode = BptGridMode.dataEntry;
  descModel: ContainerDescription = {
  };

  ngOnInit() {
    this.setColumnProperties();
    this.refreshGridOnContainerDescriptionCommit();
    this.addGridActions();
    this.setExpirationSliderStateOnLoad();
    this.setExpirationValue();
  }

  private setExpirationSliderStateOnLoad() {
    this.subscriptions.push(this.preparationEventService.openExpirationSlider$.subscribe((data: any) => {
      this.toggleExpirationSlider = data.showSlider;
    }));
  }

  /**
 * It sets the date and whileSuitableForUse to be displayed on Expiration which was committed in Expiration slider
 */
  setExpirationValue() {
    this.subscriptions.push(this.preparationEventService.preparationExpirationChanged.subscribe(expiration => {
      //value has already been set hence hide the slider
      this.toggleExpirationSlider = false;
      this.expirationHasError = false;
      this.expiration = expiration;
      this.whileSuitableForUse = expiration.suitableForUse ?? false;
      this.basedOnStability = this.isParentRecipe && !expiration.suitableForUse && expiration.duration?.value ? true : false;
      if(this.basedOnStability && expiration.duration){
        this.stabilityHasError = false;
        expiration.duration.exact = true;
        this.convertQuantity(expiration.duration,'stability');
        this.createPreparationQuantityModels.stabilityModel =  expiration.duration?.toString()
      }
      this.expirationModel = expiration.suitableForUse ? undefined : this.getExpirationType(expiration.expirationDate);
    }));
  }

  /**
   * It converts the expiration extracts expiration value from ExperimentDataValue type
   * Else returns as is
   */
  getExpirationType(expiration: InstantValue | LocalDateValue | undefined): Instant | LocalDate | undefined {
    const value = expiration ?? { type: ValueType.LocalDate, state: ValueState.Empty };
    this.expirationOptions.includeTime = (value.type === ValueType.Instant)
    switch (value.state) {
      case ValueState.Empty: return undefined;
      case ValueState.NotApplicable: return undefined;
      case ValueState.Set: return value.type === ValueType.Instant ? Instant.parse(value.value ?? '') : LocalDate.parse(value.value ?? '');
      default: throw new Error(`Invalid value state ${value}`);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.sliderOptions.visible = this.showSlider;
    if (changes['rowsPresentInPreparationsGrid']) {
      this.disableAddContainerButton = (changes['rowsPresentInPreparationsGrid'].currentValue + this.preparationDataSource.length) >= PreparationConstants.maximumPreparationsPerActivity;
    }
  }
  /**
   * It returns an empty preparation
   */
  getNewPreparation(): Preparation {
    return {
      //As part of initial design, adding empty Id here as It be assigned later based on user data once the api is in place
      name: {
        state: ValueState.Empty,
        type: ValueType.String,
      },
      formulationOrComponents: {
        state: ValueState.Empty,
        type: ValueType.String,
      },
      concentration: {
        state: ValueState.Empty,
        type: ValueType.Number
      },
      expiration: {
        suitableForUse: false,
        expirationDate: {
          state: ValueState.Empty,
          type: ValueType.LocalDate
        }
      },
      storageCondition: {
        state: ValueState.Empty,
        type: ValueType.String
      },
      preparationInternalInformation: {
        disposal: '',
        storageLocation: '',
        stability: {
          state: ValueState.Empty,
          type: ValueType.Number
        },
        hazards: ''
      },
      additionalInformation: {
        discardedOrConsumed: false,
        analysis: '',
        method: '',
        compendia: '',
        client: '',
        project: '',
      },
      isRemoved: false
    }
  }
  /**
 * Sets the slider visible state if slider is closed
 */
  sliderVisibleChange(sliderOpen: boolean) {
    if (!sliderOpen) {
      this.resetModels();
      this.closeSlider.emit(this.sliderOptions.visible);
    }
  }
  /**
   * It converts the edited quantity to a type suitable to add in preparation object
   */
  convertQuantity(newQuantity: Quantity, property: string) {
    this.sliderUnsavedChangesDictionary[property] = (newQuantity.state === 'empty' && newQuantity.unitDetails === undefined) ? "" : "true";
    this.updateQuantityInPreparation(newQuantity, property);
  }

  /**
 * It updates the quantity into respective preparation object based on the property passed
 */
  updateQuantityInPreparation(newQuantity: Quantity, property: string) {
    const dataValue = this.preparationService.getQuantityPrimitiveValue(newQuantity);
    switch (property) {
      case 'concentration':
        this.newPreparation.concentration = dataValue;
        break;
      case 'stability':
        if (this.newPreparation.preparationInternalInformation) {
          this.newPreparation.preparationInternalInformation.stability = dataValue;
          this.stabilityHasError = false;
        }
        break;
      default:
        break;
    }
  }

  convertToStringValue(value: string, property: string): StringValue {
    this.handleValidationError(value, property);
    if (value) {
      value = value.trim();
    }
    this.sliderUnsavedChangesDictionary[property] = (value === undefined || value === null) ? "" : value;
    return this.preparationService.getExperimentDataValue(undefined, value);
  }
  /**
   * It enables and populates project dropdown based on client selection
   */
  updateProject(client: string) {
    this.disableProjects = !client;
    this.projects = this.preparationService.fetchProjectsLinkedToClients([client]);
  }
  /**
   * It clears the selection that has been made in the single select dropdown
   */
  dropdownCleared(property: keyof AdditionalInformation) {
    if (property === 'client' && this.newPreparation.additionalInformation) {
      this.disableProjects = true;
      this.sliderUnsavedChangesDictionary[property] = '';
      this.newPreparation.additionalInformation[property] = '';
      this.newPreparation.additionalInformation['project'] = '';
    }
  }
  /**
   * It gathers picklist data from service and populate the options for each dropdown
   */
  populateDropDownsFromPickLists() {
    const pickLists = this.preparationService.getPickListsForPreparation();
    pickLists.storageCondition.subscribe((storageConditionData) => {
      this.optionsForStorageCondition = storageConditionData.items;
    });
    pickLists.disposal.subscribe((disposalData) => {
      this.optionsForDisposal = disposalData.items;
    });
  }
  /**
   * It will gather units data from service and populate the options for each quantity type
   */
  populateUnitsForQuantity() {
    if (this.preparationService.preparationUnits.length === 0)
      this.preparationService.getQuantityUnits();
    this.concentrationUnits = this.preparationService.preparationUnits[0];
    this.stabilityUnits = this.preparationService.preparationUnits[1];
    this.originalQuantity = this.preparationService.preparationUnits[2];
  }
  /**
   * It will open the Expiration Type slider when expiration field is clicked
   * It will also stop the calendar from being displayed when calendar icon is clicked
   */
  onExpirationClick(event: Event) {
    this.toggleExpirationSlider = true;
    event.stopPropagation();
    const expiration: ExpirationModel = {
      suitableForUse: this.whileSuitableForUse ?? false,
      expirationDate: this.expirationModel,
      duration: this.expiration.duration
    };
    this.preparationService.expirationModel = expiration;
    this.preparationEventService.openExpirationSliderSubject.next({ showSlider: true, isReadOnlyRow: false });
  }
  /**
  * Saves the preparation object and resets models after saving so that empty fields are shown on reopening the slider
  */
  createPreparation() {
    this.isErrorOccurred = false;
    this.checkValidationBeforeCreatePreparations();
    if (!this.isErrorOccurred) {
      if (this.newPreparation.name && this.newPreparation.storageCondition.value && this.newPreparation.expiration) {
        this.newPreparation.expiration = this.expirationModel ? this.expiration : {
          suitableForUse: this.expiration.suitableForUse,
          expirationDate: {
            state: this.expiration.suitableForUse ? ValueState.NotApplicable : ValueState.Empty,
            type: ValueType.LocalDate
          }
        };
        this.newPreparation.containers = this.preparationDataSource;
        let preparationSuccessMessage = "";
        if (this.preparationDataSource.length > 0) {
          preparationSuccessMessage = this.preparationDataSource.length + $localize`:@@preparationSuccessMessage: Preparation(s) added successfully.`;
        }
        this.preparationEventService.newPreparationSubmit({ data: this.newPreparation, closeHandler: this.closeEventRaiser.bind(this), toasterMessage: preparationSuccessMessage });
        this.spinnerService.Show(this.spinnerOptions);
      }
    }
  }

  checkValidationBeforeCreatePreparations() {
    this.preparationNameHasError = this.newPreparation.name?.value?.trim() ? false : true;
    this.storageConditionHasError = this.newPreparation.storageCondition?.value?.trim() ? false : true;
    this.expirationHasError = this.whileSuitableForUse ? false : this.expiration.expirationDate?.value ? false : true;
    this.expirationHasError = this.isParentRecipe ? false : this.expirationHasError;
    this.stabilityHasError =  this.isParentRecipe && !this.whileSuitableForUse && this.newPreparation.preparationInternalInformation?.stability?.state === "empty" ? true : false;
    if (this.preparationDataSource.length < 1) {
      this.isErrorOccurred = true;
      this.preparationService.buildToastMessage('notification', 'error', 'onePreparationRequired', CreatePreparationComponent.MinimumRowValidation, false);
    }
    if (this.preparationDataSource.length > 100) {
      this.isErrorOccurred = true;
      this.preparationService.buildToastMessage('notification', 'error', 'maximumRowReached', CreatePreparationComponent.maximumContainerReached, false);
    }
    if (this.expirationHasError || this.storageConditionHasError || this.preparationNameHasError || this.stabilityHasError) {
      this.isErrorOccurred = true;
      this.preparationService.buildToastMessage('notification', 'error', 'checkValidations', CreatePreparationComponent.RequiredFieldsValidation, false);
    }
  }

  closeEventRaiser(): void {
    this.sliderOptions.visible = false;
    this.closeSlider.emit(this.sliderOptions.visible);
    this.spinnerService.Hide();
  }

  handleValidationError(value: string, property: string) {
    if (value && value?.trim() !== "") {
      switch (property) {
        case "storageCondition":
          this.storageConditionHasError = false;
          break;
        default:
          break;
      }
    }
  }

  handlePreparationNameChange(value: string) {
    this.sliderUnsavedChangesDictionary['name'] = (value === undefined || value === null) ? "" : value;
    this.newPreparation.name = {
      type: ValueType.String,
      state: ValueState.Set,
      value: value
    };
    if (value && value?.trim() !== "") {
      this.preparationNameHasError = false;
    }
  }
  /**
     * This method will check if any Unsaved changes are there not if no
     * changes are there it will close the slider
     */
  resetModels() {
    this.sliderUnsavedChangesDictionary['expirationModel'] = this.expiration.suitableForUse === true ||
      (this.expiration.expirationDate?.state !== undefined && this.expiration.expirationDate?.state !== "empty")
      ? "true"
      : "";
    if (Object.values(this.sliderUnsavedChangesDictionary).some(field => field?.trim() !== "") || this.checkForUnsavedChangesInGrid()) {
      this.checkForUnSavedChanges();
    }
    else {
      this.closeSlider.emit(false);
    }
  }

  /**
       * This method will check if any Unsaved changes present in 1st row of grid
       */

  private checkForUnsavedChangesInGrid(): boolean {
    const dataSourceLength = this.preparationDataSource.length;
    if (dataSourceLength === 0) {
      this.resetUnsavedChanges();
      return false;
    }
    if (dataSourceLength === 1) {
      const preparationGridData = this.preparationDataSource[0];
      if (preparationGridData) {
        return preparationGridData.tags?.trim() !== "" || this.checkOriginalQuantity() || preparationGridData.containerDescription.state === ValueState.Set;
      }
    }
    return dataSourceLength > 1;
  }


  checkOriginalQuantity() {
    const preparationGridData = this.preparationDataSource[0];
    if (!preparationGridData.originalQuantity) {
      return false;
    }
    else if (typeof (preparationGridData.originalQuantity) === 'object') {
      const state = Object.entries(preparationGridData.originalQuantity).find(([key]) => key === "state")?.[1] || null;
      if (state === 'empty') {
        return false;
      }
    }
    return true;
  }
  /**
     * This method will check for confirmation when there are unsaved changes in slider
     */
  checkForUnSavedChanges() {
    this.confirmationService.confirm({
      message: PreparationConstants.DeleteForConfirmation,
      header: PreparationConstants.DeleteForConfirmationHeader,
      closeOnEscape: true,
      dismissableMask: false,
      acceptVisible: true,
      acceptLabel: PreparationConstants.acceptLabel,
      rejectVisible: true,
      rejectLabel: PreparationConstants.rejectLabel,
      rejectButtonStyleClass: 'p-button-outlined-compact',
      accept: () => {
        this.resetUnsavedChanges();
        this.sliderCloseConfirmation.next(true);
      },
      reject: () => {
        this.sliderCloseConfirmation.next(false);
      }
    });
  }
  /**
    * It resets all the fields on the slider
    */
  resetUnsavedChanges() {
    this.closeSlider.emit(false);
    this.preparationEventService.preparationExpirationTypeChangedEvent({
      suitableForUse: false,
      expirationDate: { type: ValueType.LocalDate, state: ValueState.Empty }
    });
    this.toggleExpirationSlider = false;
  }

  /**
   * It fetches the last data from the preparationDataSource array
   */
  private lastAddedContainer() {
    return this.preparationDataSource[this.preparationDataSource.length - 1];
  }

  /**
   * opens bpt slider on setting sliderOptions.visible to true
   */
  assignSliderState() {
    this.subscriptions.push(this.preparationEventService.sliderVisibility$.subscribe((showSlider: boolean) => {
      this.sliderOptions.visible = true;
      this.showSlider = showSlider;
    }));
  }

  /**
   * Passes data to sliderVisibilitySubject
   */
  toggleSlider(showSlider: boolean) {
    this.preparationEventService.sliderVisibilitySubject.next(showSlider);
  }



  /**
   * It adds the rows to ContainerDescription Grid and update the currentIndex and tags accordingly
   */
  addRowToGrid() {
    this.disableAddContainerButton = this.preparationDataSource.length === this.maximumAllowedRowsToAdd - 1 ||
      (this.rowsPresentInPreparationsGrid + this.preparationDataSource.length + 1) >= PreparationConstants.maximumPreparationsPerActivity;
    if (this.preparationGrid?.dataSource.length === 1) {
      const rowToUpdate = this.preparationDataSource.find(row => row.tagSequenceNumber === +this.defaultIndex);
      if (rowToUpdate && rowToUpdate.tags === "") {
        rowToUpdate.tags = this.defaultTags;
        this.preparationGrid?.gridApi.applyTransaction({ update: [rowToUpdate] });
      }
    }
    else if (this.preparationGrid?.dataSource.length === 0) {
      const defaultRow = {
        tagSequenceNumber: 1, tags: "", originalQuantity: undefined, containerDescription: {
          type: ValueType.StringDictionary,
          state: ValueState.NotApplicable,
          value: {}
        }
      };
      this.preparationDataSource.push(defaultRow);
      this.preparationGrid?.gridApi?.setGridOption('rowData', this.preparationDataSource);
      return;
    }
    else if (this.preparationGrid?.dataSource.length === this.maximumAllowedRowsToAdd) {
      return;
    }
    const currentIndex = this.lastAddedContainer()?.tagSequenceNumber + 1;
    const currentTags = 'Ctr'.concat(currentIndex.toString());
    const newRow = {
      tagSequenceNumber: currentIndex, tags: currentTags, originalQuantity: undefined,
      containerDescription: {
        type: ValueType.StringDictionary,
        state: ValueState.NotApplicable,
        value: {}
      }
    };
    this.preparationDataSource.push(newRow);
    this.preparationGrid?.gridApi?.setGridOption('rowData', this.preparationDataSource);
  }
  private addGridActions() {
    const rowActions = this.getRowActionItems();
    const actionsSubject: BehaviorSubject<BptRowActionElement[]> = new BehaviorSubject(rowActions);
    this.gridActions = {
      actions: actionsSubject
    };
  }
  /**
     * On hover on row It add icons to grid
  */
  public getRowActionItems(): BptRowActionElement[] {
    return [
      {
        id: PreparationConstants.preparationOpenContainerFlaskId,
        enabled: true,
        styleClass: 'icon-beaker',
        click: this.openContainerDescriptionSlider.bind(this),
        tooltip: PreparationConstants.flaskTooltip
      },
      {
        id: PreparationConstants.preparationRowDeleteActionId,
        enabled: true,
        styleClass: 'far fa-trash-alt',
        click: this.openConfirmationSliderToDeleteRow.bind(this),
        tooltip: PreparationConstants.deleteContainerTooltip
      }
    ];
  }
  /**
     * On click of flask icon in row It open slider and sends container description model
  */
  openContainerDescriptionSlider(e: BptGridRowActionClickEvent) {
    this.indexOfCurrentFlaskIconClickedOnGrid = e.params.data.tagSequenceNumber;
    this.preparationEventService.openContainerSlider(true);
    const containerDescriptionData = this.preparationDataSource.find(row => row.tagSequenceNumber === e.params.data.tagSequenceNumber)?.containerDescription;
    if (containerDescriptionData !== undefined) {
      this.preparationEventService.containerDescriptionModel.next(containerDescriptionData);
    }
    else {
      this.preparationEventService.containerDescriptionModel.next({ state: ValueState.Empty, type: ValueType.StringDictionary });
    }
  }
  /*
  *  refresh the grid updating the data grid
  */
  refreshGridOnContainerDescriptionCommit() {
    this.subscriptions.push(this.preparationEventService.preparationDescriptionChanged.subscribe(containerData => {
      const rowToUpdate = this.preparationDataSource.find(row => row.tagSequenceNumber === this.indexOfCurrentFlaskIconClickedOnGrid);
    if (rowToUpdate && containerData) {
        rowToUpdate.containerDescription = containerData;
        this.preparationGrid?.gridApi.applyTransaction({ update: [rowToUpdate] });
      }
    }));
  }
  /*
  *  confirmation model opens on click of delete icon in grid
  */
  openConfirmationSliderToDeleteRow(e: BptGridRowActionClickEvent) {
    this.confirmationService.confirm({
      message: PreparationConstants.confirmationMessage,
      header: $localize`:@@ConfirmationHeader:Confirmation`,
      closeOnEscape: true,
      dismissableMask: false,
      acceptVisible: true,
      acceptLabel: $localize`:@@Yes:Yes`,
      rejectVisible: true,
      rejectLabel: $localize`:@@No:No`,
      rejectButtonStyleClass: 'p-button-outlined-compact',
      accept: () => {
        this.removeRowFromContainerGrid(e.params.data);
      }
    });
  }
  /*
  *
  * Method will trigger when you click close icon of slider user to implement any logic with closing slider
  */
  sliderClosing = (e:Event) => {
    this.resetModels();
    return this.sliderCloseConfirmation.asObservable();
  }
  /**
   *
   * Removes the particular from the grid
   */
  removeRowFromContainerGrid(data: any) {
    this.disableAddContainerButton = this.preparationDataSource.length > this.maximumAllowedRowsToAdd ||
      (this.rowsPresentInPreparationsGrid + this.preparationDataSource.length - 1) >= PreparationConstants.maximumPreparationsPerActivity;
    ;
    if (this.preparationDataSource.length === this.lastRowToBeDeleted) {
      const msg = PreparationConstants.removeConfirmationMessage;
      this.preparationService.buildToastMessage('notification', 'error', 'oneRowRequiredMsg', msg, false);
      return;
    }
    const rowIndex = this.preparationDataSource.findIndex(row => row.tagSequenceNumber === data.tagSequenceNumber);
    if (rowIndex !== -1) {
      const deletedRow = this.preparationDataSource[rowIndex];
      this.preparationDataSource.splice(rowIndex, 1);
      this.preparationGrid?.gridApi?.applyTransaction({ remove: [deletedRow] });
      this.changeDetectorRef.detectChanges();
    }
  }
  /*
   *
   * This method is used to set column properties based on some condition
   * Here we are setting allowedUnit  to Original quantity field  when column Type is quantity
   */
  setColumnProperties() {
    this.columnDefinitions.forEach((column) => {
      if (column.columnType === ColumnType.quantity) {
        column.allowedUnits = this.originalQuantity;
      }
    })
  }

  ngOnDestroy() {
    UnsubscribeAll(this.subscriptions);
  }
}
