/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { RecipeAddPreparationCommand } from '../../models/recipe-add-preparation-command';
import { RecipeNewPreparations } from '../../models/recipe-new-preparations';

export interface PreparationsCreatePreparationPost$Plain$Params {
  'X-User-Puid'?: any;
  'X-User-LabsiteCode'?: any;
      body?: RecipeAddPreparationCommand
}

export function preparationsCreatePreparationPost$Plain(http: HttpClient, rootUrl: string, params?: PreparationsCreatePreparationPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<RecipeNewPreparations>> {
  const rb = new RequestBuilder(rootUrl, preparationsCreatePreparationPost$Plain.PATH, 'post');
  if (params) {
    rb.header('X-User-Puid', params['X-User-Puid'], {});
    rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
    rb.body(params.body, 'application/*+json');
  }

  return http.request(
    rb.build({ responseType: 'text', accept: 'text/plain', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<RecipeNewPreparations>;
    })
  );
}

preparationsCreatePreparationPost$Plain.PATH = '/preparations/create-preparation';
