<app-internal-comments></app-internal-comments>
<app-statements></app-statements>
<app-change-reason></app-change-reason>
<app-data-package></app-data-package>
<app-comments></app-comments>
<div class="eln-layout-wrapper">
  <app-main-menu (contextmenu)="onContextMenuClick($event)" [menuItems]="menuItems" [showElnMenuItem]="true" [draggable]="true" (ReorderedNavItems)="activitiesReOrdered($event)" [draggingEnabled]="experimentNodeReOrderService.hasPermission() && experimentNodeReOrderService.hasPermissionWithRespectToFlow()"></app-main-menu>
  <div class="eln-layout-content-container">
    <div *ngIf="loading || auditLoading" class="eln-layout-content">
      <bpt-progress-spinner [useOverlay]="true" [message]="loadingMessage"></bpt-progress-spinner>
    </div>
    <div *ngIf="!loading" class="eln-layout-content">
      <app-topbar
        *ngIf="experimentOptions.previewMode"
        [title]="experiment?.title"
        [visibleInLims]="true"
      ></app-topbar>
      <app-topbar
        *ngIf="!experimentOptions.previewMode"
        [subTitle]="experimentSubHeader"
        [title]="experimentHeader"
        [visibleInLims]="true"
      >
      </app-topbar>
      <app-middlebar
        *ngIf="experimentOptions.previewMode"
        [experiment]="true"
        [enableBreadcrumb]="false"
        [toolbarCenter]="previewModeBanner"
        styleClass="toolbar-no-inline-padding experiment-preview-banner"
      ></app-middlebar>
      <app-middlebar
        [experiment]="true"
        [toolbarRight]="right"
        [enableBreadcrumb]="false"
        [toolbarLeft]="left"
      ></app-middlebar>
      <app-warning-dialog-box *ngIf="!isCollaborator && !isExperimentAuthorizedOrCancelled">
      </app-warning-dialog-box>
      <ng-container *ngFor="let warningKey of experimentNotificationService.warningKeys">
        <app-warning-dialog-box *ngIf="experimentNotificationService.warningsHub[warningKey] && experimentNotificationService.warningsHub[warningKey].warnings!.length > 0" [validation]="experimentNotificationService.warningsHub[warningKey]">
        </app-warning-dialog-box>
      </ng-container>
      <ng-container>
        <div *ngIf="successMessages.length > 0" class="eln-success">
          <p-messages [value]="successMessages" [closable]="true"></p-messages>
        </div>
      </ng-container>
      <div *ngIf="!isInvalid" class="experiment-content-container">
        <div class="experiment-content">
          <router-outlet></router-outlet>
        </div>
      </div>
      <div *ngIf="isInvalid" class="eln-error">
        <p-messages [value]="errorMessages" [closable]="false"></p-messages>
      </div>
    </div>
    <div class="eln-layout-footer"></div>
  </div>
  <div class="eln-experimentConfirmDialog" id="eln-experimentConfirmationDialog">
    <p-confirmDialog
      [position]="'center'"
      rejectButtonStyleClass="p-button-outlined-compact"
      acceptButtonStyleClass="bpt-button-compact"
      id="startExperimentConfirmation"
    >
    </p-confirmDialog>
    <p-dialog
      [(visible)]="showReconnectDialog"
      i18n-header="@@information"
      header="Information"
      [closable]="false"
      [modal]="true"
      [draggable]="false"
      [resizable]="false"
      styleClass="dialogClass"
      id="reConnectDialog"
    >
      <p i18n="@@disconnectedMessage">
        You are disconnected from the experiment. Click on Reconnect button below to connect to the
        experiment.
      </p>
      <ng-template pTemplate="footer">
        <p-button
          i18n-label="@@reconnect"
          id="btnReconnect"
          label="Reconnect"
          (onClick)="reConnect()"
          *ngIf="!showConnecting"
        ></p-button>
        <em *ngIf="showConnecting" class="pi pi-spin pi-spinner"></em>
        <span *ngIf="showConnecting" class="pl-1" i18n="@@reconnecting">Reconnecting...</span>
      </ng-template>
    </p-dialog>
  </div>
  <app-toast></app-toast>

  <ng-template #right>
    <app-instrument-connection *ngIf="showConnectionIcon"></app-instrument-connection>
    <app-experiment-flags
      class="cursor-pointer"
      [userHasAccess]="canRaiseExperimentFlags"
    ></app-experiment-flags>
    <app-experiment-notification [enableNotification]="true"></app-experiment-notification>
  </ng-template>
  <ng-template #left>
    <div class="flex flex-row justify-content-between">
      <div *ngIf="workflowOptions.length > 0" [ngClass]="{'workflow-menu-enabled' : enableWorkflowAction, 'workflow-menu-disabled' : !enableWorkflowAction}">
        <bpt-menu-button
          id="eln-workflowStateOptions"
          [model]="workflowOptions"
          [label]="currentWorkflowState"
          [icon]="currentWorkflowIcon"
          [disabled] = "!enableWorkflowAction"
        ></bpt-menu-button>
      </div>
      <app-activity-outputs-instrument-data-dropdown
        *ngIf="isOutputsEnabled"
        class="instrument-data"
      ></app-activity-outputs-instrument-data-dropdown>

      <div class="flex flex-row justify-content-between eln-center-layout">
        <div *ngIf="showBarcodeScanner">
          <bpt-toolbar [toolbarIcons]="toolbarIcons" class="cursor-pointer"></bpt-toolbar>
        </div>
        <app-barcode-scanner
          #barcodeScanner
          *ngIf="showBarcodeSlider"
          (barcodeScannerClose)="closeBarcodeSlider()"
          [scannerOpenMode]="scannerOpenMode"
          [instrumentType]="instrumentType"
          [isBarcodeOpenedManually] = "isBarcodeOpenedManually"
        >
        </app-barcode-scanner>
        <div class="eln-instrument-event" *ngIf="hideAddInstrumentEvent === false">
          <p-button
            styleClass="eln-toolbar-button eln-p-button-text p-button-text {{
            disableAddInstrumentEvent ? 'disabled' : 'pr-0'
          }} font-semibold"
            i18n-label="@@addInstrumentEventTitle"
            label="Instrument Event"
            id="eln-btnAddInstrumentButton"
            icon="icon-s icon-laboratory"
            (onClick)="openBarcodeSlider(BarcodeScannerOpenModeOptions.Instrument)"
          ></p-button>
        </div>
        <div class="add-cross-reference" *ngIf="routeIsReferencesComponent && !activityHasCrossReferences && !showCrossReferenceSlider">
          <p-button
            id="eln-btnAddCrossReference"
            styleClass="eln-toolbar-button eln-p-button-text p-button-text font-semibold mx-1
              {{ disableAddReferences ? 'disabled' : 'pr-0' }}"
            i18n-label="@@crossReference"
            label="Cross Reference"
            (onClick)="showCrossReferencesSliderEvent()"
          ><em class="fas fa-file-export"></em>
          </p-button>
        </div>
        <div class="add-document" *ngIf="routeIsReferencesComponent && !activityHasDocumentReferences">
          <p-button
            id="eln-btnAddDocument"
            styleClass="eln-toolbar-button eln-p-button-text p-button-text font-semibold  mx-1
              {{ disableAddReferences ? 'disabled' : 'pr-0' }}"
            i18n-label="@@document"
            label="Document"
            (onClick)="addDocumentsTableEvent()"
          ><em class="fas fa-file-alt"></em>
          </p-button>
        </div>
        <div class="add-compendium" *ngIf="routeIsReferencesComponent && !activityHasCompendiumReferences">
          <p-button
            id="eln-btnAddCompendium"
            styleClass="eln-toolbar-button eln-p-button-text p-button-text font-semibold mx-1
              {{ disableAddReferences ? 'disabled' : 'pr-0' }}"
            i18n-label="@@compendium"
            label="Compendium"
            (onClick)="addCompendiaTableEvent()"
          ><em class="fas fa-book-medical"></em>
          </p-button>
        </div>
        <div class="add-template">
          <p-button
            styleClass="eln-toolbar-button eln-p-button-text p-button-text {{
              disableAddTemplateButton ? 'disabled' : 'pr-0'
            }} font-semibold"
            i18n-label="@@loadTemplateTitle"
            label="✛ Load Template/Recipe"
            id="eln-btnAddtemplate"
            (onClick)="addTemplateEvent()"
          ></p-button>
        </div>
        <div class="add-template" *ngIf = "hideAddConsumableButton === false">
          <p-button
          styleClass="eln-toolbar-button eln-p-button-text p-button-text {{
            disableAddConsumable ? 'disabled' : 'pr-0'
          }} font-semibold "
            i18n-label="@@addConsumableTitle"
            label="Add Consumable/Supply"
            id="eln-btnAddConsumable"
            (onClick)="addLabItemsConsumable()"
          ></p-button>
        </div>
      </div>
    </div>
  </ng-template>

  <app-recipe-and-template-loader
  *ngIf="showTemplateLoader"
  [loaderSearchCriteria]="loaderSearchCriteria"
  (templateLoaderResult)="updateTemplateSelection($event)"
  [templateLoaderType]="templateLoaderType"
  [insertOptions]="insertOptions"
  [searchControls]="searchControls"
  [toolTip]="toolTip"
  sliderSize="x-large"
  [insertLocationOptions]="insertLocationOptions"
  [showInlineFilters]="true"
  (templateSelectionChangedEvent)="templateSelectionChanged($event)"
  (templateLoaderFilterChangedEvent)="templateLoaderFilterChanged($event)"
  (templateLoaderInsertOptionChangedEvent)="templateLoaderInsertOptionChanged($event)"
  (templateLoaderFilterClearedEvent)="templateLoaderFilterCleared($event)"
  (templatesLoadEvent)="templateSelected($event)"
  [isExperiment]="true"
>
</app-recipe-and-template-loader>
  <ng-template #previewModeBanner>
    <div id="eln-experiment-preview-banner-container" class="pt-2 pb-2">
      <span id="eln-preview-banner-message" i18n="@@experimentPreviewBanner"
        >Experiment Preview</span
      >
    </div>
  </ng-template>
</div>

<ng-container *ngFor="let context of [ specificationEditorContext ]; trackBy: specificationEditorContextId">
  <!--
    ngFor and trackBy get us a new (single, different) app-specification-input upon a new context;
    and removes any app-specification-input upon no context.
  -->
  <app-specification-input *ngIf="context"
    [inputModel]="context.value"
    [readOnly]="context.readOnly"
    [disabled]="context.disabled"
    [allowedUnits]="context.allowedUnits"
    [allowedSpecTypes]="context.allowedSpecTypes"
    [defaultUnit]="context.defaultUnit"
    (valueChanged)="specificationValueChanged($event)"
    (closed)="closeSpecificationInputSlider()"
  ></app-specification-input>
</ng-container>

<app-cross-reference-slider
  [activity]="currentActivity"
  [visible]="showCrossReferenceSlider"
  (closed)="crossReferenceSliderClosed()"
></app-cross-reference-slider>
