import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { UserService } from 'services/user.service';
import { ConfigurationService } from 'services/configuration.service';
import { isEmpty } from 'lodash-es';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {
  constructor(private readonly userService: UserService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let modified = req;
    return from(this.userService.getOauthToken(req.url)).pipe(
      switchMap((authHeader) => {
        if (authHeader !== undefined && authHeader) {
          modified = req.clone({
            setHeaders: {
              ...this.getApiIdentityCommonHeaders(),
              Authorization: `Bearer ${authHeader}`
            }
          });
        }
        return next.handle(modified);
      })
    );
  }

  private getApiIdentityCommonHeaders(): {
    'X-User-Puid'?: string;
    'X-User-LabsiteCode'?: string;
  } {
    if (this.userService !== null && !isEmpty(this.userService.currentUser)) {
      if(ConfigurationService.isLimsHosted){
        return {
          'X-User-LabsiteCode': this.userService.currentUser.labSiteCode ?? ''
        }
      }else{
        return {
          'X-User-Puid': this.userService.currentUser.puid,
          'X-User-LabsiteCode': this.userService.currentUser.labSiteCode
        };
      }
    }
    return {};
  }
}
