/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { DataPackageResponse as ElnDataPackageApiEntitiesDataPackageResponse } from '../../models/ELN/DataPackage/API/Entities/data-package-response';

export interface ExperimentsExperimentIdDataPackagesSelectedActivitiesPost$Plain$Params {
  experimentId: string;
  'X-User-Puid'?: any;
  'X-User-LabsiteCode'?: any;

/**
 * Please use this header only when the request is for an dataPackageExperiment or preview dataPackageExperiment. Passing true will be considered a request for a preview dataPackageExperiment.
 */
  'X-DataPackageExperiment-PreviewMode'?: any;
      body?: Array<string>
}

export function experimentsExperimentIdDataPackagesSelectedActivitiesPost$Plain(http: HttpClient, rootUrl: string, params: ExperimentsExperimentIdDataPackagesSelectedActivitiesPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ElnDataPackageApiEntitiesDataPackageResponse>> {
  const rb = new RequestBuilder(rootUrl, experimentsExperimentIdDataPackagesSelectedActivitiesPost$Plain.PATH, 'post');
  if (params) {
    rb.path('experimentId', params.experimentId, {"style":"simple"});
    rb.header('X-User-Puid', params['X-User-Puid'], {"style":"simple"});
    rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {"style":"simple"});
    rb.header('X-DataPackageExperiment-PreviewMode', params['X-DataPackageExperiment-PreviewMode'], {"style":"simple"});
    rb.body(params.body, 'application/*+json');
  }

  return http.request(
    rb.build({ responseType: 'text', accept: 'text/plain', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ElnDataPackageApiEntitiesDataPackageResponse>;
    })
  );
}

experimentsExperimentIdDataPackagesSelectedActivitiesPost$Plain.PATH = '/experiments/{experimentId}/data-packages/selected-activities';
