import { Injectable } from "@angular/core";
import {
  RecipeAddColumnPromptCommand,
  RecipeAddConsumableSupplyPromptCommand,
  RecipeAddInstrumentPromptCommand,
  RecipeAddMaterialPromptCommand,
  RecipeAddPreparationPromptCommand,
  RecipeColumnPromptAddedResponse,
  RecipeConsumableSupplyPromptAddedResponse,
  RecipeEditPromptCommand,
  RecipeInstrumentPromptAddedResponse,
  RecipeMaterialPromptAddedResponse,
  RecipePreparationPromptAddedResponse,
  RecipePromptEditResponse,
  RecipePromptRemovedResponse,
  RecipePromptRestoredResponse,
  RecipeRemovePromptCommand,
  RecipeRestorePromptCommand
} from "../../api/cookbook/models";
import { PromptService } from "../../api/cookbook/services";
import { Observable, Subject } from "rxjs";
import { PromptItem, PromptItemColumn, PromptItemConsumableOrSupply, PromptItemInstrument, PromptItemMaterial, PromptItemPreparation, PromptType } from "../../prompt/models/prompt.model";
import { v4 as uuid } from "uuid";

@Injectable()
export class RecipePromptService {
  constructor(
    private readonly promptAPIService: PromptService
  ) { }

  gridCellUpdate = new Subject<void>();

  public constructMaterialCommand(data: PromptItem<PromptType, PromptItemMaterial>,
    recipeId: string, activityId?: string, activityTitle?: string): RecipeAddMaterialPromptCommand {
    const command: RecipeAddMaterialPromptCommand = {
      name: data.value["name"],
      promptId: uuid(),
      recipeId: recipeId,
      activityId: activityId,
      activityTitle: activityTitle,
      additionalDetails: data.value["additionalDetails"],
      clientFacingPrompt: data.value["clientFacingPrompt"],
      brand: data.value["brand"],
      catalog: data.value["catalog"],
      concentration: data.value["concentration"],
      grade: data.value["grade"],
      lot: data.value["lot"],
      materialType: data.value["materialType"],
      purity: data.value["purity"],
      source: data.value["source"],
      type: PromptType.Materials
    }
    return command;
  }

  public editPromptCommand(promptType: PromptType, promptId: string, editedFields: {[key: string]: string}, recipeId: string, activityId?: string): RecipeEditPromptCommand {
    const command: RecipeEditPromptCommand = {
      activityId: activityId,
      recipeId: recipeId,
      type: promptType,
      promptId: promptId,
      editedFields: editedFields
    }
    return command;
  }

  public removePromptCommand(promptType: PromptType, promptId: string, recipeId: string, activityId?: string): RecipeRemovePromptCommand {
    const command: RecipeRemovePromptCommand = {
      activityId: activityId,
      recipeId: recipeId,
      type: promptType,
      promptId: promptId,
    }
    return command;
  }

  public restorePromptCommand(promptType: PromptType, promptId: string, recipeId: string, activityId?: string): RecipeRemovePromptCommand {
    const command: RecipeRestorePromptCommand = {
      activityId: activityId,
      recipeId: recipeId,
      type: promptType,
      promptId: promptId,
    };
    return command;
  }

  public constructColumnCommand(data: PromptItem<PromptType, PromptItemColumn>,
    recipeId: string, activityId?: string, activityTitle?: string): RecipeAddColumnPromptCommand {
    const command: RecipeAddColumnPromptCommand = {
      name: data.value["name"],
      promptId: uuid(),
      recipeId: recipeId,
      activityId: activityId,
      activityTitle: activityTitle,
      dimensions: data.value["dimensions"],
      clientFacingPrompt: data.value["clientFacingPrompt"],
      manufacturer: data.value["manufacturer"],
      catalog: data.value["catalog"],
      packingPhase: data.value["packingPhase"],
      particleSizeOrFilmThickness: data.value["particleSizeOrFilmThickness"],
      serial: data.value["serial"],
      lot: data.value["lot"],
      chromatographyType: data.value["chromatographyType"],
      source: data.value["source"],
      type: PromptType.Columns
    }
    return command;
  }

  public constructInstrumentCommand(data: PromptItem<PromptType, PromptItemInstrument>,
    recipeId: string, activityId?: string, activityTitle?: string): RecipeAddInstrumentPromptCommand {
    const command: RecipeAddInstrumentPromptCommand = {
      name: data.value["name"],
      promptId: uuid(),
      recipeId: recipeId,
      activityId: activityId,
      activityTitle: activityTitle,
      additionalDetails: data.value["additionalDetails"],
      clientFacingPrompt: data.value["clientFacingPrompt"],
      manufacturer: data.value["manufacturer"],
      model: data.value["model"],
      source: data.value["source"],
      type: PromptType.Instruments
    }
    return command;
  }

  public constructPreparationCommand(data: PromptItem<PromptType, PromptItemPreparation>,
    recipeId: string, activityId?: string, activityTitle?: string): RecipeAddPreparationPromptCommand {
    const command: RecipeAddPreparationPromptCommand = {
      name: data.value["name"],
      promptId: uuid(),
      recipeId: recipeId,
      activityId: activityId,
      activityTitle: activityTitle,
      clientFacingPrompt: data.value["clientFacingPrompt"],
      analysis: data.value["analysis"],
      client: data.value["client"],
      compendia: data.value["compendia"],
      concentration: data.value["concentration"],
      containerDescription: data.value["containerDescription"],
      method: data.value["method"],
      project: data.value["project"],
      storageCondition: data.value["storageCondition"],
      source: data.value["source"],
      type: PromptType.Preparations
    }
    return command;
  }

  public constructConsumableSupplyCommand(data: PromptItem<PromptType, PromptItemConsumableOrSupply>,
    recipeId: string, activityId?: string, activityTitle?: string): RecipeAddConsumableSupplyPromptCommand {
    const command: RecipeAddConsumableSupplyPromptCommand = {
      name: data.value["name"],
      promptId: uuid(),
      recipeId: recipeId,
      activityId: activityId,
      activityTitle: activityTitle,
      clientFacingPrompt: data.value["clientFacingPrompt"],
      manufacturer: data.value["manufacturer"],
      catalog: data.value["catalog"],
      part: data.value["part"],
      lot: data.value["lot"],
      details: data.value["details"],
      source: data.value["source"],
      type: PromptType.ConsumablesAndSupplies
    }
    return command;
  }

  publishEditPromptCommand(command: RecipeEditPromptCommand): Observable<RecipePromptEditResponse> {
    return this.promptAPIService.promptsEditRecipePromptPost$Json({ body: command });
  }

  publishRemovePromptCommand(command: RecipeRemovePromptCommand): Observable<RecipePromptRemovedResponse> {
    return this.promptAPIService.promptsRemoveRecipePromptPost$Json({ body: command });
  }

  publishRestorePromptCommand(command: RecipeRestorePromptCommand): Observable<RecipePromptRestoredResponse> {
    return this.promptAPIService.promptsRestoreRecipePromptPost$Json({ body: command });
  }

  publishMaterialPromptCommand(command: RecipeAddMaterialPromptCommand): Observable<RecipeMaterialPromptAddedResponse> {
    return this.promptAPIService.promptsCreateMaterialPost$Json({ body: command });
  }

  publishColumnPromptCommand(command: RecipeAddColumnPromptCommand): Observable<RecipeColumnPromptAddedResponse> {
    return this.promptAPIService.promptsCreateColumnPost$Json({ body: command });
  }

  publishInstrumentPromptCommand(command: RecipeAddInstrumentPromptCommand): Observable<RecipeInstrumentPromptAddedResponse> {
    return this.promptAPIService.promptsCreateInstrumentPost$Json({ body: command });
  }

  publishPreparationPromptCommand(command: RecipeAddPreparationPromptCommand): Observable<RecipePreparationPromptAddedResponse> {
    return this.promptAPIService.promptsCreatePreparationPost$Json({ body: command });
  }

  publishConsumableSupplyPromptCommand(command: RecipeAddConsumableSupplyPromptCommand): Observable<RecipeConsumableSupplyPromptAddedResponse> {
    return this.promptAPIService.promptsCreateConsumableSupplyPost$Json({ body: command });
  }
}