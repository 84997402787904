/* tslint:disable */
/* eslint-disable */
export enum SpecDisplayType {
  Invalid = 'invalid',
  Inequality = 'inequality',
  Expression = 'expression',
  Abbreviation = 'abbreviation',
  ToleranceRange = 'toleranceRange',
  Range = 'range',
  ToleranceRangeAndRange = 'toleranceRangeAndRange'
}
