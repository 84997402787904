import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { RecipeService } from '../../api/cookbook/services';
import { RecipePreLoadDataTransformType, ScaledActivity, ScaledModule, ScaledPreparation } from '../../api/cookbook/models';
import { RecipeRecord } from '../../api/search/models';
import { ScalableRecipeConstants } from './scalable-recipe-constants';
import { BptGridComponent, BptGridMode, BptGridRowActionConfiguration, ColumnDefinition } from 'bpt-ui-library/bpt-grid';
import { DateAndInstantFormat, formatInstant  } from '../../shared/date-time-helpers';
import { ExperimentTemplateApplyService } from '../../template-loader/experiment-template-load/services/experiment-template-apply.service';
import { MessageService } from 'primeng/api';
import { IRowNode } from 'ag-grid-community';
import { ExperimentTemplateEventService } from '../../template-loader/experiment-template-load/services/experiment-template-event.service';
import { Subscription } from 'rxjs';
import { UnsubscribeAll } from '../../shared/rx-js-helpers';

@Component({
  selector: 'app-scalable-recipe-slider',
  templateUrl: './scalable-recipe-slider.component.html',
  styleUrls: ['./scalable-recipe-slider.component.scss']
})
export class ScalableRecipeSliderComponent implements OnInit {
  @ViewChildren('specificationGrid') specificationGrid!: QueryList<BptGridComponent>;
  @Input() recipe: RecipeRecord | undefined;
  @Input() set templateLoadResult(value: boolean | undefined) {
    if (value !== undefined) this.handleTemplateLoadResult(value);
  }
  @Output() closingScalableRecipeSlider = new EventEmitter<boolean>();
  @Output() applyRecipe = new EventEmitter<void>();

  scalableRecipeDetails: {
    name: string;
    sbu: string;
    version: string;
    publishedOn: string | undefined;
  } = {
    name: '',
    sbu: '',
    version: '',
    publishedOn: ''
  };

  scalableRecipeSliderOptions: {
    visible: boolean;
    closeOnEscape: boolean;
    primaryButtonLabel: string;
  } = {
    visible: true,
    closeOnEscape: true,
    primaryButtonLabel: $localize`:@@load:Load`
  }

  sbuLabel = $localize`:@@sbu:SBU`;
  versionLabel = $localize`:@@versionLabel:Version`;
  publishedOnLabel = $localize`:@@publishedOnLabel:Published On`;
  scalableTableLabel = $localize`:@@scalableTable:Scalable Table`;
  preparationColumnDefs!: ColumnDefinition[]
  specificationColumnDefs!: ColumnDefinition[]
  showRecipeScalableSlider = true;
  activities: ScaledActivity[] = [];
  gridMode = BptGridMode.dataEntry;
  gridActions!: BptGridRowActionConfiguration;
  private readonly subscriptions: Subscription[] = [];

  dataSourceMap: { [key: string]: any[] } = {};

  constructor(
    private readonly recipeService: RecipeService,
    private readonly messageService: MessageService,
    private readonly experimentTemplateApplyService: ExperimentTemplateApplyService,
    private readonly experimentTemplateEventService: ExperimentTemplateEventService,
  ) {
    this.preparationColumnDefs = ScalableRecipeConstants.getColumnDefinitions(this.messageService).preparation;
    this.specificationColumnDefs = ScalableRecipeConstants.getColumnDefinitions(this.messageService).specification;
  }

  ngOnInit(): void {
    this.initializeScalableRecipeDetails();
    this.loadScalableRecipe();
    this.watchRecipeApplyService();
  }

  watchRecipeApplyService() {
    this.subscriptions.push(this.experimentTemplateEventService.RecipeApplied.subscribe((_response) => {
      this.showRecipeScalableSlider = false;
      this.scalableRecipeSliderOptions.visible = false;
      this.closeSlider(false);
    }));
  }

  private initializeScalableRecipeDetails(): void {
    if (this.recipe) {
      this.scalableRecipeDetails.name = this.recipe.name;
      this.scalableRecipeDetails.sbu = this.recipe.subBusinessUnits.length > 0 ? this.recipe.subBusinessUnits[0].code : '';
      this.scalableRecipeDetails.version = this.recipe.version;
      if (this.recipe.publishedOn)
        this.scalableRecipeDetails.publishedOn = formatInstant(this.recipe?.publishedOn, DateAndInstantFormat.date);
    }
  }

  loadScalableRecipe() {
    if (this.recipe?.recipeId) {
      const params = {
        recipeId: this.recipe.recipeId
      };
      this.recipeService.recipesRecipeIdWithScalingGet$Json(params).subscribe((activities: ScaledActivity[]) => {
        this.activities = activities;
        this.mapSpecificationDataSource(activities);
      });
    }
  }

  mapSpecificationDataSource(activities: ScaledActivity[]): void {
    this.dataSourceMap = activities.reduce((map, activity) => {
      activity.modules.forEach(module => {
        const moduleId = module.moduleId;
        map[moduleId] = module.tables.map((table, index) => ({
          index: index + 1,
          tableId: table.tableId,
          tableName: table.itemTitle,
          factor: "1"
        }));
      });
      return map;
    }, {} as { [key: string]: any[] });
  }

  hasScalablePreparations(preparations: ScaledPreparation[]): boolean {
    return preparations.some(preparation =>
      preparation.transformTypes.includes(RecipePreLoadDataTransformType.ScaleUp) ||
      preparation.transformTypes.includes(RecipePreLoadDataTransformType.ScaleDown)
    );
  }

  hasScalableTables(modules: ScaledModule[]): boolean {
    return modules.some(module => module.tables.length > 0);
  }

  onCancel() {
    this.showRecipeScalableSlider = false;
    this.scalableRecipeSliderOptions.visible = false;
    this.closeSlider(false);
  }

  closeSlider(isClosed: boolean) {
    this.closingScalableRecipeSlider.emit(isClosed);
  }

  getScaledFactors(): { [key: string]: number } {
    const scaledFactors: { [key: string]: number } = {};
    for (const moduleId in this.dataSourceMap) {
      this.dataSourceMap[moduleId].forEach(table => {
        if (table.factor !== null && table.factor != 1) {
          scaledFactors[table.tableId] = table.factor;
        }
      });
    }
    return scaledFactors;
  }

  onLoad() {
    const scaledFactors = this.getScaledFactors();
    this.experimentTemplateApplyService.setScaledFactors(scaledFactors);
    this.applyRecipe.emit();
  }

  handleTemplateLoadResult(value: boolean): void {
    if (value) {
      this.onCancel();
    } else {
      this.setAllFactorsToOne();
    }
  }

  setAllFactorsToOne(): void {
    const factorColumnId = 'factor';
    this.specificationGrid.forEach(grid => {
      const rowNodes = grid.gridApi.getRenderedNodes();
      rowNodes.forEach((node: IRowNode<any>) => {
        node.setDataValue(factorColumnId, 1);
      });
    });
  }

  ngOnDestroy(): void {
    UnsubscribeAll(this.subscriptions)
  }
}