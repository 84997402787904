import { Injectable } from "@angular/core";
import { ActivatedRoute, ActivationStart, Router } from "@angular/router";
import { filter, Subject } from "rxjs";
import { InstrumentDetailsDto } from "../../../api/instrument-admin/models";
import { ExperimentWorkflowState } from "../../../api/models";
import { ClientState } from "../../../app.states";
import { ExperimentService } from "../../services/experiment.service";
import { UserService } from 'services/user.service';
import { InstrumentConfigMethods } from "./instrument-config-methods";
import { InstrumentConfigUnits } from "./instrument-config-units";
import { InstrumentPushValues } from "./instrument-push-values";
import { InstrumentsReadingResponse } from "./instrument-getreading-response";
import { DataRecordService } from "../../services/data-record.service";
import { WorkflowEventNotification } from "../../../api/data-entry/models";
import { PhDeviceInfo } from "../../services/ph-meter.service";
import { InstrumentType } from "./instrument-type";
import { InstrumentConnectionState } from "./instrument-connection-state";
import { PhMeasurementCollection } from "../../model/instrument-connection/ph-measurement-collection";
import { PhMeterMode } from "../../model/instrument-connection/ph-meter-modes";

@Injectable()
export class InstrumentConnectionHelper {
  readonly instrumentConnectionVisibilityUpdated = new Subject<boolean>();
  readonly instrumentConnectionVisibilityOnCoverPageUpdated = new Subject<boolean>();
  readonly instrumentConnectionUpdatedOnWorkflowStateChange = new Subject<boolean>();
  readonly instrumentConnectionStarted = new Subject();
  readonly connectionConfigCancelled = new Subject();
  readonly instrumentConnectionSuccess = new Subject<string>();
  readonly instrumentDetailsFetched = new Subject();
  readonly instrumentLIMSDetailsFetched = new Subject();
  readonly instrumentJoinHubStatus = new Subject<boolean>();
  readonly instrumentConnectionSuccessForOtherExperiments = new Subject<string | undefined>();
  readonly instrumentConnectionFailed = new Subject<string>();
  readonly instrumentDisconnectedSuccessfully = new Subject();
  readonly instrumentPausedSuccessfully = new Subject<InstrumentConnectionState>();
  readonly instrumentConnectionPausedSuccessfully = new Subject<InstrumentConnectionState>();
  readonly instrumentUnpaused = new Subject();
  readonly instrumentConnectionUnpausedSuccessfully = new Subject<InstrumentConnectionState>();
  readonly instrumentDetailsFetchInitiated = new Subject();
  readonly instrumentGetReadings = new Subject<InstrumentsReadingResponse>();
  readonly disconnectOnReadOnly = new Subject<boolean>();
  readonly goNextClicked = new Subject<InstrumentConfigMethods>();
  readonly sequentialReadingIsInProgress = new Subject<boolean>();
  readonly phMeterConnectionStarted = new Subject<void>();
  readonly pHMeterConnectedForOtherExperiments = new Subject<boolean>();
  readonly pHMeterConnectedForCurrentExperiment = new Subject<boolean>();
  readonly phMeterConnectionSuccess = new Subject<PhDeviceInfo>();
  readonly phMeterDisconnectedSuccessfully = new Subject<PhDeviceInfo | undefined | void>();
  readonly phMeterConnectionSuccessfulForOtherExperiments = new Subject<string | undefined>();
  readonly pageLoaded = new Subject<boolean>(); // initially it's a empty stream, sets to true on hub connection success
  readonly phMeterIsDisconnected = new Subject<boolean>();
  readonly phMeterActiveSession = new Subject<boolean>();
  readonly phMeterReadingsReceived = new Subject<PhMeasurementCollection>();
  readonly detectInstrumentConnectionChanged = new Subject<boolean>();

  currentClientState = '';
  private readonly permittedWorkflowStates = [
    ExperimentWorkflowState.InProgress,
    ExperimentWorkflowState.InCorrection
  ];

  private readonly readOnlyStates = [
    ExperimentWorkflowState.Setup,
    ExperimentWorkflowState.InReview
  ];

  private readonly permittedClientStates = [
    ClientState.EXPERIMENT_ACTIVITIES
  ];

  private currentWorkflowState!: ExperimentWorkflowState;
  private _isInstrumentConnected = false;
  private _isInstrumentPaused = false;
  private _connectedInstrument?: InstrumentDetailsDto;
  balanceName?: string;
  phName?: string;
  _instrumentType?: InstrumentType;
  _equipmentId?: string;
  instrumentDisconnected = false;

  /** Instrument type and sometimes the details for the 0 or 1 instrument connected and not paused in this browser tab */
  public get activeInThisTab(): { type: InstrumentType, details?: InstrumentDetailsDto } | undefined {
    if (!this._isInstrumentConnected) return undefined;
    if (this._isInstrumentPaused) return undefined;
    const type = this.getInstrumentType();
    if (!type) return undefined;

    return { type,  details: this._connectedInstrument };
  }

  public get isInstrumentConnected(): boolean {
    return this._isInstrumentConnected;
  }

  public get isInstrumentConnectionAvailable(): boolean {
    return !this._isInstrumentPaused && this.allowInstrumentConnection();
  }

  public get ispHInstrumentConnectionAvailable(): boolean {
    return !this._isInstrumentPaused && this.allowInstrumentConnection() && (this.instrumentType === InstrumentType.phMeter || localStorage.getItem('instrumentType') === InstrumentType.phMeter);
  }

  public get connectedInstrument(): InstrumentDetailsDto | undefined {
    return this._connectedInstrument;
  }

  public set connectedInstrument(details: InstrumentDetailsDto | undefined) {
    this._connectedInstrument = details;
  }

  public set instrumentType(instrumentType: InstrumentType | undefined) {
    this._instrumentType = instrumentType;
  }

  public get instrumentType(): InstrumentType | undefined {
    return this._instrumentType;
  }

  public get getInstrumentTypeFromLocalStorage(): string | undefined {
    return localStorage.getItem('instrumentType') ?? undefined;
  }

  configuredMethod!: InstrumentConfigMethods;
  selectedMethod?: InstrumentConfigMethods;
  configuredUnit?: InstrumentConfigUnits | string;
  configuredPushValue!: InstrumentPushValues;
  phMeterReadingSessionActive = false;
  pHMeterSequentialReadingSelectedUnit: PhMeterMode | undefined;

  constructor(
    private readonly experimentService: ExperimentService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly userService: UserService,
    private readonly dataRecordService: DataRecordService
  ) {
  }

  manageConnectionIconVisibility(): void {
    if (!this.experimentService.currentExperiment) return;
    this.currentWorkflowState = this.experimentService.currentExperiment.workflowState;

    this.route.children[0]?.children[0]?.children[0]?.children[0]?.data.subscribe((params) => {
      this.currentClientState = params.clientState;
      this.instrumentConnectionVisibilityUpdated.next(this.allowInstrumentConnection());
    });

    this.router.events
      .pipe(filter((event: any) => event instanceof ActivationStart))
      .subscribe((data) => {
        this.currentClientState = data.snapshot.data.clientState;
        if (this.currentClientState === ClientState.EXPERIMENT_COVER) {
          this.instrumentConnectionVisibilityOnCoverPageUpdated.next(this.allowInstrumentConnection());
        } else {
          this.instrumentConnectionVisibilityUpdated.next(this.allowInstrumentConnection());
        }
      });

    this.experimentService.experimentWorkFlowState.subscribe({
      next: (state) => {
        this.currentWorkflowState = state;
        this.instrumentConnectionUpdatedOnWorkflowStateChange.next(this.allowInstrumentConnection());
        if (this.isPermittedWorkflowState()) {
          this.instrumentConnectionVisibilityUpdated.next(this.allowInstrumentConnection())
        }
      }
    });

    this.dataRecordService.experimentWorkFlowDataRecordReceiver.subscribe({
      next: (data: WorkflowEventNotification) => {
        this.currentWorkflowState = data.state;
        this.instrumentConnectionVisibilityUpdated.next(this.allowInstrumentConnection());
      }
    });
  }

  isReadonlyWorkflowState() {
    return this.readOnlyStates.includes(this.currentWorkflowState)
  }

  isPermittedWorkflowState() {
    return this.permittedWorkflowStates.includes(this.currentWorkflowState)
  }

  isPermittedClientState() {
    return this.permittedClientStates.includes(this.currentClientState)
  }

  isConnectionButtonVisible() {
    return this.isPermittedClientState() && (this.isPermittedWorkflowState() || this.isReadonlyWorkflowState())
  }

  getSortedInstrumentDetails(details: InstrumentDetailsDto[]) {
    return details.sort((a: any, b: any) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)); //NOSONAR S3358 is n/a
  }

  allowInstrumentConnection() {
    return this.userService.hasAnalystRights(this.userService.currentUser.puid) &&
      this.isPermittedClientState() && this.isPermittedWorkflowState();
  }

  hasUserEditedInstrumentConnectionForPhMeter() {
    return (this.userService.hasAnalystRights(this.userService.currentUser.puid) &&
      this.isPermittedClientState()) && (this.isPermittedWorkflowState() || this.isReadonlyWorkflowState());
  }

  setInstrumentConnectedStatus(status: boolean): void {
    this._isInstrumentConnected = status;
  }

  setInstrumentPausedStatus(status: boolean): void {
    this._isInstrumentPaused = status;
  }

  setConfigurationsForSelectedInstrument(
    selectedMethod: InstrumentConfigMethods,
    selectedUnit: InstrumentConfigUnits | string,
    pushValue: InstrumentPushValues
  ) {
    this.configuredMethod = selectedMethod;
    this.configuredUnit = selectedUnit;
    this.configuredPushValue = pushValue;
  }

  getInstrumentType() {
    const savedInstrumentType = localStorage.getItem('instrumentType');
    let instrumentType = this.instrumentType;
    if (savedInstrumentType) instrumentType = savedInstrumentType as InstrumentType;
    return instrumentType;
  }
}
