import { RuleEvents } from '../../api/models';
import { ChangeFieldCommand } from '../../api/data-entry/models';
import {
  FieldChangedEvent,
  RuleInputDataValue as DataValueAsRuleInput
} from '../events/rule-event-context';
import { ContextFrom, RuleEventContextBaseConverter } from './rule-base-converter';
import { RuleEventContextConverter } from './rule-event-context-converter';
import { RuleActionHandlerHostService } from '../handler/rule-action-handler-host.service';
import { ChangeRecipeFieldCommand } from '../../api/cookbook/models';

export interface FieldChangeEventContextFrom extends ContextFrom {
  changeFieldCommand: ChangeFieldCommand | ChangeRecipeFieldCommand;
  currentForm: {
    [key: string]: any;
  };
}

export class FieldChangedEventConverter
  extends RuleEventContextBaseConverter
  implements RuleEventContextConverter<FieldChangeEventContextFrom>
{
  currentForm: { [key: string]: DataValueAsRuleInput } = {};

  public convert(
    instanceId: string,
    templateId: string,
    context: FieldChangeEventContextFrom
  ): Omit<FieldChangedEvent, 'path'> {
    const currentContext: string = context.changeFieldCommand.path[context.changeFieldCommand.path.length - 1];
    this.formControlBuilder(context.currentForm);
    const currentForm = this.currentForm;
    return {
      event: {
        context: currentContext,
        templateId: templateId,
        templateInstanceId: instanceId,
        eventType: RuleEvents.FieldChanged,
        itemId: RuleActionHandlerHostService.CurrentItemId
      },
      currentForm
    };
  }

  private formControlBuilder(formGroup: any) {
    Object.keys(formGroup).forEach((field) => {
      if (formGroup[field].value === undefined) {
        this.formControlBuilder(formGroup[field]);
      } else {
        this.currentForm[field] = formGroup[field];
      }
    });
  }
}
