import { ColumnDefinition } from 'bpt-ui-library/bpt-grid';
import { ColumnType } from '../../api/models';
import { FilesTabActionsCellRendererComponent } from './files-tab-actions-cell-renderer';
import { FileTypeIconRendererComponent } from './file-type-icon-renderer';

export class FilesTabGridOptions {
  public static prepareColumns(
    downloadAttachmentsCallback?: (data: any )  => void
  ): ColumnDefinition[] {
    return [
      {
        label: '',
        field: 'fileId',
        editable: false,
        hidden: true
      },
      {
        label: '',
        field: 'checkbox',
        editable: false,
        headerCheckboxSelection: true,
        checkboxSelection: true,
        width: 5
      },
      {
        label: $localize`:@@name:Name`,
        field: 'fileName',
        editable: false,
        onCellClicked: downloadAttachmentsCallback?.bind(this),
        cellRenderer: FileTypeIconRendererComponent
      },
      {
        label: $localize`:@@UploadedBy:Uploaded by`,
        field: 'uploadedBy',
        columnType: ColumnType.String,
        editable: false
      },
      {
        label: $localize`:@@FileSize:File size`,
        field: 'fileSize',
        columnType: ColumnType.String,
        editable: false
      },
      {  
        label: $localize`:@@UploadedDate:Uploaded date`,
        field: 'uploadedOn',
        columnType: ColumnType.Date,
        editable: false,
        sort:'desc' ,
      },
      {  
        label: $localize`:@@Action:Action`,
        cellRenderer: FilesTabActionsCellRendererComponent
      }
    ]
  }
}
