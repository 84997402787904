import { Injectable } from '@angular/core';
import { UserPreferenceService } from '../../api/services';
import { ExperimentService } from './experiment.service';
import { UserPreference, UserPreferenceListResponse } from '../../api/models';
import { NodeType } from '../../api/data-entry/models';
import { Form, Table } from '../../model/experiment.interface';
import { GridPreferenceService } from './grid-preference.service';
import {
  BptGridComponent,
  BptGridPreferences,
  PreferenceAdded,
  PreferenceDeleted,
  PreferenceSelectionChanged,
  PreferenceUpdated
} from 'bpt-ui-library/bpt-grid';
import { MessageService, Message } from 'primeng/api';
import { UserService } from '../../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class ExperimentUserPreferenceService {
  constructor(
    private readonly preferenceService: UserPreferenceService,
    private readonly experimentService: ExperimentService,
    private readonly gridPreferenceService: GridPreferenceService,
    private readonly messageService: MessageService,
    private readonly userService: UserService
  ) {}

  loadExperimentUserPreferences(): void {
    const experimentItemIds = this.experimentService.currentExperiment?.activities.flatMap(
      (activity) =>
        activity.dataModules.flatMap((module) =>
          module.items
            .filter((moduleItem) => moduleItem.itemType === NodeType.Table)
            .flatMap((table) => (table as Table).tableId)
        )
    );
    let foundUserPreferences = false;
    this.preferenceService
      .userPreferencesUserBulkPreferencePost$Json$Response({
        body: experimentItemIds
      })
      .subscribe((res) => {
        this.buildExperimentPreferences(res.body);
        foundUserPreferences = true;
      });
    if (!foundUserPreferences) {
      this.buildExperimentPreferences({
        notifications: { notifications: [] },
        userPreferences: []
      });
    }
  }

  private buildExperimentPreferences(data: UserPreferenceListResponse): void {
    const elnGridPreferences = this.buildBptGridPreferences(data.userPreferences);
    this.experimentService.currentExperiment?.activities.forEach((activity) => {
      activity.dataModules.forEach((module) => {
        module.items.forEach((moduleItem) => {
          if (moduleItem.itemType === NodeType.Table) {
            this.applyTablePreferences(moduleItem as Table, elnGridPreferences);
          }
        });
      });
    });
  }

  private buildBptGridPreferences(preferences: UserPreference[]): {
    [itemId: string]: BptGridPreferences;
  } {
    const elnGridPreferences: { [itemId: string]: BptGridPreferences } = {};
    preferences.forEach((p) => {
      const preferenceStates = JSON.parse(p.userPreferenceValue);
      elnGridPreferences[p.userPreferenceKey] = elnGridPreferences[p.userPreferenceKey] || {
        enabled: true,
        preferences: []
      };
      elnGridPreferences[p.userPreferenceKey].preferences.push({
        columnState: preferenceStates.columnState,
        filterState: preferenceStates.filterState,
        isDefault: p.isDefault,
        preferenceName: p.userPreferenceName,
        preferenceId: p.userPreferenceId,
        preferenceKey: p.userPreferenceKey
      });
    });
    return elnGridPreferences;
  }

  private applyTablePreferences(
    moduleItem: Table,
    elnGridPreferences: { [tableId: string]: BptGridPreferences }
  ): void {
    moduleItem.savedPreferences = elnGridPreferences[moduleItem.tableId] || {
      enabled: true,
      preferences: []
    };
    moduleItem.preferencesReady?.next(
      moduleItem.savedPreferences ?? { enabled: true, preferences: [] }
    );
  }

  saveNewPreference(
    $event: PreferenceAdded,
    newPreference: BptGridPreferences,
    grid: BptGridComponent,
    controlId: string
  ): void {
    this.gridPreferenceService.saveNewPreference(
      $event,
      this.userService.currentUser.puid,
      newPreference,
      grid,
      controlId
    );
  }

  deletePreference($event: PreferenceDeleted): void {
    this.gridPreferenceService.deletePreference($event);
  }

  updatePreference($event: PreferenceUpdated, controlId: string): void {
    this.gridPreferenceService.updatePreference(
      $event,
      this.userService.currentUser.puid,
      controlId
    );
  }

  changeDefaultPreference($event: PreferenceSelectionChanged, controlId: string): void {
    this.gridPreferenceService.changeDefaultPreference(
      $event,
      this.userService.currentUser.puid,
      controlId
    );
  }
}
