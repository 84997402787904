<p-toolbar class="dashboard_secondary_toolbar {{ experiment ? 'experiment' : '' }} {{styleClass}}">
  <div class="dashboard_secondary_toolbar_left flex-v-center">
    <ng-container *ngTemplateOutlet="toolbarLeft">
    </ng-container>
    <app-breadcrumb  *ngIf="enableBreadcrumb"></app-breadcrumb>
  </div>
  <div class="dashboard_secondary_toolbar_center flex-v-center">
    <ng-container *ngTemplateOutlet="toolbarCenter">
    </ng-container>
  </div>
  <div class="dashboard_secondary_toolbar_right flex flex-v-center">
    <ng-container *ngTemplateOutlet="toolbarRight"> </ng-container>
  </div>
</p-toolbar>
