/* tslint:disable */
/* eslint-disable */
export enum SpecComparisonOperator {
  Invalid = 'invalid',
  EqualTo = 'equalTo',
  NotEqualTo = 'notEqualTo',
  MoreThan = 'moreThan',
  LessThan = 'lessThan',
  NotLessThan = 'notLessThan',
  NotMoreThan = 'notMoreThan'
}
