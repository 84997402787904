import { Injectable } from '@angular/core';
import {
  BptGridPreference,
  BptGridPreferences,
  PreferenceAdded,
  PreferenceDeleted,
  PreferenceSelectionChanged,
  PreferenceUpdated
} from 'bpt-ui-library/bpt-grid/model/bpt-grid-preference';
import { UserPreference } from '../../api/models';
import { v4 as uuidv4 } from 'uuid';
import { UserPreferenceService } from '../../api/services/user-preference.service';
import { BptGridComponent } from 'bpt-ui-library/bpt-grid';
import { ElnProgressSpinnerService } from '../../eln-progress-spinner-module/eln-progress-spinner.service';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class GridPreferenceService {
  constructor(private readonly preferenceService: UserPreferenceService, 
    private readonly elnProgressSpinnerService: ElnProgressSpinnerService,
    private readonly messageService: MessageService) {}

  buildGridPreference(userPreferences: UserPreference[]): BptGridPreference[] {
    const bptGridPreferences: BptGridPreference[] = [];
    userPreferences.forEach((p) => {
      const preferenceStates = JSON.parse(p.userPreferenceValue);
      bptGridPreferences.push({
        columnState: preferenceStates.columnState,
        filterState: preferenceStates.filterState,
        isDefault: p.isDefault,
        preferenceName: p.userPreferenceName,
        preferenceId: p.userPreferenceId,
        preferenceKey: p.userPreferenceKey
      });
    });
    return bptGridPreferences;
  }

  buildUserPreference(
    bptGridPreference: BptGridPreference,
    userPuid: string,
    controlId: string,
    preferenceId?: string
  ): UserPreference {
    const userPreferenceValue = {
      columnState: bptGridPreference.columnState,
      filterState: bptGridPreference.filterState
    }
    return {
      userPreferenceId: preferenceId ? preferenceId : uuidv4(),
      userPuid: userPuid,
      userPreferenceKey: controlId,
      userPreferenceName: bptGridPreference.preferenceName,
      userPreferenceValue: JSON.stringify(userPreferenceValue),
      isDefault: bptGridPreference.isDefault
    };
  }

  
  saveNewPreference($event: PreferenceAdded, userPuid: string, savedPreferences: BptGridPreferences, grid: BptGridComponent, controlId: string): void {
    const spinnerOptions = {
      message: $localize`:@@savingPreference:Preference is being saved: ${$event.context.preferenceName}`,
      i18nMessage: `@@savingPreference`
    };
    this.elnProgressSpinnerService.Show(spinnerOptions);
    const existingPreference = savedPreferences.preferences.find(
      (x) => x.preferenceName === grid.selectedPreference?.preferenceName
    );
    if (existingPreference !== undefined) {
      $event.context.preferenceId = existingPreference.preferenceId;
      $event.context.preferenceKey = existingPreference.preferenceKey;
      return ;
    }
    const preferenceToBeSaved = $event.context;
    const newPreference: UserPreference = this.buildUserPreference(
      preferenceToBeSaved,
      userPuid,
      controlId
    );
    this.preferenceService
      .userPreferencesSaveUserPreferencePost$Json$Response({
        body: newPreference
      })
      .subscribe((res) => {
        preferenceToBeSaved.preferenceId = res.body.userPreference?.userPreferenceId;
        preferenceToBeSaved.preferenceKey = res.body.userPreference?.userPreferenceKey;
        $event.preferenceSaved(preferenceToBeSaved);
        this.elnProgressSpinnerService.Hide();

        this.messageService.add({
          key: 'notification',
          severity: 'success',
          summary: $localize`:@@preferenceSaved:Preference saved`,
          detail: $localize`:@@preferenceSaveSuccess:Preference saved successfully: ${$event.context.preferenceName}`
        });
      });
  }

  deletePreference($event: PreferenceDeleted): void {
    const spinnerOptions = {
      message: $localize`:@@deletingPreference:Preference is deleting: ${$event.context.preferenceName}`,
      i18nMessage: `@@deletingPreference`
    };
    this.elnProgressSpinnerService.Show(spinnerOptions);
    if (!$event.context.preferenceId) return;
    this.preferenceService
      .userPreferencesUserPreferenceIdDelete$Json$Response({
        userPreferenceId: $event.context.preferenceId
      })
      .subscribe((res) => {
        $event.preferenceDeleted($event.context);
        this.elnProgressSpinnerService.Hide();

        this.messageService.add({
          key: 'notification',
          severity: 'success',
          summary: $localize`:@@preferenceDelete:Preference deleted`,
          detail: $localize`:@@preferenceDeleteSuccess:Preference deleted successfully: ${$event.context.preferenceName}`
        });
      });
  }

  updatePreference($event: PreferenceUpdated, userPuid: string, controlId: string): void {
    const spinnerOptions = {
      message: $localize`:@@updatingPreference:Preference is updating: ${$event.context.preferenceName}`,
      i18nMessage: `@@updatingPreference`
    };
    this.elnProgressSpinnerService.Show(spinnerOptions);
    if (!$event.context.preferenceKey) return;
    const newPreference: UserPreference = this.buildUserPreference(
      $event.context,
      userPuid,
      controlId,
      $event.context.preferenceId
    );
    this.preferenceService
      .userPreferencesUpdateUserPreferencePut$Json$Response({
        body: newPreference
      })
      .subscribe((res) => {
        $event.preferenceUpdated($event.context);
      });
    this.preferenceService
      .userPreferencesUserPreferenceKeyUserPreferenceNamePost$Json$Response({
        userPreferenceId: $event.context.preferenceId,
        userPreferenceKey: $event.context.preferenceKey,
        userPreferenceName: $event.context.preferenceName
      })
      .subscribe((res) => {
        $event.preferenceUpdated($event.context);
        this.elnProgressSpinnerService.Hide();

        this.messageService.add({
          key: 'notification',
          severity: 'success',
          summary: $localize`:@@preferenceUpdate:Preference updated`,
          detail: $localize`:@@preferenceUpdateSuccess:Preference updated successfully: ${$event.context.preferenceName}`
        });
      });
  }

  changeDefaultPreference($event: PreferenceSelectionChanged, userPuid: string, controlId: string): void {
    const spinnerOptions = {
      message: $localize`:@@updatingPreference:Preference is updating: ${$event.context.preferenceName}`,
      i18nMessage: `@@updatingPreference`
    };
    this.unSelectCurrentPreference($event, userPuid, controlId);
    if (!$event.context.preferenceId || !$event.context.preferenceKey) return;
    this.elnProgressSpinnerService.Show(spinnerOptions);
    this.preferenceService
      .userPreferencesSetDefaultUserPreferenceIdUserPreferenceKeyPost$Json({
        userPreferenceId: $event.context.preferenceId,
        userPreferenceKey: $event.context.preferenceKey
      })
      .subscribe((_res) => {
        $event.preferenceSelectionChanged($event.context);
        this.elnProgressSpinnerService.Hide();

        this.messageService.add({
          key: 'notification',
          severity: 'success',
          summary: $localize`:@@preferenceUpdate:Preference Changed/Updated`,
          detail: $localize`:@@preferenceUpdateSuccess:Preference changed/updated successfully: ${$event.context.preferenceName}`
        });
      });
  }


  /**
   * This method provides makes an API call to set the default state of previous selected preference to false
   * when user selects the default layout as preference. the backend service has mechanism to manage the default state
   * for all preferences except the default layout which is not being saved in preferences storage.
   */
  public unSelectCurrentPreference(
    $event: PreferenceSelectionChanged,
    userPuid: string,
    controlId: string
  ): void {
    if (
      $event.context.preferenceName.toLowerCase() === 'defaultlayout' &&
      $event?.previousSelectedPreference
    ) {
      const undoCurrentDefaultPreference: PreferenceUpdated = {
        context: { ...$event?.previousSelectedPreference, ...{ isDefault: false } },
        preferenceUpdated: (_updatedPreference: BptGridPreference) => {}
      };
      this.updatePreference(undoCurrentDefaultPreference, userPuid, controlId);
    }
  }
}
