/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { User } from '../models/user';
import { usersActiveUsersPost$Json } from '../fn/user/users-active-users-post-json';
import { UsersActiveUsersPost$Json$Params } from '../fn/user/users-active-users-post-json';
import { usersActiveUsersPost$Plain } from '../fn/user/users-active-users-post-plain';
import { UsersActiveUsersPost$Plain$Params } from '../fn/user/users-active-users-post-plain';

@Injectable({ providedIn: 'root' })
export class UserService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `usersActiveUsersPost()` */
  static readonly UsersActiveUsersPostPath = '/users/active-users';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersActiveUsersPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  usersActiveUsersPost$Plain$Response(params?: UsersActiveUsersPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<User>>> {
    return usersActiveUsersPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersActiveUsersPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  usersActiveUsersPost$Plain(params?: UsersActiveUsersPost$Plain$Params, context?: HttpContext): Observable<Array<User>> {
    return this.usersActiveUsersPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<User>>): Array<User> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersActiveUsersPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  usersActiveUsersPost$Json$Response(params?: UsersActiveUsersPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<User>>> {
    return usersActiveUsersPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersActiveUsersPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  usersActiveUsersPost$Json(params?: UsersActiveUsersPost$Json$Params, context?: HttpContext): Observable<Array<User>> {
    return this.usersActiveUsersPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<User>>): Array<User> => r.body)
    );
  }

}
