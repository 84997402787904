<div class="eln-field-group">
  <p-fieldset
    legend="{{ group.itemTitle }}"
    [style.gridColumn]="group.column"
    [style.gridRow]="group.row"
    [style.width.%]="group.widthPct"
    [class]="isDisabled(group)"
    [toggleable]="group.toggleable"
    [collapsed]="group.collapsed"
  >
    <div class="eln-field-layout-grid">
      <div
        *ngFor="let field of group?.fieldDefinitions"
        class="eln-field-group-item"
        [style.gridColumn]="field.column"
        [style.gridRow]="field.row"
        [class]="isDisabled(field)"
      >
        <ng-container [ngSwitch]="field.itemType">
          <app-data-field-group
            *ngSwitchCase="'fieldGroup'"
            [group]="asFieldGroup(field)"
            [value]="value[field.field]"
            [experimentId]="experimentId"
            [parentNodeId]="parentNodeId"
            [formId]="formId"
            [path]="childPath(field.field)"
            [formEvents]="formEvents"
            (fieldChangedEvent)="applyFieldChangedEvent($event)"
            (fieldReady)="applyFieldReadyEvent($event)"
          ></app-data-field-group>
          <app-data-field
            *ngSwitchDefault
            [fieldDefinition]="asFieldDefinition(field)"
            [value]="value && value[field.field]"
            [experimentId]="experimentId"
            [formId]="formId"
            [parentNodeId]="parentNodeId"
            [path]="childPath(field.field)"
            [formEvents]="formEvents"
            (fieldChangedEvent)="applyFieldChangedEvent($event)"
            (fieldReady)="applyFieldReadyEvent($event)"
            [greatestTabOrder]="greatestTabOrder"
          ></app-data-field>
        </ng-container>
      </div>
    </div>
  </p-fieldset>
</div>
