/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AuditHistoryDataRecordResponse } from '../models/audit-history-data-record-response';
import { experimentAuditHistoryExperimentIdCoverGet$Json } from '../fn/experiment-audit-history/experiment-audit-history-experiment-id-cover-get-json';
import { ExperimentAuditHistoryExperimentIdCoverGet$Json$Params } from '../fn/experiment-audit-history/experiment-audit-history-experiment-id-cover-get-json';
import { experimentAuditHistoryExperimentIdCoverGet$Plain } from '../fn/experiment-audit-history/experiment-audit-history-experiment-id-cover-get-plain';
import { ExperimentAuditHistoryExperimentIdCoverGet$Plain$Params } from '../fn/experiment-audit-history/experiment-audit-history-experiment-id-cover-get-plain';
import { experimentAuditHistoryExperimentIdGet$Json } from '../fn/experiment-audit-history/experiment-audit-history-experiment-id-get-json';
import { ExperimentAuditHistoryExperimentIdGet$Json$Params } from '../fn/experiment-audit-history/experiment-audit-history-experiment-id-get-json';
import { experimentAuditHistoryExperimentIdGet$Plain } from '../fn/experiment-audit-history/experiment-audit-history-experiment-id-get-plain';
import { ExperimentAuditHistoryExperimentIdGet$Plain$Params } from '../fn/experiment-audit-history/experiment-audit-history-experiment-id-get-plain';

@Injectable({ providedIn: 'root' })
export class ExperimentAuditHistoryService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `experimentAuditHistoryExperimentIdGet()` */
  static readonly ExperimentAuditHistoryExperimentIdGetPath = '/experiment-audit-history/{experimentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentAuditHistoryExperimentIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentAuditHistoryExperimentIdGet$Plain$Response(params: ExperimentAuditHistoryExperimentIdGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<AuditHistoryDataRecordResponse>> {
    return experimentAuditHistoryExperimentIdGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentAuditHistoryExperimentIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentAuditHistoryExperimentIdGet$Plain(params: ExperimentAuditHistoryExperimentIdGet$Plain$Params, context?: HttpContext): Observable<AuditHistoryDataRecordResponse> {
    return this.experimentAuditHistoryExperimentIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<AuditHistoryDataRecordResponse>): AuditHistoryDataRecordResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentAuditHistoryExperimentIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentAuditHistoryExperimentIdGet$Json$Response(params: ExperimentAuditHistoryExperimentIdGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<AuditHistoryDataRecordResponse>> {
    return experimentAuditHistoryExperimentIdGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentAuditHistoryExperimentIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentAuditHistoryExperimentIdGet$Json(params: ExperimentAuditHistoryExperimentIdGet$Json$Params, context?: HttpContext): Observable<AuditHistoryDataRecordResponse> {
    return this.experimentAuditHistoryExperimentIdGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<AuditHistoryDataRecordResponse>): AuditHistoryDataRecordResponse => r.body)
    );
  }

  /** Path part for operation `experimentAuditHistoryExperimentIdCoverGet()` */
  static readonly ExperimentAuditHistoryExperimentIdCoverGetPath = '/experiment-audit-history/{experimentId}/cover';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentAuditHistoryExperimentIdCoverGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentAuditHistoryExperimentIdCoverGet$Plain$Response(params: ExperimentAuditHistoryExperimentIdCoverGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<AuditHistoryDataRecordResponse>> {
    return experimentAuditHistoryExperimentIdCoverGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentAuditHistoryExperimentIdCoverGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentAuditHistoryExperimentIdCoverGet$Plain(params: ExperimentAuditHistoryExperimentIdCoverGet$Plain$Params, context?: HttpContext): Observable<AuditHistoryDataRecordResponse> {
    return this.experimentAuditHistoryExperimentIdCoverGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<AuditHistoryDataRecordResponse>): AuditHistoryDataRecordResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentAuditHistoryExperimentIdCoverGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentAuditHistoryExperimentIdCoverGet$Json$Response(params: ExperimentAuditHistoryExperimentIdCoverGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<AuditHistoryDataRecordResponse>> {
    return experimentAuditHistoryExperimentIdCoverGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentAuditHistoryExperimentIdCoverGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentAuditHistoryExperimentIdCoverGet$Json(params: ExperimentAuditHistoryExperimentIdCoverGet$Json$Params, context?: HttpContext): Observable<AuditHistoryDataRecordResponse> {
    return this.experimentAuditHistoryExperimentIdCoverGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<AuditHistoryDataRecordResponse>): AuditHistoryDataRecordResponse => r.body)
    );
  }

}
