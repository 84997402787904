/* tslint:disable */
/* eslint-disable */
export enum ActivityInputType {
  Invalid = 'invalid',
  Aliquot = 'aliquot',
  Instrument = 'instrument',
  InstrumentColumn = 'instrumentColumn',
  Material = 'material',
  InstrumentDetails = 'instrumentDetails',
  Consumable = 'consumable',
  Preparation = 'preparation',
  ExperimentPreparations = 'experimentPreparations'
}
