/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { promptEventsRemovePromptPost$Json } from '../fn/prompt-events/prompt-events-remove-prompt-post-json';
import { PromptEventsRemovePromptPost$Json$Params } from '../fn/prompt-events/prompt-events-remove-prompt-post-json';
import { promptEventsRemovePromptPost$Plain } from '../fn/prompt-events/prompt-events-remove-prompt-post-plain';
import { PromptEventsRemovePromptPost$Plain$Params } from '../fn/prompt-events/prompt-events-remove-prompt-post-plain';
import { promptEventsRestorePromptPost$Json } from '../fn/prompt-events/prompt-events-restore-prompt-post-json';
import { PromptEventsRestorePromptPost$Json$Params } from '../fn/prompt-events/prompt-events-restore-prompt-post-json';
import { promptEventsRestorePromptPost$Plain } from '../fn/prompt-events/prompt-events-restore-prompt-post-plain';
import { PromptEventsRestorePromptPost$Plain$Params } from '../fn/prompt-events/prompt-events-restore-prompt-post-plain';
import { promptEventsSatisfyPromptPost$Json } from '../fn/prompt-events/prompt-events-satisfy-prompt-post-json';
import { PromptEventsSatisfyPromptPost$Json$Params } from '../fn/prompt-events/prompt-events-satisfy-prompt-post-json';
import { promptEventsSatisfyPromptPost$Plain } from '../fn/prompt-events/prompt-events-satisfy-prompt-post-plain';
import { PromptEventsSatisfyPromptPost$Plain$Params } from '../fn/prompt-events/prompt-events-satisfy-prompt-post-plain';
import { PromptRemovedResponse } from '../models/prompt-removed-response';
import { PromptRestoredResponse } from '../models/prompt-restored-response';
import { PromptSatisfiedResponse } from '../models/prompt-satisfied-response';

@Injectable({ providedIn: 'root' })
export class PromptEventsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `promptEventsSatisfyPromptPost()` */
  static readonly PromptEventsSatisfyPromptPostPath = '/prompt-events/satisfy-prompt';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `promptEventsSatisfyPromptPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptEventsSatisfyPromptPost$Plain$Response(params?: PromptEventsSatisfyPromptPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<PromptSatisfiedResponse>> {
    return promptEventsSatisfyPromptPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `promptEventsSatisfyPromptPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptEventsSatisfyPromptPost$Plain(params?: PromptEventsSatisfyPromptPost$Plain$Params, context?: HttpContext): Observable<PromptSatisfiedResponse> {
    return this.promptEventsSatisfyPromptPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<PromptSatisfiedResponse>): PromptSatisfiedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `promptEventsSatisfyPromptPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptEventsSatisfyPromptPost$Json$Response(params?: PromptEventsSatisfyPromptPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<PromptSatisfiedResponse>> {
    return promptEventsSatisfyPromptPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `promptEventsSatisfyPromptPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptEventsSatisfyPromptPost$Json(params?: PromptEventsSatisfyPromptPost$Json$Params, context?: HttpContext): Observable<PromptSatisfiedResponse> {
    return this.promptEventsSatisfyPromptPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<PromptSatisfiedResponse>): PromptSatisfiedResponse => r.body)
    );
  }

  /** Path part for operation `promptEventsRemovePromptPost()` */
  static readonly PromptEventsRemovePromptPostPath = '/prompt-events/remove-prompt';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `promptEventsRemovePromptPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptEventsRemovePromptPost$Plain$Response(params?: PromptEventsRemovePromptPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<PromptRemovedResponse>> {
    return promptEventsRemovePromptPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `promptEventsRemovePromptPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptEventsRemovePromptPost$Plain(params?: PromptEventsRemovePromptPost$Plain$Params, context?: HttpContext): Observable<PromptRemovedResponse> {
    return this.promptEventsRemovePromptPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<PromptRemovedResponse>): PromptRemovedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `promptEventsRemovePromptPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptEventsRemovePromptPost$Json$Response(params?: PromptEventsRemovePromptPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<PromptRemovedResponse>> {
    return promptEventsRemovePromptPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `promptEventsRemovePromptPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptEventsRemovePromptPost$Json(params?: PromptEventsRemovePromptPost$Json$Params, context?: HttpContext): Observable<PromptRemovedResponse> {
    return this.promptEventsRemovePromptPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<PromptRemovedResponse>): PromptRemovedResponse => r.body)
    );
  }

  /** Path part for operation `promptEventsRestorePromptPost()` */
  static readonly PromptEventsRestorePromptPostPath = '/prompt-events/restore-prompt';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `promptEventsRestorePromptPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptEventsRestorePromptPost$Plain$Response(params?: PromptEventsRestorePromptPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<PromptRestoredResponse>> {
    return promptEventsRestorePromptPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `promptEventsRestorePromptPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptEventsRestorePromptPost$Plain(params?: PromptEventsRestorePromptPost$Plain$Params, context?: HttpContext): Observable<PromptRestoredResponse> {
    return this.promptEventsRestorePromptPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<PromptRestoredResponse>): PromptRestoredResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `promptEventsRestorePromptPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptEventsRestorePromptPost$Json$Response(params?: PromptEventsRestorePromptPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<PromptRestoredResponse>> {
    return promptEventsRestorePromptPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `promptEventsRestorePromptPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promptEventsRestorePromptPost$Json(params?: PromptEventsRestorePromptPost$Json$Params, context?: HttpContext): Observable<PromptRestoredResponse> {
    return this.promptEventsRestorePromptPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<PromptRestoredResponse>): PromptRestoredResponse => r.body)
    );
  }

}
