/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { DataPackageResponse as ElnDataPackageApiEntitiesDataPackageResponse } from '../models/ELN/DataPackage/API/Entities/data-package-response';
import { experimentsExperimentIdDataPackagesGet$Json } from '../fn/data-package/experiments-experiment-id-data-packages-get-json';
import { ExperimentsExperimentIdDataPackagesGet$Json$Params } from '../fn/data-package/experiments-experiment-id-data-packages-get-json';
import { experimentsExperimentIdDataPackagesGet$Plain } from '../fn/data-package/experiments-experiment-id-data-packages-get-plain';
import { ExperimentsExperimentIdDataPackagesGet$Plain$Params } from '../fn/data-package/experiments-experiment-id-data-packages-get-plain';
import { experimentsExperimentIdDataPackagesSelectedActivitiesPost$Json } from '../fn/data-package/experiments-experiment-id-data-packages-selected-activities-post-json';
import { ExperimentsExperimentIdDataPackagesSelectedActivitiesPost$Json$Params } from '../fn/data-package/experiments-experiment-id-data-packages-selected-activities-post-json';
import { experimentsExperimentIdDataPackagesSelectedActivitiesPost$Plain } from '../fn/data-package/experiments-experiment-id-data-packages-selected-activities-post-plain';
import { ExperimentsExperimentIdDataPackagesSelectedActivitiesPost$Plain$Params } from '../fn/data-package/experiments-experiment-id-data-packages-selected-activities-post-plain';

@Injectable({ providedIn: 'root' })
export class DataPackageService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `experimentsExperimentIdDataPackagesGet()` */
  static readonly ExperimentsExperimentIdDataPackagesGetPath = '/experiments/{experimentId}/data-packages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentsExperimentIdDataPackagesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentsExperimentIdDataPackagesGet$Plain$Response(params: ExperimentsExperimentIdDataPackagesGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ElnDataPackageApiEntitiesDataPackageResponse>> {
    return experimentsExperimentIdDataPackagesGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentsExperimentIdDataPackagesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentsExperimentIdDataPackagesGet$Plain(params: ExperimentsExperimentIdDataPackagesGet$Plain$Params, context?: HttpContext): Observable<ElnDataPackageApiEntitiesDataPackageResponse> {
    return this.experimentsExperimentIdDataPackagesGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ElnDataPackageApiEntitiesDataPackageResponse>): ElnDataPackageApiEntitiesDataPackageResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentsExperimentIdDataPackagesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentsExperimentIdDataPackagesGet$Json$Response(params: ExperimentsExperimentIdDataPackagesGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ElnDataPackageApiEntitiesDataPackageResponse>> {
    return experimentsExperimentIdDataPackagesGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentsExperimentIdDataPackagesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentsExperimentIdDataPackagesGet$Json(params: ExperimentsExperimentIdDataPackagesGet$Json$Params, context?: HttpContext): Observable<ElnDataPackageApiEntitiesDataPackageResponse> {
    return this.experimentsExperimentIdDataPackagesGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ElnDataPackageApiEntitiesDataPackageResponse>): ElnDataPackageApiEntitiesDataPackageResponse => r.body)
    );
  }

  /** Path part for operation `experimentsExperimentIdDataPackagesSelectedActivitiesPost()` */
  static readonly ExperimentsExperimentIdDataPackagesSelectedActivitiesPostPath = '/experiments/{experimentId}/data-packages/selected-activities';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentsExperimentIdDataPackagesSelectedActivitiesPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentsExperimentIdDataPackagesSelectedActivitiesPost$Plain$Response(params: ExperimentsExperimentIdDataPackagesSelectedActivitiesPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ElnDataPackageApiEntitiesDataPackageResponse>> {
    return experimentsExperimentIdDataPackagesSelectedActivitiesPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentsExperimentIdDataPackagesSelectedActivitiesPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentsExperimentIdDataPackagesSelectedActivitiesPost$Plain(params: ExperimentsExperimentIdDataPackagesSelectedActivitiesPost$Plain$Params, context?: HttpContext): Observable<ElnDataPackageApiEntitiesDataPackageResponse> {
    return this.experimentsExperimentIdDataPackagesSelectedActivitiesPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ElnDataPackageApiEntitiesDataPackageResponse>): ElnDataPackageApiEntitiesDataPackageResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentsExperimentIdDataPackagesSelectedActivitiesPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentsExperimentIdDataPackagesSelectedActivitiesPost$Json$Response(params: ExperimentsExperimentIdDataPackagesSelectedActivitiesPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ElnDataPackageApiEntitiesDataPackageResponse>> {
    return experimentsExperimentIdDataPackagesSelectedActivitiesPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentsExperimentIdDataPackagesSelectedActivitiesPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentsExperimentIdDataPackagesSelectedActivitiesPost$Json(params: ExperimentsExperimentIdDataPackagesSelectedActivitiesPost$Json$Params, context?: HttpContext): Observable<ElnDataPackageApiEntitiesDataPackageResponse> {
    return this.experimentsExperimentIdDataPackagesSelectedActivitiesPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ElnDataPackageApiEntitiesDataPackageResponse>): ElnDataPackageApiEntitiesDataPackageResponse => r.body)
    );
  }

}
