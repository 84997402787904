import { ColumnType } from 'bpt-ui-library/bpt-grid';

export type ElnColumnType = keyof typeof ColumnType | MaskColumnType; 
export interface ColumnInfo {
  columnType: ElnColumnType;
  name: string;
  showAllowNA?: boolean;
  formatNaToUpperCase?: boolean;
  showAllowDecimal?: boolean;
  showAllowNegative?: boolean;
  hideAllowEditing?: boolean;
  hideDisableGrouping?: boolean;
  showMultiLineText?: boolean;
  showAutoResize?: boolean;
  showCharLengthSettings?: boolean;
  showNumericLimit?: boolean;
  showDateOptions?: boolean; // e.g. minDate and maxDate
  showTimeSelect?: boolean;
  showButtonBar?: boolean;
  showMultiSelect?: boolean;
  highlightTextOnFocus?: boolean;
  isKeyColumn?: boolean;
}

export enum MaskColumnType {
  editableListAsKeyColumn = "editableListAsKeyColumn"
}
