import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';
import { UserDefinedCustomStatement, StatementMode } from '../models/user-defined-statement';
import { StatementsService } from '../statements.service';
import { Subscription } from 'rxjs';
import { UnsubscribeAll } from '../../../../shared/rx-js-helpers';
import { Statement } from '../models/statement';

@Component({
  selector: 'app-user-defined-custom-statements',
  templateUrl: './user-defined-custom-statements.component.html',
  styleUrls: ['./user-defined-custom-statements.component.scss']
})
export class UserDefinedCustomStatementsComponent implements OnInit, OnDestroy {
  readonly maxCharacterLimit = 300;
  readonly customStatementSectionHeader = $localize`:@@customStatementSectionHeader:Custom Statements`;
  readonly editIcon = 'icon-s icon-pencil';
  readonly deleteIcon = 'icon-s icon-trash';
  readonly warningText = $localize`:@@multiCategorySelectedStatements:Note : User cannot select statements from different sections`;
  readonly savedCustomStatementsPreferenceKey = 'savedCustomStatements';
  statementText?: string;
  savedCustomStatements: UserDefinedCustomStatement[] = [];
  showStatementInputBox = false;
  statementToEditOrDelete?: UserDefinedCustomStatement;
  savedCustomStatementsLoading = true;
  subscriptions: Subscription[] = [];
  selectedStatements: string[] = [];

  @ViewChild('statementActionsOverlay') statementActionsOverlay?: OverlayPanel;
  @Output() selectedStatementsChanged = new EventEmitter<Statement | UserDefinedCustomStatement>();
  @Output() statementsChanged = new EventEmitter<string>();

  constructor(
    private readonly confirmationService: ConfirmationService,
    private readonly statementsService: StatementsService) {
  }

  ngOnDestroy(): void {
    UnsubscribeAll(this.subscriptions);
  }

  ngOnInit(): void {
    this.handleSubscriptions();
    this.initializeData();
  }

  onStatementsAdd() {
    this.showStatementInputBox = true;
  }

  onStatementEditCancel(savedStatement: UserDefinedCustomStatement) {
    const statement = this.savedCustomStatements.find(s => s.index === savedStatement.index);
    if (statement) {
      statement.mode = StatementMode.Add;
      statement.isModified = false;
      statement.statement = statement.originalStatement;
    }
  }

  onCancelCustomStatement() {
    this.statementText = '';
    this.showStatementInputBox = false;
    this.statementsChanged.emit(this.statementText);
  }

  onStatementEditSave(savedStatement: UserDefinedCustomStatement) {
    const statement = this.savedCustomStatements.find(s => s.index === savedStatement.index);
    if (!statement) return;
    this.selectedStatementsChanged.emit(this.statementToEditOrDelete);
    statement.mode = StatementMode.Add;
    statement.isModified = false;
    statement.originalStatement = statement.statement;
    const updatedCustomStatements = this.savedCustomStatements.map<UserDefinedCustomStatement>(s => ({
      index: s.index,
      statement: s.statement,
      selected: false,
      originalStatement: s.originalStatement,
      isModified: s.isModified,
      mode: s.mode,
      type: s.type
    }))
    updatedCustomStatements.forEach(s => s.selected = false);
    this.statementsService.updateSavedCustomStatementsPreference(updatedCustomStatements);
  }

  onStatementEditStarted(savedStatement: UserDefinedCustomStatement) {
    savedStatement.isModified = (savedStatement.statement.trim() !== savedStatement.originalStatement && savedStatement.statement !== '');
  }

  onTextChanged(statement?: string) {
    this.statementsChanged.emit(statement);
  }

  onStatementsSave() {
    if (this.statementsService.isMaxStatementCountReached(this.savedCustomStatements)) return;
    const lastAssignedStatementIndex = this.savedCustomStatements.length === 0 ?
                                  0 :
                                  this.savedCustomStatements[this.savedCustomStatements.length - 1].index;
    if (this.statementText) {
      this.savedCustomStatements.push({
        index: lastAssignedStatementIndex + 1,
        statement: this.statementText,
        originalStatement: this.statementText,
        mode: StatementMode.Add,
        isModified: false,
        selected: false
      });
      this.showStatementInputBox = false;
      this.statementText = '';
      this.statementsChanged.emit(this.statementText);
      if (this.statementsService.savedStatementsPreference) {
        this.statementsService.updateSavedCustomStatementsPreference(this.savedCustomStatements);
        return;
      }
      this.statementsService.saveNewCustomStatementsPreference(this.savedCustomStatements);
    }
  }

  onStatementActionsClick(event: any, savedStatement: UserDefinedCustomStatement) {
    this.statementToEditOrDelete = savedStatement;
    setTimeout(() => {
      this.statementActionsOverlay?.toggle(event);
    });
  }

  onStatementsEdit() {
    if (this.statementToEditOrDelete) {
      this.statementToEditOrDelete.mode = StatementMode.Edit;
      this.statementActionsOverlay?.hide();
    }
  }

  onStatementsDelete() {
    this.confirmationService.confirm({
      message: $localize`:@@statementDeleteConfirmation:Do you want to delete the statement?`,
      header: $localize`:@@confirmationHeader:Confirmation`,
      acceptVisible: true,
      acceptLabel: $localize`:@@confirmationYes:Yes`,
      rejectLabel: $localize`:@@confirmationNo:No`,
      rejectVisible: true,
      closeOnEscape: true,
      dismissableMask: false,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        if (this.statementToEditOrDelete) {
          this.statementActionsOverlay?.hide();
          this.savedCustomStatements = this.savedCustomStatements.filter(s => s.index !== this.statementToEditOrDelete?.index);
          this.statementsService.updateSavedCustomStatementsPreference(this.savedCustomStatements);
          this.statementToEditOrDelete.selected = false;
          this.selectedStatementsChanged.emit(this.statementToEditOrDelete);
        }
      }
    });
  }

  private initializeData() {
    this.savedCustomStatements = [];
    this.savedCustomStatementsLoading = true;
    this.statementsService.loadSavedCustomStatements();
    this.statementText = undefined;
    this.showStatementInputBox = false;
  }

  private handleSubscriptions() {
    this.subscriptions.push(this.statementsService.customStatementsLoaded.subscribe({
      next: (response: UserDefinedCustomStatement[]) => {
        this.savedCustomStatements = response;
        this.savedCustomStatementsLoading = false;
      }
    }));

    this.subscriptions.push(this.statementsService.sliderVisibilityChanged.subscribe({
      next: (sliderOpen: boolean) => {
        if (!sliderOpen) this.initializeData();
      }
    }));

    this.subscriptions.push(this.statementsService.clearSelectedStatements.subscribe((clearStatementSelection: boolean) => {
      if (clearStatementSelection) {
        this.savedCustomStatements.forEach(x => x.selected = false);
      }
    }));
  }

  updateSelectedStatements(selectedStatement: UserDefinedCustomStatement) {
    this.selectedStatementsChanged.emit(selectedStatement);
  }
}
