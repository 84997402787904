export class ActivityInputConstants {
    static readonly testName = 'testName';
    static readonly maxIsoDateForRefresh = '9999-12-31';
    static readonly testCode = 'testCode';
    static readonly testKey = 'testKey';
    static readonly reportableMethodReference = 'testReportableMethodReference';
    static readonly testReportableNameForAudit = 'testReportableName';
    static readonly collectedOn = 'collectedOn';

    static readonly studyActivityName = 'StudyActivityName';
    static readonly status = 'Status';
    static readonly materialAliquotStatus = 'materialAliquotStatus';

    static readonly studyActivityNameColumn = 'studyActivityName';
    static readonly studyActivityCodeColumn = 'code';
    static readonly studyActivityCode = 'studyActivityCode';
    static readonly studyActivityStatusColumn = 'studyActivityStatus';
    static readonly studyCodeColumn = 'studyCode';
    static readonly studyGroupNameColumn = 'studyGroupName';
    static readonly studyNameColumn = 'studyName';

    static readonly instrumentName = 'Name';
    static readonly instrumentEvent = 'Instrument Event';
    static readonly selectDateRemoved = 'Date Removed';
    static readonly removedFromService = 'Removed from Service';
    static readonly descriptionOfSituation = 'Description of Situation';

    static readonly iconDuplicate = 'icon-s icon-duplicate';
    static readonly iconSpinner = 'pi pi-spin pi-spinner';
    static readonly iconCheck = 'icon-s icon-check';
    static readonly iconExclamation = 'icon-s icon-exclamation';

    //apply cell lock constants
    static readonly colorString = 'color';
    static readonly pointerEventsAttr = 'pointer-events';
}
