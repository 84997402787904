import { Injectable } from "@angular/core";
import { Message, MessageService } from "primeng/api";
import { ChangeRecipeNodeReOrderCommand, NodeType, RecipeNodeOrderChangedResponse, RecipeState } from "../../api/cookbook/models";
import { Observable, Subject, of } from "rxjs";
import { RecipeEventsService } from "../../api/cookbook/services";
import { ClientStateService } from "../../services/client-state.service";
import { RecipeService } from "./recipe.service";
import { Activity, Form, Module, Table } from "../../model/experiment.interface";
import { RecipeModel } from "../model/recipe";
import { moveItemInArray } from "@angular/cdk/drag-drop";

type AllowedReorderNodeTypes = Module | Form | Table | Activity;
@Injectable()
export class RecipeNodeReOrderService {
  private static _hasPermissionToReOrderNode = true;
  public get HasPermissionToReOrderNode(): boolean {
    return RecipeNodeReOrderService._hasPermissionToReOrderNode;
  }
  public static readonly NotAllowedWorkflowStates: RecipeState[] = [
    RecipeState.PendingApproval,
    RecipeState.Cancelled,
    RecipeState.Published,
    RecipeState.PendingVerification,
    RecipeState.Retired
  ];
  public readonly nodeOrderChanged = new Subject<void>();
  constructor(
    private readonly recipeEventsApiService: RecipeEventsService,
    public readonly recipeService: RecipeService,
    private readonly messageService: MessageService,
    private readonly clientStateService: ClientStateService
  ) {}

  changeNodeReOrder(
    nodeType: NodeType,
    nodeId: string,
    parentId: string,
    parentType: NodeType,
    position: number,
    nodeTitle: string
  ): Observable<void> {
    if (!this.hasPermission()) {
      this.failedToPerformOperationMessageBecauseUnAuthorized(nodeType);
      return of(undefined);
    }

    if (
      RecipeNodeReOrderService.NotAllowedWorkflowStates.includes(
        this.recipeService.currentRecipe?.tracking.state
      )
    ) {
      this.failedToPerformOperationMessage(nodeType);
      return of(undefined);
    }

    this.recipeEventsApiService
      .recipeEventsChangeNodeOrderPost$Json({
        body: this.prepareCommandToChangeNodeReOrderFor(
          nodeType,
          nodeId,
          parentId,
          position,
          parentType
        )
      })
      .subscribe({
        next: (response) => {
          this.processReorderResponse(response, this.recipeService.currentRecipe);
          this.nodeOrderChanged.next();
          this.orderChangedNotificationMessage(nodeTitle, response.position, response.nodeType);
        }
      });
    return this.nodeOrderChanged.asObservable();
  }

  private processReorderResponse(data: RecipeNodeOrderChangedResponse, recipe: RecipeModel) {
    const { activities, modules, childOrder } = recipe;
    const isNodeTableOrForm = data.nodeType === NodeType.Form || data.nodeType === NodeType.Table;
    const isNodeModule = data.nodeType === NodeType.Module;

    if (data.parentType === NodeType.Recipe) {
      if (data.nodeType === NodeType.Activity) {
        //here reversing of activities is necessary.
        activities.reverse();
        reorderLogic<Activity>(activities, data, childOrder);
        activities.reverse();
      } else if (isNodeTableOrForm) {
        reorderLogic<Table | Form>([], data, childOrder);
      } else if (isNodeModule) {
        reorderLogic<Module>([], data, childOrder);
      }
    } else if (data.parentType === NodeType.Activity && isNodeModule) {
      const activity = findMatchingNode(activities, data.parentId);
      reorderLogic<Module>(activity.dataModules ?? [], data, []);
    } else if (data.parentType === NodeType.Module && isNodeTableOrForm) {
      const module = this.fetchLinkedModule(activities, modules, data.parentId);
      reorderLogic<Table | Form>(module.items ?? [], data, []);
    }
  }

  private fetchLinkedModule(activities: Activity[], modules: Module[], parentId: string): Module {
    return modules.length > 0
      ? findMatchingNode(modules, parentId)
      : findMatchingNode(activities.flatMap((act) => act.dataModules), parentId);
  }

  private prepareCommandToChangeNodeReOrderFor(
    nodeType: NodeType,
    nodeId: string,
    parentId: string,
    position: number,
    parentType: NodeType
  ): ChangeRecipeNodeReOrderCommand {
    return {
      recipeId: this.recipeService.currentRecipe?.recipeId,
      nodeId,
      nodeType,
      parentId,
      position,
      parentType
    };
  }

  private orderChangedNotificationMessage(
    movedLabel: string,
    newPosition: number,
    nodeType: string
  ) {
    if (nodeType === NodeType.Activity) {
      this.createNotificationMessage(
        $localize`:@@ExperimentnodeOrderChangedMessage:The ${nodeType} "${movedLabel}" has been moved to ${
          this.recipeService.currentRecipe?.activities.length - newPosition + 1
        } position.`,
        ''
      );
    } else {
      this.createNotificationMessage(
        $localize`:@@ExperimentnodeOrderChangedMessage:The ${nodeType} "${movedLabel}" has been moved to ${
          newPosition + 1
        } position.`,
        ''
      );
    }
  }

  private createNotificationMessage(summary: string, detail: string, severity = 'success') {
    const messageObj: Message = {
      key: 'notification',
      severity: severity,
      summary,
      detail,
      sticky: false
    };
    this.messageService.add(messageObj);
  }

  public hasPermission(): boolean {
    const featureFlags = this.clientStateService.getFeatureFlags('eln.recipe.designer');
    RecipeNodeReOrderService._hasPermissionToReOrderNode = featureFlags.some((data) => {
      if (!data || !JSON.parse(data)) {
        return false;
      }
      const parsedData = JSON.parse(data);
      return parsedData.CanReOrderNode && parsedData.CanReOrderNode === true;
    });
    return RecipeNodeReOrderService._hasPermissionToReOrderNode;
  }

  public hasPermissionWithRespectToFlow(): boolean {
    if (
      RecipeNodeReOrderService.NotAllowedWorkflowStates.includes(
        this.recipeService.currentRecipe?.tracking.state
      )
    ) {
      return false;
    }
    return true;
  }

  private failedToPerformOperationMessageBecauseUnAuthorized(nodeType: string) {
    this.createNotificationMessage(
      $localize`:@@NodeReOrderFailedToPerformOperationMessageUnAuthorized:The reorder ${nodeType} action cannot be performed, because your auhtorization is failed.`,
      ``,
      'error'
    );
  }

  private failedToPerformOperationMessage(nodeType: string) {
    const state = this.recipeService.currentRecipe?.tracking.state;
    const type = nodeType;
    this.createNotificationMessage(
      $localize`:@@NodeReOrderFailedToPerformOperationMessageForRecipe:The reorder ${type} action cannot be performed, because the recipe workflow state is ${state}.`,
      ``,
      'error'
    );
  }

  static getNodeId<T extends AllowedReorderNodeTypes>(node: T) {
    return 'tableId' in node
      ? node.tableId
      : 'formId' in node
        ? node.formId
        : 'moduleId' in node
          ? node.moduleId
          : 'activityId' in node
            ? node.activityId
            : '';
  }
}

function findMatchingNode<T extends AllowedReorderNodeTypes>(nodes:T[], id: string): T {
  const node = nodes.find((node) => RecipeNodeReOrderService.getNodeId(node) === id);
  if(!node) {
    throw new Error(`Cannot find node with id: ${id} in nodes ${nodes.flatMap(RecipeNodeReOrderService.getNodeId)}`);
  }
  return node;
}

function reorderLogic<T extends AllowedReorderNodeTypes>(
  nodes: T[],
  data: RecipeNodeOrderChangedResponse,
  childOrder: string[]
) {
  var currentIndex = -1;
  var indexToMoveTo = data.position;
  if (nodes.length !== 0) {
    if('activityId' in nodes[0])//for activities index 0 is about page although reversed
      indexToMoveTo -=1;
    currentIndex = nodes.indexOf(findMatchingNode(nodes, data.nodeId));
    moveItemInArray(nodes, currentIndex, indexToMoveTo);
  } else {
    currentIndex = childOrder.indexOf(data.nodeId);
  }
  moveItemInArray(childOrder, currentIndex, indexToMoveTo);
}