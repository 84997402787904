import { RuleEvents } from '../../api/models';
import { NodeLoadedEvent } from '../events/rule-event-context';
import { ContextFrom, RuleEventContextBaseConverter } from './rule-base-converter';
import { RuleEventContextConverter } from './rule-event-context-converter';
import { RuleActionHandlerHostService } from '../handler/rule-action-handler-host.service';

export interface NodeLoadedEventContextFrom extends ContextFrom {
}

export class NodeLoadedEventConverter
  extends RuleEventContextBaseConverter
  implements RuleEventContextConverter<NodeLoadedEventContextFrom>
{
  public convert(instanceId: string, templateId: string, context: NodeLoadedEventContextFrom): NodeLoadedEvent {
    return {
      event: {
        context,
        templateId,
        templateInstanceId: instanceId,
        eventType: RuleEvents.OnLoad,
        itemId: RuleActionHandlerHostService.CurrentItemId,
      },      
    };
  }
}
