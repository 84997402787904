import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import {
  BptGridCellValueChangedEvent,
  BptGridMode,
  BptGridRowActionConfiguration,
  BptGridValuesPastedEvent,
  BptGridRowActionClickEvent,
  BptRowActionElement,
  ColumnDefinition,
  GridContextMenuItem,
  BptGridComponent
} from 'bpt-ui-library/bpt-grid';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ConfirmationService } from 'primeng/api';
import { PromptConstants } from '../prompt-constants';
import { EditableCallbackParams, ICellRendererParams } from 'ag-grid-community';
import { ColumnSpecification } from '../../model/experiment.interface';
import { FillWithNaGridHelperForPrompt, NaGridTableIdentifierDataForPrompts } from '../service/fill-with-na-prompt-grid-helper';
import { PromptType } from '../../api/cookbook/models';
import { NA } from 'bpt-ui-library/shared';
import { RecipePromptService } from '../../recipe/services/recipe-prompt.service';
import { UnsubscribeAll } from '../../shared/rx-js-helpers';
import { DialogService } from 'primeng/dynamicdialog';
import { RemovedPromptsComponent } from '../removed-prompts/removed-prompts.component';
import { AddRowEventParams } from 'bpt-ui-library/bpt-grid/model/add-row-event-params.interface';

@Component({
  selector: 'app-prompt-grid',
  templateUrl: './prompt-grid.component.html'
})
export class PromptGridComponent implements OnInit, OnChanges, OnDestroy {
  @Input() columnDefinitions: ColumnDefinition[] = [];
  @Input() promptType!: PromptType;

  /*
  * This flag is mainly used to control accessability of row actions of prompt grid
  */
  @Input() accessFlag = false;
  @Input() readOnly = false;

  gridActions!: BptGridRowActionConfiguration;

  private _primitiveValue!: { [key: string]: any }[];
  @Input() get primitiveValue() {
    return this._primitiveValue
  }
  set primitiveValue(data: { [key: string]: any }[]) {
    this._primitiveValue = [];
    this._primitiveValue = this.processRows([...data,...this.removedRows]);
  }

  @Output() openPromptSlider = new EventEmitter<{ type: PromptType, label: string, values?: any }>();
  @Output() cellEditedEvent = new EventEmitter<{ type: PromptType, promptId: string, editedFields: { [key: string]: string } }>();
  @Output() removePromptEvent = new EventEmitter<{ type: PromptType, promptId: string }>();
  @Output() restorePromptEvent = new EventEmitter<{ type: PromptType, promptId: string }>();
  table: { values : { [key: string]: any }[]} = {
    values: []
  };
  protected activeSubscriptions: Subscription[] = [];

  @ViewChild('promptGrid') grid!: BptGridComponent;
  reloadGrid = true;
  removedRows: { [key: string]: any }[] = [];

  private processRows(data: { [key: string]: any; }[]): { [key: string]: any; }[] {
    return this.sortDownImportedPrompts(this.processRemovedRows(data));
  }

  private sortDownImportedPrompts(data: { [key: string]: any }[]) {
    const nativePrompts: { [key: string]: any }[] = [];
    const importedPrompts: { [key: string]: any }[] = [];
    data.forEach(row => row.source === NA ? nativePrompts.push(row) : importedPrompts.push(row));
    return nativePrompts.concat(importedPrompts);
  }

  private processRemovedRows(data: { [key: string]: any; }[]): { [key: string]: any; }[] {
    this.removedRows = [];
    data.forEach(row => {
      if (row.isPromptRemoved === true) {
        this.removedRows.push(row)
      }
    })
    return data.filter(row => !this.removedRows.includes(row));
  }

  private readonly promptTypesMap: {
    [promptType: string]: string;
  } = {
      materials: 'Materials',
      instruments: 'Instruments',
      columns: 'Columns',
      consumablesAndSupplies: 'Consumables & Supplies',
      preparations: 'Preparations'
    };
  
  gridOptions = {
    includeRowNumberColumn: true,
    showAutoSizeButton: true,
    showFilterToggleButton: true,
    showGridOptionsButton: true,
    allowRowAdd: true,
    gridMode: BptGridMode.dataEntry,
    addMultipleRows: false
  };
  title = '';
  
  constructor(
    private readonly confirmationService: ConfirmationService,
    private readonly fillWithNaGridHelper: FillWithNaGridHelperForPrompt,
    private readonly recipePromptService: RecipePromptService,
    private readonly dialogService: DialogService
  ) { }

  ngOnInit(): void {
    this.title = this.promptTypesMap[this.promptType];
    this.addGridActions();

    this.activeSubscriptions.push(
      this.recipePromptService.gridCellUpdate.subscribe({
        next: () => {
          this.grid.gridApi.refreshCells({ force: true });
        }
      })
    );
  }

  removePromptRowConfirmation(e: BptGridRowActionClickEvent) {
    this.confirmationService.confirm({
      message: PromptConstants.deletePromptConfirmation,
      header: PromptConstants.confirmationHeader,
      closeOnEscape: true,
      dismissableMask: false,
      acceptVisible: true,
      acceptLabel: PromptConstants.acceptLabel,
      rejectVisible: true,
      rejectLabel: PromptConstants.rejectLabel,
      rejectButtonStyleClass: 'p-button-outlined-compact',
      accept: () => { this.removePromptRowAction(e) },
      reject: () => { return; }
    });
  }

  removePromptRowAction(e: BptGridRowActionClickEvent) {
    e.params.data.isPromptRemoved = true;
    this.primitiveValue = this._primitiveValue;
    this.removePromptEvent.emit({type: e.params.data.type, promptId: e.params.data.promptId})
    this.reloadGridAction(0);
  }

  restorePromptRowAction(e: BptGridRowActionClickEvent) {
    e.params.data.isPromptRemoved = false;
    this.primitiveValue = this._primitiveValue;
    this.restorePromptEvent.emit({ type: e.params.data.type, promptId: e.params.data.promptId });
    this.reloadGridAction(0);
  }

  reloadGridAction(delay = 150) {
    this.reloadGrid = false;
    setTimeout(() => {
      this.reloadGrid = true;
    }, delay);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.accessFlag) {
      this.addGridActions();
      this.reloadGridAction();
    }
  }

  beforeAddRow = (_params: AddRowEventParams,_addRowCallback: (params: AddRowEventParams) => void) => {
    this.openPromptSlider.emit({ type: this.promptType, label: this.title });
  }

  private addGridActions() {
    this.table.values = this.primitiveValue;
    const rowActions = this.getRowActionItems();
    const actionsSubject: BehaviorSubject<BptRowActionElement[]> = new BehaviorSubject(rowActions);

    this.gridActions = {
      actions: actionsSubject
    };
  }

  public getRowActionItems(): BptRowActionElement[] {
    return [
      {
        id: 'recipe-prompt-edit-row',
        enabled: this.isRowActionEnabled.bind(this),
        styleClass: 'far fa-edit',
        click: this.rowEditActionClick.bind(this),
        tooltip: $localize`:@@updatePrompt:Update prompt`
      },
      {
        id: 'recipe-prompt-delete-row',
        enabled: this.isRowActionEnabled.bind(this),
        styleClass: 'far fa-trash-alt',
        click: this.removePromptRowConfirmation.bind(this),
        tooltip: $localize`:@@RemoveItem:Remove item`
      }
    ];
  }

  private readonly isRowActionEnabled = (e: ICellRendererParams) => (this.accessFlag && this.isPromptNative(e.data.promptId)) ?? false;

  cellValueChanged(e: BptGridCellValueChangedEvent) {
    this.cellEditedEvent.emit({ type: this.promptType, promptId: e.rowId as string, editedFields: {[e.field as string]: e.newValue} });
  }

  pasteValues(e: BptGridValuesPastedEvent) {
    if (e.cellChangedEvents) {
      e.cellChangedEvents.forEach(element => {
        this.cellValueChanged(element);
      });
    }
  }

  private rowEditActionClick(e: BptGridRowActionClickEvent) {
    this.openPromptSlider.emit({ type: this.promptType, label: this.title, values: e.params });
  }

  getContextMenu(): GridContextMenuItem[] {
    const menu: GridContextMenuItem[] = [
      'copy',
      'paste',
      'separator',
      this.fillWithNaMenuItem()
    ];
    return menu;
  }

  fillWithNaMenuItem (): GridContextMenuItem {
    const args : NaGridTableIdentifierDataForPrompts = {
      isCellEditable : this.isCellEditable.bind(this),
      postChangeCellCommand : this.postChangeCellCommand.bind(this),
      grid : this.grid,
      table : this.table,
      columnDefinitions : this.columnDefinitions,
      idFieldName:"promptId"
    }
    return this.fillWithNaGridHelper.getContextMenuOptionsOfFillWithNa(args);
  }

  private isCellEditable(params: EditableCallbackParams) {
    const columnName = params.colDef.field;
    const currentColumnDefinition = this.columnDefinitions.find(
      (data) => data.field === columnName
    );
    if (!(currentColumnDefinition as ColumnSpecification)?.editable || !this.isPromptNative(params.data.promptId) || !this.accessFlag) return false;
    return true;
  }

  ngOnDestroy(): void {
    UnsubscribeAll(this.activeSubscriptions);
  }

  postChangeCellCommand(promptId: string, changedValues: {[key: string]: string}) {
    this.cellEditedEvent.emit({ type: this.promptType, promptId: promptId, editedFields: changedValues });
  }

  private readonly isPromptNative = (promptId: string) => this.primitiveValue.some(obj => obj.promptId === promptId && obj.source === NA);
  
  loadRemovedPromptsDialog() {
    this.dialogService.open(RemovedPromptsComponent, {
      width: '80%',
      autoZIndex: true,
      height: '50%',
      closable: true,
      closeOnEscape: true,
      header: $localize`:@@removedPromptsModalHeader:Removed ${PromptConstants.PromptButtonOption[this.promptType]} prompts`,
      styleClass: 'eln-removed-rows-dialog',
      data: {
        table: this,
      }
    });
  }
}
