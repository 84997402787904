/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ExperimentSentForReviewResponse } from '../../models/experiment-sent-for-review-response';

export interface ExperimentEventsExperimentIdSendForReviewPost$Plain$Params {
  experimentId: string;
  'X-User-Puid'?: any;
  'X-User-LabsiteCode'?: any;

/**
 * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
 */
  'X-Experiment-PreviewMode'?: any;
}

export function experimentEventsExperimentIdSendForReviewPost$Plain(http: HttpClient, rootUrl: string, params: ExperimentEventsExperimentIdSendForReviewPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ExperimentSentForReviewResponse>> {
  const rb = new RequestBuilder(rootUrl, experimentEventsExperimentIdSendForReviewPost$Plain.PATH, 'post');
  if (params) {
    rb.path('experimentId', params.experimentId, {});
    rb.header('X-User-Puid', params['X-User-Puid'], {});
    rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
    rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
  }

  return http.request(
    rb.build({ responseType: 'text', accept: 'text/plain', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ExperimentSentForReviewResponse>;
    })
  );
}

experimentEventsExperimentIdSendForReviewPost$Plain.PATH = '/experiment-events/{experimentId}/send-for-review';
