<div *ngFor="let statement of statements" class="applied-statement-container">
  <!-- Table cell applied statements -->
  <div *ngIf="isTableCellStatement(statement)" class="border-3 border-500 border-round p-2 applied-statement-border">
    <h2 class="panel-header applied-statement" tooltipPosition="left">{{ headerText(statement) }}</h2>
    <div class="cfn-applied-statement">
      <div class="out-container">
        <span class="applied-statement">{{getAppliedStatement(statement)}}</span><br>
        <b>{{getUserName(statement)}} ({{getPuid(statement)}})</b>,<span>{{getStatementAppliedDate(statement)}}</span>
      </div>
    </div>
  </div>
  <!-- Client facing applied statements -->
  <div *ngIf="!isTableCellStatement(statement)" class="cfn-applied-statement">
    <div class="out-container">
      <span class="applied-statement">{{getAppliedStatement(statement)}}</span><br>
      <b>{{getUserName(statement)}} ({{getPuid(statement)}})</b>,<span>{{getStatementAppliedDate(statement)}}</span>
    </div>
  </div>
</div>