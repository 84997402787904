import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable, Subject } from 'rxjs';

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable({
  providedIn: 'root'
})
export class UnsavedChangesGuard implements CanDeactivate<ComponentCanDeactivate> {

  canDeactivateInterpretation : Subject<boolean> = new Subject<boolean>();
  canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
    const canDeactivateAuthor = component.canDeactivate();
    if(canDeactivateAuthor instanceof Observable) {
      canDeactivateAuthor.subscribe((_canDeactivate)=>{

         _canDeactivate
        ? this.canDeactivateInterpretation.next(true)
        
        : this.canDeactivateInterpretation.next(confirm(
            $localize`:@@unsavedWarning:WARNING: You have unsaved changes. Press Cancel to go back and save these changes, or OK to lose them.`
          ));

      })
      return this.canDeactivateInterpretation.asObservable();

    }

    // if there are no pending changes, just allow deactivation; else confirm first
    return canDeactivateAuthor
      ? true
      : confirm(
          $localize`:@@unsavedWarning:WARNING: You have unsaved changes. Press Cancel to go back and save these changes, or OK to lose them.`
        );
  }
}
