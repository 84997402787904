<p-dialog
  #overlay
  [header]="repeatForEachHeader"
  [modal]="true"
  [(visible)]="visible"
  appendTo="body"
  [resizable]="false"
  [blockScroll]="true"
  [closeOnEscape]="false"
  [draggable]="true"
  [closable]="true"
  >
  <div i18n="@@selectInput">Select an input type for this repeating group</div>
  <ng-template
    pTemplate="footer">
    <div class="repeat-footer -pt-3 -mt-3">
      <div class="repeat-radio-buttons">
        <p-radioButton label="Sample Aliquot" i18n-label="@@sampleAliquot" value="sample" [(ngModel)]="input"></p-radioButton>
        <p-radioButton label="Material Aliquot" i18n-label="@@materialAliquot" value="material" [(ngModel)]="input"></p-radioButton>
        <p-radioButton label="Instrument" i18n-label="@@Instrument" value="instrument" [(ngModel)]="input"></p-radioButton>
      </div>
      <div class="buttons">
        <p-button class="repeatForOk" label="Ok" i18n-label="@@ok" (onClick)="submit()"></p-button>
        <p-button class="repeatForCancel" label="Cancel" i18n-label="@@cancel" styleClass="p-button-outlined" (onClick)="cancel()"></p-button>
      </div>
    </div>
  </ng-template>
</p-dialog>