<!-- This entire HTML template is written as one element with an outline of template references. This is for readability. -->
<form #form="ngForm" class="eln-property-list" novalidate>
  <ng-container *ngTemplateOutlet="defaultSettingsButton"></ng-container>
  <ng-container *ngTemplateOutlet="labelProperty"></ng-container>
  <ng-container *ngTemplateOutlet="fieldProperty"></ng-container>
</form>

<ng-template #defaultSettingsButton>
  <div class="default-settings eln-properties-alignment">
    <p-button
      class="default-settings-button"
      styleClass="p-button-outlined"
      type="button"
      i18n-label="@@templateDesignerRecommendedSettings"
      label="Default Settings"
      [disabled]="isReadOnly"
      (onClick)="setDefaultsSettings()"
    ></p-button>
  </div>
</ng-template>

<ng-template #labelProperty>
  <div *ngIf="labelIsRendered"
    class="label-property eln-properties-alignment">
    <label
      class="label-label"
      i18n="@@designerPropertyNameLabel"
      >Label</label>
    <!-- DO NOT change ngModelOptions updateOn property for bpt-text-input. It must stay the default which is 'change' or you could introduce bugs such as this one: 
      https://dev.azure.com/BPTCollection/Eurofins%20ELN/_workitems/edit/3256934 -->
    <bpt-text-input #labelInput="ngModel"
      [(ngModel)]="label"
      (ngModelChange)="onLabelChange($event)"
      [readOnly]="labelIsReadonly"
      i18n-pTooltip="@@labelToolTip"
      pTooltip="The Label is the text that will appear inside the experiment to the user."
      tooltipPosition="top"
    ></bpt-text-input>
    <div
      *ngIf="labelValidation && (!labelValidation.isValid() || labelValidation.warnings.length > 0)"
      class="label-validation-message">
      <app-validation-summary
        [validation]="labelValidation">
      </app-validation-summary>
    </div>
  </div>
</ng-template>

<ng-template #fieldProperty>
  <div *ngIf="fieldIsRendered"
    class="field-property eln-properties-alignment">
    <label
      class="field-label"
      i18n="@@designerPropertyNameField"
      >Field</label>
    <!-- DO NOT change ngModelOptions updateOn property for bpt-text-input. It must stay the default which is 'change' or you could introduce bugs such as this one: 
      https://dev.azure.com/BPTCollection/Eurofins%20ELN/_workitems/edit/3256934 -->
    <bpt-text-input #fieldInput="ngModel"
      [(ngModel)]="field"
      (ngModelChange)="onFieldChange($event)"
      [readOnly]="fieldIsReadonly"
      i18n-pTooltip="@@fieldToolTip"
      pTooltip="The Field is the identifier that will be used in the backend and rules to identify the specific control or column."
      tooltipPosition="top"
      ></bpt-text-input>
    <div
      *ngIf="fieldValidation && (!fieldValidation.isValid() || fieldValidation.warnings.length > 0)"
      class="field-validation-message">
      <app-validation-summary
        [validation]="fieldValidation">
      </app-validation-summary>
    </div>
  </div>
</ng-template>
