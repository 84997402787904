/* tslint:disable */
/* eslint-disable */
export enum SpecComplianceAssessorType {
  Invalid = 'invalid',
  None = 'none',
  ExactMatch = 'exactMatch',
  InexactMatch = 'inexactMatch',
  Round = 'round',
  Truncate = 'truncate'
}
