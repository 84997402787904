/* tslint:disable */
/* eslint-disable */
export enum ExperimentRecordType {
  System = 'system',
  New = 'new',
  Change = 'change',
  Remove = 'remove',
  Restore = 'restore',
  Reading = 'reading',
  Access = 'access',
  Workflow = 'workflow',
  Statement = 'statement',
  Review = 'review',
  Template = 'template',
  Recipe = 'recipe',
  Rule = 'rule',
  Calculation = 'calculation',
  Reference = 'reference',
  LabItem = 'labItem',
  Input = 'input',
  Output = 'output',
  AttachedFile = 'attachedFile',
  Preparation = 'preparation',
  Barcode = 'barcode',
  InternalComments = 'internalComments',
  ClientFacingNotes = 'clientFacingNotes',
  Empower = 'empower',
  DataPackage = 'dataPackage'
}
