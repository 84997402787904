import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { v4 as uuidV4 } from 'uuid';
import { ExperimentWorkflowState, UserPreference, UserPreferenceListResponse } from '../../../api/models';
import { UserPreferenceService } from '../../../api/services';
import { StrictHttpResponse } from '../../../api/strict-http-response';
import { ClientStateService } from '../../../services/client-state.service';
import { UserService } from '../../../services/user.service';
import { ExperimentService } from '../../services/experiment.service';
import { StatementModel } from './models/statement-model';
import { UserDefinedCustomStatement } from './models/user-defined-statement';
import { StatementAppliedEventNotification } from '../../../api/data-entry/models';

@Injectable({
  providedIn: 'root'
})
export class StatementsService {
  readonly savedCustomStatementsPreferenceKey = 'savedCustomStatements';
  savedStatementsPreference?: UserPreference;
  savedStatementsPreferenceId?: string;
  private readonly statementDetails = new Subject<StatementModel>();
  public loadStatements = this.statementDetails.asObservable();

  readonly customStatementsLoaded = new Subject<UserDefinedCustomStatement[]>();
  readonly sliderVisibilityChanged = new Subject<boolean>();
  public statementAdded = new Subject<StatementAppliedEventNotification>();
  readonly clearSelectedStatements = new Subject<boolean>();

  constructor(
    private readonly messageService: MessageService,
    private readonly userService: UserService,
    private readonly userPreferenceApiService: UserPreferenceService,
    private readonly experimentService: ExperimentService,
    public clientStateService: ClientStateService
  ) { }

  loadSavedCustomStatements() {
    this.userPreferenceApiService.userPreferencesUserPreferenceKeyGet$Json$Response({
      userPreferenceKey: this.savedCustomStatementsPreferenceKey
    }).subscribe({
      next: (response: StrictHttpResponse<UserPreferenceListResponse>) => {
        this.savedStatementsPreference = response.body.userPreferences.pop();
        let customStatements = [];
        if (this.savedStatementsPreference) {
          customStatements = JSON.parse(this.savedStatementsPreference.userPreferenceValue);
        }
        this.customStatementsLoaded.next(customStatements);
      }
    });
  }

  isMaxStatementCountReached(statements: UserDefinedCustomStatement[]): boolean {
    if (statements.length >= 5) {
      this.messageService.add({
        key: 'notification',
        severity: 'error',
        summary: $localize`:@@maxStatementCountMessage:Maximum Statement Count Reached`
      });
      return true;
    }
    return false;
  }

  saveNewCustomStatementsPreference(statements: UserDefinedCustomStatement[]) {
    this.savedStatementsPreferenceId = uuidV4();
    const customStatementUserPreference: UserPreference = {
      isDefault: false,
      userPreferenceId: this.savedStatementsPreferenceId,
      userPreferenceKey: this.savedCustomStatementsPreferenceKey,
      userPreferenceName: this.savedCustomStatementsPreferenceKey,
      userPreferenceValue: JSON.stringify(statements),
      userPuid: this.userService.currentUser.puid
    }
    this.userPreferenceApiService.userPreferencesSaveUserPreferencePost$Json$Response({
      body: customStatementUserPreference
    }).subscribe();
  }

  updateSavedCustomStatementsPreference(statements: UserDefinedCustomStatement[]) {
    if (!this.savedStatementsPreference) return;
    if (statements.length === 0 && this.savedStatementsPreferenceId) {
      this.userPreferenceApiService.userPreferencesUserPreferenceIdDelete$Json$Response({
        userPreferenceId: this.savedStatementsPreferenceId
      }).subscribe();
      return;
    }
    this.savedStatementsPreference.userPreferenceValue = JSON.stringify(statements);
    this.userPreferenceApiService.userPreferencesUpdateUserPreferencePut$Json$Response({
      body: this.savedStatementsPreference
    }).subscribe();
  }

  public openStatements(statementDetails: StatementModel): void {
    this.statementDetails.next(statementDetails);
  }

  public canShowStatements(puid: string): boolean {
    return (this.experimentService.currentExperiment?.workflowState === ExperimentWorkflowState.InProgress ||
      this.experimentService.currentExperiment?.workflowState === ExperimentWorkflowState.InCorrection)
      && (this.userService.hasAnalystRights(puid) || this.userService.hasSupervisorOrReviewerRights());
  }
}
