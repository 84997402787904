export class LocalizeHelper {
    /**
      * Used to translate messages at runtime when the translation keys are defined outside the scope of the ELN Client.
      * When a message is found for the foreign translations key in ELN Client, the appropriate message is returned; 
      * otherwise, the same message is returned.
    */
    public static translateMessage(translationKey: string, translatedMessageInEnglish: string) {
        const localize = LocalizeHelper.getLocalizeInstance();
        // "Any" type can not avoided here as TemplateStringArray has many members which are not needed to be supplied from our end 
        const translatedMessage = localize({ '0': `:@@${translationKey}:${translationKey}`, 'raw': [':'] } as any);
        if (translatedMessage !== "undefined" && translatedMessage !== translationKey) {
            return translatedMessage;
        }
        return translatedMessageInEnglish;
    }

    // $localise is wrapped so that it can be easily mocked during unit testing.
    public static getLocalizeInstance() {
        return $localize;
    }
}
