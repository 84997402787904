import { Component, OnInit, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Observable, delay, of } from 'rxjs';
import { BptFileUploadComponent } from 'bpt-ui-library/bpt-file-upload';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FileConfigurationOptions } from '../model/file-configuration-options';
import { FileUploadedResponse } from '../../api/file-integration/models/ELN/FileIntegration/Api/File/file-uploaded-response';
import { ExperimentService } from '../services/experiment.service';
import { FileIntegrationService } from '../../api/file-integration/services/file-integration.service';

@Component({
  selector: 'app-file-upload-modal',
  templateUrl: './file-upload-modal.component.html',
  styleUrls: ['./file-upload-modal.component.scss']
})
export class FileUploadModalComponent implements OnInit {
  private fileNames: string[] = [];
  disableOk = true;
  fileDataSource = {} as FileUploadedResponse;
  fileConfigOptions: FileConfigurationOptions = { fileLimit: 100, maxFileSize: 1000,  fileSizeForDisplay: '1gb', fileTypes: '', allowMultipleFiles: false, disabled: false};
  @ViewChild('fileUploader') fileUploader!: BptFileUploadComponent;

  constructor(
    private readonly dynamicDialogConfig: DynamicDialogConfig,
    private readonly dynamicDialogRef: DynamicDialogRef,
    private readonly fileService: FileIntegrationService,
    private readonly messageService: MessageService,
    private readonly experimentService: ExperimentService
  ) { }

  ngOnInit(): void {
    this.fileConfigOptions = this.dynamicDialogConfig.data.fileDetails;
  }

  onCancel() {
    this.dynamicDialogRef.close();
  }

  onOk() {
    if (!this.disableOk) {
      var files = this.experimentService.currentExperiment?.activityFiles.filter(k => k.activityId === this.experimentService.currentActivityId)[0];
      if(!!files && files.files?.length >= this.fileConfigOptions.fileLimit)
      {
        this.messageService.add({
          id: 'file-upload-limit-warning',
          key: 'notification',
          severity: 'warn',
          summary: $localize`:@@fileUploadLimitWarning:File upload limit reached`
        });
        return;
      }
      this.messageService.add({
        id: 'file-upload-warning',
        key: 'notification',
        severity: 'warn',
        summary: $localize`:@@fileUploadInProgressWarning:File upload is in progress`,
        life: 10000
      });
      this.fileService
        .filesUploadPost$Json({
          body: {
            ExperimentId: this.dynamicDialogConfig.data.experimentId,
            ActivityId: this.dynamicDialogConfig.data.activityId,
            UploadedData: this.fileUploader.uploadedFiles,
            FolderName: 'ELNOutputFiles'
          }
        })
        .subscribe({
          next: (response : FileUploadedResponse) => {
            this.messageService.clear();
            this.experimentService.activityFileUploaded.next(response);
            this.fileDataSource = {...response};
            this.messageService.add({
              key: 'notification',
              severity: 'success',
              summary: $localize`:@@fileUploadedSuccessfully:File uploaded successfully`
            });
          },
          error: () => {
            this.messageService.clear();
            this.messageService.add({
              key: 'notification',
              severity: 'error',
              summary: $localize`:@@fileUploadFailed:File upload failed`
            });
          }
        });
      this.dynamicDialogRef.close();
    }
  }

  uploadSucceeds = (files: File[]): Observable<string[]> => {
    this.fileNames.push(files[0].name);
    this.disableOk = false;
    return of(this.fileNames).pipe();
  };

  deleteFiles = (file: string): Observable<boolean> => {
    this.fileNames = this.fileNames.filter(f => f !== file);
    if (this.fileNames.length <= 0) {
      this.disableOk = true;
    }
    return of(true);
  }
}