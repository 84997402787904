import { TemplateRule } from '../rule/template-rule';
import { sortBy } from 'lodash-es';
import { RuleEvents } from '../../api/models';

export interface RuleContextFilter<TResponse> {
  filterByContext(rules: TemplateRule[], context: string): TResponse;
}

export class RuleEventFilter implements RuleContextFilter<TemplateRule[]> {
  public readonly RuleEvent: RuleEvents;

  constructor(ruleEvent: RuleEvents) {
    this.RuleEvent = ruleEvent;
  }

  public filterByContext(rules: TemplateRule[], context: string): TemplateRule[] {
    const filteredRules = rules.filter((rule) => this.byEventAndContext(rule, context));
    return sortBy(filteredRules, (rule) => rule.priority, ['asc']);
  }

  private byEventAndContext(rule: TemplateRule, context: string): boolean {
    return rule.intermediateRuleDefinition.events.some(
      event => event.context === context && event.eventType === this.RuleEvent
    );
  }
}
