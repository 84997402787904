import { Injectable } from '@angular/core';
import { FieldDefinition, Form, Module } from 'model/experiment.interface';
import { Subject, Subscription } from 'rxjs';
import {
  FormTemplate,
  FormItemType,
  FieldGroupResponse,
  FieldDefinitionResponse,
  NodeType
} from '../api/models';

type FieldTabOrder = { [field: string]: number | undefined };
export type TabOrderChangeContext = {
  field: string;
  oldTabOrder?: number;
  newTabOrder: number;
  deleted: boolean;
};
enum TabReOrderDirection {
  Increment,
  Decrement,
  None
}

@Injectable({
  providedIn: 'root'
})
export class FormTabOrderService {
  private template: FormTemplate = {} as FormTemplate;
  private controlsTabOrder: FieldTabOrder = {};
  private tabIndexChanged = new Subject<TabOrderChangeContext>();

  private readonly tabOrderChangedDirection = (
    fieldDefinition: FieldDefinition,
    context: TabOrderChangeContext
  ) => {
    if (fieldDefinition.field === context.field) {
      return TabReOrderDirection.None;
    }
    if (context.newTabOrder >= (context.oldTabOrder || 0) || context.deleted) {
      return TabReOrderDirection.Decrement;
    } else {
      return TabReOrderDirection.Increment;
    }
  };

  private readonly incrementTabOrder = (
    fieldDefinition: FieldDefinitionResponse,
    context: TabOrderChangeContext
  ) => {
    if (
      fieldDefinition.fieldAttributes.tabOrder &&
      fieldDefinition.fieldAttributes.tabOrder >= context.newTabOrder &&
      fieldDefinition.fieldAttributes.tabOrder < (context.oldTabOrder as number)
    ) {
      fieldDefinition.fieldAttributes.tabOrder++;
      this.controlsTabOrder[fieldDefinition.field] = fieldDefinition.fieldAttributes.tabOrder;
    }
  };

  private readonly decrementTabOrder = (
    fieldDefinition: FieldDefinitionResponse,
    context: TabOrderChangeContext
  ) => {
    if (
      fieldDefinition.fieldAttributes.tabOrder &&
      fieldDefinition.fieldAttributes.tabOrder <= context.newTabOrder &&
      fieldDefinition.fieldAttributes.tabOrder > (context.oldTabOrder as number)
    ) {
      fieldDefinition.fieldAttributes.tabOrder--;
      this.controlsTabOrder[fieldDefinition.field] = fieldDefinition.fieldAttributes.tabOrder;
    }
  };

  private readonly tabOrderSequenceProcessor: {
    [direction: string]: (
      fieldDefinition: FieldDefinitionResponse,
      context: TabOrderChangeContext
    ) => void;
  } = {
    Decrement: this.decrementTabOrder,
    Increment: this.incrementTabOrder,
    None: () => {}
  };

  private fieldTabOrderListeners: { [field: string]: Subscription } = {};

  private reset() {
    this.fieldTabOrderListeners = {};
    this.controlsTabOrder = {};
    this.tabIndexChanged = new Subject<TabOrderChangeContext>();
  }

  public setTemplate(template: FormTemplate) {
    this.reset();
    this.template = template;
    this.registerTabOrderChangesHandler(this.template.fieldDefinitions);
    this.generateTabOrderInOlderVersionOfTemplate(this.template.fieldDefinitions);
  }

  public fieldNameChanged(field: FieldDefinition, newFieldName: string): void {
    this.watchTabOrderChanges(field);
  }

  public generateTabOrderFor(field: FieldDefinition, excludedFields: string[] = []): number {
    field.fieldAttributes.tabOrder = this.getTabOrderForControl(excludedFields);
    this.watchTabOrderChanges(field);
    return field.fieldAttributes.tabOrder;
  }

  private getTabOrderForControl(excludedFields: string[] = []): number {   
    const tabOrder = this.getMissingIndexInSequence();
    if (tabOrder) {
      return tabOrder;
    } 
    return this.getControlsCount(this.template.fieldDefinitions, excludedFields) + 1;
  }
  
  private getMissingIndexInSequence(): number | undefined {
    if (!this.controlsTabOrder) {
      return undefined;
    }
    const sortedTabOrder = (Object.values(this.controlsTabOrder) as number[]).sort((n1,n2) => n1 - n2);    
    for (let index = 0; index < (sortedTabOrder.length - 1); index++) {
      if (sortedTabOrder[index + 1] - sortedTabOrder[index] > 1) {
        return sortedTabOrder[index] + 1;
      }
    }
    if(sortedTabOrder.length === 1){
      if(sortedTabOrder[0] -1 > 0){
        return sortedTabOrder[0]-(sortedTabOrder[0]-1);
      }
    }
    return undefined;
  }

  public removeTabOrderOf(field: FieldDefinition) {
    this.tabOrderChanged(field, true, 10000);
    delete field.fieldAttributes.tabOrder;
    delete this.controlsTabOrder[field.field];
    this.fieldTabOrderListeners[field.field]?.unsubscribe();
  }

  public tabOrderChanged(field: FieldDefinition, fieldIsDeleted = false, newTabOrder?: number) {
    if (!field.fieldAttributes) {
      field.fieldAttributes = {};
    }
    if (field.fieldAttributes.tabOrder) {
      field.fieldAttributes.tabOrder = Number.parseInt(field.fieldAttributes.tabOrder);
    }
    if (this.controlsTabOrder[field.field] !== field.fieldAttributes.tabOrder || fieldIsDeleted) {
      this.tabIndexChanged.next({
        field: field.field,
        newTabOrder: newTabOrder || field.fieldAttributes.tabOrder,
        oldTabOrder: this.controlsTabOrder[field.field],
        deleted: fieldIsDeleted
      });
      this.controlsTabOrder[field.field] = field.fieldAttributes.tabOrder;
    }
  }

  /**
   * Get length of controls in the form
   */
  public getControlsCount(
    fields: Array<FieldGroupResponse | FieldDefinitionResponse>,
    excludedFields: string[] = []
  ): number {
    let count = 0;
    fields
      .filter(
        (f) =>
          (!(f as FieldDefinition).fieldAttributes ||
            !(f as FieldDefinition).fieldAttributes.skipTabOrder) &&
          !excludedFields.includes(f.field)
      )
      .forEach((fieldDefinition) => {
        if (fieldDefinition.itemType === FormItemType.FieldGroup) {
          count += this.getControlsCount(
            (fieldDefinition as FieldGroupResponse).fieldDefinitions,
            excludedFields
          );
        } else {
          count++;
        }
      });
    return count;
  }

  /**
   * Register tab sequence order handler
   */
  private registerTabOrderChangesHandler(
    fields: Array<FieldGroupResponse | FieldDefinitionResponse>
  ): void {
    fields.forEach((fieldDefinition) => {
      if (fieldDefinition.itemType === FormItemType.FieldGroup) {
        this.registerTabOrderChangesHandler(
          (fieldDefinition as FieldGroupResponse).fieldDefinitions
        );
      } else {
        if ((fieldDefinition as FieldDefinitionResponse).fieldAttributes.skipTabOrder) {
          return;
        }
        this.watchTabOrderChanges(fieldDefinition as FieldDefinitionResponse);
      }
    });
  }

  public watchTabOrderChanges(fieldDefinition: FieldDefinitionResponse): void {
    this.controlsTabOrder[fieldDefinition.field] = fieldDefinition.fieldAttributes.tabOrder;
    this.fieldTabOrderListeners[fieldDefinition.field] = this.tabIndexChanged.subscribe({
      next: (context) => {
        this.reOrderTabIndex(fieldDefinition, context);
      }
    });
  }

  private reOrderTabIndex(
    fieldDefinition: FieldDefinitionResponse,
    context: TabOrderChangeContext
  ): void {
    const priorityDirection = this.tabOrderChangedDirection(fieldDefinition, context);
    this.tabOrderSequenceProcessor[TabReOrderDirection[priorityDirection]](
      fieldDefinition,
      context
    );
  }

  /**
   * Generates the tab order for all controls for older version of template
   */
  private generateTabOrderInOlderVersionOfTemplate(
    fields: Array<FieldGroupResponse | FieldDefinitionResponse>
  ): void {
    if (Object.keys(this.controlsTabOrder).some((field) => this.controlsTabOrder[field])) {
      return;
    }
    let tabOrder = 0;
    fields.forEach((fieldDefinition) => {
      if (fieldDefinition.itemType === FormItemType.FieldGroup) {
        this.generateTabOrderInOlderVersionOfTemplate(
          (fieldDefinition as FieldGroupResponse).fieldDefinitions
        );
      } else {
        ++tabOrder;
        (fieldDefinition as FieldDefinition).fieldAttributes.tabOrder = tabOrder;
        this.controlsTabOrder[fieldDefinition.field] = tabOrder;
      }
    });
  }

  public buildTabOrderReservation(module: Module, formAdditionalTabOrderReservationCount: number, tableTabOrderReservationCount: number): { [greatestTabOrder: number]: number } {
    const tabOrderReservation: { [greatestTabOrder: number]: number } = {}
    let reservedTabOrder = 0;
    module.items.forEach((item, index) => {
      if (item.itemType === NodeType.Form) {
        tabOrderReservation[index] = reservedTabOrder;
        reservedTabOrder +=
          this.getControlsCount((item as Form).fieldDefinitions) + formAdditionalTabOrderReservationCount +  1;
      } else if (item.itemType === NodeType.Table) {
        tabOrderReservation[index] = reservedTabOrder;
        reservedTabOrder += tableTabOrderReservationCount;
      }
    });
    return tabOrderReservation;
  }
}
