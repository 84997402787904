import { Injectable } from '@angular/core';
import { ExperimentVariable, VariablesNode as ExperimentVariablesNode} from '../../api/models';
import { RuleDisplayMessageHandlerService } from './rule-display-message-handler.service';
import { RuleNotificationsInterceptorService } from './rule-notification-interceptor.service';
import { RuleSetVariableHandlerService } from './rule-set-variable-handler.service';
import { RecipeVariable, VariablesNode as RecipeVariablesNode} from '../../api/cookbook/models';

export type ExperimentNodeSpecificVariables = {
  [key: string]: {
    [key: string]: ExperimentVariable;
  };
};

export type RecipeNodeSpecificVariables = {
  [key: string]: {
    [key: string]: RecipeVariable;
  };
};

export enum RuleActionHandlerHostItemType {
  Experiment = 'Experiment',
  Recipe = 'Recipe'
}

@Injectable()
/** Host handlers through this service. */
export class RuleActionHandlerHostService {
  public static readonly Assembly: string = 'ELN.Blazor.Entry' as const;
  public static readonly CacheVariables: string = 'CacheVariables' as const;

  // Messages defined at rule engine level.
  public static readonly _RuleErrorMessages = {
    UnitDimensionMismatch: $localize`:@@UnitDimensionMismatch:Could Not Perform a Requested Calculation. Source Unit and Target Units did not share a dimension.`,
    SourceUnitNull: $localize`:@@SourceUnitNull:Source unit does not match.`,
    TargetUnitNull: $localize`:@@TargetUnitNull:Target unit does not match.`
  };

  public static CurrentItemId: string;
  public static ItemType: RuleActionHandlerHostItemType;
  private static _itemVariablesNode: ExperimentVariablesNode | RecipeVariablesNode;

  public static get ItemVariablesNode(): ExperimentVariablesNode | RecipeVariablesNode {
    return this._itemVariablesNode;
  }
  public static set ItemVariablesNode(value: ExperimentVariablesNode | RecipeVariablesNode) {
    this._itemVariablesNode = value;
  }

  constructor(
    private readonly _ruleDisplayMessageHandlerService: RuleDisplayMessageHandlerService,
    private readonly _ruleSetVariableHandlerService: RuleSetVariableHandlerService,
    private readonly _ruleNotificationsInterceptorService: RuleNotificationsInterceptorService
  ) { }

  public static delegateCacheVariablesToBlazor<T>(value?: ExperimentVariablesNode | RecipeVariablesNode): Promise<T> {
    let nodeWiseVariables = {};
    if (this.ItemType === RuleActionHandlerHostItemType.Experiment) {
      value = value || RuleActionHandlerHostService.ItemVariablesNode || ({} as ExperimentVariablesNode);
      nodeWiseVariables = this.convertToExperimentNodeSpecificVariables(value as ExperimentVariablesNode);
    } else {
      value = value || RuleActionHandlerHostService.ItemVariablesNode || ({} as RecipeVariablesNode);
      nodeWiseVariables = this.convertToRecipeNodeSpecificVariables(value as RecipeVariablesNode);
    }
    
    return DotNet.invokeMethodAsync(
      RuleActionHandlerHostService.Assembly,
      RuleActionHandlerHostService.CacheVariables,
      JSON.stringify(nodeWiseVariables)
    );
  }

  public static convertToExperimentNodeSpecificVariables(
    ExperimentVariablesNode: ExperimentVariablesNode
  ): ExperimentNodeSpecificVariables {
    const nodeWiseVariables: ExperimentNodeSpecificVariables = {};
    Object.keys(ExperimentVariablesNode.variables).forEach((variableName) => {
      if (!nodeWiseVariables[ExperimentVariablesNode.variables[variableName].nodeId]) {
        nodeWiseVariables[ExperimentVariablesNode.variables[variableName].nodeId] = {};
      }
      nodeWiseVariables[ExperimentVariablesNode.variables[variableName].nodeId][variableName] =
        ExperimentVariablesNode.variables[variableName];
    });
    return nodeWiseVariables;
  }

  public static convertToRecipeNodeSpecificVariables(
    RecipeVariablesNode: RecipeVariablesNode
  ): RecipeNodeSpecificVariables {
    const nodeWiseVariables: RecipeNodeSpecificVariables = {};
    Object.keys(RecipeVariablesNode.variables).forEach((variableName) => {
      if (!nodeWiseVariables[RecipeVariablesNode.variables[variableName].nodeId]) {
        nodeWiseVariables[RecipeVariablesNode.variables[variableName].nodeId] = {};
      }
      nodeWiseVariables[RecipeVariablesNode.variables[variableName].nodeId][variableName] =
        RecipeVariablesNode.variables[variableName];
    });
    return nodeWiseVariables;
  }
}
