import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { SpinnerOptions } from './spinner-options.interface';

@Injectable({
  providedIn: 'root'
})
export class ElnProgressSpinnerService {
  private readonly spinnerHidden = new Subject<void>();
  public SpinnerHidden = this.spinnerHidden.asObservable();

  private _inProgress = false;
  public get InProgress(): boolean {
    return this._inProgress;
  }

  private _spinnerOptions: SpinnerOptions = {
    message: '',
    i18nMessage: '',
    useOverlay: true
  };

  public get SpinnerOptions(): SpinnerOptions {
    return this._spinnerOptions;
  }

  public UpdateProgressMessage(message: string){
    this._spinnerOptions.message = message;
  }

  public Show(options: SpinnerOptions) {
    this._inProgress = true;
    this._spinnerOptions = { ...options };
  }

  public Hide() {
    this._inProgress = false;
    this.spinnerHidden.next();
  }

    /** 
   * Hides the spinner when notifier emits the values
   * This will be to hide the spinner on completion of asynchronous operation. 
  */
  public RegisterObservableToHideSpinner<TReason>(notifier: Observable<TReason>) : Subscription {
    return notifier.subscribe((_reason) => {
      this.Hide();
    });
  }
}
