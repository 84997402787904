import { ApiConfiguration as CoreServiceApiProxyConfiguration } from './api/api-configuration';
import { ApiConfiguration as SearchServiceApiProxyConfiguration } from './api/search/api-configuration';
import { ApiConfiguration as AuditServiceApiProxyConfiguration } from './api/audit/api-configuration';
import { ApiConfiguration as DataEntryServiceApiProxyConfiguration } from './api/data-entry/api-configuration';
import { ApiConfiguration as DataPackageServiceApiProxyConfiguration } from './api/data-package/api-configuration';
import { ApiConfiguration as InstrumentIntegrationServiceApiProxyConfiguration } from './api/instrument-integration/api-configuration';
import { ApiConfiguration as CookbookServiceApiProxyConfiguration } from './api/cookbook/api-configuration';
import { ApiConfiguration as InternalCommentServiceApiProxyConfiguration } from './api/internal-comment/api-configuration';
import { ApiConfiguration as ElnInstrumentsServiceApiProxyConfiguration } from './api/eln-instruments/api-configuration';
import { ApiConfiguration as FileIntegrationServiceApiProxyConfiguration } from './api/file-integration/api-configuration';
import { ApiConfiguration as InstrumentAdminServiceApiProxyConfiguration } from './api/instrument-admin/api-configuration';
import { ApiConfiguration as LabelServiceApiProxyConfiguration } from './api/labels/api-configuration';
import { ConfigurationService } from './services/configuration.service';
import { forkJoin, interval, Observable, of } from 'rxjs';
import { environment } from '../environments/environment';
import { I18nConfigService } from './services/i18n-config.service';
import { UnitLoaderService } from './services/unit-loader.service';
import { APP_INITIALIZER, EnvironmentProviders, Injectable, Provider } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ELimsUserData } from './integrations/data/user/elims-user-data';
import { UserService } from './services/user.service';


export function applicationInitializerDefinition(): Provider | EnvironmentProviders {
  return {
    provide: APP_INITIALIZER,
    multi: true,
    deps: [ConfigurationService, I18nConfigService,
      CoreServiceApiProxyConfiguration,
      SearchServiceApiProxyConfiguration,
      DataEntryServiceApiProxyConfiguration,
      AuditServiceApiProxyConfiguration,
      DataPackageServiceApiProxyConfiguration,
      InstrumentIntegrationServiceApiProxyConfiguration,
      CookbookServiceApiProxyConfiguration,
      InternalCommentServiceApiProxyConfiguration,
      ElnInstrumentsServiceApiProxyConfiguration,
      FileIntegrationServiceApiProxyConfiguration,
      InstrumentAdminServiceApiProxyConfiguration,
      LabelServiceApiProxyConfiguration,
      UnitLoaderService,
      UserService,
      UserLabSiteInitializer,
      HttpClientModule],
    useFactory: applicationInitializers
  }
}

// Sonar Warning for S107 Parameters lims has been ignored because the initializer can be only single function. 
export function applicationInitializers(configurationService: ConfigurationService, // NOSONAR
  i18nConfigService: I18nConfigService,
  coreServiceApiProxyConfiguration: CoreServiceApiProxyConfiguration,
  searchServiceApiProxyConfiguration: SearchServiceApiProxyConfiguration,
  dataEntryServiceApiProxyConfiguration: DataEntryServiceApiProxyConfiguration,
  auditServiceApiProxyConfiguration: AuditServiceApiProxyConfiguration,
  dataPackageServiceApiProxyConfiguration: DataPackageServiceApiProxyConfiguration,
  instrumentIntegrationServiceApiProxyConfiguration: InstrumentIntegrationServiceApiProxyConfiguration,
  cookbookServiceApiProxyConfiguration: CookbookServiceApiProxyConfiguration,
  internalCommentServiceApiProxyConfiguration: InternalCommentServiceApiProxyConfiguration,
  elnInstrumentsServiceApiProxyConfiguration: ElnInstrumentsServiceApiProxyConfiguration,
  fileIntegrationServiceApiProxyConfiguration: FileIntegrationServiceApiProxyConfiguration,
  instrumentAdminServiceApiProxyConfiguration: InstrumentAdminServiceApiProxyConfiguration,
  labelServiceApiProxyConfiguration: LabelServiceApiProxyConfiguration,
  unitLoaderService: UnitLoaderService,
  userService: UserService,
  userLabSiteInitializer: UserLabSiteInitializer
) {
  const promise = new Promise((resolve, _) => {
    const i18nConfigInitializer = i18nConfigService.initialize();
    const initializers = forkJoin({
      configurations: configurationService.loadConfig(), translations: i18nConfigInitializer || of(true),
      userLabSiteConfigurations: userLabSiteInitializer.pollUserLabSiteConfigurations(userService)
    });
    initializers.subscribe({
      next: () => {
        coreServiceApiProxyConfiguration.rootUrl = environment.coreServicesUrl;
        searchServiceApiProxyConfiguration.rootUrl = environment.searchServiceUrl;
        auditServiceApiProxyConfiguration.rootUrl = environment.auditServiceUrl;
        dataEntryServiceApiProxyConfiguration.rootUrl = environment.dataEntryServicesUrl;
        dataPackageServiceApiProxyConfiguration.rootUrl = environment.dataPackageServiceUrl;
        instrumentIntegrationServiceApiProxyConfiguration.rootUrl = environment.instrumentIntegrationServiceUrl;
        cookbookServiceApiProxyConfiguration.rootUrl = environment.cookbookServiceUrl;
        internalCommentServiceApiProxyConfiguration.rootUrl = environment.internalCommentServiceUrl;
        elnInstrumentsServiceApiProxyConfiguration.rootUrl = environment.elnInstrumentsServiceUrl;
        fileIntegrationServiceApiProxyConfiguration.rootUrl = environment.elnFilesServiceUrl;
        instrumentAdminServiceApiProxyConfiguration.rootUrl = environment.instrumentAdminServiceUrl;
        labelServiceApiProxyConfiguration.rootUrl = environment.elnLabelsServiceUrl;
        unitLoaderService.loadUnits();
        resolve(0);
      },
      error: (reason) => {
        console.error(reason);
      }
    })
  });
  return () => promise;
}

@Injectable({
  providedIn: 'root',
})
export class UserLabSiteInitializer {
  // Poll the user lab site configurations from lims system for 2 seconds
  pollUserLabSiteConfigurations(userService: UserService): Observable<boolean> {
    if (!this.isLimsHosted() || this.hasDefaultLabSiteCode()) {
      return of(true);
    }
    let counter = 0;
    return new Observable<boolean>((observer) => {
      const labSitePollingInterval = interval(500);
      const labSitePollingIntervalSubscription = labSitePollingInterval.subscribe({
        next: () => {
          if (counter === -1 || counter > 4) {
            observer.next(true);
            observer.complete();
            labSitePollingIntervalSubscription.unsubscribe();
          }
          if (this.hasDefaultLabSiteCode()) {
            counter = -1;
            console.log('The User Labsite Code Resolved In Initializer');
            userService.initializeUser();
            observer.next(true);
            observer.complete();
            labSitePollingIntervalSubscription.unsubscribe();
          }
          counter++;
        }
      })
    });
  }

  hasDefaultLabSiteCode(): boolean {
    const limsUserData = new ELimsUserData((window as any).Master, (window as any).app);
    return limsUserData.elimsMaster && limsUserData.elimsMaster.currentUser &&
      limsUserData.elimsMaster.currentUser.DefaultLabSiteCode &&
      limsUserData.elimsMaster.currentUser.DefaultLabSiteCode.toString().length > 0;
  }

  isLimsHosted(): boolean {
    return ConfigurationService.isLimsHosted;
  }
}
