import { Component, Input, ViewChild, OnDestroy,
  OnInit, Renderer2, ElementRef, OnChanges, Output, EventEmitter } from '@angular/core';
import { BptGridComponent, BptGridMode, BptGridRowActionClickEvent, BptGridRowActionConfiguration,
  BptRowActionElement, ColumnDefinition, GridContextMenuItem, IFlagConfig, RowSelection } from 'bpt-ui-library/bpt-grid';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ActivityInputType, ClientFacingNoteContextType, ExperimentPreparationStatus, ModifiableDataValue, PreparationSubStatus } from '../../api/models';
import { PreparationConstants } from '../preparation-constants';
import { PreparationService } from '../services/preparation.service';
import { PreparationItem } from '../models/preparation-presentation.model';
import { ExpirationModel } from '../models/preparation.model';
import { ClientFacingNoteRequest, FlagConfigRequest } from '../models/comments.model';
import { UnsubscribeAll } from '../../shared/rx-js-helpers';
import { FlagRendererService } from '../../experiment/services/flag-renderer.service';
import { DialogService } from 'primeng/dynamicdialog';
import { RemovedPreparationsLabitemsComponent } from './removed-preparations-labitems/removed-preparations-labitems.component';
import { PreparationLabItemsService } from '../services/preparation-labitems.service';
import { ClientStateService } from '../../services/client-state.service';
import { BaseComponent } from '../../base/base.component';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../services/user.service';
import { PreparationEventService } from '../services/preparation-event.service';
import { ExperimentService } from '../../experiment/services/experiment.service';
import { PromptType } from '../../prompt/models/prompt.model';

export type LabItemWisePermissions = { [key: string]: boolean };
export enum PreparationStatus {
  Pending,
  Reviewed
}
export interface PreparationRow {
  id: string,
  preparationNumber: string,
  name: ModifiableDataValue,
  formulaComponents?: ModifiableDataValue,
  status: ExperimentPreparationStatus,
  expiration?: ModifiableDataValue,
  storageCondition?: ModifiableDataValue,
  concentration?: ModifiableDataValue,
  description?: ModifiableDataValue
}

@Component({
  selector: 'app-preparations-labitems',
  templateUrl: './preparations.labitems.component.html',
  styleUrls: ['./preparations.labitems.component.scss']
})
export class PreparationsLabitemsComponent extends BaseComponent implements OnInit, OnDestroy,OnChanges {
  @Input() internalInformationReadOnly = false;
  @Input() preparations: PreparationItem[] = [];
  @Input() currentActivityId = "";
  @Input() allowRowEdit = false;
  @Input() isExperimentInReview = false;
  @Input() titleOfTable = $localize`:@@preparationModuleHeader:Preparations`;
  @Input() flagConfigurationProvider! : (flagConfigRequest: FlagConfigRequest) => IFlagConfig;
  @Output() clientFacingNoteRequest = new EventEmitter<ClientFacingNoteRequest>();

  subscriptions: Subscription[] = [];
  showGrid = false;
  showSlider = false;
  youDoNotHaveAnyPreparationBanner = $localize`:@@YouDontHaveAnyPreparation:YOU DON'T HAVE ANY PREPARATIONS.`;
  yourPreparationWillAppearHereBanner = $localize`:@@yourPreparationWillAppearHere: Your Preparation will appear here.`;
  preparationModuleHeader = $localize`:@@preparationModuleHeader:Preparations`;
  preparationsOptions = {
    buttonLabel: $localize`:@@createPreparationForSlider:✛ Create preparation`
  }
  private readonly keyNameOf = <T>(name: Extract<keyof T, string>): string => name;
  @ViewChild('labItemPreparationsGrid') grid!: BptGridComponent;

  gridActions!: BptGridRowActionConfiguration;
  primitiveValue!: { [key: string]: any }[];
  rowSelection: RowSelection = undefined;
  gridOptions = {
    includeRowNumberColumn: true,
    showAutoSizeButton: true,
    showFilterToggleButton: true,
    showGridOptionsButton: true,
    allowRowAdd: false,
    gridMode: BptGridMode.dataEntry,
  }
  rowActions: BptRowActionElement[] = [];
  columnDefinitions: ColumnDefinition[] = this.preparationLabItemService.getColumnDefinitions(this.allowRowEdit, false,
     this.experimentService.currentExperiment?.workflowState);
  showInternalInfoSlider = false;
  preparationDataForInternalInformation!: PreparationItem;
  lastRowToBeDeleted = 1;
  labItemsPreparationId = 'labItemsPreparation';
  preparationRowDeleteActionId = 'bpt-preparation-delete-row';
  preparationOpenContainerFlaskId = 'bpt-preparation-container-flask';
  preparationExpirationId = 'bpt-preparation-expiration'
  preparationInternalInformationId = 'bpt-preparation-internal-information-icon';
  preparationRefreshButtonId = 'bpt-preparation-refresh-icon';
  indexOfCurrentFlaskIconClickedOnGrid = "";
  indexOfCurrentInformationIconClickedOnGrid = 0;
  indexOfCurrentDeletedIconClickedOnGrid = 0;
  indexOfCurrentRefreshIconClickedOnGrid = 0;
  noOfPreparationsInActivity = 0;
  showExpirationSlider = false;
  sourceGridData: ExpirationModel = { suitableForUse: false };
  indexOfCurrentFlaskIconClickedOnGridForUserEditRevert = '';
  preparationsGridId = '';

  /** IANA time zone id for lab site */
  get labSiteTimeZone(): string {
    return UserService.currentLabSiteTimeZone.id();
  }
  constructor(
    private readonly preparationService: PreparationService,
    private readonly preparationLabItemService: PreparationLabItemsService,
    private readonly preparationEventService: PreparationEventService,
    private readonly renderer: Renderer2,
    private readonly elementRef: ElementRef,
    private readonly dialogService: DialogService,
    public readonly clientStateService: ClientStateService,
    public readonly activatedRoute: ActivatedRoute,
    private readonly flagRenderService: FlagRendererService,
    private readonly experimentService: ExperimentService
  ) {
    super(clientStateService, activatedRoute);
    this.subscriptions.push(
      this.flagRenderService.ClientFacingNoteAdded.subscribe({
        next: (note) => {
          this.elementRef.nativeElement.dispatchEvent(note);
        }
      })
    );
    this.watchRefreshGridNotification();
  }

  ngOnChanges(): void {
   this.refreshGrid();
  }

  ngOnInit(): void {
    if (this.preparations.length > 0) {
      this.augmentColumnsWithCornerFlagProviderForCells();
      this.columnDefinitions = this.preparationLabItemService.getColumnDefinitions(this.allowRowEdit,
      this.preparationLabItemService.doesRecipePromptTypeExist(PromptType.Preparations),
      this.experimentService.currentExperiment?.workflowState
      );
      this.addGridActions();
      this.refreshGrid();
      this.renderer.setAttribute(this.elementRef.nativeElement, 'data-title', this.titleOfTable);
    }
    this.watchLabItemPreparationRefreshNotification();
    this.watchPreparationRefreshedNotification();
  }

  private watchLabItemPreparationRefreshNotification() {
    this.subscriptions.push(
      this.preparationLabItemService.labItemPreparationShouldRefresh.subscribe({
        next: () => {
          this.refreshGrid();
          this.grid?.gridApi?.refreshCells({ force: true });
        }
      })
    );
  }
  private watchRefreshGridNotification() {
    this.subscriptions.push(
      this.preparationEventService.refreshGrid.subscribe({
        next: () => {
          this.grid?.gridApi?.refreshCells({ force: true });
        }
      })
    );
  }
  private watchPreparationRefreshedNotification() {
    this.subscriptions.push(
      this.preparationLabItemService.preparationRefreshed.subscribe({
        next: (preparationNumber: string) => {
          this.refreshGrid();
          this.preparationLabItemService.refreshSuccessMessage(preparationNumber);
          this.experimentService._isCurrentUserCollaboratorSubject$.next(true);
        }
      })
    );
  }
  getContextMenu(): GridContextMenuItem[] {
    return [
      {
        label: $localize`:@@clientFacingNoteContextMenuOption:Client-facing Notes`,
        action: () => {
          const cell = this.grid?.gridApi.getFocusedCell();
          if (cell) {
            const row = this.grid?.gridApi.getDisplayedRowAtIndex(cell.rowIndex);
            const colDef = cell.column.getColDef();
            const field = colDef.field as string;
            if (
              row && row.id &&
              colDef?.field
            ) {
              this.clientFacingNoteRequest.next( {
                paths:  [
                  row.id,
                  field,
                  this.currentActivityId ?? '',
                  ClientFacingNoteContextType.LabItemsPreparation
                ],
                contextType: ClientFacingNoteContextType.LabItemsPreparation,
                nodeId: this.currentActivityId ?? ''
              }
              );
            }
          }
        },
        icon: '<img src="assets/eln-assets/apps-add.svg" class="ag-icon ag-custom-icon" />',
        disabled: this.isExperimentInReview
      },
      {
        label: $localize`:@@commentsHeader:Internal Comments`,
        action: () => {

        },
        icon: '<img src="assets/eln-assets/apps-add.svg" class="ag-icon ag-custom-icon" />'
      }
    ];
  }

  private refreshGrid() {
    this.showGrid=false;
    this.primitiveValue = this.preparationService.getAllPreparationsFromCurrentActivity(
      this.preparations.filter(preparation => preparation.summary?.preparationSubStatus !== PreparationSubStatus.RemovedAndCannotBeRestored &&
        preparation.summary?.preparationSubStatus !== PreparationSubStatus.RemovedButCanBeRestored));
    this.primitiveValue = this.preparationService.getPrimitiveDataValueRows(this.primitiveValue as any);
    this.grid?.gridApi?.setGridOption('rowData', this.primitiveValue);
    this.showGrid=true;
    this.refreshGridHoverIcons();
  }
  private refreshGridHoverIcons() {
    if (this.rowActions?.length > 0) {
      var isTrashIconEnabled = this.evaluatePermissions()['eln-remove-lab-item'];
      const trashIcon = this.rowActions.find(
        (item) => item.id === this.preparationRowDeleteActionId
      );
      if (trashIcon) {
        trashIcon.enabled = isTrashIconEnabled;
      }
      var isRefreshIconEnabled =
        this.preparationLabItemService.isRefreshButtonEnabledForReviewer() ||
        this.evaluatePermissions()['eln-refresh-lab-item'];
      const refreshIcon = this.rowActions.find(
        (item) => item.id === this.preparationRefreshButtonId
      );
      if (refreshIcon) {
        refreshIcon.enabled = isRefreshIconEnabled;
      }
    }
  }

  onGridReady() {
    this.augmentColumnsWithCornerFlagProviderForCells();
  }
  addGridActions() {
    this.rowActions = this.getRowActionItems();
    const actionsSubject: BehaviorSubject<BptRowActionElement[]> = new BehaviorSubject(this.rowActions);
    this.gridActions = {
      actions: actionsSubject
    };
  }

  private  augmentColumnsWithCornerFlagProviderForCells ()  {
    if(this.flagConfigurationProvider){
      const flagConfigProvider = (
        flag: 'top-right' | 'bottom-right',
        rowId: string,
        field: string
      ): IFlagConfig => {
        let id = "";
        if( flag ==='top-right') {
          id = rowId
        }
        const fieldName = PreparationConstants.columnDisplayNames[field];
          return this.flagConfigurationProvider({
            flag,
            fieldName,
            id,
            field,
            commentContext:  ClientFacingNoteContextType.LabItemsPreparation,
            rowId
          })
        };
      this.columnDefinitions.forEach((c: ColumnDefinition) => {
        c.flagConfigProvider = flagConfigProvider;
      });
    }
  }
  /**
    * On hover on row it will add icons to grid
   */
  public getRowActionItems(): BptRowActionElement[] {
    return [
      {
        id: this.preparationInternalInformationId,
        enabled: true,
        styleClass: 'pi pi-info-circle',
        click: this.openInternalInformationSlider.bind(this),
        tooltip: $localize`:@@EditInternalInformation:Internal Information`,// Need to check on this tooltip
        tooltipPosition: 'bottom'
      },
      {
        id: this.preparationRowDeleteActionId,
        enabled: this.evaluatePermissions()['eln-remove-lab-item'],
        styleClass: 'pi pi-trash',
        click: this.rowRemoveActionClick.bind(this),
        tooltip: $localize`:@@RemoveItem:Remove item`,
        tooltipPosition: 'bottom'
      },
      {
        id: this.preparationRefreshButtonId,
        enabled: this.preparationLabItemService.isRefreshButtonEnabledForReviewer() ||  this.evaluatePermissions()['eln-refresh-lab-item'],
        styleClass: 'fas fa-sync-alt',
        click: this.refreshPreparationInformation.bind(this),
        tooltip: $localize`:@@RefreshItem:Refresh item`,
        tooltipPosition: 'bottom'
      }
    ];
  }

  openInternalInformationSlider(e: BptGridRowActionClickEvent) {
    this.indexOfCurrentInformationIconClickedOnGrid = e.params.data.hash;
    this.showInternalInfoSlider = true;
    this.preparationDataForInternalInformation = this.preparations.filter(preparation => preparation.preparationId === e.params.data.id)[0];
  }

  rowRemoveActionClick(e: BptGridRowActionClickEvent) {
    this.preparationLabItemService.removeLabItemPreparation(e.params.data.preparationNumber);
  }

  refreshPreparationInformation(e: BptGridRowActionClickEvent) {
    this.indexOfCurrentRefreshIconClickedOnGrid = e.params.data.hash;
    this.preparationLabItemService.refreshPreparation(e.params.data.id, e.params.data.preparationNumber);
  }

  openSlider() {
    this.showSlider = true;
  }

  closeSlider(isClosed: boolean) {
    this.showSlider = isClosed;
  }

  closeInternalInfoSlider(isClosed: boolean) {
    if (!isClosed) {
      this.showInternalInfoSlider = false;
    }
  }

  ngOnDestroy(): void {
    UnsubscribeAll(this.subscriptions);
  }

 public get containsRemovedRows(): boolean {
    return this.preparations.some(c => c.summary.preparationSubStatus === PreparationSubStatus.RemovedButCanBeRestored ||
      c.summary.preparationSubStatus === PreparationSubStatus.RemovedAndCannotBeRestored);
  }

  private evaluatePermissions(): LabItemWisePermissions {
    const features = this.clientStateService.getFeatureFlags(this.clientState);
    this.preparationLabItemService.featuresByClientState = features;
    return this.preparationLabItemService.evaluateUserPermissionsOnLabItems()[ActivityInputType.Preparation];
  }

  loadRemovedRowsDialog() {
    this.dialogService.open(RemovedPreparationsLabitemsComponent, {
      width: '80%',
      autoZIndex: true,
      closable: true,
      closeOnEscape: true,
      header: $localize`:@@preparationRemovedColumns:Removed Preparation Rows`,
      styleClass: 'eln-removed-preparations-dialog'
    });
  }

  loadAuditHistoryDialog() {
    this.preparationLabItemService.loadAuditHistoryDialog();
  }
}
