import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import {
  ObservationSpec,
  SingleValueSpec,
  SpecComparisonOperator,
  SpecComplianceAssessorType,
  SpecDisplayType,
  SpecificationValue as ApiSpecificationValue,
  SpecType,
  TwoValueRangeSpec,
  ValueState,
  ValueType,
  RangeType,
  SingleValueRangeSpec
} from '../../api/data-entry/models'
import { SpecificationValue } from '../../model/experiment.interface';
import { NA, Quantity } from 'bpt-ui-library/shared';
import { cloneDeep, isEqual } from 'lodash-es';
import { OperatorBoundaryType, SpecComparisonOperatorDropdownOption, SpecificationService, rangeTypes, validDisplayTypes } from './specification.service';
import { Unit } from '../../api/models';
import { DataValueService } from '../../experiment/services/data-value.service';
import { BptSliderComponent } from 'bpt-ui-library/bpt-slider';
import { BptDropdownComponent } from 'bpt-ui-library/bpt-dropdown';
import { BptTextInputComponent } from 'bpt-ui-library/bpt-text-input';
import { Checkbox } from 'primeng/checkbox';
import { BptQuantityComponent } from 'bpt-ui-library/bpt-quantity';
import { SpecificationPreloadScalingOptions } from '../../recipe/services/recipe.service';

export type SpecificationOptions = Exclude<SpecType, SpecType.Invalid> | typeof NA;
type TypeDropdownOption = {
  label: string | undefined;
  value: SpecificationOptions | undefined;
};
const emptySpecification = { type: ValueType.Specification, state: ValueState.Empty } as const;
const notApplicableSpecification = { type: ValueType.Specification, state: ValueState.NotApplicable } as const;

type ObservationValue = Omit<ObservationSpec, 'type' | 'state' | 'specType'>
  & { type: ValueType.Specification, state: ValueState.Set, specType: SpecType.Observation };

type SingleValue = Omit<SingleValueSpec, 'type' | 'state' | 'specType'>
  & { type: ValueType.Specification, state: ValueState.Set, specType: SpecType.SingleValue };

type SingleValueRange = Omit<SingleValueRangeSpec, 'type' | 'state' | 'specType'>
  & { type: ValueType.Specification, state: ValueState.Set, specType: SpecType.SingleValueRange };

type TwoValueRange = Omit<TwoValueRangeSpec, 'type' | 'state' | 'specType'>
  & { type: ValueType.Specification, state: ValueState.Set, specType: SpecType.TwoValueRange };

/** actually nothing meaningful from API SpecificationValue but it does serve as a consistency check */
type SpecificationType = (
  { type: ValueType.Specification, state: ValueState.Set, specType: SpecType.SingleValue } & Partial<SingleValueSpec> |
  { type: ValueType.Specification, state: ValueState.Set, specType: SpecType.SingleValueRange } & Partial<SingleValueRangeSpec> |
  { type: ValueType.Specification, state: ValueState.Set, specType: SpecType.TwoValueRange } & Partial<TwoValueRangeSpec> |
  { type: ValueType.Specification, state: ValueState.Set, specType: SpecType.Observation } & Partial<ObservationSpec>
);

/** A deeply partial SpecificationValue so can build up properties one-at-a-time. */
type PartialSpecificationValue = Partial<
  { type: ValueType.Specification, state: ValueState.Empty, specType?: SpecType } |
  { type: ValueType.Specification, state: ValueState.NotApplicable, specType?: SpecType } |
  Exclude<ApiSpecificationValue, 'specType'> & SpecificationType>;

type RangeTypeDropdownOption = {
  label: string;
  value: RangeType;
};

@Component({
  selector: 'app-specification-input',
  templateUrl: './specification-input.component.html',
  styleUrls: ['./specification-input.component.scss'],
})
export class SpecificationInputComponent implements OnInit, OnDestroy {
  /** Value before editing started. */
  @Input() inputModel!: SpecificationValue;
  @Input() allowedUnits!: Unit[];
  @Input() allowedSpecTypes!: SpecType[];

  /** Show the scaling checkboxes on a single-value specification. Currently limited to single-value. */
  @Input() preloadScalingOptions?: SpecificationPreloadScalingOptions;
  @Input() defaultUnit?: Unit;
  @Input() readOnly = false;
  @Input() disabled = false;
  /** Not to be used outside of the context of unit tests */
  @ViewChild('slider') slider!: BptSliderComponent;
  @ViewChildren(BptTextInputComponent) textInputs!: QueryList<BptTextInputComponent>;
  @ViewChildren(Checkbox) checkboxInputs!: QueryList<Checkbox>;
  @ViewChildren(BptQuantityComponent) quantityInputs!: QueryList<BptQuantityComponent>;
  @ViewChildren(BptDropdownComponent) dropdownInputs!: QueryList<BptDropdownComponent>;
  @Output() valueChanged: EventEmitter<SpecificationValue> = new EventEmitter<SpecificationValue>();
  @Output() preloadScalingOptionsChanged: EventEmitter<SpecificationPreloadScalingOptions> = new EventEmitter<SpecificationPreloadScalingOptions>();
  @Output() closed = new EventEmitter();

  /** Value while editing; initialized from inputModel. Required properties not enforced by type but only as errors in UI. */
  editModel!: PartialSpecificationValue;

  /** editModel typed as a partial ObservationSpec if it has specType Observation; otherwise undefined */
  get observationModel(): Partial<ObservationSpec> | undefined {
    return this.editModel.state === ValueState.Set && this.editModel.specType === SpecType.Observation ? this.editModel : undefined;
  }
  /** editModel typed as SingleValueSpec if it has specType SingleValue; otherwise undefined */
  get singleValueModel(): Partial<SingleValueSpec> | undefined {
    return this.editModel.state === ValueState.Set && this.editModel.specType === SpecType.SingleValue ? this.editModel : undefined;
  }
  /** editModel typed as TwoValueRange if it has specType TwoValueRange; otherwise undefined */
  get twoValueRangeModel(): Partial<TwoValueRangeSpec> | undefined {
    return this.editModel.state === ValueState.Set && this.editModel.specType === SpecType.TwoValueRange ? this.editModel : undefined;
  }
  /** editModel typed as singleValueRange if it has specType SingleValueRange; otherwise undefined */
  get singleValueRangeModel(): Partial<SingleValueRangeSpec> | undefined {
    return this.editModel.state === ValueState.Set && this.editModel.specType === SpecType.SingleValueRange ? this.editModel : undefined;
  }
  /** editModel typed as NotApplicable if it has state NotApplicable; otherwise undefined */
  get notApplicableModel(): typeof notApplicableSpecification | undefined {
    return this.editModel.state === ValueState.NotApplicable ? cloneDeep(notApplicableSpecification) : undefined;
  }
  /** editModel typed as Empty if it has state Empty; otherwise undefined */
  get emptyModel(): typeof emptySpecification | undefined {
    return this.editModel.state === ValueState.Empty ? cloneDeep(emptySpecification) : undefined;
  }

  /** Bound to the specification field, used to display the string value of the spec. */
  specificationDisplay?: string;

  visible = true;
  specTypes: TypeDropdownOption[] = [];
  specOperatorTypes: SpecComparisonOperatorDropdownOption[];
  specLowerComparisonOperators: SpecComparisonOperatorDropdownOption[];
  specUpperComparisonOperators: SpecComparisonOperatorDropdownOption[];
  rangeTypes: RangeTypeDropdownOption[];

  get naUnit(): Unit {
    return this.specService.naUnit;
  }

  /** Percent (%) */
  get pctUnit(): Unit {
    return this.specService.pctUnit;
  }

  get specDisplayType(): typeof SpecDisplayType {
    return SpecDisplayType;
  }

  get specDisplayStyle(): SpecDisplayType {
    const editModel = this.singleValueModel ?? this.singleValueRangeModel ?? this.twoValueRangeModel;
    if (!editModel) return SpecDisplayType.Expression;

    if (editModel.specType !== SpecType.SingleValue && editModel.specType !== SpecType.SingleValueRange && editModel.specType !== SpecType.TwoValueRange) {
      return SpecDisplayType.Expression;
    }

    if (editModel.displayType) {
      if (!validDisplayTypes[editModel.specType].includes(editModel.displayType)) {
        this.specDisplayStyle = this.specDisplayStyle = editModel.specType === SpecType.SingleValueRange ? SpecDisplayType.ToleranceRange : SpecDisplayType.Expression;
      }
      return editModel.displayType;
    }

    return SpecDisplayType.Expression;
  }
  set specDisplayStyle(value: SpecDisplayType) {
    if (this.singleValueModel) {
      this.singleValueModel.displayType = validDisplayTypes[SpecType.SingleValue].includes(value) ? value : SpecDisplayType.Expression;
    } else if (this.singleValueRangeModel) {
      this.singleValueRangeModel.displayType = validDisplayTypes[SpecType.SingleValueRange].includes(value) ? value : SpecDisplayType.ToleranceRange;
    } else if (this.twoValueRangeModel) {
      this.twoValueRangeModel.displayType = validDisplayTypes[SpecType.TwoValueRange].includes(value) ? value : SpecDisplayType.Expression;
    }
    this.setSpecificationDisplayString();
  }

  get complianceAssessment(): boolean {
    if (this.observationModel) {
      if (this.observationModel.complianceAssessorType && this.observationModel.complianceAssessorType !== SpecComplianceAssessorType.None) {
        return true;
      }
    }
    const editModel = this.singleValueModel ?? this.singleValueRangeModel ?? this.twoValueRangeModel;
    if (!editModel) return false;

    return this.getNumericSpecComplianceAssessment(editModel);
  }
  set complianceAssessment(value: boolean) {
    if (this.observationModel) {
      this.observationModel.complianceAssessorType = value ? SpecComplianceAssessorType.InexactMatch : SpecComplianceAssessorType.None;
      return;
    }
    const editModel = this.singleValueModel ?? this.singleValueRangeModel ?? this.twoValueRangeModel;
    if (!editModel) return;

    editModel.complianceAssessorType = value ? SpecComplianceAssessorType.Round : SpecComplianceAssessorType.None;
  }

  get exactMatchCompliance(): boolean {
    return this.observationModel?.complianceAssessorType === SpecComplianceAssessorType.ExactMatch;
  }
  set exactMatchCompliance(value: boolean) {
    if (this.observationModel) {
      if (!value) {
        this.observationModel.complianceAssessorType = SpecComplianceAssessorType.InexactMatch;
      } else {
        this.observationModel.complianceAssessorType = SpecComplianceAssessorType.ExactMatch;
      }
    }
  }

  get roundCompliance(): boolean | undefined {
    if (this.singleValueModel) {
      if (!this.singleValueModel.complianceAssessorType || this.singleValueModel.complianceAssessorType === SpecComplianceAssessorType.None) {
        return undefined;
      }
      return this.singleValueModel.complianceAssessorType === SpecComplianceAssessorType.Round;
    } else if (this.singleValueRangeModel) {
      if (!this.singleValueRangeModel.complianceAssessorType || this.singleValueRangeModel.complianceAssessorType === SpecComplianceAssessorType.None) {
        return undefined;
      }
      return this.singleValueRangeModel.complianceAssessorType === SpecComplianceAssessorType.Round;
    } else if (this.twoValueRangeModel) {
      if (!this.twoValueRangeModel.complianceAssessorType || this.twoValueRangeModel.complianceAssessorType === SpecComplianceAssessorType.None) {
        return undefined;
      }
      return this.twoValueRangeModel.complianceAssessorType === SpecComplianceAssessorType.Round;
    }
    return false;
  }
  set roundCompliance(value: boolean | undefined) {
    if (this.singleValueModel) {
      if (!value) {
        this.singleValueModel.complianceAssessorType = SpecComplianceAssessorType.Truncate;
      } else {
        this.singleValueModel.complianceAssessorType = SpecComplianceAssessorType.Round;
      }
    } else if (this.singleValueRangeModel) {
      if (!value) {
        this.singleValueRangeModel.complianceAssessorType = SpecComplianceAssessorType.Truncate;
      } else {
        this.singleValueRangeModel.complianceAssessorType = SpecComplianceAssessorType.Round;
      }
    } else if (this.twoValueRangeModel) {
      if (!value) {
        this.twoValueRangeModel.complianceAssessorType = SpecComplianceAssessorType.Truncate;
      } else {
        this.twoValueRangeModel.complianceAssessorType = SpecComplianceAssessorType.Round;
      }
    }
  }

  get specificationType(): SpecificationOptions | undefined {
    if (this.editModel.state === ValueState.Set) return this.editModel.specType;
    if (this.editModel.state === ValueState.NotApplicable) return NA;
    return undefined;
  }
  set specificationType(value: SpecificationOptions | undefined) {
    if (this.editModel.specType === value) return;

    if (value === NA || value === undefined) {
      this.editModel.state = value ? ValueState.NotApplicable : ValueState.Empty;
      this.specificationDisplay = value ? NA : '';
      delete this.editModel.specType;
      return;
    }

    // This is still referencing the old state of the spec type. Clear out the old value.
    this.clearOldValue();

    if (value !== SpecType.Observation && this.defaultUnit) {
      this.setDefaultUnit(value);
    }

    this.editModel.state = ValueState.Set;
    this.editModel.specType = value;
    this.specificationDisplay = '';

    if (this.twoValueRangeModel || this.singleValueModel) {
      this.specDisplayStyle = SpecDisplayType.Expression;
    } else if (this.singleValueRangeModel) {
      this.specDisplayStyle = SpecDisplayType.ToleranceRange;
    }
  }

  private _singleNumberValue: Quantity | undefined;
  get singleNumberValue(): Quantity | undefined {
    if (!this.singleValueModel && !this.singleValueRangeModel) return undefined;
    return this._singleNumberValue;
  }
  set singleNumberValue(value: Quantity | undefined) {
    if (!this.singleValueModel && !this.singleValueRangeModel) return;
    this._singleNumberValue = value;
    const model = this.singleValueModel ?? this.singleValueRangeModel;
    if (!model) throw new Error('LOGIC ERROR: Not sure how we got here');
    if (!value) {
      model.value = undefined;
      return;
    }
    DataValueService.pruneQuantity(value);
    model.value = value.valueOf();
    this.setSpecificationDisplayString();
  }

  get singleComparisonOperator(): SpecComparisonOperator | undefined {
    if (!this.singleValueModel?.sourceToValueOperator) return undefined;
    return this.singleValueModel?.sourceToValueOperator;
  }
  set singleComparisonOperator(value: SpecComparisonOperator | undefined) {
    if (!this.singleValueModel) return;
    this.singleValueModel.sourceToValueOperator = value;
    this.setSpecificationDisplayString();
  }

  get rangeType(): RangeType | undefined {
    if (!this.singleValueRangeModel?.rangeType) return undefined;
    return this.singleValueRangeModel?.rangeType;
  }
  set rangeType(value: RangeType | undefined) {
    if (!this.singleValueRangeModel) return;
    this.singleValueRangeModel.rangeType = value;
    this.setSpecificationDisplayString();
  }

  /**
   * The unit portion of Tolerance as a boolean, bound to a checkbox.
   * Also see rangeWithNaUnit.
  */
  get rangeIsPercent(): boolean | undefined {
    if (!this.singleValueRangeModel) return undefined;

    if (!this.singleValueRangeModel.tolerance) this.rangeIsPercent = false;
    return this.singleValueRangeModel.tolerance?.unit === this.pctUnit.id;
  }
  set rangeIsPercent(value: boolean | undefined) {
    if (!this.singleValueRangeModel) return;

    this.singleValueRangeModel.tolerance ??= { type: ValueType.Number, state: ValueState.Empty };
    this.singleValueRangeModel.tolerance.unit = value ? this.pctUnit.id : this.naUnit.id;

    this.setSpecificationDisplayString();
  }

  private _rangeWithNaUnit: Quantity | undefined;
  /**
   * The numeric portion of Tolerance, bound to a bpt-quantity, so is a Quantity instead of a NumberValue.
   * Also see rangeIsPercent.
  */
  get rangeWithNaUnit(): Quantity | undefined {
    if (!this.singleValueRangeModel) return undefined;

    const tolerance = this.singleValueRangeModel.tolerance;
    if (!tolerance) return undefined;

    const toleranceWithNaUnit = { ...tolerance, unit: tolerance.state === ValueState.Empty ? undefined : this.naUnit.id };
    if (!DataValueService.areEquivalentNumberValues(toleranceWithNaUnit, this._rangeWithNaUnit)) {
      this._rangeWithNaUnit = this.specService.convertNumberValueToQuantity(toleranceWithNaUnit);
    }
    return this._rangeWithNaUnit;
  }
  set rangeWithNaUnit(value: Quantity | undefined) {
    this._rangeWithNaUnit = value;
    if (!this.singleValueRangeModel) return;

    this.singleValueRangeModel.tolerance ??= { type: ValueType.Number, state: ValueState.Empty };
    this.singleValueRangeModel.tolerance.state = value?.state ?? ValueState.Empty;
    this.singleValueRangeModel.tolerance.value = value?.value;
    this.singleValueRangeModel.tolerance.unit = this.rangeIsPercent ? this.pctUnit.id : this.naUnit.id;
    this.singleValueRangeModel.tolerance.sigFigs = value?.sigFigs;
    this.singleValueRangeModel.tolerance.exact = value?.exact;

    this.setSpecificationDisplayString();
  }

  specificationTypeChange(type: SpecType | undefined) {
    if (type !== SpecType.SingleValueRange) {
      if (this.singleValueRangeModel) this.singleValueRangeModel.tolerance = undefined;
      this._rangeWithNaUnit = undefined;
    }
  }

  rangeQuantityChange(range: Quantity | undefined) {
    this.rangeWithNaUnit = range;
  }

  private _twoValueLowerNumberValue: Quantity | undefined;
  get twoValueLowerNumberValue(): Quantity | undefined {
    if (!this.twoValueRangeModel) return undefined;
    return this._twoValueLowerNumberValue;
  }
  set twoValueLowerNumberValue(value: Quantity | undefined) {
    if (!this.twoValueRangeModel) return;
    this._twoValueLowerNumberValue = value;
    if (!value) {
      this.twoValueRangeModel.lowerValue = undefined;
      return;
    }
    DataValueService.pruneQuantity(value);
    this.twoValueRangeModel.lowerValue = value.valueOf();
    this.setSpecificationDisplayString();
  }

  get twoValueLowerComparisonOperator(): SpecComparisonOperator | undefined {
    if (!this.twoValueRangeModel?.sourceToLowerValueOperator) return undefined;
    return this.twoValueRangeModel?.sourceToLowerValueOperator;
  }
  set twoValueLowerComparisonOperator(value: SpecComparisonOperator | undefined) {
    if (!this.twoValueRangeModel) return;
    this.twoValueRangeModel.sourceToLowerValueOperator = value;
    this.setSpecificationDisplayString();
  }

  private _twoValueUpperNumberValue: Quantity | undefined;
  get twoValueUpperNumberValue(): Quantity | undefined {
    if (!this.twoValueRangeModel) return undefined;
    return this._twoValueUpperNumberValue;
  }
  set twoValueUpperNumberValue(value: Quantity | undefined) {
    if (!this.twoValueRangeModel) return;
    this._twoValueUpperNumberValue = value;
    if (!value) {
      this.twoValueRangeModel.upperValue = undefined;
      return;
    }
    DataValueService.pruneQuantity(value);
    this.twoValueRangeModel.upperValue = value.valueOf();
    this.setSpecificationDisplayString();
  }

  get twoValueUpperComparisonOperator(): SpecComparisonOperator | undefined {
    if (!this.twoValueRangeModel?.sourceToUpperValueOperator) return undefined;
    return this.twoValueRangeModel?.sourceToUpperValueOperator;
  }
  set twoValueUpperComparisonOperator(value: SpecComparisonOperator | undefined) {
    if (!this.twoValueRangeModel) return;
    this.twoValueRangeModel.sourceToUpperValueOperator = value;
    this.setSpecificationDisplayString();
  }

  get showScalingControls(): boolean {
    return (this.preloadScalingOptions?.allowScaling && (!!this.singleValueModel || !!this.singleValueRangeModel)) ?? false;
  }

  allowScaleDown?: boolean;
  allowScaleUp?: boolean;

  readonly headerText = $localize`:@@specificationDataEntry:Specification Data Entry`;
  private readonly docSubscriptions: { type: string, listener: (e: Event) => void }[] = [];

  constructor(private readonly specService: SpecificationService) {
    this.specOperatorTypes = SpecificationService.validComparisonOperatorsForDropdown;
    this.specLowerComparisonOperators = this.specOperatorTypes.filter(o => o.boundaryType === OperatorBoundaryType.Lower);
    this.specUpperComparisonOperators = this.specOperatorTypes.filter(o => o.boundaryType === OperatorBoundaryType.Upper);
    this.rangeTypes = rangeTypes;
    this.specService.cacheUnitsInBlazor();

    const keydown = { type: 'keydown', listener: this.handleKeyDown };
    const keyup = { type: 'keyup', listener: this.handleKeyUp };
    document.addEventListener(keydown.type, keydown.listener.bind(this));
    document.addEventListener(keyup.type, keyup.listener.bind(this));
    this.docSubscriptions.push(keydown);
    this.docSubscriptions.push(keyup);
  }

  clearOldValue() {
    this.allowScaleDown = undefined;

    this.allowScaleUp = undefined;
    if (this.observationModel) {
      this.observationModel.value = undefined;
      this.observationModel.complianceAssessorType = SpecComplianceAssessorType.None;
    } else if (this.singleValueModel) {
      this.singleNumberValue = undefined;
      this.singleComparisonOperator = undefined;
      this.singleValueModel.complianceAssessorType = SpecComplianceAssessorType.None;
    } else if (this.singleValueRangeModel) {
      this.singleNumberValue = undefined;
      this.rangeType = undefined;
      this.rangeWithNaUnit = undefined;
      this.rangeIsPercent = undefined;
      this.singleValueRangeModel.complianceAssessorType = SpecComplianceAssessorType.None;
    } else if (this.twoValueRangeModel) {
      this.twoValueUpperComparisonOperator = undefined;
      this.twoValueUpperNumberValue = undefined;
      this.twoValueLowerComparisonOperator = undefined;
      this.twoValueLowerNumberValue = undefined;
      this.twoValueRangeModel.complianceAssessorType = SpecComplianceAssessorType.None;
    }
  }

  getAllowedSpecTypes(): TypeDropdownOption[] {
    const specTypes: TypeDropdownOption[] = [
      { label: NA, value: NA }
    ];

    // Yes, this is an anti-pattern (forEach push vs concat map), but couldn't get concat to work with types
    SpecificationService.validTypesForDropdown
      .filter(t => this.allowedSpecTypes?.includes(t.value))
      .forEach(t => specTypes.push({ label: t.label, value: t.value as SpecificationOptions }));
    return specTypes;
  }

  ngOnInit(): void {
    this.specTypes = this.getAllowedSpecTypes();
    this.loadEditModel();
    this.setSpecificationDisplayString();
    this.allowScaleDown = this.preloadScalingOptions?.allowScaleDown;
    this.allowScaleUp = this.preloadScalingOptions?.allowScaleUp;
  }

  private loadEditModel() {
    this.editModel = cloneDeep(this.inputModel);
    // It may be important to assign directly to backing fields here to prevent event storms
    if (this.singleValueModel) {
      this._singleNumberValue = this.specService.convertNumberValueToQuantity(this.singleValueModel.value);
    } else if (this.singleValueRangeModel) {
      this._singleNumberValue = this.specService.convertNumberValueToQuantity(this.singleValueRangeModel.value);
    } else if (this.twoValueRangeModel) {
      this._twoValueLowerNumberValue = this.specService.convertNumberValueToQuantity(this.twoValueRangeModel.lowerValue);
      this._twoValueUpperNumberValue = this.specService.convertNumberValueToQuantity(this.twoValueRangeModel.upperValue);
    }
  }

  ngOnDestroy(): void {
    this.docSubscriptions.forEach(s => document.removeEventListener(s.type, s.listener));
  }

  onVisibleChanged(event: boolean) {
    this.visible = event;
    if (!this.visible) this.closed.emit();
  }

  onCommit() {
    if (!isEqual(this.inputModel, this.editModel)) {
      const validSpecification = this.validate();
      if (validSpecification) {
        this.valueChanged.emit(validSpecification);
      } else {
        throw new Error('LOGIC ERROR: Commit should have been enabled.');
      }
    }

    if (this.preloadScalingOptions?.allowScaling) {
      const scaleDownChanged = this.preloadScalingOptions.allowScaleDown !== !!this.allowScaleDown;
      const scaleUpChanged = this.preloadScalingOptions.allowScaleUp !== !!this.allowScaleUp;

      if (scaleDownChanged || scaleUpChanged) {
        this.preloadScalingOptionsChanged.emit({
          allowScaleDown: this.allowScaleDown ?? false,
          allowScaleUp: this.allowScaleUp ?? false,
          allowScaling: this.preloadScalingOptions?.allowScaling ?? false
        });
      }
    }
    setTimeout(() => {
      this.visible = false;
      this.onVisibleChanged(this.visible);
    });
  }

  validate(): SpecificationValue | undefined {
    if (this.editModel.state === ValueState.Empty) return cloneDeep(emptySpecification);
    if (this.editModel.state === ValueState.NotApplicable) return cloneDeep(notApplicableSpecification);
    if (this.editModel.state !== ValueState.Set || this.editModel.type !== ValueType.Specification) return undefined;

    switch (this.editModel.specType) {
      case SpecType.Observation:
        return this.getObservationSpec();
      case SpecType.SingleValue:
        return this.getSingleValueSpec();
      case SpecType.SingleValueRange:
        return this.getSingleValueRangeSpec();
      case SpecType.TwoValueRange:
        return this.getTwoValueRangeSpec();
    }
    return undefined;
  }

  onCancel() {
    this.visible = false;
    this.onVisibleChanged(this.visible);
  }

  valueInputChanged(_newValue: any) {
    this.setSpecificationDisplayString();
  }

  valueInputBlurred(_e: any) {
    if (!this.observationModel) return;

    this.observationModel.value = this.observationModel.value?.trim();
    this.setSpecificationDisplayString();
  }

  clearType(_e: any) {
    this.editModel = cloneDeep(emptySpecification);
  }

  setSpecificationDisplayString(): void {
    let displayStr: string;
    switch (this.editModel.specType) {
      case SpecType.Observation:
        displayStr = this.specService.getObservationDisplayString(this.observationModel);
        break;
      case SpecType.SingleValue:
        displayStr = this.getSingleValueSpec() ? this.specService.getSingleValueDisplayString(this.singleValueModel) : '';
        break;
      case SpecType.SingleValueRange:
        displayStr = this.getSingleValueRangeSpec() ? this.specService.getSingleValueRangeDisplayString(this.singleValueRangeModel) : '';
        break;
      case SpecType.TwoValueRange:
        displayStr = this.getTwoValueRangeSpec() ? this.specService.getTwoValueRangeDisplayString(this.twoValueRangeModel) : '';
        break;
      default:
        displayStr = this.editModel.state === ValueState.NotApplicable ? NA : ''
        break;
    }
    this.specificationDisplay = displayStr;
  }

  /**
   * Generates a new specification object based on the editModel.
   * @returns a populated Spec object if the model value is valid, otherwise undefined
   */
  private getObservationSpec(): ObservationValue | undefined {
    if (!this.observationModel) return undefined;
    const observationValue: ObservationValue = {
      type: ValueType.Specification,
      state: ValueState.Set,
      specType: SpecType.Observation,
      value: this.observationModel.value,
      complianceAssessorType: this.observationModel.complianceAssessorType ?? SpecComplianceAssessorType.None
    };
    if (this.observationModel?.value?.trim()) return observationValue;
    return undefined;
  }

  private getSingleValueSpec(): SingleValue | undefined {
    if (!this.singleValueModel) return undefined;
    const singleValue: SingleValue = {
      type: ValueType.Specification,
      state: ValueState.Set,
      specType: SpecType.SingleValue,
      complianceAssessorType: this.singleValueModel.complianceAssessorType ?? SpecComplianceAssessorType.None,
      displayType: this.singleValueModel.displayType ?? SpecDisplayType.Expression,
      value: this.singleValueModel.value,
      sourceToValueOperator: this.singleValueModel.sourceToValueOperator
    };
    if (singleValue.value && singleValue.value.state !== ValueState.Empty && singleValue.sourceToValueOperator) return singleValue;
    return undefined;
  }

  private getSingleValueRangeSpec(): SingleValueRange | undefined {
    if (!this.singleValueRangeModel) return undefined;

    const singleValueRange: SingleValueRange = {
      type: ValueType.Specification,
      state: ValueState.Set,
      specType: SpecType.SingleValueRange,
      value: this.singleValueRangeModel.value ?? new Quantity(ValueState.Empty),
      rangeType: this.singleValueRangeModel.rangeType ?? RangeType.Invalid,
      tolerance: this.singleValueRangeModel.tolerance ?? new Quantity(ValueState.Empty),
      displayType: this.singleValueRangeModel.displayType ?? SpecDisplayType.ToleranceRange,
      complianceAssessorType: this.singleValueRangeModel.complianceAssessorType ?? SpecComplianceAssessorType.None,
    };
    if (singleValueRange.value && singleValueRange.value.state !== ValueState.Empty
      && singleValueRange.rangeType !== RangeType.Invalid
      && singleValueRange.tolerance && singleValueRange.tolerance.state !== ValueState.Empty
    ) return singleValueRange;
    return undefined;
  }

  /**
   * Generates a new specification object based on the editModel.
   * @returns a populated Spec object if the model value is valid, otherwise undefined
   */
  private getTwoValueRangeSpec(): TwoValueRange | undefined {
    if (!this.twoValueRangeModel) return undefined;

    const twoValueRange: TwoValueRange = {
      type: ValueType.Specification,
      state: ValueState.Set,
      specType: SpecType.TwoValueRange,
      complianceAssessorType: this.twoValueRangeModel.complianceAssessorType ?? SpecComplianceAssessorType.None,
      displayType: this.twoValueRangeModel.displayType ?? SpecDisplayType.Expression,
      lowerValue: this.twoValueRangeModel.lowerValue,
      sourceToLowerValueOperator: this.twoValueRangeModel.sourceToLowerValueOperator,
      upperValue: this.twoValueRangeModel.upperValue,
      sourceToUpperValueOperator: this.twoValueRangeModel.sourceToUpperValueOperator,
    };

    if (!twoValueRange.sourceToLowerValueOperator || !twoValueRange.sourceToUpperValueOperator) {
      return undefined;
    }

    if (SpecificationService.validateTwoValueRangeSpecValues(this.twoValueRangeModel).IsValid) {
      return twoValueRange;
    }

    return undefined;
  }

  /** At least one text/dropdown/quantity component was focused at time of keydown */
  private inputWasFocused = false;

  handleKeyDown(e: Event) {
    const event = e as KeyboardEvent;
    if (event.code !== 'Enter' && event.code !== 'NumpadEnter') return;

    this.inputWasFocused = [...this.textInputs, ...this.dropdownInputs, ...this.quantityInputs].some(i => i.isFocused);
  }

  handleKeyUp(e: Event) {
    const event = e as KeyboardEvent;
    if (event.code !== 'Enter' && event.code !== 'NumpadEnter') return;
    if (!this.validate()) return;

    if (!this.inputWasFocused) this.onCommit();
    this.inputWasFocused = false;
  }

  private getNumericSpecComplianceAssessment(editModel: Partial<SingleValueSpec> | Partial<SingleValueRangeSpec> | Partial<TwoValueRangeSpec>): boolean {
    if (!editModel.complianceAssessorType) {
      editModel.complianceAssessorType = SpecComplianceAssessorType.None;
    } else if (editModel.complianceAssessorType !== SpecComplianceAssessorType.None) {
      return true;
    }
    return false;
  }

  private setDefaultUnit(specType: SpecType) {
    if (specType === SpecType.SingleValue || specType === SpecType.SingleValueRange) {
      const defaultUnitQuantity = new Quantity(ValueState.Empty, undefined, this.defaultUnit, undefined, true);
      this.editModel = {
        value: defaultUnitQuantity,
        specType,
        state: ValueState.Set,
        type: ValueType.Specification
      } as PartialSpecificationValue;
      this.singleNumberValue = defaultUnitQuantity;
    }

    if (specType === SpecType.TwoValueRange) {
      const defaultUnitQuantity = new Quantity(ValueState.Empty, undefined, this.defaultUnit, undefined, true);
      this.editModel = {
        lowerValue: defaultUnitQuantity,
        upperValue: defaultUnitQuantity,
        specType: SpecType.TwoValueRange,
        type: ValueType.Specification,
        state: ValueState.Set
      };
      this.twoValueLowerNumberValue = defaultUnitQuantity;
      this.twoValueUpperNumberValue = defaultUnitQuantity;
    }
  }
}
