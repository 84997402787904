import { ColumnDefinition, ISeverityIndicatorConfig } from 'bpt-ui-library/bpt-grid';
import { ActivityInputType, ColumnType, PromptType } from '../../../../api/models';
import { BptElnGridColumnLinkoutComponent } from '../../shared/bpt-eln-grid-column-linkout/bpt-eln-grid-column-linkout.component';
import { BooleanBasedWarningIconRendererComponent } from './boolean-based-warning-icon-renderer';
import { LabItemWisePermissions, LabItemsFeatureManager } from '../../shared/lab-items-feature-manager';
import { Observable } from 'rxjs';
import { PromptsGridOptions } from '../../../prompts/prompts-grid/prompts-grid-options';
import { ExperimentWorkflowState } from '../../../../api/data-entry/models';

export class LabItemsColumnTableOptions  {
  public static readonly ColumnDefinition: { [fieldName: string]: { displayName: string }} = {
    code: { displayName: 'Column Code' },
    isActive: { displayName: 'Active' },
    packingType: { displayName: 'Packing Type' },
    manufacturerName: { displayName: 'Manufacturer' },
    dimensions: { displayName: 'Dimensions' },
    particleSize: { displayName: 'Particle Size/Film Thickness' },
    compendiaPackingPhase: { displayName: 'Compendia Packing/Phase Type' },
    lotNumber: { displayName: 'Lot Number' },
    catalogNumber: { displayName: 'Catalog Number' },
    serialNumber: { displayName: 'Serial Number' },
    AdditionalInformation: { displayName: 'Additional Details' },
    promptsName: {displayName:'Prompts'}
  };

  private static readonly cellStyleClass = 'grid-cell-align';

  public static GetColumnDefinitions(
    latestLabItemsPermissions?: Observable<LabItemWisePermissions>,
    severityIndicatorConfig?: () => ISeverityIndicatorConfig,
    isRecipePromptPresent = false,
    workflowState?: ExperimentWorkflowState
  ): ColumnDefinition[] {
    const itemType = ActivityInputType.InstrumentColumn;
    return [
      {
        label: $localize`:@@preparationRowId:Row ID`,
        field: 'code',
        columnType: ColumnType.RowId,
        hidden: true,
        editable: false,
        showInColumnChooser: false
      },
      {
        label: $localize`:@@Index:Index`,
        field: 'rowIndex',
        columnType: ColumnType.Index,
        hidden: true,
        showInColumnChooser: false,
        editable: false
      },
      PromptsGridOptions.GetPromptsDropdownColDefForLabItems(PromptType.Columns, isRecipePromptPresent, workflowState, itemType, latestLabItemsPermissions),
      {
        label: $localize`:@@InstrumentColumnGridHeader:Column Code`,
        field: 'code',
        width: 'auto',
        disableHiding: true,
        lockVisible: true,
        showHeader: false,
        sortable: true,
        editable: false,
        cellClass: 'bpt-eln-grid-row-actions',
        cellRenderer: BptElnGridColumnLinkoutComponent,
        cellRendererParams: {
          suppressCount: true,
        },
        showInColumnChooser: true
      },
      {
        field: 'isActive',
        label: $localize`:@@Active:Active`,
        width: 'auto',
        minWidth: 100,
        sortable: true,
        editable: false,
        cellRenderer: BooleanBasedWarningIconRendererComponent,
        hidden: false,
        showInColumnChooser: true,
        cellRendererParams: {
          toolTipMessage: $localize`:@@InstrumentColumnNotActiveGridHeaderToolTipMessage:The scanned column is not active.`
        }
      },
      {
        label: $localize`:@@manufacturer:Manufacturer`,
        field: 'manufacturerName',
        filterParams: {
          filterOptions: ['contains'],
          suppressAndOrCondition: true
        },
        cellRendererParams: {
          suppressCount: true
        },
        editable: false,
        width: 'auto',
        columnType: ColumnType.String,
        showInColumnChooser: true,
        sortable: true,
        hidden: false,
        allowAutoResize: true
      },
      {
        field: 'packingType',
        label: $localize`:@@PackingType:Packing Type`,
        width: 'auto',
        editable: false,
        allowAutoResize: true,
        sortable: true,
        hidden: false,
        columnType: ColumnType.String,
        cellClass: this.cellStyleClass,
        filterParams: {
          filterOptions: ['contains'],
          suppressAndOrCondition: true
        },
        showInColumnChooser: true
      },
      {
        field: 'dimensions',
        label: $localize`:@@dimensions:Dimensions`,
        width: 'auto',
        editable: false,
        allowAutoResize: true,
        sortable: true,
        columnType: ColumnType.String,
        cellClass: this.cellStyleClass,
        filterParams: {
          filterOptions: ['contains'],
          suppressAndOrCondition: true
        },
        showInColumnChooser: true
      },
      {
        field: 'particleSize',
        label: $localize`:@@ParticleSize:Particle Size/Film Thickness`,
        width: 'auto',
        sortable: true,
        editable: false,
        columnType: 'string',
        hidden: false,
        showInColumnChooser: true
      },
      {
        field: 'compendiaPackingPhase',
        label: $localize`:@@CompendiaPackingPhase:Compendia Packing/Phase Type`,
        width: 'auto',
        sortable: true,
        editable: false,
        columnType: 'string',
        hidden: false,
        showInColumnChooser: true,
      },
      {
        field: 'lotNumber',
        label: $localize`:@@LotNumberForColumns:Lot Number`,
        width: 'auto',
        sortable: true,
        editable: false,
        columnType: 'string',
        hidden: false,
        showInColumnChooser: true,
      },
      {
        field: 'catalogNumber',
        label: $localize`:@@CatalogNumber:Catalog Number`,
        width: 'auto',
        sortable: true,
        editable: false,
        columnType: 'string',
        hidden: false,
        showInColumnChooser: true,
      },
      {
        field: 'serialNumber',
        label: $localize`:@@SerialNumber:Serial Number`,
        width: 'auto',
        sortable: true,
        editable: false,
        columnType: 'string',
        hidden: false,
        showInColumnChooser: true
      },
      {
        field: 'AdditionalInformation',
        label: $localize`:@@additionalDetails:Additional Details`,
        width: 'auto',
        sortable: true,
        allowMultiLine: true,
        editable: function (params) {
          let permissions: LabItemWisePermissions = {};
          latestLabItemsPermissions?.subscribe({
            next: (latestPermissions) => {
              permissions = latestPermissions;
            }
          });
          return (
            params.data.code &&
            LabItemsColumnTableOptions.isCellLocked(params) &&
            permissions[itemType][
              LabItemsFeatureManager.FeatureNamesByItemType[itemType].cellChangeFeatureName
            ]
          );
        },
        columnType: 'string',
        hidden: true,
        allowAutoResize: true,
        showInColumnChooser: true,
        severityIndicatorConfig: severityIndicatorConfig
      }
    ];
  }
  static isCellLocked(params: any) {
    const rowId = params.node.id ?? '';
      const columnName = params.colDef.field;
      if (params.context?.inputLocks) {
        const cellLocked = params.context?.inputLocks[rowId + columnName];
        return !cellLocked;
      }
      return true;
  }
}
