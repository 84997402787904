import { ColumnType } from 'bpt-ui-library/bpt-grid';
import { ColumnInfo, MaskColumnType } from './column-info.interface';

export function getColumnInfoItem(columnType: keyof typeof ColumnType | undefined): ColumnInfo | undefined {
  return columnType && ColumnInfoItems.find((i) => i.columnType.toLowerCase() === columnType.toLowerCase());
}

export const TableConstants = {
  rowIndexColumnId : 'rowIndex'
}

export const ColumnInfoItems: ColumnInfo[] = [
  {
    columnType: ColumnType.string,
    name: $localize`:@@toolboxItemTextEntryLabel:Text Entry`,
    showMultiLineText: true,
    showAutoResize: true,
    showCharLengthSettings: true,
    formatNaToUpperCase: true
  },
  {
    columnType: ColumnType.date,
    name: $localize`:@@toolboxItemDatePickerLabel:Date and Time`,
    showAllowNA: true,
    showDateOptions: true,
    showTimeSelect: true,
  },
  {
    columnType: ColumnType.editableList,
    name: $localize`:@@toolboxItemListLabel:Dropdown List`,
    showMultiSelect: true,
    hideAllowEditing: true,
    showAllowNA: true
  },
  {
    columnType: ColumnType.quantity,
    name: $localize`:@@toolboxQuantityLabel:Quantity`,
    showAllowNA: true,
    showAllowDecimal: true,
    showAllowNegative: true,
    showNumericLimit: true,
    highlightTextOnFocus: false // not an actual feature yet
  },
  { 
    columnType: ColumnType.stepNumber, 
    name: $localize`:@@stepNumber:Step Number`,
    hideDisableGrouping: true 
  },
  {
    columnType: 'specification',
    name: $localize`:@@specification:Specification`,
    showAllowNA: true,
    showAllowDecimal: true,
    showAllowNegative: true,
    showNumericLimit: true
  },
  {
    columnType: MaskColumnType.editableListAsKeyColumn,
    name: $localize`:@@keyColumn:Key Column`,
    showMultiSelect: false,
    hideAllowEditing: true,
    isKeyColumn: true
  }
];

export const ServerColumnInfoItems: ColumnInfo[] = [
  {
    columnType: ColumnType.index,
    name: $localize`:@@Index:Index`,
  },
];
