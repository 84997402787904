import { ExperimentDataValue } from '../../api/data-entry/models';
import { RuleActions } from './rule-actions';

export enum DisplayMessageType {
  Invalid = 'Invalid',
  Confirmation = 'Confirmation',
  YesNo = 'YesNo',
  Error = 'Error'
}

export interface RuleResultByBlazor {
  ActionName: string;
  SuccessEvent: string;
  ExceptionMessage: string;
  ActionResult: string;
  AdditionalStatus: string;
  IsSuccessful: boolean;
}

export interface RuleActionExperimentDataValueResult extends RuleAction {
  Value: ExperimentDataValue;
}

export interface RuleAction {
  Context: string;
  Target: string;
  Action: RuleActions;
  AdditionalProperties: { [key: string]: any };
  FollowUpAction?: RuleAction;
}

export interface RuleActionObjectResult extends RuleAction {
  Value: any;
}

export interface RuleActionDisplayMessageResult extends RuleAction {
  MessageType: DisplayMessageType;
  Message: string;
  TranslationKey: string;
  FollowUpYesAction: RuleAction | null;
  FollowUpNoAction: RuleAction | null;
  Value: any;
}

export interface RuleActionSetVariableResult extends RuleAction {
  VariableName: string;
  Value: ExperimentDataValue;
  SetVariableType: SetVariableType;
}

export interface RuleNotificationDetail {
  Message: string;
  TranslationKey: string;
}
export interface RuleNotificationResult {
  Verbose: Array<RuleNotificationDetail>;
  Errors: Array<RuleNotificationDetail>;
}

export interface RuleEvaluationResultByBlazor {
  Notification: RuleNotificationResult;
  Results: RuleResultByBlazor[];
}

export enum SetVariableType {
  New = 'new',
  Existing = 'existing'
}
