<div
  id="eln-labitems-removed-material-grid-container"
  class="eln-lab-items-data eln-table-theme stretch"
>
  <div class="flex flex-row eln-subHeading">
    {{ subHeading }}
  </div>
  <div class="eln-tableTitle">{{ titleOfTable }}</div>
  <bpt-grid
    #labItemsRemovedMaterialGrid
    class="eln-grid hide-readonly-icons"
    ngClass="true"
    gridId="{{ labItemsMaterialId }}"
    [dataSource]="labItemsRemovedMaterialDataSource"
    [columnDefinitions]="columnDefinitions"
    [gridMode]="gridMode"
    [allowRowAdd]="false"
    (gridReady)="onGridReady()"
    [showAutoSizeButton]="true"
    [showFilterToggleButton]="true"
    [showGridOptionsButton]="false"
    [paginated]="false"
    [rowActionConfigurations]="gridActions"
    (firstDataRendered)="onFirstDataRendered($event)"
  >
  </bpt-grid>
</div>
