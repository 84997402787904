/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { statementEventsApplyPost$Json } from '../fn/statement/statement-events-apply-post-json';
import { StatementEventsApplyPost$Json$Params } from '../fn/statement/statement-events-apply-post-json';
import { statementEventsApplyPost$Plain } from '../fn/statement/statement-events-apply-post-plain';
import { StatementEventsApplyPost$Plain$Params } from '../fn/statement/statement-events-apply-post-plain';
import { StatementResponse } from '../models/statement-response';

@Injectable({ providedIn: 'root' })
export class StatementService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `statementEventsApplyPost()` */
  static readonly StatementEventsApplyPostPath = '/statement-events/apply';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `statementEventsApplyPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  statementEventsApplyPost$Plain$Response(params?: StatementEventsApplyPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<StatementResponse>> {
    return statementEventsApplyPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `statementEventsApplyPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  statementEventsApplyPost$Plain(params?: StatementEventsApplyPost$Plain$Params, context?: HttpContext): Observable<StatementResponse> {
    return this.statementEventsApplyPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<StatementResponse>): StatementResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `statementEventsApplyPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  statementEventsApplyPost$Json$Response(params?: StatementEventsApplyPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<StatementResponse>> {
    return statementEventsApplyPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `statementEventsApplyPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  statementEventsApplyPost$Json(params?: StatementEventsApplyPost$Json$Params, context?: HttpContext): Observable<StatementResponse> {
    return this.statementEventsApplyPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<StatementResponse>): StatementResponse => r.body)
    );
  }

}
