<div
  class="eln-CompletionPercent flex flex-row gap-3 {{
    completionPercent === 100
      ? 'tableFilled'
      : completionPercent === 0
      ? 'zeroFilledTable'
      : 'unFilledTable'
  }}"
>
  <p-progressBar
    [showValue]="false"
    class="eln-TableProgressBar"
    [value]="completionPercent"
  ></p-progressBar>
  <span class="font">{{ completionPercent }}%</span>
</div>
