<div class="p-grid">
  <div class="p-col-12 p-md-4">
    <div class="eln-avatar">
      <p-avatar
        *ngFor="let user of users"
        label="{{ user.initials }}"
        styleClass="p-mr-2"
        shape="circle"
        [style]="{ 'background-color': user.backgroundColor }"
        title="{{ user.fullName }}"
      ></p-avatar>
    </div>
  </div>
</div>
