import { Component, Input } from '@angular/core';

export interface ModalButton {
  label: string;
  styleClass?: string;
  command?: (event?: any) => void;
}

export interface DialogConfig {
  headerAlignment?: 'left' | 'center' | 'end';
  iconColor?: string;
  contentAlignment?: 'left' | 'center' | 'right';
}

@Component({
  selector: 'app-custom-dialog',
  templateUrl: './custom-dialog.component.html',
  styleUrls: ['./custom-dialog.component.scss']
})
export class CustomDialogComponent {
  @Input() header!: string;
  @Input() subHeader!: string;
  @Input() modal!: boolean;
  @Input() showModal = false;
  @Input() headerIcon?: string;
  @Input() closable = true;
  @Input() draggable = false;
  @Input() buttons: ModalButton[] = [];
  @Input() config: DialogConfig = {};

  handleButtonClick(button: ModalButton) {
    if (button.command) {
      button.command();
    }
  }  
}
