<div id="eln-user-defined-statements-panel" class="eln-user-defined-statements-panel">
  <div *ngIf="savedCustomStatementsLoading" class="eln-saved-statement-fetch-loading ">
    <bpt-progress-spinner
      id="eln-StatementsLoading"
      [strokeWidth]="2"
      [diameter]="25"
      [useOverlay]="false"
      i18n-message="@@loadingCustomStatements"
      message="Loading Custom Statements..."></bpt-progress-spinner>
  </div>
  <div *ngIf="savedCustomStatements.length > 0" id="eln-custom-statements-section"
    class="eln-custom-statements-section">
    <div id="eln-custom-statement-header" class="eln-custom-statement-header">{{customStatementSectionHeader}}</div>
    <div
      id="eln-custom-statement-values"
      class="eln-custom-statement-values eln-custom-statement-{{i}}"
      *ngFor="let savedStatement of savedCustomStatements; let i = index">
      <div *ngIf="savedStatement.mode === 'add'" id="eln-added-statement" class="eln-added-statement">
        <p-checkbox
          [binary]="true"
          label="{{savedStatement.statement}}"
          [(ngModel)]="savedStatement.selected"
          (onChange)="updateSelectedStatements(savedStatement)">
        </p-checkbox>
        <div id="eln-statement-actions" class="icon-s icon-dots-vertical eln-statement-actions"
          (click)="onStatementActionsClick($event, savedStatement)"></div>
      </div>
      <div *ngIf="savedStatement.mode === 'edit'" id="eln-edit-statement" class="eln-edit-statement">
        <div class="flex flex-row column-gap-3 ml-auto">
          <div id="eln-edit-statements-header" class="eln-edit-statements-header">
            <p-button
              class="eln-cancel-statement-edit-button"
              styleClass="eln-statement-save-button p-button-text font-size-14"
              i18n-label="@@cancel"
              label="Cancel"
              (onClick)="onStatementEditCancel(savedStatement)"></p-button>
          </div>
          <div id="eln-edit-statements-header" class="eln-edit-statements-header">
            <p-button
              class="eln-save-statements-button"
              styleClass="eln-statement-save-button p-button-text font-size-14"
              i18n-label="@@save"
              label="Save"
              [disabled]="!savedStatement.isModified"
              (onClick)="onStatementEditSave(savedStatement)"></p-button>
          </div>
        </div>
        <bpt-text-input
          #statementsInput
          [multiLine]="true"
          [autoResize]="true"
          [disabled]="false"
          (textInputKeyUp)="onStatementEditStarted(savedStatement)"
          [(ngModel)]="savedStatement.statement"
          [maxlength]="maxCharacterLimit"></bpt-text-input>
        <div id="eln-input-character-count" *ngIf="savedStatement.statement" class="eln-input-character-count">
          {{savedStatement.statement.length}}/{{maxCharacterLimit}}</div>
      </div>
    </div>
  </div>
  <div>
    <div id="eln-add-statements-header" class="eln-add-statements-header">
      <p-button
        class="eln-add-statements-button"
        styleClass="eln-toolbar-button eln-p-button-text p-button-text font-size-14"
        i18n-label="@@addCustomStatementsButton"
        label="✛ Add Custom Statement"
        (onClick)="onStatementsAdd()"></p-button>
      <div class="eln-custom-statements-header">
        <p-button
          *ngIf="showStatementInputBox"
          class="eln-cancel-custom-statement-button mr-3"
          styleClass="eln-statement-save-button p-button-text font-size-14"
          i18n-label="@@cancel"
          label="Cancel"
          (onClick)="onCancelCustomStatement()"></p-button>
        <p-button
          class="eln-save-statements-button"
          styleClass="eln-toolbar-button p-button-text eln-statement-save-button font-size-14"
          i18n-label="@@save"
          label="Save"
          [disabled]="statementText === undefined || (statementText !== undefined && statementText.length === 0)"
          (onClick)="onStatementsSave()"></p-button>
      </div>
    </div>
    <div *ngIf="showStatementInputBox" id="eln-statements-input-box" class="eln-statements-input-box">
      <bpt-text-input
        #statementsInput
        [multiLine]="true"
        [autoResize]="true"
        [disabled]="false"
        [(ngModel)]="statementText"
        [maxlength]="maxCharacterLimit"
        (ngModelChange)="onTextChanged(statementText)"></bpt-text-input>
      <div id="eln-input-character-count" *ngIf="statementText" class="eln-input-character-count">
        {{statementText.length}}/{{maxCharacterLimit}}</div>
    </div>
  </div>
  <span class="eln-statement-warning" >{{warningText}}</span>
  <p-overlayPanel #statementActionsOverlay>
    <div class="eln-statement-actions-overlay">
      <p-button
        class="eln-add-statements-button"
        [icon]="editIcon"
        styleClass="p-button-text"
        i18n-label="@@edit"
        label="Edit"
        (onClick)="onStatementsEdit()"></p-button>
      <p-button
        class="eln-save-statements-button"
        [icon]="deleteIcon"
        styleClass="p-button-text"
        i18n-label="@@delete"
        label="Delete"
        (onClick)="onStatementsDelete()"></p-button>
    </div>
  </p-overlayPanel>
</div>