/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { LabelTemplateResponse as ElnLabelsApiEntitiesLabelTemplateResponse } from '../../models/ELN/Labels/API/Entities/label-template-response';
import { LabelType as ElnLabelsDomainEnumsLabelType } from '../../models/ELN/Labels/Domain/Enums/label-type';

export interface LabelsLabelTemplatesTemplateTypeGet$Plain$Params {
  templateType: ElnLabelsDomainEnumsLabelType;
  'X-User-Puid'?: any;
  'X-User-LabsiteCode'?: any;
}

export function labelsLabelTemplatesTemplateTypeGet$Plain(http: HttpClient, rootUrl: string, params: LabelsLabelTemplatesTemplateTypeGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ElnLabelsApiEntitiesLabelTemplateResponse>> {
  const rb = new RequestBuilder(rootUrl, labelsLabelTemplatesTemplateTypeGet$Plain.PATH, 'get');
  if (params) {
    rb.path('templateType', params.templateType, {});
    rb.header('X-User-Puid', params['X-User-Puid'], {});
    rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
  }

  return http.request(
    rb.build({ responseType: 'text', accept: 'text/plain', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ElnLabelsApiEntitiesLabelTemplateResponse>;
    })
  );
}

labelsLabelTemplatesTemplateTypeGet$Plain.PATH = '/labels/label-templates/{templateType}';
