import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, Message, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { BarcodeScannerHelper } from 'services/barcode-scanner-helper';
import { AuditHistoryDataRecordResponse, ExperimentDataRecordNotification, ExperimentEventType } from '../../../api/audit/models';
import { ELNAppConstants } from '../../../shared/eln-app-constants';
import {
  ActivityInputRowRefreshedEventNotification,
  ActivityInputRowRemovedEventNotification,
  MaintenanceEventSelectedEventNotification,
  SelectMaintenanceEventCommand,
  ChangeInstrumentDateRemovedCommand,
  ChangeInstrumentDescriptionCommand,
  ChangeInstrumentRemovedFromServiceCommand,
  InstrumentDateRemovedChangedEventNotification,
  InstrumentDescriptionChangedEventNotification,
  InstrumentRemovedFromServiceChangedEventNotification,
  ActivityInputCellChangedEventNotification,
  InstrumentRefreshDetails,
  RefreshActivityInputRowCommand,
  ExperimentDataValue,
  InstrumentDescriptionChangedResponse
} from '../../../../app/api/data-entry/models';
import { ActivityInputEventsService } from '../../../../app/api/data-entry/services';
import {
  ActivityInputType,
  ColumnType,
  FieldType,
  Instrument,
  MaintenanceEventDetails,
  ModifiableDataValue,
  NodeType
} from '../../../../app/api/models';
import { AuditHistoryService } from '../../audit-history/audit-history.service';
import { ActivityInputInstrumentEvent } from '../../model/activity-input-instrument-event';
import { DataRecordService } from '../../services/data-record.service';
import { DataValueService } from '../../services/data-value.service';
import { ExperimentService } from '../../services/experiment.service';
import { ActivityInputMaintenanceEventComponent } from '../activity-input-maintenance-event-table/activity-input-maintenance-event-table.component';
import { InputsComponent } from '../inputs.component';
import { ActivityInputMaintenanceEvent } from '../../model/activity-input-maintenance-event';
import { InstrumentEventHelper } from './instrument-event-helper';
import { ControlCustomDefinition, NA, ValueState } from 'bpt-ui-library/shared';
import { ClientValidationDetails } from 'model/client-validation-details';
import { BptTextInputComponent } from 'bpt-ui-library/bpt-text-input';
import { BptDropdownComponent } from 'bpt-ui-library/bpt-dropdown';
import { BptDateTimeComponent } from 'bpt-ui-library/bpt-datetime';
import { ActivityInputConstants } from '../shared/activity-input-constants';
import { ActivityInputHelper } from '../shared/activity-input-helper';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-instrument-event',
  templateUrl: './instrument-event.component.html',
  styleUrls: ['./instrument-event.component.scss']
})
export class InstrumentEventComponent implements OnInit, OnDestroy {
  instrumentEventsPageHeader = $localize`:@@instrumentEventPageHeader:Instrument Event`;
  disableDateRemoved = false;
  isReadOnly = false;
  subscriptionList: Subscription[] = [];
  disableEditingInstrument = false;
  barcodeScannerHelper: BarcodeScannerHelper;
  dynamicDialogRef!: DynamicDialogRef;
  maintenanceEventCommand!: SelectMaintenanceEventCommand;
  changeInstrumentRemovedFromServiceCommand!: ChangeInstrumentRemovedFromServiceCommand;
  changeInstrumentDescriptionCommand!: ChangeInstrumentDescriptionCommand;
  changeInstrumentDateRemovedCommand!: ChangeInstrumentDateRemovedCommand;
  selectedEvent!: MaintenanceEventDetails;
  experimentId = '';
  descriptionOfSituation: any;
  dateRemoved: any;
  removedFromService: any;
  specifyNoData = $localize`:@@NoScannedInstrumentItem:No scanned data available`;
  isLoading = false;
  refreshDateRemoved = true;
  totalModifiableFields = 4;
  emptyFields: number = 0;
  validation!: ClientValidationDetails;
  refreshing = false;
  instrumentEventsDataSource?: ActivityInputInstrumentEvent;
  defaultEvents: ActivityInputMaintenanceEvent[] = [
    {
      activityType: 'Qualification',
      interval: '',
      isAdhocEvent: false,
      nameDescription: 'Change Control',
      performedBy: '',
      sopReference: '',
      isSelected: false
    },
    {
      activityType: 'Validation',
      interval: '',
      isAdhocEvent: false,
      nameDescription: 'Initial Validation',
      performedBy: '',
      sopReference: '',
      isSelected: false
    },
    {
      activityType: 'Maintenance',
      interval: '',
      isAdhocEvent: false,
      nameDescription: 'Non-Routine',
      performedBy: '',
      sopReference: '',
      isSelected: false
    },
    {
      activityType: 'Repair',
      interval: '',
      isAdhocEvent: false,
      nameDescription: 'Repair',
      performedBy: '',
      sopReference: '',
      isSelected: false
    }
  ];

  private readonly eventsForHistory: ExperimentEventType[] = [
    ExperimentEventType.InstrumentAdded,
    ExperimentEventType.InstrumentDateRemovedChanged,
    ExperimentEventType.InstrumentDescriptionChanged,
    ExperimentEventType.MaintenanceEventSelected,
    ExperimentEventType.InstrumentColumnAdded,
    ExperimentEventType.InstrumentRemovedFromServiceChanged,
    ExperimentEventType.ActivityInputCellChanged,
    ExperimentEventType.ActivityInputRowRefreshed
  ];

  @Input() value!: ModifiableDataValue;
  modifiableDataValue!: ModifiableDataValue;
  @ViewChild('removedFromServiceOptionsDropdown') removedFromServiceOptionsDropdown?: BptDropdownComponent;
  @ViewChild('descriptionField') descriptionField?: BptTextInputComponent;
  @ViewChild('instrumentEventsDropdown') instrumentEventsDropdown?: BptDropdownComponent;
  @ViewChild('instrumentDateRemovedDropDown') instrumentDateRemovedField?: BptDateTimeComponent;
  fieldType!: FieldType;
  refreshEvent = true;
  removedFromServiceOptions: any[];
  completionPercent = 0;
  private readonly errorTitle = $localize`:@@DateRemovedRequired: Date Removed Value is required`
  public controlCustomDefinitionValidatorForDescription =
    this.getControlCustomDefinitionValidatorForDescription.bind(this);

  public controlCustomDefinitionValidatorForRemovedFromService =
    this.getControlCustomDefinitionValidatorForDropdown.bind(this);

  public controlCustomDefinitionValidatorForDateRemoved =
    this.getControlCustomDefinitionValidatorForDateRemoved.bind(this);

  public controlCustomDefinitionValidatorForNameDescription =
    this.getControlCustomDefinitionValidatorForNameDescription.bind(this);

  constructor(
    private readonly barcodeScannerHelperInstance: BarcodeScannerHelper,
    private readonly inputsComponent: InputsComponent,
    private readonly experimentService: ExperimentService,
    private readonly dialogService: DialogService,
    private readonly messageService: MessageService,
    private readonly activityInputEventsService: ActivityInputEventsService,
    private readonly confirmationService: ConfirmationService,
    private readonly auditHistoryService: AuditHistoryService,
    private readonly dataValueService: DataValueService,
    private readonly userService: UserService,
    private readonly activityInputHelper: ActivityInputHelper
  ) {
    this.barcodeScannerHelper = barcodeScannerHelperInstance;
    this.removedFromServiceOptions = Object.values(RemovedFromServiceOptions);
  }

  ngOnInit(): void {
    this.completionPercent = 0;
    this.experimentId =
      typeof this.experimentService.currentExperiment?.id === 'undefined'
        ? ''
        : this.experimentService.currentExperiment?.id;
    this.setDataSource(this.inputsComponent.activityInputComponentsReadonly);
    this.validation = new ClientValidationDetails();
    this.currentUserRoleActionAssignment();
  }

  currentUserRoleActionAssignment(): boolean {
    this.disableEditingInstrument = (this.userService.hasOnlyReviewerRights() || this.inputsComponent.activityInputComponentsReadonly);
    return this.disableEditingInstrument;
  }

  findEmptyFields() {
    this.emptyFields = 0;
    if(this.instrumentEventsDataSource === undefined || this.instrumentEventsDataSource === null) return;
    if (
      this.instrumentEventsDataSource.nameDescriptionValue === undefined ||
      this.instrumentEventsDataSource.nameDescriptionValue === null ||
      this.instrumentEventsDataSource.nameDescriptionValue === ''
    ) {
      this.emptyFields += 1;
    }
    if (
      (this.instrumentEventsDataSource.dateRemovedValue === undefined ||
        this.instrumentEventsDataSource.dateRemovedValue === null ||
        this.instrumentEventsDataSource.dateRemovedValue === '')
    ) {
      this.emptyFields += 1;
    }
    if (
      this.instrumentEventsDataSource.descriptionValue === undefined ||
      this.instrumentEventsDataSource.descriptionValue === null ||
      this.instrumentEventsDataSource.descriptionValue === ''
    ) {
      this.emptyFields += 1;
    }
    if (
      this.instrumentEventsDataSource.removedFromServiceValue === undefined ||
      this.instrumentEventsDataSource.removedFromServiceValue === null ||
      (this.instrumentEventsDataSource.removedFromServiceValue as any) === ''
    ) {
      this.emptyFields += 1;
    }
  }

  calculateCompletionPercentage() {
    this.findEmptyFields();
    this.completionPercent = Math.floor(
      ((this.totalModifiableFields - this.emptyFields) / this.totalModifiableFields) * 100
    );
  }

  setDataSource(isReadOnly: boolean) {
    this.currentUserRoleActionAssignment();
    this.handleSubscriptions();
    this.isReadOnly = isReadOnly;
    this.instrumentEventsDataSource = undefined;
    this.barcodeScannerHelper.currentActivityId = this.experimentService.currentActivity?.activityId ?? this.experimentService.currentExperiment?.reservedInstances[0].instanceId;
    const instrument = this.activityInputHelper.getInstrumentByActivityId(this.barcodeScannerHelper.currentActivityId);
    if ((instrument && instrument.activityInputReference == null) || instrument === null || instrument === undefined) {
      this.experimentService.instrumentEventRemoved.next(true);
      return;
    }
    if (instrument?.activityInputReference !== null) {
      this.instrumentEventsDataSource = this.decorateDataSource(instrument);
      this.setDefaultEvents();
    }
    else {
      this.instrumentEventsDataSource = instrument as ActivityInputInstrumentEvent;
    }
    this.calculateCompletionPercentage();
    this.barcodeScannerHelper.instrumentEventDataSourcePrepared.next(this.instrumentEventsDataSource);
    this.removedFromService =
      this.instrumentEventsDataSource?.removedFromService;
    this.descriptionOfSituation = this.instrumentEventsDataSource?.description;
    this.dateRemoved = this.instrumentEventsDataSource?.dateRemoved;
    this.validation = new ClientValidationDetails();
  }

  decorateDataSource(instrument: Instrument): ActivityInputInstrumentEvent {
    return {
      instrumentNumber: instrument.instrumentNumber,
      isRemoved: instrument.isRemoved,
      maintenanceEvents: instrument.maintenanceEvents,
      activityId: this.experimentService.currentActivityId,
      activityInputReference: instrument.instrumentNumber,
      activityInputType: ActivityInputType.Instrument,
      modifiableFields: instrument.modifiableFields,
      dateRemoved: instrument.dateRemoved,
      createdBy: instrument.createdBy,
      createdOn: instrument.createdOn,
      description: instrument.description,
      removedFromService: instrument.removedFromService,
      name: instrument.name,
      status: instrument.status,
      nameDescription: instrument.nameDescription,
      activityType: '',
      interval: '',
      isAdhocEvent: false,
      performedBy: '',
      sopReference: '',
      dueDate: '',
      descriptionValue: (<any>instrument.description?.value)?.value,
      removedFromServiceValue: (<any>instrument.removedFromService?.value)?.value,
      dateRemovedValue: this.getDateRemovedValue((instrument.removedFromService?.value as any)?.value, (instrument.dateRemoved?.value as any)?.value),
      nameDescriptionValue: (<any>instrument.nameDescription?.value)?.value
    };
  }

  setDefaultEvents() {
    const eventNames = this.instrumentEventsDataSource?.maintenanceEvents.map(
      (k: MaintenanceEventDetails) => k.nameDescription
    );
    this.defaultEvents.forEach((event) => {
      if (eventNames?.indexOf(event.nameDescription) === -1) {
        this.instrumentEventsDataSource?.maintenanceEvents.push({
          activityType: event.activityType || '',
          interval: event.interval,
          isAdhocEvent: event.isAdhocEvent,
          nameDescription: event.nameDescription,
          performedBy: event.performedBy,
          sopReference: event.sopReference,
          dueDate: event.dueDate
        });
      }
    });
  }

  /** method to handle all the subscriptions */
  private handleSubscriptions() {
    this.subscriptionList.push(
      this.inputsComponent.readOnlyOnStateChange.subscribe((val) => {
        this.setDataSource(this.inputsComponent.activityInputComponentsReadonly);
      })
    );

    this.subscriptionList.push(
      this.barcodeScannerHelper.instrumentEventDataSourcePrepared.subscribe((data: ActivityInputInstrumentEvent) => {
        if(data.activityId !== (this.experimentService.currentActivity?.activityId || this.experimentService.currentExperiment?.reservedInstances[0].instanceId)) return;
        this.instrumentEventsDataSource = data;
          if (data?.activityInputType !== ActivityInputType.Instrument) return;
        this.setDefaultEvents();
        this.calculateCompletionPercentage();
      })
    );

    this.subscriptionList.push(
      this.activityInputHelper.updateMaintenanceEventDataSource.subscribe(
        (data: MaintenanceEventSelectedEventNotification) => {
          this.updateMaintenanceEventDataRecord(data);
        }
      )
    );

    this.subscriptionList.push(
      this.activityInputHelper.updateInstrumentDescriptionDataSource.subscribe(
        (data: InstrumentDescriptionChangedEventNotification) => {
          this.updateInstrumentDescriptionDataRecord(data);
        }
      )
    );

    this.subscriptionList.push(
      this.activityInputHelper.updateInstrumentDateRemovedDataSource.subscribe(
        (data: InstrumentDateRemovedChangedEventNotification) => {
          this.updateInstrumentDateRemovedRecord(data);
        }
      )
    );

    this.subscriptionList.push(
      this.activityInputHelper.updateInstrumentRemovedFromServiceDataSource.subscribe(
        (data: InstrumentRemovedFromServiceChangedEventNotification) => {
          this.updateInstrumentRemovedFromServiceDataRecord(data);
        }
      )
    );

    this.subscriptionList.push(
      this.activityInputHelper.updateInstrumentEventDataSource.subscribe((data: ActivityInputRowRemovedEventNotification) => {
        this.removeRowsEventDataRecord(data);
        this.experimentService.instrumentEventRemoved.next(true);
      })
    );

    this.subscriptionList.push(
      this.activityInputHelper.refreshInstrumentEventDataSource.subscribe({
        next: this.refreshInstrument.bind(this)
      })
    );

    this.subscriptionList.push(
      InstrumentEventHelper.instrumentEventSelectionClosed.subscribe(data => {
        if (data && !data.isSelected) {
          this.confirmEventSelectionClose(data);
        }
        else {
          this.dynamicDialogRef.close();
        }
      }));

    this.subscriptionList.push(
      InstrumentEventHelper.instrumentEventSelectionSaved.subscribe(data => {
        if (!data.isSelected) {
          this.setDataSource(this.inputsComponent.activityInputComponentsReadonly);
          this.saveInstrumentEventSelection(data);
        } else {
          this.dynamicDialogRef.close();
        }
      }));
  }

  removeRowsEventDataRecord(data: ActivityInputRowRemovedEventNotification) {
    if (data) {
      const removedInstrument =
      (this.instrumentEventsDataSource?.activityInputReference.toLocaleLowerCase() === data.activityInputReference.toLowerCase()
        && this.instrumentEventsDataSource.activityId === data.activityId)
          ? this.instrumentEventsDataSource
          : null;
      if (removedInstrument) removedInstrument.isRemoved = true;
      if (this.instrumentEventsDataSource) {
        this.instrumentEventsDataSource.isRemoved = true;
      }
    }
  }

  refreshInstruments() {
    if (this.currentUserRoleActionAssignment()) return;
    this.refreshing = true;
    this.activityInputEventsService
      .activityInputEventsRefreshRowPost$Json$Response({
        body: this.getRefreshActivityInputCommand()
      })
      .subscribe((data) => {
        this.refreshInstrumentDetails(data.body?.rowRefreshedEventNotification);
        this.messageService.add({
          key: 'notification',
          severity: 'success',
          summary: $localize`:@@InstrumentRefreshed: Instrument refreshed successfully`
        });
        this.refreshing = false;
      });
  }

  refreshInstrument = (data: ActivityInputRowRefreshedEventNotification) => {
    this.refreshInstrumentDetails(data);
  };

  private refreshInstrumentDetails(data: ActivityInputRowRefreshedEventNotification) {
    if (data?.activityId !== this.experimentService.currentActivityId) return;
    if (data?.instrumentDetails) {
      this.updatedRefreshedDataFromLims(data, this.instrumentEventsDataSource);
    }
  }

  private updatedRefreshedDataFromLims(data: ActivityInputRowRefreshedEventNotification, dataSource?: ActivityInputInstrumentEvent) {
    if (dataSource && data?.instrumentDetails) {
      const dataValue = data.instrumentDetails.modifiedFields.find(f => f.propertyName.toLowerCase() === "name")?.propertyValue;
      dataSource.maintenanceEvents = data.instrumentDetails.maintenanceEvents;
      this.setDefaultEvents();
      if (dataValue) {
        dataSource.name = this.dataValueService.getPrimitiveValue(ColumnType.String, { value: dataValue, isModified: true });
      }
    }
  }

  private getRefreshActivityInputCommand(): RefreshActivityInputRowCommand {
    const dataSource = this.buildInstrumentRefreshDetails();
    return {
      instrumentDetails: dataSource,
      experimentId: this.experimentId,
      activityInputType: ActivityInputType.Instrument,
      activityId: this.experimentService.currentActivityId
    };
  }

  removeRows() {
    if (this.currentUserRoleActionAssignment() || !this.instrumentEventsDataSource) return;
    const command = {
      activityId: this.experimentService.currentActivityId,
      activityInputReference: this.instrumentEventsDataSource.instrumentNumber,
      activityInputType: ActivityInputType.Instrument,
      experimentId: this.experimentId
    };
    const confirmationMessage = $localize`:@@activityInputRowRemovalConfirmation:Are you sure you wish to remove this Instrument Event? It cannot be undone`;
    this.confirmationService.confirm({
      message: `${confirmationMessage}`,
      header: $localize`:@@removeConfirmation:Remove Confirmation`,
      acceptVisible: true,
      acceptLabel: $localize`:@@confirmationOk:OK`,
      rejectVisible: true,
      rejectLabel: $localize`:@@confirmationCancel:Cancel`,
      closeOnEscape: true,
      dismissableMask: false,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.activityInputEventsService
          .activityInputEventsRemoveRowPost$Json$Response({
            body: command
          })
          .subscribe(
            (response) => {
              this.activityInputHelper.removeInstrumentFromDataSource(response.body.rowRemovedEventNotification.activityId);
              if(this.experimentService.currentExperiment && this.experimentService.currentExperiment.instrumentEventImpactAssessmentData)
                this.experimentService.currentExperiment.instrumentEventImpactAssessmentData.find(s => s.activityId === response.body.rowRemovedEventNotification.activityId && !s.isRemoved)!.isRemoved = true;
              if (this.instrumentEventsDataSource) this.instrumentEventsDataSource.isRemoved = true;
              this.experimentService.instrumentEventRemoved.next(true);
              this.createSuccessNotificationMessage(
                $localize`:@@instrumentRemoved: Instrument Removed Successfully ${response.body.rowRemovedEventNotification.activityInputReference}`,
                ``
              );
            },
            () => {
              this.createErrorNotificationMessage(
                $localize`:@@instrumentRemovedFailed:Failed to remove Instrument`,
                ` ${command.activityInputReference}`
              );
            }
          );
      },
      reject: () => { }
    });
  }

  ngOnDestroy(): void {
    this.subscriptionList.forEach((s) => s.unsubscribe());
  }

  updateMaintenanceEventDataRecord(data: MaintenanceEventSelectedEventNotification) {
    if (!this.instrumentEventsDataSource) return;

    this.instrumentEventsDataSource.nameDescription = DataRecordService.getModifiableDataValue(
      data.nameDescription,
      this.instrumentEventsDataSource.nameDescription
    );
    this.instrumentEventsDataSource.nameDescriptionValue = (this.instrumentEventsDataSource.nameDescription.value as any).value;
    this.calculateCompletionPercentage();
    if(this.instrumentEventsDropdown !== undefined)
    {
      this.instrumentEventsDropdown.controlCustomDefinition = this.instrumentEventsDropdown?.evaluateControlCustomDefinition(
        this.getControlCustomDefinitionValidatorForNameDescription());
      this.instrumentEventsDropdown?.cd.detectChanges();
    }
  }



  updateInstrumentDescriptionDataRecord(data: InstrumentDescriptionChangedEventNotification) {
    if (!this.instrumentEventsDataSource) return;
    this.instrumentEventsDataSource.description = this.getModifiableDataValue(data.description, this.instrumentEventsDataSource.description);
    this.instrumentEventsDataSource.descriptionValue = (this.instrumentEventsDataSource.description.value as any).value;
    this.calculateCompletionPercentage();
    if(this.descriptionField !== undefined)
    {
      this.descriptionField.controlCustomDefinition = this.descriptionField?.evaluateControlCustomDefinition(
        this.getControlCustomDefinitionValidatorForDescription());
      this.descriptionField?.cd.detectChanges();
    }
  }

  updateInstrumentDateRemovedRecord(data: InstrumentDateRemovedChangedEventNotification) {
    if (!this.instrumentEventsDataSource) return;
    this.instrumentEventsDataSource.dateRemoved = DataRecordService.getModifiableDataValue(
      data.dateRemoved,
      this.instrumentEventsDataSource.dateRemoved
    );
    this.instrumentEventsDataSource.dateRemovedValue = (this.instrumentEventsDataSource.dateRemoved.value as any).value;
    this.calculateCompletionPercentage();
    if(this.instrumentDateRemovedField !== undefined)
    {
      this.instrumentDateRemovedField.controlCustomDefinition = this.instrumentDateRemovedField?.evaluateControlCustomDefinition(
        this.getControlCustomDefinitionValidatorForDateRemoved());
      this.instrumentDateRemovedField?.cd.detectChanges();
    }
  }

  updateInstrumentRemovedFromServiceDataRecord(
    data: InstrumentRemovedFromServiceChangedEventNotification
  ) {
    if (!this.instrumentEventsDataSource) return;
    this.instrumentEventsDataSource.removedFromService = DataRecordService.getModifiableDataValue(
      data.removedFromService,
      this.instrumentEventsDataSource.removedFromService
    );
    this.instrumentEventsDataSource.removedFromServiceValue = (this.instrumentEventsDataSource.removedFromService.value as any).value;
    this.instrumentEventsDataSource.dateRemovedValue =
      this.getDateRemovedValue(this.instrumentEventsDataSource.removedFromServiceValue, (this.instrumentEventsDataSource.dateRemoved?.value as any).value)
    this.calculateCompletionPercentage();
    if (this.removedFromServiceOptionsDropdown !== undefined) {
      const validator = this.getControlCustomDefinitionValidatorForDropdown();
      this.removedFromServiceOptionsDropdown.controlCustomDefinition = this.removedFromServiceOptionsDropdown?.evaluateControlCustomDefinition(
        validator);
      this.removedFromServiceOptionsDropdown?.cd.detectChanges();
    }
  }

  onDescriptionChange(newValue: any) {
    if (!this.instrumentEventsDataSource || this.instrumentEventsDataSource.descriptionValue === undefined) return;
    if (newValue === (this.instrumentEventsDataSource.description?.value as any).value) return;
    const dataValue = this.dataValueService.getExperimentDataValue(
      FieldType.Textbox,
      newValue,
    );
    this.instrumentEventsDataSource.description = DataRecordService.getModifiableDataValue(
      dataValue,
      this.instrumentEventsDataSource.description
    );

    this.prepareChangeInstrumentDescriptionCommand(
      this.instrumentEventsDataSource.descriptionValue
    );
    this.activityInputEventsService
      .activityInputEventsChangeInstrumentDescriptionPost$Json({
        body: this.changeInstrumentDescriptionCommand
      })
      .subscribe(
        (_response: InstrumentDescriptionChangedResponse) => {
          if (!this.instrumentEventsDataSource) return;
          this.instrumentEventsDataSource.descriptionValue = (this.instrumentEventsDataSource.description.value as any).value;
          const instrument = this.activityInputHelper.getInstrumentByActivityId(this.experimentService.currentActivityId);
          if (instrument) instrument.description = this.instrumentEventsDataSource?.description;
          this.calculateCompletionPercentage();
        },
        (error: any) => {
          this.createErrorNotificationMessage(
            $localize`:@@:MaintenanceEventSelectedFailed: Maintenance Event selection failed for this instrument`,
            ``
          );
        }
      );
  }

  onDateRemovedChange(newValue: string, onRemovedFromServiceOptionChange: boolean) {
    if (!this.instrumentEventsDataSource) return;
    this.validation.removeErrorMessage(this.errorTitle);
    if (newValue === (this.instrumentEventsDataSource.dateRemoved?.value as any).value) return;

    if (!newValue || newValue === NA || newValue === '') {
      this.validation.errorTitle = $localize`:@@pleaseCorrectErrors: Please correct these error(s)`;
      this.validation.errors.push(this.errorTitle);
      return;
    }
    const dataValue = this.dataValueService.getExperimentDataValue(
      FieldType.Datepicker,
      newValue
    );
    this.instrumentEventsDataSource.dateRemoved = DataRecordService.getModifiableDataValue(
      dataValue,
      this.instrumentEventsDataSource.dateRemoved
    );

    this.prepareChangeInstrumentDateRemovedCommand(newValue);
    this.activityInputEventsService
      .activityInputEventsChangeInstrumentDateRemovedPost$Json({
        body: this.changeInstrumentDateRemovedCommand
      })
      .subscribe(
        (response: any) => {
          if (!this.instrumentEventsDataSource) return;
          const instrument = this.activityInputHelper.getInstrumentByActivityId(this.experimentService.currentActivityId);
          if (instrument) instrument.dateRemoved = this.instrumentEventsDataSource.dateRemoved;
          this.instrumentEventsDataSource.dateRemovedValue = this.instrumentEventsDataSource.dateRemoved.value.state === ValueState.NotApplicable
            ? NA : (this.instrumentEventsDataSource.dateRemoved?.value as any).value;

          if (onRemovedFromServiceOptionChange === true) {
            this.refreshDateRemoved = false;
            setTimeout(() => {
              this.refreshDateRemoved = true;
            }, 200);
          }
          this.calculateCompletionPercentage();
        },
        (error: any) => {
          this.createErrorNotificationMessage(
            $localize`:@@:MaintenanceEventSelectedFailed: Maintenance Event selection failed for this instrument`,
            ``
          );
        }
      );
  }

  removedFromServiceChanged(newValue: string) {
    if (!this.instrumentEventsDataSource) return;
    if (newValue === null || newValue === undefined || newValue === (this.instrumentEventsDataSource.removedFromService?.value as any).value) return;
       const dataValue = this.dataValueService.getExperimentDataValue(FieldType.List, newValue);
    this.instrumentEventsDataSource.removedFromService = DataRecordService.getModifiableDataValue(
      dataValue,
      this.instrumentEventsDataSource.removedFromService
    );

    this.prepareChangeInstrumentRemovedFromServiceCommand(newValue);
    this.activityInputEventsService
      .activityInputEventsChangeInstrumentRemovedFromServicePost$Json({
        body: this.changeInstrumentRemovedFromServiceCommand
      })
      .subscribe(
        (response: any) => {
          if (!this.instrumentEventsDataSource) return;
          const instrument = this.activityInputHelper.getInstrumentByActivityId(this.experimentService.currentActivityId);
          if (instrument) instrument.removedFromService = this.instrumentEventsDataSource.removedFromService;
          this.instrumentEventsDataSource.removedFromServiceValue = (this.instrumentEventsDataSource.removedFromService.value as any).value;
          this.instrumentEventsDataSource.dateRemovedValue = this.getDateRemovedValue(newValue, (this.instrumentEventsDataSource.dateRemoved.value as any).value)
          this.validation.removeErrorMessage(this.errorTitle);
          this.calculateCompletionPercentage();
          if (!this.instrumentEventsDataSource.dateRemovedValue || this.instrumentEventsDataSource.dateRemovedValue === NA || this.instrumentEventsDataSource.dateRemovedValue === '') return;
          this.onDateRemovedChange(this.instrumentEventsDataSource.dateRemovedValue, true);
        },
        (error: any) => {
          this.createErrorNotificationMessage(
            $localize`:@@:MaintenanceEventSelectedFailed: Maintenance Event selection failed for this instrument`,
            ``
          );
        }
      );
  }

  onMaintenanceEventClicked() {
    if (this.isReadOnly || !this.instrumentEventsDataSource || this.currentUserRoleActionAssignment()) return;
    const maintenanceEventForSelection: ActivityInputMaintenanceEvent[] = [];
    this.instrumentEventsDataSource.maintenanceEvents.forEach(
      (maintenanceEvent: MaintenanceEventDetails) => {
        const activityInputMaintenanceEvent: ActivityInputMaintenanceEvent = {
          isSelected: false,
          activityType: maintenanceEvent.activityType,
          interval: maintenanceEvent.interval,
          isAdhocEvent: maintenanceEvent.isAdhocEvent,
          nameDescription: maintenanceEvent.nameDescription,
          performedBy: maintenanceEvent.performedBy,
          sopReference: maintenanceEvent.sopReference,
          dueDate: maintenanceEvent.dueDate
        };

        if ( this.instrumentEventsDataSource?.nameDescription?.value &&
          maintenanceEvent.nameDescription ===
          (this.instrumentEventsDataSource?.nameDescription?.value as any).value
        ) {
          activityInputMaintenanceEvent.isSelected = true;
        }
        maintenanceEventForSelection.push(activityInputMaintenanceEvent);
      }
    );

    this.dynamicDialogRef = this.dialogService.open(ActivityInputMaintenanceEventComponent, {
      width: '56%',
      autoZIndex: true,
      closeOnEscape: true,
      data: maintenanceEventForSelection,
      showHeader: true,
      header: $localize`:@@selectInstrumentEvent:Select Instrument Event`,
      styleClass: 'eln-test-dialog'
    });

    this.dynamicDialogRef.onClose.subscribe((params) => {
      if (params && !!params.event && params.eventSelected) {
        this.confirmEventSelectionClose(params.event);
      }
    });
  }

  saveInstrumentEventSelection(data: ActivityInputMaintenanceEvent) {
    if (!this.instrumentEventsDataSource) return;
    if (data) {
      this.selectedEvent = {} as MaintenanceEventDetails;
      this.selectedEvent.activityType = data.activityType ?? "";
      this.selectedEvent.dueDate = data.dueDate;
      this.selectedEvent.interval = data.interval;
      this.selectedEvent.isAdhocEvent = data.isAdhocEvent;
      this.selectedEvent.nameDescription = data.nameDescription;
      this.selectedEvent.performedBy = data.performedBy;
      this.selectedEvent.sopReference = data.sopReference;
    }
    const dataValue = this.dataValueService.getExperimentDataValue(
      FieldType.List,
      this.selectedEvent.nameDescription
    );
    this.instrumentEventsDataSource.nameDescription = DataRecordService.getModifiableDataValue(
      dataValue,
      this.instrumentEventsDataSource.nameDescription
    );
    this.instrumentEventsDataSource.nameDescriptionValue =
    (this.instrumentEventsDataSource.nameDescription.value as any).value;
    this.refreshEvent = false;
    data.isSelected = true;
    setTimeout(() => {
      this.refreshEvent = true;
    }, 200);
    this.prepareMaintenanceEventCommand(this.instrumentEventsDataSource.instrumentNumber);
    this.activityInputEventsService
      .activityInputEventsSelectMaintenanceEventPost$Json({
        body: this.maintenanceEventCommand
      })
      .subscribe(
        (response: any) => {
          if (!this.instrumentEventsDataSource) return;
          const instrument = this.activityInputHelper.getInstrumentByActivityId(this.experimentService.currentActivityId);
          if (instrument) instrument.nameDescription = this.instrumentEventsDataSource.nameDescription;
          this.instrumentEventsDataSource.nameDescriptionValue =
          (this.instrumentEventsDataSource.nameDescription.value as any).value;
          this.calculateCompletionPercentage();
          this.createSuccessNotificationMessage(
            $localize`:@@:MaintenanceEventSelectedSuccessfully: Maintenance Event Selected Successfully ${this.instrumentEventsDataSource.nameDescriptionValue}
                  for this instrument ${response.maintenanceEventSelectedEventNotification.activityInputReference}`,
            ``
          );
          const impactAssessmentNode = this.experimentService.currentExperiment?.instrumentEventImpactAssessmentData?.find(s => s.activityId === this.experimentService.currentActivityId && !s.isRemoved);
          if(impactAssessmentNode){
            impactAssessmentNode.instrumentEvent = this.maintenanceEventCommand.nameDescription.value ?? '';
          }
        },
        (error: any) => {
          this.createErrorNotificationMessage(
            $localize`:@@:MaintenanceEventSelectedFailed: Maintenance Event selection failed for this instrument`,
            ``
          );
          data.isSelected = false;
        }
      );
     this.dynamicDialogRef.close();
  }

  confirmEventSelectionClose(params: ActivityInputMaintenanceEvent) {
    const confirmationMessage = $localize`:@@NavigateAwayConfirmation:You are about to leave this
     page without saving. All changes will be lost. Do you really want to leave without saving?`;
    this.confirmationService.confirm({
      message: `${confirmationMessage}`,
      header: $localize`:@@unsavedChangesConfirmationHeader:Unsaved Changes`,
      acceptVisible: true,
      acceptLabel: $localize`:@@saveChanges:Save changes`,
      rejectVisible: true,
      rejectLabel: $localize`:@@leaveWithoutSaving:Leave without saving`,
      closeOnEscape: true,
      dismissableMask: false,
      accept: () => {
        this.saveInstrumentEventSelection(params);
        params.isSelected = true;
      },
      reject: () => {
        this.dynamicDialogRef.close();
      }
    });
  }

  loadDialog(propertyName?: string) {
    this.isLoading = true;
    this.auditHistoryService
      .loadActivityInputsAuditHistory(this.experimentId, this.experimentService.currentActivityId)
      .subscribe((data) => {
        data.dataRecords = this.getInstrumentRecords(data.dataRecords);
        if (!propertyName) {
          this.bindDataToAuditHistoryDialog(data);
          return;
        }
        switch (propertyName) {
          case ActivityInputConstants.instrumentEvent:
            this.bindDataToAuditHistoryDialog(data, ExperimentEventType.MaintenanceEventSelected, ActivityInputConstants.instrumentEvent);
            break;
          case ActivityInputConstants.selectDateRemoved:
            this.bindDataToAuditHistoryDialog(
              data,
              ExperimentEventType.InstrumentDateRemovedChanged,
              ActivityInputConstants.selectDateRemoved
            );
            break;
          case ActivityInputConstants.removedFromService:
            this.bindDataToAuditHistoryDialog(
              data,
              ExperimentEventType.InstrumentRemovedFromServiceChanged,
              ActivityInputConstants.removedFromService
            );
            break;
          case ActivityInputConstants.descriptionOfSituation:
            this.bindDataToAuditHistoryDialog(
              data,
              ExperimentEventType.InstrumentDescriptionChanged,
              ActivityInputConstants.descriptionOfSituation
            );
            break;
          case ActivityInputConstants.instrumentName:
          case ActivityInputConstants.status:
            this.loadCellChangedHistory(data, propertyName);
            break;
        }
      });
  }

  getContextMenu(propertyName?: string) {
    return [
      {
        label: $localize`:@@History:History`,
        action: () => {
          this.loadDialog(propertyName);
        },
        icon: 'icon-s icon-audit-history'
      }
    ];
  }

  private buildInstrumentRefreshDetails(): InstrumentRefreshDetails | undefined {
    if(!this.instrumentEventsDataSource) return;
    return {
      instrumentNumber: this.instrumentEventsDataSource.instrumentNumber,
      name: this.instrumentEventsDataSource.name,
      status: this.instrumentEventsDataSource.status
    }
  }

  private getInstrumentRecords(dataRecords: ExperimentDataRecordNotification[]): ExperimentDataRecordNotification[] {
    return dataRecords
      .filter((x) => {
        return (
          x !== null &&
          x.eventContext !== null &&
          this.eventsForHistory.indexOf(x.eventContext.eventType) > -1
        );
      })
      .filter((r: any) => r.activityInputReference === this.instrumentEventsDataSource?.instrumentNumber);
  }

  private bindDataToAuditHistoryDialog(
    data: AuditHistoryDataRecordResponse,
    type?: ExperimentEventType,
    fieldName?: string
  ) {
    this.isLoading = false;
    let history;
    if (type) {
      history = data.dataRecords
        .filter((d) => d?.eventContext.eventType.toString() === type)
        .filter((r: any) => r.activityInputReference === this.instrumentEventsDataSource?.instrumentNumber);

      this.dynamicDialogRef = this.auditHistoryService.showAuditDialog(
        history,
        $localize`:@@instrumentEventPageHeader:Instrument Event`.concat(
          ELNAppConstants.WhiteSpace,
          '→',
          ELNAppConstants.WhiteSpace,
          fieldName || ''
        )
      );
      return;
    }
    this.dynamicDialogRef = this.auditHistoryService.showAuditDialog(
      data.dataRecords,
      $localize`:@@instrumentEventPageHeader:Instrument Event`);
  }

  private loadCellChangedHistory(data: AuditHistoryDataRecordResponse, fieldName: string) {
    const history = data.dataRecords
      .filter(
        (d: any): d is ActivityInputCellChangedEventNotification =>
          d?.eventContext.eventType.toString() === ExperimentEventType.ActivityInputCellChanged
      )
      .filter((c: any) => (c.propertyName.toLowerCase() === fieldName.toLowerCase()));
    this.dynamicDialogRef = this.auditHistoryService.showAuditDialog(
      history,
      $localize`:@@instrumentEventPageHeader:Instrument Event`.concat(
        ELNAppConstants.WhiteSpace,
        '→',
        ELNAppConstants.WhiteSpace,
        fieldName
      )
    );
  }

  private prepareMaintenanceEventCommand(instrumentCode: string) {
    this.maintenanceEventCommand = {
      activityId: this.experimentService.currentActivityId,
      activityInputReference: instrumentCode,
      activityInputType: ActivityInputType.Instrument,
      nameDescription: this.instrumentEventsDataSource?.nameDescription.value,
      activityType: this.selectedEvent.activityType,
      interval: this.selectedEvent.interval,
      dueDate: this.selectedEvent.dueDate,
      performedBy: this.selectedEvent.performedBy,
      sopReference: this.selectedEvent.sopReference,
      isAdhocEvent: this.selectedEvent.isAdhocEvent,
      experimentId: this.experimentId
    } as SelectMaintenanceEventCommand;
  }

  private prepareChangeInstrumentRemovedFromServiceCommand(newValue: any) {
    this.changeInstrumentRemovedFromServiceCommand = {
      activityId: this.experimentService.currentActivityId,
      activityInputReference: this.instrumentEventsDataSource?.instrumentNumber,
      activityInputType: ActivityInputType.Instrument,
      experimentId: this.experimentId,
      removedFromService: this.instrumentEventsDataSource?.removedFromService.value
    } as ChangeInstrumentRemovedFromServiceCommand;
  }

  private prepareChangeInstrumentDescriptionCommand(newValue: string) {
    this.changeInstrumentDescriptionCommand = {
      activityId: this.experimentService.currentActivityId,
      activityInputReference: this.instrumentEventsDataSource?.instrumentNumber,
      activityInputType: ActivityInputType.Instrument,
      experimentId: this.experimentId,
      description: this.instrumentEventsDataSource?.description.value //this.value,
    } as ChangeInstrumentDescriptionCommand;
  }

  private prepareChangeInstrumentDateRemovedCommand(dateRemoved: string) {
    this.changeInstrumentDateRemovedCommand = {
      activityId: this.experimentService.currentActivityId,
      activityInputReference: this.instrumentEventsDataSource?.instrumentNumber,
      activityInputType: ActivityInputType.Instrument,
      experimentId: this.experimentId,
      dateRemoved: this.instrumentEventsDataSource?.dateRemoved.value
    } as ChangeInstrumentDateRemovedCommand;
  }

  private createSuccessNotificationMessage(summary: string, detail: string) {
    const messageObj: Message = {
      key: 'notification',
      severity: 'success',
      summary,
      detail,
      sticky: false
    };
    this.messageService.add(messageObj);
  }

  private createErrorNotificationMessage(summary: string, detail: string) {
    const messageObj: Message = {
      key: 'notification',
      severity: 'error',
      summary,
      detail,
      sticky: false
    };
    this.messageService.add(messageObj);
  }

  private getDateRemovedValue(removedFromServiceValue: string, existingDateRemovedValue?: string) {
    switch (removedFromServiceValue) {
      case RemovedFromServiceOptions.Yes:
      case RemovedFromServiceOptions.PreviouslyRemoved:
        this.disableDateRemoved = false;
        if (existingDateRemovedValue) return existingDateRemovedValue;
        return '';
      case RemovedFromServiceOptions.No:
        this.disableDateRemoved = true;
        return NA;
      default:
        return existingDateRemovedValue;
    }
  }

  public getControlCustomDefinitionValidatorForDescription(): Array<ControlCustomDefinition> {
    return [
      InstrumentEventHelper.getControlCustomDefinitionForEmptyContent(() => {
        return InstrumentEventHelper.isDataValueEmpty(this.instrumentEventsDataSource);
      }),
      InstrumentEventHelper.getControlCustomDefinitionForModifiedContent(() => {
        return (
          InstrumentEventHelper.isDataValueModified(this.instrumentEventsDataSource) &&
          !InstrumentEventHelper.isDataValueEmpty(this.instrumentEventsDataSource)
        );
      })
    ];
  }

  public getControlCustomDefinitionValidatorForDateRemoved(): Array<ControlCustomDefinition> {
    return [
      InstrumentEventHelper.getControlCustomDefinitionForEmptyContent(() => {
        return InstrumentEventHelper.isDateRemovedEmpty(this.instrumentEventsDataSource);
      }),
      InstrumentEventHelper.getControlCustomDefinitionForModifiedContent(() => {
        return (
          InstrumentEventHelper.isDateRemovedModified(this.instrumentEventsDataSource) &&
          !InstrumentEventHelper.isDateRemovedEmpty(this.instrumentEventsDataSource)
        );
      })
    ];
  }

  public getControlCustomDefinitionValidatorForNameDescription(): Array<ControlCustomDefinition> {
    return [
      InstrumentEventHelper.getControlCustomDefinitionForEmptyContent(() => {
        return InstrumentEventHelper.isEventEmpty(this.instrumentEventsDataSource);
      }),
      InstrumentEventHelper.getControlCustomDefinitionForModifiedContent(() => {
        return (
          InstrumentEventHelper.isEventModified(this.instrumentEventsDataSource) &&
          !InstrumentEventHelper.isEventEmpty(this.instrumentEventsDataSource)
        );
      })
    ];
  }

  public getControlCustomDefinitionValidatorForDropdown(): Array<ControlCustomDefinition> {
    return [
      InstrumentEventHelper.getControlCustomDefinitionForEmptyContent(() => {
        return InstrumentEventHelper.isRemovedFromServiceEmpty(this.instrumentEventsDataSource);
      }),
      InstrumentEventHelper.getControlCustomDefinitionForModifiedContent(() => {
        return (
          InstrumentEventHelper.isRemovedFromServiceModified(this.instrumentEventsDataSource) &&
          !InstrumentEventHelper.isRemovedFromServiceEmpty(this.instrumentEventsDataSource)
        );
      })
    ];
  }

  getModifiableDataValue(newValue: ExperimentDataValue, oldValue: ModifiableDataValue){
    return DataRecordService.getModifiableDataValue(
      newValue,
      oldValue
    );
  }
}

export enum RemovedFromServiceOptions {
  Yes = 'Yes',
  No = 'No',
  PreviouslyRemoved = 'Previously Removed'
}
