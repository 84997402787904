import { Component, HostListener, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { BptGridComponent, BptGridMode, BptGridRowActionClickEvent, BptGridRowActionConfiguration, BptRowActionElement, ColumnDefinition } from 'bpt-ui-library/bpt-grid';
import { LabItemsService } from '../../lab-items.service';
import { MaterialAliquot as LabItemsMaterial } from '../../../../api/models/LabItemsELN/Bookshelf/Api/LabItems/material-aliquot';
import { LabItemsMaterialTableOptions } from '../lab-items-material/lab-items-material-table-options';
import { BaseComponent } from '../../../../base/base.component';
import { ClientStateService } from 'services/client-state.service';
import { ActivatedRoute } from '@angular/router';
import { UnsubscribeAll } from '../../../../shared/rx-js-helpers';
import { ActivityInputType } from '../../../../api/models';
import { BptElnGridRowActionsComponent } from '../../shared/bpt-eln-grid-row-actions.component';
import { GridActionsPositionManager } from '../../shared/grid-actions-position-manager';
import { ClientState } from '../../../../app.states';
import { LabItemWisePermissions } from '../../shared/lab-items-feature-manager';
import { BehaviorSubject } from 'rxjs';
import { UserService } from '../../../../services/user.service';
@Component({
  selector: 'app-lab-items-removed-material',
  templateUrl: './lab-items-removed-material.component.html',
  styleUrls: ['./lab-items-removed-material.component.scss']
})
export class LabItemsRemovedMaterialComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() titleOfTable = $localize`:@@LabItemsMaterialsTableTitle:Materials`;
  @Input()
  subHeading = $localize`:@@labitemsRemovedMaterialsSubHeading:Please click on the restore icon from the table to restore removed materials`;
  labItemsRemovedMaterialDataSource: LabItemsMaterial[] = [];
  @ViewChild('labItemsRemovedMaterialGrid') labItemsRemovedMaterialGrid!: BptGridComponent;

  public readonly gridActionsPositionManager = new GridActionsPositionManager(
    'app-lab-items-removed-material',
    BptElnGridRowActionsComponent.RowActionsWidth
  );

  labItemsMaterialId = 'labItemsRemovedMaterial';
  columnDefinitions: ColumnDefinition[] = [];
  gridMode = BptGridMode.dataView;
  containsRemovedRows = false;
  wasEmpty = false;
  public styleRootVariables = '';
  gridActions!: BptGridRowActionConfiguration;

  constructor(
    public readonly clientStateService: ClientStateService,
    public readonly activatedRoute: ActivatedRoute,
    private readonly labItemsService: LabItemsService,
    private readonly renderer: Renderer2,
    private readonly userService: UserService
  ) {
    super(clientStateService, activatedRoute);
    this.columnDefinitions = LabItemsMaterialTableOptions.GetColumnDefinitions(
      this.labItemsService.latestLabItemsPermissions,
      () => {
        return undefined;
      }
    );
    this.refreshDataSource();
  }

  ngOnInit(): void {
    this.addGridActions();
    this.watchRemovedMaterialRefreshNotification();
    this.watchMaterialRestoredNotification();
  }

  ngOnDestroy(): void {
    UnsubscribeAll(this.activeSubscriptions);
  }

  onGridReady() {
    this.setValidationStyles();
  }

  loadAuditHistoryDialog() {
    this.labItemsService.loadAuditHistoryDialog(ActivityInputType.Material);
  }



  private addGridActions() {
    const rowActions = this.getRowActionItems();
    const actionsSubject: BehaviorSubject<BptRowActionElement[]> = new BehaviorSubject(rowActions);

    this.gridActions = {
      actions: actionsSubject,
    };
  }

  public getRowActionItems(): BptRowActionElement[] {
    return [
      {
        id: this.labItemsService.columnRestoreActionId,
        enabled: this.evaluatePermissions()[ActivityInputType.Material]['eln-restore-lab-item'] && !(this.userService.hasOnlyReviewerRights()),
        styleClass: 'icon-s icon-restore-icon',
        click: this.rowRestoreActionClick.bind(this),
        tooltip: $localize`:@@RestoreItem:Restore item`
      }
    ];
  }

  onFirstDataRendered(e: any) {
    this.labItemsService.loadCellLocks(this.labItemsRemovedMaterialGrid.gridOptions, this.renderer);
  }

  private rowRestoreActionClick(e: BptGridRowActionClickEvent) {
    e.params.data.id = e.params.node.rowIndex;
    this.labItemsService.restoreHandler[ActivityInputType.Material](e.params.data);
  }

  private refreshDataSource(): void {
    const removedMaterials = this.labItemsService.getLabItemsRemovedMaterials();
    removedMaterials.forEach((material) => {
      this.labItemsService.unpackModifiableDataValues<LabItemsMaterial>(
        material,
        material.tableData,
        this.columnDefinitions,
        ActivityInputType.Material
      );
    });
    this.labItemsRemovedMaterialDataSource = [...removedMaterials];
    this.labItemsRemovedMaterialGrid?.gridApi?.setGridOption('rowData', this.labItemsRemovedMaterialDataSource);
    this.labItemsRemovedMaterialGrid?.gridApi.autoSizeAllColumns()
  }

  private readonly setValidationStyles = (): void => {
    this.columnDefinitions?.forEach((columnDefinition: ColumnDefinition) => {
      if (columnDefinition.editable) {
        columnDefinition.cellClassRules = this.labItemsService.getCssRules(
          this.labItemsRemovedMaterialDataSource
        );
      }
      this.labItemsRemovedMaterialGrid?.updateColumnDefinitions(columnDefinition);
    });
  };

  private watchRemovedMaterialRefreshNotification() {
    this.activeSubscriptions.push(
      this.labItemsService.materialShouldRefresh.subscribe({
        next: () => this.refreshDataSource()
      })
    );
  }

  private watchMaterialRestoredNotification() {
    this.activeSubscriptions.push(
      this.labItemsService.MaterialRestored.subscribe({
        next: () => this.refreshDataSource()
      })
    );
  }

  private evaluatePermissions(): LabItemWisePermissions {
    const features = this.clientStateService.getFeatureFlags(ClientState.EXPERIMENT_ACTIVITIES);
    this.labItemsService.featuresByClientState = features;
    return this.labItemsService.evaluateUserPermissionsOnLabItems();
  }

  @HostListener('window:resize', ['$event'])
  onResize(_event: any) {
    if (this.labItemsRemovedMaterialGrid) {
      this.gridActionsPositionManager.updatePosition(
        this.labItemsRemovedMaterialGrid.gridApi
      );
    }
  }
}
