import { Injectable } from '@angular/core';
import { UserPicklistsService, UserPreferenceService } from '../../api/services';
import { NA, Quantity, SeverityIndicatorType, Unit } from 'bpt-ui-library/shared';
import { UserPicklistResponse } from '../../api/models/user-picklist-response';
import { ExpirationModel } from '../models/preparation.model';
import { Observable, Subject, Subscription } from 'rxjs';
import { UnitLoaderService } from '../../services/unit-loader.service';
import { elnShareReplay } from '../../shared/rx-js-helpers';
import { DataValueService, FieldOrColumnType } from '../../experiment/services/data-value.service';
import { ProjectLogLoaderService } from '../../services/project-log-loader.service';
import { UserService as CurrentUserService } from 'services/user.service';
import { ColumnType, ExperimentDataSource, ExperimentDataValue, ExperimentPreparationStatus, FieldType, StringValue, TimeSelectOption, UserPreference, ValueState } from '../../api/models';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ModifiableDataValue, StringTypeDictionaryValue } from '../../api/data-entry/models';
import { PreparationRow } from '../preparations.component';
import { PreparationItem } from '../models/preparation-presentation.model';
import { EditableCallbackParams, ICellRendererParams } from 'ag-grid-enterprise';
import { PreparationEventService } from './preparation-event.service';
import {
  BptGridComponent,
  BptGridPreferences,
  ColumnDefinition,
  DropdownCellEditorParamsDefaults,
  PreferenceAdded,
  PreferenceDeleted,
  PreferenceSelectionChanged,
  PreferenceUpdated
} from 'bpt-ui-library/bpt-grid';
import { PreparationConstants } from '../preparation-constants';
import { guid } from '../../model/template.interface';
import { mapValues, capitalize } from 'lodash-es';
import { GridPreferenceService } from '../../experiment/services/grid-preference.service';
import { ShowClientFacingNotesEventData } from '../../experiment/comments/client-facing-note/client-facing-note-event.model';
import { ClientFacingNoteModel } from '../../experiment/comments/client-facing-note/client-facing-note.model';
import { DateAndInstantFormat, formatInstant, formatLocalDate } from '../../shared/date-time-helpers';
import { LocalDate } from '@js-joda/core';
import { RecipeService } from '../../recipe/services/recipe.service';
import { RecipeState } from '../../api/cookbook/models';
import { referencesConstants } from '../../configurations/app-configuration.interface';

@Injectable({
  providedIn: 'root'
})
export class PreparationService {
  ParentItemType: "Experiment" | "Recipe" = "Experiment";
  private readonly keyNameOf = <T>(name: Extract<keyof T, string>): string => name;
  pickListItems: UserPicklistResponse[] = [];
  private readonly isCellEditable = (params: EditableCallbackParams) => {
    return params.data.source !== NA && this.ParentItemType === "Recipe" ? false : true;
  };
  getColumnDefinitions(isRemovedPreparations = false): ColumnDefinition[] {
    return [{
      label: $localize`:@@Index:Index`,
      field: 'rowIndex',
      columnType: ColumnType.Index,
      hidden: true,
      showInColumnChooser: false,
      editable: false,
      suppressContextMenu: true
    },
    {
      field: this.keyNameOf<PreparationRow>('id'),
      columnType: ColumnType.RowId,
      label: $localize`:@@preparationRowId:Row ID`,
      cellClass: 'bpt-row-id-cell',
      editable: false,
      alwaysHidden: false,
      hidden: true,
      suppressContextMenu: true
    },
    {
      field: this.keyNameOf<PreparationRow>('preparationNumber'),
      label: $localize`:@@preparationId:Preparation ID`,
      width: PreparationConstants.widthMedium,
      minWidth: PreparationConstants.widthMedium,
      cellClass: 'cell-Link',
      showInColumnChooser: false,
      filterParams: {
        filterOptions: ['contains']
      },
      editable: false,
    },
    {
      field: this.keyNameOf<PreparationRow>('name'),
      label: $localize`:@@name:Name`,
      width: PreparationConstants.widthSmall,
      minWidth: PreparationConstants.widthSmall,
      columnType: ColumnType.String,
      showInColumnChooser: false,
      editable: this.isCellEditable
    },
    {
      field: this.keyNameOf<PreparationRow>('source'),
      label: $localize`:@@Source:Source`,
      columnType: ColumnType.String,
      width: PreparationConstants.widthSmall,
      minWidth: PreparationConstants.widthSmall,
      allowNA: false,
      editable: false,
      hidden: true,
      showInColumnChooser: this.ParentItemType === 'Recipe',
      valueSetter: (params: any) => {
        params.data.name = params.newValue?.trim();
        return true;
      }
    },
    {
      field: this.keyNameOf<PreparationRow>('formulaComponents'),
      label: $localize`:@@formulationOrComponents:Formulation/Components`,
      columnType: ColumnType.String,
      editable: this.isCellEditable,
    },
    {
      field: this.keyNameOf<PreparationRow>('status'),
      label: $localize`:@@status:Status`,
      columnType: ColumnType.EditableList,
      width: PreparationConstants.widthSmall,
      minWidth: PreparationConstants.widthSmall,
      dropdownEditorConfiguration: {
        ...DropdownCellEditorParamsDefaults,
        ...{
          allowCustomOptionsForDropdown: true,
          editable: this.isCellEditable,
          labelField: 'label',
          valueField: 'value',
          group: false,
          options: [
            { label: `Pending`, value: `Pending` },
            { label: `Reviewed`, value: `Reviewed` },
          ],
          allowNA: false,
          dropdownVisible: () => { }
        },
      },
    },
    {
      field: this.keyNameOf<PreparationRow>('expiration'),
      label: $localize`:@@expiration:Expiration`,
      width: PreparationConstants.widthSmall,
      minWidth: PreparationConstants.widthSmall,
      columnType: isRemovedPreparations ? ColumnType.String : ColumnType.Date,
      allowTimeSelect: TimeSelectOption.Optional,
      filterParams: {
        filterOptions: ['contains'],
        suppressAndOrCondition: true
      },
      valueGetter: (params) => {
        const expiration = params.data.expiration;
        if (expiration === NA || expiration === undefined) {
          return PreparationConstants.suitableForUseText;
        }
        else {
          if (expiration instanceof LocalDate) {
            return formatLocalDate(expiration);
          }
          else {
            return formatInstant(expiration, DateAndInstantFormat.dateTimeToMinute);
          }
        }
      },
      editable: false
    },
    {
      field: this.keyNameOf<PreparationRow>('storageCondition'),
      label: $localize`:@@storageCondition:Storage Condition`,
      columnType: ColumnType.List,
      width: PreparationConstants.widthSmall,
      minWidth: PreparationConstants.widthSmall,
      dropdownEditorConfiguration: {
        ...DropdownCellEditorParamsDefaults,
        ...{
          allowCustomOptionsForDropdown: true,
          editable: this.isCellEditable,
          labelField: 'label',
          valueField: 'value',
          group: false,
          allowNA: false,
          dropdownVisible: () => { }
        }
      }
    },
    {
      field: this.keyNameOf<PreparationRow>('concentration'),
      label: $localize`:@@concentration:Concentration`,
      columnType: ColumnType.Quantity,
      width: PreparationConstants.widthSmall,
      minWidth: PreparationConstants.widthSmall,
      allowNA: true,
      allowNegative: true,
      allowDecimal: true,
      editable: this.isCellEditable,
      allowedUnits: this.unitLoaderService.allUnits.filter((unit) => unit.dimension === 'Mass/Volume'),
      showUnitNameInList: true,
      enableSignificantFigures: true
    },
    {
      field: this.keyNameOf<PreparationRow>('description'),
      width: PreparationConstants.widthLarge,
      minWidth: PreparationConstants.widthLarge,
      columnType: ColumnType.String,
      label: $localize`:@@containerDescription:Container Description`,
      valueGetter: (params: any) => {
        if (params.data.description.state === ValueState.Set) {
          return this.buildContainerDescription(params.data.description);
        }
        return NA;
      },
      filterParams: {
        filterOptions: ['contains'],
        suppressAndOrCondition: true
      },
      editable: false,
    }];
  }
  readonly clientFacingNoteAddedNotification = new Subject<CustomEvent<ShowClientFacingNotesEventData>>();
  readonly clientFacingNoteEvents = new Subject<ClientFacingNoteModel>();
  public gridPreferencesReceived = new Subject<PreparationGridPreferences>();
  subscriptions: Subscription[] = [];
  public storageOptions: UserPicklistResponse[] = [];

  readonly pickListOptions = {
    colorId: "32489787-1eda-43f7-9006-42419748abda",
    materialId: "90090a4c-6063-4e7f-8df3-8227c10dd0e4",
    typeId: "1dc20c5c-affb-43c5-8ec7-678ed8c73158",
    exposureId: "d1e227a6-e29d-4ca2-8402-2100143ea805",
    storageConditionId: 'eb7aef54-5e41-4b2f-879c-c246152f5e4a',
    disposalId: '31b16481-4347-4b26-ac0d-0bcef163a8a5',
    compendiaId: referencesConstants.compendiaPicklistId
  };

  stabilityUnitTypeList = ['min', 'hours', 'days', 'weeks', 'months', 'years'];
  missingFieldMessage =
    'Logic error: When a grid is used to render a table, each column definition must have a field value but at least one is missing.';
  public expirationModel: ExpirationModel = { suitableForUse: false };
  public preparationUnits: Array<Unit[]> = [];
  public isSliderDisabled = false;
  public isUserAllowedToDelete = false;

  constructor(
    public readonly picklistService: UserPicklistsService,
    public readonly unitLoaderService: UnitLoaderService,
    public readonly dataValueService: DataValueService,
    public readonly projectLogLoaderService: ProjectLogLoaderService,
    private readonly currentUserService: CurrentUserService,
    public readonly messageService: MessageService,
    public readonly confirmationService: ConfirmationService,
    public readonly preparationEventService: PreparationEventService,
    public readonly gridPreferenceService: GridPreferenceService,
    public readonly userPreferenceService: UserPreferenceService,
    public readonly recipeService: RecipeService
  ) {
  }


  public getQuantityUnits() {
    const concentrationUnits = this.unitLoaderService.allUnits.filter((unit) => unit.dimension === 'Mass/Volume');
    this.preparationUnits.push(concentrationUnits);
    const stabilityUnits = this.unitLoaderService.allUnits.filter((unit) => unit.dimension === 'Time' && this.stabilityUnitTypeList.includes(unit.abbreviation));
    this.preparationUnits.push(stabilityUnits);
    const originalQuantityUnits = this.unitLoaderService.allUnits.filter((unit) => unit.dimension === 'Mass' || unit.dimension === 'Volume')
    this.preparationUnits.push(originalQuantityUnits)
  }
  public getPickListsForPreparation(): {
    storageCondition: Observable<UserPicklistResponse>,
    disposal: Observable<UserPicklistResponse>,
    color: Observable<UserPicklistResponse>,
    material: Observable<UserPicklistResponse>,
    type: Observable<UserPicklistResponse>,
    exposure: Observable<UserPicklistResponse>
  } {
    const storageCondition$ = elnShareReplay<UserPicklistResponse>('storageCondition', () =>
      this.picklistService.userPicklistsIdGet$Json({ id: this.pickListOptions.storageConditionId })
    );
    const disposal$ = elnShareReplay<UserPicklistResponse>('disposal', () =>
      this.picklistService.userPicklistsIdGet$Json({ id: this.pickListOptions.disposalId })
    );

    const color$ = elnShareReplay<UserPicklistResponse>('color', () =>
      this.picklistService.userPicklistsIdGet$Json({ id: this.pickListOptions.colorId })
    );
    const material$ = elnShareReplay<UserPicklistResponse>('material', () =>
      this.picklistService.userPicklistsIdGet$Json({ id: this.pickListOptions.materialId })
    );
    const type$ = elnShareReplay<UserPicklistResponse>('type', () =>
      this.picklistService.userPicklistsIdGet$Json({ id: this.pickListOptions.typeId })
    );
    const exposure$ = elnShareReplay<UserPicklistResponse>('exposure', () =>
      this.picklistService.userPicklistsIdGet$Json({ id: this.pickListOptions.exposureId })
    );
    return {
      storageCondition: storageCondition$,
      disposal: disposal$,
      color: color$,
      material: material$,
      type: type$,
      exposure: exposure$
    };
  }

  /**
  * It makes the content of slider readonly user user does not have analyst role and vice versa
  */
  public isSliderContentDisabled(): boolean {
    const currentUser = this.currentUserService.currentUser;
    const userHasRecipeDesignerAccess = this.currentUserService.usersWithRecipeDesignerRoles.some(user => user.puid === currentUser.puid);
    const userHasAnalystAccess = this.currentUserService.usersWithAnalystRoles.some(user => user.puid === currentUser.puid);
    return this.ParentItemType === 'Recipe' ? (!userHasRecipeDesignerAccess || this.isSliderDisabled) : (!userHasAnalystAccess || this.isSliderDisabled);
  }


  public getCurrentUser() {
    return this.currentUserService.currentUser;
  }

  public getUsersWithReviewerRole() {
    return this.currentUserService.usersWithReviewerRoles;
  }

  public getClients() {
    return this.projectLogLoaderService.getAllClients();
  }

  public getPrimitiveValue(value: ModifiableDataValue) {
    return this.dataValueService.getPrimitiveValue(FieldType.Quantity, value);
  }


  public getExperimentDataValue(fieldType: ColumnType | FieldType | undefined, value: any) {
    return this.dataValueService.getExperimentDataValue(fieldType, value);
  }


  public fetchProjectsLinkedToClients(clientIds: string[]) {
    return this.projectLogLoaderService.fetchProjectsLinkedToClients(clientIds);
  }

  public buildContainerDescription(description: StringTypeDictionaryValue): string {
    return this.dataValueService.joinValues(description);
  }

  buildGridPreference(userPreferences: UserPreference[]) {
    return this.gridPreferenceService.buildGridPreference(userPreferences);
  }

  loadGridPreferences(gridId: string) {
    this.subscriptions.push(
      this.userPreferenceService.userPreferencesUserPreferenceKeyGet$Json({
        userPreferenceKey: gridId
      }).subscribe({
        next: (preferences) => {
          this.gridPreferencesReceived.next({
            gridId: gridId,
            preferences: preferences.userPreferences
          });
        }
      })
    );
  }

  saveNewGridPreference(
    $event: PreferenceAdded,
    puid: string,
    savedPreferences: BptGridPreferences,
    grid: BptGridComponent,
    controlId: string
  ): void {
    this.gridPreferenceService.saveNewPreference($event, puid, savedPreferences, grid, controlId);
  }

  deleteGridPreference($event: PreferenceDeleted): void {
    this.gridPreferenceService.deletePreference($event);
  }

  updateGridPreference($event: PreferenceUpdated, puid: string, id: string): void {
    this.gridPreferenceService.updatePreference($event, puid, id);
  }

  changeDefaultGridPreference($event: PreferenceSelectionChanged, puid: string, id: string): void {
    this.gridPreferenceService.changeDefaultPreference($event, puid, id);
  }

  isUserAllowedToApplyScaling(currentUser: string): boolean {
    return (
      this.recipeService.currentRecipe.tracking.state === RecipeState.Draft &&
      this.recipeService.currentRecipe.tracking.assignedEditors.includes(currentUser)
    );
  }

  styleClassProperties: { [key: string]: string } = {
    rejectButtonStyleClass: 'eln-standard-popup-button p-button-outlined',
    acceptButtonStyleClass: 'eln-standard-popup-button',
    icon: 'pi pi-exclamation-triangle'
  };
  /**
   * This method is used to show toast message with some defined parameters
   */
  public buildToastMessage(key: string, severity: string, id: string, detail: string, sticky: boolean, summary?: string,) {
    this.messageService.add({
      key: key,
      severity: severity,
      id: id,
      detail: detail,
      summary: summary,
      sticky: sticky
    });
  }

  getQuantityPrimitiveValue(newQuantity: Quantity) {
    if (newQuantity.state === ValueState.Empty) {
      delete newQuantity.exact;
      delete newQuantity.sigFigs;
      delete newQuantity.value;
    }
    return this.getExperimentDataValue(FieldType.Quantity, newQuantity);
  }

  public getAllPreparationsFromCurrentActivity(activityPreparations: Array<PreparationItem>): PreparationRow[] {
    let rowIndex = 0;
    if (this.ParentItemType === 'Recipe') {
      activityPreparations.sort((a, b) => {
        const aValue = (a.source?.value as StringValue).value;
        const bValue = (b.source?.value as StringValue).value;
        if (!aValue || !bValue) throw new Error('LOGIC ERROR IN SORT: both sides to have defined string values');
        return aValue.length - bValue.length;
      });
    }
    return activityPreparations
      .map(preparation => {
        ++rowIndex;
        return this.mapToPreparationRow(preparation, rowIndex);
      });
  }

  public mapToPreparationRow(preparations: PreparationItem, index: number): PreparationRow {
    return {
      rowIndex: index,
      id: preparations.preparationId,
      preparationNumber: preparations.preparationNumber,
      name: preparations.name,
      formulaComponents: preparations.summary.formulaComponents?.value ? preparations.summary.formulaComponents : undefined,
      status: preparations.status,
      expiration: preparations.expirationValue?.expirationDateValue ? preparations.expirationValue.expirationDateValue : undefined,
      storageCondition: preparations.summary.storageCondition,
      concentration: preparations.summary.concentration?.value ? preparations.summary.concentration : undefined,
      description: preparations.description,
      source: preparations.source
    };
  }
  public getSeverityIndicatorDefinitionForPreparations = (data: { [key: string]: any }[], params: ICellRendererParams): any => {
    const noIndicator = { indicatorType: '' as SeverityIndicatorType };
    const row = data.find(r => r.preparationId === params?.data?.id);
    if (!row) return  noIndicator ;
    const field = params?.colDef?.field;
    if (!field) return noIndicator;
    if (field === PreparationConstants.id) return noIndicator;
    const cellValue = row[field] || row.summary[field] || field === PreparationConstants.preparationExpirationColumn && row.expirationValue[field + 'DateValue'];
    if (!cellValue || (cellValue?.value?.state === ValueState.Empty && this.ParentItemType !== PreparationConstants.parentItemType)) {
      return { indicatorType: SeverityIndicatorType.Empty };
    }
    if (!params || !params.data || !params.colDef || !params.colDef.field) {
      return noIndicator;
    }
    if (cellValue.isModified) return { indicatorType: SeverityIndicatorType.Modified };
    if (cellValue.value?.source === ExperimentDataSource.Recipe) {
      return { indicatorType: SeverityIndicatorType.boldPrimaryBlue }
    }
    return noIndicator;
  }

  public checkForFieldsCompletion(gridData: any) {
    if (Object.values(gridData).some(value => !value)) {
      return false;
    }
    else
      return true;
  }
  public checkAllFieldsHaveValuesOnCellChange(grid: BptGridComponent) {
    let isEmptyCount = 0;
    grid?.gridApi?.forEachNode(node => {
      if (node.data && !this.checkForFieldsCompletion(node.data)) {
        isEmptyCount++;
      }
    });
    this.preparationEventService.allPreparationsFieldsFilledSubject.next(isEmptyCount === 0);
  }

  public checkAllFieldsHaveValuesOnLoad(preparations: PreparationItem[]) {
    const allFieldsFilled = preparations.every(preparation => {
      return (
        preparation.name.value &&
        preparation.description?.value &&
        preparation.summary.formulaComponents?.value &&
        preparation.summary.storageCondition?.value &&
        preparation.summary.concentration?.value
      );
    });
    this.preparationEventService.allPreparationsFieldsFilledSubject.next(allFieldsFilled);
  }

  public syncCellValueToClientSite(preparations: PreparationItem[], rowId: string, newValue: ExperimentDataValue | ExperimentPreparationStatus, fieldName: string): void {
    const row = preparations.find((row) => row.preparationId === rowId);
    if (!row) return
    preparations.forEach((preparation: PreparationItem) => {
      if (rowId === preparation.preparationId) {
        switch (fieldName) {
          case 'name':
            preparation.name = { isModified: true, value: newValue as ExperimentDataValue }
            break;
          case 'source':
            preparation.source = { isModified: true, value: newValue as ExperimentDataValue }
            break;
          case 'formulaComponents':
            preparation.summary.formulaComponents = { isModified: true, value: newValue as ExperimentDataValue };
            break;
          case 'storageCondition':
            preparation.summary.storageCondition = { isModified: true, value: newValue as ExperimentDataValue };
            break;
          case 'concentration':
            preparation.summary.concentration = { isModified: true, value: newValue as ExperimentDataValue };
            break;
          case 'status':
            preparation.status = newValue as ExperimentPreparationStatus;
            break;
          default:
            break;
        }
      }
    });
  }

  public removePreparation(preparationId: guid, preparationNumber: string) {
    const message = this.ParentItemType === 'Recipe' ? $localize`:@@recipePreparationRemoveRow:Are you sure you want to remove from Preparations? ` :
      $localize`:@@preparationRemoveRow:Are you sure you want to remove "${preparationNumber
        }" from Preparations? The removed preparation can be restored using the "View Removed Rows" option.`;
    this.confirmTheAction(preparationId, message, ActionType.Remove);
  }

  public restorePreparation(preparationsId: string, preparationNumber: string) {
    const message = $localize`:@@preparationRestoreRow:Are you sure you want to restore "${preparationNumber}" from removed preparations? `
    this.confirmTheAction(preparationsId, message, ActionType.Restore);
  }
  public readonly restoreHandler: {
    [actionType: string]: (preparationNumber: string, message: string, actionType: ActionType) => void;
  } = {
      Restore: this.confirmTheAction.bind(this),
    };


  private confirmTheAction(preparationId: string, message: string, actionType: ActionType): void {
    this.confirmationService.confirm({
      message: message,
      header: $localize`:@@confirmationHeader:Confirmation`,
      closeOnEscape: true,
      dismissableMask: false,
      acceptVisible: true,
      acceptLabel: $localize`:@@okTitle:OK`,
      rejectVisible: true,
      rejectLabel: $localize`:@@cancelTitle:Cancel`,
      ...this.styleClassProperties,
      accept: () => {
        if (actionType === ActionType.Restore) {
          this.preparationEventService.restorePreparation(preparationId);
        } else {
          this.preparationEventService.removePreparation(preparationId);
        }
      },
      reject: () => {
      }
    });
  }


  public getPrimitiveDataValueRows(incomingRows: { [key: string]: ModifiableDataValue | string }[])
    : { [key: string]: any }[] {
    return incomingRows.map((row) =>
      mapValues(row, (value: ModifiableDataValue | string, field: string) => {
        const column = this.getColumnDefinitions().find((d) => d.field === field);
        if (!column) throw new Error(`${this.missingFieldMessage} : field name: ${field}`);
        value = this.handleStatusField(field, value);
        if (field === 'formulaComponents') {
          value = value as ModifiableDataValue;
          if (value.value.state === ValueState.Empty) return "";
        }
        if (field === "description") {
          value = value as ModifiableDataValue;
          if (value.value !== null) {
            return value.value;
          }
          else {
            return "";
          }
        }
        return this.dataValueService.getPrimitiveValue(
          column.columnType as FieldOrColumnType,
          value as ModifiableDataValue
        );
      })
    );
  }

  private handleStatusField(field: string, value: string | ModifiableDataValue) {
    if (field === 'status' && this.ParentItemType === 'Recipe') {
      value = '';
    }
    if (field === 'status') {
      if (typeof (value) === "string")
        value = capitalize(value);
    }
    return value;
  }

  confirmUnsavedChangesInternalInfo(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.confirmationService.confirm({
        message: PreparationConstants.confirmationMessage,
        header: PreparationConstants.DeleteForConfirmationHeader,
        closeOnEscape: true,
        dismissableMask: false,
        acceptVisible: true,
        acceptLabel: PreparationConstants.acceptLabel,
        rejectVisible: true,
        rejectLabel: PreparationConstants.rejectLabel,
        accept: () => resolve(),
        reject: () => reject(),
      });
    });
  }
}

export enum ActionType {
  Remove = 'remove',
  Restore = 'restore',
}

export interface PreparationGridPreferences {
  gridId: string,
  preferences: Array<UserPreference>
}
