import { Injectable } from "@angular/core";
import { ExperimentEventsService } from "../../api/data-entry/services";
import { ChangeReasonLookupEntity, ChangeReasonLookupResponse, FieldDefinitionResponse, FieldGroupResponse, FormItemType, SpecType, ValueState, ValueType } from "../../api/models";
import { DateAndInstantFormat, formatInstant, formatLocalDate } from "../../shared/date-time-helpers";
import { NA } from "bpt-ui-library/shared";
import { ExperimentService } from "./experiment.service";
import { Subject } from "rxjs";
import { ChangeReasonsSliderDetails } from "../model/change-reason-slider-details";
import { AddChangeReasonCommand, NodeType } from "../../api/data-entry/models";
import { Activity, ColumnSpecification, Form, Module, ModuleItem, Table, TableValueRow } from "../../model/experiment.interface";
import { UnitLoaderService } from "../../services/unit-loader.service";
import { SpecificationService } from "../../shared/specification-input/specification.service";
import { AuditHistoryEventsService } from "../audit-history/audit-history-events.service";
import { last } from "lodash-es";
import { ElnLookupService } from "../../api/services";

@Injectable({
  providedIn: 'root'
})
export class ChangeReasonService {
  public static changeReasonId = '';
  public static oldValue: any = '';

  private newValue = '';
  private changeReasonContextTitle = '';
  private contextPath: string[] = [];
  private changeDetails: any = {};
  private preDefinedChangeReasons: ChangeReasonLookupEntity[] = [];

  public readonly isChangeReasonSliderDetails = new Subject<ChangeReasonsSliderDetails>();

  public readonly openSlider = new Subject<boolean>();

  constructor(
    private readonly experimentEventsService: ExperimentEventsService,
    private readonly experimentService: ExperimentService,
    private readonly specificationService: SpecificationService,
    private readonly unitLoaderService: UnitLoaderService,
    private readonly auditHistoryEventsService: AuditHistoryEventsService,
    private readonly elnLookupService: ElnLookupService
  ){
  }

  getChangeReasonsFromLookup() {
    this.elnLookupService.elnLookupPredefinedChangeReasonsGet$Json().subscribe({
      next: (response: ChangeReasonLookupResponse) => {
        this.preDefinedChangeReasons = response.changeReasons;
      }
    });
  }

  getPredefinedChangeReasons() {
    return this.preDefinedChangeReasons;
  }

  setContextTitle(changeDetails: any) {
    this.changeDetails = changeDetails;
    const modules = this.experimentService.currentActivity?.dataModules;
    const currentActivity = this.experimentService.currentActivity;
    if (changeDetails.hasOwnProperty('tableId') && changeDetails.hasOwnProperty('rowId')) {
      this.getContextTitleForTable(changeDetails, modules, currentActivity);
    }
    if ( changeDetails.hasOwnProperty('tableIds') ) {
      this.getContextTitleForTable(changeDetails, modules, currentActivity);
    }
    if(changeDetails.hasOwnProperty('formId')) {
      this.getContextTitleForForm(changeDetails, modules, currentActivity);
    }
  }

  private getContextTitleForTable(changeDetails: any, modules: Module[] | undefined, currentActivity: Activity | undefined) {
    const tableId = changeDetails.tableId ?? changeDetails.tableIds[0];
    modules?.forEach((module: Module) => module.items.forEach((item: ModuleItem) => {
      if('tableId' in item && item.tableId === tableId) {
        if(!changeDetails.hasOwnProperty('columnValues')) {
          this.getContextTitleForRows(item, changeDetails, currentActivity, module)
        }
        else {
          this.getContextTitleForCellValueChange(item, changeDetails, currentActivity, module);
        }
      }
    }));
  }

  private getContextTitleForCellValueChange(item: Table, changeDetails: any, currentActivity: Activity | undefined, module: Module) {
    if (changeDetails.columnValues.length > 1 || changeDetails.rowIds.length > 1) {
      this.changeReasonContextTitle = `${currentActivity?.itemTitle} > ${module.moduleLabel} > ${item.itemTitle}`;
      this.contextPath = [currentActivity?.activityId ?? '', module.moduleId, item.tableId];
    }
    else {
      const column = this.getColumnDetails(item, changeDetails.columnValues[0].propertyName);
      const rowNumber = this.getRowNumber(item, changeDetails.rowIds[0]);
      this.changeReasonContextTitle = `${currentActivity?.itemTitle} > ${module.moduleLabel} > ${item.itemTitle} > Row Number ${rowNumber} > ${column?.label}`;
      this.contextPath = [currentActivity?.activityId ?? '', module.moduleId, item.tableId, changeDetails.rowIds[0], changeDetails.columnValues[0].propertyName];
    }
  }

  private getContextTitleForRows(item: Table, changeDetails: any, currentActivity: Activity | undefined, module: Module) {
    const rowNumber = this.getRowNumber(item, changeDetails.rowId);
    this.changeReasonContextTitle = `${currentActivity?.itemTitle} > ${module.moduleLabel} > ${item.itemTitle} > Row Number ${rowNumber}`;
    this.contextPath = [currentActivity?.activityId ?? '', module.moduleId, item.tableId, changeDetails.rowId];
  }

  private getRowNumber(table: Table, rowId: string) {
    const index = table.value.findIndex((row: TableValueRow) => row.id === rowId);
    return (index + 1).toString();
  }

  private getColumnDetails(table: Table, fieldName: string) {
    return table.columnDefinitions.find((colDef: ColumnSpecification) => colDef.field === fieldName);
  }

  private getContextTitleForForm(changeDetails: any, modules: Module[] | undefined, currentActivity: Activity | undefined) {
    modules?.forEach((module: Module) => {
      module.items.forEach((item: ModuleItem) => {
        if('formId' in item && item.formId === changeDetails.formId) {
          const fieldName = this.getFieldName(item, last(changeDetails.path) ?? '');
          this.changeReasonContextTitle = `${currentActivity?.itemTitle} > ${module.moduleLabel} > ${item.itemTitle} > ${fieldName}`;
          this.contextPath = [changeDetails.activityId, module.moduleId, changeDetails.formId, fieldName];
        }
      })
    });
  }

  private getFieldName(form: Form, fieldName: string): string {
    for(const formItem of form.fieldDefinitions) {
      if(formItem.itemType === FormItemType.Field) {
        if(formItem.field === fieldName) {
          return (formItem as FieldDefinitionResponse).label;
        }
      }
      else {
        return this.getFieldNameFromFieldGroup(formItem as FieldGroupResponse, fieldName);
      }
    }
    return '';
  }

  private getFieldNameFromFieldGroup(formItem: FieldGroupResponse, fieldName: string): string {
    for(const item of formItem.fieldDefinitions) {
      if(item.itemType === FormItemType.Field) {
        if(item.field === fieldName) {
          return (item as FieldDefinitionResponse).label;
        }
      }
      else {
        return this.getFieldNameFromFieldGroup(item as FieldGroupResponse, fieldName);
      }
    }
    return '';
  }

  setNewValue(changeDetails: any) {
    this.changeDetails = changeDetails;
    if(changeDetails.hasOwnProperty('formId')) {
      this.setNewValueForFormField(changeDetails)
    }
    else if ( changeDetails.hasOwnProperty('tableIds') ) {
      this.setNewValueForTableCell(changeDetails);
    }
  }

  private setNewValueForTableCell(changeDetails: any) {
    if (changeDetails.columnValues[0].propertyValue.state === ValueState.NotApplicable) {
      this.newValue = NA;
    }
    else if (changeDetails.columnValues[0].propertyValue.state === ValueState.Set) {
      this.newValue = this.getNewValue(changeDetails.columnValues[0].propertyValue);
    }
    else if (changeDetails.columnValues[0].propertyValue.state === ValueState.Empty) {
      this.newValue = '';
    }
  }

  private setNewValueForFormField(changeDetails: any) {
    if (changeDetails.newValue.state === ValueState.NotApplicable) {
      this.newValue = NA;
    }
    else if (changeDetails.newValue.state=== ValueState.Set) {
      this.newValue = this.getNewValue(changeDetails.newValue);
    }
    else if (changeDetails.newValue.state === ValueState.Empty) {
      this.newValue = '';
    }
  }

  private getNewValue(changeDetails: any): string {
    if (changeDetails.type === ValueType.Instant) {
      return formatInstant(changeDetails.value, DateAndInstantFormat.dateTimeToSecond);
    }
    else if (changeDetails.type === ValueType.Specification) {
      return this.getSpecificationValue(changeDetails);
    }
    else if (changeDetails.type === ValueType.LocalDate) {
      return formatLocalDate(changeDetails.value);
    }
    else if (changeDetails.type === ValueType.StringArray) {
      return changeDetails.value.toString();
    }
    else if (changeDetails.type === ValueType.Number) {
      const unit = this.unitLoaderService.allUnits.find((unit) => unit.id === changeDetails.unit)?.abbreviation;
      return `${changeDetails.value} ${unit}`;
    }
    else {
      return changeDetails.value;
    }
  }

  setOldValue() {
    if(ChangeReasonService.oldValue === undefined) return;
    if(typeof ChangeReasonService.oldValue === 'string') return;
    if(ChangeReasonService.oldValue.state === ValueState.NotApplicable) {
      ChangeReasonService.oldValue = NA
    }
    else if (ChangeReasonService.oldValue.type === ValueType.Instant) {
      ChangeReasonService.oldValue = formatInstant(ChangeReasonService.oldValue.value, DateAndInstantFormat.dateTimeToSecond);
    }
    else if (ChangeReasonService.oldValue.type === ValueType.Specification) {
      this.getSpecificationValue(ChangeReasonService.oldValue);
    }
    else if (ChangeReasonService.oldValue.type === ValueType.LocalDate) {
      ChangeReasonService.oldValue = formatLocalDate(ChangeReasonService.oldValue.value);
    }
    else if (ChangeReasonService.oldValue.type === ValueType.Number) {
      const unit = this.unitLoaderService.allUnits.find((unit) => unit.id === ChangeReasonService.oldValue.unit)?.abbreviation;
      const value = `${ChangeReasonService.oldValue.value} ${unit}`;
      ChangeReasonService.oldValue = value;
    }
    else if (
      ChangeReasonService.oldValue.type === ValueType.String ||
      ChangeReasonService.oldValue.type === ValueType.StringArray
    ) {
      ChangeReasonService.oldValue = ChangeReasonService.oldValue.value;
    }
    else {
      ChangeReasonService.oldValue = ChangeReasonService.oldValue.toString();
    }
  }

  private getSpecificationValue(changeDetails: any): string {
    if (changeDetails.specType === SpecType.SingleValue) {
      return (this.specificationService.getSingleValueDisplayString(changeDetails));
    }
    else if (changeDetails.specType === SpecType.SingleValueRange) {
      return (this.specificationService.getSingleValueRangeDisplayString(changeDetails));
    }
    else if (changeDetails.specType === SpecType.TwoValueRange) {
      return (this.specificationService.getTwoValueRangeDisplayString(changeDetails));
    }
    else
      return changeDetails.value?.value ?? changeDetails.value;
  }

  saveChangeReason(index: number, changeReasonText: string, isClientFacingReason: boolean, nodeId: string) {
    const addChangeReasonCommand: AddChangeReasonCommand = {
      changeReasonCategory: isClientFacingReason ? 'ClientFacingReason' : 'PredefinedReason ',
      changeReasonDetails: changeReasonText,
      experimentId: this.experimentService.currentExperiment?.id ?? '',
      activityId: this.experimentService.currentActivityId,
      nodeId: nodeId,
      changeReasonId: ChangeReasonService.changeReasonId,
      index: index
    }
    this.experimentEventsService.experimentEventsChangeReasonEventsAddPost$Json({
      body: addChangeReasonCommand
    }).subscribe({
      next: (result) => {
        ChangeReasonService.oldValue = '';
        ChangeReasonService.changeReasonId = result.changeReasonAddedEventNotification.changeReasonId;
      }
    });
    this.addChangeReasonNodeToCurrentExperiment(addChangeReasonCommand);
    this.auditHistoryEventsService.updateAuditHistoryDataSource.next();
    this.experimentService.changeReasonSliderDisplayDetails.next({
      isVisible: false,
      oldValue: '',
      newValue: '',
      contextTitle: '',
      showCloseIcon: false,
      nodeId: '',
      showChangedValues: true
    });
  }

  addChangeReasonNodeToCurrentExperiment(addChangeReasonCommand: AddChangeReasonCommand) {
    const activityChangeReasonNode = this.experimentService.currentExperiment?.activityChangeReasonNodes?.find(n => n.activityId === this.experimentService.currentActivityId);
    if(!activityChangeReasonNode) {
      const changeReasonId = addChangeReasonCommand.changeReasonId;
      this.experimentService.currentExperiment?.activityChangeReasonNodes?.push({
        _ts: 0,
        activityId: addChangeReasonCommand.activityId,
        changeReasons: [{
          changeReasonCategory: addChangeReasonCommand.changeReasonCategory,
          changeReasonDetails: addChangeReasonCommand.changeReasonDetails,
          changeReasonId: changeReasonId,
          index: 0,
          nodeId: addChangeReasonCommand.nodeId
        }],
        experimentId: this.experimentService.currentExperiment.id,
        id: '',
        itemType: NodeType.ChangeReason,
        changeReasonAvailabilityMapping: {
          changeReasonId: {
            isMapped: true,
            contextPath: []
          }
        }
      },
      );
      return;
    }
    if (activityChangeReasonNode.changeReasonAvailabilityMapping[addChangeReasonCommand.changeReasonId])
      activityChangeReasonNode.changeReasonAvailabilityMapping[addChangeReasonCommand.changeReasonId].isMapped = true;
    activityChangeReasonNode.changeReasons?.push({
      changeReasonCategory: addChangeReasonCommand.changeReasonCategory,
      changeReasonDetails: addChangeReasonCommand.changeReasonDetails,
      changeReasonId: addChangeReasonCommand.changeReasonId,
      nodeId: addChangeReasonCommand.nodeId,
      index: addChangeReasonCommand.index
    });
  }

  triggerSliderVisibility(isVisible: boolean, nodeId?: string, oldValue?: string, newValue?: string, contextTitle?: string, showCloseIcon = false) {
    this.experimentService.changeReasonSliderDisplayDetails.next({
      isVisible: isVisible,
      oldValue: oldValue ?? ChangeReasonService.oldValue,
      newValue: newValue ?? this.newValue,
      contextTitle: contextTitle ?? this.changeReasonContextTitle,
      showCloseIcon: showCloseIcon,
      nodeId: nodeId ?? this.getNodeId(this.changeDetails),
      showChangedValues: ChangeReasonService.oldValue !== undefined
    });
  }

  private getNodeId(changeDetails: any) {
    let nodeId = '';
    if(changeDetails.hasOwnProperty('tableIds')) {
      nodeId = changeDetails.tableIds[0];
    }
    else if(changeDetails.hasOwnProperty('tableId')) {
      nodeId = changeDetails.tableId;
    }
    else if(changeDetails.hasOwnProperty('formId')) {
      nodeId = changeDetails.formId;
    }
    return nodeId;
  }
}
