<div class="prompt-layout-content">
  <bpt-slider
    id="elnCreatePromptSlider"
    [(visible)]="sliderOptions.visible"
    [closeOnEscape]="sliderOptions.closeOnEscape"
    [headerText]="updateButton ? sliderOptions.sliderHeaderTextForEdit() : sliderOptions.sliderHeaderText()"
    [size]="sliderOptions.size"
    [isFooterSticky]="sliderOptions.isFooterSticky"
    [displayFooter]="sliderOptions.displayFooter"
    [displayFooterWithPrimaryButton]="sliderOptions.displayFooterWithPrimaryButton"
    [displayFooterWithSecondaryButton]="sliderOptions.displayFooterWithSecondaryButton"
    [isModal]="sliderOptions.isModal"
    [sliderClosing]="sliderClosing"
    (visibleChange)="sliderVisibleChange($event)">
    <div class="slider-content pt-3">
      <ng-container *ngTemplateOutlet="formField"></ng-container>
    </div>
    <div class="slider-sticky-footer" *ngIf="sliderOptions.isFooterSticky">
      <div class="buttons flex  gap-4">
        <p-button
          class="cancel"
          styleClass="p-button-outlined"
          pRipple
          type="button"
          i18n-label="@@cancel"
          label="Cancel"
          (onClick)="resetPropertyChanges()"
          id="eln-btnCreatePromptCancel"></p-button>
        <p-button
          *ngIf="!updateButton"
          class="submit"
          type="submit"
          styleClass="p-button"
          i18n-label="@@createPrompt"
          label="Create prompt"
          (onClick)="createPrompt()"
          [disabled]="!isPromptDataChanged() || !isRequiredDataCorrect()"
          id="eln-btnCreatePromptSubmit"></p-button>
          <p-button
          *ngIf="updateButton"
          styleClass="p-button"
          i18n-label="@@updatePrompt"
          (onClick)="updatePrompt()"
          [disabled]="!isPromptDataChanged() || !isRequiredDataCorrect()"
          label="Update prompt"
          id="eln-btnUpdatePrompt"></p-button>
      </div>
    </div>

  </bpt-slider>
</div>

<ng-template #formField>
  <div class="grid ">
    <div class="col col-6 mb-2" *ngFor="let prop of fieldNames()">
      <bpt-text-input
        *ngIf="prop !== 'clientFacingPrompt'"
        inputId="eln-createPrompt{{prop}}"
        label="{{prepareLabel(prop)}}"
        [ngModel]="formFieldProperties[prop]"
        (ngModelChange)="handleModelChange($event,prop)"
        [required]="isRequired(prop)"
        [errorFlag]="isRequired(prop) && (formFieldProperties[prop] === '' || !formFieldProperties[prop])"
        [errorMessage]="requiredFieldMessage"></bpt-text-input>
        <bpt-text-input
        *ngIf="prop === 'clientFacingPrompt'"
        [(ngModel)]="formFieldProperties['clientFacingPrompt']"
        [label]="sliderLabelManager.clientFacingPrompt"></bpt-text-input>
    </div>
  </div>
</ng-template>