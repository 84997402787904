import { ClientStatePermission, ElimsClientStatePermission } from 'model/client-state-permission.interface';
import { ClientState } from './client-state.interface';

export class ELimsClientState implements ClientState {
    elimsMaster: any;
    clientStatePermissions: ElimsClientStatePermission[] = [];
    
    constructor(master: any) {
        this.elimsMaster = master;
    }

    async getClientStatePermissions(): Promise<ClientStatePermission[]> {
        await this.getData();
        return Promise.resolve(this.clientStatePermissions
            .filter((data: ElimsClientStatePermission) => data.ClientState.startsWith('eln.'))
            .map((data: ElimsClientStatePermission) => {
                return {
                    clientState: data.ClientState,
                    dataState: data.DataState,
                    visibility: data.Visibility,
                    featureFlags: data.FeatureFlags,
                } as ClientStatePermission;
            })
        );
    }

    async getData(): Promise<ClientStatePermission[]> {
        var clientState = new this.elimsMaster.ClientStatePermission();
        return clientState.Initialize().then((data: ElimsClientStatePermission[]) => {
            this.clientStatePermissions = data;
        });
    }
}
