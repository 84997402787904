import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BptGridComponent, BptGridMode, ColumnDefinition } from 'bpt-ui-library/bpt-grid';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ActivityInputMaintenanceEvent } from '../../model/activity-input-maintenance-event';
import { InstrumentEventHelper } from '../instrument-event/instrument-event-helper';

@Component({
  selector: 'app-maintenance-event-table',
  templateUrl: './activity-input-maintenance-event-table.component.html',
  styleUrls: ['./activity-input-maintenance-event-table.component.scss']
})
export class ActivityInputMaintenanceEventComponent implements OnInit, OnDestroy {
  columnDefinitions: ColumnDefinition[] = [];
  data: any[] = [];
  gridMode = BptGridMode.dataEntry;
  maintenanceEventTableId: string = 'MaintenanceEventId';
  rowSelection = 'single';
  disableActionButton = true;
  maintenanceEvents: ActivityInputMaintenanceEvent[] = [];
  selectedEvent?: ActivityInputMaintenanceEvent;
  eventSelection = false;
  @ViewChild('MaintenanceEventGrid') maintenanceEventGrid!: BptGridComponent;

  constructor(
    private readonly dynamicDialogConfig: DynamicDialogConfig,
    private readonly dynamicDialogRef: DynamicDialogRef,
  ) { }
  ngOnInit(): void {
    this.maintenanceEvents = this.dynamicDialogConfig.data as ActivityInputMaintenanceEvent[];
    this.prepareColumns();
  }

  onSelected($event: any) {
    if ($event.length === 0) {
      this.disableActionButton = true;
      this.eventSelection = false;
    } else {
      this.selectedEvent = $event[0];
      this.eventSelection = !this.selectedEvent?.isSelected ? true : false;
      this.disableActionButton = false;
    }
  }

  ngOnDestroy(): void {
    if (this.dynamicDialogRef) {
      let result = {};
      result = {
        event: this.selectedEvent,
        eventSelected: this.eventSelection
      };
      this.dynamicDialogRef.close(result);
    }
  }

  private prepareColumns() {
    this.columnDefinitions = [
      {
        field: 'checkbox',
        label: '',
        width: 5,
        editable: false,
        checkboxSelection: this.updateData,
      },
      {
        field: 'nameDescription',
        label: $localize`:@@NameDescription:Name/Description`,
        width: 300,
        sortable: true,
        editable: false,
        columnType: 'string'
      },
      {
        field: 'activityType',
        label: $localize`:@@ActivityType:Activity Type`,
        width: 140,
        editable: false,
        filterParams: {
          filterOptions: ['contains'],
          suppressAndOrCondition: true
        },
        lockVisible: true
      },
      {
        field: 'interval',
        label: $localize`:@@Interval:Interval`,
        width: 150,
        editable: false,
        columnType: 'string'
      },
      {
        field: 'dueDate',
        label: $localize`:@@DueDate:Due Date`,
        width: 150,
        sortable: true,
        editable: false,
        columnType: 'date'
      }
    ];
  }

  updateData = (params: any) => {
    params.api.forEachNode(
      (node: { data: { isSelected: any }; setSelected: (arg0: boolean) => void }) => {
        if (node.data.isSelected === true) node.setSelected(true);
      }
    );
    return true;
  };

  saveInstrumentEvent(): void {
    if (this.selectedEvent && !this.disableActionButton) {
      InstrumentEventHelper.instrumentEventSelectionSaved.next(this.selectedEvent);
      this.eventSelection = false;
    }
  }

  cancelSelection(): void {
    if (this.disableActionButton && this.selectedEvent) {
      this.selectedEvent.isSelected = true;
    }
    if (!this.selectedEvent?.isSelected) {
      this.eventSelection = true;
    }
    InstrumentEventHelper.instrumentEventSelectionClosed.next(this.selectedEvent!);
  }
}
