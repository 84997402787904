<div class="eln-child-table-container">
  <bpt-grid #Grid
    class="eln-grid"
    ngClass="true"
    [columnDefinitions]="columnDefinitions"
    [dataSource]="data"
    [useJsJodaTypes]="true"
    gridId="{{testTableId}}"
    [gridMode]="gridMode"
    [allowRowAdd]="false"
    [showAutoSizeButton]="false"
    [showFilterToggleButton]="false"
    [showGridOptionsButton]="false"
    [rowSelection]="rowSelection"
    (selectionChanged)="onSelected($event)">
  </bpt-grid>
  <div class="select-aliquot-tests-buttons-align">
    <p-button
      styleClass="eln-standard-popup-button p-button-outlined align-button eln-instrument-selection-cancel-btn"
      i18n-label="@@N/A"
      label="N/A"
      id="eln-aliquotTableNASelection"
      (click)="clearSelection()"></p-button>
    <p-button
      styleClass="eln-standard-popup-button p-button-outlined align-button"
      i18n-label="@@Cancel"
      label="Cancel"
      id="eln-aliquotTableCancelSelection"
      (click)="cancelTestsSelection()"></p-button>
    <p-button
      styleClass="eln-standard-popup-button"
      id="eln-aliquotTableSaveSelection"
      icon="pi pi-check"
      i18n-label="@@commit"
      [disabled]="!checkForUnsavedChanges()"
      label="Commit"
      [ngStyle]="{ 'pointer-events': !checkForUnsavedChanges() ? 'none' : 'auto' }"
      (click)="saveAliquotTests()"></p-button>
  </div>
</div>