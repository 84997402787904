import { User } from 'model/user.interface';
import { UserData } from './user-data.interface';

export class ELimsUserData implements UserData {
  elimsMaster: any;
  app: any;
  clientStatePermissions: any = [];

  constructor(master: any, app: any) {
    this.elimsMaster = master;
    this.app = app;
  }

  getCurrentUser(): User {
    return {
      puid: (this.elimsMaster.currentUser.Puid as string)?.toUpperCase(),
      displayValue: this.elimsMaster.currentUser.DisplayValue,
      labSiteCode: this.elimsMaster.currentUser.DefaultLabSiteCode,
      labSiteName: this.elimsMaster.currentUser.DefaultLabSiteName,
      labSitePrefix: this.elimsMaster.currentUser.LabSitePrefix,
      labSiteCodes: this.elimsMaster.currentUser.LabSiteCodes,
      firstName: this.elimsMaster.currentUser.FirstName,
      lastName: this.elimsMaster.currentUser.LastName,
      primarySubBusinessUnit: this.elimsMaster.currentUser.PrimarySubBU
    };
  }

  getOauthToken(url: string): Promise<string> {
    return new this.elimsMaster.OauthTokenProvider().getOauthToken(url);
  }

  getUserLabSiteTimeZone(): string {
    //eLIMS's time zone identifiers are just a mangling of IANA-compatible ones. They replace / with __.
    return this.app.DefaultLabSiteTimeZone;
  }
}
