import { Component } from '@angular/core';
import { ElnProgressSpinnerService } from '../eln-progress-spinner.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'eln-progress-spinner',
  templateUrl: './eln-progress-spinner-component.component.html'
})
export class ElnProgressSpinnerComponentComponent {
  constructor(public readonly progressSpinnerService: ElnProgressSpinnerService) {}
}
