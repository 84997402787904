import { Component, Input, OnInit } from '@angular/core';
import { ClientValidationDetails } from '../../model/client-validation-details';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-warning-dialog-box',
  templateUrl: './warning-Dialog-Box.component.html',
  styleUrls: ['./warning-Dialog-Box.component.scss']
})
export class WarningDialogBoxComponent implements OnInit {
  @Input() validation!: Partial<ClientValidationDetails>;
  hideDetails = true;
  expandStyle = $localize`:@seeMore:See more`;
  isReviewer = false;
  isSupervisor = false;
  isAnalyst = false;
  constructor(
    private readonly userService: UserService
  ) { }

  ngOnInit(): void {

    this.isReviewer = this.userService.hasReviewerRights(this.userService.currentUser.puid);
    if (!this.validation) {
      this.setCollaboratorWarningMessages();
    }
  }

  toggleDetails() {
    this.hideDetails = !this.hideDetails;
    this.expandStyle = this.hideDetails ? $localize`:@seeMore:See more` : $localize`:@seeLess:See less`;
  }

  private setCollaboratorWarningMessages() {
    this.validation = new ClientValidationDetails();
    this.validation.warnings!.push(
      $localize`:@@editingCoverPage:Editing cover page fields`,
      $localize`:@@internalComments:Internal comments`,
      $localize`:@@transitioningToInCorrection:Transitioning to In Correction`,
      $localize`:@@transitioningToAuthorized:Transitioning to Authorized`,
      $localize`:@@transitioningFromAuthorizedToInCorrection:Transitioning from Authorized to In Correction`,
      $localize`:@@transitioningToFromCancelled:Transitioning to/from Cancelled`,
      $localize`:@@updatePreparationStatus:Updating the Status of Preparations`,
      $localize`:@@attachFiles:Attach files`,
      $localize`:@@statementsWitnessing:Statements and Witnessing`,
      $localize`:@@labItemPrepRefreshed:Lab Item Preparations Refresh`
    );

    // This displays as intended in UI despite being multiline here
    this.validation.warningTitle = $localize`:@@collaboratorWarningBanner:You have not performed any collaborator actions for this experiment yet and may still review and authorize the experiment.The following functions may be used without becoming a collaborator`;
  }

}
