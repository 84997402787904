import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-file-type-icon-renderer',
  template: `
    <span class ="icon-alignment"
     [innerHTML]="iconClass"></span> &nbsp;{{ params.value }}
  `,
  styles: [`.icon-alignment {
    vertical-align: middle;
    }
  `]
})

export class FileTypeIconRendererComponent implements ICellRendererAngularComp {
  public params!: any;
  public iconClass!: string;

  agInit(params: any): void {
    this.params = params;
    this.iconClass = this.getIconPath();
  }

  public iconMappings: { [key: string]: string } = {
    'doc': `<span class="icon-doc icon-l"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span></span>`,
    'xml': `<span class="icon-xml icon-l"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span></span>`,
    'pdf': `<span class="icon-pdf icon-l"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span></span>`,
    'docx': `<span class="icon-docx icon-l"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span></span>`,
    'docm': `<span class="icon-docx icon-l"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span></span>`,
    'dot': `<span class="icon-docx icon-l"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span></span>`,
    'xls': `<span class="icon-xls icon-l"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span></span>`,
    'xltx': `<span class="icon-xls icon-l"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span></span>`,
    'xlsb': `<span class="icon-xls icon-l"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span></span>`,
    'xlsm': `<span class="icon-xls icon-l"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span></span>`,
    'xlsx': `<span class="icon-xls icon-l"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span></span>`,
    'csv': `<span class="icon-csv icon-l"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span></span>`,
    'txt': `<span class="icon-txt icon-l"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span></span>`,
    'html': `<span class="icon-html icon-l"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span></span>`,
    'png': `<span class="icon-png icon-l"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span></span>`,
    'jpg': `<span class="icon-jpg icon-l"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span></span>`,
    'jpeg': `<span class="icon-jpg icon-l"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span></span>`,
    'msg': `<span class="icon-msg icon-l"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span></span>`,
    'ppt': `<span class="icon-ppt icon-l"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span></span>`,
    'zip': `<span class="icon-zip icon-l"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span></span>`,
    'svg': `<span class="icon-jpg icon-l"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span></span>`,
    'jfif': `<span class="icon-jpg icon-l"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span></span>`,
    'pjpeg': `<span class="icon-jpg icon-l"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span></span>`,
    'bmp': `<span class="icon-jpg icon-l"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span></span>`,
    'pjp': `<span class="icon-jpg icon-l"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span></span>`,
    'gif': `<span class="icon-jpg icon-l"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span></span>`
  }

  getIconPath(): string {
    const fileExtension = this.getFileExtension();
    return this.iconMappings[fileExtension];
  }

  private getFileExtension(): string {
    const fileName = this.params.value;
    return fileName.split('.').pop().toLowerCase();
  }

  refresh(): boolean {
    return false;
  }
}