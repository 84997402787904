import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { LimsLinkoutService } from '../../../integrations/linkout/lims-linkout.service';

@Component({
  selector: 'app-link-out-renderer',
  styles: [`.eln-aliquot-linkout{color: #337ab7; cursor: pointer;}`],
  template: `
    <span class="fas fa-external-link-alt eln-aliquot-linkout" (click)="linkClicked()"></span>&nbsp;{{ params.value }}
  `
})
export class LinkOutCellRendererComponent implements ICellRendererAngularComp {
  public params!: any;

  constructor(private readonly limsLinkoutService: LimsLinkoutService) {}

  agInit(params: any): void {
    this.params = params;
  }

  refresh(params: any) {
    return false;
  }

  linkClicked() {
    if (this.params.data.sampleNumber) {
      window.open(this.limsLinkoutService.getSampleRoute(this.params.data.sampleNumber), '_blank');
      return;
    }
    if (this.params.data.code) {
      const materialCode = this.params.data.code.split(':')[0];
      window.open(this.limsLinkoutService.getMaterialRoute(materialCode), '_blank');
    }
  }
}
