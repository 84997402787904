<div class="container-internal-information-slider">
  <bpt-slider
  id="preparation-internal-information-slider"
  [(visible)]="sliderOptions.visible"
  [closeOnEscape]="sliderOptions.closeOnEscape"
  [headerText]="sliderOptions.headerText"
  [size]="sliderOptions.size"
  [displayFooter]="sliderOptions.displayFooter"
  [isFooterSticky]="sliderOptions.isFooterSticky"
  [displayFooterWithPrimaryButton]="sliderOptions.displayFooterWithPrimaryButton"
  [displayFooterWithSecondaryButton]="sliderOptions.displayFooterWithSecondaryButton"
  [isModal]="sliderOptions.isModal"
  [primaryButtonLabel]="sliderOptions.primaryButtonLabel"
  [secondaryButtonLabel]="sliderOptions.secondaryButtonLabel"
  [sliderClosing]="sliderClosing"
  [isPrimaryButtonDisabled]="isReadOnlySlider"
  (visibleChange)="sliderVisibleChange($event)"
  (footerPrimaryButtonClick)="commitValues()"
  (footerSecondaryButtonClick)="onSliderClose()"
  >
  <div class="internal-information-form-fields flex flex-column pt-2 row-gap-5 slider-content">
    <div class="grid">
      <div class="col-6 p-3">
        <bpt-dropdown
        id="eln-preparation-int-info-disposal"
        [editable]="true"
        label="Disposal"
        i18n-label="@@preparationInternalInformationDisposal"
        [showClear]="dropdownOptions.showClear"
        [showFilter]="dropdownOptions.showFilter"
        [multiSelect]="dropdownOptions.multiSelect"
        [options]="dropdownPicklists.disposal"
        [ngModel]="preparationDisplayModel.disposal"
        (ngModelChange)="modelChanged($event.trim(),'disposal')"
        [ngModelOptions]="{ updateOn: 'blur' }"
        valueField="value"
        labelField="label"
        [readOnly]="isReadOnlySlider"
        ></bpt-dropdown>
      </div>

      <div class="col-6 p-3">
        <bpt-text-input
        id="eln-preparation-int-info-storage-location"
        i18n-label="@@preparationInternalInformationStorageLocation"
        label="Storage Location"
        [ngModel]="preparationDisplayModel.storageLocation"
        (ngModelChange)="modelChanged($event.trim(),'storageLocation')"
        [ngModelOptions]="{ updateOn: 'blur' }"
        [readOnly]="isParentRecipe || isReadOnlySlider"
        ></bpt-text-input>
      </div>

      <div class="col-12">
        <div class="eln-flex flex-two-column-layout grid">
          <div class="eln-flex-item col-6 p-3">
            <bpt-quantity
            id="eln-preparation-int-info-stability"
            label="Stability"
            i18n-label="@@preparationInternalInformationStability"
            name="stability"
            [commitValueOnEnter]="quantityOptions.commitValueOnEnter"
            ngDefaultControl
            [allowedUnits]="stabilityUnits"
            [cancelValueOnEscape]="quantityOptions.cancelValueOnEscape"
            [ngModel]="preparationDisplayModel.stability"
            [ngModelOptions]="{ updateOn: 'change' }"
            [allowNA]="quantityOptions.allowNA"
            [allowDecimal]="quantityOptions.allowDecimal"
            [enableSignificantFigures]="quantityOptions.enableSignificantFigures"
            [highlightAllOnFocus]="quantityOptions.highlightAllOnFocus"
            [suppressContextMenu]="quantityOptions.suppressContextMenu"
            [tabIndex]=3
            [readOnly]="isReadOnlySlider"
            (quantityChange)="quantityChanged($event, 'stability')">
            </bpt-quantity>
          </div>
          <div class="eln-flex-item col-6 p-3">
            <bpt-quantity
            id="eln-preparation-int-info-original-quantity"
            label="Original Quantity"
            i18n-label="@@preparationInternalInformationOriginalQuantity"
            name="original quantity"
            [commitValueOnEnter]="quantityOptions.commitValueOnEnter"
            [allowedUnits]="originalQuantityUnits"
            [enableSignificantFigures]="quantityOptions.enableSignificantFigures"
            [cancelValueOnEscape]="quantityOptions.cancelValueOnEscape"
            [ngModel]="preparationDisplayModel.originalQuantity"
            [ngModelOptions]="{ updateOn: 'change' }"
            (quantityChange)="quantityChanged($event, 'originalQuantity')"
            [readOnly]="isReadOnlySlider"
            ></bpt-quantity>
            <div *ngIf="isParentRecipe" class="flex justify-content-start gap-4 mt-3">
              <div class="flex align-items-center justify-content-center">
                <p-checkbox
                  [binary]="true"
                  label="Allow Scaling up"
                  i18n-label="@@allowScalingUp"
                  [disabled]="disableScaling"
                  [(ngModel)]="preparationDisplayModel.allowScalingUp"
                  (ngModelChange)="modelChanged($event,'allowScalingUp')"
                >
                </p-checkbox>
              </div>
              <div class="flex align-items-center justify-content-center">
                <p-checkbox
                  [binary]="true"
                  label="Allow Scaling down"
                  i18n-label="@@allowScalingDown"
                  [disabled]="disableScaling"
                  [(ngModel)]="preparationDisplayModel.allowScalingDown"
                  (ngModelChange)="modelChanged($event,'allowScalingDown')"
                >
                </p-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div class="hazards-info col-6 p-3">
        <bpt-text-input
        class="hazards w-full"
        id="eln-preparation-int-info-hazard"
        i18n-label="@@preparationInternalInformationHazards"
        label="Hazards"
        [ngModel]="preparationDisplayModel.hazards"
        (ngModelChange)="modelChanged($event.trim(),'hazards')"
        [ngModelOptions]="{ updateOn: 'blur' }"
        [readOnly]="isReadOnlySlider"
        [multiLine]="true"></bpt-text-input>
      </div>
      <div class="hazards-info col-6 p-3">
        <bpt-text-input
        class="additional-label"
        id="eln-preparation-additional-label"
        i18n-label="@@AdditionalLabelText"
        label="Additional Label Text"
        [ngModel]="preparationDisplayModel.additionalLabel"
        (ngModelChange)="modelChanged($event.trim(),'additionalLabel')"
        [ngModelOptions]="{ updateOn: 'blur' }"
        [readOnly]="isReadOnlySlider"
        [multiLine]="true"></bpt-text-input>
      </div>
    </div>
    <div class="additional-information gap-4">
      <div class="additional-information-header text-sm font-semibold">
        {{additionalInformationHeaderText}}
      </div>
      <div class="grid">
        <div class="col-6 p-3">
          <bpt-text-input
          id="eln-preparation-int-info-analysis"
          i18n-label="@@preparationInternalInformationAnalysis"
          label="Analysis"
          [ngModel]="preparationDisplayModel.analysis"
          (ngModelChange)="modelChanged($event.trim(),'analysis')"
          [readOnly]="isReadOnlySlider"
          [ngModelOptions]="{ updateOn: 'blur' }"></bpt-text-input>
        </div>

        <div class="col-6 p-3">
          <app-sub-business-unit-select
          id="eln-preparation-int-info-sbu"
          i18n-label="@@preparationInternalInformationSubBusinessUnit"
          label="Sub Business Unit"
          [multiSelect]="false"
          [wrap]="false"
          [(value)]="preparationDisplayModel.subBusinessUnit"
          [required]="true"
          [errorFlag]="!isSbuValueSet"
          [errorMessage]="fieldRequiredMessage"
          [showClear]="dropdownOptions.showClear"
          [labsiteCodes]="currentUserLabsites"
          [setPrimarySubBusinessUnit]="false"
          (valueChange)="modelChanged($event, 'subBusinessUnit')"
          [ngModelOptions]="{ updateOn: 'blur' }"
          [disabled]="isReadOnlySlider"
          ></app-sub-business-unit-select>
        </div>

        <div class="col-6 p-3">
          <bpt-dropdown
          id="eln-preparation-int-info-client"
          label="Client"
          i18n-label="@@preparationInternalInformationClient"
          [showClear]="clientDropdownOptions.showClear"
          [options]="dropdownPicklists.client"
          [ngModel]="preparationDisplayModel.client"
          [ngModelOptions]="{ updateOn: 'blur' }"
          (dropdownChange)="modelChanged($event, 'client')"
          (dropdownClear)="clientSelectionCleared()"
          valueField="value"
          labelField="label"
          [readOnly]="isReadOnlySlider"
          ></bpt-dropdown>
        </div>

        <div class="col-6 p-3">
          <bpt-dropdown
          id="eln-preparation-int-info-project"
          label="Project"
          i18n-label="@@preparationInternalInformationProject"
          [ngModel]="preparationDisplayModel.project"
          [disabled]="disableProjects"
          [options]="dropdownPicklists.project"
          (ngModelChange)="modelChanged($event, 'project')"
          [ngModelOptions]="{ updateOn: 'blur' }"
          valueField="value"
          labelField="label"
          [readOnly]="isReadOnlySlider"
          ></bpt-dropdown>
        </div>
        
        <div class="col-6 p-3">
          <bpt-text-input
          class="mb-2"
          id="eln-preparation-int-info-method"
          i18n-label="@@preparationInternalInformationMethod"
          label="Method"
          [ngModel]="preparationDisplayModel.method"
          (ngModelChange)="modelChanged($event.trim(),'method')"
          [readOnly]="isReadOnlySlider"
          [ngModelOptions]="{ updateOn: 'blur' }">
        </bpt-text-input>
        </div>

        <div class="col-6 p-3">
          <app-user-select
          id="eln-preparation-int-info-prepared-by"
          label="Prepared By"
          i18n-label="@@preparationInternalInformationPreparedBy"
          [roles]="['ELN Analyst']"
          [showClear]="dropdownOptions.showClear"
          [(value)]="preparationDisplayModel.preparedBy"
          [ngModelOptions]="{ updateOn: 'blur' }"
          (valueChange)="modelChanged($event, 'preparedBy')"
          (userListFetched)="updateUserNameFields($event)"
          [readOnly]="isParentRecipe || isReadOnlySlider"
          ></app-user-select>
        </div>
        <div class="col-6 p-3">
          <bpt-text-input
          id="eln-preparation-int-info-reviewed-by"
          label="Reviewed By"
          i18n-label="@@preparationInternalInformationReviewedBy"
          [readOnly]="true"
          [ngModel]="preparationDisplayModel.reviewedBy"
          ></bpt-text-input>
        </div>

        <div class="col-6 p-3">
          <app-user-select
          id="eln-preparation-int-info-stored-by"
          label="Stored By"
          i18n-label="@@preparationInternalInformationStoredBy"
          [roles]="['ELN Analyst', 'ELN Reviewer', 'ELN Supervisor']"
          [ngModelOptions]="{ updateOn: 'blur' }"
          [(value)]="preparationDisplayModel.storedBy"
          (valueChange)="modelChanged($event, 'storedBy')"
          [readOnly]="isParentRecipe || isReadOnlySlider"
          ></app-user-select>
        </div>
      </div>
      <div class="discarded-details flex gap-1 align-items-start pb-3">
        <div class="discarded-checkbox flex flex-row gap-1">
          <p-checkbox
          id="eln-preparation-int-info-discarded-checkbox"
          [binary]="true"
          [(ngModel)]="preparationDisplayModel.discardedOrConsumed"
          (onChange)="preparationDiscardedClicked($event)"
          [disabled]="isParentRecipe || isReadOnlySlider"
        >
        </p-checkbox>
        <label class="w-max">{{preparationDisplayModel.discardedOrConsumed ? checkboxOptions.discardedByLabel : checkboxOptions.discardedLabel}}</label>
        </div>
        <div *ngIf="preparationDisplayModel.discardedOrConsumed" id="eln-preparation-int-info-discarded-by">
          {{preparationDisplayModel.discardedOrConsumedBy}} {{onTextValue}} {{preparationDisplayModel.discardedOrConsumedOn}}
        </div>
      </div>
    </div>
  </div>

  </bpt-slider>
</div>
