/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { WitnessingStatementLookupResponse } from '../models/witnessing-statement-lookup-response';
import { witnessStatementsPredefinedLookupsGet$Json } from '../fn/statements/witness-statements-predefined-lookups-get-json';
import { WitnessStatementsPredefinedLookupsGet$Json$Params } from '../fn/statements/witness-statements-predefined-lookups-get-json';
import { witnessStatementsPredefinedLookupsGet$Plain } from '../fn/statements/witness-statements-predefined-lookups-get-plain';
import { WitnessStatementsPredefinedLookupsGet$Plain$Params } from '../fn/statements/witness-statements-predefined-lookups-get-plain';

@Injectable({ providedIn: 'root' })
export class StatementsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `witnessStatementsPredefinedLookupsGet()` */
  static readonly WitnessStatementsPredefinedLookupsGetPath = '/witness-statements/predefined-lookups';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `witnessStatementsPredefinedLookupsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  witnessStatementsPredefinedLookupsGet$Plain$Response(params?: WitnessStatementsPredefinedLookupsGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<WitnessingStatementLookupResponse>> {
    return witnessStatementsPredefinedLookupsGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `witnessStatementsPredefinedLookupsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  witnessStatementsPredefinedLookupsGet$Plain(params?: WitnessStatementsPredefinedLookupsGet$Plain$Params, context?: HttpContext): Observable<WitnessingStatementLookupResponse> {
    return this.witnessStatementsPredefinedLookupsGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<WitnessingStatementLookupResponse>): WitnessingStatementLookupResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `witnessStatementsPredefinedLookupsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  witnessStatementsPredefinedLookupsGet$Json$Response(params?: WitnessStatementsPredefinedLookupsGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<WitnessingStatementLookupResponse>> {
    return witnessStatementsPredefinedLookupsGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `witnessStatementsPredefinedLookupsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  witnessStatementsPredefinedLookupsGet$Json(params?: WitnessStatementsPredefinedLookupsGet$Json$Params, context?: HttpContext): Observable<WitnessingStatementLookupResponse> {
    return this.witnessStatementsPredefinedLookupsGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<WitnessingStatementLookupResponse>): WitnessingStatementLookupResponse => r.body)
    );
  }

}
