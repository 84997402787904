import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  BptGridComponent,
  BptGridMode,
  ColumnDefinition,
  RowSelection,
} from 'bpt-ui-library/bpt-grid';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SampleTests } from '../../model/sample-tests';
import { AliquotTestDomain } from '../../../api/models';
import { ConfirmationService } from 'primeng/api';
import { NA } from 'bpt-ui-library/shared';
import { v4 as uuidV4 } from "uuid";
import { ActivityInputHelper } from '../shared/activity-input-helper';

@Component({
  selector: 'app-test-table',
  templateUrl: './activity-input-test-table.component.html',
  styleUrls: ['./activity-input-test-table.component.scss']
})
export class ActivityInputTestTableComponent implements OnInit, OnDestroy {
  columnDefinitions: ColumnDefinition[] = [];
  data: any[] = [];
  gridMode = BptGridMode.dataEntry;
  testTableId: string = 'TestId';
  rowSelection: RowSelection = 'multiple';
  event!: AliquotTestDomain[];
  @ViewChild('Grid') grid!: BptGridComponent;
  testWithSelectedData!: SampleTests;
  checkForWarning = true;

  constructor(
    private readonly dynamicDialogConfig: DynamicDialogConfig,
    private readonly dynamicDialogRef: DynamicDialogRef,
    private readonly confirmationService: ConfirmationService,
    private readonly activityInputHelper: ActivityInputHelper
  ) {}

  ngOnInit(): void {
     this.testWithSelectedData = this.dynamicDialogConfig.data as SampleTests;
     this.data = this.testWithSelectedData.Tests;
     this.event = this.testWithSelectedData.Tests.filter(t => this.testWithSelectedData.TestIds.indexOf(t.testId) > -1);
    this.prepareColumns();
  }

  private prepareColumns() {
    this.columnDefinitions = [
      {
        field: 'checkbox',
        label: '',
        checkboxSelection: this.updateData,
        width: 5,
        editable: false
      },
      {
        field: 'testId',
        label: $localize`:@@TestId:Test Id`,
        width: 10,
        editable: false,
        columnType: 'string',
        hidden: true
      },
      {
        field: 'testStatus',
        label: $localize`:@@TestStatus:Test Status`,
        width: 110,
        sortable: true,
        editable: false,
        columnType: 'string'
      },
      {
        field: 'testReportableName',
        label: $localize`:@@TestReportableName:Test Reportable Name`,
        width: 280,
        editable: false,
        filterParams: {
          filterOptions: ['contains'],
          suppressAndOrCondition: true
        },
        lockVisible: true
      },
      {
        field: 'testName',
        label: $localize`:@@TestName:Test Name`,
        width: 110,
        sortable: true,
        editable: false,
        columnType: 'string'
      },
      {
        field: 'testCode',
        label: $localize`:@@TestCode:Test Code`,
        width: 110,
        editable: false,
        columnType: 'string'
      },
      {
        field: 'testKey',
        label: $localize`:@@TestKey:Test Key`,
        width: 110,
        sortable: true,
        editable: false,
        columnType: 'string'
      },
      {
        field: 'testReportableMethodReference',
        label: $localize`:@@TestReportableMethodReference:Reportable Method Reference`,
        width: 250,
        sortable: true,
        columnType: 'string',
        editable: false
      }
    ];
  }
  updateData = (params: any) => {
    params.api.forEachNode(
      (node: { data: { testId: string }; setSelected: (arg0: boolean) => void }) => {
      if (node.data && this.testWithSelectedData.TestIds.indexOf(node.data.testId) > -1) node.setSelected(true);
      }
    );
    return true;
  };

  onButtonClick = (params: any,isSelected=true) => {
    this.grid?.gridApi?.forEachNode(
      (node: { data: { testId: string }; setSelected: (arg0: boolean) => void }) => {
        if (node.data.testId === params[0].testId) node.setSelected(isSelected);
      }
    );
  };

  saveAliquotTests = () => {
    this.checkForWarning = false;
    const sampleTest: SampleTests = {
      AliquotNumber: this.testWithSelectedData.AliquotNumber,
      Tests: this.event,
      TestIds:[]
    }
    this.activityInputHelper.saveTestSelection.next(sampleTest);
    this.closeModal();
  }

  cancelTestsSelection = () => { 
    const sampleTest: SampleTests = {
      AliquotNumber: this.testWithSelectedData.AliquotNumber,
      Tests: this.event,
      TestIds:[]
    }
    if(this.checkForUnsavedChanges())
    {
    this.confirmEventSelectionClose(sampleTest);
    }
    else {
      this.closeModal();
    }
  }

  closeModal = () => {
    if(this.dynamicDialogRef)
    {
      this.dynamicDialogRef.close();
    }
  }

  clearSelection = () =>{
    this.checkForWarning = false;
    const sampleTest: SampleTests = {
      AliquotNumber: this.testWithSelectedData.AliquotNumber,
      Tests: [{
        testId:uuidV4(),
        testCode: NA,
        testKey:NA,
        testName: NA,
        testReportableMethodReference:NA,
        testReportableName:NA,
        testStatus: NA,
        isInProgress: false
      }],
      TestIds:[]
    }
    this.activityInputHelper.saveTestSelection.next(sampleTest);
    this.closeModal();
  }

  checkForUnsavedChanges = () => {
    this.event = this.event ?? [];    
    const allSelectedTestIds = this.event.map(ev => ev.testId) ?? [];
    const alreadySavedTestIds = this.testWithSelectedData.TestIds ?? [];
    const selectedTestIds = allSelectedTestIds.filter(function(obj) { 
      return alreadySavedTestIds.indexOf(obj) === -1; 
    });  
    const unselectedTestIds = alreadySavedTestIds.filter(function(obj) { 
      return allSelectedTestIds.indexOf(obj) === -1; 
    });  
    return selectedTestIds.length > 0 || unselectedTestIds.length > 0;
  }

  confirmEventSelectionClose(params: SampleTests) {
    const confirmationMessage = $localize`:@@NavigateAwayConfirmation:You are about to leave this
     page without saving. All changes will be lost. Do you really want to leave without saving?`;
    this.confirmationService.confirm({
      message: `${confirmationMessage}`,
      header: $localize`:@@unsavedChangesConfirmationHeader:Unsaved Changes`,
      acceptVisible: true,
      acceptLabel: $localize`:@@saveChanges:Save changes`,
      rejectVisible: true,
      rejectLabel: $localize`:@@leaveWithoutSaving:Leave without saving`,
      dismissableMask: false,
      accept: () => {
        this.activityInputHelper.saveTestSelection.next(params);
        this.closeModal();
      },
      reject: () => {
        this.closeModal();
      }
    });
  }

  onSelected($event: AliquotTestDomain[]) {
    const previouslySelectedTestIds = this.event.map(ev => ev.testId);
    const selectedTestIds = $event.map(e => e.testId);
    this.event = $event;    
    const selectedTestId = selectedTestIds.filter(function(obj) { 
      return previouslySelectedTestIds.indexOf(obj) === -1; 
    });
    const selectedTest = $event.filter(k => k.testId === selectedTestId[0]);
    const alreadySelectedTests = this.testWithSelectedData.Tests?.filter(k => this.testWithSelectedData.TestIds?.indexOf(k.testId) > -1); 

    if (!!selectedTest[0] && selectedTest[0].testId !== '' 
    && selectedTest[0].isInProgress 
    && alreadySelectedTests?.map(k => k.testId).indexOf(selectedTest[0].testId) === -1) {
      const confirmationMessage = $localize`:@@testInProgressWarning:The selected test is in progress. Do you want to proceed with adding it to the experiment?`;
      this.confirmationService.confirm({
        message: `${confirmationMessage}`,
        header: $localize`:@@confirmationHeader:Confirmation`,
        acceptVisible: true,
        acceptLabel: $localize`:@@confirmationAddTest:Add Test`,
        rejectVisible: true,
        rejectLabel: $localize`:@@confirmationCancel:Cancel`,
        closeOnEscape: true,
        dismissableMask: false,
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.onButtonClick(selectedTest)
        },
        reject: () => {
          this.onButtonClick(selectedTest, false);
        }
      })
    }
  }

  ngOnDestroy(): void {
    if (this.dynamicDialogRef) {
      if (this.checkForWarning)
         this.cancelTestsSelection();
      else   
         this.dynamicDialogRef.close();
    }
  }
}

