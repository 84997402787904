<div
  id="eln-preparations-removed-grid-container"
  class="eln-preparations-data eln-table-theme stretch">

  <div class="flex flex-row eln-subHeading">
    {{ subHeading }}
  </div>
  <div class="eln-tableTitle">{{ titleOfTable }}</div>
  <bpt-grid
    #preparationsRemovedGrid
    class="eln-grid hide-readonly-icons"
    [dataSource]="removedPreparationData"
    [columnDefinitions]="columnDefinitions"
    [gridMode]="gridOptions.gridMode"
    [allowRowAdd]="gridOptions.allowRowAdd"    
    [showFilterToggleButton]="gridOptions.showFilterToggleButton"
    [showGridOptionsButton]="gridOptions.showGridOptionsButton"
    [paginated]="gridOptions.pagination"    
    [rowActionConfigurations]="gridActions">
  </bpt-grid>
</div>