/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { chromatographyIntegrationResultSetImportRequestPost } from '../fn/chromatography-integration/chromatography-integration-result-set-import-request-post';
import { ChromatographyIntegrationResultSetImportRequestPost$Params } from '../fn/chromatography-integration/chromatography-integration-result-set-import-request-post';
import { chromatographyIntegrationResultSetsChromatographyIdRefreshRequestPost } from '../fn/chromatography-integration/chromatography-integration-result-sets-chromatography-id-refresh-request-post';
import { ChromatographyIntegrationResultSetsChromatographyIdRefreshRequestPost$Params } from '../fn/chromatography-integration/chromatography-integration-result-sets-chromatography-id-refresh-request-post';

@Injectable({ providedIn: 'root' })
export class ChromatographyIntegrationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `chromatographyIntegrationResultSetImportRequestPost()` */
  static readonly ChromatographyIntegrationResultSetImportRequestPostPath = '/chromatography-integration/result-set-import-request';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `chromatographyIntegrationResultSetImportRequestPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  chromatographyIntegrationResultSetImportRequestPost$Response(params?: ChromatographyIntegrationResultSetImportRequestPost$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return chromatographyIntegrationResultSetImportRequestPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `chromatographyIntegrationResultSetImportRequestPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  chromatographyIntegrationResultSetImportRequestPost(params?: ChromatographyIntegrationResultSetImportRequestPost$Params, context?: HttpContext): Observable<void> {
    return this.chromatographyIntegrationResultSetImportRequestPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `chromatographyIntegrationResultSetsChromatographyIdRefreshRequestPost()` */
  static readonly ChromatographyIntegrationResultSetsChromatographyIdRefreshRequestPostPath = '/chromatography-integration/result-sets/{chromatographyId}/refresh-request';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `chromatographyIntegrationResultSetsChromatographyIdRefreshRequestPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  chromatographyIntegrationResultSetsChromatographyIdRefreshRequestPost$Response(params: ChromatographyIntegrationResultSetsChromatographyIdRefreshRequestPost$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return chromatographyIntegrationResultSetsChromatographyIdRefreshRequestPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `chromatographyIntegrationResultSetsChromatographyIdRefreshRequestPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  chromatographyIntegrationResultSetsChromatographyIdRefreshRequestPost(params: ChromatographyIntegrationResultSetsChromatographyIdRefreshRequestPost$Params, context?: HttpContext): Observable<void> {
    return this.chromatographyIntegrationResultSetsChromatographyIdRefreshRequestPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
