
export class PreparationConstants {
  public static get basedOnStability() { return $localize`:@@BasedOnStability:Based on Stability` };
  public static get preparationDisposalNotSetMessage() { return $localize`:@@preparationDisposalValidationMessage:Disposal method selection is required`};
  public static get preparationsTitle() { return $localize`:@@preparationsTitle:Preparations` };
  public static get addContainerLabel() { return '✛ ' + $localize`:@@AddContainer:Add Container` };
  public static get addContainer() { return $localize`:@@AddContainers:Add Containers` };
  public static get nameTitle() { return $localize`:@@name:Name` };
  public static get preparationNumberTitle() { return $localize`:@@preparationNumber:Preparation ID` };
  public static get statusTitle() { return $localize`:@@status:Status` };
  public static get containerDescriptionTitle() { return $localize`:@@containerDescription:Container Description` };
  public static get formulationTitle() { return $localize`:@@formulationOrComponents:Formulation/Components` };
  public static get concentrationTitle() { return $localize`:@@concentration:Concentration` };
  public static get expirationTitle() { return $localize`:@@expiration:Expiration` };
  public static get storageConditionTitle() { return $localize`:@@storageCondition:Storage Condition` };
  public static get preparationInternalInfoTitle() { return $localize`:@@preparationInternalInfo:Preparation Internal Information` };
  public static get disposalTitle() { return $localize`:@@disposal:Disposal` };
  public static get storageLocationTitle() { return $localize`:@@storageLocation:Storage Location` };
  public static get stabilityTitle() { return $localize`:@@stability:Stability` };
  public static get hazardsTitle() { return $localize`:@@hazards:Hazards` };
  public static get AdditionalInfoTitle() { return $localize`:@@additionalInfo:Additional Information` };
  public static get analysisTitle() { return $localize`:@@analysis:Analysis` };
  public static get methodTitle() { return $localize`:@@method:Method` };
  public static get compendiaTitle() { return $localize`:@@compendia:Compendia` };
  public static get clientTitle() { return $localize`:@@createPreparationClient:Client` };
  public static get suitableForUseText() { return $localize`:@@whileSuitable:While suitable for use` };
  public static get HeaderText() { return $localize`:@@createPreparationHeader:Create Preparation` };
  public static get OriginalQuantity() { return $localize`:@@originalQuantity:Original Quantity` };
  public static get ContainerDescription() { return $localize`:@@containerDescription:Container Description`};
  public static get cellValueChangedMessage() { return $localize`:@@CellValueChanged:Value updated successfully for the preparation` };
  public static get DeleteForConfirmation() {
    return $localize`:@@deleteForConfirmation:You are about to leave this page without saving. All changes will be lost. Do you really want to leave without saving?`
  };
  static readonly id = 'id';
  static readonly parentItemType = 'Recipe';
  public static get DeleteForConfirmationHeader() { return $localize`:@@unsavedChangesConfirmationHeader:Unsaved Changes` };
  public static get acceptLabel() { return $localize`:@@leaveWithoutSaving:Leave without saving` };
  public static get rejectLabel() { return $localize`:@@return:Return` };
  public static get confirmationMessage() {
    return $localize`:@@deleteForConfirmationRow:If you proceed you will lose changes to the data. <br> Are you sure you want to proceed?`
  };
  public static get removeConfirmationMessage() { return $localize`:@@atleastOneRowShouldBeThere:At least one row is required to create Preparation(s)` };
  public static get maximumContainerReached() { return $localize`:@@maximumPreparationContainersReached:Maximum container numbers reached` };
  public static get PreparationSuccess() { return $localize`:@@preparationSuccessMessage:Preparation added Successfully.` };
  public static get RequiredFieldsValidation() { return $localize`:@@requiredFieldsValidation:Not all required data values are specified.` };
  public static get maxPreparationsReachedText() { return $localize`:@@maxPreparationsReached:Preparations limit is 1000 per activity` };
  public static get pastExpirationMsg() { return $localize`:@@pastExpirationMessage:Expiration is in the past!` };
  public static get fieldRequired() { return $localize`:@@fieldIsRequired:This field is required` };
  public static get warningSummary() { return $localize`:@@warningExclamation:Warning!` };
  public static get tags() { return $localize`:@@Tags:Tags` };
  public static get flaskTooltip() { return $localize`:@@FlaskIcon:Container Description Form` };
  public static get deleteContainerTooltip() { return $localize`:@@DeleteContainer:Delete Container` };
  public static get createPreparationMsg() { return $localize`:@@createPreparation:Preparation(s) is being created...` };
  public static get MinimumRowValidation() { return $localize`:@@minimumOneRowShouldBeThere:At least one container is required` };
  public static get noUnsavedChanges() { return $localize`:@@noUnsavedChanges:No unsaved changes to commit` };
  public static get confirmMoveToInvalid() { return $localize`:@@ConfirmMoveToInvalid:Setting status to "Invalid" will also change the status to "Invalid" for all references to the preparation. Are you sure want to set status to "Invalid"?` };
  public static readonly preparationRowDeleteActionId = 'bpt-preparation-delete-row';
  public static readonly preparationOpenContainerFlaskId = 'bpt-preparation-container-flask';
  public static readonly maximumPreparationsPerActivity = 1000;
  public static readonly widthSmall = 180;
  public static readonly widthMedium = 200;
  public static readonly widthLarge = 300;
  public static readonly PreparationName = "Name";
  public static readonly storageCondition = "StorageCondition";
  public static readonly formulaComponents = "FormulaComponents";
  public static readonly status = "Status";
  public static readonly concentration = "Concentration";
  public static readonly expirationValue = "ExpirationValue";
  public static readonly description = "Description";
  static readonly preparationNameColumn = 'name';
  static readonly preparationPreparationNumberColumn = 'preparationNumber';
  static readonly preparationFormulaComponentsColumn = 'formulaComponents';
  static readonly preparationStatusColumn = 'status';
  static readonly preparationStorageConditionColumn = 'storageCondition';
  static readonly preparationConcentrationColumn = 'concentration';
  static readonly studyContainerDescriptionColumn = 'containerDescription';
  static readonly preparationExpirationColumn = 'expiration';



  // Below keys were used in LabItem Preparation Refresh Dictinary
  public static readonly clientKey = "Client";
  public static readonly projectKey = "Project";
  public static readonly stabilityKey = "Stability";
  public static readonly originalQuantityKey = "OriginalQuantity";
  public static readonly expirationDateValueKey = "ExpirationDateValue";
  public static readonly discardedOrConsumedOnKey = "DiscardedOrConsumedOn";

  public static readonly columnDisplayNames : { [fieldName:string]: string } = {
    id: 'Row ID',
    rowIndex: 'Index',
    name: PreparationConstants.nameTitle,
    preparationNumber: PreparationConstants.preparationNumberTitle,
    formulaComponents: PreparationConstants.formulationTitle,
    status: PreparationConstants.statusTitle,
    storageCondition: PreparationConstants.storageConditionTitle,
    concentration: PreparationConstants.concentrationTitle,
    description: PreparationConstants.containerDescriptionTitle,
    expiration: PreparationConstants.expirationTitle
  };
}