import { TitleCasePipe } from '@angular/common';

var titleCasePipe: TitleCasePipe;
export function toTitleCase(value: string): string {
  return titleCasePipe.transform(value);
}

export function setTitleCasePipe(pipe: TitleCasePipe) {
  titleCasePipe = pipe;
}
