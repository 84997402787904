import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BptDateTimeModule } from 'bpt-ui-library/bpt-datetime';
import { BptDropdownModule } from 'bpt-ui-library/bpt-dropdown';
import { BptQuantityModule } from 'bpt-ui-library/bpt-quantity';
import { ButtonModule } from 'primeng/button';
import { BptTextInputModule } from 'bpt-ui-library/bpt-text-input';
import { BptSliderModule } from 'bpt-ui-library/bpt-slider';
import { BptGridModule } from 'bpt-ui-library/bpt-grid';
import { RemovedPreparationsComponent } from './removed-preparations/removed-preparations.component';
import { CheckboxModule } from 'primeng/checkbox';
import { CreatePreparationComponent } from './create-preparation/create-preparation.component';
import { PreparationContainerDescriptionBuilderComponent } from './preparation-container-description-builder/preparation-container-description-builder.component';
import { PreparationExpirationBuilderComponent } from './preparation-expiration-builder/preparation-expiration-builder.component';
import { PreparationInternalInformationComponent } from './preparation-internal-information/preparation-internal-information.component';
import { PreparationsComponent } from './preparations.component';
import { OrganizationModule } from '../organization/organization.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BptContextMenuModule } from 'bpt-ui-library/bpt-context-menu';
import { BptMenuButtonModule } from 'bpt-ui-library/bpt-menu';
import { BptNumericInputModule } from 'bpt-ui-library/bpt-numeric-input';
import { BptTagsModule } from 'bpt-ui-library/bpt-tags';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MessagesModule } from 'primeng/messages';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ProgressBarModule } from 'primeng/progressbar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { LayoutModule } from '../layout/layout.module';
import { SharedModule } from '../shared/shared.module';
import { PreparationStatusBarComponent } from './preparation-status-bar/preparation-status-bar.component';
import { PreparationsLabitemsComponent } from './preparation-labitems/preparations.labitems.component';
import { RemovedPreparationsLabitemsComponent } from './preparation-labitems/removed-preparations-labitems/removed-preparations-labitems.component';
import { PreparationLabItemsService } from './services/preparation-labitems.service';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    LayoutModule,
    InputTextareaModule,
    ButtonModule,
    BptDateTimeModule,
    BptDropdownModule,
    BptGridModule,
    BptNumericInputModule,
    BptQuantityModule,
    BptTagsModule,
    BptTextInputModule,
    CheckboxModule,
    InputTextModule,
    ProgressSpinnerModule,
    MessagesModule,
    OrganizationModule,
    TooltipModule,
    ContextMenuModule,
    ConfirmDialogModule,
    DialogModule,
    ToastModule,
    BptSliderModule,
    BptMenuButtonModule,
    DropdownModule,
    OverlayPanelModule,
    ReactiveFormsModule,
    BptContextMenuModule,
    ProgressBarModule,
    SharedModule
  ],
  declarations: [
    RemovedPreparationsComponent,
    PreparationsComponent,
    PreparationContainerDescriptionBuilderComponent,
    PreparationExpirationBuilderComponent,
    PreparationInternalInformationComponent,
    PreparationStatusBarComponent,
    CreatePreparationComponent,
    PreparationsLabitemsComponent,
    RemovedPreparationsLabitemsComponent
  ],
  exports:[
    PreparationsComponent,
    PreparationContainerDescriptionBuilderComponent,
    PreparationExpirationBuilderComponent,
    PreparationInternalInformationComponent,
    CreatePreparationComponent,
    PreparationsLabitemsComponent
  ],
  providers: [
    PreparationLabItemsService
  ]
})
export class PreparationModule { }
