/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { labItemEventsExperimentIdAddConsumablePost$Json } from '../fn/lab-item-events/lab-item-events-experiment-id-add-consumable-post-json';
import { LabItemEventsExperimentIdAddConsumablePost$Json$Params } from '../fn/lab-item-events/lab-item-events-experiment-id-add-consumable-post-json';
import { labItemEventsExperimentIdAddConsumablePost$Plain } from '../fn/lab-item-events/lab-item-events-experiment-id-add-consumable-post-plain';
import { LabItemEventsExperimentIdAddConsumablePost$Plain$Params } from '../fn/lab-item-events/lab-item-events-experiment-id-add-consumable-post-plain';
import { labItemEventsExperimentIdChangeCellValuePost$Json } from '../fn/lab-item-events/lab-item-events-experiment-id-change-cell-value-post-json';
import { LabItemEventsExperimentIdChangeCellValuePost$Json$Params } from '../fn/lab-item-events/lab-item-events-experiment-id-change-cell-value-post-json';
import { labItemEventsExperimentIdChangeCellValuePost$Plain } from '../fn/lab-item-events/lab-item-events-experiment-id-change-cell-value-post-plain';
import { LabItemEventsExperimentIdChangeCellValuePost$Plain$Params } from '../fn/lab-item-events/lab-item-events-experiment-id-change-cell-value-post-plain';
import { labItemEventsExperimentIdRefreshInstrumentColumnPost$Json } from '../fn/lab-item-events/lab-item-events-experiment-id-refresh-instrument-column-post-json';
import { LabItemEventsExperimentIdRefreshInstrumentColumnPost$Json$Params } from '../fn/lab-item-events/lab-item-events-experiment-id-refresh-instrument-column-post-json';
import { labItemEventsExperimentIdRefreshInstrumentColumnPost$Plain } from '../fn/lab-item-events/lab-item-events-experiment-id-refresh-instrument-column-post-plain';
import { LabItemEventsExperimentIdRefreshInstrumentColumnPost$Plain$Params } from '../fn/lab-item-events/lab-item-events-experiment-id-refresh-instrument-column-post-plain';
import { labItemEventsExperimentIdRefreshInstrumentPost$Json } from '../fn/lab-item-events/lab-item-events-experiment-id-refresh-instrument-post-json';
import { LabItemEventsExperimentIdRefreshInstrumentPost$Json$Params } from '../fn/lab-item-events/lab-item-events-experiment-id-refresh-instrument-post-json';
import { labItemEventsExperimentIdRefreshInstrumentPost$Plain } from '../fn/lab-item-events/lab-item-events-experiment-id-refresh-instrument-post-plain';
import { LabItemEventsExperimentIdRefreshInstrumentPost$Plain$Params } from '../fn/lab-item-events/lab-item-events-experiment-id-refresh-instrument-post-plain';
import { labItemEventsExperimentIdRefreshMaterialPost$Json } from '../fn/lab-item-events/lab-item-events-experiment-id-refresh-material-post-json';
import { LabItemEventsExperimentIdRefreshMaterialPost$Json$Params } from '../fn/lab-item-events/lab-item-events-experiment-id-refresh-material-post-json';
import { labItemEventsExperimentIdRefreshMaterialPost$Plain } from '../fn/lab-item-events/lab-item-events-experiment-id-refresh-material-post-plain';
import { LabItemEventsExperimentIdRefreshMaterialPost$Plain$Params } from '../fn/lab-item-events/lab-item-events-experiment-id-refresh-material-post-plain';
import { labItemEventsExperimentIdRefreshPreparationPost$Json } from '../fn/lab-item-events/lab-item-events-experiment-id-refresh-preparation-post-json';
import { LabItemEventsExperimentIdRefreshPreparationPost$Json$Params } from '../fn/lab-item-events/lab-item-events-experiment-id-refresh-preparation-post-json';
import { labItemEventsExperimentIdRefreshPreparationPost$Plain } from '../fn/lab-item-events/lab-item-events-experiment-id-refresh-preparation-post-plain';
import { LabItemEventsExperimentIdRefreshPreparationPost$Plain$Params } from '../fn/lab-item-events/lab-item-events-experiment-id-refresh-preparation-post-plain';
import { labItemEventsExperimentIdRemoveConsumablePost$Json } from '../fn/lab-item-events/lab-item-events-experiment-id-remove-consumable-post-json';
import { LabItemEventsExperimentIdRemoveConsumablePost$Json$Params } from '../fn/lab-item-events/lab-item-events-experiment-id-remove-consumable-post-json';
import { labItemEventsExperimentIdRemoveConsumablePost$Plain } from '../fn/lab-item-events/lab-item-events-experiment-id-remove-consumable-post-plain';
import { LabItemEventsExperimentIdRemoveConsumablePost$Plain$Params } from '../fn/lab-item-events/lab-item-events-experiment-id-remove-consumable-post-plain';
import { labItemEventsExperimentIdRemoveInstrumentColumnPost$Json } from '../fn/lab-item-events/lab-item-events-experiment-id-remove-instrument-column-post-json';
import { LabItemEventsExperimentIdRemoveInstrumentColumnPost$Json$Params } from '../fn/lab-item-events/lab-item-events-experiment-id-remove-instrument-column-post-json';
import { labItemEventsExperimentIdRemoveInstrumentColumnPost$Plain } from '../fn/lab-item-events/lab-item-events-experiment-id-remove-instrument-column-post-plain';
import { LabItemEventsExperimentIdRemoveInstrumentColumnPost$Plain$Params } from '../fn/lab-item-events/lab-item-events-experiment-id-remove-instrument-column-post-plain';
import { labItemEventsExperimentIdRemoveInstrumentPost$Json } from '../fn/lab-item-events/lab-item-events-experiment-id-remove-instrument-post-json';
import { LabItemEventsExperimentIdRemoveInstrumentPost$Json$Params } from '../fn/lab-item-events/lab-item-events-experiment-id-remove-instrument-post-json';
import { labItemEventsExperimentIdRemoveInstrumentPost$Plain } from '../fn/lab-item-events/lab-item-events-experiment-id-remove-instrument-post-plain';
import { LabItemEventsExperimentIdRemoveInstrumentPost$Plain$Params } from '../fn/lab-item-events/lab-item-events-experiment-id-remove-instrument-post-plain';
import { labItemEventsExperimentIdRemoveMaterialPost$Json } from '../fn/lab-item-events/lab-item-events-experiment-id-remove-material-post-json';
import { LabItemEventsExperimentIdRemoveMaterialPost$Json$Params } from '../fn/lab-item-events/lab-item-events-experiment-id-remove-material-post-json';
import { labItemEventsExperimentIdRemoveMaterialPost$Plain } from '../fn/lab-item-events/lab-item-events-experiment-id-remove-material-post-plain';
import { LabItemEventsExperimentIdRemoveMaterialPost$Plain$Params } from '../fn/lab-item-events/lab-item-events-experiment-id-remove-material-post-plain';
import { labItemEventsExperimentIdRemovePreparationPost$Json } from '../fn/lab-item-events/lab-item-events-experiment-id-remove-preparation-post-json';
import { LabItemEventsExperimentIdRemovePreparationPost$Json$Params } from '../fn/lab-item-events/lab-item-events-experiment-id-remove-preparation-post-json';
import { labItemEventsExperimentIdRemovePreparationPost$Plain } from '../fn/lab-item-events/lab-item-events-experiment-id-remove-preparation-post-plain';
import { LabItemEventsExperimentIdRemovePreparationPost$Plain$Params } from '../fn/lab-item-events/lab-item-events-experiment-id-remove-preparation-post-plain';
import { labItemEventsExperimentIdRestoreConsumablePost$Json } from '../fn/lab-item-events/lab-item-events-experiment-id-restore-consumable-post-json';
import { LabItemEventsExperimentIdRestoreConsumablePost$Json$Params } from '../fn/lab-item-events/lab-item-events-experiment-id-restore-consumable-post-json';
import { labItemEventsExperimentIdRestoreConsumablePost$Plain } from '../fn/lab-item-events/lab-item-events-experiment-id-restore-consumable-post-plain';
import { LabItemEventsExperimentIdRestoreConsumablePost$Plain$Params } from '../fn/lab-item-events/lab-item-events-experiment-id-restore-consumable-post-plain';
import { labItemEventsExperimentIdRestoreInstrumentColumnPost$Json } from '../fn/lab-item-events/lab-item-events-experiment-id-restore-instrument-column-post-json';
import { LabItemEventsExperimentIdRestoreInstrumentColumnPost$Json$Params } from '../fn/lab-item-events/lab-item-events-experiment-id-restore-instrument-column-post-json';
import { labItemEventsExperimentIdRestoreInstrumentColumnPost$Plain } from '../fn/lab-item-events/lab-item-events-experiment-id-restore-instrument-column-post-plain';
import { LabItemEventsExperimentIdRestoreInstrumentColumnPost$Plain$Params } from '../fn/lab-item-events/lab-item-events-experiment-id-restore-instrument-column-post-plain';
import { labItemEventsExperimentIdRestoreInstrumentPost$Json } from '../fn/lab-item-events/lab-item-events-experiment-id-restore-instrument-post-json';
import { LabItemEventsExperimentIdRestoreInstrumentPost$Json$Params } from '../fn/lab-item-events/lab-item-events-experiment-id-restore-instrument-post-json';
import { labItemEventsExperimentIdRestoreInstrumentPost$Plain } from '../fn/lab-item-events/lab-item-events-experiment-id-restore-instrument-post-plain';
import { LabItemEventsExperimentIdRestoreInstrumentPost$Plain$Params } from '../fn/lab-item-events/lab-item-events-experiment-id-restore-instrument-post-plain';
import { labItemEventsExperimentIdRestoreMaterialPost$Json } from '../fn/lab-item-events/lab-item-events-experiment-id-restore-material-post-json';
import { LabItemEventsExperimentIdRestoreMaterialPost$Json$Params } from '../fn/lab-item-events/lab-item-events-experiment-id-restore-material-post-json';
import { labItemEventsExperimentIdRestoreMaterialPost$Plain } from '../fn/lab-item-events/lab-item-events-experiment-id-restore-material-post-plain';
import { LabItemEventsExperimentIdRestoreMaterialPost$Plain$Params } from '../fn/lab-item-events/lab-item-events-experiment-id-restore-material-post-plain';
import { labItemEventsExperimentIdRestorePreparationPost$Json } from '../fn/lab-item-events/lab-item-events-experiment-id-restore-preparation-post-json';
import { LabItemEventsExperimentIdRestorePreparationPost$Json$Params } from '../fn/lab-item-events/lab-item-events-experiment-id-restore-preparation-post-json';
import { labItemEventsExperimentIdRestorePreparationPost$Plain } from '../fn/lab-item-events/lab-item-events-experiment-id-restore-preparation-post-plain';
import { LabItemEventsExperimentIdRestorePreparationPost$Plain$Params } from '../fn/lab-item-events/lab-item-events-experiment-id-restore-preparation-post-plain';
import { LabItemPreparationRemovedResponse } from '../models/lab-item-preparation-removed-response';
import { LabItemPreparationRestoredResponse } from '../models/lab-item-preparation-restored-response';
import { LabItemsCellChangedEventNotification } from '../models/lab-items-cell-changed-event-notification';
import { LabItemsConsumableAddedResponse } from '../models/lab-items-consumable-added-response';
import { LabItemsConsumableRemovedResponse } from '../models/lab-items-consumable-removed-response';
import { LabItemsConsumableRestoredResponse } from '../models/lab-items-consumable-restored-response';
import { LabItemsInstrumentColumnRefreshedNotification } from '../models/lab-items-instrument-column-refreshed-notification';
import { LabItemsInstrumentColumnRemovedResponse } from '../models/lab-items-instrument-column-removed-response';
import { LabItemsInstrumentColumnRestoredResponse } from '../models/lab-items-instrument-column-restored-response';
import { LabItemsInstrumentRefreshedNotification } from '../models/lab-items-instrument-refreshed-notification';
import { LabItemsInstrumentRemovedResponse } from '../models/lab-items-instrument-removed-response';
import { LabItemsInstrumentRestoredResponse } from '../models/lab-items-instrument-restored-response';
import { LabItemsMaterialRefreshedNotification } from '../models/lab-items-material-refreshed-notification';
import { LabItemsMaterialRemovedResponse } from '../models/lab-items-material-removed-response';
import { LabItemsMaterialRestoredResponse } from '../models/lab-items-material-restored-response';
import { LabItemsPreparationRefreshedNotification } from '../models/lab-items-preparation-refreshed-notification';

@Injectable({ providedIn: 'root' })
export class LabItemEventsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `labItemEventsExperimentIdRemoveMaterialPost()` */
  static readonly LabItemEventsExperimentIdRemoveMaterialPostPath = '/lab-item-events/{experimentId}/remove-material';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labItemEventsExperimentIdRemoveMaterialPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRemoveMaterialPost$Plain$Response(params: LabItemEventsExperimentIdRemoveMaterialPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<LabItemsMaterialRemovedResponse>> {
    return labItemEventsExperimentIdRemoveMaterialPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `labItemEventsExperimentIdRemoveMaterialPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRemoveMaterialPost$Plain(params: LabItemEventsExperimentIdRemoveMaterialPost$Plain$Params, context?: HttpContext): Observable<LabItemsMaterialRemovedResponse> {
    return this.labItemEventsExperimentIdRemoveMaterialPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<LabItemsMaterialRemovedResponse>): LabItemsMaterialRemovedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labItemEventsExperimentIdRemoveMaterialPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRemoveMaterialPost$Json$Response(params: LabItemEventsExperimentIdRemoveMaterialPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<LabItemsMaterialRemovedResponse>> {
    return labItemEventsExperimentIdRemoveMaterialPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `labItemEventsExperimentIdRemoveMaterialPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRemoveMaterialPost$Json(params: LabItemEventsExperimentIdRemoveMaterialPost$Json$Params, context?: HttpContext): Observable<LabItemsMaterialRemovedResponse> {
    return this.labItemEventsExperimentIdRemoveMaterialPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<LabItemsMaterialRemovedResponse>): LabItemsMaterialRemovedResponse => r.body)
    );
  }

  /** Path part for operation `labItemEventsExperimentIdRestoreMaterialPost()` */
  static readonly LabItemEventsExperimentIdRestoreMaterialPostPath = '/lab-item-events/{experimentId}/restore-material';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labItemEventsExperimentIdRestoreMaterialPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRestoreMaterialPost$Plain$Response(params: LabItemEventsExperimentIdRestoreMaterialPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<LabItemsMaterialRestoredResponse>> {
    return labItemEventsExperimentIdRestoreMaterialPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `labItemEventsExperimentIdRestoreMaterialPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRestoreMaterialPost$Plain(params: LabItemEventsExperimentIdRestoreMaterialPost$Plain$Params, context?: HttpContext): Observable<LabItemsMaterialRestoredResponse> {
    return this.labItemEventsExperimentIdRestoreMaterialPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<LabItemsMaterialRestoredResponse>): LabItemsMaterialRestoredResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labItemEventsExperimentIdRestoreMaterialPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRestoreMaterialPost$Json$Response(params: LabItemEventsExperimentIdRestoreMaterialPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<LabItemsMaterialRestoredResponse>> {
    return labItemEventsExperimentIdRestoreMaterialPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `labItemEventsExperimentIdRestoreMaterialPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRestoreMaterialPost$Json(params: LabItemEventsExperimentIdRestoreMaterialPost$Json$Params, context?: HttpContext): Observable<LabItemsMaterialRestoredResponse> {
    return this.labItemEventsExperimentIdRestoreMaterialPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<LabItemsMaterialRestoredResponse>): LabItemsMaterialRestoredResponse => r.body)
    );
  }

  /** Path part for operation `labItemEventsExperimentIdRemoveInstrumentPost()` */
  static readonly LabItemEventsExperimentIdRemoveInstrumentPostPath = '/lab-item-events/{experimentId}/remove-instrument';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labItemEventsExperimentIdRemoveInstrumentPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRemoveInstrumentPost$Plain$Response(params: LabItemEventsExperimentIdRemoveInstrumentPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<LabItemsInstrumentRemovedResponse>> {
    return labItemEventsExperimentIdRemoveInstrumentPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `labItemEventsExperimentIdRemoveInstrumentPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRemoveInstrumentPost$Plain(params: LabItemEventsExperimentIdRemoveInstrumentPost$Plain$Params, context?: HttpContext): Observable<LabItemsInstrumentRemovedResponse> {
    return this.labItemEventsExperimentIdRemoveInstrumentPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<LabItemsInstrumentRemovedResponse>): LabItemsInstrumentRemovedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labItemEventsExperimentIdRemoveInstrumentPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRemoveInstrumentPost$Json$Response(params: LabItemEventsExperimentIdRemoveInstrumentPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<LabItemsInstrumentRemovedResponse>> {
    return labItemEventsExperimentIdRemoveInstrumentPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `labItemEventsExperimentIdRemoveInstrumentPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRemoveInstrumentPost$Json(params: LabItemEventsExperimentIdRemoveInstrumentPost$Json$Params, context?: HttpContext): Observable<LabItemsInstrumentRemovedResponse> {
    return this.labItemEventsExperimentIdRemoveInstrumentPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<LabItemsInstrumentRemovedResponse>): LabItemsInstrumentRemovedResponse => r.body)
    );
  }

  /** Path part for operation `labItemEventsExperimentIdRestoreInstrumentPost()` */
  static readonly LabItemEventsExperimentIdRestoreInstrumentPostPath = '/lab-item-events/{experimentId}/restore-instrument';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labItemEventsExperimentIdRestoreInstrumentPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRestoreInstrumentPost$Plain$Response(params: LabItemEventsExperimentIdRestoreInstrumentPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<LabItemsInstrumentRestoredResponse>> {
    return labItemEventsExperimentIdRestoreInstrumentPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `labItemEventsExperimentIdRestoreInstrumentPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRestoreInstrumentPost$Plain(params: LabItemEventsExperimentIdRestoreInstrumentPost$Plain$Params, context?: HttpContext): Observable<LabItemsInstrumentRestoredResponse> {
    return this.labItemEventsExperimentIdRestoreInstrumentPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<LabItemsInstrumentRestoredResponse>): LabItemsInstrumentRestoredResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labItemEventsExperimentIdRestoreInstrumentPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRestoreInstrumentPost$Json$Response(params: LabItemEventsExperimentIdRestoreInstrumentPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<LabItemsInstrumentRestoredResponse>> {
    return labItemEventsExperimentIdRestoreInstrumentPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `labItemEventsExperimentIdRestoreInstrumentPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRestoreInstrumentPost$Json(params: LabItemEventsExperimentIdRestoreInstrumentPost$Json$Params, context?: HttpContext): Observable<LabItemsInstrumentRestoredResponse> {
    return this.labItemEventsExperimentIdRestoreInstrumentPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<LabItemsInstrumentRestoredResponse>): LabItemsInstrumentRestoredResponse => r.body)
    );
  }

  /** Path part for operation `labItemEventsExperimentIdRefreshInstrumentPost()` */
  static readonly LabItemEventsExperimentIdRefreshInstrumentPostPath = '/lab-item-events/{experimentId}/refresh-instrument';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labItemEventsExperimentIdRefreshInstrumentPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRefreshInstrumentPost$Plain$Response(params: LabItemEventsExperimentIdRefreshInstrumentPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<LabItemsInstrumentRefreshedNotification>> {
    return labItemEventsExperimentIdRefreshInstrumentPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `labItemEventsExperimentIdRefreshInstrumentPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRefreshInstrumentPost$Plain(params: LabItemEventsExperimentIdRefreshInstrumentPost$Plain$Params, context?: HttpContext): Observable<LabItemsInstrumentRefreshedNotification> {
    return this.labItemEventsExperimentIdRefreshInstrumentPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<LabItemsInstrumentRefreshedNotification>): LabItemsInstrumentRefreshedNotification => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labItemEventsExperimentIdRefreshInstrumentPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRefreshInstrumentPost$Json$Response(params: LabItemEventsExperimentIdRefreshInstrumentPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<LabItemsInstrumentRefreshedNotification>> {
    return labItemEventsExperimentIdRefreshInstrumentPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `labItemEventsExperimentIdRefreshInstrumentPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRefreshInstrumentPost$Json(params: LabItemEventsExperimentIdRefreshInstrumentPost$Json$Params, context?: HttpContext): Observable<LabItemsInstrumentRefreshedNotification> {
    return this.labItemEventsExperimentIdRefreshInstrumentPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<LabItemsInstrumentRefreshedNotification>): LabItemsInstrumentRefreshedNotification => r.body)
    );
  }

  /** Path part for operation `labItemEventsExperimentIdRefreshInstrumentColumnPost()` */
  static readonly LabItemEventsExperimentIdRefreshInstrumentColumnPostPath = '/lab-item-events/{experimentId}/refresh-instrumentColumn';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labItemEventsExperimentIdRefreshInstrumentColumnPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRefreshInstrumentColumnPost$Plain$Response(params: LabItemEventsExperimentIdRefreshInstrumentColumnPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<LabItemsInstrumentColumnRefreshedNotification>> {
    return labItemEventsExperimentIdRefreshInstrumentColumnPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `labItemEventsExperimentIdRefreshInstrumentColumnPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRefreshInstrumentColumnPost$Plain(params: LabItemEventsExperimentIdRefreshInstrumentColumnPost$Plain$Params, context?: HttpContext): Observable<LabItemsInstrumentColumnRefreshedNotification> {
    return this.labItemEventsExperimentIdRefreshInstrumentColumnPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<LabItemsInstrumentColumnRefreshedNotification>): LabItemsInstrumentColumnRefreshedNotification => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labItemEventsExperimentIdRefreshInstrumentColumnPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRefreshInstrumentColumnPost$Json$Response(params: LabItemEventsExperimentIdRefreshInstrumentColumnPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<LabItemsInstrumentColumnRefreshedNotification>> {
    return labItemEventsExperimentIdRefreshInstrumentColumnPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `labItemEventsExperimentIdRefreshInstrumentColumnPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRefreshInstrumentColumnPost$Json(params: LabItemEventsExperimentIdRefreshInstrumentColumnPost$Json$Params, context?: HttpContext): Observable<LabItemsInstrumentColumnRefreshedNotification> {
    return this.labItemEventsExperimentIdRefreshInstrumentColumnPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<LabItemsInstrumentColumnRefreshedNotification>): LabItemsInstrumentColumnRefreshedNotification => r.body)
    );
  }

  /** Path part for operation `labItemEventsExperimentIdChangeCellValuePost()` */
  static readonly LabItemEventsExperimentIdChangeCellValuePostPath = '/lab-item-events/{experimentId}/change-cell-value';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labItemEventsExperimentIdChangeCellValuePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdChangeCellValuePost$Plain$Response(params: LabItemEventsExperimentIdChangeCellValuePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<LabItemsCellChangedEventNotification>> {
    return labItemEventsExperimentIdChangeCellValuePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `labItemEventsExperimentIdChangeCellValuePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdChangeCellValuePost$Plain(params: LabItemEventsExperimentIdChangeCellValuePost$Plain$Params, context?: HttpContext): Observable<LabItemsCellChangedEventNotification> {
    return this.labItemEventsExperimentIdChangeCellValuePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<LabItemsCellChangedEventNotification>): LabItemsCellChangedEventNotification => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labItemEventsExperimentIdChangeCellValuePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdChangeCellValuePost$Json$Response(params: LabItemEventsExperimentIdChangeCellValuePost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<LabItemsCellChangedEventNotification>> {
    return labItemEventsExperimentIdChangeCellValuePost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `labItemEventsExperimentIdChangeCellValuePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdChangeCellValuePost$Json(params: LabItemEventsExperimentIdChangeCellValuePost$Json$Params, context?: HttpContext): Observable<LabItemsCellChangedEventNotification> {
    return this.labItemEventsExperimentIdChangeCellValuePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<LabItemsCellChangedEventNotification>): LabItemsCellChangedEventNotification => r.body)
    );
  }

  /** Path part for operation `labItemEventsExperimentIdRefreshMaterialPost()` */
  static readonly LabItemEventsExperimentIdRefreshMaterialPostPath = '/lab-item-events/{experimentId}/refresh-material';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labItemEventsExperimentIdRefreshMaterialPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRefreshMaterialPost$Plain$Response(params: LabItemEventsExperimentIdRefreshMaterialPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<LabItemsMaterialRefreshedNotification>> {
    return labItemEventsExperimentIdRefreshMaterialPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `labItemEventsExperimentIdRefreshMaterialPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRefreshMaterialPost$Plain(params: LabItemEventsExperimentIdRefreshMaterialPost$Plain$Params, context?: HttpContext): Observable<LabItemsMaterialRefreshedNotification> {
    return this.labItemEventsExperimentIdRefreshMaterialPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<LabItemsMaterialRefreshedNotification>): LabItemsMaterialRefreshedNotification => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labItemEventsExperimentIdRefreshMaterialPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRefreshMaterialPost$Json$Response(params: LabItemEventsExperimentIdRefreshMaterialPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<LabItemsMaterialRefreshedNotification>> {
    return labItemEventsExperimentIdRefreshMaterialPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `labItemEventsExperimentIdRefreshMaterialPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRefreshMaterialPost$Json(params: LabItemEventsExperimentIdRefreshMaterialPost$Json$Params, context?: HttpContext): Observable<LabItemsMaterialRefreshedNotification> {
    return this.labItemEventsExperimentIdRefreshMaterialPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<LabItemsMaterialRefreshedNotification>): LabItemsMaterialRefreshedNotification => r.body)
    );
  }

  /** Path part for operation `labItemEventsExperimentIdAddConsumablePost()` */
  static readonly LabItemEventsExperimentIdAddConsumablePostPath = '/lab-item-events/{experimentId}/add-consumable';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labItemEventsExperimentIdAddConsumablePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdAddConsumablePost$Plain$Response(params: LabItemEventsExperimentIdAddConsumablePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<LabItemsConsumableAddedResponse>> {
    return labItemEventsExperimentIdAddConsumablePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `labItemEventsExperimentIdAddConsumablePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdAddConsumablePost$Plain(params: LabItemEventsExperimentIdAddConsumablePost$Plain$Params, context?: HttpContext): Observable<LabItemsConsumableAddedResponse> {
    return this.labItemEventsExperimentIdAddConsumablePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<LabItemsConsumableAddedResponse>): LabItemsConsumableAddedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labItemEventsExperimentIdAddConsumablePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdAddConsumablePost$Json$Response(params: LabItemEventsExperimentIdAddConsumablePost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<LabItemsConsumableAddedResponse>> {
    return labItemEventsExperimentIdAddConsumablePost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `labItemEventsExperimentIdAddConsumablePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdAddConsumablePost$Json(params: LabItemEventsExperimentIdAddConsumablePost$Json$Params, context?: HttpContext): Observable<LabItemsConsumableAddedResponse> {
    return this.labItemEventsExperimentIdAddConsumablePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<LabItemsConsumableAddedResponse>): LabItemsConsumableAddedResponse => r.body)
    );
  }

  /** Path part for operation `labItemEventsExperimentIdRemoveConsumablePost()` */
  static readonly LabItemEventsExperimentIdRemoveConsumablePostPath = '/lab-item-events/{experimentId}/remove-consumable';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labItemEventsExperimentIdRemoveConsumablePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRemoveConsumablePost$Plain$Response(params: LabItemEventsExperimentIdRemoveConsumablePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<LabItemsConsumableRemovedResponse>> {
    return labItemEventsExperimentIdRemoveConsumablePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `labItemEventsExperimentIdRemoveConsumablePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRemoveConsumablePost$Plain(params: LabItemEventsExperimentIdRemoveConsumablePost$Plain$Params, context?: HttpContext): Observable<LabItemsConsumableRemovedResponse> {
    return this.labItemEventsExperimentIdRemoveConsumablePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<LabItemsConsumableRemovedResponse>): LabItemsConsumableRemovedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labItemEventsExperimentIdRemoveConsumablePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRemoveConsumablePost$Json$Response(params: LabItemEventsExperimentIdRemoveConsumablePost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<LabItemsConsumableRemovedResponse>> {
    return labItemEventsExperimentIdRemoveConsumablePost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `labItemEventsExperimentIdRemoveConsumablePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRemoveConsumablePost$Json(params: LabItemEventsExperimentIdRemoveConsumablePost$Json$Params, context?: HttpContext): Observable<LabItemsConsumableRemovedResponse> {
    return this.labItemEventsExperimentIdRemoveConsumablePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<LabItemsConsumableRemovedResponse>): LabItemsConsumableRemovedResponse => r.body)
    );
  }

  /** Path part for operation `labItemEventsExperimentIdRestoreConsumablePost()` */
  static readonly LabItemEventsExperimentIdRestoreConsumablePostPath = '/lab-item-events/{experimentId}/restore-consumable';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labItemEventsExperimentIdRestoreConsumablePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRestoreConsumablePost$Plain$Response(params: LabItemEventsExperimentIdRestoreConsumablePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<LabItemsConsumableRestoredResponse>> {
    return labItemEventsExperimentIdRestoreConsumablePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `labItemEventsExperimentIdRestoreConsumablePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRestoreConsumablePost$Plain(params: LabItemEventsExperimentIdRestoreConsumablePost$Plain$Params, context?: HttpContext): Observable<LabItemsConsumableRestoredResponse> {
    return this.labItemEventsExperimentIdRestoreConsumablePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<LabItemsConsumableRestoredResponse>): LabItemsConsumableRestoredResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labItemEventsExperimentIdRestoreConsumablePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRestoreConsumablePost$Json$Response(params: LabItemEventsExperimentIdRestoreConsumablePost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<LabItemsConsumableRestoredResponse>> {
    return labItemEventsExperimentIdRestoreConsumablePost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `labItemEventsExperimentIdRestoreConsumablePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRestoreConsumablePost$Json(params: LabItemEventsExperimentIdRestoreConsumablePost$Json$Params, context?: HttpContext): Observable<LabItemsConsumableRestoredResponse> {
    return this.labItemEventsExperimentIdRestoreConsumablePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<LabItemsConsumableRestoredResponse>): LabItemsConsumableRestoredResponse => r.body)
    );
  }

  /** Path part for operation `labItemEventsExperimentIdRemoveInstrumentColumnPost()` */
  static readonly LabItemEventsExperimentIdRemoveInstrumentColumnPostPath = '/lab-item-events/{experimentId}/remove-instrument-column';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labItemEventsExperimentIdRemoveInstrumentColumnPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRemoveInstrumentColumnPost$Plain$Response(params: LabItemEventsExperimentIdRemoveInstrumentColumnPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<LabItemsInstrumentColumnRemovedResponse>> {
    return labItemEventsExperimentIdRemoveInstrumentColumnPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `labItemEventsExperimentIdRemoveInstrumentColumnPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRemoveInstrumentColumnPost$Plain(params: LabItemEventsExperimentIdRemoveInstrumentColumnPost$Plain$Params, context?: HttpContext): Observable<LabItemsInstrumentColumnRemovedResponse> {
    return this.labItemEventsExperimentIdRemoveInstrumentColumnPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<LabItemsInstrumentColumnRemovedResponse>): LabItemsInstrumentColumnRemovedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labItemEventsExperimentIdRemoveInstrumentColumnPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRemoveInstrumentColumnPost$Json$Response(params: LabItemEventsExperimentIdRemoveInstrumentColumnPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<LabItemsInstrumentColumnRemovedResponse>> {
    return labItemEventsExperimentIdRemoveInstrumentColumnPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `labItemEventsExperimentIdRemoveInstrumentColumnPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRemoveInstrumentColumnPost$Json(params: LabItemEventsExperimentIdRemoveInstrumentColumnPost$Json$Params, context?: HttpContext): Observable<LabItemsInstrumentColumnRemovedResponse> {
    return this.labItemEventsExperimentIdRemoveInstrumentColumnPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<LabItemsInstrumentColumnRemovedResponse>): LabItemsInstrumentColumnRemovedResponse => r.body)
    );
  }

  /** Path part for operation `labItemEventsExperimentIdRestoreInstrumentColumnPost()` */
  static readonly LabItemEventsExperimentIdRestoreInstrumentColumnPostPath = '/lab-item-events/{experimentId}/restore-instrument-column';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labItemEventsExperimentIdRestoreInstrumentColumnPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRestoreInstrumentColumnPost$Plain$Response(params: LabItemEventsExperimentIdRestoreInstrumentColumnPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<LabItemsInstrumentColumnRestoredResponse>> {
    return labItemEventsExperimentIdRestoreInstrumentColumnPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `labItemEventsExperimentIdRestoreInstrumentColumnPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRestoreInstrumentColumnPost$Plain(params: LabItemEventsExperimentIdRestoreInstrumentColumnPost$Plain$Params, context?: HttpContext): Observable<LabItemsInstrumentColumnRestoredResponse> {
    return this.labItemEventsExperimentIdRestoreInstrumentColumnPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<LabItemsInstrumentColumnRestoredResponse>): LabItemsInstrumentColumnRestoredResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labItemEventsExperimentIdRestoreInstrumentColumnPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRestoreInstrumentColumnPost$Json$Response(params: LabItemEventsExperimentIdRestoreInstrumentColumnPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<LabItemsInstrumentColumnRestoredResponse>> {
    return labItemEventsExperimentIdRestoreInstrumentColumnPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `labItemEventsExperimentIdRestoreInstrumentColumnPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRestoreInstrumentColumnPost$Json(params: LabItemEventsExperimentIdRestoreInstrumentColumnPost$Json$Params, context?: HttpContext): Observable<LabItemsInstrumentColumnRestoredResponse> {
    return this.labItemEventsExperimentIdRestoreInstrumentColumnPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<LabItemsInstrumentColumnRestoredResponse>): LabItemsInstrumentColumnRestoredResponse => r.body)
    );
  }

  /** Path part for operation `labItemEventsExperimentIdRemovePreparationPost()` */
  static readonly LabItemEventsExperimentIdRemovePreparationPostPath = '/lab-item-events/{experimentId}/remove-preparation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labItemEventsExperimentIdRemovePreparationPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRemovePreparationPost$Plain$Response(params: LabItemEventsExperimentIdRemovePreparationPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<LabItemPreparationRemovedResponse>> {
    return labItemEventsExperimentIdRemovePreparationPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `labItemEventsExperimentIdRemovePreparationPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRemovePreparationPost$Plain(params: LabItemEventsExperimentIdRemovePreparationPost$Plain$Params, context?: HttpContext): Observable<LabItemPreparationRemovedResponse> {
    return this.labItemEventsExperimentIdRemovePreparationPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<LabItemPreparationRemovedResponse>): LabItemPreparationRemovedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labItemEventsExperimentIdRemovePreparationPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRemovePreparationPost$Json$Response(params: LabItemEventsExperimentIdRemovePreparationPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<LabItemPreparationRemovedResponse>> {
    return labItemEventsExperimentIdRemovePreparationPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `labItemEventsExperimentIdRemovePreparationPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRemovePreparationPost$Json(params: LabItemEventsExperimentIdRemovePreparationPost$Json$Params, context?: HttpContext): Observable<LabItemPreparationRemovedResponse> {
    return this.labItemEventsExperimentIdRemovePreparationPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<LabItemPreparationRemovedResponse>): LabItemPreparationRemovedResponse => r.body)
    );
  }

  /** Path part for operation `labItemEventsExperimentIdRestorePreparationPost()` */
  static readonly LabItemEventsExperimentIdRestorePreparationPostPath = '/lab-item-events/{experimentId}/restore-preparation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labItemEventsExperimentIdRestorePreparationPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRestorePreparationPost$Plain$Response(params: LabItemEventsExperimentIdRestorePreparationPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<LabItemPreparationRestoredResponse>> {
    return labItemEventsExperimentIdRestorePreparationPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `labItemEventsExperimentIdRestorePreparationPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRestorePreparationPost$Plain(params: LabItemEventsExperimentIdRestorePreparationPost$Plain$Params, context?: HttpContext): Observable<LabItemPreparationRestoredResponse> {
    return this.labItemEventsExperimentIdRestorePreparationPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<LabItemPreparationRestoredResponse>): LabItemPreparationRestoredResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labItemEventsExperimentIdRestorePreparationPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRestorePreparationPost$Json$Response(params: LabItemEventsExperimentIdRestorePreparationPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<LabItemPreparationRestoredResponse>> {
    return labItemEventsExperimentIdRestorePreparationPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `labItemEventsExperimentIdRestorePreparationPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRestorePreparationPost$Json(params: LabItemEventsExperimentIdRestorePreparationPost$Json$Params, context?: HttpContext): Observable<LabItemPreparationRestoredResponse> {
    return this.labItemEventsExperimentIdRestorePreparationPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<LabItemPreparationRestoredResponse>): LabItemPreparationRestoredResponse => r.body)
    );
  }

  /** Path part for operation `labItemEventsExperimentIdRefreshPreparationPost()` */
  static readonly LabItemEventsExperimentIdRefreshPreparationPostPath = '/lab-item-events/{experimentId}/refresh-preparation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labItemEventsExperimentIdRefreshPreparationPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRefreshPreparationPost$Plain$Response(params: LabItemEventsExperimentIdRefreshPreparationPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<LabItemsPreparationRefreshedNotification>> {
    return labItemEventsExperimentIdRefreshPreparationPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `labItemEventsExperimentIdRefreshPreparationPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRefreshPreparationPost$Plain(params: LabItemEventsExperimentIdRefreshPreparationPost$Plain$Params, context?: HttpContext): Observable<LabItemsPreparationRefreshedNotification> {
    return this.labItemEventsExperimentIdRefreshPreparationPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<LabItemsPreparationRefreshedNotification>): LabItemsPreparationRefreshedNotification => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labItemEventsExperimentIdRefreshPreparationPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRefreshPreparationPost$Json$Response(params: LabItemEventsExperimentIdRefreshPreparationPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<LabItemsPreparationRefreshedNotification>> {
    return labItemEventsExperimentIdRefreshPreparationPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `labItemEventsExperimentIdRefreshPreparationPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  labItemEventsExperimentIdRefreshPreparationPost$Json(params: LabItemEventsExperimentIdRefreshPreparationPost$Json$Params, context?: HttpContext): Observable<LabItemsPreparationRefreshedNotification> {
    return this.labItemEventsExperimentIdRefreshPreparationPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<LabItemsPreparationRefreshedNotification>): LabItemsPreparationRefreshedNotification => r.body)
    );
  }

}
