import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';

declare const Blazor: any;

@Injectable({
  providedIn: 'root'
})
export class BlazorStartService {
  public blazorLoadState: BlazorLoadState = BlazorLoadState.uninitialized;
  public blazorLoadingStatusNotifications: BehaviorSubject<BlazorLoadState> = new BehaviorSubject(this.blazorLoadState);

  private readonly blazorLoadingPromise?: Promise<IPreloadScriptResult>;

  constructor() {
    console.debug('New instance of BlazorStartService');

    /*
      BlazorWasmAntivirusProtection.lib.module.js (v 1.8.0 to at least 2.4.5) has a "feature" of deleting all entries
      in this cache if we give it our own loadBootResource. (Strangely, this doesn't happen in development (localhost).)
      So, we have to keep track of entries to keep ourselves by intercepting `put` and `delete` for this cache.

      Note: With .NET 9 Blazor, this all may need to go away along with BlazorWasmAntivirusProtection itself.
    */
    /** Name of cache as computed by both blazor boot and BlazorWasmAntivirusProtection */
    const blazorCacheName = `blazor-resources-${document.baseURI.substring(document.location.origin.length)}`;
    /**
     * Keys of cached entries added via our new cache object or known from blazor.boot.json.
     * If it's known or being added this run (i.e., via the new cache object) and someone wants to delete it, we don't. Otherwise, we allow it.
     */
    const keysToKeepInCache: { [key: string]: boolean } = {};
    const fetchKnownKeys = fetch(`${environment.elnWebUrl}/_framework/blazor.boot.json`, { cache: 'no-cache', credentials: 'omit' })
      .then(response => response.json())
      .then(document => Object.entries(document.resources.assembly).forEach(([key, value]) => {
        keysToKeepInCache[`${environment.elnWebUrl}/_framework/${key}.${value}`] = true;
      }));

    /**
     * Named Blazor cache object, pre-created here so that its operations are intercepted once.
     * The cache itself may or may not already exist, with or without any files.
     */
    const blazorCache = fetchKnownKeys
      .then(() => caches.open(blazorCacheName).then(cache => {
        // intercept caches.open to return our overridden Blazor cache object when asked for
        const cachesOpen = caches.open.bind(caches);
        caches.open = (cacheName: string) => cacheName === blazorCacheName ? blazorCache : cachesOpen(cacheName);
        interceptPut(cache);
        interceptMatch(cache);
        interceptDelete(cache);
        return cache;
      }));

    const interceptPut = (cache: Cache) => {
      const cachePut = cache.put.bind(cache);
      cache.put = (request: RequestInfo | URL, response: Response) => {
        const url = getUrl(request);
        // This was all that we needed BlazorWasmAntivirusProtection.lib.module.js to do with its usedCacheKeys, if you delve into its code,
        // which for some reason is commented out.
        keysToKeepInCache[url] = true;
        return cachePut(request, response);
      };
    };

    const interceptMatch = (cache: Cache) => {
      const cacheMatch = cache.match.bind(cache);
      cache.match = (request: RequestInfo | URL, options?: CacheQueryOptions) => {
        const url = getUrl(request);
        const actualUrl = `${environment.elnWebUrl}${url.substring(url.indexOf('/_framework'))}`; // rewrite Url to what was put
        return cacheMatch(actualUrl, options);
      };
    };

    const interceptDelete = (cache: Cache) => {
      const cacheDelete = cache.delete.bind(cache);
      cache.delete = (request: RequestInfo | URL, options?: CacheQueryOptions) => {
        const url = getUrl(request);
        const silentlySkipDeleting = keysToKeepInCache[url];
        return silentlySkipDeleting ? Promise.resolve(true) : cacheDelete(request, options);
      };
    };

    const getUrl = (request: RequestInfo | URL) => request instanceof URL ? request.href : request instanceof Request ? request.url : request;
  }

  public loadBlazor(): Promise<IPreloadScriptResult> {
    return this.blazorLoadingPromise ?? this.loadBlazorInternal();
  }

  private loadBlazorInternal(): Promise<IPreloadScriptResult> {
    if (this.blazorLoadState !== BlazorLoadState.uninitialized && this.blazorLoadingPromise) return this.blazorLoadingPromise;

    this.blazorLoadStateChanged(BlazorLoadState.starting);
    return new Promise<IPreloadScriptResult>(resolve => {
      const blazorStartOption = {
        // likely some version of https://github.com/dotnet/aspnetcore/blob/3f1acb59718cadf111a0a796681e3d3509bb3381/src/Components/Web.JS/src/Platform/WebAssemblyStartOptions.ts
        loadBootResource: (type: any, name: any, defaultUri: any, _integrity: any) =>
          type === 'dotnetjs' ? `${environment.elnWebUrl}/_framework/${name}` : `${environment.elnWebUrl}/${defaultUri}`
      };
      // likely some version of https://github.com/dotnet/aspnetcore/blob/3f1acb59718cadf111a0a796681e3d3509bb3381/src/Components/Web.JS/src/GlobalExports.ts
      Blazor.start(blazorStartOption).then(() => {
        this.blazorLoadStateChanged(BlazorLoadState.started);
        resolve({ blazorLoadState: this.blazorLoadState });
      }).catch((error: any) => {
        if (error.message === 'Blazor has already started.') {
          console.warn('Blazor re-initialization attempted, ignoring this error.');
          console.trace();
          resolve({ blazorLoadState: this.blazorLoadState });
        } else {
          console.error('Blazor initialization failed', error);
          this.blazorLoadStateChanged(BlazorLoadState.failed);
          resolve({ blazorLoadState: this.blazorLoadState });
        }
      });
    });
  }

  private blazorLoadStateChanged(newState: BlazorLoadState): void {
    console.debug(`BlazorStartService: BlazorLoadStateChanged from '${this.blazorLoadState}' to '${newState}'`);
    this.blazorLoadState = newState;
    this.blazorLoadingStatusNotifications.next(this.blazorLoadState);
  }
}

export enum BlazorLoadState {
  uninitialized = 'uninitialized',
  starting = 'starting',
  started = 'started',
  stopped = 'stopped',
  failed = 'failed'
};

export interface IPreloadScriptResult {
  blazorLoadState: BlazorLoadState
}
