import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { InstrumentConnectionStatus } from '../../../api/instrument-admin/models';

@Component({
  selector: 'app-instrument-status-renderer',
  styles: [`.eln-instrument-status{
    display: inline-flex;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 5px;}`],
  template: `
    <span [ngStyle]="{ 'background': status }" class="eln-instrument-status"></span>&nbsp;{{ params.value }}
  `
})
export class InstrumentStatusRendererComponent implements ICellRendererAngularComp {
  private readonly onlineStatusColor = '#24A148';
  private readonly offlineStatusColor = '#3333334D';
  private readonly busyStatusColor = '#C33149';
  public params!: any;
  status = '';

  refresh(params: ICellRendererParams): boolean {
    return false;
  }

  agInit(params: any): void {
    this.params = params;
    this.status = this.getStatus();
  }

  getStatus() {
    if(this.params.data.instrumentConnectionStatus === InstrumentConnectionStatus.Online)
      return this.onlineStatusColor;
    if(this.params.data.instrumentConnectionStatus === InstrumentConnectionStatus.Busy)
      return this.busyStatusColor;
    return this.offlineStatusColor;
  }
}
