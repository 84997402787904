<app-repeat-for-each-chooser #RepeatModal
  [visible]="repeatForModal"
  (option)="repeatGroupChosen($event)"></app-repeat-for-each-chooser>
<div *ngIf="table && !table.isHidden">
  <div class="col-10 font-bold eln-exp-table-header module-item-title flex flex-row align-items-center gap-3">
    <p-contextMenu [target]="tableTitle" [model]="items" appendTo="body"></p-contextMenu>
    <div class="tableTitle bpt-eln-node-inline-editing-input-size minimum-height
    flex align-items-center" #tableTitle>
      <span id="{{table.tableId}}-title"
        class="eln-module-tab-header" [innerText]="table.itemTitle" *ngIf="!retitleEnabled"
        (dblclick)="checkForPermission()">
      </span>
      <div class="bpt-eln-inline-control">
        <app-recipe-node-title-changer
          *ngIf="retitleEnabled"
          [nodeId]="table.tableId"
          [parentNodeId]="parentNodeId"
          [nodeType]="nodeType.Table"
          [isRecipe]="isRecipe"
          [currentTitle]="table.itemTitle"
          (titleChangeHasCancelled)="retitleEnabled = false;"
          (titleChanged)="table.itemTitle = $event.title;retitleEnabled = false">
        </app-recipe-node-title-changer>
      </div>
    </div>
  </div>
  <div class="-mt-4 mb-3 p-0 pt-3 inline-block flex gap-3 align-items-center align-content-center"
    style="min-height: auto;">
    <ng-container *ngIf="containsRemovedRows">
      <span class="inline-block" i18n="@@containsRemovedRows">This table contains removed rows.</span>
      <p-button styleClass="p-button-link p-0 bpt-button-compact" (click)="loadRemovedRowsDialog()"
        i18n="@@viewRemovedRows">View Removed Rows</p-button>
    </ng-container>
    <p-button *ngIf="showRepeatForEachButton && table.allowRowAdd"
      i18n-label="@@countRepeatForEach"
      label="{{selectedCount}} Repeat for Each"
      styleClass="font-semibold bpt-button-compact"
      class="selectionButtons inline-block"
      icon="fa fa-repeat"
      iconPos="left"
      (onClick)="showRepeatForModal()"></p-button>
  </div>
  <bpt-grid
    #Grid
    gridId="{{ table.tableId }}"
    class="eln-grid"
    [dataSource]="nonRemovedData"
    [useJsJodaTypes]="true"
    (cellValueChanged)="cellValueEditing($event)"
    [beforeAddRow]="beforeAddRow"
    (rowsAdded)="rowsAdded($event)"
    [columnDefinitions]="columnDefinitions"
    [rowsPerPage]="50"
    [rowsPerPageOptions]="[50, 100, 200, 500]"
    [showAutoSizeButton]="table.allowRowAdd || numberOfRows > 0"
    [showFilterToggleButton]="table.allowRowAdd || numberOfRows > 0"
    [showGridOptionsButton]="false"
    [allowRowAdd]="table.allowRowAdd && isUserAllowedToEdit"
    [addMultipleRows]="table.allowMultipleRows"
    [paginated]="table.allowPagination"
    [rowsPerPage]="50"
    [rowsPerPageOptions]="[50, 100, 200, 500]"
    [suppressContextMenu]="suppressContextMenu"
    [paginationOverlayOptions]="gridPaginationOverlayOptions"
    (mousedown)="$event.stopPropagation()"
    [cellObjectCallbacks]="cellObjectCallbacks"
    [contextMenu]="contextMenu"
    (gridReady)="onGridReady()"
    [readOnly]="isGridReadOnly()"
    [rowSelection]="rowSelection"
    [ianaTimeZone]="labSiteTimeZone">
    <ng-container class="bpt-grid-toolbar-right-layout">
      <a *ngIf="containsRemovedRows"
        class="bpt-grid-toolbar-item table-grid-toolbar-view-removed-rows-icon reference-remove-icon ml-3"
        (click)="loadRemovedRowsDialog()">
        <span
          i18n-pTooltip="@@viewRemovedRows"
          class="fa icon-view-row"
          pTooltip="View Removed Rows"
          tooltipPosition="top">
          <span class="ml-1 viewRemovedRows-iconTxt" i18n="@@viewRemovedRows">View Removed Rows</span>
        </span>
      </a>
    </ng-container>
  </bpt-grid>
</div>
