import { Activity, Module } from 'model/experiment.interface';
import { ModuleNode, NodeType, TemplateType } from '../../../api/models';
import { TemplateInsertLocationOptions } from '../models/recipe-template-insert-location-options';
import { SelectedTemplate } from '../../models/selected-template';
import { TemplateEventService } from './template-event.service';
import { RecipeModel } from '../../../recipe/model/recipe';
import {
  RecipeAddTemplateCommand,
  RecipeTemplateAddedResponse,
  RecipeTemplateOptionalLocationReference,
  RecipeType
} from '../../../api/cookbook/models';
import { Injectable } from '@angular/core';
import { TemplateLocationOptions } from '../models/insert-location.interface';

export type ActiveTemplateModule = Module | ModuleNode;
export type DefinedActiveTemplate = Activity | ActiveTemplateModule;
export type ActiveTemplate = DefinedActiveTemplate | undefined;

type InsertLocation = RecipeTemplateOptionalLocationReference;

const parentTypes = new Set([
  RecipeType.Module,
  RecipeType.Activity,
  RecipeType.ActivityGroup
]);

const childTypes = new Set([
  RecipeType.Form,
  RecipeType.Table,
  RecipeType.TableForm
]);

@Injectable({
  providedIn: 'root'
})
export class TemplateApplyService {
  originalTitle = '';

  private static readonly InsertLocationMapOf: {
    [sourceTemplateType: string]: NodeType;
  } = {
    newModule: NodeType.Recipe,
    newModuleExistingActivity: NodeType.Activity,
    newActivity: NodeType.Recipe,
    existingModule: NodeType.Module,
    newTableOrFormOrPpr: NodeType.Recipe
  };
  public command: RecipeAddTemplateCommand  = {} as RecipeAddTemplateCommand;
  insertLocationOptions: TemplateInsertLocationOptions[] = [];
  titleCount = 0;
  private static readonly BreadcrumbDefaults = { tooltipPosition: 'bottom' };

  constructor(private readonly templateEventService: TemplateEventService) {
    this.templateEventService.TemplateAppliedResponse.subscribe((response) => {
      this.processRecipeTemplateAppliedResponse(response);
    });
  }

  private processRecipeTemplateAppliedResponse(response: RecipeTemplateAddedResponse) {
    if (response.notifications && response.notifications.notifications.length > 0) {
      this.templateEventService.TemplateApplyFailedNotification(response.notifications);
    } else {
      this.templateEventService.TemplateApplySuccessNotification(this.command, response);
    }
  }
  public isRecipe = false;

  public StartCommandPreparation(selectedTemplateType: TemplateType): this {
    this.command.skipVerifiedTemplateValidation = false;
    this.command.recipeType = this.templateEventService.assessTypeOnLoad(selectedTemplateType);
    const recipe = this.templateEventService.LoadedItem() as RecipeModel;
    if(parentTypes.has(this.command.recipeType) && childTypes.has(recipe.type)){
        this.command.generateSyntheticModule = true;
    }
    return this;
  }

  public PublishApplyRecipeTemplateCommand(templateNumber: string): this {
    this.setRecipeReference();
    this.templateEventService.PublishApplyRecipeTemplateCommand(this.command, templateNumber);
    return this;
  }

  public SetInsertedLocation(
    locationOption: TemplateLocationOptions,
    moduleOrActivityReferenceId: string,
    selectedTemplateLocationNodeId: string | undefined,
    title: string
  ): this {
    const recipe = this.templateEventService.LoadedItem() as RecipeModel;
    switch (locationOption) {
      case TemplateLocationOptions.AddToExistingModule:
        this.setExistingModuleLocation(
          moduleOrActivityReferenceId,
          selectedTemplateLocationNodeId as string,
          title
        );
        break;
      case TemplateLocationOptions.AddAsNewModuleAndExistingActivity:
        this.setNewModuleLocation(recipe.name);
        this.setExistingActivityLocation(
          moduleOrActivityReferenceId,
          selectedTemplateLocationNodeId as string,
          title
        );
        break;
      case TemplateLocationOptions.AddAsNewActivity:
        this.setNewActivityLocation(recipe.name);
        if (this.command.sourceTemplateType === TemplateType.Activity)
          this.command.recipeType = this.templateEventService.assessTypeOnLoad(TemplateType.Activity as TemplateType);
        if (this.command.sourceTemplateType === TemplateType.ActivityGroup)
          this.command.recipeType = this.templateEventService.assessTypeOnLoad(TemplateType.ActivityGroup as TemplateType);
        break;
      case TemplateLocationOptions.AddAsNewModule:
        this.setNewModuleLocation(recipe.name);
        this.command.recipeType = this.templateEventService.assessTypeOnLoad(TemplateType.Module as TemplateType);
        break;
      default:
        break;
    }
    return this;
  }

  public setSelectedRecipeReference(isRecipe: boolean, recipeId: string){
    if(isRecipe){
      this.command.isRecipe = true;
    }
    return this;
  }

  private setRecipeReference() {
    const loadedItem = this.templateEventService.LoadedItem();
    if (loadedItem) {
      this.command.recipeId = loadedItem?.recipeId;
      this.command.recipeNumber = loadedItem?.number;
    }
  }

  private setNewModuleLocation(recipeName: string): void {
    if (this.command.sourceTemplateType === TemplateType.Module) {
      return;
    }
    this.command.moduleReference = {
      isSynthetic: true,
      templateTitle: recipeName + ' Module'
    } as InsertLocation;
  }

  private setNewActivityLocation(recipeName: string): void {
    if (this.command.sourceTemplateType === TemplateType.Activity ||
      this.command.sourceTemplateType === TemplateType.ActivityGroup) {
      return;
    }
    if (
      this.command.sourceTemplateType === TemplateType.Form ||
      this.command.sourceTemplateType === TemplateType.Table ||
      this.command.sourceTemplateType === TemplateType.TableForm
    ) {
      this.command.moduleReference = {
        isSynthetic: true,
        templateTitle: recipeName + ' Module'
      } as InsertLocation;
    }
    this.command.activityReference = {
      isSynthetic: true,
      templateTitle: recipeName + ' Activity'
    } as InsertLocation;
  }

  private setExistingModuleLocation(moduleId: string, nodeId: string, title: string): void {
    this.command.moduleReference = {
      templateReferenceId: moduleId,
      isSynthetic: false,
      templateTitle: title,
      templateNodeId: nodeId
    } as RecipeTemplateOptionalLocationReference;
  }

  private setExistingActivityLocation(activityId: string, nodeId: string, title: string): void {
    this.command.activityReference = {
      templateReferenceId: activityId,
      isSynthetic: false,
      templateTitle: title,
      templateNodeId: nodeId
    } as RecipeTemplateOptionalLocationReference;
  }

  public getDefaultLocationBySourceTemplateType(selectedTemplateType: string): string | undefined {
    const currentActiveTemplate: ActiveTemplate = this.getCurrentActiveTemplate();
    if (!currentActiveTemplate) {
      return undefined;
    }
    if (
      currentActiveTemplate.itemType === NodeType.Activity &&
      selectedTemplateType === TemplateType.Module
    ) {
      return (currentActiveTemplate as Activity).activityId;
    }
    if (
      currentActiveTemplate.itemType === NodeType.Module &&
      selectedTemplateType === TemplateType.Module
    ) {
      return this.getActivityIdByActiveModule(currentActiveTemplate as Module);
    }
    if (selectedTemplateType === TemplateType.Form || selectedTemplateType === TemplateType.Table) {
      return this.getActiveModuleIdOrFirstInActivity(currentActiveTemplate);
    }
    return undefined;
  }

  public getDefaultLocationByInsertLocation(insertLocationType: NodeType): string | undefined {
    const currentActiveTemplate: ActiveTemplate = this.getCurrentActiveTemplate();
    if (!currentActiveTemplate) {
      return undefined;
    }
    switch (insertLocationType) {
      case NodeType.Module:
        return this.getActiveModuleIdOrFirstInActivity(currentActiveTemplate);
      case NodeType.Activity:
        return this.getActivityIdByActiveModuleAndActivity(currentActiveTemplate);
      default:
        return undefined;
    }
  }

  private getActivityIdByActiveModuleAndActivity(
    currentActiveTemplate: DefinedActiveTemplate
  ): string {
    if (currentActiveTemplate.itemType === NodeType.Activity) {
      return (currentActiveTemplate as Activity).activityId;
    } else {
      return this.getActivityIdByActiveModule(currentActiveTemplate as Module);
    }
  }

  private getActiveModuleIdOrFirstInActivity(currentActiveTemplate: DefinedActiveTemplate): string {
    if (currentActiveTemplate.itemType === NodeType.Module) {
      return (currentActiveTemplate as Module).moduleId;
    } else {
      return (currentActiveTemplate as Activity).dataModules[0].moduleId;
    }
  }

  private getActivityIdByActiveModule(currentActiveTemplate: Module): string {
    const experiment = this.templateEventService.LoadedItem();
    return experiment?.activities.find((a) =>
      a.dataModules.some((m) => m.moduleId === currentActiveTemplate.moduleId)
    )?.activityId as string;
  }

  public buildCommandForSourceTemplateSelection(selectedTemplate: SelectedTemplate) {
    this.command = TemplateApplyService.updateSourceTemplateToCommand(
      selectedTemplate,
      {} as RecipeAddTemplateCommand
    );
  }

  public ResetApplyCommand() {
    this.templateEventService.ApplyTemplateCommandChangedNotification(
      {} as RecipeAddTemplateCommand
    );
  }

  private static updateSourceTemplateToCommand(
    selectedTemplate: SelectedTemplate,
    currentCommandState: RecipeAddTemplateCommand
  ): RecipeAddTemplateCommand {
    const currentChangedCommand = currentCommandState;
    currentChangedCommand.templateReferenceId = selectedTemplate?.id;
    currentChangedCommand.templateTitle = selectedTemplate.name;
    currentChangedCommand.sourceTemplateType = selectedTemplate.templateType;
    currentChangedCommand.referenceNumber = selectedTemplate.number;
    return currentChangedCommand;
  }

  private getCurrentActiveTemplate(): ActiveTemplate {
    let currentActiveTemplate: ActiveTemplate;
    const subscription = this.templateEventService.ActiveTemplateChanged.subscribe(
      (activeTemplate) => {
        currentActiveTemplate = activeTemplate;
      }
    );
    subscription.unsubscribe();
    return currentActiveTemplate;
  }

  public prepareInsertLocationOptionsBySourceTemplateType(
    sourceTemplateType: TemplateLocationOptions
  ): TemplateInsertLocationOptions[] {
    const loadedItem = this.templateEventService.LoadedItem() as RecipeModel;
    let insertLocationType = NodeType.Recipe;
    if (
      (loadedItem?.activities && loadedItem?.activities.length > 0) ||
      (loadedItem?.modules && loadedItem?.modules.length > 0)
    ) {
      insertLocationType =
        TemplateApplyService.InsertLocationMapOf[sourceTemplateType] || NodeType.Invalid;
    }

    let options: TemplateInsertLocationOptions[] = [];
    if (insertLocationType === NodeType.Recipe) {
      options = [this.prepareRootInsertLocation(sourceTemplateType)];
    } else {
      options = this.prepareInsertLocationOptionsToApplyTemplate();
      options = options.filter((s) => s.type === insertLocationType);
    }
    this.insertLocationOptions = options;
    return options;
  }

  public prepareRootInsertLocation(
    type: TemplateLocationOptions
  ): TemplateInsertLocationOptions {
    const rootPrefix = this.isRecipe ? 'Recipe' : 'Experiment';
    const loadedItem = this.templateEventService.LoadedItem() as RecipeModel;
    const title = `${rootPrefix}: ${loadedItem?.name}`;
    return {
      id: loadedItem?.recipeId ?? '',
      displayLabel: title,
      displayLabelWithPrefix: title,
      type: NodeType.Recipe,
      childrenTitle: this.fetchChildrenTitle(loadedItem, type),
      nodeId: loadedItem?.recipeId ?? ''
    };
  }

  fetchChildrenTitle(recipe: RecipeModel, type: TemplateLocationOptions): string[] {
    switch (type) {
      case TemplateLocationOptions.AddAsNewModule:
        return recipe.modules.map((m) => m.moduleLabel);
      case TemplateLocationOptions.AddAsNewActivity:
        return recipe.activities.map((a) => a.itemTitle);
      case TemplateLocationOptions.AddToExistingModule:
        if (recipe.modules.length > 0) return recipe.modules.map((m) => m.moduleLabel);
        else {
          recipe.activities.forEach((a) => {
            return a.dataModules.map((m) => m.moduleLabel);
          });
        }
        return [];
      case TemplateLocationOptions.AddAsNewModuleAndExistingActivity:
          recipe.activities.forEach((a) => {
            return a.dataModules.map((m) => m.moduleLabel);
          });
          return [];
      case TemplateLocationOptions.AddAsNewTableOrFormOrPpr:
        const childrenTitle: string[] = [];
        if (recipe.tables.length > 0) recipe.tables.forEach((t) => childrenTitle.push(t.itemTitle));
        if (recipe.forms.length > 0) recipe.forms.forEach((f) => childrenTitle.push(f.itemTitle));
        return childrenTitle;
      default:
        return [];
    }
  }

  public prepareInsertLocationOptionsToApplyTemplate(): TemplateInsertLocationOptions[] {
    const recipe = this.templateEventService.LoadedItem() as RecipeModel;
    let templateList: TemplateInsertLocationOptions[] = [];
    if (!recipe) {
      return templateList;
    }
    recipe.activities.forEach((activity) => {
      TemplateApplyService.addActivitiesAsInsertLocation(templateList, activity);
      templateList = templateList.concat(
        TemplateApplyService.prepareModulesAsInsertLocations(
          activity.dataModules,
          activity.itemTitle
        )
      );
    });

    templateList = templateList.concat(
      TemplateApplyService.prepareModulesAsInsertLocations(recipe.modules, '')
    );
    return templateList;
  }

  private static addActivitiesAsInsertLocation(
    templateList: TemplateInsertLocationOptions[],
    activity: Activity
  ): void {
    templateList.push({
      id: activity.activityId,
      displayLabel: `${activity.itemTitle}`,
      displayLabelWithPrefix: $localize`:@@activity:Activity: ${activity.itemTitle}`,
      type: NodeType.Activity,
      childrenTitle: activity.dataModules.map((module) => module.moduleLabel),
      nodeId: activity.sourceTemplateId ?? ''
    });
  }

  private static prepareModulesAsInsertLocations(
    modules: Module[],
    activityTitle: string
  ): TemplateInsertLocationOptions[] {
    return modules.map((module) => {
      return {
        id: (module as any as ModuleNode).moduleId,
        nodeId: module.sourceTemplateId ?? '',
        displayLabel:
          activityTitle !== '' ? `${activityTitle} / ${module.moduleLabel}` : module.moduleLabel,
        type: NodeType.Module,
        displayLabelWithPrefix: [$localize`:@@activity:Activity: ${activityTitle}`, $localize`:@@module:Module: ${module.moduleLabel}`].join(', '),
        childrenTitle: module.items.map((c) => c.itemTitle)
      };
    });
  }
}
