/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { activityReferencesApplyReferenceTemplatePost$Json } from '../fn/activity-reference-events/activity-references-apply-reference-template-post-json';
import { ActivityReferencesApplyReferenceTemplatePost$Json$Params } from '../fn/activity-reference-events/activity-references-apply-reference-template-post-json';
import { activityReferencesApplyReferenceTemplatePost$Plain } from '../fn/activity-reference-events/activity-references-apply-reference-template-post-plain';
import { ActivityReferencesApplyReferenceTemplatePost$Plain$Params } from '../fn/activity-reference-events/activity-references-apply-reference-template-post-plain';
import { activityReferencesCrossReferenceChangePost$Json } from '../fn/activity-reference-events/activity-references-cross-reference-change-post-json';
import { ActivityReferencesCrossReferenceChangePost$Json$Params } from '../fn/activity-reference-events/activity-references-cross-reference-change-post-json';
import { activityReferencesCrossReferenceChangePost$Plain } from '../fn/activity-reference-events/activity-references-cross-reference-change-post-plain';
import { ActivityReferencesCrossReferenceChangePost$Plain$Params } from '../fn/activity-reference-events/activity-references-cross-reference-change-post-plain';
import { activityReferencesCrossReferenceRemovePost$Json } from '../fn/activity-reference-events/activity-references-cross-reference-remove-post-json';
import { ActivityReferencesCrossReferenceRemovePost$Json$Params } from '../fn/activity-reference-events/activity-references-cross-reference-remove-post-json';
import { activityReferencesCrossReferenceRemovePost$Plain } from '../fn/activity-reference-events/activity-references-cross-reference-remove-post-plain';
import { ActivityReferencesCrossReferenceRemovePost$Plain$Params } from '../fn/activity-reference-events/activity-references-cross-reference-remove-post-plain';
import { activityReferencesCrossReferenceRestorePost$Json } from '../fn/activity-reference-events/activity-references-cross-reference-restore-post-json';
import { ActivityReferencesCrossReferenceRestorePost$Json$Params } from '../fn/activity-reference-events/activity-references-cross-reference-restore-post-json';
import { activityReferencesCrossReferenceRestorePost$Plain } from '../fn/activity-reference-events/activity-references-cross-reference-restore-post-plain';
import { ActivityReferencesCrossReferenceRestorePost$Plain$Params } from '../fn/activity-reference-events/activity-references-cross-reference-restore-post-plain';
import { activityReferencesCrossReferencesPost$Json } from '../fn/activity-reference-events/activity-references-cross-references-post-json';
import { ActivityReferencesCrossReferencesPost$Json$Params } from '../fn/activity-reference-events/activity-references-cross-references-post-json';
import { activityReferencesCrossReferencesPost$Plain } from '../fn/activity-reference-events/activity-references-cross-references-post-plain';
import { ActivityReferencesCrossReferencesPost$Plain$Params } from '../fn/activity-reference-events/activity-references-cross-references-post-plain';
import { CrossReferenceAddedResponse } from '../models/cross-reference-added-response';
import { CrossReferenceChangedResponse } from '../models/cross-reference-changed-response';
import { CrossReferenceRemovedResponse } from '../models/cross-reference-removed-response';
import { CrossReferenceRestoredResponse } from '../models/cross-reference-restored-response';
import { ReferenceTemplateAppliedResponse } from '../models/reference-template-applied-response';

@Injectable({ providedIn: 'root' })
export class ActivityReferenceEventsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `activityReferencesCrossReferencesPost()` */
  static readonly ActivityReferencesCrossReferencesPostPath = '/activity-references/cross-references';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityReferencesCrossReferencesPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityReferencesCrossReferencesPost$Plain$Response(params?: ActivityReferencesCrossReferencesPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CrossReferenceAddedResponse>> {
    return activityReferencesCrossReferencesPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityReferencesCrossReferencesPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityReferencesCrossReferencesPost$Plain(params?: ActivityReferencesCrossReferencesPost$Plain$Params, context?: HttpContext): Observable<CrossReferenceAddedResponse> {
    return this.activityReferencesCrossReferencesPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CrossReferenceAddedResponse>): CrossReferenceAddedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityReferencesCrossReferencesPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityReferencesCrossReferencesPost$Json$Response(params?: ActivityReferencesCrossReferencesPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<CrossReferenceAddedResponse>> {
    return activityReferencesCrossReferencesPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityReferencesCrossReferencesPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityReferencesCrossReferencesPost$Json(params?: ActivityReferencesCrossReferencesPost$Json$Params, context?: HttpContext): Observable<CrossReferenceAddedResponse> {
    return this.activityReferencesCrossReferencesPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CrossReferenceAddedResponse>): CrossReferenceAddedResponse => r.body)
    );
  }

  /** Path part for operation `activityReferencesCrossReferenceChangePost()` */
  static readonly ActivityReferencesCrossReferenceChangePostPath = '/activity-references/cross-reference/change';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityReferencesCrossReferenceChangePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityReferencesCrossReferenceChangePost$Plain$Response(params?: ActivityReferencesCrossReferenceChangePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CrossReferenceChangedResponse>> {
    return activityReferencesCrossReferenceChangePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityReferencesCrossReferenceChangePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityReferencesCrossReferenceChangePost$Plain(params?: ActivityReferencesCrossReferenceChangePost$Plain$Params, context?: HttpContext): Observable<CrossReferenceChangedResponse> {
    return this.activityReferencesCrossReferenceChangePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CrossReferenceChangedResponse>): CrossReferenceChangedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityReferencesCrossReferenceChangePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityReferencesCrossReferenceChangePost$Json$Response(params?: ActivityReferencesCrossReferenceChangePost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<CrossReferenceChangedResponse>> {
    return activityReferencesCrossReferenceChangePost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityReferencesCrossReferenceChangePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityReferencesCrossReferenceChangePost$Json(params?: ActivityReferencesCrossReferenceChangePost$Json$Params, context?: HttpContext): Observable<CrossReferenceChangedResponse> {
    return this.activityReferencesCrossReferenceChangePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CrossReferenceChangedResponse>): CrossReferenceChangedResponse => r.body)
    );
  }

  /** Path part for operation `activityReferencesApplyReferenceTemplatePost()` */
  static readonly ActivityReferencesApplyReferenceTemplatePostPath = '/activity-references/apply-reference-template';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityReferencesApplyReferenceTemplatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityReferencesApplyReferenceTemplatePost$Plain$Response(params?: ActivityReferencesApplyReferenceTemplatePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ReferenceTemplateAppliedResponse>> {
    return activityReferencesApplyReferenceTemplatePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityReferencesApplyReferenceTemplatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityReferencesApplyReferenceTemplatePost$Plain(params?: ActivityReferencesApplyReferenceTemplatePost$Plain$Params, context?: HttpContext): Observable<ReferenceTemplateAppliedResponse> {
    return this.activityReferencesApplyReferenceTemplatePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReferenceTemplateAppliedResponse>): ReferenceTemplateAppliedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityReferencesApplyReferenceTemplatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityReferencesApplyReferenceTemplatePost$Json$Response(params?: ActivityReferencesApplyReferenceTemplatePost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ReferenceTemplateAppliedResponse>> {
    return activityReferencesApplyReferenceTemplatePost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityReferencesApplyReferenceTemplatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityReferencesApplyReferenceTemplatePost$Json(params?: ActivityReferencesApplyReferenceTemplatePost$Json$Params, context?: HttpContext): Observable<ReferenceTemplateAppliedResponse> {
    return this.activityReferencesApplyReferenceTemplatePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReferenceTemplateAppliedResponse>): ReferenceTemplateAppliedResponse => r.body)
    );
  }

  /** Path part for operation `activityReferencesCrossReferenceRemovePost()` */
  static readonly ActivityReferencesCrossReferenceRemovePostPath = '/activity-references/cross-reference/remove';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityReferencesCrossReferenceRemovePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityReferencesCrossReferenceRemovePost$Plain$Response(params?: ActivityReferencesCrossReferenceRemovePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CrossReferenceRemovedResponse>> {
    return activityReferencesCrossReferenceRemovePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityReferencesCrossReferenceRemovePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityReferencesCrossReferenceRemovePost$Plain(params?: ActivityReferencesCrossReferenceRemovePost$Plain$Params, context?: HttpContext): Observable<CrossReferenceRemovedResponse> {
    return this.activityReferencesCrossReferenceRemovePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CrossReferenceRemovedResponse>): CrossReferenceRemovedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityReferencesCrossReferenceRemovePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityReferencesCrossReferenceRemovePost$Json$Response(params?: ActivityReferencesCrossReferenceRemovePost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<CrossReferenceRemovedResponse>> {
    return activityReferencesCrossReferenceRemovePost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityReferencesCrossReferenceRemovePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityReferencesCrossReferenceRemovePost$Json(params?: ActivityReferencesCrossReferenceRemovePost$Json$Params, context?: HttpContext): Observable<CrossReferenceRemovedResponse> {
    return this.activityReferencesCrossReferenceRemovePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CrossReferenceRemovedResponse>): CrossReferenceRemovedResponse => r.body)
    );
  }

  /** Path part for operation `activityReferencesCrossReferenceRestorePost()` */
  static readonly ActivityReferencesCrossReferenceRestorePostPath = '/activity-references/cross-reference/restore';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityReferencesCrossReferenceRestorePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityReferencesCrossReferenceRestorePost$Plain$Response(params?: ActivityReferencesCrossReferenceRestorePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CrossReferenceRestoredResponse>> {
    return activityReferencesCrossReferenceRestorePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityReferencesCrossReferenceRestorePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityReferencesCrossReferenceRestorePost$Plain(params?: ActivityReferencesCrossReferenceRestorePost$Plain$Params, context?: HttpContext): Observable<CrossReferenceRestoredResponse> {
    return this.activityReferencesCrossReferenceRestorePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CrossReferenceRestoredResponse>): CrossReferenceRestoredResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityReferencesCrossReferenceRestorePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityReferencesCrossReferenceRestorePost$Json$Response(params?: ActivityReferencesCrossReferenceRestorePost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<CrossReferenceRestoredResponse>> {
    return activityReferencesCrossReferenceRestorePost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityReferencesCrossReferenceRestorePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityReferencesCrossReferenceRestorePost$Json(params?: ActivityReferencesCrossReferenceRestorePost$Json$Params, context?: HttpContext): Observable<CrossReferenceRestoredResponse> {
    return this.activityReferencesCrossReferenceRestorePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CrossReferenceRestoredResponse>): CrossReferenceRestoredResponse => r.body)
    );
  }

}
