/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ActivityInputCellChangedResponse } from '../models/activity-input-cell-changed-response';
import { activityInputEventsChangeCellValuePost$Json } from '../fn/activity-input-events/activity-input-events-change-cell-value-post-json';
import { ActivityInputEventsChangeCellValuePost$Json$Params } from '../fn/activity-input-events/activity-input-events-change-cell-value-post-json';
import { activityInputEventsChangeCellValuePost$Plain } from '../fn/activity-input-events/activity-input-events-change-cell-value-post-plain';
import { ActivityInputEventsChangeCellValuePost$Plain$Params } from '../fn/activity-input-events/activity-input-events-change-cell-value-post-plain';
import { activityInputEventsChangeInstrumentDateRemovedPost$Json } from '../fn/activity-input-events/activity-input-events-change-instrument-date-removed-post-json';
import { ActivityInputEventsChangeInstrumentDateRemovedPost$Json$Params } from '../fn/activity-input-events/activity-input-events-change-instrument-date-removed-post-json';
import { activityInputEventsChangeInstrumentDateRemovedPost$Plain } from '../fn/activity-input-events/activity-input-events-change-instrument-date-removed-post-plain';
import { ActivityInputEventsChangeInstrumentDateRemovedPost$Plain$Params } from '../fn/activity-input-events/activity-input-events-change-instrument-date-removed-post-plain';
import { activityInputEventsChangeInstrumentDescriptionPost$Json } from '../fn/activity-input-events/activity-input-events-change-instrument-description-post-json';
import { ActivityInputEventsChangeInstrumentDescriptionPost$Json$Params } from '../fn/activity-input-events/activity-input-events-change-instrument-description-post-json';
import { activityInputEventsChangeInstrumentDescriptionPost$Plain } from '../fn/activity-input-events/activity-input-events-change-instrument-description-post-plain';
import { ActivityInputEventsChangeInstrumentDescriptionPost$Plain$Params } from '../fn/activity-input-events/activity-input-events-change-instrument-description-post-plain';
import { activityInputEventsChangeInstrumentRemovedFromServicePost$Json } from '../fn/activity-input-events/activity-input-events-change-instrument-removed-from-service-post-json';
import { ActivityInputEventsChangeInstrumentRemovedFromServicePost$Json$Params } from '../fn/activity-input-events/activity-input-events-change-instrument-removed-from-service-post-json';
import { activityInputEventsChangeInstrumentRemovedFromServicePost$Plain } from '../fn/activity-input-events/activity-input-events-change-instrument-removed-from-service-post-plain';
import { ActivityInputEventsChangeInstrumentRemovedFromServicePost$Plain$Params } from '../fn/activity-input-events/activity-input-events-change-instrument-removed-from-service-post-plain';
import { activityInputEventsChangeSampleTestPost$Json } from '../fn/activity-input-events/activity-input-events-change-sample-test-post-json';
import { ActivityInputEventsChangeSampleTestPost$Json$Params } from '../fn/activity-input-events/activity-input-events-change-sample-test-post-json';
import { activityInputEventsChangeSampleTestPost$Plain } from '../fn/activity-input-events/activity-input-events-change-sample-test-post-plain';
import { ActivityInputEventsChangeSampleTestPost$Plain$Params } from '../fn/activity-input-events/activity-input-events-change-sample-test-post-plain';
import { activityInputEventsRefreshRowPost$Json } from '../fn/activity-input-events/activity-input-events-refresh-row-post-json';
import { ActivityInputEventsRefreshRowPost$Json$Params } from '../fn/activity-input-events/activity-input-events-refresh-row-post-json';
import { activityInputEventsRefreshRowPost$Plain } from '../fn/activity-input-events/activity-input-events-refresh-row-post-plain';
import { ActivityInputEventsRefreshRowPost$Plain$Params } from '../fn/activity-input-events/activity-input-events-refresh-row-post-plain';
import { activityInputEventsRemoveRowPost$Json } from '../fn/activity-input-events/activity-input-events-remove-row-post-json';
import { ActivityInputEventsRemoveRowPost$Json$Params } from '../fn/activity-input-events/activity-input-events-remove-row-post-json';
import { activityInputEventsRemoveRowPost$Plain } from '../fn/activity-input-events/activity-input-events-remove-row-post-plain';
import { ActivityInputEventsRemoveRowPost$Plain$Params } from '../fn/activity-input-events/activity-input-events-remove-row-post-plain';
import { activityInputEventsRestoreRowPost$Json } from '../fn/activity-input-events/activity-input-events-restore-row-post-json';
import { ActivityInputEventsRestoreRowPost$Json$Params } from '../fn/activity-input-events/activity-input-events-restore-row-post-json';
import { activityInputEventsRestoreRowPost$Plain } from '../fn/activity-input-events/activity-input-events-restore-row-post-plain';
import { ActivityInputEventsRestoreRowPost$Plain$Params } from '../fn/activity-input-events/activity-input-events-restore-row-post-plain';
import { activityInputEventsSelectMaintenanceEventPost$Json } from '../fn/activity-input-events/activity-input-events-select-maintenance-event-post-json';
import { ActivityInputEventsSelectMaintenanceEventPost$Json$Params } from '../fn/activity-input-events/activity-input-events-select-maintenance-event-post-json';
import { activityInputEventsSelectMaintenanceEventPost$Plain } from '../fn/activity-input-events/activity-input-events-select-maintenance-event-post-plain';
import { ActivityInputEventsSelectMaintenanceEventPost$Plain$Params } from '../fn/activity-input-events/activity-input-events-select-maintenance-event-post-plain';
import { activityInputEventsSelectStudyActivityPost$Json } from '../fn/activity-input-events/activity-input-events-select-study-activity-post-json';
import { ActivityInputEventsSelectStudyActivityPost$Json$Params } from '../fn/activity-input-events/activity-input-events-select-study-activity-post-json';
import { activityInputEventsSelectStudyActivityPost$Plain } from '../fn/activity-input-events/activity-input-events-select-study-activity-post-plain';
import { ActivityInputEventsSelectStudyActivityPost$Plain$Params } from '../fn/activity-input-events/activity-input-events-select-study-activity-post-plain';
import { ActivityInputRowRefreshedResponse } from '../models/activity-input-row-refreshed-response';
import { ActivityInputRowRemovedResponse } from '../models/activity-input-row-removed-response';
import { ActivityInputRowRestoredResponse } from '../models/activity-input-row-restored-response';
import { InstrumentDateRemovedChangedResponse } from '../models/instrument-date-removed-changed-response';
import { InstrumentDescriptionChangedResponse } from '../models/instrument-description-changed-response';
import { InstrumentRemovedFromServiceChangedResponse } from '../models/instrument-removed-from-service-changed-response';
import { MaintenanceEventSelectedResponse } from '../models/maintenance-event-selected-response';
import { SampleTestAddedResponse } from '../models/sample-test-added-response';
import { StudyActivitySelectedResponse } from '../models/study-activity-selected-response';

@Injectable({ providedIn: 'root' })
export class ActivityInputEventsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `activityInputEventsChangeSampleTestPost()` */
  static readonly ActivityInputEventsChangeSampleTestPostPath = '/activity-input-events/change-sample-test';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityInputEventsChangeSampleTestPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsChangeSampleTestPost$Plain$Response(params?: ActivityInputEventsChangeSampleTestPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<SampleTestAddedResponse>> {
    return activityInputEventsChangeSampleTestPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityInputEventsChangeSampleTestPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsChangeSampleTestPost$Plain(params?: ActivityInputEventsChangeSampleTestPost$Plain$Params, context?: HttpContext): Observable<SampleTestAddedResponse> {
    return this.activityInputEventsChangeSampleTestPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<SampleTestAddedResponse>): SampleTestAddedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityInputEventsChangeSampleTestPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsChangeSampleTestPost$Json$Response(params?: ActivityInputEventsChangeSampleTestPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<SampleTestAddedResponse>> {
    return activityInputEventsChangeSampleTestPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityInputEventsChangeSampleTestPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsChangeSampleTestPost$Json(params?: ActivityInputEventsChangeSampleTestPost$Json$Params, context?: HttpContext): Observable<SampleTestAddedResponse> {
    return this.activityInputEventsChangeSampleTestPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<SampleTestAddedResponse>): SampleTestAddedResponse => r.body)
    );
  }

  /** Path part for operation `activityInputEventsChangeCellValuePost()` */
  static readonly ActivityInputEventsChangeCellValuePostPath = '/activity-input-events/change-cell-value';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityInputEventsChangeCellValuePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsChangeCellValuePost$Plain$Response(params?: ActivityInputEventsChangeCellValuePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ActivityInputCellChangedResponse>> {
    return activityInputEventsChangeCellValuePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityInputEventsChangeCellValuePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsChangeCellValuePost$Plain(params?: ActivityInputEventsChangeCellValuePost$Plain$Params, context?: HttpContext): Observable<ActivityInputCellChangedResponse> {
    return this.activityInputEventsChangeCellValuePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ActivityInputCellChangedResponse>): ActivityInputCellChangedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityInputEventsChangeCellValuePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsChangeCellValuePost$Json$Response(params?: ActivityInputEventsChangeCellValuePost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ActivityInputCellChangedResponse>> {
    return activityInputEventsChangeCellValuePost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityInputEventsChangeCellValuePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsChangeCellValuePost$Json(params?: ActivityInputEventsChangeCellValuePost$Json$Params, context?: HttpContext): Observable<ActivityInputCellChangedResponse> {
    return this.activityInputEventsChangeCellValuePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ActivityInputCellChangedResponse>): ActivityInputCellChangedResponse => r.body)
    );
  }

  /** Path part for operation `activityInputEventsRestoreRowPost()` */
  static readonly ActivityInputEventsRestoreRowPostPath = '/activity-input-events/restore-row';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityInputEventsRestoreRowPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsRestoreRowPost$Plain$Response(params?: ActivityInputEventsRestoreRowPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ActivityInputRowRestoredResponse>> {
    return activityInputEventsRestoreRowPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityInputEventsRestoreRowPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsRestoreRowPost$Plain(params?: ActivityInputEventsRestoreRowPost$Plain$Params, context?: HttpContext): Observable<ActivityInputRowRestoredResponse> {
    return this.activityInputEventsRestoreRowPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ActivityInputRowRestoredResponse>): ActivityInputRowRestoredResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityInputEventsRestoreRowPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsRestoreRowPost$Json$Response(params?: ActivityInputEventsRestoreRowPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ActivityInputRowRestoredResponse>> {
    return activityInputEventsRestoreRowPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityInputEventsRestoreRowPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsRestoreRowPost$Json(params?: ActivityInputEventsRestoreRowPost$Json$Params, context?: HttpContext): Observable<ActivityInputRowRestoredResponse> {
    return this.activityInputEventsRestoreRowPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ActivityInputRowRestoredResponse>): ActivityInputRowRestoredResponse => r.body)
    );
  }

  /** Path part for operation `activityInputEventsRemoveRowPost()` */
  static readonly ActivityInputEventsRemoveRowPostPath = '/activity-input-events/remove-row';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityInputEventsRemoveRowPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsRemoveRowPost$Plain$Response(params?: ActivityInputEventsRemoveRowPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ActivityInputRowRemovedResponse>> {
    return activityInputEventsRemoveRowPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityInputEventsRemoveRowPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsRemoveRowPost$Plain(params?: ActivityInputEventsRemoveRowPost$Plain$Params, context?: HttpContext): Observable<ActivityInputRowRemovedResponse> {
    return this.activityInputEventsRemoveRowPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ActivityInputRowRemovedResponse>): ActivityInputRowRemovedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityInputEventsRemoveRowPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsRemoveRowPost$Json$Response(params?: ActivityInputEventsRemoveRowPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ActivityInputRowRemovedResponse>> {
    return activityInputEventsRemoveRowPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityInputEventsRemoveRowPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsRemoveRowPost$Json(params?: ActivityInputEventsRemoveRowPost$Json$Params, context?: HttpContext): Observable<ActivityInputRowRemovedResponse> {
    return this.activityInputEventsRemoveRowPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ActivityInputRowRemovedResponse>): ActivityInputRowRemovedResponse => r.body)
    );
  }

  /** Path part for operation `activityInputEventsRefreshRowPost()` */
  static readonly ActivityInputEventsRefreshRowPostPath = '/activity-input-events/refresh-row';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityInputEventsRefreshRowPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsRefreshRowPost$Plain$Response(params?: ActivityInputEventsRefreshRowPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ActivityInputRowRefreshedResponse>> {
    return activityInputEventsRefreshRowPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityInputEventsRefreshRowPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsRefreshRowPost$Plain(params?: ActivityInputEventsRefreshRowPost$Plain$Params, context?: HttpContext): Observable<ActivityInputRowRefreshedResponse> {
    return this.activityInputEventsRefreshRowPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ActivityInputRowRefreshedResponse>): ActivityInputRowRefreshedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityInputEventsRefreshRowPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsRefreshRowPost$Json$Response(params?: ActivityInputEventsRefreshRowPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ActivityInputRowRefreshedResponse>> {
    return activityInputEventsRefreshRowPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityInputEventsRefreshRowPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsRefreshRowPost$Json(params?: ActivityInputEventsRefreshRowPost$Json$Params, context?: HttpContext): Observable<ActivityInputRowRefreshedResponse> {
    return this.activityInputEventsRefreshRowPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ActivityInputRowRefreshedResponse>): ActivityInputRowRefreshedResponse => r.body)
    );
  }

  /** Path part for operation `activityInputEventsSelectStudyActivityPost()` */
  static readonly ActivityInputEventsSelectStudyActivityPostPath = '/activity-input-events/select-study-activity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityInputEventsSelectStudyActivityPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsSelectStudyActivityPost$Plain$Response(params?: ActivityInputEventsSelectStudyActivityPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<StudyActivitySelectedResponse>> {
    return activityInputEventsSelectStudyActivityPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityInputEventsSelectStudyActivityPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsSelectStudyActivityPost$Plain(params?: ActivityInputEventsSelectStudyActivityPost$Plain$Params, context?: HttpContext): Observable<StudyActivitySelectedResponse> {
    return this.activityInputEventsSelectStudyActivityPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<StudyActivitySelectedResponse>): StudyActivitySelectedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityInputEventsSelectStudyActivityPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsSelectStudyActivityPost$Json$Response(params?: ActivityInputEventsSelectStudyActivityPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<StudyActivitySelectedResponse>> {
    return activityInputEventsSelectStudyActivityPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityInputEventsSelectStudyActivityPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsSelectStudyActivityPost$Json(params?: ActivityInputEventsSelectStudyActivityPost$Json$Params, context?: HttpContext): Observable<StudyActivitySelectedResponse> {
    return this.activityInputEventsSelectStudyActivityPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<StudyActivitySelectedResponse>): StudyActivitySelectedResponse => r.body)
    );
  }

  /** Path part for operation `activityInputEventsSelectMaintenanceEventPost()` */
  static readonly ActivityInputEventsSelectMaintenanceEventPostPath = '/activity-input-events/select-maintenance-event';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityInputEventsSelectMaintenanceEventPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsSelectMaintenanceEventPost$Plain$Response(params?: ActivityInputEventsSelectMaintenanceEventPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MaintenanceEventSelectedResponse>> {
    return activityInputEventsSelectMaintenanceEventPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityInputEventsSelectMaintenanceEventPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsSelectMaintenanceEventPost$Plain(params?: ActivityInputEventsSelectMaintenanceEventPost$Plain$Params, context?: HttpContext): Observable<MaintenanceEventSelectedResponse> {
    return this.activityInputEventsSelectMaintenanceEventPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MaintenanceEventSelectedResponse>): MaintenanceEventSelectedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityInputEventsSelectMaintenanceEventPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsSelectMaintenanceEventPost$Json$Response(params?: ActivityInputEventsSelectMaintenanceEventPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<MaintenanceEventSelectedResponse>> {
    return activityInputEventsSelectMaintenanceEventPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityInputEventsSelectMaintenanceEventPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsSelectMaintenanceEventPost$Json(params?: ActivityInputEventsSelectMaintenanceEventPost$Json$Params, context?: HttpContext): Observable<MaintenanceEventSelectedResponse> {
    return this.activityInputEventsSelectMaintenanceEventPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<MaintenanceEventSelectedResponse>): MaintenanceEventSelectedResponse => r.body)
    );
  }

  /** Path part for operation `activityInputEventsChangeInstrumentDescriptionPost()` */
  static readonly ActivityInputEventsChangeInstrumentDescriptionPostPath = '/activity-input-events/change-instrument-description';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityInputEventsChangeInstrumentDescriptionPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsChangeInstrumentDescriptionPost$Plain$Response(params?: ActivityInputEventsChangeInstrumentDescriptionPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<InstrumentDescriptionChangedResponse>> {
    return activityInputEventsChangeInstrumentDescriptionPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityInputEventsChangeInstrumentDescriptionPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsChangeInstrumentDescriptionPost$Plain(params?: ActivityInputEventsChangeInstrumentDescriptionPost$Plain$Params, context?: HttpContext): Observable<InstrumentDescriptionChangedResponse> {
    return this.activityInputEventsChangeInstrumentDescriptionPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<InstrumentDescriptionChangedResponse>): InstrumentDescriptionChangedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityInputEventsChangeInstrumentDescriptionPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsChangeInstrumentDescriptionPost$Json$Response(params?: ActivityInputEventsChangeInstrumentDescriptionPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<InstrumentDescriptionChangedResponse>> {
    return activityInputEventsChangeInstrumentDescriptionPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityInputEventsChangeInstrumentDescriptionPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsChangeInstrumentDescriptionPost$Json(params?: ActivityInputEventsChangeInstrumentDescriptionPost$Json$Params, context?: HttpContext): Observable<InstrumentDescriptionChangedResponse> {
    return this.activityInputEventsChangeInstrumentDescriptionPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<InstrumentDescriptionChangedResponse>): InstrumentDescriptionChangedResponse => r.body)
    );
  }

  /** Path part for operation `activityInputEventsChangeInstrumentDateRemovedPost()` */
  static readonly ActivityInputEventsChangeInstrumentDateRemovedPostPath = '/activity-input-events/change-instrument-date-removed';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityInputEventsChangeInstrumentDateRemovedPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsChangeInstrumentDateRemovedPost$Plain$Response(params?: ActivityInputEventsChangeInstrumentDateRemovedPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<InstrumentDateRemovedChangedResponse>> {
    return activityInputEventsChangeInstrumentDateRemovedPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityInputEventsChangeInstrumentDateRemovedPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsChangeInstrumentDateRemovedPost$Plain(params?: ActivityInputEventsChangeInstrumentDateRemovedPost$Plain$Params, context?: HttpContext): Observable<InstrumentDateRemovedChangedResponse> {
    return this.activityInputEventsChangeInstrumentDateRemovedPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<InstrumentDateRemovedChangedResponse>): InstrumentDateRemovedChangedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityInputEventsChangeInstrumentDateRemovedPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsChangeInstrumentDateRemovedPost$Json$Response(params?: ActivityInputEventsChangeInstrumentDateRemovedPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<InstrumentDateRemovedChangedResponse>> {
    return activityInputEventsChangeInstrumentDateRemovedPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityInputEventsChangeInstrumentDateRemovedPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsChangeInstrumentDateRemovedPost$Json(params?: ActivityInputEventsChangeInstrumentDateRemovedPost$Json$Params, context?: HttpContext): Observable<InstrumentDateRemovedChangedResponse> {
    return this.activityInputEventsChangeInstrumentDateRemovedPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<InstrumentDateRemovedChangedResponse>): InstrumentDateRemovedChangedResponse => r.body)
    );
  }

  /** Path part for operation `activityInputEventsChangeInstrumentRemovedFromServicePost()` */
  static readonly ActivityInputEventsChangeInstrumentRemovedFromServicePostPath = '/activity-input-events/change-instrument-removed-from-service';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityInputEventsChangeInstrumentRemovedFromServicePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsChangeInstrumentRemovedFromServicePost$Plain$Response(params?: ActivityInputEventsChangeInstrumentRemovedFromServicePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<InstrumentRemovedFromServiceChangedResponse>> {
    return activityInputEventsChangeInstrumentRemovedFromServicePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityInputEventsChangeInstrumentRemovedFromServicePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsChangeInstrumentRemovedFromServicePost$Plain(params?: ActivityInputEventsChangeInstrumentRemovedFromServicePost$Plain$Params, context?: HttpContext): Observable<InstrumentRemovedFromServiceChangedResponse> {
    return this.activityInputEventsChangeInstrumentRemovedFromServicePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<InstrumentRemovedFromServiceChangedResponse>): InstrumentRemovedFromServiceChangedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityInputEventsChangeInstrumentRemovedFromServicePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsChangeInstrumentRemovedFromServicePost$Json$Response(params?: ActivityInputEventsChangeInstrumentRemovedFromServicePost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<InstrumentRemovedFromServiceChangedResponse>> {
    return activityInputEventsChangeInstrumentRemovedFromServicePost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityInputEventsChangeInstrumentRemovedFromServicePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activityInputEventsChangeInstrumentRemovedFromServicePost$Json(params?: ActivityInputEventsChangeInstrumentRemovedFromServicePost$Json$Params, context?: HttpContext): Observable<InstrumentRemovedFromServiceChangedResponse> {
    return this.activityInputEventsChangeInstrumentRemovedFromServicePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<InstrumentRemovedFromServiceChangedResponse>): InstrumentRemovedFromServiceChangedResponse => r.body)
    );
  }

}
