/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { Labsite } from '../models/labsite';
import { LabsiteGetResponse } from '../models/labsite-get-response';
import { labsitesGet$Json } from '../fn/labsite/labsites-get-json';
import { LabsitesGet$Json$Params } from '../fn/labsite/labsites-get-json';
import { labsitesGet$Plain } from '../fn/labsite/labsites-get-plain';
import { LabsitesGet$Plain$Params } from '../fn/labsite/labsites-get-plain';
import { labsitesLabsiteCodeSubBusinessUnitsGet$Json } from '../fn/labsite/labsites-labsite-code-sub-business-units-get-json';
import { LabsitesLabsiteCodeSubBusinessUnitsGet$Json$Params } from '../fn/labsite/labsites-labsite-code-sub-business-units-get-json';
import { labsitesLabsiteCodeSubBusinessUnitsGet$Plain } from '../fn/labsite/labsites-labsite-code-sub-business-units-get-plain';
import { LabsitesLabsiteCodeSubBusinessUnitsGet$Plain$Params } from '../fn/labsite/labsites-labsite-code-sub-business-units-get-plain';
import { labsitesSubBusinessUnitsGet$Json } from '../fn/labsite/labsites-sub-business-units-get-json';
import { LabsitesSubBusinessUnitsGet$Json$Params } from '../fn/labsite/labsites-sub-business-units-get-json';
import { labsitesSubBusinessUnitsGet$Plain } from '../fn/labsite/labsites-sub-business-units-get-plain';
import { LabsitesSubBusinessUnitsGet$Plain$Params } from '../fn/labsite/labsites-sub-business-units-get-plain';

@Injectable({ providedIn: 'root' })
export class LabsiteService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `labsitesLabsiteCodeSubBusinessUnitsGet()` */
  static readonly LabsitesLabsiteCodeSubBusinessUnitsGetPath = '/labsites/{labsiteCode}/sub-business-units';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labsitesLabsiteCodeSubBusinessUnitsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  labsitesLabsiteCodeSubBusinessUnitsGet$Plain$Response(params: LabsitesLabsiteCodeSubBusinessUnitsGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<LabsiteGetResponse>> {
    return labsitesLabsiteCodeSubBusinessUnitsGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `labsitesLabsiteCodeSubBusinessUnitsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  labsitesLabsiteCodeSubBusinessUnitsGet$Plain(params: LabsitesLabsiteCodeSubBusinessUnitsGet$Plain$Params, context?: HttpContext): Observable<LabsiteGetResponse> {
    return this.labsitesLabsiteCodeSubBusinessUnitsGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<LabsiteGetResponse>): LabsiteGetResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labsitesLabsiteCodeSubBusinessUnitsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  labsitesLabsiteCodeSubBusinessUnitsGet$Json$Response(params: LabsitesLabsiteCodeSubBusinessUnitsGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<LabsiteGetResponse>> {
    return labsitesLabsiteCodeSubBusinessUnitsGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `labsitesLabsiteCodeSubBusinessUnitsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  labsitesLabsiteCodeSubBusinessUnitsGet$Json(params: LabsitesLabsiteCodeSubBusinessUnitsGet$Json$Params, context?: HttpContext): Observable<LabsiteGetResponse> {
    return this.labsitesLabsiteCodeSubBusinessUnitsGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<LabsiteGetResponse>): LabsiteGetResponse => r.body)
    );
  }

  /** Path part for operation `labsitesSubBusinessUnitsGet()` */
  static readonly LabsitesSubBusinessUnitsGetPath = '/labsites/sub-business-units';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labsitesSubBusinessUnitsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  labsitesSubBusinessUnitsGet$Plain$Response(params?: LabsitesSubBusinessUnitsGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<LabsiteGetResponse>> {
    return labsitesSubBusinessUnitsGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `labsitesSubBusinessUnitsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  labsitesSubBusinessUnitsGet$Plain(params?: LabsitesSubBusinessUnitsGet$Plain$Params, context?: HttpContext): Observable<LabsiteGetResponse> {
    return this.labsitesSubBusinessUnitsGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<LabsiteGetResponse>): LabsiteGetResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labsitesSubBusinessUnitsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  labsitesSubBusinessUnitsGet$Json$Response(params?: LabsitesSubBusinessUnitsGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<LabsiteGetResponse>> {
    return labsitesSubBusinessUnitsGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `labsitesSubBusinessUnitsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  labsitesSubBusinessUnitsGet$Json(params?: LabsitesSubBusinessUnitsGet$Json$Params, context?: HttpContext): Observable<LabsiteGetResponse> {
    return this.labsitesSubBusinessUnitsGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<LabsiteGetResponse>): LabsiteGetResponse => r.body)
    );
  }

  /** Path part for operation `labsitesGet()` */
  static readonly LabsitesGetPath = '/labsites';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labsitesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  labsitesGet$Plain$Response(params?: LabsitesGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Labsite>>> {
    return labsitesGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `labsitesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  labsitesGet$Plain(params?: LabsitesGet$Plain$Params, context?: HttpContext): Observable<Array<Labsite>> {
    return this.labsitesGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Labsite>>): Array<Labsite> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labsitesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  labsitesGet$Json$Response(params?: LabsitesGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Labsite>>> {
    return labsitesGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `labsitesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  labsitesGet$Json(params?: LabsitesGet$Json$Params, context?: HttpContext): Observable<Array<Labsite>> {
    return this.labsitesGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Labsite>>): Array<Labsite> => r.body)
    );
  }

}
