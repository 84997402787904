<div class="eln-activity">
  <p-confirmDialog
  [position]="'center'"
  key="outputConfirmationDialog"
  rejectButtonStyleClass="bpt-button-compact"
  acceptButtonStyleClass="bpt-button-compact"
>
</p-confirmDialog>

<app-empower-tabs
  [importedResultSetSummary]="importedResultSetsSummary || []"
  (SendResultSetEvent)="removeResultSet($event)"
  (RefreshResultSetButtonId)="handleRefreshResultSetButtonId($event)">
</app-empower-tabs>
<div id="eln-empowerDialog">
  <p-dialog
    [(visible)]="outputEmpowerData.canShowEmpowerLogin"
    [closable]="outputEmpowerData.empowerOptions.closable"
    [header]="loginTitle"
    [modal]="outputEmpowerData.empowerOptions.modal"
    [draggable]="outputEmpowerData.empowerOptions.draggable"
    [resizable]="outputEmpowerData.empowerOptions.resizable"
    (onHide)="closeLoginDialog()"
    [blockScroll]="outputEmpowerData.empowerOptions.blockScroll"
    styleClass="empowerLoginDialogClass"
    id="empower-login"
  >
    <div>
      <div class="textfield-empower">
        <bpt-text-input
          #empowerUsername
          id="empower-username"
          label="Username"
          i18n-label="@@userName"
          [(ngModel)]="outputEmpowerData.empowerData.username"
          (ngModelChange)="updateLoginStatus()"
          [readOnly] = "isResultSetImported || isImportEvent"
          [required]="true"
          [errorFlag]="outputEmpowerData.empowerData.usernameEmpty"
          [errorMessage]="outputEmpowerData.errorText"
        ></bpt-text-input>
      </div>
      <div class="textfield-empower">
        <bpt-text-input
          id="empower-password"
          label="Password"
          i18n-label="@@password"
          inputType="password"
          [(ngModel)]="outputEmpowerData.empowerData.password"
          (ngModelChange)="updateLoginStatus()"
          [required]="true"
          [errorFlag]="outputEmpowerData.empowerData.passwordEmpty"
          [maxlength]="outputEmpowerData.empowerPasswordMaxLength"
          [errorMessage]="outputEmpowerData.errorText"
        ></bpt-text-input>
      </div>
      <div class="textfield-empower" *ngIf="outputEmpowerData.empowerDatabaseOptions || outputEmpowerData.empowerData.database">
        <bpt-dropdown
          #empowerDatabase
          id="empower-database"
          label="Select Database"
          i18n-label="@@selectDatabase"
          [options]="outputEmpowerData.empowerDatabaseOptions"
          [(ngModel)]="outputEmpowerService.empowerData.database"
          (ngModelChange)="updateLoginStatus()"
          [required]="true"
          [readOnly] = "isResultSetImported || isImportEvent || outputEmpowerData.empowerData.databaseReadOnly"
          [errorFlag]="outputEmpowerData.empowerData.databaseEmpty"
          [errorMessage]="outputEmpowerData.errorText"
        ></bpt-dropdown>
      </div>
      <div class="textfield-empower mb-3">
        <bpt-text-input
          #empowerProject
          id="empower-project"
          label="Project"
          i18n-label="@@Project"
          [readOnly] = "isResultSetImported || isImportEvent || outputEmpowerData.empowerData.projectReadOnly"
          [(ngModel)]="outputEmpowerData.empowerData.project"
          (ngModelChange)="updateLoginStatus()"
          [required]="true"
          [errorFlag]="outputEmpowerData.empowerData.projectEmpty"
          [errorMessage]="outputEmpowerData.errorText"
        ></bpt-text-input>
      </div>
      <div class="text-right flex column-gap-3 justify-content-end">
        <p-button
          id="empower-cancel"
          label="Cancel"
          i18n-label="@@cancel"
          styleClass="bpt-button-compact p-button-outlined btn-cancel"
          (onClick)="cancelLoginDialog()"
        >
        </p-button>
        <p-button
          id="empower-login"
          label="Log In"
          [disabled]="outputEmpowerData.disableEmpowerLogin"
          i18n-label="@@login"
          styleClass="bpt-button-compact"
          (onClick)="callToEmpower()"
        >
        </p-button>
      </div>
      <p class="textfield-error">{{ outputEmpowerData.empowerResponse }}</p>
    </div>
  </p-dialog>
  <p-dialog
    [(visible)]="outputEmpowerData.canShowEmpowerStructure"
    [closable]="outputEmpowerData.empowerOptions.closable"
    [header]="selectResultSetTitle"
    [modal]="outputEmpowerData.empowerOptions.modal"
    [draggable]="outputEmpowerData.empowerOptions.draggable"
    [resizable]="outputEmpowerData.empowerOptions.resizable"
    (onHide)="closeQuarterStructureDialog()"
    [blockScroll]="outputEmpowerData.empowerOptions.blockScroll"
    styleClass="empowerLoginDialogClass"
    id="empower-structure"
  >
    <div>
      <div *ngIf="!outputEmpowerData.emptyQuarters; else noData">
        <bpt-tree
          #quarterTree
          id="empower-quarter"
          [treeData]="outputEmpowerData.resultSetTreeData"
          [selectionMode]="outputEmpowerData.empowerOptions.selection"
          (nodeSelectClick)="selectResultSet($event)"
          (onNodeUnselect)="selectResultSet($event)"
          [isTreeFilterable]="!outputEmpowerData.emptyQuarters"
        >
        </bpt-tree>
      </div>
      <ng-template #noData class="eln-activity-output-no-data">
        <div class="eln-activity-output-no-data-img">
          <img
            src="../../../assets/eln-assets/no-data.svg"
            alt=""
            id="eln-no-data-img"
            class="activity-outputs-no-data-img"
          />
          <span class="activity-outputs-no-data-text" i18n="@@noFilesFound">No files found.</span>
        </div>
      </ng-template>
      <div class="button-bottom flex column-gap-3 justify-content-end">
        <p-button
          id="empower-close"
          label="Cancel"
          i18n-label="@@cancel"
          styleClass="bpt-button-compact p-button-outlined btn-cancel"
          (onClick)="cancelQuarterStructureDialog()"
        >
        </p-button>
        <p-button
          id="empower-import"
          label="Import"
          [disabled]="!outputEmpowerData.canImportResultSets || isResultSetExists"
          i18n-label="@@import"
          styleClass="bpt-button-compact"
          (onClick)="importSelectedResultSet()"
        >
        </p-button>
      </div>
      <p *ngIf="isResultSetExists" class="textfield-error exists-error">The selected Result Set has already been imported into this activity. You can refresh the table to pull new data from Empower</p>
    </div>
  </p-dialog>
</div>
<div id="eln-empowerSelectColumns">
  <p-dialog    
  [(visible)]="canShowSelectColumnsToImportDialogBox"
  [closable]="outputEmpowerData.empowerOptions.closable"
  [header]="selectColumnsToImport"
  [modal]="outputEmpowerData.empowerOptions.modal"
  [draggable]="outputEmpowerData.empowerOptions.draggable"
  [resizable]="outputEmpowerData.empowerOptions.resizable"
  (onHide)="closeImportColumns()"
  [blockScroll]="outputEmpowerData.empowerOptions.blockScroll"
  styleClass="empowerLoginDialogClass"
  id="empowerSelectColumns">
  <div class="textfield-cover">
    <bpt-dropdown
      label="Select columns to import"
      labelField="columnName"
      valueField="columnName"
      i18n-label="@@selectColumnsToImport"
      [options]="columnOptions"
      [(ngModel)]="selectedItems"
      (ngModelChange)="sortSelectedItems()"
      [multiSelect]="true"
      [showToggleAll]="false"
      [showHeader]="true"
      [showFilter]="true"
      inputId="eln"
      [enableFavorites]="true"
      [favoriteOptions]="preferredOptions"
      (markedFavorite)="setColumnFavorite($event)"
      (unmarkedFavorite)="removeColumnFavorite($event)"
    ></bpt-dropdown>
  </div>
  <div class="button-bottom column-gap-3 justify-content-end">
    <p-button
    label="Cancel"
    i18n-label="@@cancel"
    styleClass="bpt-button-compact p-button-outlined btn-cancel"
    (onClick)="cancelImportColumns()"
    >
    </p-button>
    <p-button
    label="Import"
    i18n-label="@@import"
    styleClass="bpt-button-compact"
    (onClick)="importResultSetData()"
    >
    </p-button>
  </div>
</p-dialog>
</div>
