import { TemplateRule } from '../rule/template-rule';
import { RuleInput } from './rule-input';

export class RuleEvaluationInputOf<TEventContext> {
  public readonly ruleInput: RuleInput<TEventContext> = {} as RuleInput<TEventContext>;
  constructor(rule: TemplateRule, eventContext: TEventContext) {
    this.ruleInput.inputs = [{ input: eventContext }];
    this.ruleInput.rule = rule;
  }

  public convertInputToJsonStringArray(): Array<string> {
    // logging will be post demo.
    console.group('input');
    console.log(this.ruleInput.inputs[0].input);
    console.groupEnd();

    const inputJson = new Array<string>();
    if (this.ruleInput.inputs.length > 1) {
      this.ruleInput.inputs.forEach((i) => inputJson.push(JSON.stringify(i)));
    } else {
      inputJson.push(JSON.stringify(this.ruleInput.inputs[0]));
    }
    return inputJson;
  }
}
