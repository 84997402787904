import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { BptTextInputComponent } from 'bpt-ui-library/bpt-text-input';
import { clone, cloneDeep, isEmpty } from 'lodash-es';
import { ExperimentNodeTitleChangedNotification, NodeType } from '../../api/data-entry/models';
import { ExperimentNodeRetitleService } from '../services/experiment-node-re-title.service';

@Component({
  selector: 'app-experiment-node-title-changer',
  templateUrl: './experiment-node-title-changer.component.html'
})
export class ExperimentNodeTitleChangerComponent implements OnInit, AfterViewInit {
  @Input() nodeId!: string;
  @Input() parentNodeId: string[] = [];
  @Input() nodeType!: NodeType;
  @Input() currentTitle!: string;

  @Output() titleChanged = new EventEmitter<ExperimentNodeTitleChangedNotification>();
  @Output() titleChangeHasCancelled = new EventEmitter();

  @ViewChild('bptInputEntry') bptInputComponent!: BptTextInputComponent;

  title!: string;
  hasError = false;
  titleSnapshotWhileErrorOccured!: string;

  constructor(private readonly experimentNodeReTitleService: ExperimentNodeRetitleService
  ) { }
  ngOnInit(): void {
    this.title = clone(this.currentTitle);
  }

  ngAfterViewInit(): void {
    this.focusTheInput();
  }

  onBlur(_e: Event) {
    this.modelEdited(this.bptInputComponent.input.nativeElement.value);
  }

  modelEdited(title: string) {
    if (!title || isEmpty(title.trim())) {
      this.markAsInvalid();
      this.focusTheInput();
      return;
    }
    if (title === this.currentTitle || this.shouldCancel()) {
      this.titleChangeHasCancelled.next(null);
      return;
    }
    this.experimentNodeReTitleService
      .changeNodeTitle(this.nodeType, this.nodeId, title, this.currentTitle, this.parentNodeId)
      .subscribe({
        next: (response) => {
          if (response) {
            this.titleChanged.next(response);
          } else {
            this.markAsInvalid();
            this.focusTheInput();
          }
        }
      });
  }

  onkeyUp(e: KeyboardEvent) {
    if (e.key === 'Escape') {
      this.titleChangeHasCancelled.next(null);
    }
  }

  focusTheInput(): void {
    setTimeout(() => {
      try {
        this.bptInputComponent?.focus();
        this.bptInputComponent?.input?.nativeElement?.select();
      } catch (error) {
        console.warn('Failed to auto focus node tile input', error);
      }
    }, 250);
  }

  markAsInvalid(): void{
    this.hasError = true;
    this.titleSnapshotWhileErrorOccured = cloneDeep(this.title);
  }

  shouldCancel(): boolean {
    return this.hasError && this.title === this.titleSnapshotWhileErrorOccured;
  }
}
