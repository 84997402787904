<div class="preparation-layout-content">
  <bpt-slider
    id="elnCreatePreparationSlider"
    [(visible)]="sliderOptions.visible"
    [closeOnEscape]="sliderOptions.closeOnEscape"
    [headerText]="sliderOptions.headerText"
    [size]="sliderOptions.size"
    [isFooterSticky]="sliderOptions.isFooterSticky"
    [displayFooter]="sliderOptions.displayFooter"
    [displayFooterWithPrimaryButton]="sliderOptions.displayFooterWithPrimaryButton"
    [displayFooterWithSecondaryButton]="sliderOptions.displayFooterWithSecondaryButton"
    [isModal]="sliderOptions.isModal"
    [sliderClosing]="sliderClosing"
    (visibleChange)="sliderVisibleChange($event)"
    >
    <div class="slider-content" >
      <div class="flex eln-flex-container" id="flex-container">
        <div class="eln-flex-item">
          <bpt-text-input
            inputId="eln-createPreparationName"
            name="eln-createPreparationName"
            ngDefaultControl
            i18n-label="@@name"
            label="Name"
            [ngModel]="newPreparation.name.value"
            (ngModelChange)="handlePreparationNameChange($event)"
            [required]="true"
            [errorFlag]="preparationNameHasError"
            [errorMessage]="requiredFieldMessage"
            ></bpt-text-input>
        </div>
        <div class="eln-flex-item">
          <bpt-text-input
            inputId="eln-createPreparationFormulation"
            name="eln-createPreparationFormulation"
            i18n-label="@@formulationOrComponents"
            label="Formulation/Components"
            [ngModel]="newPreparation.formulationOrComponents?.value"
            (ngModelChange)="newPreparation.formulationOrComponents = convertToStringValue($event,'formulation')"
            [ngModelOptions]="{ updateOn: 'blur' }"></bpt-text-input>
        </div>
        <div class="flex eln-flex flex-three-column-layout">
          <bpt-quantity
            id="eln-createPreparationConcentration"
            label="Concentration"
            i18n-label="@@concentration"
            name="concentration"
            [commitValueOnEnter]="quantityOptions.commitValueOnEnter"
            ngDefaultControl
            [enableSignificantFigures]="quantityOptions.enableSignificantFigures"
            [allowedUnits]="concentrationUnits"
            [cancelValueOnEscape]="quantityOptions.cancelValueOnEscape"
            [ngModel]="createPreparationQuantityModels.concentrationModel"
            [ngModelOptions]="{ updateOn: 'change' }"
            [allowNA]="quantityOptions.allowNA"
            [allowDecimal]="quantityOptions.allowDecimal"
            [highlightAllOnFocus]="quantityOptions.highlightAllOnFocus"
            [suppressContextMenu]="quantityOptions.suppressContextMenu"
            [tabIndex]=3
            (quantityChange)="convertQuantity($event, 'concentration')">
          </bpt-quantity>
        </div>
        <div class="flex eln-flex flex-row gap-3 ">
          <bpt-datetime *ngIf="!whileSuitableForUse && !basedOnStability"
            #calendarInput
            i18n-label="@@expiration"
            [commitValueOnEnter]="expirationOptions.commitValueOnEnter"
            label="Expiration"
            [ngModel]="expirationModel"
            [readonlyInput]="true"
            [ngModelOptions]="{ updateOn: 'blur' }"
            id="eln-createPreparationExpiration"
            name="eln-createPreparationExpiration"
            [required]="expirationOptions.required && !isParentRecipe"
            [includeTime]="expirationOptions.includeTime"
            [hourFormat]="expirationOptions.hourFormat"
            [useJsJodaTypes]="true"
            class="no-popup mb-1"
            (mousedown)="onExpirationClick($event)"
            [errorFlag]="expirationHasError"
            [errorMessage]="requiredFieldMessage"
            [ianaTimeZone]="labSiteTimeZone"
            >
          </bpt-datetime>
          <bpt-text-input *ngIf="whileSuitableForUse"
            i18n-label="@@expiration"
            label="Expiration"
            name="Expiration"
            [ngModel]="suitableForUseText"
            class="suitableForUse mb-1"
            (mousedown)="onExpirationClick($event)"
            [required]="true && !isParentRecipe"
            >
          </bpt-text-input>
          <bpt-text-input *ngIf="basedOnStability"
            i18n-label="@@expiration"
            label="Expiration"
            name="Expiration"
            [ngModel]="basedOnStabilityText"
            class="suitableForUse mb-1"
            (mousedown)="onExpirationClick($event)"
            [required]="false">
          </bpt-text-input>
          <bpt-dropdown
            inputId="eln-create-preparation-storage-condition"
            name="eln-create-preparation-storage-condition"
            ngDefaultControl
            [editable]="true"
            label="Storage Condition"
            i18n-label="@@storageCondition"
            [showClear]="dropdownOptions.showClear"
            [showFilter]="dropdownOptions.showFilter"
            [multiSelect]="dropdownOptions.multiSelect"
            [options]="optionsForStorageCondition"
            [ngModel]="newPreparation.storageCondition.value"
            [ngClass]="{'eln-storage-condition': storageConditionHasError}"
            (ngModelChange)="newPreparation.storageCondition = convertToStringValue($event,'storageCondition')"
            valueField="value"
            labelField="label"
            [required]="true"
            [errorFlag]="storageConditionHasError"
            [errorMessage]="requiredFieldMessage">
          </bpt-dropdown>
        </div>
        <div class="flex flex-row flex-align">
          <div>
            <p style="font-weight: bold;">{{addContainer}}</p>
          </div>
          <div class="inline-flex flex-column ml-auto column-gap-3">
            <div *ngIf="(rowsPresentInPreparationsGrid + preparationDataSource.length) >= 1000" class="ml-auto" style="color: red;">{{maxPreparationsReachedText}}</div>
            <div class="ml-auto add-container-button {{disableAddContainerButton ? 'add-container-button-disabled' : ''}}">
              <p-button
                class="preparation-action-button"
                i18n-label="@@+AddContainer"
                styleClass="bpt-button-compact  p-button-outlined"
                [label]="addContainerLabel"
                [disabled]="disableAddContainerButton"
                id="eln-AddContainer"
                name="eln-AddContainer"
                (onClick)="addRowToGrid()">
              </p-button>
            </div>
          </div>
        </div>
        <div>
          <div class="preparation-grid-container flex-margin-bottom">
            <div>
              <bpt-grid
                #preparationGrid
                class="eln-preparation-grid"
                ngClass="true"
                gridId="eln-bptGrid"
                [dataSource]="preparationDataSource"
                [columnDefinitions]="columnDefinitions"
                [gridMode]="gridMode"
                [allowRowAdd]="false"
                [showAutoSizeButton]="false"
                [includeRowNumberColumn]="false"
                [showFilterToggleButton]="false"
                [showGridOptionsButton]="false"
                [rowActionConfigurations]="gridActions"
                [paginated]="false"
                [ianaTimeZone]="labSiteTimeZone">
              </bpt-grid>
            </div>
          </div>
        </div>
        <div class="flex eln-flex flex-two-column-layout flex-margin">
          <p class="semi-bold">{{preparationInternalInfoTitle}}</p>
        </div>
        <div class="grid column-gap-4">
          <div class="col-4 disposal">
            <bpt-dropdown
              inputId="eln-create-preparation-disposal"
              name="eln-create-preparation-disposal"
              ngDefaultControl
              [editable]="true"
              label="Disposal"
              i18n-label="@@disposal"
              [showClear]="dropdownOptions.showClear"
              [showFilter]="dropdownOptions.showFilter"
              [multiSelect]="dropdownOptions.multiSelect"
              [options]="optionsForDisposal"
              [ngModel]="newPreparation.preparationInternalInformation?.disposal"
              (ngModelChange)="newPreparation && newPreparation.preparationInternalInformation ? newPreparation.preparationInternalInformation.disposal = $event : null"
              valueField="value"
              labelField="label"
              class="mb-1">
            </bpt-dropdown>
          </div>
          <div class="col-4 storageLocation">
            <bpt-text-input
              inputId="eln-create-preparation-storage-location"
              name="eln-create-preparation-storage-location"
              i18n-label="@@storageLocation"
              label="Storage Location"
              [ngModel]="newPreparation.preparationInternalInformation?.storageLocation"
              (ngModelChange)="newPreparation && newPreparation.preparationInternalInformation ? newPreparation.preparationInternalInformation.storageLocation = $event : null"
              [ngModelOptions]="{ updateOn: 'blur' }"
              [disabled]="isParentRecipe">
            </bpt-text-input>
          </div>
          <div class="col-4">
            <bpt-quantity
              id="eln-createPreparationStability"
              label="Stability"
              i18n-label="@@stability"
              name="stability"
              [commitValueOnEnter]="quantityOptions.commitValueOnEnter"
              ngDefaultControl
              [enableSignificantFigures]="quantityOptions.enableSignificantFigures"
              [allowedUnits]="stabilityUnits"
              [cancelValueOnEscape]="quantityOptions.cancelValueOnEscape"
              [ngModel]="createPreparationQuantityModels.stabilityModel"
              [ngModelOptions]="{ updateOn: 'change' }"
              [allowNA]="quantityOptions.allowNA"
              [allowDecimal]="quantityOptions.allowDecimal"
              [highlightAllOnFocus]="quantityOptions.highlightAllOnFocus"
              [suppressContextMenu]="quantityOptions.suppressContextMenu"
              [tabIndex]=3
              [required]="isParentRecipe && !whileSuitableForUse"
              [errorFlag]="stabilityHasError"
              [errorMessage]="requiredFieldMessage"
              (quantityChange)="convertQuantity($event, 'stability')">
            </bpt-quantity>
          </div>
        </div>
        <div class="flex column-gap-4">
          <bpt-text-input
            class="hazards"
            inputId="eln-create-preparation-hazard"
            name="eln-create-preparation-hazard"
            i18n-label="@@hazards"
            label="Hazards"
            [ngModel]="newPreparation.preparationInternalInformation?.hazards"
            (ngModelChange)="newPreparation && newPreparation.preparationInternalInformation ? newPreparation.preparationInternalInformation.hazards = $event : null"
            [ngModelOptions]="{ updateOn: 'blur' }"
            [multiLine]="true"></bpt-text-input>
          <bpt-text-input
            class="additional-label"
            inputId="eln-create-preparation-additional-label"
            i18n-label="@@AdditionalLabelText"
            label="Additional Label Text"
            [ngModel]="newPreparation.preparationInternalInformation?.additionalLabel"
            (ngModelChange)="newPreparation && newPreparation.preparationInternalInformation ? newPreparation.preparationInternalInformation.additionalLabel = $event : null"
            [ngModelOptions]="{ updateOn: 'blur' }"
            [multiLine]="true"></bpt-text-input>
        </div>
        <div class="flex  flex-two-column-layout flex-margin">
          <p class="semi-bold">{{AdditionalInfoTitle}}</p>
        </div>
        <div class="flex  eln-flex gap-4">
            <bpt-text-input
              inputId="eln-create-preparation-analysis"
              name="eln-create-preparation-analysis"
              i18n-label="@@analysis"
              label="Analysis"
              class="bpt-set-layout"
              [ngModel]="newPreparation.additionalInformation?.analysis"
              (ngModelChange)="newPreparation && newPreparation.additionalInformation ? newPreparation.additionalInformation.analysis = $event : null"
              [ngModelOptions]="{ updateOn: 'blur' }">
            </bpt-text-input>
            <bpt-text-input
              class="mb-1 bpt-set-layout"
              inputId="eln-create-preparation-method"
              name="eln-create-preparation-method"
              i18n-label="@@method"
              label="Method"
              [ngModel]="newPreparation.additionalInformation?.method"
              (ngModelChange)="newPreparation && newPreparation.additionalInformation ? newPreparation.additionalInformation.method = $event : null"
              [ngModelOptions]="{ updateOn: 'blur' }">
            </bpt-text-input>
        </div>
        <div class="flex eln-flex flex-row gap-4">
          <bpt-dropdown
            inputId="eln-create-preparation-client"
            name="eln-create-preparation-client"
            ngDefaultControl
            label="Client"
            i18n-label="@@createPreparationClient"
            [showClear]="clientDropdownOptions.showClear"
            [wrap]="dropdownOptions.wrap"
            [showFilter]="dropdownOptions.showFilter"
            [multiSelect]="dropdownOptions.multiSelect"
            [options]="clients"
            [ngModel]="newPreparation.additionalInformation?.client"
            (ngModelChange)="newPreparation && newPreparation.additionalInformation ? newPreparation.additionalInformation.client = $event:null;updateProject($event)"
            (dropdownClear)="dropdownCleared('client')"
            valueField="value"
            labelField="label"
            class="mb-1"></bpt-dropdown>
          <bpt-dropdown
            inputId="eln-create-preparation-project"
            name="eln-create-preparation-project"
            ngDefaultControl
            label="Project"
            i18n-label="@@project"
            [showClear]="dropdownOptions.showClear"
            [showFilter]="dropdownOptions.showFilter"
            [multiSelect]="dropdownOptions.multiSelect"
            [options]="projects"
            [ngModel]="newPreparation.additionalInformation?.project"
            (ngModelChange)="newPreparation && newPreparation.additionalInformation ? newPreparation.additionalInformation.project = $event : null"
            valueField="value"
            labelField="label"
            [disabled]="disableProjects"></bpt-dropdown>
        </div>
      </div>

    </div>
    <div class="slider-sticky-footer" *ngIf="sliderOptions.isFooterSticky">
      <div class="buttons flex  gap-4">
        <p-button
          class="cancel"
          styleClass="p-button-outlined"
          pRipple
          type="button"
          i18n-label="@@cancel"
          label="Cancel"
          (onClick)="resetModels()"
          id="eln-btnCreatePreparationCancel"></p-button>
        <p-button
          class="submit"
          type="submit"
          styleClass="p-button"
          i18n-label="@@create"
          label="Create"
          (onClick)="createPreparation()"
          id="eln-btnCreatePreparationSubmit"></p-button>
      </div>
    </div>
  </bpt-slider>
</div>
<app-preparation-expiration-builder *ngIf="toggleExpirationSlider"></app-preparation-expiration-builder>