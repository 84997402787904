<div class="eln-instrument-selection-config">
  <bpt-dropdown
  #instrumentConfigMethod
  [(ngModel)]="selectedMethod"
  [ngModelOptions]="{ updateOn: 'blur' }"
  [options]="configMethods"
  label="{{instrumentMethodLabel}}"
  [showClear]=false
  [showHeader]=true
  id="eln-instrumentConfigMethod"
  ></bpt-dropdown>
  <bpt-dropdown
  #instrumentConfigUnit
  [(ngModel)]="selectedUnit"
  [ngModelOptions]="{ updateOn: 'blur' }"
  [options]="configUnits"
  label="{{instrumentUnitLabel}}"
  [showClear]=false
  [showHeader]=true
  id="eln-instrumentConfigUnit"
  ></bpt-dropdown>
  <bpt-dropdown
  #instrumentConfigPush
  [(ngModel)]="pushValue"
  [ngModelOptions]="{ updateOn: 'blur' }"
  [options]="configPushValues"
  label="{{instrumentPushLabel}}"
  [showClear]=false
  [showHeader]=true
  id="eln-instrumentConfigPush"
></bpt-dropdown>
<bpt-dropdown
*ngIf="configBlowerVisible"
[(ngModel)]="blowerState"
[ngModelOptions]="{ updateOn: 'blur' }"
[options]="configBlowerValues"
label="{{instrumentBlowerLabel}}"
[disabled]="!configBlowerEditable"
(ngModelChange)="changeBlowerState()"
[showClear]="configBlowerOptions.showClear"
[showHeader]="configBlowerOptions.showHeader"
id="eln-instrumentConfigBlower"
></bpt-dropdown>
</div>
<div class="eln-instrument-connection-config-footer">
  <p-button
    class="Cancel"
    styleClass="bpt-button-compact p-button-outlined"
    i18n-label="@@Cancel"
    label="Cancel"
    (onClick)="onCancel()"
  ></p-button>
  <p-button
    class="Submit"
    styleClass="bpt-button-compact"
    label="Ok"
    i18n-label="@@Ok"
    (onClick)="onOk()"
  ></p-button>
</div>