<ng-container>
    <div *ngIf="validation.notificationResults.notifications.length > 0 && ready">
            <span class="p-message-icon pi pi-times-circle cursor-pointer" (click)="clearNotifications()"></span>
            <span class="p-ml-2"> {{ validation.errorTitle }}</span>     
            <ul>
                <li *ngFor="let notification of validation.notificationResults.notifications">
                    <small
                    id="elnNotificationMessage"
                    class="p-error"
                    >{{notification.message}}</small>
                </li>
            </ul>
    </div>
    <div *ngIf="validation.successes.length > 0">
        <p-messages severity="success" styleClass="p-mr-2">
            <ng-template pTemplate>
                <span class="p-message-icon pi pi-check"></span>
                <span class="p-ml-2">{{ validation.successTitle }}</span>
                <ul>
                    <li *ngFor="let success of validation.successes">{{ success }}</li>
                </ul>
            </ng-template>
        </p-messages>
    </div>
    <div *ngIf="validation.errors.length > 0" class="mb-5">
        <ng-container *ngIf="validation.displayAsInline">
            <p-message *ngFor="let error of validation.errors" severity="error" text="{{error}}" styleClass="mb-1"></p-message>
        </ng-container>     
        <p-messages severity="error" styleClass="p-mr-2" *ngIf="!validation.displayAsInline">
            <ng-template pTemplate>
                <span class="p-message-icon pi pi-times-circle"></span>
                <span class="p-ml-2">{{ validation.errorTitle }}</span>
                <ul>
                    <li *ngFor="let error of validation.errors">{{ error }}</li>
                </ul>
            </ng-template>
        </p-messages>
    </div>
    <div *ngIf="validation.warnings.length > 0" class="mb-5">
        <ng-container *ngIf="validation.displayAsInline">
            <p-message *ngFor="let warning of validation.warnings" severity="warn" text="{{warning}}" styleClass="mb-1"></p-message>
        </ng-container>     
        <p-messages severity="warn" styleClass="p-mr-2"  *ngIf="!validation.displayAsInline">
            <ng-template pTemplate>
                <span class="p-message-icon pi pi-exclamation-triangle"></span>
                <span class="p-ml-2">{{ validation.warningTitle }}</span>
                <ul>
                    <li *ngFor="let warn of validation.warnings">{{ warn }}</li>
                </ul>
            </ng-template>
        </p-messages>
    </div>
</ng-container>
