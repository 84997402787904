/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ExperimentFlagRaisedEventResponse } from '../models/experiment-flag-raised-event-response';
import { experimentFlagsLowerFlagPost$Json } from '../fn/experiment-flag/experiment-flags-lower-flag-post-json';
import { ExperimentFlagsLowerFlagPost$Json$Params } from '../fn/experiment-flag/experiment-flags-lower-flag-post-json';
import { experimentFlagsLowerFlagPost$Plain } from '../fn/experiment-flag/experiment-flags-lower-flag-post-plain';
import { ExperimentFlagsLowerFlagPost$Plain$Params } from '../fn/experiment-flag/experiment-flags-lower-flag-post-plain';
import { experimentFlagsRaiseFlagPost$Json } from '../fn/experiment-flag/experiment-flags-raise-flag-post-json';
import { ExperimentFlagsRaiseFlagPost$Json$Params } from '../fn/experiment-flag/experiment-flags-raise-flag-post-json';
import { experimentFlagsRaiseFlagPost$Plain } from '../fn/experiment-flag/experiment-flags-raise-flag-post-plain';
import { ExperimentFlagsRaiseFlagPost$Plain$Params } from '../fn/experiment-flag/experiment-flags-raise-flag-post-plain';
import { ExperimentFlagUnraisedEventResponse } from '../models/experiment-flag-unraised-event-response';

@Injectable({ providedIn: 'root' })
export class ExperimentFlagService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `experimentFlagsRaiseFlagPost()` */
  static readonly ExperimentFlagsRaiseFlagPostPath = '/experiment-flags/raise-flag';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentFlagsRaiseFlagPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentFlagsRaiseFlagPost$Plain$Response(params?: ExperimentFlagsRaiseFlagPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ExperimentFlagRaisedEventResponse>> {
    return experimentFlagsRaiseFlagPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentFlagsRaiseFlagPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentFlagsRaiseFlagPost$Plain(params?: ExperimentFlagsRaiseFlagPost$Plain$Params, context?: HttpContext): Observable<ExperimentFlagRaisedEventResponse> {
    return this.experimentFlagsRaiseFlagPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExperimentFlagRaisedEventResponse>): ExperimentFlagRaisedEventResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentFlagsRaiseFlagPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentFlagsRaiseFlagPost$Json$Response(params?: ExperimentFlagsRaiseFlagPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ExperimentFlagRaisedEventResponse>> {
    return experimentFlagsRaiseFlagPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentFlagsRaiseFlagPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentFlagsRaiseFlagPost$Json(params?: ExperimentFlagsRaiseFlagPost$Json$Params, context?: HttpContext): Observable<ExperimentFlagRaisedEventResponse> {
    return this.experimentFlagsRaiseFlagPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExperimentFlagRaisedEventResponse>): ExperimentFlagRaisedEventResponse => r.body)
    );
  }

  /** Path part for operation `experimentFlagsLowerFlagPost()` */
  static readonly ExperimentFlagsLowerFlagPostPath = '/experiment-flags/lower-flag';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentFlagsLowerFlagPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentFlagsLowerFlagPost$Plain$Response(params?: ExperimentFlagsLowerFlagPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ExperimentFlagUnraisedEventResponse>> {
    return experimentFlagsLowerFlagPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentFlagsLowerFlagPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentFlagsLowerFlagPost$Plain(params?: ExperimentFlagsLowerFlagPost$Plain$Params, context?: HttpContext): Observable<ExperimentFlagUnraisedEventResponse> {
    return this.experimentFlagsLowerFlagPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExperimentFlagUnraisedEventResponse>): ExperimentFlagUnraisedEventResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentFlagsLowerFlagPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentFlagsLowerFlagPost$Json$Response(params?: ExperimentFlagsLowerFlagPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ExperimentFlagUnraisedEventResponse>> {
    return experimentFlagsLowerFlagPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentFlagsLowerFlagPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentFlagsLowerFlagPost$Json(params?: ExperimentFlagsLowerFlagPost$Json$Params, context?: HttpContext): Observable<ExperimentFlagUnraisedEventResponse> {
    return this.experimentFlagsLowerFlagPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExperimentFlagUnraisedEventResponse>): ExperimentFlagUnraisedEventResponse => r.body)
    );
  }

}
