/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ChangeClientFacingNoteResponse } from '../models/change-client-facing-note-response';
import { clientFacingNoteEventsExperimentIdContextTypeNodeIdCreatePost$Json } from '../fn/client-facing-note/client-facing-note-events-experiment-id-context-type-node-id-create-post-json';
import { ClientFacingNoteEventsExperimentIdContextTypeNodeIdCreatePost$Json$Params } from '../fn/client-facing-note/client-facing-note-events-experiment-id-context-type-node-id-create-post-json';
import { clientFacingNoteEventsExperimentIdContextTypeNodeIdCreatePost$Plain } from '../fn/client-facing-note/client-facing-note-events-experiment-id-context-type-node-id-create-post-plain';
import { ClientFacingNoteEventsExperimentIdContextTypeNodeIdCreatePost$Plain$Params } from '../fn/client-facing-note/client-facing-note-events-experiment-id-context-type-node-id-create-post-plain';
import { clientFacingNoteEventsExperimentIdNodeIdNumberChangePost$Json } from '../fn/client-facing-note/client-facing-note-events-experiment-id-node-id-number-change-post-json';
import { ClientFacingNoteEventsExperimentIdNodeIdNumberChangePost$Json$Params } from '../fn/client-facing-note/client-facing-note-events-experiment-id-node-id-number-change-post-json';
import { clientFacingNoteEventsExperimentIdNodeIdNumberChangePost$Plain } from '../fn/client-facing-note/client-facing-note-events-experiment-id-node-id-number-change-post-plain';
import { ClientFacingNoteEventsExperimentIdNodeIdNumberChangePost$Plain$Params } from '../fn/client-facing-note/client-facing-note-events-experiment-id-node-id-number-change-post-plain';
import { CreateClientFacingNoteResponse } from '../models/create-client-facing-note-response';

@Injectable({ providedIn: 'root' })
export class ClientFacingNoteService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `clientFacingNoteEventsExperimentIdContextTypeNodeIdCreatePost()` */
  static readonly ClientFacingNoteEventsExperimentIdContextTypeNodeIdCreatePostPath = '/client-facing-note-events/{experimentId}/{contextType}/{nodeId}/create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientFacingNoteEventsExperimentIdContextTypeNodeIdCreatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientFacingNoteEventsExperimentIdContextTypeNodeIdCreatePost$Plain$Response(params: ClientFacingNoteEventsExperimentIdContextTypeNodeIdCreatePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateClientFacingNoteResponse>> {
    return clientFacingNoteEventsExperimentIdContextTypeNodeIdCreatePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `clientFacingNoteEventsExperimentIdContextTypeNodeIdCreatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientFacingNoteEventsExperimentIdContextTypeNodeIdCreatePost$Plain(params: ClientFacingNoteEventsExperimentIdContextTypeNodeIdCreatePost$Plain$Params, context?: HttpContext): Observable<CreateClientFacingNoteResponse> {
    return this.clientFacingNoteEventsExperimentIdContextTypeNodeIdCreatePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateClientFacingNoteResponse>): CreateClientFacingNoteResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientFacingNoteEventsExperimentIdContextTypeNodeIdCreatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientFacingNoteEventsExperimentIdContextTypeNodeIdCreatePost$Json$Response(params: ClientFacingNoteEventsExperimentIdContextTypeNodeIdCreatePost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateClientFacingNoteResponse>> {
    return clientFacingNoteEventsExperimentIdContextTypeNodeIdCreatePost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `clientFacingNoteEventsExperimentIdContextTypeNodeIdCreatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientFacingNoteEventsExperimentIdContextTypeNodeIdCreatePost$Json(params: ClientFacingNoteEventsExperimentIdContextTypeNodeIdCreatePost$Json$Params, context?: HttpContext): Observable<CreateClientFacingNoteResponse> {
    return this.clientFacingNoteEventsExperimentIdContextTypeNodeIdCreatePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateClientFacingNoteResponse>): CreateClientFacingNoteResponse => r.body)
    );
  }

  /** Path part for operation `clientFacingNoteEventsExperimentIdNodeIdNumberChangePost()` */
  static readonly ClientFacingNoteEventsExperimentIdNodeIdNumberChangePostPath = '/client-facing-note-events/{experimentId}/{nodeId}/{number}/change';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientFacingNoteEventsExperimentIdNodeIdNumberChangePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientFacingNoteEventsExperimentIdNodeIdNumberChangePost$Plain$Response(params: ClientFacingNoteEventsExperimentIdNodeIdNumberChangePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangeClientFacingNoteResponse>> {
    return clientFacingNoteEventsExperimentIdNodeIdNumberChangePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `clientFacingNoteEventsExperimentIdNodeIdNumberChangePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientFacingNoteEventsExperimentIdNodeIdNumberChangePost$Plain(params: ClientFacingNoteEventsExperimentIdNodeIdNumberChangePost$Plain$Params, context?: HttpContext): Observable<ChangeClientFacingNoteResponse> {
    return this.clientFacingNoteEventsExperimentIdNodeIdNumberChangePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangeClientFacingNoteResponse>): ChangeClientFacingNoteResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientFacingNoteEventsExperimentIdNodeIdNumberChangePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientFacingNoteEventsExperimentIdNodeIdNumberChangePost$Json$Response(params: ClientFacingNoteEventsExperimentIdNodeIdNumberChangePost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangeClientFacingNoteResponse>> {
    return clientFacingNoteEventsExperimentIdNodeIdNumberChangePost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `clientFacingNoteEventsExperimentIdNodeIdNumberChangePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientFacingNoteEventsExperimentIdNodeIdNumberChangePost$Json(params: ClientFacingNoteEventsExperimentIdNodeIdNumberChangePost$Json$Params, context?: HttpContext): Observable<ChangeClientFacingNoteResponse> {
    return this.clientFacingNoteEventsExperimentIdNodeIdNumberChangePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangeClientFacingNoteResponse>): ChangeClientFacingNoteResponse => r.body)
    );
  }

}
