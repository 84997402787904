<div class="eln-child-table-container">
  <bpt-grid #Grid class="eln-grid" ngClass="true" [columnDefinitions]="columnDefinitions" [dataSource]="data"
    gridId="{{studyActivityTableId}}" [gridMode]="gridMode" [allowRowAdd]="false" [showAutoSizeButton]="false"
    [showFilterToggleButton]="false" [showGridOptionsButton]="false" [rowSelection]="rowSelection"
    (selectionChanged)="onSelected($event)">
  </bpt-grid>
  <div class="select-study-activity-buttons-align">
    <p-button
      styleClass="eln-standard-popup-button p-button-outlined align-button"
      label={{NA}}
      id="eln-studyActivityTableNASelection"
      (click)="clearSelection()"></p-button>
    <p-button
      styleClass="eln-standard-popup-button p-button-outlined align-button"
      i18n-label="@@cancel"
      label="Cancel"
      id="eln-studyActivityTableCancelSelection"
      (click)="cancelStudySelection()"></p-button>
    <p-button
      styleClass="eln-standard-popup-button"
      id="eln-studyActivityTableSaveSelection"
      icon="pi pi-check"
      i18n-label="@@commit"
      [disabled]="!checkForUnsavedChanges()"
      label="Commit"
      [ngStyle]="{ 'pointer-events': !checkForUnsavedChanges() ? 'none' : 'auto' }"
      (click)="saveStudyActivities()"></p-button>
  </div>
</div>