import { ColumnDefinition, ColumnType, DropdownCellEditorParams } from 'bpt-ui-library/bpt-grid'
import { ColumnSpecification, TimeSelectOption } from '../../api/models';
import { TableConstants } from './column-info-items';

/**
 * Defines the defaults to New Column for common properties with columnType string. (Some shouldn't be here, though, because they only apply to some column types.)
*/
export const NewColumnDefinition: ColumnDefinition & { containsObservableData?: boolean, allowedUnitIds: Array<string>, wrapText?: boolean } = {
  columnType: 'string',
  label: 'Label',
  width: 'auto',
  filter: true,
  sortable: true,
  editable: true,
  disableGrouping: false,
  disableHiding: false,
  hidden: false,
  suppressMenu: false,
  allowNA: true,
  formatNaToUpperCase: true,
  containsObservableData: true,
  allowedUnitIds: [],
  allowDecimal: false,
  allowNegative: false,
  allowMultiLine: false,
  allowAutoResize: false,
  allowMinCharLength: false,
  allowMaxCharLength: false,
  allowMinNumericValue: false,
  allowMaxNumericValue: false,
  allowDateRangeSelect: false,
  allowMinDate: false,
  allowMaxDate: false,
  allowTimeSelect: TimeSelectOption.Never,
  allowMultiSelect: false,
  allowCustomOptionsForDropdown: false,
  maxCharLength: undefined,
  maxDate: undefined,
  maxNumericValue: undefined,
  maxWidth: 500,
  minCharLength: undefined,
  minDate: undefined,
  wrapText: true
};
/**
 * We need following Extended Column specification and definition to bridge the gap for allowed units. Quantity Control works with Unit[] 
 * where as we are saving only UnitIds (i.e. Array<string>) in Cosmos.
*/
export type ExtendedColumnSpecification = ColumnSpecification & {
  allowedUnitIds: Array<string>
  suppressMenu?: boolean
  dropdownEditorConfiguration?: DropdownCellEditorParams
};
export type ExtendedColumnDefinition = ColumnDefinition & { allowedUnitIds: Array<string> };

// helps to identify the original field
export const ColumnDefinitionMaskFields: { [fieldName: string]: string } = {
  widthTypeAuto: 'width',
  widthTypeDefault: 'width',
  widthTypeStatic: 'width',
};

export const ColumnDefinitionValueFieldDefaults: { [fieldName: string]: { defaultValue: any, invalidValue: any[] } } = {
  maxCharLength: { defaultValue: undefined, invalidValue: [''] },
  minCharLength: { defaultValue: undefined, invalidValue: [''] },
  minDate: { defaultValue: undefined, invalidValue: [''] },
  maxDate: { defaultValue: undefined, invalidValue: [''] },
  maxWidth: { defaultValue: undefined, invalidValue: [''] },
  maxNumericValue: { defaultValue: undefined, invalidValue: [''] }
};

export const ServerRowIndexColumnSpecification = {
  field: TableConstants.rowIndexColumnId,
  columnType: ColumnType.index,
  label: 'Index',
  hidden: true,
  alwaysHidden: true,
  editable: false,
  width: 60,
  minWidth: 30
};

export const ReservedFieldNames = [
  TableConstants.rowIndexColumnId
];
