<div *ngIf="validation.warnings.length > 0">
  <p-messages severity="warn" styleClass="p-mr-2" id="eln-repeat-warning">
    <ng-template pTemplate>
      <span class="p-message-icon pi pi-exclamation-triangle"></span>
      <span class="p-ml-2">{{ validation.warningTitle }}</span>
      <div class="eln-repeat-for-warning" *ngFor="let warn of validation.warnings">{{warn}}</div>
    </ng-template>
  </p-messages>
</div>
<div *ngIf="validation && !validation.isValid()" class="eln-column-title-validation-message">
  <app-validation-summary [validation]="validation"></app-validation-summary>
</div>

<div class="grid">
  <div class="col">
    <div class="eln-tree">
      <bpt-tree
        class="rfe-bpt-tree"
        [treeData]="treeData"
        selectionMode="checkbox"
        [selectedNode]="selectedTreeData"
        [isExpandOrCollapseTree]="true"
        [isVirtualScroll]="true"
        (nodeSelectClick)="nodeSelected($event)"
        (nodeUnselectClick)="nodeUnselected($event)"
        scrollHeight="100%"
        styleClass="rfe-bpt-tree">
      </bpt-tree>
    </div>
  </div>
</div>
<div
  class="flex flex-direction-column flex-wrap justify-content-end card-container column-gap-1 step-one-options-container pt-3">
  <div class="grid">
    <div class="col pr-0">
      <div class="button-container">
        <p-button
          label="Cancel"
          i18n-label="@@cancel"
          styleClass="bpt-button p-button-outlined"
          id="repeatGroupInputCancelButton"
          (onClick)="cancel()"></p-button>
      </div>
    </div>
    <div class="col pl-0">
      <p-button
        label="Add Rows for Each"
        i18n-label="@@addRowsForEachTitle"
        styleClass="bpt-button"
        id="repeatGroupInputAddRowButtom"
        (onClick)="addRows()"></p-button>
    </div>
  </div>
