import { Component, Input } from '@angular/core';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-ellipsis-menu-overlay-component',
  templateUrl: './ellipsis-menu-overlay-component.html',
  styleUrls: ['./ellipsis-menu-overlay-component.scss']
})
export class EllipsisMenuOverlayComponent {
  @Input() menuItems!: MenuItem[];
}
