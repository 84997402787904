import { StatementType } from "./statement-type";

export enum StatementMode {
  Add = 'add',
  Edit = 'edit'
}

export interface UserDefinedCustomStatement {
  index: number;
  statement: string;
  originalStatement: string;
  isModified: boolean;
  mode: StatementMode,
  type?: StatementType
  selected?: boolean
}