/* tslint:disable */
/* eslint-disable */
export enum ColumnType {
  Invalid = 'invalid',
  RowId = 'rowId',
  String = 'string',
  Number = 'number',
  Quantity = 'quantity',
  Date = 'date',
  Boolean = 'boolean',
  StepNumber = 'stepNumber',
  AutoIncrement = 'autoIncrement',
  Link = 'link',
  List = 'list',
  EditableList = 'editableList',
  Index = 'index',
  Specification = 'specification'
}
