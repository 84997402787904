/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { DeviceMonitorStatusDto } from '../models/device-monitor-status-dto';
import { InstrumentCreateDto } from '../models/instrument-create-dto';
import { InstrumentDto } from '../models/instrument-dto';
import { instrumentsAddInstrumentPost$Json } from '../fn/instrument/instruments-add-instrument-post-json';
import { InstrumentsAddInstrumentPost$Json$Params } from '../fn/instrument/instruments-add-instrument-post-json';
import { instrumentsAddInstrumentPost$Plain } from '../fn/instrument/instruments-add-instrument-post-plain';
import { InstrumentsAddInstrumentPost$Plain$Params } from '../fn/instrument/instruments-add-instrument-post-plain';
import { instrumentsConfigByKeyConfigKeyGet$Json } from '../fn/instrument/instruments-config-by-key-config-key-get-json';
import { InstrumentsConfigByKeyConfigKeyGet$Json$Params } from '../fn/instrument/instruments-config-by-key-config-key-get-json';
import { instrumentsConfigByKeyConfigKeyGet$Plain } from '../fn/instrument/instruments-config-by-key-config-key-get-plain';
import { InstrumentsConfigByKeyConfigKeyGet$Plain$Params } from '../fn/instrument/instruments-config-by-key-config-key-get-plain';
import { InstrumentSearchResultsDto } from '../models/instrument-search-results-dto';
import { instrumentsGet$Json } from '../fn/instrument/instruments-get-json';
import { InstrumentsGet$Json$Params } from '../fn/instrument/instruments-get-json';
import { instrumentsGet$Plain } from '../fn/instrument/instruments-get-plain';
import { InstrumentsGet$Plain$Params } from '../fn/instrument/instruments-get-plain';
import { instrumentsHeartbeatGet } from '../fn/instrument/instruments-heartbeat-get';
import { InstrumentsHeartbeatGet$Params } from '../fn/instrument/instruments-heartbeat-get';
import { instrumentsIdGet$Json } from '../fn/instrument/instruments-id-get-json';
import { InstrumentsIdGet$Json$Params } from '../fn/instrument/instruments-id-get-json';
import { instrumentsIdGet$Plain } from '../fn/instrument/instruments-id-get-plain';
import { InstrumentsIdGet$Plain$Params } from '../fn/instrument/instruments-id-get-plain';
import { instrumentsIdPost$Json } from '../fn/instrument/instruments-id-post-json';
import { InstrumentsIdPost$Json$Params } from '../fn/instrument/instruments-id-post-json';
import { instrumentsIdPost$Plain } from '../fn/instrument/instruments-id-post-plain';
import { InstrumentsIdPost$Plain$Params } from '../fn/instrument/instruments-id-post-plain';
import { instrumentsInstrumentDetailsGet$Json } from '../fn/instrument/instruments-instrument-details-get-json';
import { InstrumentsInstrumentDetailsGet$Json$Params } from '../fn/instrument/instruments-instrument-details-get-json';
import { instrumentsInstrumentDetailsGet$Plain } from '../fn/instrument/instruments-instrument-details-get-plain';
import { InstrumentsInstrumentDetailsGet$Plain$Params } from '../fn/instrument/instruments-instrument-details-get-plain';
import { instrumentsRefreshDeviceMonitorPut$Json } from '../fn/instrument/instruments-refresh-device-monitor-put-json';
import { InstrumentsRefreshDeviceMonitorPut$Json$Params } from '../fn/instrument/instruments-refresh-device-monitor-put-json';
import { instrumentsRefreshDeviceMonitorPut$Plain } from '../fn/instrument/instruments-refresh-device-monitor-put-plain';
import { InstrumentsRefreshDeviceMonitorPut$Plain$Params } from '../fn/instrument/instruments-refresh-device-monitor-put-plain';
import { instrumentsSearchPost$Json } from '../fn/instrument/instruments-search-post-json';
import { InstrumentsSearchPost$Json$Params } from '../fn/instrument/instruments-search-post-json';
import { instrumentsSearchPost$Plain } from '../fn/instrument/instruments-search-post-plain';
import { InstrumentsSearchPost$Plain$Params } from '../fn/instrument/instruments-search-post-plain';
import { instrumentsUpdateInstrumentAvailabilityPut } from '../fn/instrument/instruments-update-instrument-availability-put';
import { InstrumentsUpdateInstrumentAvailabilityPut$Params } from '../fn/instrument/instruments-update-instrument-availability-put';
import { instrumentsUpdateInstrumentStatusPut } from '../fn/instrument/instruments-update-instrument-status-put';
import { InstrumentsUpdateInstrumentStatusPut$Params } from '../fn/instrument/instruments-update-instrument-status-put';

@Injectable({ providedIn: 'root' })
export class InstrumentService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `instrumentsGet()` */
  static readonly InstrumentsGetPath = '/instruments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `instrumentsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  instrumentsGet$Plain$Response(params?: InstrumentsGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<InstrumentDto>>> {
    return instrumentsGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `instrumentsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  instrumentsGet$Plain(params?: InstrumentsGet$Plain$Params, context?: HttpContext): Observable<Array<InstrumentDto>> {
    return this.instrumentsGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<InstrumentDto>>): Array<InstrumentDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `instrumentsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  instrumentsGet$Json$Response(params?: InstrumentsGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<InstrumentDto>>> {
    return instrumentsGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `instrumentsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  instrumentsGet$Json(params?: InstrumentsGet$Json$Params, context?: HttpContext): Observable<Array<InstrumentDto>> {
    return this.instrumentsGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<InstrumentDto>>): Array<InstrumentDto> => r.body)
    );
  }

  /** Path part for operation `instrumentsInstrumentDetailsGet()` */
  static readonly InstrumentsInstrumentDetailsGetPath = '/instruments/instrument-details';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `instrumentsInstrumentDetailsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  instrumentsInstrumentDetailsGet$Plain$Response(params?: InstrumentsInstrumentDetailsGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<InstrumentCreateDto>> {
    return instrumentsInstrumentDetailsGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `instrumentsInstrumentDetailsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  instrumentsInstrumentDetailsGet$Plain(params?: InstrumentsInstrumentDetailsGet$Plain$Params, context?: HttpContext): Observable<InstrumentCreateDto> {
    return this.instrumentsInstrumentDetailsGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<InstrumentCreateDto>): InstrumentCreateDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `instrumentsInstrumentDetailsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  instrumentsInstrumentDetailsGet$Json$Response(params?: InstrumentsInstrumentDetailsGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<InstrumentCreateDto>> {
    return instrumentsInstrumentDetailsGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `instrumentsInstrumentDetailsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  instrumentsInstrumentDetailsGet$Json(params?: InstrumentsInstrumentDetailsGet$Json$Params, context?: HttpContext): Observable<InstrumentCreateDto> {
    return this.instrumentsInstrumentDetailsGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<InstrumentCreateDto>): InstrumentCreateDto => r.body)
    );
  }

  /** Path part for operation `instrumentsIdGet()` */
  static readonly InstrumentsIdGetPath = '/instruments/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `instrumentsIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  instrumentsIdGet$Plain$Response(params: InstrumentsIdGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<InstrumentDto>> {
    return instrumentsIdGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `instrumentsIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  instrumentsIdGet$Plain(params: InstrumentsIdGet$Plain$Params, context?: HttpContext): Observable<InstrumentDto> {
    return this.instrumentsIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<InstrumentDto>): InstrumentDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `instrumentsIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  instrumentsIdGet$Json$Response(params: InstrumentsIdGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<InstrumentDto>> {
    return instrumentsIdGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `instrumentsIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  instrumentsIdGet$Json(params: InstrumentsIdGet$Json$Params, context?: HttpContext): Observable<InstrumentDto> {
    return this.instrumentsIdGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<InstrumentDto>): InstrumentDto => r.body)
    );
  }

  /** Path part for operation `instrumentsIdPost()` */
  static readonly InstrumentsIdPostPath = '/instruments/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `instrumentsIdPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  instrumentsIdPost$Plain$Response(params: InstrumentsIdPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return instrumentsIdPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `instrumentsIdPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  instrumentsIdPost$Plain(params: InstrumentsIdPost$Plain$Params, context?: HttpContext): Observable<string> {
    return this.instrumentsIdPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `instrumentsIdPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  instrumentsIdPost$Json$Response(params: InstrumentsIdPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return instrumentsIdPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `instrumentsIdPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  instrumentsIdPost$Json(params: InstrumentsIdPost$Json$Params, context?: HttpContext): Observable<string> {
    return this.instrumentsIdPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `instrumentsAddInstrumentPost()` */
  static readonly InstrumentsAddInstrumentPostPath = '/instruments/add-instrument';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `instrumentsAddInstrumentPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  instrumentsAddInstrumentPost$Plain$Response(params?: InstrumentsAddInstrumentPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<InstrumentDto>> {
    return instrumentsAddInstrumentPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `instrumentsAddInstrumentPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  instrumentsAddInstrumentPost$Plain(params?: InstrumentsAddInstrumentPost$Plain$Params, context?: HttpContext): Observable<InstrumentDto> {
    return this.instrumentsAddInstrumentPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<InstrumentDto>): InstrumentDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `instrumentsAddInstrumentPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  instrumentsAddInstrumentPost$Json$Response(params?: InstrumentsAddInstrumentPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<InstrumentDto>> {
    return instrumentsAddInstrumentPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `instrumentsAddInstrumentPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  instrumentsAddInstrumentPost$Json(params?: InstrumentsAddInstrumentPost$Json$Params, context?: HttpContext): Observable<InstrumentDto> {
    return this.instrumentsAddInstrumentPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<InstrumentDto>): InstrumentDto => r.body)
    );
  }

  /** Path part for operation `instrumentsRefreshDeviceMonitorPut()` */
  static readonly InstrumentsRefreshDeviceMonitorPutPath = '/instruments/refresh-device-monitor';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `instrumentsRefreshDeviceMonitorPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  instrumentsRefreshDeviceMonitorPut$Plain$Response(params?: InstrumentsRefreshDeviceMonitorPut$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<DeviceMonitorStatusDto>> {
    return instrumentsRefreshDeviceMonitorPut$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `instrumentsRefreshDeviceMonitorPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  instrumentsRefreshDeviceMonitorPut$Plain(params?: InstrumentsRefreshDeviceMonitorPut$Plain$Params, context?: HttpContext): Observable<DeviceMonitorStatusDto> {
    return this.instrumentsRefreshDeviceMonitorPut$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeviceMonitorStatusDto>): DeviceMonitorStatusDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `instrumentsRefreshDeviceMonitorPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  instrumentsRefreshDeviceMonitorPut$Json$Response(params?: InstrumentsRefreshDeviceMonitorPut$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<DeviceMonitorStatusDto>> {
    return instrumentsRefreshDeviceMonitorPut$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `instrumentsRefreshDeviceMonitorPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  instrumentsRefreshDeviceMonitorPut$Json(params?: InstrumentsRefreshDeviceMonitorPut$Json$Params, context?: HttpContext): Observable<DeviceMonitorStatusDto> {
    return this.instrumentsRefreshDeviceMonitorPut$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeviceMonitorStatusDto>): DeviceMonitorStatusDto => r.body)
    );
  }

  /** Path part for operation `instrumentsSearchPost()` */
  static readonly InstrumentsSearchPostPath = '/instruments/search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `instrumentsSearchPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  instrumentsSearchPost$Plain$Response(params?: InstrumentsSearchPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<InstrumentSearchResultsDto>> {
    return instrumentsSearchPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `instrumentsSearchPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  instrumentsSearchPost$Plain(params?: InstrumentsSearchPost$Plain$Params, context?: HttpContext): Observable<InstrumentSearchResultsDto> {
    return this.instrumentsSearchPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<InstrumentSearchResultsDto>): InstrumentSearchResultsDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `instrumentsSearchPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  instrumentsSearchPost$Json$Response(params?: InstrumentsSearchPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<InstrumentSearchResultsDto>> {
    return instrumentsSearchPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `instrumentsSearchPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  instrumentsSearchPost$Json(params?: InstrumentsSearchPost$Json$Params, context?: HttpContext): Observable<InstrumentSearchResultsDto> {
    return this.instrumentsSearchPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<InstrumentSearchResultsDto>): InstrumentSearchResultsDto => r.body)
    );
  }

  /** Path part for operation `instrumentsUpdateInstrumentStatusPut()` */
  static readonly InstrumentsUpdateInstrumentStatusPutPath = '/instruments/update-instrument-status';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `instrumentsUpdateInstrumentStatusPut()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  instrumentsUpdateInstrumentStatusPut$Response(params?: InstrumentsUpdateInstrumentStatusPut$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return instrumentsUpdateInstrumentStatusPut(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `instrumentsUpdateInstrumentStatusPut$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  instrumentsUpdateInstrumentStatusPut(params?: InstrumentsUpdateInstrumentStatusPut$Params, context?: HttpContext): Observable<void> {
    return this.instrumentsUpdateInstrumentStatusPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `instrumentsUpdateInstrumentAvailabilityPut()` */
  static readonly InstrumentsUpdateInstrumentAvailabilityPutPath = '/instruments/update-instrument-availability';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `instrumentsUpdateInstrumentAvailabilityPut()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  instrumentsUpdateInstrumentAvailabilityPut$Response(params?: InstrumentsUpdateInstrumentAvailabilityPut$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return instrumentsUpdateInstrumentAvailabilityPut(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `instrumentsUpdateInstrumentAvailabilityPut$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  instrumentsUpdateInstrumentAvailabilityPut(params?: InstrumentsUpdateInstrumentAvailabilityPut$Params, context?: HttpContext): Observable<void> {
    return this.instrumentsUpdateInstrumentAvailabilityPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `instrumentsConfigByKeyConfigKeyGet()` */
  static readonly InstrumentsConfigByKeyConfigKeyGetPath = '/instruments/config-by-key/{configKey}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `instrumentsConfigByKeyConfigKeyGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  instrumentsConfigByKeyConfigKeyGet$Plain$Response(params: InstrumentsConfigByKeyConfigKeyGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return instrumentsConfigByKeyConfigKeyGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `instrumentsConfigByKeyConfigKeyGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  instrumentsConfigByKeyConfigKeyGet$Plain(params: InstrumentsConfigByKeyConfigKeyGet$Plain$Params, context?: HttpContext): Observable<string> {
    return this.instrumentsConfigByKeyConfigKeyGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `instrumentsConfigByKeyConfigKeyGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  instrumentsConfigByKeyConfigKeyGet$Json$Response(params: InstrumentsConfigByKeyConfigKeyGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return instrumentsConfigByKeyConfigKeyGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `instrumentsConfigByKeyConfigKeyGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  instrumentsConfigByKeyConfigKeyGet$Json(params: InstrumentsConfigByKeyConfigKeyGet$Json$Params, context?: HttpContext): Observable<string> {
    return this.instrumentsConfigByKeyConfigKeyGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `instrumentsHeartbeatGet()` */
  static readonly InstrumentsHeartbeatGetPath = '/instruments/heartbeat';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `instrumentsHeartbeatGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  instrumentsHeartbeatGet$Response(params?: InstrumentsHeartbeatGet$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return instrumentsHeartbeatGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `instrumentsHeartbeatGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  instrumentsHeartbeatGet(params?: InstrumentsHeartbeatGet$Params, context?: HttpContext): Observable<void> {
    return this.instrumentsHeartbeatGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
