/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ChangeJustificationCommand } from '../../models/change-justification-command';
import { JustificationChangedResponse } from '../../models/justification-changed-response';

export interface ActivityOutputEventsChangeJustificationPost$Json$Params {
  'X-User-Puid'?: any;
  'X-User-LabsiteCode'?: any;

/**
 * Please use this header only when the request is for an experiment or preview experiment. Passing true will be considered a request for a preview experiment.
 */
  'X-Experiment-PreviewMode'?: any;
      body?: ChangeJustificationCommand
}

export function activityOutputEventsChangeJustificationPost$Json(http: HttpClient, rootUrl: string, params?: ActivityOutputEventsChangeJustificationPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<JustificationChangedResponse>> {
  const rb = new RequestBuilder(rootUrl, activityOutputEventsChangeJustificationPost$Json.PATH, 'post');
  if (params) {
    rb.header('X-User-Puid', params['X-User-Puid'], {});
    rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
    rb.header('X-Experiment-PreviewMode', params['X-Experiment-PreviewMode'], {});
    rb.body(params.body, 'application/*+json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<JustificationChangedResponse>;
    })
  );
}

activityOutputEventsChangeJustificationPost$Json.PATH = '/activity-output-events/change-justification';
