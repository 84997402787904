/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { TemplateSummaryForLoad } from '../../models/template-summary-for-load';

export interface TemplatesSearchForLoadGet$Json$Params {
  GetLatestVersion?: boolean;
  ConsumingLabsiteCodes?: string;
  SubBusinessUnitCodes?: string;
  TemplateTypes?: string;
  'X-User-Puid'?: any;
  'X-User-LabsiteCode'?: any;
}

export function templatesSearchForLoadGet$Json(http: HttpClient, rootUrl: string, params?: TemplatesSearchForLoadGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TemplateSummaryForLoad>>> {
  const rb = new RequestBuilder(rootUrl, templatesSearchForLoadGet$Json.PATH, 'get');
  if (params) {
    rb.query('GetLatestVersion', params.GetLatestVersion, {});
    rb.query('ConsumingLabsiteCodes', params.ConsumingLabsiteCodes, {});
    rb.query('SubBusinessUnitCodes', params.SubBusinessUnitCodes, {});
    rb.query('TemplateTypes', params.TemplateTypes, {});
    rb.header('X-User-Puid', params['X-User-Puid'], {});
    rb.header('X-User-LabsiteCode', params['X-User-LabsiteCode'], {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<TemplateSummaryForLoad>>;
    })
  );
}

templatesSearchForLoadGet$Json.PATH = '/templates/search-for-load';
