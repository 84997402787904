<ng-container *ngIf="blankRecipe">
  <div class="eln-recipe-designer-no-data flex align-items-center justify-content-center">
    <div class="col-12 flex flex-column align-items-center eln-recipe-designer-no-data-img">
      <img
        src="../../../assets/eln-assets/no-data.svg"
        alt=""
        id="eln-no-data-img"
        class="eln-recipe-designer-no-data-img"
      />
      <span i18n="@@noRecipeTemplates" class="recipe-designer-no-data-text mt-4"
        >NO TEMPLATE/RECIPE AVAILABLE YET</span
      >
      <span i18n="@@noRecipeTemplatesSub" class="recipe-designer-no-data-text"
        >Click the button below to find a Template/Recipe</span
      >
      <p-button
        i18n-label="@@loadTemplateRecipeTitle"
        styleClass="p-button-link font-semibold"
        label="✛ Load Template/Recipe"
        id="eln-recipe-btnAddtemplate"
        class="designer-load-template-btn"
        [disabled]="checkRecipeState()"
        (onClick)="addTemplateEvent()"
      >
      </p-button>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!blankRecipe">
  <div
    *ngIf="!hasAnyModules && !hasAnyActivities"
    class="eln-recipe-designer"
  >
  <p-orderList [value]="formTables" 
    [dragdrop]="recipeNodeReOrderService.hasPermission() && recipeNodeReOrderService.hasPermissionWithRespectToFlow() && !recipeHasErrors" 
    (onReorder)="onReorder($event)" 
    class="standalone-items">
    <ng-template let-item let-i="index" pTemplate="item" class="recipe-standalone-items">
      <ng-container [ngSwitch]="item.itemType" class="recipe-standalone-items">
        <app-recipe-data-form [form]="asForm(item)"  
          *ngSwitchCase="'form'" 
          [parentNodeId]="[recipe.recipeId]">
        </app-recipe-data-form>
        <app-recipe-data-table 
          [table]="asTable(item)" 
          *ngSwitchCase="'table'" 
          [recipeId]="recipe.recipeId" 
          [parentNodeId]="[recipe.recipeId]" 
        ></app-recipe-data-table>
      </ng-container>
      
    </ng-template>
  </p-orderList>
  </div>

  <ng-container *ngIf="!hasAnyActivities && hasAnyModules">
    <app-recipe-data *ngIf="hasAnyModules"> </app-recipe-data>
  </ng-container>
</ng-container>

<app-recipe-and-template-loader
  *ngIf="showTemplateLoader"
  [loaderSearchCriteria]="loaderSearchCriteria"
  (templateLoaderResult)="updateTemplateSelection($event)"
  [templateLoaderType]="templateLoaderType"
  [searchControls]="searchControls"
  [insertOptions]="recipeService.insertOptions"
  [confirmationMessage]="confirmationMessage"
  sliderSize="x-large"
  [showInlineFilters]="true"
  i18n-headerTitle="@@sliderHeader"
  headerTitle="Search Template/Recipe"
  [locationRequired]="false"
  [toolTip]="toolTip"
  (templateLoaderInsertOptionChangedEvent)="templateLoaderInsertOptionChanged($event)"
  (templateLoaderFilterChangedEvent)="templateLoaderFilterChanged($event)"
  (templateLoaderFilterClearedEvent)="templateLoaderFilterCleared($event)"
  (confirmationDialogEvent)="confirmationFunction($event[0], $event[1], $event[2])"
  [loadConfirmationEvent]="isConfirmationRequired"
  (templateSelectionChangedEvent)="templateSelectionChanged($event)"
  (templatesLoadEvent)="applySelectedTemplate($event)"
  >
</app-recipe-and-template-loader>
