import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  OnDestroy
} from '@angular/core';
import { ActivationStart, Router } from '@angular/router';
import { BptTextInputComponent } from 'bpt-ui-library/bpt-text-input';
import { UserService } from 'services/user.service';
import { filter, timeout } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { ActivityInputItemState } from './activity-input-item-state';
import { ActivityInputItem } from '../../model/activity-input-item';
import { ActivityInputType, AddActivityInputCommand } from './../../api/models';
import { ExperimentService } from '../services/experiment.service';
import { ActivityInputService } from './../../api/services';
import { BarcodeScannerHelper } from 'services/barcode-scanner-helper';
import { Message, MessageService } from 'primeng/api';
import { AddExperimentScannedItemsCommand } from './../../api/models/add-experiment-scanned-items-command';
import { BptTreeNode } from 'bpt-ui-library/bpt-tree/model/bpttreenode.interface';
import { ActivityInputConstants } from '../inputs/shared/activity-input-constants';
import { InstrumentType } from '../instrument-connection/shared/instrument-type';
import { ActivityInputHelper } from '../inputs/shared/activity-input-helper';
export interface ActivityDetails {
  activityId: string;
  activityName: string;
}
@Component({
  selector: 'app-barcode-scanner',
  templateUrl: './barcode-scanner.component.html',
  styleUrls: ['./barcode-scanner.component.scss']
})
export class BarcodeScannerComponent implements OnInit, AfterViewInit, OnDestroy {
  scanInputModel = '';
  noDataText: string = $localize`:@@NoData:No Data`;
  textInputLabel: string = $localize`:@@ScanInput:Scan Input`;
  textInputPlaceHolder: string = $localize`:@@StartInputPlaceholder:Click here to start scanning`;
  subscriptionList: Subscription[] = [];
  barcodeScannerHelper!: BarcodeScannerHelper;
  disabledTextInput = false;
  displayScannedActivityTree = true;
  scannedWithoutTemplate = false;
  scannedItemsToDisplay: BptTreeNode[] = [];
  experimentActivities: ActivityDetails[] = [];
  selectedActivitiesToScan: ActivityDetails[] = [];
  iconList = [
    { 'type': 'copy', 'class': ActivityInputConstants.iconDuplicate },
    { 'type': 'pending', "class": ActivityInputConstants.iconSpinner },
    { 'type': 'success', 'class': ActivityInputConstants.iconCheck },
    { 'type': 'warning', 'class': ActivityInputConstants.iconExclamation }
  ]
  @Output() barcodeScannerClose = new EventEmitter();
  @Input() scannerOpenMode?: ActivityInputType;
  @Input() instrumentType?: InstrumentType;
  @Input() isBarcodeOpenedManually?: boolean ;

  @ViewChild('elnBarcodeScannerTextInput') inputBox!: BptTextInputComponent;
  constructor(
    private readonly userService: UserService,
    private readonly router: Router,
    private readonly experimentService: ExperimentService,
    private readonly activityInputService: ActivityInputService,
    private readonly barcodeScannerHelper2: BarcodeScannerHelper,
    private readonly messageService: MessageService,
    private readonly activityInputHelper: ActivityInputHelper
  ) {
    this.barcodeScannerHelper = barcodeScannerHelper2;
  }

  sliderOptions: SliderOptions = {} as SliderOptions;

  ngOnInit(): void {
    this.initialize();
    this.experimentActivities = this.experimentService.currentExperiment?.activities.map(activity =>
      ({ activityId: activity.activityId, activityName: activity.itemTitle }) as ActivityDetails) || [];

    const currentActivity = this.experimentService.currentActivity;
    if (currentActivity) {
      this.selectedActivitiesToScan = [{ activityId: currentActivity.activityId, activityName: currentActivity.itemTitle }];
    }
    this.sliderOptions = {
      size: 'medium', // Options : 'large','medium','small'
      visible: true,
      closeOnEscape: true,
      headerText: $localize`:@@ScanBarcode:Scan Barcode`,
      isFooterSticky: false,
      displayFooter: false,
      displayFooterWithPrimaryButton: false,
      displayFooterWithSecondaryButton: false
    };
    this.subscriptionList.push(
      this.router.events.pipe(filter((event) => event instanceof ActivationStart)).subscribe(() => {
        this.performSliderClosingActivity(false);
      })
    );

    this.subscriptionList.push(
      this.barcodeScannerHelper.instrumentEventDataSourcePrepared.subscribe((data) => {
        if (!!data) {
          if (data.activityInputType === ActivityInputType.Instrument)
            this.performSliderClosingActivity(false);
        } else {
          this.performSliderClosingActivity(false);
        }
      })
    );

    this.subscriptionList.push(
      this.barcodeScannerHelper.activityScannedNotification.subscribe((data: boolean) => {
        this.getActivityScannedData();
        if (!data) {
          this.disabledTextInput = data;
        }
      })
    )
  }

  private disableInputTextForInstrument(disable: boolean) {
    this.disabledTextInput = disable;
  }

  getLabItemsNode(activityId: string){
   return this.experimentService.currentExperiment?.activityLabItems?.find(
      (a: any) => a.nodeId === activityId
    );
  }

  ngAfterViewInit(): void {
    this.inputBox.focus();
  }

  performSliderClosingActivity(IsSliderOpen: boolean): void {
    if (!IsSliderOpen) {
      this.barcodeScannerClose.emit(true);
    }
  }

  removeScanItem(item: ActivityInputItem): void {
    const index = this.barcodeScannerHelper.pendingScanListItems.indexOf(item, 0);
    if (index > -1) {
      this.barcodeScannerHelper.pendingScanListItems.splice(index, 1);
      if(item.state === ActivityInputItemState.Warning) {
        this.barcodeScannerHelper.allScannedItems.splice(index, 1);
      }
    }
  }

  checkForMultiScan(scanItems: ActivityInputItem[]) {
    if (this.selectedActivitiesToScan && this.selectedActivitiesToScan.length === 0 &&
       this.experimentService.currentExperiment?.reservedInstances.length !== 0) {
      this.experimentService.currentActivityId = this.experimentService.currentExperiment?.reservedInstances[0].instanceId as string;
      this.scannedWithoutTemplate = true;
      const scanItem = this.scanItemWhenNoTemplateIsAdded();
      this.barcodeScannerHelper.pendingScanListItems.unshift(scanItem);
      scanItems.push(scanItem);
    }
    this.selectedActivitiesToScan.forEach(activity => {
      const scanItem = this.getScanItem(activity.activityId);
      this.scannedWithoutTemplate = false;
      scanItem.activityId = activity.activityId;
      const isItemPreviouslyScanned = this.barcodeScannerHelper.pendingScanListItems.some(scannedItem => scannedItem.activityId === scanItem.activityId
        && scannedItem.state === ActivityInputItemState.Pending &&
        scannedItem.activityInputReference.toLowerCase() === scanItem.activityInputReference.toLowerCase())
      if (isItemPreviouslyScanned) {
        scanItem.state = ActivityInputItemState.Copy;
      }
      this.barcodeScannerHelper.pendingScanListItems.unshift(scanItem);
      scanItems.push(scanItem);
    });
  }

  scanItemWhenNoTemplateIsAdded(): ActivityInputItem {
    const scanItem = this.getScanItem(this.experimentService.currentActivityId);
    this.barcodeScannerHelper.setScannedWithoutTemplateStatus(true);
    const isItemPreviouslyScanned = this.barcodeScannerHelper.pendingScanListItems.some(
      (scannedItem) =>
        (scannedItem.state === ActivityInputItemState.Pending) &&
        scannedItem.activityInputReference.toLowerCase() ===
          scanItem.activityInputReference.toLowerCase()
    );
    if (isItemPreviouslyScanned) {
      scanItem.state = ActivityInputItemState.Copy;
    }
    if (this.experimentService.currentExperiment?.reservedInstances[0])
      scanItem.activityId =
        this.experimentService.currentExperiment?.reservedInstances[0].instanceId;
    return scanItem;
  }

  proceedToScan(scanItems: ActivityInputItem[]) {
    const scansToProceed = scanItems.filter(k => k.state !== ActivityInputItemState.Copy && k.state !== ActivityInputItemState.Warning);
      if (!!scansToProceed && scansToProceed.length > 0) {
        const scanItem = scanItems.find((k: any) => k.activityInputReference === this.scanInputModel);
        if (scanItem) {
          this.addActivityInputItem(scanItem, scansToProceed.map(s => s.activityId));
        }
      }
    }

    addScanItem(e: KeyboardEvent): void {
      if (e.key === 'Enter') {
        const activityInputType = this.getActivityInputType();
        if (activityInputType === ActivityInputType.Aliquot ||
          activityInputType === ActivityInputType.Material ||
          activityInputType === ActivityInputType.InstrumentDetails ||
          activityInputType === ActivityInputType.InstrumentColumn ||
          activityInputType === ActivityInputType.Instrument ||
          activityInputType === ActivityInputType.Preparation) {
          if (!this.validScanForNoTemplate(activityInputType)) {
            const invalidScan = $localize`:@@itemCannotBeScanned:Couldn't scan item ${this.scanInputModel}`;
            this.createErrorNotificationMessage(invalidScan, $localize`:@@onlyInputsScanIsAllowed:Cannot scan this item without an activity`);
            return;
          }
          const scanItems: ActivityInputItem[] = [];
          if (!this.isScanItemValid()) return;
          if (activityInputType === ActivityInputType.Preparation &&
            this.isPreparationScanNotAllowed()) {
            return;
          }
          this.checkForMultiScan(scanItems);
          this.getActivityScannedData();
          this.proceedToScan(scanItems);
          this.scanInputModel = '';
        }
      }
    }

  public validScanForNoTemplate(activityInputType: ActivityInputType): boolean {
    const scannableActivityInputTypes = [
      ActivityInputType.Aliquot,
      ActivityInputType.Material,
      ActivityInputType.Instrument
    ];
    return ((scannableActivityInputTypes.includes(activityInputType) &&
        this.selectedActivitiesToScan.length === 0) ||
      (this.selectedActivitiesToScan.length > 0 && this.experimentActivities.length > 0))
  }

  public disableInputTextForPhMeterScan(disable: boolean) {
    if (this.instrumentType)
      this.disabledTextInput = disable;
  }


  private getScanItem(activityId?: string): ActivityInputItem {
    const activityInputType = this.getActivityInputType();
    //check for unsupported scanned items
    if (
      (activityInputType === ActivityInputType.Instrument &&
        this.scannerOpenMode !== ActivityInputType.Instrument) ||
      (activityInputType !== ActivityInputType.Instrument &&
        this.scannerOpenMode === ActivityInputType.Instrument) || (this.scannerOpenMode === ActivityInputType.InstrumentDetails &&
          this.instrumentType === InstrumentType.phMeter && activityInputType !== ActivityInputType.InstrumentDetails)
    ) {
      return this.failedScannedItemDetails(activityInputType);
    }
    if (activityId) {
      if (this.isRemovedActivityInput(activityId)) {
        return this.decorateScannedRemovedItem(activityInputType);
      }
      if (this.isExistingScanItem(activityInputType, activityId)) {
        if (activityInputType === ActivityInputType.InstrumentDetails) {
          this.experimentService.barcodeScanned.next({
            scanStatus: ActivityInputItemState.Success,
            scannerMode: this.scannerOpenMode as ActivityInputType,
            instrumentType: this.instrumentType as InstrumentType
          });
        }
        return this.buildScanItem(ActivityInputItemState.Copy, activityInputType);
      }
    }
    switch (activityInputType) {
      case ActivityInputType.Instrument:
        this.disableInputTextForInstrument(true);
        return this.buildScanItem(ActivityInputItemState.Pending, activityInputType, false);
      case ActivityInputType.InstrumentColumn:
        return this.buildScanItem(ActivityInputItemState.Pending, activityInputType);
      case ActivityInputType.Material:
      case ActivityInputType.Aliquot:
      case ActivityInputType.InstrumentDetails:
        this.disableInputTextForPhMeterScan(true);
        return this.buildScanItem(ActivityInputItemState.Pending, activityInputType);
      case ActivityInputType.Preparation:
        return this.buildScanItem(ActivityInputItemState.Pending, activityInputType);
      default:
        return this.failedScannedItemDetails(activityInputType);
    }
  }

  /*
  Determines the Preparation Scan is valid in Scenario
  1.Not exists in same activity.
  2.Not exists in the same LabItem  already.
  3.Not exists in the removed rows section of the table.
  */
  isPreparationScanNotAllowed():boolean {

    if (this.selectedActivitiesToScan.length > 1) {
      this.createErrorNotificationMessage($localize`:@@preparationCannotBeScanned:Couldn't scan preparation ${this.scanInputModel}`,
        $localize`:@@preparationScanMultipleActivities:Preparation cannot be scanned into multiple activities. Please select one activity at a time to scan as lab items`)
      return true;
    }
    const activityId = this.selectedActivitiesToScan[0].activityId
    const activityLabItems = this.experimentService.currentExperiment?.activityLabItems.find(
      labItems => labItems.nodeId === activityId);
    const currentActivity = this.experimentService.currentExperiment?.activities.find(
      act => act.activityId === activityId);

    const cannotScanPreparationHeading = $localize`:@@preparationCannotBeScanned:Couldn't scan preparation ${this.scanInputModel}`;

    //return true if its belongs to same activity
    if(currentActivity?.preparations?.some(prep=>prep.preparationNumber === this.scanInputModel)){
      this.createErrorNotificationMessage(cannotScanPreparationHeading, $localize`:@@preparationOfSameSource:Preparation cannot be scanned into the same activity where it is created.`)
      return true;
    }
    //return true if scanned preparation is present in the removed rows section of the table
    if(activityLabItems?.preparations?.some(prep=>prep.preparationNumber === this.scanInputModel && prep.summary.preparationSubStatus)) {
      this.createErrorNotificationMessage(cannotScanPreparationHeading, $localize`:@@preparationIsInRemovedSection:Item was previously scanned and was removed. You must restore the item to add it to the lab item.`);
      return true;
    }
    // return true if in scanned in the same activity
    if(activityLabItems?.preparations?.some(prep=>prep.preparationNumber === this.scanInputModel)){
      this.createErrorNotificationMessage(cannotScanPreparationHeading, $localize`:@@preparationAlreadyExists:Preparation already exists in the selected activity.`)
      return true;
    }
    return false;
  }

  private failedScannedItemDetails(activityInputType: ActivityInputType): ActivityInputItem {
    const item = this.buildScanItem(ActivityInputItemState.Warning, activityInputType);
    item.tooltipText = $localize`:@@InvalidScannedItemMessage:Type of item scanned could not be identified`;
    this.experimentService.barcodeScanned.next({
      scanStatus: ActivityInputItemState.Warning,
      scannerMode: this.scannerOpenMode as ActivityInputType,
      instrumentType: this.instrumentType as InstrumentType
    });
    return item;
  }

  private buildScanItem(
    state: ActivityInputItemState,
    type: ActivityInputType,
    isSupported = true
  ): ActivityInputItem {
    return {
      activityId: this.getActivityId(type),
      experimentId:
        typeof this.experimentService.currentExperiment?.id === 'undefined'
          ? ''
          : this.experimentService.currentExperiment?.id,
      activityInputReference: this.scanInputModel,
      state: state,
      activityInputType: type,
      isSupported: isSupported,
      tooltipText: ''
    };
  }

  getActivityId(type: ActivityInputType){
    if(type === ActivityInputType.Aliquot || type === ActivityInputType.Instrument || type === ActivityInputType.Material)
    {
         return '';
    }
    else{
      return this.experimentService?.currentActivityId;
    }
  }

  private isRemovedActivityInput(activityId: string): boolean {
    return this.isRemovedMaterial(activityId) || this.isRemovedInstrument(activityId) || this.isRemovedSampleAliquot(activityId) || this.isRemovedMaterialAliquot(activityId) || this.isRemovedInstrumentColumn(activityId);
  }

  private isRemovedMaterial(activityId:string): boolean {
    if (activityId !== this.experimentService.currentActivityId) {
      return this.doesMaterialRemovedInAnyOtherSelectedActivity(activityId) as boolean
    }
    return this.barcodeScannerHelper.labItemsMaterialCollection.some(
      (material) => material.code === this.scanInputModel && material.isRemoved
    );
  }

  private isRemovedInstrument(activityId: string): boolean {
    if (this.scannerOpenMode !== ActivityInputType.Instrument) {
    if (activityId !== this.experimentService.currentActivityId) {
      return this.doesLabItemInstrumentRemovedInAnyOtherSelectedActivity(activityId) as boolean
    }
    return this.barcodeScannerHelper.labItemsInstrumentCollection.some(
      (instrument) => instrument.code === this.scanInputModel && instrument.isRemoved
    );
    }
    return false;
  }

   isRemovedInstrumentColumn(activityId: string): boolean {
    if (activityId !== this.experimentService.currentActivityId) {
      return this.doesLabItemInstrumentColumnRemovedInAnyOtherSelectedActivity(activityId) as boolean
    }
    return this.barcodeScannerHelper.labItemsColumnCollection.some(
      (column) => column.code === this.scanInputModel && column.isRemoved
    );
  }

   isRemovedSampleAliquot(activityId: string): boolean {
    if (activityId !== this.experimentService.currentActivityId) {
      return this.doesSampleRemovedInAnyOtherSelectedActivity(activityId) as boolean
    }
    return this.activityInputHelper.getSampleAliquotByAliquotNumber(this.scanInputModel)?.isRemoved === true;
  }

   isRemovedMaterialAliquot(activityId: string): boolean {
    if (activityId !== this.experimentService.currentActivityId) {
      return this.doesMaterialRemovedInAnyOtherSelectedActivity(activityId) as boolean
    }
    return this.activityInputHelper.getMaterialByAliquotNumber(this.scanInputModel)?.isRemoved === true;
  }

  private getActivityInputType(): ActivityInputType {

    if (this.isPreparationRegeX()) return ActivityInputType.Preparation;

    if (this.isMaterialAliquotRegX()) return ActivityInputType.Material;

    if (this.isSampleAliquotRegX()) return ActivityInputType.Aliquot;

    if (this.isInstrumentColumnRegX()) return ActivityInputType.InstrumentColumn;

    if (this.isInstrumentRegX() && this.scannerOpenMode === ActivityInputType.Instrument)
      return ActivityInputType.Instrument;
    if (this.isInstrumentRegX() && this.scannerOpenMode !== ActivityInputType.Instrument)
      return ActivityInputType.InstrumentDetails;

    return ActivityInputType.Invalid;
  }

  private isInstrumentRegX() {
    const instrumentRegX = new RegExp(`^[a-zA-Z]{2}-[a-zA-Z0-9-:]*$`, 'gi');
    return instrumentRegX.test(this.scanInputModel);
  }

  private isInstrumentColumnRegX() {
    const instrumentColumnRegX = /^IC-/gi;
    return instrumentColumnRegX.test(this.scanInputModel);
  }

  private isSampleAliquotRegX() {
    const sampleAliquotRegX = /:[a-h,j-n,q,s-z]{1,3}$/gi;
    return sampleAliquotRegX.test(this.scanInputModel);
  }

  private isMaterialAliquotRegX() {
    const materialAliquotRegX =
      /^[a-zA-Z]{2}-[a-zA-Z]{3}-[a-zA-Z0-9]{4}-[0-9]{2}-[0-9]{3}-[a-zA-Z0-9]{4}:[a-zA-Z]*$/gi;
    return materialAliquotRegX.test(this.scanInputModel);
  }
  private isPreparationRegeX() {
    const prepRegX=/^PRP-/gi;
    return prepRegX.test(this.scanInputModel);
  }

  private isExistingScanItem(activityInputType: ActivityInputType, activityId: string): boolean {
    const existsInPendingScanQueue = !!this.barcodeScannerHelper.pendingScanListItems.find(
      (i) => i.activityInputReference?.toLowerCase() === this.scanInputModel.toLowerCase()
        && i.state !== ActivityInputItemState.Warning && i.activityId === activityId);
    switch (activityInputType) {
      case ActivityInputType.Aliquot:
        if(activityId !== this.experimentService.currentActivityId)
        {
          return this.doesAliquotExistsInAnyOtherSelectedActivity(activityId) as boolean;
        }
        return (
          existsInPendingScanQueue ||
          this.doesAliquotExistsInAnyOtherSelectedActivity(activityId) as boolean
        );
      case ActivityInputType.Material:
        if(activityId !== this.experimentService.currentActivityId)
        {
          return this.doesMaterialAliquotExistsInAnyOtherSelectedActivity(activityId) as boolean || this.doesLabItemMaterialExistsInAnyOtherSelectedActivity(activityId) as boolean;
        }
        return (
          existsInPendingScanQueue ||
          this.doesMaterialAliquotExistsInAnyOtherSelectedActivity(activityId) as boolean || this.doesLabItemMaterialExistsInAnyOtherSelectedActivity(activityId) as boolean
        );
      case ActivityInputType.InstrumentColumn:
        if(activityId !== this.experimentService.currentActivityId)
        {
          return this.doesLabItemInstrumentColumnExistsInAnyOtherSelectedActivity(activityId) as boolean;
        }
        return (
          existsInPendingScanQueue ||
          this.doesLabItemInstrumentColumnExistsInAnyOtherSelectedActivity(activityId) as boolean
        );
      case ActivityInputType.InstrumentDetails:
        if(activityId !== this.experimentService.currentActivityId)
        {
          return this.doesLabItemInstrumentExistsInAnyOtherSelectedActivity(activityId) as boolean;
        }
        return (
          existsInPendingScanQueue ||
          this.doesLabItemInstrumentExistsInAnyOtherSelectedActivity(activityId) as boolean
        );
      default:
        return existsInPendingScanQueue;
    }
  }

  doesAliquotExistsInAnyOtherSelectedActivity(activityId: string) {
    const activityInputNode = this.experimentService.currentExperiment?.activityInputs?.find(
      (a: any) => a.activityId === activityId
    );
    return activityInputNode?.aliquots.some(k => k.aliquotNumber === this.scanInputModel);
  }

  doesSampleRemovedInAnyOtherSelectedActivity(activityId: string) {
    const activityInputNode = this.experimentService.currentExperiment?.activityInputs?.find(
      (a: any) => a.activityId === activityId
    );
    return activityInputNode?.aliquots.some(k => k.aliquotNumber === this.scanInputModel && k.isRemoved);
  }

  doesMaterialAliquotExistsInAnyOtherSelectedActivity(activityId: string) {
    const activityInputNode = this.experimentService.currentExperiment?.activityInputs?.find(
      (a: any) => a.activityId === activityId
    );
    return activityInputNode?.materials.some(k => k.code === this.scanInputModel);
  }

  doesMaterialRemovedInAnyOtherSelectedActivity(activityId: string) {
    const activityInputNode = this.experimentService.currentExperiment?.activityInputs?.find(
      (a: any) => a.activityId === activityId
    );
    return activityInputNode?.materials.some(k => k.code === this.scanInputModel && k.isRemoved) ||  this.getLabItemsNode(activityId)?.materials.some((k: any) => k.code === this.scanInputModel && k.isRemoved);
  }


  doesLabItemInstrumentColumnExistsInAnyOtherSelectedActivity(activityId: string) {
    return this.getLabItemsNode(activityId)?.instrumentColumns.some((k: any) => k.code === this.scanInputModel);
  }

  doesLabItemInstrumentColumnRemovedInAnyOtherSelectedActivity(activityId: string) {
    return this.getLabItemsNode(activityId)?.instrumentColumns.some((k: any) => k.code === this.scanInputModel && k.isRemoved);
  }

  doesLabItemMaterialExistsInAnyOtherSelectedActivity(activityId: string) {
    return this.getLabItemsNode(activityId)?.materials.some((k: any) => k.code === this.scanInputModel);
  }

  doesLabItemInstrumentRemovedInAnyOtherSelectedActivity(activityId: string) {
    return this.getLabItemsNode(activityId)?.instruments.some((k: any) => k.code === this.scanInputModel && k.isRemoved);
  }

  doesLabItemInstrumentExistsInAnyOtherSelectedActivity(activityId: string) {
    return this.getLabItemsNode(activityId)?.instruments.some((k: any) => k.code === this.scanInputModel);
  }

  private isScanItemValid(): boolean {
    return this.scanInputModel.trim().length !== 0;
  }

  private addActivityInputItem(activityInputItem: ActivityInputItem, activityIdsToProceedForScan: string[]): void {
    if (activityInputItem.activityInputType === ActivityInputType.Aliquot || activityInputItem.activityInputType === ActivityInputType.InstrumentColumn ||
      activityInputItem.activityInputType === ActivityInputType.InstrumentDetails || activityInputItem.activityInputType === ActivityInputType.Material
      || activityInputItem.activityInputType === ActivityInputType.Instrument|| activityInputItem.activityInputType === ActivityInputType.Preparation) {
      const inputItemCommand: AddExperimentScannedItemsCommand = {
        activityIds: activityIdsToProceedForScan,
        experimentId: activityInputItem.experimentId,
        activityInputReference: activityInputItem.activityInputReference,
        activityInputType: activityInputItem.activityInputType,
        areReservedIdsUsed: this.scannedWithoutTemplate
      };

      this.activityInputService.activityInputAddExperimentScannedItemsPost$Response({ body: inputItemCommand }).subscribe();
      this.experimentService._isCurrentUserCollaboratorSubject$.next(true);
      return;
    }

  }

  private initialize() {
    this.barcodeScannerHelper.pendingScanListItems = [];
    this.barcodeScannerHelper.allScannedItems = [];
    const aliquots: ActivityInputItem[] = this.barcodeScannerHelper.pendingScanListItems;
    const materials: ActivityInputItem[] = [];
    const instrumentColumns: ActivityInputItem[] = [];
    const instruments: ActivityInputItem[] = [];

    this.barcodeScannerHelper.pendingScanListItems.push(
      ...aliquots,
      ...instrumentColumns,
      ...instruments,
      ...materials
    );
  }

  ngOnDestroy(): void {
    this.subscriptionList.forEach((s) => s.unsubscribe());
  }

  private decorateScannedRemovedItem(activityInputType: ActivityInputType) {
    const item = this.buildScanItem(ActivityInputItemState.Warning, activityInputType);
    this.experimentService.barcodeScanned.next({
      scanStatus: ActivityInputItemState.Warning,
      scannerMode: this.scannerOpenMode as ActivityInputType,
      instrumentType: this.instrumentType as InstrumentType
    });
    item.tooltipText = $localize`:@@RemovedScannedItemMessage: Item was previously scanned and was removed. You must restore the item to return it to the proper grid.`;
    this.createErrorNotificationMessage(
      $localize`:@@RemovedScannedItemMessage: Item was previously scanned and was removed. You must restore the item to return it to the proper grid.`,
      ''
    );
    return item;
  }

  public activitySelectionChanged() {
    this.disabledTextInput = this.selectedActivitiesToScan.length === 0;
  }

  public disableInputField(activity: ActivityDetails): boolean {
    if ((this.experimentActivities.length === 1) || this.instrumentType === InstrumentType.phMeter ) {
      return true;
    }
    else if (this.scannerOpenMode === ActivityInputType.Instrument) {
      const instrument = this.experimentService.currentExperiment?.activityInputs?.find(
        (a: any) => a.activityId === activity.activityId)?.instruments;
        return instrument ? !instrument.isRemoved : false;
    }
    return false;
  }

  public getActivityScannedData() {
    this.displayScannedActivityTree = false;
    if(this.selectedActivitiesToScan.length === 0) {
      this.getScannedDataForInputs();
    }
    if(this.selectedActivitiesToScan) {
    this.selectedActivitiesToScan.map(s => s.activityId).forEach(activityId => {
      const scannedItemPerActivity = this.barcodeScannerHelper.pendingScanListItems.filter(k => k.activityId === activityId);
      if (!!scannedItemPerActivity) {
        var childrenPerActivity = [] as BptTreeNode[]
        scannedItemPerActivity.forEach((child: ActivityInputItem) => {
           childrenPerActivity.push({
            label: child.activityInputReference,
            type: child.state,
            tooltip: child.tooltipText,
            tooltipPosition: 'left'
          })
        })
        const activityLabel = this.experimentActivities.find(act => act.activityId === activityId)!.activityName
        if (this.scannedItemsToDisplay.some(data => data.label === activityLabel)) {
          this.scannedItemsToDisplay.find(data => data.label === activityLabel)!.children = childrenPerActivity;
        }
        else if (childrenPerActivity.length > 0) {
          this.scannedItemsToDisplay.push({
            label: this.experimentActivities.find(act => act.activityId === activityId)!.activityName,
            children: childrenPerActivity
          });
        }
      }
    })
  }
    setTimeout(() => {
      this.displayScannedActivityTree = true;
    }, 0)
  }

  private getScannedDataForInputs() {
    const scannedItemPerActivity = this.barcodeScannerHelper.pendingScanListItems;
    if (!!scannedItemPerActivity) {
      var childrenPerActivity = [] as BptTreeNode[];
      scannedItemPerActivity.forEach((child: ActivityInputItem) => {
        childrenPerActivity.push({
          label: child.activityInputReference,
          type: child.state,
          tooltip: child.tooltipText,
          tooltipPosition: 'left'
        });
      });
      const activityLabel = 'Inputs';
      const inputs = this.scannedItemsToDisplay.find((data) => data.label === activityLabel);
      if (inputs) {
        inputs.children = childrenPerActivity;
      } else if (childrenPerActivity.length > 0) {
        this.scannedItemsToDisplay.push({
          label: 'Inputs',
          children: childrenPerActivity
        });
      }
    }
  }

  private createErrorNotificationMessage(summary: string, detail: string) {
    const messageObj: Message = {
      key: 'notification',
      severity: 'error',
      summary,
      detail,
      sticky: false
    };
    this.messageService.add(messageObj);
  }
}

export interface SliderOptions {
  size: string;
  visible: boolean;
  closeOnEscape: boolean;
  headerText: string;
  isFooterSticky: boolean;
  displayFooter: boolean;
  displayFooterWithPrimaryButton: boolean;
  displayFooterWithSecondaryButton: boolean;
}
