import { Injectable } from '@angular/core';
import { FieldType } from '../api/models';
import { FormContextMenuItem } from 'bpt-ui-library/bpt-context-menu';
import { RecipeFieldComponent } from '../recipe/data/field/recipe-field.component';
import { FieldComponent } from '../experiment/data/field/field.component';

@Injectable({
  providedIn: 'root'
})
export class FormFieldCopyHelper {
  readonly copyIcon = '<img src="assets/eln-assets/apps-add.svg" class="ag-icon ag-custom-icon" />';
  readonly copyLabel = $localize`:@@copy:Copy`;

  copyMenuItem(component: RecipeFieldComponent | FieldComponent): FormContextMenuItem {
    let copyMenuItem: FormContextMenuItem;
    switch (component.fieldType) {
      case FieldType.Quantity:
      case FieldType.Specification:
        copyMenuItem = {
          label: this.copyLabel,
          action: () => {
            switch (component.fieldType) {
              case FieldType.Quantity:
                component.quantityInput.copyQuantityToClipboard().catch((err: any) => { throw err; });
                break;
              case FieldType.Specification:
                component.copySpecToClipboard().catch((err: any) => {
                  throw err;
                });
                break;
              default:
                throw new Error(`Copy not implemented for ${component.fieldType}!`);
            }
          },
          icon: this.copyIcon,
        }
        break;
      case FieldType.EditableList:
      case FieldType.List:
        const actor = (component.dropdownList ?? component.editableList);
        copyMenuItem = {
          label: this.copyLabel,
          disabled: actor === undefined,
          action: () => {
            actor.copy().catch((err: any) => { throw err; });
          },
          icon: this.copyIcon,
        }
        break;
      default:
        copyMenuItem = 'copy';
        break;
    }

    return copyMenuItem;
  }
}
