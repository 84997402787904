/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ChangeUnitListResponse } from '../models/change-unit-list-response';
import { ChangeUnitResponse } from '../models/change-unit-response';
import { CreateUnitListResponse } from '../models/create-unit-list-response';
import { CreateUnitResponse } from '../models/create-unit-response';
import { UnitListResponse } from '../models/unit-list-response';
import { UnitResponse } from '../models/unit-response';
import { unitsCreatePost$Json } from '../fn/units/units-create-post-json';
import { UnitsCreatePost$Json$Params } from '../fn/units/units-create-post-json';
import { unitsCreatePost$Plain } from '../fn/units/units-create-post-plain';
import { UnitsCreatePost$Plain$Params } from '../fn/units/units-create-post-plain';
import { unitsGet$Json } from '../fn/units/units-get-json';
import { UnitsGet$Json$Params } from '../fn/units/units-get-json';
import { unitsGet$Plain } from '../fn/units/units-get-plain';
import { UnitsGet$Plain$Params } from '../fn/units/units-get-plain';
import { unitsIdPut$Json } from '../fn/units/units-id-put-json';
import { UnitsIdPut$Json$Params } from '../fn/units/units-id-put-json';
import { unitsIdPut$Plain } from '../fn/units/units-id-put-plain';
import { UnitsIdPut$Plain$Params } from '../fn/units/units-id-put-plain';
import { unitsListsCreatePost$Json } from '../fn/units/units-lists-create-post-json';
import { UnitsListsCreatePost$Json$Params } from '../fn/units/units-lists-create-post-json';
import { unitsListsCreatePost$Plain } from '../fn/units/units-lists-create-post-plain';
import { UnitsListsCreatePost$Plain$Params } from '../fn/units/units-lists-create-post-plain';
import { unitsListsGet$Json } from '../fn/units/units-lists-get-json';
import { UnitsListsGet$Json$Params } from '../fn/units/units-lists-get-json';
import { unitsListsGet$Plain } from '../fn/units/units-lists-get-plain';
import { UnitsListsGet$Plain$Params } from '../fn/units/units-lists-get-plain';
import { unitsListsIdPut$Json } from '../fn/units/units-lists-id-put-json';
import { UnitsListsIdPut$Json$Params } from '../fn/units/units-lists-id-put-json';
import { unitsListsIdPut$Plain } from '../fn/units/units-lists-id-put-plain';
import { UnitsListsIdPut$Plain$Params } from '../fn/units/units-lists-id-put-plain';

@Injectable({ providedIn: 'root' })
export class UnitsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `unitsGet()` */
  static readonly UnitsGetPath = '/units';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unitsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  unitsGet$Plain$Response(params?: UnitsGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UnitResponse>> {
    return unitsGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unitsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unitsGet$Plain(params?: UnitsGet$Plain$Params, context?: HttpContext): Observable<UnitResponse> {
    return this.unitsGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UnitResponse>): UnitResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unitsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  unitsGet$Json$Response(params?: UnitsGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<UnitResponse>> {
    return unitsGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unitsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unitsGet$Json(params?: UnitsGet$Json$Params, context?: HttpContext): Observable<UnitResponse> {
    return this.unitsGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<UnitResponse>): UnitResponse => r.body)
    );
  }

  /** Path part for operation `unitsCreatePost()` */
  static readonly UnitsCreatePostPath = '/units/create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unitsCreatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  unitsCreatePost$Plain$Response(params?: UnitsCreatePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateUnitResponse>> {
    return unitsCreatePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unitsCreatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  unitsCreatePost$Plain(params?: UnitsCreatePost$Plain$Params, context?: HttpContext): Observable<CreateUnitResponse> {
    return this.unitsCreatePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateUnitResponse>): CreateUnitResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unitsCreatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  unitsCreatePost$Json$Response(params?: UnitsCreatePost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateUnitResponse>> {
    return unitsCreatePost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unitsCreatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  unitsCreatePost$Json(params?: UnitsCreatePost$Json$Params, context?: HttpContext): Observable<CreateUnitResponse> {
    return this.unitsCreatePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateUnitResponse>): CreateUnitResponse => r.body)
    );
  }

  /** Path part for operation `unitsIdPut()` */
  static readonly UnitsIdPutPath = '/units/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unitsIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  unitsIdPut$Plain$Response(params: UnitsIdPut$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangeUnitResponse>> {
    return unitsIdPut$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unitsIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  unitsIdPut$Plain(params: UnitsIdPut$Plain$Params, context?: HttpContext): Observable<ChangeUnitResponse> {
    return this.unitsIdPut$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangeUnitResponse>): ChangeUnitResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unitsIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  unitsIdPut$Json$Response(params: UnitsIdPut$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangeUnitResponse>> {
    return unitsIdPut$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unitsIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  unitsIdPut$Json(params: UnitsIdPut$Json$Params, context?: HttpContext): Observable<ChangeUnitResponse> {
    return this.unitsIdPut$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangeUnitResponse>): ChangeUnitResponse => r.body)
    );
  }

  /** Path part for operation `unitsListsGet()` */
  static readonly UnitsListsGetPath = '/units/lists';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unitsListsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  unitsListsGet$Plain$Response(params?: UnitsListsGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UnitListResponse>> {
    return unitsListsGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unitsListsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unitsListsGet$Plain(params?: UnitsListsGet$Plain$Params, context?: HttpContext): Observable<UnitListResponse> {
    return this.unitsListsGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UnitListResponse>): UnitListResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unitsListsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  unitsListsGet$Json$Response(params?: UnitsListsGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<UnitListResponse>> {
    return unitsListsGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unitsListsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unitsListsGet$Json(params?: UnitsListsGet$Json$Params, context?: HttpContext): Observable<UnitListResponse> {
    return this.unitsListsGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<UnitListResponse>): UnitListResponse => r.body)
    );
  }

  /** Path part for operation `unitsListsCreatePost()` */
  static readonly UnitsListsCreatePostPath = '/units/lists/create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unitsListsCreatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  unitsListsCreatePost$Plain$Response(params?: UnitsListsCreatePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateUnitListResponse>> {
    return unitsListsCreatePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unitsListsCreatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  unitsListsCreatePost$Plain(params?: UnitsListsCreatePost$Plain$Params, context?: HttpContext): Observable<CreateUnitListResponse> {
    return this.unitsListsCreatePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateUnitListResponse>): CreateUnitListResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unitsListsCreatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  unitsListsCreatePost$Json$Response(params?: UnitsListsCreatePost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateUnitListResponse>> {
    return unitsListsCreatePost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unitsListsCreatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  unitsListsCreatePost$Json(params?: UnitsListsCreatePost$Json$Params, context?: HttpContext): Observable<CreateUnitListResponse> {
    return this.unitsListsCreatePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateUnitListResponse>): CreateUnitListResponse => r.body)
    );
  }

  /** Path part for operation `unitsListsIdPut()` */
  static readonly UnitsListsIdPutPath = '/units/lists/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unitsListsIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  unitsListsIdPut$Plain$Response(params: UnitsListsIdPut$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangeUnitListResponse>> {
    return unitsListsIdPut$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unitsListsIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  unitsListsIdPut$Plain(params: UnitsListsIdPut$Plain$Params, context?: HttpContext): Observable<ChangeUnitListResponse> {
    return this.unitsListsIdPut$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangeUnitListResponse>): ChangeUnitListResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unitsListsIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  unitsListsIdPut$Json$Response(params: UnitsListsIdPut$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangeUnitListResponse>> {
    return unitsListsIdPut$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unitsListsIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  unitsListsIdPut$Json(params: UnitsListsIdPut$Json$Params, context?: HttpContext): Observable<ChangeUnitListResponse> {
    return this.unitsListsIdPut$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangeUnitListResponse>): ChangeUnitListResponse => r.body)
    );
  }

}
