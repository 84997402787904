import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-slide-panel',
  templateUrl: './slide-panel.component.html',
  styleUrls: ['./slide-panel.component.scss']
})

export class SlidePanelComponent {
  @Input() slidePanelContent?:any;
  @Input() showSlidePanel=false;
  @Input() showBack=false;

  @Output() closePanel:EventEmitter<string>=new EventEmitter<string>();
  @Output() goBack:EventEmitter<string>=new EventEmitter<string>();
  
  closeSlidePanel(){
    this.closePanel.emit("closePanel");
  }

  pageBack(){
    this.goBack.emit("goBack");
  }
}
