<div *ngIf="showRecipeScalableSlider" class="template-loader-slider-container">
  <bpt-slider
    id="eln-ScalableRecipeSlider"
    [(visible)]="scalableRecipeSliderOptions.visible"
    [closeOnEscape]="scalableRecipeSliderOptions.closeOnEscape"
    [headerText]="scalableRecipeDetails.name"
    [primaryButtonLabel]="scalableRecipeSliderOptions.primaryButtonLabel"
    (footerSecondaryButtonClick)="onCancel()"
    (visibleChange)="closeSlider($event)"
    (footerPrimaryButtonClick)="onLoad()"
  >
    <div>
      <div class="text-color-secondary mb-5">
        <span>
          {{ sbuLabel }}: {{ scalableRecipeDetails.sbu }} &nbsp;
          {{ versionLabel }}: {{ scalableRecipeDetails.version }} &nbsp;
          {{ publishedOnLabel }}: {{ scalableRecipeDetails.publishedOn }}
        </span>
      </div>

      <div *ngFor="let activity of activities" class="mb-4">
        <div *ngIf="activity.itemTitle" class="text-lg font-semibold mb-3">{{ activity.itemTitle }}</div>

        <!--Setting it to false as preparations are targeted for 012 release-->
        <div *ngIf="false && hasScalablePreparations(activity.preparations)" class="mb-3">
          <div class="text-base font-semibold mb-3">Preparations</div>
          <div class="mb-3">
            <bpt-grid></bpt-grid>
          </div>
        </div>

        <div *ngFor="let module of activity.modules" class="mb-3">
          <div *ngIf="hasScalableTables([module])" class="text-base font-semibold mb-3">
            {{ scalableTableLabel }}
            <span *ngIf="module.moduleName"> - {{ module.moduleName }}</span>
          </div>
          <div class="mb-3">
            <bpt-grid
              #specificationGrid
              gridId="eln-scalable-recipe-bptGrid"
              [columnDefinitions]="specificationColumnDefs"
              [dataSource]="dataSourceMap[module.moduleId]"
              [allowRowAdd]="false"
              [gridMode]="gridMode"
              [showFilterToggleButton]="false"
              [showGridOptionsButton]="false"
              [includeRowNumberColumn]="false"
              [useJsJodaTypes]="true"
              [paginated]="false"
              [rowActionConfigurations]="gridActions"
            >
            </bpt-grid>
          </div>
        </div>
        <p-divider></p-divider>
      </div>
    </div>
  </bpt-slider>
</div>
