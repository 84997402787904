<bpt-progress-spinner
  i18n-message="@@loadingHistory"
  message="History Loading..."
  [useOverlay]="true"
  *ngIf="isLoading"></bpt-progress-spinner>

<div
  class="border-500 surface-overlay border-3 border-round p-2 surface-300 min-sized-div cfn-border"
  *ngIf="visible">
  <div class="cfn-ellipse" *ngIf="menuItems?.length! > 0 && !isNewClientFacingNote">
    <app-ellipsis-menu-overlay-component [menuItems]="menuItems"></app-ellipsis-menu-overlay-component>
  </div>
  <h2 class="panel-header" [pTooltip]="headerTooltip" tooltipPosition="left">{{ headerText }}</h2>
  <h5 *ngIf="!isNewClientFacingNote && !isNotAllowedToAddOrEditClientFacingNote()" class="-mt-2 cfn-info">{{ lastEditedBy }}, {{ lastEditedOn }}</h5>
  <div
    (click)="noteInputClicked($event)">
    <bpt-text-input
      #noteInput
      class="min-sized-textbox"
      [multiLine]="true"
      [rows]="2"
      [autoResize]="true"
      [label]="fieldLabel"
      [readOnly]="!isBeingEdited"
      [placeholder]="fieldPlaceholder"
      [(ngModel)]="commentText"
      (ngModelChange)="noteInputChange()"
      [contextMenu]="getContextMenu()"
      [suppressContextMenu]="false"></bpt-text-input>
    </div>
  <div
    class="slider-footer flex justify-content-end" [ngClass]="{'cfn-edit-submission': !isNewClientFacingNote}"
    *ngIf="isBeingEdited">
    <p-button
      class="Cancel"
      styleClass="bpt-button-compact p-button-outlined"
      [disabled]="isCancelButtonDisabled"
      i18n-label="@@Cancel"
      label="Cancel"
      (onClick)="cancel()"></p-button>
    <p-button
      styleClass="bpt-button-compact"
      [disabled]="isSubmitButtonDisabled"
      label="Submit"
      i18n-label="@@Submit"
      (onClick)="submit()"></p-button>
  </div>
  <app-applied-statement [statements]="getStatementAppliedToCfn()" [clientFacingNoteModel]="clientFacingNote">
  </app-applied-statement>
</div>
