/* tslint:disable */
/* eslint-disable */
export enum TemplateType {
  Invalid = 'invalid',
  ActivityGroup = 'activityGroup',
  Activity = 'activity',
  Module = 'module',
  Form = 'form',
  Table = 'table',
  Recipe = 'recipe',
  TableForm = 'tableForm',
  ReferencePromptPreparation = 'referencePromptPreparation'
}
