import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PromptConstants } from './prompt-constants';
import { PromptItem, PromptType, PromptItemValueType, PromptData } from './models/prompt.model';
import { MessageService } from 'primeng/api';
import { SpinnerOptions } from '../eln-progress-spinner-module/spinner-options.interface';
import { ElnProgressSpinnerService } from '../eln-progress-spinner-module/eln-progress-spinner.service';
import { Subject } from 'rxjs';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-prompt',
  templateUrl: './prompt.component.html',
  styleUrls: ['./prompt.component.scss']
})
export class PromptComponent implements OnInit {
  @Input() enableAddPrompt = false;
  @Input() prompts!: Array<PromptData>;
  @Output() submitPromptData = new EventEmitter<PromptItem<PromptType, PromptItemValueType>>();
  @Input() savePromptNotifier! : Subject<boolean>;
  @Input() editPromptNotifier! : Subject<boolean>;
  @Input() promptsReadOnly = false
  @Output() editPromptCellChangedData = new EventEmitter<{ type: PromptType, promptId: string, editedFields: {[key: string]: string}}>();
  @Output() removePromptData = new EventEmitter<{ type: PromptType, promptId: string}>();
  @Output() restorePromptData = new EventEmitter<{ type: PromptType, promptId: string }>();

  selectedPromptType!: PromptType;
  promptSliderHeaderLabel = '';
  promptModuleHeader = PromptConstants.PromptModuleHeader;
  noPromptsAvailableYet = PromptConstants.NoPromptsAvailableYet;
  promptsWillAppearHere = PromptConstants.PromptsWillAppearHere;  
  currentPromptItemValues!: ICellRendererParams;

  showSlider = false;
  promptButton = {
    label: PromptConstants.AddPromptButtonLabel,
    options: this.optionsBuilder()
  }

  private readonly spinnerOptions: SpinnerOptions = {
    message: $localize`:@@creatingPromptMessage:Creating Prompt...`,
    i18nMessage: `@@creatingPromptMessage`
  };

  private readonly spinnerOptionsForEdit: SpinnerOptions = {
    message: $localize`:@@updatingPromptMessage:Updating Prompt...`,
    i18nMessage: `@@updateingPromptMessage`
  };

  constructor(
    private readonly messageService: MessageService,
    private readonly elnProgressSpinnerService: ElnProgressSpinnerService) { }


  ngOnInit(): void {
    this.savePromptNotifier.subscribe(response => {
      if(response === true){
        this.showSuccessMessage();
      }
      this.closeSlider();
      this.elnProgressSpinnerService.Hide();
    });
    this.editPromptNotifier.subscribe(() => {
      this.closeSlider();
      this.elnProgressSpinnerService.Hide();
    });
  }

  private optionsBuilder() {
    return Object.entries(PromptType).filter(obj => obj[1] !== PromptType.Invalid).map(obj => {
      const [key, value] = obj;
      const label = PromptConstants.PromptButtonOption[key]
      return {
        label,
        command: () => this.openSlider(value, label)
      }
    })
  }

  openPromptSlider(event: { type: PromptType, label: string, values?: ICellRendererParams }) {
    this.openSlider(event.type, event.label, event.values);
  }

  openSlider(promptType: PromptType, label: string, values?: ICellRendererParams) {
    this.selectedPromptType = promptType;
    this.promptSliderHeaderLabel = label;
    this.showSlider = true;
    this.currentPromptItemValues = values as ICellRendererParams;
  }

  closeSlider() {
    this.showSlider = false;
  }

  handleUpdatePrompt(event: {type: PromptType, promptId: string, editedFields: {[key: string]: string}}) {
    this.elnProgressSpinnerService.Show(this.spinnerOptionsForEdit);
    this.editPromptCellChangedData.emit(event);
  }

  handleCellEditedInPrompt(event: { type: PromptType, promptId: string, editedFields: {[key: string]: string}}) {
    this.editPromptCellChangedData.emit(event);
  }

  handleCreatePrompt(promptItem: PromptItem<PromptType, PromptItemValueType>) {
    this.elnProgressSpinnerService.Show(this.spinnerOptions);
    this.submitPromptData.emit(promptItem);
  }

  getColumnDefinitions(type: PromptType) {
    switch (type) {
      case PromptType.Materials:
        return PromptConstants.MaterialPromptDefinitions;
      case PromptType.Columns:
        return PromptConstants.ColumnPromptDefinitions;
      case PromptType.Instruments:
        return PromptConstants.InstrumentPromptDefinitions;
      case PromptType.Preparations:
        return PromptConstants.PreparationPromptDefinitions;
      case PromptType.ConsumablesAndSupplies:
        return PromptConstants.ConsumableSupplyPromptDefinitions;
      default:
        return [];
    }
  }

  private showSuccessMessage() {
    this.messageService.add({
      key: 'notification',
      severity: 'success',
      id: 'prompt-create-success-toast',
      detail: $localize`:@@promptSuccessMessage:Prompt Added successfully`,
      summary: '',
      sticky: false
    });
  }
}
