import { Component} from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { NA } from 'bpt-ui-library/shared';
import { ActivityInputValidationHelper } from 'services/activity-input-validation-helper';

@Component({
  selector: 'app-formatted-date-renderer',
  template: `
            <span>{{this.formattedDateToDisplay}}</span>`
})

export class FormattedDateRendererComponent implements ICellRendererAngularComp{
  refresh(): boolean {
    return false;
  }

  constructor(public validationHelper : ActivityInputValidationHelper){}
  public params!: any;
  formattedDateToDisplay = ""

  agInit(params: any): void {
    this.params = params;
    this.formattedDateToDisplay = this.params.value === NA || this.params.value ==="" ? this.params.value : this.validationHelper.getConvertedDateToDisplay(this.params.value);
  }
}
