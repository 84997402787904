/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { experimentLabelsPrintPost$Json } from '../fn/labels/experiment-labels-print-post-json';
import { ExperimentLabelsPrintPost$Json$Params } from '../fn/labels/experiment-labels-print-post-json';
import { experimentLabelsPrintPost$Plain } from '../fn/labels/experiment-labels-print-post-plain';
import { ExperimentLabelsPrintPost$Plain$Params } from '../fn/labels/experiment-labels-print-post-plain';
import { PrintJobResponse } from '../models/print-job-response';

@Injectable({ providedIn: 'root' })
export class LabelsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `experimentLabelsPrintPost()` */
  static readonly ExperimentLabelsPrintPostPath = '/experiment-labels/print';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentLabelsPrintPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentLabelsPrintPost$Plain$Response(params?: ExperimentLabelsPrintPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<PrintJobResponse>> {
    return experimentLabelsPrintPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentLabelsPrintPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentLabelsPrintPost$Plain(params?: ExperimentLabelsPrintPost$Plain$Params, context?: HttpContext): Observable<PrintJobResponse> {
    return this.experimentLabelsPrintPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<PrintJobResponse>): PrintJobResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentLabelsPrintPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentLabelsPrintPost$Json$Response(params?: ExperimentLabelsPrintPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<PrintJobResponse>> {
    return experimentLabelsPrintPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentLabelsPrintPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentLabelsPrintPost$Json(params?: ExperimentLabelsPrintPost$Json$Params, context?: HttpContext): Observable<PrintJobResponse> {
    return this.experimentLabelsPrintPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<PrintJobResponse>): PrintJobResponse => r.body)
    );
  }

}
