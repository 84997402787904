/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AuditHistoryDataRecordResponse } from '../models/audit-history-data-record-response';
import { preparationsAuditHistoryExperimentIdPost$Json } from '../fn/preparation-audit-history/preparations-audit-history-experiment-id-post-json';
import { PreparationsAuditHistoryExperimentIdPost$Json$Params } from '../fn/preparation-audit-history/preparations-audit-history-experiment-id-post-json';
import { preparationsAuditHistoryExperimentIdPost$Plain } from '../fn/preparation-audit-history/preparations-audit-history-experiment-id-post-plain';
import { PreparationsAuditHistoryExperimentIdPost$Plain$Params } from '../fn/preparation-audit-history/preparations-audit-history-experiment-id-post-plain';

@Injectable({ providedIn: 'root' })
export class PreparationAuditHistoryService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `preparationsAuditHistoryExperimentIdPost()` */
  static readonly PreparationsAuditHistoryExperimentIdPostPath = '/preparations-audit-history/{experimentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `preparationsAuditHistoryExperimentIdPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsAuditHistoryExperimentIdPost$Plain$Response(params: PreparationsAuditHistoryExperimentIdPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<AuditHistoryDataRecordResponse>> {
    return preparationsAuditHistoryExperimentIdPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `preparationsAuditHistoryExperimentIdPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsAuditHistoryExperimentIdPost$Plain(params: PreparationsAuditHistoryExperimentIdPost$Plain$Params, context?: HttpContext): Observable<AuditHistoryDataRecordResponse> {
    return this.preparationsAuditHistoryExperimentIdPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<AuditHistoryDataRecordResponse>): AuditHistoryDataRecordResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `preparationsAuditHistoryExperimentIdPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsAuditHistoryExperimentIdPost$Json$Response(params: PreparationsAuditHistoryExperimentIdPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<AuditHistoryDataRecordResponse>> {
    return preparationsAuditHistoryExperimentIdPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `preparationsAuditHistoryExperimentIdPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsAuditHistoryExperimentIdPost$Json(params: PreparationsAuditHistoryExperimentIdPost$Json$Params, context?: HttpContext): Observable<AuditHistoryDataRecordResponse> {
    return this.preparationsAuditHistoryExperimentIdPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<AuditHistoryDataRecordResponse>): AuditHistoryDataRecordResponse => r.body)
    );
  }

}
