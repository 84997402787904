
<div
  id="eln-labitems-removed-column-grid-container"
  class="eln-lab-items-data eln-table-theme stretch"
>
  <div class="flex flex-row eln-subHeading">
    {{ subHeading }}
  </div>
  <div class="eln-tableTitle">{{ titleOfTable }}</div>
  <bpt-grid
    #labItemsRemovedColumnGrid
    class="eln-grid hide-readonly-icons"
    ngClass="true"
    gridId="{{removedRowsColumnId}}"
    [dataSource]="labItemsColumnRemovedDataSource"
    [columnDefinitions]="columnDefinitions"
    [gridMode]="gridMode"
    [allowRowAdd]="false"
    [paginated]="true"
    [showFilterToggleButton]="true"
    [showGridOptionsButton]="false"
    [paginated]="false"
    [rowActionConfigurations]="gridActions"
    (firstDataRendered)="onFirstDataRendered($event)"
    >
  </bpt-grid>
</div>