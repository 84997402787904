export const ClientState = {
  BOOKSHELF: 'eln.bookshelf',
  BOOKSHELF_QUERY: 'eln.bookshelf.query',
  BOOKSHELF_NEWEXPERIMENT: 'eln.bookshelf.new-experiment',
  BOOKSHELF_MYSHELF: 'eln.bookshelf',
  EXPERIMENT: 'eln.experiment',
  EXPERIMENT_COVER: 'eln.experiment.cover',
  EXPERIMENT_ACTIVITIES: 'eln.experiment.activities',
  EXPERIMENT_VIEWS: 'eln.experiment.views',
  COOKBOOK: 'eln.cookbook',
  COOKBOOK_MYCOOKBOOK: 'eln.cookbook',
  COOKBOOK_NEWRECIPE: 'eln.cookbook.create-recipe',
  HUDDLE: 'eln.huddle',
  NEXUS: 'eln.nexus',
  NEXUS_ACTIVITY_DESIGNER: 'eln.nexus.activity-designer',
  NEXUS_TABLE_DESIGNER: 'eln.nexus.table-designer',
  NEXUS_NEW_TABLE_DESIGNER: 'eln.nexus.new.table-designer',
  NEXUS_FORM_DESIGNER: 'eln.nexus.form-designer',
  NEXUS_NEW_FORM_DESIGNER: 'eln.nexus.new.form-designer',
  NEXUS_MODULE_DESIGNER: 'eln.nexus.module-designer',
  NEXUS_NEW_MODULE_DESIGNER: 'eln.nexus.new.module-designer',
  NEXUS_ABOUT: 'eln.nexus.about',
  NEXUS_RULES: 'eln.nexus.rules',
  RECIPE: 'eln.recipe',
  RECIPE_ABOUT: 'eln.recipe.about',
  RECIPE_DESIGNER: 'eln.recipe.designer'
};

export const CurrentState: any = {
  'eln.bookshelf': '/',
  'eln.nexus': '/nexus',
  'eln.cookbook': '/cookbook'
};

export const AccessibilityTypes = {
  ReadOnly: 'ReadOnly',
  ReadWrite: 'ReadWrite',
  Hidden: 'Hidden'
};
