export enum RuleActions {
  Na = 'Na',
  Invalid = 'Invalid',
  DisplayMessage = 'DisplayMessage',
  SetVariable = 'SetVariable',
  Calculate = 'Calculate',
  SetValue = 'SetValue',
  SetFieldValue = 'SetFieldValue',
  SeverityIndicator = 'SeverityIndicator',
  AddRow = 'AddRow',
  LoadTemplate = 'LoadTemplate'
}

export enum RuleActionContext {
  currentRow = 'CurrentRow',
  field = 'Field',
  row = 'Row'
}