import { Injectable } from '@angular/core';
import { UserPreferenceService } from '../../api/services';
import { InstrumentDetailsLimsDto, InstrumentParameterDetails, UserPreference, UserPreferenceListResponse, UserPreferenceResponse } from '../../api/models';
import { UserService } from '../../services/user.service';
import { v4 as uuid } from 'uuid';
import { MessageService, Message } from 'primeng/api';
import { Observable, Subject } from 'rxjs';
import { BlowerState } from '../model/instrument-connection/blower-state';
import { InstrumentParameterConditionalMode } from '../model/instrument-connection/instrument-parameter-conditional-modes';
import { InstrumentBlowerValues } from './shared/instrument-blower-value';

@Injectable({
  providedIn: 'root'
})
export class InstrumentConnectionService {

  balanceBlowerState?: BlowerState;
  WEIGHTS_PARAMETER_NAME = 'Weight';
  BLOWER_STATE = 'Blower State';
  BLOWER_VALUE = 'Blower Value';

  constructor(
    public readonly messageService: MessageService,
    private readonly preferenceService: UserPreferenceService,
    private readonly userService: UserService
  ) {
  }

  public readonly favoriteUserPreferenceKey: string = 'eln-instrument-connection-favorites';
  elnUserPreferenceName = 'ELN user instrument connection favorites';

  public loadFavoritePreferences(): Observable<UserPreference[]> {
    const favoritePreferencesLoaded = new Subject<UserPreference[]>();
    this.preferenceService
      .userPreferencesUserPreferenceKeyGet$Json({
        userPreferenceKey: this.favoriteUserPreferenceKey
      })
      .subscribe({
        next: (res: UserPreferenceListResponse) => {
          favoritePreferencesLoaded.next(res.userPreferences);
        }, error: () => {
          this.createNotificationMessage(
            $localize`:@@FavoriteLoadingErrorMessage:Error while loading Favorites.`,
            '',
            'error'
          );
        }
      });
    return favoritePreferencesLoaded;
  }

  public addFavoritePreference(favouritePreferences: UserPreference[], id: string): Observable<UserPreference[]> {
    const favoritePreferencesAdded = new Subject<UserPreference[]>()
    this.preferenceService
      .userPreferencesSaveUserPreferencePost$Json({
        body: this.getDefaultUserPreferenceOfFavorites(id)
      })
      .subscribe({
        next: (result: UserPreferenceResponse) => {
          if (result && result.userPreference) {
            favouritePreferences.push(result.userPreference);
            favoritePreferencesAdded.next(favouritePreferences);
          }
        }, error: () => {
          this.createNotificationMessage(
            $localize`:@@FavoriteAdditionErrorMessage:Error while adding Favorites.`,
            '',
            'error'
          );
        }
      });
    return favoritePreferencesAdded;
  }

  private getDefaultUserPreferenceOfFavorites(id: string): UserPreference {
    return {
      userPuid: this.userService.currentUser.puid,
      userPreferenceId: uuid(),
      userPreferenceKey: this.favoriteUserPreferenceKey,
      userPreferenceName: this.elnUserPreferenceName,
      userPreferenceValue: id,
      isDefault: false
    };
  }

  public deleteFavoritePreference(favouritePreferences: UserPreference[], preferenceId: string): Observable<UserPreference[]> {
    const favoritePreferencesRemoved = new Subject<UserPreference[]>()
    this.preferenceService
      .userPreferencesUserPreferenceIdDelete$Json({
        userPreferenceId: preferenceId
      })
      .subscribe({
        next: (result: UserPreferenceResponse) => {
          if (result) {
            favouritePreferences = favouritePreferences.filter(
              (preference) => preference.userPreferenceId !== preferenceId
            );
            favoritePreferencesRemoved.next(favouritePreferences);
          }
        }, error: () => {
          this.createNotificationMessage(
            $localize`:@@FavoriteRemovalErrorMessage:Error while removing Favorites`,
            '',
            'error'
          );
        }
      });
    return favoritePreferencesRemoved;
  }

  private createNotificationMessage(summary: string, detail: string, severity = 'success') {
    const messageObj: Message = {
      key: 'notification',
      severity: severity,
      summary,
      detail,
      sticky: false
    };
    this.messageService.add(messageObj);
  }

  setBlowerState(instrumentDetailsLims: InstrumentDetailsLimsDto) {
    const weightBlowerParameters =
      instrumentDetailsLims?.parameters?.filter(
        (parameter: InstrumentParameterDetails) =>
          parameter.name === this.WEIGHTS_PARAMETER_NAME &&
          parameter.conditionalMode?.includes('Blower')
      );
    if (this.checkIfInvalidBlowerState(instrumentDetailsLims)) {
      this.balanceBlowerState = BlowerState.No_Blower;
        localStorage.setItem(this.BLOWER_STATE, BlowerState.No_Blower);
        return;
    }
    if (weightBlowerParameters && weightBlowerParameters.length > 0) {
      if (weightBlowerParameters.length === 1) {
        switch (weightBlowerParameters[0].conditionalMode) {
          case InstrumentParameterConditionalMode.BlowerOn:
            this.balanceBlowerState = BlowerState.Blower_On;
            localStorage.setItem(this.BLOWER_STATE, BlowerState.Blower_On);
            return;
          case InstrumentParameterConditionalMode.BlowerOff:
            this.balanceBlowerState = BlowerState.Blower_Off;
            localStorage.setItem(this.BLOWER_STATE , BlowerState.Blower_Off);
            return;
        }
      } else if (
        weightBlowerParameters.length === 2 &&
        weightBlowerParameters.some(
          (weightParameter: InstrumentParameterDetails) =>
            weightParameter.conditionalMode === InstrumentParameterConditionalMode.BlowerOn
        ) &&
        weightBlowerParameters.some(
          (weightParameter: InstrumentParameterDetails) =>
            weightParameter.conditionalMode === InstrumentParameterConditionalMode.BlowerOff
        )
      ) {
        this.balanceBlowerState = BlowerState.Blower_On_Off;
        if (localStorage.getItem(this.BLOWER_STATE) !== this.balanceBlowerState) {
          localStorage.setItem(this.BLOWER_VALUE, InstrumentBlowerValues.yes)
        }
        localStorage.setItem(this.BLOWER_STATE , BlowerState.Blower_On_Off);
        return;
      }
    }
    this.balanceBlowerState = BlowerState.No_Blower;
    localStorage.setItem(this.BLOWER_STATE, BlowerState.No_Blower);
  }

  checkIfInvalidBlowerState(instrumentDetailsLims: InstrumentDetailsLimsDto) {
    const place4Parameters = instrumentDetailsLims?.parameters?.filter(
      (instrumentParameter: InstrumentParameterDetails) =>
        instrumentParameter.name === this.WEIGHTS_PARAMETER_NAME 
        && instrumentParameter.conditionalMode === InstrumentParameterConditionalMode.Place_4
      ) || [];
    const naWeightParameters = instrumentDetailsLims?.parameters?.filter(
      (instrumentParameter: InstrumentParameterDetails) =>
        instrumentParameter.name === this.WEIGHTS_PARAMETER_NAME 
        && instrumentParameter.conditionalMode !== InstrumentParameterConditionalMode.Place_4
        && !instrumentParameter.conditionalMode?.includes('Blower')
      ) || [];
    return (place4Parameters.length > 1 || naWeightParameters.length > 0);      
  }
}