import { HttpClient } from '@angular/common/http';
import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { IFlagConfig } from 'bpt-ui-library/bpt-grid';
import { Experiment } from 'model/experiment.interface';
import {
  ActivityInputType,
  ClientFacingNoteContextType,
  TableNode
} from '../../api/models';
import { ApiConfiguration } from '../../api/api-configuration';
import { BaseService } from '../../api/base-service';
import {
  CommentContextType,
  CommentResponse,
  InternalCommentStatus
} from '../../api/internal-comment/models';
import { ELNAppConstants } from '../../shared/eln-app-constants';
import { CommentDetails } from '../comments/comment.model';
import { ExperimentService } from './experiment.service';
import { CommentService } from '../comments/comment.service';
import { Subject } from 'rxjs';
import { ShowClientFacingNotesEventData } from '../comments/client-facing-note/client-facing-note-event.model';
import { ClientFacingNoteModel } from '../comments/client-facing-note/client-facing-note.model';

@Injectable({
  providedIn: 'root'
})
export class FlagRendererService extends BaseService {
  private readonly internalCommentsNotification = new Subject<{
    currentRowId: string;
    currentField: string;
  }>();
  private readonly clientFacingNoteNotification = new Subject<
    CustomEvent<ShowClientFacingNotesEventData>
  >();
  public InternalCommentsAdded = this.internalCommentsNotification.asObservable();
  public ClientFacingNoteAdded = this.clientFacingNoteNotification.asObservable();
  experiment: Experiment;
  table: TableNode | undefined;
  internalCommentData?: CommentDetails;
  private readonly renderer: Renderer2;

  constructor(
    config: ApiConfiguration,
    http: HttpClient,
    private readonly experimentService: ExperimentService,
    private readonly commentService: CommentService,
    rendererFactory: RendererFactory2
  ) {
    super(config, http);
    this.experiment = this.experimentService.currentExperiment as Experiment;
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  getFlagRendererConfig(
    flag: 'top-right' | 'bottom-right',
    paths: string[],
    contextType: CommentContextType | ClientFacingNoteContextType = CommentContextType.TableCell,
    itemId: string
  ): IFlagConfig {
    if (flag === 'top-right') {
      return this.clientFacingNoteFlagRenderer(contextType, paths, itemId);
    } else if (flag === 'bottom-right') {
      const tableCellContextType: CommentContextType = CommentContextType.TableCell;      
      const internalComments =
        this.experimentService.currentExperiment?.internalComments?.comments.find(
          (comment: CommentResponse) => {
            const modifiedPaths = comment.path.filter((_, index, array) => index !== array.length - 2); //exclude rowIndex at second-to-last position in path
            if (paths.join(`~`) === modifiedPaths.join('~') && comment.status !== InternalCommentStatus.Removed) {
              paths = comment.path;
              return true;
            }
            return false;
          }); // can depend on rowId being globally unique

      return {
        enabled: internalComments ? true : false,
        color: ELNAppConstants.InternalCommentFlagColor,
        hoverText: this.getHoverOverText(internalComments?.content),
        isHollow: internalComments?.status === InternalCommentStatus.Pending,
        onClick: () => this.showInternalComments(paths, tableCellContextType)
      };
    }
    // default. Future: could use the 'bottom-right' flag for, say, internal comments.
    return { enabled: false, color: 'orange', hoverText: 'not used today', onClick: () => {} };
  }

  private clientFacingNoteFlagRenderer(contextType: ClientFacingNoteContextType | CommentContextType, paths: string[], itemId: string) {
    let note;
    if (contextType === CommentContextType.Preparations || ClientFacingNoteContextType.LabItemsPreparation) {
      note = this.experimentService.currentExperiment?.clientFacingNotes.find(
        (n) => paths[0] === n.path[0] &&
          paths[1] === n.path[1] &&
          paths[2] === n.path[2]
      );
    } else {
      ({ contextType, note } = this.getContextTypeAndNote(contextType, paths)); // can depend on rowId being globally unique
    }
    return {
      enabled: note ? true : false,
      color: ELNAppConstants.ClientFacingNoteFlagColor,
      hoverText: note?.indicatorText ?? '', // by policy for notes, empty is not allowed
      onClick: () => this.showClientFacingNotes(paths, contextType as ClientFacingNoteContextType, itemId)
    };
  }

  private getContextTypeAndNote(contextType: CommentContextType | ClientFacingNoteContextType, paths: string[]) {
    if (contextType !== ClientFacingNoteContextType.Preparations) {
      contextType = ClientFacingNoteContextType.LabItems;
    }
    const note = this.experimentService.currentExperiment?.clientFacingNotes.find(
      (n) => paths.join(`~`) === n.path.join(`~`)
    );
    return { contextType, note };
  }

  showInternalComments(
    paths: string[],
    contextType: CommentContextType = CommentContextType.TableCell,
  ): void {
    this.internalCommentsNotification.next({ currentRowId: paths[2], currentField: paths[3] });  
    this.highlightCurrentCell(paths);  
    this.buildInternalCommentsForLabItems(paths, contextType);
  }

  highlightCurrentCell(paths: string[]) {
    const cell = document.querySelector(
      `ag-grid-angular [row-id="${paths[2]}"] [col-id="${paths[3]}"]`
    );

    if (cell)
      this.renderer.setStyle(
        cell,
        'background-color',
        ELNAppConstants.InternalCommentBackGroundColor
      );
  }

  showClientFacingNotes<TContext>(
    paths: string[],
    contextType: ClientFacingNoteContextType = ClientFacingNoteContextType.TableCell,
    itemId: string,
    context?: TContext
  ): void {
    const eventTarget = {
      ...ClientFacingNoteModel.getContextByTypeAndPath(itemId, contextType, paths),
      ...(context || {})
    };
    const details: ShowClientFacingNotesEventData = {
      eventContext: {
        contextType,
        mode: 'clientFacingNotes'
      },
      targetContext: eventTarget
    };
    const customEvent = new CustomEvent<ShowClientFacingNotesEventData>('ShowSlider', {
      bubbles: true,
      detail: details
    });
    this.clientFacingNoteNotification.next(customEvent);
  }

  private buildInternalCommentsForLabItems(
    paths: string[],
    contextType: CommentContextType,
  ) {
    const activity = this.experimentService.currentActivity;
    if (activity) {
      this.openInternalComments(
        activity.activityId,
        paths,
        contextType
      );
    }
  }

  private openInternalComments(
    nodeId: string,
    path: string[],
    contextType: CommentContextType
  ) {
    this.internalCommentData = {} as CommentDetails;
    this.internalCommentData.nodeId = nodeId;
    this.internalCommentData.path = path;
    this.internalCommentData.contextType = contextType;
    this.commentService.openInternalComments(this.internalCommentData);
  }

  private getHoverOverText(message = ''): string {
    return (
      $localize`:@@internalComments:Internal Comments` +
      `:${this.shortContent(
        message.replace(/<[^>]*>/g, ' ').replace(/\s{2,}/g, ' ').replace(/\&nbsp;/g,'')
      )}(click to view)`
    );
  }

  private shortContent(message: string): string {
    const limit = 30;
    return message.length <= limit ? message : message.substring(0, limit) + '…';
  }

  getPathBasedOnFlag(
    flag: string, 
    fieldName: string, 
    rowId: string, 
    field: string, 
    commentContext: CommentContextType | ClientFacingNoteContextType, 
    labItemType?: ActivityInputType
  ): string[] {
    return flag === 'bottom-right' ? [
      this.experimentService.currentActivityId,
      fieldName,
      rowId,
      field,
      labItemType ?? commentContext
    ] :
      [
        rowId,
        field,
        this.experimentService.currentActivityId,
        labItemType ?? commentContext
      ];
  }
}
