/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { DeviceResponse as ElnLabelsApiEntitiesDeviceResponse } from '../models/ELN/Labels/API/Entities/device-response';
import { LabelTemplateResponse as ElnLabelsApiEntitiesLabelTemplateResponse } from '../models/ELN/Labels/API/Entities/label-template-response';
import { PrinterStatusResponse as ElnLabelsApiEntitiesPrinterStatusResponse } from '../models/ELN/Labels/API/Entities/printer-status-response';
import { labelsLabelTemplatesTemplateTypeGet$Json } from '../fn/labels/labels-label-templates-template-type-get-json';
import { LabelsLabelTemplatesTemplateTypeGet$Json$Params } from '../fn/labels/labels-label-templates-template-type-get-json';
import { labelsLabelTemplatesTemplateTypeGet$Plain } from '../fn/labels/labels-label-templates-template-type-get-plain';
import { LabelsLabelTemplatesTemplateTypeGet$Plain$Params } from '../fn/labels/labels-label-templates-template-type-get-plain';
import { labelsPrintersGet$Json } from '../fn/labels/labels-printers-get-json';
import { LabelsPrintersGet$Json$Params } from '../fn/labels/labels-printers-get-json';
import { labelsPrintersGet$Plain } from '../fn/labels/labels-printers-get-plain';
import { LabelsPrintersGet$Plain$Params } from '../fn/labels/labels-printers-get-plain';
import { labelsPrinterStatusPrinterIdGet$Json } from '../fn/labels/labels-printer-status-printer-id-get-json';
import { LabelsPrinterStatusPrinterIdGet$Json$Params } from '../fn/labels/labels-printer-status-printer-id-get-json';
import { labelsPrinterStatusPrinterIdGet$Plain } from '../fn/labels/labels-printer-status-printer-id-get-plain';
import { LabelsPrinterStatusPrinterIdGet$Plain$Params } from '../fn/labels/labels-printer-status-printer-id-get-plain';

@Injectable({ providedIn: 'root' })
export class LabelsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `labelsLabelTemplatesTemplateTypeGet()` */
  static readonly LabelsLabelTemplatesTemplateTypeGetPath = '/labels/label-templates/{templateType}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labelsLabelTemplatesTemplateTypeGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  labelsLabelTemplatesTemplateTypeGet$Plain$Response(params: LabelsLabelTemplatesTemplateTypeGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ElnLabelsApiEntitiesLabelTemplateResponse>> {
    return labelsLabelTemplatesTemplateTypeGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `labelsLabelTemplatesTemplateTypeGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  labelsLabelTemplatesTemplateTypeGet$Plain(params: LabelsLabelTemplatesTemplateTypeGet$Plain$Params, context?: HttpContext): Observable<ElnLabelsApiEntitiesLabelTemplateResponse> {
    return this.labelsLabelTemplatesTemplateTypeGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ElnLabelsApiEntitiesLabelTemplateResponse>): ElnLabelsApiEntitiesLabelTemplateResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labelsLabelTemplatesTemplateTypeGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  labelsLabelTemplatesTemplateTypeGet$Json$Response(params: LabelsLabelTemplatesTemplateTypeGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ElnLabelsApiEntitiesLabelTemplateResponse>> {
    return labelsLabelTemplatesTemplateTypeGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `labelsLabelTemplatesTemplateTypeGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  labelsLabelTemplatesTemplateTypeGet$Json(params: LabelsLabelTemplatesTemplateTypeGet$Json$Params, context?: HttpContext): Observable<ElnLabelsApiEntitiesLabelTemplateResponse> {
    return this.labelsLabelTemplatesTemplateTypeGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ElnLabelsApiEntitiesLabelTemplateResponse>): ElnLabelsApiEntitiesLabelTemplateResponse => r.body)
    );
  }

  /** Path part for operation `labelsPrintersGet()` */
  static readonly LabelsPrintersGetPath = '/labels/printers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labelsPrintersGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  labelsPrintersGet$Plain$Response(params?: LabelsPrintersGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ElnLabelsApiEntitiesDeviceResponse>> {
    return labelsPrintersGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `labelsPrintersGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  labelsPrintersGet$Plain(params?: LabelsPrintersGet$Plain$Params, context?: HttpContext): Observable<ElnLabelsApiEntitiesDeviceResponse> {
    return this.labelsPrintersGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ElnLabelsApiEntitiesDeviceResponse>): ElnLabelsApiEntitiesDeviceResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labelsPrintersGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  labelsPrintersGet$Json$Response(params?: LabelsPrintersGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ElnLabelsApiEntitiesDeviceResponse>> {
    return labelsPrintersGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `labelsPrintersGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  labelsPrintersGet$Json(params?: LabelsPrintersGet$Json$Params, context?: HttpContext): Observable<ElnLabelsApiEntitiesDeviceResponse> {
    return this.labelsPrintersGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ElnLabelsApiEntitiesDeviceResponse>): ElnLabelsApiEntitiesDeviceResponse => r.body)
    );
  }

  /** Path part for operation `labelsPrinterStatusPrinterIdGet()` */
  static readonly LabelsPrinterStatusPrinterIdGetPath = '/labels/printer-status/{printerId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labelsPrinterStatusPrinterIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  labelsPrinterStatusPrinterIdGet$Plain$Response(params: LabelsPrinterStatusPrinterIdGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ElnLabelsApiEntitiesPrinterStatusResponse>> {
    return labelsPrinterStatusPrinterIdGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `labelsPrinterStatusPrinterIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  labelsPrinterStatusPrinterIdGet$Plain(params: LabelsPrinterStatusPrinterIdGet$Plain$Params, context?: HttpContext): Observable<ElnLabelsApiEntitiesPrinterStatusResponse> {
    return this.labelsPrinterStatusPrinterIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ElnLabelsApiEntitiesPrinterStatusResponse>): ElnLabelsApiEntitiesPrinterStatusResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labelsPrinterStatusPrinterIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  labelsPrinterStatusPrinterIdGet$Json$Response(params: LabelsPrinterStatusPrinterIdGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ElnLabelsApiEntitiesPrinterStatusResponse>> {
    return labelsPrinterStatusPrinterIdGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `labelsPrinterStatusPrinterIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  labelsPrinterStatusPrinterIdGet$Json(params: LabelsPrinterStatusPrinterIdGet$Json$Params, context?: HttpContext): Observable<ElnLabelsApiEntitiesPrinterStatusResponse> {
    return this.labelsPrinterStatusPrinterIdGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ElnLabelsApiEntitiesPrinterStatusResponse>): ElnLabelsApiEntitiesPrinterStatusResponse => r.body)
    );
  }

}
