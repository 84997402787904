/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ExperimentNewPreparations } from '../models/experiment-new-preparations';
import { PreparationCellChangedResponse } from '../models/preparation-cell-changed-response';
import { PreparationDiscardedOrConsumedResponse } from '../models/preparation-discarded-or-consumed-response';
import { PreparationInternalInformationChangedResponse } from '../models/preparation-internal-information-changed-response';
import { PreparationRemovedResponse } from '../models/preparation-removed-response';
import { PreparationRestoredResponse } from '../models/preparation-restored-response';
import { preparationsEventsChangePreparationInternalInformationPost$Json } from '../fn/preparations-events/preparations-events-change-preparation-internal-information-post-json';
import { PreparationsEventsChangePreparationInternalInformationPost$Json$Params } from '../fn/preparations-events/preparations-events-change-preparation-internal-information-post-json';
import { preparationsEventsChangePreparationInternalInformationPost$Plain } from '../fn/preparations-events/preparations-events-change-preparation-internal-information-post-plain';
import { PreparationsEventsChangePreparationInternalInformationPost$Plain$Params } from '../fn/preparations-events/preparations-events-change-preparation-internal-information-post-plain';
import { preparationsEventsChangePreparationStatusPost$Json } from '../fn/preparations-events/preparations-events-change-preparation-status-post-json';
import { PreparationsEventsChangePreparationStatusPost$Json$Params } from '../fn/preparations-events/preparations-events-change-preparation-status-post-json';
import { preparationsEventsChangePreparationStatusPost$Plain } from '../fn/preparations-events/preparations-events-change-preparation-status-post-plain';
import { PreparationsEventsChangePreparationStatusPost$Plain$Params } from '../fn/preparations-events/preparations-events-change-preparation-status-post-plain';
import { preparationsEventsCreatePreparationsPost$Json } from '../fn/preparations-events/preparations-events-create-preparations-post-json';
import { PreparationsEventsCreatePreparationsPost$Json$Params } from '../fn/preparations-events/preparations-events-create-preparations-post-json';
import { preparationsEventsCreatePreparationsPost$Plain } from '../fn/preparations-events/preparations-events-create-preparations-post-plain';
import { PreparationsEventsCreatePreparationsPost$Plain$Params } from '../fn/preparations-events/preparations-events-create-preparations-post-plain';
import { preparationsEventsDiscardOrConsumePreparationPost$Json } from '../fn/preparations-events/preparations-events-discard-or-consume-preparation-post-json';
import { PreparationsEventsDiscardOrConsumePreparationPost$Json$Params } from '../fn/preparations-events/preparations-events-discard-or-consume-preparation-post-json';
import { preparationsEventsDiscardOrConsumePreparationPost$Plain } from '../fn/preparations-events/preparations-events-discard-or-consume-preparation-post-plain';
import { PreparationsEventsDiscardOrConsumePreparationPost$Plain$Params } from '../fn/preparations-events/preparations-events-discard-or-consume-preparation-post-plain';
import { preparationsEventsPreparationCellChangePost$Json } from '../fn/preparations-events/preparations-events-preparation-cell-change-post-json';
import { PreparationsEventsPreparationCellChangePost$Json$Params } from '../fn/preparations-events/preparations-events-preparation-cell-change-post-json';
import { preparationsEventsPreparationCellChangePost$Plain } from '../fn/preparations-events/preparations-events-preparation-cell-change-post-plain';
import { PreparationsEventsPreparationCellChangePost$Plain$Params } from '../fn/preparations-events/preparations-events-preparation-cell-change-post-plain';
import { preparationsEventsRemovePreparationPost$Json } from '../fn/preparations-events/preparations-events-remove-preparation-post-json';
import { PreparationsEventsRemovePreparationPost$Json$Params } from '../fn/preparations-events/preparations-events-remove-preparation-post-json';
import { preparationsEventsRemovePreparationPost$Plain } from '../fn/preparations-events/preparations-events-remove-preparation-post-plain';
import { PreparationsEventsRemovePreparationPost$Plain$Params } from '../fn/preparations-events/preparations-events-remove-preparation-post-plain';
import { preparationsEventsRestorePreparationPost$Json } from '../fn/preparations-events/preparations-events-restore-preparation-post-json';
import { PreparationsEventsRestorePreparationPost$Json$Params } from '../fn/preparations-events/preparations-events-restore-preparation-post-json';
import { preparationsEventsRestorePreparationPost$Plain } from '../fn/preparations-events/preparations-events-restore-preparation-post-plain';
import { PreparationsEventsRestorePreparationPost$Plain$Params } from '../fn/preparations-events/preparations-events-restore-preparation-post-plain';
import { PreparationStatusChangedResponse } from '../models/preparation-status-changed-response';

@Injectable({ providedIn: 'root' })
export class PreparationsEventsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `preparationsEventsCreatePreparationsPost()` */
  static readonly PreparationsEventsCreatePreparationsPostPath = '/preparations-events/create-preparations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `preparationsEventsCreatePreparationsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsEventsCreatePreparationsPost$Plain$Response(params?: PreparationsEventsCreatePreparationsPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ExperimentNewPreparations>> {
    return preparationsEventsCreatePreparationsPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `preparationsEventsCreatePreparationsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsEventsCreatePreparationsPost$Plain(params?: PreparationsEventsCreatePreparationsPost$Plain$Params, context?: HttpContext): Observable<ExperimentNewPreparations> {
    return this.preparationsEventsCreatePreparationsPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExperimentNewPreparations>): ExperimentNewPreparations => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `preparationsEventsCreatePreparationsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsEventsCreatePreparationsPost$Json$Response(params?: PreparationsEventsCreatePreparationsPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ExperimentNewPreparations>> {
    return preparationsEventsCreatePreparationsPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `preparationsEventsCreatePreparationsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsEventsCreatePreparationsPost$Json(params?: PreparationsEventsCreatePreparationsPost$Json$Params, context?: HttpContext): Observable<ExperimentNewPreparations> {
    return this.preparationsEventsCreatePreparationsPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExperimentNewPreparations>): ExperimentNewPreparations => r.body)
    );
  }

  /** Path part for operation `preparationsEventsRemovePreparationPost()` */
  static readonly PreparationsEventsRemovePreparationPostPath = '/preparations-events/remove-preparation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `preparationsEventsRemovePreparationPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsEventsRemovePreparationPost$Plain$Response(params?: PreparationsEventsRemovePreparationPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<PreparationRemovedResponse>> {
    return preparationsEventsRemovePreparationPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `preparationsEventsRemovePreparationPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsEventsRemovePreparationPost$Plain(params?: PreparationsEventsRemovePreparationPost$Plain$Params, context?: HttpContext): Observable<PreparationRemovedResponse> {
    return this.preparationsEventsRemovePreparationPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<PreparationRemovedResponse>): PreparationRemovedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `preparationsEventsRemovePreparationPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsEventsRemovePreparationPost$Json$Response(params?: PreparationsEventsRemovePreparationPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<PreparationRemovedResponse>> {
    return preparationsEventsRemovePreparationPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `preparationsEventsRemovePreparationPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsEventsRemovePreparationPost$Json(params?: PreparationsEventsRemovePreparationPost$Json$Params, context?: HttpContext): Observable<PreparationRemovedResponse> {
    return this.preparationsEventsRemovePreparationPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<PreparationRemovedResponse>): PreparationRemovedResponse => r.body)
    );
  }

  /** Path part for operation `preparationsEventsRestorePreparationPost()` */
  static readonly PreparationsEventsRestorePreparationPostPath = '/preparations-events/restore-preparation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `preparationsEventsRestorePreparationPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsEventsRestorePreparationPost$Plain$Response(params?: PreparationsEventsRestorePreparationPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<PreparationRestoredResponse>> {
    return preparationsEventsRestorePreparationPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `preparationsEventsRestorePreparationPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsEventsRestorePreparationPost$Plain(params?: PreparationsEventsRestorePreparationPost$Plain$Params, context?: HttpContext): Observable<PreparationRestoredResponse> {
    return this.preparationsEventsRestorePreparationPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<PreparationRestoredResponse>): PreparationRestoredResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `preparationsEventsRestorePreparationPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsEventsRestorePreparationPost$Json$Response(params?: PreparationsEventsRestorePreparationPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<PreparationRestoredResponse>> {
    return preparationsEventsRestorePreparationPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `preparationsEventsRestorePreparationPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsEventsRestorePreparationPost$Json(params?: PreparationsEventsRestorePreparationPost$Json$Params, context?: HttpContext): Observable<PreparationRestoredResponse> {
    return this.preparationsEventsRestorePreparationPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<PreparationRestoredResponse>): PreparationRestoredResponse => r.body)
    );
  }

  /** Path part for operation `preparationsEventsDiscardOrConsumePreparationPost()` */
  static readonly PreparationsEventsDiscardOrConsumePreparationPostPath = '/preparations-events/discard-or-consume-preparation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `preparationsEventsDiscardOrConsumePreparationPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsEventsDiscardOrConsumePreparationPost$Plain$Response(params?: PreparationsEventsDiscardOrConsumePreparationPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<PreparationDiscardedOrConsumedResponse>> {
    return preparationsEventsDiscardOrConsumePreparationPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `preparationsEventsDiscardOrConsumePreparationPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsEventsDiscardOrConsumePreparationPost$Plain(params?: PreparationsEventsDiscardOrConsumePreparationPost$Plain$Params, context?: HttpContext): Observable<PreparationDiscardedOrConsumedResponse> {
    return this.preparationsEventsDiscardOrConsumePreparationPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<PreparationDiscardedOrConsumedResponse>): PreparationDiscardedOrConsumedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `preparationsEventsDiscardOrConsumePreparationPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsEventsDiscardOrConsumePreparationPost$Json$Response(params?: PreparationsEventsDiscardOrConsumePreparationPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<PreparationDiscardedOrConsumedResponse>> {
    return preparationsEventsDiscardOrConsumePreparationPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `preparationsEventsDiscardOrConsumePreparationPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsEventsDiscardOrConsumePreparationPost$Json(params?: PreparationsEventsDiscardOrConsumePreparationPost$Json$Params, context?: HttpContext): Observable<PreparationDiscardedOrConsumedResponse> {
    return this.preparationsEventsDiscardOrConsumePreparationPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<PreparationDiscardedOrConsumedResponse>): PreparationDiscardedOrConsumedResponse => r.body)
    );
  }

  /** Path part for operation `preparationsEventsChangePreparationInternalInformationPost()` */
  static readonly PreparationsEventsChangePreparationInternalInformationPostPath = '/preparations-events/change-preparation-internal-information';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `preparationsEventsChangePreparationInternalInformationPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsEventsChangePreparationInternalInformationPost$Plain$Response(params?: PreparationsEventsChangePreparationInternalInformationPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<PreparationInternalInformationChangedResponse>> {
    return preparationsEventsChangePreparationInternalInformationPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `preparationsEventsChangePreparationInternalInformationPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsEventsChangePreparationInternalInformationPost$Plain(params?: PreparationsEventsChangePreparationInternalInformationPost$Plain$Params, context?: HttpContext): Observable<PreparationInternalInformationChangedResponse> {
    return this.preparationsEventsChangePreparationInternalInformationPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<PreparationInternalInformationChangedResponse>): PreparationInternalInformationChangedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `preparationsEventsChangePreparationInternalInformationPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsEventsChangePreparationInternalInformationPost$Json$Response(params?: PreparationsEventsChangePreparationInternalInformationPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<PreparationInternalInformationChangedResponse>> {
    return preparationsEventsChangePreparationInternalInformationPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `preparationsEventsChangePreparationInternalInformationPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsEventsChangePreparationInternalInformationPost$Json(params?: PreparationsEventsChangePreparationInternalInformationPost$Json$Params, context?: HttpContext): Observable<PreparationInternalInformationChangedResponse> {
    return this.preparationsEventsChangePreparationInternalInformationPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<PreparationInternalInformationChangedResponse>): PreparationInternalInformationChangedResponse => r.body)
    );
  }

  /** Path part for operation `preparationsEventsPreparationCellChangePost()` */
  static readonly PreparationsEventsPreparationCellChangePostPath = '/preparations-events/preparation-cell-change';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `preparationsEventsPreparationCellChangePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsEventsPreparationCellChangePost$Plain$Response(params?: PreparationsEventsPreparationCellChangePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<PreparationCellChangedResponse>> {
    return preparationsEventsPreparationCellChangePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `preparationsEventsPreparationCellChangePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsEventsPreparationCellChangePost$Plain(params?: PreparationsEventsPreparationCellChangePost$Plain$Params, context?: HttpContext): Observable<PreparationCellChangedResponse> {
    return this.preparationsEventsPreparationCellChangePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<PreparationCellChangedResponse>): PreparationCellChangedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `preparationsEventsPreparationCellChangePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsEventsPreparationCellChangePost$Json$Response(params?: PreparationsEventsPreparationCellChangePost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<PreparationCellChangedResponse>> {
    return preparationsEventsPreparationCellChangePost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `preparationsEventsPreparationCellChangePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsEventsPreparationCellChangePost$Json(params?: PreparationsEventsPreparationCellChangePost$Json$Params, context?: HttpContext): Observable<PreparationCellChangedResponse> {
    return this.preparationsEventsPreparationCellChangePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<PreparationCellChangedResponse>): PreparationCellChangedResponse => r.body)
    );
  }

  /** Path part for operation `preparationsEventsChangePreparationStatusPost()` */
  static readonly PreparationsEventsChangePreparationStatusPostPath = '/preparations-events/change-preparation-status';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `preparationsEventsChangePreparationStatusPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsEventsChangePreparationStatusPost$Plain$Response(params?: PreparationsEventsChangePreparationStatusPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<PreparationStatusChangedResponse>> {
    return preparationsEventsChangePreparationStatusPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `preparationsEventsChangePreparationStatusPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsEventsChangePreparationStatusPost$Plain(params?: PreparationsEventsChangePreparationStatusPost$Plain$Params, context?: HttpContext): Observable<PreparationStatusChangedResponse> {
    return this.preparationsEventsChangePreparationStatusPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<PreparationStatusChangedResponse>): PreparationStatusChangedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `preparationsEventsChangePreparationStatusPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsEventsChangePreparationStatusPost$Json$Response(params?: PreparationsEventsChangePreparationStatusPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<PreparationStatusChangedResponse>> {
    return preparationsEventsChangePreparationStatusPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `preparationsEventsChangePreparationStatusPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preparationsEventsChangePreparationStatusPost$Json(params?: PreparationsEventsChangePreparationStatusPost$Json$Params, context?: HttpContext): Observable<PreparationStatusChangedResponse> {
    return this.preparationsEventsChangePreparationStatusPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<PreparationStatusChangedResponse>): PreparationStatusChangedResponse => r.body)
    );
  }

}
