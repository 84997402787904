import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { BptGridComponent, BptGridMode, BptGridRowActionClickEvent, BptGridRowActionConfiguration, BptRowActionElement, ColumnDefinition } from 'bpt-ui-library/bpt-grid';
import { PromptItem } from '../../../api/models';
import { ExperimentService } from '../../services/experiment.service';
import { PromptsService } from '../prompts.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { UnsubscribeAll } from '../../../shared/rx-js-helpers';
import { RemovedPromptsComponent } from './prompts-removed-rows/prompts-removed-rows.component';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { PromptType } from '../../../api/cookbook/models';
import { BaseComponent } from '../../../base/base.component';
import { ClientStateService } from '../../../services/client-state.service';
import { ActivatedRoute } from '@angular/router';
import { LockType } from '../../../model/input-lock.interface';
import { SatisfyPromptCommand } from '../../../api/data-entry/models';

@Component({
  selector: 'app-prompts-grid',
  templateUrl: './prompts-grid.component.html',
  styleUrls: ['./prompts-grid.component.scss']
})
export class PromptsGridComponent extends BaseComponent implements OnDestroy {
  @Input() columnDefinitions: ColumnDefinition[] = [];
  @Input() dataSource: PromptItem[] = [];
  @Input() title = '';
  @Input() promptType? : PromptType;
  gridActions!: BptGridRowActionConfiguration;
  @ViewChild('promptsGrid') grid!: BptGridComponent;
  dynamicDialogRef!: DynamicDialogRef;
  containsRemovedRows = false;

  private readonly excelSheetName = 'prompt-grid';

  gridMode = BptGridMode.dataView;
  promptsGridId?: string;
  subscriptions: Subscription[] = [];
  constructor(
    private readonly experimentService: ExperimentService,
    private readonly promptsService: PromptsService,
    private readonly dialogService: DialogService,
    public readonly clientStateService: ClientStateService,
    public readonly activatedRoute: ActivatedRoute
  ) {
    super(clientStateService, activatedRoute);
    this.handleSubscriptions();
  }

  ngOnInit(): void{
    this.addGridActions();
    this.refreshDataSource();
  }

  private addGridActions() {
    const rowActions = this.getRowActionItems();
    const actionsSubject: BehaviorSubject<BptRowActionElement[]> = new BehaviorSubject(rowActions);
    this.gridActions = {
      actions: actionsSubject
    };
  }

  public getRowActionItems(): BptRowActionElement[] {
    if(!this.promptType) return [];
    return [
      {
        id: 'lab-items-prompts-delete-row',
        enabled: this.promptsService.validateRemovePromptFeatureToEnable.bind(this, this.promptType, this.clientState),
        styleClass: 'far fa-trash-alt',
        click: this.rowDeleteActionClick.bind(this),
        tooltip: $localize`:@@RemoveItem:Remove item`
      }
    ];
  }

  private rowDeleteActionClick(e: BptGridRowActionClickEvent) {
    if(!this.promptType) return;
    if (!this.promptsService.hasRemovePermission(this.promptType)) {
      this.promptsService.failedToPerformOperationMessage();
      return;
    }
    this.promptsService.sendInputStatus(
      LockType.lock,
      e.gridId ?? '',
      e.params.data.promptId,
      this.promptsService.rowActionIds['removeRowIconId']
    );
    this.promptsService.confirmThenRemovePrompts(e.params.data);
    this.refreshDataSource();
  }

  viewRemovedRows() {
    this.dynamicDialogRef = this.dialogService.open(RemovedPromptsComponent, {
      width: '80%',
      autoZIndex: true,
      closable: true,
      closeOnEscape: true,
      data: {
        isReadOnly: true,
        columnDefinitions: this.columnDefinitions,
        type: this.promptType
      },
      styleClass: 'eln-removed-prompts-dialog',
      header: $localize`:@@removedPromptHeader:Removed Rows for ${this.promptType?.toString()
        .replace(/([A-Z])/g, ' $1')
        .trim()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
        } Prompt`
    });
  }

  private refreshDataSource(): void {
    this.promptsGridId = `promptId-${this.promptType}`;
    this.dataSource = this.promptsService.getPrompts(this.promptType);
    this.grid?.gridApi?.setGridOption('rowData', this.dataSource);
    this.grid?.gridApi?.refreshCells();
    this.containsRemovedRows = (this.promptsService.getRemovedPrompts(this.promptType)).length > 0;
  }

  ngOnDestroy(): void {
    UnsubscribeAll(this.subscriptions);
  }
  promptGridConfig = {
    allowRowAdd: false,
    includeRowNumberColumn: false,
    enableExport: true,
    useJsJodaTypes: true,
    debounceVerticalScrollbar: true
  }

  exportDataToExcel() {
    const promptType = this.grid.dataSource[0].type as PromptType;
    const visibleData = this.promptsService.getCurrentActivityPrompts(promptType) ?? [];
    const allColumns = this.grid.gridApi.getColumns();
    const columnToRemove = "promptId";
    const filteredColumns = allColumns?.filter(column => column.getColId() !== columnToRemove);
    const params = {
      columnKeys: !filteredColumns ? [] : filteredColumns.map(column => column.getColId()),
      fileName: this.getFileName() + '_' + promptType.charAt(0).toUpperCase() + promptType.slice(1) + '_Prompt',
      sheetName: this.excelSheetName,
    };
    this.grid.gridApi.updateGridOptions({
      rowData: visibleData
    });
    this.grid.gridApi.exportDataAsExcel(params);
    this.grid.gridApi.updateGridOptions({
      rowData: this.dataSource
    });
  }

  getFileName() {
    return this.experimentService?.currentExperiment?.activities.find(p => p.activityId === this.experimentService.currentActivityId)?.activityReferenceNumber;
  }

  private handleSubscriptions() {
    this.subscriptions.push(this.promptsService.promptSatisfied.subscribe({
      next: (result: SatisfyPromptCommand) => {
        const promptsNode = this.experimentService.currentExperiment?.activityPrompts?.find(p => p.activityId === this.experimentService.currentActivity?.activityId);
        const prompt = promptsNode?.prompts.find(p => p.promptId === result.promptId);
        if(prompt)  {prompt.isUsed = result.isSatisfied}
        const data = this.dataSource.find(x => x.promptId === prompt?.promptId);
        if(data) {
          this.grid.gridApi.applyTransaction({update: [data]});
          this.promptsService.promptSelectionUpdated.next(result);
        }
      }
    }));

    this.subscriptions.push(this.promptsService.PromptRemovedRefresh.subscribe({
      next: () => {
        this.refreshDataSource();
      }
    }));

    this.subscriptions.push(this.promptsService.PromptRestoredRefresh.subscribe({
      next: () => {
        this.refreshDataSource();
      }
    }))
  }
}
