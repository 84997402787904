import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ExperimentOptionsHelper } from '../../shared/experiment-options-helper';

@Injectable({
  providedIn: 'root'
})
export class ExperimentPreviewHeaderInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers.has("X-Experiment-PreviewMode")) return next.handle(req);
    const modified = req.clone({
      setHeaders: {
        ...this.previewModeHeaders()
      }
    });
    return next.handle(modified);
  }

  private previewModeHeaders(): {
    'X-Experiment-PreviewMode': string;
  } {
    return {
      'X-Experiment-PreviewMode':
      ExperimentOptionsHelper.getOptionsFromRoute().previewMode ? 'true' : 'false'
    };
  }

}

