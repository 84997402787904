import { Component, OnDestroy } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";
import { FilesTabHelper } from "./shared/files-tab-helper";
import { ExperimentWorkflowState } from "../../api/models";
import { UserService } from "../../services/user.service";
import { ExperimentService } from "../services/experiment.service";
import { Subscription } from "rxjs";
import { DataRecordService } from "../services/data-record.service";
import { UnsubscribeAll } from "../../shared/rx-js-helpers";

@Component({
  selector: 'app-files-tab-actions-cell-renderer',
  templateUrl: './files-tab-actions-cell-renderer.html',
  styleUrls: ['./files-tab-actions-cell-renderer.scss']
})
export class FilesTabActionsCellRendererComponent implements ICellRendererAngularComp, OnDestroy {
  public params!: any;
  public deleteFile = $localize`:@@fileDelete:Delete file`;
  public downloadFile = $localize`:@@fileDownload:Download file`;
  public fileNotFound = $localize`:@@FileNotAvailable:File not available : Please raise a support ticket`;
  userActionsAllowed = false;
  subscriptionList: Subscription[] = [];

  constructor(private readonly filesTabHelper: FilesTabHelper,
    private readonly userService: UserService,
    private readonly experimentService: ExperimentService,
    readonly dataRecordService: DataRecordService) {
      this.subscriptionList.push(
        this.experimentService.experimentWorkFlowState.subscribe({
          next: (experimentWorkflowState: ExperimentWorkflowState) => {
            this.getFileActionsAccess(experimentWorkflowState);
          }
        })
      );

      this.subscriptionList.push(
      this.dataRecordService.experimentWorkFlowDataRecordReceiver.subscribe((data) => {
        this.getFileActionsAccess(data.state);
      }));
  }

  ngOnDestroy(): void {
    UnsubscribeAll(this.subscriptionList);
  }

  refresh(): boolean {
    return false;
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.getFileActionsAccess(this.experimentService.currentExperiment!.workflowState);
  }

  downloadClicked() {
    this.filesTabHelper.fileDownloadActionClicked.next(this.params.data.fileId);
  }

  deleteClicked() {
    this.filesTabHelper.fileDeleteActionClicked.next({
      fileId : this.params.data.fileId,
      fileName:  this.params.data.fileName});
  }

  private getFileActionsAccess(experimentWorkflowState: ExperimentWorkflowState) {
    this.userActionsAllowed = (this.userService.hasAnalystRights(this.userService.currentUser.puid)
    || this.userService.hasReviewerRights(this.userService.currentUser.puid)
    || this.userService.hasSupervisorRights(this.userService.currentUser.puid))
    && (experimentWorkflowState === ExperimentWorkflowState.Setup
      || experimentWorkflowState === ExperimentWorkflowState.InProgress
      || experimentWorkflowState === ExperimentWorkflowState.InReview
      || experimentWorkflowState === ExperimentWorkflowState.InCorrection)
  }
}