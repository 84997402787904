import { ChangeDetectorRef, Component, HostListener, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ElnProgressSpinnerService } from '../../eln-progress-spinner-module/eln-progress-spinner.service';
import { UnsubscribeAll } from '../../shared/rx-js-helpers';
import { ExperimentService } from '../services/experiment.service';
import { PhDeviceInfo, PhMeterService } from '../services/ph-meter.service';
import { InstrumentConnectionModalComponent } from './instrument-connection-modal/instrument-connection-modal.component';
import { InstrumentConfigMethods } from './shared/instrument-config-methods';
import { InstrumentConfigUnits } from './shared/instrument-config-units';
import { InstrumentConfigurationKeys } from './shared/instrument-configuration-keys';
import { InstrumentConnectionHelper } from './shared/instrument-connection-helper';
import { InstrumentNotificationService } from './shared/instrument-notification-service';
import { InstrumentPushValues } from './shared/instrument-push-values';
import { InstrumentType } from './shared/instrument-type';
import { InstrumentBlowerValues } from './shared/instrument-blower-value';
import { BlowerState } from '../model/instrument-connection/blower-state';
import { PhMeterMode } from '../model/instrument-connection/ph-meter-modes';

@Component({
  selector: 'app-instrument-connection',
  templateUrl: './instrument-connection.component.html',
  styleUrls: ['./instrument-connection.component.scss']
})
export class InstrumentConnectionComponent implements OnInit, OnDestroy {
  dynamicDialogRef?: DynamicDialogRef;
  enableInstrumentConnection = false;
  isInstrumentConnected = false;
  phMeterActiveInOtherTab: string | undefined;
  phMeterActiveInOtherTabToolTip: string | undefined;
  readOnlyState = false;
  disablePhMeter = false;
  isPhMeterConnectedInCurrentTab = false;
  displaySequentialReadingProgressMessage = false;
  connectedInstrumentId?: string;
  isPaused = false;
  selectedMethod = InstrumentConfigMethods.direct;
  selectedUnit: InstrumentConfigUnits | string = InstrumentConfigUnits.g;
  pushValue = InstrumentPushValues.yes;
  selectedBinaryPushValue = true;
  selectedBinaryBlowerValue = true;
  configMethods: string[] = [];
  configUnits: string[] = [];
  configPushValues: string[] = [];
  instrumentMethodLabel = $localize`:@@instrumentMethodLabel:Method`;
  instrumentUnitModeLabel = "";
  instrumentPushLabel = $localize`:@@instrumentPushLabel:Default to Push`;
  sequentialReadingMessage = $localize`:@@SequentialReadingProgressMessage:Sequential reading in progress`;
  lastConnectedInstrumentId?: string;
  reconnectAttempt = false;
  isPhInstrument = false;
  private inactiveTimeOut = 0;
  private readonly subscriptionList: Subscription[] = [];
  private readonly lastConnectedInstrumentKey = 'lastConnectedInstrumentkey';
  configBlowerVisible = false;
  blowerValue = InstrumentBlowerValues.yes;
  BLOWER_VALUE = 'Blower Value';
  BLOWER_STATE = 'Blower State';
  configBlowerEditable = false;

  @ViewChild('configurationModal') configurationModal!: OverlayPanel;
  @ViewChild('lastConnectedInstrumentModal') lastConnectedInstrumentModal!: OverlayPanel;

  constructor(
    private readonly instrumentConnectionHelper: InstrumentConnectionHelper,
    private readonly dialogService: DialogService,
    private readonly messageService: MessageService,
    private readonly instrumentNotificationService: InstrumentNotificationService,
    private readonly ngZone: NgZone,
    private readonly elnProgressSpinnerService: ElnProgressSpinnerService,
    private readonly phMeterService: PhMeterService,
    public readonly experimentService: ExperimentService,
    public readonly changeDetection: ChangeDetectorRef
  ) {
    this.handleSubscriptions();
  }
  ngOnDestroy(): void {
    UnsubscribeAll(this.subscriptionList);
    this.dynamicDialogRef?.destroy();
  }

  ngOnInit(): void {
    this.initializeDropdownValues();
    this.instrumentConnectionHelper.manageConnectionIconVisibility();
    this.instrumentNotificationService.joinInstrumentHub(InstrumentType.balance);
  }

  startTimer(): void {
    this.inactiveTimeOut = window.setTimeout(() => {
      this.disconnectFromInstrument();
      const instrumentType = this.instrumentConnectionHelper.instrumentType ?? localStorage.getItem('instrumentType');
      this.messageService.add({
        key: 'notification',
        severity: 'error',
        sticky: true,
        summary: $localize`:@@instrumentDisconnectedDueToInactivity:Disconnected from ${instrumentType} due to inactivity`
      });
    }, environment.instrumentDisconnectTimeout);
  }

  onOk() {
    const previousSelectedMethod = localStorage.getItem(InstrumentConfigurationKeys.method);
    const previousPushValue = localStorage.getItem(InstrumentConfigurationKeys.push);
    const previousSelectedUnit = localStorage.getItem(InstrumentConfigurationKeys.unit);
    const previousBlowerValue = localStorage.getItem(this.BLOWER_VALUE);
    if (previousPushValue === this.pushValue
      && previousSelectedMethod === this.selectedMethod
      && previousSelectedUnit === this.selectedUnit
      && previousBlowerValue === this.blowerValue) return;
    localStorage.setItem(InstrumentConfigurationKeys.method, this.selectedMethod);
    localStorage.setItem(InstrumentConfigurationKeys.push, this.pushValue);
    localStorage.setItem(InstrumentConfigurationKeys.unit, this.selectedUnit);
    localStorage.setItem(this.BLOWER_VALUE, this.blowerValue);
    this.instrumentConnectionHelper.setConfigurationsForSelectedInstrument(this.selectedMethod, this.selectedUnit, this.pushValue);
    this.messageService.add({
      key: 'notification',
      severity: 'success',
      summary: $localize`:@@instrumentConfigConfigured:Instrument Configuration Updated`
    });
  }

  openInstrumentConnectionModal() {
    if (!this.enableInstrumentConnection) return;
    this.dynamicDialogRef = this.dialogService.open(
      InstrumentConnectionModalComponent, {
      width: '60%',
      autoZIndex: true,
      closeOnEscape: true,
      maximizable: true,
      header: $localize`:@@instrumentSelection:Instrument selection`,
      styleClass: 'eln-instrument-connection-dialog'
    });
  }

  async connectToLastConnectedInstrument() {
    this.elnProgressSpinnerService.Show({
      message: $localize`:@@balanceConnecting:Connecting to Balance...`,
      i18nMessage: '@@balanceConnecting'
    });
    this.reconnectAttempt = true;
    await this.instrumentNotificationService.startConnection(InstrumentType.balance, this.lastConnectedInstrumentId);
    this.elnProgressSpinnerService.Hide();
    this.lastConnectedInstrumentModal.hide();
    if (this.lastConnectedInstrumentId) {
      this.instrumentConnectionHelper.instrumentType = InstrumentType.balance;
      localStorage.setItem('instrumentType', this.instrumentConnectionHelper.instrumentType);
    }
  }

  disconnectFromInstrument() {
    if (!this.instrumentConnectionHelper.instrumentDisconnected) {
      clearTimeout(this.inactiveTimeOut);
      const instrumentType = this.instrumentConnectionHelper.instrumentType || localStorage.getItem('instrumentType');
      if (this.connectedInstrumentId) {
        this.elnProgressSpinnerService.Show({
          i18nMessage: '@@instrumentDisconnecting',
          message: $localize`:@@instrumentDisconnecting:Disconnecting from ${instrumentType}...`,
        })
        if (instrumentType === InstrumentType.phMeter && this.instrumentConnectionHelper.phName) {
          console.log('Disconnection for ph meter initiated: ' + this.instrumentConnectionHelper.phName);
          this.phMeterService.disconnect();
          this.instrumentNotificationService.disconnectFromInstrument(this.instrumentConnectionHelper.phName, instrumentType);
        } else {
          this.instrumentNotificationService.disconnectFromInstrument(this.connectedInstrumentId, InstrumentType.balance);
        }
        this.isPaused = false;
      }
      this.instrumentConnectionHelper.instrumentDisconnected = true;
      this.configurationModal.hide();
      this.elnProgressSpinnerService.Hide();
      this.messageService.add({
        key: 'notification',
        severity: 'error',
        closable: true,
        summary: $localize`:@@instrumentDisconnected:Disconnected from ${instrumentType}`
      });
    }
  }

  async pauseOrUnpauseInstrumentConnection() {
    const instrumentType = this.instrumentConnectionHelper.instrumentType ?? localStorage.getItem('instrumentType');
    if (instrumentType) {
      const instrument = instrumentType === InstrumentType.balance ? InstrumentType.balance : InstrumentType.phMeter;
      this.isPaused = !this.isPaused;
      if (this.connectedInstrumentId) {
        await this.instrumentNotificationService.pauseOrUnpauseInstrumentConnection(
          this.connectedInstrumentId,
          this.isPaused,
          instrument
        );
        if (this.isPaused) {
          this.messageService.add({
            key: 'notification',
            severity: 'warn',
            closable: true,
            summary: $localize`:@@instrumentPaused:${instrumentType} connection paused`
          });
        }
      }
    }
  }


  @HostListener('mousedown')
  @HostListener('keydown')
  resetTimer() {
    this.ngZone.runOutsideAngular(() => {
      if (this.connectedInstrumentId) {
        clearTimeout(this.inactiveTimeOut);
        this.startTimer();
      }
    });
  }

  private handleSubscriptions() {
    this.subscriptionList.push(this.instrumentConnectionHelper.instrumentConnectionVisibilityUpdated.subscribe(visibility => {
      const instrumentType = this.instrumentConnectionHelper.instrumentType || localStorage.getItem('instrumentType');
      this.enableInstrumentConnection = visibility;
      this.readOnlyState = this.instrumentConnectionHelper.isReadonlyWorkflowState();
      this.isInstrumentConnected = !!this.connectedInstrumentId
      if (!this.connectedInstrumentId) return;
      if (!this.enableInstrumentConnection && instrumentType === InstrumentType.phMeter) {
        this.disconnectFromInstrument();
        return;
      }
      this.enableInstrumentConnection = this.instrumentConnectionHelper.isConnectionButtonVisible();
      this.isInstrumentConnected = true;
    }));

    this.subscriptionList.push(this.instrumentConnectionHelper.instrumentConnectionVisibilityOnCoverPageUpdated.subscribe(visibility => {
      this.enableInstrumentConnection = visibility;
      this.isInstrumentConnected = !!this.connectedInstrumentId
      if (this.connectedInstrumentId) {
        this.enableInstrumentConnection = this.instrumentConnectionHelper.isConnectionButtonVisible();
        this.isInstrumentConnected = true;
      }
    }));

    this.subscriptionList.push(this.instrumentConnectionHelper.phMeterIsDisconnected.subscribe(() => {
      this.connectedInstrumentId = undefined;
      this.enableInstrumentConnection = this.instrumentConnectionHelper.isPermittedClientState() && this.instrumentConnectionHelper.isPermittedWorkflowState();
      this.lastConnectedInstrumentId = localStorage.getItem(this.lastConnectedInstrumentKey) ?? undefined;
      this.readOnlyState = this.instrumentConnectionHelper.isReadonlyWorkflowState();
      this.isInstrumentConnected = false;
      this.reconnectAttempt = false;
      this.isPaused = false;
      this.disablePhMeter = false;
      this.instrumentConnectionHelper.setInstrumentConnectedStatus(false);
    }));

    this.subscriptionList.push(this.instrumentConnectionHelper.instrumentConnectionUpdatedOnWorkflowStateChange.subscribe(visibility => {
      const instrumentType = this.instrumentConnectionHelper.instrumentType || localStorage.getItem('instrumentType');
      this.readOnlyState = this.instrumentConnectionHelper.isReadonlyWorkflowState();
      if (instrumentType === InstrumentType.phMeter && this.connectedInstrumentId) {
        this.validateInstrumentConnection();
      } else {
        if (!this.connectedInstrumentId) this.instrumentNotificationService.joinInstrumentHub(InstrumentType.balance);
        this.readOnlyState = this.displaySequentialReadingProgressMessage === true ? true : this.instrumentConnectionHelper.isReadonlyWorkflowState();
        this.enableInstrumentConnection = !this.readOnlyState && this.connectedInstrumentId ? true : this.instrumentConnectionHelper.isPermittedClientState() && this.instrumentConnectionHelper.isPermittedWorkflowState();
        if (!this.enableInstrumentConnection && this.connectedInstrumentId) this.instrumentNotificationService.disconnectFromInstrument(this.connectedInstrumentId, InstrumentType.balance);
      }
    }));

    this.subscriptionList.push(
      this.instrumentConnectionHelper.instrumentConnectionSuccess.subscribe(
        (connectedInstrumentId: string) => {
          this.startTimer();
          this.initializeInstrumentConnection();
          this.connectedInstrumentId = connectedInstrumentId;
          this.reconnectAttempt = false;
          localStorage.setItem(this.lastConnectedInstrumentKey, this.connectedInstrumentId);
          if (this.instrumentConnectionHelper.balanceName) localStorage.setItem("lastConnectedInstrumentName", this.instrumentConnectionHelper.balanceName);
          if (this.instrumentConnectionHelper.instrumentType === InstrumentType.phMeter) {
            localStorage.removeItem(InstrumentConfigurationKeys.unit);
            this.instrumentConnectionHelper.configuredUnit = undefined;
          } else if (this.instrumentConnectionHelper.instrumentType === InstrumentType.balance) {
            this.phMeterActiveInOtherTab = this.phMeterActiveInOtherTabToolTip = undefined;
            this.disablePhMeter = false;
          }
          this.instrumentConnectionHelper.setInstrumentConnectedStatus(true);
          this.dynamicDialogRef?.close();
        }
      )
    );

    this.subscriptionList.push(
      this.instrumentConnectionHelper.instrumentConnectionSuccessForOtherExperiments.subscribe(
        (connectedInstrumentId: string | undefined) => {
          if (connectedInstrumentId) {
            this.initializeInstrumentConnection();
            this.connectedInstrumentId = connectedInstrumentId;
          }
        }
      )
    );

    this.subscriptionList.push(this.instrumentConnectionHelper.instrumentConnectionPausedSuccessfully.subscribe((result) => {
      this.isPaused = true;
      this.instrumentConnectionHelper.setInstrumentPausedStatus(true);
      this.instrumentConnectionHelper.instrumentPausedSuccessfully.next(result);
      if (result && result.instrumentType === InstrumentType.phMeter) {
        this.phMeterActiveInOtherTabToolTip = $localize`:@@phMeterPausedInOtherTab:pH meter connection paused`;
      }

    }));

    this.subscriptionList.push(this.instrumentConnectionHelper.instrumentConnectionUnpausedSuccessfully.subscribe((result) => {
      this.isPaused = false;
      this.instrumentConnectionHelper.setInstrumentPausedStatus(false);
      this.instrumentConnectionHelper.instrumentUnpaused.next(result);
      if (result && result.instrumentType === InstrumentType.phMeter) {
        this.phMeterActiveInOtherTabToolTip = $localize`:@@phMeterActiveInOtherTab:pH meter active in a different tab`;
      }
    }));

    this.subscriptionList.push(this.instrumentConnectionHelper.instrumentDisconnectedSuccessfully.subscribe(() => {
      this.connectedInstrumentId = undefined;
      this.enableInstrumentConnection = this.instrumentConnectionHelper.isConnectionButtonVisible();
      this.enableInstrumentConnection = this.instrumentConnectionHelper.isPermittedWorkflowState();
      this.lastConnectedInstrumentId = localStorage.getItem(this.lastConnectedInstrumentKey) || undefined;
      this.readOnlyState = this.instrumentConnectionHelper.isReadonlyWorkflowState();
      this.isInstrumentConnected = false;
      this.reconnectAttempt = false;
      this.isPaused = false;
      this.instrumentConnectionHelper.setInstrumentConnectedStatus(false);
      console.log("Instrument Disconnected Successfully: ", this.isInstrumentConnected);
    }));

    this.subscriptionList.push(this.instrumentConnectionHelper.instrumentConnectionFailed.subscribe((instrumentName: string) => {
      if (this.reconnectAttempt) {
        this.messageService.add({
          key: 'notification',
          severity: 'warn',
          summary: $localize`:@@instrumentReconnectionFailed:Selected balance not available, please connect to another balance`
        })
        this.reconnectAttempt = false;
      }
      else {
        console.log('Instrument Connection Failed: ' + instrumentName);
        this.messageService.add({
          key: 'notification',
          severity: 'error',
          summary: $localize`:@@instrumentConnectionFailed:Connection to ${instrumentName} Failed`
        });
      }
      this.isInstrumentConnected = false;
      this.instrumentConnectionHelper.setInstrumentConnectedStatus(false);
      this.connectedInstrumentId = undefined;
      console.log("Instrument Connection Failed: ", this.isInstrumentConnected);
    }));

    this.subscriptionList.push(
      this.instrumentConnectionHelper.sequentialReadingIsInProgress.subscribe((result: boolean) => {
        this.readOnlyState = result === true ? true : this.instrumentConnectionHelper.isReadonlyWorkflowState();
        this.displaySequentialReadingProgressMessage = result;
      })
    )

    this.subscriptionList.push(this.instrumentConnectionHelper.phMeterConnectionStarted.subscribe(() => {
      localStorage.removeItem(InstrumentConfigurationKeys.unit);
      this.instrumentConnectionHelper.configuredUnit = this.selectedUnit;
      this.phMeterService.connectToPhMeter();
    }));

    this.subscriptionList.push(this.instrumentConnectionHelper.phMeterConnectionSuccess.subscribe((deviceInfo: PhDeviceInfo) => {
      this.connectedInstrumentId = $localize`:@@phMeter:pH Meter`;
      this.instrumentConnectionHelper.phName = deviceInfo.InstrumentModel;
      if (this.connectedInstrumentId) {
        this.instrumentNotificationService.connectToPhMeter();
        this.enableInstrumentConnection = this.instrumentConnectionHelper.isConnectionButtonVisible();
        this.isInstrumentConnected = true;
        this.isPhMeterConnectedInCurrentTab = true;
        this.instrumentConnectionHelper.phName = $localize`:@@phMeter:pH Meter`;
        this.changeDetection.detectChanges();
        this.instrumentConnectionHelper.pHMeterConnectedForCurrentExperiment.next(true);
        this.setPhMeterMasterConfigUnit();
        console.log('Connection is processed ok with hub');
      }
      this.instrumentConnectionHelper.setInstrumentConnectedStatus(true);
      console.log('Instrument connected status is set');
      this.dynamicDialogRef?.close();
    }));

    this.subscriptionList.push(
      this.instrumentConnectionHelper.phMeterDisconnectedSuccessfully.subscribe(() => {
        this.connectedInstrumentId = undefined;
        this.enableInstrumentConnection =
          this.instrumentConnectionHelper.isConnectionButtonVisible();
        this.readOnlyState = this.instrumentConnectionHelper.isReadonlyWorkflowState();
        this.isInstrumentConnected = false;
        this.instrumentConnectionHelper.setInstrumentConnectedStatus(false);
        this.dynamicDialogRef?.close();
        localStorage.removeItem(InstrumentConfigurationKeys.unit);
        this.instrumentConnectionHelper.configuredUnit = undefined;
        this.instrumentConnectionHelper.pHMeterSequentialReadingSelectedUnit = undefined;
        console.log("ph Meter Disconnected Successfully: ", this.isInstrumentConnected);
      })
    );

    this.subscriptionList.push(this.instrumentConnectionHelper.detectInstrumentConnectionChanged.subscribe((changed) => {
      if (changed) {
        this.changeDetection.detectChanges();
      }
    }))

    this.subscriptionList.push(this.instrumentConnectionHelper.phMeterConnectionSuccessfulForOtherExperiments.subscribe((equipmentId) => {
      this.handleActivePhMeterForOtherExperiments(equipmentId);
      if (equipmentId === undefined) {
        this.connectedInstrumentId = undefined;
        this.enableInstrumentConnection = this.instrumentConnectionHelper.isPermittedWorkflowState();
        this.isInstrumentConnected = false;
        this.readOnlyState = this.instrumentConnectionHelper.isReadonlyWorkflowState();
        this.isPaused = false;
        this.phMeterActiveInOtherTab = this.phMeterActiveInOtherTabToolTip = undefined;
        this.disablePhMeter = false;
        console.log("ph Meter Connection Successful For Other Experiments: ", this.isInstrumentConnected);
      }
    }));
  }

  private setPhMeterMasterConfigUnit() {
    localStorage.setItem(InstrumentConfigurationKeys.unit, PhMeterMode.pH);
  }

  private handleActivePhMeterForOtherExperiments(equipmentId: string | undefined) {
    const isPhMeterConnectedInCurrentTab = this.phMeterService.getPhMeterActiveTab();
    if (equipmentId && !isPhMeterConnectedInCurrentTab) {
      if (this.instrumentConnectionHelper.hasUserEditedInstrumentConnectionForPhMeter()) {
        this.enableInstrumentConnection = this.instrumentConnectionHelper.isConnectionButtonVisible();
        this.phMeterActiveInOtherTabToolTip = $localize`:@@phMeterActiveInOtherTab:pH meter active in a different tab`;
        this.phMeterActiveInOtherTab = this.connectedInstrumentId = $localize`:@@phMeter:pH Meter`;
        this.isInstrumentConnected = true;
        this.disablePhMeter = true;
        this.isPhMeterConnectedInCurrentTab = !this.disablePhMeter;
        this.instrumentConnectionHelper.phName = $localize`:@@phMeter:pH Meter`;
        this.instrumentConnectionHelper.setInstrumentConnectedStatus(true);
        this.changeDetection.detectChanges();
        this.instrumentConnectionHelper.pHMeterConnectedForOtherExperiments.next(true);
      }
    }
  }

  validateInstrumentConnection() {
    this.enableInstrumentConnection = this.instrumentConnectionHelper.isPermittedWorkflowState();
    if (!this.enableInstrumentConnection) this.disconnectFromInstrument();
  }

  private initializeInstrumentConnection() {
    this.enableInstrumentConnection = this.instrumentConnectionHelper.isConnectionButtonVisible();
    this.readOnlyState = this.displaySequentialReadingProgressMessage === true ? true : this.instrumentConnectionHelper.isReadonlyWorkflowState();
    this.isInstrumentConnected = true;
    this.instrumentConnectionHelper.setInstrumentConnectedStatus(true);
  }

  private initializeDropdownValues() {
    const instrumentType = this.instrumentConnectionHelper.instrumentType ?? localStorage.getItem('instrumentType');
    if (instrumentType === InstrumentType.phMeter)
      this.isPhInstrument = true;
    this.configMethods = Object.values(InstrumentConfigMethods);
    if (this.isPhInstrument) {
      this.configMethods = [this.configMethods[0]];
      this.selectedMethod = this.configMethods[0] as InstrumentConfigMethods;
      this.configUnits = ['pH', 'mV'];
      this.instrumentUnitModeLabel = $localize`:@@mode:Mode`;
      this.selectedUnit = localStorage.getItem(InstrumentConfigurationKeys.unit) as InstrumentConfigUnits ?? this.configUnits[0];
      this.selectedBinaryPushValue = true;
      this.connectedInstrumentId = this.instrumentConnectionHelper.phName;
      localStorage.setItem(InstrumentConfigurationKeys.unit, this.selectedUnit);
      return
    }
    this.configUnits = [InstrumentConfigUnits.g, InstrumentConfigUnits.mg];
    this.configPushValues = Object.values(InstrumentPushValues);
    this.instrumentUnitModeLabel = $localize`:@@instrumentUnitLabel:Unit`;
    this.lastConnectedInstrumentId = localStorage.getItem(this.lastConnectedInstrumentKey) || undefined;
    this.selectedBinaryBlowerValue = this.blowerValue === InstrumentBlowerValues.yes;
  }

  updatePushValue(event: boolean) {
    this.pushValue = event ? InstrumentPushValues.yes : InstrumentPushValues.no;
  }

  setConfigValue() {
    const instrumentType = this.instrumentConnectionHelper.instrumentType ?? localStorage.getItem('instrumentType');
    if (instrumentType === InstrumentType.balance) {
      this.isPhInstrument = false;
      this.pushValue = localStorage.getItem(InstrumentConfigurationKeys.push) as InstrumentPushValues;
      this.setBlowerState();
      this.selectedBinaryPushValue = this.pushValue === InstrumentPushValues.yes;
      this.blowerValue = this.getBlowerValue();
      this.selectedBinaryBlowerValue = this.blowerValue === InstrumentBlowerValues.yes;
      this.selectedMethod = localStorage.getItem(InstrumentConfigurationKeys.method) as InstrumentConfigMethods;
      this.selectedUnit = localStorage.getItem(InstrumentConfigurationKeys.unit) as InstrumentConfigUnits;
    }
    this.initializeDropdownValues();
  }

  getBlowerValue() {
    if (localStorage.getItem(this.BLOWER_STATE) == BlowerState.Blower_On_Off)
      return localStorage.getItem(this.BLOWER_VALUE) as InstrumentBlowerValues || InstrumentBlowerValues.yes
    else if (localStorage.getItem(this.BLOWER_STATE) == BlowerState.Blower_On) return InstrumentBlowerValues.yes
    else return InstrumentBlowerValues.no
  }


  handleConnectedInstrumentConnection(event: MouseEvent) {
    if (localStorage.getItem('instrumentType') === InstrumentType.phMeter) {
      const isPhMeterConnectedInCurrentTab = this.phMeterService.getPhMeterActiveTab();
      if (isPhMeterConnectedInCurrentTab && !(+isPhMeterConnectedInCurrentTab)) return;
    }

    this.configurationModal.toggle(event);
  }

  handleInstrumentLabel() {
    return this.phMeterActiveInOtherTab ? this.phMeterActiveInOtherTab : this.connectedInstrumentId;
  }

  handleInstrumentLabelToolTip() {
    return this.phMeterActiveInOtherTabToolTip ? this.phMeterActiveInOtherTabToolTip : this.connectedInstrumentId;
  }

  setBlowerState() {
    const blowerState = localStorage.getItem(this.BLOWER_STATE) || BlowerState.No_Blower;
    if (blowerState === BlowerState.No_Blower || blowerState === BlowerState.Blower_Off) {
      this.configBlowerVisible = false
    } else {
      this.configBlowerVisible = true
      this.configBlowerEditable = blowerState === BlowerState.Blower_On_Off;
      this.blowerValue = localStorage.getItem(this.BLOWER_VALUE) as InstrumentBlowerValues;
    }
  }

  updateBlowerValue(event: boolean) {
    this.blowerValue = event ? InstrumentBlowerValues.yes : InstrumentBlowerValues.no;
  }
}
